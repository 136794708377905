import * as constants from '@sep/br24-constants';

import { calculatePercentage, normalizeData } from '.';

const getChartDataModuleTypes = (
	data: {
		type: string;
		count: number;
	}[]
) => {
	const normalizedData = normalizeData(data);
	const totalCount = normalizedData.totalAv + normalizedData.totalText;

	return {
		text: {
			name: 'Artikel mit Bild',
			fill: constants.COLOR_ALIZARIN,
			value: normalizedData.text,
			percentage: calculatePercentage(normalizedData.text, totalCount),
		},
		breakingNews: {
			name: 'Eilmeldungen',
			fill: constants.COLOR_PUMPKIN,
			value: normalizedData.breakingNews,
			percentage: calculatePercentage(normalizedData.breakingNews, totalCount),
		},
		audio: {
			name: 'Artikel mit Audio',
			fill: constants.COLOR_TURQUOISE,
			value: normalizedData.audio,
			percentage: calculatePercentage(normalizedData.audio, totalCount),
		},
		video: {
			name: 'Artikel mit Video',
			fill: constants.COLOR_GREEN_SEA,
			value: normalizedData.video,
			percentage: calculatePercentage(normalizedData.video, totalCount),
		},
	};
};

export default getChartDataModuleTypes;
