import type { UpdateSearchTerm } from '..';
import { UPDATE_IMPORTER_SEARCH_TERM_FROM_ARTICLE_EDITOR } from '../actionTypes';

const updateImporterSearchTermFromArticleEditor = (
	importerSearchTermFromArticleEditor: string
): UpdateSearchTerm => ({
	type: UPDATE_IMPORTER_SEARCH_TERM_FROM_ARTICLE_EDITOR,
	importerSearchTermFromArticleEditor,
});

export default updateImporterSearchTermFromArticleEditor;
