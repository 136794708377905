import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../environment';

import type { CategoryArticleStatsContainerQuery } from './__generated__/CategoryArticleStatsContainerQuery.graphql';

type Props = {
	startDate: string;
	endDate: string;
	render: QueryRendererProps<CategoryArticleStatsContainerQuery>['render'];
};

export default function ({ startDate, endDate, render }: Props) {
	return (
		<QueryRenderer<CategoryArticleStatsContainerQuery>
			environment={environment}
			query={graphql`
				query CategoryArticleStatsContainerQuery($startDate: Datetime, $endDate: Datetime) {
					stats: allCategories {
						edges {
							node {
								title
								color
								articleCount(fromDate: $startDate, toDate: $endDate)
							}
						}
					}
				}
			`}
			variables={{
				startDate,
				endDate,
			}}
			render={render}
		/>
	);
}
