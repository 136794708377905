import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../environment';

import type { RegionStatsContainerQuery } from './__generated__/RegionStatsContainerQuery.graphql';
type Props = {
	endDate: string;
	startDate: string;
	render: QueryRendererProps<RegionStatsContainerQuery>['render'];
};

export default function ({ startDate, endDate, ...rest }: Props) {
	return (
		<QueryRenderer<RegionStatsContainerQuery>
			environment={environment}
			query={graphql`
				query RegionStatsContainerQuery($startDate: Datetime, $endDate: Datetime) {
					stats: regionsArticleCountByFirstPublicationDate(
						fromDate: $startDate
						toDate: $endDate
						statusFilter: PUBLISHED
					) {
						nodes {
							district @required(action: LOG)
							count @required(action: LOG)
						}
					}
				}
			`}
			variables={{
				startDate,
				endDate,
			}}
			{...rest}
		/>
	);
}
