import { useState } from 'react';
import styled from 'styled-components';

import Checkmark from '../../../ui/Checkmark';

const Wrapper = styled.div`
	width: 250px;
	flex: 1 1 250px;
	min-height: 250px;
	background: #fff;
	margin: 0 2.5rem 1.6rem 0;
	overflow: hidden;
	cursor: pointer;
	box-shadow: ${({ theme }) => theme.boxShadow.default};
	border-radius: 10px;
`;

const ImageContainer = styled.div<{ image: string; isActive: boolean; isHovered: boolean }>`
	width: 100%;
	height: 190px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;

		background-image: url(${(props) => props.image});
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		${(props) => (!props.isActive ? `opacity: 0.4` : '')};
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-bottom-style: solid;

		${(props) => (props.isActive ? `border-bottom-color: #1ebd6f` : `border-bottom-color: #ccc`)};
		${(props) => (!props.isHovered ? `border-bottom-width: 3px` : `border-bottom-width: 6px`)};
		transition: all 200ms ease-in-out;
	}
`;

const TextWrapper = styled.div`
	display: flex;
	padding: 1rem 1.6rem;
`;

const UserInfo = styled.div`
	flex: 1 1 auto;
`;

const Firstname = styled.h4`
	font-size: 13px;
	font-weight: 400;
	line-height: 20px;
`;

const Lastname = styled.h3`
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
`;

type Props = {
	firstname: string;
	isActive: boolean;
	isLdapUser: boolean;
	lastname: string;
	profilePicture: string;
	handleOnClick: () => void;
};

function AuthorsListItem({
	firstname,
	isActive,
	isLdapUser = false,
	lastname,
	profilePicture,
	handleOnClick,
}: Props) {
	const [hovered, setHovered] = useState(false);

	return (
		<Wrapper
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			onClick={handleOnClick}
		>
			<ImageContainer isHovered={hovered} isActive={isActive} image={profilePicture} />
			<TextWrapper>
				<UserInfo>
					<Firstname>{firstname}</Firstname>
					<Lastname>{lastname}</Lastname>
				</UserInfo>
				{isLdapUser ? <Checkmark /> : null}
			</TextWrapper>
		</Wrapper>
	);
}

export default AuthorsListItem;
