import { fetchQuery, graphql } from 'react-relay';

import type { BrBildSearchParameters } from '../../..';
import config from '../../../../../config';
import environment from '../../../../../environment';

import { type searchBrBildLoginQuery } from './__generated__/searchBrBildLoginQuery.graphql';
import { type searchBrBildMetadataQuery } from './__generated__/searchBrBildMetadataQuery.graphql';
import { type searchBrBildSearchQuery } from './__generated__/searchBrBildSearchQuery.graphql';

const MAX = 1000; // Default for total number of results
const COUNT = 25; // Default number of results per page
const OFFSET = 0; // Default offset value

const loginQuery = graphql`
	query searchBrBildLoginQuery($credentials: BRBildCredentials, $config: BRBildRequestConfig) {
		brBildAPI {
			login(credentials: $credentials, config: $config)
		}
	}
`;

const searchQuery = graphql`
	query searchBrBildSearchQuery(
		$config: BRBildRequestConfig
		$query: String
		$options: BRBildSearchOptionsWithRestrictions
	) {
		brBildAPI {
			search(config: $config, query: $query, options: $options) {
				totalCount
				nodes {
					id
				}
			}
		}
	}
`;

const metadataQuery = graphql`
	query searchBrBildMetadataQuery($config: BRBildRequestConfig, $ids: [String]) {
		brBildAPI {
			metadata(config: $config, ids: $ids) {
				nodes {
					id
					imageId
					type
					title
					description
					provider
					producer
					copyright
					copyrightNotice
					copyrightStatus
					usageConstraints
					createdAt
					format
				}
			}
		}
	}
`;

async function login() {
	if (!config.VITE_BRBILD_USER || !config.VITE_BRBILD_PASSWORD) {
		throw new Error('No login credentials provided for BR Bild');
	}

	const loginVariables = {
		credentials: {
			username: config.VITE_BRBILD_USER,
			password: config.VITE_BRBILD_PASSWORD,
		},
		config: {
			endpoint: config.VITE_BRBILD_URL_EXT,
		},
	} as unknown as searchBrBildLoginQuery['variables'];

	return fetchQuery<searchBrBildLoginQuery>(environment, loginQuery, loginVariables)
		.toPromise()
		.then((res) => res?.brBildAPI?.login);
}

async function fetchImages(parameters: BrBildSearchParameters) {
	const sessionId = await login();

	if (!sessionId) {
		throw new Error('Could not retrieve session ID for BR Bild importer');
	}

	const searchVariables = {
		config: {
			sessionId,
			endpoint: config.VITE_BRBILD_URL_EXT,
		},
		query: parameters.terms,
		options: {
			max: parameters?.max ?? MAX,
			offset: parameters?.offset ?? OFFSET,
			count: parameters?.count ?? COUNT,
			imageTypes: parameters?.imageTypes ?? [],
			rights: parameters?.rightsOwner ?? [],
		},
	} as searchBrBildSearchQuery['variables'];

	return fetchQuery<searchBrBildSearchQuery>(environment, searchQuery, searchVariables)
		.toPromise()
		.then((res) => {
			return res?.brBildAPI?.search;
		});
}

async function fetchData(ids: string[]) {
	const sessionId = await login();
	if (!sessionId) {
		throw new Error('Could not retrieve session ID for BR Bild importer');
	}

	const variables = {
		config: {
			sessionId,
			endpoint: config.VITE_BRBILD_URL_EXT,
		},
		ids,
	};

	return fetchQuery<searchBrBildMetadataQuery>(environment, metadataQuery, variables)
		.toPromise()
		.then((res) => {
			return res?.brBildAPI?.metadata;
		});
}

export async function searchBrBild(searchParameters: BrBildSearchParameters) {
	return fetchImages(searchParameters);
}

export async function getBrBildData(ids: string[]) {
	return fetchData(ids);
}
