/**
 * @generated SignedSource<<2dfb2905a18285cc483eae7d4404b318>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type RepublishArticleInput = {
  articleId?: string | null;
  clientMutationId?: string | null;
  overwriteSlug?: string | null;
  overwriteTitle?: string | null;
};
export type RepublishArticleMutation$variables = {
  input: RepublishArticleInput;
};
export type RepublishArticleMutation$data = {
  readonly republishArticle: {
    readonly article: {
      readonly lastEdited: string;
      readonly publicationDate: string | null;
      readonly rowId: string;
      readonly slug: string;
      readonly status: Status;
      readonly title: string;
    } | null;
  } | null;
};
export type RepublishArticleMutation = {
  response: RepublishArticleMutation$data;
  variables: RepublishArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastEdited",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RepublishArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepublishArticlePayload",
        "kind": "LinkedField",
        "name": "republishArticle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "article",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RepublishArticleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepublishArticlePayload",
        "kind": "LinkedField",
        "name": "republishArticle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "article",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd11fc25fbbcbb25fd06b8636cf447bf",
    "id": null,
    "metadata": {},
    "name": "RepublishArticleMutation",
    "operationKind": "mutation",
    "text": "mutation RepublishArticleMutation(\n  $input: RepublishArticleInput!\n) {\n  republishArticle(input: $input) {\n    article {\n      rowId\n      slug\n      title\n      status\n      lastEdited\n      publicationDate\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7cbe11ed909252b30610037a66e70026";

export default node;
