/**
 * @generated SignedSource<<b9e4a774e852cc444ee71715db95a4d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PushNotification = "NONE" | "SILENT" | "SOUND";
export type LatestPushNotificationsContainerQuery$variables = {};
export type LatestPushNotificationsContainerQuery$data = {
  readonly data: {
    readonly nodes: ReadonlyArray<{
      readonly articleByArticleId: {
        readonly rowId: string;
        readonly title: string;
      } | null;
      readonly createdAt: string;
      readonly pushType: PushNotification | null;
      readonly recipients: number;
    } | null>;
  } | null;
};
export type LatestPushNotificationsContainerQuery = {
  response: LatestPushNotificationsContainerQuery$data;
  variables: LatestPushNotificationsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "pushType": {
        "notEqualTo": "NONE"
      }
    }
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "CREATED_AT_DESC"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipients",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pushType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LatestPushNotificationsContainerQuery",
    "selections": [
      {
        "alias": "data",
        "args": (v0/*: any*/),
        "concreteType": "OneSignalNotificationsConnection",
        "kind": "LinkedField",
        "name": "allOneSignalNotifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OneSignalNotification",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "articleByArticleId",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allOneSignalNotifications(filter:{\"pushType\":{\"notEqualTo\":\"NONE\"}},first:10,orderBy:\"CREATED_AT_DESC\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LatestPushNotificationsContainerQuery",
    "selections": [
      {
        "alias": "data",
        "args": (v0/*: any*/),
        "concreteType": "OneSignalNotificationsConnection",
        "kind": "LinkedField",
        "name": "allOneSignalNotifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OneSignalNotification",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "articleByArticleId",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allOneSignalNotifications(filter:{\"pushType\":{\"notEqualTo\":\"NONE\"}},first:10,orderBy:\"CREATED_AT_DESC\")"
      }
    ]
  },
  "params": {
    "cacheID": "0c90d5a9a53d484fa05582b36fc6eedf",
    "id": null,
    "metadata": {},
    "name": "LatestPushNotificationsContainerQuery",
    "operationKind": "query",
    "text": "query LatestPushNotificationsContainerQuery {\n  data: allOneSignalNotifications(first: 10, orderBy: CREATED_AT_DESC, filter: {pushType: {notEqualTo: NONE}}) {\n    nodes {\n      createdAt\n      recipients\n      pushType\n      articleByArticleId {\n        rowId\n        title\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9a67b30c45787525c05a71cc45f8f3c";

export default node;
