import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { WarningOutlined, CheckOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Button, Space, Modal } from 'antd';
import cn from 'classnames';
import { type FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import routes from '../../config/config.routes';
import globalStyles from '../../styles';

import translations from './translations';
import type { NavigationItemModifier } from './types';

interface Props {
	isSaveEnabled: boolean;
	isLoading?: boolean;
	onRequestEditorDialog: NavigationItemModifier;
	onSave: () => void;
}

export const NavigationNavBar: FC<Props> = ({
	isSaveEnabled,
	isLoading = false,
	onRequestEditorDialog,
	onSave,
}) => {
	const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);

	const handleRequestSave = useCallback(() => {
		setIsConfirmationDialogVisible(true);
	}, []);

	const handleConfirmSave = useCallback(() => {
		onSave();
		setIsConfirmationDialogVisible(false);
	}, [onSave]);

	return (
		<Layout className={globalStyles.mbM}>
			<Row>
				<Col span={24} className={cn(globalStyles.pM, globalStyles.toolbar)}>
					<Space>
						<Link to={routes.navigations}>
							<Button icon={<LeftOutlined />} type="primary">
								{translations.NM_GO_BACK_BUTTON_NAME}
							</Button>
						</Link>
						<Button onClick={() => onRequestEditorDialog()} icon={<PlusOutlined />} type="primary">
							{translations.NM_ADD_TOPLEVEL_NAME}
						</Button>
					</Space>
					{/* SAVE BUTTON */}
					<div>
						<Button
							onClick={handleRequestSave}
							loading={isLoading}
							disabled={!isSaveEnabled}
							icon={<CheckOutlined />}
							type="primary"
						>
							Speichern
						</Button>

						{/** ----------------------------------------------------------------------------------------------
						 * CONFIRMATION MODAL
						 * _______________________________________________________________________________________________ */}
						<Modal
							title={
								<span style={{ marginTop: 20 }}>
									<WarningOutlined style={{ marginRight: '5px' }} />
									{translations.NM_SAVE_CONFIRMATION_HEADLINE}
								</span>
							}
							open={isConfirmationDialogVisible}
							onOk={handleConfirmSave}
							okText="Speichern"
							cancelText="Abbrechen"
							onCancel={() => setIsConfirmationDialogVisible(false)}
						>
							<p>{translations.NM_SAVE_CONFIRMATION_EXPLINATION}</p>
							<p style={{ color: '#ff0000' }}>{translations.NM_SAVE_CONFIRMATION_ASK}</p>
						</Modal>
					</div>
				</Col>
			</Row>
		</Layout>
	);
};
