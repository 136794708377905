import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import { ArticleModuleHeader } from '../../../../ui/ArticleModule';
import { sanitizeUrl } from '../../../../util/urlify';
import updateAction from '../../actions/update';
import { articleModuleHeaderSelector } from '../../selectors';

export class ArticleHeaderModuleContainer extends Component<ReduxProps> {
	handleChangeTeaserText = (value: string) => this.props.update({ teaserText: value });

	handleChangeTitle = (value: string) => {
		let updateValue: Parameters<typeof this.props.update>[0] = { title: value };
		const { status, seoTitle } = this.props;

		// change seo / slug only when article was not published yet
		if (status && status !== 'PUBLISHED' && !seoTitle) {
			updateValue = {
				...updateValue,
				slug: sanitizeUrl(value),
			};
		}

		this.props.update(updateValue);
	};

	render() {
		const { title, teaserText, validation } = this.props;

		return (
			<ArticleModuleHeader
				onChangeTeaserText={this.handleChangeTeaserText}
				onChangeTitle={this.handleChangeTitle}
				teaserText={teaserText || ''}
				title={title || ''}
				validationErrorTeaser={validation && validation.teaserText ? validation.teaserText : null}
				validationErrorTitle={validation && validation.title ? validation.title : null}
			/>
		);
	}
}

const connector = connect(articleModuleHeaderSelector, {
	update: updateAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ArticleHeaderModuleContainer);
