import { SettingOutlined } from '@ant-design/icons';
import { DND_BOARD_SECTION } from '@sep/br24-constants';
import * as util from '@sep/br24-util';
import { Popover, Tag, Tooltip } from 'antd';
import { type ReactNode, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import config from '../../../config';
import { provideSectionValidationById } from '../../../store/reducers/boardBuffer';
import type {
	BoardSectionBuffer,
	BoardSectionBufferPatch,
} from '../../../store/reducers/boardBuffer';
import translations from '../../../translations';
import { CreateExternalLinkBold as ExternalLinkIcon, ErrorSolid } from '../../../ui/Icon';
import Card from '../../Card';
import SectionIcon from '../SectionIcon';

import PreviewLinkContainer from './PreviewLinkContainer';
import styles from './Section.module.scss';
import { getDisplayBoardLinkTooltipText } from './util';

export type DisplayBoardLinkContainerProps = {
	boardName?: string;
	boardStatus?: string | null;
	className?: string;
	hasError: boolean;
	link?: string;
};

function DisplayBoardLinkContainer(props: DisplayBoardLinkContainerProps) {
	const { boardStatus, className, hasError, link } = props;

	const tooltipText = getDisplayBoardLinkTooltipText(props);

	return (
		<Tooltip title={tooltipText} className={className}>
			{hasError || boardStatus !== 'PUBLISHED' ? (
				<ErrorSolid />
			) : (
				<a href={link} target="_blank" rel="noopener noreferrer">
					<ExternalLinkIcon />
				</a>
			)}
		</Tooltip>
	);
}

const StyledDisplayBoardLinkContainer = styled(DisplayBoardLinkContainer)`
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: ${(props) => (props.hasError ? 'default' : 'pointer')};
	display: inline-flex;
	margin-left: 4px;
	color: ${(props) => props.theme?.colors.stateRed};

	> svg {
		width: 12px;
		height: 12px;
		color: ${(props) => props.theme?.colors.boardSectionTitleColor};
	}

	&:hover {
		> svg {
			color: ${(props) =>
				props.hasError ? props.theme?.colors.stateRed : props.theme?.colors.primaryBlue};
		}
	}
`;

const InfoPopover = styled.div`
	max-width: 200px;
	text-align: center;
	svg {
		width: 180px;
	}
`;

const Title = styled.span<{ sectionBgColor: string }>`
	padding-left: 12px;
	color: ${(props) => props.sectionBgColor};
`;

interface Props extends ReduxProps, OwnProps {
	children: ReactNode;
	onMove: (dragOrder: number, hoverOrder: number) => void;
	onClickClose: () => void;
	onClickSettings: () => void;
	onRequestUpdate: (sectionPatch: BoardSectionBufferPatch) => void;
}

const settingErrors = ['title', 'color', 'categoryId', 'autofill'];

function SortableCard({
	children,
	section,
	validation,
	onClickSettings,
	onClickClose,
	onRequestUpdate,
	onMove,
}: Props) {
	const elementRef = useRef<HTMLDivElement>(null);

	const [, drop] = useDrop<{ index?: number; order: number }, {}, unknown>({
		accept: DND_BOARD_SECTION,
		hover(item, monitor) {
			// use index when it exists (section move preview) otherwise order (from onclick)
			const dragOrder = item.index || item.order;
			const hoverOrder = section.order;

			// Don't replace items with themselves
			if (dragOrder === hoverOrder) {
				return;
			}

			const hoverBoundingRect = elementRef.current?.getBoundingClientRect();

			if (!hoverBoundingRect) {
				return;
			}

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset?.y ?? 0) - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			onMove(dragOrder, hoverOrder);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverOrder; // eslint-disable-line no-param-reassign
		},
	});

	const [, drag, dragPreview] = useDrag(
		() => ({
			type: DND_BOARD_SECTION,
			item: {
				id: section.__meta__.internalId,
				order: section.order,
			},
			collect(monitor) {
				return { isDragging: monitor.isDragging() };
			},
		}),
		[section.__meta__.internalId, section.order]
	);

	const errors: string[] = [];

	if (
		validation &&
		Object.keys(validation).filter((key) => settingErrors.indexOf(key) >= 0).length > 0
	) {
		errors.push(
			'Diese Sektion beinhaltet Fehler in den Einstellungen - diese kannst Du am oberen rechten Rand öffnen.'
		);
	}

	if (validation && validation.items) {
		errors.push(...validation.items);
	}

	const handleClickDisableAutofill = () => onRequestUpdate({ autofill: false });

	return drop(
		dragPreview(
			<div ref={elementRef}>
				<Card
					title={drag(
						<span className={styles.draggable}>
							<Title sectionBgColor={util.color.byCategory(section)}>{section.title}</Title>

							{section.boardLink ? (
								<PreviewLinkContainer rowId={section.boardLink}>
									{({ error, props }) => {
										if (error) {
											return <StyledDisplayBoardLinkContainer hasError={true} />;
										}

										if (props && props.boardByRowId) {
											const {
												boardByRowId: { rowId, slug, status, title },
											} = props;

											const createdLink = `${config.VITE_WEB_CLIENT_URL_EXT}${slug},${rowId}`;

											return (
												<StyledDisplayBoardLinkContainer
													boardName={title}
													boardStatus={status}
													hasError={false}
													link={createdLink}
												/>
											);
										}

										return null;
									}}
								</PreviewLinkContainer>
							) : null}
							{section.autofill ? (
								<Tooltip title="Diese Sektion befüllt sich automatisch. Du kannst dieses Verhalten ändern indem Du auf das kleine Kreuz drückst oder in den Einstellungen das dafür vorgesehene Häckchen betätigst.">
									{' '}
									<Tag closable={true} color="blue" onClose={handleClickDisableAutofill}>
										Automatisch befüllt
									</Tag>
								</Tooltip>
							) : (
								false
							)}
							{errors.length > 0 ? (
								<Tooltip
									// eslint-disable-next-line react/no-array-index-key
									title={
										<ul>
											{errors.map((error, index) => (
												// eslint-disable-next-line react/no-array-index-key
												<li key={index}>{error}</li>
											))}
										</ul>
									}
								>
									<Tag color="red">Fehler</Tag>
								</Tooltip>
							) : (
								false
							)}
							<Popover
								className={styles.popover}
								placement="bottom"
								key="infoSchema"
								title={<strong>{translations.boardSectionSchema.title[section.schema]}</strong>}
								content={
									<InfoPopover>
										<SectionIcon schema={section.schema} />
										<p>{translations.boardSectionSchema.description[section.schema]}</p>
									</InfoPopover>
								}
								trigger="hover"
							>
								{translations.boardSectionSchema.title[section.schema]}
							</Popover>
						</span>
					)}
					collapsible={true}
					closable={true}
					confirmClose="Bitte bestätige, dass Du diese Sektion hiermit unwiderruflich löschen möchtest"
					onClose={onClickClose}
					actions={[
						<Tooltip key="openSettings" title="Generelle Einstellungen öffnen">
							<SettingOutlined onClick={onClickSettings} />
						</Tooltip>,
					]}
				>
					{children}
				</Card>
			</div>
		)
	);
}

interface OwnProps {
	section: BoardSectionBuffer;
}

const connector = connect((state: ReduxState, { section }: OwnProps) => ({
	validation: provideSectionValidationById(state.boardBuffer, section.__meta__.internalId),
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SortableCard);
