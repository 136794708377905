import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { Exchange } from '../Icon';

const EmptyContent = styled.div`
	text-align: center;
	font-size: 1.1rem;
	max-width: 80%;
	margin: 0 auto;
	font-weight: 200;
`;

const IconWrapper = styled.span`
	font-size: 3rem;
	margin-bottom: 0.5rem;
`;

export default function AssetManagerEmptyPlaceholder() {
	const translate = useTranslate();

	return (
		<EmptyContent>
			<IconWrapper>
				<Exchange />
			</IconWrapper>
			<p>{translate('assetManager.emptyText')}</p>
		</EmptyContent>
	);
}
