import styled from 'styled-components';

type AssetManagerContentProps = {
	isBlurred: boolean;
	isLocked: boolean;
	children: any;
};

const Content = styled.div`
	height: 100%;
	z-index: 1;
	position: relative;
	width: 100%;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;

	overflow-x: hidden;
	color: #ffffff;
	filter: ${(props: AssetManagerContentProps) => (props.isBlurred ? 'blur(4px)' : 'none')};

	&::-webkit-scrollbar {
		width: 0px; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}
`;

function AssetManagerContent({ isBlurred, isLocked, children }: AssetManagerContentProps) {
	return (
		<Content isBlurred={isBlurred} isLocked={isLocked}>
			{children}
		</Content>
	);
}

export default AssetManagerContent;
