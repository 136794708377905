/**
 * @generated SignedSource<<ddc270416e40f19b7287cd4fa5a3551c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagsContainerTagByTextQuery$variables = {
  tagText: string;
};
export type TagsContainerTagByTextQuery$data = {
  readonly tag: {
    readonly count: number;
    readonly id: string;
    readonly rowId: number;
    readonly text: string;
  } | null;
};
export type TagsContainerTagByTextQuery = {
  response: TagsContainerTagByTextQuery$data;
  variables: TagsContainerTagByTextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagText"
  }
],
v1 = [
  {
    "alias": "tag",
    "args": [
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "tagText"
      }
    ],
    "concreteType": "Tag",
    "kind": "LinkedField",
    "name": "tagByText",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagsContainerTagByTextQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagsContainerTagByTextQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3de41f3cc0209ebcf3e78542c1f4de76",
    "id": null,
    "metadata": {},
    "name": "TagsContainerTagByTextQuery",
    "operationKind": "query",
    "text": "query TagsContainerTagByTextQuery(\n  $tagText: String!\n) {\n  tag: tagByText(text: $tagText) {\n    id\n    rowId\n    text\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c7ab5f84064e6c4e897b6da241d080f";

export default node;
