/**
 * @generated SignedSource<<a716d317766f6f1c47850ccb6746cca0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type LatestPublicationContainerQuery$variables = {};
export type LatestPublicationContainerQuery$data = {
  readonly data: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstPublicationDate: string | null;
        readonly id: string;
        readonly publicationDate: string | null;
        readonly rowId: string;
        readonly status: Status;
        readonly title: string;
      } | null;
    }>;
  } | null;
};
export type LatestPublicationContainerQuery = {
  response: LatestPublicationContainerQuery$data;
  variables: LatestPublicationContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": "data",
    "args": [
      {
        "kind": "Literal",
        "name": "condition",
        "value": {
          "status": "PUBLISHED"
        }
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 10
      },
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "FIRST_PUBLICATION_DATE_DESC"
      }
    ],
    "concreteType": "ArticlesConnection",
    "kind": "LinkedField",
    "name": "allArticles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArticlesEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rowId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstPublicationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publicationDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allArticles(condition:{\"status\":\"PUBLISHED\"},first:10,orderBy:\"FIRST_PUBLICATION_DATE_DESC\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LatestPublicationContainerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LatestPublicationContainerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7b3e4ce47873d0a1399a7e238d962c7e",
    "id": null,
    "metadata": {},
    "name": "LatestPublicationContainerQuery",
    "operationKind": "query",
    "text": "query LatestPublicationContainerQuery {\n  data: allArticles(first: 10, condition: {status: PUBLISHED}, orderBy: FIRST_PUBLICATION_DATE_DESC) {\n    edges {\n      node {\n        rowId\n        id\n        title\n        status\n        firstPublicationDate\n        publicationDate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "956482a8b381b651b5a60ad78c8141fc";

export default node;
