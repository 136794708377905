import { Upload, Spin, Alert } from 'antd';
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { lighten } from 'polished';
import { Component, type PropsWithChildren } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { Cancel, Plus } from '../../Icon';

const { Dragger } = Upload;

const StyledDragger = styled(Dragger)<{
	isThumbnailUpload?: boolean;
	validation?: any;
	isDropBlocked?: boolean;
}>`
	&.ant-upload.ant-upload-drag {
		min-height: ${(props) => (props.isThumbnailUpload ? '120px' : '400px')};
		display: flex;
		justify-content: center;
		align-items: center;
		border-width: 2px;
		background-color: ${(props) =>
			props.validation ? lighten(0.3, props.theme?.colors.stateRed) : ''};

		.ant-upload-list {
			margin-bottom: 15px;
		}
	}
`;

const Paragraph = styled.p`
	font-size: 1.7em;
	padding-top: 25px;
`;

const IconWrapper = styled.div<{ isThumbnailUpload?: boolean }>`
	color: ${(props) => props.theme?.colors.primaryBlue};
	font-size: ${(props) => (props.isThumbnailUpload ? '4em' : '7em')};
`;

type Props = WithTranslationProps & {
	multiple: boolean;
	action: string;
	acceptedDataTypes: Array<string>;
	user: any;
	showUploadList?: boolean;
	validation?: boolean;
	icon?: ReactNode;
	label?: string | null;
	isThumbnailUpload?: boolean;
	isDropBlocked?: boolean;
	onCancel?: () => void;
	onSuccess: (fileList: UploadFile<{ url: string }>[] | UploadFile<{ url: string }>) => void;
};

type State = {
	isUploading: boolean;
	hasError: boolean;
	fileList: UploadFile<any>[];
};

const DELAY_UNTIL_ERROR_REMOVED_MS = 4000;
class ArticleModuleUploaderComponent extends Component<PropsWithChildren<Props>, State> {
	static defaultProps = {
		showUploadList: true,
		isThumbnailUpload: false,
		validation: false,
		icon: null,
		label: null,
	};

	state = {
		isUploading: false,
		hasError: false,
		fileList: [],
	};

	handleChange = (info: UploadChangeParam<UploadFile<{ url: string }>>) => {
		const { fileList } = info;
		const { multiple } = this.props;

		const isUploading = fileList.filter((item) => item.status === 'uploading').length > 0;
		const hasError = fileList.filter((item) => item.status === 'error').length > 0;
		const hasSuccess = fileList.filter((item) => item.status === 'done').length === fileList.length;

		if (isUploading) {
			this.setState({
				hasError: false,
				isUploading: true,
				fileList,
			});
		}

		if (hasError) {
			this.setState(
				{
					isUploading: false,
					hasError: true,
				},
				() => {
					this.resetError();
				}
			);
		}

		if (hasSuccess) {
			this.setState({
				isUploading: false,
				hasError: false,
				fileList: [],
			});

			if (!multiple && fileList.length > 1) {
				this.props.onSuccess(fileList[0]);
			} else {
				this.props.onSuccess(fileList);
			}
		}
	};

	resetError = () => {
		setTimeout(() => {
			this.setState({
				hasError: false,
				fileList: [],
			});
		}, DELAY_UNTIL_ERROR_REMOVED_MS);
	};

	render() {
		const {
			multiple,
			action,
			acceptedDataTypes,
			showUploadList,
			user,
			children,
			icon,
			label,
			isThumbnailUpload,
			validation,
			isDropBlocked,
			translation: { translate },
		} = this.props;
		const { isUploading, hasError, fileList } = this.state;

		let displayContent: typeof children | null = null;

		// view while uploading
		if (isUploading) {
			displayContent = (
				<div>
					<Spin size="large" />
					{!isThumbnailUpload ? (
						<Paragraph>{translate('modules.mixedDropzone.labelFilesAreUploaded')}</Paragraph>
					) : null}
				</div>
			);
		}

		// view when error occured
		if (hasError) {
			displayContent = (
				<div>
					<IconWrapper isThumbnailUpload={isThumbnailUpload}>
						<Cancel />
					</IconWrapper>
					{!isThumbnailUpload ? (
						<Paragraph>{translate('modules.mixedDropzone.labelErrorOnUpload')}</Paragraph>
					) : null}
				</div>
			);
		}

		// default view
		if (!isUploading && !hasError && icon && label) {
			displayContent = (
				<div>
					<IconWrapper isThumbnailUpload={isThumbnailUpload}>{icon}</IconWrapper>
					<Paragraph>{label}</Paragraph>
				</div>
			);
		}

		// view for thumbnail upload
		if (isThumbnailUpload && !isUploading) {
			displayContent = (
				<IconWrapper isThumbnailUpload={isThumbnailUpload}>
					<Plus />
				</IconWrapper>
			);
		}

		// drop not allowed view
		if (!isUploading && !hasError && isDropBlocked) {
			displayContent = (
				<div>
					<IconWrapper isThumbnailUpload={isThumbnailUpload}>
						<Cancel />
					</IconWrapper>
					{!isThumbnailUpload ? (
						<Paragraph>{translate('modules.mixedDropzone.dropIsNotAllowedPlaceholder')}</Paragraph>
					) : null}
				</div>
			);
		}

		if (!user) {
			return (
				<Alert
					message={translate('modules.mixedDropzone.errorNotAuthenticatedHeadline')}
					description={translate('modules.mixedDropzone.errorNotAuthenticatedDesc')}
					type="error"
				/>
			);
		}

		if (children) {
			displayContent = children;
		}

		return (
			<StyledDragger
				isThumbnailUpload={isThumbnailUpload}
				showUploadList={showUploadList}
				isDropBlocked={isDropBlocked}
				disabled={isDropBlocked}
				accept={acceptedDataTypes.join(',')}
				multiple={multiple}
				action={action}
				fileList={fileList}
				onChange={this.handleChange}
				validation={validation}
			>
				{displayContent}
			</StyledDragger>
		);
	}
}

export const ArticleModuleUploader = withTranslation(ArticleModuleUploaderComponent);
