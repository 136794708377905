import type { ItemEmbedCode } from '..';
import { AssetManagerItemEmbedCode as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemEmbedCode;
};

function AssetManagerItemEmbedCode({ item }: Props) {
	return <Wrapper item={item} />;
}

export default AssetManagerItemEmbedCode;
