import { COLOR_ALL } from '@sep/br24-constants';

export default function randomColor(known?: Array<string>): string | undefined {
	const currentStack = Array.isArray(known)
		? COLOR_ALL.filter((color) => !(known || []).includes(color))
		: COLOR_ALL;

	if (currentStack.length === 0) {
		return undefined;
	}

	return currentStack[Math.floor(Math.random() * currentStack.length)];
}
