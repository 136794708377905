import { Select } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

const { Option } = Select;

const FullWidthSelect = styled(Select<string>)`
	width: 100%;
`;

type OptionItem = {
	id: string;
	rowId: string;
	title: string;
};

type Props = WithTranslationProps & {
	categories: Array<OptionItem>;
	onSelect: (id: string) => void;
	selected: string | undefined | null;
	key: string | number;
};

class ArticleMetaCategory extends Component<Props> {
	handleChange = (id: string) => {
		this.props.onSelect(id);
	};

	renderOption = (item: OptionItem) => (
		<Option key={item.id} value={item.rowId}>
			{item.title}
		</Option>
	);

	render() {
		const {
			categories,
			selected,
			translation: { translate },
			onSelect,
			...rest
		} = this.props;

		return (
			<ArticleMetaPanel
				help={translate('article.meta.category.help')}
				isRequired={true}
				title={translate('article.meta.category.title')}
				{...rest}
			>
				<FullWidthSelect
					defaultValue={selected}
					onChange={this.handleChange}
					placeholder={translate('article.meta.category.searchPlaceholder')}
				>
					{Array.isArray(categories) && categories.length > 0
						? categories.map((category) => this.renderOption(category))
						: null}
				</FullWidthSelect>
			</ArticleMetaPanel>
		);
	}
}

export default withTranslation(ArticleMetaCategory);
