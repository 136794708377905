import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import { useUser } from '../../auth/AuthContext';
import environment from '../../environment';
import type { AuthorsFilterType as AuthorsFilterState } from '../../store/reducers/authorsFilter';

import AuthorsList from './AuthorsList';
import type { AuthorFilter as LoggedInUserFilterType } from './__generated__/AuthorLoggedInUserContainerQuery.graphql';
import type {
	AuthorsListContainerQuery,
	AuthorFilter as AllAuthorsFilterType,
} from './__generated__/AuthorsListContainerQuery.graphql';
import { convertActivityFilterValue, convertLoginTypeFilterValue } from './utils';

type Props = {
	currentFilter: AuthorsFilterState;
};

const ContentWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
`;

const SpinnerWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
	height: 90vh;
`;

const Loader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
`;

function AuthorsListContainer({ currentFilter }: Props) {
	const user = useUser();
	const isActive = convertActivityFilterValue(currentFilter.isActive);
	const isLdapUser = convertLoginTypeFilterValue(currentFilter.isLdapUser);
	const guid = user?.guid;

	const filter: Pick<AllAuthorsFilterType, 'lastname' | 'isActive' | 'isLdapUser'> = {
		lastname: {
			includesInsensitive: currentFilter.searchTerm,
		},
		isActive: {
			equalTo: isActive,
		},
		isLdapUser: {
			equalTo: isLdapUser,
		},
	};

	// we need to delete the nullable properties here in order to have valid gql queries
	if (isActive === null) {
		delete filter.isActive;
	}

	if (isLdapUser === null) {
		delete filter.isLdapUser;
	}

	const loggedInUserFilter: LoggedInUserFilterType = {
		...filter,
		guid: {
			equalTo: guid,
		},
	};

	const allAuthorsFilter: AllAuthorsFilterType = {
		...filter,
		guid: {
			notEqualTo: guid,
		},
	};

	return (
		<QueryRenderer<AuthorsListContainerQuery>
			environment={environment}
			query={graphql`
				query AuthorsListContainerQuery(
					$count: Int
					$cursor: Cursor
					$allAuthorsFilter: AuthorFilter
				) {
					...AuthorsList_query
				}
			`}
			variables={{
				count: 25,
				allAuthorsFilter,
			}}
			render={({ props: queryRendererProps, error }) => {
				if (error) {
					return (
						<ContentWrapper>
							<Alert message="Fehler" description={error.message} type="error" />
						</ContentWrapper>
					);
				}

				if (!queryRendererProps) {
					return (
						<SpinnerWrapper>
							<Loader>
								<Spin size="large" />
							</Loader>
						</SpinnerWrapper>
					);
				}

				return <AuthorsList query={queryRendererProps} loggedInUserFilter={loggedInUserFilter} />;
			}}
		/>
	);
}

export default AuthorsListContainer;
