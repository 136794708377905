import { state as authState } from '@sep/cms-auth-client';
import {
	Environment,
	type FetchFunction,
	Network,
	Observable,
	RecordSource,
	Store,
	type SubscribeFunction,
} from 'relay-runtime';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import config from './config';

const fetchQuery: FetchFunction = (operation, variables) =>
	fetch(`${config.VITE_GRAPHQL_URL_EXT}/graphql`, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			// Authorization: `Bearer ${auth.getUser().getAccessToken()}`,
			'X-Client': `${config.title || ''} (${config.VITE_BRANCH || 'default'})`,
		},
		body: JSON.stringify({
			query: operation.text,
			variables,
		}),
	})
		.then((response) => {
			if (response.status === 401) {
				throw new Error('401');
			} else {
				return response.json();
			}
		})
		.catch((err) => {
			if (err.message === '401') {
				console.error('Received 401, cancelling requests');
			}
			throw err;
		});

const subscriptionClient = new SubscriptionClient(
	`${config.VITE_GRAPHQL_URL_EXT}/graphql`.replace(/^http/, 'ws'),
	{
		reconnect: true,
		timeout: 20000,
		// initialize connection on demand (this prevents connection errors on the login screen)
		lazy: true,
		connectionParams() {
			/**
			 * HINT: this is an absolute exception !
			 */
			return { authToken: authState.tokenSet?.access_token?.value };
		},
		connectionCallback: (err) => {
			if (err) {
				// eslint-disable-next-line no-console
				console.error(`error occured in graphql subscription connection:`, err);
			}
		},
	}
);

if (config.VITE_BRANCH !== 'master') {
	const prefix = '%c[proxy-graphql-subscriptions]';
	const css = 'background: orange; color: #fff';

	subscriptionClient.on('connected', () => {
		// eslint-disable-next-line no-console
		console.debug(prefix, css, 'client connected');
	});

	subscriptionClient.on('error', (err) => {
		// eslint-disable-next-line no-console
		console.debug(prefix, css, `error occured on socket connection`, err);
	});

	subscriptionClient.on('disconnected', () => {
		// eslint-disable-next-line no-console
		console.debug(prefix, css, `client disconnected`);
	});

	subscriptionClient.on('reconnected', () => {
		// eslint-disable-next-line no-console
		console.debug(prefix, css, 'client reconnected');
	});
}

function subscribe(request, variables): ReturnType<SubscribeFunction> {
	const subscribeObservable = subscriptionClient.request({
		query: request.text ?? undefined,
		operationName: request.name,
		variables,
	});
	// Important: Convert subscriptions-transport-ws observable type to Relay's
	// @ts-expect-error there are incompatibilities in typings between react-relay and graphql typings
	return Observable.from(subscribeObservable);
}

const environment = new Environment({
	network: Network.create(fetchQuery, subscribe),
	store: new Store(new RecordSource()),
});

export function resetGraphqlSubscriptionClient() {
	subscriptionClient.unsubscribeAll();
	subscriptionClient.close();
}

export const relayEnvironment = environment;

export default environment;
