import type { AppThunkAction } from '@/client/store/redux';

import type { ImportBroadcastChannelsAction } from '../..';
import { IMPORT_BROADCAST_CHANNELS } from '../../actionTypes';

import fetchBroadcastChannels from './fetchBroadcastChannels';

export function importBroadcastChannels(): AppThunkAction<ImportBroadcastChannelsAction> {
	return async (dispatch) => {
		const res = await fetchBroadcastChannels();

		const channels = res
			.map((broadcastChannel) => {
				const channelName = broadcastChannel?.labels[0];

				if (!channelName) {
					return null;
				}

				return {
					id: broadcastChannel.id,
					channelName,
				};
			})
			.filter((mappedChannel) => mappedChannel !== null);

		dispatch({
			type: IMPORT_BROADCAST_CHANNELS,
			payload: channels,
		});
	};
}
