import * as util from '@sep/br24-util';
import { graphql, useLazyLoadQuery } from 'react-relay';
import styled from 'styled-components';

import { Avatar } from '../../../ui/Avatar';

import type { AvatarLockInfoQuery } from './__generated__/AvatarLockInfoQuery.graphql';

const Root = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row-reverse;
	flex-wrap: nowrap;
	padding: 6px;
`;

type Props = {
	lockedBy?: string | null;
};

export function AvatarLockInfo({ lockedBy }: Props) {
	const { authorByGuid } = useLazyLoadQuery<AvatarLockInfoQuery>(
		graphql`
			query AvatarLockInfoQuery($guid: String!, $skip: Boolean!) {
				authorByGuid(guid: $guid) @skip(if: $skip) {
					guid
					profilePicture
					firstname
					lastname
				}
			}
		`,
		{ guid: lockedBy ?? '', skip: !Boolean(lockedBy) }
	);

	if (!lockedBy || !authorByGuid) {
		return null;
	}

	return (
		<Root>
			<Avatar
				style={{ margin: -6 }}
				firstname={authorByGuid?.firstname}
				guid={authorByGuid.guid}
				lastname={authorByGuid.lastname}
				url={
					!authorByGuid.profilePicture
						? null
						: util.image.resize(authorByGuid.profilePicture, 'square', 's')
				}
			/>
		</Root>
	);
}
