import classnames from 'classnames';
import styled from 'styled-components';

import { Lock, LockOpen } from '../../Icon';

type Props = {
	isLockedForDeletion: boolean;
	onToggle: () => void;
};

const StyledButton = styled.button`
	width: 2.5em;
	height: 2.5em;

	> svg {
		opacity: 0;
		transition: opacity 200ms;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 33% !important;
		height: 33% !important;
		transform: translate(-50%, -50%);
		fill: red;
	}

	position: absolute;
	bottom: 0;
	right: 0;
	background: transparent;
	border: none;

	cursor: pointer;

	&:focus {
		outline: none;
		> svg {
			opacity: 1;
		}
	}

	&.is-locked {
		> svg {
			opacity: 1;
		}
	}
`;

function LockButton({ onToggle, isLockedForDeletion = false }: Props) {
	return (
		<StyledButton
			className={classnames({ 'is-locked': isLockedForDeletion })}
			type="button"
			onClick={onToggle}
		>
			{isLockedForDeletion ? <Lock /> : <LockOpen />}
		</StyledButton>
	);
}

export default LockButton;

export const ItemWrapper = styled.div`
	position: relative;

	&:hover {
		button > svg {
			opacity: 1;
		}
	}
`;
