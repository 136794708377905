import moment from 'moment';

import { filterNullOrUndefined } from '@/client/util/filter';

import type { BoardLinkPickerQuery$data } from '../__generated__/BoardLinkPickerQuery.graphql';

const defaultRegions = [
	'/bayern/mittelfranken',
	'/bayern/niederbayern',
	'/bayern/oberbayern',
	'/bayern/oberfranken',
	'/bayern/oberpfalz',
	'/bayern/schwaben',
	'/bayern/unterfranken',
];

const sortAlphabetically = (board1, board2) => board1.title.localeCompare(board2.title);

type SortableBoards = NonNullable<BoardLinkPickerQuery$data['allBoards']>['edges'];

const sortBoards = (boards: SortableBoards) => {
	const flattenedBoards = boards.map((board) => board.node).filter(filterNullOrUndefined);

	const homepage = flattenedBoards.filter((board) => board.fullSlug === '/');

	const regions = flattenedBoards
		.filter((board) => defaultRegions.includes(board.fullSlug))
		.sort(sortAlphabetically);

	const protectedBoards = flattenedBoards
		.filter(
			(board) =>
				board.fullSlug !== '/' && board.protected && !defaultRegions.includes(board.fullSlug)
		)
		.sort(sortAlphabetically);

	const rest = flattenedBoards
		.filter(
			(board) =>
				board.fullSlug !== '/' && !board.protected && !defaultRegions.includes(board.fullSlug)
		)
		.sort(
			(board1, board2) => moment(board2.createdAt).valueOf() - moment(board1.createdAt).valueOf()
		);

	return [...homepage, ...regions, ...protectedBoards, ...rest];
};

export default sortBoards;
