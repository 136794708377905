import type { User } from '@sep/cms-auth-client';
import { ConfigProvider, notification } from 'antd';
import deDE from 'antd/lib/locale-provider/de_DE';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { RelayEnvironmentProvider } from 'react-relay';
import { ThemeProvider } from 'styled-components';

import { AuthContext } from './auth/AuthContext';
import { useSyncAccessTokenWithCookieForPreviewMode } from './auth/previewMode';
import { useFirebase } from './auth/useFirebase';
import { useRegisterUser } from './auth/useRegisterUser';
import AppContainer from './containers/App';
import { initializeDatabase as initializeAssetManagerFirebaseDatabase } from './containers/AssetManager/database.init';
import { relayEnvironment } from './environment';
import { store } from './initializedStore';
import { initialize } from './store/reducers/app';
import { theme } from './theme';
import { TranslationProvider } from './translation/Context';

import './styles';
import './errorRecorder';
import './styles/global.scss';

notification.config({
	placement: 'bottomRight',
	duration: 3,
});

store.dispatch(initialize());

export default function ({ user, logout }: { user: User; logout: () => void }) {
	useRegisterUser(user);
	useSyncAccessTokenWithCookieForPreviewMode();

	const { loading, error } = useFirebase(user.guid);

	if (error) {
		console.warn('cannot initialize firebase');
	}

	if (loading) {
		return null;
	}

	initializeAssetManagerFirebaseDatabase(user.guid);

	return (
		<RelayEnvironmentProvider environment={relayEnvironment}>
			<ReduxProvider store={store}>
				<TranslationProvider>
					<ThemeProvider theme={theme}>
						<ConfigProvider locale={deDE}>
							<AuthContext.Provider value={{ user, logout }}>
								<HelmetProvider>
									<AppContainer />
								</HelmetProvider>
							</AuthContext.Provider>
						</ConfigProvider>
					</ThemeProvider>
				</TranslationProvider>
			</ReduxProvider>
		</RelayEnvironmentProvider>
	);
}
