import { BOARD_BUFFER_SECTION_ITEM_UPDATE } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import type { BoardSectionItemBufferPatch } from '../types';
import { findSectionItemById } from '../util/find';

export type UpdateItemInSectionAction = {
	type: typeof BOARD_BUFFER_SECTION_ITEM_UPDATE;
	constraint: {
		boardId: string;
		sectionItemId: string;
	};
	payload: BoardSectionItemBufferPatch;
};

/**
 * Updates an existing item by `sectionItemInternalId`.
 *
 * @param  {string} sectionItemInternalId
 * @param  {Object} itemPatch
 * @return {Function}
 */
export default function updateItemInSection(
	sectionItemInternalId: string,
	itemPatch: BoardSectionItemBufferPatch
): AppThunkAction<UpdateItemInSectionAction> {
	return (dispatch, getState) => {
		const sectionItem = findSectionItemById(sectionItemInternalId, getState);

		if (!sectionItem) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SECTION_ITEM_UPDATE,
			constraint: {
				boardId: sectionItem.__meta__.boardId,
				sectionItemId: sectionItemInternalId,
			},
			payload: itemPatch,
		});
	};
}
