import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import translations from '../../../translations';
import type { SocialMediaAccount } from '../SocialMediaAccounts';

const Wrapper = styled.div`
	display: flex;
`;

const DisplayWrapper = styled.div`
	flex: 1;
	word-break: break-all;

	h2,
	h3 {
		color: #4a4a4a;
		font-size: 18px;
		line-height: 26px;
		margin: 0;
	}
	h2 {
		font-weight: 600;
	}
	h3 {
		font-weight: 400;
	}
`;

const EditHandlerWrapper = styled.div`
	margin-left: 10px;
	display: flex;
	align-items: center;
`;

type Props = SocialMediaAccount & {
	isEditable: boolean | undefined | null;
	onEdit: () => void;
	onDelete: () => void;
};

export function AccountListShow({
	service,
	accountName,
	label,
	isEditable,
	onEdit,
	onDelete,
}: Props) {
	return (
		<Wrapper>
			<DisplayWrapper>
				{service && <h2>{String(translations[service])}</h2>}
				<h3>{accountName}</h3>
				<h3 style={{ cursor: 'pointer' }}>
					{service === 'EMAIL' ? (
						<a href={`mailto:${accountName}`} target="_blank" rel="noopener noreferrer">
							{label}
						</a>
					) : (
						<a href={accountName ?? undefined} target="_blank" rel="noopener noreferrer">
							{label}
						</a>
					)}
				</h3>
			</DisplayWrapper>
			{isEditable ? (
				<EditHandlerWrapper>
					<Button type="default" shape="circle" icon={<EditOutlined />} onClick={onEdit} />
					<Button type="default" shape="circle" icon={<CloseOutlined />} onClick={onDelete} />
				</EditHandlerWrapper>
			) : null}
		</Wrapper>
	);
}
