import {
	LS_UPDATE_LOCKSTATUS,
	LS_LOCK_DIALOG_BROKEN_BY_USER,
	LS_READ_ONLY_MODE,
	LS_LOCK_DIALOG_OTHER_AUTHOR,
	LS_LOCK_DIALOG_UPDATED_WHILE_LOCK,
} from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';
import type { LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED } from '@/client/util/lockHandler/temporary-cms-constants';

export type LockStatusUpdateInput = {
	mode:
		| null
		| typeof LS_LOCK_DIALOG_BROKEN_BY_USER
		| typeof LS_LOCK_DIALOG_OTHER_AUTHOR
		| typeof LS_LOCK_DIALOG_UPDATED_WHILE_LOCK
		| typeof LS_READ_ONLY_MODE
		| typeof LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED;

	brokenBy?: {
		firstname: string;
		lastname: string;
		// lastEdited: string;
		since: string | null;
	} | null;
	userInfo?: {
		differentUser: boolean;
		ownUser: boolean;
		noUser: boolean;
	} | null;
};

type LockStatusUpdateAction = {
	type: string;
	payload: LockStatusUpdateInput;
};

export default function updateLockStatus(
	input: LockStatusUpdateInput
): AppThunkAction<LockStatusUpdateAction> {
	return (dispatch) => {
		dispatch({
			type: LS_UPDATE_LOCKSTATUS,
			payload: input,
		});
	};
}
