import { createContext } from 'react';

/**
 * This _interface_ is used to define its structure of a React __Context__ in the __ArticleEditor__.
 * If this context should contain further elements and features in the future, they are defined here.
 * This interface should be used in the containers that use and extend the context -> e.g. _ArticleEditorMetaNotes/NotesContainer.tsx_
 *
 * The Context should simplify the storage and data management of the __ArticleEditor__ and thus reduce the complexity of Redux or perhaps
 * at some point it should be possible to do without it completely.
 */
export interface IArticleEditorContext {
	meta?: {
		notes?: {
			hasArticleNotes: boolean;
		};
	};
}

export type ArticleEditorContextType = {
	context: IArticleEditorContext;
	setContext: (context: IArticleEditorContext) => void;
};

export const ArticleEditorContext = createContext<ArticleEditorContextType>({
	context: {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setContext: () => {},
});
