import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import Board from '../components/Board';
import environment from '../environment';

import type { BoardContainerQuery } from './__generated__/BoardContainerQuery.graphql';

const SpinnerWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
	height: 90vh;
`;

const Loader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
`;

type Props = {
	match: {
		params: {
			id: string;
		};
	};
};

export default function (props: Props) {
	return (
		<QueryRenderer<BoardContainerQuery>
			environment={environment}
			query={graphql`
				query BoardContainerQuery($boardId: String!) {
					board: boardByRowId(rowId: $boardId) {
						...Board_board
						historiesByBoardId(
							first: 2
							condition: { boardId: $boardId }
							orderBy: CREATED_AT_DESC
						) {
							edges {
								node {
									id
									createdAt
									status
									author: authorByAccomplishedBy {
										name
									}
								}
							}
						}
						rowId
					}
				}
			`}
			variables={{
				boardId: props.match.params.id,
			}}
			render={({ error, props: renderProps }) => {
				const isReady = error === null && renderProps;

				if (error) {
					return <Alert message="Fehler" description={error.message} type="error" />;
				}

				if (isReady) {
					if (!renderProps.board) {
						return (
							<Alert
								message="Fehler"
								description="Die Seite konnte nicht gefunden werden."
								type="error"
							/>
						);
					}

					return <Board board={renderProps.board} id={renderProps.board.rowId} />;
				}

				return (
					<SpinnerWrapper>
						<Loader>
							<Spin size="large" />
						</Loader>
					</SpinnerWrapper>
				);
			}}
		/>
	);
}
