import { debounce, type DebouncedFunc } from 'lodash-es';
import { Component, type ReactNode } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';

import config from '../../../../config';
import { ArticleModuleEmbed } from '../../../../ui/ArticleModule';
import { ArticleModuleEmbedEditor } from '../../../../ui/ArticleModule/ArticleModuleEmbed';

type Props = {
	source: string | undefined | null;
	service: string | undefined | null;
	altText: string | undefined | null;
	imageUploader: ReactNode;
	validation: any;
	relay: any;
	query: any;
	onChange: (input: { [key: string]: string | undefined | null }) => void;
};

type State = {
	hasErrors: boolean;
};

class EmbedModuleEditorContainer extends Component<Props, State> {
	state = {
		hasErrors: false,
	};

	checkService = async (domain: string) => {
		if (!domain) {
			return null;
		}

		const response = await fetch(`${config.VITE_GRAPHQL_URL_EXT}/graphql`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				query: `{
					allEmbedServices(condition: { domain: "${domain}" }) {
						nodes {
							rowId
							id
							name
							domain
						}
					}
				}`,
			}),
		});

		const responseJson = await response.json();
		const data = responseJson?.data?.allEmbedServices?.nodes;

		if (Array.isArray(data) && data.length > 0) {
			return data[0];
		}

		return null;
	};

	debounceRef: DebouncedFunc<() => void> | null = null;

	handleChangeCode = async (input: string) => {
		// regex gets the src attribute and filters the domain WITHOUT sub domains
		// use https://regexr.com/40vca for debugging or changing the expression
		const sourceUrlRegEx = new RegExp(
			'\\Wsrc[\\s=:]+[\'"]((?:https?:)?//.*?\\.?(\\w+\\.\\w+)/.+?)[\'"]',
			'g'
		);
		let sourceUrl = sourceUrlRegEx.exec(input);
		if (!sourceUrl) {
			// Handle special case for BR24Sport Embeds as they don't use iframe or src tags
			const br24SportPattern = new RegExp('^(https://live.)(br24sport.de)/iframe/', 'g');
			sourceUrl = br24SportPattern.exec(input);
		}
		const incomingDomain = sourceUrl ? sourceUrl[2] : null;

		this.props.onChange({ source: input });

		if (!incomingDomain) {
			this.props.onChange({ service: null });
			return;
		}

		this.debounceRef?.cancel();
		this.debounceRef = debounce(async () => {
			// validate incoming code with services from the backend
			const foundService = await this.checkService(incomingDomain);

			if (foundService) {
				this.props.onChange({ service: foundService.rowId });
			} else {
				this.props.onChange({ service: null });
			}
		}, 500);
		this.debounceRef();
	};

	handleRefetch = () => {
		this.props.relay.refetch(
			{
				source: this.props.source ? encodeURIComponent(this.props.source) : '',
				service: this.props.service || '',
				renderImage: false,
			},
			null,
			() => {
				if (!this.props.query.embed) {
					this.setState({
						hasErrors: true,
					});
				} else {
					this.setState(
						{
							hasErrors: false,
						},
						() => {
							this.props.onChange({
								url: this.props.query.embed.url,
								source: this.props.query.embed.source,
							});
						}
					);
				}
			},
			{ force: true }
		);
	};

	render() {
		const { service, source, altText, validation, onChange, ...rest } = this.props;

		return (
			<ArticleModuleEmbed
				service={service}
				altText={altText}
				preview={
					<ArticleModuleEmbedEditor
						source={source || ''}
						validation={validation}
						onChangeCode={this.handleChangeCode}
						onRefetch={this.handleRefetch}
						hasErrors={this.state.hasErrors}
					/>
				}
				validation={validation}
				onChange={onChange}
				{...rest}
			/>
		);
	}
}

export default createRefetchContainer(
	EmbedModuleEditorContainer,
	{
		query: graphql`
			fragment EmbedModuleEditorContainer_query on Query {
				embed(service: $service, source: $source, renderImage: $renderImage) {
					service
					url
					source
					image {
						url
						base64Data
						width
						height
					}
				}
			}
		`,
	},
	graphql`
		query EmbedModuleEditorContainerQuery(
			$service: String!
			$source: String!
			$renderImage: Boolean
		) {
			...EmbedModuleEditorContainer_query
		}
	`
);
