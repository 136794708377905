/**
 * @generated SignedSource<<8410ee5828472e7f187e2cdcf0859b2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NavigationType = "ARTICLE" | "BOARD" | "CUSTOM" | "NAVIGATION";
import { FragmentRefs } from "relay-runtime";
export type Navigation_hierarchy$data = {
  readonly articleByArticle: {
    readonly rowId: string;
    readonly title: string;
  } | null;
  readonly boardByBoard: {
    readonly rowId: string;
    readonly title: string;
  } | null;
  readonly color: string | null;
  readonly id: string;
  readonly navigationsByParent: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly articleByArticle: {
          readonly rowId: string;
          readonly title: string;
        } | null;
        readonly boardByBoard: {
          readonly rowId: string;
          readonly title: string;
        } | null;
        readonly color: string | null;
        readonly id: string;
        readonly navigationsByParent: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly articleByArticle: {
                readonly rowId: string;
                readonly title: string;
              } | null;
              readonly boardByBoard: {
                readonly rowId: string;
                readonly title: string;
              } | null;
              readonly color: string | null;
              readonly id: string;
              readonly order: number;
              readonly parent: number | null;
              readonly path: string | null;
              readonly rowId: number;
              readonly text: string | null;
              readonly type: NavigationType;
            } | null;
          }>;
        };
        readonly order: number;
        readonly parent: number | null;
        readonly path: string | null;
        readonly rowId: number;
        readonly text: string | null;
        readonly type: NavigationType;
      } | null;
    }>;
  };
  readonly order: number;
  readonly parent: number | null;
  readonly path: string | null;
  readonly rowId: number;
  readonly text: string | null;
  readonly type: NavigationType;
  readonly " $fragmentType": "Navigation_hierarchy";
};
export type Navigation_hierarchy$key = {
  readonly " $data"?: Navigation_hierarchy$data;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_hierarchy">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parent",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  (v1/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Board",
  "kind": "LinkedField",
  "name": "boardByBoard",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "articleByArticle",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_hierarchy",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NavigationsConnection",
      "kind": "LinkedField",
      "name": "navigationsByParent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Navigation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NavigationsConnection",
                  "kind": "LinkedField",
                  "name": "navigationsByParent",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NavigationsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Navigation",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v7/*: any*/),
                            (v8/*: any*/),
                            (v9/*: any*/),
                            (v10/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Navigation",
  "abstractKey": null
};
})();

(node as any).hash = "921878dca037c01e29edb0177e690530";

export default node;
