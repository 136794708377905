import { commitMutation, type Environment, graphql } from 'react-relay';

import type { CombineToStoryMutation } from './__generated__/CombineToStoryMutation.graphql';

const mutation = graphql`
	mutation CombineToStoryMutation($input: CombineToStoryInput!) {
		combineToStory(input: $input) {
			articles {
				id
				rowId
				title
				articlesInStoryCount
			}
		}
	}
`;

export default (
	{
		affectedArticles,
	}: {
		affectedArticles: string[];
	},
	environment: Environment
): Promise<CombineToStoryMutation['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<CombineToStoryMutation>(environment, {
			mutation,
			variables: {
				input: {
					affectedArticles,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
