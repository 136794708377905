import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { Title, Content } from './AssetManagerItemControl';
import TypeIcon from './TypeIcon';

type AssetManagerItemLoadingProps = {
	type: string;
};

const LoadingContent = styled(Content)`
	color: #19a084;
	h6 {
		color: #19a084;
	}
`;

export default function AssetManagerItemLoading({ type }: AssetManagerItemLoadingProps) {
	const translate = useTranslate();

	return (
		<>
			<TypeIcon type={type} />
			<LoadingContent>
				<Title>{translate(`assetManager.item.loading.title`)}</Title>
				{translate(`assetManager.item.loading.description`)}
			</LoadingContent>
		</>
	);
}
