import * as constants from '@sep/br24-constants';

import { calculatePercentage, normalizeData } from '.';

const getChartDataTotal = (
	data: {
		type: string;
		count: number;
	}[]
) => {
	const normalizedData = normalizeData(data);
	const totalCount = normalizedData.totalAv + normalizedData.totalText;

	return {
		totalText: {
			name: 'Bild-Artikel + Eil',
			fill: constants.COLOR_POMEGRANATE,
			value: normalizedData.totalText,
			percentage: calculatePercentage(normalizedData.totalText, totalCount),
		},
		totalAv: {
			name: 'Audio-/Video-Artikel gesamt',
			fill: constants.COLOR_EMERALD,
			value: normalizedData.totalAv,
			percentage: calculatePercentage(normalizedData.totalAv, totalCount),
		},
	};
};

export default getChartDataTotal;
