import type { SetFirebaseConnectedAction } from '../..';
import { SET_FIREBASE_CONNECTED } from '../../actionTypes';

const setFirebaseConnected = (connected: boolean): SetFirebaseConnectedAction => ({
	type: SET_FIREBASE_CONNECTED,
	payload: {
		connected,
	},
});

export default setFirebaseConnected;
