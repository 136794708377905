import { Tooltip, Card, Alert } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useTranslate } from '@/client/translation/useTranslate';

import config from '../../../config';
import routes from '../../../config/config.routes';
import styles from '../../../styles';
import { List, ListItem } from '../../List';

import MyLatestArticlesContainer from './MyLatestArticlesContainer';

export default () => {
	const translate = useTranslate();

	return (
		<MyLatestArticlesContainer
			render={({ error, props }) => {
				const dataAvailable =
					props !== null &&
					props.data &&
					Array.isArray(props.data.edges) &&
					props.data.edges.length > 0;

				return (
					<div>
						{error ? (
							<Alert message="Fehler" description={error.message} type="error" />
						) : (
							<Card
								className={classNames({ [styles.cardWithoutPadding]: dataAvailable })}
								title={translate('dashboard.myLatestArticles')}
								loading={error === null && props === null}
							>
								{dataAvailable ? (
									<List bordered={false}>
										{props.data.edges.map(({ node }, index) => {
											const createdAt = moment.utc(node.createdAt).local();
											if (!node.article) {
												return null;
											}

											return (
												<ListItem
													// eslint-disable-next-line react/no-array-index-key
													key={index}
													actions={
														<Tooltip
															className={styles.noWrap}
															title={createdAt.format(config.moment.absolute)}
														>
															{createdAt.fromNow()}
														</Tooltip>
													}
												>
													<Link to={`${routes.article}/${node.article.rowId}`}>
														{node.article.title}
													</Link>
												</ListItem>
											);
										})}
									</List>
								) : (
									<p className={styles.pM}>{translate('noData')}</p>
								)}
							</Card>
						)}
					</div>
				);
			}}
		/>
	);
};
