import { COLOR_SILVER, COLOR_EMERALD } from '@sep/br24-constants';
import { Component } from 'react';
import { ResponsiveContainer, PieChart as PieChartContainer, Pie, Tooltip, Cell } from 'recharts';

import { withTranslation } from '@/client/translation/withTranslation';
import type { SourceOrigin } from '@/types/schema';

import styles from '../../../styles';
import type { WithTranslationProps } from '../../../translation';

type Props = WithTranslationProps & {
	data: Array<{
		node: {
			sourceOrigin: SourceOrigin;
			count: number;
		};
	}>;
};

class PieChart extends Component<Props> {
	shouldComponentUpdate({ data: nextData }: Props) {
		return this.props.data !== nextData;
	}

	render() {
		const {
			translation: { translate },
		} = this.props;
		const totalCount = this.props.data.reduce(
			(accumulator, { node: { count } }) => accumulator + count,
			0
		);

		const data = this.props.data
			.map(({ node: { sourceOrigin, count: value } }) => ({
				name: sourceOrigin
					? translate('dashboard.withSourceOrigin')
					: translate('dashboard.noSourceOrigin'),
				value,
				color: sourceOrigin ? COLOR_EMERALD : COLOR_SILVER,
				percent: Math.round((100 / totalCount) * value * 100) / 100,
			}))
			.filter(({ value }) => value > 0);

		/* eslint-disable react/no-array-index-key */
		return (
			<div>
				<ResponsiveContainer width="100%" aspect={1}>
					<PieChartContainer>
						<Tooltip />
						<Pie dataKey="value" data={data} innerRadius="50%" endAngle={-270} startAngle={90}>
							{data.map(({ color }, index) => (
								<Cell key={index} fill={color} />
							))}
						</Pie>
					</PieChartContainer>
				</ResponsiveContainer>
				<ul className={styles.chartLegend}>
					{data.map(({ name, percent, color }, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<li key={index}>
							<div style={{ backgroundColor: color }}>{percent}%</div> {name}
						</li>
					))}
				</ul>
			</div>
		);
		/* eslint-enable react/no-array-index-key */
	}
}

export default withTranslation(PieChart);
