import { BOARD_BUFFER_SECTION_ADD, BOARD_BUFFER_SECTION_REBUILD_ORDER } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';
import { randomId } from '@/client/util/br24Utils';

import type { BoardSectionSchema } from '../types';
import { findBoardById } from '../util/find';

import type { RebuildSectionOrderAction } from './deleteSection';
import { validateSection } from './validate';

export type AddSectionAction = {
	type: typeof BOARD_BUFFER_SECTION_ADD;
	constraint: {
		boardId: string;
	};
	payload: {
		schema: BoardSectionSchema;
		sectionId: string;
		order: number;
	};
};

/**
 * Adds a new section to the board with the identifier `boardId`. The function also takes a `schema` to create the
 * section. To update an existing section use `updateSection`.
 *
 * @param  {string} boardId
 * @param  {string} schema
 * @return {Function}
 */
export default function addSection(
	boardId: string,
	schema: BoardSectionSchema,
	order?: number | null
): AppThunkAction<AddSectionAction | RebuildSectionOrderAction> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		const sectionId = randomId();

		dispatch({
			type: BOARD_BUFFER_SECTION_ADD,
			constraint: { boardId: board.__meta__.internalId },
			payload: {
				schema,
				sectionId,
				order: order || board.sections.length,
			},
		});

		dispatch({
			type: BOARD_BUFFER_SECTION_REBUILD_ORDER,
			constraint: {
				boardId: board.__meta__.internalId,
			},
		});

		dispatch(validateSection(sectionId, { skipIfAutoValidationIsDisabled: true }));
	};
}
