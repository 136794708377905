export const IMPORTED_TEXT = 'AM_DD_IMPORTED_ITEM_TYPE_TEXT';
export const IMPORTED_BRBILD_IMAGE = 'AM_DD_IMPORTED_ITEM_TYPE_BRBILD_IMAGE';
export const IMPORTED_IMAGE = 'AM_DD_IMPORTED_ITEM_TYPE_IMAGE';
export const IMPORTED_AUDIO = 'AM_DD_IMPORTED_ITEM_TYPE_AUDIO';
export const IMPORTED_VIDEO = 'AM_DD_IMPORTED_ITEM_TYPE_VIDEO';
export const IMPORTED_LIVESTREAM = 'AM_DD_IMPORTED_ITEM_TYPE_LIVESTREAM';
export const IMPORTED_ITEM_GROUP = 'AM_DD_IMPORTED_ITEM_GROUP';
export const IMPORTED_ARTICLE = 'AM_DD_IMPORTED_ITEM_TYPE_ARTICLE';
export const IMPORTED_BOARD = 'AM_DD_IMPORTED_ITEM_TYPE_BOARD';
export const IMPORTED_GALLERY = 'AM_DD_IMPORTED_ITEM_TYPE_GALLERY';
export const IMPORTED_EMBED = 'AM_DD_IMPORTED_ITEM_TYPE_EMBED';

// all types togehter
export default {
	IMPORTED_TEXT,
	IMPORTED_BRBILD_IMAGE,
	IMPORTED_IMAGE,
	IMPORTED_AUDIO,
	IMPORTED_VIDEO,
	IMPORTED_LIVESTREAM,
	IMPORTED_ITEM_GROUP,
	IMPORTED_ARTICLE,
	IMPORTED_BOARD,
	IMPORTED_GALLERY,
	IMPORTED_EMBED,
};
