import styled from 'styled-components';

import { Edit } from '../../Icon';

import type { Link } from './ArticleMetaLinks';

const Wrapper = styled.div`
	display: flex;
`;

const DisplayWrapper = styled.div`
	flex: 1;
	word-break: break-all;

	.noLabel {
		font-style: italic;
		color: #aaa5a5;
	}
`;

const EditHandlerWrapper = styled.div`
	margin-left: 10px;
	display: flex;
	align-items: center;
`;

type EditActionProps = {
	className?: string;
	onClick: () => void;
};

export const EditAction = styled(({ className, onClick }: EditActionProps) => (
	<button data-testid="edit-action" className={className} onClick={onClick}>
		<Edit />
	</button>
))`
	cursor: pointer;
	font-size: 1.3em;
	color: ${(props) => props.theme.colors.br24Light};
`;

type OpenLinkActionProps = {
	className?: string;
	url: string | null;
};

export const OpenLinkAction = styled(({ className, url }: OpenLinkActionProps) => (
	<a
		data-testid="open-link-action"
		className={className}
		href={url ?? undefined}
		target="_blank"
		rel="noopener noreferrer"
	>
		{url}
	</a>
))`
	cursor: pointer;
`;

type Props = Link & {
	isEditable: boolean | undefined | null;
	onEdit: () => void;
};

function ArticleMetaLinksShow({ label, url, isEditable, onEdit }: Props) {
	return (
		<Wrapper>
			<DisplayWrapper>
				<div className={!label ? 'noLabel' : undefined}>{label || '[Neuer Link - Kein Label]'}</div>
				<OpenLinkAction url={url} />
			</DisplayWrapper>
			{isEditable ? (
				<EditHandlerWrapper>
					<EditAction onClick={onEdit} />
				</EditHandlerWrapper>
			) : null}
		</Wrapper>
	);
}

export default ArticleMetaLinksShow;
