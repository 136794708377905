/**
 * @generated SignedSource<<3b0e50e506f73629eba9fc5b0c9e3f81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardsTeaserRefetchQuery$variables = {
  boardsTeaserID: string;
};
export type BoardsTeaserRefetchQuery$data = {
  readonly boardsTeaser: {
    readonly rowId: string;
    readonly " $fragmentSpreads": FragmentRefs<"BoardsTeaser_boardsTeaser">;
  } | null;
};
export type BoardsTeaserRefetchQuery = {
  response: BoardsTeaserRefetchQuery$data;
  variables: BoardsTeaserRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardsTeaserID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "boardsTeaserID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardsTeaserRefetchQuery",
    "selections": [
      {
        "alias": "boardsTeaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoardsTeaser_boardsTeaser"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardsTeaserRefetchQuery",
    "selections": [
      {
        "alias": "boardsTeaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyright",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "altText",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Link",
            "kind": "LinkedField",
            "name": "link",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedSince",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Author",
            "kind": "LinkedField",
            "name": "authorByLockedBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "guid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePicture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastname",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockedBy",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42e10f6e0e1ee63f3243c8a7ad164565",
    "id": null,
    "metadata": {},
    "name": "BoardsTeaserRefetchQuery",
    "operationKind": "query",
    "text": "query BoardsTeaserRefetchQuery(\n  $boardsTeaserID: String!\n) {\n  boardsTeaser: boardsTeaserByRowId(rowId: $boardsTeaserID) {\n    ...BoardsTeaser_boardsTeaser\n    rowId\n    id\n  }\n}\n\nfragment BoardsTeaser_boardsTeaser on BoardsTeaser {\n  rowId\n  title\n  description\n  image {\n    url\n    copyright\n    title\n    altText\n    id\n  }\n  link {\n    url\n    label\n  }\n  status\n  ...LockedByAuthor_boardsTeaser\n  ...LockHandling_boardsTeaser\n}\n\nfragment LockHandling_boardsTeaser on BoardsTeaser {\n  rowId\n  lockedBy\n  lockedSince\n  authorByLockedBy {\n    guid\n    firstname\n    lastname\n    id\n  }\n}\n\nfragment LockedByAuthor_boardsTeaser on BoardsTeaser {\n  lockedSince\n  authorByLockedBy {\n    guid\n    profilePicture\n    firstname\n    lastname\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1db7d52f5278125d37b3ef78a919805";

export default node;
