import ErrorMessageText from '../../ErrorMessageText';

type Props = {
	errorText: string | undefined | null;
};

export function ArticleBrokenModule(props: Props) {
	return <ErrorMessageText text={props.errorText ? props.errorText : 'Fehler'} />;
}

export default ArticleBrokenModule;
