import { MODULE_TYPE_EMBED } from '@sep/br24-constants';
import { Alert } from 'antd';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { ModuleTypeEmbed } from '../..';
import type { WithTranslationProps } from '../../../../translation';
import {
	ArticleModuleItem,
	ArticleModuleEmbed,
	ArticleModuleImage,
} from '../../../../ui/ArticleModule';
import { ArticleModuleEmbedPreview } from '../../../../ui/ArticleModule/ArticleModuleEmbed';
import type { Item } from '../../../AssetManager';
import { DndSortModuleContainer, DndUpdateModuleContainer } from '../../DragAndDropHandler';
import type { SimpleItem } from '../../DragAndDropHandler/DndSortModuleContainer';
import { getAEItemType, getAMItemType, toSortModule } from '../../DragAndDropHandler/dndUtils';
import { activeArticleModuleSelector } from '../../selectors';
import BrokenModuleContainer from '../ArticleEditorBrokenModule';

import EmbedModuleEditorContainer from './EmbedModuleEditorContainer';
import EmbedModuleRenderer from './EmbedModuleRenderer';

interface Props extends WithTranslationProps, OwnProps, ReduxProps {
	isEditing: boolean;
	isThumbnailUploading: boolean;
	onAddViaAttachedDropzone: (item: Item | undefined) => void;
	onAddViaInitialDropzone: (id: string, type: string, item: Item) => void;
	onDisplayAttachedDropzone: (order: number, position: 'top' | 'bottom') => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
	onDropUpdateThumbnail: (id: string, type: string, dropItem: Item) => void;
	onDropUploadThumbnail: (id: string, type: string, dropItem: any) => void;
	onMove: (dragIndex: SimpleItem, hoverIndex: SimpleItem) => void;
	onUpdate: (id: string, type: string, item: any) => void;
	onDelete: (id: string) => void;
	onResetAttachedDropzone?: () => void;
	onMoveToAssetManager: (id: string | number) => void;
}

class EmbedModuleContainer extends Component<Props> {
	handleChange = (value: any) => {
		this.props.onUpdate(this.props.id, 'embed', value);
	};

	handleChangeThumbnailMeta = (key: 'url' | 'title' | 'altText' | 'copyright', value: string) => {
		this.props.onUpdate(this.props.id, 'embed', {
			thumbnail: {
				...this.props.module.embed?.thumbnail,
				[key]: value,
			},
		});
	};

	handleDelete = () => {
		this.props.onDelete(this.props.id);
	};

	handleUpdateOnDrop = (dropItem: Item) => {
		this.props.onDropUpdateThumbnail(this.props.id, 'embed', dropItem);
	};

	handleUploadOnDrop = (dropItem: File[]) => {
		const [image] = dropItem;

		// only support one picture upload, not multiple
		if (image && dropItem.length === 1) {
			this.props.onDropUploadThumbnail(this.props.id, 'embed', image);
		}
	};

	render() {
		const {
			translation: { translate },
			module,
			...rest
		} = this.props;

		if (!module.embed) {
			return <BrokenModuleContainer id={module.rowId} />;
		}

		const { service, source, url, altText } = module.embed || {};

		const imageUploader = (
			<DndUpdateModuleContainer
				acceptedItemTypes={[getAMItemType('IMAGE'), getAEItemType('NATIVE')]}
				itemTypes={[getAMItemType('IMAGE'), getAEItemType('NATIVE')]}
				onDropUpdate={this.handleUpdateOnDrop}
				onDropUpload={this.handleUploadOnDrop}
			>
				<ArticleModuleImage
					altText={(module.embed.thumbnail && module.embed.thumbnail.altText) || ''}
					copyright={(module.embed.thumbnail && module.embed.thumbnail.copyright) || ''}
					title={(module.embed.thumbnail && module.embed.thumbnail.title) || ''}
					url={(module.embed.thumbnail && module.embed.thumbnail.url) || ''}
					onChange={this.handleChangeThumbnailMeta}
					{...rest}
				/>
			</DndUpdateModuleContainer>
		);

		let renderedView: JSX.Element | null = null;
		if (url && url !== '') {
			renderedView = (
				<ArticleModuleEmbed
					{...rest}
					service={service}
					altText={altText}
					preview={
						<ArticleModuleEmbedPreview
							previewUrl={url}
							source={source ?? ''}
							service={service}
							validation={module.__validation__}
							onChange={this.handleChange}
						/>
					}
					onChange={this.handleChange}
					validation={module.__validation__}
					imageUploader={imageUploader}
				/>
			);
		} else {
			renderedView = (
				<EmbedModuleRenderer>
					{({ error, props }) => {
						const hasError = !!error;

						if (hasError) {
							return (
								<Alert message={translate('error')} description={error.message} type="error" />
							);
						}

						if (props) {
							return (
								<EmbedModuleEditorContainer
									query={props}
									source={source}
									service={service}
									altText={altText}
									onChange={this.handleChange}
									validation={module.__validation__}
									imageUploader={imageUploader}
									{...rest}
								/>
							);
						}

						return null;
					}}
				</EmbedModuleRenderer>
			);
		}

		return (
			<DndSortModuleContainer
				acceptedItemTypes={{
					dragSource: getAEItemType('EMBED'),
					dropTarget: toSortModule,
				}}
				{...this.props}
			>
				<ArticleModuleItem
					onDelete={this.handleDelete}
					label={translate(`modules.${MODULE_TYPE_EMBED}.text`)}
				>
					{renderedView}
				</ArticleModuleItem>
			</DndSortModuleContainer>
		);
	}
}

interface OwnProps {
	id: string;
}

const connector = connect((state: ReduxState, { id }: OwnProps) => ({
	module: activeArticleModuleSelector(state, id) as ModuleTypeEmbed,
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(EmbedModuleContainer));
