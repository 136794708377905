import {
	CAPABILITY_ALL,
	CAPABILITY_TEXT_ONLY,
	DISTRICTS_ALL,
	EXPIRATION_EPG_BUSINESS_CONTENT,
	EXPIRATION_REPEATED_EVENTS,
	EXPIRATION_SUPPORTING_INFO,
	EXPIRATION_SERIES,
	EXPIRATION_EDUCATION,
	EXPIRATION_TIME_CULTURE,
	PRIORITY_ALL,
	PUSH_NOTIFICATION_ALL,
	PUSH_NOTIFICATION_NONE,
	STATUS_ALL,
	STATUS_DRAFT,
} from '@sep/br24-constants';

import type { Article } from '..';
import config from '../../../config';
import { translate } from '../../../translation';
import { createJoiError } from '../../../util';
import { processValidation } from '../../../util/processValidation';
import type { ValidationResult } from '../../../util/processValidation';

import joi from './extendedJoi';

const validUrlRegEx = /(^$)|(^(http|https):\/\/.*)/;

const ARTICLE_TEASERTEXT_MIN_LENGTH = config.articleModules.header.teasertextMinLength;
const ARTICLE_TEASERTEXT_MAX_LENGTH = config.articleModules.header.teasertextMaxLength;

const ARTICLE_TITLE_MIN_LENGTH = config.articleModules.header.titleMinLength;
const ARTICLE_TITLE_MAX_LENGTH = config.articleModules.header.titleMaxLength;

const ARTICLE_SEO_META_DESC_MAX_LENGTH = config.articleEditor.meta.seo.descriptionMaxLength;
const ARTICLE_SEO_META_DESC_MIN_LENGTH = config.articleEditor.meta.seo.descriptionMinLength;

const ARTICLE_META_NOTES_MAX_LENGTH = config.articleEditor.meta.notes.maxLength;

// TODO: Remove these after switch to new MZT because this is not set by the user
const ARTICLE_LINKS_META_TITLE_MAX_LENGTH = 250;
const ARTICLE_LINKS_META_TITLE_MIN_LENGTH = config.articleEditor.meta.links.minLength;

const schema = joi.object().keys({
	allowComments: joi.boolean().required(),
	authorsDescription: joi.string().min(3).allow(null).optional().default(null),
	capability: joi
		.any()
		.only(...CAPABILITY_ALL)
		.required(),
	districts: joi
		.array()
		.items(...DISTRICTS_ALL)
		.allow(null)
		.optional()
		.default(null),
	enableAmp: joi.boolean().default(true),
	expirationAt: joi.string().required().allow(null),
	expirationFulfilledAt: joi.array().items(joi.string()).allow(null),
	expirationTemplate: joi
		.any()
		.only([
			EXPIRATION_EPG_BUSINESS_CONTENT,
			EXPIRATION_REPEATED_EVENTS,
			EXPIRATION_SUPPORTING_INFO,
			EXPIRATION_SERIES,
			EXPIRATION_EDUCATION,
			EXPIRATION_TIME_CULTURE,
		])
		.allow(null)
		.required(),
	geolocation: joi
		.string()
		.allow(null)
		.optional()
		.regex(/((-)?\d+(\.(\d+))?),((-)?\d+(\.(\d+))?)/)
		.error(createJoiError(translate('validation.errors.article.geolocation'))),
	headline: joi.string().min(3).max(42).allow('', null).optional(),
	id: joi.string().required(),
	isImported: joi.boolean().required(),
	isLive: joi.boolean().default(false),
	isTemplate: joi.boolean().required(),
	language: joi
		.string()
		.allow(null)
		.optional()
		.error(createJoiError(translate('validation.errors.article.language'))),
	lastEdited: joi.any().allow(null),
	links: joi
		.array()
		.items(
			joi.object().keys({
				label: joi
					.string()
					.min(ARTICLE_LINKS_META_TITLE_MIN_LENGTH)
					.max(ARTICLE_LINKS_META_TITLE_MAX_LENGTH)
					.required(),
				url: joi.string().regex(validUrlRegEx).required().allow(''),
				isExternal: joi.boolean().optional(),
			})
		)
		.allow(null)
		.error((errors) => {
			// Was having the problem that when defining specific error messages for label and url,
			// the validation object sent to the components looked like 'links.1.url' which was a pain.
			// May be good to spend more time trying to find a better way to handle the errors at some point.
			const position = errors[0].context.pos;
			const cause = errors[0].context.reason[0].context.label;

			if (cause === 'url') {
				return {
					message: `${translate('validation.errors.article.links.url')} [link ${
						Number(position) + 1
					}]`,
				};
			}

			if (cause === 'label') {
				return {
					message: `${translate('validation.errors.article.links.label', {
						minLength: ARTICLE_LINKS_META_TITLE_MIN_LENGTH,
						maxLength: ARTICLE_LINKS_META_TITLE_MAX_LENGTH,
					})} [link ${Number(position) + 1}]`,
				};
			}

			return errors;
		}),
	location: joi.string().allow(null).optional().json(),
	metaDescription: joi
		.string()
		.min(ARTICLE_SEO_META_DESC_MIN_LENGTH)
		.max(ARTICLE_SEO_META_DESC_MAX_LENGTH)
		.allow(null, '')
		.optional()
		.default(null)
		.error(
			createJoiError(
				translate('validation.errors.article.seo.metaDescription', {
					minLength: ARTICLE_SEO_META_DESC_MIN_LENGTH,
					maxLength: ARTICLE_SEO_META_DESC_MAX_LENGTH,
				})
			)
		),
	notes: joi
		.string()
		.replace(/<[^>]+>/g, '')
		.max(ARTICLE_META_NOTES_MAX_LENGTH)
		.allow(null, '')
		.optional()
		.default(null)
		.error(
			createJoiError(
				translate('validation.errors.article.notes', {
					maxLength: ARTICLE_META_NOTES_MAX_LENGTH,
				})
			)
		),
	canonicalUrl: joi.string().regex(validUrlRegEx).allow(null, '').optional(),
	otherCategories: joi.array().items(joi.string()).allow(null).optional().default(null),
	primaryCategory: joi.string().required(),
	publicationDate: joi.date().iso().allow(null).optional().default(null),
	publicationPriority: joi
		.any()
		.only(...PRIORITY_ALL)
		.allow(null)
		.optional()
		.default(null),
	pushNotification: joi
		.any()
		.only(...PUSH_NOTIFICATION_ALL)
		.default(PUSH_NOTIFICATION_NONE),
	slug: joi
		.string()
		.regex(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/)
		.required()
		.error(
			createJoiError(
				translate('validation.errors.article.seo.slug', {
					minLength: ARTICLE_TEASERTEXT_MIN_LENGTH,
					maxLength: ARTICLE_TEASERTEXT_MAX_LENGTH,
				})
			)
		),
	sourceBroadcastDate: joi.date().iso().allow(null).optional().default(null),
	sourceOrigin: joi.string().allow(null).optional().default(null),
	status: joi
		.any()
		.only(...STATUS_ALL)
		.default(STATUS_DRAFT)
		.required(),
	story: joi.string().uuid(),
	teaserText: joi
		.string()
		.min(ARTICLE_TEASERTEXT_MIN_LENGTH)
		.max(ARTICLE_TEASERTEXT_MAX_LENGTH)
		.required()
		.error(
			createJoiError(
				translate('validation.errors.article.teaserText', {
					minLength: ARTICLE_TEASERTEXT_MIN_LENGTH,
					maxLength: ARTICLE_TEASERTEXT_MAX_LENGTH,
				})
			)
		),
	modules: joi.any().when('capability', {
		is: CAPABILITY_TEXT_ONLY,
		then: joi.array().required(),
		otherwise: joi
			.array()
			.min(1)
			.required()
			.error(createJoiError(translate('validation.errors.article.modules'))),
	}),
	authors: joi
		.array()
		.min(1)
		.max(5)
		.required()
		.error(createJoiError(translate('validation.errors.article.authors'))),
	title: joi
		.string()
		.min(ARTICLE_TITLE_MIN_LENGTH)
		.max(ARTICLE_TITLE_MAX_LENGTH)
		.required()
		.error(
			createJoiError(
				translate('validation.errors.article.title', {
					minLength: ARTICLE_TITLE_MIN_LENGTH,
					maxLength: ARTICLE_TITLE_MAX_LENGTH,
				})
			)
		),
});

const defaultOptions = {
	abortEarly: false,
	allowUnknown: true,
};

export default function validator(article: Article): ValidationResult {
	const result = joi.validate(article, schema, defaultOptions);
	return processValidation(result);
}
