type IncomingThumbnail = {
	title: string[] | undefined | null;
	url: string[] | undefined | null;
	altText: string[] | undefined | null;
	copyright: string[] | undefined | null;
};

type OutputThumbnail = { [K in keyof IncomingThumbnail as `image.${K}`]?: string[] };

export function convertThumbnailValidation(input: IncomingThumbnail) {
	return Object.entries(input).reduce((acc, [key, value]) => {
		if (value !== undefined || value !== null) {
			acc[`image.${key}`] = value;
		}
		return acc;
	}, {} as OutputThumbnail);
}
