import {
	CREATOR_UPDATE,
	CREATOR_RESET,
	CAPABILITY_FULLY_FEATURED,
	CAPABILITY_TEXT_ONLY,
	TYPE_ARTICLE,
	TYPE_BOARD,
	TYPE_AUTHOR,
} from '@sep/br24-constants';
import type { Action } from 'redux';

import type { CreatorType } from '@/client/ui/Header';
import { TYPE_TEASER } from '@/client/ui/Header/Header';

export interface State {
	isVisible: boolean;
	data: CreatorDataState;
}

export type CreatorDataState =
	| CreatorArticleType
	| CreatorBoardType
	| CreatorAuthorType
	| CreatorBoardTeaserType
	| CreatorEmptyType;

export interface CreatorTypeMap {
	[TYPE_ARTICLE]: CreatorArticleType;
	[TYPE_BOARD]: CreatorBoardType;
	[TYPE_AUTHOR]: CreatorAuthorType;
	[TYPE_TEASER]: CreatorBoardTeaserType;
}

export type CreatorProperties = Omit<CreatorArticleType, 'type'> &
	Omit<CreatorBoardType, 'type'> &
	Omit<CreatorAuthorType, 'type'> &
	Omit<CreatorBoardTeaserType, 'type'> & { type: CreatorType };

export interface CreatorArticleType {
	type: typeof TYPE_ARTICLE;
	capability: typeof CAPABILITY_FULLY_FEATURED | typeof CAPABILITY_TEXT_ONLY;
	title: string;
	slug: string;
	isTemplate: boolean;
	primaryCategory?: string;
}

export interface CreatorBoardType {
	type: typeof TYPE_BOARD;
	parentId?: string;
	title: string;
	slug: string;
	description: string;
}

export interface CreatorAuthorType {
	type: typeof TYPE_AUTHOR;
	firstname?: string;
	lastname?: string;
	jobTitle?: string;
}

export interface CreatorBoardTeaserType {
	type: typeof TYPE_TEASER;
	title: string;
	description?: string;
	link?: {
		url: string | undefined | null;
		label: string | undefined | null;
	};
}

interface CreatorEmptyType {
	type: null;
}

interface UpdateCreatorAction extends Action<typeof CREATOR_UPDATE> {
	payload: State;
}

type ResetCreatorAction = Action<typeof CREATOR_RESET>;

type CreatorAction = UpdateCreatorAction | ResetCreatorAction;

const initialState: State = {
	isVisible: false,
	data: {
		type: null,
	},
};

export default function reducer(state = initialState, action: CreatorAction) {
	if (action.type === CREATOR_UPDATE) {
		const {
			payload: { isVisible },
		} = action;
		return {
			...state,
			data: {
				...state.data,
				...action.payload.data,
			},
			isVisible: typeof isVisible !== 'undefined' ? isVisible : state.isVisible,
		};
	}
	if (action.type === CREATOR_RESET) {
		return { ...initialState };
	}
	return {
		...state,
	};
}

function updateVisibility(isVisible: boolean, data?: { type: CreatorType }) {
	return {
		type: CREATOR_UPDATE,
		payload: {
			isVisible,
			data,
		},
	};
}

export function show(data: { type: CreatorType }) {
	return updateVisibility(true, data);
}

export function hide() {
	return updateVisibility(false);
}

export function update(data: Partial<CreatorDataState>) {
	return {
		type: CREATOR_UPDATE,
		payload: { data },
	};
}

export function reset() {
	return { type: CREATOR_RESET };
}
