import {
	TYPE_ARTICLE,
	TYPE_BOARD,
	TYPE_AUTHOR,
	CAPABILITY_FULLY_FEATURED,
	CAPABILITY_BASIC,
	CAPABILITY_TEXT_ONLY,
} from '@sep/br24-constants';
import joi from 'joi-browser';

import config from '../../config';
import { translate } from '../../translation';
import { isSlugAvailable } from '../../util/board';
import createJoiError from '../../util/createJoiError';
import { processValidation } from '../../util/processValidation';
import type { ValidationResult } from '../../util/processValidation';
import { schema as linkSchema } from '../../validators/link';

const TYPE_TEASER = 'TEASER';

const FIRSTNAME_LENGTH_MIN = config.author.firstname.minLength;
const FIRSTNAME_LENGTH_MAX = config.author.firstname.maxLength;
const LASTNAME_LENGTH_MIN = config.author.lastname.minLength;
const LASTNAME_LENGTH_MAX = config.author.lastname.maxLength;
const JOBTITLE_LENGTH_MIN = config.author.jobTitle.minLength;
const JOBTITLE_LENGTH_MAX = config.author.jobTitle.maxLength;

const schema = joi.object().keys({
	// GLOBAL
	type: joi
		.any()
		.valid([TYPE_ARTICLE, TYPE_BOARD, TYPE_AUTHOR, TYPE_TEASER])
		.required()
		.error(createJoiError('Bitte wähle einen Typ.')),

	// AUTHOR
	firstname: joi.when('type', {
		is: TYPE_AUTHOR,
		then: joi
			.string()
			.min(FIRSTNAME_LENGTH_MIN)
			.max(FIRSTNAME_LENGTH_MAX)
			.required()
			.error(
				createJoiError(
					translate('author.validation.firstname', {
						min: FIRSTNAME_LENGTH_MIN,
						max: FIRSTNAME_LENGTH_MAX,
					})
				)
			),
		otherwise: joi.any(),
	}),
	lastname: joi.when('type', {
		is: TYPE_AUTHOR,
		then: joi
			.string()
			.min(LASTNAME_LENGTH_MIN)
			.max(LASTNAME_LENGTH_MAX)
			.required()
			.error(
				createJoiError(
					translate('author.validation.lastname', {
						min: LASTNAME_LENGTH_MIN,
						max: LASTNAME_LENGTH_MAX,
					})
				)
			),
		otherwise: joi.any(),
	}),
	jobTitle: joi.when('type', {
		is: TYPE_AUTHOR,
		then: joi
			.string()
			.min(JOBTITLE_LENGTH_MIN)
			.max(JOBTITLE_LENGTH_MAX)
			.required()
			.error(
				createJoiError(
					translate('author.validation.jobTitle', {
						min: JOBTITLE_LENGTH_MIN,
						max: JOBTITLE_LENGTH_MAX,
					})
				)
			),
		otherwise: joi.any(),
	}),

	// ARTICLE, BOARD, TEASER
	title: joi
		.any()
		.when('type', {
			is: TYPE_BOARD,
			then: joi
				.string()
				.min(3)
				.max(64)
				.required()
				.error(createJoiError('Der Titel muss aus min. 3, max. jedoch aus 64 Zeichen bestehen.')),
		})
		.when('type', {
			is: TYPE_ARTICLE,
			then: joi
				.string()
				.min(3)
				.max(64)
				.required()
				.error(createJoiError('Der Titel muss aus min. 3, max. jedoch aus 64 Zeichen bestehen.')),
		})
		.when('type', {
			is: TYPE_TEASER,
			then: joi
				.string()
				.required()
				.min(3)
				.max(64)
				.error(createJoiError('Der Titel muss aus min. 3, max. jedoch aus 64 Zeichen bestehen.')),
			otherwise: joi.any(),
		})
		.when('type', { is: TYPE_AUTHOR, then: joi.any() }),
	// ARTICLE, BOARD
	slug: joi
		.any()
		.when('type', {
			is: TYPE_BOARD,
			then: joi
				.string()
				.regex(/^\/[0-9a-z]*((-|\/)[0-9a-z]+)*$/)
				.max(64)
				.required()
				.error(
					createJoiError(
						'Das URL-Kürzel darf ausschließlich Buchstaben, Zahlen und / am Anfang als auch zwischen den Buchstaben und Zahlen enthalten.'
					)
				),
		})
		.when('type', {
			is: TYPE_ARTICLE,
			then: joi
				.string()
				.regex(/^[a-z0-9-]+$/, 'must be a valid slug')
				.required()
				.error(
					createJoiError('Das URL-Kürzel darf ausschließlich Buchstaben, Zahlen und "-" bestehen.')
				),
		})
		.when('type', { is: TYPE_AUTHOR, then: joi.any() })
		.when('type', { is: TYPE_TEASER, then: joi.any() }),

	// BOARD, TEASER
	description: joi
		.any()
		.when('type', {
			is: TYPE_BOARD,
			then: joi
				.string()
				.required()
				.min(3)
				.max(160)
				.error(
					createJoiError('Die Beschreibung muss aus min. 3, max. jedoch aus 160 Zeichen bestehen.')
				),
		})
		.when('type', {
			is: TYPE_TEASER,
			then: joi
				.string()
				.required()
				.min(3)
				.max(250)
				.error(
					createJoiError('Die Beschreibung muss aus min. 3, max. jedoch aus 250 Zeichen bestehen.')
				),
		})
		.when('type', { is: TYPE_AUTHOR, then: joi.any() })
		.when('type', { is: TYPE_ARTICLE, then: joi.any() }),

	// BOARD
	parentId: joi.when('type', {
		is: TYPE_BOARD,
		then: joi.string().optional().allow(null),
		otherwise: joi.any(),
	}),

	// ARTICLE
	primaryCategory: joi.when('type', {
		is: TYPE_ARTICLE,
		then: joi.string().error(createJoiError('Wähle eine Primärkategorie.')).required(),
		otherwise: joi.any(),
	}),
	capability: joi.when('type', {
		is: TYPE_ARTICLE,
		then: joi
			.string()
			.valid([CAPABILITY_FULLY_FEATURED, CAPABILITY_BASIC, CAPABILITY_TEXT_ONLY])
			.error(createJoiError('Wähle eine Artikelart.'))
			.required(),
		otherwise: joi.any(),
	}),

	// Teaser
	link: joi.when('type', {
		is: TYPE_TEASER,
		then: joi
			.object()
			.required()
			.keys({
				label: joi.reach(linkSchema, 'label'),
				url: joi.reach(linkSchema, 'url'),
			}),
		otherwise: joi.any(),
	}),
});

type ValidationOptions = {
	abortEarly?: boolean;
	allowUnknown?: boolean;
};

const defaultOptions: ValidationOptions = {
	abortEarly: false,
	allowUnknown: true,
};

async function validateSlug(
	validationResult: ValidationResult,
	data: { parentId: string; slug: string | null }
): Promise<ValidationResult> {
	const isAvailable = await isSlugAvailable(data.slug ?? '', null, data.parentId || null);
	const extendedValidationResult = { ...validationResult };
	if (!isAvailable) {
		extendedValidationResult.isValid = false;
		extendedValidationResult.error.slug = extendedValidationResult.error.slug || [];
		extendedValidationResult.error.slug.push(
			'Es existiert bereits eine Seite mit diesem URL-Kürzel.'
		);
	}
	return extendedValidationResult;
}

export default function validator(
	data: any,
	options: ValidationOptions = {}
): Promise<ValidationResult> {
	const configuration: ValidationOptions = { ...defaultOptions, ...options };

	return new Promise<ValidationResult>((resolve) => {
		joi.validate(data, schema, configuration, (error) => {
			if (error) {
				resolve(processValidation({ error }));
			} else {
				resolve({ isValid: true, error: {} });
			}
		});
	}).then((result: ValidationResult) => {
		if (data.type === TYPE_BOARD) {
			return validateSlug(result, data);
		} else {
			return result;
		}
	});
}
