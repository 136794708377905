import { type ComponentType, type RefAttributes, useRef } from 'react';
import { useDrag } from 'react-dnd';

import { connect } from '@/client/store/redux';
import { getDisplayName } from '@/client/util/react';

import type {
	ImportedItem,
	RemoveItemFromImporterActionFunction,
	Importer,
	AddItemToActiveSpaceActionFunction,
	TransferLinkFromPositionActionFunction,
} from '..';
import { AssetManagerItemControl } from '../../../ui/AssetManager';
import {
	IMPORTED_AUDIO,
	IMPORTED_VIDEO,
	IMPORTED_TEXT,
	IMPORTED_IMAGE,
	IMPORTED_BRBILD_IMAGE,
} from '../AssetManagerImporter/itemTypes';
import isDroppable from '../AssetManagerSpace/isDroppable';
import performDrop from '../AssetManagerSpace/performDrop';
import * as actions from '../actions';

import type { AssetManagerStatus } from '.';

type WrappedComponentProps = {
	importer: Importer;
	importedItem: ImportedItem;
	isDraggable?: boolean;
	removeItemFromImporter: RemoveItemFromImporterActionFunction;
	addItemToActiveSpace: AddItemToActiveSpaceActionFunction;
	transferLinkFromPosition: TransferLinkFromPositionActionFunction;
};

export default function withDragAndDrop<I extends ImportedItem>(
	itemType: string,
	InnerComponent: ComponentType<{ item: I } & RefAttributes<{ showBrBildImageInfo: () => void }>>
) {
	function WrappedComponent(props: WrappedComponentProps) {
		const {
			addItemToActiveSpace,
			importedItem,
			isDraggable = true,
			transferLinkFromPosition,
			...rest
		} = props;

		const { removeItemFromImporter, importer } = props;

		const [{ isDragging }, dragSource] = useDrag<
			{ importedItem: ImportedItem },
			{ wasAdded: boolean },
			{ isDragging: boolean }
		>(
			() => ({
				type: itemType,
				item: () => ({ importedItem }),

				collect: (monitor) => ({
					isDragging: !!monitor.isDragging(),
				}),
				end: ({ importedItem }, monitor) => {
					if (!monitor.didDrop()) {
						return;
					}

					const result = monitor.getDropResult();
					const type = monitor.getItemType();

					if (
						result?.wasAdded &&
						type &&
						[
							IMPORTED_TEXT,
							IMPORTED_IMAGE,
							IMPORTED_BRBILD_IMAGE,
							IMPORTED_AUDIO,
							IMPORTED_VIDEO,
						].includes(type as string)
					) {
						removeItemFromImporter(importer, importedItem.id);
					}
				},
			}),
			[importedItem, importer, removeItemFromImporter]
		);

		const handleClickArrow = () => {
			if (isDroppable(itemType, props)) {
				performDrop(addItemToActiveSpace, transferLinkFromPosition, itemType, props);
			}
		};

		const ref = useRef<{ showBrBildImageInfo: () => void }>(null);

		const handleClickInfo = () => {
			if (ref.current) {
				ref.current.showBrBildImageInfo();
			}
		};

		const currentStatus: AssetManagerStatus = {
			isInImporter: true,
			isDraggable,
			isDragging,
			isMoveable: true,
		};

		return (
			<div ref={dragSource}>
				<AssetManagerItemControl
					currentStatus={currentStatus}
					color="dark"
					item={importedItem}
					sendToAssetManager={handleClickArrow}
					onClickInfoMark={() => handleClickInfo()}
				>
					<InnerComponent
						item={importedItem as any}
						ref={itemType === IMPORTED_BRBILD_IMAGE ? ref : null}
						{...rest}
					/>
				</AssetManagerItemControl>
			</div>
		);
	}

	WrappedComponent.displayName = `withImportedItemDND(${getDisplayName(InnerComponent)})`;

	const connector = connect(null, {
		removeItemFromImporter: actions.removeItemFromImporter,
		addItemToActiveSpace: actions.addItemToActiveSpace,
		transferLinkFromPosition: actions.transferLinkFromPosition,
	});

	return connector(WrappedComponent);
}
