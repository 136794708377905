import { Layout } from 'antd';
import cn from 'classnames';
import type { ReactNode } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import config from '../config';
import type { ReduxState } from '../store/reducers';
import styles from '../styles';
import { upperCaseFirst } from '../util/br24Utils';

interface Props extends ReduxProperties {
	children: ReactNode;
	className?: string;
	border: 'left' | 'right';
	overwriteWidth?: number | null;
}

function Sidebar({ children, className, breakpoint, overwriteWidth, border = 'left' }: Props) {
	return (
		<Layout.Sider
			className={cn(styles.sidebar, styles[`border${upperCaseFirst(border)}`], className)}
			width={overwriteWidth || (breakpoint && config.sidebarWidth[breakpoint]) || 300}
		>
			{children}
		</Layout.Sider>
	);
}

const connector = connect(({ app }: ReduxState) => ({
	breakpoint: app && app?.currentBreakPoint ? app.currentBreakPoint : null,
}));

type ReduxProperties = ConnectedProps<typeof connector>;

export default connector(Sidebar);
