import type { AppThunkAction } from '@/client/store/redux';

import type { RemoveItemAction } from '..';
import { assetManagerStatusSelector } from '../selectors';

import removeItem from './removeItem';

const removeSelected = (): AppThunkAction<RemoveItemAction> => (dispatch, getState) => {
	const { assetManager } = getState();
	const { isInGlobalGroup, activeGlobalGroup } = assetManagerStatusSelector(getState());

	// This ensures only the selected items in the currently open space are removed
	const affectedItems = assetManager.selected.filter((id) => {
		if (isInGlobalGroup) {
			return !!activeGlobalGroup?.items[id];
		}
		return !!assetManager.spaces[assetManager.activeSpace][id];
	});
	affectedItems.map((id) => dispatch(removeItem(assetManager.activeSpace, id, { persist: true })));
};

export default removeSelected;
