import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button, Card, Alert } from 'antd';

import { useTranslate } from '@/client/translation/useTranslate';
import { filterNullOrUndefined } from '@/client/util/filter';

import type { CurrentFilterType } from '../Dashboard';

import ModuleAllocationStatsContainer from './ModuleAllocationStatsContainer';
import PieChart from './PieChartMultimedia';

type Props = {
	startDate: moment.Moment;
	endDate: moment.Moment;
	filter: CurrentFilterType;
};

export default ({ startDate, endDate, filter }: Props) => {
	const translate = useTranslate();

	return (
		<ModuleAllocationStatsContainer
			startDate={startDate.toISOString()}
			endDate={endDate.toISOString()}
			filter={filter}
			render={({ error, props }) => {
				const stats = (props?.stats?.edges || [])
					.map((edge) => edge.node)
					.filter(filterNullOrUndefined);

				return (
					<div>
						{error ? (
							<Alert message={translate('error')} description={error.message} type="error" />
						) : (
							<Card
								title={translate('dashboard.moduleAllocationStats')}
								loading={error === null && props === null}
								extra={
									<Tooltip title={translate('dashboard.moduleAllocationStatsHelp')}>
										<Button shape="circle" size="small" icon={<QuestionCircleOutlined />} />
									</Tooltip>
								}
							>
								{stats && stats.length > 0 ? (
									<PieChart data={stats} />
								) : (
									<p>{translate('dashboard.noDataSelectedDateRange')}</p>
								)}
							</Card>
						)}
					</div>
				);
			}}
		/>
	);
};
