import { DND_BOARD_ITEM } from '@sep/br24-constants';

export const AE_AUTHOR = 'AE_DD_ITEM_TYPE_AUTHOR';
export const AE_LINK = 'AE_DD_ITEM_TYPE_LINK';
export const AE_ARTICLE = 'AE_DD_ITEM_TYPE_ARTICLE';
export const AE_AUDIO = 'AE_DD_ITEM_TYPE_AUDIO';
export const AE_IMAGE = 'AE_DD_ITEM_TYPE_IMAGE';
export const AE_BRBILD_IMAGE = 'AE_DD_ITEM_TYPE_BRBILD_IMAGE';
export const AE_GALLERY = 'AE_DD_ITEM_TYPE_GALLERY';
export const AE_GALLERY_ITEM = 'AE_DD_ITEM_TYPE_GALLERYIMAGE';
export const AE_TEXT = 'AE_DD_ITEM_TYPE_TEXT';
export const AE_VIDEO = 'AE_DD_ITEM_TYPE_VIDEO';
export const AE_VIDEO360 = 'AE_DD_ITEM_TYPE_VIDEO360';
export const AE_EMBED_CODE = 'AE_DD_ITEM_TYPE_EMBED_CODE';
export const AE_LIVESTREAM = 'AE_DD_ITEM_TYPE_LIVESTREAM';
// Don't know if it needs to stay at this value
export const AE_BOARD = DND_BOARD_ITEM;

// All types together
export default {
	AE_AUTHOR,
	AE_LINK,
	AE_ARTICLE,
	AE_AUDIO,
	AE_IMAGE,
	AE_BRBILD_IMAGE,
	AE_GALLERY,
	AE_GALLERY_ITEM,
	AE_TEXT,
	AE_VIDEO,
	AE_VIDEO360,
	AE_EMBED_CODE,
	AE_LIVESTREAM,
	AE_BOARD,
};
