/**
 * @generated SignedSource<<ebd1b1342435d1a197d2cf942f1b7ee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type fetchBroadcastChannelsQuery$variables = {};
export type fetchBroadcastChannelsQuery$data = {
  readonly viewer: {
    readonly allLivestreams: {
      readonly count: number | null;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly broadcastedBy?: {
            readonly id: string;
          } | null;
          readonly id?: string;
          readonly labels?: ReadonlyArray<string | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type fetchBroadcastChannelsQuery = {
  response: fetchBroadcastChannelsQuery$data;
  variables: fetchBroadcastChannelsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labels",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchBroadcastChannelsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MangoLivestreamConnection",
            "kind": "LinkedField",
            "name": "allLivestreams",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MangoLivestreamEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "broadcastedBy",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MangoLivestream",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fetchBroadcastChannelsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MangoLivestreamConnection",
            "kind": "LinkedField",
            "name": "allLivestreams",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MangoLivestreamEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "broadcastedBy",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MangoLivestream",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a922e8e227bb15275fecf68814dd4bf",
    "id": null,
    "metadata": {},
    "name": "fetchBroadcastChannelsQuery",
    "operationKind": "query",
    "text": "query fetchBroadcastChannelsQuery {\n  viewer {\n    allLivestreams {\n      count\n      edges {\n        node {\n          __typename\n          ... on MangoLivestream {\n            labels\n            id\n            broadcastedBy {\n              __typename\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "74816f31a1e8ceca6b7480cb8383b376";

export default node;
