import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { fetchQuery, graphql } from 'relay-runtime';
import type { Subscription } from 'relay-runtime';

import {
	type WithRelayEnvironment,
	withRelayEnvironment,
} from '@/client/relay/withRelayEnvironment';
import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import type { GeolocationChangeEvent } from '@/client/ui/ArticleMeta/ArticleMetaGeolocation/ArticleMetaGeolocation';
import { filterNullOrUndefined } from '@/client/util/filter';

import type { District } from '../..';
import { ArticleMetaGeolocation } from '../../../../ui/ArticleMeta';
import buildGeoTagsFilter from '../../../../util/buildGeoTagsFilter';
import addTagAction from '../../actions/addTag';
import removeTagAction from '../../actions/removeTag';
import update from '../../actions/update';
import {
	articleGeolocationSelector,
	articleInfoValidationSelector,
	activeArticleTagsSelector,
} from '../../selectors';

import type { GeolocationContainerQuery } from './__generated__/GeolocationContainerQuery.graphql';

interface Props extends ReduxProps, WithRelayEnvironment {
	key: string | number;
}

type RegionTagType = {
	[key: string]: {
		rowId: number;
		text: string;
		count: number;
	};
};

type State = {
	availableGeoTags: RegionTagType;
};

const geolocationTagsQuery = graphql`
	query GeolocationContainerQuery($textFilter: [String]) {
		tags: filterTags(textFilter: $textFilter) {
			edges {
				node {
					rowId
					text
				}
			}
		}
	}
`;

class GeolocationContainer extends Component<Props, State> {
	state: Readonly<State> = {
		availableGeoTags: {},
	};
	private geolocationTagsSubscription: Subscription | undefined;

	componentDidMount() {
		this.fetchGeolocationTags();
	}

	componentWillUnmount(): void {
		this.geolocationTagsSubscription?.unsubscribe();
	}

	fetchGeolocationTags = () => {
		this.geolocationTagsSubscription = fetchQuery<GeolocationContainerQuery>(
			this.props.environment,
			geolocationTagsQuery,
			buildGeoTagsFilter()
		).subscribe({
			next: (response) => {
				const geolocationTags = response?.tags?.edges;

				if (geolocationTags && Array.isArray(geolocationTags) && geolocationTags.length > 0) {
					this.setState({
						availableGeoTags: geolocationTags.reduce(
							(acc, value) => ({ ...acc, [value.node.text.toUpperCase()]: { ...value.node } }),
							{}
						),
					});
				} else {
					this.setState({ availableGeoTags: {} });
				}
			},
			error(err: unknown) {
				console.error('error while fetching region tags for geolocation', err);
			},
		});
	};

	handleChange = (values: GeolocationChangeEvent) => {
		this.props.update(values);
	};

	handleDistrictDeselect = (value: District) => {
		const { availableGeoTags } = this.state;
		const { articleTags } = this.props;

		const districtTag = availableGeoTags[value];

		// only delete, if a region tag is available and is selected in the tag cloud
		// otherwise we would delete a non existing tag which throws an error because it's not there
		if (districtTag && articleTags?.includes(districtTag.rowId)) {
			const { rowId } = districtTag;

			this.props.removeTag(rowId);
		}
	};

	handleDistrictSelect = (value: District) => {
		const { availableGeoTags } = this.state;
		const { articleTags } = this.props;

		if (!availableGeoTags) {
			return;
		}

		const districtTag = availableGeoTags[value];

		// only add the tag when it's not already there
		if (districtTag && !articleTags?.includes(districtTag.rowId)) {
			this.props.addTag({
				id: districtTag.rowId,
				...districtTag,
			});
		}
	};

	render() {
		const {
			districts,
			location,
			geolocation,
			validation,
			articleTags,
			addTag,
			removeTag,
			environment,
			update,
			...rest
		} = this.props;

		return (
			<ArticleMetaGeolocation
				districts={districts?.filter(filterNullOrUndefined)}
				geolocation={geolocation}
				isLoading={!this.state.availableGeoTags}
				location={location}
				validation={validation && validation.geolocation ? validation.geolocation : null}
				onChange={this.handleChange}
				onDistrictSelect={this.handleDistrictSelect}
				onDistrictDeselect={this.handleDistrictDeselect}
				{...rest}
			/>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		articleTags: activeArticleTagsSelector(state),
		...articleGeolocationSelector(state),
		...articleInfoValidationSelector(state),
	}),
	{
		update,
		addTag: addTagAction,
		removeTag: removeTagAction,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withRelayEnvironment(GeolocationContainer));
