import { combineReducers } from 'redux';

import type { State as ArticleEditorState } from '../../containers/ArticleEditor';
import articleEditor from '../../containers/ArticleEditor/reducer';
import assetManager, { type AssetManagerState } from '../../containers/AssetManager/reducer';

import app from './app';
import type { State as AppState } from './app';
import articleRepublisher from './articleRepublisher';
import type { State as ArticleRepublisherState } from './articleRepublisher';
import type { State as ArticlesManagerState } from './articlesManager';
import articlesManager from './articlesManager';
import type { AuthorsFilterType as AuthorsFilterState } from './authorsFilter';
import authorsFilter from './authorsFilter';
import type { State as BoardBufferState } from './boardBuffer';
import boardBuffer from './boardBuffer/reducer';
import type { BoardsTeaserBufferState } from './boardsTeaserBuffer';
import boardsTeaserBuffer from './boardsTeaserBuffer';
import type { SearchQuery as BoardsTeasersManagerState } from './boardsTeasersManager';
import boardsTeasersManager from './boardsTeasersManager';
import type { State as CreatorState } from './creator';
import creator from './creator';
import lockStatus from './lockStatus/index';
import type { LockStatusState } from './lockStatus/index';
import type { State as SophoraState } from './sophora';
import sophora from './sophora';
import type { State as UserState } from './user';
import user from './user';

export type ReduxState = {
	app: AppState;
	boardBuffer: BoardBufferState;
	creator: CreatorState;
	articlesManager: ArticlesManagerState;
	boardsTeaserBuffer: BoardsTeaserBufferState;
	boardsTeasersManager: BoardsTeasersManagerState;
	user: UserState;
	authorsFilter: AuthorsFilterState;
	sophora: SophoraState;
	articleRepublisher: ArticleRepublisherState;
	lockStatus: LockStatusState;
	assetManager: AssetManagerState;
	articleEditor: ArticleEditorState;
};

export const rootReducer = combineReducers({
	app,
	boardBuffer,
	creator,
	articlesManager,
	boardsTeaserBuffer,
	boardsTeasersManager,
	user,
	authorsFilter,
	sophora,
	articleRepublisher,
	lockStatus,
	assetManager,
	articleEditor,
});

// this code snippes will error when the inferred state gets out of sync with the declared state.
// it does not add any overhead to the application as it should be tree shaken during the build.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
function typeCheckRootReducer(): void {
	const inferredState = null as unknown as ReturnType<typeof rootReducer>;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-expect-error
	const testCorrectState: ReduxState = inferredState;
}
