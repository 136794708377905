import { useTranslate } from '@/client/translation/useTranslate';

import { AM_TYPE_IMAGE } from '../../../constants';

import { Title, Content, Copyright } from './AssetManagerItemControl';
import Media from './Media';
import TypeIcon from './TypeIcon';

type AssetManagerItemImageProps = {
	image?: string | null;
	title?: string | null;
	copyright: string | undefined | null;
};

function AssetManagerItemImage({ image, title, copyright }: AssetManagerItemImageProps) {
	const translate = useTranslate();
	return (
		<>
			<TypeIcon type={AM_TYPE_IMAGE} isOverlying={true} />
			<Media image={image} />
			<Content>
				<Title>{title || translate(`assetManager.item.${AM_TYPE_IMAGE}.noTitle`)}</Title>
				{copyright && <Copyright>{copyright}</Copyright>}
			</Content>
		</>
	);
}

export default AssetManagerItemImage;
