import { useEffect } from 'react';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

const DropzoneWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.primaryBlue};
	height: 100px;
	width: 100%;
	margin-top: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DropzoneText = styled.p`
	font-weight: 400;
	font-size: 1.4em;
	margin-bottom: 0;
	color: ${(props) => props.theme.colors.white};
`;

interface Props {
	onResetAttachedDropzone?: () => void;
	isDropBlocked?: boolean;
}

export function ModulePartAttachedDropzone({ isDropBlocked, onResetAttachedDropzone }: Props) {
	const translate = useTranslate();

	useEffect(() => {
		if (isDropBlocked && onResetAttachedDropzone) {
			onResetAttachedDropzone();
		}
	}, [isDropBlocked, onResetAttachedDropzone]);

	return (
		<DropzoneWrapper>
			<DropzoneText>{translate(`modules.attachedDropzone.title`)}</DropzoneText>
		</DropzoneWrapper>
	);
}
