import AssetManagerSpace from './AssetManagerSpace';

function AssetManagerPersonalSpace({
	removeAfterDrop,
	toggleDragIsCopy,
}: {
	removeAfterDrop: boolean;
	toggleDragIsCopy: (isCopy: boolean) => void;
}) {
	return (
		<AssetManagerSpace removeAfterDrop={removeAfterDrop} toggleDragIsCopy={toggleDragIsCopy} />
	);
}
export default AssetManagerPersonalSpace;
