import { Component } from 'react';
import { shouldUpdate } from 'should-update';

import styles from './TimeIndicator.module.scss';

type Props = {
	duration: number;
	tick: number;
	onFulfil(): void;
};

type State = {
	percent: number;
};

export default class TimeIndicator extends Component<Props, State> {
	timeInterval: any = null;

	static defaultProps = {
		duration: 60000,
		tick: 1000,
	};

	state = {
		percent: 0,
	};

	componentDidMount() {
		this.start();
	}

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		return (
			shouldUpdate(['duration', 'tick', 'onFulfil'], nextProps) ||
			this.state.percent !== nextState.percent
		);
	}

	componentWillUnmount() {
		this.clear();
	}

	clear() {
		if (this.timeInterval) {
			clearInterval(this.timeInterval);
			this.timeInterval = null;
		}
	}

	reset() {
		this.clear();
		this.setState({ percent: 0 }, this.start);
	}

	start() {
		const {
			tick = TimeIndicator.defaultProps.tick,
			duration = TimeIndicator.defaultProps.duration,
			onFulfil,
		} = this.props;

		this.timeInterval = setInterval(() => {
			const { percent } = this.state;
			const nextPercent = (100 / duration) * tick + percent;

			this.setState({
				percent: nextPercent,
			});

			if (nextPercent >= 100) {
				onFulfil();
				this.clear();
				setTimeout(() => this.setState({ percent: 0 }, this.start), tick);
			}
		}, tick);
	}

	render() {
		const { percent } = this.state;

		return (
			<div className={styles.timeIndicator}>
				<div className={styles.percent} style={{ width: `${Math.round(percent * 100) / 100}%` }} />
			</div>
		);
	}
}
