import type { AppThunkAction } from '@/client/store/redux';

import type { AddItemsOptions, AddItemsAction, Item, RawItem } from '..';
import { AM_PERSONAL_SPACE } from '../../../constants';

import { addItems } from './addItems';

const addItemToActiveSpace =
	(item: Item | RawItem, options?: AddItemsOptions): AppThunkAction<AddItemsAction> =>
	(dispatch, getState) => {
		const { assetManager } = getState();

		// The individual asset locking feature is not intended to be a part of the private space
		if (
			assetManager.activeSpace === AM_PERSONAL_SPACE &&
			item.hasOwnProperty('isLockedForDeletion')
		) {
			const { isLockedForDeletion, ...itemToPrivateSpace } = item as (Item | RawItem) & {
				isLockedForDeletion: unknown;
			};
			return dispatch(addItems(assetManager.activeSpace, [itemToPrivateSpace], options));
		}

		return dispatch(addItems(assetManager.activeSpace, [item], options));
	};

export default addItemToActiveSpace;
