import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import { UserContainer } from '../../auth/AuthContext';
import AssetManagerGroupItem from '../../ui/AssetManager/AssetManagerGroups/AssetManagerGroupItem';
import { AssetManagerGroupsAddButton } from '../../ui/AssetManager/AssetManagerGroups/AssetManagerGroupsAddButton';
import AssetManagerGroupsSearch from '../../ui/AssetManager/AssetManagerGroups/AssetManagerGroupsSearch';
import AssetManagersGroupsWrapper from '../../ui/AssetManager/AssetManagerGroups/AssetManagersGroupsWrapper';
import { compare } from '../../util/sortBy';

import type { GlobalGroup } from '.';
import { isDefaultGroupId } from './AssetManagerGlobalSpace';
import * as actions from './actions';
import { globalGroupsSelector } from './selectors';

type Groups = ReduxProps['groups'];

interface AMSpaceGroupedProps extends ReduxProps {
	onShowSpace: (a: string) => void;
	onToggleSubGroups: (a: string) => void;
}

function groupsAsArray(groups: Groups): GlobalGroup[] {
	return Object.values(groups);
}

function getTopLevelGroups(groups: Groups): GlobalGroup[] {
	return groupsAsArray(groups).filter((item) => !item.hasOwnProperty('parent'));
}

function getChildrenGroups(parent: string, groups: Groups) {
	return groupsAsArray(groups).filter((item) => item.parent === parent);
}

function compareGroupsToSort(a: GlobalGroup, b: GlobalGroup): number {
	if (a.sortOrder !== undefined && b.sortOrder === undefined) {
		return -1;
	}

	if (b.sortOrder !== undefined && a.sortOrder === undefined) {
		return 1;
	}

	if (a.sortOrder !== undefined && b.sortOrder !== undefined) {
		return b.sortOrder - a.sortOrder;
	}

	return compare(a.title, b.title);
}

type State = {
	searchTerm: string;
};

const getAncestorTree = (groups: GlobalGroup[], matches: GlobalGroup[]) => {
	const parentIds = matches.map((g) => g.parent).filter(Boolean);

	if (parentIds.length > 0) {
		const parents = groups.filter((g) => parentIds.includes(g.id));
		return [...matches, ...getAncestorTree(groups, parents)];
	}

	return [...matches];
};

const getDescendantTree = (groups: GlobalGroup[], matches: GlobalGroup[]) => {
	const ids = matches.map((g) => g.id);
	const children = groups.filter((g) => g.parent && ids.includes(g.parent));

	if (children.length > 0) {
		return [...matches, ...getDescendantTree(groups, children)];
	}

	return [...matches];
};

class AssetManagerSpaceGroupList extends Component<AMSpaceGroupedProps, State> {
	state = {
		searchTerm: '',
	};

	getFilteredGroups = (): Groups => {
		const { groups: allGroups } = this.props;
		const { searchTerm } = this.state;

		if (!searchTerm) {
			return allGroups;
		}

		const allGroupsList = Object.values(allGroups);

		const leafMatches = allGroupsList.filter((g) => g.title.toLowerCase().indexOf(searchTerm) >= 0);

		const withAncestors = getAncestorTree(allGroupsList, leafMatches);
		const withDescendants = getDescendantTree(allGroupsList, leafMatches);

		return [...withAncestors, ...withDescendants].reduce(
			(reduced, item) => ({ ...reduced, [item.id]: item }),
			{}
		);
	};

	handleSearchChange = (searchTerm: string) => {
		this.setState({ searchTerm });
	};

	renderGroupById = (id: string, level = 0, parentColor = 'transparent') => {
		const { searchTerm } = this.state;
		const { groups, updateGlobalGroup, removeGlobalGroup, toggleGlobalGroupLock } = this.props;
		const { title, color, isLocked, showSubGroups, isHardLocked = false } = groups[id];
		const children = getChildrenGroups(id, this.getFilteredGroups());

		return (
			<AssetManagerGroupItem
				id={id}
				key={id}
				color={color}
				level={level}
				title={title}
				isLocked={isLocked || isHardLocked}
				isDefault={isDefaultGroupId(id)}
				isHardLocked={isHardLocked}
				searchActive={!!searchTerm.length}
				updateGroup={updateGlobalGroup}
				removeGroup={removeGlobalGroup}
				toggleGroupLock={toggleGlobalGroupLock}
				showSubGroups={showSubGroups}
				onToggleSubGroups={() => this.props.onToggleSubGroups(id)}
				onShowSpace={this.props.onShowSpace}
				parentColor={parentColor}
			>
				{children
					.sort(compareGroupsToSort)
					.map((child) => this.renderGroupById(child.id, level + 1, color ?? parentColor))}
			</AssetManagerGroupItem>
		);
	};

	render() {
		const { addGlobalGroups } = this.props;
		const { searchTerm } = this.state;
		const filteredGroups = this.getFilteredGroups();

		return (
			<AssetManagersGroupsWrapper>
				<AssetManagerGroupsSearch value={searchTerm} onChange={this.handleSearchChange} />
				{getTopLevelGroups(filteredGroups)
					.sort(compareGroupsToSort)
					.map((topLevelGroup) => this.renderGroupById(topLevelGroup.id))}
				<UserContainer>
					{(user) => (
						<AssetManagerGroupsAddButton
							onAdd={({ color, title }) =>
								addGlobalGroups([{ color, title }], { persist: true, user })
							}
						/>
					)}
				</UserContainer>
			</AssetManagersGroupsWrapper>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		groups: { ...globalGroupsSelector(state) },
	}),
	{
		addGlobalGroups: actions.addGlobalGroups,
		updateGlobalGroup: actions.updateGlobalGroup,
		removeGlobalGroup: actions.removeGlobalGroup,
		toggleGlobalGroupLock: actions.toggleGlobalGroupLock,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssetManagerSpaceGroupList);
