// I'm not sure how to get the type of a board.
export function categoryNames(section: number) {
	switch (section) {
		case 0:
			return 'boards.table.homepage';

		case 1:
			return 'boards.table.regions';

		case 2:
			return 'boards.table.categories';

		default:
			return 'boards.table.topics';
	}
}
