import type { ReactNode } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { ArticleMetaDraggableListItem } from '../../../../ui/ArticleMeta';

export const AnimatedMetaDraggableItem = styled(ArticleMetaDraggableListItem)`
	opacity: ${({ $isDragging }: { $isDragging: boolean }) => ($isDragging ? 0 : 1)};
`;

interface Props<T extends string> {
	id?: string;
	url?: string | null;
	articleId?: string;
	isInvalidOrDepublished?: boolean;
	isDraggable?: boolean | undefined;
	isDeletable?: boolean | undefined;
	index: number;
	children: ReactNode;
	type: T;
	acceptedItemTypes: T[];
	onMove: (dragIndex: number, hoverIndex: number) => void;
	onRemove: (value: number) => void;
}

export function DraggableListItemContainer<T extends string>({
	children,
	index,
	id,
	url,
	articleId,
	onRemove,
	onMove,
	isInvalidOrDepublished = false,
	isDraggable = true,
	isDeletable = true,
	type,
	acceptedItemTypes,
}: Props<T>) {
	const originalIndex = index;
	const [collected, drag] = useDrag(
		() => ({
			type,
			item: {
				type: articleId ? 'article' : 'board',
				id: id,
				url: url,
				articleId: articleId,
				index: index,
				originalIndex,
			},
			collect(monitor) {
				return { isDragging: monitor.isDragging() };
			},
			end({ originalIndex }, monitor) {
				const didDrop = monitor.didDrop();
				if (!didDrop) {
					onMove(index, originalIndex);
				}
			},
		}),
		[articleId, id, index, type, url, originalIndex, onMove]
	);

	const [_, drop] = useDrop(
		() => ({
			accept: acceptedItemTypes,
			hover(item: { index: number }) {
				const dragIndex = item.index;
				const hoverIndex = index;

				if (Number.isInteger(dragIndex) && dragIndex === hoverIndex) {
					return;
				}

				onMove(dragIndex, hoverIndex);
				item.index = hoverIndex;
			},
		}),
		[index, onMove, acceptedItemTypes]
	);

	return (
		<div ref={(node) => drag(drop(node))}>
			<AnimatedMetaDraggableItem
				index={index}
				onRemove={onRemove}
				$isDragging={collected.isDragging}
				isDraggable={isDraggable}
				isDeletable={isDeletable}
				isInvalidOrDepublished={isInvalidOrDepublished}
			>
				{children}
			</AnimatedMetaDraggableItem>
		</div>
	);
}
