/**
 * @generated SignedSource<<254c9b9619f514569f6816890d260434>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SourceOriginContainerRefetch_query$data = {
  readonly viewer: {
    readonly searchSourceOrigins: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly title: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly series?: {
      readonly id: string;
      readonly title: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "SourceOriginContainerRefetch_query";
};
export type SourceOriginContainerRefetch_query$key = {
  readonly " $data"?: SourceOriginContainerRefetch_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"SourceOriginContainerRefetch_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "hasSourceOrigin"
    },
    {
      "kind": "RootArgument",
      "name": "sourceOriginRowId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SourceOriginContainerRefetch_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MangoViewer",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": "searchSourceOrigins",
          "args": [
            {
              "kind": "Variable",
              "name": "filter",
              "variableName": "filter"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 20
            }
          ],
          "concreteType": "MangoSeriesConnection",
          "kind": "LinkedField",
          "name": "allSeries",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MangoSeriesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "hasSourceOrigin",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "sourceOriginRowId"
                }
              ],
              "concreteType": null,
              "kind": "LinkedField",
              "name": "series",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "11023dcc59c49f697efddc1132805ec5";

export default node;
