import { TYPE_ARTICLE, TYPE_BOARD, TYPE_AUTHOR } from '@sep/br24-constants';
import type { User } from '@sep/cms-auth-client';
import { Modal, Form, Input, Button, Checkbox } from 'antd';
import { debounce } from 'lodash-es';
import { type ChangeEvent, Component, type KeyboardEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import {
	type CreatorArticleType,
	type CreatorBoardType,
	type CreatorAuthorType,
	type CreatorBoardTeaserType,
	type CreatorProperties,
	hide,
	reset,
	update,
} from '@/client/store/reducers/creator';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';
import type { CreatorType } from '@/client/ui/Header';

import { useUser } from '../../auth/AuthContext';
import config from '../../config';
import environment from '../../environment';
import type { WithTranslationProps } from '../../translation';
import translations from '../../translations';
import { urlify } from '../../util';
import attachFormItemErrorProps from '../../util/attachFormItemErrorProps';
import type { ValidationResult } from '../../util/processValidation';
import CategoryPicker from '../CategoryPicker';
import { ParentBoardPicker } from '../ParentBoardPicker/ParentBoardPicker';

import { createArticleMutation } from './CreateArticleMutation';
import { createAuthorMutation } from './CreateAuthorMutation';
import { createBoardMutation } from './CreateBoardMutation';
import { createBoardsTeaserMutation } from './CreateBoardsTeaserMutation';
import styles from './Creator.module.scss';
import FancyCapabilityPicker from './FancyCapabilityPicker';
import TypePicker from './TypePicker';
import validator from './validator';

const TYPE_TEASER = 'TEASER';

const { TextArea } = Input;

type State = {
	slugWasManuallyChanged: boolean;
	error:
		| {
				[key: string]: Array<string>;
		  }
		| undefined
		| null;
	url: string | undefined | null;
	label: string | undefined | null;
	creatorIsVisible: boolean;
};

interface Props extends WithTranslationProps, ReduxProps {
	onSuccess: (
		type: typeof TYPE_ARTICLE | typeof TYPE_BOARD | typeof TYPE_AUTHOR | typeof TYPE_TEASER,
		id: string | null | undefined
	) => void;
}

function CreatorWithUser(props: Props) {
	const user = useUser();
	return <Creator {...props} user={user} />;
}

export class Creator extends Component<
	Props & {
		user?: User;
	},
	State
> {
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		// reset local state on close
		if (prevState.creatorIsVisible && prevState.creatorIsVisible !== nextProps.creator.isVisible) {
			return {
				creatorIsVisible: (nextProps.creator && nextProps.creator.isVisible) || false,
				slugWasManuallyChanged: false,
				error: null,
			};
		}
		return null;
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			slugWasManuallyChanged: false,
			error: null,
			url: null,
			label: null,
			creatorIsVisible: (props.creator && props.creator.isVisible) || false,
		};
	}

	componentDidUpdate(prevProps: Props) {
		const { type } = this.props.creator.data;
		const { type: prevType } = prevProps.creator.data;

		if (prevType !== null && type !== prevType) {
			this.validate();
		}

		switch (type) {
			case TYPE_ARTICLE:
				{
					const { capability, title, slug, primaryCategory, isTemplate } = this.props.creator.data;
					const {
						capability: prevCapability,
						title: prevTitle,
						slug: prevSlug,
						primaryCategory: prevPrimaryCategory,
						isTemplate: prevIsTemplate,
					} = prevProps.creator.data as CreatorArticleType;

					if (
						capability !== prevCapability ||
						title !== prevTitle ||
						slug !== prevSlug ||
						primaryCategory !== prevPrimaryCategory ||
						isTemplate !== prevIsTemplate
					) {
						this.validate();
					}
				}
				break;

			case TYPE_BOARD:
				{
					const { parentId, title, slug, description } = this.props.creator.data;
					const {
						parentId: prevParentId,
						title: prevTitle,
						slug: prevSlug,
						description: prevDescription,
					} = prevProps.creator.data as CreatorBoardType;
					if (
						parentId !== prevParentId ||
						title !== prevTitle ||
						slug !== prevSlug ||
						description !== prevDescription
					) {
						this.validate();
					}
				}
				break;

			case TYPE_AUTHOR:
				{
					const { firstname, lastname, jobTitle } = this.props.creator.data;
					const {
						firstname: prevFirstname,
						lastname: prevLastname,
						jobTitle: prevJobTitle,
					} = prevProps.creator.data as CreatorAuthorType;

					if (
						firstname !== prevFirstname ||
						lastname !== prevLastname ||
						jobTitle !== prevJobTitle
					) {
						this.validate();
					}
				}
				break;

			case TYPE_TEASER:
				{
					const { title, description, link } = this.props.creator.data;
					const {
						title: prevTitle,
						description: prevDescription,
						link: prevLink,
					} = prevProps.creator.data as CreatorBoardTeaserType;

					if (title !== prevTitle || description !== prevDescription || link !== prevLink) {
						this.validate();
					}
				}
				break;
		}
	}

	componentWillUnmount(): void {
		this.validate.cancel();
	}

	captureLinkChange = () => {
		const linkData = { url: this.state.url, label: this.state.label };
		this.changeField('link', linkData);
	};

	changeField<K extends keyof CreatorProperties>(key: K, value: CreatorProperties[K]) {
		this.props.update({ [key]: value });
	}

	handleCancel = () => {
		this.props.hide();
		setTimeout(this.props.reset, 500);
	};

	handleKeyPressInput = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			this.handleSave();
		}
	};

	handleLinkChange = (key: string, value: string) => {
		if (key === 'url') {
			this.setState({ url: value }, () => {
				this.captureLinkChange();
			});
		}
		if (key === 'label') {
			this.setState({ label: value }, () => {
				this.captureLinkChange();
			});
		}
	};

	handleSave = async () => {
		const { type } = this.props.creator.data;

		const isValid = await this.validate();

		const { hide, reset, user } = this.props;

		if (!type || !isValid) {
			return;
		}

		if (type === TYPE_ARTICLE) {
			const { capability, title, slug, primaryCategory, isTemplate } = this.props.creator.data;
			const { createArticle } = await createArticleMutation(
				{
					capability,
					title,
					slug,
					isTemplate,
					revisionBy: user?.guid,
					primaryCategory,
				},
				environment
			);
			hide();
			setTimeout(reset, 500);
			this.props.onSuccess(type, createArticle?.article?.rowId);
		} else if (type === TYPE_BOARD) {
			const { parentId, title, slug, description } = this.props.creator.data;

			const { createBoard } = await createBoardMutation(
				{
					parentId: parentId || null,
					title,
					slug,
					description,
				},
				environment
			);

			hide();
			setTimeout(reset, 500);
			this.props.onSuccess(type, createBoard?.board?.rowId);
		} else if (type === TYPE_AUTHOR) {
			const { firstname, lastname, jobTitle } = this.props.creator.data;
			const { createAuthor } = await createAuthorMutation(
				{
					firstname,
					lastname,
					jobTitle: jobTitle || null,
				},
				environment
			);
			hide();
			setTimeout(reset, 500);
			this.props.onSuccess(type, createAuthor?.author?.guid);
		} else if (type === TYPE_TEASER) {
			const { title, description, link } = this.props.creator.data;

			const { createBoardsTeaser } = await createBoardsTeaserMutation(
				{
					title,
					description,
					link,
				},
				environment
			);

			hide();
			setTimeout(reset, 500);
			this.props.onSuccess(type, createBoardsTeaser?.boardsTeaser?.rowId);
		}
		this.setState({ url: null });
		this.setState({ label: null });
	};

	handleSlugChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { target } = event;

		if (target instanceof HTMLInputElement) {
			this.changeField('slug', target.value);
			this.setState({ slugWasManuallyChanged: true });
		}
	};
	handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { target } = event;
		const { slugWasManuallyChanged } = this.state;
		const { type } = this.props.creator.data;

		if (target instanceof HTMLInputElement) {
			this.changeField('title', target.value || '');
			if (!slugWasManuallyChanged) {
				this.changeField('slug', `${type === TYPE_BOARD ? '/' : ''}${urlify(target.value || '')}`);
			}
		}
	};

	returnTitle = (type: CreatorType | null) => {
		switch (type) {
			case TYPE_BOARD:
				return translations.createNewBoard;

			case TYPE_ARTICLE:
				return translations.createNewArticle;

			case TYPE_TEASER:
				return translations.createNewTeaser;

			default:
				return translations.createNewItem;
		}
	};

	validate = debounce(
		(): Promise<ValidationResult> =>
			new Promise((resolve, reject) => {
				validator(this.props.creator.data).then(({ isValid, error }) => {
					this.setState({
						error: isValid ? null : error,
					});
					resolve({ isValid, error });
				}, reject);
			}),
		config.typingInterval
	);

	renderArticleForm({ title, slug, capability, primaryCategory }: CreatorArticleType) {
		const { error } = this.state;

		return (
			<Form layout="vertical">
				<FancyCapabilityPicker
					hasError={!!error && !!error.capability}
					value={capability}
					onChange={(value) => this.changeField('capability', value)}
				/>
				<Form.Item label="Überschrift" {...attachFormItemErrorProps(error, 'title')}>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translations.title}
						value={title}
						onChange={(e) => this.handleTitleChange(e)}
					/>
				</Form.Item>
				<Form.Item label="URL-Kürzel" {...attachFormItemErrorProps(error, 'slug')}>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translations.slug}
						value={slug}
						onChange={(e) => this.handleSlugChange(e)}
					/>
				</Form.Item>
				<Form.Item label="Rubrik" {...attachFormItemErrorProps(error, 'primaryCategory')}>
					<CategoryPicker
						defaultValue={primaryCategory}
						placeholder={translations.pleaseSelectPrimaryCategorie}
						onChange={(value) => this.changeField('primaryCategory', value ?? undefined)}
					/>
				</Form.Item>
				<Form.Item
					label="Vorlage"
					{...attachFormItemErrorProps(error, 'template')}
					className={styles.adjustedLineHeight}
				>
					<Checkbox onChange={(event) => this.changeField('isTemplate', event.target.checked)}>
						Als neue Vorlage erstellen
					</Checkbox>
					<div className={styles.templateDescription}>
						Hier können Vorlagen für immer wiederkehrende Standard-Formate erstellt werden (Börse,
						100 Sekunden).
					</div>
				</Form.Item>
			</Form>
		);
	}

	renderAuthorForm({ firstname, lastname, jobTitle }: CreatorAuthorType) {
		const { error } = this.state;
		const {
			translation: { translate },
		} = this.props;

		const FIRSTNAME_LENGTH_MAX = config.author.firstname.maxLength;
		const LASTNAME_LENGTH_MAX = config.author.lastname.maxLength;
		const JOBTITLE_LENGTH_MAX = config.author.jobTitle.maxLength;

		return (
			<Form layout="vertical">
				<Form.Item
					label={translate('author.firstname.label')}
					{...attachFormItemErrorProps(error, 'firstname')}
					help={translate('author.characterCount', {
						count: firstname ? firstname.length : 0,
						max: FIRSTNAME_LENGTH_MAX,
					})}
				>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translate('author.firstname.placeholder')}
						value={firstname}
						onChange={(event) => this.changeField('firstname', event.target.value)}
					/>
				</Form.Item>
				<Form.Item
					label={translate('author.lastname.label')}
					{...attachFormItemErrorProps(error, 'lastname')}
					help={translate('author.characterCount', {
						count: lastname ? lastname.length : 0,
						max: LASTNAME_LENGTH_MAX,
					})}
				>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translate('author.lastname.placeholder')}
						value={lastname}
						onChange={(event) => this.changeField('lastname', event.target.value)}
					/>
				</Form.Item>
				<Form.Item
					label={translate('author.jobTitle.label')}
					{...attachFormItemErrorProps(error, 'jobTitle')}
					help={translate('author.characterCount', {
						count: jobTitle ? jobTitle.length : 0,
						max: JOBTITLE_LENGTH_MAX,
					})}
				>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translate('author.jobTitle.placeholder')}
						value={jobTitle}
						onChange={(event) => this.changeField('jobTitle', event.target.value)}
					/>
				</Form.Item>
			</Form>
		);
	}

	renderBoardForm({ parentId, title, slug, description }: CreatorBoardType) {
		const { error } = this.state;

		return (
			<Form layout="vertical">
				<Form.Item label="Überschrift" {...attachFormItemErrorProps(error, 'title')}>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translations.title}
						value={title}
						onChange={this.handleTitleChange}
					/>
				</Form.Item>
				<Form.Item label="URL-Kürzel" {...attachFormItemErrorProps(error, 'slug')}>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translations.slug}
						value={slug}
						onChange={this.handleSlugChange}
					/>
				</Form.Item>
				<Form.Item label="Übergeordnete Seite" {...attachFormItemErrorProps(error, 'parentId')}>
					<ParentBoardPicker
						onSelect={(selectedParentId: string | null) =>
							this.changeField('parentId', selectedParentId ?? undefined)
						}
						defaultValue={parentId}
					/>
				</Form.Item>
				<Form.Item label="Teasertext" {...attachFormItemErrorProps(error, 'description')}>
					<TextArea
						onKeyPress={this.handleKeyPressInput}
						placeholder={translations.description}
						value={description || ''}
						onChange={(event) => this.changeField('description', event.target.value)}
					/>
				</Form.Item>
			</Form>
		);
	}

	renderTeaserForm({ title, description }: CreatorBoardTeaserType) {
		const { error, url, label } = this.state;

		return (
			<Form layout="vertical">
				<Form.Item label="Überschrift" {...attachFormItemErrorProps(error, 'title')}>
					<Input
						onKeyPress={this.handleKeyPressInput}
						placeholder={translations.title}
						value={title}
						onChange={(event) => this.changeField('title', event.target.value)}
					/>
				</Form.Item>
				<Form.Item label="Teasertext" {...attachFormItemErrorProps(error, 'description')}>
					<TextArea
						onKeyPress={this.handleKeyPressInput}
						placeholder={translations.description}
						value={description || ''}
						onChange={(event) => this.changeField('description', event.target.value)}
					/>
				</Form.Item>
				{/* eslint-disable-next-line jsx-a11y/label-has-for */}
				<label>Link</label>
				<Form.Item {...attachFormItemErrorProps(error, 'link.url')}>
					<Input
						onKeyPress={(event) => this.handleKeyPressInput(event)}
						placeholder={translations.url}
						value={url ?? undefined}
						onChange={(event) => this.handleLinkChange('url', event.target.value)}
					/>
				</Form.Item>
				<Form.Item {...attachFormItemErrorProps(error, 'link.label')}>
					<Input
						onKeyPress={(event) => this.handleKeyPressInput(event)}
						placeholder={translations.label}
						value={label ?? undefined}
						onChange={(event) => this.handleLinkChange('label', event.target.value)}
					/>
				</Form.Item>
			</Form>
		);
	}

	renderForm() {
		const { type } = this.props.creator.data;

		if (type === TYPE_BOARD) {
			return this.renderBoardForm(this.props.creator.data);
		}
		if (type === TYPE_ARTICLE) {
			return this.renderArticleForm(this.props.creator.data);
		}
		if (type === TYPE_AUTHOR) {
			return this.renderAuthorForm(this.props.creator.data);
		}
		if (type === TYPE_TEASER) {
			return this.renderTeaserForm(this.props.creator.data);
		}

		return <TypePicker onChange={(changedType) => this.changeField('type', changedType)} />;
	}

	render() {
		const { error } = this.state;
		const { isVisible } = this.props.creator;
		const { type } = this.props.creator.data;

		return (
			<Modal
				title={this.returnTitle(type)}
				open={isVisible}
				onCancel={this.handleCancel}
				closable={true}
				width={800}
				footer={[
					<Button onClick={this.handleCancel} key="cancel">
						{translations.cancel}
					</Button>,
					<Button
						key="create"
						disabled={error !== null}
						onClick={this.handleSave}
						type="primary"
						htmlType="submit"
					>
						{translations.create}
					</Button>,
				]}
			>
				{this.renderForm()}
			</Modal>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		creator: state.creator,
	}),
	{ hide, reset, update }
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(CreatorWithUser));
