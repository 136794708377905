import { Row, Col } from 'antd';
import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { createPaginationContainer, graphql, type RelayPaginationProp } from 'react-relay';
import { type RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import routeConfig from '../../../config/config.routes';
import ProfileImageFallback from '../../../images/profile-placeholder.png';
import globalStyles from '../../../styles';
import AuthorsListItemLoggedInUserContainer from '../AuthorLoggedInUserContainer';

import AuthorsListItem from './AuthorsListItem';
import type { AuthorFilter as LoggedInUserFilterType } from './__generated__/AuthorsListQuery.graphql';
import type { AuthorsList_query$data } from './__generated__/AuthorsList_query.graphql';

const AuthorList = styled.div`
	margin-top: 2.2rem;
	margin-right: -2.5rem;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
`;

const EmptyAuthorsListItem = styled.div`
	width: 250px;
	flex: 1 1 250px;
	height: 0;
	margin: 0 2.5rem 0 0;
`;

interface Props extends RouteComponentProps {
	relay: RelayPaginationProp;
	query: AuthorsList_query$data;
	loggedInUserFilter: LoggedInUserFilterType;
}

class AuthorsList extends Component<Props> {
	onHandleLoadMore = () => {
		this.props.relay.loadMore(AuthorsList.LOAD_MORE_NUM);
	};

	// eslint-disable-next-line react/no-array-index-key
	addFlexFillItems = () =>
		[...Array(5)].map((_elem, index) => <EmptyAuthorsListItem key={index} />);

	handleClickEdit = (guid: string | undefined) => {
		if (guid) {
			this.props.history.push(`${routeConfig.authors}/${guid}`);
		}
	};

	static LOAD_MORE_NUM = 25;

	render() {
		const { relay, query, loggedInUserFilter } = this.props;
		const edges = query?.authors?.edges || [];

		return (
			<Row className={globalStyles.mtM}>
				<Col span={24}>
					<InfiniteScroll hasMore={relay.hasMore()} loadMore={this.onHandleLoadMore}>
						<AuthorList>
							<AuthorsListItemLoggedInUserContainer loggedInUserFilter={loggedInUserFilter} />
							{edges.map(({ node }) =>
								node ? (
									<AuthorsListItem
										key={node.guid}
										firstname={node.firstname ?? ''}
										lastname={node.lastname ?? ''}
										isLdapUser={node.isLdapUser ?? false}
										isActive={node.isActive ?? false}
										profilePicture={node.profilePicture || ProfileImageFallback}
										handleOnClick={() => this.handleClickEdit(node?.guid)}
									/>
								) : null
							)}
							{this.addFlexFillItems()}
						</AuthorList>
					</InfiniteScroll>
				</Col>
			</Row>
		);
	}
}

export default createPaginationContainer(
	withRouter(AuthorsList),
	{
		query: graphql`
			fragment AuthorsList_query on Query {
				authors: allAuthors(
					first: $count
					after: $cursor
					filter: $allAuthorsFilter
					orderBy: LASTNAME_ASC
				) @connection(key: "AuthorsList_authors") {
					totalCount
					pageInfo {
						hasNextPage
						startCursor
						endCursor
					}
					edges {
						node {
							guid
							firstname
							lastname
							profilePicture
							isActive
							isLdapUser
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps(props) {
			return props.query && props.query.authors;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			};
		},
		getVariables(_props, { count, cursor }, fragmentVariables) {
			return {
				...fragmentVariables,
				count,
				cursor,
			};
		},
		query: graphql`
			query AuthorsListQuery($count: Int!, $cursor: Cursor, $allAuthorsFilter: AuthorFilter) {
				...AuthorsList_query
			}
		`,
	}
);
