import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from './List.module.scss';

type Props = {
	className?: string;
	bordered?: boolean;
	children?: ReactNode;
};

export default function List({ className, children, bordered = true, ...rest }: Props) {
	return (
		<ul className={classNames(styles.list, className, { [styles.bordered]: bordered })} {...rest}>
			{children}
		</ul>
	);
}
