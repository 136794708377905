import {
	COLOR_SUN_FLOWER,
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
} from '@sep/br24-constants';
import Button from 'antd/lib/button';
import type { ComponentProps, ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

import config from '../../../config';
import { media } from '../../../util/styled';

export type Status =
	| typeof STATUS_DELETED
	| typeof STATUS_DEPUBLISHED
	| typeof STATUS_DRAFT
	| typeof STATUS_PUBLISHED
	| typeof STATUS_REVIEW
	| typeof STATUS_SCHEDULED;

type DropdownButtonProps = ComponentProps<typeof Button> & {
	$isTemplate: boolean;
	$status: Status | null | undefined;
	children?: ReactNode;
};

function getStatusColor(status: Status | null | undefined) {
	if (status) {
		return config.statusTagColorMap[status];
	}
	return undefined;
}

export const DropdownButton = styled<ComponentType>(Button)<DropdownButtonProps>`
	padding: 0 10px;
	color: ${(props) => props.theme?.colors.white} !important;
	&:hover,
	&:focus {
		filter: brightness(115%);
	}
	&:active {
		filter: brightness(85%);
	}
	background-color: ${({ $isTemplate, $status }) =>
		$isTemplate ? COLOR_SUN_FLOWER : getStatusColor($status)} !important;
	border-color: ${({ $isTemplate, $status }) =>
		$isTemplate ? COLOR_SUN_FLOWER : getStatusColor($status)} !important;

	${media.lessThan('medium')`
		.title {
			display: none;
		}
	`};
	&:hover {
		.title {
			display: block;
		}
	}
`;
