import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'react-relay';

import type { DeleteBoardSectionMutation as DeleteBoardSectionMutationTypes } from './__generated__/DeleteBoardSectionMutation.graphql';

const mutation = graphql`
	mutation DeleteBoardSectionMutation($input: DeleteBoardsSectionByRowIdInput!) {
		deleteBoardsSectionByRowId(input: $input) {
			boardsSection {
				rowId
				schema
				categoryId
				title
				color
				autofill
				meta
				order
				showHeroTitleForMobile
				updatedBy
				updatedAt
				createdBy
				createdAt
			}
		}
	}
`;

export default function DeleteBoardSectionMutation(
	boardSectionRowId: string,
	environment: Environment
): Promise<DeleteBoardSectionMutationTypes['response']> {
	return new Promise((resolve, reject) => {
		commitMutation<DeleteBoardSectionMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					rowId: boardSectionRowId,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
}
