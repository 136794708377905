import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'react-relay';

import type { BoardSectionSchema } from '../store/reducers/boardBuffer';

import type { CreateBoardSectionMutation as CreateBoardSectionMutationTypes } from './__generated__/CreateBoardSectionMutation.graphql';

const mutation = graphql`
	mutation CreateBoardSectionMutation($input: CreateBoardsSectionInput!) {
		createBoardsSection(input: $input) {
			boardsSection {
				rowId
				schema
				categoryId
				title
				color
				autofill
				meta
				order
				showHeroTitleForMobile
				updatedBy
				updatedAt
				createdBy
				createdAt
			}
		}
	}
`;

export type CreateBoardSectionInput = {
	boardId: string;
	schema: BoardSectionSchema;
	categoryId?: string | null;
	title?: string | null;
	color?: string | null;
	autofill?: boolean;
	meta?: string | null;
	order: number;
	showHeroTitleForMobile?: boolean | null;
};

export type CreateBoardSectionOutput = NonNullable<
	NonNullable<CreateBoardSectionMutationTypes['response']>['createBoardsSection']
>['boardsSection'];

const execute = (
	input: CreateBoardSectionInput,
	environment: Environment
): Promise<CreateBoardSectionMutationTypes['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<CreateBoardSectionMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					boardsSection: input,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});

export default async function CreateBoardSectionMutation(
	input: CreateBoardSectionInput,
	environment: Environment
): Promise<CreateBoardSectionOutput> {
	const res = await execute(input, environment);
	if (!res || !res.createBoardsSection || !res.createBoardsSection.boardsSection) {
		throw new Error('Something went wrong');
	}
	return res.createBoardsSection.boardsSection;
}
