import { STATUS_PUBLISHED } from '@sep/br24-constants';
import * as util from '@sep/br24-util';
import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import type { ItemBoard } from '..';
import environment from '../../../environment';
import PlaceholderImage from '../../../images/placeholder.png';
import { AssetManagerItemBoard as Wrapper } from '../../../ui/AssetManager';

import type { AssetManagerItemBoardQuery } from './__generated__/AssetManagerItemBoardQuery.graphql';

const SpinWrapper = styled.div`
	padding: 10px;
`;

interface Props {
	item: ItemBoard;
}

// * These Item components are used both in AssetManagerSpace to re-render them, and in ItemGroupResults to show the preview without an additional query
function AssetManagerItemBoard(props: Props) {
	return (
		<>
			{props.item.title ? (
				<Wrapper
					status={props.item.status}
					title={props.item.title}
					image={
						props.item.image
							? util.image.resize(props.item.image, 'landscape', 's')
							: PlaceholderImage
					}
					isPublished={props.item.isPublished}
					publicationDate={props.item.publicationDate}
				/>
			) : (
				<AssetManagerItemBoardRenderer {...props} />
			)}
		</>
	);
}

function AssetManagerItemBoardRenderer(props: Props) {
	const translate = useTranslate();
	return (
		<QueryRenderer<AssetManagerItemBoardQuery>
			environment={environment}
			query={graphql`
				query AssetManagerItemBoardQuery($boardId: String!) {
					board: boardByRowId(rowId: $boardId) {
						rowId
						title
						status
						image {
							url
						}
					}
				}
			`}
			variables={{ boardId: props.item.boardId }}
			render={({ error, props: result }) => {
				const hasError = !!error;
				const isReady = error === null && result;

				if (hasError) {
					return <Alert message={translate('error')} type="error" />;
				}

				if (!isReady) {
					return (
						<SpinWrapper>
							<Spin size="large" />
						</SpinWrapper>
					);
				}

				const title = result?.board?.title;
				const teaserImage = result?.board?.image?.url;
				const status = result?.board?.status;

				const image = teaserImage
					? util.image.resize(teaserImage, 'landscape', 's')
					: PlaceholderImage;

				return (
					<Wrapper
						title={title}
						image={image}
						isPublished={status === STATUS_PUBLISHED}
						status={status}
					/>
				);
			}}
		/>
	);
}

export default AssetManagerItemBoard;
