import { fetchQuery, graphql } from 'react-relay';

import environment from '../../../../environment';

import type { fetchBroadcastChannelsQuery } from './__generated__/fetchBroadcastChannelsQuery.graphql';

const query = graphql`
	query fetchBroadcastChannelsQuery {
		viewer {
			allLivestreams {
				count
				edges {
					node {
						... on MangoLivestream {
							labels
							id
							broadcastedBy {
								id
							}
						}
					}
				}
			}
		}
	}
`;

export default function fetchBroadcastChannels(): Promise<Array<any>> {
	return fetchQuery<fetchBroadcastChannelsQuery>(environment, query, {})
		.toPromise()
		.then((res) => (res?.viewer?.allLivestreams?.edges ?? []).map((edge) => edge?.node));
}
