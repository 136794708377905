import {
	LOCATION_CITY,
	LOCATION_COUNTRY,
	LOCATION_DISTRICT,
	LOCATION_STATE,
	LOCATION_STREET,
	LOCATION_STREETNUMBER,
	LOCATION_SUBURB,
	LOCATION_ZIPCODE,
} from '@sep/br24-constants';
import { Col, Row, Spin } from 'antd';
import GoogleMap, { type ChangeEventValue, type Coords, type Point } from 'google-map-react';
import { Component } from 'react';
import styled from 'styled-components';

import config from '@/client/config';
import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../translation';
import { Close } from '../Icon';

import MapMarker from './MapMarker';
import { MapSearchBox } from './MapSearchBox';

const availableLocationMeta = [
	LOCATION_DISTRICT,
	LOCATION_ZIPCODE,
	LOCATION_STREET,
	LOCATION_STREETNUMBER,
	LOCATION_COUNTRY,
	LOCATION_STATE,
	LOCATION_CITY,
	LOCATION_SUBURB,
];

/**
 * Location of the BR headquarter.
 */
const DEFAULT_CENTER = { lat: 48.1443593, lng: 11.55383 };

export const GOOGLE_MAP_KEYS: GoogleMap.BootstrapURLKeys = {
	key: config.VITE_GOOGLE_MAPS_API_KEY,
	language: 'de',
	region: 'DE',
	libraries: ['places'],
};

const Spinner = styled(Spin)`
	margin-bottom: 1rem !important;
`;

const Wrapper = styled.div`
	width: 100%;
`;

const MapBox = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	background-color: ${(props) => props.theme.colors.light1};
	padding: 0.4rem;
`;

const MapBoxHeader = styled.div`
	width: 100%;
	display: flex;
`;

const MapBoxTitle = styled.h2`
	display: flex;
	flex: 1;
	margin-left: 0.4rem;
	font-size: 1.6rem;
`;

const MetaWrapper = styled.div`
	width: 100%;
	margin-left: 0.4rem;
	margin-bottom: 0.8rem;
`;

const MapWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 300px;
	overflow: hidden;
	background-color: white;
	padding: 0.4rem;
`;

const MapRoundingWrapper = styled.div`
	flex: 1;
	border-radius: 4px;
	overflow: hidden;
`;

type CloseIconProps = {
	className?: string;
	onClick: () => void;
};

const CloseIcon = styled(({ className, onClick }: CloseIconProps) => (
	<button className={className} onClick={onClick}>
		<Close />
	</button>
))`
	font-size: 1.6em;
	color: ${(props) => props.theme.colors.br24Light};
`;

export interface LocationMeta {
	city?: string;
	country?: string;
	district?: string;
	state?: string;
	street?: string;
	streetNumber?: string;
	suburb?: string;
	zipCode?: string;
}

export type MapChangeEvent = {
	point: Point | undefined | null;
	selectedLocationMeta: LocationMeta | undefined | null;
};

type Props = WithTranslationProps & {
	defaultCenter?: Coords;
	defaultSelectedLocation?: Coords;
	defaultSelectedLocationMeta?: LocationMeta | null;
	defaultZoom?: number;
	isSearchDisabled?: boolean;
	onChange: (event: MapChangeEvent) => void;
};

type State = {
	center: Coords | undefined;
	isDraggable: boolean;
	isLoadingMeta: boolean;
	selectedLocation: Coords | undefined | null;
	selectedLocationMeta: LocationMeta | undefined | null;
	zoom: number;
	maps: typeof google.maps | undefined;
	map: google.maps.Map | undefined;
};

class MapComponent extends Component<Props, State> {
	private geocoder: google.maps.Geocoder | undefined;

	constructor(props: Props) {
		super(props);
		this.state = {
			center: props.defaultCenter,
			isDraggable: true,
			isLoadingMeta: false,
			selectedLocation: props.defaultSelectedLocation || null,
			selectedLocationMeta: props.defaultSelectedLocationMeta || null,
			zoom: props.defaultZoom || 14,
			maps: undefined,
			map: undefined,
		};
	}

	onMapInitialized = ({
		maps,
		map,
	}: {
		map: google.maps.Map;
		maps: typeof google.maps;
		ref: Element | null;
	}) => {
		if (maps?.Geocoder) {
			this.geocoder = new maps.Geocoder();
		}
		this.setState({ maps, map });
	};

	getLocationMeta = (addressComponents: google.maps.GeocoderAddressComponent[]): LocationMeta => ({
		[LOCATION_CITY]: this.extractAddressComponent(addressComponents, 'locality'),
		[LOCATION_COUNTRY]: this.extractAddressComponent(addressComponents, 'country'),

		[LOCATION_DISTRICT]: this.extractAddressComponent(
			addressComponents,
			'administrative_area_level_2'
		),

		[LOCATION_STATE]: this.extractAddressComponent(
			addressComponents,
			'administrative_area_level_1'
		),

		[LOCATION_STREET]: this.extractAddressComponent(addressComponents, 'route'),
		[LOCATION_STREETNUMBER]: this.extractAddressComponent(addressComponents, 'street_number'),
		[LOCATION_SUBURB]: this.extractAddressComponent(addressComponents, 'sublocality_level_1'),
		[LOCATION_ZIPCODE]: this.extractAddressComponent(addressComponents, 'postal_code'),
	});

	extractAddressComponent = (
		data: google.maps.GeocoderAddressComponent[],
		type: string
	): string | undefined => {
		const componentOfType = data.find((component) => component.types[0] === type);

		return componentOfType?.long_name;
	};

	geocoderCallback = (
		results: google.maps.GeocoderResult[] | null,
		status: google.maps.GeocoderStatus
	) => {
		let selectedLocationMeta: LocationMeta | null = null;
		if (status === 'OK') {
			if (Array.isArray(results) && results.length > 0 && results[0].address_components) {
				selectedLocationMeta = this.getLocationMeta(results[0].address_components);
			}
		} else {
			// eslint-disable-next-line no-console
			console.warn(`Geocoder failed due to: ${status}`);
		}
		this.setState({ isLoadingMeta: false, selectedLocationMeta });
		this.props.onChange({
			point: { x: this.state.selectedLocation?.lat ?? 0, y: this.state.selectedLocation?.lng ?? 0 },
			selectedLocationMeta,
		});
	};

	handleChangeLocation = (place: google.maps.places.PlaceResult) => {
		if (!place.geometry) {
			return;
		}

		const { location } = place.geometry;

		if (!location || !place.address_components) {
			return;
		}

		const currentLocation = location.toJSON();

		const selectedLocationMeta = this.getLocationMeta(place.address_components);

		this.setState({
			center: currentLocation,
			selectedLocation: currentLocation,
			selectedLocationMeta,
		});

		this.props.onChange({
			point: { x: currentLocation.lat, y: currentLocation.lng },
			selectedLocationMeta,
		});
	};

	handleChangeMap = ({ center, zoom }: ChangeEventValue) => this.setState({ center, zoom });

	handleChildMouseDown = () => {
		this.setState({ isDraggable: false });
	};

	handleChildMouseMove = (_key: any, _marker: any, newCoords: Coords) => {
		this.setState({
			selectedLocation: newCoords,
		});
	};

	handleChildMouseUp = () => {
		this.setState({ isDraggable: true, isLoadingMeta: true }, () => {
			if (this.geocoder && this.geocoder.geocode && this.state.selectedLocation) {
				this.geocoder.geocode(
					{
						location: this.state.selectedLocation,
					},
					this.geocoderCallback
				);
			}
		});
	};

	handleDeleteLocation = () => {
		this.setState({
			selectedLocation: null,
			selectedLocationMeta: null,
		});
		this.props.onChange({
			point: null,
			selectedLocationMeta: null,
		});
	};

	renderLocationMeta() {
		const { selectedLocationMeta, isLoadingMeta } = this.state;
		const {
			translation: { translate },
		} = this.props;
		return isLoadingMeta ? (
			<Spinner />
		) : (
			<MetaWrapper>
				{availableLocationMeta.map(
					(key) =>
						selectedLocationMeta &&
						selectedLocationMeta[key] && (
							<Row key={key}>
								<Col span={10}>
									<b>{translate(`locations.${key}`)}</b>
								</Col>
								<Col span={14}>{selectedLocationMeta[key]}</Col>
							</Row>
						)
				)}
			</MetaWrapper>
		);
	}

	render() {
		const { center, zoom, selectedLocation, selectedLocationMeta, isDraggable } = this.state;

		const {
			isSearchDisabled,
			translation: { translate },
		} = this.props;

		return (
			<Wrapper>
				{isSearchDisabled ? null : <MapSearchBox onChange={this.handleChangeLocation} />}
				{selectedLocation || selectedLocationMeta ? (
					<MapBox>
						<MapBoxHeader>
							<MapBoxTitle>{translate('map.title')}</MapBoxTitle>
							<CloseIcon onClick={this.handleDeleteLocation} />
						</MapBoxHeader>
						{selectedLocationMeta ? this.renderLocationMeta() : null}
						{selectedLocation ? (
							<MapWrapper>
								<MapRoundingWrapper>
									<GoogleMap
										bootstrapURLKeys={GOOGLE_MAP_KEYS}
										defaultCenter={DEFAULT_CENTER}
										center={center}
										zoom={zoom}
										draggable={isDraggable}
										onChange={this.handleChangeMap}
										onChildMouseDown={this.handleChildMouseDown}
										onChildMouseMove={this.handleChildMouseMove}
										onChildMouseUp={this.handleChildMouseUp}
										yesIWantToUseGoogleMapApiInternals={true}
										onGoogleApiLoaded={this.onMapInitialized}
									>
										<MapMarker lat={selectedLocation.lat} lng={selectedLocation.lng} />
									</GoogleMap>
								</MapRoundingWrapper>
							</MapWrapper>
						) : null}
					</MapBox>
				) : null}
			</Wrapper>
		);
	}
}
export const Map = withTranslation(MapComponent);
