import classNames from 'classnames';
import type { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
	className?: string;
	src?: string;
	children?: ReactNode;
}

const SVG = styled(({ src, className, children }: Props) => {
	if (src) {
		return (
			<i dangerouslySetInnerHTML={{ __html: src }} className={classNames('svg', className)}>
				{children}
			</i>
		);
	} else {
		return <i className={classNames('svg', className)}>{children}</i>;
	}
})`
	display: inline-block;
`;

export default SVG;
