import type { ItemGallery } from '..';
import { AssetManagerItemGallery as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemGallery;
};

function AssetManagerItemGallery({ item }: Props) {
	return <Wrapper images={item.gallery ?? []} />;
}

export default AssetManagerItemGallery;
