import { DISTRICTS_ALL } from '@sep/br24-constants';
import { Form, Select, Card } from 'antd';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { upperCaseFirst } from '@/client/util/br24Utils';
import type { District } from '@/types/schema';

import {
	provideSectionValidationById,
	provideMetaBySectionId,
	updateSectionMeta,
} from '../../../../store/reducers/boardBuffer';
import gs from '../../../../styles';
import attachFormItemErrorProps from '../../../../util/attachFormItemErrorProps';

const { Option } = Select;

interface Props extends ReduxProps, OwnProps {}

function MapItem({ district, validation, updateDistrict }: Props) {
	return (
		<Card title="Karte">
			<p className={gs.mbM}>
				Hier kannst Du den aktiven Bezirk Bayerns auswählen. Dieser wird auf der Webseite
				hervorgehoben und dient dem Besucher als Einstiegshilfe in die einzelnen Regionen.
			</p>
			<Form.Item className={gs.mb0} {...attachFormItemErrorProps(validation, 'meta.district')}>
				<Select
					allowClear={true}
					className={gs.fullWidth}
					defaultValue={district}
					placeholder="Bezirk auswählen"
					onChange={updateDistrict}
				>
					<Option key="none" value={null}>
						Kein Bezirk
					</Option>
					{DISTRICTS_ALL.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Option key={index} value={item}>
							{upperCaseFirst(item.toLowerCase())}
						</Option>
					))}
				</Select>
			</Form.Item>
		</Card>
	);
}

interface OwnProps {
	sectionInternalId: string;
}

const connector = connect(
	({ boardBuffer }: ReduxState, { sectionInternalId }: OwnProps) => ({
		validation: provideSectionValidationById(boardBuffer, sectionInternalId),
		district: provideMetaBySectionId(boardBuffer, sectionInternalId, 'district'),
	}),
	(dispatch, { sectionInternalId }: OwnProps) => ({
		updateDistrict: (district: District) =>
			dispatch(updateSectionMeta(sectionInternalId, { district })),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MapItem);
