/**
 * @generated SignedSource<<346be6e550db28378eac2f1cc361d6ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthorRendererQuery$variables = {
  guid: string;
};
export type AuthorRendererQuery$data = {
  readonly author: {
    readonly firstname: string | null;
    readonly isLdapUser: boolean | null;
    readonly jobTitle: string | null;
    readonly lastname: string | null;
  } | null;
};
export type AuthorRendererQuery = {
  response: AuthorRendererQuery$data;
  variables: AuthorRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "guid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "guid",
    "variableName": "guid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLdapUser",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorRendererQuery",
    "selections": [
      {
        "alias": "author",
        "args": (v1/*: any*/),
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "authorByGuid",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorRendererQuery",
    "selections": [
      {
        "alias": "author",
        "args": (v1/*: any*/),
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "authorByGuid",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ae5994860388a39d551f29634ea66e4",
    "id": null,
    "metadata": {},
    "name": "AuthorRendererQuery",
    "operationKind": "query",
    "text": "query AuthorRendererQuery(\n  $guid: String!\n) {\n  author: authorByGuid(guid: $guid) {\n    firstname\n    lastname\n    isLdapUser\n    jobTitle\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "57175a237dcb419e39f21238994051f8";

export default node;
