import { useContext } from 'react';

import { translationContext } from './Context';

/**
 * Returns the translate function to translate i18n texts for use within
 * functional components.
 *
 * Example:
 * ```
 * const translate = useTranslate();
 * ...
 * <span>{translate('path.to.translate.key')}</span>
 * ...
 * ```
 */
export function useTranslate() {
	return useContext(translationContext).translate;
}
