import UUID from 'uuid-js';

import { AM_PERSONAL_SPACE } from '@/client/constants';
import type { AppThunkAction } from '@/client/store/redux';

import type { AddItemsOptions, AddItemsAction, Space, Item, RawItem } from '..';
import { ADD_ITEMS } from '../actionTypes';
import { addItemToSpace } from '../database';
import { assetManagerStatusSelector } from '../selectors';

function isItem(item: Item | RawItem): item is Item {
	return item.hasOwnProperty('id');
}

export function addItems(
	space: Space,
	items: Array<Item | RawItem>,
	{ persist = true }: Partial<AddItemsOptions> = {}
): AppThunkAction<AddItemsAction> {
	return (dispatch, getState) => {
		const preparedItems: Item[] = items.map((item) => {
			if (isItem(item)) {
				return { ...item };
			} else {
				return {
					...item,
					id: UUID.create().toString(),
					updatedAt: new Date().toISOString(),
					createdAt: new Date().toISOString(),
					isLockedForDeletion: false,
				};
			}
		});

		const currentSpace = getState().assetManager.spaces[space];
		const knownItems = Object.keys(currentSpace);

		const { isAbleToSendToAssetManager, activeGlobalGroup, isInGlobalGroup } =
			assetManagerStatusSelector(getState());

		if (!isAbleToSendToAssetManager) {
			return;
		}

		dispatch({
			type: ADD_ITEMS,
			payload: {
				space,
				activeGlobalGroup: activeGlobalGroup?.id ?? null,
				isInGlobalGroup,
				items: preparedItems.filter((item) => !knownItems.includes(item.id)),
			},
		});

		if (persist) {
			for (const item of preparedItems) {
				if (space === AM_PERSONAL_SPACE) {
					addItemToSpace(space, item);
				} else if (activeGlobalGroup?.id) {
					addItemToSpace(space, item, activeGlobalGroup.id);
				}
			}
		}
	};
}
