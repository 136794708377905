import { STATUS_PUBLISHED } from '@sep/br24-constants';
import * as util from '@sep/br24-util';
import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import type { ItemBoardTeaser } from '..';
import environment from '../../../environment';
import PlaceholderImage from '../../../images/placeholder.png';
import { AssetManagerItemBoardTeaser as Wrapper } from '../../../ui/AssetManager';

import type { AssetManagerItemBoardTeaserQuery } from './__generated__/AssetManagerItemBoardTeaserQuery.graphql';

const SpinWrapper = styled.div`
	padding: 10px;
`;

type Props = {
	item: ItemBoardTeaser;
};

function AssetManagerItemBoardTeaser(props: Props) {
	const translate = useTranslate();

	return (
		<QueryRenderer<AssetManagerItemBoardTeaserQuery>
			environment={environment}
			query={graphql`
				query AssetManagerItemBoardTeaserQuery($boardsTeaserId: String!) {
					teaser: boardsTeaserByRowId(rowId: $boardsTeaserId) {
						rowId
						title
						status
						image {
							url
						}
					}
				}
			`}
			variables={{ boardsTeaserId: props.item.boardTeaserId }}
			render={({ error, props: result }) => {
				const hasError = !!error;
				const isReady = error === null && result;

				if (hasError) {
					return <Alert message={translate('error')} type="error" />;
				}

				if (!isReady) {
					return (
						<SpinWrapper>
							<Spin size="large" />
						</SpinWrapper>
					);
				}

				const title = result?.teaser?.title;
				const teaserImage = result?.teaser?.image?.url;
				const status = result?.teaser?.status;

				return (
					<Wrapper
						title={title ?? ''}
						image={
							teaserImage ? util.image.resize(teaserImage, 'landscape', 's') : PlaceholderImage
						}
						isPublished={status === STATUS_PUBLISHED}
					/>
				);
			}}
		/>
	);
}

export default AssetManagerItemBoardTeaser;
