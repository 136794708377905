import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button, Card, Alert } from 'antd';

import { useTranslate } from '@/client/translation/useTranslate';
import { filterNullOrUndefined } from '@/client/util/filter';

import PieChartRegions from './PieChartRegions';
import RegionStatsContainer from './RegionStatsContainer';

type Props = {
	startDate: moment.Moment;
	endDate: moment.Moment;
};

export function RegionStats({ startDate, endDate }: Props) {
	const translate = useTranslate();

	return (
		<RegionStatsContainer
			startDate={startDate.toISOString()}
			endDate={endDate.toISOString()}
			render={({ error, props }) => {
				const stats = (props?.stats?.nodes || []).filter(filterNullOrUndefined);

				if (error || typeof stats === 'undefined') {
					return <Alert message={translate('fatalError.title')} type="error" />;
				}

				return (
					<Card
						title={translate('dashboard.regionsArticleStats')}
						loading={error === null && props === null}
						extra={
							<Tooltip title={translate('dashboard.regionsArticleStatsHelp')}>
								<Button shape="circle" size="small" icon={<QuestionCircleOutlined />} />
							</Tooltip>
						}
					>
						{stats && stats.length > 0 ? (
							<PieChartRegions data={stats} />
						) : (
							<p>{translate('dashboard.noDataSelectedDateRange')}</p>
						)}
					</Card>
				);
			}}
		/>
	);
}

RegionStats.displayName = 'RegionStats';
