import { Form, AutoComplete } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';
import GoogleMap from 'google-map-react';
import { useState, useEffect, useRef, useCallback } from 'react';

import { useTranslate } from '@/client/translation/useTranslate';

import { GOOGLE_MAP_KEYS } from './Map';

interface Props {
	onChange: (place: google.maps.places.PlaceResult) => void;
}

export function MapSearchBox(props: Props) {
	const [hasError, setHasError] = useState(false);
	const [searchResults, setSearchResults] = useState<DefaultOptionType[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const translate = useTranslate();

	const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | null>(null);
	const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);
	const googleAttribuitionRef = useRef<HTMLDivElement | null>(null);

	const { onChange } = props;

	useEffect(() => {
		async function initializeGooleMap() {
			// we use a hidden property of the GoogleMaps component class to initialize maps API so that
			// initialization only takes place once
			const maps: typeof google.maps = await (GoogleMap as any).googleMapLoader(GOOGLE_MAP_KEYS);
			if (maps && googleAttribuitionRef.current) {
				autocompleteServiceRef.current = new maps.places.AutocompleteService();
				placesServiceRef.current = new maps.places.PlacesService(googleAttribuitionRef.current);
			}
		}

		initializeGooleMap();

		return () => {
			autocompleteServiceRef.current = null;
			placesServiceRef.current = null;
		};
	});

	const handleSearch = async (searchTerm: string) => {
		setSearchValue(searchTerm);

		if (autocompleteServiceRef.current) {
			const results = await autocompleteServiceRef.current.getPlacePredictions({
				input: searchTerm,
			});

			if (results) {
				setSearchResults(
					results.predictions.map(({ place_id, description }) => ({
						value: place_id,
						label: description,
					}))
				);
			} else {
				setHasError(true);
			}
		}
	};

	const handlePlaceSelect = useCallback(
		async (placeId: string) => {
			placesServiceRef.current?.getDetails({ placeId: placeId }, (place, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK && place?.address_components) {
					onChange(place);
					setHasError(false);
					setSearchValue('');
				} else {
					setHasError(true);
				}
				setSearchResults([]);
			});
		},
		[onChange]
	);

	return (
		<Form.Item
			hasFeedback={hasError}
			validateStatus={hasError ? 'error' : 'success'}
			help={hasError ? translate('map.searchError') : ''}
		>
			<AutoComplete
				placeholder={translate('map.searchPlaceholder')}
				value={searchValue}
				onSearch={handleSearch}
				onSelect={handlePlaceSelect}
				options={searchResults}
				data-testid="map-autocomplete"
			/>
			<div ref={googleAttribuitionRef} />
		</Form.Item>
	);
}
