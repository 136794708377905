import { DatePicker, Layout, Row, Col, Space } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { withTranslation } from '@/client/translation/withTranslation';

import styles from '../../styles';
import type { TranslationContext } from '../../translation';

import CategoryArticleStats from './CategoryArticleStats';
import DataFilter from './DataFilter';
import LatestPublication from './LatestPublication';
import LatestPushNotifications from './LatestPushNotifications';
import ModuleAllocationStats from './ModuleAllocationStats';
import type { District } from './ModuleAllocationStats/__generated__/ModuleAllocationStatsContainerQuery.graphql';
import MyLatestArticles from './MyLatestArticles';
import { RegionStats } from './RegionStats';
import SourceOriginReferenceStats from './SourceOriginReferenceStats';

const { RangePicker } = DatePicker;
const { Content } = Layout;

type Props = {
	translation: TranslationContext;
};

export type CurrentFilterType = {
	districtsFilter?: Array<District> | null;
	categoryFilter?: string;
} | null;

type State = {
	startDate: moment.Moment;
	endDate: moment.Moment;
	selectedFilterValue: string | null;
	selectedFilterText: string | null;
	currentFilter: CurrentFilterType;
};

export class Dashboard extends Component<Props, State> {
	state = {
		startDate: moment().startOf('day'),
		endDate: moment(),
		currentFilter: null,
		selectedFilterValue: null,
		selectedFilterText: null,
	};

	handleChangeDate = (dateChangeEvent: [moment.Moment | null, moment.Moment | null] | null) => {
		const { startDate, endDate } = this.state;

		if (!dateChangeEvent) {
			return;
		}

		const [nextStartDate, nextEndDate] = dateChangeEvent;

		if (
			(startDate === nextStartDate && endDate === nextEndDate) ||
			typeof nextStartDate === 'undefined' ||
			typeof nextEndDate === 'undefined'
		) {
			return;
		}

		if (nextStartDate && nextEndDate) {
			this.setState({
				startDate: nextStartDate.startOf('day'),
				endDate: nextEndDate.endOf('day'),
			});
		}
	};

	handleChangeFilter = (filter: CurrentFilterType, selectedValue: string, selectedText: string) => {
		this.setState({
			currentFilter: filter,
			selectedFilterValue: selectedValue,
			selectedFilterText: selectedText,
		});
	};

	handleResetFilter = () => {
		if (this.state.currentFilter) {
			this.setState({
				currentFilter: null,
				selectedFilterValue: null,
				selectedFilterText: null,
			});
		}
	};

	render() {
		const {
			translation: { translate },
		} = this.props;
		const { startDate, endDate, currentFilter, selectedFilterValue, selectedFilterText } =
			this.state;

		return (
			<>
				<Helmet>
					<title>{translate('dashboard.title')}</title>
				</Helmet>

				<Layout>
					<Content className={classNames(styles.pM, styles.highlightedCard)}>
						<Row gutter={[24, 24]}>
							<Col md={12} lg={8}>
								<MyLatestArticles />
							</Col>
							<Col md={12} lg={8}>
								<LatestPublication />
							</Col>
							<Col md={12} lg={8}>
								<LatestPushNotifications />
							</Col>
						</Row>
						<Row
							align="top"
							className={classNames(styles.toolbar, styles.toolbarDashboard)}
							gutter={24}
						>
							<Col span={8}>
								<h2>{`${translate('dashboard.statistics')} - ${
									selectedFilterText || translate('dashboard.filter.headlineTotal')
								}`}</h2>
							</Col>
							<Col span={16}>
								<Space align="start" style={{ display: 'flex', justifyContent: 'end' }}>
									<DataFilter
										selectedValue={selectedFilterValue || undefined}
										onChange={this.handleChangeFilter}
										onResetFilter={this.handleResetFilter}
									/>
									<RangePicker
										ranges={{
											Heute: [moment(), moment()],
											[translate('dashboard.thisMonth')]: [
												moment().startOf('month'),
												moment().endOf('month'),
											],
											[translate('dashboard.lastMonth')]: [
												moment().subtract(1, 'month').startOf('month').add(1, 'ms'),
												moment().subtract(1, 'month').endOf('month').subtract(1, 'ms'),
											],
											[translate('dashboard.aYearAgo', {
												info: `${moment().subtract(1, 'year').format('MMMM')} ${moment()
													.subtract(1, 'year')
													.format('YYYY')}`,
											})]: [
												moment().subtract(1, 'year').startOf('month').add(1, 'ms'),
												moment().subtract(1, 'year').endOf('month').subtract(1, 'ms'),
											],
										}}
										defaultValue={[startDate, endDate]}
										format="DD.MM.YYYY"
										onChange={this.handleChangeDate}
										size="middle"
									/>
								</Space>
							</Col>
						</Row>
						<Row gutter={[24, 24]}>
							<Col md={12} lg={6}>
								<ModuleAllocationStats
									startDate={startDate}
									endDate={endDate}
									filter={currentFilter}
								/>
							</Col>
							<Col md={12} lg={6}>
								<SourceOriginReferenceStats
									startDate={startDate}
									endDate={endDate}
									filter={currentFilter}
								/>
							</Col>
							{selectedFilterValue ? null : (
								<Col md={12} lg={6}>
									<CategoryArticleStats startDate={startDate} endDate={endDate} />
								</Col>
							)}
							{selectedFilterValue ? null : (
								<Col md={12} lg={6}>
									<RegionStats startDate={startDate} endDate={endDate} />
								</Col>
							)}
						</Row>
						<Row
							style={{
								placeContent: 'center',
								padding: '0.5em',
								margin: '1.5em 0',
								backgroundColor: 'rgb(231 237 240)',
								borderRadius: 4,
							}}
						>
							<strong>Hinweis:</strong> Statistiken mit Regionalbezug berücksichtigen ausschließlich
							Artikel mit Primärkategorie &quot;Bayern&quot;.
						</Row>
					</Content>
				</Layout>
			</>
		);
	}
}

export default withTranslation(Dashboard);
