import {
	MODULE_TYPE_AUDIO,
	MODULE_TYPE_EMBED,
	MODULE_TYPE_GALLERY,
	MODULE_TYPE_IMAGE,
	MODULE_TYPE_TEXT,
	MODULE_TYPE_VIDEO,
	MODULE_TYPE_VIDEO360,
	MODULE_TYPE_LIVESTREAM,
} from '@sep/br24-constants';

import type {
	UnpersistedModule,
	Image,
	Audio,
	Gallery,
	Video,
	Video360,
	Embed,
	Livestream,
	RedirectionTemplate,
} from '.';

function getBaseModule(articleId: string | null | undefined, order: number) {
	return {
		__meta__: { created: true, deleted: false, updated: false },
		__validation__: null,
		articleId,
		audio: null,
		embed: null,
		image: null,
		gallery: null,
		order,
		text: null,
		video360: null,
		video: null,
		livestream: null,
	};
}

const baseImageModule: Image = {
	copyright: null,
	title: null,
	altText: null,
	url: null,
};

const baseAudioModule: Audio = {
	copyright: null,
	duration: 0,
	thumbnail: baseImageModule,
	title: null,
	url: null,
};

const baseGalleryModule: Gallery = [];

const baseVideoModule: Video = {
	duration: 0,
	thumbnail: baseImageModule,
	url: null,
	title: null,
	copyright: null,
};

const baseLivestreamModule: Livestream = {
	livestreamId: null,
	programmeId: null,
	thumbnail: baseImageModule,
};

const baseVideo360Module: Video360 = {
	title: null,
	copyright: null,
	duration: 0,
	sources: [],
	thumbnail: baseImageModule,
};

const baseEmbedModule: Embed = {
	service: null,
	source: null,
	url: null,
	altText: null,
	thumbnail: baseImageModule,
};

export const createModuleFromTemplate = (
	articleId: string | null | undefined,
	order: number,
	type: string
): UnpersistedModule => {
	switch (type) {
		case MODULE_TYPE_AUDIO: {
			return {
				type: MODULE_TYPE_AUDIO,
				...getBaseModule(articleId, order),
				audio: baseAudioModule,
				meta: null,
			};
		}

		case MODULE_TYPE_EMBED: {
			return {
				type: MODULE_TYPE_EMBED,
				...getBaseModule(articleId, order),
				embed: baseEmbedModule,
			};
		}

		case MODULE_TYPE_GALLERY: {
			return {
				type: MODULE_TYPE_GALLERY,
				...getBaseModule(articleId, order),
				gallery: baseGalleryModule,
			};
		}

		case MODULE_TYPE_IMAGE: {
			return {
				type: MODULE_TYPE_IMAGE,
				...getBaseModule(articleId, order),
				image: baseImageModule,
			};
		}

		case MODULE_TYPE_VIDEO: {
			return {
				type: MODULE_TYPE_VIDEO,
				...getBaseModule(articleId, order),
				video: baseVideoModule,
				meta: null,
			};
		}

		case MODULE_TYPE_VIDEO360: {
			return {
				type: MODULE_TYPE_VIDEO360,
				...getBaseModule(articleId, order),
				video360: baseVideo360Module,
			};
		}

		case MODULE_TYPE_LIVESTREAM: {
			return {
				type: MODULE_TYPE_LIVESTREAM,
				...getBaseModule(articleId, order),
				livestream: baseLivestreamModule,
			};
		}

		default: {
			return {
				type: MODULE_TYPE_TEXT,
				...getBaseModule(articleId, order),
				text: '',
			};
		}
	}
};

export const createRedirectionFromTemplate = (
	articleId: string,
	sophoraId: string
): RedirectionTemplate => ({
	__meta__: { created: true, deleted: false, updated: false },
	__validation__: null,
	articleId,
	sophoraId,
});
