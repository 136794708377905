import {
	BOARD_BUFFER_PERSIST,
	STATUS_REQUEST,
	STATUS_SUCCESS,
	STATUS_ERROR,
} from '@sep/br24-constants';
import type { Environment } from 'react-relay';

import type { AppThunkAction } from '@/client/store/redux';

import type { ReduxState } from '../..';
import BoardDepositHistoryMutation from '../../../../mutations/BoardDepositHistoryMutation';
import CreateBoardSectionItemMutation from '../../../../mutations/CreateBoardSectionItemMutation';
import CreateBoardSectionMutation from '../../../../mutations/CreateBoardSectionMutation';
import { DeleteBoardSectionItemMutation } from '../../../../mutations/DeleteBoardSectionItemMutation';
import DeleteBoardSectionMutation from '../../../../mutations/DeleteBoardSectionMutation';
import UpdateBoardMutation from '../../../../mutations/UpdateBoardMutation';
import UpdateBoardSectionItemMutation from '../../../../mutations/UpdateBoardSectionItemMutation';
import { UpdateBoardSectionMutation } from '../../../../mutations/UpdateBoardSectionMutation';
import { SUBSCRIBED_STORE_KEY } from '../config';
import type { BoardSectionItemReference } from '../types';
import { findBoardById } from '../util/find';

function dropEntityMetaInformation<T extends { lockedBy: unknown; lockedSince: unknown }>({
	lockedBy: _lockedBy,
	lockedSince: _lockedSince,
	...necessaryModuleInformation
}: T): Omit<T, 'lockedBy' | 'lockedSince'> {
	return necessaryModuleInformation;
}

export type PersistActionRequest = {
	type: typeof BOARD_BUFFER_PERSIST;
	status: typeof STATUS_REQUEST;
	constraint: {
		boardId: string;
	};
};

export type PersistActionSuccess = {
	type: typeof BOARD_BUFFER_PERSIST;
	status: typeof STATUS_SUCCESS;
	constraint: {
		boardId: string;
	};
};

export type PersistActionError = {
	type: typeof BOARD_BUFFER_PERSIST;
	status: typeof STATUS_ERROR;
	constraint: {
		boardId: string;
	};
	payload: Error;
};

function persistRequest(boardId: string): PersistActionRequest {
	return { type: BOARD_BUFFER_PERSIST, status: STATUS_REQUEST, constraint: { boardId } };
}

function persistSuccess(boardId: string): PersistActionSuccess {
	return { type: BOARD_BUFFER_PERSIST, status: STATUS_SUCCESS, constraint: { boardId } };
}

function persistError(boardId: string, error: Error): PersistActionError {
	return {
		type: BOARD_BUFFER_PERSIST,
		status: STATUS_ERROR,
		constraint: { boardId },
		payload: error,
	};
}

async function runTransaction(
	boardId: string,
	getState: () => ReduxState,
	environment: Environment
): Promise<unknown> {
	const state = getState()[SUBSCRIBED_STORE_KEY];
	const updatedBoard = state.boards[boardId];
	const originalBoard = state.raw[boardId];

	const standardJobs: unknown[] = [];

	if (updatedBoard.__meta__.updated) {
		const { __meta__, rowId, sections, rssFeed, rdfFeed, socialMediaAccounts, ...boardPatch } =
			updatedBoard;

		const cleanedBoardPatch = dropEntityMetaInformation(boardPatch);

		standardJobs.push(
			UpdateBoardMutation(
				boardId,
				{
					...cleanedBoardPatch,
					socialMediaAccounts: socialMediaAccounts.map(({ __internalId__ }) => {
						const { __meta__: weDontNeedMeta, ...item } = state.socialMediaAccounts[__internalId__];
						return item;
					}),
				},
				environment
			)
		);
	} else {
		// The `UpdateBoardMutation` not only updates the board but also the internal history, the `updatedAt`
		// column and a few other things. Changes made on the boards-table will also perform a change notification
		// which is used to generate internal events (such as invalidation).
		//
		// see BR24-1891
		standardJobs.push(
			UpdateBoardMutation(
				boardId,
				{
					status: updatedBoard.status,
				},
				environment
			)
		);
	}

	// create new sections and items
	Object.keys(state.sections)
		.filter(
			(internalId) =>
				state.sections[internalId].__meta__.boardId === originalBoard.rowId &&
				state.sections[internalId].__meta__.created &&
				!state.sections[internalId].__meta__.deleted &&
				!state.sections[internalId].rowId
		)
		.forEach((internalId) => {
			const { __meta__, rowId, items, meta, ...section } = state.sections[internalId];

			standardJobs.push(
				CreateBoardSectionMutation(
					{
						boardId: originalBoard.rowId,
						...section,
						meta: meta ? JSON.stringify(meta) : null,
					},
					environment
				).then((createdBoardSection) =>
					Promise.all(
						items
							.filter((item): item is BoardSectionItemReference => !!(item && item.__internalId__))
							.map(({ __internalId__: sectionItemInternalId }) => {
								const { __meta__, rowId, ...sectionItem } =
									state.sectionItems[sectionItemInternalId];

								return CreateBoardSectionItemMutation(
									{
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										boardsSectionId: createdBoardSection?.rowId,
										...sectionItem,
									},
									environment
								);
							})
					)
				)
			);
		});

	// create new section items
	standardJobs.push(
		...Object.keys(state.sectionItems)
			.filter(
				(internalId) =>
					state.sectionItems[internalId].__meta__.boardId === originalBoard.rowId &&
					state.sectionItems[internalId].__meta__.created &&
					!state.sectionItems[internalId].__meta__.deleted &&
					// TODO: there is a bug when the user is to fast and changes something between BUFFER_PERSIST
					//       and BUFFER_INITALIZE_AND_CLEAN, while we are waiting for the refetch results --Andi
					!state.sections[state.sectionItems[internalId].__meta__.sectionId].__meta__.created &&
					!state.sectionItems[internalId].rowId
			)
			.map((internalId) => {
				const { __meta__, rowId, ...sectionItem } = state.sectionItems[internalId];

				return CreateBoardSectionItemMutation(
					{
						boardsSectionId: String(state.sections[__meta__.sectionId].rowId),
						...sectionItem,
					},
					environment
				);
			})
	);

	// update sections
	Object.keys(state.sections)
		.filter(
			(internalId) =>
				state.sections[internalId].__meta__.boardId === originalBoard.rowId &&
				state.sections[internalId].__meta__.updated &&
				!state.sections[internalId].__meta__.deleted &&
				state.sections[internalId].rowId
		)
		.forEach((internalId) => {
			const { __meta__, rowId: sectionRowId, items, meta, ...section } = state.sections[internalId];

			standardJobs.push(
				UpdateBoardSectionMutation(
					String(sectionRowId),
					{
						...section,
						meta: meta ? JSON.stringify(meta) : null,
					},
					environment
				)
			);
		});

	// update section items
	Object.keys(state.sectionItems)
		.filter(
			(internalId) =>
				state.sectionItems[internalId].__meta__.boardId === originalBoard.rowId &&
				state.sectionItems[internalId].__meta__.updated &&
				!state.sectionItems[internalId].__meta__.deleted &&
				state.sectionItems[internalId].rowId
		)
		.forEach((internalId) => {
			const { rowId, __meta__, ...sectionItem } = state.sectionItems[internalId];

			standardJobs.push(UpdateBoardSectionItemMutation(rowId, sectionItem, environment));
		});

	await Promise.all(standardJobs);
	await Promise.all(
		Object.keys(state.sectionItems).map((sectionItemInternalId) => {
			const affectedSectionItem = state.sectionItems[sectionItemInternalId];

			if (
				affectedSectionItem.__meta__.boardId !== boardId ||
				!affectedSectionItem.__meta__.deleted ||
				!affectedSectionItem.rowId
			) {
				return Promise.resolve();
			}

			return DeleteBoardSectionItemMutation(affectedSectionItem.rowId, environment);
		})
	);
	return await Promise.all(
		Object.keys(state.sections).map((sectionInternalId) => {
			const affectedSection = state.sections[sectionInternalId];

			if (
				affectedSection.__meta__.boardId !== boardId ||
				!affectedSection.__meta__.deleted ||
				!affectedSection.rowId
			) {
				return Promise.resolve();
			}

			return DeleteBoardSectionMutation(affectedSection.rowId, environment);
		})
	);
}

/**
 * Persists the stored buffer.
 *
 * @param  {string} boardId
 * @return {Function}
 */
export default function persist(
	boardId: string,
	environment: Environment,
	callback?: (err: null | Error, res?: any | null) => void
): AppThunkAction<PersistActionRequest | PersistActionSuccess | PersistActionError> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		dispatch(persistRequest(boardId));
		runTransaction(boardId, getState, environment)
			.then((res) => {
				dispatch(persistSuccess(boardId));

				BoardDepositHistoryMutation(boardId, environment).finally(() => {
					if (typeof callback === 'function') {
						callback(null, res);
					}
				});
			})
			.catch((err) => {
				dispatch(persistError(boardId, err));

				if (typeof callback === 'function') {
					callback(err, null);
				}
			});
	};
}
