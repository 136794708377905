import styled from 'styled-components';

import AnimatedLogo from '../../AnimatedLogo';
import { Empty } from '../../Icon';

const AssetManagerImporterContent = styled.div`
	position: relative;
	width: 100%;
	background-color: ${(props) => props.theme.assetManager.importer.backgroundColor};
	padding: ${(props) => props.theme.assetManager.innerPadding};
	color: #ffffff;
	overflow-x: auto;
	::-webkit-scrollbar {
		width: 5px;
	}
	::-webkit-scrollbar-thumb {
		background: rgba(255, 255, 255, 0.2);
	}
	::-webkit-scrollbar-corner {
		background: rgba(0, 0, 0, 0);
	}
`;

const EmptyContentWrapper = styled.div`
	width: 100%;
	text-align: center;
	font-size: 1.3rem;
	font-weight: 200;
`;

const EmptyIconWrapper = styled.div`
	font-size: 4rem;
	margin-bottom: 1rem;
`;

type AssetManagerImporterContentEmptyProps = {
	children: any;
};

export function AssetManagerImporterContentEmpty({
	children,
}: AssetManagerImporterContentEmptyProps) {
	return (
		<EmptyContentWrapper>
			<EmptyIconWrapper>
				<Empty />
			</EmptyIconWrapper>
			{children}
		</EmptyContentWrapper>
	);
}

const LoadingWrapper = styled.div`
	position: sticky;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: ${(props) => props.theme.assetManager.importer.backgroundColor};
	box-shadow: 0 20px 0 0 ${(props) => props.theme.assetManager.importer.backgroundColor};
	z-index: 11;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
`;

const LoadingWrapperInner = styled.div`
	margin: 0 auto;
	text-align: center;
`;

const LoadingWrapperInnerText = styled.p`
	color: rgba(255, 255, 255, 0.7);
	font-weight: 200;
	font-size: 1.1rem;
	margin-top: 0.5rem;
`;

export function AssetManagerImporterContentLoading({ children }: any) {
	return (
		<LoadingWrapper>
			<LoadingWrapperInner>
				<AnimatedLogo layout="light" />
				<LoadingWrapperInnerText>{children}</LoadingWrapperInnerText>
			</LoadingWrapperInner>
		</LoadingWrapper>
	);
}

export default AssetManagerImporterContent;
