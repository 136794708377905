/**
 * @generated SignedSource<<bc059223a8f9ecf4fb925e1b1eb9b0b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BoardsSectionsSchema = "CATEGORY_LEFT_1_BIG" | "CATEGORY_LEFT_2_SMALL" | "CATEGORY_RIGHT_1_BIG" | "CATEGORY_RIGHT_2_SMALL" | "HIGHLIGHT_3" | "RAISED_LEFT_SIMPLE" | "RAISED_RIGHT_SHORTNEWS" | "RAISED_RIGHT_SHORTNEWS_2_SMALL" | "RAISED_RIGHT_SIMPLE" | "RAISED_RIGHT_SIMPLE_WEATHER" | "REGIONAL_1_BIG_TOP_2_SMALL_BELOW" | "REGIONAL_2_BIG_TOP" | "REGIONAL_2_SMALL_TOP_1_BIG_BELOW" | "REGIONAL_4_SIMPLE" | "SMALL_3" | "STAGE_CAROUSEL" | "STAGE_SPLITTED" | "SUBJECTS_NEWS_WEATHER";
export type SocialMediaAccountService = "EMAIL" | "FACEBOOK_PAGE" | "INSTAGRAM" | "SPOTIFY" | "TWITTER" | "YOUTUBE";
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
import { FragmentRefs } from "relay-runtime";
export type Board_board$data = {
  readonly authorByCreatedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
  } | null;
  readonly authorByLockedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
  } | null;
  readonly authorByUpdatedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
  } | null;
  readonly boardsSectionsByBoardId: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly authorByCreatedBy: {
          readonly firstname: string | null;
          readonly guid: string;
          readonly lastname: string | null;
        } | null;
        readonly authorByUpdatedBy: {
          readonly firstname: string | null;
          readonly guid: string;
          readonly lastname: string | null;
        } | null;
        readonly autofill: boolean | null;
        readonly boardLink: string | null;
        readonly boardsSectionsItemsByBoardsSectionId: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly articleId: string | null;
              readonly boardId: string | null;
              readonly boardsTeaserId: string | null;
              readonly order: number | null;
              readonly rowId: number;
            } | null;
          }>;
          readonly totalCount: number;
        };
        readonly categoryByCategoryId: {
          readonly rowId: string;
          readonly title: string;
        } | null;
        readonly categoryId: string | null;
        readonly color: string | null;
        readonly createdAt: string;
        readonly createdBy: string | null;
        readonly meta: any | null;
        readonly order: number;
        readonly rowId: string;
        readonly schema: BoardsSectionsSchema;
        readonly showHeroTitleForMobile: boolean | null;
        readonly title: string | null;
        readonly updatedAt: string | null;
        readonly updatedBy: string | null;
      } | null;
    }>;
    readonly totalCount: number;
  };
  readonly categoryByCategoryId: {
    readonly rowId: string;
  } | null;
  readonly categoryId: string | null;
  readonly createdAt: string;
  readonly createdBy: string | null;
  readonly description: string;
  readonly hasIntroductionText: boolean | null;
  readonly historiesByBoardId: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly authorByAccomplishedBy: {
          readonly name: string | null;
        } | null;
        readonly createdAt: string;
        readonly data: any;
        readonly rowId: number;
      } | null;
    }>;
    readonly totalCount: number;
  };
  readonly image: {
    readonly altText: string | null;
    readonly copyright: string | null;
    readonly title: string | null;
    readonly url: string | null;
  } | null;
  readonly introductionHeadline: string | null;
  readonly introductionText: string | null;
  readonly lockedBy: string | null;
  readonly lockedSince: string | null;
  readonly noindex: boolean;
  readonly parentId: string | null;
  readonly protected: boolean;
  readonly rdfFeed: string | null;
  readonly rowId: string;
  readonly rssFeed: string | null;
  readonly seoTitle: string | null;
  readonly slug: string;
  readonly socialMediaAccounts: ReadonlyArray<{
    readonly accountName: string | null;
    readonly label: string | null;
    readonly service: SocialMediaAccountService | null;
  } | null> | null;
  readonly status: Status | null;
  readonly title: string;
  readonly updatedAt: string | null;
  readonly updatedBy: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"LockHandling_board">;
  readonly " $fragmentType": "Board_board";
};
export type Board_board$key = {
  readonly " $data"?: Board_board$data;
  readonly " $fragmentSpreads": FragmentRefs<"Board_board">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "ORDER_ASC"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedBy",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "guid",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstname",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastname",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "authorByUpdatedBy",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdBy",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "authorByCreatedBy",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "boardId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Board_board",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rssFeed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rdfFeed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleImage",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "altText",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "copyright",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasIntroductionText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "introductionHeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "introductionText",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categoryByCategoryId",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialMediaAccount",
      "kind": "LinkedField",
      "name": "socialMediaAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "service",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "noindex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "boardId",
              "variableName": "boardId"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_DESC"
        }
      ],
      "concreteType": "HistoriesConnection",
      "kind": "LinkedField",
      "name": "historiesByBoardId",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HistoriesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "History",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "data",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "authorByAccomplishedBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v5/*: any*/),
      "concreteType": "BoardsSectionsConnection",
      "kind": "LinkedField",
      "name": "boardsSectionsByBoardId",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BoardsSectionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BoardsSection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "boardLink",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "schema",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Category",
                  "kind": "LinkedField",
                  "name": "categoryByCategoryId",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v5/*: any*/),
                  "concreteType": "BoardsSectionsItemsConnection",
                  "kind": "LinkedField",
                  "name": "boardsSectionsItemsByBoardsSectionId",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BoardsSectionsItemsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "BoardsSectionsItem",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "articleId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "boardsTeaserId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "boardId",
                              "storageKey": null
                            },
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "boardsSectionsItemsByBoardsSectionId(first:1000,orderBy:\"ORDER_ASC\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "autofill",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "meta",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "showHeroTitleForMobile",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "boardsSectionsByBoardId(first:1000,orderBy:\"ORDER_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protected",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v7/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authorByLockedBy",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedSince",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LockHandling_board"
    }
  ],
  "type": "Board",
  "abstractKey": null
};
})();

(node as any).hash = "352726626061e14d942512105a424fee";

export default node;
