import type { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 20px;
`;

type ModeCode = 'code';
type ModeStandard = 'standard';
type Mode = ModeCode | ModeStandard;
type SizeFull = 'full';
type SizeStandard = 'standard';
type Size = SizeFull | SizeStandard;
type ColorDark = 'dark';
type ColorLight = 'light';
type Color = ColorDark | ColorLight;

type InputProps = {
	mode: Mode;
	size: Size;
	color: Color;
};

const Input = styled.input<InputProps>`
	width: ${(props: InputProps) => (props.size === 'full' ? '100%' : 'auto')};
	height: 38px;
	border-radius: 3px;
	line-height: 38px;
	padding: 0 1rem;
	${(props: InputProps) =>
		props.color === 'dark'
			? `
  color: #ffffff;
	background-color: rgba(255, 255, 255, 0.2);`
			: `
  color: #000000;
	background-color: #ffffff;`} border: 0;
	outline: none;
	font-family: ${(props: InputProps) =>
		props.mode === 'code'
			? 'Source Code Pro, Open Sans, Helvetica Neue, Arial, sans-serif'
			: 'inherit'};
`;

const ActionWrapper = styled.div``;

export const AssetManagerImporterInputAction = styled.button`
	width: 38px;
	height: 38px;
	border: none;
	background-color: ${(props) => props.theme.colors.primaryBlue};
	outline: none;
	border-radius: 3px;
	cursor: pointer;

	&.loading {
		svg {
			animation: spin 2s infinite linear;
		}

		@keyframes spin {
			100% {
				transform: rotate(360deg);
			}
		}
	}
`;

type AssetManagerImporterInputProps = Partial<InputProps> & {
	actions?: ReactNode;
};

export function AssetManagerImporterInput({
	actions,
	mode = 'standard',
	size = 'standard',
	// FIXME Color can only be `light` or `dark` it seems
	color = 'standard' as Color,
	...rest
}: AssetManagerImporterInputProps & ComponentProps<typeof Input>) {
	return (
		<Wrapper>
			<Input mode={mode} size={size} color={color} {...rest} />
			{actions && <ActionWrapper>{actions}</ActionWrapper>}
		</Wrapper>
	);
}

const AssetManagerImporterForm = styled.div`
	width: 100%;
	margin-bottom: ${(props) => props.theme.assetManager.innerPadding};
`;

export default AssetManagerImporterForm;
