import invariant from 'invariant';
import { createSelector } from 'reselect';

import type { ReduxState } from '@/client/store/reducers';
import type { Language } from '@/client/ui/ArticleMeta/ArticleMetaMore/languages';
import type { Capability, District, Priority, Status } from '@/types/schema';

import type { State, Module, Redirection, Validation } from '.';

// UTILITY FUNCTIONS

export const root = (globalState: Pick<ReduxState, 'articleEditor'>) => globalState.articleEditor;

// SELECTORS

export const latestFatalErrorSelector = createSelector(
	[root],
	(state: State) => state.latestFatalError
);

export const activeArticleIdSelector = createSelector(
	[root],
	(state: Pick<State, 'activeArticleId'>) => state.activeArticleId
);

export const activeArticleSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		invariant(id, 'Required: selected article');

		return id ? state.articles[id] : null;
	}
);

export const activeArticleTagsSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		invariant(id, 'Required: selected article');

		return id ? state.articles[id].tags : null;
	}
);

export const activeArticleAllModulesSelector = createSelector(
	[root, activeArticleSelector],
	(_, article) => {
		return article?.modules;
	}
);

export const activeArticleModuleTypeSelector = createSelector(
	[root, activeArticleSelector],
	(state, article) => {
		const activeModules = (article?.modules ?? [])
			.map((id) => state.modules[id])
			.filter((item: Module) => !item.__meta__.deleted);

		return activeModules?.map((item) => ({
			id: item.rowId ? item.rowId : item.id,
			type: item.type,
		}));
	}
);

const getModule = (currentState: Pick<ReduxState, 'articleEditor'>, id: string | number) => {
	const state = root(currentState);
	return state.modules[id];
};

export const activeArticleModuleSelector = createSelector([getModule], (module) => module);

export const articleEditorMetaSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		let publicationPriority: Priority | null = null;
		if (id) {
			publicationPriority = state?.articles[id]?.publicationPriority;
		}
		return {
			publicationPriority,
		};
	}
);

export const isArticleEditedSelector = createSelector([root], ({ isEdited }) => isEdited);

export const articleRedirectionSelector = createSelector(
	[root, activeArticleSelector],
	(state: State, article) =>
		article?.redirections
			.map((id) => state.redirections[id])
			.filter((item: Redirection) => !item.__meta__.deleted)
);

export const articleAuthorsSelector = createSelector(
	[root, activeArticleSelector],
	(state: State, article: any) =>
		article.authors.map((connectionId) => state.authors[connectionId].guid)
);

export const articleTagsSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		let tags: { id: string | number; text: string; status: any }[] = [];
		if (id) {
			const articleTags = state?.articles[id]?.tags;

			if (Array.isArray(articleTags) && articleTags.length) {
				tags = articleTags.map((tag) => ({
					id: state?.tags[tag]?.rowId || tag,
					text: state?.tags[tag]?.text,
					status: state?.tags[tag]?.status,
				}));
			}
		}

		return {
			tags,
		};
	}
);

export const articleMetaSEOSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		invariant(id, 'Required: selected article');

		const article = state.articles[id];

		const seoTitle = article?.seoTitle;
		const metaDescription = article?.metaDescription;
		const slug = article?.slug;
		const validation = article?.__validation__;
		const title = article?.title;
		const status = article?.status;
		const canonicalUrl = article?.canonicalUrl;

		return {
			metaDescription,
			seoTitle,
			slug,
			status,
			title,
			canonicalUrl,
			validation,
		};
	}
);

export const articleMetaMoreSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		invariant(id, 'Required: selected article');

		const isLive = state?.articles[id]?.isLive;
		const allowComments = state?.articles[id]?.allowComments;
		const enableAmp = state?.articles[id]?.enableAmp;
		const language = state?.articles[id]?.language as Language;
		const redirections = state?.articles[id]?.redirections;
		const externalId = state?.articles[id]?.externalId;

		return {
			isLive,
			allowComments,
			enableAmp,
			language,
			redirections,
			externalId,
		};
	}
);

export const articleMetaNotesSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id: string | null | undefined) => {
		invariant(id, 'Required: selected article');

		const validation = state?.articles[id]?.__validation__;

		return {
			validation,
			notes: state.articles[id]?.notes ?? '',
		};
	}
);

export const articleSourceOriginSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		let sourceOriginId: string | null | undefined = '';
		let sourceOriginDate: string | null = null;
		if (id) {
			sourceOriginId = state?.articles[id]?.sourceOrigin;

			sourceOriginDate = state?.articles[id]?.sourceBroadcastDate;
		}
		return {
			sourceOriginId,
			sourceOriginDate,
		};
	}
);

export const articleGeolocationSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		let geolocation: string | null | undefined = null;
		let location: null | string = null;
		let districts: (District | null)[] | null | undefined = [];
		if (id) {
			geolocation = state?.articles[id]?.geolocation;
			location = state?.articles[id]?.location;
			districts = state?.articles[id]?.districts;
		}
		return {
			geolocation,
			location,
			districts,
		};
	}
);

export const articleStatusBarSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		invariant(id, 'Required: selected article');

		const isSaving = state?.isWorking || false;

		const boardsSectionsItemsByArticleId = state?.articles[id]?.boardsSectionsItemsByArticleId;
		const capability = state?.articles[id]?.capability;
		const primaryCategory = state?.articles[id]?.primaryCategory;
		const expirationAt = state?.articles[id]?.expirationAt;
		const expirationTemplate = state?.articles[id]?.expirationTemplate;
		const historiesByArticleId = state?.articles[id]?.historiesByArticleId;
		const isTemplate = state?.articles[id]?.isTemplate;
		const publicationDate = state?.articles[id]?.publicationDate;
		const pushNotification = state?.articles[id]?.pushNotification;
		const rowId = state?.articles[id]?.rowId;
		const shareUrl = state?.articles[id]?.shareUrl;
		const status = state?.articles[id]?.status;
		const title = state?.articles[id]?.title;
		const slug = state?.articles[id]?.slug;

		return {
			boardsSectionsItemsByArticleId,
			capability,
			primaryCategory,
			expirationAt,
			expirationTemplate,
			historiesByArticleId,
			isSaving,
			isTemplate,
			publicationDate,
			pushNotification,
			rowId,
			shareUrl,
			slug,
			status,
			title,
		};
	}
);

export const articleLinksSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		invariant(id, 'Required: selected article');

		const links = state?.articles[id]?.links;
		const invalidLinks = state?.articles[id]?.invalidLinks;
		const rawLinks = state?.articles[id]?.rawLinks;

		return {
			links,
			invalidLinks,
			rawLinks,
		};
	}
);

export const articleCategorySelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		invariant(id, 'Required: selected article');

		const primaryCategory = state?.articles[id]?.primaryCategory;

		return { primaryCategory };
	}
);

export const articleModuleHeaderSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		let capability: Capability | null = null;
		let seoTitle: string | null = null;
		let status: Status | null = null;
		let teaserText: string | null | undefined = null;
		let title: string | null = null;
		let validation: Validation | null | undefined = null;

		if (id) {
			title = state?.articles[id]?.title;
			teaserText = state?.articles[id]?.teaserText;
			validation = state?.articles[id]?.__validation__;
			capability = state?.articles[id]?.capability;
			status = state?.articles[id]?.status;
			seoTitle = state?.articles[id]?.seoTitle;
		}

		return {
			capability,
			status,
			seoTitle,
			teaserText,
			title,
			validation,
		};
	}
);

export const articleInfoValidationSelector = createSelector(
	[root, activeArticleIdSelector],
	(state: State, id?: string | null) => {
		let validation: Validation | null | undefined = null;

		if (id) {
			validation = state?.articles[id]?.__validation__;
		}

		return {
			validation,
		};
	}
);

export const articleModulesContainerSelector = createSelector(
	[activeArticleModuleTypeSelector, activeArticleAllModulesSelector, activeArticleSelector],
	(modules, moduleIdxs, article) => ({
		modules,
		moduleIdxs,
		capability: article?.capability,
	})
);
