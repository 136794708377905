import styled, { keyframes } from 'styled-components';

interface AnimatedLogoProps {
	isPlaying: boolean;
	layout: 'dark' | 'light';
}

const Animation = keyframes`
	0% {
		background-position: 0px 0px;
	}
	100% {
		background-position: -15000px 0px;
	}
`;

const Wrapper = styled.div<AnimatedLogoProps>`
	animation-duration: 2500ms;
	animation-timing-function: steps(150);
	animation-iteration-count: infinite;
	width: 100px;
	height: 63px;
	background-repeat: no-repeat;
	background-position: 0px 0px;
	animation-name: ${(props) => (props.isPlaying ? Animation : 'none')};
	background-image: ${({ layout }) =>
		`url("/image/${layout === 'dark' ? 'LogoSpriteDark' : 'LogoSpriteLight'}.svg")`};
`;

function AnimatedLogo({ isPlaying = true, layout = 'dark' }: Partial<AnimatedLogoProps>) {
	return <Wrapper isPlaying={isPlaying} layout={layout} />;
}

export default AnimatedLogo;
