import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faExternalLink as ExternalLinkIcon } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faFileAlt as BoardIcon } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faNewspaper as ArticleIcon } from '@fortawesome/pro-light-svg-icons/faNewspaper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSortableTree, { removeNode } from '@nosferatu500/react-sortable-tree';
import type { ReactSortableTreeProps } from '@nosferatu500/react-sortable-tree/react-sortable-tree';
import { Button, Popconfirm, Space } from 'antd';
import { type FC, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import config from '@/client/config';

import s from './react-sortable-tree.module.scss';
import type {
	TreeNavigationItem,
	TreeDataModifier,
	NavigationItemModifier,
	NavigationItemId,
} from './types';
import {
	cloneTreeData,
	getNodeKeyFromTreeNavigationItem,
	walkTreeByOrderPath,
} from './utils/react-sortable-tree-helpers';

import '@nosferatu500/react-sortable-tree/style.css';

export const NavigationEditorTree: FC<{
	rootId: NavigationItemId;
	treeData: TreeNavigationItem[];
	onChangeTree: TreeDataModifier;
	onRequestEditorDialog: NavigationItemModifier;
}> = ({ rootId, treeData, onRequestEditorDialog, onChangeTree }) => {
	const createTreeItemControls = useCallback<
		NonNullable<ReactSortableTreeProps['generateNodeProps']>
	>(
		(opts) => {
			const { node, path } = opts as { node: TreeNavigationItem; path: number[] };

			return {
				buttons: [
					<Space key={node.data.rowId.toString()}>
						<div style={{ display: 'grid', gridAutoFlow: 'column', gap: '2rem' }}>
							{path.length < config.navigation.maxDepth && (
								<Button
									onClick={() => {
										onRequestEditorDialog({ parent: Number(node.data.rowId) });
									}}
									size="small"
									icon={<PlusOutlined />}
									type="primary"
								/>
							)}
							<Button
								onClick={() => onRequestEditorDialog(node.data)}
								size="small"
								icon={<EditOutlined />}
								type="ghost"
							/>
							<Popconfirm
								title="Möchtest du den Navigationspunkt wirklich löschen?"
								onConfirm={() => {
									onChangeTree(
										/* TODO: we could add generic types for this methods */
										removeNode({
											treeData,
											path,
											getNodeKey: getNodeKeyFromTreeNavigationItem,
										})?.treeData as TreeNavigationItem[]
									);
								}}
								onCancel={(event) => event?.preventDefault()}
								okText="Ja"
								cancelText="Nein"
								disabled={config.navigation.disableDeleteForIds.includes(Number(node.data.rowId))}
							>
								<Button
									color="warn"
									size="small"
									disabled={config.navigation.disableDeleteForIds.includes(Number(node.data.rowId))}
									icon={<DeleteOutlined />}
									type="primary"
									danger
								/>
							</Popconfirm>
						</div>
					</Space>,
				],
			};
		},
		[onChangeTree, onRequestEditorDialog, treeData]
	);

	/**
	 * clone tree before passing on
	 * react-sortable-tree is mutating the treeData
	 * AND we have added a non-clonable property (.title) to the treeData
	 * that we must remove first
	 */
	// const handleChangeTree = useCallback(
	// 	(treeData: TreeNavigationItem[]) => {
	// 		onChangeTree(treeData);
	// 	},
	// 	[onChangeTree]
	// );

	/**
	 * ⚠️ clone tree data to avoid mutating the original treeData
	 * add a title property to each child which is a non-serializable react element
	 */
	const renderableClonedTreeData = useMemo(() => {
		const clone = cloneTreeData(treeData);
		walkTreeByOrderPath(clone, rootId, (node) => (node.title = <TreeItemTitle data={node.data} />));
		return clone;
	}, [rootId, treeData]);

	const theme = useTheme();

	return (
		<div
			style={{
				boxShadow: theme.boxShadow.default,
			}}
			className={s.root}
		>
			<ReactSortableTree
				className={s.sortableTree}
				getNodeKey={getNodeKeyFromTreeNavigationItem}
				maxDepth={config.navigation.maxDepth}
				generateNodeProps={createTreeItemControls}
				onChange={onChangeTree}
				treeData={renderableClonedTreeData}
			/>
		</div>
	);
};

export const TreeItemTitle = ({ data }: { data: TreeNavigationItem['data'] }) => {
	let icon: IconDefinition | null = null;
	let title = data.text;

	const hasText = Boolean(data.text);

	switch (data.type) {
		case 'BOARD':
			icon = BoardIcon;
			title = hasText ? data.text : data.boardByBoard?.title ?? null;
			break;
		case 'ARTICLE':
			icon = ArticleIcon;
			title = hasText ? data.text : data.articleByArticle?.title ?? null;
			break;
		case 'CUSTOM':
			icon = ExternalLinkIcon;
			break;
		case 'NAVIGATION':
			// no icon, it's only the root navigation
			break;
		default:
			throw new Error('Unhandeled type: ' + data.type);
	}

	return (
		<div
			style={{
				minWidth: 300,
				display: 'grid',
				gridAutoFlow: 'column',
				gap: '1em',
				justifyContent: 'start',
			}}
		>
			<span style={{ minWidth: 15 }}>{icon && <FontAwesomeIcon icon={icon} />}</span>
			<span style={{ maxWidth: 400, textOverflow: 'ellipsis', overflow: 'hidden' }}>{title}</span>
		</div>
	);
};
