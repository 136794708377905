import invariant from 'invariant';
import { darken } from 'polished';
import { Component } from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';
import type { LiteralUnion } from 'type-fest';

import type { SortDirection } from '@/client/containers/AssetManager';

import { Sort } from '../../Icon';

type SortDirectionItem = {
	id: string;
	label: string;
};

interface AssetManagerSortDirectionProps extends SortDirectionButtonProps {
	isDisabled?: boolean;
	onChange: (sortDirection: LiteralUnion<SortDirection, string>) => void;
	value?: LiteralUnion<SortDirection, string>;
	items: Array<SortDirectionItem>;
}

interface SortDirectionButtonProps {
	isActive: boolean;
}

type AssetManagerSortDirectionState = {
	isOverlayVisible: boolean;
};

const SortDirectionButton = styled.button<SortDirectionButtonProps>`
	position: relative;
	display: inline-block;
	height: 40px;
	width: 40px;
	padding: 0 1rem;
	border: none;
	background-color: ${(props) => (props.isActive ? props.theme.colors.primaryBlue : '#fcfcfc')};
	color: ${(props) => (props.isActive ? '#ffffff' : '#4c4c4c')};
	outline: none;
	cursor: pointer;
	&:hover {
		color: ${(props) => (props.isActive ? '#ffffff' : props.theme.colors.primaryBlue)};
		background-color: ${(props) =>
			darken(0.1, props.isActive ? props.theme?.colors.primaryBlue : '#fcfcfc')};
	}
`;

type SortDirectionButtonContentProps = {
	$isDisabled?: boolean;
};

const SortDirectionButtonContent = styled.span<SortDirectionButtonContentProps>`
	display: inline-block;
	height: 40px;
	line-height: 40px;
	&:hover {
		cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
	}
`;

type SortDirectionButtonPopoverProps = {
	isVisible: boolean;
};

const SortDirectionButtonPopover = styled.div`
	min-width: 100%;
	display: ${(props: SortDirectionButtonPopoverProps) => (props.isVisible ? 'block' : 'none')};
	position: absolute;
	top: 40px;
	right: 0;
	background-color: #1e2027;
	z-index: 10000;
`;

const SortDirectionButtonPopoverList = styled.ul`
	margin: 0;
	padding: 0;
	list-style-type: none;
`;

const SortDirectionButtonPopoverListItem = styled.li`
	width: 100%;
	text-align: center;
	padding: 0.4rem 10px;
	color: #ffffff;
	&:hover {
		color: ${(props) => props.theme.colors.primaryBlue};
	}

	&.selected {
		background-color: ${(props) => props.theme.colors.primaryBlue};
		&:hover {
			color: inherit;
		}
	}
`;

export default class AssetManagerSortDirection extends Component<
	AssetManagerSortDirectionProps,
	AssetManagerSortDirectionState
> {
	static defaultProps = {
		isDisabled: false,
	};

	static getDerivedStateFromProps(
		props: AssetManagerSortDirectionProps,
		state: AssetManagerSortDirectionState
	) {
		if (props.isDisabled && state.isOverlayVisible) {
			return {
				isOverlayVisible: false,
			};
		}

		return null;
	}

	state = {
		isOverlayVisible: false,
	};

	handleClickItem = (event: SyntheticEvent<HTMLLIElement>, id: string) => {
		event.preventDefault();

		const { onChange } = this.props;
		onChange(id as LiteralUnion<SortDirection, string>);
	};

	handleMouseEnter = () => {
		const { isDisabled } = this.props;

		if (isDisabled) {
			return;
		}

		this.setState({ isOverlayVisible: true });
	};

	handleMouseLeave = () => {
		const { isDisabled } = this.props;

		if (isDisabled) {
			return;
		}

		this.setState({ isOverlayVisible: false });
	};

	render() {
		const { isDisabled, items, value, isActive } = this.props;
		const { isOverlayVisible } = this.state;
		const selectedItem = items.filter((item) => item.id === value).shift();

		invariant(selectedItem, `${value} not found`);

		return (
			<SortDirectionButton
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				isActive={isActive}
			>
				<SortDirectionButtonContent $isDisabled={isDisabled}>
					<Sort />
				</SortDirectionButtonContent>
				<SortDirectionButtonPopover isVisible={isOverlayVisible}>
					<SortDirectionButtonPopoverList>
						{items.map((item) => (
							<SortDirectionButtonPopoverListItem
								key={item.id}
								onClick={(e) => this.handleClickItem(e, item.id)}
								className={item === selectedItem ? 'selected' : ''}
							>
								{item.label}
							</SortDirectionButtonPopoverListItem>
						))}
					</SortDirectionButtonPopoverList>
				</SortDirectionButtonPopover>
			</SortDirectionButton>
		);
	}
}
