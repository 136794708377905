import styled from 'styled-components';

const AssetManagerImporterError = styled.div`
	width: 100%;
	padding: 1rem;
	background-color: ${(props) => props.theme.colors.stateRed};
	color: #ffffff;
	font-weight: 200;
	margin-bottom: 1rem;
`;

export default AssetManagerImporterError;
