import {
	CAPABILITY_ALL,
	CATEGORY_ALL,
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
} from '@sep/br24-constants';
import { Form, DatePicker, Select, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';

import type { FilterQuery } from '@/client/store/reducers/articlesManager';
import type { Capability, Status } from '@/types/schema';

import styles from '../../../styles';
import translations from '../../../translations';
import CategoryPicker from '../../CategoryPicker';

const { Option } = Select;

type Props = {
	currentFilter: FilterQuery;
	onChange: (currentFilter: FilterQuery) => void;
};

const FILTERABLE_STATUS = [
	STATUS_DRAFT,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
	STATUS_DEPUBLISHED,
	STATUS_PUBLISHED,
	STATUS_DELETED,
] as const;

export function ArticlesFilter({ currentFilter, onChange }: Props) {
	const handleChangeCapability = (capability: Capability, checked: boolean) => {
		const capabilityFilter = [...(currentFilter ? currentFilter.capabilityFilter || [] : [])];

		if (checked) {
			capabilityFilter.push(capability);
		} else {
			capabilityFilter.splice(capabilityFilter.indexOf(capability), 1);
		}

		onChange({
			capabilityFilter: capabilityFilter.length === 0 ? undefined : capabilityFilter,
		});
	};

	const handleChangeCategory = (category: string | null) =>
		onChange({ categoryFilter: category === CATEGORY_ALL ? undefined : category ?? undefined });

	const handleChangeOnlyTemplates = (event: CheckboxChangeEvent) =>
		onChange({ onlyTemplates: event.target.checked });

	const handleChangePublishedBefore = (value?: moment.Moment | null) =>
		onChange({ publishedBefore: value ? value.toDate().toISOString() : undefined });

	const handleChangeStatus = (value: Status | 'all') =>
		onChange({ statusFilter: value === 'all' ? undefined : value });

	const handleChangeTags = (tagsFilter: Array<string>) => onChange({ tagsFilter });

	return (
		<Form layout="vertical" className={styles.pM}>
			<Form.Item label="Rubrik">
				<CategoryPicker
					showPickAll={true}
					className={styles.fullWidth}
					onChange={handleChangeCategory}
					value={currentFilter.categoryFilter || null}
					displayBlock={true}
				/>
			</Form.Item>
			<Form.Item label="Typ">
				{CAPABILITY_ALL.map((capability) => (
					<Checkbox
						key={capability}
						id={capability}
						onChange={({ target: { checked } }) => handleChangeCapability(capability, checked)}
						checked={
							Array.isArray(currentFilter.capabilityFilter)
								? currentFilter.capabilityFilter.includes(capability)
								: false
						}
					>
						{translations[capability] as string}
					</Checkbox>
				))}
			</Form.Item>
			<Form.Item label="Schlagwörter">
				<Select
					className={styles.fullWidth}
					mode="tags"
					onChange={handleChangeTags}
					// searchPlaceholder="Schlüsselwörter"
					placeholder="mehrere Schlagwörter kommagetrennt"
					tokenSeparators={[',']}
					allowClear={true}
					value={currentFilter.tagsFilter}
				/>
			</Form.Item>
			<Form.Item label="Status">
				<Select<Status | 'all'>
					onChange={handleChangeStatus}
					className={styles.fullWidth}
					value={!currentFilter.statusFilter ? 'all' : currentFilter.statusFilter}
				>
					<Option value="all">alle</Option>
					{FILTERABLE_STATUS.map((status: Status) => (
						<Option key={status} value={status}>
							{translations[status] as string}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item label="Publiziert vor">
				<DatePicker
					style={{ width: '100%' }}
					showTime={true}
					format="DD.MM.YYYY HH:mm"
					value={currentFilter.publishedBefore ? moment(currentFilter.publishedBefore) : null}
					placeholder="Datum auswählen"
					onChange={handleChangePublishedBefore}
				/>
			</Form.Item>
			<Form.Item label="Vorlagen anzeigen">
				<Checkbox checked={currentFilter.onlyTemplates} onChange={handleChangeOnlyTemplates}>
					Ausschließlich Vorlagen anzeigen
				</Checkbox>
			</Form.Item>
		</Form>
	);
}
