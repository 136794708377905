import { Form } from 'antd';
import { cloneElement, Children, useState } from 'react';
import type { ReactElement, SyntheticEvent } from 'react';
import styled from 'styled-components';

interface Props {
	children: ReactElement;
	maxLength: number;
	required?: boolean;
	label: string;
}

const FormItem = styled(Form.Item)`
	margin-bottom: 35px;
	width: 100%;
`;

export default function LimitedLengthItem({ children, maxLength, ...rest }: Props) {
	const [currentLength, setCurrentLength] = useState<number>(0);

	function handleChange(event: SyntheticEvent<HTMLInputElement>) {
		const { value } = event.currentTarget;

		setCurrentLength(() => value.length);
	}

	const isGreaterThanMaxLength = currentLength > maxLength;
	const child = Children.only(children);

	const actualLength = currentLength || (child.props.defaultValue || '').length;

	return (
		<FormItem
			{...rest}
			validateStatus={isGreaterThanMaxLength ? 'error' : 'success'}
			hasFeedback={isGreaterThanMaxLength}
			help={`${actualLength}/${maxLength} Zeichen`}
		>
			{cloneElement(child, {
				onChange: handleChange,
			})}
		</FormItem>
	);
}
