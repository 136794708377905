import { fetchQuery, graphql } from 'react-relay';

import environment from '../environment';

import type { boardQuery } from './__generated__/boardQuery.graphql';

const query = graphql`
	query boardQuery($slug: String!, $parentId: String) {
		findBoardsByParentIdAndSlug(filterSlug: $slug, filterParentId: $parentId) {
			totalCount
			edges {
				node {
					rowId
				}
			}
		}
	}
`;

export async function isSlugAvailable(
	slug: string,
	boardId: string | undefined | null = null,
	parentId: string | undefined | null = null
): Promise<boolean> {
	const res = await fetchQuery<boardQuery>(environment, query, { parentId, slug }).toPromise();
	let totalCount =
		res?.findBoardsByParentIdAndSlug && res.findBoardsByParentIdAndSlug.totalCount
			? res.findBoardsByParentIdAndSlug.totalCount
			: 0;
	const edges =
		res?.findBoardsByParentIdAndSlug && res.findBoardsByParentIdAndSlug.edges
			? res.findBoardsByParentIdAndSlug.edges
			: [];
	if (
		totalCount > 0 &&
		edges.filter((edge) => edge && edge.node && edge.node.rowId === boardId).length === 1
	) {
		totalCount -= 1;
	}
	return totalCount === 0;
}
