import { Alert } from 'antd';
import { debounce } from 'lodash-es';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../../translation';
import { ArticleMetaAuthor, ArticleMetaAuthorSearch } from '../../../../ui/ArticleMeta';
import addAuthorAction from '../../actions/addAuthor';
import removeAuthorAction from '../../actions/removeAuthor';
import updateAuthorAction from '../../actions/updateAuthor';
import { AE_AUTHOR } from '../../itemTypes';
import {
	articleAuthorsSelector,
	activeArticleIdSelector,
	articleInfoValidationSelector,
} from '../../selectors';
import { DraggableListItemContainer } from '../DraggableListItem';

import AuthorRenderer from './AuthorRenderer';
import SearchAuthorRenderer from './SearchAuthorRenderer';

interface Props extends WithTranslationProps, ReduxProps {
	key: string | number;
}

type State = {
	searchTerm: string;
};

export class AuthorContainer extends Component<Props, State> {
	state = {
		searchTerm: '',
	};

	convertToSearchItem = ({ node }: any) => ({
		guid: node.guid,
		fullName: `${node.firstname} ${node.lastname}`,
		isActive: node.isActive,
		isLdapUser: node.isLdapUser,
		jobTitle: node.jobTitle,
	});

	handleAdd = (guid: string) => {
		this.props.addAuthor(guid);
		this.setState({ searchTerm: '' });
	};

	handleMove = (dragIndex: number, hoverIndex: number) => {
		this.props.updateAuthor(dragIndex, hoverIndex);
	};

	handleRemove = (removeIndex: number) => {
		this.props.removeAuthor(removeIndex);
	};

	componentWillUnmount() {
		this.handleSearch.cancel();
	}

	handleSearch = debounce((searchTerm: string) => {
		this.setState({ searchTerm });
	}, 500);

	renderAuthorItem = (key: number, guid: string) => (
		<DraggableListItemContainer<typeof AE_AUTHOR>
			acceptedItemTypes={[AE_AUTHOR]}
			type={AE_AUTHOR}
			key={guid}
			index={key}
			onRemove={this.handleRemove}
			onMove={this.handleMove}
		>
			<AuthorRenderer guid={guid} />
		</DraggableListItemContainer>
	);

	render() {
		const { searchTerm } = this.state;
		const {
			authors,
			validation,
			translation: { translate },
			articleId,
			addAuthor,
			removeAuthor,
			updateAuthor,
			...rest
		} = this.props;

		return (
			<ArticleMetaAuthor
				search={
					<SearchAuthorRenderer searchTerm={searchTerm}>
						{({ error, props }) => {
							const hasError = !!error;

							if (hasError) {
								return (
									<Alert message={translate('error')} description={error.message} type="error" />
								);
							}

							return (
								<ArticleMetaAuthorSearch
									isWorking={error === null && (!props || !props.authors)}
									items={(props?.authors?.edges || []).map(this.convertToSearchItem)}
									onSearch={this.handleSearch}
									onSelect={this.handleAdd}
									defaultValue={searchTerm}
								/>
							);
						}}
					</SearchAuthorRenderer>
				}
				{...rest}
				validation={validation && validation.authors ? validation.authors : null}
			>
				{Array.isArray(authors) && authors.length > 0
					? authors.map((guid, key) => this.renderAuthorItem(key, guid))
					: null}
			</ArticleMetaAuthor>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		authors: articleAuthorsSelector(state),
		articleId: activeArticleIdSelector(state),
		...articleInfoValidationSelector(state),
	}),
	{
		addAuthor: addAuthorAction,
		removeAuthor: removeAuthorAction,
		updateAuthor: updateAuthorAction,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(AuthorContainer));
