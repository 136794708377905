import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { createPaginationContainer, graphql, type RelayPaginationProp } from 'react-relay';

import { withLock } from '@/client/hooks/useLock';
import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import TimeIndicator from '../../TimeIndicator';

import styles from './BoardsTeasersTable.module.scss';
import BoardsTeasersTableRow, { type BoardsTeaserNode } from './BoardsTeasersTableRow';
import type { BoardsTeasersTable_query$data } from './__generated__/BoardsTeasersTable_query.graphql';

interface Props extends WithTranslationProps {
	relay: RelayPaginationProp;
	query: BoardsTeasersTable_query$data;
	currentFilter: any;
	onClickOpen: (node: BoardsTeaserNode) => void;
	autoRefreshIn: number;
}

class BoardsTeasersTable extends Component<Props> {
	indicator: TimeIndicator | undefined;

	onHandleLoadMore = () => {
		this.props.relay.loadMore(BoardsTeasersTable.LOAD_MORE_NUM);
	};

	captureIndicator = (ref: TimeIndicator) => {
		this.indicator = ref;
	};

	handleRefresh = () => {
		this.props.relay.refetchConnection(50, () => null, this.props.currentFilter);
	};

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.currentFilter !== this.props.currentFilter) {
			this.indicator?.reset();
		}
	}

	static LOAD_MORE_NUM = 50;

	render() {
		const { relay, query, onClickOpen, autoRefreshIn } = this.props;
		const {
			translation: { translate },
		} = this.props;

		return (
			<InfiniteScroll hasMore={relay.hasMore()} loadMore={this.onHandleLoadMore}>
				<div className={styles.tableWrapper}>
					{autoRefreshIn ? (
						<TimeIndicator
							ref={this.captureIndicator}
							duration={autoRefreshIn}
							onFulfil={this.handleRefresh}
						/>
					) : (
						false
					)}
					<table className={styles.table}>
						<thead>
							<tr>
								<th className={styles.title}>{translate('boardsTeaser.table.title')}</th>
								<th>{translate('boardsTeaser.table.description')}</th>
								<th style={{ textAlign: 'center' }}>{translate('boardsTeaser.table.active')}</th>
								<th>{translate('boardsTeaser.table.lastAuthor')}</th>
								<th className={styles.lastUpdate}>{translate('boardsTeaser.table.lastUpdate')}</th>
							</tr>
						</thead>
						<tbody>
							{query.boardTeasers &&
							query.boardTeasers.edges &&
							query.boardTeasers.edges.length > 0 ? (
								query.boardTeasers.edges.map(({ node }) => (
									<BoardsTeasersTableRow
										key={node?.rowId}
										node={node}
										dateKey={node?.updatedAt == null ? 'createdAt' : 'updatedAt'}
										onClickOpen={onClickOpen}
									/>
								))
							) : (
								<tr>
									<td className={styles.empty} colSpan={10}>
										{translate('boardsTeaser.table.noEntry')}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</InfiniteScroll>
		);
	}
}

const BoardsTeaserTablePagination = createPaginationContainer(
	withLock(BoardsTeasersTable, { entityType: 'BoardTeaser' }),
	{
		query: graphql`
			fragment BoardsTeasersTable_query on Query {
				boardTeasers: filterBoardsTeasersCms(
					first: $count
					after: $cursor
					searchTerm: $searchTerm
				) @connection(key: "BoardsTeasersTable_boardTeasers") {
					pageInfo {
						hasNextPage
						startCursor
						endCursor
					}
					totalCount
					edges {
						node {
							rowId
							title
							description
							image {
								url
								copyright
								title
							}
							link {
								url
								label
							}
							status
							updatedAt
							createdAt
							updatedBy
							createdBy
							authorByCreatedBy {
								firstname
								lastname
							}
							authorByUpdatedBy {
								firstname
								lastname
							}
							...LockedByAuthor_boardsTeaser
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps(props) {
			return props.query && props.query.boardTeasers;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			};
		},
		getVariables(_props, { count, cursor }, fragmentVariables) {
			return {
				...fragmentVariables,
				count,
				cursor,
			};
		},
		query: graphql`
			query BoardsTeasersTableQuery($count: Int, $cursor: Cursor, $searchTerm: String) {
				...BoardsTeasersTable_query
			}
		`,
	}
);

export default withTranslation(BoardsTeaserTablePagination);
