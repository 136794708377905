import { TYPE_AUTHOR } from '@sep/br24-constants';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { UploadChangeParam } from 'antd/lib/upload';
import { Component, type MouseEventHandler } from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql, type RelayProp } from 'react-relay';
import { type RouteComponentProps, withRouter } from 'react-router';
import styled, { css } from 'styled-components';

import routeConfig from '@/client/config/config.routes';
import { withTranslation } from '@/client/translation/withTranslation';
import { ASPECT_RATIO_SQUARE } from '@/client/ui/ImageCropper/ImageCropper';
import {
	ImageCropperModal,
	type ImageCropperModalResultHandler,
} from '@/client/ui/ImageCropper/ImageCropperModal';
import { filterNullOrUndefined } from '@/client/util/filter';

import config from '../../config';
import type { WithTranslationProps } from '../../translation';
import ErrorMessageText from '../../ui/ErrorMessageText';
import RichTextEditor from '../../ui/RichTextEditor';
import { createPreviewUrl } from '../../util/preview';
import { Page } from '../Page';
import SocialMediaAccounts from '../SocialMediaAccounts';
import SwitchLabel from '../SwitchLabel';

import { ProfilePicture } from './ProfilePicture';
import UpdateAuthorMutation from './UpdateAuthorMutation';
import type { Author_author$data } from './__generated__/Author_author.graphql';
import type { Author_authorArticles$data } from './__generated__/Author_authorArticles.graphql';
import validator from './util/validator';

const Wrapper = styled('div')`
	background: #fff;
	padding: 2rem 5%;
	margin: 0 auto 1rem auto;
`;

const centerAlignment = css`
	max-width: 800px;
	margin: 0 auto;
`;

const AuthorInformation = styled.div`
	${centerAlignment};

	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const AuthorInformationText = styled.div`
	margin-right: 10%;

	> p:not(:last-child) {
		margin-bottom: 0.6rem;
	}
`;

const AuthorSwitchLabel = styled.div`
	min-width: 120px;
`;

const StyledRow = styled(Row)`
	${centerAlignment};
`;

const FormItem = styled(Form.Item)`
	margin-bottom: 35px;
	width: 100%;
`;

const Footer = styled.div`
	${centerAlignment};

	text-align: right;
	> * {
		margin-right: 1rem;
	}

	> *:last-child {
		margin-right: 0;
	}
`;

type State = {
	buffer: Author_author$data;
	isFormDisabled: boolean;
	isImageEditorVisible: boolean;
	imageEditorUrl: string;
	uploadStatus: number | undefined | null;
	validation:
		| {
				[key: string]: Array<string>;
		  }
		| undefined
		| null;
	isSocialMediaEditActive: boolean;
};

interface Props extends WithTranslationProps, RouteComponentProps {
	author: Author_author$data;
	authorArticles?: Author_authorArticles$data | null;
	relay: RelayProp;
}

const FIRSTNAME_LENGTH_MAX = config.author.firstname.maxLength;
const LASTNAME_LENGTH_MAX = config.author.lastname.maxLength;
const JOBTITLE_LENGTH_MAX = config.author.jobTitle.maxLength;
const DESCRIPTION_LENGTH_MAX = config.author.description.maxLength;

class Author extends Component<Props, State> {
	state = {
		buffer: this.props.author,
		isFormDisabled: !this.props.author.isActive || false,
		isImageEditorVisible: false,
		imageEditorUrl: '',
		uploadStatus: null,
		validation: null as State['validation'],
		isSocialMediaEditActive: false,
	};

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyboardEvent);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyboardEvent);
	}

	getCleanedDescriptionLength = (): number => {
		const { buffer } = this.state;

		return (buffer.description || '')
			.replace(/<(?:.|\n)*?>/gm, '') // remove all breaks
			.replace(/<[^>]*>?/gm, '').length; // remove all html tags
	};

	// we need to make a validation workaround here because
	// if we validate against the value in the redux store
	// we have a different length than the cleaned in the local state
	// as the redux store has html tags in it; this leads to inconsistent validation
	// see: https://jira.ard.de/browse/BR24-487
	executeCustomValidation = () => {
		const {
			translation: { translate },
		} = this.props;

		notification.error({
			message: translate('author.save.error.message'),
			description: translate('author.save.error.description'),
			duration: 4,
		});
	};

	handleChangeUpload = (info: UploadChangeParam) => {
		const {
			translation: { translate },
		} = this.props;
		const { event } = info;
		const { status } = info.file;

		switch (status) {
			case 'uploading':
				this.setState({
					uploadStatus: event ? event.percent : 1,
				});
				break;

			case 'error':
				notification.error({
					message: translate('author.upload.error.description'),
					description: translate('author.upload.error.description', { name: info.file.name }),
					duration: 4,
				});
				this.setState({
					uploadStatus: null,
				});
				break;

			case 'done':
				{
					const { url } = info.file.response;

					this.setState({
						uploadStatus: null,
						isImageEditorVisible: true,
						imageEditorUrl: url,
					});
				}
				break;

			default:
				break;
		}
	};

	handleClickEdit = (event: Event) => {
		event.preventDefault();
	};

	handleDismiss: MouseEventHandler<HTMLElement> = (event) => {
		event.preventDefault();

		const { author } = this.props;

		this.setState({ buffer: author, isFormDisabled: !author.isActive });
	};

	handleImageEditorCancel = () => {
		this.setState({ isImageEditorVisible: false, imageEditorUrl: '' });
	};

	handleImageEditorOk: ImageCropperModalResultHandler = (url) => {
		this.writeToBuffer('profilePicture', url);
		this.setState({ isImageEditorVisible: false, imageEditorUrl: '' });
	};

	handleKeyboardEvent = (event: KeyboardEvent) => {
		const { buffer } = this.state;

		if ((event.metaKey || event.ctrlKey) && event.which === 83) {
			if (Object.keys(buffer).length === 0) {
				return;
			}

			event.preventDefault();
			this.handleSave?.();
		}
	};

	handlePreview = (event: Event) => {
		event.preventDefault();
	};

	handleSave: () => void = () => {
		if (this.getCleanedDescriptionLength() > DESCRIPTION_LENGTH_MAX) {
			this.executeCustomValidation();
		}

		const { buffer } = this.state;

		const validationState = validator(buffer);
		if (!validationState.isValid) {
			this.setState({ validation: validationState.error });
			return;
		}

		this.saveAuthor(this.state.buffer);
	};

	handleActiveStateChange = (isActive: boolean) => {
		const { author } = this.props;

		const authorData = {
			...author,
			isActive,
		};

		this.setState({ buffer: authorData, isFormDisabled: !isActive });
		this.saveAuthor({ isActive: isActive });
	};

	saveAuthor = async (data: Partial<Author_author$data>) => {
		const { slug, ...dataWithoutSlug } = data;

		const {
			author: { guid },
			translation: { translate },
		} = this.props;

		this.setState({ validation: null });

		if (!guid) {
			return;
		}

		try {
			UpdateAuthorMutation(guid, dataWithoutSlug, this.props.relay.environment);
			notification.success({
				message: translate('author.save.success.message'),
				description: translate('author.save.success.description'),
				duration: 3,
			});
		} catch (error: any) {
			notification.error({
				message: translate('author.save.error.message'),
				description: translate('author.save.error.description'),
				duration: 4,
			});
		}
	};

	writeToBuffer = <T extends keyof Author_author$data>(
		fieldName: T,
		value?: Author_author$data[T],
		onComplete?: () => void
	): void => {
		const { buffer } = this.state;

		this.setState({ buffer: { ...buffer, [fieldName]: value } }, onComplete);
	};

	handlePreviewAuthorProfile(author: Author_author$data) {
		window.open(createPreviewUrl(TYPE_AUTHOR, author), '_blank');
	}

	handleKeyPress(event: any) {
		// We need to disable the enter event because that would cause a problem for our
		// social media form.
		if (event.target.type !== 'textarea' && event.which === 13) {
			event.preventDefault();
		}
	}

	setSocialMediaEditActive = (isActive: boolean) => {
		this.setState({ isSocialMediaEditActive: isActive });
	};

	static inputProps = {
		size: 'large' as SizeType,
	};

	render() {
		const {
			author,
			translation: { translate },
		} = this.props;
		const {
			buffer,
			isFormDisabled,
			isImageEditorVisible,
			imageEditorUrl,
			uploadStatus,
			validation,
			isSocialMediaEditActive,
		} = this.state;

		const { isActive, firstname, lastname, jobTitle, profilePicture } = buffer;
		const description = buffer.description || '<p></p>';

		const descriptionLength = this.getCleanedDescriptionLength();
		const buttonDisabled =
			(isFormDisabled ? true : Object.keys(buffer).length === 0) || isSocialMediaEditActive;

		const authorArticles =
			!!this.props.authorArticles && Array.isArray(this.props.authorArticles.nodes) ? (
				this.props.authorArticles.nodes.map((article) => (
					<li key={String(article.rowId)}>
						<a href={`${routeConfig.article}/${String(article.rowId)}`}>{article.title}</a>
					</li>
				))
			) : (
				<li>Keine Artikel vorhanden.</li>
			);

		const socialMediaAccounts = (
			buffer?.socialMediaAccounts ??
			author?.socialMediaAccounts ??
			[]
		).filter(filterNullOrUndefined);

		return (
			<Page>
				<Helmet>
					<title>{`Autor "${String(firstname)} ${String(lastname)}"`}</title>
				</Helmet>

				<Page.Content>
					<Wrapper>
						<AuthorInformation>
							<AuthorInformationText>
								<h2>
									{isActive
										? translate('author.statusDescription.active.title')
										: translate('author.statusDescription.inactive.title')}
								</h2>
								<p>
									{isActive
										? translate('author.statusDescription.active.firstline')
										: translate('author.statusDescription.inactive.firstline')}
								</p>
								<p>
									{isActive
										? translate('author.statusDescription.active.secondline')
										: translate('author.statusDescription.inactive.secondline')}
								</p>
							</AuthorInformationText>
							<AuthorSwitchLabel>
								<SwitchLabel
									activeLabel={translate('author.statusLabel.active')}
									checked={Boolean(isActive)}
									inactiveLabel={translate('author.statusLabel.inactive')}
									onChange={(status) => this.handleActiveStateChange(status)}
								/>
							</AuthorSwitchLabel>
						</AuthorInformation>
					</Wrapper>

					<Wrapper>
						{profilePicture && isImageEditorVisible && (
							<ImageCropperModal
								hideMeta={true}
								aspect={ASPECT_RATIO_SQUARE}
								src={imageEditorUrl}
								onConfirm={this.handleImageEditorOk}
								onCancel={this.handleImageEditorCancel}
								circularCrop={true}
							/>
						)}
						<Form layout="vertical" onFinish={this.handleSave} onKeyPress={this.handleKeyPress}>
							<StyledRow>
								<Col span={24}>
									<ProfilePicture
										disabled={isFormDisabled}
										image={profilePicture}
										uploadStatus={uploadStatus}
										onEdit={() =>
											// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
											this.setState({ isImageEditorVisible: true, imageEditorUrl: profilePicture! })
										}
										onDelete={() => this.writeToBuffer('profilePicture')}
										onUpload={this.handleChangeUpload}
									/>
								</Col>
							</StyledRow>
							<StyledRow>
								<Col span={24}>
									<FormItem
										label={translate('author.firstname.label')}
										required={true}
										help={translate('author.characterCount', {
											count: firstname ? firstname.length : 0,
											max: FIRSTNAME_LENGTH_MAX,
										})}
										validateStatus={
											firstname && firstname.length > FIRSTNAME_LENGTH_MAX ? 'error' : ''
										}
									>
										<Input
											name="firstname"
											{...Author.inputProps}
											disabled={isFormDisabled}
											value={firstname ?? undefined}
											onChange={(event) => this.writeToBuffer('firstname', event.target.value)}
											placeholder={translate('author.firstname.placeholder')}
										/>
									</FormItem>
									<ErrorMessageText
										text={validation && validation.firstname ? validation.firstname : undefined}
										hasBottomMargin={true}
									/>
									<FormItem
										label={translate('author.lastname.label')}
										required={true}
										help={translate('author.characterCount', {
											count: lastname ? lastname.length : 0,
											max: LASTNAME_LENGTH_MAX,
										})}
										validateStatus={
											lastname && lastname.length > LASTNAME_LENGTH_MAX ? 'error' : undefined
										}
									>
										<Input
											name="lastname"
											{...Author.inputProps}
											disabled={isFormDisabled}
											value={lastname ?? undefined}
											onChange={(event) => this.writeToBuffer('lastname', event.target.value)}
											placeholder={translate('author.lastname.placeholder')}
										/>
									</FormItem>
									<ErrorMessageText
										text={validation && validation.lastname ? validation.lastname : undefined}
										hasBottomMargin={true}
									/>
									<FormItem
										label={translate('author.jobTitle.label')}
										required={true}
										help={translate('author.characterCount', {
											count: jobTitle ? jobTitle.length : 0,
											max: JOBTITLE_LENGTH_MAX,
										})}
										validateStatus={
											jobTitle && jobTitle.length > JOBTITLE_LENGTH_MAX ? 'error' : undefined
										}
									>
										<Input
											name="jobTitle"
											{...Author.inputProps}
											disabled={isFormDisabled}
											value={jobTitle ?? undefined}
											onChange={(event) => this.writeToBuffer('jobTitle', event.target.value)}
											placeholder={translate('author.jobTitle.placeholder')}
										/>
									</FormItem>
									<ErrorMessageText
										text={validation && validation.jobTitle ? validation.jobTitle : undefined}
										hasBottomMargin={true}
									/>
									<FormItem
										label={translate('author.description.label')}
										help={translate('author.characterCount', {
											count: descriptionLength,
											max: DESCRIPTION_LENGTH_MAX,
										})}
										validateStatus={
											descriptionLength > DESCRIPTION_LENGTH_MAX ? 'error' : undefined
										}
									>
										<RichTextEditor
											defaultValue={description}
											disabled={isFormDisabled}
											onChange={(value) => this.writeToBuffer('description', value)}
											placeholder={translate('author.description.placeholder')}
										/>
									</FormItem>
									<ErrorMessageText
										text={
											descriptionLength > DESCRIPTION_LENGTH_MAX
												? translate('author.validation.description', {
														max: DESCRIPTION_LENGTH_MAX,
												  })
												: ''
										}
										hasBottomMargin={true}
									/>
									{!isFormDisabled && (
										<FormItem label={translate('author.contact.label')}>
											<SocialMediaAccounts
												onChange={(socialMediaAccounts) =>
													this.writeToBuffer('socialMediaAccounts', socialMediaAccounts)
												}
												items={socialMediaAccounts}
												onEditStatChange={this.setSocialMediaEditActive}
											/>
										</FormItem>
									)}
								</Col>
							</StyledRow>
							<StyledRow>
								<span>Letzte 10 Artikel von {author.firstname}</span>
								<Col span={24}>
									<ul>{authorArticles}</ul>
								</Col>
							</StyledRow>
							<Footer>
								<Button disabled={buttonDisabled} size="large" onClick={this.handleDismiss}>
									{translate('author.buttonDismiss')}
								</Button>
								<Button
									disabled={buttonDisabled}
									size="large"
									onClick={() => this.handlePreviewAuthorProfile(author)}
								>
									{translate('author.buttonPreview')}
								</Button>
								<Button disabled={buttonDisabled} size="large" type="primary" htmlType="submit">
									{translate('author.buttonSave')}
								</Button>
							</Footer>
						</Form>
					</Wrapper>
				</Page.Content>
			</Page>
		);
	}
}

const AuthorCompose = withRouter(withTranslation(Author));

export default createFragmentContainer(AuthorCompose, {
	author: graphql`
		fragment Author_author on Author {
			guid
			isActive
			firstname
			lastname
			profilePicture
			jobTitle
			description
			slug
			socialMediaAccounts {
				service
				accountName
				label
			}
		}
	`,
	authorArticles: graphql`
		fragment Author_authorArticles on ArticlesConnection {
			nodes {
				rowId
				title
			}
		}
	`,
});
