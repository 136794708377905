/**
 * Ideally, this provider would not be necessary and instead
 * we'd use `useAuth` from `@sep/cms-auth-client`.
 *
 * However, for downwards compatibility, we've been using the following helpers.
 *
 * TODO:
 * We should be able to remove this file completely as long as useAuth
 * works properly. But then we'd need to make sure that the `recentUser` is
 * being used for the user prop instead of the current user.
 * */

import type { User } from '@sep/cms-auth-client';
import { createContext, type ReactElement, useContext } from 'react';

import { resetGraphqlSubscriptionClient } from '../environment';

export type AuthContextType = {
	logout: (options?: {
		onCancel?: () => void | Promise<void>;
		onConfirm?: () => void | Promise<void>;
	}) => void;
	user: User;
};

export const AuthContext = createContext<Partial<AuthContextType>>({});

export const useUser = () => {
	const { user } = useContext(AuthContext);
	return user;
};

export const useLogout = () => {
	// unsubscribe from all subscriptions and close subscription client
	resetGraphqlSubscriptionClient();

	const { logout } = useContext(AuthContext);
	return logout;
};

export const UserContainer = ({
	children,
}: {
	children: ReactElement | ((user: User | undefined) => ReactElement);
}) => {
	const user = useUser();

	if (typeof children === 'function') {
		return children(user);
	}

	return children;
};
