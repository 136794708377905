import type { CSSProperties, ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';

type Props = {
	children: ReactNode;
	className?: string;
	style?: CSSProperties;
};

const GlobalStyles = createGlobalStyle`
	body, html {
		font-family: Open Sans, Helvetica Neue, Arial, sans-serif;
		font-size: 12px;
	}

	html {
		min-height: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	body {
		height: auto;
	}
`;

export default function ({ children, className, style }: Props) {
	return (
		<main className={className} style={style}>
			<GlobalStyles />
			{children}
		</main>
	);
}
