import { Form, Input } from 'antd';
import { type FC, useCallback } from 'react';

import { NAVIGATION_TYPE_CUSTOM } from '@/client/constants';

import translations from '../translations';

export const NavigationEditorFormCustomLink: FC<{}> = () => {
	const form = Form.useFormInstance();

	const onBlur = useCallback(() => {
		let path = form.getFieldValue('path');

		if (
			typeof path === 'string' &&
			path.trim().length > 0 &&
			!(path.startsWith('http://') || path.startsWith('https://'))
		) {
			path = `https://${path}`;
		}

		form.setFieldsValue({ path: path.trim() });
	}, [form]);

	return (
		<>
			<Form.Item initialValue={NAVIGATION_TYPE_CUSTOM} name="type" hidden>
				<Input />
			</Form.Item>

			<Form.Item
				label={translations.NM_LINK_TITLE_LABEL}
				name="text"
				rules={[{ required: true, message: translations.NM_LINK_TITLE_ERROR, min: 3 }]}
			>
				<Input placeholder={translations.NM_LINK_TITLE_PLACEHOLDER} />
			</Form.Item>
			<Form.Item
				name="path"
				label={translations.NM_CUSTOM_LINK_LABEL}
				rules={[
					{
						type: 'url',
						required: true,
						message: translations.NM_CUSTOM_LINK_ERROR,
						whitespace: true,
					},
				]}
			>
				<Input onBlur={onBlur} placeholder={translations.NM_CUSTOM_LINK_PLACEHOLDER} />
			</Form.Item>
		</>
	);
};
