import { Layout } from 'antd';
import type { CSSProperties, PropsWithChildren } from 'react';

export function Page({
	children,
	style,
	className,
}: PropsWithChildren<{ style?: CSSProperties; className?: string }>) {
	return (
		<Layout
			className={className}
			style={{ paddingBottom: '4rem', backgroundColor: 'transparent', ...style }}
		>
			{children}
		</Layout>
	);
}

Page.Content = Layout.Content;
