import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Component, type ComponentProps, type ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

import ArticleMetaMoreLanguage from './ArticleMetaMoreLanguage';
import type { Language } from './languages';

export interface MoreChangeEvent {
	language: string;
}

export const CheckboxWrapper = styled(
	({ children, name, onChange, checked }: ComponentProps<typeof Checkbox>) => (
		<div>
			<Checkbox data-testid={`checkbox-${name}`} name={name} onChange={onChange} checked={checked}>
				{children}
			</Checkbox>
		</div>
	)
)``;

const SophoraSearchWrapper = styled.div`
	margin-top: 15px;
`;

type Props = WithTranslationProps & {
	allowComments?: boolean;
	enableAmp?: boolean;
	hasMigration: boolean;
	isLive?: boolean;
	externalId?: string;
	language?: Language;
	onChange: (key: string, value: boolean) => void;
	onChangeMigration: () => void;
	onSelectLanguage: (value: Language) => void;
	sophoraSearch: ReactNode;
	key: string | number;
};

const LanguageWrapper = styled.div`
	margin-bottom: 1rem;
`;

class ArticleMetaMore extends Component<Props> {
	handleChange = (event: CheckboxChangeEvent) => {
		const { onChange } = this.props;
		const target = event.target as HTMLInputElement;

		const changedValue = target.name;

		onChange(changedValue, target.checked);
	};

	render() {
		const {
			allowComments,
			enableAmp,
			isLive,
			externalId,
			language,
			onChangeMigration,
			onSelectLanguage,
			onChange,
			hasMigration,
			sophoraSearch,
			translation: { translate },
			...rest
		} = this.props;

		return (
			<ArticleMetaPanel
				title={translate('article.meta.more.title')}
				help={undefined}
				isRequired={false}
				{...rest}
			>
				<LanguageWrapper>
					<ArticleMetaMoreLanguage language={language} onSelectLanguage={onSelectLanguage} />
				</LanguageWrapper>
				<CheckboxWrapper name="isLive" onChange={this.handleChange} checked={isLive}>
					{translate('article.meta.more.labels.isLive')}
				</CheckboxWrapper>
				<CheckboxWrapper name="allowComments" onChange={this.handleChange} checked={allowComments}>
					{translate('article.meta.more.labels.allowComments')}
				</CheckboxWrapper>
				<CheckboxWrapper name="enableAmp" onChange={this.handleChange} checked={enableAmp}>
					{translate('article.meta.more.labels.enableAmp')}
				</CheckboxWrapper>
				<CheckboxWrapper name="hasMigration" onChange={onChangeMigration} checked={hasMigration}>
					{translate('article.meta.more.labels.hasMigration')}
				</CheckboxWrapper>
				{hasMigration ? <SophoraSearchWrapper>{sophoraSearch}</SophoraSearchWrapper> : null}
				{externalId ? <>Externe Id: {externalId}</> : null}
			</ArticleMetaPanel>
		);
	}
}

export default withTranslation(ArticleMetaMore);
