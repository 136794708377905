import { Modal, Button, Tooltip } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../translation';

const StyledLockData = styled.span`
	font-weight: bold;
`;

const StyledBreakLockButton = styled(Button)`
	margin-left: ${(props) => (props.disabled ? '8px' : '')};
`;

type Props = WithTranslationProps & {
	isVisible: boolean;
	editedBy: string;
	editedSince: string;
	onBackToOverview: () => void;
	onOpenReadOnlyView: () => void;
	onLockTakeover: () => void;
	onReloadDocument?: () => void;
};

type State = {
	refetchButtonDisabled: boolean;
};

class DocumentLockDialog extends Component<Props, State> {
	state = {
		refetchButtonDisabled: false,
	};

	refetchBtn: HTMLButtonElement | undefined | null;

	handleCancelDialog = () => {
		this.props.onBackToOverview();
	};

	handleLockTakeover = () => {
		this.setState({
			refetchButtonDisabled: true,
		});

		this.props.onLockTakeover();
	};

	handleOpenReadOnlyView = () => {
		this.props.onOpenReadOnlyView();
	};

	handleBackToOverView = () => {
		this.props.onBackToOverview();
	};

	render() {
		const {
			translation: { translate },
			isVisible,
			editedBy,
			editedSince,
		} = this.props;

		return (
			<Modal
				width={850}
				open={isVisible}
				title={translate('lockWarnings.contentLockDialog.title')}
				onCancel={this.handleCancelDialog}
				footer={[
					<Tooltip
						key="backToOverview"
						title={translate('lockWarnings.backToOverviewButton.tooltip')}
					>
						<Button type="primary" onClick={this.handleBackToOverView}>
							{translate('lockWarnings.backToOverviewButton.title')}
						</Button>
					</Tooltip>,
					<Tooltip key="readOnly" title={translate('lockWarnings.openReadOnlyButton.tooltip')}>
						<Button type="primary" onClick={this.handleOpenReadOnlyView}>
							{translate('lockWarnings.openReadOnlyButton.title')}
						</Button>
					</Tooltip>,
					<Tooltip key="lockTakeover" title={translate('lockWarnings.lockTakeoverButton.tooltip')}>
						<StyledBreakLockButton
							type="primary"
							onClick={this.handleLockTakeover}
							disabled={this.state.refetchButtonDisabled}
						>
							{translate('lockWarnings.lockTakeoverButton.title')}
						</StyledBreakLockButton>
					</Tooltip>,
				]}
			>
				<p>
					{translate('lockWarnings.contentLockDialog.textPiece1')}
					<StyledLockData>{editedSince}</StyledLockData>
					{translate('lockWarnings.contentLockDialog.textPiece2')}
					<StyledLockData>{editedBy}</StyledLockData>
					{translate('lockWarnings.contentLockDialog.textPiece3')}
				</p>
			</Modal>
		);
	}
}

export default withTranslation(DocumentLockDialog);
