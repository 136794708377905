export class LockError<T> extends Error {
	name = 'LockError';

	constructor(private reference: T) {
		super();
	}

	getReference(): T {
		return this.reference;
	}
}
