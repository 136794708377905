/* eslint-disable relay/unused-fields */
import { graphql } from 'react-relay';

graphql`
	fragment Navigation_item on Navigation {
		id # relay id (ignore)
		rowId # row id of navigation table
		type # BOARD | ARTICLE | CUSTOM | NAVIGATION
		parent # the parent rowId or null if it is a root item
		order # order of the item
		text # text to be used, null if type is board and text === board.title
		# fetch board data if it's a board
		boardByBoard {
			title
			rowId
		}

		articleByArticle {
			# fetch article data if it's an article
			title
			rowId
		}

		# -------- no longer fetch this as we don't see it being used
		path # HINT: path is the url src of a CUSTOM type link
		color # HINT: it seems we have different colors in our database. but there is no way to set it in the UI
		# --------
	}
`;

graphql`
	fragment Navigation_hierarchy on Navigation {
		...Navigation_item @relay(mask: false)
		# root children
		navigationsByParent {
			edges {
				node {
					...Navigation_item @relay(mask: false)

					# sub level children
					navigationsByParent {
						edges {
							node {
								...Navigation_item @relay(mask: false)
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * we use `@relay(mask: false)` because there is no realy UI yet
 * where we use the resulting data. We just pass it on to redux
 * and reformat it there
 */
export const navigationQuery = graphql`
	query NavigationQuery($rowId: Int!) {
		navigationByRowId(rowId: $rowId) {
			rowId
			...Navigation_hierarchy @relay(mask: false)
		}
	}
`;

export const navigationCreateMutation = graphql`
	mutation NavigationCreateMutation($input: CreateNavigationInput!) {
		createNavigation(input: $input) {
			navigation {
				...Navigation_item @relay(mask: false)
			}
		}
	}
`;

export const navigationDeleteMutation = graphql`
	mutation NavigationDeleteMutation($input: DeleteNavigationByRowIdInput!) {
		deleteNavigationByRowId(input: $input) {
			navigation {
				...Navigation_item @relay(mask: false)
			}
		}
	}
`;

export const navigationUpdateMutation = graphql`
	mutation NavigationUpdateMutation($input: UpdateNavigationByRowIdInput!) {
		updateNavigationByRowId(input: $input) {
			navigation {
				...Navigation_item @relay(mask: false)
			}
		}
	}
`;
