import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';

import { useTranslate } from '@/client/translation/useTranslate';

import TeaserImagePlaceholder from '../../../../images/placeholder.png';
import ClickHandler from '../ClickHandler';
import { DraggableItem } from '../DraggableItem';

import styles from './ArticleSearchItem.module.scss';
import type { ArticleSearchItem_article$data } from './__generated__/ArticleSearchItem_article.graphql';

type Props = {
	article: ArticleSearchItem_article$data;
	onRequestOpen: (rowId: string) => void;
};

function ArticleSearchItem({
	article: { rowId, title, capability, teaserImage, primaryCategory, publicationDate, status },
	onRequestOpen,
}: Props) {
	const translate = useTranslate();
	return (
		<DraggableItem
			className={styles.article}
			type="article"
			id={rowId}
			status={status}
			sectionId=""
			internalId=""
		>
			<div
				className={styles.articleTeaserImage}
				style={{
					backgroundImage: `url(${teaserImage || TeaserImagePlaceholder})`,
				}}
			/>
			<div className={styles.articleInfo}>
				<em style={{ color: primaryCategory?.color ?? '#444444' }}>
					{primaryCategory && primaryCategory.title}
				</em>
				<h3>{title}</h3>
				<p>{translate(`capabilities.${capability}`)}</p>
				<p>
					{translate('board.sectionItem.article.published')}
					{moment.utc(String(publicationDate)).local().fromNow()}.
				</p>
			</div>
			<div>
				<ClickHandler rowId={rowId} handleClick={onRequestOpen} />
			</div>
		</DraggableItem>
	);
}

export default createFragmentContainer(ArticleSearchItem, {
	article: graphql`
		fragment ArticleSearchItem_article on Article {
			rowId
			title
			capability
			status
			primaryCategory: categoryByPrimaryCategory {
				rowId
				title
				color
			}
			teaserImage
			publicationDate
		}
	`,
});
