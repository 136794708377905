import { commitMutation, graphql } from 'react-relay';

import environment from '../../../environment';

import type { ArticleEditorDeleteArticleTagMutation } from './__generated__/ArticleEditorDeleteArticleTagMutation.graphql';

const mutation = graphql`
	mutation ArticleEditorDeleteArticleTagMutation(
		$input: DeleteArticlesTagByArticleIdAndTagIdInput!
	) {
		deleteArticlesTagByArticleIdAndTagId(input: $input) {
			tagByTagId {
				count
				rowId
				text
			}
		}
	}
`;

export default (
	articleRowId: string,
	tagId: number
): Promise<ArticleEditorDeleteArticleTagMutation['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<ArticleEditorDeleteArticleTagMutation>(environment, {
			mutation,
			variables: {
				input: {
					articleId: articleRowId,
					tagId,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
