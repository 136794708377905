import { Select, Tooltip } from 'antd';
import { sortBy } from 'lodash-es';
import { useCallback } from 'react';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { Info } from '../../Icon';

import { type Language, languages } from './languages';

const FullWidthInput = styled(Select<Language>)`
	width: 100%;
	.ant-select-selection__choice {
		background-color: ${(props) => props.theme.colors.light1} !important;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const Span = styled.span`
	align-self: flex-end;
	margin-left: 1rem;
	margin-right: 1rem;
	font-size: 1.4rem;
`;

type Props = {
	language?: Language;
	onSelectLanguage: (value: Language) => void;
};

export default function ArticleMetaMoreLanguage({ language, onSelectLanguage }: Props) {
	const translate = useTranslate();

	const handleSelect = useCallback(
		(value: Language) => {
			if (value) {
				onSelectLanguage(value);
			}
		},
		[onSelectLanguage]
	);

	return (
		<Row>
			<FullWidthInput
				defaultValue={language}
				onSelect={handleSelect}
				placeholder={translate('article.meta.more.languagePlaceholder')}
			>
				{sortBy(Object.entries(languages), ([, [language]]) => language).map(
					([code, [language]]) => (
						<Select.Option
							// eslint-disable-next-line react/no-array-index-key
							key={`language-${code}`}
							value={code}
							data-value={code}
							label={language}
						>
							{language}
						</Select.Option>
					)
				)}
			</FullWidthInput>
			<Span>
				<Tooltip title={translate('article.meta.more.tooltip')}>
					<Info />
				</Tooltip>
			</Span>
		</Row>
	);
}
