import cn from 'classnames';
import { PureComponent } from 'react';

import styles from './Gallery.module.scss';

type Props = {
	title: string;
	image?: string;
	bordered?: boolean;
	borderColor?: string;
	draggable?: boolean;
	rounded?: boolean;
	className?: string | Array<string> | any;
	actions?: Array<any> | any;
};

export default class GalleryItem extends PureComponent<Props & JSX.IntrinsicElements['div']> {
	static defaultProps = {
		// TODO: fallback-image
		image: '#',
		bordered: false,
		draggable: false,
	};

	render() {
		const { title, image, rounded, bordered, borderColor, draggable, className, actions, ...rest } =
			this.props;
		const informationStyle: { borderBottomColor?: string } = {};

		if (bordered) {
			informationStyle.borderBottomColor = borderColor;
		}

		return (
			<div
				className={cn(styles.galleryItem, className, {
					[styles.draggable]: draggable,
					[styles.bordered]: bordered,
					[styles.rounded]: rounded,
				})}
				style={{ backgroundImage: `url(${image})` }}
				{...rest}
			>
				{title && (
					<div className={styles.galleryInformation} style={informationStyle}>
						<div className={styles.title}>{title}</div>
					</div>
				)}
				{actions ? <div className={styles.galleryActions}>{actions}</div> : false}
			</div>
		);
	}
}
