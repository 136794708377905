import { create } from 'urlify';

type UrlifyFunction = (value: string) => string;

const config = {
	addEToUmlauts: true,
	szToSs: true,
	spaces: '-',
	nonPrintable: '-',
	toLower: true,
};

export const urlify: UrlifyFunction = create({
	...config,
	trim: true,
});

export const urlifyOnChange: UrlifyFunction = create(config);

export const sanitizeUrl: UrlifyFunction = (value: string) => {
	let sanitizedValue = urlifyOnChange(value).replace(/--+/g, '-').replace(/-$/gm, '');
	// workaround, because replace with ˆ does not work (?)
	sanitizedValue = sanitizedValue.startsWith('-') ? sanitizedValue.substring(1) : sanitizedValue;

	return sanitizedValue;
};
