/**
 * @generated SignedSource<<40a7d2a9179ad8fb9c1be4f92d863166>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorListItemLoggedInUser_query$data = {
  readonly loggedInUser: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstname: string | null;
        readonly guid: string;
        readonly isActive: boolean | null;
        readonly isLdapUser: boolean | null;
        readonly lastname: string | null;
        readonly profilePicture: string | null;
      } | null;
    }>;
  } | null;
  readonly " $fragmentType": "AuthorListItemLoggedInUser_query";
};
export type AuthorListItemLoggedInUser_query$key = {
  readonly " $data"?: AuthorListItemLoggedInUser_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorListItemLoggedInUser_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "loggedInUserFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthorListItemLoggedInUser_query",
  "selections": [
    {
      "alias": "loggedInUser",
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "cursor"
        },
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "loggedInUserFilter"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "LASTNAME_ASC"
        }
      ],
      "concreteType": "AuthorsConnection",
      "kind": "LinkedField",
      "name": "allAuthors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthorsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Author",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "guid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastname",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePicture",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isActive",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isLdapUser",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4e7dca35754c3922c86bcfb96817ddbb";

export default node;
