import { Component } from 'react';
import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import {
	type WithRelayEnvironment,
	withRelayEnvironment,
} from '@/client/relay/withRelayEnvironment';
import { withTranslation } from '@/client/translation/withTranslation';

import { ArticleMetaTeasersSkeleton, ArticleMetaTeasersError } from '../../../../../ui/ArticleMeta';
import ArticleMetaTeasersItem from '../../../../../ui/ArticleMeta/ArticleMetaTeasers/ArticleMetaTeasersItem';

import { metaText } from './ContainerTeaserItem';
import type { ExternalProps } from './ContainerTeaserItemExternal';
import type {
	ContainerTeaserItemInternalQuery,
	ContainerTeaserItemInternalQuery$data,
} from './__generated__/ContainerTeaserItemInternalQuery.graphql';

type InternalProps = ExternalProps & {
	rowId: string;
	isDepublished: boolean;
};

class ContainerTeaserItemInternal extends Component<InternalProps & WithRelayEnvironment> {
	renderItem: QueryRendererProps<ContainerTeaserItemInternalQuery>['render'] = ({
		error,
		props,
	}) => {
		const {
			translation: { translate },
		} = this.props;

		if (this.props.isInvalid) {
			return (
				<ArticleMetaTeasersItem
					onRemove={this.props.onRemove}
					url={this.props.url}
					title=""
					isExternal={false}
					info={translate('article.meta.teasers.info.invalid')}
				/>
			);
		}
		if (error) {
			return <ArticleMetaTeasersError onRemove={this.props.onRemove} error={error.toString()} />;
		}
		if (!props || (!props.article && !props.board)) {
			return <ArticleMetaTeasersSkeleton onRemove={this.props.onRemove} />;
		}
		const isBoard = !!props.board;
		const isArticle = !!props.article;

		let date: string | null = null;
		let title: string | null = null;
		let teaserImage: string | null = null;
		let teaserText: string | null = null;
		let status: string | null | undefined = undefined;
		if (isBoard) {
			date = props.board.publicationDate;
			title = props.board.title;
			teaserImage = props.board.teaserImage?.url ?? null;
			teaserText = props.board.teaserText;
		} else if (isArticle) {
			date = props.article.publicationDate;
			title = props.article.title;
			teaserImage = props.article.teaserImage;
			teaserText = props.article.teaserText;
			status = props.article.status;
		} else {
			return (
				<ArticleMetaTeasersError
					onRemove={this.props.onRemove}
					error={translate(
						isArticle
							? 'article.meta.teasers.articleNotFoundError'
							: 'article.meta.teasers.boardNotFoundError'
					)}
				/>
			);
		}

		if (this.props.isDepublished) {
			return (
				<ArticleMetaTeasersItem
					onRemove={this.props.onRemove}
					url={this.props.url}
					isExternal={false}
					info={translate('article.meta.teasers.info.depublished')}
					editUrl={isArticle ? `/articleEditor/${this.props.rowId}` : `/boards/${this.props.rowId}`}
					meta={metaText(this.props.url, date ? new Date(date) : null, 'BR24')}
					title={title}
					description={teaserText ?? undefined}
					thumbnailUrl={teaserImage ?? undefined}
					status={status}
				/>
			);
		}

		return (
			<ArticleMetaTeasersItem
				onDataFetch={this.props.onDataFetch}
				index={this.props.index}
				onRemove={this.props.onRemove}
				editUrl={isArticle ? `/articleEditor/${this.props.rowId}` : `/boards/${this.props.rowId}`}
				url={this.props.url}
				isExternal={false}
				meta={metaText(this.props.url, date ? new Date(date) : null, 'BR24')}
				title={title}
				description={teaserText ?? undefined}
				thumbnailUrl={teaserImage ?? undefined}
				status={status}
			/>
		);
	};

	render() {
		if (this.props.meta) {
			const {
				date: publicationDate,
				description: teaserText,
				thumbnail: teaserImage,
				title,
			} = this.props.meta;

			return this.renderItem({
				props: {
					article: {
						publicationDate,
						teaserText,
						teaserImage,
						title,
					} as ContainerTeaserItemInternalQuery$data['article'],
					board: null,
				},
				error: null,
				retry() {
					// noop
				},
			});
		}

		return (
			<QueryRenderer<ContainerTeaserItemInternalQuery>
				environment={this.props.environment}
				query={graphql`
					query ContainerTeaserItemInternalQuery($rowId: String!) {
						article: articleByRowId(rowId: $rowId) {
							title
							publicationDate
							teaserImage
							teaserText
							status
						}

						board: boardByRowId(rowId: $rowId) {
							title
							publicationDate: updatedAt
							teaserImage: image {
								url
							}
							teaserText: description
						}
					}
				`}
				variables={{ rowId: this.props.rowId }}
				render={this.renderItem}
			/>
		);
	}
}
export default withTranslation(withRelayEnvironment(ContainerTeaserItemInternal));
