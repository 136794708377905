import { Spin } from 'antd';
import { PureComponent } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { District } from '../../../containers/ArticleEditor';
import type { WithTranslationProps } from '../../../translation';
import ErrorMessageText from '../../ErrorMessageText';
import { geoLocationToCoordinate, pointToGeoLocation } from '../../Map';
import type { MapChangeEvent } from '../../Map';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

import ArticleMetaGeolocationDistrict from './ArticleMetaGeolocationDistrict';
import ArticleMetaGeolocationLocation from './ArticleMetaGeolocationLocation';

const LocationWrapper = styled.div`
	margin-top: 1rem;
`;

const DistrictWrapper = styled.div`
	margin-top: 1rem;
`;

const LoadingSpinnerWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
`;

export type GeolocationChangeEvent = {
	districts?: Array<District> | null;
	geolocation?: string | null;
	location?: string;
};

type Props = WithTranslationProps & {
	districts?: Array<District> | null;
	geolocation?: string | null;
	isLoading: boolean;
	location?: string | null;
	onChange: (value: GeolocationChangeEvent) => void;
	onDistrictDeselect: (value: District) => void;
	onDistrictSelect: (value: District) => void;
	validation?: string | string[] | null;
	key: string | number;
};

class ArticleMetaGeolocation extends PureComponent<Props> {
	handleChangeDistricts = (districts?: District[] | null) => {
		this.props.onChange({ districts });
	};

	handleChangeGeolocation = (changes: MapChangeEvent) => {
		const { districts } = this.props;

		let changedValues: GeolocationChangeEvent = {
			geolocation: pointToGeoLocation(changes.point),
			location: changes.selectedLocationMeta
				? JSON.stringify(changes.selectedLocationMeta)
				: undefined,
		};

		const districtExists =
			districts && changes.selectedLocationMeta && changes.selectedLocationMeta.district
				? districts.includes(changes.selectedLocationMeta.district.toUpperCase() as District)
				: false;

		if (
			!districtExists &&
			changes.selectedLocationMeta &&
			changes.selectedLocationMeta.district &&
			changes.selectedLocationMeta.state === 'Bayern'
		) {
			changedValues = Object.assign(changedValues, {
				districts: [...(districts || []), changes.selectedLocationMeta.district.toUpperCase()],
			});

			// set found district value as region tag as well
			this.props.onDistrictSelect(changes.selectedLocationMeta.district.toUpperCase() as District);
		}

		this.props.onChange(changedValues);
	};

	handleDistrictDeselect = (value: District) => {
		this.props.onDistrictDeselect(value);
	};

	handleDistrictSelect = (value: District) => {
		this.props.onDistrictSelect(value);
	};

	render() {
		const {
			districts,
			geolocation,
			isLoading,
			location,
			translation: { translate },
			validation,
			onChange,
			onDistrictDeselect,
			onDistrictSelect,
			...rest
		} = this.props;

		return (
			<ArticleMetaPanel
				help={translate('article.meta.geolocation.help')}
				title={translate('article.meta.geolocation.title')}
				{...rest}
			>
				{isLoading ? (
					<LoadingSpinnerWrapper>
						<Spin />
					</LoadingSpinnerWrapper>
				) : (
					<>
						<ErrorMessageText text={validation ?? ''} hasBottomMargin={true} />
						<DistrictWrapper>
							<ArticleMetaGeolocationDistrict
								districts={districts}
								onChange={this.handleChangeDistricts}
								onSelect={this.handleDistrictSelect}
								onDeselect={this.handleDistrictDeselect}
							/>
						</DistrictWrapper>
						<LocationWrapper>
							<ArticleMetaGeolocationLocation
								location={typeof location === 'string' ? JSON.parse(location) : location || null}
								onChange={this.handleChangeGeolocation}
								point={geoLocationToCoordinate(geolocation)}
							/>
						</LocationWrapper>
					</>
				)}
			</ArticleMetaPanel>
		);
	}
}

export default withTranslation(ArticleMetaGeolocation);
