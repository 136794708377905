import type { AppThunkAction, Dispatch } from '@/client/store/redux';
import { filterNullOrUndefined } from '@/client/util/filter';

import type {
	AddItemsToImporterAction,
	BrBildSearchParameters,
	ImportResultsErrorAction,
	ImportResultsRequestAction,
	RawItemBrBildImage,
} from '../../..';
import config from '../../../../../config';
import { AM_IMPORTER_BRBILD, AM_TYPE_BRBILD_IMAGE } from '../../../../../constants';
import { AM_IMPORT_BRBILD } from '../../../actionTypes';
import {
	addItemGroupToImporter,
	addItemsToImporter,
	setItemGroupPagination,
} from '../../addItemsToImporter';
import { request, success, error, alreadyImported } from '../importUtils';

import { searchBrBild, getBrBildData } from './searchBrBild';

const createItemGroupTitle = (parameters: BrBildSearchParameters): string => {
	let itemGroupTitle = '';

	if (parameters?.terms) {
		itemGroupTitle = parameters.terms;

		if (parameters.imageTypes && parameters.imageTypes.length > 0) {
			itemGroupTitle += ' - ';
			itemGroupTitle += parameters.imageTypes.join(' ');
		}
		if (parameters.rightsOwner && parameters.rightsOwner.length > 0) {
			itemGroupTitle += ' - ';
			itemGroupTitle += parameters.rightsOwner.join(' ');
		}
	}

	return itemGroupTitle;
};

const brBildWithSearch = async (
	dispatch: Dispatch,
	searchParameters: BrBildSearchParameters,
	isImported: boolean,
	itemGroupTitle: string
) => {
	const res = await searchBrBild(searchParameters);

	if (!res?.nodes) {
		throw new Error(`Error while fetching search results from BR Bild: ${res}`);
	}

	const ids = res?.nodes.map((node) => node?.id);
	const data = await getBrBildData(ids);

	if (!data?.nodes) {
		throw new Error(`Error while fetching image data from BR Bild: ${data}`);
	}

	const items = data.nodes.filter(filterNullOrUndefined).map((node) => {
		const {
			imageId,
			title,
			description,
			copyright,
			copyrightNotice,
			copyrightStatus,
			usageConstraints,
			createdAt,
		} = node;
		const url = `${config.VITE_IMAGE_UPLOAD_URL_EXT}/brbild/streamImage/${imageId}/medium`;

		const newItem: RawItemBrBildImage = {
			type: AM_TYPE_BRBILD_IMAGE,
			url,
			title,
			altText: description,
			copyright,
			copyrightNotice,
			copyrightStatus,
			usageConstraints,
			timestamp: createdAt,
		};

		return newItem;
	});

	if (!isImported) {
		dispatch(addItemGroupToImporter(AM_IMPORTER_BRBILD, itemGroupTitle, searchParameters));
	}

	const hasNextPage =
		res.totalCount - items.length > (searchParameters?.offset ?? 0) && res.totalCount > 25;
	const lastCursor = searchParameters?.offset
		? `${searchParameters.offset + items.length}`
		: `${items.length}`;

	dispatch(setItemGroupPagination(AM_IMPORTER_BRBILD, itemGroupTitle, hasNextPage, lastCursor));

	dispatch(addItemsToImporter(AM_IMPORTER_BRBILD, items, itemGroupTitle));

	dispatch(success(AM_IMPORT_BRBILD));
};

const importBrBild =
	(
		mode: any,
		searchParameters: BrBildSearchParameters
	): AppThunkAction<
		| ImportResultsRequestAction<typeof AM_IMPORT_BRBILD>
		| ImportResultsErrorAction<typeof AM_IMPORT_BRBILD>
		| AddItemsToImporterAction
	> =>
	async (dispatch, getState) => {
		const { assetManager } = getState();

		if (assetManager.isImporterWorking || searchParameters.terms?.trim() === '') {
			return;
		}

		try {
			const { mode: currentMode } = mode;
			const itemGroupTitle = createItemGroupTitle(searchParameters);
			const isImported = alreadyImported(AM_IMPORTER_BRBILD, itemGroupTitle, assetManager);

			if (isImported) {
				if (currentMode !== 'fetchNextGroupPage') {
					return;
				}

				const currentItemGroup = assetManager.imports[AM_IMPORTER_BRBILD][itemGroupTitle];
				const { lastCursor } = currentItemGroup;

				dispatch(request(AM_IMPORT_BRBILD));

				brBildWithSearch(
					dispatch,
					{
						...searchParameters,
						offset: parseInt(lastCursor, 10),
					},
					isImported,
					itemGroupTitle
				);

				return;
			}

			dispatch(request(AM_IMPORT_BRBILD));

			brBildWithSearch(dispatch, searchParameters, isImported, itemGroupTitle);
		} catch (err: any) {
			dispatch(error(AM_IMPORT_BRBILD, err));
		}
	};

export default importBrBild;
