import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import type { SaveActionFunctionCallback, SaveActionErrorCallback, Status } from '..';
import ChangePubDateOverlay from '../../../ui/ChangePubDateOverlay/ChangePubDateOverlay';
import saveAction from '../actions/save';
import updateAction from '../actions/update';
import { articleStatusBarSelector } from '../selectors';

interface Props extends ReduxProps {
	isVisible: boolean;
	status: Status;
	onDisplayChange: (isVisible: boolean) => void;
	onSaveFinished: SaveActionFunctionCallback;
	onSaveError: SaveActionErrorCallback;
}

function ChangePubDateOverlayContainer({
	article,
	isVisible,
	save,
	status,
	update,
	onDisplayChange,
	onSaveFinished,
	onSaveError,
}: Props) {
	const handleCloseModal = () => {
		onDisplayChange(false);
	};

	const handleSave = (date: string | null) => {
		update({
			publicationDate: date,
		});

		onDisplayChange(false);

		save({ status }, onSaveFinished, onSaveError);
	};

	return (
		<ChangePubDateOverlay
			isVisible={isVisible}
			currentPublicationDate={article.publicationDate}
			articleStatus={article.status}
			onClose={handleCloseModal}
			onSave={handleSave}
		/>
	);
}

const connector = connect(
	(state: ReduxState) => ({
		article: {
			...articleStatusBarSelector(state),
		},
	}),
	{
		save: saveAction,
		update: updateAction,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ChangePubDateOverlayContainer);
