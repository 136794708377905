import type { TYPE_ARTICLE, TYPE_AUTHOR, TYPE_BOARD } from '@sep/br24-constants';
import type { SyntheticEvent, ReactElement, MouseEvent } from 'react';
import { Fragment, Component } from 'react';
import Headroom from 'react-headroom';

import {
	HeaderContainer,
	HeaderContainerPrimary,
	HeaderContainerSecondary,
} from './HeaderContainer';
import type { IndicatorType } from './HeaderContainer';
import HeaderCreatorButton from './HeaderCreatorButton';
import HeaderLogo from './HeaderLogo';
import {
	HeaderPrimaryNavigation,
	HeaderPrimaryNavigationList,
	HeaderPrimaryNavigationListItem,
} from './HeaderPrimaryNavigation';
import {
	HeaderSecondaryNavigation,
	HeaderSecondaryNavigationList,
	HeaderSecondaryNavigationListItem,
} from './HeaderSecondaryNavigation';
import enhanceNavigationChildren from './util/enhanceNavigationChildren';

export const TYPE_TEASER = 'TEASER';

export type CreatorType =
	| typeof TYPE_ARTICLE
	| typeof TYPE_BOARD
	| typeof TYPE_TEASER
	| typeof TYPE_AUTHOR;

type Props = {
	displayCreator?: boolean;
	hideHeaderOnScroll?: boolean;
	indicator?: IndicatorType;
	isWorking?: boolean;
	onClickCreator?: (event: MouseEvent<HTMLDivElement>, type: CreatorType) => void;
	onClickItem?: (
		event: SyntheticEvent<HTMLElement>,
		action: string,
		route: string,
		type: 'primary' | 'secondary'
	) => void;
	onClickLogo?: () => void;
	onClickPrimaryItem?: (event: SyntheticEvent<HTMLElement>, action: string, route: string) => void;
	onClickSecondaryItem?: (
		event: SyntheticEvent<HTMLElement>,
		action: string,
		route: string
	) => void;
	primaryItems?: Array<ReactElement<typeof HeaderPrimaryNavigationListItem>>;
	secondaryItems?: Array<ReactElement<typeof HeaderSecondaryNavigationListItem>>;
};

export default class Header extends Component<Props> {
	static defaultProps = {
		displayCreator: true,
		hideHeaderOnScroll: true,
		indicator: 'master',
		isWorking: false,
	};

	handleClickPrimaryNavigation = (
		event: SyntheticEvent<HTMLElement>,
		action: string,
		route: string
	) => {
		const { onClickPrimaryItem, onClickItem } = this.props;

		if (onClickPrimaryItem) {
			onClickPrimaryItem(event, action, route);
		}

		if (onClickItem) {
			onClickItem(event, action, route, 'primary');
		}
	};

	handleClickSecondaryNavigation = (
		event: SyntheticEvent<HTMLElement>,
		action: string,
		route: string
	) => {
		const { onClickSecondaryItem, onClickItem } = this.props;

		if (onClickSecondaryItem) {
			onClickSecondaryItem(event, action, route);
		}

		if (onClickItem) {
			onClickItem(event, action, route, 'secondary');
		}
	};

	handleClickTypeCreator = (event: MouseEvent<HTMLDivElement>, type: CreatorType) => {
		const { onClickCreator } = this.props;

		if (onClickCreator) {
			onClickCreator(event, type);
		}
	};

	render() {
		const {
			displayCreator,
			hideHeaderOnScroll,
			indicator,
			isWorking,
			onClickLogo,
			primaryItems,
			secondaryItems,
		} = this.props;
		/* istanbul ignore next */
		const HeaderMain = hideHeaderOnScroll ? Headroom : Fragment;

		return (
			<HeaderMain>
				<HeaderContainer
					indicator={indicator}
					isFixed={!hideHeaderOnScroll}
					isWorking={Boolean(isWorking)}
				>
					<HeaderContainerPrimary>
						<HeaderLogo onClick={onClickLogo} onKeyPress={onClickLogo} />
						{displayCreator && <HeaderCreatorButton onClickType={this.handleClickTypeCreator} />}
						{Array.isArray(primaryItems) && primaryItems.length > 0 ? (
							<HeaderPrimaryNavigation>
								<HeaderPrimaryNavigationList>
									{enhanceNavigationChildren(this.handleClickPrimaryNavigation, primaryItems)}
								</HeaderPrimaryNavigationList>
							</HeaderPrimaryNavigation>
						) : null}
					</HeaderContainerPrimary>
					<HeaderContainerSecondary>
						{Array.isArray(secondaryItems) && secondaryItems.length > 0 ? (
							<HeaderSecondaryNavigation>
								<HeaderSecondaryNavigationList>
									{enhanceNavigationChildren(this.handleClickSecondaryNavigation, secondaryItems)}
								</HeaderSecondaryNavigationList>
							</HeaderSecondaryNavigation>
						) : null}
					</HeaderContainerSecondary>
				</HeaderContainer>
			</HeaderMain>
		);
	}
}
