import { COLOR_BOARD_SECTION_4 } from '@sep/br24-constants';

export const HOST_BLACKLIST = ['preview.br24.de', 'preview-qs.br24.de', 'preview.br24-local.de'];

export const de_DE = 'de_DE';
export const de_BY = 'de_BY';
export const en_EN = 'en_EN';
export const AVAILABLE_LANGUAGES = [de_DE, de_BY, en_EN];

export const COOKIE_NAME_BR24_ACCESS_TOKEN_FOR_PREVIEW_MODE = 'br24_access_token';

export const APP_UPDATE_WORKING = 'APP_UPDATE_WORKING';
// test 4s
export const RIGHT_SIGNIN = 'signin';
export const RIGHT_SIGNOUT = 'signout';
export const RIGHT_ARTICLE_READ = 'article.read';
export const RIGHT_ARTICLE_CREATE = 'article.create';
export const RIGHT_ARTICLE_UPDATE = 'article.update';
export const RIGHT_ARTICLE_PUBLICATION = 'article.publication';
export const RIGHT_AUTHOR_READ = 'author.read';
export const RIGHT_AUTHOR_CREATE = 'author.create';
export const RIGHT_AUTHOR_UPDATE = 'author.update';
export const RIGHT_BOARD_READ = 'board.read';
export const RIGHT_BOARD_CREATE = 'board.create';
export const RIGHT_BOARD_UPDATE = 'board.update';
export const RIGHT_BOARD_PUBLICATION = 'board.publication';
export const RIGHT_NAVIGATION_READ = 'navigation.read';
export const RIGHT_NAVIGATION_CREATE = 'navigation.create';
export const RIGHT_NAVIGATION_UPDATE = 'navigation.update';

export const NAVIGATION_INITIALIZE = 'NAVIGATION_INITIALIZE';
export const NAVIGATION_UPDATE = 'NAVIGATION_UPDATE';
export const NAVIGATION_ADD = 'NAVIGATION_ADD';
export const NAVIGATION_BULK_UPDATE = 'NAVIGATION_BULK_UPDATE';
export const NAVIGATION_REMOVE = 'NAVIGATION_REMOVE';
export const NAVIGATION_BULK_REMOVE = 'NAVIGATION_BULK_REMOVE';
export const NAVIGATION_EDITOR_TOGGLE = 'NAVIGATION_EDITOR_TOGGLE';
export const NAVIGATION_EDITOR_UPDATE = 'NAVIGATION_EDITOR_UPDATE';
export const NAVIGATION_CLEAN_BUFFER = 'NAVIGATION_CLEAN_BUFFER';
export const NAVIGATION_SAVE = 'NAVIGATION_SAVE';
export const NAVIGATION_VALIDATE = 'NAVIGATION_VALIDATE';

export const NM_DEFAULT_BUFFER_COLOR = COLOR_BOARD_SECTION_4;

export const NAVIGATION_TYPE_NAVIGATION = 'NAVIGATION';
export const NAVIGATION_TYPE_CUSTOM = 'CUSTOM';
export const NAVIGATION_TYPE_BOARD = 'BOARD';
export const NAVIGATION_TYPE_ARTICLE = 'ARTICLE';

export const AM_HOVER_ACTION_ACCEPT = 'AM_HOVER_ACTION_ACCEPT';
export const AM_HOVER_ACTION_COPY = 'AM_HOVER_ACTION_COPY';
export const AM_HOVER_ACTION_DRAG = 'AM_HOVER_ACTION_DRAG';
export const AM_HOVER_ACTION_EDIT = 'AM_HOVER_ACTION_EDIT';
export const AM_HOVER_ACTION_MOVE = 'AM_HOVER_ACTION_MOVE';
export const AM_HOVER_ACTION_TRASH = 'AM_HOVER_ACTION_TRASH';

export const AM_HOVER_ACTION_ALL = [
	AM_HOVER_ACTION_ACCEPT,
	AM_HOVER_ACTION_COPY,
	AM_HOVER_ACTION_DRAG,
	AM_HOVER_ACTION_EDIT,
	AM_HOVER_ACTION_MOVE,
	AM_HOVER_ACTION_TRASH,
] as const;

export const AM_SORT_DIRECTION_RECENT = 'AM_SORT_DIRECTION_RECENT';
export const AM_SORT_DIRECTION_OLDEST = 'AM_SORT_DIRECTION_OLDEST';
export const AM_SORT_DIRECTION_ALPHABETICAL_ASC = 'AM_SORT_DIRECTION_ALPHABETICAL_ASC';
export const AM_SORT_DIRECTION_ALPHABETICAL_DESC = 'AM_SORT_DIRECTION_ALPHABETICAL_DESC';

export const AM_TYPE_BOARD_TEASER = 'AM_TYPE_BOARD_TEASER';
export const AM_TYPE_BOARD = 'AM_TYPE_BOARD';
export const AM_TYPE_ARTICLE = 'AM_TYPE_ARTICLE';
export const AM_TYPE_SOPHORA_ARTICLE = 'AM_TYPE_SOPHORA_ARTICLE';
export const AM_TYPE_TEXT = 'AM_TYPE_TEXT';
export const AM_TYPE_LINK = 'AM_TYPE_LINK';
export const AM_TYPE_EMBED_CODE = 'AM_TYPE_EMBED_CODE';
export const AM_TYPE_GALLERY = 'AM_TYPE_GALLERY';
export const AM_TYPE_BRBILD_IMAGE = 'AM_TYPE_BRBILD_IMAGE';
export const AM_TYPE_IMAGE = 'AM_TYPE_IMAGE';
export const AM_TYPE_AUDIO = 'AM_TYPE_AUDIO';
export const AM_TYPE_VIDEO360 = 'AM_TYPE_VIDEO360';
export const AM_TYPE_VIDEO = 'AM_TYPE_VIDEO';
export const AM_TYPE_LIVESTREAM = 'AM_TYPE_LIVESTREAM';
export const AM_TYPE_GROUP = 'AM_TYPE_GROUP';

export const AM_TYPE_ALL = [
	AM_TYPE_BOARD_TEASER,
	AM_TYPE_BOARD,
	AM_TYPE_ARTICLE,
	AM_TYPE_TEXT,
	AM_TYPE_LINK,
	AM_TYPE_EMBED_CODE,
	AM_TYPE_GALLERY,
	AM_TYPE_IMAGE,
	AM_TYPE_BRBILD_IMAGE,
	AM_TYPE_AUDIO,
	AM_TYPE_VIDEO360,
	AM_TYPE_VIDEO,
	AM_TYPE_LIVESTREAM,
	AM_TYPE_GROUP,
] as const;

export const AM_TYPE_FILTER = [
	AM_TYPE_BOARD_TEASER,
	AM_TYPE_BOARD,
	AM_TYPE_ARTICLE,
	AM_TYPE_TEXT,
	AM_TYPE_LINK,
	AM_TYPE_EMBED_CODE,
	AM_TYPE_GALLERY,
	AM_TYPE_IMAGE,
	AM_TYPE_AUDIO,
	AM_TYPE_VIDEO360,
	AM_TYPE_VIDEO,
	AM_TYPE_LIVESTREAM,
];

export const AM_SECTION_DIVIDER = 'AM_SECTION_DIVIDER';

export const AM_IMPORTER_SOPHORA = 'sophora';
export const AM_IMPORTER_SOPHORA_ARTICLE = 'sophora_article';
export const AM_IMPORTER_LIVESTREAMS = 'livestreams';
export const AM_IMPORTER_NEWSROOM = 'newsroom';
export const AM_IMPORTER_BRBILD = 'brbild';

export const AM_PERSONAL_SPACE = 'personal';
export const AM_GLOBAL_SPACE = 'global';

export const AM_MODE_HARVEST = 'AM_MODE_HARVEST';
export const AM_MODE_FILTER = 'AM_MODE_FILTER';
export const AM_MODE_EDIT = 'AM_MODE_EDIT';
export const AM_MODE_IMPORT = 'AM_MODE_IMPORT';

export const AM_DEFAULT_GROUP = 'DEFAULT_GROUP';

export const IMPORTER_DATE_FORMAT = 'DD.MM.YYYY';

export const FEATURE_GLOBAL_SPACE = 'FEATURE_GLOBAL_SPACE';

export const HOTKEY_TOGGLE_ASSETMANAGER_VISIBILITY = 'alt+a';
export const HOTKEY_ASSETMANAGER_TOGGLE_MODE_HARVEST = 'alt+h';
export const HOTKEY_ASSETMANAGER_TOGGLE_MODE_FILTER = 'alt+f';
export const HOTKEY_ASSETMANAGER_TOGGLE_MODE_EDIT = 'alt+e';
export const HOTKEY_ASSETMANAGER_TOGGLE_MODE_IMPORT = 'alt+i';
export const HOTKEY_ASSETMANAGER_APPLY_FILTER = 'enter';

export const AUTHORS_FILTERS_ACTIVITY_ACTIVE = 'AUTHORS_FILTERS_ACTIVITY_ACTIVE';
export const AUTHORS_FILTERS_ACTIVITY_ALL = 'AUTHORS_FILTERS_ACTIVITY_ALL';
export const AUTHORS_FILTERS_ACTIVITY_INACTIVE = 'AUTHORS_FILTERS_ACTIVITY_INACTIVE';
export const AUTHORS_FILTERS_LOGIN_TYPE_ALL = 'AUTHORS_FILTERS_LOGIN_TYPE_ALL';
export const AUTHORS_FILTERS_LOGIN_TYPE_LDAP = 'AUTHORS_FILTERS_LOGIN_TYPE_LDAP';
export const AUTHORS_FILTERS_LOGIN_TYPE_PROFILE = 'AUTHORS_FILTERS_LOGIN_TYPE_PROFILE';
