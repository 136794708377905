/**
 * @generated SignedSource<<743842012857b244f51c3a9310cd58a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CombineToStoryInput = {
  affectedArticles?: ReadonlyArray<string | null> | null;
  clientMutationId?: string | null;
  storyId?: any | null;
};
export type CombineToStoryMutation$variables = {
  input: CombineToStoryInput;
};
export type CombineToStoryMutation$data = {
  readonly combineToStory: {
    readonly articles: ReadonlyArray<{
      readonly articlesInStoryCount: number | null;
      readonly id: string;
      readonly rowId: string;
      readonly title: string;
    } | null> | null;
  } | null;
};
export type CombineToStoryMutation = {
  response: CombineToStoryMutation$data;
  variables: CombineToStoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CombineToStoryPayload",
    "kind": "LinkedField",
    "name": "combineToStory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articles",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "articlesInStoryCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CombineToStoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CombineToStoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b970bbbcdf0e48dfd914556968ccdd30",
    "id": null,
    "metadata": {},
    "name": "CombineToStoryMutation",
    "operationKind": "mutation",
    "text": "mutation CombineToStoryMutation(\n  $input: CombineToStoryInput!\n) {\n  combineToStory(input: $input) {\n    articles {\n      id\n      rowId\n      title\n      articlesInStoryCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "13a31a66d7ec4e8e8a0b706f4d2a9125";

export default node;
