import styled from 'styled-components';

import type { SlateRenderNodeProps } from './formattingTypes';

const UnorderedList = styled.ul`
	margin-left: 1rem;
	li {
		list-style: square inside none;
	}
`;

const OrderedList = styled.ol`
	margin-left: 1rem;
	li {
		list-style: decimal inside none;
	}
`;

export function UnorderedListNode({ attributes, children }: SlateRenderNodeProps) {
	return <UnorderedList {...attributes}>{children}</UnorderedList>;
}

export function OrderedListNode({ attributes, children }: SlateRenderNodeProps) {
	return <OrderedList {...attributes}>{children}</OrderedList>;
}
