/**
 * @generated SignedSource<<0d1f7cf566b323d811eaf9aec177f865>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BRBildRequestConfig = {
  sessionId: string;
};
export type searchBrBildMetadataQuery$variables = {
  config?: BRBildRequestConfig | null;
  ids?: ReadonlyArray<string | null> | null;
};
export type searchBrBildMetadataQuery$data = {
  readonly brBildAPI: {
    readonly metadata: {
      readonly nodes: ReadonlyArray<{
        readonly copyright: string | null;
        readonly copyrightNotice: string | null;
        readonly copyrightStatus: string | null;
        readonly createdAt: string | null;
        readonly description: string | null;
        readonly format: string | null;
        readonly id: string;
        readonly imageId: string;
        readonly producer: string | null;
        readonly provider: string | null;
        readonly title: string | null;
        readonly type: string;
        readonly usageConstraints: ReadonlyArray<string | null> | null;
      } | null> | null;
    } | null;
  } | null;
};
export type searchBrBildMetadataQuery = {
  response: searchBrBildMetadataQuery$data;
  variables: searchBrBildMetadataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "config"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BRBildAPI",
    "kind": "LinkedField",
    "name": "brBildAPI",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "config",
            "variableName": "config"
          },
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "BRBildMetadataConnection",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BRBildMetadataNode",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "provider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "producer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyright",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyrightNotice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyrightStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usageConstraints",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "format",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "searchBrBildMetadataQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "searchBrBildMetadataQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82d5cf9a3c155d9a5176bb242633c0db",
    "id": null,
    "metadata": {},
    "name": "searchBrBildMetadataQuery",
    "operationKind": "query",
    "text": "query searchBrBildMetadataQuery(\n  $config: BRBildRequestConfig\n  $ids: [String]\n) {\n  brBildAPI {\n    metadata(config: $config, ids: $ids) {\n      nodes {\n        id\n        imageId\n        type\n        title\n        description\n        provider\n        producer\n        copyright\n        copyrightNotice\n        copyrightStatus\n        usageConstraints\n        createdAt\n        format\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e84069fa8591249569395dfb9fee14c8";

export default node;
