import {
	STATUS_CANNOT_PLACE,
	STATUS_NOT_WHITELISTED,
	STATUS_ALREADY_ON_THE_LIST,
} from '@sep/br24-constants';
import * as util from '@sep/br24-util';
import { Button, notification } from 'antd';
import ih from 'immutability-helper';
import { useState, useEffect, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import type { DropTargetMonitor } from 'react-dnd';
import type { ConnectedProps } from 'react-redux';
import { useRelayEnvironment } from 'react-relay';
import { Environment, fetchQuery, graphql } from 'relay-runtime';
import styled from 'styled-components';

import { AM_TYPE_ARTICLE, AM_TYPE_BOARD, AM_TYPE_BOARD_TEASER } from '@/client/constants';
import type {
	ImportedItem,
	Item,
	ItemArticle,
	ItemBoard,
	ItemBoardTeaser,
} from '@/client/containers/AssetManager';
import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { useTranslate } from '@/client/translation/useTranslate';
import { filterNullOrUndefined } from '@/client/util/filter';
import type { Status } from '@/types/schema';

import type { TeaserPatch, UpdateInput } from '../..';
import {
	ArticleMetaTeasers,
	ArticleMetaTeasersItemFresh,
	ArticleMetaTeasersDropzone,
	ArticleMetaTeasersDropzoneWrapper,
	ArticleMetaTeasersImporterSearch,
} from '../../../../ui/ArticleMeta';
import { toMZT } from '../../DragAndDropHandler/dndUtils';
import searchInNewsroomImporter from '../../actions/searchInNewsroomImporter';
import update from '../../actions/update';
import { getErrorMessage } from '../../notifications';
import { articleLinksSelector, articleInfoValidationSelector } from '../../selectors';

import LinkItem from './LinkItem';
import type { ContainerTeasersArticleURLByRowIdQuery } from './__generated__/ContainerTeasersArticleURLByRowIdQuery.graphql';

interface ContainerTeasersProps extends ReduxProps {
	key: string | number;
}

const defaultItem: TeaserPatch = {
	url: '',
	isFresh: true,
	label: null,
	article: null,
	board: null,
	meta: null,
};

const defaultMeta = {
	label: null,
	description: '',
	thumbnailUrl: '',
	publicationDate: new Date(),
};

const AddButton = styled(Button)`
	margin-bottom: 2rem;
	display: block;
	width: 100%;
`;

const getBR24UrlFromRowId = async (
	rowId: string,
	environment: Environment
): Promise<string | null> => {
	const data = await fetchQuery<ContainerTeasersArticleURLByRowIdQuery>(
		environment,
		graphql`
			query ContainerTeasersArticleURLByRowIdQuery($rowId: String!) {
				article: articleByRowId(rowId: $rowId) {
					shareUrl
					status
				}
				board: boardByRowId(rowId: $rowId) {
					shareUrl
					status
				}
				boardsTeaser: boardsTeaserByRowId(rowId: $rowId) {
					link {
						url
					}
				}
			}
		`,
		{
			rowId,
		}
	).toPromise();

	if (!data) {
		return null;
	}

	/**
	 * do not allow to process the data if there is no article or board
	 * do not allow to add an article to the teasers in ("Link Empfehlung") if the article or board is not published
	 */
	if (data && !data.article && !data.board) {
		notification.error(getErrorMessage(STATUS_CANNOT_PLACE));
		return null;
	}

	if (
		(data.article && data.article.status.toUpperCase() !== 'PUBLISHED') ||
		(data.board && data.board.status?.toUpperCase() !== 'PUBLISHED')
	) {
		// TODO: A more specific error message would be good here
		notification.error(getErrorMessage(STATUS_CANNOT_PLACE));
		return null;
	}

	const boardTeaserUrl = data.boardsTeaser?.link?.url;
	// TeaserLinks can link to anything at the moment, so if there is no rowId we should still return the url
	if (boardTeaserUrl) {
		const articleOrBoardRowId = boardTeaserUrl.split(',').splice(1, 2);
		return articleOrBoardRowId.length > 0
			? `${boardTeaserUrl},${articleOrBoardRowId}`
			: boardTeaserUrl;
	}

	const articleUrl = data.article && data.article.shareUrl;
	const boardUrl = data.board && data.board.shareUrl;
	const url = articleUrl || boardUrl;
	if (url === null) {
		return null;
	}
	const urlWithRowIdRemoved = url.split(',').splice(0, 1);
	return urlWithRowIdRemoved.length > 0 ? `${urlWithRowIdRemoved},${rowId}` : null;
};

const drop = (
	monitor: DropTargetMonitor<{ importedItem?: ImportedItem; item: ImportedItem }>,
	add: ContainerTeasersProps['add'],
	environment: Environment
) => {
	if (monitor.didDrop()) {
		return undefined;
	}

	const item = monitor.getItem().item || monitor.getItem().importedItem;
	if (!item) {
		return undefined;
	}

	if (hasRowId(item)) {
		const rowId = item['articleId'] || item['boardId'] || item['boardTeaserId'];
		// ! The drop effects forcing these to be copies aren't actually doing anything because it is jumping to the return value on line 175.
		// TODO: Find another way to make these failure drops a copy since drop can't be async
		getBR24UrlFromRowId(rowId, environment).then((url) => {
			if (!url) {
				return { dropEffect: 'copy' };
			}

			// Because it was originally a linkTeaser and could be anything
			const hostname = new URL(url).hostname;
			const isWhitelisted = util.url.hostIsWhitelisted(hostname);
			if (!isWhitelisted) {
				notification.error(getErrorMessage(STATUS_NOT_WHITELISTED));
				return { dropEffect: 'copy' };
			}

			let article: null | { rowId: string; status: Status } = null;
			let board: null | { rowId: string; status: Status } = null;
			if (item.type === 'AM_TYPE_ARTICLE') {
				article = {
					rowId,
					status: 'PUBLISHED',
				};
			} else if (item.type === 'AM_TYPE_BOARD') {
				board = {
					rowId,
					status: 'PUBLISHED',
				};
			}

			add({ article, board, url, label: null, isFresh: false, meta: null });
			return undefined;
		});
	} else {
		const url = item['url'];
		if (!url) {
			notification.error(getErrorMessage()); /** This should throw a default error message. */
			return { dropEffect: 'copy' };
		}
		add({ url, label: null, isFresh: false, article: null, board: null, meta: null });

		return undefined;
	}

	return { dropEffect: 'copy' };
};

function ContainerTeasers(props: ContainerTeasersProps) {
	const translate = useTranslate();
	const [addingNewItem, setAddingNewItem] = useState(false);

	const environment = useRelayEnvironment();

	// componentDidMount
	useEffect(() => {
		const { links, invalidLinks, rawLinks } = props;
		if (Array.isArray(links) && Array.isArray(rawLinks) && links.length !== rawLinks.length) {
			if (Array.isArray(invalidLinks) && invalidLinks.length > 0) {
				props.update({
					links: [
						...links,
						...invalidLinks.map((link) => ({ ...link, article: null, meta: null, board: null })),
					],
				} as UpdateInput);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleItemSave = useCallback(
		(url: string) => {
			const newItem = { ...defaultItem, ...defaultMeta, url, isLoading: false, isFresh: false };
			props.add({ newItem, url, label: null, meta: null, article: null, board: null });
		},
		[props]
	);

	const handleAdd = useCallback(() => {
		if (!addingNewItem) {
			setAddingNewItem(true);
		}
	}, [addingNewItem]);

	const [{ isOver }, dropTarget] = useDrop(
		() => ({
			accept: toMZT,
			drop: (_item, monitor) => drop(monitor, props.add, environment),
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		}),
		[props.add, environment]
	);

	const {
		links,
		validation,
		add,
		invalidLinks,
		move,
		rawLinks,
		remove,
		searchInNewsroomImporter,
		update,
		updateLabelFromMeta,
		...rest
	} = props;
	const hasLinks = Array.isArray(links) && links.length > 0;

	return (
		<ArticleMetaTeasers
			validation={validation && validation.links ? validation.links : null}
			{...rest}
		>
			<div ref={dropTarget}>
				<ArticleMetaTeasersImporterSearch
					onSubmit={(term: string) => props.searchInNewsroomImporter(term)}
				/>

				{!addingNewItem && (
					<AddButton type="primary" onClick={handleAdd}>
						{translate('article.meta.teasers.addButtonLabel')}
					</AddButton>
				)}
				{addingNewItem && (
					<ArticleMetaTeasersItemFresh
						onSave={handleItemSave}
						onCancel={() => setAddingNewItem(false)}
						existingUrls={
							hasLinks ? links.map((link) => link?.url).filter(filterNullOrUndefined) : []
						}
					/>
				)}
				<ArticleMetaTeasersDropzoneWrapper>
					{hasLinks
						? links
								.filter(filterNullOrUndefined)
								.map((item, index) => (
									<LinkItem
										key={item.url}
										index={index}
										item={item}
										move={props.move}
										invalidLinks={props.invalidLinks?.filter(filterNullOrUndefined) ?? []}
										updateLabelFromMeta={props.updateLabelFromMeta}
										onRemove={props.remove}
									/>
								))
						: null}

					{isOver && (
						<ArticleMetaTeasersDropzone className={hasLinks ? 'has-links' : 'no-links'}>
							{translate(`modules.attachedDropzone.title`)}
						</ArticleMetaTeasersDropzone>
					)}
				</ArticleMetaTeasersDropzoneWrapper>
			</div>
		</ArticleMetaTeasers>
	);
}

const connector = connect(
	(state: ReduxState) => ({
		...articleLinksSelector(state),
		...articleInfoValidationSelector(state),
	}),
	{
		update,
		searchInNewsroomImporter,
	},
	(propsFromState, propsFromDispatch, ownProps) => ({
		...propsFromState,
		...propsFromDispatch,
		...ownProps,
		// add a new link
		add: (data: TeaserPatch) => {
			if (!data.url) {
				notification.error(getErrorMessage()); /** This should throw a default error message. */
				return;
			}
			const urlIsAlreadyInList =
				propsFromState.links && propsFromState.links.some((link) => link?.url === data.url);
			if (urlIsAlreadyInList) {
				notification.error(
					getErrorMessage(STATUS_ALREADY_ON_THE_LIST)
				); /** This should throw a default error message. */
				return;
			}
			propsFromDispatch.update({
				links: ih(propsFromState.links, {
					$set: Array.isArray(propsFromState.links) ? [...propsFromState.links, data] : [data],
				}),
			});
		},
		// copies the title property returned by Heavy Meta into the article link's label property (required by older verisons of apps as thats the property they are accessing)
		updateLabelFromMeta: (index: number, title: string) => {
			if (Array.isArray(propsFromState.links)) {
				const nextLinks = propsFromState.links.slice();
				const affectedLink = nextLinks[index];

				if (affectedLink && affectedLink?.label !== title) {
					nextLinks[index] = {
						...affectedLink,
						label: title,
						article: null,
						board: null,
						meta: null,
					};
					propsFromDispatch.update({ links: nextLinks });
				}
			}
		},
		// remove a existing link
		remove: (index: number) => {
			if (Array.isArray(propsFromState.links)) {
				propsFromDispatch.update({
					links: ih(propsFromState.links, {
						$splice: [[index, 1]],
					}),
				});
			}
		},
		// move a item after dragging
		move: (dragIndex: number, hoverIndex: number) => {
			if (Array.isArray(propsFromState.links)) {
				const draggedCard = propsFromState.links[dragIndex];
				propsFromDispatch.update({
					links: ih(propsFromState.links, {
						$splice: [
							[dragIndex, 1],
							[hoverIndex, 0, draggedCard],
						],
					}),
				});
			}
		},
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ContainerTeasers);

function hasRowId(item: ImportedItem | Item): item is ItemArticle | ItemBoard | ItemBoardTeaser {
	return (
		item.type === AM_TYPE_ARTICLE ||
		item.type === AM_TYPE_BOARD ||
		item.type === AM_TYPE_BOARD_TEASER
	);
}
