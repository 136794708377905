import { STATUS_PUBLISHED } from '@sep/br24-constants';
import { Tooltip } from 'antd';
import moment from 'moment';
import { DragPreviewImage, useDrag } from 'react-dnd';
import styled from 'styled-components';

import dragPreviewImage from '@/client/images/newsroom_drag.png';
import { displayName } from '@/client/util/br24Utils';

import { AE_ARTICLE } from '../../../containers/ArticleEditor/itemTypes';
import translations from '../../../translations';
import { media } from '../../../util/styled';
import { LockedByAuthorArticle } from '../../LockedByAuthor';
import PriorityIndicator from '../../PriorityIndicator';
import Status from '../../Status';

import { ArticleIcon } from './ArticleIcon';
import { ArticleNotes } from './ArticleNotes';
import ArticleOrigin from './ArticleOrigin';
import type { TableRowListeners } from './ArticlesTable';
import ArticlesTableRowButtons from './ArticlesTableRowButtons';
import ArticlesTableRowDate from './ArticlesTableRowDate';
import ArticlesTableRowTitle from './ArticlesTableRowTitle';
import type { ArticlesTableArticle } from './types';

type Props = TableRowListeners & {
	isSelected: boolean;
	node: ArticlesTableArticle;
	dateKey: 'firstPublicationDate' | 'publicationDate' | 'lastEdited';
};

const CategoryIndicator = styled.td<{ color?: string | null }>`
	width: 10px;
	height: 4em;
	border-block: 1px solid white;
	background-color: ${(props) => (props.color ? props.color : '#cccccc')};

	${media.lessThan('monster')`
		.am-open {
			height: 6em;
		}
	`};

	${media.lessThan('giant')`
		height: 6em;
	`};
`;

interface TableFlexProps {
	width: number;
	center?: boolean;
	desktopW?: number;
	hide?: boolean;
	mobileW?: number;
	sHide?: boolean;
}

const TableFlex = styled.td<TableFlexProps>`
	flex-basis: ${(props) => props.width}vw;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;

	${media.lessThan<TableFlexProps>('monster')`
		.am-open {
			width: ${(props) => (props.desktopW ? props.desktopW : props.width)}vw;
			display: ${(props) => (props.hide ? 'none' : 'flex')};
		}
	`}

	${media.lessThan<TableFlexProps>('giant')`
		flex-basis: ${(props) => (props.desktopW ? props.desktopW : props.width)}vw;
		display: ${(props) => (props.hide ? 'none' : 'flex')};
		.am-open {
			width: ${(props) => (props.mobileW ? props.mobileW : props.desktopW)}vw;
		}
	`};

	${media.lessThan<TableFlexProps>('medium')`
		flex-basis: ${(props) => (props.mobileW ? props.mobileW : props.desktopW)}vw;
	`};
`;

interface TableDataProps {
	hide?: boolean;
	center?: boolean;
	desktopW?: number;
	mobileHide?: boolean;
	mobileW?: number;
	width: number;
}

const TableData = styled.td<TableDataProps>`
	width: ${(props) => props.width}vw;
	text-align: ${(props) => (props.center ? 'center' : 'left')};
	display: block;

	${media.lessThan<TableDataProps>('monster')`
		.am-open {
			width: ${(props) => (props.desktopW ? props.desktopW : props.width)}vw;
			display: ${(props) => (props.hide ? 'none' : 'block')};
		}
	`};

	${media.lessThan<TableDataProps>('giant')`
		width: ${(props) => (props.desktopW ? props.desktopW : props.width)}vw;
		display: ${(props) => (props.hide ? 'none' : 'block')};
		.am-open {
			width: ${(props) => (props.mobileW ? props.mobileW : props.desktopW)}vw;
			display: ${(props) => (props.mobileHide || props.hide ? 'none' : 'block')};
		}
	`};

	${media.lessThan<TableDataProps>('medium')`
		width: ${(props) => (props.mobileW ? props.mobileW : props.desktopW)}vw;
		display: ${(props) => (props.mobileHide || props.hide ? 'none' : 'block')};
	`};
`;

const TableRow = styled.tr`
	height: 4em;
	cursor: move;
	user-select: none;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	&:hover {
		background: #0a9ed711;
	}
	${media.lessThan('monster')`
		.am-open {
			height: 6em;
		}
	`};
	${media.lessThan('giant')`
		height: 6em;
	`};
`;

export default function ArticlesTableRowNew({
	node,
	onClickPreview,
	onClickOpen,
	onClickCreateFromTemplate,
	onClickRepublish,
	dateKey,
}: Props) {
	const additionalProps = {
		onClickPreview,
		onClickOpen,
		onClickCreateFromTemplate,
		onClickRepublish,
	};

	const [, drag, dragPreview] = useDrag(
		() => ({
			type: AE_ARTICLE,
			item: {
				type: AE_ARTICLE,
				articleId: node.rowId,
				isPublished: node.status === STATUS_PUBLISHED,
			},
		}),
		[node]
	);

	return (
		<>
			<DragPreviewImage connect={dragPreview} src={dragPreviewImage} />

			<TableRow ref={drag}>
				<CategoryIndicator color={node.categoryByPrimaryCategory?.color ?? undefined} />
				<TableFlex width={3} desktopW={5}>
					<PriorityIndicator priority={node.publicationPriority} />
				</TableFlex>
				<TableData
					width={43}
					desktopW={40}
					mobileW={75}
					onClick={(event) => onClickOpen(event, node)}
				>
					<ArticlesTableRowTitle node={node} dateKey={dateKey} />
				</TableData>
				<TableData width={5} hide={true}>
					{translations[node.capability]}
				</TableData>
				<TableData width={10} hide={true}>
					<ArticleIcon node={node} small={false} />
				</TableData>
				<TableData width={10} hide={true}>
					{displayName(node?.authorByRevisionBy)}
				</TableData>
				<TableData width={5} hide={true}>
					<ArticleNotes node={node} />
				</TableData>
				<TableFlex width={5} desktopW={10}>
					<LockedByAuthorArticle article={node} />
				</TableFlex>
				<TableData width={8} center={true} desktopW={15} mobileHide={true}>
					<ArticlesTableRowDate node={node} dateKey={dateKey} />
				</TableData>
				<TableData width={8} center={true} hide={true}>
					<ArticleOrigin node={node} hide={true} />
				</TableData>
				<TableFlex width={3} desktopW={5} mobileW={10}>
					<Tooltip key="article_status" title={translations[node.status]}>
						<Status
							status={node.status}
							publicationDate={moment.utc(node.publicationDate).local()}
						/>
					</Tooltip>
				</TableFlex>
				<TableData width={6} desktopW={10} mobileHide={true}>
					<ArticlesTableRowButtons node={node} {...additionalProps} />
				</TableData>
			</TableRow>
		</>
	);
}
