/**
 * @generated SignedSource<<b46e3cf017b094ebb05221b856fee4a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NavigationType = "ARTICLE" | "BOARD" | "CUSTOM" | "NAVIGATION";
export type NavigationQuery$variables = {
  rowId: number;
};
export type NavigationQuery$data = {
  readonly navigationByRowId: {
    readonly articleByArticle: {
      readonly rowId: string;
      readonly title: string;
    } | null;
    readonly boardByBoard: {
      readonly rowId: string;
      readonly title: string;
    } | null;
    readonly color: string | null;
    readonly id: string;
    readonly navigationsByParent: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly articleByArticle: {
            readonly rowId: string;
            readonly title: string;
          } | null;
          readonly boardByBoard: {
            readonly rowId: string;
            readonly title: string;
          } | null;
          readonly color: string | null;
          readonly id: string;
          readonly navigationsByParent: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly articleByArticle: {
                  readonly rowId: string;
                  readonly title: string;
                } | null;
                readonly boardByBoard: {
                  readonly rowId: string;
                  readonly title: string;
                } | null;
                readonly color: string | null;
                readonly id: string;
                readonly order: number;
                readonly parent: number | null;
                readonly path: string | null;
                readonly rowId: number;
                readonly text: string | null;
                readonly type: NavigationType;
              } | null;
            }>;
          };
          readonly order: number;
          readonly parent: number | null;
          readonly path: string | null;
          readonly rowId: number;
          readonly text: string | null;
          readonly type: NavigationType;
        } | null;
      }>;
    };
    readonly order: number;
    readonly parent: number | null;
    readonly path: string | null;
    readonly rowId: number;
    readonly text: string | null;
    readonly type: NavigationType;
  } | null;
};
export type NavigationQuery = {
  response: NavigationQuery$data;
  variables: NavigationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parent",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  (v2/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Board",
  "kind": "LinkedField",
  "name": "boardByBoard",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "articleByArticle",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v14 = [
  (v8/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Board",
  "kind": "LinkedField",
  "name": "boardByBoard",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Article",
  "kind": "LinkedField",
  "name": "articleByArticle",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Navigation",
        "kind": "LinkedField",
        "name": "navigationByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NavigationsConnection",
            "kind": "LinkedField",
            "name": "navigationsByParent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Navigation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NavigationsConnection",
                        "kind": "LinkedField",
                        "name": "navigationsByParent",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NavigationsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Navigation",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavigationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Navigation",
        "kind": "LinkedField",
        "name": "navigationByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NavigationsConnection",
            "kind": "LinkedField",
            "name": "navigationsByParent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Navigation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NavigationsConnection",
                        "kind": "LinkedField",
                        "name": "navigationsByParent",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NavigationsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Navigation",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v15/*: any*/),
                                  (v16/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d29a12ef4d6dcd475854d344ae832246",
    "id": null,
    "metadata": {},
    "name": "NavigationQuery",
    "operationKind": "query",
    "text": "query NavigationQuery(\n  $rowId: Int!\n) {\n  navigationByRowId(rowId: $rowId) {\n    rowId\n    id\n    type\n    parent\n    order\n    text\n    boardByBoard {\n      title\n      rowId\n      id\n    }\n    articleByArticle {\n      title\n      rowId\n      id\n    }\n    path\n    color\n    navigationsByParent {\n      edges {\n        node {\n          id\n          rowId\n          type\n          parent\n          order\n          text\n          boardByBoard {\n            title\n            rowId\n            id\n          }\n          articleByArticle {\n            title\n            rowId\n            id\n          }\n          path\n          color\n          navigationsByParent {\n            edges {\n              node {\n                id\n                rowId\n                type\n                parent\n                order\n                text\n                boardByBoard {\n                  title\n                  rowId\n                  id\n                }\n                articleByArticle {\n                  title\n                  rowId\n                  id\n                }\n                path\n                color\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2781abc02d39c37a4bb8c353259a213";

export default node;
