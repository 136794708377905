import { CAPABILITY_TEXT_ONLY, MODULE_TYPE_TEXT } from '@sep/br24-constants';
import invariant from 'invariant';

import type { AppThunkAction } from '@/client/store/redux';

import type { State, ValidateAction, ValidationMembership, Validation } from '..';
import { translate } from '../../../translation';
import { VALIDATE } from '../actionTypes';
import articleValidator from '../validators/article';
import moduleValidator from '../validators/module';

const validate = (
	membership: ValidationMembership,
	id: any,
	validation: null | Validation
): ValidateAction => ({
	type: VALIDATE,

	payload: {
		membership,
		id,
		result: validation,
	},
});

export const validateArticle = (): AppThunkAction<ValidateAction> => (dispatch, getState) => {
	const { articleEditor } = getState();
	invariant(articleEditor.activeArticleId, 'no article selected');

	const result = articleValidator(articleEditor.articles[articleEditor.activeArticleId]);
	dispatch(
		validate('articles', articleEditor.activeArticleId, !result.isValid ? result.error : null)
	);
};

export const validateModule =
	(id: string | number): AppThunkAction<ValidateAction> =>
	(dispatch, getState) => {
		const { articleEditor } = getState();
		const affectedModule = articleEditor.modules[id];

		invariant(articleEditor.activeArticleId, 'no article selected');
		invariant(affectedModule, 'module not found');

		const activeArticle = articleEditor.articles[articleEditor.activeArticleId];

		if (!affectedModule.__meta__.deleted) {
			const result = moduleValidator(affectedModule);

			// this one is a special handling for eilmeldungen. Normally a text module cannot be added to order position 0.
			// in case of a eilmeldung this is not true because text is the only item which is possible. Because it's not possible
			// to check this validation via joi (article capability is not on the same level as modules) we look after this one here.
			// when we have a eilmeldung we reset the validation for the text module in order to have only validation errors,
			// when the input field is empty.
			if (
				activeArticle.capability === CAPABILITY_TEXT_ONLY &&
				affectedModule.type === MODULE_TYPE_TEXT &&
				result.error &&
				Array.isArray(result.error.text)
			) {
				if (affectedModule && affectedModule.text && affectedModule.text.length < 8) {
					result.error = { text: [translate('validation.errors.modules.text.isEmpty')] };
				} else {
					result.isValid = true;
				}
			}

			return dispatch(
				validate(
					'modules',
					affectedModule.rowId ? affectedModule.rowId : affectedModule.id,
					!result.isValid ? result.error : null
				)
			);
		}
	};

export const validateAll = (): AppThunkAction<ValidateAction> => (dispatch, getState) => {
	const { articleEditor } = getState();
	invariant(articleEditor.activeArticleId, 'no article selected');

	const article = articleEditor.articles[articleEditor.activeArticleId];

	dispatch(validateArticle());
	article.modules.forEach((id) => dispatch(validateModule(id)));
};

export const checkForErrors = (state: State) => {
	let articleHasErrors = false;

	invariant(state.activeArticleId, 'no article selected');

	const article = state.articles[state.activeArticleId];
	const { modules } = state.articles[state.activeArticleId];
	const haveModulesError = modules.some((id) => !!state.modules[id].__validation__);

	if (article.__validation__ || haveModulesError) {
		articleHasErrors = true;
	}

	return articleHasErrors;
};
