import moment, { type Moment } from 'moment';
import { Component, type ComponentType } from 'react';
import type { SyntheticEvent, KeyboardEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import * as constants from '../../../../constants';
import { type WithTranslationProps, withTranslation } from '../../../../translation';
import {
	AssetManagerImporterError,
	AssetManagerImporterForm,
	AssetManagerImporterRow,
	AssetManagerImporterRowItem,
	AssetManagerImporterInput,
	AssetManagerImporterInputAction,
	AssetManagerImporterSelect,
	AssetManagerImporterDatepicker,
	AssetManagerImporterContentEmpty,
} from '../../../../ui/AssetManager';
import { Accept, Spinner } from '../../../../ui/Icon';
import * as actions from '../../actions';
import { importerBaseInformationSelector, importedItemsByImporterSelector } from '../../selectors';
import AssetManagerImporterResults from '../AssetManagerImportResults/AssetManagerImporterResults';

const ALL_CHANNELS_VALUE = 'ALL_BROADCAST_CHANNELS';
const ALL_CHANNELS_LABEL = 'Alle';

interface AssetManagerImporterLiveStreamProps extends WithTranslationProps, ReduxProps {
	// ConnectedProps messes up funciton overloads
	importResults: typeof actions.importResults;
}

type State = {
	term: null | string;
	channelId: string;
	channelName: null | string;
	startDate: null | Moment;
	endDate: null | Moment;
};

const initialState: State = {
	term: '',
	channelId: ALL_CHANNELS_VALUE,
	channelName: ALL_CHANNELS_LABEL,
	startDate: moment().startOf('day'),
	endDate: moment().endOf('day'),
};

class AssetManagerImporterLiveStream extends Component<AssetManagerImporterLiveStreamProps, State> {
	state = {
		...initialState,
	};

	constructor(props: AssetManagerImporterLiveStreamProps) {
		super(props);
	}

	componentDidMount() {
		this.props.importBroadcastChannels();
		if (this.props.importedItems.length === 0) {
			this.props.importResults(constants.AM_IMPORTER_LIVESTREAMS, {
				mode: 'loadAvailableLivestreams',
			});
		}
	}

	componentDidUpdate() {
		if (this.props.importedItems.length === 0) {
			this.props.importResults(constants.AM_IMPORTER_LIVESTREAMS, {
				mode: 'loadAvailableLivestreams',
			});
		}
	}

	clearFilterState = () => {
		this.setState({
			...initialState,
		});
	};

	handleChangeDates = (value: [start: Moment | null, end: Moment | null] | null) => {
		const startDate = value && value[0] ? moment(value[0]).startOf('day') : null;
		const endDate = value && value[1] ? moment(value[1]).endOf('day') : null;

		this.setState({
			startDate,
			endDate,
		});
	};

	handleChangeFilterValue = (event: SyntheticEvent<HTMLInputElement>) => {
		event.preventDefault();
		this.setState({
			term: event.currentTarget.value,
		});
	};

	handleChangeSelect = (value: string) => {
		const channels = this.props.livestreams.broadcastChannels;

		if (value !== ALL_CHANNELS_VALUE) {
			const selectedChannel = channels.find((channel) => channel.id === value);
			if (selectedChannel) {
				this.setState({
					channelId: selectedChannel.id,
					channelName: selectedChannel.channelName,
				});
				return;
			}
		}

		this.setState({
			channelId: ALL_CHANNELS_VALUE,
			channelName: ALL_CHANNELS_LABEL,
		});
	};

	handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			this.handleSubmitFilters();
		}
	};

	handleSubmitFilters = () => {
		const defaultGroupPresent = this.props.importedItems.some(
			(itemGroup) => itemGroup.groupTitle === constants.AM_DEFAULT_GROUP
		);

		if (defaultGroupPresent) {
			this.props.cleanImporter(constants.AM_IMPORTER_LIVESTREAMS);
		}
		const { term, startDate, endDate } = this.state;

		const formattedStartDate = startDate ? startDate.format() : null;
		const formattedEndDate = endDate ? endDate.format() : null;

		let channelId: string | null = this.state.channelId;
		let channelName: string | null = this.state.channelName;
		if (channelId === ALL_CHANNELS_VALUE) {
			channelId = null;
			channelName = null;
		}

		this.props.importResults(
			constants.AM_IMPORTER_LIVESTREAMS,
			{
				mode: 'searchAvailableLivestreams',
			},
			{
				term,
				channelId,
				channelName,
				startDate: formattedStartDate,
				endDate: formattedEndDate,
			}
		);

		this.setState({ ...initialState });
	};

	render() {
		const {
			latestImporterError,
			isImporterWorking,
			importedItems,
			livestreams,
			translation: { translate },
		} = this.props;

		const channels = [
			{
				label: ALL_CHANNELS_LABEL,
				value: ALL_CHANNELS_VALUE,
			},
			...livestreams.broadcastChannels.map((channel) => ({
				label: channel.channelName,
				value: channel.id,
			})),
		];

		return (
			<>
				{latestImporterError && (
					<AssetManagerImporterError>{latestImporterError.message}</AssetManagerImporterError>
				)}
				<AssetManagerImporterRow>
					<AssetManagerImporterRowItem size={10}>
						<AssetManagerImporterForm>
							<AssetManagerImporterInput
								size="full"
								mode="code"
								placeholder={translate(
									`assetManager.importer.${constants.AM_IMPORTER_LIVESTREAMS}.inputPlaceholder`
								)}
								onChange={this.handleChangeFilterValue}
								onKeyDown={this.handleInputKeyDown}
								value={this.state.term}
							/>
						</AssetManagerImporterForm>
					</AssetManagerImporterRowItem>

					<AssetManagerImporterRowItem size={5}>
						<AssetManagerImporterSelect
							value={this.state.channelId}
							onChange={this.handleChangeSelect}
							options={channels}
						/>
					</AssetManagerImporterRowItem>

					<AssetManagerImporterRowItem size={8}>
						<AssetManagerImporterDatepicker
							// TODO : fix scroll
							onHandleChangeDates={this.handleChangeDates}
							value={[this.state.startDate, this.state.endDate]}
						/>
					</AssetManagerImporterRowItem>

					<AssetManagerImporterRowItem size={1}>
						<AssetManagerImporterInputAction
							key="import"
							onClick={this.handleSubmitFilters}
							className={isImporterWorking ? 'loading' : ''}
						>
							{isImporterWorking ? <Spinner /> : <Accept />}
						</AssetManagerImporterInputAction>
					</AssetManagerImporterRowItem>
				</AssetManagerImporterRow>

				<AssetManagerImporterResults
					importer={constants.AM_IMPORTER_LIVESTREAMS}
					importedItems={importedItems}
					importerWorking={isImporterWorking}
				/>
				{!isImporterWorking && importedItems.length === 0 && (
					<AssetManagerImporterContentEmpty>
						{translate(`assetManager.importer.${constants.AM_IMPORTER_LIVESTREAMS}.empty`)}
					</AssetManagerImporterContentEmpty>
				)}
			</>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		...importerBaseInformationSelector(state),
		importedItems: importedItemsByImporterSelector(constants.AM_IMPORTER_LIVESTREAMS)(state),
		livestreams: state.assetManager.livestreams,
	}),
	{
		importResults: actions.importResults,
		importBroadcastChannels: actions.importBroadcastChannels,
		cleanImporter: actions.cleanImporter,
		removeItemGroup: actions.removeItemGroupFromImporter,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default withTranslation(
	connector(
		AssetManagerImporterLiveStream as unknown as ComponentType<ReduxProps & WithTranslationProps>
	)
);
