import styled from 'styled-components';

const PlusIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #0a9ed7;
	color: #fff;
	font-size: 17px;
	cursor: pointer;
`;

type Props = {
	insertAt: number;
	onAddSectionBetween: (insertAt: number) => void;
};

function AddIcon({ insertAt, onAddSectionBetween }: Props) {
	const handleAddSection = () => {
		onAddSectionBetween(insertAt);
	};

	return <PlusIcon onClick={handleAddSection}>+</PlusIcon>;
}

export default AddIcon;
