import { Table } from 'antd';
import type { ComponentType } from 'react';
import type { FunctionComponent } from 'react';

import * as constants from '../../constants';

declare type Question = {
	id: string;
	question: string;
	answer: FunctionComponent<any> | ComponentType<any>;
	tags: Array<string> | undefined | null;
};

declare type FAQ = Array<Question>;

const questions: FAQ = [
	{
		id: 'hotkeys',
		question: 'Hotkeys für den Newsroom',
		answer: () => (
			<>
				<p>Die nachfolgende Tabelle erklärt den Umgang mit Hotkeys.</p>
				<br />
				<Table
					columns={[
						{
							title: 'Hotkey',
							dataIndex: 'hotKey',
							render: (value) => <code>{value}</code>,
						},
						{
							title: 'Einschränkung',
							dataIndex: 'restriction',
						},
						{
							title: 'Auswirkung',
							dataIndex: 'description',
						},
					]}
					dataSource={[
						{
							hotKey: constants.HOTKEY_TOGGLE_ASSETMANAGER_VISIBILITY,
							restriction: '-',
							description: 'Zeigt/versteckt den AssetManager',
						},
						{
							hotKey: constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_HARVEST,
							restriction: 'Nur verfügbar wenn der AssetManager geöffnet ist.',
							description: 'Aktiviert den AssetManager-Modus "Sammeln".',
						},
						{
							hotKey: constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_FILTER,
							restriction: 'Nur verfügbar wenn der AssetManager geöffnet ist.',
							description: 'Aktiviert/deaktiviert den AssetManager-Modus: "Filtern".',
						},
						{
							hotKey: constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_EDIT,
							restriction: 'Nur verfügbar wenn der AssetManager geöffnet ist.',
							description: 'Aktiviert/deaktiviert den AssetManager-Modus: "Bearbeiten".',
						},
						{
							hotKey: constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_IMPORT,
							restriction: 'Nur verfügbar wenn der AssetManager geöffnet ist.',
							description: 'Aktiviert/deaktiviert den AssetManager-Modus: "Importieren".',
						},
					]}
					size="small"
					pagination={false}
					bordered={true}
				/>
			</>
		),
		tags: ['hotkey', 'hotkeys', 'kürzel', 'schnell'],
	},
	{
		id: 'anleitungen',
		question: 'Übersicht: Anleitungen der Redaktion',
		answer: () => (
			<p>
				<ul>
					<li>
						<a
							href="https://s3-eu-west-1.amazonaws.com/br24-tutorials/100-sekunden-rundschau.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							100 Sekunden Rundschau
						</a>
					</li>
					<li>
						<a
							href="https://s3-eu-west-1.amazonaws.com/br24-tutorials/rundschau-app.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Rundschau in der APP
						</a>
					</li>
					<li>
						<a
							href="https://s3-eu-west-1.amazonaws.com/br24-tutorials/basics.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Basics
						</a>
					</li>
					<li>
						<a
							href="https://s3-eu-west-1.amazonaws.com/br24-tutorials/video-artikel.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Audio/Video Artikel
						</a>
					</li>
					<li>
						<a
							href="https://s3-eu-west-1.amazonaws.com/br24-tutorials/b5.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							B5
						</a>
					</li>
					<li>
						<a
							href="https://s3-eu-west-1.amazonaws.com/br24-tutorials/faq.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Allgemeine Fragen und Antworten
						</a>
					</li>
					<li>
						<a
							href="https://s3-eu-west-1.amazonaws.com/br24-tutorials/vorlagen.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Vorlagen
						</a>
					</li>
				</ul>
			</p>
		),
		tags: ['anleitung'],
	},
	{
		id: 'wie-kann-ich-mich-ausloggen',
		question: 'Wie kann ich mich ausloggen?',
		answer: () => (
			<p>
				Am oberen rechten Rand befindet sich Dein Profilbild - ein Klick darauf öffnet das
				dazugehörige Menü. Hier findest Du den Button Logout.
			</p>
		),
		tags: ['logout'],
	},
	{
		id: 'seo-informationen-fuer-artikel-editor',
		question: 'SEO-Informationen',
		answer: () => <p>Folgt.</p>,
		tags: ['seo', 'informationen', 'editor', 'meta description'],
	},
	{
		id: 'google-suchverhalten',
		question: 'Google-Suchverhalten',
		answer: () => <p>Folgt.</p>,
		tags: ['google', 'suchverhalten', 'editor'],
	},
	{
		id: 'wie-kann-ich-einen-artikel-erstellen',
		question: 'Wie kann ich einen Artikel erstellen?',
		answer: () => (
			<video
				width="100%"
				src="https://s3-eu-west-1.amazonaws.com/br24-tutorials/videos/create-article_1080p.mp4"
				controls={true}
			/>
		),
		tags: ['video', 'artikel', 'erstellen'],
	},
	{
		id: 'wie-kann-ich-ein-autorenprofil-erstellen',
		question: 'Wie kann ich ein Autorenprofil erstellen?',
		answer: () => (
			<video
				width="100%"
				src="https://s3-eu-west-1.amazonaws.com/br24-tutorials/videos/create-author_1080p.mp4"
				controls={true}
			/>
		),
		tags: ['video', 'autoren', 'autor', 'erstellen'],
	},
	{
		id: 'wie-kann-ich-ein-autorenprofil-bearbeiten',
		question: 'Wie kann ich ein mein Autorenprofil bearbeiten?',
		answer: () => (
			<video
				width="100%"
				src="https://s3-eu-west-1.amazonaws.com/br24-tutorials/videos/my-profile_1080p.mp4"
				controls={true}
			/>
		),
		tags: ['video', 'autor', 'autoren', 'bearbeiten'],
	},
	{
		id: 'wie-kann-ich-meine-einstellungen-verwalten',
		question: 'Wie kann ich meine Einstellungen verwalten?',
		answer: () => (
			<video
				width="100%"
				src="https://s3-eu-west-1.amazonaws.com/br24-tutorials/videos/settings_1080p.mp4"
				controls={true}
			/>
		),
		tags: ['video', 'einstellungen'],
	},
	{
		id: 'wie-funktioniert-die-artikeluebersicht',
		question: 'Wie funktioniert die Artikelübersicht?',
		answer: () => (
			<video
				width="100%"
				src="https://s3-eu-west-1.amazonaws.com/br24-tutorials/videos/articles_1080p.mp4"
				controls={true}
			/>
		),
		tags: ['video', 'artikel', 'übersicht'],
	},
	{
		id: 'wie-funktioniert-die-autorenuebersicht',
		question: 'Wie funktioniert die Autorenübersicht?',
		answer: () => (
			<video
				width="100%"
				src="https://s3-eu-west-1.amazonaws.com/br24-tutorials/videos/authors_1080p.mp4"
				controls={true}
			/>
		),
		tags: ['video', 'autoren', 'autor', 'übersicht'],
	},
];

export default questions;
