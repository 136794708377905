/**
 * @generated SignedSource<<717c74bb3bdc3029f1c5bac46c5d9289>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ParentBoardPickerQuery$variables = {
  searchTerm: string;
};
export type ParentBoardPickerQuery$data = {
  readonly boards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly rowId: string;
        readonly title: string;
      } | null;
    }>;
  } | null;
};
export type ParentBoardPickerQuery = {
  response: ParentBoardPickerQuery$data;
  variables: ParentBoardPickerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  },
  {
    "kind": "Literal",
    "name": "statusFilter",
    "value": [
      "PUBLISHED"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ParentBoardPickerQuery",
    "selections": [
      {
        "alias": "boards",
        "args": (v1/*: any*/),
        "concreteType": "BoardsConnection",
        "kind": "LinkedField",
        "name": "filterBoards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Board",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ParentBoardPickerQuery",
    "selections": [
      {
        "alias": "boards",
        "args": (v1/*: any*/),
        "concreteType": "BoardsConnection",
        "kind": "LinkedField",
        "name": "filterBoards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Board",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d10bad3923ba28c6c197386651327eea",
    "id": null,
    "metadata": {},
    "name": "ParentBoardPickerQuery",
    "operationKind": "query",
    "text": "query ParentBoardPickerQuery(\n  $searchTerm: String!\n) {\n  boards: filterBoards(searchTerm: $searchTerm, statusFilter: [PUBLISHED], first: 1000) {\n    edges {\n      node {\n        rowId\n        title\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18292e905d59e8391251362cc066ed3a";

export default node;
