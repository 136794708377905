import styled from 'styled-components';

import { ReactComponent as Logo } from './logo.svg';

const LogoSVG = styled(Logo)`
	width: 40px;
	height: 40px;
`;

export const HomeButton = styled.a`
	height: 100%;
	display: grid;
	align-items: center;

	&:hover {
		#ui_header_logo_24 {
			fill: url('#header_logo_gradient_animation');
		}
	}
`;

const Container = styled.div`
	float: left;
	padding: 0 20px;
	height: 100%;
	&:hover {
		#ui_header_logo_24 {
			fill: url('#header_logo_gradient_animation');
		}
	}
`;

type Props = {
	onClick?: () => void;
	onKeyPress?: () => void;
};

export default function (props: Props) {
	return (
		<Container>
			<HomeButton data-testid="logo" {...props} title="Logo">
				<LogoSVG />
			</HomeButton>
		</Container>
	);
}
