import { Children } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import ErrorMessageText from '../../ErrorMessageText';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

type HeaderProps = {
	hasItemsBelow: boolean;
};

export const Header = styled.div`
	margin-bottom: ${(props: HeaderProps) => (props.hasItemsBelow ? '1rem' : 0)};
`;

export type Props = {
	children: ReactNode;
	validation?: string[] | string | null;
	search: ReactNode;
	key: string | number;
};

export default function ArticleMetaAuthor({ search, children, validation, ...rest }: Props) {
	const translate = useTranslate();
	return (
		<ArticleMetaPanel
			help={translate('article.meta.author.help')}
			isRequired={true}
			title={translate('article.meta.author.title')}
			{...rest}
		>
			<ErrorMessageText text={validation ?? undefined} hasBottomMargin={true} />
			{search ? <Header hasItemsBelow={Children.count(children) > 0}>{search}</Header> : null}
			{children}
		</ArticleMetaPanel>
	);
}
