import type { AppThunkAction } from '@/client/store/redux';

import type { RemoveAuthorAction, ValidateAction } from '..';
import { AUTHOR_REMOVE } from '../actionTypes';

import { validateArticle } from './validate';

const removeAuthor =
	(removeIndex: number): AppThunkAction<RemoveAuthorAction | ValidateAction> =>
	(dispatch) => {
		dispatch({
			type: AUTHOR_REMOVE,
			payload: removeIndex,
		});

		dispatch(validateArticle());
	};

export default removeAuthor;
