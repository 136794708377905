import type { AppThunkAction } from '@/client/store/redux';

import type { AddAuthorAction, ValidateAction } from '..';
import { AUTHOR_ADD } from '../actionTypes';

import { validateArticle } from './validate';

const addAuthor =
	(guid: string): AppThunkAction<AddAuthorAction | ValidateAction> =>
	(dispatch, getState) => {
		const { articleEditor } = getState();

		const relationId = `${articleEditor.activeArticleId}_${guid}`;

		// do not add duplicates
		if (articleEditor.authors[relationId] && !articleEditor.authors[relationId].__meta__.deleted) {
			return;
		}

		dispatch({
			type: AUTHOR_ADD,
			payload: guid,
		});

		dispatch(validateArticle());
	};

export default addAuthor;
