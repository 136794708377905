/**
 * @generated SignedSource<<6bd3b55d7cd49cac71c7fe84a54c785a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type MyLatestArticlesContainerQuery$variables = {
  affectedAuthorGuid: string;
};
export type MyLatestArticlesContainerQuery$data = {
  readonly data: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly article: {
          readonly rowId: string;
          readonly status: Status;
          readonly title: string;
        } | null;
        readonly createdAt: string | null;
      } | null;
    }>;
  } | null;
};
export type MyLatestArticlesContainerQuery = {
  response: MyLatestArticlesContainerQuery$data;
  variables: MyLatestArticlesContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "affectedAuthorGuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "affectedAuthorGuid",
    "variableName": "affectedAuthorGuid"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyLatestArticlesContainerQuery",
    "selections": [
      {
        "alias": "data",
        "args": (v1/*: any*/),
        "concreteType": "MyArticlesByAuthorGuidEntitiesConnection",
        "kind": "LinkedField",
        "name": "myArticlesByAuthorGuid",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MyArticlesByAuthorGuidEntitiesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MyArticlesByAuthorGuidEntity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Article",
                    "kind": "LinkedField",
                    "name": "article",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyLatestArticlesContainerQuery",
    "selections": [
      {
        "alias": "data",
        "args": (v1/*: any*/),
        "concreteType": "MyArticlesByAuthorGuidEntitiesConnection",
        "kind": "LinkedField",
        "name": "myArticlesByAuthorGuid",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MyArticlesByAuthorGuidEntitiesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MyArticlesByAuthorGuidEntity",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Article",
                    "kind": "LinkedField",
                    "name": "article",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15d7324f458a033f9210db312e5542f4",
    "id": null,
    "metadata": {},
    "name": "MyLatestArticlesContainerQuery",
    "operationKind": "query",
    "text": "query MyLatestArticlesContainerQuery(\n  $affectedAuthorGuid: String!\n) {\n  data: myArticlesByAuthorGuid(affectedAuthorGuid: $affectedAuthorGuid, first: 10) {\n    edges {\n      node {\n        createdAt\n        article {\n          rowId\n          title\n          status\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f6ca9de36dd3b3454c7c90b9761a6b8";

export default node;
