import { Component } from 'react';
import { ResponsiveContainer, PieChart as PieChartContainer, Pie, Tooltip, Cell } from 'recharts';

import config from '../../../config';
import styles from '../../../styles';

type Props = {
	data: Array<{
		district: string;
		count: number;
	}>;
};

class PieChartRegions extends Component<Props> {
	shouldComponentUpdate({ data: nextData }: Props) {
		return this.props.data !== nextData;
	}

	render() {
		const { data } = this.props;

		// calculate the total amount per district
		const totalCount = data.reduce((accumulator, { count }) => accumulator + count, 0);

		const chartData = data
			.map(({ district, count }) => ({
				name: district.charAt(0).toUpperCase() + district.slice(1).toLowerCase(),
				value: count,
				percent: Math.round((100 / totalCount) * count * 100) / 100,
				color: config.regionColors[district],
			}))
			.filter(({ value }) => value > 0);

		/* eslint-disable react/no-array-index-key */
		return (
			<div>
				<ResponsiveContainer width="100%" aspect={1}>
					<PieChartContainer>
						<Tooltip />
						<Pie dataKey="value" data={chartData} innerRadius="50%" endAngle={-270} startAngle={90}>
							{chartData.map(({ color }, index) => (
								<Cell key={index} fill={color} />
							))}
						</Pie>
					</PieChartContainer>
				</ResponsiveContainer>
				<ul className={styles.chartLegend}>
					{chartData.map(({ name, percent, color }, index) => (
						<li key={index}>
							<div style={{ backgroundColor: color }}>{percent}%</div> {name}
						</li>
					))}
				</ul>
			</div>
		);
		/* eslint-enable react/no-array-index-key */
	}
}

export default PieChartRegions;
