import {
	LS_UPDATE_LOCKSTATUS,
	MODULE_TYPE_TEXT,
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
} from '@sep/br24-constants';
import type { User } from '@sep/cms-auth-client';
import invariant from 'invariant';
import { fetchQuery, graphql } from 'react-relay';

import environment from '@/client/environment';
import type { AppThunkAction } from '@/client/store/redux';
import { LockError } from '@/client/util/lockHandler/LockError';

import type {
	Author,
	Module,
	Redirection,
	SaveErrorAction,
	SaveRequestAction,
	SaveSuccessAction,
	SaveActionFunctionCallback,
	SaveActionErrorCallback,
	BeforeSaveFunctionCallback,
	State,
	Tag,
	SaveInput,
	ValidateAction,
	UpdateStatusAction,
	UpdateInput,
	Article,
} from '..';
import { SAVE, STATUS_REQUEST, STATUS_SUCCESS, STATUS_ERROR, UPDATE } from '../actionTypes';
import { validateAll, checkForErrors } from '../actions/validate';
import deleteArticleTag from '../mutations/ArticleEditorDeleteArticleTagMutation';
import depositHistory from '../mutations/ArticleEditorDepositHistoryMutation';
import updateArticle from '../mutations/ArticleEditorUpdateArticleMutation';
import type {
	ModulesArticleIdFkeyModulesCreateInput,
	ArticleOnModuleForModulesArticleIdFkeyNodeIdUpdate,
	ArticlesAuthorArticlesAuthorsPkeyDelete,
	ArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyUsingArticlesAuthorsPkeyUpdate,
	ArticlesAuthorsArticleIdFkeyArticlesAuthorsCreateInput,
	ArticlesTagsArticleIdFkeyArticlesTagsCreateInput,
	ModuleNodeIdDelete,
	RedirectionNodeIdDelete,
	RedirectionsArticleIdFkeyRedirectionsCreateInput,
	ArticlesTagArticlesTagsArticleIdTagIdKeyConnect,
} from '../mutations/__generated__/ArticleEditorUpdateArticleMutation.graphql';

import type { saveArticleLockByRowIdQuery } from './__generated__/saveArticleLockByRowIdQuery.graphql';

export const request = (input: SaveInput): SaveRequestAction => ({
	type: SAVE,
	status: STATUS_REQUEST,

	payload: {
		...input,
	},
});

export const success = (input: SaveInput): SaveSuccessAction => ({
	type: SAVE,
	status: STATUS_SUCCESS,

	payload: {
		...input,
	},
});

export const error = (err: Error): SaveErrorAction => ({
	type: SAVE,
	status: STATUS_ERROR,
	payload: err,
});

export const updateStatus = (input: UpdateInput): UpdateStatusAction => ({
	type: UPDATE,

	payload: {
		...input,
	},
});

function removeUnneccesaryHtmlEntities(input?: string | null) {
	// clean html output of editor
	const unnecessaryEntities = new RegExp(/<br\/>|<br \/>|<br>|<p><\/p>|<h2><\/h2>/gi);
	// convert all headlines to h2 because sophora imports h3, h1 sometimes. (only <h1/> / <h3/> tags)
	const convertHeadlines = new RegExp(/((?:(?:&lt;)|(?:<\)))\/?)(h1|h3)((?:&gt;)|(?:>\)))/gi);

	if (input) {
		const strippedInput = input
			.replace(unnecessaryEntities, '')
			.replace(convertHeadlines, '$1h2$3');
		return strippedInput || '<p></p>';
	}

	return null;
}

type EntityMetaInformationKeys =
	| 'id'
	| '__meta__'
	| '__validation__'
	| 'lastEdited'
	| 'authorByRevisionBy'
	| 'lockedBy'
	| 'lockedSince'
	| 'invalidLinks'
	| 'links';

function dropEntityMetaInformation<T extends { [K in EntityMetaInformationKeys]?: any }>({
	id: _id,
	__meta__,
	__validation__,
	lastEdited: _lastEdited,
	authorByRevisionBy: _authorByRevisionBy,
	lockedBy: _lockedBy,
	lockedSince: _lockedSince,
	invalidLinks: _invalidLinks,
	links: _links,
	...necessaryModuleInformation
}: T): Omit<T, EntityMetaInformationKeys> {
	return necessaryModuleInformation;
}

function dropRowId<T extends { rowId?: any }>({
	rowId: _rowId,
	...withoutRowId
}: T): Omit<T, 'rowId'> {
	return withoutRowId;
}

function dropArticleId<T extends { articleId?: any }>({
	articleId: _articleId,
	...withoutArticleId
}: T): Omit<T, 'articleId'> {
	return withoutArticleId;
}

async function articleValidateLock(article: Article, user: User | null) {
	const { rowId, lastEdited, lockedBy } = article;
	const currentUserId = user?.guid ?? null;

	const articleLockByRowIdQuery = graphql`
		query saveArticleLockByRowIdQuery($id: String!) {
			article: articleByRowId(rowId: $id) {
				rowId
				lastEdited
				lockedBy
				lockedSince
				authorByRevisionBy {
					firstname
					lastname
					id
				}
			}
		}
	`;

	const response = await fetchQuery<saveArticleLockByRowIdQuery>(
		environment,
		articleLockByRowIdQuery,
		{ id: rowId },
		{
			fetchPolicy: 'network-only',
		}
	).toPromise();

	const reference = response?.article;

	if (!reference) {
		throw new LockError(article);
	}

	let isValid = true;
	isValid = isValid && reference?.lastEdited === lastEdited;
	isValid = isValid && [lockedBy, currentUserId].includes(reference?.lockedBy);

	if (!isValid) {
		throw new LockError(article);
	}
}

const mutate = async (articleEditor: State) => {
	const { activeArticleId: affectedArticleRowId } = articleEditor;

	if (!affectedArticleRowId) {
		throw new Error('No article selected.');
	}

	const article = articleEditor.articles[affectedArticleRowId];

	// initialize an array for parallel transactions
	const transactions: Array<any> = [];
	const moduleMutations = {
		create: new Array<ModulesArticleIdFkeyModulesCreateInput>(),
		updateById: new Array<ArticleOnModuleForModulesArticleIdFkeyNodeIdUpdate>(),
		deleteById: new Array<ModuleNodeIdDelete>(),
	};
	const authorMutations = {
		create: new Array<ArticlesAuthorsArticleIdFkeyArticlesAuthorsCreateInput>(),
		updateByAuthorGuidAndArticleId:
			new Array<ArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyUsingArticlesAuthorsPkeyUpdate>(),
		deleteByAuthorGuidAndArticleId: new Array<ArticlesAuthorArticlesAuthorsPkeyDelete>(),
	};
	const redirectionsMutations = {
		create: new Array<RedirectionsArticleIdFkeyRedirectionsCreateInput>(),
		deleteById: new Array<RedirectionNodeIdDelete>(),
	};
	const tagsMutations = {
		create: new Array<ArticlesTagsArticleIdFkeyArticlesTagsCreateInput>(),
		connectByArticleIdAndTagId: new Array<ArticlesTagArticlesTagsArticleIdTagIdKeyConnect>(),
	};

	// next up: focus on modules (created & updated)
	article.modules
		.map((id) => articleEditor.modules[id])
		.forEach((affectedModule: Module) => {
			if (
				affectedModule.__meta__.created &&
				!affectedModule.__meta__.deleted &&
				!affectedModule.rowId
			) {
				const cleanedAffectedModule = dropEntityMetaInformation(affectedModule);

				moduleMutations.create.push({
					...dropArticleId({ ...dropRowId(cleanedAffectedModule) }),
					text:
						affectedModule.type === MODULE_TYPE_TEXT
							? removeUnneccesaryHtmlEntities(affectedModule.text)
							: null,
				});
			} else if (affectedModule.__meta__.updated) {
				if (!affectedModule.rowId) {
					throw new Error(
						`there is no rowId defined for the module ${affectedModule.id} with order ${affectedModule.order} .`
					);
				}

				moduleMutations.updateById.push({
					id: affectedModule.id,
					modulePatch: {
						...dropEntityMetaInformation(affectedModule),
						text:
							affectedModule.type === MODULE_TYPE_TEXT
								? removeUnneccesaryHtmlEntities(affectedModule.text)
								: null,
					},
				});
			}
		});

	// modules (deleted)
	Object.keys(articleEditor.modules)
		.filter(
			(id) =>
				articleEditor.modules[id].__meta__.deleted &&
				articleEditor.modules[id].articleId === affectedArticleRowId
		)
		.map((id) => articleEditor.modules[id])
		.forEach((affectedModule) => {
			const { rowId } = affectedModule;

			// module was created, but deleted before saving
			if (!rowId && affectedModule.__meta__.created) {
				return;
			}

			moduleMutations.deleteById.push({ id: affectedModule.id });
		});

	// here we go: the authors
	article.authors
		.map((id) => articleEditor.authors[id])
		.forEach((affectedAuthor: Author) => {
			if (!affectedAuthor.guid) {
				throw new Error(
					'guid for an author is missing. without the guid the author could not connected to the article.'
				);
			}

			if (affectedAuthor.__meta__.created && !affectedAuthor.__meta__.deleted) {
				authorMutations.create.push({
					authorGuid: affectedAuthor.guid,
					order: affectedAuthor.order,
				});
			}

			if (
				affectedAuthor.__meta__.updated &&
				!affectedAuthor.__meta__.created &&
				!affectedAuthor.__meta__.deleted
			) {
				authorMutations.updateByAuthorGuidAndArticleId.push({
					authorGuid: affectedAuthor.guid,
					articleId: article.rowId,
					articlesAuthorPatch: {
						order: affectedAuthor.order,
					},
				});
			}
		});

	// authors (deleted)
	Object.keys(articleEditor.authors)
		.filter((id) => articleEditor.authors[id].__meta__.deleted)
		.map((id) => articleEditor.authors[id])
		.forEach((affectedAuthor) => {
			// author was created, but deleted before saving
			if (affectedAuthor.__meta__.created && affectedAuthor.__meta__.deleted) {
				return;
			}

			authorMutations.deleteByAuthorGuidAndArticleId.push({
				authorGuid: affectedAuthor.guid,
				articleId: article.rowId,
			});
		});

	// the sophora redirection fuck-up begins (create)
	article.redirections
		.map((id) => articleEditor.redirections[id])
		.forEach((redirection: Redirection) => {
			if (redirection.__meta__.created) {
				if (!redirection.sophoraId) {
					throw new Error('the sophora id is empty.');
				}

				redirectionsMutations.create.push({
					sophoraId: redirection.sophoraId,
					articleToArticleId: { connectByRowId: { rowId: article.rowId } },
				});
			}
		});

	// redirections (delete)
	Object.keys(articleEditor.redirections)
		.filter(
			(id) =>
				articleEditor.redirections[id].__meta__.deleted &&
				articleEditor.redirections[id].articleId === affectedArticleRowId
		)
		.map((id) => articleEditor.redirections[id])
		.forEach((affectedRedirection) => {
			const { rowId, id } = affectedRedirection;

			// redirection was created, but deleted before saving
			if (!Number.isInteger(rowId) && affectedRedirection.__meta__.created) {
				return;
			}

			if (id) {
				redirectionsMutations.deleteById.push({ id });
			}
		});

	// next up: tags
	article.tags
		.map((id) => articleEditor.tags[id])
		.forEach((tag: Tag) => {
			if (tag.__meta__.created) {
				if (tag.rowId) {
					throw new Error('there is a rowId defined in a newly created tag.');
				}

				tagsMutations.create.push({
					tagToTagId: {
						create: {
							text: tag.text,
						},
					},
				});
			} else if (tag.__meta__.updated) {
				tagsMutations.create.push({
					articleToArticleId: { connectByRowId: { rowId: article.rowId } },
					tagToTagId: { connectByRowId: { rowId: tag.rowId! } },
				});
			}
		});

	// tags (delete)
	Object.keys(articleEditor.tags)
		.filter((id) => articleEditor.tags[id].__meta__.deleted)
		.map((id) => articleEditor.tags[id])
		.forEach((affectedTag) => {
			const { rowId } = affectedTag;

			// redirection was created, but deleted before saving
			if (!rowId && affectedTag.__meta__.created) {
				return;
			}

			if (rowId) {
				transactions.push(() => deleteArticleTag(article.rowId, rowId));
			}
		});

	transactions.push(() => {
		const {
			shareUrl,
			shortUrl,
			historiesByArticleId,
			boardsSectionsItemsByArticleId,
			authors,
			modules,
			redirections,
			tags,
			authorByLockedBy,
			...necessaryArticleInformation
		} = article;

		return updateArticle(
			dropEntityMetaInformation({
				...necessaryArticleInformation,
				// $FlowFixMe | article.links => isExternal is not supported right now
				rawLinks: (necessaryArticleInformation.links || []).map((link) => ({
					url: link?.url,
					label: link?.label,
				})),
				modulesUsingRowId: moduleMutations,
				articlesAuthorsUsingRowId: authorMutations,
				redirectionsUsingRowId: redirectionsMutations,
				articlesTagsUsingRowId: tagsMutations,
			})
		);
	});

	// finally, run the transactions
	await Promise.all(transactions.map((transaction) => transaction()));

	// save current state as history
	if (transactions.length > 0 || article.__meta__.updated) {
		await depositHistory(affectedArticleRowId);
	}
};

const save =
	(
		input: SaveInput,
		onSaveFinished: SaveActionFunctionCallback,
		onSaveError: SaveActionErrorCallback,
		onBeforeSave?: BeforeSaveFunctionCallback
	): AppThunkAction<
		| SaveRequestAction
		| SaveSuccessAction
		| SaveErrorAction
		| ValidateAction
		| UpdateStatusAction
		| SaveErrorAction
	> =>
	async (dispatch, getState) => {
		const { articleEditor } = getState();

		const { activeArticleId: affectedArticleRowId } = articleEditor;

		if (!affectedArticleRowId) {
			throw new Error('No article selected.');
		}

		const article = articleEditor.articles[affectedArticleRowId];

		if (onBeforeSave && article.status === STATUS_PUBLISHED) {
			await onBeforeSave(true);
			return;
		}

		if (articleEditor.isWorking) {
			return;
		}

		try {
			await articleValidateLock(article, articleEditor.user);
		} catch (err: unknown) {
			// FIXME: Rework the errors
			const errToThrow = new LockError(article);

			onSaveError(errToThrow, LS_UPDATE_LOCKSTATUS);
			dispatch(error(errToThrow));

			return;
		}

		dispatch(request(input));
		dispatch(validateAll());

		invariant(articleEditor.activeArticleId, 'article not found');

		const articleHasErrors = checkForErrors(articleEditor);

		// when article is saved in draft mode or will be depublished / deleted
		// then we update the status and push it to the store.
		// within this modes the article can have errors
		if (
			input &&
			(input.status === STATUS_DRAFT ||
				input.status === STATUS_DEPUBLISHED ||
				input.status === STATUS_DELETED)
		) {
			try {
				dispatch(updateStatus({ status: input.status }));
				const { articleEditor: updatedArticleEditor } = getState();

				await mutate(updatedArticleEditor);
				await onSaveFinished(input.status);
				dispatch(success(input));
			} catch (err) {
				onSaveError(err as Error, input.status);
				dispatch(error(err as Error));
			}
		}

		// status changes from draft -> review & review -> published require no errors
		// in article, so we have to check this here in particular
		if (
			input &&
			(input.status === STATUS_REVIEW ||
				input.status === STATUS_SCHEDULED ||
				input.status === STATUS_PUBLISHED) &&
			articleHasErrors
		) {
			const err = new Error('Validation failed');

			await onSaveError(err, input.status);
			dispatch(error(err));

			return;
		}

		// when article is saved in review / published mode
		// we update the status and push it to the store when there are NO errors.
		// within this modes the article is not allowed to have errors.
		if (
			input &&
			(input.status === STATUS_REVIEW ||
				input.status === STATUS_SCHEDULED ||
				input.status === STATUS_PUBLISHED) &&
			!articleHasErrors
		) {
			try {
				dispatch(updateStatus({ status: input.status }));
				const { articleEditor: updatedArticleEditor } = getState();

				await mutate(updatedArticleEditor);
				await onSaveFinished(input.status);
				dispatch(success(input));
			} catch (err) {
				await onSaveError(err as Error, input.status);
				dispatch(error(err as Error));
			}
		}
	};

export default save;
