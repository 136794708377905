import { Modal, Button, Tooltip } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../translation';

const StyledLockData = styled.span`
	font-weight: bold;
`;

type Props = WithTranslationProps & {
	isVisible: boolean;
	brokenBy: string;
	brokenDate: string;
	onBackToOverview: () => void;
	onOpenReadOnlyView: () => void;
};

class BrokenLockDialog extends Component<Props> {
	handleBackToOverView = () => {
		this.props.onBackToOverview();
	};

	handleCancelDialog = () => {
		this.props.onBackToOverview();
	};

	handleOpenReadOnlyView = () => {
		this.props.onOpenReadOnlyView();
	};

	render() {
		const {
			isVisible,
			brokenBy,
			brokenDate,
			translation: { translate },
		} = this.props;

		return (
			<Modal
				width={800}
				open={isVisible}
				title={translate('lockWarnings.brokenLockDialog.title')}
				onCancel={this.handleBackToOverView}
				footer={[
					<Tooltip
						key="backToOverview"
						title={translate('lockWarnings.backToOverviewButton.tooltip')}
					>
						<Button type="primary" onClick={this.handleBackToOverView}>
							{translate('lockWarnings.backToOverviewButton.title')}
						</Button>
					</Tooltip>,
					<Tooltip key="readOnly" title={translate('lockWarnings.openReadOnlyButton.tooltip')}>
						<Button type="primary" onClick={this.handleOpenReadOnlyView}>
							{translate('lockWarnings.openReadOnlyButton.title')}
						</Button>
					</Tooltip>,
				]}
			>
				<p>
					{translate('lockWarnings.brokenLockDialog.textPiece1')}
					<StyledLockData>{brokenDate} Uhr</StyledLockData>
					{translate('lockWarnings.brokenLockDialog.textPiece2')}
					<StyledLockData>{brokenBy}</StyledLockData>
					{translate('lockWarnings.brokenLockDialog.textPiece3')}
				</p>
			</Modal>
		);
	}
}
export default withTranslation(BrokenLockDialog);
