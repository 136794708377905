import * as util from '@sep/br24-util';
import { Popover } from 'antd';
import { useEffect } from 'react';
import type { SetOptional } from 'type-fest';

import type { Teaser } from '../../../containers/ArticleEditor';
import {
	TypeImage as ImageIcon,
	TypeLink as LinkIcon,
	Trash2 as TrashIcon,
	Edit as EditIcon,
} from '../../Icon';

import { PreviewImage, Wrapper, Footer, FooterButton, MetaText, PreviewHead, Info } from '.';

type Props = {
	onDataFetch?: (index: number, title: string) => void;
	onRemove: () => void;
	index?: number;
	meta?: string;
	info?: string;
};

function getTextContent(str: string, length: number) {
	const tmp = document.createElement('div');
	tmp.innerHTML = str;
	const text = tmp.textContent || tmp.innerText || '';
	return util.string.truncate(text, length);
}

function ArticleMetaTeasersItem(props: Props & SetOptional<Teaser, 'meta'>) {
	const {
		description,
		editUrl,
		index,
		info,
		isExternal,
		title,
		meta,
		onDataFetch,
		onRemove,
		thumbnailUrl,
		url,
	} = props;

	// componentDidMount
	useEffect(() => {
		if (title && typeof index === 'number' && onDataFetch) {
			// Saves the title from HeavyMeta to the old article property 'label' for backwards compatability
			onDataFetch(index, title);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const thumbnail = thumbnailUrl
		? util.image.resize(thumbnailUrl, { width: 320, height: 180 })
		: null;
	const previewHeadText = title || url;

	return (
		<Wrapper>
			{info && <Info>{info}</Info>}
			<PreviewHead dangerouslySetInnerHTML={{ __html: getTextContent(previewHeadText, 64) }} />
			{description && <p dangerouslySetInnerHTML={{ __html: getTextContent(description, 250) }} />}

			<Footer>
				{thumbnail && (
					<Popover
						content={
							<a href={thumbnailUrl} target="_blank" rel="noopener noreferrer">
								<PreviewImage src={thumbnail} alt={title} />
							</a>
						}
					>
						<FooterButton type="link" aria-label="Artikelbild öffnen">
							<ImageIcon />
						</FooterButton>
					</Popover>
				)}
				<FooterButton type="link" href={url} target="_blank" aria-label="Link öffnen">
					<LinkIcon />
				</FooterButton>

				{!isExternal && (
					<FooterButton type="link" href={editUrl} target="_blank" aria-label="bearbeiten">
						<EditIcon />
					</FooterButton>
				)}
				{meta && <MetaText>{meta}</MetaText>}
				<FooterButton type="link" className="flex-end" onClick={onRemove} aria-label="Entfernen">
					<TrashIcon />
				</FooterButton>
			</Footer>
		</Wrapper>
	);
}

export default ArticleMetaTeasersItem;
