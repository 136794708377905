import type { Item } from '@/client/containers/AssetManager';

import { ModulePartAttachedDropzone } from '../../../../ui/ArticleModule/ArticleModuleParts';
import { DndCreateModuleContainer } from '../../DragAndDropHandler';
import { allAssetManagerTypes, toCreateModule } from '../../DragAndDropHandler/dndUtils';

interface Props {
	onAddViaAttachedDropzone?: (item: Item | undefined) => void;
	onResetAttachedDropzone: () => void;
}

// ? Still have to ask why acceptedItemTypes and itemTypes is passed down here
export function ArticleEditorAttachedDropzone(props: Props) {
	return (
		<DndCreateModuleContainer
			acceptedItemTypes={toCreateModule}
			itemTypes={allAssetManagerTypes}
			{...props}
		>
			<ModulePartAttachedDropzone />
		</DndCreateModuleContainer>
	);
}
