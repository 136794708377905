import styled from 'styled-components';

import { displayName } from '@/client/util/br24Utils';

import translations from '../../../translations';
import { media } from '../../../util/styled';

import { ArticleIcon } from './ArticleIcon';
import { ArticleNotes } from './ArticleNotes';
import ArticleOrigin from './ArticleOrigin';
import ArticlesTableRowDate from './ArticlesTableRowDate';
import type { ArticlesTableArticle } from './types';

const ArticleAnchor = styled.span`
	display: block;
	width: 100%;
	color: #000;
`;

interface ArticleRowStyles {
	hide?: boolean;
}

const ArticleTitle = styled.span<ArticleRowStyles>`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus {
		text-decoration: none;
	}
	span {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 600;
		font-size: 1.1em;
	}
	${media.lessThan<ArticleRowStyles>('monster')`
		.am-open {
			display: ${(props) => (props.hide ? 'none' : 'flex')};
		}
	`};
	${media.lessThan<ArticleRowStyles>('giant')`
		display: ${(props) => (props.hide ? 'none' : 'flex')};
	`};
`;
const ArticleBottom = styled.div`
	display: flex;
	flex-direction: row;
	padding-top: 0.5em;
	span {
		padding-right: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const ResponsiveContainer = styled.div`
	display: none;
	flex-direction: column;

	${media.lessThan('monster')`
		.am-open {
			display: flex;
		}
	`};
	${media.lessThan('giant')`
		display: flex;
	`};
`;

const SmartphoneContainer = styled.span<ArticleRowStyles>`
	display: ${(props) => (props.hide ? 'none' : 'inline')};

	${media.lessThan<ArticleRowStyles>('giant')`
		.am-open {
			display: ${(props) => (props.hide ? 'inline' : 'none')};
		}
	`};

	${media.lessThan<ArticleRowStyles>('medium')`
		display: ${(props) => (props.hide ? 'inline' : 'none')};
	`}
`;

type Props = {
	node: ArticlesTableArticle;
	dateKey: 'firstPublicationDate' | 'publicationDate' | 'lastEdited';
};

function ArticlesTableRowTitle(props: Props) {
	const { node, dateKey } = props;

	return (
		<ArticleAnchor>
			<ArticleTitle hide={true}>
				<span>{node.title}</span>
			</ArticleTitle>
			<ResponsiveContainer>
				<ArticleTitle>
					<span>{node.title}</span>
				</ArticleTitle>
				<ArticleBottom>
					<span>
						{displayName(node.authorByRevisionBy)}
						{' - '}
						{translations[node.capability]} <ArticleOrigin node={node} hide={false} />
						<SmartphoneContainer hide={true}>
							{' - '}
							<ArticlesTableRowDate node={node} dateKey={dateKey} />
						</SmartphoneContainer>
					</span>
					<ArticleIcon node={node} small={true} />
					<ArticleNotes node={node} />
				</ArticleBottom>
			</ResponsiveContainer>
		</ArticleAnchor>
	);
}

export default ArticlesTableRowTitle;
