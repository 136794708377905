import { PureComponent } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

import ArticleMetaTagsInput from './ArticleMetaTagsInput';
import type { OptionItem } from './ArticleMetaTagsInput';
import ArticleMetaTagsItems from './ArticleMetaTagsItems';
import type { TagItem } from './ArticleMetaTagsItems';

const Content = styled.div`
	margin-top: 1rem;
`;

type Props = WithTranslationProps & {
	onAdd: (tag: string) => void;
	onRemove: (id: number | string) => void;
	onSearch: (value: string) => void;
	onSelect: (item: OptionItem) => void;
	suggestions: Array<OptionItem> | undefined | null;
	tags: Array<TagItem> | undefined | null;
	key: string | number;
};

class ArticleMetaTags extends PureComponent<Props> {
	render() {
		const {
			onAdd,
			onRemove,
			onSearch,
			onSelect,
			suggestions,
			tags,
			translation: { translate },
			...rest
		} = this.props;

		return (
			<ArticleMetaPanel
				help={translate('article.meta.tags.help')}
				title={translate('article.meta.tags.title')}
				{...rest}
			>
				<ArticleMetaTagsInput
					suggestions={suggestions}
					onSearch={onSearch}
					onSelect={onSelect}
					onAdd={onAdd}
				/>
				{Array.isArray(tags) && tags.length ? (
					<Content>
						<ArticleMetaTagsItems tags={tags} onRemove={onRemove} />
					</Content>
				) : null}
			</ArticleMetaPanel>
		);
	}
}

export default withTranslation(ArticleMetaTags);
