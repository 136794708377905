import { Layout, Row, Col, Collapse, Tag } from 'antd';
import cn from 'classnames';
import { Component, type ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import globalStyles from '../../styles';
import DelayedSearch from '../DelayedSearch';

import styles from './FAQ.module.scss';
import data from './data';

const { Content } = Layout;
const { Panel } = Collapse;

const SPECIAL_TAGS_LIST = ['anleitung', 'video'];
const SPECIAL_TAGS_COLORS = { anleitung: 'green', video: 'blue' };
const intersectSpecialTags = (tags: Array<string>): Array<string> =>
	tags.reduce(
		(accumulator: Array<string>, value: string) =>
			SPECIAL_TAGS_LIST.includes(value) ? [...accumulator, value] : accumulator,
		[]
	);

type State = {
	searchTerm: string | undefined | null;
};

export class FAQ extends Component<any, State> {
	state = {
		searchTerm: null,
	};

	filterData = (searchTerm?: string | null) => {
		if (!searchTerm) {
			return data;
		}

		const query = new RegExp(`(.*)${searchTerm}(.*)`, 'i');

		return data.filter(
			({ question, tags }) =>
				!!(
					query.test(question) ||
					(!Array.isArray(tags) || tags.length === 0 ? true : tags.includes(searchTerm))
				)
		);
	};

	handleChangeSearchTerm = (value: string) => this.setState({ searchTerm: value });

	render() {
		const { searchTerm } = this.state;
		const intersection = this.filterData(searchTerm);
		const {
			location: { hash },
		} = this.props;

		const activeKey = hash && hash.length !== 0 ? hash.replace('#', '') : '';

		return (
			<Layout>
				<Helmet>
					<title>FAQ</title>
				</Helmet>
				<Content>
					<Row>
						<Col span={24} className={cn(styles.header, globalStyles.pM)}>
							<DelayedSearch
								placeholder="Durchsuchen..."
								onChange={this.handleChangeSearchTerm}
								defaultValue={searchTerm || ''}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							{intersection.length > 0 ? (
								<Collapse bordered={false} defaultActiveKey={activeKey}>
									{intersection.map(({ id, question, answer: Answer, tags }) => {
										let header: ReactNode = question;
										const specialTags = intersectSpecialTags(tags || []);

										if (specialTags.length > 0) {
											header = (
												<span>
													{specialTags.map((tag) => (
														<Tag key={tag} color={SPECIAL_TAGS_COLORS[tag] || null}>
															{tag.toUpperCase()}
														</Tag>
													))}{' '}
													{header}
												</span>
											);
										}

										return (
											<Panel key={id} header={header}>
												<Answer />
											</Panel>
										);
									})}
								</Collapse>
							) : (
								<div className={globalStyles.pM}>Keine Einträge gefunden.</div>
							)}
						</Col>
					</Row>
				</Content>
			</Layout>
		);
	}
}

export default FAQ;
