import { MODULE_TYPE_GALLERY } from '@sep/br24-constants';
import type { UploadFile } from 'antd';
import ih from 'immutability-helper';
import { Component, type ReactNode } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { Gallery, Image, ModuleTypeGallery, UpdateModuleInput } from '../..';
import { UserContainer } from '../../../../auth/AuthContext';
import config from '../../../../config';
import type { WithTranslationProps } from '../../../../translation';
import {
	ArticleModuleItem,
	ArticleModuleGallery,
	ArticleModuleUploader,
} from '../../../../ui/ArticleModule';
import { TypeGallery } from '../../../../ui/Icon';
import type { Item } from '../../../AssetManager';
import { DndSortModuleContainer, DndCreateModuleContainer } from '../../DragAndDropHandler';
import type { SimpleItem } from '../../DragAndDropHandler/DndSortModuleContainer';
import {
	getAllowedDataTypes,
	allAssetManagerTypes,
	getAEItemType,
	getAMItemType,
	toSortModule,
} from '../../DragAndDropHandler/dndUtils';
import deleteModule from '../../actions/deleteModule';
import updateModule from '../../actions/updateModule';
import convert from '../../convert';
import { activeArticleModuleSelector } from '../../selectors';
import BrokenModuleContainer from '../ArticleEditorBrokenModule';

interface Props extends WithTranslationProps, OwnProps, ReduxProps {
	isEditing: boolean;
	isThumbnailUploading: boolean;
	onDisplayAttachedDropzone: (order: number, position: 'top' | 'bottom') => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
	onResetAttachedDropzone?: () => void;
	onMoveToAssetManager: (id: string | number) => void;
	onMove: (dragIndex: SimpleItem, hoverIndex: SimpleItem) => void;
	onAddViaAttachedDropzone?: (item: Item | undefined) => void;
}

class GalleryModuleContainer extends Component<Props> {
	handleAddViaInitialDropzone = async (dropItem: Item | undefined) => {
		// check if dropitem exists, because if not
		// a file from outside the app has dropped
		if (!dropItem) {
			return;
		}

		const convertedItem = await convert(dropItem);
		const { thumbnailAdd } = this.props;

		if (convertedItem) {
			thumbnailAdd(
				this.props.id,

				convertedItem.data['gallery'] ? convertedItem.data['gallery'] : convertedItem.data['image']
			);
		}
	};

	handleAssetManagerThumbnailDrop = async (dropItem: Item | undefined) => {
		// check if dropitem exists, because if not
		// a file from outside the app has dropped
		if (!dropItem) {
			return;
		}

		const convertedItem = await convert(dropItem);
		const { thumbnailAdd } = this.props;

		if (convertedItem) {
			thumbnailAdd(
				this.props.id,

				convertedItem.data['gallery'] ? convertedItem.data['gallery'] : convertedItem.data['image']
			);
		}
	};

	handleChange = (key: string, value: string, index: number) => {
		this.props.updateItem(this.props.id, key, value, index);
	};

	handleDelete = () => {
		this.props.delete(this.props.id);
	};

	handleMoveImage = (dragIndex: number, hoverIndex: number) => {
		this.props.move(dragIndex, hoverIndex);
	};

	handleSuccessfulThumbnailUpload = (
		files: UploadFile<{ url: string }>[] | UploadFile<{ url: string }>
	) => {
		const { thumbnailAdd } = this.props;
		if (!Array.isArray(files)) {
			files = [files];
		}

		if (files.length >= 1) {
			const nextGallery = files.map((file) => ({
				url: file?.response?.url ?? null,
				title: '',
				altText: '',
				copyright: '',
			}));

			thumbnailAdd(this.props.id, nextGallery);
		}
	};

	handleSuccessfulUpload = (files: UploadFile<{ url: string }>[] | UploadFile<{ url: string }>) => {
		const { update } = this.props;

		if (!Array.isArray(files)) {
			files = [files];
		}

		if (files.length > 0) {
			const nextGallery = files.map((file) => ({
				url: file?.response?.url ?? null,
				title: '',
				altText: '',
				copyright: '',
			}));

			update(this.props.id, nextGallery);
		}
	};

	handleThumbnailDelete = (index: number) => {
		this.props.thumbnailDelete(this.props.id, index);
	};

	render() {
		const {
			translation: { translate },
			module,
			...rest
		} = this.props;

		if (!module.gallery) {
			return <BrokenModuleContainer id={module.rowId} />;
		}

		const acceptedDataTypes = getAllowedDataTypes();

		let displayModule: null | ReactNode = null;
		if (module.gallery && module.gallery.length > 0) {
			displayModule = (
				<div>
					<DndSortModuleContainer
						acceptedItemTypes={{
							dragSource: getAEItemType('GALLERY'),
							dropTarget: toSortModule,
						}}
						{...this.props}
					>
						<ArticleModuleItem
							onDelete={this.handleDelete}
							label={translate(`modules.${MODULE_TYPE_GALLERY}.text`)}
						>
							<ArticleModuleGallery
								images={module.gallery || []}
								onThumbnailDelete={this.handleThumbnailDelete}
								onChange={this.handleChange}
								onMoveImage={this.handleMoveImage}
								validation={module.__validation__}
								thumbnailDropzone={
									<DndCreateModuleContainer
										onAddViaInitialDropzone={this.handleAssetManagerThumbnailDrop}
										acceptedItemTypes={[
											getAMItemType('IMAGE'),
											getAMItemType('GALLERY'),
											getAEItemType('NATIVE'),
										]}
										itemTypes={[...allAssetManagerTypes, getAEItemType('NATIVE')]}
									>
										<UserContainer>
											{(user) => (
												<ArticleModuleUploader
													icon={<TypeGallery />}
													user={user}
													isThumbnailUpload={true}
													showUploadList={false}
													action={`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`}
													label={translate(`modules.${MODULE_TYPE_GALLERY}.dropzoneText`)}
													multiple={true}
													acceptedDataTypes={acceptedDataTypes}
													validation={Array.isArray(module.gallery) && module.gallery.length === 0}
													onSuccess={this.handleSuccessfulThumbnailUpload}
												/>
											)}
										</UserContainer>
									</DndCreateModuleContainer>
								}
								{...rest}
							/>
						</ArticleModuleItem>
					</DndSortModuleContainer>
				</div>
			);
		} else {
			displayModule = (
				<ArticleModuleItem
					label={translate(`modules.${MODULE_TYPE_GALLERY}.text`)}
					onDelete={this.handleDelete}
				>
					<DndCreateModuleContainer
						onAddViaInitialDropzone={this.handleAddViaInitialDropzone}
						acceptedItemTypes={[
							getAMItemType('IMAGE'),
							getAMItemType('GALLERY'),
							getAEItemType('NATIVE'),
						]}
						itemTypes={[...allAssetManagerTypes, getAEItemType('NATIVE')]}
					>
						<UserContainer>
							{(user) => (
								<ArticleModuleUploader
									icon={<TypeGallery />}
									user={user}
									action={`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`}
									label={translate(`modules.${MODULE_TYPE_GALLERY}.dropzoneText`)}
									multiple={true}
									acceptedDataTypes={acceptedDataTypes}
									validation={!!module.__validation__}
									onSuccess={this.handleSuccessfulUpload}
								/>
							)}
						</UserContainer>
					</DndCreateModuleContainer>
				</ArticleModuleItem>
			);
		}

		return displayModule;
	}
}

interface OwnProps {
	id: string;
}

const connector = connect(
	(state: ReduxState, props: OwnProps) => ({
		module: activeArticleModuleSelector(state, props.id) as ModuleTypeGallery,
	}),
	{
		update: (id: string, value: UpdateModuleInput['value']) =>
			updateModule(id, {
				type: 'gallery',
				value,
			}),
		delete: deleteModule,
	},
	(propsFromState, propsFromDispatch, ownProps) => ({
		...propsFromState,
		...propsFromDispatch,
		...ownProps,
		// move a item after dragging
		move: (dragItem: any, hoverItem: any) => {
			const currentGalleryList = propsFromState.module.gallery;
			const draggedItem = currentGalleryList?.[dragItem];
			if (!draggedItem) {
				return;
			}

			const updatedGalleryList = ih(currentGalleryList, {
				$splice: [
					[dragItem, 1],
					[hoverItem, 0, draggedItem],
				],
			});

			propsFromDispatch.update(ownProps.id, updatedGalleryList);
		},
		// update data in current item
		updateItem: (id: string, key: string, value: string, index: number) => {
			if (!propsFromState.module.gallery) {
				return;
			}
			const nextGallery = Array.from(propsFromState.module.gallery);
			nextGallery[index] = {
				...nextGallery[index],
				[key]: value,
			};

			propsFromDispatch.update(id, nextGallery);
		},
		// remove a existing image
		thumbnailDelete: (id: string, index: number) => {
			const nextGallery = ih(propsFromState.module.gallery, {
				$splice: [[index, 1]],
			});

			propsFromDispatch.update(id, nextGallery);
		},
		thumbnailAdd: (id: string, value: Gallery | Image) => {
			const nextGallery = ih(propsFromState.module.gallery, {
				$push: Array.isArray(value) ? value : [value],
			});

			propsFromDispatch.update(id, nextGallery);
		},
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(GalleryModuleContainer));
