import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import invariant from 'invariant';
import { Children, type SyntheticEvent, type ReactElement } from 'react';
import styled from 'styled-components';

export const HeaderSecondaryNavigationSubList = styled(({ className, children }) => (
	<ul className={cx(className, 'subItems')}>{children}</ul>
))`
	z-index: 2147483647;
	display: none;
	position: absolute;
	top: ${(props) => props.theme.header.height};
	list-style-type: none;
	margin: 0;
	left: calc(50% - ${(props) => props.theme.header.height} / 4 * 3 - 0.5rem);
	padding: 1rem;
	background-color: ${(props) => props.theme?.colors.greyHeader};
	&:after {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-bottom-color: ${(props) => props.theme.colors.greyHeader};
		border-width: 6px;
		margin-left: -6px;
	}
`;

type HeaderSecondaryNavigationSubListItemAnchorProps = {
	isActive: boolean;
};

export const HeaderSecondaryNavigationSubListItemAnchor = styled.a<HeaderSecondaryNavigationSubListItemAnchorProps>`
	color: ${(props) => props.theme.colors.br24Font};
	font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
	&:hover {
		color: ${(props) => props.theme.colors.primaryBlue};
	}
`;

type HeaderSecondaryNavigationSubListItemProps = {
	action?: string;
	isActive?: boolean;
	label: string;
	onClick?: (
		event: SyntheticEvent<HTMLAnchorElement>,
		action?: string | null,
		route?: string | null
	) => void;
	route?: string;
};

export function HeaderSecondaryNavigationSubListItem({
	action,
	isActive = false,
	label,
	onClick,
	route,
	...rest
}: HeaderSecondaryNavigationSubListItemProps) {
	const handleClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
		if (onClick) {
			onClick(event, action, route);
		}
	};

	return (
		<li {...rest}>
			<HeaderSecondaryNavigationSubListItemAnchor
				href={route}
				onClick={handleClick}
				isActive={isActive}
			>
				{label}
			</HeaderSecondaryNavigationSubListItemAnchor>
		</li>
	);
}

export const HeaderSecondaryNavigation = styled.nav`
	height: ${(props) => props.theme.header.height};
	position: absolute;
	right: 0;
`;

export const HeaderSecondaryNavigationList = styled.ul`
	z-index: 50;
	list-style-type: none;
	margin: 0;
	padding: 0;
`;

type HeaderSecondaryNavigationListItemAnchorProps = {
	isActive?: boolean;
	image?: string | null;
};

export const HeaderSecondaryNavigationListItemAnchor = styled.a<{
	$image?: string;
	$isActive?: boolean;
}>`
	display: inline-block;
	width: ${(props) => props.theme.header.height};
	max-width: ${(props) => props.theme.header.height};
	height: ${(props) => props.theme.header.height};
	max-height: ${(props) => props.theme.header.height};
	line-height: ${(props) => props.theme.header.height};
	text-align: center;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.br24Font};
	background: rgba(0, 0, 0, 0.6);
	font-size: 1.2rem;
	${(props) =>
		props.$image
			? `
		background-image: url(${props.$image});
		background-size: cover;
		`
			: ''};
`;

type HeaderSecondaryNavigationListItemProps = {
	action?: string;
	className?: string;
	icon?: IconProp;
	image?: string;
	label?: string;
	onClick?: (
		event: SyntheticEvent<HTMLAnchorElement>,
		action?: string | null,
		route?: string | null
	) => void;
	route?: string;
	subItems?: ReactElement<typeof HeaderSecondaryNavigationSubListItem>[];
} & HeaderSecondaryNavigationListItemAnchorProps;

function _HeaderSecondaryNavigationListItem({
	action,
	className,
	icon,
	image,
	isActive = false,
	label,
	onClick,
	route,
	subItems,
	...rest
}: HeaderSecondaryNavigationListItemProps) {
	invariant(
		typeof label === 'undefined' || typeof icon === 'undefined' || typeof image === 'undefined',
		'either the prop "label", "icon" or "image" must be provided'
	);

	const handleClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
		if (onClick) {
			onClick(event, action, route);
		}
	};

	return (
		<li className={className} {...rest}>
			<HeaderSecondaryNavigationListItemAnchor
				href={route}
				$image={image}
				$isActive={isActive}
				onClick={handleClick}
				title={label}
				data-testid="header-secondary-navigation-list-item"
			>
				{icon && <FontAwesomeIcon icon={icon} />}
			</HeaderSecondaryNavigationListItemAnchor>
			{Children.count(subItems) ? (
				<HeaderSecondaryNavigationSubList>{subItems}</HeaderSecondaryNavigationSubList>
			) : null}
		</li>
	);
}

export const HeaderSecondaryNavigationListItem = styled(_HeaderSecondaryNavigationListItem)`
	position: relative;
	float: left;
	max-height: ${(props) => props.theme.header.height};
	border-left: 1px solid rgba(255, 255, 255, 0.1);
	&:hover {
		.subItems {
			display: block;
		}
	}
	&.newIcon {
		animation: bg 4s ease;

		@keyframes bg {
			from {
				background-color: rgba(0, 0, 0, 0);
			}

			25% {
				background-color: rgba(10, 158, 215, 1);
			}

			50% {
				background-color: rgba(10, 158, 215, 0.5);
			}

			75% {
				background-color: rgba(10, 158, 215, 1);
			}

			to {
				background-color: rgba(0, 0, 0, 0);
			}
		}
	}
`;
