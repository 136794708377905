/**
 * @generated SignedSource<<14e0de97e25af322b78f31ee0e631b40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Gender = "FEMALE" | "MALE";
export type AuthorFilter = {
  and?: ReadonlyArray<AuthorFilter> | null;
  description?: StringFilter | null;
  email?: StringFilter | null;
  firstname?: StringFilter | null;
  gender?: GenderFilter | null;
  guid?: StringFilter | null;
  isActive?: BooleanFilter | null;
  isLdapUser?: BooleanFilter | null;
  jobTitle?: StringFilter | null;
  lastname?: StringFilter | null;
  not?: AuthorFilter | null;
  or?: ReadonlyArray<AuthorFilter> | null;
  profilePicture?: StringFilter | null;
  shortDescription?: StringFilter | null;
  status?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
  updatedBy?: StringFilter | null;
};
export type StringFilter = {
  distinctFrom?: string | null;
  distinctFromInsensitive?: string | null;
  endsWith?: string | null;
  endsWithInsensitive?: string | null;
  equalTo?: string | null;
  equalToInsensitive?: string | null;
  greaterThan?: string | null;
  greaterThanInsensitive?: string | null;
  greaterThanOrEqualTo?: string | null;
  greaterThanOrEqualToInsensitive?: string | null;
  in?: ReadonlyArray<string> | null;
  inInsensitive?: ReadonlyArray<string> | null;
  includes?: string | null;
  includesInsensitive?: string | null;
  isNull?: boolean | null;
  lessThan?: string | null;
  lessThanInsensitive?: string | null;
  lessThanOrEqualTo?: string | null;
  lessThanOrEqualToInsensitive?: string | null;
  like?: string | null;
  likeInsensitive?: string | null;
  notDistinctFrom?: string | null;
  notDistinctFromInsensitive?: string | null;
  notEndsWith?: string | null;
  notEndsWithInsensitive?: string | null;
  notEqualTo?: string | null;
  notEqualToInsensitive?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notInInsensitive?: ReadonlyArray<string> | null;
  notIncludes?: string | null;
  notIncludesInsensitive?: string | null;
  notLike?: string | null;
  notLikeInsensitive?: string | null;
  notStartsWith?: string | null;
  notStartsWithInsensitive?: string | null;
  startsWith?: string | null;
  startsWithInsensitive?: string | null;
};
export type GenderFilter = {
  distinctFrom?: Gender | null;
  equalTo?: Gender | null;
  greaterThan?: Gender | null;
  greaterThanOrEqualTo?: Gender | null;
  in?: ReadonlyArray<Gender> | null;
  isNull?: boolean | null;
  lessThan?: Gender | null;
  lessThanOrEqualTo?: Gender | null;
  notDistinctFrom?: Gender | null;
  notEqualTo?: Gender | null;
  notIn?: ReadonlyArray<Gender> | null;
};
export type BooleanFilter = {
  distinctFrom?: boolean | null;
  equalTo?: boolean | null;
  greaterThan?: boolean | null;
  greaterThanOrEqualTo?: boolean | null;
  greaterThanOrEqualTo?: boolean | null;
  in?: ReadonlyArray<boolean> | null;
  isNull?: boolean | null;
  lessThan?: boolean | null;
  lessThanOrEqualTo?: boolean | null;
  notDistinctFrom?: boolean | null;
  notEqualTo?: boolean | null;
};
export type IntFilter = {
  distinctFrom?: number | null;
  equalTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notDistinctFrom?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
};
export type DatetimeFilter = {
  distinctFrom?: string | null;
  equalTo?: string | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isNull?: boolean | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notDistinctFrom?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
};
export type AuthorsListQuery$variables = {
  allAuthorsFilter?: AuthorFilter | null;
  count: number;
  cursor?: any | null;
};
export type AuthorsListQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorsList_query">;
};
export type AuthorsListQuery = {
  response: AuthorsListQuery$data;
  variables: AuthorsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allAuthorsFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "allAuthorsFilter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "LASTNAME_ASC"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorsListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuthorsList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuthorsListQuery",
    "selections": [
      {
        "alias": "authors",
        "args": (v3/*: any*/),
        "concreteType": "AuthorsConnection",
        "kind": "LinkedField",
        "name": "allAuthors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "guid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePicture",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isLdapUser",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "authors",
        "args": (v3/*: any*/),
        "filters": [
          "filter",
          "orderBy"
        ],
        "handle": "connection",
        "key": "AuthorsList_authors",
        "kind": "LinkedHandle",
        "name": "allAuthors"
      }
    ]
  },
  "params": {
    "cacheID": "1267c2dc813fa6c59f99deb5e7e41a0f",
    "id": null,
    "metadata": {},
    "name": "AuthorsListQuery",
    "operationKind": "query",
    "text": "query AuthorsListQuery(\n  $count: Int!\n  $cursor: Cursor\n  $allAuthorsFilter: AuthorFilter\n) {\n  ...AuthorsList_query\n}\n\nfragment AuthorsList_query on Query {\n  authors: allAuthors(first: $count, after: $cursor, filter: $allAuthorsFilter, orderBy: LASTNAME_ASC) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        guid\n        firstname\n        lastname\n        profilePicture\n        isActive\n        isLdapUser\n        id\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd221f53ef333e3ebac28b7f526bc786";

export default node;
