/**
 * @generated SignedSource<<e36434858f68c77e3b69b105780802ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MangoLivestreamFilter = {
  audioOnly?: MangoBooleanFilter | null;
  broadcastedBy?: MangoBroadcastServiceRelationFilter | null;
  createdAt?: MangoDateFilter | null;
  importedFrom?: MangoImportLocatorConnectionFilter | null;
  labels?: MangoStringFilter | null;
  linksTo?: MangoLinkableThingRelationFilter | null;
  modifiedAt?: MangoDateFilter | null;
  regions?: MangoBroadcastRegionConnectionFilter | null;
  streamingUrls?: MangoLivestreamResourceConnectionFilter | null;
};
export type MangoBooleanFilter = {
  eq?: boolean | null;
  ne?: boolean | null;
};
export type MangoBroadcastServiceRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoIDFilter = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
};
export type MangoDateFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string | null> | null;
  lt?: string | null;
  lte?: string | null;
  ne?: string | null;
};
export type MangoImportLocatorConnectionFilter = {
  createdAt?: MangoDateFilter | null;
  empty?: MangoBooleanFilter | null;
  identifier?: MangoStringFilter | null;
  modifiedAt?: MangoDateFilter | null;
  system?: MangoSystemRelationFilter | null;
};
export type MangoStringFilter = {
  endsWith?: string | null;
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string | null> | null;
  lt?: string | null;
  lte?: string | null;
  matches?: string | null;
  ne?: string | null;
  startsWith?: string | null;
};
export type MangoSystemRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoLinkableThingRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoBroadcastRegionConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoLivestreamResourceConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type fetchLiveStreamsQuery$variables = {
  filter?: MangoLivestreamFilter | null;
};
export type fetchLiveStreamsQuery$data = {
  readonly viewer: {
    readonly allLivestreams: {
      readonly broadcastChannels: ReadonlyArray<{
        readonly node: {
          readonly broadcastedBy: {
            readonly id: string;
          } | null;
          readonly id: string;
          readonly labels: ReadonlyArray<string | null> | null;
        } | null;
      } | null> | null;
      readonly count: number | null;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly epg?: ReadonlyArray<{
            readonly broadcastEvent: {
              readonly end: string | null;
              readonly id: string;
              readonly publicationOf: {
                readonly defaultTeaserImage: {
                  readonly imageFiles: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly publicLocation: string | null;
                      } | null;
                    } | null> | null;
                  } | null;
                } | null;
                readonly id: string;
                readonly kicker: string | null;
                readonly title: string | null;
              } | null;
              readonly start: string | null;
            } | null;
            readonly current: boolean;
          } | null> | null;
          readonly id?: string;
          readonly streamingUrls?: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly publicLocation: string | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type fetchLiveStreamsQuery = {
  response: fetchLiveStreamsQuery$data;
  variables: fetchLiveStreamsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "MODIFIEDAT_ASC"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labels",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicLocation",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v6/*: any*/)
    ],
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kicker",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v13/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchLiveStreamsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "MangoLivestreamConnection",
            "kind": "LinkedField",
            "name": "allLivestreams",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "broadcastChannels",
                "args": null,
                "concreteType": "MangoLivestreamEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "broadcastedBy",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MangoLivestreamEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": (v5/*: any*/),
                            "concreteType": "MangoLivestreamResourceConnection",
                            "kind": "LinkedField",
                            "name": "streamingUrls",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MangoLivestreamResourceEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": "streamingUrls(first:1)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoEPGEntry",
                            "kind": "LinkedField",
                            "name": "epg",
                            "plural": true,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "broadcastEvent",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "publicationOf",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "defaultTeaserImage",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "MangoImageFileConnection",
                                            "kind": "LinkedField",
                                            "name": "imageFiles",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "MangoImageFileEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": (v7/*: any*/),
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MangoLivestream",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchLiveStreamsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "MangoLivestreamConnection",
            "kind": "LinkedField",
            "name": "allLivestreams",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "broadcastChannels",
                "args": null,
                "concreteType": "MangoLivestreamEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "broadcastedBy",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MangoLivestreamEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": (v5/*: any*/),
                            "concreteType": "MangoLivestreamResourceConnection",
                            "kind": "LinkedField",
                            "name": "streamingUrls",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MangoLivestreamResourceEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": "streamingUrls(first:1)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoEPGEntry",
                            "kind": "LinkedField",
                            "name": "epg",
                            "plural": true,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "broadcastEvent",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "publicationOf",
                                    "plural": false,
                                    "selections": [
                                      (v13/*: any*/),
                                      (v4/*: any*/),
                                      (v11/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "defaultTeaserImage",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "MangoImageFileConnection",
                                            "kind": "LinkedField",
                                            "name": "imageFiles",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "MangoImageFileEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": (v14/*: any*/),
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "MangoLivestream",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fa07927601877bc91c9039b93c6de90",
    "id": null,
    "metadata": {},
    "name": "fetchLiveStreamsQuery",
    "operationKind": "query",
    "text": "query fetchLiveStreamsQuery(\n  $filter: MangoLivestreamFilter\n) {\n  viewer {\n    allLivestreams(filter: $filter, orderBy: MODIFIEDAT_ASC) {\n      count\n      broadcastChannels: edges {\n        node {\n          __typename\n          labels\n          id\n          broadcastedBy {\n            __typename\n            id\n          }\n        }\n      }\n      edges {\n        node {\n          __typename\n          ... on MangoLivestream {\n            id\n            streamingUrls(first: 1) {\n              edges {\n                node {\n                  __typename\n                  publicLocation\n                  id\n                }\n              }\n            }\n            epg {\n              current\n              broadcastEvent {\n                __typename\n                start\n                end\n                id\n                publicationOf {\n                  __typename\n                  id\n                  title\n                  kicker\n                  defaultTeaserImage {\n                    __typename\n                    imageFiles {\n                      edges {\n                        node {\n                          __typename\n                          publicLocation\n                          id\n                        }\n                      }\n                    }\n                    id\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f49aff42ce8855cd99ee645f8f4780aa";

export default node;
