import type { ItemText, ImportedItemText } from '..';
import { AssetManagerItemText as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemText | ImportedItemText;
};

function AssetManagerItemText({ item }: Props) {
	return <Wrapper text={item.text} />;
}

export default AssetManagerItemText;
