import {
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
	STATUS_CHANGE_PUBLICATION_DATE,
} from '@sep/br24-constants';
import { Dropdown, type MenuProps } from 'antd';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { getStatusIcon, TemplateStatus, DropdownUp, DropdownDown } from '../../Icon';

import { DropdownButton, type Status } from './DropdownButton';

const ButtonIconWrapper = styled.div`
	font-size: 14px !important;
`;

const NextButtonIconWrapper = styled(ButtonIconWrapper)`
	margin-left: 10px;
`;

const Title = styled.span`
	margin-left: 10px;
`;

const DropdownButtonContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

type Props = {
	isDisabled: boolean;
	isTemplate: boolean;
	onClick: (x: Status) => void;
	status: Status | null | undefined;
};

export default function ArticleStatusActionDropdown({
	isTemplate,
	status,
	isDisabled,
	onClick,
}: Props) {
	const [isVisible, setIsVisible] = useState(false);

	const translate = useTranslate();

	const statusMenu = useMemo(() => {
		const buttons: MenuProps['items'] = [];

		if (isTemplate) {
			buttons.push({
				key: STATUS_DRAFT,
				disabled: isDisabled,
				label: translate('article.action.button.menu.template'),
			});

			buttons.push({
				key: STATUS_DELETED,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_DELETED}`),
			});
		} else if (status === STATUS_DRAFT) {
			buttons.push({
				key: STATUS_REVIEW,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_REVIEW}`),
			});
			buttons.push({
				key: STATUS_DELETED,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_DELETED}`),
			});
		} else if (status === STATUS_REVIEW) {
			buttons.push({
				key: STATUS_PUBLISHED,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_PUBLISHED}`),
			});
			buttons.push({
				key: STATUS_DRAFT,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_DRAFT}`),
			});
			buttons.push({
				key: STATUS_DELETED,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_DELETED}`),
			});
		} else if (status === STATUS_PUBLISHED) {
			buttons.push({
				key: STATUS_CHANGE_PUBLICATION_DATE,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.changePublicationDate`),
			});
			buttons.push({
				key: STATUS_DEPUBLISHED,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_DEPUBLISHED}`),
			});
		} else if (status === STATUS_DEPUBLISHED) {
			buttons.push({
				key: STATUS_REVIEW,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_REVIEW}`),
			});
			buttons.push({
				key: STATUS_DELETED,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_DELETED}`),
			});
		} else if (status === STATUS_SCHEDULED) {
			buttons.push({
				key: STATUS_DRAFT,
				disabled: isDisabled,
				label: translate(`article.action.button.menu.${STATUS_DRAFT}`),
			});
		}
		return buttons;
	}, [isDisabled, isTemplate, status, translate]);

	const handleOpenChange = (flag: boolean) => {
		setIsVisible(flag);
	};

	const renderIcon = () => {
		const Icon = isTemplate ? TemplateStatus : getStatusIcon(status);
		if (Icon) {
			return (
				<ButtonIconWrapper>
					<Icon />
				</ButtonIconWrapper>
			);
		}
		return null;
	};

	const renderTitle = () => {
		if (isTemplate) {
			return translate('article.action.button.text.template');
		}
		return translate(`article.action.button.text.${status}`);
	};

	return (
		<Dropdown
			menu={{ items: statusMenu, onClick: ({ key }) => onClick(key as Status) }}
			placement="bottomRight"
			open={isVisible}
			onOpenChange={handleOpenChange}
			disabled={isDisabled}
		>
			<DropdownButton $isTemplate={isTemplate} $status={status}>
				<DropdownButtonContent>
					{renderIcon()}
					<Title className="title">{renderTitle()}</Title>
					<NextButtonIconWrapper>
						{isVisible ? <DropdownUp /> : <DropdownDown />}
					</NextButtonIconWrapper>
				</DropdownButtonContent>
			</DropdownButton>
		</Dropdown>
	);
}
