/**
 * @generated SignedSource<<e45feb5a46fa2aedfaf44797b60ce93d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LockedByAuthor_boardsTeaser$data = {
  readonly authorByLockedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
    readonly profilePicture: string | null;
  } | null;
  readonly lockedSince: string | null;
  readonly " $fragmentType": "LockedByAuthor_boardsTeaser";
};
export type LockedByAuthor_boardsTeaser$key = {
  readonly " $data"?: LockedByAuthor_boardsTeaser$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockedByAuthor_boardsTeaser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockedByAuthor_boardsTeaser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authorByLockedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "guid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePicture",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BoardsTeaser",
  "abstractKey": null
};

(node as any).hash = "2d0dd8ae74f6de089b8d7f34c9915edd";

export default node;
