import styled from 'styled-components';

const ActionWrapper = styled.div`
	font-size: 2rem;
	color: rgba(255, 255, 255, 0.6);
	flex: 0 1 100%;
	max-width: 100%;
	min-height: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ease 200ms all;
	background-color: rgba(0, 0, 0, 0.4);
	&:hover {
		color: #ffffff;
		background-color: rgba(10, 158, 215, 0.6);
	}
`;

export type AssetManagerItemActionProps = {
	icon: any;
	innerRef: any;
};

function AssetManagerItemAction({ innerRef, icon, ...rest }: AssetManagerItemActionProps) {
	return (
		<ActionWrapper ref={innerRef} {...rest}>
			{icon}
		</ActionWrapper>
	);
}

export default AssetManagerItemAction;
