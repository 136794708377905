import { Popover, Dropdown, Modal } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { darken } from 'polished';
import { useState, useEffect, useRef, useCallback, Children } from 'react';
import { useDrop } from 'react-dnd';
import styled, { useTheme } from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';
import type { MenuInfo } from '@/types/libs';

import { toAssetManager } from '../../../containers/ArticleEditor/DragAndDropHandler/dndUtils';
import type {
	UpdateGlobalGroupActionFunction,
	RemoveGlobalGroupActionFunction,
	ToggleGlobalGroupActionFunction,
} from '../../../containers/AssetManager/index';
import { Option as IconDots, Lock, DirectionRight, DirectionDown, UserDefault } from '../../Icon';

import AssetManagerGroupsCreator from './AssetManagerGroupsCreator';

const { confirm } = Modal;

interface Props {
	id: string;
	title: string;
	color?: string;
	isDefault?: boolean;
	parentColor?: string;
	onShowSpace: (a: string) => void;
	searchActive: boolean;
	children: any;
	level: number;
	showSubGroups?: boolean;
	onToggleSubGroups: () => void;
	updateGroup: UpdateGlobalGroupActionFunction;
	removeGroup: RemoveGlobalGroupActionFunction;
	toggleGroupLock: ToggleGlobalGroupActionFunction;
	isLocked: boolean;
	isHardLocked: boolean;
}

const Wrapper = styled.div`
	margin-bottom: 10px;
`;

const FauxButton = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: stretch;
	color: white;
	border-radius: 2px;
	padding: 5px;
	cursor: pointer;
	border: 0px solid transparent;
	border-style: solid;

	> * {
		flex: 0 1 auto;
		&:last-child {
			margin-right: 0;
			margin-left: auto;
		}
	}
`;

const InnerButton = styled.button`
	background-color: transparent;
	border: none;
	color: white;
	width: 32px;
	height: 32px;
	text-align: center;

	&:focus,
	&:active {
		outline: none;
	}
`;

const SpacerButton = styled(InnerButton)`
	pointer-events: none;
`;

const Title = styled.p`
	color: white;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
`;

const ChildrenWrapper = styled.div`
	margin-top: 10px;
`;

export default function AssetManagerGroupItem(props: Props) {
	const {
		children,
		parentColor,
		searchActive,
		isLocked,
		isHardLocked,
		level,
		isDefault,
		showSubGroups,
	} = props;

	const translate = useTranslate();
	const theme = useTheme();
	// HINT: used to be `theme.assetManager.groups.defaultColor` but there is none defined
	const defaultColor = theme.assetManager.groups.colors[0];

	const [editModalVisible, setModalVisible] = useState(false);
	const [editableGroupData, setEditableGroupData] = useState({
		title: props.title,
		color: props.color || defaultColor,
	});

	const [{ isOver }, dropTarget] = useDrop(
		() => ({
			accept: toAssetManager,
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		}),
		[]
	);

	const timer = useRef<NodeJS.Timeout | null>(null);
	const isLeafGroup = Children.count(props.children) === 0;
	const isLockStatusChangeable = isLeafGroup && !isHardLocked;

	useEffect(() => {
		if (!editModalVisible) {
			setEditableGroupData({
				title: props.title,
				color: props.color ?? defaultColor,
			});
		}
	}, [editModalVisible, props.color, props.title, defaultColor]);

	const toggleShowChildren = useCallback(() => {
		props.onToggleSubGroups();
	}, [props]);

	const handleShowSpace = useCallback(() => {
		props.onShowSpace(props.id);
	}, [props]);

	useEffect(() => {
		if (isOver) {
			timer.current = setTimeout(() => {
				if (isLeafGroup) {
					handleShowSpace();
				} else {
					toggleShowChildren();
				}
			}, 800);
		}
		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		};
	}, [handleShowSpace, isLeafGroup, isOver, toggleShowChildren]);

	const handleClick = () => {
		if (isLeafGroup) {
			handleShowSpace();
		} else {
			toggleShowChildren();
		}
	};

	const handleChange = (newGroup: Partial<{ color: string; title: string }>) => {
		setEditableGroupData({ ...editableGroupData, ...newGroup });
	};

	const handleSave = () => {
		const { title, color } = editableGroupData;
		props.updateGroup(props.id, { title, color });

		setModalVisible(false);
	};

	const handleEditModalCancel = () => {
		setModalVisible(false);
	};

	const handleDelete = () => {
		confirm({
			zIndex: 10000,
			content: translate('modules.remove'),
			onOk: () => props.removeGroup(props.id),
		});
	};

	const handleContextMenuClick = (info: MenuInfo) => {
		switch (info.key) {
			case 'unlock':
				props.toggleGroupLock(props.id, false);
				break;
			case 'lock':
				props.toggleGroupLock(props.id, true);
				break;
			case 'edit':
				setModalVisible(true);
				break;
			case 'delete':
				handleDelete();
				break;
		}
	};

	const { title, color } = editableGroupData;

	// 7 was chosen by trial and error...
	const backgroundColor =
		color ?? (typeof parentColor === 'string' ? darken(level / 7, parentColor) : 'transparent');
	const borderLeftWidth = `${level * 10}px`;

	const contextMenuItems: ItemType[] = [];
	if (isLocked && isLockStatusChangeable) {
		contextMenuItems.push({ key: 'unlock', label: translate('assetManager.groups.unlock') });
	}
	if (!isLocked && isLockStatusChangeable) {
		contextMenuItems.push({ key: 'lock', label: translate('assetManager.groups.lock') });
	}
	if (!isDefault) {
		contextMenuItems.push({ key: 'edit', label: translate('assetManager.groups.edit') });
		contextMenuItems.push({ key: 'delete', label: translate('assetManager.groups.delete') });
	}

	return (
		<div ref={dropTarget} id="assetManagerGroupItem">
			<h1 />
			<Wrapper>
				<Popover
					title=""
					open={editModalVisible}
					content={
						<AssetManagerGroupsCreator
							color={color}
							title={title}
							isVisible={true}
							windowTitle={translate('assetManager.groups.edit')}
							onChange={handleChange}
							onSubmit={handleSave}
							onDelete={handleDelete}
							onHidePopover={handleEditModalCancel}
						/>
					}
				>
					<FauxButton style={{ backgroundColor, borderColor: parentColor, borderLeftWidth }}>
						{!isLeafGroup && (
							<InnerButton onClick={handleClick}>
								{showSubGroups ? <DirectionDown /> : <DirectionRight />}
							</InnerButton>
						)}

						{!isDefault && (
							<InnerButton onClick={handleClick}>
								<UserDefault
									title={translate('assetManager.groups.userGenerated')}
									titleId={'user-generated'}
								/>
							</InnerButton>
						)}

						{isLocked && (
							<InnerButton onClick={handleClick}>
								<Lock
									style={{
										opacity: isHardLocked ? 0.5 : 1,
									}}
								/>
							</InnerButton>
						)}

						<SpacerButton onClick={handleClick} />

						<Title onClick={handleClick} className="title">
							{title}
						</Title>

						{[!isLeafGroup, !isDefault, isLocked]
							.filter((b) => !!b)
							.map((_, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<SpacerButton key={index} onClick={handleClick} />
							))}

						{isLeafGroup && (!isDefault || isLockStatusChangeable) ? (
							<Dropdown
								menu={{ items: contextMenuItems, onClick: handleContextMenuClick }}
								trigger={['click']}
								getPopupContainer={() => {
									const element = document.getElementById('assetManagerGroupItem');

									if (!element) {
										throw new Error("Can't find element");
									}
									return element;
								}}
							>
								<InnerButton>
									<IconDots />
								</InnerButton>
							</Dropdown>
						) : (
							<SpacerButton onClick={handleClick} />
						)}
					</FauxButton>
				</Popover>
				{!isLeafGroup && (showSubGroups || searchActive) && (
					<ChildrenWrapper>{children}</ChildrenWrapper>
				)}
			</Wrapper>
		</div>
	);
}
