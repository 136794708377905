import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../../../environment';
import styles from '../../../../styles';

import ArticlesSearchContainer from './ArticlesSearchContainer';
import type { ArticleSearchQuery } from './__generated__/ArticleSearchQuery.graphql';

type Props = {
	searchTerm: string | undefined | null;
	category: string | undefined | null;
};

export default function ({ searchTerm, category }: Props) {
	return (
		<QueryRenderer<ArticleSearchQuery>
			environment={environment}
			query={graphql`
				query ArticleSearchQuery(
					$count: Int
					$cursor: Cursor
					$searchTerm: String
					$category: String
				) {
					...ArticlesSearchContainer_query
				}
			`}
			variables={{
				count: 25,
				searchTerm: searchTerm || '',
				category: category || '',
			}}
			render={({ props, error }) => {
				if (error) {
					return (
						<div className={styles.contentWrapper}>
							<Alert message="Fehler" description={error.message} type="error" />
						</div>
					);
				}

				if (!props) {
					return (
						<div className={styles.contentWrapper}>
							<div>
								<Spin size="large" />
							</div>
						</div>
					);
				}
				return <ArticlesSearchContainer query={props} />;
			}}
		/>
	);
}
