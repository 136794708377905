import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { PRIORITY_CRITICAL, PRIORITY_HIGH, PRIORITY_LOW } from '@sep/br24-constants';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import type { ReactElement } from 'react';

import type { Priority } from '@/types/schema';

import translations from '../../translations';

import styles from './index.module.scss';

type Props = {
	priority: Priority;
};

export default ({ priority }: Props) => {
	let type: ReactElement;

	if (priority === PRIORITY_CRITICAL || priority === PRIORITY_HIGH) {
		type = (
			<ArrowUpOutlined className={classNames(styles.priority, styles[priority.toLowerCase()])} />
		);
	} else if (priority === PRIORITY_LOW) {
		type = (
			<ArrowDownOutlined className={classNames(styles.priority, styles[priority.toLowerCase()])} />
		);
	} else {
		return null;
	}

	return <Tooltip title={`Priorität: ${translations[priority]}`}>{type}</Tooltip>;
};
