import type { AppThunkAction } from '@/client/store/redux';

import type { RemoveItemOptions, RemoveItemAction, Space } from '..';

import removeItem from './removeItem';

const removeItemFromSelectedSpace =
	(
		id: string,
		space: Space,
		group: string | null,
		options?: RemoveItemOptions
	): AppThunkAction<RemoveItemAction> =>
	(dispatch) => {
		dispatch(removeItem(space, id, options, group));
	};

export default removeItemFromSelectedSpace;
