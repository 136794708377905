import { LS_READ_ONLY_MODE } from '@sep/br24-constants';
import { Alert, Modal, notification } from 'antd';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { graphql, type PreloadedQuery, useFragment, usePreloadedQuery } from 'react-relay';
import styled from 'styled-components';

import { updateLockStatus } from '@/client/store/reducers/lockStatus';
import { lockStatusSelector } from '@/client/store/reducers/lockStatus/selectors';
import { useDispatch } from '@/client/store/redux';
import { useTranslate } from '@/client/translation/useTranslate';
import { LockError } from '@/client/util/lockHandler/LockError';
import { LockHandlingArticle } from '@/client/util/lockHandler/LockHandling';
import { LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED } from '@/client/util/lockHandler/temporary-cms-constants';

import { useUser } from '../../auth/AuthContext';
import { Spinner } from '../../ui/Icon';
import { triggerReloadPreview } from '../../util/preview';
import { media } from '../../util/styled';
import { isAssetManagerVisibleSelector } from '../AssetManager/selectors';

import { ArticleEditorContext, type IArticleEditorContext } from './ArticleEditorContext';
import ArticleEditorMeta from './ArticleEditorMeta';
import ArticleEditorModules from './ArticleEditorModules';
import ArticleEditorStatusBarContainer from './ArticleEditorStatusBar/ArticleEditorStatusBarContainer';
import ChangePubDateOverlayContainer from './ChangePubDateOverlay';
import ArticleEditorContainerQuery, {
	type ArticleEditorContainerQuery as ArticleEditorContainerQueryType,
} from './__generated__/ArticleEditorContainerQuery.graphql';
import type {
	ArticleEditor_article$data,
	ArticleEditor_article$key,
} from './__generated__/ArticleEditor_article.graphql';
import exit from './actions/exit';
import { initialize } from './actions/initialize';
import save from './actions/save';
import { getSuccessMessage, getErrorMessage } from './notifications';
import {
	activeArticleIdSelector,
	isArticleEditedSelector,
	latestFatalErrorSelector,
} from './selectors';

type ContainerProps = {
	isViewportLimited: boolean;
	isInactive: boolean;
};

const AutoSaveOverlay = styled.p`
	display: block;
	border: 1;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	> svg {
		animation: rotate 1s linear infinite;
	}
`;

const Container = styled.div<ContainerProps>`
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	opacity: ${(props: ContainerProps) => (props.isInactive ? 0.2 : 1)};
	pointer-events: ${(props: ContainerProps) => (props.isInactive ? 'none' : 'all')};

	${media.greaterThan<ContainerProps>('giant')`
		width: ${(props) => (props.isViewportLimited ? '90%' : '1300px')};
	`}
	${media.between<ContainerProps>('large', 'giant')`
		width: ${(props) => (props.isViewportLimited ? '90%' : '1200px')};
	`}
	${media.between('tablet', 'large')`
		width: 90%;
	`}
	${media.lessThan('tablet')`
		width: 90%;
	`}
`;

const ContainerPublicationBar = styled.div`
	flex-shrink: 0;
	width: 100%;
`;

const ContainerMeta = styled.div`
	${media.greaterThan('giant')`
		width: 400px;
	`}
	${media.between('large', 'giant')`
		width: 350px;
	`}
	${media.between('tablet', 'large')`
		width: 300px;
	`}
	${media.lessThan('tablet')`
		width: 100%;
		order: 2;
	`}
`;

const ContainerEditor = styled.div`
	background-color: #ffffff;
	border: 1px solid #d9d9d9;
	margin-left: 1rem;
	padding: 3rem;
	flex: 1;
	width: 100%;
	overflow: hidden;
	${media.lessThan('tablet')`
		flex-shrink: 0;
		width: 100%;
		order: 1;
	`};
`;

const ContainerAlert = styled.div`
	margin-left: 1rem;
	padding: 3rem;
	flex: 1;
	width: 100%;
	overflow: hidden;
	${media.lessThan('tablet')`
		flex-shrink: 0;
		width: 100%;
		order: 1;
	`};
`;

export interface Props {
	id: string | null;
	article: ArticleEditor_article$data;
	refresh: (handler: (error?: Error) => void) => void;
	queryReference: PreloadedQuery<ArticleEditorContainerQueryType>;
	isRefreshing?: boolean;
}

export default function ArticleEditor(props: Props) {
	const { refresh, id: articleId } = props;
	const [isChangePubDateOverlayVisible, setIsChangePubDateOverlayVisible] = useState(false);
	const translate = useTranslate();
	const [isAutoSaving, setIsAutoSaving] = useState(false);
	const [articleEditorContext, setArticleEditorContext] = useState<IArticleEditorContext>({});
	const activeArticleId = useSelector(activeArticleIdSelector);
	const isInitialized = activeArticleId === articleId;
	const isAssetManagerVisible = useSelector(isAssetManagerVisibleSelector);
	const isArticleEdited = useSelector(isArticleEditedSelector);
	const latestFatalError = useSelector(latestFatalErrorSelector);
	const { mode: lockMode } = useSelector(lockStatusSelector);
	const dispatch = useDispatch();

	const user = useUser();

	const queryResult = usePreloadedQuery<ArticleEditorContainerQueryType>(
		ArticleEditorContainerQuery,
		props.queryReference
	);
	const article = useFragment<ArticleEditor_article$key>(ArticleFragment, queryResult.article);

	/**
	 * Performs a refetch of the data and (re)initializes the document.
	 * If a callback is given the callback will be executed afterwards.
	 *
	 * The refetchDocument method will also be executed in the HOC withLockHandlingForEditor.
	 */
	const refetchDocument = useCallback(
		async (callback: (error?: Error | null) => void): Promise<boolean> => {
			try {
				return new Promise<boolean>((resolve, reject) => {
					refresh((error?: Error) => {
						if (error) {
							const errorMessage = getErrorMessage();
							reject(errorMessage);
						}

						resolve(true);

						if (typeof callback === 'function') {
							callback(error);
						}
					});
				});
			} catch (error) {
				callback(error as Error);

				// eslint-disable-next-line no-console
				console.error('refetch error', error);
			}

			return false;
		},
		[refresh]
	);

	const LockHandlerComponent = useCallback(() => {
		if (!article || !isInitialized) {
			return;
		}

		return <LockHandlingArticle article={article} refetchDocument={refetchDocument} />;
	}, [isInitialized, article, refetchDocument]);

	const handleSaveFinished = useCallback(
		async (status?: string): Promise<void> => {
			try {
				setIsAutoSaving(false);

				await refetchDocument((error) => {
					if (!error) {
						triggerReloadPreview();
					}
				}).catch(() => {
					notification.error({
						message: translate(`saveArticle.error.refetch.title`),
						description: translate(`saveArticle.error.refetch.description`),
						duration: 3600,
					});
				});
			} catch (error) {
				notification.error(getErrorMessage());
			}

			notification.success(getSuccessMessage(status));
		},
		[refetchDocument, translate]
	);

	const handleLockError = useCallback(
		async (err: LockError<ArticleEditor_article$data>) => {
			const editor = err.getReference().authorByLockedBy;
			const lastEdited = err.getReference().lastEdited;

			dispatch(
				updateLockStatus({
					mode: LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED,
					brokenBy: {
						firstname: String(editor?.firstname),
						lastname: String(editor?.lastname),
						since: lastEdited,
					},
					userInfo: {
						differentUser: false,
						ownUser: false,
						noUser: true,
					},
				})
			);
		},
		[dispatch]
	);

	const handleSaveError = useCallback(
		async (err: Error, status?: string) => {
			if (err.name === 'RelayNetwork' && (err as any).type === 'error') {
				notification.error({
					message: translate(`saveArticle.error.refetch.title`),
					description: translate(`saveArticle.error.refetch.description`),
					duration: 3600,
				});
			}

			if (err instanceof LockError) {
				handleLockError(err);
			}

			notification.error(getErrorMessage(status));

			setIsAutoSaving(false);
		},
		[handleLockError, translate]
	);

	const debounceRef = useRef<DebouncedFunc<() => void>>();

	const handleKeyboardEvent = useCallback(
		(event: KeyboardEvent) => {
			if (isAutoSaving) {
				event.preventDefault();
				return;
			}

			// allow saving only when article is not in read only mode
			if (
				lockMode !== LS_READ_ONLY_MODE &&
				(event.metaKey || event.ctrlKey) &&
				event.which === 83
			) {
				window.focus();
				if (document.activeElement && typeof document.activeElement['blur'] === 'function') {
					(document.activeElement as any).blur();
				}
				event.preventDefault();

				if (isArticleEdited) {
					debounceRef.current?.cancel();
					debounceRef.current = debounce(() => {
						// TODO: Check if it really applies
						const nextStatus = article?.status || 'DRAFT';

						dispatch(
							save(
								{
									status: nextStatus,
								},
								handleSaveFinished,
								handleSaveError,
								handleChangePubDateModalVisibility
							)
						);
					}, 500);
					debounceRef.current?.();
				}
			}
		},
		[
			article?.status,
			handleSaveError,
			handleSaveFinished,
			isArticleEdited,
			isAutoSaving,
			lockMode,
			dispatch,
		]
	);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyboardEvent);
		return () => document.removeEventListener('keydown', handleKeyboardEvent);
	}, [handleKeyboardEvent]);

	const initializeEditor = useCallback(() => {
		if (article) {
			dispatch(initialize(article, user));
		}
	}, [article, dispatch, user]);

	const oldQueryReference = useRef<PreloadedQuery<any>>();

	useEffect(() => {
		if (props.queryReference !== oldQueryReference.current) {
			initializeEditor();
			oldQueryReference.current = props.queryReference;
		}
	}, [initializeEditor, props.queryReference]);

	useEffect(() => {
		if (latestFatalError) {
			const errorMessage = latestFatalError.message ? latestFatalError.message : latestFatalError;

			const { destroy } = Modal.error({
				title: translate('fatalError.title'),
				content: (
					<div
						dangerouslySetInnerHTML={{
							__html: translate('fatalError.description', { message: errorMessage }),
						}}
					/>
				),
			});

			return () => destroy();
		}
	}, [latestFatalError, translate]);

	useEffect(() => {
		return () => {
			dispatch(exit());
		};
		// this should only run when the component is unmounted
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAutoSaveFinished = async () => {
		try {
			notification.success(getSuccessMessage('autoSave'));
			await refetchDocument(() => {
				setIsAutoSaving(false);
			});
		} catch (error) {
			console.error(`Autosave failing: ${error}`);
		}
	};

	const handleChangePubDateModalVisibility = async (isVisible: boolean) => {
		setIsChangePubDateOverlayVisible(isVisible);
	};

	return (
		<>
			{!article && (
				<ContainerAlert>
					<Alert
						message="Fehler"
						description="Der Artikel konnte nicht gefunden werden."
						type="error"
					/>
				</ContainerAlert>
			)}

			{isAutoSaving && (
				<AutoSaveOverlay>
					<Spinner /> Automatische Sicherung
				</AutoSaveOverlay>
			)}

			{LockHandlerComponent()}

			{article && isInitialized && (
				<ArticleEditorContext.Provider
					value={{ context: articleEditorContext, setContext: setArticleEditorContext }}
				>
					<Container isInactive={isAutoSaving} isViewportLimited={isAssetManagerVisible}>
						<ChangePubDateOverlayContainer
							status={article.status}
							isVisible={isChangePubDateOverlayVisible}
							onDisplayChange={handleChangePubDateModalVisibility}
							onSaveFinished={handleSaveFinished}
							onSaveError={handleSaveError}
						/>
						<ContainerPublicationBar>
							<ArticleEditorStatusBarContainer
								lockedBy={article.lockedBy}
								hasArticleNotes={!!articleEditorContext?.meta?.notes?.hasArticleNotes}
								onSaveFinished={handleSaveFinished}
								onSaveError={handleSaveError}
								onAutoSaveFinished={handleAutoSaveFinished}
								onBeforeSave={handleChangePubDateModalVisibility}
								onAutoSaveStart={() => {
									setIsAutoSaving(true);
								}}
							/>
						</ContainerPublicationBar>
						<ContainerMeta>
							<ArticleEditorMeta />
						</ContainerMeta>
						<ContainerEditor>
							<ArticleEditorModules />
						</ContainerEditor>
					</Container>
				</ArticleEditorContext.Provider>
			)}
		</>
	);
}

export const ArticleFragment = graphql`
	fragment ArticleEditor_article on Article {
		id
		rowId
		title
		status
		pushNotification
		capability
		shareUrl
		headline
		title
		lastEdited
		teaserText
		modules {
			totalCount
			edges {
				node {
					id
					rowId
					articleId
					type
					order
					meta
					image {
						url
						altText
						copyright
						title
					}
					audio {
						url
						copyright
						title
						duration
						thumbnail {
							url
							altText
							copyright
							title
						}
					}
					video {
						url
						copyright
						title
						duration
						thumbnail {
							url
							altText
							copyright
							title
						}
					}
					livestream {
						livestreamId
						programmeId
						thumbnail {
							url
							altText
							copyright
							title
						}
					}
					video360 {
						copyright
						title
						duration
						thumbnail {
							url
							altText
							copyright
							title
						}
						sources {
							url
							resolution
						}
					}
					embed {
						source
						service
						altText
						url
						thumbnail {
							url
							altText
							copyright
							title
						}
					}
					gallery {
						url
						altText
						copyright
						title
					}
					text
				}
			}
		}
		pushNotification
		slug
		shortUrl
		publicationDate
		publicationPriority
		enableAmp
		links {
			url
			label
			meta {
				title
				url
				title
				date
				description
				source
				thumbnail
				originalThumbnail
			}
			article {
				status
				rowId
			}
			board {
				status
				rowId
			}
		}
		rawLinks {
			url
			label
			article {
				status
			}
			board {
				status
			}
		}
		invalidLinks {
			url
			label
		}
		tags
		authorsDescription
		authorByRevisionBy {
			firstname
			lastname
		}
		articlesAuthorsByArticleId(first: 20, orderBy: ORDER_ASC) {
			edges {
				node {
					authorGuid
					articleId
					order
					authorByAuthorGuid {
						firstname
						lastname
					}
				}
			}
		}
		redirectionsByArticleId {
			edges {
				node {
					id
					rowId
					sophoraId
					articleId
				}
			}
		}
		articlesTagsByArticleId(first: 1000) {
			edges {
				node {
					tagByTagId {
						count
						rowId
						text
						status
					}
				}
			}
		}
		sourceOrigin
		sourceBroadcastDate
		primaryCategory
		otherCategories
		location
		language
		geolocation
		districts
		metaDescription
		isLive
		allowComments
		isTemplate
		isImported
		expirationAt
		expirationTemplate
		seoTitle
		historiesByArticleId(orderBy: CREATED_AT_DESC, first: 2) {
			totalCount
			edges {
				node {
					createdAt
					rowId
					data
				}
			}
		}
		boardsSectionsItemsByArticleId {
			totalCount
		}
		authorByLockedBy {
			guid
			firstname
			lastname
		}
		lockedBy
		lockedSince
		notes
		externalId
		canonicalUrl
		...LockHandling_article
	}
`;
