import { debounce } from 'lodash-es';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import { updateSearchTerm, fetchById } from '../../../../store/reducers/sophora';
import { SophoraSearch, SophoraSearchLinkList } from '../../../../ui/ArticleMeta';
import createRedirection from '../../actions/createRedirection';
import deleteRedirection from '../../actions/deleteRedirection';
import { articleRedirectionSelector } from '../../selectors';

const SEARCH_DELAY_MILLISECONDS = 500;

class SophoraSearchContainer extends Component<ReduxProps> {
	private searchById = debounce(
		(isSearchWorking: boolean, searchValue: string | null | undefined) => {
			if (!isSearchWorking && searchValue) {
				this.props.startSearchById(searchValue);
			}
		},
		SEARCH_DELAY_MILLISECONDS
	);

	componentWillUnmont() {
		this.searchById.cancel();
	}

	handleBlur = () => {
		this.props.changeSearchValue(null);
	};

	handleRemove = (id: string | number) => {
		this.props.deleteMigration(id);
	};

	handleSearch = (searchValue: string | undefined | null) => {
		const { isSearchWorking } = this.props;

		this.props.changeSearchValue(searchValue);
		this.searchById(isSearchWorking, searchValue);
	};

	handleSelect = (sophoraId: string) => {
		this.props.changeSearchValue(null);
		this.props.createMigration(sophoraId);
	};

	render() {
		const { searchTerm, isSearchWorking, result, redirections } = this.props;

		return (
			<SophoraSearch
				searchTerm={searchTerm}
				isSearchWorking={isSearchWorking}
				searchResult={result}
				onSearch={this.handleSearch}
				onSelect={this.handleSelect}
				onBlur={this.handleBlur}
			>
				<SophoraSearchLinkList
					onRemove={this.handleRemove}
					connectedSophoraIds={redirections ?? []}
				/>
			</SophoraSearch>
		);
	}
}

const conector = connect(
	(state: ReduxState) => {
		const { searchTerm, isSearchWorking, map } = state.sophora;

		return {
			searchTerm,
			isSearchWorking,
			result: !isSearchWorking && searchTerm ? Object.keys(map).map((id) => map[id]) : null,
			redirections: articleRedirectionSelector(state),
		};
	},
	{
		changeSearchValue: updateSearchTerm,
		startSearchById: fetchById,
		createMigration: createRedirection,
		deleteMigration: deleteRedirection,
	}
);

type ReduxProps = ConnectedProps<typeof conector>;

export default conector(SophoraSearchContainer);
