import type {
	BrBildSearchParameters,
	Importer,
	ImportSophoraArticle,
	ImportSophoraEntityByIDInput,
	LivestreamSearchParameters,
	NewsroomSearchParameters,
} from '../..';
import {
	AM_IMPORTER_LIVESTREAMS,
	AM_IMPORTER_NEWSROOM,
	AM_IMPORTER_SOPHORA,
	AM_IMPORTER_BRBILD,
	AM_IMPORTER_SOPHORA_ARTICLE,
} from '../../../../constants';

import { importBrBild } from './importBrBild';
import { importLiveStreams } from './importLiveStreams';
import { importNewsroom } from './importNewsroom';
import { importSophora } from './importSophora';
import { importSophoraArticle } from './importSophoraArticle';

export function importResults(
	importer: typeof AM_IMPORTER_NEWSROOM,
	mode: { mode: 'fetchNextGroupPage' | 'searchNewsroom' },
	searchParameters: NewsroomSearchParameters
): ReturnType<typeof importNewsroom>;
export function importResults(
	importer: typeof AM_IMPORTER_LIVESTREAMS,
	mode: { mode: 'loadAvailableLivestreams' | 'searchAvailableLivestreams' },
	searchParameters?: LivestreamSearchParameters
): ReturnType<typeof importLiveStreams>;
export function importResults(
	importer: typeof AM_IMPORTER_SOPHORA,
	mode: { mode: 'searchSophora' },
	searchParameters: ImportSophoraEntityByIDInput
): ReturnType<typeof importSophora>;
export function importResults(
	importer: typeof AM_IMPORTER_SOPHORA_ARTICLE,
	mode: { mode: 'searchSophora' },
	searchParameters: ImportSophoraArticle
): ReturnType<typeof importSophora>;
export function importResults(
	importer: typeof AM_IMPORTER_BRBILD,
	mode: { mode: 'searchBrBild' },
	searchParameters: BrBildSearchParameters
): ReturnType<typeof importBrBild>;
export function importResults(
	importer: Importer,
	mode: {
		mode:
			| 'fetchNextGroupPage'
			| 'searchNewsroom'
			| 'searchSophora'
			| 'loadAvailableLivestreams'
			| 'searchAvailableLivestreams'
			| 'searchBrBild';
	},
	searchParameters?:
		| LivestreamSearchParameters
		| NewsroomSearchParameters
		| ImportSophoraEntityByIDInput
		| ImportSophoraArticle
		| BrBildSearchParameters
):
	| ReturnType<typeof importLiveStreams>
	| ReturnType<typeof importNewsroom>
	| ReturnType<typeof importSophora>
	| ReturnType<typeof importSophoraArticle>
	| ReturnType<typeof importBrBild>;
export function importResults(
	importer: Importer,
	mode: {
		mode:
			| 'fetchNextGroupPage'
			| 'searchNewsroom'
			| 'searchSophora'
			| 'loadAvailableLivestreams'
			| 'searchAvailableLivestreams'
			| 'searchBrBild';
	},
	searchParameters?:
		| LivestreamSearchParameters
		| NewsroomSearchParameters
		| ImportSophoraEntityByIDInput
		| BrBildSearchParameters
):
	| ReturnType<typeof importLiveStreams>
	| ReturnType<typeof importNewsroom>
	| ReturnType<typeof importSophora>
	| ReturnType<typeof importSophoraArticle>
	| ReturnType<typeof importBrBild> {
	switch (importer) {
		case AM_IMPORTER_LIVESTREAMS:
			return importLiveStreams(
				mode as { mode: 'loadAvailableLivestreams' | 'searchAvailableLivestreams' },
				searchParameters as LivestreamSearchParameters | undefined
			);
		case AM_IMPORTER_NEWSROOM:
			return importNewsroom(
				mode as {
					mode: 'loadAvailableLivestreams' | 'searchAvailableLivestreams' | 'fetchNextGroupPage';
				},
				searchParameters as NewsroomSearchParameters
			);
		case AM_IMPORTER_SOPHORA:
			return importSophora(searchParameters as ImportSophoraEntityByIDInput);
		case AM_IMPORTER_BRBILD:
			return importBrBild(mode, searchParameters as BrBildSearchParameters);
		case AM_IMPORTER_SOPHORA_ARTICLE:
			return importSophoraArticle(searchParameters as ImportSophoraArticle);
		default:
			throw new Error(`Importer type ${importer} is not recognized or defined in importResults`);
	}
}
