import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../environment';

import type { LatestPushNotificationsContainerQuery } from './__generated__/LatestPushNotificationsContainerQuery.graphql';

export default function (
	props: Pick<QueryRendererProps<LatestPushNotificationsContainerQuery>, 'render'>
) {
	return (
		<QueryRenderer<LatestPushNotificationsContainerQuery>
			environment={environment}
			variables={{}}
			query={graphql`
				query LatestPushNotificationsContainerQuery {
					data: allOneSignalNotifications(
						first: 10
						orderBy: CREATED_AT_DESC
						filter: { pushType: { notEqualTo: NONE } }
					) {
						nodes {
							createdAt
							recipients
							pushType
							articleByArticleId {
								rowId
								title
							}
						}
					}
				}
			`}
			{...props}
		/>
	);
}
