import {
	SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE,
	SOCIAL_MEDIA_ACCOUNT_TWITTER,
	SOCIAL_MEDIA_ACCOUNT_INSTAGRAM,
	SOCIAL_MEDIA_ACCOUNT_YOUTUBE,
	SOCIAL_MEDIA_ACCOUNT_SPOTIFY,
	BOARD_BUFFER_SOCIAL_MEDIA_ADD,
} from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';
import { randomId } from '@/client/util/br24Utils';

import { findBoardById } from '../util/find';

type BoardSocialMediaAccountCreation = {
	service:
		| typeof SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE
		| typeof SOCIAL_MEDIA_ACCOUNT_TWITTER
		| typeof SOCIAL_MEDIA_ACCOUNT_INSTAGRAM
		| typeof SOCIAL_MEDIA_ACCOUNT_YOUTUBE
		| typeof SOCIAL_MEDIA_ACCOUNT_SPOTIFY;
	label?: string | null;
	accountName: string;
};

export type AddSocialMediaAccountAction = {
	type: typeof BOARD_BUFFER_SOCIAL_MEDIA_ADD;
	constraint: {
		boardId: string;
	};
	payload: BoardSocialMediaAccountCreation & {
		socialMediaAccountId: string;
	};
};

export default function addSocialMediaAccount(
	boardId: string,
	socialMediaAccount: BoardSocialMediaAccountCreation
): AppThunkAction<AddSocialMediaAccountAction> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SOCIAL_MEDIA_ADD,
			constraint: { boardId },
			payload: {
				socialMediaAccountId: randomId(),
				...socialMediaAccount,
			},
		});
	};
}
