import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
	CAPABILITY_FULLY_FEATURED,
	CAPABILITY_TEXT_ONLY,
	LS_READ_ONLY_MODE,
	PUSH_NOTIFICATION_NONE,
	STATUS_CHANGE_PUBLICATION_DATE,
	STATUS_CHANGED,
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_SCHEDULED,
	TYPE_ARTICLE,
} from '@sep/br24-constants';
import { Modal } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type {
	SaveActionErrorCallback,
	SaveActionFunctionCallback,
	BeforeSaveFunctionCallback,
	Status,
} from '..';
import { start as articleRepublisherStartAction } from '../../../store/reducers/articleRepublisher';
import type { WithTranslationProps } from '../../../translation';
import ReadOnlyBar from '../../../ui/ReadOnlyBar';
import { createPreviewUrl, openPreview } from '../../../util/preview';
import { ArticleStatusBar, type ArticleStatusBarOnChangeAttribute } from '../../ArticleStatusBar';
import type { HistoryTimelineItemType } from '../../HistoryTimeline';
import type { Expiration } from '../__generated__/ArticleEditor_article.graphql';
import createArticleFromTemplateAction from '../actions/createArticleFromTemplate';
import saveAction from '../actions/save';
import updateAction from '../actions/update';
import { articleStatusBarSelector } from '../selectors';

import ArticleEditorStatusBarDraggableIcon from './ArticleEditorStatusBarDraggableIcon';

interface Props extends WithTranslationProps, ReduxProps, RouteComponentProps {
	onSaveFinished: SaveActionFunctionCallback;
	onSaveError: SaveActionErrorCallback;
	onAutoSaveFinished: SaveActionFunctionCallback;
	onBeforeSave: BeforeSaveFunctionCallback;
	lockedBy?: string | null;
	onAutoSaveStart: () => void;
	hasArticleNotes: boolean;
}

type State = {
	defaultDepublicationDate: string | undefined | null;
	defaultDepublicationId: Expiration | null;
	defaultPublicationDate: string | undefined | null;
};

export class StatusBarContainer extends Component<Props, State> {
	static getDerivedStateFromProps(props: Props, state: State) {
		if (props.publicationDate !== state.defaultPublicationDate) {
			return {
				defaultPublicationDate: props.publicationDate,
			};
		}

		return null;
	}

	autoSaveInterval: number | undefined = undefined;

	constructor(props: Props) {
		super(props);
		this.state = {
			defaultDepublicationDate: props.expirationAt,
			defaultDepublicationId: props.expirationTemplate,
			defaultPublicationDate: props.publicationDate,
		};
	}

	componentDidUpdate(prevProps: Props) {
		console.log('Statusbar did update');

		if (
			/* if the lockStatus.mode is set, we don't allow auto save ! */
			prevProps.lockStatus?.mode === null &&
			!this.autoSaveInterval &&
			prevProps.userEnabledAutoSave
		) {
			this.setAutoSaveInterval();
		}
		if (this.autoSaveInterval && !prevProps.userEnabledAutoSave) {
			this.clearAutoSaveInterval();
		}
	}

	componentWillUnmount() {
		this.clearAutoSaveInterval();
	}

	setAutoSaveInterval = () => {
		this.autoSaveInterval = window.setInterval(this.autoSave, 120 * 1000);
	};

	autoSave = () => {
		const isReadOnly = this.props.lockStatus?.mode === LS_READ_ONLY_MODE;
		if (isReadOnly) {
			this.clearAutoSaveInterval();
			return;
		}

		if (!this.props.isArticleEdited) {
			return;
		}

		if (!!this.props.publicationDate) {
			return;
		}

		this.handleSaveClick(true);
	};

	clearAutoSaveInterval = () => {
		window.clearInterval(this.autoSaveInterval);
	};

	openArticleNotesModal = (key: any) => {
		const {
			onSaveError,
			onSaveFinished,
			translation: { translate },
		} = this.props;

		Modal.confirm({
			title: translate('modals.articleNotes.title'),
			content: translate('modals.articleNotes.content'),
			onOk: () => {
				this.props.save({ status: key }, onSaveFinished, onSaveError);
			},
			okText: translate('modals.articleNotes.ok'),
			cancelText: translate('modals.articleNotes.cancel'),
			icon: <ExclamationCircleOutlined />,
		});
	};

	openSaveModal = (isAutoSave = false) => {
		const {
			status,
			publicationDate,
			onSaveError,
			onSaveFinished,
			onAutoSaveFinished,
			onBeforeSave,
			translation: { translate },
		} = this.props;

		if (status === STATUS_SCHEDULED && publicationDate === null) {
			Modal.confirm({
				title: translate('modals.articlePublishNow.title'),
				content: translate('modals.articlePublishNow.content'),
				onOk: () => {
					this.handleStatusClick(STATUS_PUBLISHED);
				},
				onCancel: () => null,
				okText: translate('modals.articlePublishNow.ok'),
				cancelText: translate('modals.articlePublishNow.cancel'),
				icon: <ExclamationCircleOutlined />,
			});
		} else {
			this.props.save(
				{
					status,
				},
				isAutoSave ? onAutoSaveFinished : onSaveFinished,
				onSaveError,
				onBeforeSave
			);
		}
	};

	handleChange = (attr: ArticleStatusBarOnChangeAttribute, value: any) => {
		const mapping = {
			publicationDate: 'publicationDate',
			depublicationId: 'expirationTemplate',
			depublicationDate: 'expirationAt',
			depublicationInterval: 'expirationIn',
			pushNotification: 'pushNotification',
		};
		this.props.update({ [mapping[attr]]: value });
	};

	handlePreviewClick = () => {
		openPreview(createPreviewUrl(TYPE_ARTICLE, this.props));
	};

	handleSaveClick = (isAutoSave = false) => {
		if (isAutoSave && typeof this.props.onAutoSaveStart === 'function') {
			this.props.onAutoSaveStart();
		}

		const {
			isSaving,
			hasArticleNotes,
			translation: { translate },
		} = this.props;
		if (isSaving) {
			return;
		}

		if (hasArticleNotes && !isAutoSave) {
			Modal.confirm({
				title: translate('modals.articleNotes.title'),
				content: translate('modals.articleNotes.content'),
				onOk: () => {
					this.openSaveModal();
				},
				onCancel: () => null,
				okText: translate('modals.articleNotes.ok'),
				cancelText: translate('modals.articleNotes.cancel'),
				icon: <ExclamationCircleOutlined />,
			});
		} else {
			this.openSaveModal(isAutoSave);
		}
	};

	handleStatusClick = (key: Status | typeof STATUS_CHANGE_PUBLICATION_DATE) => {
		const {
			boardsSectionsItemsByArticleId,
			capability,
			createArticleFromTemplate,
			history,
			hasArticleNotes,
			isTemplate,
			onBeforeSave,
			onSaveFinished,
			onSaveError,
			publicationDate,
			pushNotification,
			rowId,
			save,
			slug,
			startRepublishing,
			status,
			title,
			translation: { translate },
		} = this.props;

		const isScheduled = publicationDate && publicationDate > new Date().toISOString();
		const actionKey = key === STATUS_PUBLISHED && isScheduled ? STATUS_SCHEDULED : key;

		// show change pub date modal when status dropdown clicked
		if (actionKey === STATUS_CHANGE_PUBLICATION_DATE) {
			onBeforeSave(true);
			return;
		}

		if (isTemplate && actionKey === STATUS_DRAFT) {
			createArticleFromTemplate(rowId, (newRowId) => {
				history.push(`/articleEditor/${newRowId}`);
			});
		} else if (
			actionKey === STATUS_PUBLISHED &&
			!isTemplate &&
			status === STATUS_PUBLISHED &&
			capability === CAPABILITY_FULLY_FEATURED
		) {
			startRepublishing({ rowId, title, slug });
		} else if (
			(actionKey === STATUS_SCHEDULED || actionKey === STATUS_PUBLISHED) &&
			capability === CAPABILITY_TEXT_ONLY &&
			pushNotification &&
			pushNotification !== PUSH_NOTIFICATION_NONE
		) {
			Modal.confirm({
				title: translate('modals.notificationActivated.title'),
				content: translate('modals.notificationActivated.content'),
				onOk: () =>
					hasArticleNotes
						? this.openArticleNotesModal(actionKey)
						: save({ status: actionKey }, onSaveFinished, onSaveError),
				okText: translate('modals.notificationActivated.ok'),
				cancelText: translate('modals.notificationActivated.cancel'),
			});
		} else if (
			actionKey === STATUS_DEPUBLISHED &&
			boardsSectionsItemsByArticleId &&
			boardsSectionsItemsByArticleId.totalCount &&
			boardsSectionsItemsByArticleId.totalCount > 0
		) {
			Modal.error({
				title: translate('modals.articleDepublishConnected.title'),
				content: translate('modals.articleDepublishConnected.content'),
				okText: translate('modals.articleDepublishConnected.ok'),
			});
		} else if (actionKey === STATUS_DELETED) {
			const deleteModal = Modal.confirm({
				title: translate('modals.articleDelete.title'),
				content: translate('modals.articleDelete.content'),
				onOk: () => {
					deleteModal.destroy();
					save({ status: actionKey }, onSaveFinished, () => {
						/* noop on save error */
					});
				},
				okText: translate('modals.articleDelete.ok'),
				cancelText: translate('modals.articleDelete.cancel'),
				icon: <ExclamationCircleOutlined />,
			});
		} else if (hasArticleNotes) {
			this.openArticleNotesModal(actionKey);
		} else {
			save({ status: actionKey }, onSaveFinished, onSaveError);
		}
	};

	render() {
		const {
			capability,
			historiesByArticleId,
			isSaving,
			isTemplate,
			rowId,
			status,
			translation: { translate },
			isAssetManagerVisible,
			isArticleEdited,
			lockStatus: { mode },
			lockedBy,
			hasArticleNotes,
		} = this.props;

		const { defaultDepublicationDate, defaultDepublicationId, defaultPublicationDate } = this.state;

		let totalHistoryItems = 0;
		let historyItem: HistoryTimelineItemType | undefined;

		if (historiesByArticleId) {
			totalHistoryItems = historiesByArticleId.totalCount ?? 0;
			if (
				Array.isArray(historiesByArticleId.edges) &&
				historiesByArticleId.edges[0] &&
				historiesByArticleId.edges[1]
			) {
				let changedStatus = false;
				const { node } = historiesByArticleId.edges[0];
				const { node: followingNode } = historiesByArticleId.edges[1];

				if (node && followingNode) {
					try {
						const nodeJson = JSON.parse(node.data);
						const followingNodeJson = JSON.parse(followingNode.data);

						if (nodeJson.status === followingNodeJson.status) {
							changedStatus = true;
						}

						historyItem = {
							id: node.rowId,
							date: moment(node.createdAt),
							status: changedStatus ? STATUS_CHANGED : nodeJson.status,
						};
					} catch (err) {
						// eslint-disable-next-line no-console
						console.warn('Failed parsing article history');
					}
				}
			}
		}

		return (
			<>
				{mode === LS_READ_ONLY_MODE ? (
					<ReadOnlyBar />
				) : (
					<ArticleStatusBar
						pushNotification={this.props.pushNotification || PUSH_NOTIFICATION_NONE}
						affixed={!isAssetManagerVisible}
						articleId={rowId}
						content={`article_${rowId}`}
						defaultDepublicationDate={defaultDepublicationDate}
						defaultDepublicationId={defaultDepublicationId}
						defaultPublicationDate={defaultPublicationDate}
						draggableIcon={
							<ArticleEditorStatusBarDraggableIcon
								articleId={rowId}
								isPublished={status === STATUS_PUBLISHED}
							/>
						}
						historyItem={historyItem}
						isNotification={capability === CAPABILITY_TEXT_ONLY}
						isSaveEnabled={isArticleEdited}
						isSaving={isSaving}
						isTemplate={isTemplate}
						onChange={this.handleChange}
						onPreviewClick={this.handlePreviewClick}
						onSaveClick={this.handleSaveClick}
						onStatusClick={this.handleStatusClick}
						status={status}
						title={
							isTemplate
								? translate('article.title.template')
								: translate(`capabilities.${capability}`)
						}
						totalHistoryItems={totalHistoryItems}
						lockedBy={lockedBy}
						hasArticleNotes={hasArticleNotes}
					/>
				)}
			</>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		isArticleEdited: state.articleEditor.isEdited,
		isAssetManagerVisible: state.assetManager.isVisible,
		lockStatus: state.lockStatus,
		userEnabledAutoSave: state.user.settings.articleAutoSave,
		...articleStatusBarSelector(state),
	}),
	{
		save: saveAction,
		update: updateAction,
		startRepublishing: articleRepublisherStartAction,
		createArticleFromTemplate: createArticleFromTemplateAction,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default withRouter(withTranslation(connector(StatusBarContainer)));
