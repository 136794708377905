import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateSelectionAction } from '..';

import deselect from './deselect';
import select from './select';

function toggleSelected(id: string): AppThunkAction<UpdateSelectionAction> {
	return (dispatch, getState) => {
		const { assetManager } = getState();

		if (assetManager.selected.includes(id)) {
			dispatch(deselect(id));
		} else {
			dispatch(select(id));
		}
	};
}

export default toggleSelected;
