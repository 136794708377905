import {
	DISTRICT_UNTERFRANKEN,
	DISTRICT_OBERFRANKEN,
	DISTRICT_MITTELFRANKEN,
	DISTRICT_SCHWABEN,
	DISTRICT_OBERBAYERN,
	DISTRICT_NIEDERBAYERN,
	DISTRICT_OBERPFALZ,
} from '@sep/br24-constants';

type buildGeoTagsFilterResponse = {
	textFilter: Array<string>;
};

const buildGeoTagsFilter = (): buildGeoTagsFilterResponse => {
	const regions = [
		DISTRICT_UNTERFRANKEN,
		DISTRICT_OBERFRANKEN,
		DISTRICT_MITTELFRANKEN,
		DISTRICT_SCHWABEN,
		DISTRICT_OBERBAYERN,
		DISTRICT_NIEDERBAYERN,
		DISTRICT_OBERPFALZ,
	];

	return {
		textFilter: regions.map(
			(region) => String(region.charAt(0).toUpperCase()) + String(region.slice(1).toLowerCase())
		),
	};
};

export default buildGeoTagsFilter;
