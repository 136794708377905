import { Dropdown, Button, Tooltip } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';
import type { Expiration } from '@/types/schema';

import type { WithTranslationProps } from '../../../translation';
import { media } from '../../../util/styled';
import { DropdownDown, DropdownUp, Clock } from '../../Icon';
import ArticleStatusTimeControlForm, {
	type TimeControlFormOnChangeAttribute,
} from '../ArticleStatusTimeControlForm';

const VISIBILITY_DELAY_SECONDS = 1;

const DropdownButton = styled(Button)`
	display: flex;
	align-items: center;
	padding: 0 10px;
`;

const DropdownButtonContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ButtonIcon = styled.div`
	font-size: 14px !important;
`;

const NextButtonIcon = styled(ButtonIcon)`
	margin-left: 10px;
`;

const Title = styled.span`
	margin-left: 10px;
	white-space: nowrap;
	font-size: 1em;
	${media.lessThan('medium')`
		display: none;
	`};
`;

export type OnChangeAttribute = TimeControlFormOnChangeAttribute;

type Props = WithTranslationProps & {
	defaultDepublicationDate?: string | null;
	defaultDepublicationId?: Expiration | null;
	defaultPublicationDate?: string | null;
	isDisabled: boolean;
	isDisabledPublication: boolean;
	onChange?: (x: OnChangeAttribute, y: any) => void;
};

type State = {
	dropdownOpen: boolean;
	publicationDate: string | undefined | null;
};

class ArticleStatusTimeControlDropdown extends Component<Props, State> {
	static getDerivedStateFromProps(props: Props, state: State) {
		if (props.defaultPublicationDate !== state.publicationDate) {
			return {
				publicationDate: props.defaultPublicationDate,
			};
		}

		return null;
	}

	constructor(props: Props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			publicationDate: props.defaultPublicationDate,
		};
	}

	handleButtonOnClick = () => {
		this.setState((state) => ({ dropdownOpen: !state.dropdownOpen }));
	};

	handleOnChange = (attr: OnChangeAttribute, value: any) => {
		if (attr === 'publicationDate') {
			this.setState({ publicationDate: value });
		}
		this.notifyChange(attr, value);
	};

	handleOpenChange = (open: boolean) => {
		this.setState({ dropdownOpen: open });
	};

	notifyChange = (attr: OnChangeAttribute, value: any) => {
		if (this.props.onChange) {
			this.props.onChange(attr, value);
		}
	};

	renderTitle() {
		const {
			translation: { translate },
		} = this.props;

		if (this.state.publicationDate) {
			const timeString = moment(this.state.publicationDate).format(
				translate('article.publishing.delay.moment.format')
			);
			return <Title>{timeString}</Title>;
		}
		if (this.state.dropdownOpen) {
			return <Title>{translate('article.publishing.delay.delay')}</Title>;
		}
		return null;
	}

	render() {
		const {
			defaultDepublicationDate,
			defaultDepublicationId,
			isDisabled,
			defaultPublicationDate,
			isDisabledPublication,
			translation: { translate },
		} = this.props;

		const popupContent = (
			<ArticleStatusTimeControlForm
				defaultDepublicationDate={defaultDepublicationDate}
				defaultDepublicationId={defaultDepublicationId}
				defaultPublicationDate={defaultPublicationDate}
				isDisabled={isDisabled}
				isDisabledPublication={isDisabledPublication}
				onChange={this.handleOnChange}
			/>
		);

		return (
			<Tooltip title={translate('article.publishing.tooltip')} placement="right">
				<Dropdown
					disabled={isDisabled}
					dropdownRender={() => popupContent}
					onOpenChange={this.handleOpenChange}
					placement="bottomRight"
					trigger={['click']}
					mouseLeaveDelay={VISIBILITY_DELAY_SECONDS}
				>
					<DropdownButton onClick={this.handleButtonOnClick}>
						<DropdownButtonContent>
							<ButtonIcon>
								{' '}
								<Clock />
							</ButtonIcon>
							{this.renderTitle()}
							<NextButtonIcon>
								{' '}
								{this.state.dropdownOpen ? <DropdownUp /> : <DropdownDown />}
							</NextButtonIcon>
						</DropdownButtonContent>
					</DropdownButton>
				</Dropdown>
			</Tooltip>
		);
	}
}

export default withTranslation(ArticleStatusTimeControlDropdown);
