import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Alert, Card, type CardProps } from 'antd';
import type { MouseEventHandler } from 'react';
import { graphql, QueryRenderer } from 'react-relay';

import { useTranslate } from '@/client/translation/useTranslate';

import environment from '../../../../environment';
import placeholderImage from '../../../../images/placeholder.png';

import styles from './SectionItem.module.scss';
import type { BoardsTeaserItemQuery } from './__generated__/BoardsTeaserItemQuery.graphql';

type BoardsTeaserByRowId = {
	rowId: string;
	title: string;
	image:
		| {
				url: string | null;
		  }
		| undefined
		| null;
	link: {
		label: string | null;
	} | null;
};

type PassThroughProps = {
	compact?: boolean;
	removable?: boolean;
	openable?: boolean;
	onRequestRemove?: MouseEventHandler<HTMLSpanElement>;
	onRequestOpen?: MouseEventHandler<HTMLSpanElement>;
};

type BoardsTeaserProps = PassThroughProps & {
	boardsTeaser: BoardsTeaserByRowId;
};

function BoardsTeaser({
	boardsTeaser: { title, image, link },
	compact,
	removable = true,
	onRequestRemove,
	openable = true,
	onRequestOpen,
}: BoardsTeaserProps) {
	const additionalProps: CardProps = {};

	if (!compact) {
		additionalProps.cover = (
			<img alt="Vorschaubild" src={image?.url ? image.url : placeholderImage} />
		);
	}

	if (removable || openable) {
		additionalProps.actions = [];

		if (removable) {
			additionalProps.actions.push(<CloseOutlined onClick={onRequestRemove} />);
		}

		if (openable) {
			additionalProps.actions.push(<EditOutlined onClick={onRequestOpen} />);
		}
	}

	return (
		<Card bodyStyle={{ padding: 0 }} {...additionalProps}>
			<div className={styles.content}>
				<b>{title}</b>
				<p>{link?.label}</p>
			</div>
		</Card>
	);
}

interface Props extends PassThroughProps {
	id: string;
}

export default ({ id: boardsTeaserId, ...rest }: Props) => {
	const translate = useTranslate();

	return (
		<QueryRenderer<BoardsTeaserItemQuery>
			environment={environment}
			query={graphql`
				query BoardsTeaserItemQuery($boardsTeaserId: String!) {
					boardsTeaser: boardsTeaserByRowId(rowId: $boardsTeaserId) {
						rowId
						title
						image {
							url
						}
						link {
							label
						}
					}
				}
			`}
			variables={{ boardsTeaserId }}
			render={({ error, props: queryRendererProps }) => {
				if (error) {
					return (
						<Alert
							message={translate('error')}
							description={translate('board.sectionItem.boardsTeaser.errorDescription', {
								title: boardsTeaserId,
							})}
							type="error"
						/>
					);
				}
				if (queryRendererProps && queryRendererProps.boardsTeaser) {
					return <BoardsTeaser boardsTeaser={queryRendererProps.boardsTeaser} {...rest} />;
				}

				return <Card loading={true} />;
			}}
		/>
	);
};
