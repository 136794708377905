import {
	FileTextOutlined,
	UserOutlined,
	ClockCircleOutlined,
	ExclamationCircleOutlined,
	CheckOutlined,
	CloseCircleOutlined,
} from '@ant-design/icons';
import {
	STATUS_DRAFT,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
	STATUS_DEPUBLISHED,
	STATUS_PUBLISHED,
	STATUS_DELETED,
} from '@sep/br24-constants';
import { Tag, Tooltip } from 'antd';
import moment from 'moment-timezone';
import { PureComponent } from 'react';

import config from '../../config';
import translations from '../../translations';

import styles from './Status.module.scss';

type StatusType =
	| typeof STATUS_DRAFT
	| typeof STATUS_REVIEW
	| typeof STATUS_SCHEDULED
	| typeof STATUS_DEPUBLISHED
	| typeof STATUS_PUBLISHED
	| typeof STATUS_DELETED;

type Props = {
	status?: StatusType;
	publicationDate: moment.Moment | undefined | null;
};

export default class Status extends PureComponent<Props> {
	render() {
		const { status, publicationDate } = this.props;

		const getStatusIcon = (actualStatus: StatusType | undefined) => {
			switch (actualStatus) {
				case 'DRAFT':
					return <FileTextOutlined />;
				case 'REVIEW':
					return <UserOutlined />;
				case 'SCHEDULED':
					return <ClockCircleOutlined />;
				case 'DEPUBLISHED':
					return <ExclamationCircleOutlined />;
				case 'DELETED':
					return <CloseCircleOutlined />;
				default:
					return <CheckOutlined />;
			}
		};
		return (
			<Tooltip title={status ? translations[status] : ''}>
				<Tag
					style={{ borderRadius: 99999, opacity: 0.9, transform: 'scale(0.8)' }}
					color={status ? config.statusTagColorMap[status] : undefined}
					className={styles.status}
				>
					{getStatusIcon(status)}
					{publicationDate && status === STATUS_SCHEDULED
						? ` ${moment(publicationDate).format('DD.MM.YYYYY')} um ${moment(
								publicationDate
						  ).format('HH:mm')}`
						: false}
				</Tag>
			</Tooltip>
		);
	}
}
