import { Alert, Button, Modal, Tooltip, Typography } from 'antd';
import { type ReactNode, useState } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import type { ImportedItem, ImportedItemSophoraArticle, Importer } from '..';
import { AM_TYPE_ARTICLE } from '../../../constants';
import PlaceholderImage from '../../../images/placeholder.png';
import { withTranslation, type WithTranslationProps } from '../../../translation';
import { Content, Title } from '../../../ui/AssetManager/AssetManagerItem/AssetManagerItemControl';
import Media from '../../../ui/AssetManager/AssetManagerItem/Media';
import TypeIcon from '../../../ui/AssetManager/AssetManagerItem/TypeIcon';
import createModule from '../../ArticleEditor/actions/createModule';
import updateArticleEditor from '../../ArticleEditor/actions/update';
import {
	convertSophoraModulesToArticleEditorModules,
	isTable,
} from '../actions/import/importSophoraArticle';
import type { SophoraArticleImporterResult } from '../sophora-article-import';

type Props = {
	importedItem: ImportedItem;
	importer: Importer;
};

const Wrapper = styled.div<{ isHovered: boolean }>`
	align-items: center;
	background-color: #000000;
	border-radius: 2px;
	display: flex;
	position: relative;
	overflow: hidden;
	margin-bottom: ${(props) => props.theme.assetManager.innerPadding};
	filter: ${(props: { isHovered: boolean }) => (props.isHovered ? 'blur(4px)' : 'none')};
`;

const TakeoverActionWrapper = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 10;
	align-items: center;
	justify-content: space-around;
`;

function TakeoverModalDialogComponent({
	article,
	open,
	articleContainsTable,
	translation: { translate },
	onOk,
	onCancel,
}: {
	article: SophoraArticleImporterResult;
	open: boolean;
	articleContainsTable: boolean;
	onOk: () => void;
	onCancel: () => void;
} & WithTranslationProps) {
	return (
		<Modal
			title={translate(`assetManager.dataTakeoverModalNew.headline`)}
			okText={translate(`assetManager.dataTakeoverModalNew.confirmOk`)}
			cancelText={translate(`assetManager.dataTakeoverModalNew.confirmCancel`)}
			open={open}
			onOk={onOk}
			onCancel={onCancel}
		>
			<p>{translate(`assetManager.dataTakeoverModalNew.confirmText`)}</p>
			<Typography.Paragraph>
				{translate(`assetManager.dataTakeoverModalNew.warningOverwrite`)}
				<ul>
					<li>{translate(`assetManager.dataTakeoverModalNew.headlineContent`)}</li>
					<li>{translate(`assetManager.dataTakeoverModalNew.headlineTeaserText`)}</li>
					{article.metaDescription && (
						<li>{translate(`assetManager.dataTakeoverModalNew.metaDescription`)}</li>
					)}
					{article.sourceSystemId && (
						<li>{translate(`assetManager.dataTakeoverModalNew.canonicalUrl`)}</li>
					)}
				</ul>

				<p>{translate(`assetManager.dataTakeoverModalNew.modules`)}</p>

				{articleContainsTable && (
					<>
						<br />
						<Alert
							message={translate('assetManager.dataTakeoverModalNew.warningTable')}
							type="warning"
						/>
					</>
				)}
			</Typography.Paragraph>
		</Modal>
	);
}

const TakeoverModalDialog = withTranslation(TakeoverModalDialogComponent);

function TakeoverActionComponent(
	props: {
		children: ReactNode;
		isHovered: boolean;
		article: SophoraArticleImporterResult;
	} & ReduxProsp
) {
	const [takeoverModalIsOpen, setTakeoverModalOpen] = useState(false);

	const handleTakeover = () => {
		props.updateArticleEditor({
			title: getTitle(props.article),
			teaserText: props.article.teaserText,
			isImported: true,
			metaDescription: props.article.metaDescription,
			externalId: props.article.id,
			canonicalUrl: props.article.sourceSystemId,
		});

		const modules = convertSophoraModulesToArticleEditorModules(props.article).map(
			(module, index) => ({
				type: module.type,
				order: index,
				data: module,
			})
		);

		props.createModule(modules);

		setTakeoverModalOpen(false);
	};

	const closeTakeoverModal = () => setTakeoverModalOpen(false);

	const articleViewIsActive = window.location.href.includes('articleEditor');
	const tooltipText = !articleViewIsActive
		? 'Um einen Sophora Artikel zu übernehmen müssen Sie den Artikel bearbeiten in welchen Sie den Sophora Artikel übernehmen möchten.'
		: undefined;

	const articleContainsTable = props.article.modules.some((module) => isTable(module));

	return (
		<>
			{props.isHovered && (
				<TakeoverActionWrapper>
					<Tooltip title={tooltipText}>
						<Button disabled={!articleViewIsActive} onClick={() => setTakeoverModalOpen(true)}>
							Artikel übernehmen
						</Button>
					</Tooltip>
				</TakeoverActionWrapper>
			)}
			<TakeoverModalDialog
				open={takeoverModalIsOpen}
				article={props.article}
				articleContainsTable={articleContainsTable}
				onCancel={closeTakeoverModal}
				onOk={handleTakeover}
			/>
			{props.children}
		</>
	);
}

const connector = connect(null, { updateArticleEditor, createModule });
type ReduxProsp = ConnectedProps<typeof connector>;

const TakeoverAction = connector(TakeoverActionComponent);

const AssetManagerItemArticleComponent = (props: Props) => {
	const [isHovered, setHovered] = useState(false);
	const handleMouseEnter = () => setHovered(true);
	const handleMouseLeave = () => setHovered(false);

	const { article } = props.importedItem as ImportedItemSophoraArticle;

	const title = article.title;
	const image = article.leadImage?.[0]?.url;

	return (
		<div
			style={{ position: 'relative' }}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<TakeoverAction isHovered={isHovered} article={article}>
				<Wrapper isHovered={isHovered}>
					<TypeIcon type={AM_TYPE_ARTICLE} isOverlying={true} />
					<Media image={image ?? PlaceholderImage} />
					<Content>
						<Title>{title}</Title>
					</Content>
				</Wrapper>
			</TakeoverAction>
		</div>
	);
};

export const AssetManagerItemSophoraArticle = AssetManagerItemArticleComponent;

function getTitle(article: SophoraArticleImporterResult) {
	return [article.headline, article.title].filter((s) => !!s).join(': ');
}
