import type { ComponentType } from 'react';
import { useDrag } from 'react-dnd';
import type { ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { connect } from '@/client/store/redux';

import type { ImportedItem, ImportedItemGroup, Importer } from '../..';
import {
	AM_TYPE_TEXT,
	AM_TYPE_BRBILD_IMAGE,
	AM_TYPE_IMAGE,
	AM_TYPE_AUDIO,
	AM_TYPE_VIDEO,
	AM_TYPE_LIVESTREAM,
	AM_DEFAULT_GROUP,
	AM_TYPE_ARTICLE,
	AM_TYPE_BOARD,
	AM_TYPE_SOPHORA_ARTICLE,
	AM_TYPE_EMBED_CODE,
	AM_TYPE_GALLERY,
} from '../../../../constants';
import { AssetManagerImporterResultSet as ResultSet } from '../../../../ui/AssetManager/AssetManagerImporter';
import { Trash } from '../../../../ui/Icon';
import {
	AssetManagerImportedItemText,
	AssetManagerImportedItemVideo,
	AssetManagerImportedItemBrBildImage,
	AssetManagerImportedItemImage,
	AssetManagerImportedItemAudio,
	AssetManagerImportedItemLiveStream,
	AssetManagerImportedItemArticle,
	AssetManagerImportedItemBoard,
	AssetManagerImportedItemSophoraArticle,
	AssetManagerImportedItemEmbed,
	AssetManagerImportedItemGallery,
} from '../../AssetManagerItem';
import * as actions from '../../actions';
import { IMPORTED_ITEM_GROUP } from '../itemTypes';

function itemGroupItems(importedItems: ImportedItem[], importer: Importer) {
	if (!importedItems || importedItems.length === 0) {
		return <p>Keine Ergebnisse verfügbar.</p>;
	}

	return importedItems.map((importedItemGroupElement) => {
		const importedItem = importedItemGroupElement;
		const forwardProps = {
			key: importedItem.id,
			importer,
			importedItem,
		};

		switch (importedItem.type) {
			case AM_TYPE_TEXT:
				return <AssetManagerImportedItemText {...forwardProps} />;
			case AM_TYPE_BRBILD_IMAGE:
				return <AssetManagerImportedItemBrBildImage {...forwardProps} />;
			case AM_TYPE_IMAGE:
				return <AssetManagerImportedItemImage {...forwardProps} />;
			case AM_TYPE_AUDIO:
				return <AssetManagerImportedItemAudio {...forwardProps} />;
			case AM_TYPE_VIDEO:
				return <AssetManagerImportedItemVideo {...forwardProps} />;
			case AM_TYPE_LIVESTREAM:
				return <AssetManagerImportedItemLiveStream {...forwardProps} />;
			case AM_TYPE_ARTICLE:
				return <AssetManagerImportedItemArticle {...forwardProps} />;
			case AM_TYPE_SOPHORA_ARTICLE:
				return <AssetManagerImportedItemSophoraArticle {...forwardProps} />;
			case AM_TYPE_BOARD:
				return <AssetManagerImportedItemBoard {...forwardProps} />;
			case AM_TYPE_EMBED_CODE:
				return <AssetManagerImportedItemEmbed {...forwardProps} />;
			case AM_TYPE_GALLERY:
				return <AssetManagerImportedItemGallery {...forwardProps} />;

			default:
				return null;
		}
	});
}

interface ConnectedItemGroupProps extends ReduxProps, Omit<ImportedItemGroup, 'additionalData'> {
	importer: Importer;
	importResults: typeof actions.importResults;
}

const LoadMoreItems = styled.div`
	&& {
		flex: 1 100%;
		max-width: none;
		text-align: right;
		margin-top: 20px;
	}

	button {
		height: 38px;
		width: 150px;
		border: none;
		background-color: ${(props) => props.theme.colors.primaryBlue};
		outline: none;
		border-radius: 3px;
		cursor: pointer;
	}
`;

function ItemGroupResultSet(props: ConnectedItemGroupProps) {
	const { items, groupTitle, importer, hasNextPage, searchParameters } = props;

	const [, drag] = useDrag(
		() => ({
			type: IMPORTED_ITEM_GROUP,
			item: {
				importedItemGroupItems: items,
			},
		}),
		[items]
	);

	if (!groupTitle) {
		// This will never happen Flow, but OK.
		return null;
	}
	if (groupTitle === AM_DEFAULT_GROUP) {
		return <ResultSet dragRef={drag}>{itemGroupItems(items, importer)}</ResultSet>;
	}

	return (
		<ResultSet
			title={groupTitle}
			actions={[<Trash key="trash" onClick={() => props.removeItemGroup(importer, groupTitle)} />]}
			dragRef={drag}
		>
			{itemGroupItems(items, importer)}
			{hasNextPage && (
				<LoadMoreItems>
					<button
						type="button"
						key="import"
						onClick={() =>
							props.importResults(importer, { mode: 'fetchNextGroupPage' }, searchParameters)
						}
					>
						Mehr Ergebnisse
					</button>
				</LoadMoreItems>
			)}
		</ResultSet>
	);
}

const connector = connect(null, {
	importResults: actions.importResults,
	removeItemGroup: actions.removeItemGroupFromImporter,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(
	ItemGroupResultSet as unknown as ComponentType<
		Omit<ConnectedItemGroupProps, 'importResults' | 'connectDragSource' | 'connectDragPreview'>
	>
);
