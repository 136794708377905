import Joi from 'joi-browser';

import type { JoiHack } from '@/types/hacks';

import config from '../../../config';
import { translate } from '../../../translation';
import { createJoiError } from '../../../util';
import { processValidation } from '../../../util/processValidation';
import type { ValidationResult } from '../../../util/processValidation';
import type { Author_author$data } from '../__generated__/Author_author.graphql';

const FIRSTNAME_LENGTH_MIN = config.author.firstname.minLength;
const FIRSTNAME_LENGTH_MAX = config.author.firstname.maxLength;
const LASTNAME_LENGTH_MIN = config.author.lastname.minLength;
const LASTNAME_LENGTH_MAX = config.author.lastname.maxLength;
const JOBTITLE_LENGTH_MIN = config.author.jobTitle.minLength;
const JOBTITLE_LENGTH_MAX = config.author.jobTitle.maxLength;
const DESCRIPTION_LENGTH_MAX = config.author.description.maxLength;

const joi = Joi.extend({
	base: Joi.string(),
	name: 'string',
	language: {
		json: 'invalid json',
	},
	rules: [
		{
			name: 'json',
			validate(_params, value, state, options) {
				try {
					JSON.parse(value);
					return value;
				} catch (err) {
					return this.createError?.('string.json', { value }, state, options);
				}
			},
		} as JoiHack,
	],
});

const schema = joi.object().keys({
	firstname: joi
		.string()
		.min(FIRSTNAME_LENGTH_MIN)
		.max(FIRSTNAME_LENGTH_MAX)
		.required()
		.error(
			createJoiError(
				translate('author.validation.firstname', {
					min: FIRSTNAME_LENGTH_MIN,
					max: FIRSTNAME_LENGTH_MAX,
				})
			)
		),
	lastname: joi
		.string()
		.min(LASTNAME_LENGTH_MIN)
		.max(LASTNAME_LENGTH_MAX)
		.required()
		.error(
			createJoiError(
				translate('author.validation.lastname', {
					min: LASTNAME_LENGTH_MIN,
					max: LASTNAME_LENGTH_MAX,
				})
			)
		),
	jobTitle: joi
		.string()
		.min(JOBTITLE_LENGTH_MIN)
		.max(JOBTITLE_LENGTH_MAX)
		.required()
		.error(
			createJoiError(
				translate('author.validation.jobTitle', {
					min: JOBTITLE_LENGTH_MIN,
					max: JOBTITLE_LENGTH_MAX,
				})
			)
		),
	description: joi
		.string()
		.allow(null)
		.optional()
		.error(
			createJoiError(
				translate('author.validation.description', {
					max: DESCRIPTION_LENGTH_MAX,
				})
			)
		),
	image: joi
		.object()
		.allow(null, '')
		.optional()
		.keys({
			url: joi
				.string()
				.regex(
					/^(https:\/\/)(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
				),
		}),
});

const defaultOptions = {
	abortEarly: false,
	allowUnknown: true,
};

export default function validator(author: Author_author$data): ValidationResult {
	const result = joi.validate(author, schema, defaultOptions);
	return processValidation(result);
}
