import classNames from 'classnames';

import styles from '../../styles';

type Props = {
	src: string | undefined;
	className?: string;
};

export function SVG({ src, className }: Props) {
	if (src) {
		// eslint-disable-next-line jsx-a11y/alt-text
		return <img src={src} className={classNames('svg', styles.svg, className)} />;
	} else {
		return null;
	}
}
