import { Form, Input } from 'antd';
import { type ComponentProps, type FC, useCallback, useState } from 'react';

import { ParentBoardPicker } from '@/client/components/ParentBoardPicker/ParentBoardPicker';
import { NAVIGATION_TYPE_BOARD } from '@/client/constants';

import translations from '../translations';
import type { UpsertNavigationItemPayload } from '../types';

export const NavigationEditorFormBoard: FC<{}> = () => {
	const form = Form.useFormInstance<UpsertNavigationItemPayload>();

	const [placeholder, setPlaceholder] = useState<string | undefined>(
		form.getFieldValue(['boardByBoard', 'title']) ?? ''
	);

	const handleSelectBoard = useCallback<ComponentProps<typeof ParentBoardPicker>['onSelect']>(
		(_, options) => {
			if (typeof options?.value === 'undefined' || typeof options?.value === 'undefined') {
				throw new Error(`Cannot set board. Missing data. ${JSON.stringify(options)}`);
			}

			console.log('handle select board', options);

			form.setFieldsValue({
				boardByBoard: { title: options.label, rowId: options.value },
			});
			setPlaceholder(options?.label);
		},
		[form]
	);

	return (
		<>
			<Form.Item initialValue={NAVIGATION_TYPE_BOARD} name="type" hidden>
				<Input />
			</Form.Item>

			<Form.Item name={['boardByBoard', 'title']} hidden>
				<Input />
			</Form.Item>

			<Form.Item
				label={translations.NM_BOARD_SELECT_LABEL}
				name={['boardByBoard', 'rowId']}
				rules={[{ required: true, message: translations.NM_BOARD_SELECT_ERROR, min: 1 }]}
			>
				<ParentBoardPicker
					initialValue={form.getFieldValue(['boardByBoard', 'rowId'])}
					onSelect={handleSelectBoard}
					placeholder={translations.NM_BOARD_SELECT_PLACEHOLDER}
				/>
			</Form.Item>

			<Form.Item
				name="text"
				label="Link-Titel (optional)"
				help="Wenn du einen alternativen Titel für den Link zum Board angeben möchtest, kannst du das hier tun."
			>
				<Input placeholder={placeholder} />
			</Form.Item>
		</>
	);
};
