import { TAG_STATUS_ACTIVE } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';
import { randomId } from '@/client/util/br24Utils';

import type { AddTagAction, AddTagInput } from '..';
import { TAG_ADD } from '../actionTypes';

const addTag =
	(input: AddTagInput): AppThunkAction<AddTagAction> =>
	(dispatch) => {
		const { id, text, status } = input;

		dispatch({
			type: TAG_ADD,
			payload: {
				id: id ?? randomId(),
				count: 1,
				text,
				status: status || TAG_STATUS_ACTIVE,
			},
		});
	};

export default addTag;
