import { type FC, useRef } from 'react';
import { type DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import config from '../../../config';
import type { Image, Validation } from '../../../containers/ArticleEditor';
import { AE_GALLERY_ITEM } from '../../../containers/ArticleEditor/itemTypes';
import { Trash2 } from '../../Icon';

const ImageDisplayWrapper = styled.div<{ hasErrors: boolean; isActive: boolean }>`
	margin: 10px;
	padding: 10px;
	border: ${(props) => (props.hasErrors ? '2px solid red' : 'none')};
	box-shadow: ${(props) =>
		props.isActive
			? '0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);'
			: 'none'};
	cursor: pointer;
`;

const ImageDisplay = styled.img<{ isHidden?: boolean }>`
	width: auto;
	height: auto;
	max-width: 200px;
	max-height: 100px;
	display: ${(props) => (props.isHidden ? 'none' : 'block')};
`;

interface TrashIconWrapperProps {
	onClick: () => void;
}

const TrashIconWrapper = styled(({ onClick }: TrashIconWrapperProps) => (
	<div onClick={onClick}>
		<Trash2 />
	</div>
))`
	margin-top: 5px;
	text-align: center;
	&:hover {
		cursor: pointer;
	}
`;

type Props = {
	index: number;
	image: Image;
	activeElement: number;
	previewLoaded: boolean;
	validation?: Validation;
	handleImageLoad: () => void;
	handleMoveImage: (dragIndex: number, hoverIndex: number) => void;
	handleThumbnailClick: (index: number) => void;
	handleThumbnailDelete: (index: number) => void;
};

const IMAGE_PRELOAD_BLUR = config.articleModules.image.preLoadBlur;
const IMAGE_PRELOAD_QUALITY = config.articleModules.image.preLoadQuality;
const IMAGE_PRELOAD_FIT = config.articleModules.image.preLoadFit;

const ArticleModuleGalleryImage: FC<Props> = ({
	validation,
	index,
	activeElement,
	handleThumbnailClick,
	image,
	previewLoaded,
	handleImageLoad,
	handleThumbnailDelete,
	handleMoveImage,
}: Props) => {
	const ref = useRef<HTMLDivElement>(null);

	const [, drag] = useDrag(
		() => ({
			type: AE_GALLERY_ITEM,
			item: () => {
				return {
					index: index,
				};
			},
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[index]
	);

	const [, drop] = useDrop(
		() => ({
			accept: AE_GALLERY_ITEM,
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
			hover: (item: { index: number }, monitor: DropTargetMonitor<{ index: number }>) => {
				const dragIndex = item.index;
				const hoverIndex = index;

				// Don't replace items with themselves
				if (dragIndex === hoverIndex) {
					return;
				}

				// Time to actually perform the action
				handleMoveImage(dragIndex, hoverIndex);

				// Note: we're mutating the monitor item here!
				// Generally it's better to avoid mutations,
				// but it's good here for the sake of performance
				// to avoid expensive index searches.
				/* eslint-disable-next-line */
				monitor.getItem().index = hoverIndex;
			},
		}),
		[handleMoveImage, index]
	);

	const hasErrors =
		validation &&
		(validation[`gallery.${index}.url`] ||
			validation[`gallery.${index}.title`] ||
			validation[`gallery.${index}.altText`] ||
			validation[`gallery.${index}.copyright`]);

	drag(drop(ref));
	return (
		<div>
			<ImageDisplayWrapper
				isActive={activeElement === index}
				hasErrors={!!hasErrors}
				onClick={() => handleThumbnailClick(index)}
				ref={ref}
			>
				{!previewLoaded ? (
					<div>
						<ImageDisplay
							alt={image.altText ?? undefined}
							src={`${image.url}?blur=${IMAGE_PRELOAD_BLUR}&q=${IMAGE_PRELOAD_QUALITY}&fit=${IMAGE_PRELOAD_FIT}`}
						/>
						<ImageDisplay
							isHidden={true}
							alt={image.altText ?? undefined}
							src={`${image.url}?q=80&w=200`}
							onLoad={handleImageLoad}
						/>
					</div>
				) : (
					<ImageDisplay alt={image.altText ?? undefined} src={`${image.url}?q=80&w=200`} />
				)}
			</ImageDisplayWrapper>
			<TrashIconWrapper onClick={() => handleThumbnailDelete(index)} />
		</div>
	);
};

export default ArticleModuleGalleryImage;
