import type { Action } from 'redux';

export interface StateAction<T, S> extends Action<T> {
	status: S;
}

interface StateReducerDefinition<S> {
	[actionState: string]: (state: S, action: any) => S;
}

interface ReducerDefinition<S> {
	[actionType: string]: ((state: S, action: any) => S) | StateReducerDefinition<S>;
}

export function generateReducer<S>(
	initialState: S,
	reducer: ReducerDefinition<S>,
	onChange?: (state: S, action: Action) => void
) {
	return (state = initialState, action: Action) => {
		const { type } = action;
		let status: string | undefined;
		if (isSateAction(action)) {
			status = action.status;
		}

		let fn: (state: S, action: Action) => S;

		const reducerType = reducer[type];

		if (isSimpleReducer(reducerType)) {
			fn = reducerType;
		} else if (isStateReducerDefinition(reducerType) && status && reducerType[status]) {
			fn = reducerType[status];
		} else {
			return { ...state };
		}

		const nextState = fn(state, action);

		if (onChange) {
			onChange(nextState, action);
		}

		return nextState;
	};
}

function isSateAction<A>(
	action: Action<A> | StateAction<A, string>
): action is StateAction<A, string> {
	return !!action['status'];
}

function isSimpleReducer<S, A>(
	reducer: ((state: S, action: Action<A>) => S) | StateReducerDefinition<S>
): reducer is (state: S, action: Action<A>) => S {
	return typeof reducer === 'function';
}

function isStateReducerDefinition<S>(
	reducer: ((state: S, action: Action) => S) | StateReducerDefinition<S>
): reducer is StateReducerDefinition<S> {
	return typeof reducer === 'object';
}
