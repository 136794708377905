import { DragOutlined } from '@ant-design/icons';
import { COLOR_ASBESTOS } from '@sep/br24-constants';
import { DragPreviewImage, useDrag } from 'react-dnd';
import styled from 'styled-components';

import { AE_ARTICLE } from '../itemTypes';

import dragPreviewImage from './drag-preview.png';

type Props = {
	articleId: string | null;
	isPublished: boolean;
};

const Wrapper = styled.span`
	cursor: move;
	color: ${COLOR_ASBESTOS};
	font-size: 24px;
`;

export default function ArticleEditorDraggableIcon({ isPublished, articleId }: Props) {
	const [, drag, dragPreview] = useDrag(
		() => ({
			type: AE_ARTICLE,
			item: {
				type: AE_ARTICLE,
				articleId: articleId,
				isPublished: isPublished,
			},
		}),
		[articleId, isPublished]
	);

	return (
		<>
			<DragPreviewImage src={dragPreviewImage} connect={dragPreview} />
			<span ref={drag}>
				<Wrapper>
					<DragOutlined />
				</Wrapper>
			</span>
		</>
	);
}
