/**
 * @generated SignedSource<<dcc0ea1448a91fde5b22996acf53d5f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LockHandling_boardsTeaser$data = {
  readonly authorByLockedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
  } | null;
  readonly lockedBy: string | null;
  readonly lockedSince: string | null;
  readonly rowId: string;
  readonly " $fragmentType": "LockHandling_boardsTeaser";
};
export type LockHandling_boardsTeaser$key = {
  readonly " $data"?: LockHandling_boardsTeaser$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockHandling_boardsTeaser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockHandling_boardsTeaser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authorByLockedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "guid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BoardsTeaser",
  "abstractKey": null
};

(node as any).hash = "ca99663956cc7ef5c695087911a3a707";

export default node;
