import { TYPE_BOARD, TYPE_ARTICLE } from '@sep/br24-constants';
import { Card } from 'antd';
import cn from 'classnames';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import routesConfig from '../../../../config/config.routes';
import {
	provideSectionBySectionInternalIdAndPosition,
	addItemToSection,
	updateItemInSection,
	deleteItemInSection,
	orderItemInSection,
} from '../../../../store/reducers/boardBuffer';
import type {
	BoardDragItemRef,
	SectionItemType,
} from '../../../../store/reducers/boardBuffer/types';
import { DraggableItem } from '../../Search/DraggableItem';

import ArticleItem from './ArticleItem';
import BoardItem from './BoardItem';
import BoardsTeaserItem from './BoardsTeaserItem';
import DropItemTarget from './DropItemTarget';
import styles from './SectionItem.module.scss';

const TYPE_TEASER = 'TEASER';

const noop = () => null;

interface Props extends ReduxProps, OwnProps {
	className?: string;
	isDroppable: boolean;
	compact?: boolean;
}

type State = {
	isHovered: boolean;
};

class PooledItem extends Component<Props, State> {
	state = {
		isHovered: false,
	};

	handleDrop = (droppedItem: BoardDragItemRef) => {
		const {
			sectionItem: targetItem,
			sectionInternalId,
			itemPosition,
			addItem,
			updateItem,
			remove,
			reorder,
		} = this.props;

		// only update item was not only moved inside section
		if (droppedItem.internalId && droppedItem.sectionId === sectionInternalId) {
			// ingore action if item was dropped onto its old slot
			if (targetItem && targetItem.__meta__.internalId === droppedItem.internalId) {
				return;
			}
			reorder(droppedItem.internalId, itemPosition);
		} else {
			// is slot is already in use?
			if (targetItem) {
				updateItem(targetItem.__meta__.internalId, droppedItem.type, droppedItem.id);
			} else {
				addItem(droppedItem.type, droppedItem.id);
			}

			// remove dropped item from old slot, if applicable
			if (droppedItem.internalId) {
				remove(droppedItem.internalId);
			}
		}
	};

	handleMouseEnter = () => this.setState({ isHovered: true });

	handleMouseLeave = () => this.setState({ isHovered: false });

	handleOpen = () => {
		const { sectionItem } = this.props;

		if (!sectionItem) {
			return;
		}

		let type: typeof TYPE_ARTICLE | typeof TYPE_BOARD | typeof TYPE_TEASER | null = null;

		if (typeof sectionItem.articleId === 'string') {
			type = TYPE_ARTICLE;
		} else if (typeof sectionItem.boardId === 'string') {
			type = TYPE_BOARD;
		} else {
			type = TYPE_TEASER;
		}

		if (type === TYPE_TEASER) {
			window.open(`${routesConfig.boardsTeasers}/${String(sectionItem.boardsTeaserId)}`);
		} else if (type === TYPE_ARTICLE) {
			window.open(`${routesConfig.article}/${String(sectionItem.articleId)}`);
		} else if (type === TYPE_BOARD) {
			window.open(`${routesConfig.boards}/${String(sectionItem.boardId)}`);
		}
	};

	handleRemove = () => {
		const { sectionItem, remove } = this.props;

		if (!sectionItem) {
			return;
		}

		remove(sectionItem.__meta__.internalId);
	};

	renderItem = () => {
		const { sectionItem, compact } = this.props;

		if (!sectionItem) {
			return false;
		}

		if (sectionItem.articleId) {
			return (
				<DraggableItem
					type="article"
					id={sectionItem.articleId}
					internalId={sectionItem.__meta__.internalId}
					sectionId={sectionItem.__meta__.sectionId}
					status={''}
				>
					<ArticleItem
						id={sectionItem.articleId}
						compact={compact}
						onRequestRemove={this.handleRemove}
						onRequestOpen={this.handleOpen}
					/>
				</DraggableItem>
			);
		}
		if (sectionItem.boardId) {
			return (
				<DraggableItem
					type="board"
					id={sectionItem.boardId}
					internalId={sectionItem.__meta__.internalId}
					sectionId={sectionItem.__meta__.sectionId}
					status={''}
				>
					<BoardItem
						id={sectionItem.boardId}
						compact={compact}
						onRequestRemove={this.handleRemove}
						onRequestOpen={this.handleOpen}
					/>
				</DraggableItem>
			);
		}
		if (sectionItem.boardsTeaserId) {
			return (
				<DraggableItem
					type="boardsTeaser"
					id={sectionItem.boardsTeaserId}
					internalId={sectionItem.__meta__.internalId}
					sectionId={sectionItem.__meta__.sectionId}
					status={''}
				>
					<BoardsTeaserItem
						id={sectionItem.boardsTeaserId}
						compact={compact}
						onRequestRemove={this.handleRemove}
						onRequestOpen={this.handleOpen}
					/>
				</DraggableItem>
			);
		}
		return false;
	};

	render() {
		const { isHovered } = this.state;
		const { sectionItem, className, isDroppable } = this.props;

		return (
			<div
				className={cn(className, styles.pooledItem, {
					[styles.isHovered]: isHovered && sectionItem,
				})}
				onMouseEnter={sectionItem ? this.handleMouseEnter : noop}
				onMouseLeave={sectionItem ? this.handleMouseLeave : noop}
			>
				<DropItemTarget isDroppable={isDroppable} onDrop={this.handleDrop} internalId={''}>
					{sectionItem ? this.renderItem() : <Card className={styles.card} loading={true} />}
				</DropItemTarget>
			</div>
		);
	}
}

interface OwnProps {
	itemPosition: number;
	sectionInternalId: string;
}

const connector = connect(
	({ boardBuffer }: ReduxState, { sectionInternalId, itemPosition }: OwnProps) => ({
		sectionItem: provideSectionBySectionInternalIdAndPosition(
			boardBuffer,
			sectionInternalId,
			itemPosition
		),
	}),
	(dispatch, { sectionInternalId, itemPosition }: OwnProps) => ({
		addItem: (type: SectionItemType, id: string) =>
			dispatch(addItemToSection(sectionInternalId, type, id, itemPosition)),
		updateItem: (sectionItemInternalId: string, type: SectionItemType, id: string) =>
			dispatch(
				updateItemInSection(sectionItemInternalId, {
					boardId: null,
					articleId: null,
					boardsTeaserId: null,
					[`${type}Id`]: id,
				})
			),
		remove: (sectionItemInternalId: string) => dispatch(deleteItemInSection(sectionItemInternalId)),
		// maybe TODO: use itemPosition (of slot) instead of hoverOrder?
		reorder: (draggedItemInternalId: string, hoverOrder: number) =>
			dispatch(orderItemInSection(draggedItemInternalId, hoverOrder)),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PooledItem);
