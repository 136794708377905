import { Card } from 'antd';
import { Component } from 'react';

import type { BoardSectionSchema } from '../../../store/reducers/boardBuffer';
import gs from '../../../styles';
import translations from '../../../translations';
import SectionIcon from '../SectionIcon';

import styles from './SectionPickerItem.module.scss';

type Props = {
	value: BoardSectionSchema;
	onClick: (value: BoardSectionSchema) => void;
};

export default class SectionPickerItem extends Component<Props> {
	handleClick = () => this.props.onClick(this.props.value);

	render() {
		const { value } = this.props;
		return (
			<Card className={styles.card} bodyStyle={{ padding: 0 }} onClick={this.handleClick}>
				<div className={styles.header}>
					<SectionIcon schema={value} />
				</div>
				<div className={gs.pM}>
					<b>{translations.boardSectionSchema.title[value]}</b>
					<p>{translations.boardSectionSchema.description[value]}</p>
				</div>
			</Card>
		);
	}
}
