import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import * as util from '@sep/br24-util';
import { Alert, Card, type CardProps } from 'antd';
import type { MouseEventHandler } from 'react';
import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../../../environment';
import placeholderImage from '../../../../images/placeholder.png';

import styles from './SectionItem.module.scss';
import type { ArticleItemQuery } from './__generated__/ArticleItemQuery.graphql';

type ArticleByRowId = {
	rowId: string;
	title: string;
	teaserText: string | null;
	teaserImage: string | undefined | null;
};

type PassThroughProps = {
	compact?: boolean;
	removable?: boolean;
	openable?: boolean;
	onRequestRemove?: MouseEventHandler<HTMLSpanElement>;
	onRequestOpen?: MouseEventHandler<HTMLSpanElement>;
};

type ArticleProps = PassThroughProps & {
	article: ArticleByRowId;
};

function Article({
	article: { title, teaserText, teaserImage },
	removable = true,
	onRequestRemove,
	openable = true,
	onRequestOpen,
	compact,
}: ArticleProps) {
	const additionalProps: CardProps = {};

	if (!compact) {
		additionalProps.cover = <img alt="Vorschaubild" src={teaserImage || placeholderImage} />;
	}

	if (removable || openable) {
		additionalProps.actions = [];

		if (removable) {
			additionalProps.actions.push(<CloseOutlined onClick={onRequestRemove} />);
		}

		if (openable) {
			additionalProps.actions.push(<EditOutlined onClick={onRequestOpen} />);
		}
	}

	return (
		<Card bodyStyle={{ padding: 0 }} {...additionalProps}>
			<div className={styles.content}>
				<b>{title}</b>
				<p>{util.string.truncate(teaserText ?? '', 80)}</p>
			</div>
		</Card>
	);
}

type Props = PassThroughProps & {
	id: string;
};

export default function ({ id: articleId, ...rest }: Props) {
	return (
		<QueryRenderer<ArticleItemQuery>
			environment={environment}
			query={graphql`
				query ArticleItemQuery($articleId: String!) {
					article: articleByRowId(rowId: $articleId) {
						rowId
						title
						teaserImage
						teaserText
					}
				}
			`}
			variables={{ articleId }}
			render={({ error, props: queryRendererProps }) => {
				if (error) {
					return (
						<Alert
							message="Fehler"
							description={`Der Artikel "${articleId}" konnte nicht geladen werden.`}
							type="error"
						/>
					);
				}
				if (queryRendererProps && queryRendererProps.article) {
					return <Article article={queryRendererProps.article} {...rest} />;
				}

				return <Card loading={true} />;
			}}
		/>
	);
}
