import { APP_INITIALIZE, APP_BREAKPOINT_UPDATE, BP_ALL } from '@sep/br24-constants';
import type { Action } from 'redux';

import type { ActionWithPayload, AppThunkAction } from '@/client/store/redux';
import { generateReducer } from '@/client/util/generateReducer';

import { APP_UPDATE_WORKING } from '../../constants';

export type State = {
	isInitialized: boolean;
	isWorking: boolean;
	currentBreakPoint: (typeof BP_ALL)[number] | undefined | null;
};

type UpdateBreakPointAction = ActionWithPayload<
	typeof APP_BREAKPOINT_UPDATE,
	(typeof BP_ALL)[number]
>;
type UpdateWorkingAction = ActionWithPayload<typeof APP_UPDATE_WORKING, boolean>;

const initialState: State = {
	isInitialized: false,
	isWorking: false,
	currentBreakPoint: null,
};

export default generateReducer(initialState, {
	[APP_INITIALIZE]: (state: State) => ({
		...state,
		isInitialized: true,
	}),
	[APP_BREAKPOINT_UPDATE]: (state: State, action: UpdateBreakPointAction) => ({
		...state,
		currentBreakPoint: action.payload,
	}),
	[APP_UPDATE_WORKING]: (state: State, action: UpdateWorkingAction) => ({
		...state,
		isWorking: action.payload,
	}),
});

export function initialize(): AppThunkAction<
	UpdateBreakPointAction | Action<typeof APP_INITIALIZE>
> {
	return (dispatch, getState) => {
		const { isInitialized } = getState().app;

		if (isInitialized) {
			return;
		}

		dispatch({ type: APP_INITIALIZE });

		let initialBreakPoint: string | null = null;
		BP_ALL.forEach((size) => {
			const mm = window.matchMedia(size);

			mm.addListener(() => {
				if (mm.matches) {
					dispatch({ type: APP_BREAKPOINT_UPDATE, payload: size });
				}
			});

			if (mm.matches) {
				initialBreakPoint = size;
			}
		});

		if (initialBreakPoint) {
			dispatch({ type: APP_BREAKPOINT_UPDATE, payload: initialBreakPoint });
		}
	};
}

function setWorking(isWorking: boolean) {
	return {
		type: APP_UPDATE_WORKING,
		payload: isWorking,
	};
}

export function enableWorkingIndicator() {
	return setWorking(true);
}

export function disableWorkingIndicator() {
	return setWorking(false);
}
