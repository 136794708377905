import * as tree from '@nosferatu500/react-sortable-tree';
import type { TreeItem } from '@nosferatu500/react-sortable-tree';
import type { PartialDeep } from 'type-fest';

import type {
	NavigationItemId,
	TreeNavigationItem,
	NavigationItemPayload,
	UpsertNavigationItemPayload,
} from '../types';

export type Key = string | number | symbol;

export const getNodeKeyFromTreeNavigationItem = (item: any, key = 'rowId') => {
	return 'node' in item ? String(item.node.data[key]) : (String(item.data[key]) as any);
};

/**
 * A wrapper for react-sortable-tree's native getFlatDataFromTree to support generics
 */
export function getFlatDataFromTree<T extends Record<string, any> = any>(
	treeData: ReturnType<typeof getTreeFromFlatData<T>>
) {
	const result = tree.getFlatDataFromTree({
		treeData,
		getNodeKey: getNodeKeyFromTreeNavigationItem,
		ignoreCollapsed: false,
	});

	return result.map((item) => item.node) as TreeNavigationItem[];
}

/**
 * A wrapper for react-sortable-tree's native getTreeFromFlatData to support generics
 */
export function getTreeFromFlatData<T extends Record<string, any> = any>(
	flatData: readonly T[],
	rootId: NavigationItemId
) {
	const result = tree.getTreeFromFlatData({
		flatData,
		getKey: getNodeKeyFromTreeNavigationItem,
		getParentKey: (item) => item.data.parent ?? rootId,
		rootKey: rootId as unknown as string, // the lib requires the type to be string but it can be anything
	});

	return result as TreeNavigationItem[];
}

export function map<T extends TreeNavigationItem, C extends (item: T) => T>(
	treeData: T[],
	callback: C
) {
	const result = tree.map({
		treeData,
		ignoreCollapsed: false,
		getNodeKey: getNodeKeyFromTreeNavigationItem,
		callback: ({ node }) => callback(node),
	}) as ReturnType<C>[];

	return result;
}

export function walk<
	T extends Omit<TreeNavigationItem, 'data'> & PartialDeep<Pick<TreeNavigationItem, 'data'>>
>(
	treeData: T[],
	callback: (
		data: null | {
			node: T;
			parentNode: T;
			path: number[];
			lowerSiblingCounts: number;
			treeIndex: number;
		}
	) => void
) {
	tree.walk({
		treeData,
		ignoreCollapsed: false,
		getNodeKey: getNodeKeyFromTreeNavigationItem,
		callback,
	});
}

export function walkTreeByOrderPath(
	tree: TreeNavigationItem[],
	parentId: NavigationItemId,
	callback: (item: TreeNavigationItem, path: number[], parentId: NavigationItemId) => void,
	path: number[] = []
) {
	tree.forEach((item, index) => {
		path.push(index);
		callback(item, [...path], parentId);
		if (item.children) {
			walkTreeByOrderPath(item.children, item.data.rowId, callback, path);
		}
		path.pop();
	});
}

export function changeNodeById(
	treeData: TreeNavigationItem[],
	rowId: NavigationItemId,
	update: NavigationItemPayload
): TreeNavigationItem[] {
	const clonedTreeData = cloneTreeData(treeData);

	walk(clonedTreeData, (item) => {
		if (item?.node.data.rowId === rowId) {
			item.node.data = { ...item.node.data, ...update };
		}
	});

	return clonedTreeData;
}

export function cloneTreeData(treeData: TreeNavigationItem[]): TreeNavigationItem[] {
	const clonedTreeData = map(treeData, (item) => {
		return {
			...item,
			data: structuredClone(item.data),
		};
	});

	return clonedTreeData;
}

export function addNodeUnderParent<T extends TreeNavigationItem>(
	treeData: T[],
	newNode: PartialDeep<T>,
	parent: string | undefined
) {
	const result = tree.addNodeUnderParent({
		treeData: cloneTreeData(treeData),
		getNodeKey: getNodeKeyFromTreeNavigationItem,
		newNode: newNode as TreeItem,
		parentKey: parent,
		expandParent: true,
	});

	return result.treeData as TreeNavigationItem[];
}

export class TreeId {
	private static current = -1000;

	static new() {
		return this.current--;
	}

	static isNew(id: number) {
		return id < 0;
	}
}

export function createTreeItemFromNavigationItem(
	data: UpsertNavigationItemPayload
): PartialDeep<TreeNavigationItem> {
	switch (data.type) {
		case 'ARTICLE':
			return {
				data: {
					...data,
					boardByBoard: null,
					rowId: TreeId.new(),
				},
			};
		case 'BOARD':
			return {
				data: {
					...data,
					articleByArticle: null,
					rowId: TreeId.new(),
				},
			};
		case 'CUSTOM':
			return {
				data: {
					...data,
					boardByBoard: null,
					articleByArticle: null,
					rowId: TreeId.new(),
				},
			};
		default:
			return {
				data: data,
			};
	}
}
