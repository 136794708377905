import * as util from '@sep/br24-util';
import { graphql, QueryRenderer } from 'react-relay';

import type { ItemLiveStream, ImportedItemLiveStream } from '..';
import environment from '../../../environment';
import PlaceholderImage from '../../../images/placeholder.png';
import {
	AssetManagerItemLiveStream as Wrapper,
	AssetManagerItemInvalid,
	AssetManagerItemLoading,
} from '../../../ui/AssetManager';

import type { AssetManagerItemLiveStreamRenderedQuery } from './__generated__/AssetManagerItemLiveStreamRenderedQuery.graphql';

type Props = {
	item: ItemLiveStream | ImportedItemLiveStream;
};

function AssetManagerItemLiveStream({ item }: Props) {
	return (
		<>
			{item.title ? (
				<Wrapper
					title={item.title}
					kicker={item.kicker}
					image={item.image ? util.image.resize(item.image, 'landscape', 's') : PlaceholderImage}
					start={item.start}
					end={item.end}
				/>
			) : (
				<AssetManagerItemLiveStreamRenderer item={item} />
			)}
		</>
	);
}

function AssetManagerItemLiveStreamRenderer({ item }: Props) {
	return (
		<QueryRenderer<AssetManagerItemLiveStreamRenderedQuery>
			environment={environment}
			query={graphql`
				query AssetManagerItemLiveStreamRenderedQuery($programmeId: ID!) {
					viewer {
						programme(id: $programmeId) {
							__typename
							id
							title
							kicker
							defaultTeaserImage {
								imageFiles {
									edges {
										node {
											publicLocation
										}
									}
								}
							}
							broadcasts(orderBy: START_DESC) {
								edges {
									node {
										start
										end
										id
									}
								}
							}
						}
					}
				}
			`}
			variables={{ programmeId: item.programmeId }}
			render={({ error, props: result }) => {
				if (error) {
					return null;
				}
				if (!result) {
					return <AssetManagerItemLoading type={item.type} />;
				}
				const program = result?.viewer?.programme;
				if (!program) {
					return <AssetManagerItemInvalid type={item.type} />;
				}
				const title = program?.title;
				const kicker = program?.kicker;
				let broadcast = program?.broadcasts?.edges?.find(
					(specificBroadcast) => specificBroadcast?.node?.id === item.broadcastId
				);

				// For now we decided we should at least return the livestream, even if the dates are wrong.
				if (!broadcast) {
					// eslint-disable-next-line prefer-destructuring
					broadcast = program?.broadcasts?.edges?.[0];
				}

				const { start, end } = broadcast?.node ?? {};
				const image = program?.defaultTeaserImage?.imageFiles?.edges?.[0]?.node?.publicLocation;

				return (
					<Wrapper
						title={title ?? ''}
						kicker={kicker}
						image={image ? util.image.resize(image, 'landscape', 's') : PlaceholderImage}
						start={start}
						end={end}
					/>
				);
			}}
		/>
	);
}
export default AssetManagerItemLiveStream;
