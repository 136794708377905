import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import Author from '../components/Author';
import environment from '../environment';

import type { AuthorContainerQuery } from './__generated__/AuthorContainerQuery.graphql';

const SpinnerWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
	height: 90vh;
`;

const Loader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
`;

type Props = {
	match: {
		params: {
			id: string;
		};
	};
};

export default function (props: Props) {
	return (
		<QueryRenderer<AuthorContainerQuery>
			environment={environment}
			query={graphql`
				query AuthorContainerQuery($authorId: String!, $articlesCount: Int!, $cursor: Cursor) {
					author: authorByGuid(guid: $authorId) {
						...Author_author
					}
					authorArticles: articlesByAuthor(guid: $authorId, first: $articlesCount, after: $cursor) {
						...Author_authorArticles
					}
				}
			`}
			variables={{
				authorId: props.match.params.id,
				articlesCount: 10,
				cursor: null,
			}}
			render={({ error, props: renderProps }) => {
				const isReady = error === null && renderProps;

				if (error) {
					return <Alert message="Fehler" description={error.message} type="error" />;
				}

				if (isReady) {
					if (!renderProps.author) {
						return (
							<Alert
								message="Fehler"
								description="Der Autor konnte nicht gefunden werden."
								type="error"
							/>
						);
					}
					return <Author author={renderProps.author} authorArticles={renderProps.authorArticles} />;
				}

				return (
					<SpinnerWrapper>
						<Loader>
							<Spin size="large" />
						</Loader>
					</SpinnerWrapper>
				);
			}}
		/>
	);
}
