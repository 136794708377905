import {
	SOPHORA_SEARCH_UPDATE,
	SOPHORA_SEARCH_FIRE,
	SOPHORA_FETCH,
	STATUS_REQUEST,
	STATUS_SUCCESS,
	STATUS_ERROR,
} from '@sep/br24-constants';
import update from 'immutability-helper';

import { generateReducer } from '@/client/util/generateReducer';

import config from '../../config';
import type { ActionWithPayload, AppThunkAction } from '../redux';

export type SophoraArticle = {
	id: string;
	title: string;
	teaserText: string | undefined | null;
	publicationDate: string | undefined | null;
	idHistory: Array<string> | undefined | null;
};

export type State = {
	isSearchWorking: boolean;
	latestSearchError: string | undefined | null;
	fetchWorking: {
		[key: string]: boolean;
	};
	fetchLatestError: {
		[key: string]: Error | null;
	};
	searchTerm: string | undefined | null;
	map: {
		[key: string]: SophoraArticle;
	};
	resultSets: {
		[key: string]: Array<string>;
	};
};

const initialState: State = {
	isSearchWorking: false,
	latestSearchError: null,
	searchTerm: null,
	fetchWorking: {},
	fetchLatestError: {},
	map: {},
	resultSets: {},
};

export default generateReducer(initialState, {
	[SOPHORA_FETCH]: {
		[STATUS_REQUEST]: (state: State, { payload }: SophoraFetchActionRequest) =>
			update(state, {
				isSearchWorking: { $set: true },
				fetchWorking: { [payload]: { $set: true } },
				fetchLatestError: { [payload]: { $set: null } },
			}),
		[STATUS_SUCCESS]: (state: State, { payload }: SophoraFetchActionSuccess) =>
			update(state, {
				isSearchWorking: { $set: false },
				fetchWorking: { [payload.id]: { $set: false } },
				fetchLatestError: { [payload.id]: { $set: null } },
				map: {
					[payload.id]: { $set: payload },
				},
			}),
		[STATUS_ERROR]: (state: State, { payload: { error, id } }: SophoraFetchActionFailure) =>
			update(state, {
				isSearchWorking: { $set: false },
				fetchWorking: { [id]: { $set: false } },
				fetchLatestError: { [id]: { $set: error } },
			}),
	},
	[SOPHORA_SEARCH_UPDATE]: (state: State, { payload }) =>
		update(state, {
			searchTerm: { $set: payload },
		}),
	[SOPHORA_SEARCH_FIRE]: {
		[STATUS_REQUEST]: (state: State) =>
			update(state, {
				isSearchWorking: { $set: true },
				latestSearchError: { $set: null },
			}),
		[STATUS_SUCCESS]: (state: State, { payload }: SophoraSearchFireActionSuccess) =>
			update(state, {
				isSearchWorking: { $set: false },
				latestSearchError: { $set: null },
				map: payload.reduce(
					(accumulator, article) => ({
						...accumulator,
						[article.id]: { $set: article },
					}),
					{}
				),
				resultSets: {
					[state.searchTerm as string]: { $set: payload.map((article) => article.id) },
				},
			}),
		[STATUS_ERROR]: (state: State, { payload }: SophoraSearchFireActionFailure) =>
			update(state, {
				isSearchWorking: { $set: false },
				latestSearchError: { $set: payload.message },
			}),
	},
});

async function callResource(resource: string, qs: any = {}) {
	const headers = new Headers();

	const url = new URL(`${config.VITE_SOPHORA_URL_EXT}/${resource}`);

	Object.entries(qs).forEach(([key, value]) => url.searchParams.set(key, String(value)));

	const res = await fetch(url, {
		method: 'GET',
		headers,
	});
	return await res.json();
}

export function updateSearchTerm(searchTerm?: string | null) {
	return {
		type: SOPHORA_SEARCH_UPDATE,
		payload: searchTerm,
	};
}

interface WithStatus<T extends string> {
	status: T;
}

interface SophoraSearchFireActionSuccess
	extends ActionWithPayload<typeof SOPHORA_SEARCH_FIRE, SophoraArticle[]>,
		WithStatus<typeof STATUS_SUCCESS> {}

interface SophoraSearchFireActionFailure
	extends ActionWithPayload<typeof SOPHORA_SEARCH_FIRE, Error>,
		WithStatus<typeof STATUS_ERROR> {}

interface SophoraFetchActionSuccess
	extends ActionWithPayload<typeof SOPHORA_FETCH, SophoraArticle>,
		WithStatus<typeof STATUS_SUCCESS> {}

interface SophoraFetchActionFailure
	extends ActionWithPayload<typeof SOPHORA_FETCH, { error: Error; id: string }>,
		WithStatus<typeof STATUS_ERROR> {}

interface SophoraFetchActionRequest
	extends ActionWithPayload<typeof SOPHORA_FETCH, string>,
		WithStatus<typeof STATUS_REQUEST> {}

export function fetchById(
	id: string
): AppThunkAction<
	SophoraFetchActionSuccess | SophoraFetchActionFailure | SophoraFetchActionRequest
> {
	return (dispatch, getState) => {
		const state = getState();

		if (state.sophora.fetchWorking[id] || state.sophora.map[id]) {
			return;
		}

		dispatch({ type: SOPHORA_FETCH, status: STATUS_REQUEST, payload: id });

		callResource(`sophora-by-id/${id}`)
			.then((res) => res.data)
			.then((res) => {
				dispatch({
					type: SOPHORA_FETCH,
					status: STATUS_SUCCESS,
					payload: res,
				});
			})
			.catch((err: Error) =>
				dispatch({ type: SOPHORA_FETCH, status: STATUS_ERROR, payload: { id, error: err } })
			);
	};
}
