import styled from 'styled-components';

import config from '../../../config';
import { getAssetManagerTypeIcon } from '../../../ui/Icon';

import type { ModuleType } from './__generated__/ArticlesTable_query.graphql';
import type { ArticlesTableArticle } from './types';

type Props = {
	node: ArticlesTableArticle;
	small?: boolean;
};

const generateKey = (pre: string) => {
	return `${pre}_${new Date().getTime()}`;
};

const IconWrapper = styled.span<{ small: boolean }>`
	display: inline-block;
	color: '#000000';
	font-size: ${(props) => (props.small ? '1em' : '1.3em')};
	padding-right: 10px;
`;

const IconTypes = styled(({ type, small }) => {
	const Icon = getAssetManagerTypeIcon(type);
	return (
		<IconWrapper small={small}>
			<Icon />
		</IconWrapper>
	);
})`
	bottom: ${(props) => (props.isOverlying ? '0.4rem' : 'auto')};
	display: flex;
	align-items: center;
	justify-content: center;
	left: ${(props) => (props.isOverlying ? '0.4rem' : 'auto')};
	position: ${(props) => (props.isOverlying ? 'absolute' : 'static')};
	margin: ${(props) => (props.isOverlying ? 'none' : '0 0.4rem')};
`;

const IconGroup = styled.div`
	text-align: left;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
`;

export function ArticleIcon(props: Props) {
	const { node, small } = props;

	if (!node) {
		return null;
	}

	const moduleIcons: ModuleType[] = [];

	for (const { node: modulesByArticleIdNode } of node.modules.edges) {
		if (modulesByArticleIdNode?.type && !moduleIcons.includes(modulesByArticleIdNode.type)) {
			moduleIcons.push(modulesByArticleIdNode.type);
		}
	}

	return (
		<IconGroup>
			{moduleIcons.map((type) => (
				<IconTypes
					key={generateKey(type)}
					type={config.articleIcons[type]}
					isOverlying={true}
					small={small}
				/>
			))}
		</IconGroup>
	);
}
