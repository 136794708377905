import styled from 'styled-components';

import { ReactComponent as MapMarkerSvg } from './MapMarkerSvg.svg';

const MapMarkerSvgStyled = styled(MapMarkerSvg)`
	enable-background: new 0 0 32 48;

	.st0 {
		fill: #2c3139;
	}
	.st1 {
		fill: #00a0eb;
	}
	.st2 {
		fill: #ffffff;
	}
`;

export default MapMarkerSvgStyled;
