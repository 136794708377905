import { differenceBy } from 'lodash-es';
import isEqual from 'react-fast-compare';

import type { TreeNavigationItem } from '../types';

import { TreeId } from './react-sortable-tree-helpers';

export function getNavigationDiff(
	initial: readonly TreeNavigationItem[],
	current: TreeNavigationItem[]
) {
	const currentMap = new Map(current.map((item) => [item.data.rowId, item]));

	const addedItems: TreeNavigationItem[] = current.filter((item) => TreeId.isNew(item.data.rowId));

	/**
	 * elements that are in the initial map but not in the current map
	 */
	const removedItems = differenceBy(initial, current, (item) => item.data.rowId);

	const changedItems: TreeNavigationItem[] = [];

	for (const initialItem of initial) {
		const currentItem = currentMap.get(initialItem.data.rowId);

		if (currentItem && !isEqual(currentItem.data, initialItem.data)) {
			changedItems.push(currentItem);
		}
	}

	const items = [
		...addedItems.map((item) => ({ type: 'create' as const, item })),
		...changedItems.map((item) => ({ type: 'update' as const, item })),
		...removedItems.map((item) => ({ type: 'delete' as const, item })),
	];

	return {
		items,
		needsCommit: items.length > 0,
	};
}
