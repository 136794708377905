import { useTranslate } from '@/client/translation/useTranslate';

import { AM_TYPE_IMAGE } from '../../../constants';

import { Title, Content, Copyright } from './AssetManagerItemControl';
import Media from './Media';
import TypeIcon from './TypeIcon';

type AssetManagerItemBrBildImageProps = {
	image: string | null;
	title: string | null;
	copyright: string | null;
};

export default function AssetManagerItemBrBildImage({
	image,
	title,
	copyright,
}: AssetManagerItemBrBildImageProps) {
	const translate = useTranslate();
	return (
		<>
			<TypeIcon type={AM_TYPE_IMAGE} isOverlying={true} />
			<Media image={image} />
			<Content>
				<Title>{title || translate(`assetManager.item.${AM_TYPE_IMAGE}.noTitle`)}</Title>
				{copyright && <Copyright>{copyright}</Copyright>}
			</Content>
		</>
	);
}
