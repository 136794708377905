/**
 * @generated SignedSource<<b23ee9c9db01bb5c113d157bf503ea33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type saveArticleLockByRowIdQuery$variables = {
  id: string;
};
export type saveArticleLockByRowIdQuery$data = {
  readonly article: {
    readonly authorByRevisionBy: {
      readonly firstname: string | null;
      readonly id: string;
      readonly lastname: string | null;
    } | null;
    readonly lastEdited: string;
    readonly lockedBy: string | null;
    readonly lockedSince: string | null;
    readonly rowId: string;
  } | null;
};
export type saveArticleLockByRowIdQuery = {
  response: saveArticleLockByRowIdQuery$data;
  variables: saveArticleLockByRowIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastEdited",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lockedBy",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lockedSince",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "authorByRevisionBy",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveArticleLockByRowIdQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveArticleLockByRowIdQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0d7cd57caea27c8028170be79d8e932",
    "id": null,
    "metadata": {},
    "name": "saveArticleLockByRowIdQuery",
    "operationKind": "query",
    "text": "query saveArticleLockByRowIdQuery(\n  $id: String!\n) {\n  article: articleByRowId(rowId: $id) {\n    rowId\n    lastEdited\n    lockedBy\n    lockedSince\n    authorByRevisionBy {\n      firstname\n      lastname\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "77d53a06efb3dfb90a1ee6c333fb7090";

export default node;
