import { AUTHORS_MANAGER_UPDATE_FILTER } from '@sep/br24-constants';
import update from 'immutability-helper';

import type { ActivityFilter, LoginTypeFilter } from '@/client/components/Authors/utils';
import { generateReducer } from '@/client/util/generateReducer';

import { AUTHORS_FILTERS_LOGIN_TYPE_ALL, AUTHORS_FILTERS_ACTIVITY_ACTIVE } from '../../constants';

export type AuthorsFilterType = {
	searchTerm?: string;
	isActive?: ActivityFilter;
	isLdapUser?: LoginTypeFilter;
};

const initialState: AuthorsFilterType = {
	searchTerm: '',
	isActive: AUTHORS_FILTERS_ACTIVITY_ACTIVE,
	isLdapUser: AUTHORS_FILTERS_LOGIN_TYPE_ALL,
};

export default generateReducer(initialState, {
	[AUTHORS_MANAGER_UPDATE_FILTER]: (state: AuthorsFilterType, action: UpdateFilterAction) =>
		update(state, {
			$merge: action.payload,
		}),
});

export type UpdateFilterAction = {
	type: typeof AUTHORS_MANAGER_UPDATE_FILTER;
	payload: AuthorsFilterType;
};

export function updateFilter(payload: AuthorsFilterType): UpdateFilterAction {
	return {
		type: AUTHORS_MANAGER_UPDATE_FILTER,
		payload,
	};
}
