import { graphql, requestSubscription, type Disposable } from 'react-relay';
import type { RecordProxy } from 'relay-runtime';

import environment from '../../environment';

import type {
	SubscriptionHandlerattachClientToLockSubscription,
	SubscriptionHandlerattachClientToLockSubscription$data,
} from './__generated__/SubscriptionHandlerattachClientToLockSubscription.graphql';

export class LockSubscriptionHandler {
	private subscription: Disposable | undefined;

	subscribe(
		updateHandler: (
			data: SubscriptionHandlerattachClientToLockSubscription$data | undefined | null
		) => void,
		errorHandler: (err: Error) => void
	) {
		this.subscription = requestSubscription<SubscriptionHandlerattachClientToLockSubscription>(
			environment,
			{
				subscription: graphql`
					subscription SubscriptionHandlerattachClientToLockSubscription {
						onLockUpdate {
							type
							entityId
							rowId
							lockedBy
							lockedByEntityId
							lockedSince
							authorFirstName
							authorLastName
							authorProfilePicture
						}
					}
				`,
				variables: {},
				onError(error) {
					errorHandler(error);
				},
				onNext(data) {
					updateHandler(data);
				},
				updater: (store, { onLockUpdate }) => {
					if (!onLockUpdate) {
						return;
					}
					// extract the row id from the subscription event. The row id can belong to
					// an article, board or teaser
					const entityId = store.getRootField('onLockUpdate').getValue('entityId');

					const entityToUpdate = store.get(entityId);

					if (!entityToUpdate) {
						return;
					}

					let author: RecordProxy | null | undefined;
					let lockedSince: null | string;

					if (!onLockUpdate.lockedBy) {
						author = null;
						lockedSince = null;
					} else {
						// do a lookup of the locking author
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						author = store.get(onLockUpdate.lockedByEntityId!);

						if (!author) {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							author = store.create(onLockUpdate.lockedByEntityId!, 'Author');
						}
						author.setValue(onLockUpdate.authorFirstName ?? null, 'firstname');
						author.setValue(onLockUpdate.authorLastName ?? null, 'lastname');
						author.setValue(onLockUpdate.authorProfilePicture ?? null, 'profilePicture');

						lockedSince = onLockUpdate.lockedSince ?? null;
					}

					if (author) {
						entityToUpdate.setLinkedRecord(author, 'authorByLockedBy');
					} else {
						entityToUpdate.setValue(null, 'authorByLockedBy');
					}
					entityToUpdate.setValue(lockedSince, 'lockedSince');
				},
			}
		);
	}

	unsubscribe() {
		if (this.subscription) {
			this.subscription.dispose();
			this.subscription = undefined;
		}
	}
}
