import { Layout, Row, Col } from 'antd';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import PlaceholderImage from '../../images/placeholder_xl.png';
import styles from '../../styles';

const { Content } = Layout;

type State = {
	joke: string | undefined | null;
};

export default class NotFound extends Component<any, State> {
	state = {
		joke: null,
	};

	private abortController: AbortController | undefined;

	componentDidMount() {
		this.abortController = new AbortController();

		fetch('http://api.icndb.com/jokes/random', { signal: this.abortController.signal })
			.then((res) => res.json())
			.then((res) => this.setState({ joke: res.value.joke }));
	}

	componentWillUnmount(): void {
		this.abortController?.abort();
	}

	render() {
		const { joke } = this.state;

		return (
			<Layout>
				<Helmet>
					<title>Seite nicht gefunden</title>
				</Helmet>
				<Content>
					<Row gutter={24}>
						<Col span={24}>
							<div>
								<div
									className={styles.notFoundHeader}
									style={{ backgroundImage: `url(${PlaceholderImage})` }}
								>
									<h1>404</h1>
								</div>
								<div className={styles.pXL}>
									<h2>Die von Dir aufgerufene Seite existiert nicht (mehr).</h2>
									{joke ? <p>{joke}</p> : false}
								</div>
							</div>
						</Col>
					</Row>
				</Content>
			</Layout>
		);
	}
}
