import { Form, Input } from 'antd';
import { type ComponentProps, type FC, useCallback, useMemo } from 'react';

import { NAVIGATION_TYPE_ARTICLE } from '@/client/constants';

import translations from '../translations';
import type { UpsertNavigationItemPayload } from '../types';

import { NavigationArticlePicker } from './NavigationArticlePicker';

export const NavigationEditorFormArticle: FC<{}> = () => {
	const form = Form.useFormInstance<UpsertNavigationItemPayload>();

	const initialPickerValues = useMemo<
		ComponentProps<typeof NavigationArticlePicker>['initialValues']
	>(
		() => ({
			id: form.getFieldValue(['articleByArticle', 'rowId']),
			title: form.getFieldValue(['articleByArticle', 'title']),
		}),
		[form]
	);

	const handlePickerSelect = useCallback<
		ComponentProps<typeof NavigationArticlePicker>['onSelect']
	>(
		({ id, title }) => {
			form.setFieldsValue({
				articleByArticle: { title, rowId: id },
			});
		},
		[form]
	);

	return (
		<>
			<Form.Item initialValue={NAVIGATION_TYPE_ARTICLE} name="type" hidden>
				<Input />
			</Form.Item>

			{/* we need to define this field here to have antd Form publish it on submit */}
			<Form.Item name={['articleByArticle', 'title']} hidden>
				<Input />
			</Form.Item>

			<Form.Item
				name={'text'}
				label={translations.NM_LINK_TITLE_LABEL}
				hasFeedback={true}
				rules={[{ required: true, message: translations.NM_LINK_TITLE_ERROR, min: 3 }]}
			>
				<Input placeholder={translations.NM_LINK_TITLE_PLACEHOLDER} />
			</Form.Item>

			<Form.Item
				name={['articleByArticle', 'rowId']}
				label={translations.NM_ARTICLE_SEARCH_LABEL}
				rules={[{ required: true, message: translations.NM_ARTICLE_SEARCH_ERROR }]}
			>
				<NavigationArticlePicker
					initialValues={initialPickerValues}
					onSelect={handlePickerSelect}
				/>
			</Form.Item>
		</>
	);
};
