import { Alert, type AlertProps } from 'antd';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import type { ReduxState } from '../store/reducers';
import { addFlag as addFlagActionCreator } from '../store/reducers/user';

interface Props extends AlertProps, PropsFromRedux {
	flag: string;
}

function UserAlert({ flag, addFlag, isFlagged, ...rest }: Props) {
	function handleClose() {
		addFlag(flag);
	}

	if (isFlagged) {
		return null;
	}

	return <Alert {...rest} closable={true} onClose={handleClose} />;
}

const connector = connect(
	(state: ReduxState, props: { flag: string }) => ({
		isFlagged: state.user.flags.includes(props.flag),
	}),
	{
		addFlag: addFlagActionCreator,
	}
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserAlert);
