/**
 * @generated SignedSource<<fa0ddb3c399f67e66c63854fe8faf224>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HeaderUserQueryContainerQuery$variables = {
  guid: string;
};
export type HeaderUserQueryContainerQuery$data = {
  readonly authorByGuid: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
    readonly profilePicture: string | null;
  } | null;
};
export type HeaderUserQueryContainerQuery = {
  response: HeaderUserQueryContainerQuery$data;
  variables: HeaderUserQueryContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "guid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "guid",
    "variableName": "guid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "guid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePicture",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderUserQueryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "authorByGuid",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HeaderUserQueryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "authorByGuid",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5be0cf83688ec03b87e76de6a21f4ae8",
    "id": null,
    "metadata": {},
    "name": "HeaderUserQueryContainerQuery",
    "operationKind": "query",
    "text": "query HeaderUserQueryContainerQuery(\n  $guid: String!\n) {\n  authorByGuid(guid: $guid) {\n    guid\n    profilePicture\n    firstname\n    lastname\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a0cfaa2b3921e2a370375891d6079e3d";

export default node;
