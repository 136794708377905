import { Modal, notification, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	type PreloadedQuery,
	usePreloadedQuery,
	type UseQueryLoaderLoadQueryOptions,
} from 'react-relay';
import { isPresent } from 'ts-extras';

import { navigationQuery } from './Navigation.graphql';
import { NavigationEditorDialog } from './NavigationEditorDialog';
import { NavigationEditorTree } from './NavigationEditorTree';
import { NavigationNavBar } from './NavigationNavBar';
import type {
	NavigationQuery,
	NavigationQuery$variables,
} from './__generated__/NavigationQuery.graphql';
import type {
	NavigationItemModifier,
	TreeNavigationItem,
	TreeDataModifier,
	UpsertNavigationItemPayload,
	NavigationItemId,
} from './types';
import { useMutateNavigation } from './useMutateNavigation';
import { flattenNavigationData } from './utils/dataToNavigationItemMap';
import { getNavigationDiff } from './utils/getNavigationDiff';
import {
	addNodeUnderParent,
	changeNodeById,
	createTreeItemFromNavigationItem,
	getFlatDataFromTree,
	getTreeFromFlatData,
	walkTreeByOrderPath,
} from './utils/react-sortable-tree-helpers';

const DEBUG = false;

type NavigationProps = {
	queryRef: PreloadedQuery<NavigationQuery>;
	refresh: (
		variables: NavigationQuery$variables,
		options?: UseQueryLoaderLoadQueryOptions | undefined
	) => void;
};

export function Navigation({ queryRef, refresh }: NavigationProps) {
	const [editingItem, setEditingItem] = useState<Partial<UpsertNavigationItemPayload> | null>(null);

	const { navigationByRowId } = usePreloadedQuery<NavigationQuery>(navigationQuery, queryRef);

	const saveDiff = useMutateNavigation();
	const [isSaving, setSaving] = useState(false);

	const rootId = useMemo<NavigationItemId>(
		() => navigationByRowId?.rowId ?? -1,
		[navigationByRowId?.rowId]
	);

	const [treeData, setTreeData] = useState<TreeNavigationItem[]>([]);

	const initialFlatNavigationList = useMemo(
		() => Object.freeze(flattenNavigationData(navigationByRowId)),
		[navigationByRowId]
	);

	const currentFlatNavigationList = useMemo(() => getFlatDataFromTree(treeData), [treeData]);

	useEffect(() => {
		// only update tree when we're not saving to avoid flickering
		if (!isSaving) {
			// clone the data to avoid mutating the original and mess up diffs
			setTreeData(getTreeFromFlatData(structuredClone(initialFlatNavigationList), rootId));
		}
	}, [initialFlatNavigationList, isSaving, rootId]);

	const handleChangeTree = useCallback<TreeDataModifier>(
		(nextTreeData) => {
			if (nextTreeData) {
				console.log(nextTreeData);
				/* update order and parent of items */
				walkTreeByOrderPath(nextTreeData, rootId, (item, path, parentId) => {
					item.data.order = path.at(-1) ?? 0;
					item.data.parent = parentId;
				});
				setTreeData(nextTreeData);
			}
		},
		[rootId]
	);

	const diff = useMemo(
		() => getNavigationDiff(initialFlatNavigationList, currentFlatNavigationList),
		[currentFlatNavigationList, initialFlatNavigationList]
	);

	/** ----------------------------------------------------------------------------------------------
	 * ADD ITEM (through form with defaults)
	 * _______________________________________________________________________________________________ */
	const handleConfirmItem = useCallback<(data: UpsertNavigationItemPayload) => void>(
		(data) => {
			if (data) {
				if ('rowId' in data && isPresent(data.rowId)) {
					handleChangeTree(changeNodeById(treeData, data.rowId, data));
				} else {
					handleChangeTree(
						addNodeUnderParent(
							treeData,
							createTreeItemFromNavigationItem(data),
							data.parent !== rootId ? `${data.parent}` : undefined
						)
					);
				}
			}
			setEditingItem(null);
		},
		[treeData, rootId, handleChangeTree]
	);

	const handleSave = useCallback(async () => {
		try {
			setSaving(true);
			await saveDiff(diff, treeData);
			notification.success({
				message: 'Die Navigation wurde erfolgreich gespeichert!',
				description: 'Neue Navigation ist nun live verfügbar.',
				duration: 3,
			});
		} catch (error: any) {
			console.error('Could not save navigation update', error);
			notification.error({
				message: 'Es ist ein Fehler aufgetreten.',
				description: 'Bitte versuche es später noch einmal.',
				duration: 3,
			});
		} finally {
			refresh(
				{ rowId: rootId },
				{ fetchPolicy: 'store-and-network', networkCacheConfig: { force: true } }
			);
			setSaving(false);
		}
	}, [diff, refresh, rootId, saveDiff, treeData]);

	const handleRequestCreateOrEditItem = useCallback<NavigationItemModifier>((item) => {
		setEditingItem({ ...item });
	}, []);

	const handleCancel = useCallback(() => {
		setEditingItem(null);
	}, []);

	if (DEBUG) {
		console.log({ diff, treeData, currentFlatNavigationList, initialFlatNavigationList, isSaving });
	}

	return (
		<>
			<NavigationNavBar
				isSaveEnabled={diff.needsCommit}
				onSave={handleSave}
				onRequestEditorDialog={handleRequestCreateOrEditItem}
			/>

			<NavigationEditorTree
				rootId={rootId}
				onChangeTree={handleChangeTree}
				treeData={treeData}
				onRequestEditorDialog={handleRequestCreateOrEditItem}
			/>

			<NavigationEditorDialog
				onCancel={handleCancel}
				onConfirmItem={handleConfirmItem}
				item={editingItem}
				rootId={rootId}
				open={!!editingItem}
			/>

			<Modal
				title="Änderungen werden gespeichert"
				centered
				open={isSaving}
				footer={null}
				closable={false}
			>
				<div style={{ display: 'grid', placeContent: 'center', height: 100 }}>
					<Spin />
				</div>
			</Modal>
		</>
	);
}
