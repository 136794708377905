import { DND_BOARD_ITEM, STATUS_PUBLISHED } from '@sep/br24-constants';

import type {
	AddItemToActiveSpaceActionFunction,
	TransferLinkFromPositionActionFunction,
} from '..';
import {
	AM_TYPE_ARTICLE,
	AM_TYPE_BOARD,
	AM_TYPE_BOARD_TEASER,
	AM_TYPE_TEXT,
	AM_TYPE_GALLERY,
	AM_TYPE_IMAGE,
	AM_TYPE_BRBILD_IMAGE,
	AM_TYPE_VIDEO,
	AM_TYPE_AUDIO,
	AM_TYPE_LIVESTREAM,
	AM_TYPE_EMBED_CODE,
} from '../../../constants';
import * as articleEditorItemTypes from '../../ArticleEditor/itemTypes';
import * as importerItemTypes from '../AssetManagerImporter/itemTypes';
import * as assetManagerItemTypes from '../itemTypes';

export type DropResult = {
	wasAdded: boolean;
	dropEffect?: 'copy' | 'move';
};

// TOOD: MISSING -> AUTHOR, EMBED, VIDEO360
export default function performDrop(
	addItemToActiveSpace: AddItemToActiveSpaceActionFunction,
	transferLinkFromPosition: TransferLinkFromPositionActionFunction,
	type: symbol | string | null,
	payload: any
): DropResult {
	switch (type) {
		case DND_BOARD_ITEM:
			switch (payload.type) {
				case 'article':
					addItemToActiveSpace({
						type: AM_TYPE_ARTICLE,
						articleId: payload.id,
						isPublished: payload.status === STATUS_PUBLISHED,
						title: payload.title,
						status: payload.status,
						image: payload.image,
						publicationDate: payload.publicationDate,
					});
					return { wasAdded: true, dropEffect: 'copy' };
				case 'board':
					addItemToActiveSpace({
						type: AM_TYPE_BOARD,
						boardId: payload.id,
						isPublished: payload.status === STATUS_PUBLISHED,
						title: payload.title,
						image: payload.image,
						publicationDate: payload.publicationDate,
					});
					return { wasAdded: true, dropEffect: 'copy' };
				case 'boardsTeaser':
					addItemToActiveSpace({
						type: AM_TYPE_BOARD_TEASER,
						boardTeaserId: payload.id,
						isPublished: payload.status === STATUS_PUBLISHED,
					});
					return { wasAdded: true, dropEffect: 'copy' };
				default:
					break;
			}
			return { wasAdded: false };

		case articleEditorItemTypes.AE_ARTICLE:
			addItemToActiveSpace({
				type: AM_TYPE_ARTICLE,
				articleId: payload.articleId,
				isPublished: payload.isPublished,
				title: payload.title,
				status: payload.status,
				image: payload.image,
				publicationDate: payload.publicationDate,
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_LINK:
			transferLinkFromPosition(payload.index);
			return { wasAdded: true };

		case articleEditorItemTypes.AE_VIDEO:
			addItemToActiveSpace({
				type: AM_TYPE_VIDEO,
				url: payload.video.url,
				title: payload.video.title,
				copyright: payload.video.copyright || null,
				thumbnail: payload.video.thumbnail || null,
				duration: payload.video.duration || 0,
				meta: payload.meta || null,
				altText: null,
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_TEXT:
			addItemToActiveSpace({
				type: AM_TYPE_TEXT,
				text: payload.text || '',
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_IMAGE:
			addItemToActiveSpace({
				type: AM_TYPE_IMAGE,
				url: payload.image.url || '',
				title: payload.image.title || '',
				copyright: payload.image.copyright || '',
				altText: payload.image.altText || '',
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_BRBILD_IMAGE:
			addItemToActiveSpace({
				type: AM_TYPE_BRBILD_IMAGE,
				url: payload.image.url || '',
				title: payload.image.title || '',
				copyright: payload.image.copyright || '',
				altText: payload.image.altText || '',
				copyrightStatus: payload.image.copyrightStatus,
				createdAt: payload.image.createdAt,
				copyrightNotice: payload.image.copyrightNotice,
				usageConstraints: payload.image.usageConstraints,
				timestamp: payload.image.timestamp,
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_GALLERY:
			addItemToActiveSpace({
				type: AM_TYPE_GALLERY,
				gallery: (payload.gallery || []).map((image) => ({
					url: image.url || '',
					title: image.title || '',
					copyright: image.copyright || '',
					altText: image.altText || '',
				})),
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_AUDIO:
			addItemToActiveSpace({
				type: AM_TYPE_AUDIO,
				url: payload.audio.url,
				title: payload.audio.title,
				copyright: payload.audio.copyright,
				thumbnail: payload.audio.thumbnail,
				duration: payload.audio.duration,
				meta: payload.meta || null,
				altText: null,
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_LIVESTREAM:
			addItemToActiveSpace({
				type: AM_TYPE_LIVESTREAM,
				livestreamId: payload.livestream.livestreamId,
				programmeId: payload.livestream.programmeId,
				broadcastId: payload.livestream.broadcastId,
			});
			return { wasAdded: true };

		case articleEditorItemTypes.AE_EMBED_CODE:
			addItemToActiveSpace({
				type: AM_TYPE_EMBED_CODE,
				...payload.embed,
			});
			return { wasAdded: true };

		// From other AssetManagerSpace
		case assetManagerItemTypes.AM_BOARD_TEASER:
		case assetManagerItemTypes.AM_BOARD:
		case assetManagerItemTypes.AM_ARTICLE:
		case assetManagerItemTypes.AM_TEXT:
		case assetManagerItemTypes.AM_LINK:
		case assetManagerItemTypes.AM_EMBED_CODE:
		case assetManagerItemTypes.AM_GALLERY:
		case assetManagerItemTypes.AM_IMAGE:
		case assetManagerItemTypes.AM_BRBILD_IMAGE:
		case assetManagerItemTypes.AM_AUDIO:
		case assetManagerItemTypes.AM_VIDEO360:
		case assetManagerItemTypes.AM_VIDEO:
		case assetManagerItemTypes.AM_LIVESTREAM: {
			addItemToActiveSpace(payload.item);
			return { wasAdded: true };
		}
		// From Importer
		case importerItemTypes.IMPORTED_AUDIO:
		case importerItemTypes.IMPORTED_VIDEO:
		case importerItemTypes.IMPORTED_TEXT:
		case importerItemTypes.IMPORTED_IMAGE:
		case importerItemTypes.IMPORTED_BRBILD_IMAGE:
		case importerItemTypes.IMPORTED_ARTICLE:
		case importerItemTypes.IMPORTED_BOARD:
		case importerItemTypes.IMPORTED_LIVESTREAM:
		case importerItemTypes.IMPORTED_EMBED:
		case importerItemTypes.IMPORTED_GALLERY: {
			addItemToActiveSpace(payload.importedItem);
			return { wasAdded: true };
		}
		case importerItemTypes.IMPORTED_ITEM_GROUP: {
			payload.importedItemGroupItems.forEach((item) => {
				addItemToActiveSpace(item);
			});
			return { wasAdded: true };
		}
		default:
			break;
	}
	return {
		wasAdded: false,
	};
}
