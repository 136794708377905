import { graphql, QueryRenderer, type QueryRendererProps, useRelayEnvironment } from 'react-relay';

import type { CategoryRendererQuery } from './__generated__/CategoryRendererQuery.graphql';

type Props = {
	children: QueryRendererProps<CategoryRendererQuery>['render'];
};

function CategoryRenderer({ children }: Props) {
	const environment = useRelayEnvironment();

	return (
		<QueryRenderer<CategoryRendererQuery>
			environment={environment}
			variables={{}}
			query={graphql`
				query CategoryRendererQuery {
					allCategories(first: 1000, condition: { active: true }) {
						edges {
							node {
								id
								rowId
								title
							}
						}
					}
				}
			`}
			render={children}
		/>
	);
}

export default CategoryRenderer;
