import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../environment';

import type { DataFilterRendererQuery } from './__generated__/DataFilterRendererQuery.graphql';

type Props = {
	renderFilter: QueryRendererProps<DataFilterRendererQuery>['render'];
};

function DataFilterRenderer({ renderFilter }: Props) {
	return (
		<QueryRenderer<DataFilterRendererQuery>
			environment={environment}
			query={graphql`
				query DataFilterRendererQuery {
					allCategories(first: 1000, condition: { active: true }) {
						edges {
							node {
								id
								rowId
								title
							}
						}
					}
				}
			`}
			variables={{}}
			render={renderFilter}
		/>
	);
}

export default DataFilterRenderer;
