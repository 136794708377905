import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';

import { useTranslate } from '@/client/translation/useTranslate';

import placeholder from '../../../../images/placeholder.png';
import ClickHandler from '../ClickHandler';
import { DraggableItem } from '../DraggableItem';

import styles from './BoardsTeaserSearchItem.module.scss';
import type { BoardsTeaserSearchItem_boardsTeaser$data } from './__generated__/BoardsTeaserSearchItem_boardsTeaser.graphql';

type Props = {
	boardsTeaser: BoardsTeaserSearchItem_boardsTeaser$data;
	onRequestOpen: (rowId: string) => void;
};

function BoardsTeaserSearchItem({
	boardsTeaser: { rowId, title, image, link, updatedAt, createdAt, status },
	onRequestOpen,
}: Props) {
	const translate = useTranslate();

	return (
		<DraggableItem
			className={styles.boardsTeaser}
			type="boardsTeaser"
			id={rowId}
			status={status as string}
			internalId=""
			sectionId=""
		>
			<div
				className={styles.boardsTeaserImage}
				style={{
					backgroundImage: `url(${image ? image.url || placeholder : placeholder})`,
				}}
			/>
			<div className={styles.boardsTeaserInfo}>
				<h3>{title}</h3>
				{link ? <p>{link.label}</p> : null}
				<p>
					{translate('board.sectionItem.boardsTeaser.updated')}
					{moment
						.utc(updatedAt ?? createdAt)
						.local()
						.fromNow()}
					.
				</p>
			</div>
			<div>
				<ClickHandler rowId={rowId} handleClick={onRequestOpen} />
			</div>
		</DraggableItem>
	);
}

export default createFragmentContainer(BoardsTeaserSearchItem, {
	boardsTeaser: graphql`
		fragment BoardsTeaserSearchItem_boardsTeaser on BoardsTeaser {
			rowId
			title
			status
			image {
				url
			}
			link {
				label
			}
			createdAt
			updatedAt
		}
	`,
});
