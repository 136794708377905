import { Button, Form, Input } from 'antd';
import { type ComponentProps, type FC, type PropsWithChildren, useEffect } from 'react';

import type { RawNavigationItem, UpsertNavigationItemPayload } from '../types';

export interface NavigationEditorFormProps extends Pick<ComponentProps<typeof Form>, 'name'> {
	initialValues: UpsertNavigationItemPayload | null;
	onCancel: () => void;
	onConfirm: (item: UpsertNavigationItemPayload) => void;
	hiddenFields: (keyof RawNavigationItem)[];
}

export const NavigationEditorForm: FC<PropsWithChildren<NavigationEditorFormProps>> = ({
	children,
	onCancel,
	onConfirm,
	initialValues,
	hiddenFields = [],
}) => {
	const [form] = Form.useForm<UpsertNavigationItemPayload>();

	useEffect(() => {
		form.resetFields();
	}, [form, initialValues]);

	return (
		<Form
			preserve={false}
			form={form}
			layout="vertical"
			initialValues={initialValues ?? {}}
			onFinish={onConfirm}
		>
			{hiddenFields.map((fieldname) => (
				<Form.Item key={fieldname} name={fieldname} hidden>
					<Input />
				</Form.Item>
			))}

			{children}

			<div style={{ marginTop: '1rem' }} />
			<hr
				style={{
					position: 'absolute',
					left: 0,
					right: 0,
					opacity: 0.2,
				}}
			/>
			<div
				style={{
					display: 'grid',
					gridAutoFlow: 'column',
					paddingTop: '2rem',
					gap: '1rem',
					justifyContent: 'end',
				}}
			>
				<Button key="back" onClick={onCancel}>
					Abbrechen
				</Button>
				<Button style={{ minWidth: 100 }} htmlType="submit" key="submit" type="primary">
					OK
				</Button>
			</div>
		</Form>
	);
};
