import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
	SECTION_SCHEMA_STAGE_SPLITTED,
	SECTION_SCHEMA_SMALL_3,
	SECTION_SCHEMA_RAISED_LEFT_SIMPLE,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE,
	SECTION_SCHEMA_REGIONAL_4_SIMPLE,
	SECTION_SCHEMA_HIGHLIGHT_3,
	SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_LEFT_1_BIG,
	SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER,
	SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER,
} from '@sep/br24-constants';
import { Button, Modal, Row, Col, Tabs } from 'antd';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import config from '../../../config';
import translations from '../../../translations';

import SectionPickerItem from './SectionPickerItem';

interface Props extends ReduxProps {
	onSelect: (
		schema:
			| typeof SECTION_SCHEMA_STAGE_SPLITTED
			| typeof SECTION_SCHEMA_SMALL_3
			| typeof SECTION_SCHEMA_RAISED_LEFT_SIMPLE
			| typeof SECTION_SCHEMA_RAISED_RIGHT_SIMPLE
			| typeof SECTION_SCHEMA_REGIONAL_4_SIMPLE
			| typeof SECTION_SCHEMA_HIGHLIGHT_3
			| typeof SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL
			| typeof SECTION_SCHEMA_CATEGORY_LEFT_1_BIG
			| typeof SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL
			| typeof SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG
			| typeof SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS
			| typeof SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL
			| typeof SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER
			| typeof SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER,
		order?: number | null
	) => void;
	isSectionPickerVisible: boolean;
	insertAt: number | undefined | null;
	onClickNew: () => void;
	onClickCancel: () => void;
}

type State = {
	activeKey: string;
};

const heroSections = [
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER,
	SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER,
] as const;

const defaultSections = [
	SECTION_SCHEMA_SMALL_3,
	SECTION_SCHEMA_RAISED_LEFT_SIMPLE,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE,
	SECTION_SCHEMA_REGIONAL_4_SIMPLE,
	SECTION_SCHEMA_HIGHLIGHT_3,
	SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_LEFT_1_BIG,
	SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG,
] as const;

const shortnewsSections = [
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL,
] as const;

const TabPanePadding = styled.div`
	padding: 0 30px;
`;

const TabButton = styled(Button)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 15px;
	z-index: 5;
`;

const TabButtonRight = styled(TabButton)`
	left: auto;
	right: 15px;
`;

class SectionPicker extends Component<Props, State> {
	maxTabs: number;

	constructor(props: Props) {
		super(props);
		this.state = {
			activeKey: '1',
		};
		this.maxTabs = 3;
	}

	handleChangeTab = (activeKey) => {
		this.setState({ activeKey });
	};

	handleClickCancel = () => this.props.onClickCancel();

	handleClickNew = () => this.props.onClickNew();

	handleSelect = (value: any) => {
		const { insertAt } = this.props;

		// use cancel function for closing the modal
		this.props.onClickCancel();
		this.props.onSelect(value, insertAt);
	};

	handleSwitchTab = (direction) => {
		let activeKey = Number(this.state.activeKey) + Number(direction);

		// Switch to the first or last tab if activeKey exceeds the maximum tabs or is lower than 1
		if (activeKey > this.maxTabs) {
			activeKey = 1;
		} else if (activeKey < 1) {
			activeKey = this.maxTabs;
		}
		this.setState({
			activeKey: String(activeKey),
		});
	};

	render() {
		const { availableWidth, isSectionPickerVisible } = this.props;

		const tabs = [
			{
				label: translations.sectionPicker.tabSectionshero,
				key: '1',
				children: (
					<TabPanePadding>
						<Row gutter={8}>
							{heroSections.map((section) => (
								<Col key={`sectionPickerItem-${section}`} span={availableWidth >= 1200 ? 4 : 8}>
									<SectionPickerItem value={section} onClick={this.handleSelect} />
								</Col>
							))}
						</Row>
					</TabPanePadding>
				),
			},
			{
				label: translations.sectionPicker.tabSectionsStandard,
				key: '2',
				children: (
					<TabPanePadding>
						<Row gutter={8}>
							{defaultSections.map((section) => (
								<Col key={`sectionPickerItem-${section}`} span={availableWidth >= 1200 ? 4 : 8}>
									<SectionPickerItem value={section} onClick={this.handleSelect} />
								</Col>
							))}
						</Row>
					</TabPanePadding>
				),
			},
			{
				label: translations.sectionPicker.tabSectionsShortnews,
				key: '3',
				children: (
					<TabPanePadding>
						<Row gutter={8}>
							{shortnewsSections.map((section) => (
								<Col key={`sectionPickerItem-${section}`} span={availableWidth >= 1200 ? 4 : 8}>
									<SectionPickerItem value={section} onClick={this.handleSelect} />
								</Col>
							))}
						</Row>
					</TabPanePadding>
				),
			},
		];

		return (
			<div>
				<Button type="primary" onClick={this.handleClickNew}>
					{translations.sectionPicker.modalTitle}
				</Button>
				<Modal
					title={translations.sectionPicker.modalTitle}
					open={isSectionPickerVisible}
					footer={null}
					width={availableWidth * 0.8}
					onCancel={this.handleClickCancel}
				>
					<TabButton
						size="small"
						shape="circle"
						icon={<LeftOutlined />}
						onClick={() => this.handleSwitchTab(-1)}
					/>
					<TabButtonRight
						size="small"
						shape="circle"
						icon={<RightOutlined />}
						onClick={() => this.handleSwitchTab(+1)}
					/>

					<Tabs activeKey={this.state.activeKey} onChange={this.handleChangeTab} items={tabs} />
				</Modal>
			</div>
		);
	}
}

const connector = connect(({ app }: ReduxState) => ({
	availableWidth: app.currentBreakPoint
		? Math.round(config.breakPointsMinAvailableSize[app.currentBreakPoint])
		: 1000,
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SectionPicker);
