import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { createPaginationContainer, graphql } from 'react-relay';
import type { RelayPaginationProp } from 'react-relay';

import { withLock } from '@/client/hooks/useLock';
import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import BoardsTable from './BoardsTable';
import type { BoardsTableContainer_query$data } from './__generated__/BoardsTableContainer_query.graphql';

type Props = {
	query: BoardsTableContainer_query$data;
	relay: RelayPaginationProp;
};

export type BoardsTableItem = NonNullable<
	NonNullable<BoardsTableContainer_query$data['boards']>['edges'][number]['node']
>;

class BoardsTableContainer extends Component<Props, any> {
	componentDidMount() {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.props.relay.refetchConnection(50, () => null, this.props.query);
	}

	handleLoadMore = () => {
		this.props.relay.loadMore(BoardsTableContainer.LOAD_MORE_NUM);
	};

	static LOAD_MORE_NUM = 8;

	render() {
		const { relay, query } = this.props;

		const categoryNodes = query?.allCategories?.edges || [];
		const categories = categoryNodes
			.map((node) => node?.node)
			.map((category) => (category ? `/${category.rowId}` : ''));
		const boardNodes = query?.boards?.edges || [];
		const boards = boardNodes
			.map((node) => node?.node)
			.filter((board): board is BoardsTableItem => !!board);

		if (!boards) {
			return null;
		}

		return (
			<InfiniteScroll hasMore={relay.hasMore()} loadMore={this.handleLoadMore} useWindow={false}>
				<BoardsTable boards={boards} categories={categories} />
			</InfiniteScroll>
		);
	}
}

const BoardsTablePaginationContainer = createPaginationContainer(
	connect((state: ReduxState) => ({
		currentBreakpoint: state.app && state.app.isInitialized ? state.app.currentBreakPoint : null,
	}))(withLock(BoardsTableContainer, { entityType: 'Board' })),
	{
		query: graphql`
			fragment BoardsTableContainer_query on Query {
				allCategories(condition: { active: true }) {
					edges {
						node {
							rowId
						}
					}
				}
				boards: filterBoards(
					statusFilter: $statusFilter
					searchTerm: $searchTerm
					first: $count
					after: $cursor
				) @connection(key: "BoardsTableContainer_boards") {
					totalCount
					pageInfo {
						hasNextPage
						startCursor
						endCursor
					}
					edges {
						node {
							createdAt
							fullSlug
							slug
							protected
							rowId
							status
							title
							updatedAt
							...LockedByAuthor_board
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps(props: any) {
			return props.query && props.query.boards;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			};
		},
		getVariables(_, { count, cursor }, fragmentVariables) {
			return {
				...fragmentVariables,
				count,
				cursor,
			};
		},
		query: graphql`
			query BoardsTableContainerQuery(
				$searchTerm: String
				$statusFilter: [Status]
				$count: Int!
				$cursor: Cursor
			) {
				...BoardsTableContainer_query
			}
		`,
	}
);

export default BoardsTablePaginationContainer;
