import { Component, type ReactElement, type ReactNode } from 'react';

interface State {
	error: Error | null;
	fetchKey: number;
}

interface Props {
	children?: ReactNode | ((props: { fetchKey: number }) => ReactElement);
	fallback?: (props: { error: Error | null; retry: () => void }) => ReactNode;
}

export class ErrorBoundaryWithRetry extends Component<Props, State> {
	state = { error: null, fetchKey: 0 };

	static getDerivedStateFromError(error: Error | null): State {
		return { error: error, fetchKey: 0 };
	}

	private retry() {
		this.setState((prev) => ({
			// Clear the error
			error: null,
			// Increment and set a new fetchKey in order
			// to trigger a re-evaluation and refetching
			// of the query using useLazyLoadQuery
			fetchKey: prev.fetchKey + 1,
		}));
	}

	render() {
		const { children, fallback } = this.props;
		const { error, fetchKey } = this.state;

		if (error) {
			if (typeof fallback === 'function') {
				return fallback({ error, retry: this.retry });
			}
			return fallback;
		}

		return typeof children === 'function' ? children({ fetchKey }) : <>{children}</>;
	}
}
