export default function determineTitleTemplate(branch?: string | null): string {
	if (!branch || branch === 'dev') {
		return '%s | DEV | BR24 Newsroom';
	}
	if (branch === 'staging') {
		return '%s | QS | BR24 Newsroom';
	}
	if (branch === 'training') {
		return '%s | Training | BR24 Newsroom';
	}
	return '%s | BR24 Newsroom';
}
