import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import config from '../../../config';
import type { WithTranslationProps } from '../../../translation';
import { ModulePartInput } from '../ArticleModuleParts';

const TITLE_LENGTH_MAX = config.articleModules.header.titleMaxLength;
const TEASERTEXT_LENGTH_MAX = config.articleModules.header.teasertextMaxLength;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const TitleInput = styled(ModulePartInput)`
	margin-bottom: 1rem !important;
	.input {
		font-size: 2rem !important;
		font-weight: 200 !important;

		&::placeholder {
			font-size: 2rem !important;
			font-weight: 200 !important;
		}
	}
`;

const TeaserTextInput = styled(ModulePartInput)`
	.input {
		font-size: 1.3rem !important;
		&::placeholder {
			font-size: 1.3rem !important;
		}
	}
`;

type Props = WithTranslationProps & {
	onChangeTeaserText: (teaserText: string) => void;
	onChangeTitle: (title: string) => void;
	teaserText: string;
	title: string;
	validationErrorTeaser: string | string[] | null;
	validationErrorTitle: string | string[] | null;
};

class ArticleModuleHeader extends Component<Props> {
	handleChangeTeaserText = (value: string) => {
		this.props.onChangeTeaserText(value);
	};

	handleChangeTitle = (value: string) => {
		this.props.onChangeTitle(value);
	};

	render() {
		const {
			teaserText,
			title,
			translation: { translate },
			validationErrorTeaser,
			validationErrorTitle,
		} = this.props;

		const titleHelp =
			title && title.length > TITLE_LENGTH_MAX
				? translate('modules.header.titleHelp', {
						count: title ? `${title.length}` : 0,
						max: TITLE_LENGTH_MAX,
				  })
				: null;

		const teaserTextHelp = translate('modules.header.teasertextHelp', {
			count: teaserText ? `${teaserText.length}` : 0,
			max: TEASERTEXT_LENGTH_MAX,
		});

		return (
			<Wrapper>
				<TitleInput
					help={titleHelp}
					onChange={this.handleChangeTitle}
					placeholder={translate('modules.header.titlePlaceholder')}
					validationError={validationErrorTitle}
					value={title}
				/>
				<TeaserTextInput
					help={teaserTextHelp}
					isTextArea={true}
					placeholder={translate('modules.header.teasertextPlaceholder')}
					value={teaserText}
					onChange={this.handleChangeTeaserText}
					validationError={validationErrorTeaser}
				/>
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleModuleHeader);
