import * as constants from '@sep/br24-constants';

import * as internalConstants from '../../constants';

export default {
	sayHello: 'Hello {{name}}, you have {{quantity}} new material!',
	sayHello_plural: 'Hello {{name}}, you have {{quantity}} new materials!',

	// locales
	german: 'German',
	english: 'English',
	bavarian: 'Bavarian',

	// navigation items
	navigation: {
		dashboard: 'Dashboard',
		articles: 'Articles',
		boards: 'Boards',
		authors: 'Authors',
		navigation: 'Navigation',
		comments: 'Comments',
		profile: 'Profile',
		settings: 'Settings',
		signOut: 'Sign out',
	},

	// modules
	modules: {
		remove: 'Bist Du Dir sicher, dass Du dieses Element löschen möchtest?',
		creator: {
			textPlaceholder: 'Start writing...',
		},
		header: {
			titlePlaceholder: 'Add title...',
			titleHelp: '{{count}}/{{max}} characters',
			teasertextPlaceholder: 'Teasertext',
			teasertextHelp: '{{count}}/{{max}} characters',
		},
		[constants.MODULE_TYPE_IMAGE]: {
			text: 'Image',
			titleLabel: 'Description',
			titlePlaceholder: 'Enter description',
			titleHelp: '{{count}}/{{max}} characters',
			altTextLabel: 'Alternative text',
			altTextPlaceholder: 'Enter alternative text',
			copyrightLabel: 'Copyright',
			copyrightPlaceholder: 'Enter copyright',
			editCropButtonLabel: 'Edit crop',
			noRectWarning:
				'This image does not contain a proper crop and will therefore displayed in full resolution. Please select a crop!',
			saveRectButtonLabel: 'Save',
			abortRectButtonLabel: 'Abort',
			dropzoneText: 'Drag a image onto this area.',
			selectDownloadLabel: 'Selection for download',
			downloadCroppedImage: 'Cropped image',
			downloadOriginal: 'Original image',
			downloadNewsletter: 'Newsletter (700 x 394px)',
			downloadDone: 'Done',
		},
		[constants.MODULE_TYPE_GALLERY]: {
			text: 'Gallery',
			dropzoneText: 'Drag a collection of pictures onto this area.',
		},
		[constants.MODULE_TYPE_TEXT]: {
			text: 'Text',
			textPlaceholder: 'Enter text',
			linkEditorPlaceholder: 'Enter link',
		},
		[constants.MODULE_TYPE_EMBED]: {
			text: 'Embed',
			serviceLabel: 'Platform',
			servicePlaceholder: 'will be set after pasting code',
			altTextLabel: 'Alternative text',
			altTextPlaceholder: 'Enter alternative text',
			previewButtonText: 'Load preview',
			editEmbedText: 'Edit',
		},
		[constants.MODULE_TYPE_AUDIO]: {
			text: 'Audio',
			titleLabel: 'Teaser',
			titlePlaceholder: 'Teaser text',
			titleHelp: '{{count}}/{{max}} characters',
			copyrightLabel: 'Copyright',
			copyrightPlaceholder: 'Copyright text',
			copyrightHelp: '{{count}}/{{max}} characters',
			dropzoneText: 'Drag an audio onto this area.',
		},
		[constants.MODULE_TYPE_VIDEO]: {
			text: 'Video',
			titleLabel: 'Description',
			titlePlaceholder: 'Enter description',
			titleHelp: '{{count}}/{{max}} characters',
			copyrightLabel: 'Copyright',
			copyrightPlaceholder: 'Enter copyright',
			loadVideoButtonLabel: 'Load video',
			dropzoneText: 'Drag a video onto this area.',
		},
		[constants.MODULE_TYPE_LIVESTREAM]: {
			text: 'Livestream',
			titleLabel: 'Title',
			startTimeLabel: 'Start',
			endTimeLabel: 'End',
			dropzoneText: 'Drag a livestream onto this area.',
			livestreamNotAvailable:
				'The inserted livestream is not available anymore. It will not be displayed further here.',
		},
		[constants.MODULE_TYPE_VIDEO360]: {
			text: '360° Video ( Deprecated )',
		},
		mixedDropzone: {
			defaultPlaceholder: 'Drag a video, audio or images onto this area.',
			errorNotAuthenticatedHeadline: 'You are not logged in.',
			errorNotAuthenticatedDesc: 'Please login to the CMS again.',
			labelFilesAreUploaded: 'Files are uploaded.',
			labelErrorOnUpload: 'An error occured while uploading.',
			dropIsNotAllowedPlaceholder: 'The module could not be dropped here.',
		},
		attachedDropzone: {
			title: 'Drop the module on this field.',
		},
		brokenModule: {
			title: 'Broken module',
			text: 'This module is broken. Please delete it by hand.',
		},
	},

	// dashboard stuff
	dashboard: {
		title: 'Dashboard',
		statistics: 'Stats',
		thisMonth: 'This Month',
		lastMonth: 'Last Month',
		aYearAgo: 'A year ago ({{info}})',
		noDataSelectedDateRange: 'No data could be found for the selected range.',
		sourceOriginReferenceStats: 'Articles with Source-Origins',
		sourceOriginReferenceStatsHelp: 'Coming soon.',
		withSourceOrigin: 'With origin programme reference',
		noSourceOrigin: 'No origin programme',
		myLatestArticles: 'My latest articles',
		moduleAllocationStats: 'Multimediaratio',
		moduleAllocationStatsHelp: 'Ratio of multimedia articles for all articles',
		latestPushNotifications: 'Recent Push-Notifications',
		latestPublication: 'Recently Published',
		categoryArticleStats: 'Article per Category',
		categoryArticleStatsHelp: 'Number of articles per category.',
		regionsArticleStats: 'Articles per region',
		regionsArticleStatsHelp: 'Number of articles per region',
		filter: {
			placeholder: 'Select filter',
			dropdownLabelRegions: 'Regions',
			dropwdownLabelCategory: 'Category',
			resetFilterText: 'Reset filter',
			headlineTotal: 'Total',
			errorText: 'Error occured.',
		},
		push: {
			withSound: 'Push with sound',
			withoutSound: 'Push without sound',
			tagPush: 'Tag push',
		},
	},

	board: {
		search: {
			emptySearch: 'No entries found!',
		},
		sectionItem: {
			article: {
				published: 'Published ',
			},
			board: {
				updated: 'Updated ',
			},
			boardsTeaser: {
				updated: 'Updated ',
				errorDescription: 'The teaser {{title}} could not be loaded.',
			},
		},
		publicationStatusPicker: {
			publish: 'Publish',
			changeToDraft: 'Change to draft',
			depublish: 'Depublish',
		},
		saveButton: {
			save: 'Save',
			locked: 'No changes to the article made.',
		},
	},

	boardsTeaser: {
		pageTitle: 'Editing teaser {{title}}',
		dropDownMenu: {
			save: 'Save',
			delete: 'Delete teaser',
		},
		formItemHelp: 'Characters',
		action: {
			save: {
				auto: {
					message: 'Automatically saved',
					description: 'The teaser {{title}} was automatically saved.',
				},
				manual: {
					message: 'Successfully saved',
					description: 'The teaser {{title}} was successfully saved.',
				},
				error: {
					message: 'Error when saving',
					description: 'Your changes could not be saved.',
				},
			},
			deleteTeaser: {
				modalTitle: 'Delete teaser ',
				confirmDelete: 'Do you really want to delete this teaser?',
				usedTeaser: {
					problem: 'The teaser is currently used on a board und can therefore not be deleted.',
					description:
						'If you still want to delete this Teaser, remove it from all boards listed below',
				},
			},
			searchPlaceholder: 'Teaser-Search...',
			createTeaser: 'Create Teaser',
		},
		image: {
			title: 'Image',
			upload: {
				error: {
					message: 'error',
					description: 'The file could not be uploaded!',
				},
				upload: 'Upload image',
				replace: 'Replace image',
			},
			confirmDelete: 'Do you really want to delete this image?',
		},
		table: {
			title: 'Title',
			description: 'Description',
			acitve: 'Active',
			lastAuthor: 'Last edited by',
			lastUpdate: 'Last edited',
			noEntry: 'There was no match for your search!',
		},
	},

	// article editor
	article: {
		authErrorLogout:
			'Sie wurden leider ausgeloggt. Bitte öffnen Sie <a href="/logout" target="_blank">den Login</a> in einem neuen Tab, loggen Sie sich ein und drücken Sie dann in diesem Tab auf speichern. <code>{{error}}</code>',
		meta: {
			title: 'Meta',
			author: {
				title: 'Authors',
				help: 'Search for involved BR authors; Please be aware that only active profiles are display in search results. Creation of new authors are possible via <a target="_blank" href="/author">Autoren</a>.',
				searchPlaceholder: 'Search a person...',
			},
			seo: {
				title: 'SEO - Infos for search engines',
				help: '<a target="_blank" href="/faq#seo-informationen-fuer-artikel-editor">Infos</a> to SEO conditions. Without entry the teaser text will be picked.',
				titlePlaceholder: 'SEO-Title (optional)',
				descPlaceholder: 'Meta Description',
				characterCountText: '{{currentCount}}/{{characterMaxLength}} characters',
				slugCountText: '{{currentCount}}/{{characterMaxLength}} characters',
				slugPlaceholder: 'Enter URL',
				canonicalUrlPlaceholder: 'Enter canonical URL',
				seoTitleEditTooltip: 'Change SEO title',
			},
			category: {
				title: 'Category',
				help: 'Selection of position and color of the content.',
				searchPlaceholder: 'Pick a category',
			},
			sourceOrigin: {
				title: 'Reference',
				help: 'Enter always a source origin with date and time. Currently it is possible to select on entry only.',
				datePlaceholder: 'Date',
				timePlaceholder: 'Time',
				inputPlaceholder: 'Search for show',
				inputButton: 'Add',
			},
			links: {
				title: 'Links',
				help: 'Possible entries are URLs to BR24 content or external pages. Select good link titles e. g. B5 aktuell live hören. First link is visible in Article teaser',
				titlePlaceholder: 'Linktext',
				linkPlaceholder: 'URL',
				externalLinkLabel: 'open externally',
				addButtonTitle: 'Add',
				characterCountText: '{{currentCount}}/{{titleMaxLength}} characters',
			},
			teasers: {
				title: 'Links',
				help: 'Possible entries are URLs to BR24 content or external pages. Select good link titles e. g. B5 aktuell live hören. First link is visible in Article teaser',
				searchPlaceholder: 'Article search',
				searchButtonLabel: 'Search',
				addButtonLabel: 'Add link',
				linkPlaceholder: 'URL',
				save: 'Save',
				articleNotFoundError: 'Could not find article with this ID',
				boardNotFoundError: 'Could not find board with this ID',
				couldNotFetchMetaError: 'Could not load data',
				invalidUrlError: 'Incomplete or invalid URL.',
				alreadyExistsError: 'URLs can only be added once.',
				isNotWhitelisted: 'URLs from {{hostname}} are not allowed.',
				whiteListedURLS:
					'Following domains are allowed: br.de, tagesschau.de, sportschau.de, bayern3.de, br-klassik.de, br24.de, ard.de, ardalpha.de, ardmediathek.de, ardaudiothek.de',
				info: {
					invalid: 'The linked website is not accessible. Please remove or revise this entry.',
					depublished: 'This BR24 article is depublished and is not displayed in Web/App.',
				},
			},
			tags: {
				title: 'Tags',
				help: 'In best case enter 2 to 4 tags. Selection analog <a target="_blank" href="/faq#google-suchverhalten">Google-Suchverhalten</a>. Set spaces and watch out for large and lower case.',
				placeholder: 'Add a tag',
				button: 'Add',
				inputHelp: '{{count}}/{{max}} characters',
				addButtonLabel: 'Add',
			},
			geolocation: {
				title: 'Geolocation / Districts',
				help: 'Select matching county and language. If city info is available, enter with a editorial perspective. The default language is german.',
				districtPlaceholder: 'Choose a district',
			},
			more: {
				title: 'More',
				labels: {
					live: 'LIVE-Reporting',
					allowComments: 'Comments are allowed',
					enableAmp: 'AMP',
					hasMigration: 'Migration',
				},
				sophoraSearch: {
					searchPlaceholder: 'Enter Sophora-URL',
					help: 'Articles which are connected to Sophora will be redirected from br.de/nachrichten to the BR24 website. Important note: All sophora articles must be dupublicated after the migration manually.',
					connectedArticleText: 'Connected with Sophora ID:',
				},
			},
			notes: {
				title: 'Notes',
				help: 'Internal notes and comments for the article. These are visible for editors in the CMS only.',
				characterCountText: '{{currentCount}}/{{characterMaxLength}} characters',
			},
		},
		action: {
			button: {
				text: {
					[constants.STATUS_DELETED]: 'Deleted',
					[constants.STATUS_DEPUBLISHED]: 'Depublished',
					[constants.STATUS_DRAFT]: 'Draft',
					[constants.STATUS_PUBLISHED]: 'Published',
					[constants.STATUS_REVIEW]: 'in Review',
					[constants.STATUS_SCHEDULED]: 'Scheduled',
					template: 'Template',
				},
				menu: {
					[constants.STATUS_DELETED]: 'to Delete',
					[constants.STATUS_DEPUBLISHED]: 'to Depublish',
					[constants.STATUS_DRAFT]: 'to Draft',
					[constants.STATUS_PUBLISHED]: 'to Publish',
					[constants.STATUS_REVIEW]: 'to Review',
					[constants.STATUS_SCHEDULED]: 'to Schedule',
					changePublicationDate: 'change publication date',
					template: 'Create an article',
				},
			},
		},
		historyTimeline: {
			status: {
				[constants.STATUS_DELETED]: 'deleted',
				[constants.STATUS_DEPUBLISHED]: 'depublished',
				[constants.STATUS_DRAFT]: 'changed',
				[constants.STATUS_PUBLISHED]: 'published',
				[constants.STATUS_REVIEW]: 'to review',
				[constants.STATUS_SCHEDULED]: 'scheduled',
				[constants.STATUS_CHANGED]: 'changed',
			},
			moment: {
				format: '[on] DD/MM/YYYY [at] HH:mm',
			},
			history: 'History',
			author: {
				unknown: 'Unknown',
			},
			item: '$t(article.historyTimeline.status.{{status}}) {{date}}',
			itemWithAuthor: '$t(article.historyTimeline.status.{{status}}) by {{author}} {{date}}',
			itemFirstWarning: 'History Begin {{date}} ',
			itemFirst: 'Status: $t(article.historyTimeline.status.{{status}}), Author: {{ author }}',
		},
		publishing: {
			publishing: 'Publishing',
			now: 'Publish now',
			tooltip: 'Adjusted publication date',
			delay: {
				activate: 'Set publication date',
				delay: 'Publication date',
				moment: {
					format: 'DD/MM/YYYY [at] HH:mm',
				},
			},
			error: {
				error: 'Error',
				empty: 'The delayed publishing time can not be empty.',
			},
		},
		depublishing: {
			depublishing: 'Depublishing',
			category: {
				custom: 'Custom depublishing',
				[constants.EXPIRATION_EPG_BUSINESS_CONTENT]:
					'1 - Programme information (EPG) and business content',
				[constants.EXPIRATION_REPEATED_EVENTS]: '2 - Repeated events',
				[constants.EXPIRATION_SUPPORTING_INFO]: '3 - Supporting info for Programme',
				[constants.EXPIRATION_SERIES]: '4 - Content for Series and mini-Series',
				[constants.EXPIRATION_EDUCATION]: '5 - Education content',
				[constants.EXPIRATION_TIME_CULTURE]: '6 - Time and culture',
			},
			error: {
				error: 'Error',
				empty: 'The delayed depublishing time can not be empty.',
			},
		},
		notification: {
			silent: 'with Push',
			tooltip: 'With/without Push-Notification',
		},
		notificationModal: {
			cancel: 'Cancel',
			headline: 'Attention',
			save: 'Send Push Now',
			text: 'Should a (silent) push notification be sent? This will save all unsaved changes made to the article.',
		},
		previewButton: {
			preview: 'Preview',
			toArticle: 'Show Article',
			tooltip: 'Preview/Article',
		},
		saveButton: {
			save: 'Save',
			locked: 'Saving your changes is enabled by another user´s lock',
		},
		title: {
			template: 'Template',
		},
		changePubDateOverlay: {
			headline: 'Adjust publication date',
			description:
				'In case of smaller changes the displayed date could be stay on the current state or a custom publication date could be selected.',
			select: {
				headline: 'Select a time stamp:',
				now: 'now',
				lastDate: 'Set last date',
				custom: 'Set custom date',
			},
			saveButton: 'Save',
			cancelButton: 'Cancel',
		},
	},

	boards: {
		boards: 'Boards',
		newBoard: 'New Board',
		searchPlaceholder: 'Search...',
		table: {
			categories: 'Categories',
			edited: 'Edited',
			active: 'Active',
			emptyText: 'No boards found.',
			filters: {
				ALL: 'All',
				[constants.STATUS_DEPUBLISHED]: 'Depublished',
				[constants.STATUS_DRAFT]: 'Draft',
				[constants.STATUS_PUBLISHED]: 'Published',
			},
			homepage: 'Homepage',
			regions: 'Regions',
			slug: 'Slug',
			status: 'Status',
			topics: 'Topics',
		},
	},

	articles: {
		article: 'Article',
		createArticle: 'Create article',
		notes: 'Notes have been left for this article',
		table: {
			header: {
				type: 'Type',
				headline: 'Headline',
				components: 'Components',
				active: 'Active',
				editor: 'Editor',
				status: 'Status',
				publication: 'Publication',
				edited: 'Edited',
				notes: 'Notes',
			},
			row: {
				createFromTemplate: 'Use as a template',
				editArticle: 'Edit Article',
				republish: 'Re-publish',
			},
		},
		search: {
			articleSearch: 'Articlesearch...',
			emptySearch: 'No articles found.',
			clearFilter: 'Clear all filters',
		},
		copyArticle: {
			error: {
				message: 'Duplicating the article was not successfull. ',
				description: 'The template {{title}} could not be copied.',
			},
			success: {
				message: 'The article was created',
				description: 'The template {{title}} was successfully copied',
			},
		},
		selection: {
			story: 'Combine to a story',
			reset: 'Clear selection',
			successMessage: 'The following articles were combined: {{articles}}.',
			errorMessage: 'The articles could not be combined',
		},
	},
	lockWarnings: {
		backToOverviewButton: {
			title: 'zur Übersicht',
			tooltip: 'zur Übersicht',
		},
		openReadOnlyButton: {
			title: 'Dokument lesend öffnen',
			tooltip: 'Dokument lesend öffnen',
		},
		lockTakeoverButton: {
			title: 'Sperre brechen',
			tooltip: 'Sperre brechen',
		},
		setLockButton: {
			title: 'Sperre setzen',
			tooltip: 'Sperre setzen',
		},
		reloadDocumentButton: {
			title: 'Revert Changes',
			tooltip: 'Reloads the document',
		},
		openNewTabButton: {
			title: 'Open in a new Tab',
			tooltip: 'Opens the original article in a new tab.',
		},
		updatedWhileLockDialog: {
			title: 'Sperre des Dokuments wurde zwischenzeitlich entfernt',
			text: 'Die Sperre für das aktuelle Dokument wurde aufgehoben. Das Dokument ist nun wieder zur Bearbeitung verfügbar.',
		},
		brokenLockDialog: {
			title: 'Achtung! Sperrung dieses Dokuments wurde gebrochen',
			textPiece1: 'Deine Sperre wurde am ',
			textPiece2: ' von ',
			textPiece3: ' durchbrochen. Du kannst deine Änderungen im Lesemodus sichern.',
		},
		saveErrorLockDialog: {
			title: 'Someone saved the document while you were working.',
			text: 'The document lock has been broken. On {{date}} {{name}} saved the document. You can continue in Readonly mode and use the asset manager to save your work.',
		},
		contentLockDialog: {
			title: 'Dokument durch anderen Newsroom-Nutzer gesperrt',
			textPiece1: 'Dieses Dokument wird bereits seit ',
			textPiece2: ' von ',
			textPiece3:
				' bearbeitet. Bitte beachte, dass Du nur Änderungen vornehmen kannst, wenn Du die Sperre brichst.',
		},
		readOnlyBar: {
			text: 'Dieses Dokument wurde nur zum Lesen geöffnet, Änderungen daran können NICHT gespeichert werden.',
		},
	},
	capabilities: {
		[constants.CAPABILITY_FULLY_FEATURED]: 'Article',
		[constants.CAPABILITY_BASIC]: 'Article',
		[constants.CAPABILITY_TEXT_ONLY]: 'Breaking News',
	},

	priorities: {
		[constants.PRIORITY_NORMAL]: 'Standard',
		[constants.PRIORITY_HIGH]: 'High',
		[constants.PRIORITY_CRITICAL]: 'Critical',
	},

	modals: {
		articlePublishNow: {
			title: 'Publish now',
			content: 'Are you sure that you want to publish this article right now?',
			ok: 'Publish',
			cancel: 'Cancel',
		},
		articleDelete: {
			title: 'Delete an article',
			content: 'This action is permanent and deleted articles are not available anymore.',
			ok: 'Delete',
			cancel: 'Cancel',
		},
		notificationActivated: {
			title: 'Push-Notification',
			content: 'A Push-Notification will be sent right after publishing this article.',
			ok: 'Publish',
			cancel: 'Cancel',
		},
		articleDepublishConnected: {
			title: 'Depublishing is not available',
			content:
				'This content is connected to one or more sites and therefore cannot be depublished. Please remove this content manually from sites first.',
			ok: 'Ok',
		},
		articleCreateFromTemplateSuccess: {
			title: 'Article was successfuly created',
			content: 'The template was successfuly copied.',
		},
		articleCreateFromTemplateError: {
			title: 'The template could not be copied.',
		},
		articleNotes: {
			title: 'Article notes are present',
			content:
				'Notes have been left for this article. Are you sure you want to save the article anyway?',
			ok: 'Ok',
			cancel: 'Cancel',
		},
	},

	map: {
		searchPlaceholder: 'Search a place',
		searchError: "Place couldn't be found. Please choose a place from the list.",
		title: 'Current location',
	},

	locations: {
		[constants.LOCATION_DISTRICT]: 'District',
		[constants.LOCATION_ZIPCODE]: 'Zip-code',
		[constants.LOCATION_STREET]: 'Street',
		[constants.LOCATION_STREETNUMBER]: 'Street number',
		[constants.LOCATION_COUNTRY]: 'Country',
		[constants.LOCATION_STATE]: 'State',
		[constants.LOCATION_CITY]: 'City',
		[constants.LOCATION_SUBURB]: 'Suburb',
	},

	validation: {
		errors: {
			article: {
				title:
					'For the headline minimum are {{minLength}} characters and maximum {{maxLength}} characters.',
				seoTitle:
					'For the SEO-Title minimum are {{minLength}} characters and maximum {{maxLength}} characters.',
				teaserText:
					'For the teaser text minimum {{minLength}} characters and maximum {{maxLength}} characters are allowed.',
				modules: 'An article must contain at least one module.',
				author: 'At least 1 and max. 5 author needs to be added.',
				seo: {
					title: 'SEO title only {{maxLength}} characters maximum allowed',
					slug: 'For the url minimum are {{minLength}} characters and maximum {{maxLength}} characters allowed. Further only numbers, letters and hyphen are allowed.',
					metaDescription:
						'For the meta description are {{minLength}} characters and maximum {{maxLength}} characters allowed.',
				},
				notes: 'For notes a maximum of {{maxLength}} characters are allowed',
				links: {
					label: 'A link is preventing save and must be edited or removed.',
					url: 'Links must be in the format "http(s)://www.<url>.<domain>".',
				},
				teasers: 'URLs have to have the format "http(s)://www.<url>.<domain>".',
				geolocation: 'Geolocation has wrong format. Please select one location from the dropdown.',
			},
			modules: {
				audio: {
					copyright: 'The copyright for an image must be filled.',
					title:
						'For the teaser text minimum {{minLength}} characters and maximum {{maxLength}} characters are allowed.',
					url: 'An audio must be provided.',
				},
				image: {
					copyright: 'The copyright for an image must be filled.',
					title:
						'For the image description minimum {{minLength}} characters and maximum {{maxLength}} characters are allowed.',
					url: 'An image must be provided and a cropping of 16:9 must be selected.',
					altText: 'An Alt-Text must provided.',
				},
				gallery: {
					isOnFirstPosition: 'The gallery module is not allowed on first postion. Please move it.',
				},
				thumbnail: {
					copyright: 'The copyright for an image must be filled.',
					title:
						'For the image description minimum {{minLength}} characters and maximum {{maxLength}} characters are allowed.',
					url: 'An image must be provided and a cropping of 16:9 must be selected.',
					altText: 'An Alt-Text must provided.',
				},
				video: {
					url: 'A video must be provided.',
					title:
						'For the video description minimum {{minLength}} characters and maximum {{maxLength}} characters are allowed.',
					copyright: 'The copyright for an video must be filled.',
				},
				text: {
					isEmpty: 'A text must be provided.',
					isOnFirstPosition:
						'The text module is not allowed on first postion. Please move it somehwere beyond first position.',
				},
				video360: {
					sources: 'validation.errors.modules.video360.sources',
					url: 'validation.errors.modules.video360.url',
				},
				livestream: 'A livestream must be provided.',
				embed: {
					isOnFirstPosition:
						'The embed module is not allowed on first postion. Please move it somehwere beyond first position.',
					noService: 'There is at least one service necessary.',
					noAltText: 'An Alt-Text must provided.',
					noValidation: 'The pasted Embed-Code could not be validated correctly.',
					noEmbedInsert: 'An Embed-Code must be provided.',
					noPreviewLoaded: 'The preview of the embed must be loaded.',
					embedCodeValidationFailed: 'Validation failed. Please check code and try again.',
				},
			},
		},
	},

	// asset manager
	assetManager: {
		connecting: 'Connecting to the Asset Manager…',
		emptyText: 'Drag a module from an article here to store it.',
		importer: {
			[internalConstants.AM_IMPORTER_SOPHORA]: {
				title: 'Sophora',
				inputPlaceholder: 'Fill in Sophora-ID or br.de URL...',
				empty: 'Enter a Sophora ID in the search field to view content.',
				loading: 'Sophora-Inhalte werden gesucht...',
			},
			[internalConstants.AM_IMPORTER_SOPHORA_ARTICLE]: {
				title: 'Sophora',
				inputPlaceholder: 'Fill in Sophora-ID or br.de URL...',
				empty: 'Enter a Sophora ID in the search field to view content.',
				loading: 'Sophora-Inhalte werden gesucht...',
			},
			[internalConstants.AM_IMPORTER_LIVESTREAMS]: {
				title: 'Live streams',
				inputPlaceholder: 'Livestream search term...',
				empty: 'Unfortunately there are currently no live streams available.',
				loading: 'Loading live streams...',
			},
			[internalConstants.AM_IMPORTER_NEWSROOM]: {
				title: 'Newsroom',
				inputPlaceholder: 'Newsroom search term...',
				empty: 'Unfortunately, no newsroom content is available for this search.',
				loading: 'Newsroom content is loading...',
			},
			[internalConstants.AM_IMPORTER_BRBILD]: {
				title: 'BR Bild',
				inputPlaceholder: 'BR Bild searchterm...',
				selectImageTypePlaceholder: 'Select image type...',
				selectRightsOwnerPlaceholder: 'Select rights issuer...',
				empty: 'Enter a searchterm to find images.',
				loading: 'BR Bild content is loading...',
			},
		},
		dataTakeoverModal: {
			headline: 'Transfer data',
			confirmText: 'Would you like to transfer the following data directly to the article?',
			confirmOk: 'Transfer',
			confirmCancel: 'Cancel',
			headlineContent: 'Dachzeile + Überschrift:',
			headlineTeaserText: 'Teaser text:',
			other: 'Meta Description (SEO)',
			warningTable: 'The imported article contains a table that will not be imported into newsroom',
		},
		dataTakeoverModalNew: {
			headline: 'Transfer data',
			confirmText: 'Would you like to transfer the following data directly to the article?',
			confirmOk: 'Transfer',
			confirmCancel: 'Cancel',
			headlineContent: 'Title',
			headlineTeaserText: 'Teaser text',
			other: 'Meta Description (SEO)',
			canonicalUrl: 'Canonical URL (SEO)',
			warningTable: 'The imported article contains a table that will not be imported into newsroom',
			warningOverwrite: 'The following fields will be replaced:',
			metaDescription: 'Meta Description (SEO)',
			modules: 'The modules of the imported article will be added to the end of the article',
		},
		item: {
			invalid: {
				title: 'This element no longer exists...',
				description:
					'There may be several reasons for this. You can find more information under FAQ.',
			},
			loading: {
				title: 'This element is loading...',
				description: 'One moment please!',
			},
			[internalConstants.AM_TYPE_IMAGE]: {
				noTitle: 'Title is missing',
				noCopyright: 'Copyright is missing',
			},
		},
		space: {
			[internalConstants.AM_GLOBAL_SPACE]: 'Public',
			[internalConstants.AM_PERSONAL_SPACE]: 'Private',
		},
		groups: {
			search: 'Search',
			add: 'Add group',
			save: 'Save group',
			lock: 'Lock',
			unlock: 'Unlock',
			edit: 'Edit',
			delete: 'Delete',
			info: 'Info',
			infoItems: 'Element(s)',
			infoCreatedBy: 'Created by',
			infoCreatedAt: 'Created',
			infoLocked: 'This group will not be|cleared automatically!',
			infoNotLocked: 'This group will be cleared|every day at 1:00 am!',
			infoModified: 'Latest change',
			creatorHead: 'Add new group',
			creatorPlaceholder: 'Group name',
			emptyTitleError: 'Group name cannot be empty',
			userGenerated: 'User generated group',
		},
		trash: {
			deleteAll: 'Delete all',
			deleteSelected: 'Delete selected',
			tip: 'Drag here',
		},
		filter: {
			[internalConstants.AM_SORT_DIRECTION_RECENT]: 'Fresh',
			[internalConstants.AM_SORT_DIRECTION_OLDEST]: 'Old',
			[internalConstants.AM_SORT_DIRECTION_ALPHABETICAL_ASC]: 'A-Z',
			[internalConstants.AM_SORT_DIRECTION_ALPHABETICAL_DESC]: 'Z-A',
		},
		type: {
			confirm: {
				ok: 'OK',
				reset: 'Reset',
			},
			[internalConstants.AM_TYPE_BOARD_TEASER]: 'Teaser',
			[internalConstants.AM_TYPE_BOARD]: 'Board',
			[internalConstants.AM_TYPE_ARTICLE]: 'Article',
			[internalConstants.AM_TYPE_TEXT]: 'Text',
			[internalConstants.AM_TYPE_LINK]: 'Link',
			[internalConstants.AM_TYPE_EMBED_CODE]: 'Embed-Code',
			[internalConstants.AM_TYPE_GALLERY]: 'Gallery',
			[internalConstants.AM_TYPE_IMAGE]: 'Image',
			[internalConstants.AM_TYPE_AUDIO]: 'Audio',
			[internalConstants.AM_TYPE_VIDEO360]: 'Video 360°',
			[internalConstants.AM_TYPE_VIDEO]: 'Video',
			[internalConstants.AM_TYPE_LIVESTREAM]: 'Livestream',
		},
		AssetManagerButton: {
			toolTip: 'Toggle Asset Manager',
		},
		ImporterButton: {
			toolTip: 'Toggle Importer',
		},
		status: {
			depublished: 'Depublished',
			draft: 'Draft',
			published: 'Published',
			publishedAt: 'Published at {{date}}',
		},
		pagination: {
			query: 'search query',
			queries: 'search queries',
			result: 'result',
			results: 'results',
			page: 'Seite',
		},
	},

	richTextEditor: {
		inlineLinkEditor: {
			title: 'Edit link',
			deleteLink: 'Delete link',
			cancel: 'Cancel',
			ok: 'Ok',
			linkTextLabel: 'Link text',
			emptyLinkTextError: 'Please enter the link text',
			linkURLLabel: 'URL',
			linkURLError: 'Please enter a valid URL',
		},
	},

	// general stuff
	featureInWorkTitle: 'Wir arbeiten hart...',
	featureInWorkDescription:
		'Um Dir Deine Arbeit zu erleichtern. Leider ist diese Funktionalität noch nicht ausgereift.',
	noData: 'No data.',
	error: 'Error',
	withSound: 'With Sound',
	withoutSound: 'Without Sound',
	load: 'Load...',
	yes: 'Yes',
	no: 'No',
	success: 'Success',
	fatalError: {
		title: 'Es ist ein unerwarteter Fehler aufgetreten!',
		description:
			'Sollten Sie diesen Fehler nicht zum ersten Mal sehen, wenden Sie sich bitte an den Support. <br><br>Original Fehlermeldung: {{message}}',
	},
	saveArticle: {
		error: {
			[constants.STATUS_DRAFT]: {
				title: 'Error while saving',
				description: 'The draft could not be saved.',
			},
			[constants.STATUS_REVIEW]: {
				title: 'Review not possible!',
				description: 'The article could not be forwarded to review. Please check the red marks.',
			},
			[constants.STATUS_PUBLISHED]: {
				title: 'Publishing not possible!',
				description: 'The article could not be published. Please check the red marks.',
			},
			[constants.STATUS_SCHEDULED]: {
				title: 'Timed publishing not possible!',
				description: 'The article could not be published on time. Please check the red marks.',
			},
			[constants.STATUS_DEPUBLISHED]: {
				title: 'Depublication not possible!',
				description: 'The article could not be depublished. Please check the red marks.',
			},
			[constants.STATUS_DELETED]: {
				title: 'Delete is not possible!',
				description: 'The article could not be deleted.',
			},
			[constants.STATUS_CANNOT_PLACE]: {
				title: 'Cannot place this here.',
				description: '',
			},
			[constants.STATUS_NOT_WHITELISTED]: {
				title: 'The URL is not whitelisted.',
				description:
					'Following URLs are allowed: br.de, tagesschau.de, sportschau.de, bayern3.de, br-klassik.de, br24.de',
			},
			[constants.STATUS_ALREADY_ON_THE_LIST]: {
				title: 'Cannot place this here.',
				description: 'This article is already on the list.',
			},
			[constants.LS_UPDATE_LOCKSTATUS]: {
				title: 'Error while saving.',
				description:
					'The lock status of this article cannot be checked. Please reload the article.',
			},
			refetch: {
				title: 'Could not refetch the article after saving.',
				description:
					'Please reload the window manually, in order to avoid locking related problems.',
			},
			general: {
				title: 'Action was not successful!',
				description: 'An error occured.',
			},
		},
		success: {
			[constants.STATUS_DRAFT]: {
				title: 'Saving successful!',
				description: 'The draft has been saved successful.',
			},
			[constants.STATUS_REVIEW]: {
				title: 'Article in review mode',
				description: 'The article has been forwarded to review mode.',
			},
			[constants.STATUS_PUBLISHED]: {
				title: 'Publication successful!',
				description: 'The article has been published successfully.',
			},
			[constants.STATUS_SCHEDULED]: {
				title: 'Timed publication successful!',
				description: 'The article has been published on time successfully.',
			},
			[constants.STATUS_DEPUBLISHED]: {
				title: 'Depublication successful!',
				description: 'The article have been depublicated successfully.',
			},
			[constants.STATUS_DELETED]: {
				title: 'Article deleted successfully!',
				description: 'The article has been deleted successfully.',
			},
			general: {
				title: 'Saving successful!',
				description: 'Saving has been successful.',
			},
			autoSave: {
				title: 'Automatically saved',
			},
		},
	},
	authors: {
		createAuthor: 'Create new author',
		searchPlaceholder: 'Search for authors...',
		me: 'Me',
		noData: 'No data found. Please note that the author search currently only works for lastname.',
		filter: {
			all: 'All',
			status: {
				active: 'active',
				inactive: 'inactive',
			},
			loginType: {
				ldap: 'with Account',
				profile: 'without Account',
			},
			info: 'Please note: Currently only a search for surnames is possible!',
		},
		update: {
			error: 'Error while saving the data!',
			success: 'The author {{firstname}} {{lastname}} was edited successfully!',
		},
		systemprofile: 'System profile',
	},
	author: {
		buttonSave: 'Save profile',
		buttonDismiss: 'Discard',
		buttonPreview: 'Preview',
		characterCount: '{{count}}/{{max}} characters',
		statusDescription: {
			active: {
				title: 'Author profile active',
				firstline:
					'AutorIn wird auf Artikel-Detailseite Web & App angezeigt, zusätzlich Detailseite Autorenprofil verlinkt.',
				secondline: 'Autorenprofil inaktiv: keine Detailseite Autorenprofil mehr verlinkt',
			},
			inactive: {
				title: 'Author profile inactive',
				firstline:
					'AutorIn wird auf Artikel-Detailseite Web & App angezeigt, keine zusätzliche Detailseite Autorenprofil verlinkt.',
				secondline: 'Autorenprofil aktiv: zusätzlich Detailseite Autorenprofil verlinkt',
			},
		},
		statusLabel: {
			active: 'activ',
			inactive: 'inactiv',
		},
		firstname: {
			label: 'Firstname',
			placeholder: 'Enter firstname',
		},
		lastname: {
			label: 'Lastname',
			placeholder: 'Enter lastname',
		},
		jobTitle: {
			label: 'Field of activity',
			placeholder: 'Describe what you do, e.g. BR Sport-Experte',
		},
		description: {
			label: 'Editorial profile (optional)',
			placeholder: 'Enter your editorial profile',
		},
		contact: {
			label: 'Contact',
		},
		profilePicture: {
			alt: 'Your profile picture',
			delete: 'Delete your current picture',
			edit: 'Edit your current picture',
			upload: 'Upload a new profile picture',
		},
		upload: {
			error: {
				message: 'Error',
				description: 'The file {{name}} could not be uploaded!',
			},
		},
		validation: {
			firstname: 'Your first name should consist of at least {{min}}, but max. {{max}} characters.',
			lastname: 'Your last name should consist of at least {{min}}, but max. {{max}} characters.',
			jobTitle:
				'Your field of activity should contain at least min. {{min}}, but max. {{max}} characters.',
			description:
				'Your description should contain at least min. {{min}}, but max. {{max}} characters.',
		},
		save: {
			success: {
				message: 'Success',
				description: 'Changes successfully saved!',
			},
			error: {
				message: 'Error',
				description: 'Your changes could not be saved.',
			},
		},
		socialMedia: {
			add: 'Create',
			select: {
				placeholder: 'Select platform...',
			},
			platform: {
				title: 'Platform',
			},
			account: {
				title: 'Account name',
				placeholder: 'URL of your account',
			},
			label: {
				title: 'Label',
				placeholder: 'Description of the link',
			},
		},
	},
};
