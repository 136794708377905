import { fetchQuery, graphql } from 'react-relay';

import environment from '../../../../../environment';

import type { fetchLiveStreamsQuery } from './__generated__/fetchLiveStreamsQuery.graphql';

// eslint-disable-next-line no-unused-expressions
graphql`
	fragment fetchLiveStreamsLiveStream on MangoLivestream {
		id
		streamingUrls(first: 1) {
			edges {
				node {
					publicLocation
				}
			}
		}
		epg {
			current
			broadcastEvent {
				start
				end
				id
				publicationOf {
					id
					title
					kicker
					defaultTeaserImage {
						imageFiles {
							edges {
								node {
									publicLocation
								}
							}
						}
					}
				}
			}
		}
	}
`;

const query = graphql`
	query fetchLiveStreamsQuery($filter: MangoLivestreamFilter) {
		viewer {
			allLivestreams(filter: $filter, orderBy: MODIFIEDAT_ASC) {
				count
				broadcastChannels: edges {
					node {
						labels
						id
						broadcastedBy {
							id
						}
					}
				}
				edges {
					node {
						...fetchLiveStreamsLiveStream @relay(mask: false)
					}
				}
			}
		}
	}
`;

export default async function fetchLiveStreams() {
	const res = await fetchQuery<fetchLiveStreamsQuery>(environment, query, {
		filter: { broadcastedBy: { id: { eq: 'av:http://ard.de/ontologies/ard#BRde' } } },
	}).toPromise();

	return (res?.viewer?.allLivestreams?.edges || []).map((edge) => edge?.node);
}
