import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

const ReadOnlyBar = styled.div`
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: ${(props) => props.theme?.colors.white};
	background-color: ${(props) => props.theme?.colors.stateRed};
	margin-bottom: 15px;
	width: 100%;
`;

export default () => {
	const translate = useTranslate();
	return <ReadOnlyBar>{translate('lockWarnings.readOnlyBar.text')}</ReadOnlyBar>;
};
