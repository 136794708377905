import { Pagination as antdPagination } from 'antd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

type IPaginationProps = {
	total: number;
	current?: number | null;
	defaultPageSize: number;
	searchCount: number;
	resultCount: number;
	onChange: (page: number, pageSize: number) => void;
};

const PaginationWrapper = styled.div`
	position: sticky;
	padding: 10px 0;
	background-color: ${(props) => props.theme.assetManager.itemColor};
	box-shadow: 0 5px 0 rgba(29, 32, 39, 0.5);
	top: -10px;
	z-index: 10;
	margin-bottom: 20px;
`;

const PaginationInfo = styled.p`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	font-size: 0.9em;
`;

const StyledPagination = styled(antdPagination)`
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;

	li.ant-pagination-item,
	a.ant-pagination-item-link {
		background-color: ${(props) => props.theme.assetManager.itemColor};
		color: white;
		border-color: ${(props) => props.theme.colors.br24Light};
		border-radius: 3px;
	}

	.ant-pagination-item {
		display: none;
	}

	.ant-pagination-prev {
		display: block;
		margin-left: 0;
		margin-right: auto;
	}

	.ant-pagination-next {
		display: block;
		margin-left: auto;
		margin-right: 0;
	}

	.ant-pagination-item a {
		color: white;
	}

	.ant-pagination-item-active,
	.ant-pagination-item-active a {
		/* Default antd color */
		border-color: #0a9ed7;
		color: #0a9ed7;
	}

	.ant-pagination-disabled {
		opacity: 0.5;
	}

	.ant-pagination-disabled a,
	.ant-pagination-disabled:hover a,
	.ant-pagination-disabled:focus a,
	.ant-pagination-disabled .ant-pagination-item-link,
	.ant-pagination-disabled:hover .ant-pagination-item-link,
	.ant-pagination-disabled:focus .ant-pagination-item-link {
		color: white;
	}
`;

export default function Pagination({
	total,
	defaultPageSize,
	current = null,
	onChange,
	searchCount,
	resultCount,
}: IPaginationProps) {
	const translate = useTranslate();

	// The presence of the current prop means that it is partially controlled, which we dont always need
	return (
		<PaginationWrapper>
			{current ? (
				<StyledPagination
					total={total}
					current={current}
					defaultPageSize={defaultPageSize}
					onChange={onChange}
					hideOnSinglePage={true}
				/>
			) : (
				<StyledPagination
					total={total}
					defaultPageSize={defaultPageSize}
					onChange={onChange}
					hideOnSinglePage={true}
				/>
			)}
			{total <= 1 && <div style={{ height: '32px' }} />}
			<PaginationInfo>
				{searchCount}{' '}
				{searchCount > 1
					? translate('assetManager.pagination.queries')
					: translate('assetManager.pagination.query')}{' '}
				– {resultCount}{' '}
				{resultCount > 1
					? translate('assetManager.pagination.results')
					: translate('assetManager.pagination.result')}{' '}
				– {translate('assetManager.pagination.page')} {current} / {total}{' '}
			</PaginationInfo>
		</PaginationWrapper>
	);
}
