import type { Coords } from 'google-map-react';

export function geoLocationToCoordinate(input?: string | null): Coords | undefined {
	if (typeof input !== 'string') {
		return undefined;
	}

	const found = input.match(/\(?(.+),(.+)\)?/i);
	if (Array.isArray(found) && found.length === 3) {
		return { lat: parseFloat(found[1]), lng: parseFloat(found[2]) };
	}

	return undefined;
}
