import i18next from 'i18next';
import moment from 'moment';
// see https://github.com/moment/moment/issues/5926 for more details on moment locale with vite
import 'moment/dist/locale/de';

import config from '../config';
import { de_DE, de_BY, en_EN } from '../constants';
import { AVAILABLE_LANGUAGES } from '../constants';

import deBYTranslation from './languages/de-BY';
import deDETranslation from './languages/de-DE';
import enENTranslation from './languages/en-EN';

export type Language = typeof de_DE | typeof de_BY | typeof en_EN;

type Resources = {
	[language in Language]: any;
};

const STORAGE_KEY = 'newsroom-language';

export function getLanguage(): Language {
	const possibileLanguage = localStorage.getItem(STORAGE_KEY);

	if (AVAILABLE_LANGUAGES.includes(possibileLanguage as any)) {
		return possibileLanguage as Language;
	}

	return config.VITE_DEFAULT_LANGUAGE as Language;
}

export function persistLanguage(language: Language): void {
	localStorage.setItem(STORAGE_KEY, language);
}

export function setMomentLocale(language: Language) {
	moment.locale(language.substring(0, 2));
}

const resources: Resources = {
	[de_DE]: { translation: deDETranslation },
	[de_BY]: { translation: deBYTranslation },
	[en_EN]: { translation: enENTranslation },
};

i18next.init({
	lng: getLanguage(),
	debug: false,
	fallbackLng: config.VITE_DEFAULT_LANGUAGE,
	// whitelist: Object.keys(resources),
	resources,
	interpolation: {
		escapeValue: false,
	},
	react: {
		// wait: true,
	},
	ns: ['translation'],
	defaultNS: 'translation',
});
setMomentLocale(getLanguage());

export default i18next;
