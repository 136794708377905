import type { ComponentType, FunctionComponent } from 'react';
import { useRelayEnvironment } from 'react-relay';
import type { Environment } from 'relay-runtime';

import { getDisplayName } from '../util/react';

/**
 * Omit type that behaves better with union types
 *
 * @see https://github.com/microsoft/TypeScript/issues/39556
 */
type BetterOmit<T, K extends string | number | symbol> = T extends unknown ? Omit<T, K> : never;

export interface WithRelayEnvironment {
	environment: Environment;
}

export function withRelayEnvironment<P extends WithRelayEnvironment>(
	WrappedComponent: ComponentType<P>
) {
	// Creating the inner component. The calculated Props type here is the where the magic happens.
	const ComponentWithRelayEnvironment: FunctionComponent<
		BetterOmit<P, keyof WithRelayEnvironment>
	> = (props: BetterOmit<P, keyof WithRelayEnvironment>) => {
		const relayEnvironment = useRelayEnvironment();

		return <WrappedComponent {...(props as any)} environment={relayEnvironment} />;
	};
	// Try to create a nice displayName for React Dev Tools.
	ComponentWithRelayEnvironment.displayName = `withRelayEnvironment(${getDisplayName(
		WrappedComponent
	)})`;

	return ComponentWithRelayEnvironment;
}
