import type { DisplayBoardLinkContainerProps } from '../SortableCard';

const getDisplayBoardLinkTooltipText = (props: DisplayBoardLinkContainerProps) => {
	const { boardStatus, hasError, boardName, link } = props;

	if (boardStatus !== 'PUBLISHED') {
		return `Die hinterlegte Verteilseite ist nicht publiziert. Bitte einen anderen oder keinen Link hinterlegen.`;
	}

	if (hasError || !link || !boardName) {
		return 'Der hinterlegte Link zur Sektionsüberschrift kann aktuell nicht angezeigt werden. Bitte wende dich an den Support oder versuche es später noch einmal.';
	}

	return `Die Sektion ist mit der Verteilseite "${boardName}" verlinkt.`;
};

export default getDisplayBoardLinkTooltipText;
