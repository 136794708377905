import { MODULE_TYPE_VIDEO } from '@sep/br24-constants';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { ModuleTypeVideo } from '../..';
import type { WithTranslationProps } from '../../../../translation';
import {
	ArticleModuleItem,
	ArticleModuleVideo,
	ArticleModuleDropzone,
	ArticleModuleImage,
} from '../../../../ui/ArticleModule';
import { convertThumbnailValidation } from '../../../../util/convertThumbnailValidation';
import type { Item } from '../../../AssetManager';
import {
	DndSortModuleContainer,
	DndCreateModuleContainer,
	DndUpdateModuleContainer,
} from '../../DragAndDropHandler';
import type { SimpleItem } from '../../DragAndDropHandler/DndSortModuleContainer';
import {
	allAssetManagerTypes,
	getAEItemType,
	getAMItemType,
	toSortModule,
} from '../../DragAndDropHandler/dndUtils';
import { activeArticleModuleSelector } from '../../selectors';
import BrokenModuleContainer from '../ArticleEditorBrokenModule';

interface Props extends WithTranslationProps, ReduxProps, OwnProps {
	isEditing: boolean;
	isThumbnailUploading: boolean;
	onMove: (dragIndex: SimpleItem, hoverIndex: SimpleItem) => void;
	onUpdate: (id: string, type: string, item: any) => void;
	onDelete: (id: string) => void;
	onDisplayAttachedDropzone: (order: number, position: 'top' | 'bottom') => void;
	onAddViaAttachedDropzone: (item: Item | undefined) => void;
	onAddViaInitialDropzone: (id: string, type: string, item: Item | undefined) => void;
	onDropUpdateThumbnail: (id: string, type: string, dropItem: Item) => void;
	onDropUploadThumbnail: (id: string, type: string, dropItem: any) => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
	onMoveToAssetManager: (id: string | number) => void;
	onResetAttachedDropzone?: () => void;
}

class VideoModuleContainer extends Component<Props> {
	handleChange = (key: string, value: string) => {
		this.props.onUpdate(this.props.id, 'video', {
			[key]: value,
		});
	};

	handleChangeThumbnailMeta = (key: 'url' | 'title' | 'altText' | 'copyright', value: string) => {
		this.props.onUpdate(this.props.id, 'video', {
			thumbnail: {
				...this.props.module.video?.thumbnail,
				[key]: value,
			},
		});
	};

	handleDelete = () => {
		this.props.onDelete(this.props.id);
	};

	handleInitialDropzoneDrop = (dropItem: Item | undefined) => {
		this.props.onAddViaInitialDropzone(this.props.id, 'video', dropItem);
	};

	handleUpdateOnDrop = (dropItem: Item) => {
		this.props.onDropUpdateThumbnail(this.props.id, 'video', dropItem);
	};

	handleUploadOnDrop = (dropItem: File[]) => {
		const [image] = dropItem;

		// only support one picture upload, not multiple
		if (image && dropItem.length === 1) {
			this.props.onDropUploadThumbnail(this.props.id, 'video', image);
		}
	};

	render() {
		const {
			translation: { translate },
			module,
			...rest
		} = this.props;

		if (!module.video) {
			return <BrokenModuleContainer id={module.rowId} />;
		}

		let displayModule: JSX.Element | null = null;
		if (module.video && module.video.url && module.video.url !== '') {
			displayModule = (
				<div>
					<DndSortModuleContainer
						acceptedItemTypes={{
							dragSource: getAEItemType('VIDEO'),
							dropTarget: toSortModule,
						}}
						{...this.props}
					>
						<ArticleModuleItem
							label={translate(`modules.${MODULE_TYPE_VIDEO}.text`)}
							onDelete={this.handleDelete}
						>
							<ArticleModuleVideo
								url={module.video.url || ''}
								title={module.video.title || ''}
								copyright={module.video.copyright || ''}
								videoTeaserImage={(module.video.thumbnail && module.video.thumbnail.url) || ''}
								validation={module.__validation__}
								onChange={this.handleChange}
								{...rest}
							>
								<DndUpdateModuleContainer
									acceptedItemTypes={[getAMItemType('IMAGE'), getAEItemType('NATIVE')]}
									itemTypes={[getAMItemType('IMAGE'), getAEItemType('NATIVE')]}
									onDropUpdate={this.handleUpdateOnDrop}
									onDropUpload={this.handleUploadOnDrop}
								>
									<ArticleModuleImage
										altText={(module.video.thumbnail && module.video.thumbnail.altText) || ''}
										copyright={(module.video.thumbnail && module.video.thumbnail.copyright) || ''}
										title={(module.video.thumbnail && module.video.thumbnail.title) || ''}
										url={(module.video.thumbnail && module.video.thumbnail.url) || ''}
										onChange={this.handleChangeThumbnailMeta}
										validation={
											module.__validation__
												? convertThumbnailValidation({
														title: module.__validation__['video.thumbnail.title'] || null,
														url: module.__validation__['video.thumbnail.url'] || null,
														altText: module.__validation__['video.thumbnail.altText'] || null,
														copyright: module.__validation__['video.thumbnail.copyright'] || null,
												  })
												: null
										}
										{...rest}
									/>
								</DndUpdateModuleContainer>
							</ArticleModuleVideo>
						</ArticleModuleItem>
					</DndSortModuleContainer>
				</div>
			);
		} else {
			displayModule = (
				<ArticleModuleItem
					label={translate(`modules.${MODULE_TYPE_VIDEO}.text`)}
					onDelete={this.handleDelete}
				>
					<DndCreateModuleContainer
						onAddViaInitialDropzone={this.handleInitialDropzoneDrop}
						acceptedItemTypes={[getAMItemType('VIDEO')]}
						itemTypes={allAssetManagerTypes}
					>
						<ArticleModuleDropzone
							type={MODULE_TYPE_VIDEO}
							text={translate(`modules.${MODULE_TYPE_VIDEO}.dropzoneText`)}
							validation={!!module.__validation__}
						/>
					</DndCreateModuleContainer>
				</ArticleModuleItem>
			);
		}

		return displayModule;
	}
}

interface OwnProps {
	id: string;
}

const connector = connect((state: ReduxState, { id }: OwnProps) => ({
	module: activeArticleModuleSelector(state, id) as ModuleTypeVideo,
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(VideoModuleContainer));
