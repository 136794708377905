import { child, set, get, remove, update } from 'firebase/database';

import { AM_GLOBAL_SPACE, AM_PERSONAL_SPACE } from '../../constants';

import type { Item, Space, GlobalGroup } from '.';
import { getDatabaseReference } from './database.init';

function deleteUndefinedPropertiesFromObject<T extends { [s: string]: unknown }>(object: T): T {
	for (const [key, value] of Object.entries(object)) {
		if (value === undefined) {
			delete object[key];
		}
	}
	return object;
}

export async function addGlobalGroup(group: GlobalGroup): Promise<void> {
	const ref = getDatabaseReference(AM_GLOBAL_SPACE);
	return set(child(ref, group.id), group);
}

export async function addItemToSpace(space: typeof AM_PERSONAL_SPACE, item: Item): Promise<void>;
export async function addItemToSpace(
	space: typeof AM_GLOBAL_SPACE,
	item: Item,
	activeGlobalGroup: string
): Promise<void>;
export async function addItemToSpace(
	space: Space,
	item: Item,
	activeGlobalGroup?: string
): Promise<void> {
	const ref = getDatabaseReference(space);

	if (space === AM_GLOBAL_SPACE && activeGlobalGroup && item.id) {
		await set(child(ref, `${activeGlobalGroup}/lastModifiedAt`), new Date().toISOString());
		return set(
			child(ref, `${activeGlobalGroup}/items/${item.id}`),
			deleteUndefinedPropertiesFromObject(item)
		);
	}

	return set(child(ref, item.id), deleteUndefinedPropertiesFromObject(item));
}

export async function lockItemInSpace(
	space: Space,
	itemId: string,
	activeGlobalGroup: string | null,
	isLockedForDeletion: boolean
): Promise<void> {
	const ref = getDatabaseReference(space);

	if (space === AM_GLOBAL_SPACE && activeGlobalGroup && itemId) {
		await set(child(ref, `${activeGlobalGroup}/lastModifiedAt`), new Date().toISOString());

		return set(
			child(ref, `${activeGlobalGroup}/items/${itemId}/isLockedForDeletion`),
			isLockedForDeletion
		);
	}

	return set(child(ref, `${itemId}/isLockedForDeletion`), isLockedForDeletion);
}

export async function removeGlobalGroup(groupId: string): Promise<boolean> {
	const ref = getDatabaseReference(AM_GLOBAL_SPACE);

	const snapshot = await get(child(ref, groupId));

	if (!snapshot.exists()) {
		return false;
	}

	await remove(child(ref, groupId));

	return true;
}

export async function updateGlobalGroup(
	groupId: string,
	groupData: Partial<GlobalGroup>
): Promise<boolean> {
	const ref = getDatabaseReference(AM_GLOBAL_SPACE);

	await update(child(ref, groupId), { ...groupData, lastModifiedAt: new Date().toISOString() });

	return true;
}

export async function updateGlobalGroupLockStatus(
	groupId: string,
	isLocked: boolean
): Promise<boolean> {
	const ref = getDatabaseReference(AM_GLOBAL_SPACE);

	await set(child(ref, `${groupId}/lastModifiedAt`), new Date().toISOString());
	await set(child(ref, `${groupId}/isLocked`), isLocked);

	return true;
}

export async function updateItem(space: Space, id: string, patch: unknown): Promise<boolean> {
	const ref = getDatabaseReference(space);
	const snapshot = await get(child(ref, id));

	if (!snapshot.exists()) {
		return false;
	}

	await update(child(ref, id), patch as any);

	return true;
}

export async function removeFromSpace(space: Space, id: string): Promise<boolean> {
	const ref = getDatabaseReference(space);
	const snapshot = await get(child(ref, id));

	if (!snapshot.exists()) {
		return false;
	}

	await remove(child(ref, id));
	return true;
}

export async function removeFromGlobalGroup(group: string | null, id: string): Promise<boolean> {
	if (!group) {
		throw new Error(`Cannot remove item. 'group' not provided`);
	}

	const ref = getDatabaseReference(AM_GLOBAL_SPACE);
	const snapshot = await get(child(ref, `${group}/items/${id}`));

	if (!snapshot.exists()) {
		return false;
	}

	await set(child(ref, `${group}/lastModifiedAt`), new Date().toISOString());
	await remove(child(ref, `${group}/items/${id}`));

	return true;
}

async function clearGlobal(): Promise<boolean> {
	const ref = getDatabaseReference(AM_GLOBAL_SPACE);

	await remove(ref);

	return true;
}

declare global {
	interface Window {
		__DEBUG__addItemToSpace?: typeof addItemToSpace;
		__DEBUG__updateItem?: typeof updateItem;
		__DEBUG__removeFromSpace?: typeof removeFromSpace;
		__DEBUG__clearGlobal?: typeof clearGlobal;
	}
}

if (import.meta.env.VITE_NODE_ENV === 'development') {
	// eslint-disable-next-line no-underscore-dangle
	window.__DEBUG__addItemToSpace = addItemToSpace;
	// eslint-disable-next-line no-underscore-dangle
	window.__DEBUG__updateItem = updateItem;
	// eslint-disable-next-line no-underscore-dangle
	window.__DEBUG__removeFromSpace = removeFromSpace;
	// eslint-disable-next-line no-underscore-dangle
	window.__DEBUG__clearGlobal = clearGlobal;
}
