import { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

const Wrapper = styled.div<{ isVisible?: boolean }>`
	display: ${(props) => (props.isVisible ? 'flex' : 'none')};
	justify-content: stretch;
	position: fixed;
	width: ${(props) =>
		`calc(${props.theme.assetManager.importer.width} - (${props.theme.assetManager.width} / 2))`};
	height: calc(100vh - 60px);
	right: ${(props) => props.theme.assetManager.width};
	top: 60px;
	background-color: #000000;
	padding: ${(props) => props.theme.assetManager.innerPadding};
`;

const OutsideCloser = styled.div`
	background-color: transparent;
	position: absolute;
	top: 0;
	right: 100%;
	width: ${(props) => `calc(100% - ${props.theme.assetManager.importer.width})`};
	height: calc(100vh - 60px);
`;

type AssetManagerImporterProps = {
	isVisible: boolean;
	onCloseImporter: () => void;
	children: any;
};

export default function AssetManagerImporter({
	children,
	onCloseImporter,
	isVisible = false,
	...rest
}: AssetManagerImporterProps) {
	const [{ isOver }, dropTarget] = useDrop(
		() => ({
			accept: [],
			canDrop: () => false,
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
		}),
		[]
	);

	const timer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (isOver) {
			timer.current = setTimeout(() => {
				onCloseImporter();
				if (timer.current) {
					clearTimeout(timer.current);
				}
			}, 1000);
		}

		return () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		};
	}, [isOver, onCloseImporter]);

	return (
		<Wrapper isVisible={isVisible} {...rest}>
			<div ref={dropTarget}>
				<OutsideCloser onClick={onCloseImporter} />
			</div>
			{children}
		</Wrapper>
	);
}
