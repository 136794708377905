import { BOARD_BUFFER_UPDATE } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import type { BoardBufferPatch } from '../types';
import { findBoardById } from '../util/find';

import { validateBoard } from './validate';

export type UpdateAction = {
	type: typeof BOARD_BUFFER_UPDATE;
	constraint: {
		boardId: string;
	};
	payload: BoardBufferPatch;
};

/**
 * Updates a board by `boardId` with the given data `boardPatch`. Note that this function cannot change the data of any section
 * or section item. To do so use `updateSection` or `updateItemInSection`.
 *
 * @param  {string} boardId
 * @param  {Object} boardPatch
 * @return {Function}
 */
export default function update(
	boardId: string,
	boardPatch: BoardBufferPatch
): AppThunkAction<UpdateAction> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_UPDATE,
			constraint: { boardId: board.__meta__.internalId },
			payload: boardPatch,
		});

		const shouldValidateSlug = !!(boardPatch && boardPatch.slug);

		dispatch(
			validateBoard(boardId, {
				skipIfAutoValidationIsDisabled: true,
				shouldValidateSlug,
			})
		);
	};
}
