import * as importerItemTypes from '../AssetManagerImporter/itemTypes';
import * as assetManagerItemTypes from '../itemTypes';

import ItemArticle from './AssetManagerItemArticle';
import ItemAudio from './AssetManagerItemAudio';
import ItemBoard from './AssetManagerItemBoard';
import ItemBoardTeaser from './AssetManagerItemBoardTeaser';
import ItemBrBildImage from './AssetManagerItemBrBildImage';
import ItemEmbedCode from './AssetManagerItemEmbedCode';
import ItemGallery from './AssetManagerItemGallery';
import ItemImage from './AssetManagerItemImage';
import ItemLink from './AssetManagerItemLink';
import ItemLiveStream from './AssetManagerItemLiveStream';
import { AssetManagerItemSophoraArticle } from './AssetManagerItemSophoraArticle';
import ItemText from './AssetManagerItemText';
import ItemVideo from './AssetManagerItemVideo';
import ItemVideo360 from './AssetManagerItemVideo360';
import withImportedItemDND from './withImportedItemDND';
import withItemDND from './withItemDND';

export const AssetManagerItemArticle = withItemDND(assetManagerItemTypes.AM_ARTICLE, ItemArticle);
export const AssetManagerItemAudio = withItemDND(assetManagerItemTypes.AM_AUDIO, ItemAudio);
export const AssetManagerItemBoard = withItemDND(assetManagerItemTypes.AM_BOARD, ItemBoard);
export const AssetManagerItemBoardTeaser = withItemDND(
	assetManagerItemTypes.AM_BOARD_TEASER,
	ItemBoardTeaser
);
export const AssetManagerItemEmbedCode = withItemDND(
	assetManagerItemTypes.AM_EMBED_CODE,
	ItemEmbedCode
);
export const AssetManagerItemGallery = withItemDND(assetManagerItemTypes.AM_GALLERY, ItemGallery);
export const AssetManagerItemImage = withItemDND(assetManagerItemTypes.AM_IMAGE, ItemImage);
export const AssetManagerItemBrBildImage = withItemDND(
	assetManagerItemTypes.AM_BRBILD_IMAGE,
	ItemBrBildImage
);
export const AssetManagerItemLink = withItemDND(assetManagerItemTypes.AM_LINK, ItemLink);
export const AssetManagerItemText = withItemDND(assetManagerItemTypes.AM_TEXT, ItemText);
export const AssetManagerItemVideo = withItemDND(assetManagerItemTypes.AM_VIDEO, ItemVideo);
export const AssetManagerItemVideo360 = withItemDND(
	assetManagerItemTypes.AM_VIDEO360,
	ItemVideo360
);
export const AssetManagerItemLiveStream = withItemDND(
	assetManagerItemTypes.AM_LIVESTREAM,
	ItemLiveStream
);

export const AssetManagerImportedItemText = withImportedItemDND(
	importerItemTypes.IMPORTED_TEXT,
	ItemText
);

export const AssetManagerImportedItemBrBildImage = withImportedItemDND(
	importerItemTypes.IMPORTED_BRBILD_IMAGE,
	ItemBrBildImage
);

export const AssetManagerImportedItemImage = withImportedItemDND(
	importerItemTypes.IMPORTED_IMAGE,
	ItemImage
);
export const AssetManagerImportedItemAudio = withImportedItemDND(
	importerItemTypes.IMPORTED_AUDIO,
	ItemAudio
);
export const AssetManagerImportedItemVideo = withImportedItemDND(
	importerItemTypes.IMPORTED_VIDEO,
	ItemVideo
);
export const AssetManagerImportedItemLiveStream = withImportedItemDND(
	importerItemTypes.IMPORTED_LIVESTREAM,
	ItemLiveStream
);
export const AssetManagerImportedItemArticle = withImportedItemDND(
	importerItemTypes.IMPORTED_ARTICLE,
	ItemArticle
);

export const AssetManagerImportedItemSophoraArticle = AssetManagerItemSophoraArticle;

export const AssetManagerImportedItemBoard = withImportedItemDND(
	importerItemTypes.IMPORTED_BOARD,
	ItemBoard
);

export const AssetManagerImportedItemGallery = withImportedItemDND(
	importerItemTypes.IMPORTED_GALLERY,
	ItemGallery
);

export const AssetManagerImportedItemEmbed = withImportedItemDND(
	importerItemTypes.IMPORTED_EMBED,
	ItemEmbedCode
);

export type AssetManagerStatus = {
	isDraggable: boolean;
	isDragging: boolean;
	inDeletionMode?: boolean;
	isEditable?: boolean;
	isDeletable?: boolean;
	isInImporter: boolean;
	isMoveable?: boolean;
	isSelected?: boolean;
	isBrBildContent?: boolean;
};
