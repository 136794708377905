import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'react-relay';

import type { BoardSocialMediaAccountService, BoardStatus } from '../store/reducers/boardBuffer';

import type { UpdateBoardMutation as UpdateBoardMutationTypes } from './__generated__/UpdateBoardMutation.graphql';

const mutation = graphql`
	mutation UpdateBoardMutation($input: UpdateBoardByRowIdInput!) {
		updateBoardByRowId(input: $input) {
			board {
				rowId
				slug
				title
				description
				introductionText
				hasIntroductionText
				categoryId
				socialMediaAccounts {
					service
					label
					accountName
				}
				noindex
				protected
				status
				updatedBy
				updatedAt
				createdBy
				createdAt
				authorByLockedBy {
					guid
					firstname
					lastname
				}
				lockedBy
				lockedSince
			}
		}
	}
`;

export type SocialMediaAccountInput = {
	service: BoardSocialMediaAccountService;
	label: string | undefined | null;
	accountName: string;
};

export type UpdateBoardInput = {
	parentId?: string | null;
	categoryId?: string | null;
	image?: {
		url: string | undefined | null;
		copyright: string | undefined | null;
		title: string | undefined | null;
	} | null;
	slug?: string;
	title?: string;
	description?: string;
	hasIntroductionText?: boolean | null;
	introductionHeadline?: string | null;
	introductionText?: string | null;
	socialMediaAccounts?: SocialMediaAccountInput[];
	protected?: boolean;
	noindex?: boolean;
	status?: BoardStatus | null;
	lockedBy?: string | null;
};

export type UpdateBoardOutput = NonNullable<
	NonNullable<UpdateBoardMutationTypes['response']>['updateBoardByRowId']
>['board'];

const execute = (
	boardRowId: string,
	input: UpdateBoardInput,
	environment: Environment
): Promise<UpdateBoardMutationTypes['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<UpdateBoardMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					rowId: boardRowId,
					boardPatch: input,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});

export default async function UpdateBoardMutation(
	boardRowId: string,
	input: UpdateBoardInput,
	environment: Environment
): Promise<UpdateBoardOutput> {
	const res = await execute(boardRowId, input, environment);
	if (!res || !res.updateBoardByRowId || !res.updateBoardByRowId.board) {
		throw new Error('Something went wrong');
	}
	return res.updateBoardByRowId.board;
}
