import type { Input } from 'antd';
import { debounce } from 'lodash-es';
import {
	Component,
	type ComponentProps,
	type FocusEvent,
	type FocusEventHandler,
	type KeyboardEvent,
	type KeyboardEventHandler,
} from 'react';

import config from '@/client/config';

export interface Props {
	onMagicChange?: ((value: string) => void) | null;
	isTypingDelayActive: boolean;
}

type State = {
	isTyping: boolean;
};

export class AbstractHTMLElement<
	Element extends typeof Input | typeof Input.TextArea
> extends Component<Props & ComponentProps<Element>, State> {
	static defaultProps = {
		isTypingDelayActive: true,
	};

	state = {
		isTyping: false,
	};

	private debounceRef = debounce((value: string) => {
		if (typeof this.props.onMagicChange === 'function') {
			this.props.onMagicChange(value);
		}
	}, config.typingInterval);

	componentWillUnmount() {
		this.debounceRef.cancel();
	}

	getChildProps(): Omit<Props, 'isTypingDelayActive'> & ComponentProps<Element> {
		const { isTypingDelayActive, ...rest } = this.props;
		const additionalProps: {
			onBlur: FocusEventHandler<Element>;
			onInput?: KeyboardEventHandler<Element>;
		} = {
			onBlur: this.handleFakeBlur,
		};

		if (isTypingDelayActive) {
			additionalProps.onInput = this.handleKeyDown;
		}

		return {
			...rest,
			...additionalProps,
		} as unknown as Omit<Props, 'isTypingDelayActive'> & ComponentProps<Element>;
	}

	handleFakeBlur = (event: FocusEvent<Element>) => {
		const { onMagicChange } = this.props;

		if (typeof onMagicChange === 'function') {
			onMagicChange((event.currentTarget as any).value);
		}
	};

	handleKeyDown = (event: KeyboardEvent<Element>) => {
		const { isTypingDelayActive, onMagicChange } = this.props;

		if (!isTypingDelayActive || typeof onMagicChange !== 'function') {
			return;
		}

		event.persist();

		this.setState(
			{
				isTyping: true,
			},
			() => this.debounceRef((event.target as any).value)
		);
	};
}
