import { BOARD_BUFFER_SECTION_UPDATE } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';
import { filterNullOrUndefined } from '@/client/util/filter';

import type { BoardSectionBufferPatch } from '../types';
import { findSectionById } from '../util/find';

import deleteItemInSection from './deleteItemInSection';
import { validateSection } from './validate';

export type UpdateSectionAction = {
	type: typeof BOARD_BUFFER_SECTION_UPDATE;
	constraint: {
		boardId: string;
		sectionId: string;
	};
	payload: BoardSectionBufferPatch;
};

/**
 * Updates an existing section by `sectionInternalId` and `sectionPatch`. Note that `sectionPatch.meta` must be
 * sent completely.
 *
 * @param  {string} sectionInternalId
 * @param  {Object} sectionPatch
 * @return {Function}
 */
export default function updateSection(
	sectionInternalId: string,
	sectionPatch: BoardSectionBufferPatch
): AppThunkAction<UpdateSectionAction> {
	return (dispatch, getState) => {
		const section = findSectionById(sectionInternalId, getState);

		if (!section) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SECTION_UPDATE,
			constraint: {
				boardId: section.__meta__.boardId,
				sectionId: sectionInternalId,
			},
			payload: sectionPatch,
		});

		if (sectionPatch.autofill && sectionPatch.autofill !== section.autofill) {
			section.items
				.filter(filterNullOrUndefined)
				.forEach(({ __internalId__ }) => dispatch(deleteItemInSection(__internalId__)));
		}

		dispatch(
			validateSection(section.__meta__.internalId, { skipIfAutoValidationIsDisabled: true })
		);
	};
}
