import type { ComponentProps } from 'react';
import styled from 'styled-components';

import { SearchFilter } from '../../Icon';

const SearchWrapper = styled.div`
	flex: 1;
`;

const SearchInput = styled.input`
	width: calc(100% - 40px);
	height: 40px;
	line-height: 40px;
	border: none;
	&:focus {
		outline: 0;
	}
`;

const SearchIconWrapper = styled.span`
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background-color: #ffffff;
`;

function AssetManagerFilterSearch({ ...rest }: ComponentProps<typeof SearchInput>) {
	return (
		<SearchWrapper>
			<SearchIconWrapper>
				<SearchFilter />
			</SearchIconWrapper>
			<SearchInput type="search" {...rest} />
		</SearchWrapper>
	);
}

export default AssetManagerFilterSearch;
