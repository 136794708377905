import { Button } from 'antd';
import styled from 'styled-components';

export { default as ArticleMetaTeasers } from './ArticleMetaTeasers';
export { default as ArticleMetaTeasersItemFresh } from './ArticleMetaTeasersItemFresh';
export { default as ArticleMetaTeasersItem } from './ArticleMetaTeasersItem';
export { default as ArticleMetaTeasersSkeleton } from './ArticleMetaTeasersSkeleton';
export { default as ArticleMetaTeasersError } from './ArticleMetaTeasersError';
export {
	default as ArticleMetaTeasersDropzone,
	ArticleMetaTeasersDropzoneWrapper,
} from './ArticleMetaTeasersDropzone';
export { default as ArticleMetaTeasersImporterSearch } from './ArticleMetaTeasersImporterSearch';

export const Wrapper = styled.div`
	font-size: 0.85em;
	line-height: 1.2;
	padding-top: 15px;
`;

export const Footer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	margin-top: 5px;
`;

export const FooterButton = styled(Button)`
	flex-basis: 24px;
	background: transparent;
	border: none;
	padding-left: 0;
	padding-right: 0;

	&:hover,
	&:active {
		background: transparent;
		svg path {
			fill: ${(props) => props.theme.colors.stateBlue};
		}
	}

	&:first-child {
		margin-left: -8px;
	}

	&.flex-end {
		margin-left: auto;
		margin-right: 0;
	}

	svg path {
		fill: ${(props) => props.theme.colors.greyModulesItem};
		transition: fill 400ms;
	}
`;

export const PreviewHead = styled.p`
	margin-bottom: 3px;
	font-weight: 600;
	color: ${(props) => props.theme.colors.primaryBlue};
`;

export const PreviewImage = styled.img`
	display: block;
	width: 160px;
	height: 90px;
	object-fit: cover;
`;

export const MetaText = styled.p`
	flex: 1;
	color: ${(props) => props.theme.colors.greyModulesItem};
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: pre;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Info = styled.p`
	margin-bottom: 6px;
	font-weight: 600;
	color: #e84b3d;
	text-transform: uppercase;
`;
