import type { MODULE_TYPE_TEXT } from '@sep/br24-constants';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';
import type { OnCreate } from '@/client/ui/ArticleModule/ArticleModuleCreator/ArticleModuleCreator';

import type { ModuleType } from '../..';
import { ArticleModuleCreator } from '../../../../ui/ArticleModule';
import createModuleAction from '../../actions/createModule';

interface Props extends ReduxProps {
	insertAt: number;
	hasTextarea?: boolean;
}

export class ModuleCreatorContainer extends Component<Props> {
	static defaultProps = {
		hasTextarea: true,
	};

	handleCreate: OnCreate = (
		type: Omit<ModuleType, typeof MODULE_TYPE_TEXT> | typeof MODULE_TYPE_TEXT,
		data?: { text: string }
	) => {
		const { insertAt } = this.props;
		this.props.createModule({
			type: type as ModuleType,
			data,
			order: insertAt,
		});
	};

	render() {
		const { hasTextarea } = this.props;

		return <ArticleModuleCreator hasTextarea={hasTextarea} onCreate={this.handleCreate} />;
	}
}

const connector = connect(null, {
	createModule: createModuleAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ModuleCreatorContainer);
