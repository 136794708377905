import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Card, Alert } from 'antd';

import { useTranslate } from '@/client/translation/useTranslate';

import CategoryArticleStatsContainer from './CategoryArticleStatsContainer';
import PieChart from './PieChart';

type Props = {
	startDate: moment.Moment;
	endDate: moment.Moment;
};

export default ({ startDate, endDate }: Props) => {
	const translate = useTranslate();

	return (
		<CategoryArticleStatsContainer
			startDate={startDate.toISOString()}
			endDate={endDate.toISOString()}
			render={({ error, props }) => (
				<div>
					{error ? (
						<Alert message={translate('error')} description={error.message} type="error" />
					) : (
						<Card
							title={translate('dashboard.categoryArticleStats')}
							loading={error === null && props === null}
							extra={
								<Tooltip title={translate('dashboard.categoryArticleStatsHelp')}>
									<Button shape="circle" size="small" icon={<QuestionCircleOutlined />} />
								</Tooltip>
							}
						>
							{props?.stats &&
							Array.isArray(props.stats.edges) &&
							props.stats.edges.filter(({ node: { articleCount } }) => articleCount > 0).length >
								0 ? (
								<PieChart data={props.stats.edges} />
							) : (
								<p>{translate('dashboard.noDataSelectedDateRange')}</p>
							)}
						</Card>
					)}
				</div>
			)}
		/>
	);
};
