import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateSelectionAction } from '..';

import updateSelection from './updateSelection';

function select(id: string): AppThunkAction<UpdateSelectionAction> {
	return (dispatch, getState) => {
		const { assetManager } = getState();
		dispatch(updateSelection([...assetManager.selected, id]));
	};
}
export default select;
