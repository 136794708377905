import { Form } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';

import config from '@/client/config';
import { useTranslate } from '@/client/translation/useTranslate';
import { ArticleMetaPanel } from '@/client/ui/ArticleMeta/ArticleMetaPanel';
import ErrorMessageText from '@/client/ui/ErrorMessageText';
import RichTextEditor from '@/client/ui/RichTextEditor';

const NOTES_MAX_LENGTH = config.articleEditor.meta.notes.maxLength;

const FormItem = styled(Form.Item)`
	.ant-form-explain {
		text-align: end;
		font-size: 12px;
	}
	.ant-input-disabled {
		cursor: pointer !important;
	}
	.meta-input-slug {
		cursor: unset !important;
	}
`;

type Props = {
	notes: string | null;
	validation: any;
	onChangeNotes: (notes: string) => void;
	key: string | number;
};

export const ArticleMetaNotes = ({ notes = '', validation, onChangeNotes, ...rest }: Props) => {
	const strippedNotesText = useMemo(() => (notes ?? '').replace(/<[^>]+>/g, ''), [notes]);
	const translate = useTranslate();

	return (
		<ArticleMetaPanel
			help={translate('article.meta.notes.help')}
			isRequired={false}
			title={translate('article.meta.notes.title')}
			{...rest}
		>
			<FormItem
				help={translate('article.meta.notes.characterCountText', {
					currentCount: strippedNotesText ? `${strippedNotesText.length}` : 0,
					characterMaxLength: NOTES_MAX_LENGTH,
				})}
				validateStatus={
					strippedNotesText && strippedNotesText.length > NOTES_MAX_LENGTH ? 'error' : 'success'
				}
			>
				<RichTextEditor
					defaultValue={notes ?? undefined}
					onChange={onChangeNotes}
					isSmallStyle={true}
					hasError={validation?.notes}
					{...rest}
				/>
			</FormItem>
			<ErrorMessageText
				text={validation && validation.notes ? validation.notes : null}
				hasBottomMargin={true}
			/>
		</ArticleMetaPanel>
	);
};
