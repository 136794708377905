import { STATUS_REQUEST, STATUS_SUCCESS, STATUS_ERROR } from '@sep/br24-constants';
import update from 'immutability-helper';

import { generateReducer } from '@/client/util/generateReducer';

import * as constants from '../../constants';

import type {
	State,
	UpdateVisibilityAction,
	ChangeSpaceAction,
	ChangeModeAction,
	UpdateFilterAction,
	AddItemsAction,
	UpdateItemAction,
	RemoveItemAction,
	CleanSpaceAction,
	UpdateSelectionAction,
	ImportResultsErrorAction,
	AddItemsToImporterAction,
	AddItemGroupToImporterAction,
	SetItemGroupPaginationAction,
	RemoveItemFromImporterAction,
	RemoveItemGroupFromImporterAction,
	ActivateImporterAction,
	CleanImporterAction,
	ImportBroadcastChannelsAction,
	AddGlobalGroupsAction,
	SetActiveGlobalGroupAction,
	UpdateGlobalGroupAction,
	RemoveGlobalGroupAction,
	ToggleGlobalGroupLockAction,
	ToggleSubGroupsAction,
	LockItemForDeletionAction,
	UpdateSearchTerm,
	SetFirebaseConnectedAction,
	ImportActionType,
} from '.';
import * as actionTypes from './actionTypes';

export type AssetManagerState = State;

const initialState: State = {
	firebaseConnected: false,
	isVisible: false,
	mode: constants.AM_MODE_HARVEST,
	activeSpace: constants.AM_PERSONAL_SPACE,
	activeImporter: constants.AM_IMPORTER_SOPHORA,
	activeGlobalGroupId: null,
	showActiveGlobalGroup: false,
	isImporterWorking: false,
	latestImporterError: null,
	imports: {
		[constants.AM_IMPORTER_SOPHORA]: {},
		[constants.AM_IMPORTER_SOPHORA_ARTICLE]: {},
		[constants.AM_IMPORTER_LIVESTREAMS]: {},
		[constants.AM_IMPORTER_NEWSROOM]: {},
		[constants.AM_IMPORTER_BRBILD]: {},
	},
	spaces: {
		personal: {},
		global: {},
	},
	filter: {
		personal: {
			searchValue: null,
			types: [],
			sortDirection: constants.AM_SORT_DIRECTION_RECENT,
		},
		global: {
			searchValue: null,
			types: [],
			sortDirection: constants.AM_SORT_DIRECTION_RECENT,
		},
	},
	livestreams: {
		selection: null,
		broadcastChannels: [],
	},
	selected: [],
	importerSearchTermFromArticleEditor: '',
};

const IMPORTER_STATUS_REDUCER = {
	[STATUS_REQUEST]: (state: State) =>
		update(state, {
			isImporterWorking: {
				$set: true,
			},
		}),
	[STATUS_SUCCESS]: (state: State) =>
		update(state, {
			isImporterWorking: {
				$set: false,
			},
			latestImporterError: {
				$set: null,
			},
		}),
	[STATUS_ERROR]: (state: State, action: ImportResultsErrorAction<ImportActionType>) =>
		update(state, {
			isImporterWorking: {
				$set: false,
			},
			latestImporterError: {
				$set: action.payload,
			},
		}),
};

export default generateReducer(initialState, {
	[actionTypes.UPDATE_VISIBILITY]: (state: State, action: UpdateVisibilityAction) =>
		update(state, {
			isVisible: { $set: action.payload },
		}),
	[actionTypes.CHANGE_SPACE]: (state: State, action: ChangeSpaceAction) =>
		update(state, {
			activeSpace: { $set: action.payload },
		}),
	[actionTypes.CHANGE_MODE]: (state: State, action: ChangeModeAction) =>
		update(state, {
			mode: { $set: action.payload },
		}),
	[actionTypes.UPDATE_FILTER]: (state: State, action: UpdateFilterAction) =>
		update(state, {
			filter: {
				[action.payload.space]: {
					$merge: action.payload.filterData,
				},
			},
		}),
	[actionTypes.ADD_ITEMS]: (state: State, action: AddItemsAction) => {
		const reducerFunction = (acc, item) => ({
			...acc,
			[item.id]: {
				$set: item,
			},
		});

		// second condition is redundant but needed for flow
		if (action.payload.isInGlobalGroup && action.payload.activeGlobalGroup) {
			return update(state, {
				spaces: {
					[action.payload.space]: {
						[action.payload.activeGlobalGroup]: {
							items: () => action.payload.items.reduce(reducerFunction, {}),
						},
					},
				},
			});
		}

		return update(state, {
			spaces: {
				[action.payload.space]: action.payload.items.reduce(reducerFunction, {}),
			},
		});
	},
	[actionTypes.UPDATE_ITEM]: (state: State, action: UpdateItemAction) => {
		if (!action.payload.patch) {
			return state;
		}
		return update(state, {
			spaces: {
				[action.payload.space]: {
					[action.payload.id]: {
						$merge: action.payload.patch,
					},
				},
			},
		});
	},
	[actionTypes.REMOVE_ITEM]: (state: State, action: RemoveItemAction) => {
		if (action.payload.space === constants.AM_PERSONAL_SPACE) {
			return update(state, {
				spaces: {
					[constants.AM_PERSONAL_SPACE]: {
						$unset: [action.payload.id],
					},
				},
			});
		}

		if (action.payload.space === constants.AM_GLOBAL_SPACE) {
			if (action.payload.group) {
				return update(state, {
					spaces: {
						[constants.AM_GLOBAL_SPACE]: {
							[action.payload.group]: {
								items: {
									$unset: [action.payload.id],
								},
							},
						},
					},
				});
			}
		}

		return state;
	},
	[actionTypes.CLEAN_SPACE]: (state: State, action: CleanSpaceAction) =>
		update(state, {
			spaces: {
				[action.payload]: {
					$set: {},
				},
			},
		}),
	[actionTypes.LOCK_ITEM_FOR_DELETION]: (state: State, action: LockItemForDeletionAction) => {
		if (action.payload.space === constants.AM_PERSONAL_SPACE) {
			return update(state, {
				spaces: {
					[action.payload.space]: {
						[action.payload.itemId]: {
							$toggle: ['isLockedForDeletion'],
						},
					},
				},
			});
		}

		if (action.payload.space === constants.AM_GLOBAL_SPACE && action.payload.activeGlobalGroup) {
			return update(state, {
				spaces: {
					[action.payload.space]: {
						[action.payload.activeGlobalGroup]: {
							items: {
								[action.payload.itemId]: {
									$toggle: ['isLockedForDeletion'],
								},
							},
						},
					},
				},
			});
		}

		return state;
	},
	[actionTypes.UPDATE_SELECTION]: (state: State, action: UpdateSelectionAction) =>
		update(state, {
			selected: {
				$set: action.payload,
			},
		}),
	[actionTypes.ACTIVATE_IMPORTER]: (state: State, action: ActivateImporterAction) =>
		update(state, {
			activeImporter: {
				$set: action.payload,
			},
			latestImporterError: {
				$set: null,
			},
		}),
	[actionTypes.UPDATE_IMPORTER_SEARCH_TERM_FROM_ARTICLE_EDITOR]: (
		state: State,
		action: UpdateSearchTerm
	) =>
		update(state, {
			importerSearchTermFromArticleEditor: {
				$set: action.importerSearchTermFromArticleEditor,
			},
		}),
	[actionTypes.CLEAN_IMPORTER]: (state: State, action: CleanImporterAction) =>
		update(state, {
			imports: {
				[action.payload]: {
					$set: {},
				},
			},
		}),
	[actionTypes.ADD_ITEMS_TO_IMPORTER]: (state: State, action: AddItemsToImporterAction) =>
		update(state, {
			imports: {
				[action.payload.importer]: {
					[action.payload.itemGroupTitle]: {
						items: {
							$push: action.payload.items,
						},
						additionalData: {
							$set: action.payload.additionalData,
						},
					},
				},
			},
		}),
	[actionTypes.ADD_ITEM_GROUP_TO_IMPORTER]: (state: State, action: AddItemGroupToImporterAction) =>
		update(state, {
			imports: {
				[action.payload.importer]: {
					[action.payload.itemGroupTitle]: {
						$set: {
							items: [],
							groupTitle: action.payload.itemGroupTitle,
							searchParameters: action.payload.searchParameters,
						},
					},
				},
			},
		}),
	[actionTypes.SET_ITEM_GROUP_PAGINATION]: (state: State, action: SetItemGroupPaginationAction) =>
		update(state, {
			imports: {
				[action.payload.importer]: {
					[action.payload.itemGroupTitle]: {
						hasNextPage: {
							$set: action.payload.hasNextPage,
						},
						lastCursor: {
							$set: action.payload.lastCursor,
						},
					},
				},
			},
		}),
	[actionTypes.REMOVE_ITEM_FROM_IMPORTER]: (state: State, action: RemoveItemFromImporterAction) =>
		update(state, {
			imports: {
				[action.payload.importer]: {
					$unset: [action.payload.id],
				},
			},
		}),
	[actionTypes.REMOVE_ITEM_GROUP_FROM_IMPORTER]: (
		state: State,
		action: RemoveItemGroupFromImporterAction
	) =>
		update(state, {
			imports: {
				[action.payload.importer]: {
					$unset: [action.payload.itemGroupTitle],
				},
			},
		}),
	[actionTypes.IMPORT_BROADCAST_CHANNELS]: (state: State, action: ImportBroadcastChannelsAction) =>
		update(state, {
			livestreams: {
				broadcastChannels: {
					$set: action.payload,
				},
			},
		}),
	[actionTypes.IMPORT_LIVESTREAMS]: IMPORTER_STATUS_REDUCER,
	[actionTypes.AM_IMPORT_NEWSROOM]: IMPORTER_STATUS_REDUCER,
	[actionTypes.AM_IMPORT_SOPHORA_ENTITY_BY_ID]: IMPORTER_STATUS_REDUCER,
	[actionTypes.AM_IMPORT_BRBILD]: IMPORTER_STATUS_REDUCER,
	[actionTypes.AM_IMPORT_SOPHORA_ARTICLE]: IMPORTER_STATUS_REDUCER,
	[actionTypes.ADD_GLOBAL_GROUP]: (state: State, action: AddGlobalGroupsAction) =>
		update(state, {
			spaces: {
				global: action.payload.groups.reduce(
					(acc, group) => ({
						...acc,
						[group.id]: {
							$set: {
								...group,
							},
						},
					}),
					{}
				),
			},
		}),
	[actionTypes.UPDATE_GLOBAL_GROUP]: (state: State, action: UpdateGlobalGroupAction) =>
		update(state, {
			spaces: {
				global: {
					[action.payload.id]: {
						$merge: action.payload,
					},
				},
			},
		}),
	[actionTypes.REMOVE_GLOBAL_GROUP]: (state: State, action: RemoveGlobalGroupAction) =>
		update(state, {
			spaces: {
				global: {
					$unset: [action.payload.id],
				},
			},
		}),
	[actionTypes.TOGGLE_GLOBAL_GROUP_LOCK]: (state: State, action: ToggleGlobalGroupLockAction) =>
		update(state, {
			spaces: {
				global: {
					[action.payload.id]: {
						isLocked: {
							$set: action.payload.isLocked,
						},
					},
				},
			},
		}),
	[actionTypes.SET_ACTIVE_GLOBAL_GROUP]: (state: State, action: SetActiveGlobalGroupAction) => {
		const showActiveGlobalGroup = !!action.payload.activeGlobalGroup;

		if (!showActiveGlobalGroup) {
			// keep the current active global groupId, because there is a transition that will still show
			// the content of the group when leaving the group ot return to the space overview
			return update(state, {
				showActiveGlobalGroup: {
					$set: showActiveGlobalGroup,
				},
			});
		} else {
			return update(state, {
				activeGlobalGroupId: {
					$set: action.payload.activeGlobalGroup,
				},
				showActiveGlobalGroup: {
					$set: showActiveGlobalGroup,
				},
			});
		}
	},
	[actionTypes.SET_FIREBASE_CONNECTED]: (state: State, action: SetFirebaseConnectedAction) => {
		return update(state, {
			firebaseConnected: {
				$set: action.payload.connected,
			},
		});
	},
	[actionTypes.TOGGLE_SUB_GROUPS]: (state: State, action: ToggleSubGroupsAction) =>
		update(state, {
			spaces: {
				global: {
					[action.payload.id]: {
						$toggle: ['showSubGroups'],
					},
				},
			},
		}),
});
