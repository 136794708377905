import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { UpdateModuleInput } from '../..';
import type { WithTranslationProps } from '../../../../translation';
import { ArticleModuleItem, ArticleModuleVideo360 } from '../../../../ui/ArticleModule';
import type { SimpleItem } from '../../DragAndDropHandler/DndSortModuleContainer';
import deleteModule from '../../actions/deleteModule';
import updateModule from '../../actions/updateModule';
import { activeArticleModuleSelector } from '../../selectors';

interface Props extends WithTranslationProps, ReduxProps, OwnProps {
	onMove: (dragIndex: SimpleItem, hoverIndex: SimpleItem) => void;
}

class Video360ModuleContainer extends Component<Props> {
	handleDelete = () => {
		this.props.delete(this.props.id);
	};

	render() {
		const {
			translation: { translate },
			id,
		} = this.props;

		return (
			<div>
				<ArticleModuleItem label={translate('modules.VIDEO360.text')} onDelete={this.handleDelete}>
					<ArticleModuleVideo360 />
					{id}
				</ArticleModuleItem>
			</div>
		);
	}
}

interface OwnProps {
	id: string;
}

const connector = connect(
	(state: ReduxState, { id }: OwnProps) => ({
		module: activeArticleModuleSelector(state, id),
	}),
	{
		update: (id: string, value: UpdateModuleInput['value']) =>
			updateModule(id, {
				type: 'video360',
				value,
			}),
		delete: deleteModule,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(Video360ModuleContainer));
