import { DISTRICTS_ALL } from '@sep/br24-constants';
import { Select, Spin } from 'antd';
import { uniqueId } from 'lodash-es';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';
import { upperCaseFirst } from '@/client/util/br24Utils';

import ErrorMessageText from '../../../ui/ErrorMessageText';
import type { CurrentFilterType } from '../Dashboard';
import type { District } from '../ModuleAllocationStats/__generated__/ModuleAllocationStatsContainerQuery.graphql';

import DataFilterRenderer from './DataFilterRenderer';
import type { DataFilterRendererQuery } from './__generated__/DataFilterRendererQuery.graphql';
import { removeFilterPrefix } from './util/removeFilterPrefix';

const { Option, OptGroup } = Select;

const StyledSelect = styled(Select<string>)`
	width: 300px;
`;

const Wrapper = styled.div``;

const ResetFilterDiv = styled.div`
	margin-top: 5px;

	&:hover {
		color: #0a9ed7;
		cursor: pointer;
	}
`;

const PREFIX_REGIONEN = 'region';
const PREFIX_CATEGORY = 'kategorie';

type FilterResponse = {
	props: DataFilterRendererQuery['response'] | null;
	error: Error | null;
};

type Props = {
	selectedValue?: string;
	onChange: (filter: CurrentFilterType, selectedValue: string, selectedText: string) => void;
	onResetFilter: () => void;
};

function buildGraphqlFilter(value: string) {
	let computedFilter: CurrentFilterType = null;

	if (!value) {
		return null;
	}

	if (value.includes(PREFIX_REGIONEN)) {
		computedFilter = {
			districtsFilter: [removeFilterPrefix(value, PREFIX_REGIONEN)].filter(
				(filter): filter is string => !!filter
			) as District[],
		};
	} else if (value.includes(PREFIX_CATEGORY)) {
		computedFilter = {
			categoryFilter: removeFilterPrefix(value, PREFIX_CATEGORY),
		};
	}

	return computedFilter;
}

export default function DataFilter({ selectedValue, onChange, onResetFilter }: Props) {
	const translate = useTranslate();

	const handleSelectChange = (value: string, component: any) => {
		const computedGraphqlFilter = buildGraphqlFilter(value);
		const selectedText = component.props.children;

		if (computedGraphqlFilter) {
			onChange(computedGraphqlFilter, value, selectedText);
		}
	};

	const renderFilter = ({ error, props }: FilterResponse) => {
		if (error) {
			return (
				<div>
					<ErrorMessageText hasBottomMargin={true} text={translate('dashboard.filter.errorText')} />
				</div>
			);
		}

		if (props) {
			const categories = props?.allCategories?.edges || [];

			return (
				<Wrapper>
					<StyledSelect
						value={selectedValue}
						placeholder={translate('dashboard.filter.placeholder')}
						onChange={handleSelectChange}
					>
						<OptGroup label={translate('dashboard.filter.dropdownLabelRegions')}>
							{DISTRICTS_ALL.map((district) => (
								<Option
									key={`${uniqueId()}_${district.toLocaleLowerCase()}`}
									value={`${PREFIX_REGIONEN}_${district}`}
								>
									{upperCaseFirst(district.toLocaleLowerCase())}
								</Option>
							))}
						</OptGroup>
						<OptGroup label={translate('dashboard.filter.dropwdownLabelCategory')}>
							{categories.length > 0
								? categories.map(({ node }: any) => {
										if (!node) {
											return null;
										}

										return (
											<Option
												key={`${uniqueId().toString()}_${node.rowId}`}
												value={`${PREFIX_CATEGORY}_${node.rowId}`}
											>
												{node.title}
											</Option>
										);
								  })
								: null}
						</OptGroup>
					</StyledSelect>
					<ResetFilterDiv onClick={onResetFilter}>
						{translate('dashboard.filter.resetFilterText')}
					</ResetFilterDiv>
				</Wrapper>
			);
		}

		return (
			<div>
				<Spin size="small" />
			</div>
		);
	};

	return <DataFilterRenderer renderFilter={renderFilter} />;
}
