import { Spin, Alert } from 'antd';
import { Suspense, useEffect } from 'react';
import { useQueryLoader } from 'react-relay';
import { useParams } from 'react-router';

import { ErrorBoundaryWithRetry } from '../ErrorBoundaryWithRetry';
import { Page } from '../Page';

import { Navigation } from './Navigation';
import { navigationQuery } from './Navigation.graphql';
import type { NavigationQuery } from './__generated__/NavigationQuery.graphql';

function Content() {
	const { id } = useParams<{ id?: string }>();

	if (!id) {
		throw new Error('No id provided');
	}

	const [queryReference, loadQuery] = useQueryLoader<NavigationQuery>(navigationQuery);

	useEffect(() => {
		loadQuery(
			{ rowId: parseInt(id, 10) },
			{ fetchPolicy: 'store-and-network', networkCacheConfig: { force: true } }
		);
	}, [id, loadQuery]);

	// if (error) {
	// 	return <Alert message="Fehler" description={error.message} type="error" />;
	// }

	return queryReference ? <Navigation refresh={loadQuery} queryRef={queryReference} /> : null;
}

export default function NavigationContainer() {
	return (
		<Page style={{ padding: 0 }}>
			<ErrorBoundaryWithRetry
				fallback={({ error }) => (
					<Alert message="Fehler" description={error?.message} type="error" />
				)}
			>
				<Suspense fallback={<Spin size="large" />}>
					<Content />
				</Suspense>
			</ErrorBoundaryWithRetry>
		</Page>
	);
}
