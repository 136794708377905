import { EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Tooltip, Button, Space } from 'antd';
import moment from 'moment';
import { Component, type MouseEvent } from 'react';
import { shouldUpdate } from 'should-update';

import { withTranslation } from '@/client/translation/withTranslation';

import config from '../../config';
import type { WithTranslationProps } from '../../translation';
import translations from '../../translations';
import { LockedByAuthorBoard } from '../LockedByAuthor';
import Status from '../Status';

import styles from './BoardsTable.module.scss';
import { categoryNames } from './util';
import type { TransformedBoard } from './util/transformBoards';

type Props = WithTranslationProps & {
	node: TransformedBoard;
	categoryCount?: number;
	onClickOpen: (event: MouseEvent, node: TransformedBoard) => void;
};

class BoardsTableRow extends Component<Props> {
	shouldComponentUpdate(nextProps: Props) {
		return shouldUpdate(['node', 'onClickOpen', 'users', 'categoryCount'], this.props, nextProps);
	}

	getUpdatedAt = <T extends { updatedAt: string | null; createdAt: string | null }>(record: T) => {
		if (!record.updatedAt) {
			return moment.utc(record.createdAt).local().fromNow();
		}

		if (moment.utc(record.updatedAt).isBefore(moment.utc().subtract(12, 'hours'))) {
			return moment.utc(record.updatedAt).local().format(config.moment.absoluteDate);
		}

		return moment.utc(record.updatedAt).local().fromNow();
	};

	render() {
		const {
			node,
			onClickOpen,
			categoryCount,
			translation: { translate },
		} = this.props;

		return (
			<tr onClick={(event) => onClickOpen(event, node)}>
				{categoryCount ? (
					<td rowSpan={categoryCount} className={styles.categories}>
						<span className={styles.tableHeader}>{translate(categoryNames(node.section))}</span>
					</td>
				) : (
					false
				)}
				<td>{node.title}</td>
				<td>{node.fullSlug}</td>

				<td className={styles.activeUsers}>
					<LockedByAuthorBoard board={node} />
				</td>

				<td>{this.getUpdatedAt(node)}</td>

				<td>
					<Tooltip title={node.status ? translations[node.status] : ''}>
						<Status
							status={node.status ?? undefined}
							publicationDate={moment.utc(node.createdAt).local()}
						/>
					</Tooltip>
				</td>

				<td className={styles.lastColumn}>
					<Space>
						<Button size="small" disabled={true}>
							{node.protected ? <LockOutlined /> : <UnlockOutlined />}
						</Button>
						<Button size="small" type="primary">
							<EditOutlined />
						</Button>
					</Space>
				</td>
			</tr>
		);
	}
}

export default withTranslation(BoardsTableRow);
