import { commitMutation, type Environment, graphql } from 'react-relay';

import type { BoardDepositHistoryMutation as BoardDepositHistoryMutationTypes } from './__generated__/BoardDepositHistoryMutation.graphql';

const mutation = graphql`
	mutation BoardDepositHistoryMutation($input: CreateHistoryForBoardInput!) {
		createHistoryForBoard(input: $input) {
			history {
				rowId
			}
		}
	}
`;

export default (
	boardRowId: string,
	environment: Environment
): Promise<BoardDepositHistoryMutationTypes['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<BoardDepositHistoryMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					affectedBoardId: boardRowId,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
