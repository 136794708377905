import styled from 'styled-components';

import { QuoteStart, QuoteEnd } from '../Icon';

import type { SlateRenderNodeProps } from './formattingTypes';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const QuoteStartIcon = styled(QuoteStart)`
	margin-right: 1rem;
`;

const QuoteEndIcon = styled(QuoteEnd)`
	margin-left: 1rem;
	align-self: flex-end;
`;

function BlockquoteNode({ attributes, children }: SlateRenderNodeProps) {
	return (
		<Wrapper>
			<QuoteStartIcon />
			<blockquote {...attributes}>{children}</blockquote>
			<QuoteEndIcon />
		</Wrapper>
	);
}

export default BlockquoteNode;
