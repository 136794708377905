import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../../translation';
import { ArticleModuleItem, ArticleBrokenModule } from '../../../../ui/ArticleModule';
import deleteModule from '../../actions/deleteModule';
import { activeArticleModuleSelector } from '../../selectors';

interface Props extends WithTranslationProps, ReduxProps, OwnProps {}

class BrokenModuleContainer extends Component<Props> {
	handleDelete = () => {
		this.props.delete(this.props.id);
	};

	render() {
		const {
			translation: { translate },
		} = this.props;

		return (
			<ArticleModuleItem
				label={translate('modules.brokenModule.title')}
				onDelete={this.handleDelete}
			>
				<ArticleBrokenModule errorText={translate('modules.brokenModule.text')} />
			</ArticleModuleItem>
		);
	}
}

const connector = connect(
	(state: ReduxState, { id }: OwnProps) => ({
		module: activeArticleModuleSelector(state, id),
	}),
	{
		delete: deleteModule,
	}
);

interface OwnProps {
	id: string | number;
}

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(BrokenModuleContainer));
