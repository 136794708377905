import type { ComponentType } from 'react';

export function getDisplayName(component: ComponentType<any>) {
	if (typeof component === 'string') {
		return component;
	}

	if (!component) {
		return undefined;
	}

	return component.displayName || component.name || 'Component';
}
