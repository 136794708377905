import type { ItemAudio, ImportedItemAudio } from '..';
import PlaceholderImage from '../../../images/placeholder.png';
import { AssetManagerItemAudio as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemAudio | ImportedItemAudio;
};

function AssetManagerItemAudio({ item }: Props) {
	return (
		<Wrapper
			image={item.thumbnail?.url ?? PlaceholderImage}
			title={item.title ?? ''}
			copyright={item.copyright}
			duration={item.duration}
		/>
	);
}

export default AssetManagerItemAudio;
