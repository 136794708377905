import {
	SORT_PUBL_DESC,
	SORT_PUBL_ASC,
	AM_UPDATE,
	AM_SET,
	AM_TEMPLATE_ADD,
	AM_TEMPLATE_DELETE,
	AM_TEMPLATE_SELECT,
	AM_UPDATE_SELECTION,
	STATUS_SCHEDULED,
	STATUS_REVIEW,
	STATUS_PUBLISHED,
	STATUS_DRAFT,
} from '@sep/br24-constants';
import ih from 'immutability-helper';

import type { FilterArticlesSort } from '@/client/components/Articles/ArticlesTable/__generated__/ArticlesTableContainerQuery.graphql';
import { randomId } from '@/client/util/br24Utils';
import { generateReducer } from '@/client/util/generateReducer';
import type { Capability, Status } from '@/types/schema';

import type { AppThunkAction } from '../redux';

const STORAGE_KEY = 'br24-af-templates';

export type FilterQuery = {
	searchTerm?: string;
	publishedBefore?: string;
	categoryFilter?: string;
	tagsFilter?: Array<string>;
	capabilityFilter?: Array<Capability>;
	statusFilter?: Status;
	sortBy?: FilterArticlesSort;
	onlyTemplates?: boolean;
};

export type Template = {
	id: string;
	name: string;
	custom: boolean;
	filter: FilterQuery;
};

export type State = {
	selection: string[];
	currentFilter: FilterQuery;
	selectedTemplateId: string | undefined | null;
	templates: Array<Template>;
};

type ArticlesManagerUpdateSelectionAction = {
	type: typeof AM_UPDATE_SELECTION;
	payload: string[];
};

type ArticlesManagerUpdateAction = {
	type: typeof AM_UPDATE;
	payload: FilterQuery;
};

type ArticlesManagerSetAction = {
	type: typeof AM_SET;
	payload: any;
};

type ArticlesManagerDeleteTemplateAction = {
	type: typeof AM_TEMPLATE_DELETE;
	payload: string;
};

type ArticlesManagerAddTemplateAction = {
	type: typeof AM_TEMPLATE_ADD;
	payload: any;
};

type ArticleManagerSelectTemplateAction = {
	type: typeof AM_TEMPLATE_SELECT;
	payload: string | null;
};

function loadTemplates(): Array<Template> {
	return [...(JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]') || [])];
}

const initialState: State = {
	selection: [],
	currentFilter: {},
	selectedTemplateId: null,
	templates: [
		{
			id: '1',
			name: 'Publizierte Artikel',
			custom: false,
			filter: {
				statusFilter: STATUS_PUBLISHED,
				sortBy: SORT_PUBL_DESC,
			},
		},
		{
			id: '2',
			name: 'Zeitgesteuerte Artikel',
			custom: false,
			filter: {
				statusFilter: STATUS_SCHEDULED,
				sortBy: SORT_PUBL_ASC,
			},
		},
		{
			id: '3',
			name: 'Freigabe erwartende Artikel',
			custom: false,
			filter: {
				statusFilter: STATUS_REVIEW,
			},
		},
		{
			id: '4',
			name: 'Entwürfe',
			custom: false,
			filter: {
				statusFilter: STATUS_DRAFT,
			},
		},
		{
			id: '5',
			name: 'Vorlagen',
			custom: false,
			filter: {
				onlyTemplates: true,
			},
		},
		...loadTemplates(),
	],
};

const filter = new URL(window.location.href).searchParams.get('filter');

if (filter) {
	try {
		const storedFilter = JSON.parse(decodeURIComponent(filter));
		initialState.currentFilter = storedFilter;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(`Cannot parse filter (reason: ${(err as Error).message})`);
	}
}

export default generateReducer(
	initialState,
	{
		[AM_UPDATE_SELECTION]: (state, action: ArticlesManagerUpdateSelectionAction) =>
			ih(state, {
				selection: {
					$set: action.payload,
				},
			}),
		[AM_UPDATE]: (state, action: ArticlesManagerUpdateAction) =>
			ih(state, { currentFilter: { $merge: action.payload } }),
		[AM_SET]: (state, action: ArticlesManagerSetAction) =>
			ih(state, {
				currentFilter: {
					$set: {
						...action.payload,
						searchTerm: action.payload.searchTerm || undefined,
					},
				},
			}),
		[AM_TEMPLATE_SELECT]: (state, action: ArticleManagerSelectTemplateAction) =>
			ih(state, {
				selectedTemplateId: {
					$set: action.payload,
				},
			}),
		[AM_TEMPLATE_ADD]: (state, action: ArticlesManagerAddTemplateAction) =>
			ih(state, {
				templates: {
					$push: [action.payload],
				},
			}),
		[AM_TEMPLATE_DELETE]: (state, action: ArticlesManagerDeleteTemplateAction) => ({
			...state,
			templates: state.templates.filter((template) => template.id !== action.payload),
		}),
	},
	(nextState, action) => {
		if (action.type === AM_TEMPLATE_ADD) {
			localStorage.setItem(
				STORAGE_KEY,
				JSON.stringify([...nextState.templates.filter((template) => template.custom)])
			);
		}
	}
);

export function updateSelection(payload: Array<string>): ArticlesManagerUpdateSelectionAction {
	return {
		type: AM_UPDATE_SELECTION,
		payload,
	};
}

export function updateFilter(payload: FilterQuery): ArticlesManagerUpdateAction {
	return {
		type: AM_UPDATE,
		payload,
	};
}

export function resetSelectedTemplateId(): ArticleManagerSelectTemplateAction {
	return {
		type: AM_TEMPLATE_SELECT,
		payload: null,
	};
}

export function setFilter(payload: any): ArticlesManagerSetAction {
	return {
		type: AM_SET,
		payload,
	};
}

export function refresh(): ArticlesManagerUpdateAction {
	return updateFilter({});
}

export function activateTemplate(
	id: string
): AppThunkAction<ArticlesManagerSetAction | ArticleManagerSelectTemplateAction> {
	return (dispatch, getState) => {
		const { articlesManager } = getState();
		const matchedFilters = articlesManager.templates.filter((template) => template.id === id);

		if (matchedFilters.length === 0) {
			return;
		}

		const affected = matchedFilters[0];

		dispatch({ type: AM_TEMPLATE_SELECT, payload: affected.id });
		dispatch(setFilter(affected.filter));
	};
}

export function addTemplate(
	name: string,
	addTemplateFilter: FilterQuery
): ArticlesManagerAddTemplateAction {
	return {
		type: AM_TEMPLATE_ADD,
		payload: {
			id: randomId(),
			name,
			custom: true,
			filter: addTemplateFilter,
		},
	};
}

export function deleteTemplate(id: string): ArticlesManagerDeleteTemplateAction {
	return {
		type: AM_TEMPLATE_DELETE,
		payload: id,
	};
}
