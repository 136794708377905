import { WarningOutlined } from '@ant-design/icons';
import {
	COLOR_ASBESTOS,
	COLOR_SUN_FLOWER,
	PUSH_NOTIFICATION_NONE,
	PUSH_NOTIFICATION_SILENT,
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
} from '@sep/br24-constants';
import { Affix, Layout, Button, Modal, Switch, Tooltip } from 'antd';
import { Component, Suspense } from 'react';
import type { ReactNode } from 'react';
import { Prompt } from 'react-router';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';
import { ArticleStatusNotes } from '@/client/ui/StatusBar/ArticleStatusNotes';

import type { WithTranslationProps } from '../../translation';
import { Bell, Preview } from '../../ui/Icon';
import ArticleStatusActionDropdown from '../../ui/StatusBar/ArticleStatusActionDropdown';
import ArticleStatusSaveButton from '../../ui/StatusBar/ArticleStatusSaveButton';
import ArticleStatusTimeControlDropdown from '../../ui/StatusBar/ArticleStatusTimeControlDropdown';
import type { TimeControlDropdownOnChangeAttribute } from '../../ui/StatusBar/ArticleStatusTimeControlDropdown';
import HistoryDropdown from '../../ui/StatusBar/HistoryDropdown';
import { media } from '../../util/styled';
import type { Expiration } from '../ArticleEditor/__generated__/ArticleEditor_article.graphql';
import type { HistoryTimelineItemType } from '../HistoryTimeline';

import { AvatarLockInfo } from './AvatarLockInfo/AvatarLockInfo';

const StyledLayout = styled(Layout)`
	margin-bottom: 10px;
`;

type LayoutContentProps = {
	$isAffixed: boolean;
	$isTemplate?: boolean;
};

const LayoutContent = styled(Layout.Content)<LayoutContentProps>`
	border: 1px solid
		${(props: LayoutContentProps) => (props.$isTemplate ? COLOR_SUN_FLOWER : 'rgba(0, 0, 0, 0.15)')};
	display: flex;
	align-items: center;
	padding: 0.6rem 1rem;
	background-color: #fff;
	${(props: LayoutContentProps) =>
		props.$isAffixed
			? `
		position: fixed;
		z-index: 1;
		top: 0px;
		left: 0px;
		right: 0px;
		height: 60px;
		box-shadow: 0 1px 5px ${props.$isTemplate ? COLOR_SUN_FLOWER : 'rgba(0, 0, 0, 0.15)'};`
			: ''};
`;

const MarginItem = styled.div`
	margin: 0 1rem;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
`;

const BellIcon = styled(Bell)`
	margin-left: 1rem;
`;

const UsersItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 1rem;
`;

const PreviewButtonWrapper = styled(MarginItem)`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	margin-left: 1rem;
`;

const PreviewButton = styled(Button)`
	font-size: 1.2em;
`;

const PreviewButtonTitle = styled.span`
	margin-left: 1rem;
	${media.lessThan('giant')`
		display: none;
	`};
`;

const PushModalHeadline = styled.span`
	color: #ff0000;
`;

const TimeControlWrapper = styled(MarginItem)`
	margin-left: 1rem;
`;

const StatusWrapper = styled(MarginItem)`
	display: flex;
	margin-left: 1rem;
`;

const PushNotificationWrapper = styled.div`
	margin-left: 1rem;
`;

const Title = styled.h2`
	font-size: 1.4em;
	line-height: 34px;
	color: ${COLOR_ASBESTOS};
	white-space: nowrap;
	${media.lessThan('giant')`
		font-size: 1.2em;
	`};
`;

type HeaderBarProps = {
	affixed: boolean;
	children?: ReactNode;
	onChangeAffix: (affixed?: boolean | undefined) => void;
};

function HeaderBar({ affixed, children, onChangeAffix }: HeaderBarProps) {
	return affixed ? (
		<Affix onChange={onChangeAffix}>
			{/* children seems to be multiple nodes so we need to wrap it in a fragment. Otherwise resizeobserver complains */}
			<div>{children}</div>
		</Affix>
	) : (
		<div>{children}</div>
	);
}

type Status =
	| typeof STATUS_DRAFT
	| typeof STATUS_REVIEW
	| typeof STATUS_SCHEDULED
	| typeof STATUS_DEPUBLISHED
	| typeof STATUS_PUBLISHED
	| typeof STATUS_DELETED;

export type OnChangeAttribute = TimeControlDropdownOnChangeAttribute | 'pushNotification';

type Props = WithTranslationProps & {
	pushNotification: string;
	affixed: boolean;
	articleId: string | null;
	content: string;
	defaultDepublicationDate: string | undefined | null;
	defaultDepublicationId: Expiration | null;
	defaultPublicationDate: string | undefined | null;
	draggableIcon: ReactNode | undefined | null;
	historyItem: HistoryTimelineItemType | undefined;
	isNotification: boolean;
	isSaveEnabled: boolean;
	isSaving: boolean;
	isTemplate: boolean;
	onChange: (x: OnChangeAttribute, y: any) => void;
	onPreviewClick: () => void;
	onSaveClick: () => void;
	onStatusClick: (x: Status) => void;
	status: Status | null | undefined;
	title: string | undefined | null;
	totalHistoryItems: number;
	lockedBy?: string | null;
	hasArticleNotes: boolean;
};

type State = {
	$isAffixed: boolean;
	isPushNotificationModalVisible: boolean;
};

class ArticleStatusBar extends Component<Props, State> {
	form: Record<string, unknown>;

	constructor(props: Props) {
		super(props);
		this.state = {
			$isAffixed: false,
			isPushNotificationModalVisible: false,
		};
		this.form = {};
	}

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.affixed && this.props.affixed) {
			this.handleChangeAffix(this.state.$isAffixed);
		}
	}

	handleChangeAffix = (affixed?: boolean | undefined) => {
		if (this.props.affixed) {
			this.setState({ $isAffixed: !!affixed });
		}
	};

	handleChangePushNotificationSilent = (flag: boolean) => {
		const { status, onSaveClick } = this.props;

		this.handleOnChange(
			'pushNotification',
			flag ? PUSH_NOTIFICATION_SILENT : PUSH_NOTIFICATION_NONE
		);

		if (flag && status === STATUS_PUBLISHED) {
			this.setState({ isPushNotificationModalVisible: true });
		} else {
			this.setState({ isPushNotificationModalVisible: false });

			// SAVING is necessary here to enable re-publishing of PUSHES.
			// see br24-core/src/schema/functions/update_article_trigger.pgsql
			// Save once when disabling push, and once when enabling push toggle.
			onSaveClick();
		}
	};

	handleOnChange = (attr: OnChangeAttribute, value: any) => {
		if (this.props.onChange) {
			this.props.onChange(attr, value);
		}
	};

	handlePushNotificationModalCancel = () => {
		this.handleChangePushNotificationSilent(false);
	};

	handlePushNotificationModalOk = () => {
		const { onSaveClick } = this.props;

		this.setState({ isPushNotificationModalVisible: false });
		onSaveClick();
	};

	renderNotification = () => {
		const {
			isNotification,
			isSaving,
			translation: { translate },
		} = this.props;

		if (isNotification) {
			return <BellIcon size="lg" />;
		}

		return (
			<PushNotificationWrapper>
				<Tooltip title={translate('article.notification.tooltip')} placement="bottom">
					<Switch
						checked={this.props.pushNotification !== PUSH_NOTIFICATION_NONE}
						disabled={isSaving}
						onChange={this.handleChangePushNotificationSilent}
					/>
				</Tooltip>
			</PushNotificationWrapper>
		);
	};

	renderPreviewButton = () => {
		const {
			translation: { translate },
		} = this.props;
		const btnText =
			this.props.status === STATUS_PUBLISHED
				? translate('article.previewButton.toArticle')
				: translate('article.previewButton.preview');
		return (
			<PreviewButton
				disabled={this.props.isSaving}
				type="primary"
				onClick={() => {
					this.props.onPreviewClick();
				}}
			>
				<Preview />
				<PreviewButtonTitle>{btnText}</PreviewButtonTitle>
			</PreviewButton>
		);
	};

	renderTitle = () => {
		const {
			isNotification,
			title,
			translation: { translate },
		} = this.props;
		if (title) {
			const pushNotificationString =
				!isNotification && this.props.pushNotification === PUSH_NOTIFICATION_SILENT
					? translate('article.notification.silent')
					: '';
			const titleString = pushNotificationString ? `${title} ${pushNotificationString}` : title;
			return <Title>{titleString}</Title>;
		}
		return null;
	};

	render() {
		const {
			affixed,
			articleId,
			defaultDepublicationDate,
			defaultDepublicationId,
			defaultPublicationDate,
			draggableIcon,
			historyItem,
			isSaveEnabled,
			isSaving,
			isTemplate,
			onSaveClick,
			onStatusClick,
			status,
			lockedBy,
			totalHistoryItems,
			hasArticleNotes,
			translation: { translate },
		} = this.props;

		const tooltipText = isSaveEnabled
			? translate('article.saveButton.save')
			: translate('article.saveButton.locked');

		return (
			<HeaderBar affixed={affixed} onChangeAffix={this.handleChangeAffix}>
				<StyledLayout>
					<LayoutContent $isAffixed={this.state.$isAffixed}>
						{draggableIcon}
						{this.renderNotification()}
						<MarginItem>{this.renderTitle()}</MarginItem>
						<UsersItem>
							<Suspense fallback={''}>
								<AvatarLockInfo lockedBy={lockedBy} />
							</Suspense>
						</UsersItem>
						<MarginItem>
							<ArticleStatusNotes
								tooltip={translate('articles.notes')}
								isDisabled={!hasArticleNotes}
							/>
						</MarginItem>
						<MarginItem>
							<HistoryDropdown
								articleId={articleId}
								historyItem={historyItem}
								isDisabled={isSaving}
								totalItems={totalHistoryItems}
							/>
						</MarginItem>
						<PreviewButtonWrapper>
							<Tooltip title={translate('article.previewButton.tooltip')} placement="bottom">
								{this.renderPreviewButton()}
							</Tooltip>
						</PreviewButtonWrapper>
						{!isTemplate ? (
							<TimeControlWrapper>
								<ArticleStatusTimeControlDropdown
									defaultDepublicationDate={defaultDepublicationDate}
									defaultDepublicationId={defaultDepublicationId}
									defaultPublicationDate={defaultPublicationDate}
									isDisabled={isSaving}
									isDisabledPublication={status === STATUS_PUBLISHED}
									onChange={this.handleOnChange}
								/>
							</TimeControlWrapper>
						) : null}
						<StatusWrapper>
							<ArticleStatusActionDropdown
								isDisabled={isSaving}
								isTemplate={isTemplate}
								onClick={onStatusClick}
								status={status}
							/>
							<Prompt
								when={isSaveEnabled}
								message={() => {
									return `Ungespeicherte Änderungen erkannt. Wollen Sie diese verwerfen?`;
								}}
							/>
							<ArticleStatusSaveButton
								isSaveEnabled={isSaveEnabled}
								isSaving={isSaving}
								isTemplate={isTemplate}
								onSaveClick={onSaveClick}
								status={status}
								tooltip={tooltipText}
							/>
						</StatusWrapper>
					</LayoutContent>
				</StyledLayout>

				<Modal
					title={
						<PushModalHeadline>
							<WarningOutlined style={{ marginRight: '5px' }} />
							{translate('article.notificationModal.headline')}
						</PushModalHeadline>
					}
					open={this.state.isPushNotificationModalVisible}
					onOk={this.handlePushNotificationModalOk}
					okText={translate('article.notificationModal.save')}
					cancelText={translate('article.notificationModal.cancel')}
					onCancel={this.handlePushNotificationModalCancel}
				>
					<p>{translate('article.notificationModal.text')}</p>
				</Modal>
			</HeaderBar>
		);
	}
}

export default withTranslation(ArticleStatusBar);
