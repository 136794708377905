/**
 * @generated SignedSource<<9fb9d092719e592d70607a97c15e7562>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NavigationType = "ARTICLE" | "BOARD" | "CUSTOM" | "NAVIGATION";
import { FragmentRefs } from "relay-runtime";
export type Navigation_item$data = {
  readonly articleByArticle: {
    readonly rowId: string;
    readonly title: string;
  } | null;
  readonly boardByBoard: {
    readonly rowId: string;
    readonly title: string;
  } | null;
  readonly color: string | null;
  readonly id: string;
  readonly order: number;
  readonly parent: number | null;
  readonly path: string | null;
  readonly rowId: number;
  readonly text: string | null;
  readonly type: NavigationType;
  readonly " $fragmentType": "Navigation_item";
};
export type Navigation_item$key = {
  readonly " $data"?: Navigation_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Board",
      "kind": "LinkedField",
      "name": "boardByBoard",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "articleByArticle",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    }
  ],
  "type": "Navigation",
  "abstractKey": null
};
})();

(node as any).hash = "621761138ccaacb27f3ad26235f4baee";

export default node;
