import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Component } from 'react';

import styles from './ClickHandler.module.scss';

type Props = {
	rowId: string;
	handleClick: (rowId: string) => void;
};

export default class ClickHandler extends Component<Props> {
	handleClick = () => {
		const { rowId, handleClick } = this.props;
		handleClick(rowId);
	};

	render() {
		return (
			<Button className={styles.editButton} onClick={this.handleClick}>
				<EditOutlined key="open" />
			</Button>
		);
	}
}
