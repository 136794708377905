const translations = {
	NM_TAB_NAME_ARTICLE: 'Artikel',
	NM_TAB_NAME_BOARD: 'Board',
	NM_TAB_NAME_CUSTOM: 'Benutzerdefiniert',
	NM_LINK_TITLE_LABEL: 'Link-Titel',
	NM_LINK_TITLE_PLACEHOLDER: 'Link-Titel eingeben',
	NM_LINK_TITLE_ERROR: 'Bitte einen Link-Titel eingeben.',
	NM_BOARD_SELECT_LABEL: 'Board',
	NM_BOARD_SELECT_PLACEHOLDER: 'Board auswählen',
	NM_BOARD_SELECT_ERROR: 'Bitte ein Board auswählen.',
	NM_BOARD_CHECKBOX_LABEL: 'Link-Titel ändern',
	NM_BOARD_CHECKBOX_TEXT: 'Link-Titel ist anders als Board-Titel',
	NM_BOARD_DIFFTITLE_PLACEHOLDER: 'Alternativen Link-Titel eingeben (optional)',
	NM_LINK_TARGET_LABEL: 'Link-Ziel',
	NM_LINK_TARGET_HELP:
		'Entweder den Link in einem neuen Browser-Fenster öffnen lassen (externe Links) oder im selben Fenster öffnen (interne Links).',
	NM_ARTICLE_SEARCH_LABEL: 'Zu verlinkender Artikel',
	NM_ARTICLE_SEARCH_PLACEHOLDER: 'Artikel-Überschrift eingeben',
	NM_ARTICLE_SEARCH_ERROR: 'Bitte einen Artikel angeben.',
	NM_COLOR_PICKER_LABEL: 'Link-Farbe',
	NM_CUSTOM_LINK_LABEL: 'Link',
	NM_CUSTOM_LINK_PLACEHOLDER: 'URL eintragen',
	NM_CUSTOM_LINK_ERROR: 'Bitte eine URL angeben',
	NM_EDITOR_HEADLINE: 'Navigation bearbeiten',
	NM_ADD_TOPLEVEL_NAME: 'Navigationspunkt hinzufügen',
	NM_SAVE_CONFIRMATION_HEADLINE: 'Navigation speichern',
	NM_SAVE_CONFIRMATION_EXPLINATION:
		'Bitte beachte, dass es für die geänderte Navigation keine weitere Freigabestufe gibt und die Änderungen direkt im Live-Betrieb sichtbar werden.',
	NM_SAVE_CONFIRMATION_ASK: 'Möchtest du die neue Navigation freigeben?',
	NM_GO_BACK_BUTTON_NAME: 'Übersicht',
};

export default translations;
