import update from 'immutability-helper';

import type { BoardsTeaser_boardsTeaser$data } from '@/client/components/BoardsTeaser/__generated__/BoardsTeaser_boardsTeaser.graphql';
import type { ActionWithPayload } from '@/client/store/redux';

/*
import {
	BOARDS_TEASER_BUFFER_INITIALIZE,
	BOARDS_TEASER_BUFFER_UPDATE,
	BOARDS_TEASER_BUFFER_CLEAN,
} from '@sep/br24-constants';
*/

const BOARDS_TEASER_BUFFER_INITIALIZE = 'BOARDS_TEASER_BUFFER_INITIALIZE';
const BOARDS_TEASER_BUFFER_UPDATE = 'BOARDS_TEASER_BUFFER_UPDATE';
const BOARDS_TEASER_BUFFER_CLEAN = 'BOARDS_TEASER_BUFFER_CLEAN';

export type BoardsTeaserBuffer = Omit<
	BoardsTeaser_boardsTeaser$data,
	' $fragmentType' | ' $fragmentSpreads'
>;

export type BoardsTeaserBufferState = {
	[id: string]: BoardsTeaserBuffer;
};

type BoardsTeaserBufferInitializeAction = ActionWithPayload<
	typeof BOARDS_TEASER_BUFFER_INITIALIZE,
	{
		id: string;
		data: BoardsTeaserBuffer;
	}
>;

type BoardsTeaserBufferUpdateAction = ActionWithPayload<
	typeof BOARDS_TEASER_BUFFER_UPDATE,
	{
		id: string;
		data: Partial<BoardsTeaserBuffer>;
	}
>;

type BoardsTeaserBufferCleanAction = ActionWithPayload<typeof BOARDS_TEASER_BUFFER_CLEAN, string>;
type BoardsTeaserBufferAction =
	| BoardsTeaserBufferInitializeAction
	| BoardsTeaserBufferUpdateAction
	| BoardsTeaserBufferCleanAction;

const initialState: BoardsTeaserBufferState = {};

/**
 * The boardsTeaser reducer.
 *
 * @return {object}
 */
export default function reducer(
	state: BoardsTeaserBufferState = initialState,
	action: BoardsTeaserBufferAction
) {
	if (!action) {
		return { ...state };
	}

	if (action.type === BOARDS_TEASER_BUFFER_INITIALIZE) {
		const { id, data } = action.payload;
		return update(state, {
			[id]: {
				$set: data,
			},
		});
	}
	if (action.type === BOARDS_TEASER_BUFFER_UPDATE) {
		const { id, data } = action.payload;
		return update(state, {
			[id]: {
				$merge: data,
			},
		});
	}
	if (action.type === BOARDS_TEASER_BUFFER_CLEAN) {
		const nextState = { ...state };
		delete nextState[action.payload];
		return nextState;
	}

	return { ...state };
}

export function initializeBuffer(
	id: string,
	data: BoardsTeaserBuffer
): BoardsTeaserBufferInitializeAction {
	return {
		type: BOARDS_TEASER_BUFFER_INITIALIZE,
		payload: { id, data },
	};
}
export function updateBuffer(
	id: string,
	data: Partial<BoardsTeaserBuffer>
): BoardsTeaserBufferUpdateAction {
	return {
		type: BOARDS_TEASER_BUFFER_UPDATE,
		payload: { id, data },
	};
}
export function cleanBuffer(id: string): BoardsTeaserBufferCleanAction {
	return {
		type: BOARDS_TEASER_BUFFER_CLEAN,
		payload: id,
	};
}
