import { BOARD_BUFFER_SECTION_ORDER, BOARD_BUFFER_SECTION_ITEM_ORDER } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import {
	findBoardById,
	findSectionById,
	findSectionItemById,
	findSectionByBoardIdAndPosition,
	findSectionItemBySectionIdAndPosition,
} from '../util/find';

import { validateSection } from './validate';

export type OrderSectionAction = {
	type: typeof BOARD_BUFFER_SECTION_ORDER;
	constraint: {
		boardId: string;
		dragOrder: number;
		hoverOrder: number;
	};
};

export function orderSection(
	boardId: string,
	dragOrder: number,
	hoverOrder: number
): AppThunkAction<OrderSectionAction> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		// validate if sections really exist
		const dragItem = findSectionByBoardIdAndPosition(boardId, dragOrder, getState);
		const hoverItem = findSectionByBoardIdAndPosition(boardId, hoverOrder, getState);

		if (!dragItem || !hoverItem) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SECTION_ORDER,
			constraint: {
				boardId,
				dragOrder,
				hoverOrder,
			},
		});
	};
}

export function orderSectionBySectionId(
	sectionId: string,
	dragOrder: number,
	hoverOrder: number
): AppThunkAction<OrderSectionAction> {
	return (dispatch, getState) => {
		const section = findSectionById(sectionId, getState);

		if (!section) {
			return;
		}

		dispatch(orderSection(section.__meta__.boardId, dragOrder, hoverOrder));
	};
}

export type OrderSectionItemAction = {
	type: typeof BOARD_BUFFER_SECTION_ITEM_ORDER;
	constraint: {
		boardId: string;
		sectionId: string;
		dragOrder: number;
		hoverOrder: number;
	};
};

export function orderSectionItem(
	sectionId: string,
	dragOrder: number,
	hoverOrder: number
): AppThunkAction<OrderSectionItemAction> {
	return (dispatch, getState) => {
		// validate if item really exists
		const dragItem = findSectionItemBySectionIdAndPosition(sectionId, dragOrder, getState);
		if (!dragItem) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SECTION_ITEM_ORDER,
			constraint: {
				boardId: dragItem.__meta__.boardId,
				sectionId,
				dragOrder,
				hoverOrder,
			},
		});

		dispatch(validateSection(sectionId, { skipIfAutoValidationIsDisabled: true }));
	};
}

export function orderItemInSection(
	sectionItemInternalId: string,
	hoverOrder: number
): AppThunkAction<OrderSectionItemAction> {
	return (dispatch, getState) => {
		const sectionItem = findSectionItemById(sectionItemInternalId, getState);

		if (!sectionItem) {
			return;
		}

		dispatch(orderSectionItem(sectionItem.__meta__.sectionId, sectionItem.order, hoverOrder));
	};
}
