import { COLOR_BOARD_SECTION_ALL } from '@sep/br24-constants';
import cn from 'classnames';

import Color from './Color';
import styles from './ColorPicker.module.scss';

type Props = {
	onChange: (color: string) => void;
	className?: string;
	isSmall?: boolean;
	colors: ReadonlyArray<string>;
	defaultColor?: string;
};

function ColorPicker({ isSmall, className, colors, onChange, defaultColor }: Props) {
	return (
		<div className={cn(className, styles.colorPicker)}>
			{colors.map((color) => (
				<Color
					isSmall={isSmall}
					key={color}
					color={color}
					onClick={onChange}
					isActive={color === defaultColor}
				/>
			))}
		</div>
	);
}

ColorPicker.defaultProps = {
	// If I use default props, I'm not allowed to make the prop as optional, otherwise Flow will complain, but then eslint complains.
	// eslint-disable-next-line react/default-props-match-prop-types
	colors: COLOR_BOARD_SECTION_ALL,
};

export default ColorPicker;
