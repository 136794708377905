/**
 * @generated SignedSource<<dbc32e726e109b2a254a46e3c03ef6d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorContainerQuery$variables = {
  articlesCount: number;
  authorId: string;
  cursor?: any | null;
};
export type AuthorContainerQuery$data = {
  readonly author: {
    readonly " $fragmentSpreads": FragmentRefs<"Author_author">;
  } | null;
  readonly authorArticles: {
    readonly " $fragmentSpreads": FragmentRefs<"Author_authorArticles">;
  } | null;
};
export type AuthorContainerQuery = {
  response: AuthorContainerQuery$data;
  variables: AuthorContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "articlesCount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "authorId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "kind": "Variable",
  "name": "guid",
  "variableName": "authorId"
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "articlesCount"
  },
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorContainerQuery",
    "selections": [
      {
        "alias": "author",
        "args": (v4/*: any*/),
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "authorByGuid",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Author_author"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "authorArticles",
        "args": (v5/*: any*/),
        "concreteType": "ArticlesConnection",
        "kind": "LinkedField",
        "name": "articlesByAuthor",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Author_authorArticles"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuthorContainerQuery",
    "selections": [
      {
        "alias": "author",
        "args": (v4/*: any*/),
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "authorByGuid",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "guid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profilePicture",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SocialMediaAccount",
            "kind": "LinkedField",
            "name": "socialMediaAccounts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "service",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "authorArticles",
        "args": (v5/*: any*/),
        "concreteType": "ArticlesConnection",
        "kind": "LinkedField",
        "name": "articlesByAuthor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rowId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b04962a3b70b8ea3f8ea5a2bc5cf0b0",
    "id": null,
    "metadata": {},
    "name": "AuthorContainerQuery",
    "operationKind": "query",
    "text": "query AuthorContainerQuery(\n  $authorId: String!\n  $articlesCount: Int!\n  $cursor: Cursor\n) {\n  author: authorByGuid(guid: $authorId) {\n    ...Author_author\n    id\n  }\n  authorArticles: articlesByAuthor(guid: $authorId, first: $articlesCount, after: $cursor) {\n    ...Author_authorArticles\n  }\n}\n\nfragment Author_author on Author {\n  guid\n  isActive\n  firstname\n  lastname\n  profilePicture\n  jobTitle\n  description\n  slug\n  socialMediaAccounts {\n    service\n    accountName\n    label\n  }\n}\n\nfragment Author_authorArticles on ArticlesConnection {\n  nodes {\n    rowId\n    title\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cf635d15c9a0f0348eb054df6c39d6a";

export default node;
