import styled from 'styled-components';

import { Trash2 as TrashIcon } from '../../Icon';

import { Wrapper, FooterButton } from '.';

type Props = {
	onRemove: () => void;
};

interface FlickerProps {
	size: `${number}%`;
}

const Flicker = styled.div<FlickerProps>`
	position: absolute;
	width: ${(props) => props.size};
	height: 100%;
	margin-bottom: 3px;
	background-image: linear-gradient(-90deg, currentColor, transparent);
	animation: skeleton-flicker 1s ease-in-out infinite;

	@keyframes skeleton-flicker {
		0%,
		100% {
			opacity: 0;
		}
		50% {
			opacity: 0.1;
		}
	}
`;

const Description = styled.div`
	position: relative;
	height: 1rem;
	margin-bottom: 3px;
`;
const PreviewHead = styled.div`
	position: relative;
	height: 1rem;
	margin-bottom: 1rem;
`;

const Footer = styled.div`
	display: flex;
	flex-flow: row nowrap;
	margin-top: 5px;
`;

function ArticleMetaTeasersSkeleton(props: Props) {
	const { onRemove } = props;
	return (
		<Wrapper>
			<PreviewHead>
				<Flicker size="66%" />
			</PreviewHead>
			<Description>
				<Flicker size="75%" />
			</Description>
			<PreviewHead>
				<Flicker size="100%" />
			</PreviewHead>
			<Footer>
				<FooterButton type="link" className="flex-end" onClick={onRemove} aria-label="Entfernen">
					<TrashIcon />
				</FooterButton>
			</Footer>
		</Wrapper>
	);
}

export default ArticleMetaTeasersSkeleton;
