import { Component } from 'react';
import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import {
	type WithRelayEnvironment,
	withRelayEnvironment,
} from '@/client/relay/withRelayEnvironment';
import { withTranslation, type WithTranslationProps } from '@/client/translation/withTranslation';

import type { MetaTeaserData } from '../../..';
import { ArticleMetaTeasersSkeleton, ArticleMetaTeasersError } from '../../../../../ui/ArticleMeta';
import ArticleMetaTeasersItem from '../../../../../ui/ArticleMeta/ArticleMetaTeasers/ArticleMetaTeasersItem';

import { metaText } from './ContainerTeaserItem';
import type { ContainerTeaserItemExternalQuery } from './__generated__/ContainerTeaserItemExternalQuery.graphql';

export type ExternalProps = WithTranslationProps & {
	index: number;
	url: string;
	meta: MetaTeaserData | null;
	onRemove: () => void;
	onDataFetch: (index: number, label: string) => void;
	isInvalid: boolean;
	isNewItem: boolean;
	hostname: string;
};

class ContainerTeaserItemExternal extends Component<ExternalProps & WithRelayEnvironment> {
	renderItem: QueryRendererProps<ContainerTeaserItemExternalQuery>['render'] = ({
		error,
		props,
	}) => {
		const {
			translation: { translate },
		} = this.props;

		if (this.props.isInvalid) {
			return (
				<ArticleMetaTeasersItem
					onRemove={this.props.onRemove}
					url={this.props.url}
					isExternal={true}
					title=""
					info={translate('article.meta.teasers.info.invalid')}
				/>
			);
		}
		if (error) {
			return <ArticleMetaTeasersError onRemove={this.props.onRemove} error={error.toString()} />;
		}
		if (!props) {
			return <ArticleMetaTeasersSkeleton onRemove={this.props.onRemove} />;
		}

		const { meta } = props;
		if (!meta) {
			return (
				<ArticleMetaTeasersError
					onRemove={this.props.onRemove}
					error={translate('article.meta.teasers.couldNotFetchMetaError')}
				/>
			);
		}
		const date = meta.date ? new Date(meta.date) : null;
		return (
			<ArticleMetaTeasersItem
				onDataFetch={this.props.onDataFetch}
				index={this.props.index}
				onRemove={this.props.onRemove}
				url={this.props.url}
				isExternal={true}
				meta={metaText(this.props.url, date, meta.source)}
				title={meta.title}
				description={meta.description ?? undefined}
				thumbnailUrl={meta.thumbnail ?? undefined}
			/>
		);
	};

	render() {
		if (this.props.meta) {
			return this.renderItem({
				props: { meta: this.props.meta },
				error: null,
				retry() {
					/* noop */
				},
			});
		}

		return (
			<QueryRenderer<ContainerTeaserItemExternalQuery>
				environment={this.props.environment}
				query={graphql`
					query ContainerTeaserItemExternalQuery(
						$url: String!
						$thumbnailWidth: Int
						$forceFresh: Boolean
					) {
						meta(url: $url, thumbnailWidth: $thumbnailWidth, forceFresh: $forceFresh) {
							title
							date
							description
							source
							thumbnail
						}
					}
				`}
				variables={{ url: this.props.url, thumbnailWidth: 200, forceFresh: this.props.isNewItem }}
				render={this.renderItem}
			/>
		);
	}
}

export default withTranslation(withRelayEnvironment(ContainerTeaserItemExternal));
