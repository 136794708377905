/**
 * @generated SignedSource<<7a52187e140f316785dc1683177dc41c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TagsStatus = "ACTIVE" | "RECOMMENDED" | "SPECIAL" | "SUPERSEDED";
export type TagsRendererQuery$variables = {
  searchTerm: string;
};
export type TagsRendererQuery$data = {
  readonly tags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly count: number;
        readonly rowId: number;
        readonly status: TagsStatus | null;
        readonly text: string;
      } | null;
    }>;
  } | null;
};
export type TagsRendererQuery = {
  response: TagsRendererQuery$data;
  variables: TagsRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagsRendererQuery",
    "selections": [
      {
        "alias": "tags",
        "args": (v1/*: any*/),
        "concreteType": "TagsConnection",
        "kind": "LinkedField",
        "name": "filterTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagsRendererQuery",
    "selections": [
      {
        "alias": "tags",
        "args": (v1/*: any*/),
        "concreteType": "TagsConnection",
        "kind": "LinkedField",
        "name": "filterTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c9a854619009246d3a8544da2cbcbe3",
    "id": null,
    "metadata": {},
    "name": "TagsRendererQuery",
    "operationKind": "query",
    "text": "query TagsRendererQuery(\n  $searchTerm: String!\n) {\n  tags: filterTags(first: 10, searchTerm: $searchTerm) {\n    edges {\n      node {\n        rowId\n        text\n        count\n        status\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "df8251247284c7cb04c559be7f2df948";

export default node;
