import * as constants from '@sep/br24-constants';
import { Alert, Button } from 'antd';
import { type ReactEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import ReactImageCrop, {
	convertToPercentCrop,
	convertToPixelCrop,
	type PixelCrop,
	type PercentCrop,
	type ReactCropProps,
} from 'react-image-crop';
import styled from 'styled-components';

import { useSize } from '@/client/hooks/useSize';
import 'react-image-crop/src/ReactCrop.scss';
import './ImageCropper.module.scss';
import { useTranslate } from '@/client/translation/useTranslate';

const ButtonsWrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
	gap: 1em;
`;

const AlertWrapper = styled(Alert)`
	margin-bottom: 15px !important;
	white-space: pre-wrap;
`;

/**
 * The aspect ratio used for landscape images.
 *
 * @var {Array}
 */
export const ASPECT_RATIO_LANDSCAPE = 16 / 9;

/**
 * The aspect ratio used for square images.
 *
 * @var {Array}
 */
export const ASPECT_RATIO_SQUARE = 1;

type AspectRatioSquare = typeof ASPECT_RATIO_SQUARE;
type AspectRatioHD = typeof ASPECT_RATIO_LANDSCAPE;

export type ImageCropperAspectRatio = AspectRatioSquare | AspectRatioHD;

const IMAGE_COMPRESSION_QUALITY = 0.9;

export interface Props
	extends Pick<
		ReactCropProps,
		'minWidth' | 'minHeight' | 'maxWidth' | 'maxHeight' | 'circularCrop' | 'aspect' | 'ruleOfThirds'
	> {
	onChange?: (src: string) => void;
	onReady?: () => void;

	/**
	 * if true, the component will not display save/cancel buttons and not fire
	 * onSave or onCancel events
	 */
	hideControls?: boolean;
	onConfirm?: (finalImageUrl: string) => void;
	onCancel?: () => void;

	src: string;
}

/**
 * Since `react-image-crop` does not accept absolute cropping information this component was created. It
 * takes care of converting all the given information (e.g. absolute x and y values to its percentage
 * value based on the natural image width & height).
 */
export function ImageCropper(props: Props) {
	const translate = useTranslate();

	const {
		hideControls,
		src,
		ruleOfThirds,
		aspect = ASPECT_RATIO_LANDSCAPE,
		minWidth = 100,
		minHeight = 100,
		maxHeight,
		maxWidth,
		circularCrop,
		onChange,
		onReady,
		onConfirm,
		onCancel,
	} = props;

	const [imageElement, setImageElement] = useState<HTMLImageElement | undefined | null>(null);
	const [crop, setCrop] = useState<PercentCrop | undefined>();

	const originalImageElementRef = useRef<HTMLImageElement | null>(null);
	const size = useSize(originalImageElementRef);

	const hasRectangle = useMemo(() => new URL(src).searchParams.has('rect'), [src]);

	const scaleRatio = useMemo(() => {
		if (!size || !originalImageElementRef.current) {
			return 0;
		}

		return size.width / originalImageElementRef.current.naturalWidth;
	}, [size]);

	/**
	 * Gets the original image url (without imageserver params like rect, w or h).
	 */
	const uncroppedImageUrl = useMemo<string | null>(() => {
		const url = new URL(src);

		url.searchParams.delete('rect');
		url.searchParams.delete('w');
		url.searchParams.delete('h');

		// remove the quality attribute as we set this in the br24-web client
		url.searchParams.delete('q');

		return url.href;
	}, [src]);

	/** ----------------------------------------------------------------------------------------------
	 * calcualte
	 * _______________________________________________________________________________________________ */
	const finalImageUrl = useMemo(() => {
		if (!crop || !imageElement) {
			return src;
		}

		const { naturalWidth: containerWidth, naturalHeight: containerHeight } = imageElement;

		/** ----------------------------------------------------------------------------------------------
		 * determine the crop in px by using the images natural dimensions and round the resulting object's
		 * values if it's of type number
		 * _______________________________________________________________________________________________ */
		const cropInPixels = convertToPixelCrop(crop, containerWidth, containerHeight);

		const url = new URL(src);

		url.searchParams.set(
			'rect',
			`${Math.round(cropInPixels.x)},${Math.round(cropInPixels.y)},${Math.round(
				cropInPixels.width
			)},${Math.round(cropInPixels.height)}`
		);

		url.searchParams.set('q', `${IMAGE_COMPRESSION_QUALITY}`);

		// url.searchParams.set('_v', `${Date.now()}`);

		onChange?.(url.href);
		return url.href;
	}, [crop, imageElement, onChange, src]);

	const handleChangeCrop = useCallback((_: PixelCrop, percentCrop: PercentCrop) => {
		setCrop(percentCrop);
	}, []);

	/**
	 * This function gets invoked once the image has been successfully loaded.
	 * It'll determine the initial crop and set state and references for further
	 * usage.
	 */
	const handleImageLoaded: ReactEventHandler<HTMLImageElement> = useCallback(
		({ currentTarget: element }) => {
			function setInitialCrop() {
				const { naturalWidth: containerWidth, naturalHeight: containerHeight } = element;

				const url = new URL(src);

				const rect = url.searchParams.get('rect');

				// only set crop if crop is already known in image url
				if (rect) {
					const [x = 0, y = 0, width = containerWidth, height = containerHeight] = rect
						.split(',')
						.map((coordinate) => parseFloat(coordinate));

					const initialCrop = convertToPercentCrop(
						{ x, y, width, height },
						containerWidth,
						containerHeight
					);

					setCrop(initialCrop);
				}
			}

			setInitialCrop();
			setImageElement(element);
			onReady?.();
		},
		[src, onReady]
	);

	return (
		<div
			style={{
				display: 'block',
			}}
		>
			{!hasRectangle ? (
				<AlertWrapper
					type="warning"
					message={translate(`modules.${constants.MODULE_TYPE_IMAGE}.noRectWarning`)}
				/>
			) : null}

			{uncroppedImageUrl && (
				<ReactImageCrop
					style={{
						borderRadius: 3,
						overflow: 'hidden',
						inset: 0,
						backgroundColor: 'rgba(0, 255, 0, .3)',
						aspectRatio: `${
							imageElement
								? imageElement.naturalWidth / imageElement.naturalHeight
								: ASPECT_RATIO_LANDSCAPE
						}`,
					}}
					circularCrop={circularCrop}
					aspect={aspect}
					crop={crop}
					onChange={handleChangeCrop}
					keepSelection={true}
					minWidth={minWidth * scaleRatio}
					minHeight={minHeight * scaleRatio}
					maxWidth={maxWidth ? maxWidth * scaleRatio : undefined}
					maxHeight={maxHeight ? maxHeight * scaleRatio : undefined}
					ruleOfThirds={ruleOfThirds}
				>
					<img
						onLoad={handleImageLoaded}
						ref={originalImageElementRef}
						src={uncroppedImageUrl}
						alt=""
						style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
					/>
				</ReactImageCrop>
			)}

			{!hideControls && (
				<ImageCropperDialogControls
					onCancel={onCancel}
					onConfirm={() => onConfirm?.(finalImageUrl)}
				/>
			)}
		</div>
	);
}

export function ImageCropperDialogControls({
	onCancel,
	onConfirm,
	cancelLabel,
	confirmLabel,
}: {
	onCancel?: () => void;
	onConfirm?: () => void;
	cancelLabel?: string;
	confirmLabel?: string;
}) {
	const translate = useTranslate();

	return (
		<ButtonsWrapper>
			<Button type="ghost" onClick={onCancel}>
				{cancelLabel ?? translate(`modules.${constants.MODULE_TYPE_IMAGE}.abortRectButtonLabel`)}
			</Button>
			<Button type="primary" onClick={onConfirm}>
				{confirmLabel ?? translate(`modules.${constants.MODULE_TYPE_IMAGE}.saveRectButtonLabel`)}
			</Button>
		</ButtonsWrapper>
	);
}
