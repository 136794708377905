import { clearFix, lighten } from 'polished';
import styled, { type DefaultTheme, keyframes } from 'styled-components';

export const INDICATOR_DEV = 'dev';
export const INDICATOR_STAGING = 'staging';
export const INDICATOR_TESTING = 'testing';
export const INDICATOR_MASTER = 'master';

export type IndicatorType =
	| typeof INDICATOR_DEV
	| typeof INDICATOR_TESTING
	| typeof INDICATOR_STAGING
	| typeof INDICATOR_MASTER;

type HeaderContainerProps = {
	indicator?: IndicatorType;
	isWorking: boolean;
	isFixed: boolean;
	children: any;
};

export const convertIndicatorPropToColor = (
	indicator: IndicatorType | undefined,
	theme: DefaultTheme
): string => {
	switch (indicator) {
		case INDICATOR_DEV:
			return theme.indicators.dev;
		case INDICATOR_STAGING:
			return theme.indicators.staging;
		case INDICATOR_TESTING:
			return theme.indicators.testing;
		case INDICATOR_MASTER:
			return theme.indicators.master;
		default:
			return '#000000';
	}
};

const workingAnimation = keyframes`
	from {
		left: 50%;
		width: 0;
		z-index:100;
	}
	33.3333% {
		left: 0;
		width: 100%;
		z-index: 10;
	}
	to {
		left: 0;
		width: 100%;
	}
`;

type HeaderContainerIndicatorProps = {
	$indicator?: IndicatorType;
};

export const HeaderContainerWorkingAnimation = styled.div<HeaderContainerIndicatorProps>`
	content: '';
	display: inline;
	position: absolute;
	width: 0;
	height: 5px;
	left: 50%;
	text-align: center;
	background-color: ${(props) =>
		lighten(0.15, convertIndicatorPropToColor(props.$indicator, props.theme))};
	animation: ${workingAnimation} 2s linear infinite;
`;

export const HeaderContainerIndicator = styled.div<HeaderContainerIndicatorProps>`
	width: 100%;
	height: ${(props) => props.theme?.header.indicatorHeight};
	background-color: ${(props) => convertIndicatorPropToColor(props.$indicator, props.theme)};
`;

export const HeaderContainerInner = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: ${(props) => props.theme.header.height};
	background-color: ${(props) => props.theme.colors.greyHeader};
	font-size: 14px;
`;

type WrapperProps = {
	isFixed: boolean;
};

const Wrapper = styled.header`
	${(props: WrapperProps) =>
		props.isFixed
			? `
		position: fixed;
		top: 0;
		left: 0;
	`
			: ''};
	width: 100%;
	z-index: 500;
`;

export function HeaderContainer({ isWorking, isFixed, indicator, children }: HeaderContainerProps) {
	return (
		<Wrapper isFixed={isFixed}>
			<HeaderContainerIndicator $indicator={indicator}>
				{isWorking ? <HeaderContainerWorkingAnimation $indicator={indicator} /> : null}
			</HeaderContainerIndicator>
			<HeaderContainerInner>{children}</HeaderContainerInner>
		</Wrapper>
	);
}

export const HeaderContainerPrimary = styled.div`
	flex: 2;
	${clearFix()};
`;

export const HeaderContainerSecondary = styled.div`
	${clearFix()};
`;
