import moment from 'moment';
import { Component, type ComponentType } from 'react';
import type { SyntheticEvent, KeyboardEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import type { NewsroomSearchParameters } from '../..';
import * as constants from '../../../../constants';
import { type WithTranslationProps, withTranslation } from '../../../../translation';
import {
	AssetManagerImporterError,
	AssetManagerImporterForm,
	AssetManagerImporterRow,
	AssetManagerImporterRowItem,
	AssetManagerImporterInput,
	AssetManagerImporterInputAction,
	AssetManagerImporterDatepicker,
	AssetManagerImporterSelect,
	AssetManagerImporterCategoryPicker,
} from '../../../../ui/AssetManager';
import { Accept, Spinner } from '../../../../ui/Icon';
import * as actions from '../../actions';
import {
	importerBaseInformationSelector,
	importedItemsByImporterSelector,
	searchedTermFromArticleEditorSelector,
} from '../../selectors';
import AssetManagerImporterResults from '../AssetManagerImportResults/AssetManagerImporterResults';

interface AssetManagerImporterNewsroomProps extends WithTranslationProps, ReduxProps {
	// ConnectedProps messes up funciton overloads
	importResults: typeof actions.importResults;
}

type State = NewsroomSearchParameters;

const initialState = {
	term: '',
	typeFilter: constants.AM_TYPE_ARTICLE,
	categoryFilter: null,
	categoryFilterTitle: null,
	startDate: null,
	endDate: null,
} as const;

class AssetManagerImporterNewsroom extends Component<AssetManagerImporterNewsroomProps, State> {
	constructor(props: AssetManagerImporterNewsroomProps) {
		super(props);
	}

	state: State = {
		...initialState,
	};

	componentDidMount() {
		this.clearFilterState();
		this.setState({
			term: this.props.importerSearchTermFromArticleEditor,
		});
		this.props.updateImporterSearchTermFromArticleEditor('');
	}

	componentDidUpdate() {
		const term = this.props.importerSearchTermFromArticleEditor;
		if (this.state.term !== term && term !== '') {
			// the following disable-next-line is fine because of
			// https://github.com/yannickcr/eslint-plugin-react/issues/1707#issuecomment-521826558
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ term });
		}
	}

	clearFilterState = () => {
		this.setState({
			...initialState,
		});
	};

	handleChangeCategory = (category: string | null, categoryTitle: string | null) => {
		this.setState({ categoryFilter: category, categoryFilterTitle: categoryTitle });
	};

	handleChangeDates = (value: [moment.Moment | null, moment.Moment | null] | null) => {
		if (!value) {
			return;
		}
		const [start, end] = value;
		if (start) {
			this.setState({
				startDate: moment(start).startOf('day'),
			});
		}
		if (end) {
			this.setState({
				endDate: moment(end).startOf('day'),
			});
		}
	};

	handleChangeSearchTerm = (event: SyntheticEvent<HTMLInputElement>) => {
		event.preventDefault();
		const term = event.currentTarget.value;
		this.setState({
			term,
		});
		this.props.updateImporterSearchTermFromArticleEditor(term);
	};

	handleChangeType = (type: string) => {
		this.setState({
			typeFilter: type as typeof constants.AM_TYPE_BOARD | typeof constants.AM_TYPE_ARTICLE,
		});

		if (type !== constants.AM_TYPE_ARTICLE) {
			this.setState({
				endDate: null,
				startDate: null,
			});
		}
	};

	handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			this.handleSubmitFilters();
		}
	};

	handleSubmitFilters = () => {
		this.props.importResults(
			constants.AM_IMPORTER_NEWSROOM,
			{
				mode: 'searchNewsroom',
			},
			{
				...this.state,
			}
		);
	};

	render() {
		const {
			latestImporterError,
			translation: { translate },
			isImporterWorking,
			importedItems,
		} = this.props;

		const options = [
			{
				value: constants.AM_TYPE_ARTICLE,
				label: translate(`assetManager.type.${constants.AM_TYPE_ARTICLE}`),
			},
			{
				value: constants.AM_TYPE_BOARD,
				label: translate(`assetManager.type.${constants.AM_TYPE_BOARD}`),
			},
		];

		return (
			<>
				{latestImporterError && (
					<AssetManagerImporterError>{latestImporterError.message}</AssetManagerImporterError>
				)}
				<AssetManagerImporterRow>
					<AssetManagerImporterRowItem size={12}>
						<AssetManagerImporterForm>
							<AssetManagerImporterInput
								size="full"
								mode="code"
								placeholder={translate(
									`assetManager.importer.${constants.AM_IMPORTER_NEWSROOM}.inputPlaceholder`
								)}
								onChange={this.handleChangeSearchTerm}
								onKeyDown={this.handleInputKeyDown}
								value={this.state.term}
							/>
						</AssetManagerImporterForm>
					</AssetManagerImporterRowItem>

					<AssetManagerImporterRowItem size={4}>
						<AssetManagerImporterDatepicker
							disabled={this.state.typeFilter !== constants.AM_TYPE_ARTICLE}
							allowPastDates={true}
							onHandleChangeDates={this.handleChangeDates}
							value={[this.state.startDate, this.state.endDate]}
						/>
					</AssetManagerImporterRowItem>

					<AssetManagerImporterRowItem size={3}>
						<AssetManagerImporterSelect
							value={this.state.typeFilter}
							onChange={this.handleChangeType}
							options={options}
						/>
					</AssetManagerImporterRowItem>

					<AssetManagerImporterRowItem size={4}>
						<AssetManagerImporterCategoryPicker
							defaultValue={this.state.categoryFilter ?? undefined}
							onChange={this.handleChangeCategory}
							showPickAll={true}
						/>
					</AssetManagerImporterRowItem>

					<AssetManagerImporterRowItem size={1}>
						<AssetManagerImporterInputAction
							key="import"
							onClick={this.handleSubmitFilters}
							className={isImporterWorking ? 'loading' : ''}
						>
							{isImporterWorking ? <Spinner /> : <Accept />}
						</AssetManagerImporterInputAction>
					</AssetManagerImporterRowItem>
				</AssetManagerImporterRow>
				<AssetManagerImporterResults
					importer={constants.AM_IMPORTER_NEWSROOM}
					importedItems={importedItems}
					importerWorking={isImporterWorking}
				/>
			</>
		);
	}
}

const connector = connect(
	(state) => ({
		...importerBaseInformationSelector(state),
		...searchedTermFromArticleEditorSelector(state),
		importedItems: importedItemsByImporterSelector(constants.AM_IMPORTER_NEWSROOM)(state),
	}),
	{
		importResults: actions.importResults,
		cleanImporter: actions.cleanImporter,
		removeItemGroup: actions.removeItemGroupFromImporter,
		updateImporterSearchTermFromArticleEditor: actions.updateImporterSearchTermFromArticleEditor,
	}
);
type ReduxProps = ConnectedProps<typeof connector>;

// ConnectedProps is not handling funciton overloads correctly
export default withTranslation(
	connector(
		AssetManagerImporterNewsroom as unknown as ComponentType<ReduxProps & WithTranslationProps>
	)
);
