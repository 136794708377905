import {
	SECTION_SCHEMA_STAGE_CAROUSEL,
	SECTION_SCHEMA_SMALL_3,
	SECTION_SCHEMA_RAISED_LEFT_SIMPLE,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE,
	SECTION_SCHEMA_REGIONAL_4_SIMPLE,
	SECTION_SCHEMA_REGIONAL_2_BIG_TOP,
	SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW,
	SECTION_SCHEMA_HIGHLIGHT_3,
	SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_LEFT_1_BIG,
	SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER,
	SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER,
} from '@sep/br24-constants';

import type { BoardSectionSchema } from '../../store/reducers/boardBuffer';
import { SVG } from '../SVG';

// Karusell
// geteilter Header (currently not in use)
// import IconStageSplitted from './svg/SECTION_SCHEMA_STAGE_SPLITTED.svg';
// Dreier-Teaser
// Zweier-Teaser: klein/groß
// Zweier-Teaser: groß/klein
// Regional mit 4
// Regional mit 2
// Regional mit 3
// Regional mit 3 (currently not in use)
// import IconRegional2SmallTop1BigBelow from './svg/REGIONAL_1_BIG_TOP_2_SMALL_BELOW.svg';
// Dreier-Teaser dunkel
// Kategorie (1)
import IconCategoryLeft1Big from './svg/SECTION_SCHEMA_CATEGORY_LEFT_1_BIG.svg';
import IconCategoryLeft2Small from './svg/SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL.svg';
// Kategorie (2)
// Kategorie (3)
import IconCategoryRight1Big from './svg/SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG.svg';
import CategoryRight2Small from './svg/SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL.svg';
// Kategorie (4)
import IconHighlight3 from './svg/SECTION_SCHEMA_HIGHLIGHT_3.svg';
import IconRaisedLeftSimple from './svg/SECTION_SCHEMA_RAISED_LEFT_SIMPLE.svg';
// Meldungen rechts, großer Teaser links
import IconRaisedRightShortnews from './svg/SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS.svg';
// Meldungen rechts, zwei kleine Teaser links
import IconRaisedRightShortnews2Small from './svg/SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL.svg';
import IconRaisedRightSimple from './svg/SECTION_SCHEMA_RAISED_RIGHT_SIMPLE.svg';
// Aufmacher Zweier-Teaser
import IconRaisedRightSimpleWeather from './svg/SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER.svg';
import IconRegional1BigTop2SmallBelow from './svg/SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW.svg';
import IconRegional2BigTop from './svg/SECTION_SCHEMA_REGIONAL_2_BIG_TOP.svg';
import IconRegional4Simple from './svg/SECTION_SCHEMA_REGIONAL_4_SIMPLE.svg';
import IconSmall3 from './svg/SECTION_SCHEMA_SMALL_3.svg';
import IconStageCarousel from './svg/SECTION_SCHEMA_STAGE_CAROUSEL.svg';
// Aufmacher Vierer-Teaser
import IconSubjectsNewsWeather from './svg/SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER.svg';

function getIcon(schema: BoardSectionSchema) {
	switch (schema) {
		case SECTION_SCHEMA_STAGE_CAROUSEL:
			return IconStageCarousel;
		// Features is currently not in use
		// case SECTION_SCHEMA_STAGE_SPLITTED:
		// 	return IconStageSplitted;
		case SECTION_SCHEMA_SMALL_3:
			return IconSmall3;
		case SECTION_SCHEMA_RAISED_LEFT_SIMPLE:
			return IconRaisedLeftSimple;
		case SECTION_SCHEMA_RAISED_RIGHT_SIMPLE:
			return IconRaisedRightSimple;
		case SECTION_SCHEMA_REGIONAL_4_SIMPLE:
			return IconRegional4Simple;
		case SECTION_SCHEMA_REGIONAL_2_BIG_TOP:
			return IconRegional2BigTop;
		case SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW:
			return IconRegional1BigTop2SmallBelow;
		// case SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW:
		// Feature is currently not in use
		// 	return IconRegional2SmallTop1BigBelow;
		case SECTION_SCHEMA_HIGHLIGHT_3:
			return IconHighlight3;
		case SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL:
			return IconCategoryLeft2Small;
		case SECTION_SCHEMA_CATEGORY_LEFT_1_BIG:
			return IconCategoryLeft1Big;
		case SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL:
			return CategoryRight2Small;
		case SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG:
			return IconCategoryRight1Big;
		case SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS:
			return IconRaisedRightShortnews;
		case SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL:
			return IconRaisedRightShortnews2Small;
		case SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER:
			return IconRaisedRightSimpleWeather;
		case SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER:
			return IconSubjectsNewsWeather;
		default:
			return undefined;
	}
}

type Props = {
	schema: BoardSectionSchema;
};

export default function SectionIcon({ schema, ...rest }: Props) {
	return <SVG {...rest} src={getIcon(schema)} />;
}
