import { Component, type ComponentProps, type ReactNode } from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

import type { AssetManagerStatus } from '@/client/containers/AssetManager/AssetManagerItem';

import { Copy, Drag, Edit, ArrowRight, Accept, Info } from '../../Icon';

const HoverWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
`;

const HoverButtonStyled = styled.a`
	flex: 1;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	transition: background 300ms;
	color: #fff !important;

	&:hover {
		background-color: rgba(10, 158, 215, 0.5);
		.icon {
			transform: scale(1.5);
		}
	}

	.icon {
		transform: scale(1.25);
		transition: transform 300ms;
	}

	&.drag {
		cursor: move;
	}

	&.copy {
		cursor: move;
	}

	&.edit {
		cursor: pointer;
	}
`;

type HoverButtonType = 'info' | 'copy' | 'drag' | 'edit' | 'accept' | 'sendToAssetManager';

type HoverButtonProps = {
	type: HoverButtonType;
};

function HoverButton(props: HoverButtonProps & ComponentProps<typeof HoverButtonStyled>) {
	const { type, ...rest } = props;
	return (
		<HoverButtonStyled className={type} {...rest}>
			{type === 'info' && <Info />}
			{type === 'copy' && <Copy />}
			{type === 'drag' && <Drag />}
			{type === 'edit' && <Edit />}
			{type === 'accept' && <Accept />}
			{type === 'sendToAssetManager' && <ArrowRight />}
		</HoverButtonStyled>
	);
}

type Props = {
	currentStatus: AssetManagerStatus;
	editUrl: string | undefined | null;
	toggleDragIsCopy?: (a: boolean) => void;
	sendToAssetManager: () => void;
	handleClickSelectionMark: (event: SyntheticEvent<HTMLElement>) => void;
	handleClickInfoMark?: () => void;
};

class AssetManagerItemHoverOverlay extends Component<Props> {
	setMethodToCopy = () => {
		const { currentStatus, toggleDragIsCopy } = this.props;

		if (!currentStatus.isInImporter && toggleDragIsCopy) {
			toggleDragIsCopy(true);
		}
	};

	setMethodToDrag = () => {
		const { currentStatus, toggleDragIsCopy } = this.props;
		if (!currentStatus.isInImporter && toggleDragIsCopy) {
			toggleDragIsCopy(false);
		}
	};

	render() {
		const { editUrl, currentStatus, handleClickSelectionMark } = this.props;

		const { isEditable, isInImporter, inDeletionMode, isMoveable, isDeletable, isBrBildContent } =
			currentStatus;

		let HoverButtons: ReactNode;

		if (inDeletionMode) {
			// nested because if it isn't deletable then I dont want to show anything in this mode
			if (isDeletable) {
				HoverButtons = <HoverButton onClick={handleClickSelectionMark} type="accept" />;
			}
		} else {
			HoverButtons = (
				<>
					{isInImporter && isBrBildContent && (
						<HoverButton onClick={this.props.handleClickInfoMark} type="info" />
					)}
					{isMoveable && <HoverButton onMouseEnter={this.setMethodToDrag} type="drag" />}
					{!isInImporter && <HoverButton onMouseEnter={this.setMethodToCopy} type="copy" />}
					{isInImporter && (
						<HoverButton onClick={this.props.sendToAssetManager} type="sendToAssetManager" />
					)}
					{isEditable && <HoverButton target="_blank" href={editUrl} type="edit" />}
				</>
			);
		}

		return <HoverWrapper>{HoverButtons}</HoverWrapper>;
	}
}

export default AssetManagerItemHoverOverlay;
