import type { ReduxState } from '../..';

import { findBoardById } from './find';

type Relations = {
	boardId: string;
	socialMediaAccountIds: Array<string>;
	sectionIds: Array<string>;
	sectionItemIds: Array<string>;
};

export default function determineRelations(
	boardId: string,
	getState: () => ReduxState
): false | Relations {
	const board = findBoardById(boardId, getState);

	if (!board) {
		return false;
	}

	const socialMediaAccountIds = board.socialMediaAccounts.map(
		({ __internalId__ }) => __internalId__
	);
	const sectionItemIds = board.sections.reduce(
		(accumulator, { items }) => [
			...accumulator,
			...items.map(({ __internalId__ }) => __internalId__),
		],
		[] as string[]
	);
	const sectionIds = board.sections.map(({ __internalId__ }) => __internalId__);

	return {
		boardId,
		socialMediaAccountIds,
		sectionIds,
		sectionItemIds,
	};
}
