import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Card, Tooltip } from 'antd';
import type { Point } from 'google-map-react';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import {
	geoLocationToCoordinate,
	type LocationMeta,
	Map,
	type MapChangeEvent,
	pointToGeoLocation,
} from '../../../../../client/ui/Map';
import {
	provideSectionValidationById,
	provideMetaBySectionId,
	updateSectionMeta,
} from '../../../../store/reducers/boardBuffer';
import gs from '../../../../styles';
import translations from '../../../../translations';
import attachFormItemErrorProps from '../../../../util/attachFormItemErrorProps';

interface Props extends ReduxProps, OwnProps {
	geoLocationMeta: string | any;
	geoLocation: string;
}

class HeaderItem extends Component<Props> {
	handleChangeLocation = ({ point, selectedLocationMeta }: MapChangeEvent) => {
		const { updateGeoLocation } = this.props;
		updateGeoLocation(point, selectedLocationMeta);
	};

	render() {
		const { geoLocation, geoLocationMeta, validation } = this.props;
		const point = geoLocationToCoordinate(geoLocation);

		return (
			<Card
				title={
					<span>
						<span style={{ marginRight: '5px' }}>{translations.searchLocationTitle}</span>
						<Tooltip title={translations.searchLocationInfo}>
							<InfoCircleOutlined />
						</Tooltip>
					</span>
				}
			>
				<Form.Item
					className={gs.mb0}
					{...attachFormItemErrorProps(validation, 'meta.geoLocationMeta.state', {
						validateStatus: 'error',
					})}
				>
					<Map
						onChange={this.handleChangeLocation}
						defaultCenter={point}
						defaultSelectedLocation={point}
						defaultSelectedLocationMeta={
							typeof geoLocationMeta === 'string'
								? JSON.parse(geoLocationMeta)
								: geoLocationMeta || null
						}
					/>
				</Form.Item>
			</Card>
		);
	}
}

interface OwnProps {
	sectionInternalId: string;
}

const connector = connect(
	({ boardBuffer }: ReduxState, { sectionInternalId }: OwnProps) => ({
		validation: provideSectionValidationById(boardBuffer, sectionInternalId),
		geoLocation: provideMetaBySectionId(boardBuffer, sectionInternalId, 'geoLocation'),
		geoLocationMeta: provideMetaBySectionId(boardBuffer, sectionInternalId, 'geoLocationMeta'),
	}),
	(dispatch, { sectionInternalId }: OwnProps) => ({
		updateGeoLocation: (
			point: Point | undefined | null,
			geoLocationMeta: LocationMeta | null | undefined
		) =>
			dispatch(
				updateSectionMeta(sectionInternalId, {
					geoLocation: pointToGeoLocation(point),
					geoLocationMeta,
				})
			),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(HeaderItem);
