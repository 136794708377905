import { useTranslate } from '@/client/translation/useTranslate';

import { AM_TYPE_GALLERY, AM_TYPE_IMAGE } from '../../../constants';

import { Title, Content, Copyright } from './AssetManagerItemControl';
import AssetManagerMedia from './Media';
import TypeIcon from './TypeIcon';

type AssetManagerItemGalleryProps = {
	images: Array<{
		url?: null | string;
		title?: null | string;
		copyright?: null | string;
		altText?: null | string;
	}>;
};

export default function AssetManagerItemGallery({ images }: AssetManagerItemGalleryProps) {
	const translate = useTranslate();

	const firstImageWithContent = images.find((image) => image.title && image.title.length > 0);

	return (
		<>
			<TypeIcon type={AM_TYPE_GALLERY} isOverlying={true} />
			<AssetManagerMedia images={images.map(({ url }) => url)} />
			<Content>
				<Title>
					{firstImageWithContent
						? firstImageWithContent.title
						: translate(`assetManager.item.${AM_TYPE_IMAGE}.noTitle`)}
				</Title>
				<Copyright>
					{firstImageWithContent
						? firstImageWithContent.copyright
						: translate(`assetManager.item.${AM_TYPE_IMAGE}.noCopyright`)}
				</Copyright>
			</Content>
		</>
	);
}
