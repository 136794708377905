import { Switch } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
`;

const Label = styled.span`
	color: ${(props) => props.color};
	margin-left: 1rem;
`;

type Props = {
	activeLabel: string;
	checked?: boolean;
	disabled?: boolean;
	inactiveLabel: string;
	labelColor?: string;
	loading?: boolean;
	size?: 'small' | 'default';
	onChange: (active: boolean) => void;
};

function SwitchLabel({
	activeLabel,
	checked,
	disabled,
	inactiveLabel,
	labelColor,
	loading,
	size,
	onChange,
}: Props) {
	const [active, setActive] = useState(checked);

	const handleChange = () => {
		onChange(!active);
		setActive(!active);
	};
	const label = active ? activeLabel : inactiveLabel;

	return (
		<Wrapper>
			<Switch
				checked={active}
				disabled={disabled}
				loading={loading}
				size={size}
				onChange={handleChange}
			/>
			<Label color={labelColor}>{label}</Label>
		</Wrapper>
	);
}

SwitchLabel.defaultProps = {
	checked: true,
	disabled: false,
	labelColor: '#1EBD6F',
	loading: false,
	size: 'default',
};

export default SwitchLabel;
