import {
	SECTION_SCHEMA_ALL,
	SECTION_SCHEMA_STAGE_CAROUSEL,
	SECTION_SCHEMA_STAGE_SPLITTED,
	SECTION_SCHEMA_SMALL_3,
	SECTION_SCHEMA_RAISED_LEFT_SIMPLE,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE,
	SECTION_SCHEMA_REGIONAL_4_SIMPLE,
	SECTION_SCHEMA_REGIONAL_2_BIG_TOP,
	SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW,
	SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW,
	SECTION_SCHEMA_HIGHLIGHT_3,
	SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_LEFT_1_BIG,
	SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL,
	SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS,
	SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL,
	SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER,
	SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER,
	DISTRICTS_ALL,
} from '@sep/br24-constants';
import joi from 'joi-browser';

import translations from '../../../../../translations';
import createJoiError from '../../../../../util/createJoiError';
import { processValidation } from '../../../../../util/processValidation';
import type { ValidationResult } from '../../../../../util/processValidation';
import type { BoardSectionBuffer } from '../../types';

const regionalMetaSchema = joi.object().keys({
	district: joi
		.string()
		.allow(...DISTRICTS_ALL)
		.allow(null),
});

export const sectionSchemaSlotCountMapping = {
	[SECTION_SCHEMA_STAGE_CAROUSEL]: 3,
	[SECTION_SCHEMA_STAGE_SPLITTED]: 2,
	[SECTION_SCHEMA_SMALL_3]: 3,
	[SECTION_SCHEMA_RAISED_LEFT_SIMPLE]: 2,
	[SECTION_SCHEMA_RAISED_RIGHT_SIMPLE]: 2,
	[SECTION_SCHEMA_REGIONAL_4_SIMPLE]: 4,
	[SECTION_SCHEMA_REGIONAL_2_BIG_TOP]: 2,
	[SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW]: 3,
	[SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW]: 3,
	[SECTION_SCHEMA_HIGHLIGHT_3]: 3,
	[SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL]: 7,
	[SECTION_SCHEMA_CATEGORY_LEFT_1_BIG]: 6,
	[SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL]: 7,
	[SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG]: 6,
	[SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS]: 1,
	[SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL]: 2,
	[SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER]: 2,
	[SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER]: 4,
};

const HEADER_ITEM_WEATHER_SEARCH_STATE = 'Bayern';

const schema = joi.object().keys({
	schema: joi
		.allow(...SECTION_SCHEMA_ALL)
		.error(
			createJoiError(
				`Das angegebene Schema ist inkorrekt / wird nicht mehr unterstützt (bitte verwende eins der nachfolgenden: ${SECTION_SCHEMA_ALL.map(
					(value) => translations.boardSectionSchema.title[value]
				).join(', ')}`
			)
		)
		.required(),
	categoryId: joi
		.string()
		.required()
		.allow(null)
		.error(createJoiError('Die ausgewählte Kategorie ist nicht valide.')),
	title: joi
		.string()
		.required()
		.allow(null)
		.min(3)
		.max(64)
		.error(
			createJoiError(
				'Der Titel muss aus min. 3 Zeichen bestehen und darf 64 Zeichen nicht überschreiten.'
			)
		),
	color: joi
		.string()
		.required()
		.allow(null)
		.length(7)
		.error(createJoiError('Die angegebene Farbe ist nicht korrekt.')),
	items: joi.alternatives().when('autofill', {
		is: false,
		then: joi
			.any()
			.when('schema', {
				is: SECTION_SCHEMA_STAGE_CAROUSEL,
				then: joi
					.array()
					.min(0)
					.max(0)
					.error(
						createJoiError(
							'Die Sektion "Aufmacher: Karussell" kann aktuell nicht mehr eingesetzt werden. Bitte verwenden Sie eine andere Sektion.'
						)
					),
			})
			.when('schema', {
				is: SECTION_SCHEMA_STAGE_SPLITTED,
				then: joi
					.array()
					.length(2)
					.error(createJoiError('Die Sektion muss exakt zwei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_SMALL_3,
				then: joi
					.array()
					.length(3)
					.error(createJoiError('Die Sektion muss exakt drei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_RAISED_LEFT_SIMPLE,
				then: joi
					.array()
					.length(2)
					.error(createJoiError('Die Sektion muss exakt zwei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_RAISED_RIGHT_SIMPLE,
				then: joi
					.array()
					.length(2)
					.error(createJoiError('Die Sektion muss exakt zwei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_REGIONAL_4_SIMPLE,
				then: joi
					.array()
					.length(4)
					.error(createJoiError('Die Sektion muss exakt vier Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_REGIONAL_2_BIG_TOP,
				then: joi
					.array()
					.length(2)
					.error(createJoiError('Die Sektion muss exakt zwei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW,
				then: joi
					.array()
					.length(3)
					.error(createJoiError('Die Sektion muss exakt drei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW,
				then: joi
					.array()
					.length(3)
					.error(createJoiError('Die Sektion muss exakt drei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_HIGHLIGHT_3,
				then: joi
					.array()
					.length(3)
					.error(createJoiError('Die Sektion muss exakt drei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL,
				then: joi
					.array()
					.length(7)
					.error(createJoiError('Die Sektion muss exakt sieben Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_CATEGORY_LEFT_1_BIG,
				then: joi
					.array()
					.length(6)
					.error(createJoiError('Die Sektion muss exakt sechs Eintrag enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL,
				then: joi
					.array()
					.length(7)
					.error(createJoiError('Die Sektion muss exakt sieben Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG,
				then: joi
					.array()
					.length(6)
					.error(createJoiError('Die Sektion muss exakt sechs Eintrag enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS,
				then: joi
					.array()
					.length(1)
					.error(createJoiError('Die Sektion muss exakt einen Eintrag enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL,
				then: joi
					.array()
					.length(2)
					.error(createJoiError('Die Sektion muss exakt zwei Einträge enthalten.')),
			})
			.when('schema', {
				is: SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER,
				then: joi.when('order', {
					is: 0,
					then: joi
						.array()
						.length(2)
						.error(createJoiError('Die Sektion muss exakt zwei Einträge enthalten.')),
					otherwise: joi
						.number()
						.error(
							createJoiError(
								'Das Aufmacher-Element (Hero) darf nur an der ersten Position der Seite verwendet werden.'
							)
						),
				}),
			})
			.when('schema', {
				is: SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER,
				then: joi.when('order', {
					is: 0,
					then: joi
						.array()
						.length(4)
						.error(createJoiError('Die Sektion muss exakt vier Einträge enthalten.')),
					otherwise: joi
						.number()
						.error(
							createJoiError(
								'Das Aufmacher-Element (Hero) darf nur an der ersten Position der Seite verwendet werden.'
							)
						),
				}),
			}),
		otherwise: joi.array().length(0),
	}),
	autofill: joi.boolean().required(),
	boardLink: joi
		.string()
		.required()
		.allow(null)
		.error(createJoiError('Die ausgewählte Verteilseite ist nicht valide.')),
	meta: joi
		.any()
		.when('schema', {
			is: SECTION_SCHEMA_STAGE_CAROUSEL,
			then: joi.object().keys({
				geoLocation: joi
					.string()
					// further desc: https://stackoverflow.com/a/18524380/7855038
					.regex(
						/^(\()([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?(\)))$/g
					)
					.optional()
					.allow(null),
				geoLocationMeta: joi
					.object()
					.keys({
						state: joi
							.string()
							.valid(HEADER_ITEM_WEATHER_SEARCH_STATE)
							.error(
								createJoiError(
									'Der ausgewählte Ort für das Wetter muss zwingend in Bayern liegen. Bitte überprüfe den Ort.'
								)
							),
					})
					.allow(null),
			}),
		})
		.when('schema', {
			is: SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW,
			then: regionalMetaSchema,
		})
		.when('schema', {
			is: SECTION_SCHEMA_REGIONAL_2_BIG_TOP,
			then: regionalMetaSchema,
		})
		.when('schema', {
			is: SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW,
			then: regionalMetaSchema,
		})
		.when('schema', {
			is: SECTION_SCHEMA_REGIONAL_4_SIMPLE,
			then: regionalMetaSchema,
		}),
	order: joi.number().required(),
});

const defaultOptions = {
	abortEarly: false,
	allowUnknown: true,
};

export default function validator(
	section: BoardSectionBuffer,
	options = defaultOptions
): Promise<ValidationResult> {
	return new Promise((resolve) => {
		const filterdSection = {
			...section,
			items: section.items.filter((item) => item !== null),
		};

		// TODO: use costom validator with sectionSchemaSlotCountMapping Object to check for filled slot count
		//       instead of when schema: x => "Die Sektion muss exakt y Einträge enthalten." with joi above.
		//       see board.js -> validateSlug()

		joi.validate(filterdSection, schema, options, (error) => resolve(processValidation({ error })));
	});
}
