/**
 * @generated SignedSource<<04f1b1a7313cad34db4a4f5f9eb53070>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NavigationArticlePickerQuery$variables = {
  searchTerm: string;
};
export type NavigationArticlePickerQuery$data = {
  readonly articles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstPublicationDate: string | null;
        readonly rowId: string;
        readonly title: string;
      } | null;
    }>;
  } | null;
};
export type NavigationArticlePickerQuery = {
  response: NavigationArticlePickerQuery$data;
  variables: NavigationArticlePickerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Variable",
    "name": "searchPlain",
    "variableName": "searchTerm"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "FIRST_PUBLICATION_DATE_DESC"
  },
  {
    "kind": "Literal",
    "name": "statusFilter",
    "value": "PUBLISHED"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstPublicationDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationArticlePickerQuery",
    "selections": [
      {
        "alias": "articles",
        "args": (v1/*: any*/),
        "concreteType": "ArticlesConnection",
        "kind": "LinkedField",
        "name": "filterArticles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticlesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavigationArticlePickerQuery",
    "selections": [
      {
        "alias": "articles",
        "args": (v1/*: any*/),
        "concreteType": "ArticlesConnection",
        "kind": "LinkedField",
        "name": "filterArticles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticlesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9be15f36c7a60dcff98a6f5e97ae9525",
    "id": null,
    "metadata": {},
    "name": "NavigationArticlePickerQuery",
    "operationKind": "query",
    "text": "query NavigationArticlePickerQuery(\n  $searchTerm: String!\n) {\n  articles: filterArticles(searchPlain: $searchTerm, first: 100, statusFilter: PUBLISHED, sortBy: FIRST_PUBLICATION_DATE_DESC) {\n    edges {\n      node {\n        title\n        rowId\n        firstPublicationDate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0f868abed587e148b3e47d9f935a30e";

export default node;
