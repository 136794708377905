import { Col } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding-right: 8px;
`;

type Props = {
	size: number;
	children: any;
};

function AssetManagerImporterRowItem({ size = 6, children }: Props) {
	return (
		<Col span={size}>
			<Wrapper>{children}</Wrapper>
		</Col>
	);
}

export default AssetManagerImporterRowItem;
