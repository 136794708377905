import { Modal, Tabs } from 'antd';
import { type FC, useMemo, useState } from 'react';

import {
	NAVIGATION_TYPE_ARTICLE,
	NAVIGATION_TYPE_BOARD,
	NAVIGATION_TYPE_CUSTOM,
} from '@/client/constants';

import { NavigationEditorForm } from './forms/NavigationEditorForm';
import { NavigationEditorFormArticle } from './forms/NavigationEditorFormArticle';
import { NavigationEditorFormBoard } from './forms/NavigationEditorFormBoard';
import { NavigationEditorFormCustomLink } from './forms/NavigationEditorFormCustomLink';
import translations from './translations';
import type { NavigationType, UpsertNavigationItemPayload, NavigationItemId } from './types';

interface Props {
	onConfirmItem: (item: UpsertNavigationItemPayload) => void;
	onCancel: () => void;
	item: UpsertNavigationItemPayload | null;
	rootId: NavigationItemId | null;
	open: boolean;
}

export const NavigationEditorDialog: FC<Props> = ({
	onConfirmItem,
	item,
	onCancel,
	rootId,
	open,
}) => {
	const [tabKey, setTabKey] = useState<NavigationType>(item?.type ?? 'BOARD');

	const tabItems = useMemo(
		() => [
			{
				key: NAVIGATION_TYPE_BOARD,
				label: translations[`NM_TAB_NAME_${NAVIGATION_TYPE_BOARD}`],
				children: <NavigationEditorFormBoard />,
			},
			{
				key: NAVIGATION_TYPE_ARTICLE,
				label: translations[`NM_TAB_NAME_${NAVIGATION_TYPE_ARTICLE}`],
				children: <NavigationEditorFormArticle />,
			},
			{
				key: NAVIGATION_TYPE_CUSTOM,
				label: translations[`NM_TAB_NAME_${NAVIGATION_TYPE_CUSTOM}`],
				children: <NavigationEditorFormCustomLink />,
			},
		],
		[]
	);

	const initialValues = useMemo<UpsertNavigationItemPayload>(() => {
		// strip type as it will be set by the tab form
		const { type, ...rest } = item ?? {};

		return {
			...rest,
			parent: Number(item?.parent ?? rootId),
		};
	}, [item, rootId]);

	return (
		<Modal
			/* destroy modal to ensure that form gets reset on close */
			destroyOnClose
			onCancel={onCancel}
			title={translations.NM_EDITOR_HEADLINE}
			open={open}
			footer={null}
		>
			<NavigationEditorForm
				hiddenFields={['rowId', 'color', 'parent']}
				onCancel={onCancel}
				onConfirm={onConfirmItem}
				initialValues={initialValues}
			>
				<Tabs
					/* destroyInactiveTabPane is required to reset field values on tab change */
					destroyInactiveTabPane
					items={tabItems}
					activeKey={tabKey}
					onChange={setTabKey}
				/>
			</NavigationEditorForm>
		</Modal>
	);
};
