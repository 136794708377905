/**
 * @generated SignedSource<<692e27dd1dfbee6463c416c183184506>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmbedModuleEditorContainer_query$data = {
  readonly embed: {
    readonly image: {
      readonly base64Data: string | null;
      readonly height: number | null;
      readonly url: string | null;
      readonly width: number | null;
    } | null;
    readonly service: string | null;
    readonly source: string | null;
    readonly url: string | null;
  } | null;
  readonly " $fragmentType": "EmbedModuleEditorContainer_query";
};
export type EmbedModuleEditorContainer_query$key = {
  readonly " $data"?: EmbedModuleEditorContainer_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmbedModuleEditorContainer_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "renderImage"
    },
    {
      "kind": "RootArgument",
      "name": "service"
    },
    {
      "kind": "RootArgument",
      "name": "source"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmbedModuleEditorContainer_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "renderImage",
          "variableName": "renderImage"
        },
        {
          "kind": "Variable",
          "name": "service",
          "variableName": "service"
        },
        {
          "kind": "Variable",
          "name": "source",
          "variableName": "source"
        }
      ],
      "concreteType": "Embed",
      "kind": "LinkedField",
      "name": "embed",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "service",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "source",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmbedImage",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "base64Data",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "5fcda00a3c9ae01b301a3aaeeec51f08";

export default node;
