/**
 * @generated SignedSource<<30518f81546878ca10ed61d4219b7c14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type ContainerTeasersArticleURLByRowIdQuery$variables = {
  rowId: string;
};
export type ContainerTeasersArticleURLByRowIdQuery$data = {
  readonly article: {
    readonly shareUrl: string;
    readonly status: Status;
  } | null;
  readonly board: {
    readonly shareUrl: string;
    readonly status: Status | null;
  } | null;
  readonly boardsTeaser: {
    readonly link: {
      readonly url: string | null;
    } | null;
  } | null;
};
export type ContainerTeasersArticleURLByRowIdQuery = {
  response: ContainerTeasersArticleURLByRowIdQuery$data;
  variables: ContainerTeasersArticleURLByRowIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shareUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Link",
  "kind": "LinkedField",
  "name": "link",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerTeasersArticleURLByRowIdQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": "board",
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": "boardsTeaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerTeasersArticleURLByRowIdQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": "board",
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": "boardsTeaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7dbf279120c03155bc591d9e3384c59a",
    "id": null,
    "metadata": {},
    "name": "ContainerTeasersArticleURLByRowIdQuery",
    "operationKind": "query",
    "text": "query ContainerTeasersArticleURLByRowIdQuery(\n  $rowId: String!\n) {\n  article: articleByRowId(rowId: $rowId) {\n    shareUrl\n    status\n    id\n  }\n  board: boardByRowId(rowId: $rowId) {\n    shareUrl\n    status\n    id\n  }\n  boardsTeaser: boardsTeaserByRowId(rowId: $rowId) {\n    link {\n      url\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ac431bc141167b71a532d00b039313c2";

export default node;
