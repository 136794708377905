import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateInput, UpdateStatusAction, ValidateAction } from '..';
import { UPDATE } from '../actionTypes';

import { validateArticle } from './validate';

const update =
	(input: UpdateInput): AppThunkAction<ValidateAction | UpdateStatusAction> =>
	(dispatch) => {
		dispatch({
			type: UPDATE,
			payload: {
				...input,
			},
		});

		dispatch(validateArticle());
	};

export default update;
