/**
 * @generated SignedSource<<a04935fabfeb477877c51e03ce154ad3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type fetchLiveStreamsLiveStream$data = {
  readonly epg: ReadonlyArray<{
    readonly broadcastEvent: {
      readonly end: string | null;
      readonly id: string;
      readonly publicationOf: {
        readonly defaultTeaserImage: {
          readonly imageFiles: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly publicLocation: string | null;
              } | null;
            } | null> | null;
          } | null;
        } | null;
        readonly id: string;
        readonly kicker: string | null;
        readonly title: string | null;
      } | null;
      readonly start: string | null;
    } | null;
    readonly current: boolean;
  } | null> | null;
  readonly id: string;
  readonly streamingUrls: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly publicLocation: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "fetchLiveStreamsLiveStream";
};
export type fetchLiveStreamsLiveStream$key = {
  readonly " $data"?: fetchLiveStreamsLiveStream$data;
  readonly " $fragmentSpreads": FragmentRefs<"fetchLiveStreamsLiveStream">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publicLocation",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "fetchLiveStreamsLiveStream",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "MangoLivestreamResourceConnection",
      "kind": "LinkedField",
      "name": "streamingUrls",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MangoLivestreamResourceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "streamingUrls(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MangoEPGEntry",
      "kind": "LinkedField",
      "name": "epg",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "broadcastEvent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "start",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "end",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "publicationOf",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kicker",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "defaultTeaserImage",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MangoImageFileConnection",
                      "kind": "LinkedField",
                      "name": "imageFiles",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MangoImageFileEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MangoLivestream",
  "abstractKey": null
};
})();

(node as any).hash = "1985363cc7af4fcbd67a7b7373eb34fa";

export default node;
