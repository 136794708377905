import { Form, Row, Col, Collapse, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import type { ModuleImage } from '@/types/schema';

import {
	update,
	provideBoardValidationById,
	type State,
	type BoardBufferPatch,
} from '../../../store/reducers/boardBuffer';
import RichTextEditor from '../../../ui/RichTextEditor';
import attachFormItemErrorProps from '../../../util/attachFormItemErrorProps';
import CategoryPicker from '../../CategoryPicker';
import Uploader from '../../ImageManager/Uploader';
import LimitedLengthItem from '../../LimitedLengthItem';
import { ParentBoardPicker } from '../../ParentBoardPicker/ParentBoardPicker';
import { Input, TextArea } from '../Form';

const { Panel } = Collapse;

interface Props extends ReduxProps, OwnProps {}

const INTRODUCTION_TEXT_LENGTH_MAX = 300;

class MetaGeneralComponent extends Component<Props, State> {
	handleChangeCategory = (value: string | null) => this.props.update('categoryId', value);

	handleChangeDescription = (value: string) => this.props.update('description', value);

	handleChangeImage = ([image]: ModuleImage[]) => this.props.update('image', image || null);

	handleChangeParentBoard = (parentId: string | null) =>
		this.props.update('parentId', parentId || undefined);

	handleChangeSeoTitle = (value: string) => this.props.update('seoTitle', value);

	handleChangeSlug = (value: string) => this.props.update('slug', value);

	handleChangeTitle = (value: string) => this.props.update('title', value);

	handleChangeIntroductionHeadline = (value: string) => {
		this.props.update('introductionHeadline', value);
	};

	handleChangeIntroductionText = (value: string) => {
		if (this.props.introductionText !== value) {
			this.props.update('introductionText', value.trim());
		}
	};

	handleChangehasIntroductionText = (event: CheckboxChangeEvent) => {
		this.props.update('hasIntroductionText', event.target.checked);
	};

	getCleanedIntruductionTextLength = (): number => {
		return (this.props.introductionText || '')
			.replace(/<(?:.|\n)*?>/gm, '') // remove all breaks
			.replace(/<[^>]*>?/gm, '')
			.trim().length; // remove all html tags
	};

	render() {
		const {
			boardId,
			categoryId,
			description,
			hasIntroductionText,
			introductionHeadline,
			introductionText,
			image,
			isProtected,
			parentId,
			seoTitle,
			slug,
			title,
			validation,
		} = this.props;

		const descriptionLength = this.getCleanedIntruductionTextLength();

		return (
			<Panel key={''} {...this.props} header="Allgemein">
				<Form layout="vertical">
					<Row>
						<Col span="24">
							<Form.Item
								label="Übergeordnete Seite"
								{...attachFormItemErrorProps(validation, 'parentId')}
							>
								<ParentBoardPicker
									onSelect={this.handleChangeParentBoard}
									ignore={[boardId]}
									defaultValue={parentId}
								/>
							</Form.Item>
							<Form.Item
								label="Bild"
								{...attachFormItemErrorProps(validation, 'image', { startsWith: true })}
							>
								<Uploader
									maxImages={1}
									onChange={this.handleChangeImage}
									images={image ? [image as ModuleImage] : undefined}
								/>
							</Form.Item>
							<LimitedLengthItem
								required={true}
								label="URL-Kürzel"
								maxLength={64}
								{...attachFormItemErrorProps(validation, 'slug')}
							>
								<Input
									placeholder="URL-Kürzel"
									defaultValue={slug || ''}
									onMagicChange={this.handleChangeSlug}
									disabled={isProtected}
								/>
							</LimitedLengthItem>
							<LimitedLengthItem
								required={true}
								label="Überschrift Verteilseite"
								maxLength={64}
								{...attachFormItemErrorProps(validation, 'title')}
							>
								<Input
									placeholder="Überschrift Verteilseite"
									defaultValue={title || ''}
									onMagicChange={this.handleChangeTitle}
								/>
							</LimitedLengthItem>
							<LimitedLengthItem
								required={true}
								label="SEO-Titel"
								maxLength={64}
								{...attachFormItemErrorProps(validation, 'seoTitle')}
							>
								<Input
									placeholder="SEO-Titel"
									defaultValue={seoTitle || ''}
									onMagicChange={this.handleChangeSeoTitle}
								/>
							</LimitedLengthItem>
							<LimitedLengthItem
								required={true}
								label="Teasertext Verteilseite"
								maxLength={160}
								{...attachFormItemErrorProps(validation, 'description')}
							>
								<TextArea
									rows={4}
									placeholder="Teasertext Verteilseite"
									defaultValue={description ?? undefined}
									onMagicChange={this.handleChangeDescription}
								/>
							</LimitedLengthItem>
							<Form.Item>
								<Checkbox
									defaultChecked={hasIntroductionText ?? undefined}
									onChange={this.handleChangehasIntroductionText}
								>
									Diese Seite enthält ein Textfeld
								</Checkbox>
							</Form.Item>
							<LimitedLengthItem
								label="Überschrift Textfeld"
								maxLength={75}
								{...attachFormItemErrorProps(validation, 'introductionHeadline')}
							>
								<Input
									placeholder="Überschrift Textfeld"
									defaultValue={introductionHeadline || ''}
									onMagicChange={this.handleChangeIntroductionHeadline}
								/>
							</LimitedLengthItem>
							<Form.Item
								label="Textfeld"
								help={`${descriptionLength}/${INTRODUCTION_TEXT_LENGTH_MAX} Zeichen`}
								{...attachFormItemErrorProps(validation, 'introductionText')}
								style={{ marginBottom: '35px' }}
							>
								<RichTextEditor
									defaultValue={introductionText?.trim() || '<p></p>'}
									onChange={this.handleChangeIntroductionText}
									placeholder="Textfeld"
									isSmallStyle={true}
									hasError={descriptionLength > INTRODUCTION_TEXT_LENGTH_MAX}
								/>
							</Form.Item>
							<Form.Item label="Rubrik" {...attachFormItemErrorProps(validation, 'categoryId')}>
								<CategoryPicker
									value={categoryId}
									onChange={this.handleChangeCategory}
									placeholder="Rubrik"
									showEmpty={true}
									displayBlock={true}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Panel>
		);
	}
}

interface OwnProps {
	boardId: string;
}

const connector = connect(
	({ boardBuffer }: ReduxState, { boardId }: OwnProps) => {
		// "protected" is a reserved word
		const {
			parentId,
			slug,
			title = null,
			seoTitle = null,
			description = null,
			hasIntroductionText = false,
			introductionHeadline = null,
			introductionText = null,
			categoryId = null,
			image = null,
			...rest
		} = boardBuffer.boards[boardId] || {};

		return {
			parentId,
			slug,
			title,
			description,
			hasIntroductionText,
			introductionHeadline,
			introductionText,
			categoryId,
			image,
			seoTitle,
			isProtected: rest.protected || false,
			validation: provideBoardValidationById(boardBuffer, boardId),
		};
	},
	(dispatch, { boardId }: OwnProps) => ({
		update: <T extends keyof BoardBufferPatch>(key: T, value: BoardBufferPatch[T]) =>
			dispatch(update(boardId, { [key]: value })),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export const MetaGeneral = connector(MetaGeneralComponent);
