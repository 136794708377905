import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import type { ConnectedProps } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';

import { connect } from '@/client/store/redux';

import routesConfig from '../../config/config.routes';
import * as boardsTeasersActions from '../../store/reducers/boardsTeasersManager';
import { Page } from '../Page';

import BoardsTeasersFilter from './BoardsTeasersFilter';
import BoardsTeasersTable from './BoardsTeasersTable';
import type { BoardsTeaserNode } from './BoardsTeasersTable/BoardsTeasersTableRow';

interface Props extends ReduxProps, RouteComponentProps {}

class BoardsTeasers extends Component<Props> {
	handleChangeSearchTerm = (value: { searchTerm: string }): void => {
		this.props.updateFilter(value);
	};

	handleClickOpen = (node: BoardsTeaserNode) => {
		this.props.history.push(`${routesConfig.boardsTeasers}/${node?.rowId}`);
	};

	render() {
		const { currentFilter, autoRefreshIn } = this.props;
		return (
			<Page>
				<Helmet>
					<title>Teaser</title>
				</Helmet>
				<Page.Content style={{ paddingInline: '1rem' }}>
					<BoardsTeasersFilter
						currentFilter={currentFilter}
						onChange={this.handleChangeSearchTerm}
					/>
					<BoardsTeasersTable
						onClickOpen={this.handleClickOpen}
						currentFilter={currentFilter}
						autoRefreshIn={autoRefreshIn}
					/>
				</Page.Content>
			</Page>
		);
	}
}

const connector = connect(
	(state) => ({
		currentFilter: state.boardsTeasersManager,
		autoRefreshIn: state.user.settings.autoRefreshIn,
	}),
	{
		updateFilter: boardsTeasersActions.updateFilter,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default withRouter(connector(BoardsTeasers));
