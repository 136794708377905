/**
 * @generated SignedSource<<ce9b0923fafeca4196053b0516d86309>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Capability = "BASIC" | "FULLY_FEATURED" | "TEXT_ONLY";
export type ModuleType = "AUDIO" | "EMBED" | "GALLERY" | "IMAGE" | "LIVESTREAM" | "TEXT" | "VIDEO" | "VIDEO360";
export type Priority = "CRITICAL" | "HIGH" | "LOW" | "NORMAL";
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
import { FragmentRefs } from "relay-runtime";
export type ArticlesTable_query$data = {
  readonly articles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly authorByRevisionBy: {
          readonly firstname: string | null;
          readonly lastname: string | null;
        } | null;
        readonly capability: Capability;
        readonly categoryByPrimaryCategory: {
          readonly color: string | null;
        } | null;
        readonly createdAt: string;
        readonly firstPublicationDate: string | null;
        readonly headline: string | null;
        readonly id: string;
        readonly image: {
          readonly url: string | null;
        } | null;
        readonly isTemplate: boolean;
        readonly lastEdited: string;
        readonly modules: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly type: ModuleType | null;
            } | null;
          }>;
        };
        readonly notes: string | null;
        readonly primaryCategory: string;
        readonly publicationDate: string | null;
        readonly publicationPriority: Priority;
        readonly rowId: string;
        readonly slug: string;
        readonly sourceOrigin: string | null;
        readonly status: Status;
        readonly title: string;
        readonly " $fragmentSpreads": FragmentRefs<"LockedByAuthor_article">;
      } | null;
    }>;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
      readonly startCursor: any | null;
    };
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "ArticlesTable_query";
};
export type ArticlesTable_query$key = {
  readonly " $data"?: ArticlesTable_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticlesTable_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "capabilityFilter"
    },
    {
      "kind": "RootArgument",
      "name": "categoryFilter"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "onlyTemplates"
    },
    {
      "kind": "RootArgument",
      "name": "publishedBefore"
    },
    {
      "kind": "RootArgument",
      "name": "searchTerm"
    },
    {
      "kind": "RootArgument",
      "name": "sortBy"
    },
    {
      "kind": "RootArgument",
      "name": "statusFilter"
    },
    {
      "kind": "RootArgument",
      "name": "tagsFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "articles"
        ]
      }
    ]
  },
  "name": "ArticlesTable_query",
  "selections": [
    {
      "alias": "articles",
      "args": [
        {
          "kind": "Variable",
          "name": "capabilityFilter",
          "variableName": "capabilityFilter"
        },
        {
          "kind": "Variable",
          "name": "categoryFilter",
          "variableName": "categoryFilter"
        },
        {
          "kind": "Variable",
          "name": "onlyTemplates",
          "variableName": "onlyTemplates"
        },
        {
          "kind": "Variable",
          "name": "publishedBefore",
          "variableName": "publishedBefore"
        },
        {
          "kind": "Variable",
          "name": "searchPlain",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "sortBy",
          "variableName": "sortBy"
        },
        {
          "kind": "Variable",
          "name": "statusFilter",
          "variableName": "statusFilter"
        },
        {
          "kind": "Variable",
          "name": "tagsFilter",
          "variableName": "tagsFilter"
        }
      ],
      "concreteType": "ArticlesConnection",
      "kind": "LinkedField",
      "name": "__ArticlesTable_articles_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticlesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Article",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "headline",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primaryCategory",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "capability",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstPublicationDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publicationDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publicationPriority",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastEdited",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isTemplate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sourceOrigin",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleImage",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Category",
                  "kind": "LinkedField",
                  "name": "categoryByPrimaryCategory",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModulesConnection",
                  "kind": "LinkedField",
                  "name": "modules",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ModulesEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Module",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "type",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "authorByRevisionBy",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstname",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastname",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notes",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LockedByAuthor_article"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a75e982afab94ccc5f70e56496669210";

export default node;
