import { Tooltip } from 'antd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

const CheckmarkContainer = styled.div`
	align-items: center;
	background-color: #0a9ed7;
	border-radius: 50%;
	display: flex;
	height: 17px;
	justify-content: center;
	margin-left: 5px;
	width: 17px;
`;

const CheckmarkIcon = styled.div`
	display: inline-block;
	margin-top: -2px;
	transform: rotate(45deg);
	height: 9px;
	width: 5px;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
`;

export default function Checkmark() {
	const translate = useTranslate();

	return (
		<Tooltip title={translate('authors.systemprofile')}>
			<CheckmarkContainer>
				<CheckmarkIcon />
			</CheckmarkContainer>
		</Tooltip>
	);
}
