import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../environment';

import CategoryPickerDropdown from './CategoryPickerDropdown';
import type { CategoryPickerQuery } from './__generated__/CategoryPickerQuery.graphql';

interface Props {
	defaultValue?: string | null;
	onChange?: (category: string | null, categoryTitle: string | null) => void;
	showPickAll?: boolean;
	className?: string;
	displayBlock?: boolean;
	value?: string | null;
	placeholder?: string | null;
	showEmpty?: boolean;
}

export default function (componentProps: Props) {
	return (
		<QueryRenderer<CategoryPickerQuery>
			environment={environment}
			query={graphql`
				query CategoryPickerQuery {
					allCategories(first: 1000, condition: { active: true }) {
						edges {
							node {
								id
								rowId
								title
							}
						}
					}
				}
			`}
			render={({ props }) => {
				if (props) {
					const options = (props?.allCategories?.edges || [])
						.map((edge) => {
							const node = edge?.node;

							if (!node) {
								return false;
							}

							return { key: node.rowId, label: node.title };
						})
						.filter((value): value is { key: string; label: string } => !!value);

					return <CategoryPickerDropdown options={options} {...componentProps} />;
				}
				return null;
			}}
			variables={{}}
		/>
	);
}
