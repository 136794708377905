import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import updateAction from '../../actions/update';
import { articleSourceOriginSelector } from '../../selectors';

import SourceOriginContainerRefetch from './SourceOriginContainerRefetch';
import SourceOriginRenderer from './SourceOriginRenderer';

interface Props extends ReduxProps {
	key: string | number;
}

class SourceOriginContainer extends Component<Props> {
	handleAdd = (id?: string | null, date?: string | null) => {
		this.props.update({
			sourceOrigin: id,
			sourceBroadcastDate: date,
			language: undefined,
		});
	};

	handleChange = (
		_index: number,
		id: string | null | undefined,
		date: string | null | undefined
	) => {
		this.props.update({
			sourceOrigin: id,
			sourceBroadcastDate: date,
			language: undefined,
		});
	};

	handleRemove = () => {
		this.props.update({
			sourceOrigin: null,
			sourceBroadcastDate: null,
			language: undefined,
		});
	};

	render() {
		const { sourceOriginId, sourceOriginDate, update, ...rest } = this.props;

		return (
			<SourceOriginRenderer defaultSourceOriginRowId={sourceOriginId}>
				{({ error, props }) => {
					if (error) {
						throw error;
					}

					return (
						<SourceOriginContainerRefetch
							{...rest}
							query={props}
							onAdd={this.handleAdd}
							onChange={this.handleChange}
							onRemove={this.handleRemove}
							sourceOriginDate={sourceOriginDate}
							sourceOriginRowId={sourceOriginId}
						/>
					);
				}}
			</SourceOriginRenderer>
		);
	}
}

const connector = connect(articleSourceOriginSelector, {
	update: updateAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SourceOriginContainer);
