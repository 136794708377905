import { sortBy } from 'lodash-es';

import type { NavigationRoot, TreeNavigationItem, UpsertNavigationItemPayload } from '../types';

export const flattenNavigationData = (data: NavigationRoot) => {
	if (data === null) {
		return [];
	}

	const clone = structuredClone(data);

	const flattenTree = (data: UpsertNavigationItemPayload, result: TreeNavigationItem[] = []) => {
		if (data.navigationsByParent) {
			for (const edge of data.navigationsByParent.edges) {
				const { node } = edge;

				if (!node || typeof node.rowId === 'undefined' || node.rowId === null) {
					return result;
				}

				result.push({ data: node });

				if ('navigationsByParent' in node) {
					flattenTree(node, result);
					delete node.navigationsByParent;
				}
			}
		}
		return result;
	};

	return sortBy(flattenTree(clone), (item) => item.data.order);
};
