import { commitMutation, type Environment, graphql } from 'react-relay';

import type { RepublishArticleMutation } from './__generated__/RepublishArticleMutation.graphql';

type ArticleRepublishPatch = {
	slug: string;
	title?: string;
};

export default (
	articleId: string,
	patch: ArticleRepublishPatch,
	environment: Environment
): Promise<RepublishArticleMutation['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<RepublishArticleMutation>(environment, {
			mutation: graphql`
				mutation RepublishArticleMutation($input: RepublishArticleInput!) {
					republishArticle(input: $input) {
						article {
							rowId
							slug
							title
							status
							lastEdited
							publicationDate
						}
					}
				}
			`,
			variables: {
				input: {
					articleId,
					overwriteSlug: patch.slug,
					overwriteTitle: patch.title || null,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
