import { Component } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../translation';

type WrapperProps = {
	isVisible: boolean;
};

const Wrapper = styled.div<WrapperProps>`
	font-size: 14px;
	z-index: 20;
	position: fixed;
	top: 55px;
	right: ${(props) => (props.isVisible ? '0px' : `-${props.theme.assetManager.width}`)};
	width: ${(props) => props.theme.assetManager.width};
	height: calc(100vh - 55px);
	padding: ${(props) => props.theme.assetManager.innerPadding};
	background-color: ${(props) => props.theme.colors.dark4};
	transition-duration: ${(props) => props.theme.assetManager.transitionDuration};

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
`;

type Props = WithTranslationProps & {
	children: ReactNode;
	outsideClick: () => void;
	isVisible?: boolean;
};

class AssetManager extends Component<Props> {
	wrapperRef: HTMLDivElement | undefined | null;

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef = (node?: HTMLDivElement | null) => {
		this.wrapperRef = node;
	};

	isParentModal = (node: HTMLElement): boolean => {
		const modals = document.querySelectorAll('.ant-modal-wrap');

		if (modals.length > 0) {
			for (const modal of modals) {
				if (modal.contains(node)) {
					return true;
				}
			}
		}

		return false;
	};

	handleClickOutside = (event: MouseEvent) => {
		if (!event.target) {
			return;
		}

		const isInsideOfModal =
			event.target instanceof HTMLElement ? this.isParentModal(event.target) : false;

		const { outsideClick } = this.props;

		if (!outsideClick) {
			return;
		}

		const {
			translation: { translate },
		} = this.props;

		if (this.wrapperRef && !this.wrapperRef.contains(event.target as any)) {
			const targetButtonEl = (event.target as any).closest('a');
			// yes, this is hacky, but it minimizes refactoring necessary to do this the "right way (TM)"
			if (
				targetButtonEl &&
				targetButtonEl.title &&
				targetButtonEl.title === translate('assetManager.ImporterButton.toolTip')
			) {
				return;
			}

			if (isInsideOfModal) {
				return;
			}

			outsideClick();
		}
	};

	render() {
		const { children, isVisible = false, ...rest } = this.props;
		return (
			<Wrapper isVisible={isVisible} {...rest} ref={this.setWrapperRef}>
				{children}
			</Wrapper>
		);
	}
}

export default withTranslation(AssetManager);
