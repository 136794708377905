import { Component } from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

import { AM_TYPE_ARTICLE, AM_TYPE_BOARD, AM_TYPE_BRBILD_IMAGE } from '../../../constants';
import type { ImportedItem, Item } from '../../../containers/AssetManager';
import type { AssetManagerStatus } from '../../../containers/AssetManager/AssetManagerItem';
import { Copyright as CopyrightIcon, Accept } from '../../Icon';

import AssetManagerItemHoverOverlay from './AssetManagerItemHoverOverlay';

type ColorStandard = 'standard';
type ColorDark = 'dark';
type Color = ColorStandard | ColorDark;

type WrapperProps = {
	isDragging: boolean;
	isDraggable: boolean;
	color?: Color;
};

const Wrapper = styled.div<WrapperProps>`
	cursor: ${(props: WrapperProps) => (props.isDraggable ? 'move' : 'pointer')};
	align-items: center;
	background-color: ${(props) =>
		props.color === 'standard' ? props.theme.assetManager.itemColor : '#000000'};
	border-radius: 2px;
	display: flex;
	position: relative;
	overflow: hidden;
	margin-bottom: ${(props) => props.theme.assetManager.innerPadding};
	box-shadow: ${(props) =>
		props.isDragging ? `inset 0 0 2px ${props.theme.colors.primaryBlue}` : 'none'};
`;

type CenteredWrapperProps = {
	isVisible: boolean;
};

const CenteredWrapper = styled.div`
	display: ${(props: CenteredWrapperProps) => (props.isVisible ? 'flex' : 'none')};
	filter: blur(0px);
	z-index: 1;
	left: 0;
	min-height: 100%;
	position: absolute;
	top: 0;
	width: 100%;
`;

const SelectionMarkWrapper = styled.div`
	font-size: 3rem;
	color: #ffffff;
	flex: 0 1 100%;
	max-width: 100%;
	min-height: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ease 200ms all;
	background-color: rgba(0, 0, 0, 0.4);
`;

function SelectionMark({ ...rest }: any) {
	return (
		<CenteredWrapper {...rest} isVisible={true}>
			<SelectionMarkWrapper>
				<Accept />
			</SelectionMarkWrapper>
		</CenteredWrapper>
	);
}

type InnerProps = {
	isBlurred?: boolean;
};

const Inner = styled.div<InnerProps>`
	filter: ${(props) => (props.isBlurred ? 'blur(4px)' : 'none')};
	display: flex;
	min-height: 100%;
	pointer-events: none;
	width: 100%;
`;

export const Title = styled.h6`
	font-weight: 200;
	margin-bottom: 0.1rem;
	font-size: 1.2rem;
	color: #ffffff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Subtitle = styled.h6`
	font-weight: 200;
	font-size: 1rem;
	color: #fff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Info = styled.div`
	color: rgba(255, 255, 255, 0.6);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
`;

type ContentProps = {
	$isProminent?: boolean;
};

export const Content = styled.div<ContentProps>`
	width: 100%;
	padding: ${(props) => props.theme.assetManager.item.padding};
	color: #ffffff;
	overflow: hidden;
	font-size: ${(props) => (props.$isProminent ? '1.2rem' : '1rem')};
`;

const CopyrightWrapper = styled.div`
	color: rgba(255, 255, 255, 0.6);
	margin-top: 0.5rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

type AssetManagerItemCopyrightProps = {
	children: any;
};

export function Copyright({ children }: AssetManagerItemCopyrightProps) {
	return (
		<CopyrightWrapper>
			<CopyrightIcon /> {children}
		</CopyrightWrapper>
	);
}

type ContainerProps = {
	isSelected?: boolean;
	isDragging?: boolean;
	isDraggable?: boolean;
	children: any;
	color?: Color;
	item: Item | ImportedItem;
	currentStatus: AssetManagerStatus;
	mode?: string;
	onClickSelectionMark?: (event: SyntheticEvent<HTMLElement>) => void;
	onClickInfoMark?: () => void;
	toggleDragIsCopy?: (isCopy: boolean) => void;
	sendToAssetManager: () => void;
};

type ContainerState = {
	isHovered: boolean;
};

class Container extends Component<ContainerProps, ContainerState> {
	static defaultProps = {
		isSelected: false,
		isDragging: false,
		isDraggable: true,
		color: 'standard',
	};

	state = {
		isHovered: false,
	};

	handleClickSelectionMark = (event: SyntheticEvent<HTMLElement>) => {
		const { onClickSelectionMark } = this.props;

		if (onClickSelectionMark) {
			onClickSelectionMark(event);
		}

		// TODO: fucking hacky... find another solution
		setTimeout(() => this.updateHoverState(true), 5);
	};

	handleMouseEnter = () => this.updateHoverState(true);

	handleMouseLeave = () => this.updateHoverState(false);

	updateHoverState = (isHovered: boolean) => {
		const { isSelected } = this.props.currentStatus;

		if (isHovered && isSelected) {
			return;
		}

		this.setState({ isHovered });
	};

	render() {
		const {
			mode,
			currentStatus,
			children,
			onClickSelectionMark,
			onClickInfoMark,
			toggleDragIsCopy,
			item,
			sendToAssetManager,
			...rest
		} = this.props;
		const { isHovered } = this.state;

		const { isSelected, isDragging, isDraggable } = currentStatus;

		let isEditable = false;
		let isBrBildContent = false;
		let editUrl: null | string = null;

		if (item.type === AM_TYPE_BOARD) {
			isEditable = true;
			editUrl = `/boards/${item.boardId}`;
		}

		if (item.type === AM_TYPE_ARTICLE) {
			isEditable = true;
			editUrl = `/articleEditor/${item.articleId}`;
		}

		if (item.type === AM_TYPE_BRBILD_IMAGE) {
			isBrBildContent = true;
		}

		let isBlurred = isHovered || isSelected;
		if (isDragging && isDraggable) {
			isBlurred = false;
		}

		return (
			<Wrapper
				{...rest}
				isDragging={isDragging}
				isDraggable={isDraggable}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				onClick={this.handleMouseLeave}
			>
				{isSelected && (
					<SelectionMark
						onClick={this.handleClickSelectionMark}
						onMouseLeave={this.handleMouseLeave}
					/>
				)}
				<Inner isBlurred={isBlurred}>{children}</Inner>

				{isHovered && !isSelected && (
					<AssetManagerItemHoverOverlay
						toggleDragIsCopy={toggleDragIsCopy}
						currentStatus={{
							...currentStatus,
							isEditable,
							isBrBildContent,
						}}
						editUrl={editUrl}
						sendToAssetManager={sendToAssetManager}
						handleClickSelectionMark={this.handleClickSelectionMark}
						handleClickInfoMark={onClickInfoMark}
					/>
				)}
			</Wrapper>
		);
	}
}

export default Container;
