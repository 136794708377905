import { CloseCircleOutlined } from '@ant-design/icons';
import { CATEGORY_ALL } from '@sep/br24-constants';
import { Affix, Radio, type RadioChangeEvent } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import CategoryPicker from '../../CategoryPicker';
import DelayedSearch from '../../DelayedSearch';

import ArticleSearch from './ArticleSearch';
import BoardSearch from './BoardSearch';
import BoardsTeaserSearch from './BoardsTeaserSearch';
import { InfiniteScrollProvider } from './InfiniteScroll';
import MaxHeight from './MaxHeight';
import { updateSearchTerm, updateCategory, resetFilter } from './state';

const WrapperQuery = styled.div`
	background-color: #efefef;
	padding: 12px !important;
`;

const WrapperQueryItem = styled.div`
	margin-bottom: 6px !important;
`;

const WrapperResultOptions = styled.div`
	background-color: #efefef;
	justify-content: center;
	display: flex;
	padding: 12px !important;
`;

const WrapperResult = styled.div`
	flex: 1;
	display: flex;
	overflow-y: scroll;
`;

type Props = any;

export type State = {
	searchTerm: string | undefined | null;
	category: string | undefined | null;
	selectedResultOption: string;
};

export default class Search extends Component<Props, State> {
	private wrapperResultRef: HTMLDivElement | null = null;

	state = {
		searchTerm: null,
		category: null,
		selectedResultOption: 'articles',
	};

	setWrapperResultRef = (ref: HTMLDivElement | null) => {
		this.wrapperResultRef = ref;
	};

	handleChangeCategory = (category: string | null) => this.setState(updateCategory(category));

	handleChangeResultOptions = (event: RadioChangeEvent) => {
		const target = event.target;
		this.setState({ selectedResultOption: target.value });
	};

	handleChangeSearchTerm = (searchTerm: string) => this.setState(updateSearchTerm(searchTerm));

	handleClickResetFilter = () => this.setState(resetFilter);

	render() {
		const { searchTerm, category, selectedResultOption } = this.state;
		const query = { searchTerm, category };
		const hasChanges = searchTerm !== null || category !== null;

		return (
			<Affix>
				<MaxHeight>
					<WrapperQuery>
						<WrapperQueryItem>
							<DelayedSearch
								placeholder="Durchsuchen..."
								defaultValue={searchTerm}
								onChange={this.handleChangeSearchTerm}
							/>
						</WrapperQueryItem>
						<WrapperQueryItem>
							<CategoryPicker
								showPickAll={true}
								defaultValue={category || CATEGORY_ALL}
								onChange={this.handleChangeCategory}
							/>
						</WrapperQueryItem>
						{hasChanges ? (
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<a onClick={this.handleClickResetFilter}>
								<CloseCircleOutlined /> Alle Filter zurücksetzten.
							</a>
						) : (
							false
						)}
					</WrapperQuery>
					<WrapperResultOptions>
						<Radio.Group
							value={selectedResultOption}
							buttonStyle="solid"
							onChange={this.handleChangeResultOptions}
						>
							<Radio.Button value="articles">Artikel</Radio.Button>
							<Radio.Button value="boards">Verteilseiten</Radio.Button>
							<Radio.Button value="boardsTeasers">Linkteaser</Radio.Button>
						</Radio.Group>
					</WrapperResultOptions>
					<WrapperResult ref={(ref) => this.setWrapperResultRef(ref)}>
						<InfiniteScrollProvider value={this.wrapperResultRef}>
							{selectedResultOption === 'articles' ? <ArticleSearch {...query} /> : null}
							{selectedResultOption === 'boards' ? <BoardSearch {...query} /> : null}
							{selectedResultOption === 'boardsTeasers' ? <BoardsTeaserSearch {...query} /> : null}
						</InfiniteScrollProvider>
					</WrapperResult>
				</MaxHeight>
			</Affix>
		);
	}
}
