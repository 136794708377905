import { EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { STATUS_PUBLISHED, CAPABILITY_FULLY_FEATURED } from '@sep/br24-constants';
import { Tooltip, Button, Dropdown } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';
import type { MenuInfo } from '@/types/libs';

import type { TableRowListeners } from './ArticlesTable';
import type { ArticlesTableArticle } from './types';

type Props = TableRowListeners & {
	node: ArticlesTableArticle;
};

const ButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: 1rem;
	padding-left: 1rem;
`;
const ButtonBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SquareButton = styled(Button)`
	border-radius: 4px;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	position: relative;
`;

function handleMenuClick(info: MenuInfo, props: Props, node: ArticlesTableArticle) {
	switch (info.key) {
		case 'preview':
			props.onClickPreview(info, node);
			break;
		case 'republish':
			props.onClickRepublish(node);
			break;
		case 'createFromTemplate':
			props.onClickCreateFromTemplate(info, node);
			break;
	}
}

export default function ArticlesTableRowButtons(props: Props) {
	const translate = useTranslate();

	const { node, onClickOpen } = props;

	if (!node) {
		return null;
	}
	const isPublished = node.status && node.status === STATUS_PUBLISHED;
	const isFullyCapable = node.capability && node.capability === CAPABILITY_FULLY_FEATURED;
	const isRepublishable = isPublished && isFullyCapable;

	const menuItems: ItemType[] = [{ label: 'Vorschau', key: 'preview' }];

	if (!node.isTemplate) {
		menuItems.push({
			label: translate('articles.table.row.republish'),
			key: 'republish',
			disabled: !isRepublishable,
		});
	} else {
		menuItems.push({
			label: translate('articles.table.row.createFromTemplate'),
			key: 'createFromTemplate',
		});
	}

	return (
		<ButtonWrapper>
			<ButtonBox>
				<Tooltip title={translate('articles.table.row.editArticle')}>
					<SquareButton onClick={(event) => onClickOpen(event, node)} size="small" type="primary">
						<EditOutlined />
					</SquareButton>
				</Tooltip>
			</ButtonBox>
			<ButtonBox>
				<Dropdown
					menu={{
						items: menuItems,
						onClick: (info) => handleMenuClick(info, props, node),
					}}
					placement="bottomRight"
				>
					<SquareButton size="small" type="ghost">
						<EllipsisOutlined />
					</SquareButton>
				</Dropdown>
			</ButtonBox>
		</ButtonWrapper>
	);
}
