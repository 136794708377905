import type { ComponentType } from 'react';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import * as Form from '../Form';

const connector = connect(
	(state: ReduxState) => ({
		isTypingDelayActive: state.user.settings.boardAutoValidation,
	}),
	// this will prevent the addition of dispatch to the properties of the component
	{}
);

type WithValidationStateProps = {
	isTypingDelayActive: boolean;
};

function withValidationState<T extends WithValidationStateProps>(component: ComponentType<T>) {
	return connector(component as any) as ComponentType<Omit<T, keyof WithValidationStateProps>>;
}

export const Input = withValidationState(Form.Input);
export const TextArea = withValidationState(Form.TextArea);
