import Form from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input/Input';
import Modal from 'antd/lib/modal/Modal';
import { useCallback, useEffect, useState } from 'react';

import { ImageCropper, type Props as ImageCropperProps } from './ImageCropper';

interface Meta {
	title?: string;
	copyright?: string;
	description?: string;
}

export type ImageCropperModalResultHandler = (src: string, meta?: Meta) => void;

export const ImageCropperModal = ({
	onCancel,
	onChange,
	onConfirm,
	meta,
	width,
	hideMeta,
	...rest
}: Omit<ImageCropperProps, 'hideControls' | 'onChange' | 'onConfirm'> & {
	meta?: Meta;
	width?: number;
	hideMeta?: boolean;
	onChange?: ImageCropperModalResultHandler;
	onConfirm: ImageCropperModalResultHandler;
}) => {
	const [title, setTitle] = useState(meta?.title);
	const [description, setDescription] = useState(meta?.description);
	const [copyright, setCopyright] = useState(meta?.copyright);

	const [imageUrl, setImageUrl] = useState(rest.src);

	/** ----------------------------------------------------------------------------------------------
	 * handle confirmation
	 * _______________________________________________________________________________________________ */
	const handleOk = useCallback(() => {
		onConfirm(imageUrl, { title, copyright, description });
	}, [copyright, description, imageUrl, onConfirm, title]);

	/** ----------------------------------------------------------------------------------------------
	 * propagate changes
	 * _______________________________________________________________________________________________ */
	useEffect(() => {
		onChange?.(imageUrl, { title, copyright, description });
	}, [copyright, description, imageUrl, onChange, title]);

	return (
		<Modal
			width={width ?? 800}
			title="Bild bearbeiten"
			onCancel={onCancel}
			onOk={handleOk}
			okText="Bestätigen"
			cancelText="Abbrechen"
			open={true}
			maskClosable={false}
		>
			<ImageCropper hideControls={true} onChange={setImageUrl} {...rest} />
			{hideMeta ? null : (
				<Form
					labelCol={{ span: 5 }}
					wrapperCol={{ span: 19 }}
					labelAlign="left"
					style={{ marginTop: '2em' }}
				>
					<FormItem label="Bildbeschreibung">
						<Input
							type="text"
							defaultValue={meta?.description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</FormItem>
					<FormItem label="Bildunterschrift">
						<Input
							type="text"
							defaultValue={meta?.title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</FormItem>
					<FormItem label="Copyright">
						<Input
							type="text"
							defaultValue={meta?.copyright}
							onChange={(e) => setCopyright(e.target.value)}
						/>
					</FormItem>
				</Form>
			)}
		</Modal>
	);
};
