import { Spin } from 'antd';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { fetchQuery, graphql, useQueryLoader } from 'react-relay';
import styled from 'styled-components';

import ErrorBoundry from '@/client/components/ErrorHandler/index';

import environment from '../../environment';

import ArticleEditor, { type Props as ArticleEditorProps } from './ArticleEditor';
import type { ArticleEditorContainerQuery } from './__generated__/ArticleEditorContainerQuery.graphql';

type Props = {
	match: {
		params: {
			id: string;
		};
	};
} & ArticleEditorProps;

const query = graphql`
	query ArticleEditorContainerQuery($id: String!) {
		article: articleByRowId(rowId: $id) {
			...ArticleEditor_article
		}
	}
`;

const SpinnerWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
	height: 90vh;
`;

const Loader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
`;

export default function (props: Props) {
	const [queryReference, loadQuery] = useQueryLoader<ArticleEditorContainerQuery>(query);
	const [isRefreshing, setIsRefreshing] = useState(false);

	useEffect(() => {
		loadQuery({ id: props.match.params.id }, { fetchPolicy: 'network-only' });
	}, [props.match.params.id]);

	const refresh = useCallback(
		(onFinished: (err?: Error) => void) => {
			if (isRefreshing) {
				return;
			}
			const variables = { id: props.match.params.id };
			setIsRefreshing(true);

			// fetchQuery will fetch the query and write
			// the data to the Relay store. This will ensure
			// that when we re-render, the data is already
			// cached and we don't suspend
			fetchQuery(environment, query, variables).subscribe({
				complete: () => {
					setIsRefreshing(false);

					// *After* the query has been fetched, we call
					// loadQuery again to re-render with a new
					// queryRef.
					// At this point the data for the query should
					// be cached, so we use the 'store-only'
					// fetchPolicy to avoid suspending.
					loadQuery(variables, { fetchPolicy: 'store-only' });
					onFinished();
				},
				error: (err: Error) => {
					setIsRefreshing(false);
					onFinished(err);
				},
			});
		},
		[props.match.params.id]
	);

	const loadingSpinner = (
		<SpinnerWrapper>
			<Loader>
				<Spin size="large" />
			</Loader>
		</SpinnerWrapper>
	);

	return (
		<Suspense fallback={loadingSpinner}>
			<ErrorBoundry>
				{queryReference != null && (
					<ArticleEditor
						{...(props as ArticleEditorProps)}
						id={props.match.params.id}
						queryReference={queryReference}
						refresh={refresh}
					/>
				)}
			</ErrorBoundry>
		</Suspense>
	);
}
