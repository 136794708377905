import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { media } from '../../../util/styled';

type Props = {
	isOrderedByPublicationDate: boolean;
};

type TableHeadProps = {
	width: number;
	center?: boolean;
	desktopW?: number;
	hide?: boolean;
	mobileW?: number;
	sHide?: boolean;
};

const TableHead = styled.th<TableHeadProps>`
	flex-basis: ${(props) => props.width}vw;
	text-align: ${(props) => (props.center ? 'center' : 'left')};

	${media.lessThan<TableHeadProps>('monster')`
				.am-open {
					width: ${(props) => (props.desktopW ? props.desktopW : props.width)}vw;
					display: ${(props) => (props.hide ? 'none' : 'block')};
				}
			`};
	${media.lessThan<TableHeadProps>('giant')`
				flex-basis: ${(props) => (props.desktopW ? props.desktopW : props.width)}vw;
				display: ${(props) => (props.hide ? 'none' : 'block')};
				.am-open {
					width: ${(props) => (props.mobileW ? props.mobileW : props.desktopW)}vw;
					display: ${(props) => (props.hide || props.sHide ? 'none' : 'block')};
				}
			`};
	${media.lessThan<TableHeadProps>('medium')`
				flex-basis: ${(props) => (props.mobileW ? props.mobileW : props.desktopW)}vw;
				display: ${(props) => (props.hide || props.sHide ? 'none' : 'block')};
			`};
`;

const Indicator = styled.th`
	width: 6px;
`;

const TableRow = styled.tr`
	height: 4em;
	cursor: default;
	user-select: none;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export function ArticlesTableHead(props: Props) {
	const { isOrderedByPublicationDate } = props;

	const translate = useTranslate();

	return (
		<thead>
			<TableRow>
				<Indicator />
				<TableHead width={3} desktopW={5} />
				<TableHead width={43} desktopW={40} mobileW={75}>
					{translate('articles.table.header.headline')}
				</TableHead>
				<TableHead width={5} hide={true}>
					{translate('articles.table.header.type')}
				</TableHead>
				<TableHead width={10} hide={true}>
					{translate('articles.table.header.components')}
				</TableHead>
				<TableHead width={10} hide={true}>
					{translate('articles.table.header.editor')}
				</TableHead>
				<TableHead width={5} center={true} hide={true}>
					{translate('articles.table.header.notes')}
				</TableHead>
				<TableHead width={5} center={true} desktopW={10}>
					{translate('articles.table.header.active')}
				</TableHead>
				<TableHead width={8} center={true} desktopW={15} sHide={true}>
					{isOrderedByPublicationDate
						? translate('articles.table.header.publication')
						: translate('articles.table.header.edited')}
				</TableHead>
				<TableHead width={8} center={true} hide={true}>
					{translate('articles.table.header.sourceOrigin')}
				</TableHead>
				<TableHead width={3} center={true} desktopW={5} mobileW={10}>
					{translate('articles.table.header.status')}
				</TableHead>
				<TableHead width={6} desktopW={10} sHide={true} />
			</TableRow>
		</thead>
	);
}
