import { Button as AntButton, Input as AntInput } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';

type Props = WithTranslationProps & {
	onSubmit: (a: string) => void;
};

type State = {
	term: string;
};

const Wrapper = styled.div`
	display: flex;
	margin-bottom: 2rem;
`;

const Input = styled(AntInput)`
	flex: 1;
	border-right: none;
`;

const Button = styled(AntButton)``;

class ArticleMetaTeasersImporterSearch extends Component<Props, State> {
	state = {
		term: '',
	};

	handleSubmit = () => {
		if (this.state.term) {
			this.props.onSubmit(this.state.term);
		}
	};

	handleTermChange = (ev) => {
		this.setState({ term: ev.currentTarget.value });
	};

	render() {
		const {
			translation: { translate },
		} = this.props;

		return (
			<Wrapper>
				<Input
					onChange={this.handleTermChange}
					placeholder={translate('article.meta.teasers.searchPlaceholder')}
					onPressEnter={this.handleSubmit}
				/>
				<Button type="primary" onClick={this.handleSubmit}>
					{translate('article.meta.teasers.searchButtonLabel')}
				</Button>
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleMetaTeasersImporterSearch);
