import useResizeObserver from '@react-hook/resize-observer';
import { type RefObject, useLayoutEffect, useState } from 'react';

export const useSize = <T extends HTMLElement>(target: RefObject<T>) => {
	const [size, setSize] = useState<DOMRect>();

	useLayoutEffect(() => {
		setSize(target.current?.getBoundingClientRect());
	}, [target]);

	// Where the magic happens
	useResizeObserver(target, (entry) => setSize(entry.contentRect));
	return size;
};
