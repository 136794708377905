import classNames from 'classnames';
import { createElement } from 'react';

type IconFlip = 'horizontal' | 'vertical';
type IconSize = 'lg' | '2x' | '3x' | '4x' | '5x';
type IconRotate = 90 | 180 | 270;
type IconStack = '1x' | '2x';
type AdditionalClassNames =
	| `fa-${IconSize}`
	| `fa-flip-${IconFlip}`
	| `fa-rotate-${IconRotate}`
	| `fa-stack-${IconStack}`
	| 'fa-spin'
	| 'fa-pulse'
	| 'fa-inverse';

type Props = {
	componentType?: string | null;
	name: string;
	className?: string;
	flip?: IconFlip;
	inverse?: boolean;
	pulse?: boolean;
	rotate?: IconRotate;
	size?: IconSize;
	spin?: boolean;
	stack?: IconStack;
};

export default function Icon(props: Props) {
	const { name, className, flip, inverse, pulse, rotate, size, spin, stack } = props;

	const additionalClassNames: AdditionalClassNames[] = [];

	if (size) {
		additionalClassNames.push(`fa-${size}`);
	}

	if (spin) {
		additionalClassNames.push('fa-spin');
	}

	if (pulse) {
		additionalClassNames.push('fa-pulse');
	}

	if (inverse) {
		additionalClassNames.push('fa-inverse');
	}

	if (flip) {
		additionalClassNames.push(`fa-flip-${flip}`);
	}

	if (rotate) {
		additionalClassNames.push(`fa-rotate-${rotate}`);
	}

	if (stack) {
		additionalClassNames.push(`fa-stack-${stack}`);
	}

	return createElement(props.componentType || 'span', {
		className: classNames('fa', `fa-${name}`, className, additionalClassNames),
		'aria-hidden': true,
	});
}
