import {
	CAPABILITY_TEXT_ONLY,
	MODULE_TYPE_AUDIO,
	MODULE_TYPE_EMBED,
	MODULE_TYPE_GALLERY,
	MODULE_TYPE_IMAGE,
	MODULE_TYPE_TEXT,
	MODULE_TYPE_VIDEO,
	MODULE_TYPE_VIDEO360,
	MODULE_TYPE_LIVESTREAM,
} from '@sep/br24-constants';
import type { User } from '@sep/cms-auth-client';
import ih from 'immutability-helper';
import { Component, Fragment, type ReactElement } from 'react';
import type { ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { connect } from '@/client/store/redux';

import { useUser } from '../../../auth/AuthContext';
import config from '../../../config';
import type { Item } from '../../AssetManager';
import type { SimpleItem } from '../DragAndDropHandler/DndSortModuleContainer';
import createModuleAction from '../actions/createModule';
import deleteModuleAction from '../actions/deleteModule';
import update from '../actions/update';
import updateModuleAction from '../actions/updateModule';
import convert from '../convert';
import { articleModulesContainerSelector } from '../selectors';

import { ArticleEditorAttachedDropzone } from './ArticleEditorAttachedDropzone';
import ArticleBrokenModule from './ArticleEditorBrokenModule';
import ArticleEditorModule360 from './ArticleEditorModule360';
import ArticleEditorModuleAudio from './ArticleEditorModuleAudio';
import ArticleEditorModuleCreator from './ArticleEditorModuleCreator';
import ArticleEditorModuleEmbed from './ArticleEditorModuleEmbed';
import ArticleEditorModuleGallery from './ArticleEditorModuleGallery';
import ArticleEditorModuleHeader from './ArticleEditorModuleHeader';
import ArticleEditorModuleImage from './ArticleEditorModuleImage';
import ArticleEditorModuleLivestream from './ArticleEditorModuleLivestream';
import ArticleEditorModuleText from './ArticleEditorModuleText';
import ArticleEditorModuleVideo from './ArticleEditorModuleVideo';
import ArticleEditorModuleStartdropzone from './ArticleEditorModulesStartDropzone';

const Wrapper = styled.div`
	margin: 0 0 50px 0;
`;

const ModuleCreatorWrapper = styled.div`
	margin-top: 1.5em;
	margin-bottom: 1.5em;
`;

type InputImage = {
	url: string | undefined | null;
	copyright: string | undefined | null;
	altText: string | undefined | null;
};

const prepareThumbnail = (image: InputImage) => ({
	thumbnail: {
		...image,
	},
});

const renderNotificationTextModule = (
	modules: { id: string | number; type: string }[],
	updateFunc: (id: string | number, type: string, value: any) => void
) =>
	modules
		.filter((item) => item.type && item.type === MODULE_TYPE_TEXT)
		.slice(0, 1)
		.map((item) => (
			<ArticleEditorModuleText
				isHeaderModule={true}
				onUpdate={updateFunc}
				key={item.id}
				id={item.id}
			/>
		));

export type DropIndicatorType = {
	index: number | undefined | null;
	top: boolean;
	bottom: boolean;
};

interface Props extends ReduxProps {
	user?: User;
}

type State = {
	isEditing: boolean;
	dropIndicator: DropIndicatorType;
	isThumbnailUploading: boolean;
};

const initialState = {
	isEditing: false,
	isThumbnailUploading: false,
	dropIndicator: {
		index: null,
		top: false,
		bottom: false,
	},
};

function ArticleEditorModulesWithUser(props: Props) {
	const user = useUser();
	return <ArticleEditorModules {...props} user={user} />;
}

export class ArticleEditorModules extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		const { modules, deleteModule, createModule, capability } = props;

		// when notification, delete modules that are not text and create text module if not exists
		if (
			capability === CAPABILITY_TEXT_ONLY &&
			modules.filter((item) => item.type && item.type === MODULE_TYPE_TEXT).length === 0
		) {
			modules.forEach((item) => {
				deleteModule(item.id);
			});
			createModule({ type: MODULE_TYPE_TEXT, order: 0 });
		}
	}

	state = initialState;

	handleAddViaAttachedDropzone = async (dragItem: Item | undefined) => {
		if (dragItem) {
			const dropIndicator = this.state.dropIndicator;
			const convertedItem = await convert(dragItem);

			if (convertedItem) {
				this.props.addViaAttachedDropzone(convertedItem, dropIndicator);
			}

			this.setState(initialState);
		}
	};

	handleAddViaInitialDropzone = async (id: string, type: string, dropItem: Item | undefined) => {
		// check if dropitem exists, because if not
		// a file from outside has dropped
		if (!dropItem) {
			return;
		}

		const convertedItem = await convert(dropItem);
		if (convertedItem) {
			this.props.updateModule(id, {
				type,
				meta: convertedItem.data['meta'],
				value: convertedItem.data[type],
			});
		}
	};

	handleDelete = (id: string | number) => {
		this.props.deleteModule(id);
	};

	handleDisplayAttachedDropzone = (
		hoverIndex: number | undefined | null = null,
		position: 'top' | 'bottom'
	) => {
		const { dropIndicator } = this.state;

		const nextDropIndicator = {
			index: hoverIndex,
			top: position === 'top',
			bottom: position === 'bottom',
		};

		if (dropIndicator.index !== nextDropIndicator.index) {
			this.setState({
				dropIndicator: nextDropIndicator,
			});
		}
	};

	handleModuleIsEditing = (nextIsEditing: boolean) => {
		if (this.state.isEditing !== nextIsEditing) {
			this.setState({
				isEditing: nextIsEditing,
			});
		}
	};

	handleMove = (dragItem: SimpleItem, hoverItem: SimpleItem) => {
		this.props.move(dragItem, hoverItem);
	};

	handleMoveToAssetManager = (id: string | number) => {
		this.props.deleteModule(id);
	};

	handleResetAttachedDropzone = () => {
		if (this.state.dropIndicator.index !== null) {
			this.setState(initialState);
		}
	};

	handleThumbnailUpdateOnDrop = async (id: string, type: string, dropItem: Item) => {
		if (!dropItem) {
			return;
		}

		const convertedItem = await convert(dropItem);

		if (convertedItem && convertedItem.data && convertedItem.data['image']) {
			const image = {
				title: convertedItem.data['image'].title ?? '',
				altText: convertedItem.data['image'].altText ?? '',
				copyright: convertedItem.data['image'].copyright ?? '',
				url: convertedItem.data['image'].url ?? '',
			};

			this.props.updateModule(id, {
				type,
				value:
					type === 'video' || type === 'audio' || type === 'embed'
						? prepareThumbnail(image)
						: image,
			});
		}
	};

	handleThumbnailUploadOnDrop = async (id: string, type: string, dropItem: File) => {
		this.setState({
			isThumbnailUploading: true,
		});

		const files = new FormData();

		files.append('file', dropItem, '1337.jpg');

		try {
			const response = await fetch(`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`, {
				method: 'POST',
				body: files,
			});

			const { url } = await response.json();

			const image = {
				altText: null,
				copyright: null,
				url,
			};

			this.props.updateModule(id, {
				type,
				value:
					type === 'video' || type === 'audio' || type === 'embed'
						? prepareThumbnail(image)
						: image,
			});

			this.setState({
				isThumbnailUploading: false,
			});
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);

			this.setState({
				isThumbnailUploading: false,
			});
		}
	};

	handleUpdate = (id: string | number, type: string, value: Record<string, string>) => {
		this.props.updateModule(id, {
			type,
			value,
		});
	};

	renderModules = (modules: any) => {
		const renderedModules = modules.map((item, index: number) => {
			const { id, type } = item;
			let currentModule: null | ReactElement = null;

			switch (type) {
				case MODULE_TYPE_IMAGE:
					currentModule = (
						<ArticleEditorModuleImage
							key={id}
							id={id}
							isEditing={this.state.isEditing}
							isThumbnailUploading={this.state.isThumbnailUploading}
							onDisplayAttachedDropzone={this.handleDisplayAttachedDropzone}
							onResetAttachedDropzone={this.handleResetAttachedDropzone}
							onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
							onAddViaInitialDropzone={this.handleAddViaInitialDropzone}
							onMoveToAssetManager={this.handleMoveToAssetManager}
							onModuleIsEditing={this.handleModuleIsEditing}
							onDropUpdateThumbnail={this.handleThumbnailUpdateOnDrop}
							onDropUploadThumbnail={this.handleThumbnailUploadOnDrop}
							onUpdate={this.handleUpdate}
							onDelete={this.handleDelete}
							onMove={this.handleMove}
						/>
					);

					break;

				case MODULE_TYPE_GALLERY:
					currentModule = (
						<ArticleEditorModuleGallery
							key={id}
							id={id}
							isEditing={this.state.isEditing}
							isThumbnailUploading={this.state.isThumbnailUploading}
							onDisplayAttachedDropzone={this.handleDisplayAttachedDropzone}
							onResetAttachedDropzone={this.handleResetAttachedDropzone}
							onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
							onMoveToAssetManager={this.handleMoveToAssetManager}
							onModuleIsEditing={this.handleModuleIsEditing}
							onMove={this.handleMove}
						/>
					);

					break;

				case MODULE_TYPE_EMBED:
					currentModule = (
						<ArticleEditorModuleEmbed
							key={id}
							id={id}
							isEditing={this.state.isEditing}
							isThumbnailUploading={this.state.isThumbnailUploading}
							onDisplayAttachedDropzone={this.handleDisplayAttachedDropzone}
							onResetAttachedDropzone={this.handleResetAttachedDropzone}
							onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
							onAddViaInitialDropzone={this.handleAddViaInitialDropzone}
							onMoveToAssetManager={this.handleMoveToAssetManager}
							onModuleIsEditing={this.handleModuleIsEditing}
							onDropUpdateThumbnail={this.handleThumbnailUpdateOnDrop}
							onDropUploadThumbnail={this.handleThumbnailUploadOnDrop}
							onUpdate={this.handleUpdate}
							onDelete={this.handleDelete}
							onMove={this.handleMove}
						/>
					);

					break;

				case MODULE_TYPE_TEXT:
					currentModule = (
						<ArticleEditorModuleText
							key={id}
							id={id}
							isEditing={this.state.isEditing}
							onDisplayAttachedDropzone={this.handleDisplayAttachedDropzone}
							onResetAttachedDropzone={this.handleResetAttachedDropzone}
							onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
							onMoveToAssetManager={this.handleMoveToAssetManager}
							onModuleIsEditing={this.handleModuleIsEditing}
							onUpdate={this.handleUpdate}
							onDelete={this.handleDelete}
							onMove={this.handleMove}
						/>
					);

					break;

				case MODULE_TYPE_AUDIO:
					currentModule = (
						<ArticleEditorModuleAudio
							key={id}
							id={id}
							isEditing={this.state.isEditing}
							isThumbnailUploading={this.state.isThumbnailUploading}
							onDisplayAttachedDropzone={this.handleDisplayAttachedDropzone}
							onResetAttachedDropzone={this.handleResetAttachedDropzone}
							onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
							onAddViaInitialDropzone={this.handleAddViaInitialDropzone}
							onMoveToAssetManager={this.handleMoveToAssetManager}
							onModuleIsEditing={this.handleModuleIsEditing}
							onDropUpdateThumbnail={this.handleThumbnailUpdateOnDrop}
							onDropUploadThumbnail={this.handleThumbnailUploadOnDrop}
							onUpdate={this.handleUpdate}
							onDelete={this.handleDelete}
							onMove={this.handleMove}
						/>
					);

					break;

				case MODULE_TYPE_VIDEO:
					currentModule = (
						<ArticleEditorModuleVideo
							key={id}
							id={id}
							isEditing={this.state.isEditing}
							isThumbnailUploading={this.state.isThumbnailUploading}
							onDisplayAttachedDropzone={this.handleDisplayAttachedDropzone}
							onResetAttachedDropzone={this.handleResetAttachedDropzone}
							onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
							onAddViaInitialDropzone={this.handleAddViaInitialDropzone}
							onMoveToAssetManager={this.handleMoveToAssetManager}
							onModuleIsEditing={this.handleModuleIsEditing}
							onDropUpdateThumbnail={this.handleThumbnailUpdateOnDrop}
							onDropUploadThumbnail={this.handleThumbnailUploadOnDrop}
							onUpdate={this.handleUpdate}
							onDelete={this.handleDelete}
							onMove={this.handleMove}
						/>
					);

					break;

				case MODULE_TYPE_LIVESTREAM:
					currentModule = (
						<ArticleEditorModuleLivestream
							key={id}
							id={id}
							isEditing={this.state.isEditing}
							onDisplayAttachedDropzone={this.handleDisplayAttachedDropzone}
							onResetAttachedDropzone={this.handleResetAttachedDropzone}
							onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
							onMoveToAssetManager={this.handleMoveToAssetManager}
							onModuleIsEditing={this.handleModuleIsEditing}
							onMove={this.handleMove}
							onUpdate={this.handleUpdate}
						/>
					);

					break;

				case MODULE_TYPE_VIDEO360:
					currentModule = <ArticleEditorModule360 key={id} id={id} onMove={this.handleMove} />;

					break;

				default:
					return <ArticleBrokenModule key={id} id={id} />;
			}

			// only attach a plus icon when it's not the last module in the list
			return (
				<Fragment key={id}>
					<>
						{currentModule}
						{index + 1 !== modules.length ? (
							<ModuleCreatorWrapper>
								<ArticleEditorModuleCreator hasTextarea={false} insertAt={index + 1} />
							</ModuleCreatorWrapper>
						) : null}
					</>
				</Fragment>
			);
		});

		// when the user hovers over a item we show a attached dropzone
		// in order to drop the item directly
		const { index } = this.state.dropIndicator;

		if (Number.isInteger(index)) {
			const { bottom } = this.state.dropIndicator;

			renderedModules.splice(
				bottom ? Number(index) + 1 : index,
				0,
				<ArticleEditorAttachedDropzone
					key={index}
					onResetAttachedDropzone={this.handleResetAttachedDropzone}
					onAddViaAttachedDropzone={this.handleAddViaAttachedDropzone}
				/>
			);
		}

		return renderedModules;
	};

	render() {
		const { capability, modules } = this.props;

		if (capability === CAPABILITY_TEXT_ONLY) {
			return (
				<Wrapper>
					<ArticleEditorModuleHeader />
					{renderNotificationTextModule(modules, this.handleUpdate)}
				</Wrapper>
			);
		}

		return (
			<Wrapper>
				<ArticleEditorModuleHeader />
				{Array.isArray(modules) && modules.length > 0 ? (
					this.renderModules(modules)
				) : (
					<ArticleEditorModuleStartdropzone />
				)}
				<ModuleCreatorWrapper>
					<ArticleEditorModuleCreator insertAt={modules.length} />
				</ModuleCreatorWrapper>
			</Wrapper>
		);
	}
}

const connector = connect(
	articleModulesContainerSelector,
	{
		update,
		updateModule: updateModuleAction,
		createModule: createModuleAction,
		deleteModule: deleteModuleAction,
	},
	(propsFromState, propsFromDispatch, ownProps) => ({
		...propsFromState,
		...propsFromDispatch,
		...ownProps,
		// move a item after dragging
		move: (dragItem: SimpleItem, hoverItem: SimpleItem) => {
			if (Array.isArray(propsFromState.moduleIdxs)) {
				const draggedItem = propsFromState.moduleIdxs[dragItem.order];

				const nextModuleOrder = ih(propsFromState.moduleIdxs, {
					$splice: [
						[dragItem.order, 1],
						[hoverItem.order, 0, draggedItem],
					],
				}).filter((item) => !!item);

				propsFromDispatch.update({
					modules: nextModuleOrder,
				});

				const fistChangedModule = Math.min(dragItem.order, hoverItem.order);
				const lastChangedModule = Math.max(dragItem.order, hoverItem.order);

				for (let i = fistChangedModule; i <= lastChangedModule; i += 1) {
					propsFromDispatch.updateModule(nextModuleOrder[i], {
						type: 'order',
						value: i,
					});
				}
			}
		},
		addViaAttachedDropzone: (
			convertedItem: NonNullable<Awaited<ReturnType<typeof convert>>>,
			indicator: DropIndicatorType
		) => {
			const { type, data } = convertedItem;
			const { bottom } = indicator;
			const { index } = indicator;
			const insertIndex = index || 0;

			// we need to add 1 in order to add the item at the bottom
			// and not before the element
			if (bottom) {
				propsFromDispatch.createModule({
					type,
					data,
					order: insertIndex + 1,
				});

				return;
			}

			propsFromDispatch.createModule({
				type,
				data,
				order: insertIndex,
			});
		},
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ArticleEditorModulesWithUser);
