import { faCommentAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

type Props = {
	node: {
		notes: string | null;
	};
};

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TooltipDescription = styled.p`
	margin-top: 0.25rem;
	display: block;
`;

export function ArticleNotes({ node }: Props) {
	const translate = useTranslate();

	if (!node) {
		return null;
	}

	const strippedNotesText = node.notes?.replace(/<[^>]+>/g, '');
	const title = <TooltipDescription>{translate('articles.notes')}</TooltipDescription>;

	return (
		<Wrapper>
			{strippedNotesText && (
				<Tooltip placement="bottom" title={title}>
					<FontAwesomeIcon icon={faCommentAlt} />
				</Tooltip>
			)}
		</Wrapper>
	);
}
