import { BOARD_BUFFER_SOCIAL_MEDIA_DELETE } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import { findSocialMediaAccountById } from '../util/find';

export type DeleteSocialMediaAccountAction = {
	type: typeof BOARD_BUFFER_SOCIAL_MEDIA_DELETE;
	constraint: {
		boardId: string;
		socialMediaAccountId: string;
	};
};

export default function deleteSocialMediaAccount(
	socialMediaAccountInternalId: string
): AppThunkAction<DeleteSocialMediaAccountAction> {
	return (dispatch, getState) => {
		const socialMediaAccount = findSocialMediaAccountById(socialMediaAccountInternalId, getState);

		if (!socialMediaAccount) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SOCIAL_MEDIA_DELETE,
			constraint: {
				boardId: socialMediaAccount.__meta__.boardId,
				socialMediaAccountId: socialMediaAccount.__meta__.internalId,
			},
		});
	};
}
