import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Children,
	cloneElement,
	Component,
	type JSXElementConstructor,
	type ReactElement,
	type ReactNode,
} from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	width: 100%;
	height: ${(props) => props.theme.assetManager.navigatorHeight};
	margin-bottom: 10px;
`;

const List = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
`;

type ListItemProps = {
	itemCount: number;
};

const ListItem = styled.li`
	float: left;
	width: ${(props: ListItemProps) => `calc(100% / ${props.itemCount})`};
`;

type ListItemAnchorProps = {
	children: ReactNode[];
	isActive: boolean;
};

const ListItemAnchor = styled('a')<ListItemAnchorProps>`
	padding: 0.8rem 0;
	display: inline-block;
	width: 100%;
	text-align: center;
	color: ${(props) => (props.isActive ? '#ffffff' : 'rgba(255, 255, 255, 0.6)')};
	border-bottom: 2px solid ${(props) => (props.isActive ? '#ffffff' : 'rgba(255, 255, 255, 0.4)')};
`;

const ListItemAnchorIcon = styled.span`
	position: relative;
	top: -1px;
	margin-right: 0.5rem;
`;

type AssetManagerNavigatorItemProps = {
	onClick?: (id: string) => void;
	itemCount?: number;
	id: string;
	icon?: IconProp;
	title: string;
	isActive: boolean;
};

export class AssetManagerNavigatorItem extends Component<AssetManagerNavigatorItemProps> {
	handleClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
		const { id, onClick } = this.props;

		event.preventDefault();
		if (onClick) {
			onClick(id);
		}
	};

	render() {
		const { isActive, icon, title, itemCount } = this.props;

		return (
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			<ListItem itemCount={itemCount!}>
				<ListItemAnchor isActive={isActive} onClick={this.handleClick}>
					{icon && (
						<ListItemAnchorIcon>
							<FontAwesomeIcon icon={icon} />
						</ListItemAnchorIcon>
					)}
					{title}
				</ListItemAnchor>
			</ListItem>
		);
	}
}

type AssetManagerNavigatorProps = {
	children:
		| ReactElement<AssetManagerNavigatorItemProps, string | JSXElementConstructor<any>>[]
		| ReactElement<AssetManagerNavigatorItemProps, string | JSXElementConstructor<any>>; // ChildrenArray<ComponentType<AssetManagerNavigatorItemProps>>;
	onSelect: (id: string) => void;
};

const enhanceChildren = (
	children: ReactElement<AssetManagerNavigatorItemProps, string | JSXElementConstructor<any>>[], // ChildrenArray<ComponentType<AssetManagerNavigatorItemProps>>,
	onSelect: (id: string) => void
) =>
	Children.map(children, (child) =>
		cloneElement(child, {
			itemCount: Children.count(children),
			onClick: onSelect,
		})
	);

function AssetManagerNavigator({ children, onSelect }: AssetManagerNavigatorProps) {
	return (
		<Wrapper>
			<List>
				{Children.count(children) > 0
					? enhanceChildren(Array.isArray(children) ? children : [children], onSelect)
					: null}
			</List>
		</Wrapper>
	);
}

export default AssetManagerNavigator;
