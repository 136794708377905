/**
 * @generated SignedSource<<d61cb838a7c96a26ae87a9cd920805cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ArticleItemQuery$variables = {
  articleId: string;
};
export type ArticleItemQuery$data = {
  readonly article: {
    readonly rowId: string;
    readonly teaserImage: string | null;
    readonly teaserText: string | null;
    readonly title: string;
  } | null;
};
export type ArticleItemQuery = {
  response: ArticleItemQuery$data;
  variables: ArticleItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "articleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teaserImage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teaserText",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticleItemQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticleItemQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35370b93291682adc7eeb4e4c492b742",
    "id": null,
    "metadata": {},
    "name": "ArticleItemQuery",
    "operationKind": "query",
    "text": "query ArticleItemQuery(\n  $articleId: String!\n) {\n  article: articleByRowId(rowId: $articleId) {\n    rowId\n    title\n    teaserImage\n    teaserText\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c0fea4093385a39b328258163c712e84";

export default node;
