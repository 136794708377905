import moment from 'moment';
import { createFragmentContainer, graphql } from 'react-relay';

import { useTranslate } from '@/client/translation/useTranslate';

import placeholder from '../../../../images/placeholder.png';
import ClickHandler from '../ClickHandler';
import { DraggableItem } from '../DraggableItem';

import styles from './BoardSearchItem.module.scss';
import type { BoardSearchItem_board$data } from './__generated__/BoardSearchItem_board.graphql';

type Props = {
	board: BoardSearchItem_board$data;
	onRequestOpen: (rowId: string) => void;
};

function BoardSearchItem({
	board: { rowId, title, image, category, updatedAt, createdAt, status },
	onRequestOpen,
}: Props) {
	const translate = useTranslate();

	return (
		<DraggableItem
			type="board"
			id={rowId}
			className={styles.board}
			status={status as string}
			internalId=""
			sectionId=""
		>
			<div
				className={styles.boardTeaserImage}
				style={{ backgroundImage: `url(${image ? image.url || placeholder : placeholder})` }}
			/>
			<div className={styles.boardInfo}>
				{category ? (
					<em style={{ color: category.color || '#444444' }}>{category && category.title}</em>
				) : (
					false
				)}
				<h3>{title}</h3>
				<p>
					{translate('board.sectionItem.board.updated')}
					{moment
						.utc(updatedAt || createdAt)
						.local()
						.fromNow()}
					.
				</p>
			</div>
			<div>
				<ClickHandler rowId={rowId} handleClick={onRequestOpen} />
			</div>
		</DraggableItem>
	);
}

export default createFragmentContainer(BoardSearchItem, {
	board: graphql`
		fragment BoardSearchItem_board on Board {
			rowId
			title
			slug
			status
			image {
				url
			}
			category: categoryByCategoryId {
				title
				color
			}
			updatedBy
			updatedAt
			createdAt
		}
	`,
});
