import { AutoComplete, DatePicker, TimePicker, Button } from 'antd';
import type { Moment } from 'moment';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';

const FullWidthInput = styled(AutoComplete)`
	width: 100%;
`;

const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	margin-top: 0.2rem;
	justify-content: space-between;
`;

const MarginLeft = styled.div`
	margin-left: 1.6rem;
`;

const ButtonWrapper = styled.div`
	align-self: flex-end;
	margin-left: 1.6rem;
`;

type SuggestionItem = {
	id: string;
	title: string;
};

type Props = WithTranslationProps & {
	onSearch?: (value: string) => void;
	onSubmit: (id?: string | null, date?: string | null) => void;
	suggestions: Array<SuggestionItem>;
};

type State = {
	date: Moment | undefined | null;
	searchText: string | undefined | null;
	selectedId: string | undefined | null;
	selectedText: string | undefined | null;
};

class ArticleMetaSourceOriginInput extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			date: null,
			searchText: null,
			selectedId: null,
			selectedText: null,
		};
	}

	handleChangeDate = (date: Moment | null) => {
		this.setState({ date });
	};

	handleChangeTime = (time: Moment | null) => {
		this.setState({ date: time });
	};

	handleClick = () => {
		this.props.onSubmit(
			this.state.selectedId,
			this.state.date ? this.state.date.toISOString() : null
		);
		this.setState({
			date: null,
			searchText: null,
			selectedId: null,
			selectedText: null,
		});
	};

	handleSearch = (value: string) => {
		this.setState({ searchText: value, selectedId: null, selectedText: null });
		if (this.props.onSearch) {
			this.props.onSearch(value);
		}
	};

	handleSelect = (id: any) => {
		const selectedItem = this.props.suggestions.filter((item) => item.id === id).shift();
		if (selectedItem) {
			this.setState({ searchText: null, selectedId: id, selectedText: selectedItem.title });
		}
	};

	renderOption = (item: SuggestionItem) => (
		<AutoComplete.Option key={item.id}>{item.title}</AutoComplete.Option>
	);

	render() {
		const { selectedId, selectedText, searchText, date } = this.state;
		const {
			suggestions,
			translation: { translate },
		} = this.props;

		const options =
			Array.isArray(suggestions) && suggestions.length ? suggestions.map(this.renderOption) : [];

		return (
			<Column>
				<FullWidthInput
					filterOption={false}
					onSearch={this.handleSearch}
					onSelect={this.handleSelect}
					placeholder={translate('article.meta.sourceOrigin.inputPlaceholder')}
					value={selectedText || searchText}
				>
					{options}
				</FullWidthInput>
				<Row>
					<DatePicker
						format="DD.MM.YYYY"
						onChange={this.handleChangeDate}
						placeholder={translate('article.meta.sourceOrigin.datePlaceholder')}
						value={date}
					/>
					<MarginLeft>
						<TimePicker
							format="HH:mm"
							onChange={this.handleChangeTime}
							placeholder={translate('article.meta.sourceOrigin.timePlaceholder')}
							value={date}
						/>
					</MarginLeft>
					<ButtonWrapper>
						<Button disabled={!selectedId} onClick={this.handleClick}>
							{translate('article.meta.sourceOrigin.inputButton')}
						</Button>
					</ButtonWrapper>
				</Row>
			</Column>
		);
	}
}

export default withTranslation(ArticleMetaSourceOriginInput);
