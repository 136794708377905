import type { User } from '@sep/cms-auth-client';
import { useEffect, useState } from 'react';

import environment from '../environment';

import InsertNewAuthorMutation from './mutations/InsertNewAuthor';

export function useRegisterUser(user: User) {
	const [isUserRegistered, setIsUserRegistered] = useState(false);

	useEffect(() => {
		if (user) {
			InsertNewAuthorMutation(
				{
					firstname: user.firstName,
					lastname: user.lastName,
					email: user.email,
					guid: user.guid,
				},
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				environment
			)
				.then((wasInserted) => {
					setIsUserRegistered(true);
					if (wasInserted) {
						window.location.reload();
					}
				})
				.catch((reason) => {
					console.error('could not register user', reason);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.guid]);

	return isUserRegistered;
}
