import { Alert } from 'antd';
import { debounce } from 'lodash-es';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { fetchQuery, graphql } from 'react-relay';

import {
	type WithRelayEnvironment,
	withRelayEnvironment,
} from '@/client/relay/withRelayEnvironment';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../../translation';
import { ArticleMetaTags } from '../../../../ui/ArticleMeta';
import type { OptionItem } from '../../../../ui/ArticleMeta/ArticleMetaTags';
import addTagAction from '../../actions/addTag';
import removeTagAction from '../../actions/removeTag';
import { articleTagsSelector } from '../../selectors';

import TagsRenderer from './TagsRenderer';
import type { TagsContainerTagByTextQuery } from './__generated__/TagsContainerTagByTextQuery.graphql';

const SEARCH_DELAY_MS = 500;

interface Props extends WithTranslationProps, ReduxProps, WithRelayEnvironment {
	key: string | number;
}

type State = {
	searchTerm: string;
};

class TagsContainer extends Component<Props, State> {
	state = {
		searchTerm: '',
	};

	handleSearch = debounce((searchTerm) => {
		this.setState({ searchTerm });
	}, SEARCH_DELAY_MS);

	handleAdd = async (value: string) => {
		const tagsCheckQuery = graphql`
			query TagsContainerTagByTextQuery($tagText: String!) {
				tag: tagByText(text: $tagText) {
					id
					rowId
					text
					count
				}
			}
		`;

		const response = await fetchQuery<TagsContainerTagByTextQuery>(
			this.props.environment,
			tagsCheckQuery,
			{
				tagText: value,
			}
		).toPromise();

		if (response?.tag) {
			const importTag: OptionItem = {
				id: response.tag.rowId,
				count: response.tag.count,
				text: response.tag.text,
			};

			this.props.addTag(importTag);
		} else {
			this.props.addTag({ text: value });
		}
	};

	handleRemove = (id: number | string) => {
		this.props.removeTag(id);
	};

	handleSelect = (item: OptionItem) => {
		this.props.addTag(item);
	};

	componentWillUnmount() {
		this.handleSearch.cancel();
	}

	render() {
		const { searchTerm } = this.state;
		const {
			tags,
			translation: { translate },
			addTag,
			removeTag,
			environment,
			...rest
		} = this.props;

		// sort all tags ASC because they are coming unsorted from the db
		const sortedTags = tags.sort((tagA, tagB) => {
			if (tagA.text > tagB.text) {
				return 1;
			}
			if (tagA.text < tagB.text) {
				return -1;
			}

			return 0;
		});

		return (
			<TagsRenderer searchTerm={searchTerm}>
				{({ error, props }) => {
					const hasError = !!error;

					if (hasError) {
						return <Alert message={translate('error')} description={error.message} type="error" />;
					}

					const edges = props?.tags?.edges || [];

					let suggestions: OptionItem[] = [];
					if (Array.isArray(edges) && edges.length) {
						suggestions = edges
							.filter((item) => item.node && item.node.text)
							.map((item) => ({
								id: item.node.rowId,
								text: item.node.text,
								count: item.node.count,
								status: item.node.status,
							}));
					}

					return (
						<ArticleMetaTags
							suggestions={suggestions}
							tags={sortedTags}
							onSearch={this.handleSearch}
							onSelect={this.handleSelect}
							onAdd={this.handleAdd}
							onRemove={this.handleRemove}
							{...rest}
						/>
					);
				}}
			</TagsRenderer>
		);
	}
}

const connector = connect(articleTagsSelector, {
	addTag: addTagAction,
	removeTag: removeTagAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(withRelayEnvironment(TagsContainer)));
