import { faExchangeAlt as ExchangeLightIcon } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faHandPaper as HandIcon } from '@fortawesome/free-solid-svg-icons/faHandPaper';
import { faBars as BarsIcon } from '@fortawesome/pro-light-svg-icons/faBars';
import { faComments as CommentIcon } from '@fortawesome/pro-light-svg-icons/faComments';
import { faExternalLink as ExternalLinkIcon } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faFileAlt as FileIcon } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faGlobe as GlobeIcon } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faNewspaper as NewspaperIcon } from '@fortawesome/pro-light-svg-icons/faNewspaper';
import { faQuestion as QuestionIcon } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faTachometer as TachometerIcon } from '@fortawesome/pro-light-svg-icons/faTachometer';
import { faUsers as UsersIcon } from '@fortawesome/pro-light-svg-icons/faUsers';
import type { User } from '@sep/cms-auth-client';
import { Component } from 'react';
import type { SyntheticEvent } from 'react';
import type { ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation, type WithTranslationProps } from '@/client/translation/withTranslation';
import type { IndicatorType } from '@/client/ui/Header/HeaderContainer';

import { useLogout, useUser } from '../../auth/AuthContext';
import config from '../../config';
import routes from '../../config/config.routes';
import { AM_MODE_IMPORT, AM_MODE_HARVEST } from '../../constants';
import changeMode from '../../containers/AssetManager/actions/changeMode';
import {
	toggleVisibility as toggleAssetManagerVisibility,
	hide as hideAssetManagerVisibility,
} from '../../containers/AssetManager/actions/toggleVisibility';
import FallbackProfilePicture from '../../images/profileImage11.jpg';
import { show } from '../../store/reducers/creator';
import Header, {
	HeaderPrimaryNavigationListItem,
	HeaderSecondaryNavigationListItem,
	HeaderSecondaryNavigationSubListItem,
} from '../../ui/Header';
import type { CreatorType } from '../../ui/Header';

import HeaderUserQueryContainer from './HeaderUserQueryContainer';

const ACTION_TOGGLE_ASSET_MANAGER = 'ACTION_TOGGLE_ASSET_MANAGER';
const ACTION_TOGGLE_IMPORTER = 'ACTION_TOGGLE_IMPORTER';
const ACTION_LOGOUT = 'ACTION_LOGOUT';
const ACTION_CHANGE_LANGUAGE_GERMAN = 'ACTION_CHANGE_LANGUAGE_GERMAN';
const ACTION_CHANGE_LANGUAGE_ENGLISH = 'ACTION_CHANGE_LANGUAGE_ENGLISH';
const ACTION_CHANGE_LANGUAGE_BAVARIAN = 'ACTION_CHANGE_LANGUAGE_BAVARIAN';

interface Props extends WithTranslationProps, RouteComponentProps, ReduxProps {
	user?: User;
	logout?: ReturnType<typeof useLogout>;
}

const ExtendedHandSubListItem = styled(HeaderSecondaryNavigationListItem)`
	${({ isActive }) =>
		isActive
			? `
				width: 400px;
				a {
					width: 400px;
					max-width: 400px;
				}`
			: ''};
`;

function MainHeaderWithUser(props: Props) {
	const user = useUser();
	const logout = useLogout();
	const history = useHistory();

	return <MainHeader {...props} user={user} logout={logout} history={history} />;
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

class MainHeader extends Component<Props> {
	unlistenHistory: (() => void) | null = null;

	handleAction = async (actionType: string) => {
		switch (actionType) {
			case ACTION_TOGGLE_ASSET_MANAGER:
				this.props.toggleAssetManagerVisibility();
				break;
			case ACTION_TOGGLE_IMPORTER:
				this.props.changeMode(
					this.props.mode === AM_MODE_IMPORT ? AM_MODE_HARVEST : AM_MODE_IMPORT
				);
				break;
			case ACTION_LOGOUT:
				this.props.hideAssetManagerVisibility();

				// HINT: let's give it 1 second to finish routing back to `/` and potentially running cleanup calls
				// e.g. for the article unlock

				await wait(1000);

				break;

			case ACTION_CHANGE_LANGUAGE_GERMAN:
				{
					const {
						translation: { changeLanguage },
					} = this.props;
					changeLanguage('de_DE');
				}
				break;

			case ACTION_CHANGE_LANGUAGE_ENGLISH:
				{
					const {
						translation: { changeLanguage },
					} = this.props;
					changeLanguage('en_EN');
				}
				break;

			case ACTION_CHANGE_LANGUAGE_BAVARIAN:
				{
					const {
						translation: { changeLanguage },
					} = this.props;
					changeLanguage('de_BY');
				}
				break;

			default:
				break;
		}
	};

	handleClickCreator = (_, type: CreatorType) => {
		const { showCreator } = this.props;
		showCreator({ type });
	};

	handleClickItem = (
		event: SyntheticEvent<HTMLElement>,
		action?: string | null,
		route?: string | null
	) => {
		if (route) {
			event.preventDefault();
			this.props.history.push(route);
		} else if (action) {
			event.preventDefault();
			this.handleAction(action);
		}
	};

	handleClickLogo = () => {
		const { history } = this.props;
		history.push(routes.dashboard);
	};

	render() {
		const {
			isAssetManagerVisible,
			mode,
			isWorking,
			location,
			translation: { translate, language },
			user,
			logout,
			history,
		} = this.props;
		const myProfileRoute = `${routes.authors}/${user?.guid ?? ''}`;

		return (
			<Header
				hideHeaderOnScroll={!isAssetManagerVisible}
				indicator={config.VITE_BRANCH as IndicatorType}
				isWorking={isWorking}
				onClickCreator={this.handleClickCreator}
				onClickItem={this.handleClickItem}
				onClickLogo={this.handleClickLogo}
				primaryItems={[
					<HeaderPrimaryNavigationListItem
						icon={TachometerIcon}
						isActive={location.pathname === routes.dashboard}
						key="route.dashboard"
						label={translate('navigation.dashboard')}
						route={routes.dashboard}
					/>,
					<HeaderPrimaryNavigationListItem
						icon={NewspaperIcon}
						isActive={location.pathname === routes.articles}
						key="route.articles"
						label={translate('navigation.articles')}
						route={routes.articles}
					/>,
					<HeaderPrimaryNavigationListItem
						icon={FileIcon}
						isActive={location.pathname === routes.boards}
						key="route.boards"
						label={translate('navigation.boards')}
						route={routes.boards}
					/>,
					<HeaderPrimaryNavigationListItem
						icon={ExternalLinkIcon}
						isActive={location.pathname === routes.boardsTeasers}
						key="route.boardsTeasers"
						label={translate('navigation.boardsTeasers')}
						route={routes.boardsTeasers}
					/>,
					<HeaderPrimaryNavigationListItem
						icon={UsersIcon}
						isActive={location.pathname === routes.authors}
						key="route.authors"
						label={translate('navigation.authors')}
						route={routes.authors}
					/>,
					<HeaderPrimaryNavigationListItem
						icon={BarsIcon}
						isActive={location.pathname === routes.navigations}
						key="route.navigationManager"
						label={translate('navigation.navigation')}
						route={routes.navigations}
					/>,
				]}
				secondaryItems={[
					<HeaderSecondaryNavigationListItem
						icon={GlobeIcon}
						key="language"
						subItems={[
							<HeaderSecondaryNavigationSubListItem
								action={ACTION_CHANGE_LANGUAGE_GERMAN}
								isActive={language === 'de_DE'}
								key={`action.${ACTION_CHANGE_LANGUAGE_GERMAN}`}
								label={translate('german')}
							/>,
							<HeaderSecondaryNavigationSubListItem
								action={ACTION_CHANGE_LANGUAGE_ENGLISH}
								isActive={language === 'en_EN'}
								key={`action.${ACTION_CHANGE_LANGUAGE_ENGLISH}`}
								label={translate('english')}
							/>,
							<HeaderSecondaryNavigationSubListItem
								action={ACTION_CHANGE_LANGUAGE_BAVARIAN}
								isActive={language === 'de_BY'}
								key={`action.${ACTION_CHANGE_LANGUAGE_BAVARIAN}`}
								label={translate('bavarian')}
							/>,
						]}
					/>,
					<HeaderSecondaryNavigationListItem
						icon={QuestionIcon}
						isActive={location.pathname === routes.faq}
						key="route.faq"
						route={routes.faq}
					/>,
					<HeaderSecondaryNavigationListItem
						icon={CommentIcon}
						isActive={location.pathname === routes.comments}
						key="route.comments"
						route={routes.comments}
					/>,
					<HeaderUserQueryContainer
						guid={user?.guid ?? ''}
						key="user"
						render={(internalUser) => (
							<HeaderSecondaryNavigationListItem
								image={
									internalUser && internalUser.profilePicture
										? internalUser.profilePicture
										: FallbackProfilePicture
								}
								subItems={[
									<HeaderSecondaryNavigationSubListItem
										isActive={location.pathname === myProfileRoute}
										key="route.myProfileRoute"
										label={translate('navigation.profile')}
										onClick={this.handleClickItem}
										route={myProfileRoute}
									/>,
									<HeaderSecondaryNavigationSubListItem
										isActive={location.pathname === routes.profile}
										key="route.userSettings"
										label={translate('navigation.settings')}
										onClick={this.handleClickItem}
										route={routes.profile}
									/>,
									<HeaderSecondaryNavigationSubListItem
										action={ACTION_LOGOUT}
										key={`action.${ACTION_LOGOUT}`}
										label={translate('navigation.signOut')}
										onClick={(e) => {
											if (window.location.pathname !== routes.dashboard) {
												this.unlistenHistory?.();

												const unlisten = history.listen(() => {
													logout?.({
														onCancel: () => {
															history.goBack();
															unlisten();
														},
														onConfirm: () => {
															unlisten();
														},
													});
												});

												this.unlistenHistory = unlisten;
											} else {
												logout?.();
											}

											this.handleClickItem(e, null, routes.dashboard);
										}}
									/>,
								]}
							/>
						)}
					/>,

					...(isAssetManagerVisible
						? [
								<HeaderSecondaryNavigationListItem
									action={ACTION_TOGGLE_IMPORTER}
									icon={ExchangeLightIcon}
									isActive={mode === AM_MODE_IMPORT}
									label={translate(`assetManager.ImporterButton.toolTip`)}
									key={`action.${ACTION_TOGGLE_IMPORTER}`}
								/>,
						  ]
						: []),
					<ExtendedHandSubListItem
						action={ACTION_TOGGLE_ASSET_MANAGER}
						icon={HandIcon}
						isActive={isAssetManagerVisible}
						key={`action.${ACTION_TOGGLE_ASSET_MANAGER}`}
						label={translate(`assetManager.AssetManagerButton.toolTip`)}
					/>,
				]}
			/>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		isWorking: state.app.isWorking,
		featureFlags: state.user.flags,
		isAssetManagerVisible: state.assetManager.isVisible,
		mode: state.assetManager.mode,
	}),
	{
		showCreator: show,
		toggleAssetManagerVisibility,
		hideAssetManagerVisibility,
		changeMode,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default withTranslation(withRouter(connector(MainHeaderWithUser)));
