import { Component, type ComponentType } from 'react';
import { useDrag } from 'react-dnd';
import type { DragSourceMonitor } from 'react-dnd';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { getDisplayName } from '@/client/util/react';

import type { ImportedItem, Item, Space } from '..';
import { AM_MODE_EDIT } from '../../../constants';
import { AssetManagerItemControl } from '../../../ui/AssetManager';
import type { DropResult } from '../AssetManagerSpace/performDrop';
import * as actions from '../actions';
import { baseInformationSelector, assetManagerStatusSelector } from '../selectors';

import type { AssetManagerStatus } from '.';

export interface WrappedComponentProps extends ReduxProps {
	removeAfterDrop: boolean;
	isDraggable: boolean;
	isSelected?: boolean;
	onSelect?: (id: string) => void;
	toggleDragIsCopy: (isCopy: boolean) => void;
	item: Item;
}

const endDrag = (
	item: DragItem,
	monitor: DragSourceMonitor<DragItem, DropResult>,
	removeAfterDrop: boolean,
	removeFromSelectedSpace: WrappedComponentProps['removeFromSelectedSpace']
): void => {
	if (!monitor.didDrop()) {
		return;
	}

	const { origin, item: module } = item;

	const dropResult = monitor.getDropResult();

	// Can be different if they use the move item overlay but change their mind and use the alt shortcut key
	if (removeAfterDrop && dropResult?.dropEffect !== 'copy') {
		const spaceToRemoveFrom = origin.space;
		const groupToRemoveFrom = origin.globalGroup;

		removeFromSelectedSpace(module.id, spaceToRemoveFrom, groupToRemoveFrom, {
			persist: true,
		});
	}
};

const handleClickSelect = (onSelect, itemId) => {
	if (onSelect) {
		onSelect(itemId);
	}
};

const connector = connect(
	(state: ReduxState) => {
		const { mode } = baseInformationSelector(state);
		const { activeGlobalGroup, activeSpace, isInGlobalGroup } = assetManagerStatusSelector(state);

		return {
			mode,
			activeSpace,
			activeGlobalGroup: activeGlobalGroup?.id,
			isInGlobalGroup,
			inLockedGlobalGroup: isInGlobalGroup && activeGlobalGroup && activeGlobalGroup.isLocked,
		};
	},
	{
		removeFromSelectedSpace: actions.removeItemFromSelectedSpace,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

interface DragItem {
	item: Item;
	origin: {
		globalGroup: string | null;
		space: Space;
	};
}

export default function withDragAndDrop<I extends Item | ImportedItem>(
	itemType: string,
	InnerComponent: ComponentType<{ item: I }>
) {
	function WrappedComponent(props: WrappedComponentProps) {
		const {
			isSelected = false,
			isDraggable = true,
			onSelect,
			item,
			mode,
			inLockedGlobalGroup,
			toggleDragIsCopy,
			...rest
		} = props;

		const {
			isInGlobalGroup,
			activeGlobalGroup,
			activeSpace,
			removeAfterDrop,
			removeFromSelectedSpace,
		} = props;

		const [{ isDragging }, dragSource] = useDrag(
			() => ({
				type: itemType,
				item: {
					item: item,
					origin: {
						globalGroup: isInGlobalGroup ? activeGlobalGroup ?? null : null,
						space: activeSpace,
					},
				},
				canDrag: isDraggable,
				collect: (monitor) => ({
					isDragging: !!monitor.isDragging(),
				}),
				end: (item, monitor) => endDrag(item, monitor, removeAfterDrop, removeFromSelectedSpace),
			}),
			[
				item,
				isInGlobalGroup,
				activeGlobalGroup,
				activeSpace,
				isDraggable,
				removeAfterDrop,
				removeFromSelectedSpace,
			]
		);

		const currentStatus: AssetManagerStatus = {
			isInImporter: false,
			isEditable: false,
			inDeletionMode: mode === AM_MODE_EDIT,
			isMoveable: !inLockedGlobalGroup,
			isDeletable: !item.isLockedForDeletion,
			isDraggable,
			isDragging,
			isSelected,
		};

		return (
			<div ref={dragSource}>
				<AssetManagerItemControl
					currentStatus={currentStatus}
					mode={mode}
					item={item}
					onClickSelectionMark={() => handleClickSelect(onSelect, item.id)}
					toggleDragIsCopy={toggleDragIsCopy}
					sendToAssetManager={() => {
						// noop
					}}
				>
					<InnerComponent item={item as I} {...rest} />
				</AssetManagerItemControl>
			</div>
		);
	}

	WrappedComponent.displayName = `withItemDND(${getDisplayName(Component)})`;

	return connector(WrappedComponent);
}
