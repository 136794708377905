import type { ItemImage, ImportedItemImage } from '..';
import { AssetManagerItemImage as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemImage | ImportedItemImage;
};

function AssetManagerItemImage({ item }: Props) {
	return <Wrapper image={item.url} title={item.title} copyright={item.copyright} />;
}

export default AssetManagerItemImage;
