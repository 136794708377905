import { Modal, Button, Tooltip } from 'antd';
import { Component } from 'react';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../translation';

type Props = WithTranslationProps & {
	isVisible: boolean;
	onBackToOverview: () => void;
	onSetLock: () => void;
};

type State = {
	refetchButtonDisabled: boolean;
};

class BrokenLockDialog extends Component<Props, State> {
	state = {
		refetchButtonDisabled: false,
	};

	handleBackToOverView = () => {
		this.props.onBackToOverview();
	};

	handleSetLock = () => {
		this.setState({
			refetchButtonDisabled: true,
		});

		this.props.onSetLock();
	};

	render() {
		const {
			isVisible,
			translation: { translate },
		} = this.props;

		return (
			<Modal
				width={800}
				open={isVisible}
				title={translate('lockWarnings.updatedWhileLockDialog.title')}
				onCancel={this.handleBackToOverView}
				footer={[
					<Tooltip
						key="backToOverview"
						title={translate('lockWarnings.backToOverviewButton.tooltip')}
					>
						<Button type="primary" onClick={this.handleBackToOverView}>
							{translate('lockWarnings.backToOverviewButton.title')}
						</Button>
					</Tooltip>,
					<Tooltip key="readOnly" title={translate('lockWarnings.setLockButton.tooltip')}>
						<Button
							type="primary"
							onClick={this.handleSetLock}
							disabled={this.state.refetchButtonDisabled}
						>
							{translate('lockWarnings.setLockButton.title')}
						</Button>
					</Tooltip>,
				]}
			>
				{translate('lockWarnings.updatedWhileLockDialog.text')}
			</Modal>
		);
	}
}
export default withTranslation(BrokenLockDialog);
