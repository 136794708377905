import joi from 'joi-browser';

import createJoiError from '../../../util/createJoiError';
import { processValidation } from '../../../util/processValidation';

const schema = joi.object().keys({
	slug: joi
		.string()
		.regex(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/)
		.min(3)
		.max(64)
		.error(
			createJoiError(
				'Das URL-Kürzel darf ausschließlich Buchstaben, Zahlen und / am Anfang als auch zwischen den Buchstaben und Zahlen enthalten. Des Weiteren muss er zwischen drei und 64 enthalten.'
			)
		)
		.required(),
	title: joi
		.string()
		.min(3)
		.max(64)
		.error(
			createJoiError(
				'Der Titel muss aus min. drei Zeichen besteht. Des Weiteren darf er nicht länger als 64 Zeichen sein.'
			)
		)
		.required(),
});

export default function validator(updated: any, original: any, previous: any) {
	const patch = {
		...original,
		...previous,
		...updated,
	};
	const { error: originalError, value } = joi.validate(patch, schema, {
		abortEarly: false,
		allowUnknown: true,
	});
	const res = processValidation({ error: originalError });

	if (patch.slug === original.slug) {
		res.isValid = false;
		res.error.slug = res.error.slug || [];
		res.error.slug.push(
			'Bitte verwende ein anderes URL-Kürzel um eine eindeutige Unterscheidung der Artikel zu generieren.'
		);
	}

	if (value && value.rowId) {
		delete value.rowId;
	}

	return { value, ...res };
}
