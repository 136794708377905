import { STATUS_DRAFT } from '@sep/br24-constants';
import { type Environment, commitMutation, graphql } from 'react-relay';

import type { CreateBoardMutation, BoardInput } from './__generated__/CreateBoardMutation.graphql';

const mutation = graphql`
	mutation CreateBoardMutation($input: CreateBoardInput!) {
		createBoard(input: $input) {
			board {
				rowId
			}
		}
	}
`;

export function createBoardMutation(
	{
		title,
		slug,
		description,
		parentId,
	}: Pick<BoardInput, 'title' | 'slug' | 'description' | 'parentId'>,
	environment: Environment
): Promise<CreateBoardMutation['response']> {
	return new Promise((resolve, reject) => {
		commitMutation<CreateBoardMutation>(environment, {
			mutation,
			variables: {
				input: {
					board: {
						title,
						slug,
						description,
						parentId,
						status: STATUS_DRAFT,
					},
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
}
