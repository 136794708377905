import type { ItemVideo, ImportedItemVideo } from '..';
import PlaceholderImage from '../../../images/placeholder.png';
import { AssetManagerItemVideo as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemVideo | ImportedItemVideo;
};

function AssetManagerItemVideo({ item }: Props) {
	return (
		<Wrapper
			image={item.thumbnail?.url ?? PlaceholderImage}
			title={item.title ?? ''}
			copyright={item.copyright}
			duration={item.duration}
		/>
	);
}

export default AssetManagerItemVideo;
