import type { AppThunkAction } from '@/client/store/redux';

import type { CreateModuleInput, CreateModuleAction } from '..';
import { MODULE_CREATE } from '../actionTypes';

import { validateAll } from './validate';

function createModule(
	input: CreateModuleInput | CreateModuleInput[]
): AppThunkAction<CreateModuleAction> {
	return (dispatch) => {
		let modules: CreateModuleInput[];
		if (!Array.isArray(input)) {
			modules = [input];
		} else {
			modules = input;
		}

		dispatch({
			type: MODULE_CREATE,
			payload: modules,
		});

		dispatch(validateAll());
	};
}

export default createModule;
