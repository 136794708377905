import styled from 'styled-components';

import { ErrorSolid as ErrorIcon, Trash2 as TrashIcon } from '../../Icon';

import { Wrapper, Footer, FooterButton } from '.';

type Props = {
	onRemove: () => void;
	error: string;
};

const Error = styled.p`
	font-weight: bold;
	color: ${(props) => props.theme.colors.stateRed};
`;

function ArticleMetaTeasersError(props: Props) {
	return (
		<Wrapper>
			<Error>
				<ErrorIcon />
				{props.error}
			</Error>
			<Footer>
				<FooterButton
					type="link"
					className="flex-end"
					onClick={props.onRemove}
					aria-label="Entfernen"
					data-testid="removebutton"
				>
					<TrashIcon />
				</FooterButton>
			</Footer>
		</Wrapper>
	);
}

export default ArticleMetaTeasersError;
