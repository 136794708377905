import UUID from 'uuid-js';

import type {
	AddItemsToImporterAction,
	AddItemGroupToImporterAction,
	SetItemGroupPaginationAction,
	ImporterSearchParameters,
	RawItem,
	Importer,
	ImportedItem,
	AdditionalData,
	RawItemSophoraArticle,
} from '..';
import {
	ADD_ITEMS_TO_IMPORTER,
	ADD_ITEM_GROUP_TO_IMPORTER,
	SET_ITEM_GROUP_PAGINATION,
} from '../actionTypes';

export const addItemsToImporter = (
	importer: Importer,
	items: Array<RawItem | RawItemSophoraArticle>,
	itemGroupTitle: string,
	additionalData?: AdditionalData
): AddItemsToImporterAction => ({
	type: ADD_ITEMS_TO_IMPORTER,

	payload: {
		importer,
		itemGroupTitle,

		items: items.map(
			(item) =>
				({
					id: UUID.create().toString(),
					...item,
				} as ImportedItem)
		),
		additionalData,
	},
});

// Added to match the design of filters staying in Importer as a history
export const addItemGroupToImporter = (
	importer: Importer,
	itemGroupTitle: string,
	searchParameters?: ImporterSearchParameters
): AddItemGroupToImporterAction => ({
	type: ADD_ITEM_GROUP_TO_IMPORTER,

	payload: {
		importer,
		itemGroupTitle,
		searchParameters,
	},
});

export const setItemGroupPagination = (
	importer: Importer,
	itemGroupTitle: string,
	hasNextPage: boolean,
	lastCursor: string
): SetItemGroupPaginationAction => ({
	type: SET_ITEM_GROUP_PAGINATION,

	payload: {
		importer,
		itemGroupTitle,
		hasNextPage,
		lastCursor,
	},
});
