import {
	BOARD_BUFFER_SECTION_DELETE,
	BOARD_BUFFER_SECTION_REBUILD_ORDER,
} from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import { findSectionById } from '../util/find';

export type DeleteSectionAction = {
	type: typeof BOARD_BUFFER_SECTION_DELETE;
	constraint: {
		boardId: string;
		sectionId: string;
		sectionItemIds: Array<string>;
	};
};

export type RebuildSectionOrderAction = {
	type: typeof BOARD_BUFFER_SECTION_REBUILD_ORDER;
	constraint: {
		boardId: string;
	};
};

/**
 * Deletes an existing section by `internalSectionId`.
 *
 * @param  {string} sectionInternalId
 * @return {Function}
 */
export default function deleteSection(
	sectionInternalId: string
): AppThunkAction<DeleteSectionAction | RebuildSectionOrderAction> {
	return (dispatch, getState) => {
		const section = findSectionById(sectionInternalId, getState);

		if (!section) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SECTION_DELETE,
			constraint: {
				sectionId: section.__meta__.internalId,
				boardId: section.__meta__.boardId,
				sectionItemIds: section.items.reduce(
					(acc, item) => (item ? [...acc, item.__internalId__] : acc),
					[] as string[]
				),
			},
		});

		dispatch({
			type: BOARD_BUFFER_SECTION_REBUILD_ORDER,
			constraint: {
				boardId: section.__meta__.boardId,
			},
		});
	};
}
