import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { Component } from 'react';
import styled from 'styled-components';

import { IMPORTER_DATE_FORMAT } from '../../../constants';

const { RangePicker } = DatePicker;

const StyleWrapper = styled.div`
	.ant-input {
		background-color: ${(props) => props.theme.assetManager.importer.backgroundColor};
		height: 38px;
		vertical-align: baseline;
		border-radius: 3px;
		color: ${(props) => props.theme.colors.white};
	}

	.ant-calendar-picker-icon,
	.ant-calendar-range-picker-separator {
		color: ${(props) => props.theme.colors.white};
	}
`;

// Disable selection from dates in the past
const disabledDate = (current: any) => current && current < moment().startOf('day');

interface Props {
	allowPastDates?: boolean;
	onHandleChangeDates: (
		value: [start: moment.Moment | null, end: moment.Moment | null] | null
	) => void;
	value?: [moment.Moment | null, moment.Moment | null];
	disabled?: boolean;
}

class AssetManagerImporterDatepicker extends Component<Props> {
	getCalendarContainer = (trigger: any) => trigger.parentNode;

	handleChangeDate: DatePickerProps['onChange'] = (date) => {
		if (date) {
			this.props.onHandleChangeDates([date, date]);
		}
	};

	render() {
		const { value, allowPastDates, ...rest } = this.props;

		const disabledDateProps = allowPastDates ? {} : { disabledDate };

		return (
			<StyleWrapper>
				{value && value.length > 1 ? (
					<RangePicker
						onChange={this.props.onHandleChangeDates}
						getPopupContainer={this.getCalendarContainer}
						// getCalendarContainer={this.getCalendarContainer}
						defaultValue={[moment(), moment()]}
						value={value}
						format={IMPORTER_DATE_FORMAT}
						{...disabledDateProps}
						{...rest}
					/>
				) : (
					<DatePicker
						onChange={this.handleChangeDate}
						getPopupContainer={this.getCalendarContainer}
						// getCalendarContainer={this.getCalendarContainer}
						value={value && value[0]}
						format={IMPORTER_DATE_FORMAT}
						{...disabledDateProps}
						{...rest}
					/>
				)}
			</StyleWrapper>
		);
	}
}

export default AssetManagerImporterDatepicker;
