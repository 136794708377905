import { Tooltip, Card, Alert } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useTranslate } from '@/client/translation/useTranslate';

import config from '../../../config';
import routes from '../../../config/config.routes';
import styles from '../../../styles';
import { List, ListItem } from '../../List';

import LatestPublicationContainer from './LatestPublicationContainer';

export default () => {
	const translate = useTranslate();

	return (
		<LatestPublicationContainer
			render={({ error, props }: any) => {
				const dataAvailable =
					props !== null &&
					props.data &&
					Array.isArray(props.data.edges) &&
					props.data.edges.length > 0;

				return (
					<div>
						{error ? (
							<Alert message={translate('error')} description={error.message} type="error" />
						) : (
							<Card
								className={classNames({ [styles.cardWithoutPadding]: dataAvailable })}
								title={translate('dashboard.latestPublication')}
								loading={error === null && props === null}
							>
								{dataAvailable ? (
									<List bordered={false}>
										{props.data.edges.map(({ node }) => {
											const publicationDate = moment
												.utc(node.firstPublicationDate ?? node.publicationDate)
												.local();

											return (
												<ListItem
													key={node.rowId}
													actions={
														<Tooltip
															className={styles.noWrap}
															title={publicationDate.format(config.moment.absolute)}
														>
															{publicationDate.fromNow()}
														</Tooltip>
													}
												>
													<Link to={`${routes.article}/${node.rowId}`}>{node.title}</Link>
												</ListItem>
											);
										})}
									</List>
								) : (
									<p className={styles.pM}>{translate('noData')}</p>
								)}
							</Card>
						)}
					</div>
				);
			}}
		/>
	);
};
