import { NODES } from '../formattingTypes';
import type { SlateRenderNodeProps } from '../formattingTypes';

const nodeTypes = Array.from(NODES.values()).reduce(
	(accumulator, node) => ({
		...accumulator,
		[node.type]: node.render,
	}),
	{}
);

export default (renderProps: SlateRenderNodeProps) => {
	if (nodeTypes[renderProps.node.type]) {
		return nodeTypes[renderProps.node.type](renderProps);
	}

	return null;
};
