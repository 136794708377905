import { AM_TYPE_AUDIO } from '../../../constants';

import ItemMultimedia from './ItemMultimedia';
import type { BaseProps } from './ItemMultimedia';

function AssetManagerItemAudio(props: BaseProps) {
	return <ItemMultimedia {...props} type={AM_TYPE_AUDIO} />;
}

export default AssetManagerItemAudio;
