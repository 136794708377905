import { DND_BOARD_ITEM } from '@sep/br24-constants';
import { NativeTypes } from 'react-dnd-html5-backend';

import importerTypes, {
	IMPORTED_ARTICLE,
	IMPORTED_BOARD,
} from '../../AssetManager/AssetManagerImporter/itemTypes';
import assetManagerTypes from '../../AssetManager/itemTypes';
import articleEditorTypes from '../itemTypes';

export function getAllowedDataTypes() {
	return ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
}

const AETypes = {
	ARTICLE: articleEditorTypes.AE_ARTICLE,
	AUDIO: articleEditorTypes.AE_AUDIO,
	BOARD: articleEditorTypes.AE_BOARD,
	EMBED: articleEditorTypes.AE_EMBED_CODE,
	GALLERY_ITEM: articleEditorTypes.AE_GALLERY_ITEM,
	GALLERY: articleEditorTypes.AE_GALLERY,
	IMAGE: articleEditorTypes.AE_IMAGE,
	LINK: articleEditorTypes.AE_LINK,
	LIVESTREAM: articleEditorTypes.AE_LIVESTREAM,
	NATIVE: NativeTypes.FILE,
	TEXT: articleEditorTypes.AE_TEXT,
	VIDEO: articleEditorTypes.AE_VIDEO,
	VIDEO360: articleEditorTypes.AE_VIDEO360,
} as const;

// I think these should be later removed and in these cases we just get the type directly,
// or take it from the all types export. but up for discussion.
export function getAEItemType<T extends keyof typeof AETypes>(
	requestedType: T
): (typeof AETypes)[T] {
	return AETypes[requestedType];
}

const AMTypes = {
	ARTICLE: assetManagerTypes.AM_ARTICLE,
	AUDIO: assetManagerTypes.AM_AUDIO,
	BOARD: assetManagerTypes.AM_BOARD,
	EMBED: assetManagerTypes.AM_EMBED_CODE,
	GALLERY: assetManagerTypes.AM_GALLERY,
	IMAGE: assetManagerTypes.AM_IMAGE,
	LINK: assetManagerTypes.AM_LINK,
	LIVESTREAM: assetManagerTypes.AM_LIVESTREAM,
	TEXT: assetManagerTypes.AM_TEXT,
	VIDEO: assetManagerTypes.AM_VIDEO,
	VIDEO360: assetManagerTypes.AM_VIDEO360,
} as const;

export function getAMItemType<T extends keyof typeof AMTypes>(
	requestedType: T
): (typeof AMTypes)[T] {
	return AMTypes[requestedType];
}

// * Collecting values
export const allAssetManagerTypes = [...Object.values(assetManagerTypes)];
export const allArticleEditorTypes = [...Object.values(articleEditorTypes)];
export const allImporterTypes = [...Object.values(importerTypes)];

// * Exceptions
// Items like articles and authors shouldn't be droppable in the Article Editor
const omitFromAE = [
	assetManagerTypes.AM_ARTICLE,
	assetManagerTypes.AM_BOARD,
	assetManagerTypes.AM_BOARD_TEASER,
	assetManagerTypes.AM_LINK,
	articleEditorTypes.AE_ARTICLE,
	articleEditorTypes.AE_BOARD,
	articleEditorTypes.AE_LINK,
	articleEditorTypes.AE_AUTHOR,
];
const toCreateModuleTypes = allAssetManagerTypes.filter(
	(itemType) => !omitFromAE.includes(itemType)
);
const toSortModuleTypes = [...allAssetManagerTypes, ...allArticleEditorTypes].filter(
	(itemType) => !omitFromAE.includes(itemType)
);

// * Use case bundles
export const toCreateModule = [...toCreateModuleTypes];
export const toSortModule = [...toSortModuleTypes];
export const toAssetManager = [
	...allArticleEditorTypes,
	...allAssetManagerTypes,
	...allImporterTypes,
];
export const fromAssetManager = [...allAssetManagerTypes];

export const toMZT = [
	...([
		assetManagerTypes.AM_ARTICLE,
		assetManagerTypes.AM_BOARD,
		assetManagerTypes.AM_BOARD_TEASER,
		assetManagerTypes.AM_LINK,
		IMPORTED_ARTICLE,
		IMPORTED_BOARD,
	] as const),
];

export const toBoardSection = [
	assetManagerTypes.AM_ARTICLE,
	assetManagerTypes.AM_BOARD,
	assetManagerTypes.AM_BOARD_TEASER,
	DND_BOARD_ITEM,
] as const;
