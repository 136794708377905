import type { AppThunkAction } from '@/client/store/redux';
import type { LocationMeta } from '@/client/ui/Map';
import type { District } from '@/types/schema';

import { findSectionById } from '../util/find';

import updateSection, { type UpdateSectionAction } from './updateSection';

export default function updateSectionMeta(
	sectionInternalId: string,
	metaPatch: {
		geoLocationMeta?: Partial<LocationMeta> | null;
		geoLocation?: string | null;
		district?: District | null;
	}
): AppThunkAction<UpdateSectionAction> {
	return (dispatch, getState) => {
		const section = findSectionById(sectionInternalId, getState);

		if (!section) {
			return;
		}

		dispatch(
			updateSection(sectionInternalId, {
				meta: {
					...(section.meta || {}),
					...metaPatch,
				},
			})
		);
	};
}
