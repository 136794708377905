import { Input, Button } from 'antd';
import { Component } from 'react';
import type { SyntheticEvent } from 'react';
import styled, { type DefaultTheme, type ThemeProps, withTheme } from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import ColorPicker from '../../../components/ColorPicker';
import type { WithTranslationProps } from '../../../translation';
import { Cancel as CancelIcon, Trash2 as TrashIcon } from '../../Icon';

interface Props extends WithTranslationProps, ThemeProps<DefaultTheme> {
	color: string;
	title: string;
	windowTitle: string;
	isVisible: boolean;
	onHidePopover: () => void;
	onChange: (change: Partial<{ color: string; title: string }>) => void;
	onSubmit: () => void;
	onDelete?: () => void;
}

const StyledButton = styled.button`
	border: none;
	background: transparent;
	position: absolute;
	top: 20px;
	right: 10px;
	width: 24px;
	height: 24px;
`;

const IconButton = styled.button`
	background: transparent;
	border: none;
`;

const ButtonWrapper = styled.div`
	display: flex;
	margin-top: 20px;

	> *:last-child {
		margin-right: 0;
		margin-left: auto;

		&:first-child {
			margin-right: auto;
			margin-left: 0;
		}
	}
`;

function Cancel(props) {
	return (
		<StyledButton>
			<CancelIcon {...props} />
		</StyledButton>
	);
}

type CharCounterProps = {
	limit: number;
	used: number;
	className?: string;
};

function CharCounter({ limit, used, className }: CharCounterProps) {
	return <p className={className}>{`${used}/${limit}`}</p>;
}

const StyledCharCounter = styled(CharCounter)`
	opacity: 0.5;
	font-size: 0.75em;
	padding: 4px 0;
	pointer-events: none;
	text-align: right;
`;

const Header = styled.p`
	font-weight: bold;
	margin-bottom: 20px;
`;

const Wrapper = styled.div`
	padding: 5px 10px;
`;

const StyledColorPicker = styled(ColorPicker)`
	margin-top: 10px;
	padding: 4px 20px;
`;

class AssetManagerGroupsCreator extends Component<Props> {
	handleColorChange = (color: string): void => {
		this.props.onChange({ color });
	};

	handleDelete = () => {
		const { onDelete } = this.props;
		if (onDelete) {
			onDelete();
		}
	};

	handleSave = () => {
		this.props.onSubmit();
	};

	handleTitleChange = (event: SyntheticEvent<HTMLInputElement>): void => {
		this.props.onChange({ title: event.currentTarget.value });
	};

	render() {
		const {
			translation: { translate },
			windowTitle,
			onDelete,
			onHidePopover,
		} = this.props;

		const canSave = this.props.title.length > 0;

		return (
			<Wrapper>
				<Header>
					{windowTitle}
					<Cancel onClick={onHidePopover} />
				</Header>
				<Input
					maxLength={60}
					ref={(ref) => ref && ref.focus()}
					value={this.props.title}
					onChange={this.handleTitleChange}
					onPressEnter={this.props.onSubmit}
					placeholder={translate('assetManager.groups.creatorPlaceholder')}
				/>
				<StyledCharCounter limit={60} used={this.props.title.length} />
				<StyledColorPicker
					isSmall={true}
					onChange={this.handleColorChange}
					colors={this.props.theme.assetManager.groups.colors}
					defaultColor={this.props.color}
				/>
				<ButtonWrapper>
					<Button disabled={!canSave} type="primary" onClick={this.handleSave}>
						{translate('assetManager.groups.save')}
					</Button>
					{onDelete && (
						<IconButton onClick={this.handleDelete}>
							<TrashIcon />
						</IconButton>
					)}
				</ButtonWrapper>
			</Wrapper>
		);
	}
}

export default withTheme(withTranslation(AssetManagerGroupsCreator));
