import * as util from '@sep/br24-util';
import { Component } from 'react';

import config from '@/client/config';

import type { MetaTeaserData } from '../../..';

import ContainerTeaserItemExternal from './ContainerTeaserItemExternal';
import ContainerTeaserItemInternal from './ContainerTeaserItemInternal';

type UrlInfoType =
	| { isExternal: true; rowId: string | null | undefined; hostname: string }
	| { isExternal: false; rowId: string; hostname: string };

const getUrlInfo = (url: string) => {
	const { hostname } = new URL(url);
	const { meta: potentialRowId, site } = util.url.getInfo(url, config.VITE_BRANCH);
	const isExternal = site !== 'BR24' || !potentialRowId;

	return {
		isExternal,
		rowId: potentialRowId,
		hostname,
	} as UrlInfoType;
};

export const metaText = (
	_url: string,
	date: Date | null,
	hostname: string | null = null
): string => {
	const pieces = [hostname];
	if (date && date.getDate) {
		pieces.push(`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`);
	}
	return pieces.filter((p) => !!p).join(' – ');
};

type ContainerTeaserItemProps = {
	index: number;
	url: string;
	meta: MetaTeaserData | null;
	onRemove: (index: number) => void;
	onDataFetch: (index: number, label: string) => void;
	isInvalid: boolean;
	isDepublished: boolean;
	isNewItem: boolean;
};

class ContainerTeaserItem extends Component<ContainerTeaserItemProps> {
	handleRemove = () => {
		this.props.onRemove(this.props.index);
	};

	render() {
		const { url, onDataFetch, index, isInvalid, isDepublished, isNewItem, meta } = this.props;
		const { isExternal, rowId, hostname } = getUrlInfo(url);

		return isExternal ? (
			<ContainerTeaserItemExternal
				isNewItem={isNewItem}
				index={index}
				url={url}
				meta={meta}
				hostname={hostname}
				onRemove={this.handleRemove}
				onDataFetch={onDataFetch}
				isInvalid={isInvalid}
			/>
		) : (
			<ContainerTeaserItemInternal
				isNewItem={isNewItem}
				index={index}
				rowId={rowId}
				url={url}
				meta={meta}
				hostname={hostname}
				onRemove={this.handleRemove}
				onDataFetch={onDataFetch}
				isInvalid={isInvalid}
				isDepublished={isDepublished}
			/>
		);
	}
}

export default ContainerTeaserItem;
