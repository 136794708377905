import { Row, Col } from 'antd';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { Cancel, DragItem } from '../../Icon';

const iconStyles = `
	cursor: pointer;
	font-size: 1.3em;
`;

type WrapperProps = {
	isInvalidOrDepublished: boolean;
};

const Wrapper = styled.div<WrapperProps>`
	background-color: ${(props) =>
		props.isInvalidOrDepublished ? 'rgba(232, 75, 61, 0.2)' : props.theme.colors.light1};
	border-radius: ${(props) => props.theme.borderRadius};
	padding: 0.6rem;
	margin-top: 0.2rem;
`;

const DragHandleWrapper = styled(Col)`
	cursor: grab;
`;

type RemoveActionProps = {
	className?: string;
	index: number;
	onClick: (index: number) => void;
};

export const RemoveAction = styled(({ className, onClick, index }: RemoveActionProps) => (
	<button data-testid="removebutton" className={className} onClick={() => onClick(index)}>
		<Cancel />
	</button>
))`
	${iconStyles};
	color: ${(props) => props.theme.colors.br24Light};
`;

type DragActionProps = {
	className?: string;
};

export const DragAction = styled(({ className }: DragActionProps) => (
	<span className={className}>
		<DragItem />
	</span>
))`
	${iconStyles}
	color: ${(props) => props.theme.colors.br24Light};
	cursor: grab;
`;

export type Props = {
	children: ReactNode;
	index: number;
	isInvalidOrDepublished?: boolean;
	isDeletable?: boolean;
	isDraggable: boolean;
	onRemove: (index: number) => void;
	className?: string;
};

function ArticleMetaDraggableListItem({
	onRemove,
	children,
	index,
	isInvalidOrDepublished = false,
	isDraggable = true,
	isDeletable = true,
	className,
}: Props) {
	return (
		<Wrapper className={className} isInvalidOrDepublished={isInvalidOrDepublished}>
			<Row justify="space-around" align="middle" gutter={12}>
				{isDraggable ? (
					<DragHandleWrapper span={1}>
						<DragAction />
					</DragHandleWrapper>
				) : null}
				<Col span={21}>{children}</Col>
				{isDeletable ? (
					<Col span={2}>
						<RemoveAction data-testid="remove-action" index={index} onClick={onRemove} />
					</Col>
				) : null}
			</Row>
		</Wrapper>
	);
}

export default ArticleMetaDraggableListItem;
