/**
 * @generated SignedSource<<32575ab631cf0cc76467c197042ec5d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteModalContainerRefetchQuery$variables = {
  boardsTeaserId: string;
};
export type DeleteModalContainerRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DeleteModal_query">;
};
export type DeleteModalContainerRefetchQuery = {
  response: DeleteModalContainerRefetchQuery$data;
  variables: DeleteModalContainerRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardsTeaserId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteModalContainerRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DeleteModal_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteModalContainerRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "rowId",
            "variableName": "boardsTeaserId"
          }
        ],
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsSectionsItemsConnection",
            "kind": "LinkedField",
            "name": "boardsSectionsItemsByBoardsTeaserId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardsSectionsItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BoardsSection",
                    "kind": "LinkedField",
                    "name": "boardsSectionByBoardsSectionId",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "boardId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Board",
                        "kind": "LinkedField",
                        "name": "boardByBoardId",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89be6d4408c7dec6f97cf53626b82afd",
    "id": null,
    "metadata": {},
    "name": "DeleteModalContainerRefetchQuery",
    "operationKind": "query",
    "text": "query DeleteModalContainerRefetchQuery(\n  $boardsTeaserId: String!\n) {\n  ...DeleteModal_query\n}\n\nfragment DeleteModal_query on Query {\n  boardsTeaserByRowId(rowId: $boardsTeaserId) {\n    boardsSectionsItemsByBoardsTeaserId {\n      nodes {\n        boardsSectionByBoardsSectionId {\n          boardId\n          boardByBoardId {\n            id\n            rowId\n            title\n          }\n          id\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "63921ee99866388522e47ecaef18e193";

export default node;
