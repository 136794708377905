import { CATEGORY_ALL } from '@sep/br24-constants';
import { Select } from 'antd';
import cx from 'classnames';
import { uniqueId } from 'lodash-es';
import { Component } from 'react';

import translations from '../../translations';

import styles from './CategoryPicker.module.scss';

const { Option } = Select;

interface Props {
	options: Array<{
		key: string;
		label: string;
	}>;
	defaultValue?: string | null;
	value?: string | null;
	onChange?: (key: string | null, label: string | null) => void;
	placeholder?: string | null;
	showEmpty?: boolean;
	showPickAll?: boolean;
	displayBlock?: boolean;
	className?: string;
	disabled?: boolean;
}

export default class CategoryPickerDropdown extends Component<Props> {
	elementId = uniqueId('CategoryPickerDropdown-');

	handleChange = (value: { key: string; label: string }) => {
		if (this.props.onChange) {
			const nextValue = value.key === CATEGORY_ALL ? '' : value.key;
			const nextTitle = value.key === CATEGORY_ALL ? '' : value.label;

			this.props.onChange(nextValue || null, nextTitle || null);
		}
	};

	render() {
		const { showEmpty, showPickAll, placeholder, displayBlock, className, disabled } = this.props;

		let { options } = this.props;
		const defaultValue = options.find(({ key }) => key === this.props.defaultValue);
		let value = options.find(({ key }) => key === this.props.value) || defaultValue;

		if (showPickAll) {
			options = [{ key: CATEGORY_ALL, label: translations[CATEGORY_ALL] }, ...options];
			value = value || options[0];
		}

		if (showEmpty) {
			options = [{ key: '', label: 'Keine Kategorie' }, ...options];
		}

		return (
			<div id={this.elementId}>
				<Select
					showSearch={true}
					labelInValue={true}
					disabled={disabled || false}
					onChange={this.handleChange}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					getPopupContainer={() => document.getElementById(this.elementId)!}
					value={value}
					placeholder={placeholder}
					className={cx(
						{
							[styles.block]: displayBlock,
						},
						styles.categoryPicker,
						className
					)}
				>
					{options.map(({ key, label }) => (
						<Option key={key} value={key}>
							{label}
						</Option>
					))}
				</Select>
			</div>
		);
	}
}
