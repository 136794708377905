import { createFragmentContainer, graphql } from 'react-relay';
import type { RouteComponentProps } from 'react-router';

import { useTranslate } from '@/client/translation/useTranslate';

import routeConfig from '../../../config/config.routes';
import ProfileImageFallback from '../../../images/profile-placeholder.png';

import AuthorsListItem from './AuthorsListItem';
import type { AuthorListItemLoggedInUser_query$data } from './__generated__/AuthorListItemLoggedInUser_query.graphql';

interface Props extends RouteComponentProps {
	query: AuthorListItemLoggedInUser_query$data;
}

function AuthorListItemLoggedInUser({ query, history }: Props) {
	const translate = useTranslate();
	const loggedInUser = query?.loggedInUser?.edges[0]?.node || null;

	const handleOnClick = () => {
		if (loggedInUser?.guid) {
			history.push(`${routeConfig.authors}/${loggedInUser.guid}`);
		}
	};

	if (loggedInUser) {
		return (
			<AuthorsListItem
				firstname={loggedInUser.firstname ?? ''}
				lastname={`${loggedInUser.lastname} (${translate('authors.me')})`}
				isLdapUser={loggedInUser.isLdapUser ?? false}
				isActive={loggedInUser.isActive ?? false}
				profilePicture={loggedInUser.profilePicture || ProfileImageFallback}
				handleOnClick={handleOnClick}
			/>
		);
	}

	return null;
}

export default createFragmentContainer(AuthorListItemLoggedInUser, {
	query: graphql`
		fragment AuthorListItemLoggedInUser_query on Query {
			loggedInUser: allAuthors(
				first: $count
				after: $cursor
				filter: $loggedInUserFilter
				orderBy: LASTNAME_ASC
			) {
				edges {
					node {
						guid
						firstname
						lastname
						profilePicture
						isActive
						isLdapUser
					}
				}
			}
		}
	`,
});
