import type { ReactNode } from 'react';

import styles from './List.module.scss';

type Props = {
	children?: ReactNode;
	actions?: ReactNode;
};

export default function ListItem({ children, actions, ...rest }: Props) {
	return (
		<li {...rest} className={styles.listItem}>
			<div className={styles.listItemChildren}>{children}</div>
			{actions ? <div className={styles.listItemActions}>{actions}</div> : false}
		</li>
	);
}
