import { Component } from 'react';
import { createPaginationContainer, graphql, type RelayPaginationProp } from 'react-relay';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../../translation';
import InfiniteScroll from '../InfiniteScroll';

import ArticleSearchItem from './ArticleSearchItem';
import type { ArticlesSearchContainer_query$data } from './__generated__/ArticlesSearchContainer_query.graphql';

interface Props extends WithTranslationProps {
	query: ArticlesSearchContainer_query$data;
	relay: RelayPaginationProp;
}

class ArticlesSearchContainer extends Component<Props> {
	onLoadMore = () => {
		this.props.relay.loadMore(25);
	};

	handleOpen = (rowId: string) => {
		if (rowId) {
			window.open(`/articleEditor/${String(rowId)}`);
		}
	};

	render() {
		const {
			translation: { translate },
			relay,
		} = this.props;

		return (
			<InfiniteScroll hasMore={relay.hasMore()} loadMore={this.onLoadMore}>
				{this.props.query.articles && Array.isArray(this.props.query.articles.edges) ? (
					this.props.query.articles.edges.map((item) => {
						if (!item || !item.node) {
							return false;
						}

						return (
							<ArticleSearchItem
								key={item.node.rowId}
								article={item.node}
								onRequestOpen={this.handleOpen}
							/>
						);
					})
				) : (
					<p>{translate('board.search.emptySearch')}</p>
				)}
			</InfiniteScroll>
		);
	}
}

export default withTranslation(
	createPaginationContainer(
		ArticlesSearchContainer,
		{
			query: graphql`
				fragment ArticlesSearchContainer_query on Query {
					articles: filterArticles(
						first: $count
						after: $cursor
						searchPlain: $searchTerm
						categoryFilter: $category
						capabilityFilter: [BASIC, FULLY_FEATURED]
						statusFilter: PUBLISHED
						sortBy: FIRST_PUBLICATION_DATE_DESC
					) @connection(key: "ArticlesSearchContainer_articles") {
						totalCount
						pageInfo {
							hasNextPage
							startCursor
							endCursor
						}
						edges {
							node {
								rowId
								...ArticleSearchItem_article
							}
						}
					}
				}
			`,
		},
		{
			direction: 'forward',
			getConnectionFromProps(props) {
				return props.query && props.query.articles;
			},
			getFragmentVariables(prevVars, totalCount) {
				return {
					...prevVars,
					count: totalCount,
				};
			},
			getVariables(_props, { count, cursor }, variables) {
				return {
					...variables,
					count,
					cursor,
				};
			},
			query: graphql`
				query ArticlesSearchContainerQuery(
					$count: Int!
					$cursor: Cursor
					$searchTerm: String
					$category: String
				) {
					...ArticlesSearchContainer_query
				}
			`,
		}
	)
);
