import { createContext, type FC, type PropsWithChildren, useCallback, useState } from 'react';

import i18n, { type Language, persistLanguage, setMomentLocale } from './i18n';
import translate from './translate';

export type TranslationVariables = {
	[id: string]: any;
};

export type TranslationContextType = {
	language: Language;
	translate: typeof translate;
	changeLanguage: (language: Language) => void;
};

export const translationContext = createContext<TranslationContextType>({
	language: 'de_DE',
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	translate: () => null!,
	changeLanguage: () => null,
});

export const TranslationProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
	const [language, setLanguage] = useState<Language>('de_DE');

	const changeLanguage = useCallback((language: Language) => {
		persistLanguage(language);
		setMomentLocale(language);
		setLanguage(language);
		i18n.changeLanguage(language);
	}, []);

	return (
		<translationContext.Provider
			value={{
				language,
				changeLanguage,
				translate,
			}}
		>
			{children}
		</translationContext.Provider>
	);
};
