import { Spin } from 'antd';
import { createContext, type ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

type Props = {
	hasMore: boolean;
	loadMore: (page: number) => void;
	children: ReactNode;
};

const SpinLoader = styled(Spin)`
	width: 100%;
`;

const InfiniteScrollContext = createContext<HTMLElement | null>(null);
export const InfiniteScrollProvider = InfiniteScrollContext.Provider;
const InfiniteScrollConsumer = InfiniteScrollContext.Consumer;

export default function ({ children, hasMore, loadMore }: Props) {
	return (
		<InfiniteScrollConsumer>
			{(scrollParentRef) => {
				return (
					<InfiniteScroll
						loadMore={loadMore}
						hasMore={hasMore}
						getScrollParent={() => scrollParentRef}
						useWindow={false}
						loader={<SpinLoader key="history-timeline-spinner" size="large" />}
						threshold={500}
					>
						{children}
					</InfiniteScroll>
				);
			}}
		</InfiniteScrollConsumer>
	);
}
