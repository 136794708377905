import { SORT_PUBL_DESC, STATUS_PUBLISHED } from '@sep/br24-constants';
import { Component, type MouseEvent } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import type { ConnectedProps } from 'react-redux';
import { createPaginationContainer, graphql, type RelayPaginationProp } from 'react-relay';
import styled from 'styled-components';

import { withLock } from '@/client/hooks/useLock';
import type { ReduxState } from '@/client/store/reducers';
import type { FilterQuery } from '@/client/store/reducers/articlesManager';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';
import type { MenuInfo } from '@/types/libs';

import type { WithTranslationProps } from '../../../translation';
import TimeIndicator from '../../TimeIndicator';

import { ArticlesTableHead } from './ArticlesTableHead';
import ArticlesTableRow from './ArticlesTableRow';
import type { ArticlesTable_query$data } from './__generated__/ArticlesTable_query.graphql';
import type { ArticlesTableArticle } from './types';

export interface TableRowListeners {
	onClickOpen: (
		event: MouseEvent<HTMLTableDataCellElement | HTMLElement>,
		node: ArticlesTableArticle
	) => void;
	onClickPreview: (event: MenuInfo, node: ArticlesTableArticle) => void;
	onClickCreateFromTemplate: (event: MenuInfo, node: ArticlesTableArticle) => void;
	onClickRepublish: (node: ArticlesTableArticle) => void;
}

interface Props extends ReduxProperties, WithTranslationProps, TableRowListeners {
	relay: RelayPaginationProp;
	query: ArticlesTable_query$data;
	filter: FilterQuery;
	onChangeSelection: (selection: string[]) => void;
	selection: Array<string>;
	autoRefreshIn: number;
}

const TableWrapper = styled.div`
	width: 100%;
	overflow-x: scroll;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	display: flex;
	flex-direction: column;
`;

class ArticlesTable extends Component<Props> {
	indicator: TimeIndicator | null = null;

	componentDidMount() {
		this.handleRefresh();
	}

	componentDidUpdate(prevProps: Props) {
		if (this.indicator && prevProps.filter !== this.props.filter) {
			this.indicator.reset();
		}
	}

	onHandleLoadMore = () => {
		this.props.relay.loadMore(ArticlesTable.LOAD_MORE_NUM);
	};

	captureIndicator = (ref) => {
		this.indicator = ref;
	};

	handleClickSelect = (event: Event, id: string): void => {
		if (event.target instanceof HTMLInputElement) {
			let { selection } = this.props;

			if (event.target.checked) {
				selection = [...selection, id];
			} else {
				selection = selection.filter((item) => item !== id);
			}

			event.stopPropagation();
			this.props.onChangeSelection(selection);
		}
	};

	handleRefresh = () => {
		this.props.relay.refetchConnection(
			50,
			() => {
				// do nothing
			},
			this.props.filter
		);
	};

	static LOAD_MORE_NUM = 50;

	render() {
		const {
			relay,
			query,
			selection,
			onClickOpen,
			onClickPreview,
			onClickCreateFromTemplate,
			onClickRepublish,
			autoRefreshIn,
			filter,
			translation: { translate },
			isAssetManagerVisible,
		} = this.props;

		const additionalProps = {
			onClickPreview,
			onClickOpen,
			onClickCreateFromTemplate,
			onClickSelect: this.handleClickSelect,
			onClickRepublish,
		};

		const isOrderedByPublicationDate =
			filter && filter.sortBy === SORT_PUBL_DESC && filter.statusFilter === STATUS_PUBLISHED;

		return (
			<InfiniteScroll hasMore={relay.hasMore()} loadMore={this.onHandleLoadMore}>
				<TableWrapper>
					{autoRefreshIn ? (
						<TimeIndicator
							ref={this.captureIndicator}
							duration={autoRefreshIn}
							onFulfil={this.handleRefresh}
						/>
					) : (
						false
					)}
					<Table className={isAssetManagerVisible ? 'am-open' : 'am-closed'}>
						<ArticlesTableHead isOrderedByPublicationDate={isOrderedByPublicationDate} />
						<tbody style={{ paddingBottom: 150 }}>
							{query.articles && query.articles.edges && query.articles.edges.length > 0 ? (
								query.articles.edges.map(({ node }) =>
									!node ? null : (
										<ArticlesTableRow
											key={node.rowId}
											isSelected={selection.includes(node.rowId)}
											node={node}
											dateKey={isOrderedByPublicationDate ? 'publicationDate' : 'lastEdited'}
											{...additionalProps}
										/>
									)
								)
							) : (
								<tr>
									<td colSpan={10}>{translate('articles.search.emptySearch')}</td>
								</tr>
							)}
						</tbody>
					</Table>
				</TableWrapper>
			</InfiniteScroll>
		);
	}
}

const ArticleTablePagination = createPaginationContainer(
	withLock(ArticlesTable, { entityType: 'Article' }),
	{
		query: graphql`
			fragment ArticlesTable_query on Query {
				articles: filterArticles(
					first: $count
					after: $cursor
					searchPlain: $searchTerm
					publishedBefore: $publishedBefore
					categoryFilter: $categoryFilter
					tagsFilter: $tagsFilter
					capabilityFilter: $capabilityFilter
					onlyTemplates: $onlyTemplates
					statusFilter: $statusFilter
					sortBy: $sortBy
				) @connection(key: "ArticlesTable_articles") {
					pageInfo {
						hasNextPage
						startCursor
						endCursor
					}
					totalCount
					edges {
						node {
							createdAt
							id
							rowId
							status
							headline
							title
							slug
							primaryCategory
							capability
							firstPublicationDate
							publicationDate
							publicationPriority
							lastEdited
							isTemplate
							sourceOrigin
							image {
								url
							}
							categoryByPrimaryCategory {
								color
							}
							modules {
								edges {
									node {
										type
									}
								}
							}
							authorByRevisionBy {
								firstname
								lastname
							}
							notes
							...LockedByAuthor_article
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps(props) {
			return props.query && props.query.articles;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			};
		},
		getVariables(_props, { count, cursor }, fragmentVariables) {
			return {
				...fragmentVariables,
				count,
				cursor,
			};
		},
		query: graphql`
			query ArticlesTableQuery(
				$count: Int!
				$cursor: Cursor
				$searchTerm: String
				$publishedBefore: Datetime
				$categoryFilter: String
				$tagsFilter: [String]
				$capabilityFilter: [Capability]
				$statusFilter: Status
				$onlyTemplates: Boolean
				$sortBy: FilterArticlesSort
			) {
				...ArticlesTable_query
			}
		`,
	}
);

const connector = connect(({ assetManager }: ReduxState) => ({
	isAssetManagerVisible: assetManager.isVisible,
}));

type ReduxProperties = ConnectedProps<typeof connector>;

export default withTranslation(connector(ArticleTablePagination));
