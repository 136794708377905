import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateVisibilityAction, ChangeModeAction, UpdateSelectionAction } from '..';
import { AM_MODE_HARVEST } from '../../../constants';
import { UPDATE_VISIBILITY } from '../actionTypes';

import changeMode from './changeMode';

const updateVisibility =
	(
		isVisible: boolean
	): AppThunkAction<UpdateVisibilityAction | ChangeModeAction | UpdateSelectionAction> =>
	(dispatch, getState) => {
		const { assetManager } = getState();

		if (assetManager.mode !== AM_MODE_HARVEST) {
			dispatch(changeMode(AM_MODE_HARVEST));
		}

		dispatch({
			type: UPDATE_VISIBILITY,
			payload: isVisible,
		});
	};

export default updateVisibility;
