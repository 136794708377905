import * as constants from '@sep/br24-constants';
import { debounce } from 'lodash-es';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import {
	provideSectionByInternalId,
	updateSection,
	deleteSection,
	orderSectionBySectionId,
} from '../../../store/reducers/boardBuffer';
import type { BoardSectionBufferPatch } from '../../../store/reducers/boardBuffer/types';

import SectionCategoryLeft1Big from './SectionCategoryLeft1Big';
import SectionCategoryLeft2Small from './SectionCategoryLeft2Small';
import SectionCategoryRight1Big from './SectionCategoryRight1Big';
import SectioncategoryRight2Small from './SectionCategoryRight2Small';
import SectionHighlight3 from './SectionHighlight3';
import SectionRaisedLeftSimple from './SectionRaisedLeftSimple';
import SectionRaisedRightShortnews from './SectionRaisedRightShortnews';
import SectionRaisedRightShortnews2Small from './SectionRaisedRightShortnews2Small';
import SectionRaisedRightSimple from './SectionRaisedRightSimple';
import SectionRaisedRightSimpleWeather from './SectionRaisedRightSimpleWeather';
import SectionRegional4Simple from './SectionRegional4Simple';
import SectionSettings from './SectionSettings';
import SectionSmall3 from './SectionSmall3';
import SectionStageCarousel from './SectionStageCarousel';
import SectionStageSplitted from './SectionStageSplitted';
import SectionSubjectsNewsWeather from './SectionSubjectsNewsWeather';
import SortableCard from './SortableCard';

const map = {
	[constants.SECTION_SCHEMA_STAGE_CAROUSEL]: SectionStageCarousel,
	[constants.SECTION_SCHEMA_STAGE_SPLITTED]: SectionStageSplitted,
	[constants.SECTION_SCHEMA_SMALL_3]: SectionSmall3,
	[constants.SECTION_SCHEMA_RAISED_LEFT_SIMPLE]: SectionRaisedLeftSimple,
	[constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE]: SectionRaisedRightSimple,
	[constants.SECTION_SCHEMA_REGIONAL_4_SIMPLE]: SectionRegional4Simple,
	[constants.SECTION_SCHEMA_HIGHLIGHT_3]: SectionHighlight3,
	[constants.SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL]: SectionCategoryLeft2Small,
	[constants.SECTION_SCHEMA_CATEGORY_LEFT_1_BIG]: SectionCategoryLeft1Big,
	[constants.SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL]: SectioncategoryRight2Small,
	[constants.SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG]: SectionCategoryRight1Big,
	[constants.SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS]: SectionRaisedRightShortnews,
	[constants.SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL]: SectionRaisedRightShortnews2Small,
	[constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER]: SectionRaisedRightSimpleWeather,
	[constants.SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER]: SectionSubjectsNewsWeather,
};

interface Props extends ReduxProps, OwnProps {}

type State = {
	isSettingsModalVisible: boolean;
};

class SectionContainer extends Component<Props, State> {
	state = {
		isSettingsModalVisible: false,
	};

	handleMove = debounce(
		(dragOrder: number, hoverOrder: number) => this.props.order(dragOrder, hoverOrder),
		100
	);

	componentWillUnmount() {
		this.handleMove.flush();
	}

	handleClickSettings = () => this.setState({ isSettingsModalVisible: true });

	handleCloseSettings = () => this.setState({ isSettingsModalVisible: false });

	render() {
		const { section, update, remove } = this.props;
		const { isSettingsModalVisible } = this.state;

		const additionalProps = { section, update };
		if (!section) {
			return false;
		}

		const MappedComponent = map[section.schema];
		if (!MappedComponent) {
			return false;
		}

		return (
			<SortableCard
				section={section}
				onRequestUpdate={update}
				onClickSettings={this.handleClickSettings}
				onClickClose={remove}
				onMove={this.handleMove}
			>
				<SectionSettings
					section={section}
					isVisible={isSettingsModalVisible}
					onRequestClose={this.handleCloseSettings}
					onRequestUpdate={update}
				/>
				<MappedComponent {...additionalProps} />
			</SortableCard>
		);
	}
}

interface OwnProps {
	internalId: string;
}

const connector = connect(
	({ boardBuffer }, { internalId }: OwnProps) => ({
		section: provideSectionByInternalId(boardBuffer, internalId),
	}),
	(dispatch, { internalId }: OwnProps) => ({
		update: (sectionPatch: BoardSectionBufferPatch) =>
			dispatch(updateSection(internalId, sectionPatch)),
		remove: () => dispatch(deleteSection(internalId)),
		order: (dragOrder: number, hoverOrder: number) =>
			dispatch(orderSectionBySectionId(internalId, dragOrder, hoverOrder)),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SectionContainer);
