import {
	AUTHORS_FILTERS_ACTIVITY_ACTIVE,
	AUTHORS_FILTERS_ACTIVITY_ALL,
	AUTHORS_FILTERS_ACTIVITY_INACTIVE,
	AUTHORS_FILTERS_LOGIN_TYPE_ALL,
	AUTHORS_FILTERS_LOGIN_TYPE_LDAP,
	AUTHORS_FILTERS_LOGIN_TYPE_PROFILE,
} from '../../constants';

export type ActivityFilter =
	| typeof AUTHORS_FILTERS_ACTIVITY_ACTIVE
	| typeof AUTHORS_FILTERS_ACTIVITY_ALL
	| typeof AUTHORS_FILTERS_ACTIVITY_INACTIVE;

export type LoginTypeFilter =
	| typeof AUTHORS_FILTERS_LOGIN_TYPE_ALL
	| typeof AUTHORS_FILTERS_LOGIN_TYPE_LDAP
	| typeof AUTHORS_FILTERS_LOGIN_TYPE_PROFILE;

export const convertActivityFilterValue = (
	activityFilter: ActivityFilter | undefined
): boolean | null => {
	switch (activityFilter) {
		case AUTHORS_FILTERS_ACTIVITY_ACTIVE:
			return true;

		case AUTHORS_FILTERS_ACTIVITY_INACTIVE:
			return false;

		default:
			return null;
	}
};

export const convertLoginTypeFilterValue = (
	loginType: LoginTypeFilter | undefined
): boolean | null => {
	switch (loginType) {
		case AUTHORS_FILTERS_LOGIN_TYPE_LDAP:
			return true;

		case AUTHORS_FILTERS_LOGIN_TYPE_PROFILE:
			return false;

		default:
			return null;
	}
};
