import { Tooltip } from 'antd';
import type { CSSProperties } from 'react';
import styled from 'styled-components';

import { displayName } from '@/client/util/br24Utils';

import { UserDefault } from '../Icon';

const AvatarTile = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	box-shadow: 0 0 8px 0 ${(props) => props.theme.colors.white};
	background-size: cover;
	cursor: default;
	color: ${(props) => props.theme.colors.br24Font};
`;

const AvatarImage = styled(AvatarTile)`
	background-color: ${(props) => props.theme?.colors.primaryBlue};
`;

const AvatarText = styled(AvatarTile)`
	background-color: ${(props) => props.theme?.colors.greyHeader};
	justify-content: flex-end;
	padding-right: 0.3rem;
	font-size: 1rem;
	text-align: center;
	overflow: hidden;
	user-select: none;
`;

export type AvatarProps = {
	firstname: string | undefined | null;
	guid: string;
	lastname: string | undefined | null;
	url: string | undefined | null;
	className?: string;
	style?: CSSProperties;
};

export function Avatar(props: AvatarProps) {
	return (
		<div style={{ ...props.style }} className={props.className}>
			<Tooltip
				key={props.guid}
				placement="bottom"
				title={displayName({
					firstname: String(props.firstname),
					lastname: String(props.lastname),
				})}
			>
				<AvatarImage
					style={props.url ? { backgroundImage: `url(${props.url})` } : undefined}
					data-testid="avatar-image"
				>
					{props.url ? null : <UserDefault size="lg" />}
				</AvatarImage>
			</Tooltip>
		</div>
	);
}

export type AvatarOverflowProps = {
	tooltipText: string | undefined | null;
	count: number;
};

export function AvatarOverflow(props: AvatarOverflowProps) {
	return (
		<Tooltip placement="bottom" title={props.tooltipText}>
			<AvatarText>{`+${props.count}`}</AvatarText>
		</Tooltip>
	);
}
