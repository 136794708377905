import { Collapse, Tooltip } from 'antd';
import type { ReactNode, FunctionComponent } from 'react';
import styled from 'styled-components';

import { Info } from '../Icon';

const { Panel } = Collapse;

const PanelHeader = styled.div`
	display: flex;
	align-items: center;
`;

const Title = styled.span`
	flex: 1;
`;

// help text could contain links, to we have to render it with dangerouslySetInnerHTML in order to display the links in antd tooltips
const createHelpText = (text: string) => ({ __html: text });

type ArticleMetaPanelInfoProps = {
	className?: string;
	help: string;
};

export const ArticleMetaPanelInfo = styled(({ className, help }: ArticleMetaPanelInfoProps) => (
	<Tooltip title={<div dangerouslySetInnerHTML={createHelpText(help)} />}>
		<span className={className}>
			<Info />
		</span>
	</Tooltip>
))`
	align-self: flex-end;
	margin-left: 1rem;
	margin-right: 1rem;
	font-size: 1.4rem;
`;

export interface Props {
	children: ReactNode;
	help?: string;
	isRequired?: boolean;
	title: string;
	key: string | number;
}

export const ArticleMetaPanel: FunctionComponent<Props> = ({
	children,
	help,
	isRequired = false,
	title,
	...rest
}: Props) => (
	<Panel
		{...rest}
		header={
			<PanelHeader>
				<Title>
					{title} {isRequired && '*'}
				</Title>
				{help ? <ArticleMetaPanelInfo help={help} /> : null}
			</PanelHeader>
		}
	>
		{children}
	</Panel>
);
