import { BOARD_BUFFER_INITIALIZE_AND_CLEAN } from '@sep/br24-constants';

import type { Board_board$data } from '@/client/components/Board/__generated__/Board_board.graphql';
import type { AppThunkAction } from '@/client/store/redux';

import type {
	BoardBuffer,
	BoardSectionBuffer,
	BoardSectionItemBuffer,
	BoardSocialMediaAccountBuffer,
} from '../types';
import determineRelations from '../util/determineRelations';
import untangle from '../util/untangle';

import { validateAll } from './validate';

export type InitializeAndCleanAction = {
	type: typeof BOARD_BUFFER_INITIALIZE_AND_CLEAN;
	payload: {
		board: BoardBuffer;
		sections: Array<BoardSectionBuffer>;
		sectionItems: Array<BoardSectionItemBuffer>;
		socialMediaAccounts: Array<BoardSocialMediaAccountBuffer>;
		raw: Board_board$data;
	};
	constraint:
		| {
				boardId: string | undefined | null;
				socialMediaAccountIds: Array<string>;
				sectionIds: Array<string>;
				sectionItemIds: Array<string>;
		  }
		| undefined
		| null;
};

const noop = () => null;

export default function initializeAndClean(
	board: Board_board$data
): AppThunkAction<InitializeAndCleanAction> {
	return (dispatch, getState) => {
		const relations = determineRelations(board.rowId, getState);

		dispatch({
			type: BOARD_BUFFER_INITIALIZE_AND_CLEAN,
			payload: {
				...untangle(board),
				raw: board,
			},
			constraint: relations
				? {
						boardId: relations.boardId,
						socialMediaAccountIds: relations.socialMediaAccountIds,
						sectionIds: relations.sectionIds,
						sectionItemIds: relations.sectionItemIds,
				  }
				: null,
		});

		dispatch(
			validateAll(board.rowId, noop, {
				skipIfAutoValidationIsDisabled: true,
				shouldValidateSlug: true,
			})
		);
	};
}
