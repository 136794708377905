import type { PropsWithChildren } from 'react';
import type { ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import { AM_MODE_IMPORT } from '../../constants';

type MainProps = {
	isLimited: boolean;
	isBlurred: boolean;
};

const Root = styled.div<MainProps>`
	/* padding: ${(props) => (props.isLimited ? '0 1.5rem' : '0 4rem')}; */
	padding-top: ${(props) => (props.isLimited ? `calc(${props.theme.header.height} + 2rem)` : 0)};
	max-width: ${(props) =>
		props.isLimited ? `calc(100vw - ${props.theme.assetManager.width})` : '100vw'};
	filter: ${(props) => (props.isBlurred ? 'blur(4px)' : 'none')};
	/* padding-bottom: 100px; */
	padding-bottom: 0;
	min-height: ${({ theme }) =>
		`calc(100vh - ${theme.header.height} - ${theme.header.indicatorHeight})`};

	display: grid;
	grid-template-columns: minmax(0, 1fr);
	overflow: hidden;
	/* padding-bottom: 200px; */
`;

interface ContentProps extends ReduxProps {
	isAssetManagerVisible: boolean;
	isAssetImporterVisible: boolean;
}

function Content({
	children,
	isAssetManagerVisible,
	isAssetImporterVisible,
}: PropsWithChildren<ContentProps>) {
	return (
		<Root isLimited={isAssetManagerVisible} isBlurred={isAssetImporterVisible}>
			{children}
		</Root>
	);
}

const connector = connect((state: ReduxState) => ({
	isAssetManagerVisible: state.assetManager.isVisible,
	isAssetImporterVisible: state.assetManager.mode === AM_MODE_IMPORT,
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Content);
