import { BOARD_BUFFER_VALIDATE } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import type { State as UserState } from '../../user';
import { findBoardById, findSectionById } from '../util/find';
import * as validators from '../util/validators';

export type ValidateAction = {
	type: typeof BOARD_BUFFER_VALIDATE;
	constraint: {
		type: 'boards' | 'sections' | 'sectionItems';
		internalId: string;
	};
	payload:
		| {
				[key: string]: Array<string>;
		  }
		| undefined
		| null;
};

type ValidationOptions = {
	skipIfAutoValidationIsDisabled?: boolean;
};

const defaultOptions: ValidationOptions = {
	skipIfAutoValidationIsDisabled: false,
};

function validate(
	type: 'boards' | 'sections' | 'sectionItems',
	internalId: string,
	payload?: {
		[key: string]: Array<string>;
	} | null
): ValidateAction {
	return {
		type: BOARD_BUFFER_VALIDATE,
		constraint: {
			type,
			internalId,
		},
		payload,
	};
}

function shouldValidate(
	options: ValidationOptions,
	state: {
		user: UserState;
	}
): boolean {
	if (options.skipIfAutoValidationIsDisabled && !state.user.settings.boardAutoValidation) {
		return false;
	}

	return true;
}

export function validateBoard(
	boardId: string,
	options: ValidationOptions & {
		shouldValidateSlug?: boolean;
	} = {}
): AppThunkAction<ValidateAction> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		const { shouldValidateSlug = false, ...givenOptions } = {
			...defaultOptions,
			...options,
		};

		if (shouldValidate(givenOptions, getState())) {
			validators
				.board(board, { shouldValidateSlug })
				.then(({ isValid, error }) =>
					dispatch(validate('boards', boardId, isValid ? null : error))
				);
		}
	};
}

export function validateSection(
	sectionInternalId: string,
	optionsInput: ValidationOptions = {}
): AppThunkAction<ValidateAction> {
	return (dispatch, getState) => {
		const section = findSectionById(sectionInternalId, getState);

		if (!section) {
			return;
		}

		const options = { ...defaultOptions, ...optionsInput };

		if (shouldValidate(options, getState())) {
			validators
				.section(section)
				.then(({ isValid, error }) =>
					dispatch(validate('sections', sectionInternalId, isValid ? null : error))
				);
		}
	};
}

type Validations = Array<{
	board?: {
		__meta__: {
			internalId: string;
		};
	};
	section?: {
		__meta__: {
			internalId: string;
		};
	};
	isValid: boolean;
	error: {
		[key: string]: Array<string>;
	};
}>;

export function validateAll(
	boardId: string,
	callback?: (isValid: boolean) => void,
	options: ValidationOptions & {
		shouldValidateSlug?: boolean;
	} = {}
): AppThunkAction<ValidateAction> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		const { _shouldValidateSlug = false, ...givenOptions } = {
			...defaultOptions,
			...options,
		};
		// as slug validation requires roundtrips to backend, only process them if we are actuall interessted in the results
		const shouldValidateSlug = _shouldValidateSlug && shouldValidate(givenOptions, getState());

		return Promise.all([
			validators
				.board(board, { shouldValidateSlug })
				.then(({ isValid, error }) => ({ board, isValid, error })),
			...board.sections.map(async ({ __internalId__ }) => {
				const section = findSectionById(__internalId__, getState);
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const { isValid, error } = await validators.section(section!);
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				return { section: section!, isValid, error };
			}),
		]).then((res: Validations) => {
			const isValid = res.filter(({ isValid: value }) => !value).length === 0;

			if (typeof callback === 'function') {
				callback(isValid);
			}

			// add error labels to board/sections if not disabled
			if (shouldValidate(givenOptions, getState())) {
				res.forEach((item) => {
					if (item.board) {
						dispatch(
							validate('boards', item.board.__meta__.internalId, isValid ? null : item.error)
						);
					} else if (item.section) {
						dispatch(
							validate('sections', item.section.__meta__.internalId, isValid ? null : item.error)
						);
					}
				});
			}
		});
	};
}

export function clearAllValidations(boardId: string): AppThunkAction<ValidateAction> {
	return (dispatch, getState) => {
		const board = findBoardById(boardId, getState);

		if (!board) {
			return;
		}

		dispatch(validate('boards', board.__meta__.internalId, null));

		board.sections.forEach((section) => {
			dispatch(validate('sections', section.__internalId__, null));
		});
	};
}
