import styled from 'styled-components';

import { AssetManagerImport, Cancel } from '../../Icon';

const Wrapper = styled.div`
	width: 36px;
	height: 36px;
	background-color: ${(props) => props.theme.colors.dark4};
	position: absolute;
	top: calc(50% - 18px);
	left: -36px;
	font-size: 1.4rem;
	text-align: center;
	line-height: 36px;
	color: #ffffff;
	cursor: pointer;
	transition: ease 200ms all;
	&:hover {
		color: #ffffff;
		background-color: ${(props) => props.theme.colors.primaryBlue};
	}
`;

type AssetManagerImporterIconProps = {
	action: 'open' | 'close';
};

function AssetManagerImporterIcon({ action, ...rest }: AssetManagerImporterIconProps) {
	return <Wrapper {...rest}>{action === 'open' ? <AssetManagerImport /> : <Cancel />}</Wrapper>;
}

export default AssetManagerImporterIcon;
