/**
 * @generated SignedSource<<2f1e3ab29e3a34433ae82fca7a926856>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BoardsSectionsSchema = "CATEGORY_LEFT_1_BIG" | "CATEGORY_LEFT_2_SMALL" | "CATEGORY_RIGHT_1_BIG" | "CATEGORY_RIGHT_2_SMALL" | "HIGHLIGHT_3" | "RAISED_LEFT_SIMPLE" | "RAISED_RIGHT_SHORTNEWS" | "RAISED_RIGHT_SHORTNEWS_2_SMALL" | "RAISED_RIGHT_SIMPLE" | "RAISED_RIGHT_SIMPLE_WEATHER" | "REGIONAL_1_BIG_TOP_2_SMALL_BELOW" | "REGIONAL_2_BIG_TOP" | "REGIONAL_2_SMALL_TOP_1_BIG_BELOW" | "REGIONAL_4_SIMPLE" | "SMALL_3" | "STAGE_CAROUSEL" | "STAGE_SPLITTED" | "SUBJECTS_NEWS_WEATHER";
export type BoardsTeaserStatus = "DELETED" | "PUBLISHED";
export type Capability = "BASIC" | "FULLY_FEATURED" | "TEXT_ONLY";
export type District = "MITTELFRANKEN" | "NIEDERBAYERN" | "OBERBAYERN" | "OBERFRANKEN" | "OBERPFALZ" | "SCHWABEN" | "UNTERFRANKEN";
export type Expiration = "EDUCATION" | "EPG_BUSINESS_CONTENT" | "REPEATED_EVENTS" | "SERIES" | "SUPPORTING_INFO" | "TIME_CULTURE";
export type Gender = "FEMALE" | "MALE";
export type ModuleType = "AUDIO" | "EMBED" | "GALLERY" | "IMAGE" | "LIVESTREAM" | "TEXT" | "VIDEO" | "VIDEO360";
export type NavigationType = "ARTICLE" | "BOARD" | "CUSTOM" | "NAVIGATION";
export type Priority = "CRITICAL" | "HIGH" | "LOW" | "NORMAL";
export type PushNotification = "NONE" | "SILENT" | "SOUND";
export type SocialMediaAccountService = "EMAIL" | "FACEBOOK_PAGE" | "INSTAGRAM" | "SPOTIFY" | "TWITTER" | "YOUTUBE";
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type TagsStatus = "ACTIVE" | "RECOMMENDED" | "SPECIAL" | "SUPERSEDED";
export type VideoResolution = "FULL_HD" | "HLS_HIGH" | "HLS_LOW" | "UHD";
export type UpdateBoardsTeaserByRowIdInput = {
  boardsTeaserPatch: BoardsTeaserPatch;
  clientMutationId?: string | null;
  rowId: string;
};
export type BoardsTeaserPatch = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorCreateFkInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: AuthorCreateFkAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardsTeaserForAuthorCreateFkUsingAuthorsPkeyUpdate | null;
  updateById?: BoardsTeaserOnBoardsTeaserForAuthorCreateFkNodeIdUpdate | null;
};
export type AuthorAuthorsPkeyConnect = {
  guid: string;
};
export type AuthorNodeIdConnect = {
  id: string;
};
export type AuthorCreateFkAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticlesAuthorsAuthorIdFkeyInverseInput = {
  connectByAuthorGuidAndArticleId?: ReadonlyArray<ArticlesAuthorArticlesAuthorsPkeyConnect> | null;
  connectById?: ReadonlyArray<ArticlesAuthorNodeIdConnect> | null;
  create?: ReadonlyArray<ArticlesAuthorsAuthorIdFkeyArticlesAuthorsCreateInput> | null;
  deleteByAuthorGuidAndArticleId?: ReadonlyArray<ArticlesAuthorArticlesAuthorsPkeyDelete> | null;
  deleteById?: ReadonlyArray<ArticlesAuthorNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByAuthorGuidAndArticleId?: ReadonlyArray<ArticlesAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyUsingArticlesAuthorsPkeyUpdate> | null;
  updateById?: ReadonlyArray<AuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyNodeIdUpdate> | null;
};
export type ArticlesAuthorArticlesAuthorsPkeyConnect = {
  articleId: string;
  authorGuid: string;
};
export type ArticlesAuthorNodeIdConnect = {
  id: string;
};
export type ArticlesAuthorsAuthorIdFkeyArticlesAuthorsCreateInput = {
  articleId?: string | null;
  articleToArticleId?: ArticlesAuthorsArticleIdFkeyInput | null;
  authorToAuthorGuid?: ArticlesAuthorsAuthorIdFkeyInput | null;
  order?: number | null;
};
export type ArticlesAuthorsArticleIdFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: ArticlesAuthorsArticleIdFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: ArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnArticlesAuthorForArticlesAuthorsArticleIdFkeyUsingArticlesPkeyUpdate | null;
};
export type ArticleNodeIdConnect = {
  id: string;
};
export type ArticleArticlesPkeyConnect = {
  rowId: string;
};
export type ArticlesAuthorsArticleIdFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ArticlesAuthorsArticleIdFkeyInverseInput = {
  connectByAuthorGuidAndArticleId?: ReadonlyArray<ArticlesAuthorArticlesAuthorsPkeyConnect> | null;
  connectById?: ReadonlyArray<ArticlesAuthorNodeIdConnect> | null;
  create?: ReadonlyArray<ArticlesAuthorsArticleIdFkeyArticlesAuthorsCreateInput> | null;
  deleteByAuthorGuidAndArticleId?: ReadonlyArray<ArticlesAuthorArticlesAuthorsPkeyDelete> | null;
  deleteById?: ReadonlyArray<ArticlesAuthorNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByAuthorGuidAndArticleId?: ReadonlyArray<ArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyUsingArticlesAuthorsPkeyUpdate> | null;
  updateById?: ReadonlyArray<ArticleOnArticlesAuthorForArticlesAuthorsArticleIdFkeyNodeIdUpdate> | null;
};
export type ArticlesAuthorsArticleIdFkeyArticlesAuthorsCreateInput = {
  articleToArticleId?: ArticlesAuthorsArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAuthorGuid?: ArticlesAuthorsAuthorIdFkeyInput | null;
  order?: number | null;
};
export type ArticlesAuthorsAuthorIdFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: ArticlesAuthorsAuthorIdFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: ArticlesAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyNodeIdUpdate | null;
};
export type ArticlesAuthorsAuthorIdFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticlesCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<ArticleNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ArticleArticlesPkeyConnect> | null;
  create?: ReadonlyArray<ArticlesCreatedByFkeyArticlesCreateInput> | null;
  deleteById?: ReadonlyArray<ArticleNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ArticleArticlesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnArticleForArticlesCreatedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ArticleOnArticleForArticlesCreatedByFkeyUsingArticlesPkeyUpdate> | null;
};
export type ArticlesCreatedByFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ArticlesTagsArticleIdFkeyInverseInput = {
  connectByArticleIdAndTagId?: ReadonlyArray<ArticlesTagArticlesTagsArticleIdTagIdKeyConnect> | null;
  create?: ReadonlyArray<ArticlesTagsArticleIdFkeyArticlesTagsCreateInput> | null;
  updateByArticleIdAndTagId?: ReadonlyArray<ArticlesTagOnArticlesTagForArticlesTagsArticleIdFkeyUsingArticlesTagsArticleIdTagIdKeyUpdate> | null;
};
export type ArticlesTagArticlesTagsArticleIdTagIdKeyConnect = {
  articleId: string;
  tagId: number;
};
export type ArticlesTagsArticleIdFkeyArticlesTagsCreateInput = {
  articleToArticleId?: ArticlesTagsArticleIdFkeyInput | null;
  tagId?: number | null;
  tagToTagId?: ArticlesTagsTagIdFkeyInput | null;
};
export type ArticlesTagsArticleIdFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: ArticlesTagsArticleIdFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: ArticlesTagOnArticlesTagForArticlesTagsArticleIdFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnArticlesTagForArticlesTagsArticleIdFkeyUsingArticlesPkeyUpdate | null;
};
export type ArticlesTagsArticleIdFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ArticlesCreatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: ArticlesCreatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnArticleForArticlesCreatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: ArticleOnArticleForArticlesCreatedByFkeyNodeIdUpdate | null;
};
export type ArticlesCreatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticlesLockedByFkeyInverseInput = {
  connectById?: ReadonlyArray<ArticleNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ArticleArticlesPkeyConnect> | null;
  create?: ReadonlyArray<ArticlesLockedByFkeyArticlesCreateInput> | null;
  deleteById?: ReadonlyArray<ArticleNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ArticleArticlesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnArticleForArticlesLockedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ArticleOnArticleForArticlesLockedByFkeyUsingArticlesPkeyUpdate> | null;
};
export type ArticlesLockedByFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ArticlesLockedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: ArticlesLockedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnArticleForArticlesLockedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: ArticleOnArticleForArticlesLockedByFkeyNodeIdUpdate | null;
};
export type ArticlesLockedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticlesRevisionByFkeyInverseInput = {
  connectById?: ReadonlyArray<ArticleNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ArticleArticlesPkeyConnect> | null;
  create?: ReadonlyArray<ArticlesRevisionByFkeyArticlesCreateInput> | null;
  deleteById?: ReadonlyArray<ArticleNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ArticleArticlesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnArticleForArticlesRevisionByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ArticleOnArticleForArticlesRevisionByFkeyUsingArticlesPkeyUpdate> | null;
};
export type ArticlesRevisionByFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ArticlesRevisionByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: ArticlesRevisionByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnArticleForArticlesRevisionByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: ArticleOnArticleForArticlesRevisionByFkeyNodeIdUpdate | null;
};
export type ArticlesRevisionByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type StatusFkInput = {
  connectById?: AuthorStatusNodeIdConnect | null;
  connectByRowId?: AuthorStatusAuthorStatusPkeyConnect | null;
  create?: StatusFkAuthorStatusCreateInput | null;
  deleteById?: AuthorStatusNodeIdDelete | null;
  deleteByRowId?: AuthorStatusAuthorStatusPkeyDelete | null;
  updateById?: AuthorOnAuthorForStatusFkNodeIdUpdate | null;
  updateByRowId?: AuthorStatusOnAuthorForStatusFkUsingAuthorStatusPkeyUpdate | null;
};
export type AuthorStatusNodeIdConnect = {
  id: string;
};
export type AuthorStatusAuthorStatusPkeyConnect = {
  rowId: number;
};
export type StatusFkAuthorStatusCreateInput = {
  authorGuid?: string | null;
  authorToAuthorGuid?: AuthorGuidAuthorFkInput | null;
  authorsUsingRowId?: StatusFkInverseInput | null;
  emoji?: string | null;
  message: string;
  rowId?: number | null;
  usedAt?: string | null;
};
export type AuthorGuidAuthorFkInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: AuthorGuidAuthorFkAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnAuthorStatusForAuthorGuidAuthorFkUsingAuthorsPkeyUpdate | null;
  updateById?: AuthorStatusOnAuthorStatusForAuthorGuidAuthorFkNodeIdUpdate | null;
};
export type AuthorGuidAuthorFkAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorGuidAuthorFkInverseInput = {
  connectById?: ReadonlyArray<AuthorStatusNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<AuthorStatusAuthorStatusPkeyConnect> | null;
  create?: ReadonlyArray<AuthorGuidAuthorFkAuthorStatusCreateInput> | null;
  deleteById?: ReadonlyArray<AuthorStatusNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<AuthorStatusAuthorStatusPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnAuthorStatusForAuthorGuidAuthorFkNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<AuthorStatusOnAuthorStatusForAuthorGuidAuthorFkUsingAuthorStatusPkeyUpdate> | null;
};
export type AuthorGuidAuthorFkAuthorStatusCreateInput = {
  authorToAuthorGuid?: AuthorGuidAuthorFkInput | null;
  authorsUsingRowId?: StatusFkInverseInput | null;
  emoji?: string | null;
  message: string;
  rowId?: number | null;
  usedAt?: string | null;
};
export type StatusFkInverseInput = {
  connectByGuid?: ReadonlyArray<AuthorAuthorsPkeyConnect> | null;
  connectById?: ReadonlyArray<AuthorNodeIdConnect> | null;
  create?: ReadonlyArray<StatusFkAuthorsCreateInput> | null;
  deleteByGuid?: ReadonlyArray<AuthorAuthorsPkeyDelete> | null;
  deleteById?: ReadonlyArray<AuthorNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByGuid?: ReadonlyArray<AuthorOnAuthorForStatusFkUsingAuthorsPkeyUpdate> | null;
  updateById?: ReadonlyArray<AuthorStatusOnAuthorForStatusFkNodeIdUpdate> | null;
};
export type StatusFkAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorsUpdatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: AuthorsUpdatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnAuthorForAuthorsUpdatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: AuthorOnAuthorForAuthorsUpdatedByFkeyNodeIdUpdate | null;
};
export type AuthorsUpdatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type BoardsSectionsItemsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionsItemNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsItemsCreatedByFkeyBoardsSectionsItemsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionsItemNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyUsingBoardsSectionsItemsPkeyUpdate> | null;
};
export type BoardsSectionsItemNodeIdConnect = {
  id: string;
};
export type BoardsSectionsItemBoardsSectionsItemsPkeyConnect = {
  rowId: number;
};
export type BoardsSectionsItemsCreatedByFkeyBoardsSectionsItemsCreateInput = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemsArticleIdFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: BoardsSectionsItemsArticleIdFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyUsingArticlesPkeyUpdate | null;
};
export type BoardsSectionsItemsArticleIdFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type BoardsSectionsItemsArticleIdFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionsItemNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsItemsArticleIdFkeyBoardsSectionsItemsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionsItemNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ArticleOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyUsingBoardsSectionsItemsPkeyUpdate> | null;
};
export type BoardsSectionsItemsArticleIdFkeyBoardsSectionsItemsCreateInput = {
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemsCreatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsSectionsItemsCreatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyNodeIdUpdate | null;
};
export type BoardsSectionsItemsCreatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionsItemNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsItemsUpdatedByFkeyBoardsSectionsItemsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionsItemNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyUsingBoardsSectionsItemsPkeyUpdate> | null;
};
export type BoardsSectionsItemsUpdatedByFkeyBoardsSectionsItemsCreateInput = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
};
export type BoardsSectionsItemsUpdatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsSectionsItemsUpdatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyNodeIdUpdate | null;
};
export type BoardsSectionsItemsUpdatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type BoardsSectionsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsCreatedByFkeyBoardsSectionsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardsSectionForBoardsSectionsCreatedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionOnBoardsSectionForBoardsSectionsCreatedByFkeyUsingBoardsSectionsPkeyUpdate> | null;
};
export type BoardsSectionNodeIdConnect = {
  id: string;
};
export type BoardsSectionBoardsSectionsPkeyConnect = {
  rowId: string;
};
export type BoardsSectionsCreatedByFkeyBoardsSectionsCreateInput = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  meta?: any | null;
  order: number;
  rowId?: string | null;
  schema: BoardsSectionsSchema;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsCreatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsSectionsCreatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardsSectionForBoardsSectionsCreatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardsSectionOnBoardsSectionForBoardsSectionsCreatedByFkeyNodeIdUpdate | null;
};
export type BoardsSectionsCreatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsUpdatedByFkeyBoardsSectionsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardsSectionForBoardsSectionsUpdatedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionOnBoardsSectionForBoardsSectionsUpdatedByFkeyUsingBoardsSectionsPkeyUpdate> | null;
};
export type BoardsSectionsUpdatedByFkeyBoardsSectionsCreateInput = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order: number;
  rowId?: string | null;
  schema: BoardsSectionsSchema;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
};
export type BoardsSectionsUpdatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsSectionsUpdatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardsSectionForBoardsSectionsUpdatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardsSectionOnBoardsSectionForBoardsSectionsUpdatedByFkeyNodeIdUpdate | null;
};
export type BoardsSectionsUpdatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type AuthorCreateFkInverseInput = {
  connectById?: ReadonlyArray<BoardsTeaserNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsTeaserBoardsTeasersPkeyConnect> | null;
  create?: ReadonlyArray<AuthorCreateFkBoardsTeasersCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsTeaserNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsTeaserBoardsTeasersPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardsTeaserForAuthorCreateFkNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsTeaserOnBoardsTeaserForAuthorCreateFkUsingBoardsTeasersPkeyUpdate> | null;
};
export type BoardsTeaserNodeIdConnect = {
  id: string;
};
export type BoardsTeaserBoardsTeasersPkeyConnect = {
  rowId: string;
};
export type AuthorCreateFkBoardsTeasersCreateInput = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsTeasersLockedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsTeasersLockedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardsTeaserForBoardsTeasersLockedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardsTeaserOnBoardsTeaserForBoardsTeasersLockedByFkeyNodeIdUpdate | null;
};
export type BoardsTeasersLockedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsTeasersLockedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsTeaserNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsTeaserBoardsTeasersPkeyConnect> | null;
  create?: ReadonlyArray<BoardsTeasersLockedByFkeyBoardsTeasersCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsTeaserNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsTeaserBoardsTeasersPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardsTeaserForBoardsTeasersLockedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsTeaserOnBoardsTeaserForBoardsTeasersLockedByFkeyUsingBoardsTeasersPkeyUpdate> | null;
};
export type BoardsTeasersLockedByFkeyBoardsTeasersCreateInput = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorUpdateFkInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: AuthorUpdateFkAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardsTeaserForAuthorUpdateFkUsingAuthorsPkeyUpdate | null;
  updateById?: BoardsTeaserOnBoardsTeaserForAuthorUpdateFkNodeIdUpdate | null;
};
export type AuthorUpdateFkAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type AuthorUpdateFkInverseInput = {
  connectById?: ReadonlyArray<BoardsTeaserNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsTeaserBoardsTeasersPkeyConnect> | null;
  create?: ReadonlyArray<AuthorUpdateFkBoardsTeasersCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsTeaserNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsTeaserBoardsTeasersPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardsTeaserForAuthorUpdateFkNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsTeaserOnBoardsTeaserForAuthorUpdateFkUsingBoardsTeasersPkeyUpdate> | null;
};
export type AuthorUpdateFkBoardsTeasersCreateInput = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title: string;
  updatedAt?: string | null;
};
export type BoardsSectionsItemsTeaserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionsItemNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsItemsTeaserIdFkeyBoardsSectionsItemsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionsItemNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<BoardsTeaserOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyUsingBoardsSectionsItemsPkeyUpdate> | null;
};
export type BoardsSectionsItemsTeaserIdFkeyBoardsSectionsItemsCreateInput = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemsBoardIdFkeyInput = {
  connectById?: BoardNodeIdConnect | null;
  connectByParentIdAndSlug?: BoardBoardsParentIdSlugKeyConnect | null;
  connectByRowId?: BoardBoardsPkeyConnect | null;
  create?: BoardsSectionsItemsBoardIdFkeyBoardsCreateInput | null;
  deleteById?: BoardNodeIdDelete | null;
  deleteByParentIdAndSlug?: BoardBoardsParentIdSlugKeyDelete | null;
  deleteByRowId?: BoardBoardsPkeyDelete | null;
  updateById?: BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyNodeIdUpdate | null;
  updateByParentIdAndSlug?: BoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyUsingBoardsParentIdSlugKeyUpdate | null;
  updateByRowId?: BoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyUsingBoardsPkeyUpdate | null;
};
export type BoardNodeIdConnect = {
  id: string;
};
export type BoardBoardsParentIdSlugKeyConnect = {
  parentId: string;
  slug: string;
};
export type BoardBoardsPkeyConnect = {
  rowId: string;
};
export type BoardsSectionsItemsBoardIdFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardsCreatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsCreatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardForBoardsCreatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardOnBoardForBoardsCreatedByFkeyNodeIdUpdate | null;
};
export type BoardsCreatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsCreatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardNodeIdConnect> | null;
  connectByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyConnect> | null;
  connectByRowId?: ReadonlyArray<BoardBoardsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsCreatedByFkeyBoardsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardNodeIdDelete> | null;
  deleteByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardBoardsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardForBoardsCreatedByFkeyNodeIdUpdate> | null;
  updateByParentIdAndSlug?: ReadonlyArray<BoardOnBoardForBoardsCreatedByFkeyUsingBoardsParentIdSlugKeyUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardOnBoardForBoardsCreatedByFkeyUsingBoardsPkeyUpdate> | null;
};
export type BoardsCreatedByFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardsLockedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsLockedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardForBoardsLockedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardOnBoardForBoardsLockedByFkeyNodeIdUpdate | null;
};
export type BoardsLockedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsLockedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardNodeIdConnect> | null;
  connectByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyConnect> | null;
  connectByRowId?: ReadonlyArray<BoardBoardsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsLockedByFkeyBoardsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardNodeIdDelete> | null;
  deleteByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardBoardsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardForBoardsLockedByFkeyNodeIdUpdate> | null;
  updateByParentIdAndSlug?: ReadonlyArray<BoardOnBoardForBoardsLockedByFkeyUsingBoardsParentIdSlugKeyUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardOnBoardForBoardsLockedByFkeyUsingBoardsPkeyUpdate> | null;
};
export type BoardsLockedByFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardsUpdatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: BoardsUpdatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnBoardForBoardsUpdatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: BoardOnBoardForBoardsUpdatedByFkeyNodeIdUpdate | null;
};
export type BoardsUpdatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type BoardsUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardNodeIdConnect> | null;
  connectByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyConnect> | null;
  connectByRowId?: ReadonlyArray<BoardBoardsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsUpdatedByFkeyBoardsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardNodeIdDelete> | null;
  deleteByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardBoardsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnBoardForBoardsUpdatedByFkeyNodeIdUpdate> | null;
  updateByParentIdAndSlug?: ReadonlyArray<BoardOnBoardForBoardsUpdatedByFkeyUsingBoardsParentIdSlugKeyUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardOnBoardForBoardsUpdatedByFkeyUsingBoardsPkeyUpdate> | null;
};
export type BoardsUpdatedByFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  visitable?: boolean | null;
};
export type BoardsParentIdFkeyInput = {
  connectById?: BoardNodeIdConnect | null;
  connectByParentIdAndSlug?: BoardBoardsParentIdSlugKeyConnect | null;
  connectByRowId?: BoardBoardsPkeyConnect | null;
  create?: BoardsParentIdFkeyBoardsCreateInput | null;
  deleteById?: BoardNodeIdDelete | null;
  deleteByParentIdAndSlug?: BoardBoardsParentIdSlugKeyDelete | null;
  deleteByRowId?: BoardBoardsPkeyDelete | null;
  updateById?: BoardOnBoardForBoardsParentIdFkeyNodeIdUpdate | null;
  updateByParentIdAndSlug?: BoardOnBoardForBoardsParentIdFkeyUsingBoardsParentIdSlugKeyUpdate | null;
  updateByRowId?: BoardOnBoardForBoardsParentIdFkeyUsingBoardsPkeyUpdate | null;
};
export type BoardsParentIdFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardsSectionsItemsBoardIdFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionsItemNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsItemsBoardIdFkeyBoardsSectionsItemsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionsItemNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<BoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyUsingBoardsSectionsItemsPkeyUpdate> | null;
};
export type BoardsSectionsItemsBoardIdFkeyBoardsSectionsItemsCreateInput = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemsBoardsSectionIdFkeyInput = {
  connectById?: BoardsSectionNodeIdConnect | null;
  connectByRowId?: BoardsSectionBoardsSectionsPkeyConnect | null;
  create?: BoardsSectionsItemsBoardsSectionIdFkeyBoardsSectionsCreateInput | null;
  deleteById?: BoardsSectionNodeIdDelete | null;
  deleteByRowId?: BoardsSectionBoardsSectionsPkeyDelete | null;
  updateById?: BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyNodeIdUpdate | null;
  updateByRowId?: BoardsSectionOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyUsingBoardsSectionsPkeyUpdate | null;
};
export type BoardsSectionsItemsBoardsSectionIdFkeyBoardsSectionsCreateInput = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order: number;
  rowId?: string | null;
  schema: BoardsSectionsSchema;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsBoardIdFkeyInput = {
  connectById?: BoardNodeIdConnect | null;
  connectByParentIdAndSlug?: BoardBoardsParentIdSlugKeyConnect | null;
  connectByRowId?: BoardBoardsPkeyConnect | null;
  create?: BoardsSectionsBoardIdFkeyBoardsCreateInput | null;
  deleteById?: BoardNodeIdDelete | null;
  deleteByParentIdAndSlug?: BoardBoardsParentIdSlugKeyDelete | null;
  deleteByRowId?: BoardBoardsPkeyDelete | null;
  updateById?: BoardsSectionOnBoardsSectionForBoardsSectionsBoardIdFkeyNodeIdUpdate | null;
  updateByParentIdAndSlug?: BoardOnBoardsSectionForBoardsSectionsBoardIdFkeyUsingBoardsParentIdSlugKeyUpdate | null;
  updateByRowId?: BoardOnBoardsSectionForBoardsSectionsBoardIdFkeyUsingBoardsPkeyUpdate | null;
};
export type BoardsSectionsBoardIdFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardsSectionsBoardIdFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsBoardIdFkeyBoardsSectionsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<BoardOnBoardsSectionForBoardsSectionsBoardIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionOnBoardsSectionForBoardsSectionsBoardIdFkeyUsingBoardsSectionsPkeyUpdate> | null;
};
export type BoardsSectionsBoardIdFkeyBoardsSectionsCreateInput = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order: number;
  rowId?: string | null;
  schema: BoardsSectionsSchema;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsBoardLinkFkeyInput = {
  connectById?: BoardNodeIdConnect | null;
  connectByParentIdAndSlug?: BoardBoardsParentIdSlugKeyConnect | null;
  connectByRowId?: BoardBoardsPkeyConnect | null;
  create?: BoardsSectionsBoardLinkFkeyBoardsCreateInput | null;
  deleteById?: BoardNodeIdDelete | null;
  deleteByParentIdAndSlug?: BoardBoardsParentIdSlugKeyDelete | null;
  deleteByRowId?: BoardBoardsPkeyDelete | null;
  updateById?: BoardsSectionOnBoardsSectionForBoardsSectionsBoardLinkFkeyNodeIdUpdate | null;
  updateByParentIdAndSlug?: BoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyUsingBoardsParentIdSlugKeyUpdate | null;
  updateByRowId?: BoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyUsingBoardsPkeyUpdate | null;
};
export type BoardsSectionsBoardLinkFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardsSectionsBoardLinkFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsBoardLinkFkeyBoardsSectionsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<BoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionOnBoardsSectionForBoardsSectionsBoardLinkFkeyUsingBoardsSectionsPkeyUpdate> | null;
};
export type BoardsSectionsBoardLinkFkeyBoardsSectionsCreateInput = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order: number;
  rowId?: string | null;
  schema: BoardsSectionsSchema;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemsBoardsSectionIdFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionsItemNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsItemsBoardsSectionIdFkeyBoardsSectionsItemsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionsItemNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionsItemBoardsSectionsItemsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<BoardsSectionOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyUsingBoardsSectionsItemsPkeyUpdate> | null;
};
export type BoardsSectionsItemsBoardsSectionIdFkeyBoardsSectionsItemsCreateInput = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemsTeaserIdFkeyInput = {
  connectById?: BoardsTeaserNodeIdConnect | null;
  connectByRowId?: BoardsTeaserBoardsTeasersPkeyConnect | null;
  create?: BoardsSectionsItemsTeaserIdFkeyBoardsTeasersCreateInput | null;
  deleteById?: BoardsTeaserNodeIdDelete | null;
  deleteByRowId?: BoardsTeaserBoardsTeasersPkeyDelete | null;
  updateById?: BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyNodeIdUpdate | null;
  updateByRowId?: BoardsTeaserOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyUsingBoardsTeasersPkeyUpdate | null;
};
export type BoardsSectionsItemsTeaserIdFkeyBoardsTeasersCreateInput = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ModuleImageInput = {
  altText?: string | null;
  copyright?: string | null;
  title?: string | null;
  url?: string | null;
};
export type LinkInput = {
  label?: string | null;
  url?: string | null;
};
export type BoardsTeaserNodeIdDelete = {
  id: string;
};
export type BoardsTeaserBoardsTeasersPkeyDelete = {
  rowId: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyNodeIdUpdate = {
  boardsTeaserPatch: BoardsTeaserPatch;
  id: string;
};
export type BoardsTeaserOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyUsingBoardsTeasersPkeyUpdate = {
  boardsTeaserPatch: updateBoardsTeaserOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyPatch;
  rowId: string;
};
export type updateBoardsTeaserOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyPatch = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemNodeIdDelete = {
  id: string;
};
export type BoardsSectionsItemBoardsSectionsItemsPkeyDelete = {
  rowId: number;
};
export type BoardsSectionOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyNodeIdUpdate = {
  boardsSectionsItemPatch: BoardsSectionsItemPatch;
  id: string;
};
export type BoardsSectionsItemPatch = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyUsingBoardsSectionsItemsPkeyUpdate = {
  boardsSectionsItemPatch: updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyPatch;
  rowId: number;
};
export type updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsCategoryIdFkeyInput = {
  connectById?: CategoryNodeIdConnect | null;
  connectByRowId?: CategoryCategoryPkeyConnect | null;
  create?: BoardsSectionsCategoryIdFkeyCategoriesCreateInput | null;
  deleteById?: CategoryNodeIdDelete | null;
  deleteByRowId?: CategoryCategoryPkeyDelete | null;
  updateById?: BoardsSectionOnBoardsSectionForBoardsSectionsCategoryIdFkeyNodeIdUpdate | null;
  updateByRowId?: CategoryOnBoardsSectionForBoardsSectionsCategoryIdFkeyUsingCategoryPkeyUpdate | null;
};
export type CategoryNodeIdConnect = {
  id: string;
};
export type CategoryCategoryPkeyConnect = {
  rowId: string;
};
export type BoardsSectionsCategoryIdFkeyCategoriesCreateInput = {
  active: boolean;
  articlesUsingRowId?: ArticlesCategoryPrimaryFkeyInverseInput | null;
  boardsSectionsUsingRowId?: BoardsSectionsCategoryIdFkeyInverseInput | null;
  boardsUsingRowId?: BoardsCategoryIdFkeyInverseInput | null;
  color?: string | null;
  rowId: string;
  title: string;
};
export type ArticlesCategoryPrimaryFkeyInverseInput = {
  connectById?: ReadonlyArray<ArticleNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ArticleArticlesPkeyConnect> | null;
  create?: ReadonlyArray<ArticlesCategoryPrimaryFkeyArticlesCreateInput> | null;
  deleteById?: ReadonlyArray<ArticleNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ArticleArticlesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<CategoryOnArticleForArticlesCategoryPrimaryFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ArticleOnArticleForArticlesCategoryPrimaryFkeyUsingArticlesPkeyUpdate> | null;
};
export type ArticlesCategoryPrimaryFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ArticlesCategoryPrimaryFkeyInput = {
  connectById?: CategoryNodeIdConnect | null;
  connectByRowId?: CategoryCategoryPkeyConnect | null;
  create?: ArticlesCategoryPrimaryFkeyCategoriesCreateInput | null;
  deleteById?: CategoryNodeIdDelete | null;
  deleteByRowId?: CategoryCategoryPkeyDelete | null;
  updateById?: ArticleOnArticleForArticlesCategoryPrimaryFkeyNodeIdUpdate | null;
  updateByRowId?: CategoryOnArticleForArticlesCategoryPrimaryFkeyUsingCategoryPkeyUpdate | null;
};
export type ArticlesCategoryPrimaryFkeyCategoriesCreateInput = {
  active: boolean;
  articlesUsingRowId?: ArticlesCategoryPrimaryFkeyInverseInput | null;
  boardsSectionsUsingRowId?: BoardsSectionsCategoryIdFkeyInverseInput | null;
  boardsUsingRowId?: BoardsCategoryIdFkeyInverseInput | null;
  color?: string | null;
  rowId: string;
  title: string;
};
export type BoardsSectionsCategoryIdFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardsSectionNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsSectionsCategoryIdFkeyBoardsSectionsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardsSectionNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardsSectionBoardsSectionsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<CategoryOnBoardsSectionForBoardsSectionsCategoryIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardsSectionOnBoardsSectionForBoardsSectionsCategoryIdFkeyUsingBoardsSectionsPkeyUpdate> | null;
};
export type BoardsSectionsCategoryIdFkeyBoardsSectionsCreateInput = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order: number;
  rowId?: string | null;
  schema: BoardsSectionsSchema;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionNodeIdDelete = {
  id: string;
};
export type BoardsSectionBoardsSectionsPkeyDelete = {
  rowId: string;
};
export type CategoryOnBoardsSectionForBoardsSectionsCategoryIdFkeyNodeIdUpdate = {
  boardsSectionPatch: BoardsSectionPatch;
  id: string;
};
export type BoardsSectionPatch = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order?: number | null;
  rowId?: string | null;
  schema?: BoardsSectionsSchema | null;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsCategoryIdFkeyUsingBoardsSectionsPkeyUpdate = {
  boardsSectionPatch: updateBoardsSectionOnBoardsSectionForBoardsSectionsCategoryIdFkeyPatch;
  rowId: string;
};
export type updateBoardsSectionOnBoardsSectionForBoardsSectionsCategoryIdFkeyPatch = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order?: number | null;
  rowId?: string | null;
  schema?: BoardsSectionsSchema | null;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsCategoryIdFkeyInverseInput = {
  connectById?: ReadonlyArray<BoardNodeIdConnect> | null;
  connectByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyConnect> | null;
  connectByRowId?: ReadonlyArray<BoardBoardsPkeyConnect> | null;
  create?: ReadonlyArray<BoardsCategoryIdFkeyBoardsCreateInput> | null;
  deleteById?: ReadonlyArray<BoardNodeIdDelete> | null;
  deleteByParentIdAndSlug?: ReadonlyArray<BoardBoardsParentIdSlugKeyDelete> | null;
  deleteByRowId?: ReadonlyArray<BoardBoardsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<CategoryOnBoardForBoardsCategoryIdFkeyNodeIdUpdate> | null;
  updateByParentIdAndSlug?: ReadonlyArray<BoardOnBoardForBoardsCategoryIdFkeyUsingBoardsParentIdSlugKeyUpdate> | null;
  updateByRowId?: ReadonlyArray<BoardOnBoardForBoardsCategoryIdFkeyUsingBoardsPkeyUpdate> | null;
};
export type BoardsCategoryIdFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardsCategoryIdFkeyInput = {
  connectById?: CategoryNodeIdConnect | null;
  connectByRowId?: CategoryCategoryPkeyConnect | null;
  create?: BoardsCategoryIdFkeyCategoriesCreateInput | null;
  deleteById?: CategoryNodeIdDelete | null;
  deleteByRowId?: CategoryCategoryPkeyDelete | null;
  updateById?: BoardOnBoardForBoardsCategoryIdFkeyNodeIdUpdate | null;
  updateByRowId?: CategoryOnBoardForBoardsCategoryIdFkeyUsingCategoryPkeyUpdate | null;
};
export type BoardsCategoryIdFkeyCategoriesCreateInput = {
  active: boolean;
  articlesUsingRowId?: ArticlesCategoryPrimaryFkeyInverseInput | null;
  boardsSectionsUsingRowId?: BoardsSectionsCategoryIdFkeyInverseInput | null;
  boardsUsingRowId?: BoardsCategoryIdFkeyInverseInput | null;
  color?: string | null;
  rowId: string;
  title: string;
};
export type CategoryNodeIdDelete = {
  id: string;
};
export type CategoryCategoryPkeyDelete = {
  rowId: string;
};
export type BoardOnBoardForBoardsCategoryIdFkeyNodeIdUpdate = {
  categoryPatch: CategoryPatch;
  id: string;
};
export type CategoryPatch = {
  active?: boolean | null;
  articlesUsingRowId?: ArticlesCategoryPrimaryFkeyInverseInput | null;
  boardsSectionsUsingRowId?: BoardsSectionsCategoryIdFkeyInverseInput | null;
  boardsUsingRowId?: BoardsCategoryIdFkeyInverseInput | null;
  color?: string | null;
  rowId?: string | null;
  title?: string | null;
};
export type CategoryOnBoardForBoardsCategoryIdFkeyUsingCategoryPkeyUpdate = {
  categoryPatch: updateCategoryOnBoardForBoardsCategoryIdFkeyPatch;
  rowId: string;
};
export type updateCategoryOnBoardForBoardsCategoryIdFkeyPatch = {
  active?: boolean | null;
  articlesUsingRowId?: ArticlesCategoryPrimaryFkeyInverseInput | null;
  boardsSectionsUsingRowId?: BoardsSectionsCategoryIdFkeyInverseInput | null;
  boardsUsingRowId?: BoardsCategoryIdFkeyInverseInput | null;
  color?: string | null;
  rowId?: string | null;
  title?: string | null;
};
export type HistoryBoardIdFkeyInverseInput = {
  connectById?: ReadonlyArray<HistoryNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<HistoryHistoryPkeyConnect> | null;
  create?: ReadonlyArray<HistoryBoardIdFkeyHistoryCreateInput> | null;
  deleteById?: ReadonlyArray<HistoryNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<HistoryHistoryPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<BoardOnHistoryForHistoryBoardIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<HistoryOnHistoryForHistoryBoardIdFkeyUsingHistoryPkeyUpdate> | null;
};
export type HistoryNodeIdConnect = {
  id: string;
};
export type HistoryHistoryPkeyConnect = {
  rowId: number;
};
export type HistoryBoardIdFkeyHistoryCreateInput = {
  accomplishedBy?: string | null;
  articleId?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data: any;
  rowId?: number | null;
};
export type HistoryArticleIdFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: HistoryArticleIdFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: HistoryOnHistoryForHistoryArticleIdFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnHistoryForHistoryArticleIdFkeyUsingArticlesPkeyUpdate | null;
};
export type HistoryArticleIdFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type IntervalInput = {
  days?: number | null;
  hours?: number | null;
  minutes?: number | null;
  months?: number | null;
  seconds?: number | null;
  years?: number | null;
};
export type HistoryArticleIdFkeyInverseInput = {
  connectById?: ReadonlyArray<HistoryNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<HistoryHistoryPkeyConnect> | null;
  create?: ReadonlyArray<HistoryArticleIdFkeyHistoryCreateInput> | null;
  deleteById?: ReadonlyArray<HistoryNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<HistoryHistoryPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ArticleOnHistoryForHistoryArticleIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<HistoryOnHistoryForHistoryArticleIdFkeyUsingHistoryPkeyUpdate> | null;
};
export type HistoryArticleIdFkeyHistoryCreateInput = {
  accomplishedBy?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data: any;
  rowId?: number | null;
};
export type HistoryAccomplishedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: HistoryAccomplishedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnHistoryForHistoryAccomplishedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: HistoryOnHistoryForHistoryAccomplishedByFkeyNodeIdUpdate | null;
};
export type HistoryAccomplishedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ConfigUpdatedByFkeyInverseInput = {
  connectById?: ReadonlyArray<ConfigNodeIdConnect> | null;
  connectByKey?: ReadonlyArray<ConfigConfigPkeyConnect> | null;
  create?: ReadonlyArray<ConfigUpdatedByFkeyConfigCreateInput> | null;
  deleteById?: ReadonlyArray<ConfigNodeIdDelete> | null;
  deleteByKey?: ReadonlyArray<ConfigConfigPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnConfigForConfigUpdatedByFkeyNodeIdUpdate> | null;
  updateByKey?: ReadonlyArray<ConfigOnConfigForConfigUpdatedByFkeyUsingConfigPkeyUpdate> | null;
};
export type ConfigNodeIdConnect = {
  id: string;
};
export type ConfigConfigPkeyConnect = {
  key: string;
};
export type ConfigUpdatedByFkeyConfigCreateInput = {
  authorToUpdatedBy?: ConfigUpdatedByFkeyInput | null;
  key: string;
  updatedAt?: string | null;
  value?: any | null;
};
export type ConfigUpdatedByFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: ConfigUpdatedByFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnConfigForConfigUpdatedByFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: ConfigOnConfigForConfigUpdatedByFkeyNodeIdUpdate | null;
};
export type ConfigUpdatedByFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type HistoryAccomplishedByFkeyInverseInput = {
  connectById?: ReadonlyArray<HistoryNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<HistoryHistoryPkeyConnect> | null;
  create?: ReadonlyArray<HistoryAccomplishedByFkeyHistoryCreateInput> | null;
  deleteById?: ReadonlyArray<HistoryNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<HistoryHistoryPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnHistoryForHistoryAccomplishedByFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<HistoryOnHistoryForHistoryAccomplishedByFkeyUsingHistoryPkeyUpdate> | null;
};
export type HistoryAccomplishedByFkeyHistoryCreateInput = {
  articleId?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data: any;
  rowId?: number | null;
};
export type HistoryAuthorGuidFkeyInput = {
  connectByGuid?: AuthorAuthorsPkeyConnect | null;
  connectById?: AuthorNodeIdConnect | null;
  create?: HistoryAuthorGuidFkeyAuthorsCreateInput | null;
  deleteByGuid?: AuthorAuthorsPkeyDelete | null;
  deleteById?: AuthorNodeIdDelete | null;
  updateByGuid?: AuthorOnHistoryForHistoryAuthorGuidFkeyUsingAuthorsPkeyUpdate | null;
  updateById?: HistoryOnHistoryForHistoryAuthorGuidFkeyNodeIdUpdate | null;
};
export type HistoryAuthorGuidFkeyAuthorsCreateInput = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type HistoryAuthorGuidFkeyInverseInput = {
  connectById?: ReadonlyArray<HistoryNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<HistoryHistoryPkeyConnect> | null;
  create?: ReadonlyArray<HistoryAuthorGuidFkeyHistoryCreateInput> | null;
  deleteById?: ReadonlyArray<HistoryNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<HistoryHistoryPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<AuthorOnHistoryForHistoryAuthorGuidFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<HistoryOnHistoryForHistoryAuthorGuidFkeyUsingHistoryPkeyUpdate> | null;
};
export type HistoryAuthorGuidFkeyHistoryCreateInput = {
  accomplishedBy?: string | null;
  articleId?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data: any;
  rowId?: number | null;
};
export type HistoryBoardIdFkeyInput = {
  connectById?: BoardNodeIdConnect | null;
  connectByParentIdAndSlug?: BoardBoardsParentIdSlugKeyConnect | null;
  connectByRowId?: BoardBoardsPkeyConnect | null;
  create?: HistoryBoardIdFkeyBoardsCreateInput | null;
  deleteById?: BoardNodeIdDelete | null;
  deleteByParentIdAndSlug?: BoardBoardsParentIdSlugKeyDelete | null;
  deleteByRowId?: BoardBoardsPkeyDelete | null;
  updateById?: HistoryOnHistoryForHistoryBoardIdFkeyNodeIdUpdate | null;
  updateByParentIdAndSlug?: BoardOnHistoryForHistoryBoardIdFkeyUsingBoardsParentIdSlugKeyUpdate | null;
  updateByRowId?: BoardOnHistoryForHistoryBoardIdFkeyUsingBoardsPkeyUpdate | null;
};
export type HistoryBoardIdFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type NavigationBoardFkeyInverseInput = {
  connectById?: ReadonlyArray<NavigationNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<NavigationNavigationPkeyConnect> | null;
  create?: ReadonlyArray<NavigationBoardFkeyNavigationCreateInput> | null;
  deleteById?: ReadonlyArray<NavigationNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<NavigationNavigationPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<BoardOnNavigationForNavigationBoardFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<NavigationOnNavigationForNavigationBoardFkeyUsingNavigationPkeyUpdate> | null;
};
export type NavigationNodeIdConnect = {
  id: string;
};
export type NavigationNavigationPkeyConnect = {
  rowId: number;
};
export type NavigationBoardFkeyNavigationCreateInput = {
  article?: string | null;
  articleToArticle?: NavigationArticleFkeyInput | null;
  boardToBoard?: NavigationBoardFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  navigationToParent?: NavigationParentFkeyInput | null;
  order?: number | null;
  parent?: number | null;
  path?: string | null;
  rowId?: number | null;
  text?: string | null;
  type: NavigationType;
};
export type NavigationArticleFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: NavigationArticleFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: NavigationOnNavigationForNavigationArticleFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnNavigationForNavigationArticleFkeyUsingArticlesPkeyUpdate | null;
};
export type NavigationArticleFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ModulesArticleIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ModuleNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ModuleModulesPkeyConnect> | null;
  create?: ReadonlyArray<ModulesArticleIdFkeyModulesCreateInput> | null;
  deleteById?: ReadonlyArray<ModuleNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ModuleModulesPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ArticleOnModuleForModulesArticleIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ModuleOnModuleForModulesArticleIdFkeyUsingModulesPkeyUpdate> | null;
};
export type ModuleNodeIdConnect = {
  id: string;
};
export type ModuleModulesPkeyConnect = {
  rowId: number;
};
export type ModulesArticleIdFkeyModulesCreateInput = {
  articleToArticleId?: ModulesArticleIdFkeyInput | null;
  audio?: ModuleAudioInput | null;
  embed?: ModuleEmbedInput | null;
  gallery?: ReadonlyArray<ModuleImageInput | null> | null;
  image?: ModuleImageInput | null;
  livestream?: ModuleLivestreamInput | null;
  meta?: any | null;
  order?: number | null;
  rowId?: number | null;
  text?: string | null;
  type?: ModuleType | null;
  video?: ModuleVideoInput | null;
  video360?: ModuleVideo360Input | null;
};
export type ModulesArticleIdFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: ModulesArticleIdFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: ModuleOnModuleForModulesArticleIdFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnModuleForModulesArticleIdFkeyUsingArticlesPkeyUpdate | null;
};
export type ModulesArticleIdFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type NavigationArticleFkeyInverseInput = {
  connectById?: ReadonlyArray<NavigationNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<NavigationNavigationPkeyConnect> | null;
  create?: ReadonlyArray<NavigationArticleFkeyNavigationCreateInput> | null;
  deleteById?: ReadonlyArray<NavigationNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<NavigationNavigationPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ArticleOnNavigationForNavigationArticleFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<NavigationOnNavigationForNavigationArticleFkeyUsingNavigationPkeyUpdate> | null;
};
export type NavigationArticleFkeyNavigationCreateInput = {
  articleToArticle?: NavigationArticleFkeyInput | null;
  board?: string | null;
  boardToBoard?: NavigationBoardFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  navigationToParent?: NavigationParentFkeyInput | null;
  order?: number | null;
  parent?: number | null;
  path?: string | null;
  rowId?: number | null;
  text?: string | null;
  type: NavigationType;
};
export type NavigationBoardFkeyInput = {
  connectById?: BoardNodeIdConnect | null;
  connectByParentIdAndSlug?: BoardBoardsParentIdSlugKeyConnect | null;
  connectByRowId?: BoardBoardsPkeyConnect | null;
  create?: NavigationBoardFkeyBoardsCreateInput | null;
  deleteById?: BoardNodeIdDelete | null;
  deleteByParentIdAndSlug?: BoardBoardsParentIdSlugKeyDelete | null;
  deleteByRowId?: BoardBoardsPkeyDelete | null;
  updateById?: NavigationOnNavigationForNavigationBoardFkeyNodeIdUpdate | null;
  updateByParentIdAndSlug?: BoardOnNavigationForNavigationBoardFkeyUsingBoardsParentIdSlugKeyUpdate | null;
  updateByRowId?: BoardOnNavigationForNavigationBoardFkeyUsingBoardsPkeyUpdate | null;
};
export type NavigationBoardFkeyBoardsCreateInput = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description: string;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title: string;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type SocialMediaAccountInput = {
  accountName?: string | null;
  label?: string | null;
  service?: SocialMediaAccountService | null;
};
export type BoardNodeIdDelete = {
  id: string;
};
export type BoardBoardsParentIdSlugKeyDelete = {
  parentId: string;
  slug: string;
};
export type BoardBoardsPkeyDelete = {
  rowId: string;
};
export type NavigationOnNavigationForNavigationBoardFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnNavigationForNavigationBoardFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnNavigationForNavigationBoardFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnNavigationForNavigationBoardFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnNavigationForNavigationBoardFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnNavigationForNavigationBoardFkeyPatch;
  rowId: string;
};
export type NavigationParentFkeyInput = {
  connectById?: NavigationNodeIdConnect | null;
  connectByRowId?: NavigationNavigationPkeyConnect | null;
  create?: NavigationParentFkeyNavigationCreateInput | null;
  deleteById?: NavigationNodeIdDelete | null;
  deleteByRowId?: NavigationNavigationPkeyDelete | null;
  updateById?: NavigationOnNavigationForNavigationParentFkeyNodeIdUpdate | null;
  updateByRowId?: NavigationOnNavigationForNavigationParentFkeyUsingNavigationPkeyUpdate | null;
};
export type NavigationParentFkeyNavigationCreateInput = {
  article?: string | null;
  articleToArticle?: NavigationArticleFkeyInput | null;
  board?: string | null;
  boardToBoard?: NavigationBoardFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  navigationToParent?: NavigationParentFkeyInput | null;
  order?: number | null;
  path?: string | null;
  rowId?: number | null;
  text?: string | null;
  type: NavigationType;
};
export type NavigationNodeIdDelete = {
  id: string;
};
export type NavigationNavigationPkeyDelete = {
  rowId: number;
};
export type NavigationOnNavigationForNavigationParentFkeyNodeIdUpdate = {
  id: string;
  navigationPatch: NavigationPatch;
};
export type NavigationPatch = {
  article?: string | null;
  articleToArticle?: NavigationArticleFkeyInput | null;
  board?: string | null;
  boardToBoard?: NavigationBoardFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  navigationToParent?: NavigationParentFkeyInput | null;
  order?: number | null;
  parent?: number | null;
  path?: string | null;
  rowId?: number | null;
  text?: string | null;
  type?: NavigationType | null;
};
export type NavigationOnNavigationForNavigationParentFkeyUsingNavigationPkeyUpdate = {
  navigationPatch: updateNavigationOnNavigationForNavigationParentFkeyPatch;
  rowId: number;
};
export type updateNavigationOnNavigationForNavigationParentFkeyPatch = {
  article?: string | null;
  articleToArticle?: NavigationArticleFkeyInput | null;
  board?: string | null;
  boardToBoard?: NavigationBoardFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  navigationToParent?: NavigationParentFkeyInput | null;
  order?: number | null;
  path?: string | null;
  rowId?: number | null;
  text?: string | null;
  type?: NavigationType | null;
};
export type ArticleOnNavigationForNavigationArticleFkeyNodeIdUpdate = {
  id: string;
  navigationPatch: NavigationPatch;
};
export type NavigationOnNavigationForNavigationArticleFkeyUsingNavigationPkeyUpdate = {
  navigationPatch: updateNavigationOnNavigationForNavigationArticleFkeyPatch;
  rowId: number;
};
export type updateNavigationOnNavigationForNavigationArticleFkeyPatch = {
  articleToArticle?: NavigationArticleFkeyInput | null;
  board?: string | null;
  boardToBoard?: NavigationBoardFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  navigationToParent?: NavigationParentFkeyInput | null;
  order?: number | null;
  parent?: number | null;
  path?: string | null;
  rowId?: number | null;
  text?: string | null;
  type?: NavigationType | null;
};
export type OneSignalNotificationsArticleIdFkeyInverseInput = {
  connectById?: ReadonlyArray<OneSignalNotificationNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<OneSignalNotificationOneSignalNotificationsPkeyConnect> | null;
  create?: ReadonlyArray<OneSignalNotificationsArticleIdFkeyOneSignalNotificationsCreateInput> | null;
  deleteById?: ReadonlyArray<OneSignalNotificationNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<OneSignalNotificationOneSignalNotificationsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ArticleOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<OneSignalNotificationOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyUsingOneSignalNotificationsPkeyUpdate> | null;
};
export type OneSignalNotificationNodeIdConnect = {
  id: string;
};
export type OneSignalNotificationOneSignalNotificationsPkeyConnect = {
  rowId: number;
};
export type OneSignalNotificationsArticleIdFkeyOneSignalNotificationsCreateInput = {
  articleToArticleId?: OneSignalNotificationsArticleIdFkeyInput | null;
  categories?: ReadonlyArray<string | null> | null;
  createdAt?: string | null;
  districts?: ReadonlyArray<string | null> | null;
  isBreakingnews?: boolean | null;
  oneSignalId: string;
  pushType?: PushNotification | null;
  recipients: number;
  rowId?: number | null;
  title?: string | null;
};
export type OneSignalNotificationsArticleIdFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: OneSignalNotificationsArticleIdFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: OneSignalNotificationOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyUsingArticlesPkeyUpdate | null;
};
export type OneSignalNotificationsArticleIdFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type RedirectionsArticleIdFkeyInverseInput = {
  connectById?: ReadonlyArray<RedirectionNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<RedirectionRedirectionsPkeyConnect> | null;
  create?: ReadonlyArray<RedirectionsArticleIdFkeyRedirectionsCreateInput> | null;
  deleteById?: ReadonlyArray<RedirectionNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<RedirectionRedirectionsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ArticleOnRedirectionForRedirectionsArticleIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<RedirectionOnRedirectionForRedirectionsArticleIdFkeyUsingRedirectionsPkeyUpdate> | null;
};
export type RedirectionNodeIdConnect = {
  id: string;
};
export type RedirectionRedirectionsPkeyConnect = {
  rowId: number;
};
export type RedirectionsArticleIdFkeyRedirectionsCreateInput = {
  articleToArticleId?: RedirectionsArticleIdFkeyInput | null;
  createdAt?: string | null;
  rowId?: number | null;
  sophoraId: string;
};
export type RedirectionsArticleIdFkeyInput = {
  connectById?: ArticleNodeIdConnect | null;
  connectByRowId?: ArticleArticlesPkeyConnect | null;
  create?: RedirectionsArticleIdFkeyArticlesCreateInput | null;
  deleteById?: ArticleNodeIdDelete | null;
  deleteByRowId?: ArticleArticlesPkeyDelete | null;
  updateById?: RedirectionOnRedirectionForRedirectionsArticleIdFkeyNodeIdUpdate | null;
  updateByRowId?: ArticleOnRedirectionForRedirectionsArticleIdFkeyUsingArticlesPkeyUpdate | null;
};
export type RedirectionsArticleIdFkeyArticlesCreateInput = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability: Capability;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug: string;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title: string;
};
export type ArticleNodeIdDelete = {
  id: string;
};
export type ArticleArticlesPkeyDelete = {
  rowId: string;
};
export type RedirectionOnRedirectionForRedirectionsArticleIdFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticlePatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type ArticleOnRedirectionForRedirectionsArticleIdFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnRedirectionForRedirectionsArticleIdFkeyPatch;
  rowId: string;
};
export type updateArticleOnRedirectionForRedirectionsArticleIdFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type RedirectionNodeIdDelete = {
  id: string;
};
export type RedirectionRedirectionsPkeyDelete = {
  rowId: number;
};
export type ArticleOnRedirectionForRedirectionsArticleIdFkeyNodeIdUpdate = {
  id: string;
  redirectionPatch: RedirectionPatch;
};
export type RedirectionPatch = {
  articleId?: string | null;
  articleToArticleId?: RedirectionsArticleIdFkeyInput | null;
  createdAt?: string | null;
  rowId?: number | null;
  sophoraId?: string | null;
};
export type RedirectionOnRedirectionForRedirectionsArticleIdFkeyUsingRedirectionsPkeyUpdate = {
  redirectionPatch: updateRedirectionOnRedirectionForRedirectionsArticleIdFkeyPatch;
  rowId: number;
};
export type updateRedirectionOnRedirectionForRedirectionsArticleIdFkeyPatch = {
  articleToArticleId?: RedirectionsArticleIdFkeyInput | null;
  createdAt?: string | null;
  rowId?: number | null;
  sophoraId?: string | null;
};
export type OneSignalNotificationOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyPatch;
  rowId: string;
};
export type updateArticleOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type OneSignalNotificationNodeIdDelete = {
  id: string;
};
export type OneSignalNotificationOneSignalNotificationsPkeyDelete = {
  rowId: number;
};
export type ArticleOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyNodeIdUpdate = {
  id: string;
  oneSignalNotificationPatch: OneSignalNotificationPatch;
};
export type OneSignalNotificationPatch = {
  articleId?: string | null;
  articleToArticleId?: OneSignalNotificationsArticleIdFkeyInput | null;
  categories?: ReadonlyArray<string | null> | null;
  createdAt?: string | null;
  districts?: ReadonlyArray<string | null> | null;
  isBreakingnews?: boolean | null;
  oneSignalId?: string | null;
  pushType?: PushNotification | null;
  recipients?: number | null;
  rowId?: number | null;
  title?: string | null;
};
export type OneSignalNotificationOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyUsingOneSignalNotificationsPkeyUpdate = {
  oneSignalNotificationPatch: updateOneSignalNotificationOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyPatch;
  rowId: number;
};
export type updateOneSignalNotificationOnOneSignalNotificationForOneSignalNotificationsArticleIdFkeyPatch = {
  articleToArticleId?: OneSignalNotificationsArticleIdFkeyInput | null;
  categories?: ReadonlyArray<string | null> | null;
  createdAt?: string | null;
  districts?: ReadonlyArray<string | null> | null;
  isBreakingnews?: boolean | null;
  oneSignalId?: string | null;
  pushType?: PushNotification | null;
  recipients?: number | null;
  rowId?: number | null;
  title?: string | null;
};
export type ModuleOnModuleForModulesArticleIdFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnModuleForModulesArticleIdFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnModuleForModulesArticleIdFkeyPatch;
  rowId: string;
};
export type updateArticleOnModuleForModulesArticleIdFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type ModuleAudioInput = {
  copyright?: string | null;
  duration?: number | null;
  thumbnail?: ModuleImageInput | null;
  title?: string | null;
  url?: string | null;
};
export type ModuleEmbedInput = {
  altText?: string | null;
  service?: string | null;
  source?: string | null;
  thumbnail?: ModuleImageInput | null;
  url?: string | null;
};
export type ModuleLivestreamInput = {
  livestreamId?: string | null;
  programmeId?: string | null;
  thumbnail?: ModuleImageInput | null;
};
export type ModuleVideoInput = {
  copyright?: string | null;
  duration?: number | null;
  thumbnail?: ModuleImageInput | null;
  title?: string | null;
  url?: string | null;
};
export type ModuleVideo360Input = {
  copyright?: string | null;
  duration?: number | null;
  sources?: ReadonlyArray<VideoSourceInput | null> | null;
  thumbnail?: ModuleImageInput | null;
  title?: string | null;
};
export type VideoSourceInput = {
  resolution?: VideoResolution | null;
  url?: string | null;
};
export type ModuleNodeIdDelete = {
  id: string;
};
export type ModuleModulesPkeyDelete = {
  rowId: number;
};
export type ArticleOnModuleForModulesArticleIdFkeyNodeIdUpdate = {
  id: string;
  modulePatch: ModulePatch;
};
export type ModulePatch = {
  articleId?: string | null;
  articleToArticleId?: ModulesArticleIdFkeyInput | null;
  audio?: ModuleAudioInput | null;
  embed?: ModuleEmbedInput | null;
  gallery?: ReadonlyArray<ModuleImageInput | null> | null;
  image?: ModuleImageInput | null;
  livestream?: ModuleLivestreamInput | null;
  meta?: any | null;
  order?: number | null;
  rowId?: number | null;
  text?: string | null;
  type?: ModuleType | null;
  video?: ModuleVideoInput | null;
  video360?: ModuleVideo360Input | null;
};
export type ModuleOnModuleForModulesArticleIdFkeyUsingModulesPkeyUpdate = {
  modulePatch: updateModuleOnModuleForModulesArticleIdFkeyPatch;
  rowId: number;
};
export type updateModuleOnModuleForModulesArticleIdFkeyPatch = {
  articleToArticleId?: ModulesArticleIdFkeyInput | null;
  audio?: ModuleAudioInput | null;
  embed?: ModuleEmbedInput | null;
  gallery?: ReadonlyArray<ModuleImageInput | null> | null;
  image?: ModuleImageInput | null;
  livestream?: ModuleLivestreamInput | null;
  meta?: any | null;
  order?: number | null;
  rowId?: number | null;
  text?: string | null;
  type?: ModuleType | null;
  video?: ModuleVideoInput | null;
  video360?: ModuleVideo360Input | null;
};
export type NavigationOnNavigationForNavigationArticleFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnNavigationForNavigationArticleFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnNavigationForNavigationArticleFkeyPatch;
  rowId: string;
};
export type updateArticleOnNavigationForNavigationArticleFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type BoardOnNavigationForNavigationBoardFkeyNodeIdUpdate = {
  id: string;
  navigationPatch: NavigationPatch;
};
export type NavigationOnNavigationForNavigationBoardFkeyUsingNavigationPkeyUpdate = {
  navigationPatch: updateNavigationOnNavigationForNavigationBoardFkeyPatch;
  rowId: number;
};
export type updateNavigationOnNavigationForNavigationBoardFkeyPatch = {
  article?: string | null;
  articleToArticle?: NavigationArticleFkeyInput | null;
  boardToBoard?: NavigationBoardFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  navigationToParent?: NavigationParentFkeyInput | null;
  order?: number | null;
  parent?: number | null;
  path?: string | null;
  rowId?: number | null;
  text?: string | null;
  type?: NavigationType | null;
};
export type HistoryOnHistoryForHistoryBoardIdFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnHistoryForHistoryBoardIdFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnHistoryForHistoryBoardIdFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnHistoryForHistoryBoardIdFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnHistoryForHistoryBoardIdFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnHistoryForHistoryBoardIdFkeyPatch;
  rowId: string;
};
export type HistoryNodeIdDelete = {
  id: string;
};
export type HistoryHistoryPkeyDelete = {
  rowId: number;
};
export type AuthorOnHistoryForHistoryAuthorGuidFkeyNodeIdUpdate = {
  historyPatch: HistoryPatch;
  id: string;
};
export type HistoryPatch = {
  accomplishedBy?: string | null;
  articleId?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data?: any | null;
  rowId?: number | null;
};
export type HistoryOnHistoryForHistoryAuthorGuidFkeyUsingHistoryPkeyUpdate = {
  historyPatch: updateHistoryOnHistoryForHistoryAuthorGuidFkeyPatch;
  rowId: number;
};
export type updateHistoryOnHistoryForHistoryAuthorGuidFkeyPatch = {
  accomplishedBy?: string | null;
  articleId?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data?: any | null;
  rowId?: number | null;
};
export type AuthorAuthorsPkeyDelete = {
  guid: string;
};
export type AuthorNodeIdDelete = {
  id: string;
};
export type AuthorOnHistoryForHistoryAuthorGuidFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnHistoryForHistoryAuthorGuidFkeyPatch;
  guid: string;
};
export type updateAuthorOnHistoryForHistoryAuthorGuidFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type HistoryOnHistoryForHistoryAuthorGuidFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorOnHistoryForHistoryAccomplishedByFkeyNodeIdUpdate = {
  historyPatch: HistoryPatch;
  id: string;
};
export type HistoryOnHistoryForHistoryAccomplishedByFkeyUsingHistoryPkeyUpdate = {
  historyPatch: updateHistoryOnHistoryForHistoryAccomplishedByFkeyPatch;
  rowId: number;
};
export type updateHistoryOnHistoryForHistoryAccomplishedByFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data?: any | null;
  rowId?: number | null;
};
export type AuthorOnConfigForConfigUpdatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnConfigForConfigUpdatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnConfigForConfigUpdatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type ConfigOnConfigForConfigUpdatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type ConfigNodeIdDelete = {
  id: string;
};
export type ConfigConfigPkeyDelete = {
  key: string;
};
export type AuthorOnConfigForConfigUpdatedByFkeyNodeIdUpdate = {
  configPatch: ConfigPatch;
  id: string;
};
export type ConfigPatch = {
  authorToUpdatedBy?: ConfigUpdatedByFkeyInput | null;
  key?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  value?: any | null;
};
export type ConfigOnConfigForConfigUpdatedByFkeyUsingConfigPkeyUpdate = {
  configPatch: updateConfigOnConfigForConfigUpdatedByFkeyPatch;
  key: string;
};
export type updateConfigOnConfigForConfigUpdatedByFkeyPatch = {
  authorToUpdatedBy?: ConfigUpdatedByFkeyInput | null;
  key?: string | null;
  updatedAt?: string | null;
  value?: any | null;
};
export type AuthorOnHistoryForHistoryAccomplishedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnHistoryForHistoryAccomplishedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnHistoryForHistoryAccomplishedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type HistoryOnHistoryForHistoryAccomplishedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type ArticleOnHistoryForHistoryArticleIdFkeyNodeIdUpdate = {
  historyPatch: HistoryPatch;
  id: string;
};
export type HistoryOnHistoryForHistoryArticleIdFkeyUsingHistoryPkeyUpdate = {
  historyPatch: updateHistoryOnHistoryForHistoryArticleIdFkeyPatch;
  rowId: number;
};
export type updateHistoryOnHistoryForHistoryArticleIdFkeyPatch = {
  accomplishedBy?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data?: any | null;
  rowId?: number | null;
};
export type HistoryOnHistoryForHistoryArticleIdFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnHistoryForHistoryArticleIdFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnHistoryForHistoryArticleIdFkeyPatch;
  rowId: string;
};
export type updateArticleOnHistoryForHistoryArticleIdFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type BoardOnHistoryForHistoryBoardIdFkeyNodeIdUpdate = {
  historyPatch: HistoryPatch;
  id: string;
};
export type HistoryOnHistoryForHistoryBoardIdFkeyUsingHistoryPkeyUpdate = {
  historyPatch: updateHistoryOnHistoryForHistoryBoardIdFkeyPatch;
  rowId: number;
};
export type updateHistoryOnHistoryForHistoryBoardIdFkeyPatch = {
  accomplishedBy?: string | null;
  articleId?: string | null;
  articleToArticleId?: HistoryArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAccomplishedBy?: HistoryAccomplishedByFkeyInput | null;
  authorToAuthorGuid?: HistoryAuthorGuidFkeyInput | null;
  boardToBoardId?: HistoryBoardIdFkeyInput | null;
  createdAt?: string | null;
  data?: any | null;
  rowId?: number | null;
};
export type CategoryOnBoardForBoardsCategoryIdFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardForBoardsCategoryIdFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsCategoryIdFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardForBoardsCategoryIdFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardForBoardsCategoryIdFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsCategoryIdFkeyPatch;
  rowId: string;
};
export type ArticleOnArticleForArticlesCategoryPrimaryFkeyNodeIdUpdate = {
  categoryPatch: CategoryPatch;
  id: string;
};
export type CategoryOnArticleForArticlesCategoryPrimaryFkeyUsingCategoryPkeyUpdate = {
  categoryPatch: updateCategoryOnArticleForArticlesCategoryPrimaryFkeyPatch;
  rowId: string;
};
export type updateCategoryOnArticleForArticlesCategoryPrimaryFkeyPatch = {
  active?: boolean | null;
  articlesUsingRowId?: ArticlesCategoryPrimaryFkeyInverseInput | null;
  boardsSectionsUsingRowId?: BoardsSectionsCategoryIdFkeyInverseInput | null;
  boardsUsingRowId?: BoardsCategoryIdFkeyInverseInput | null;
  color?: string | null;
  rowId?: string | null;
  title?: string | null;
};
export type CategoryOnArticleForArticlesCategoryPrimaryFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnArticleForArticlesCategoryPrimaryFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnArticleForArticlesCategoryPrimaryFkeyPatch;
  rowId: string;
};
export type updateArticleOnArticleForArticlesCategoryPrimaryFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsCategoryIdFkeyNodeIdUpdate = {
  categoryPatch: CategoryPatch;
  id: string;
};
export type CategoryOnBoardsSectionForBoardsSectionsCategoryIdFkeyUsingCategoryPkeyUpdate = {
  categoryPatch: updateCategoryOnBoardsSectionForBoardsSectionsCategoryIdFkeyPatch;
  rowId: string;
};
export type updateCategoryOnBoardsSectionForBoardsSectionsCategoryIdFkeyPatch = {
  active?: boolean | null;
  articlesUsingRowId?: ArticlesCategoryPrimaryFkeyInverseInput | null;
  boardsSectionsUsingRowId?: BoardsSectionsCategoryIdFkeyInverseInput | null;
  boardsUsingRowId?: BoardsCategoryIdFkeyInverseInput | null;
  color?: string | null;
  rowId?: string | null;
  title?: string | null;
};
export type BoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyNodeIdUpdate = {
  boardsSectionPatch: BoardsSectionPatch;
  id: string;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsBoardLinkFkeyUsingBoardsSectionsPkeyUpdate = {
  boardsSectionPatch: updateBoardsSectionOnBoardsSectionForBoardsSectionsBoardLinkFkeyPatch;
  rowId: string;
};
export type updateBoardsSectionOnBoardsSectionForBoardsSectionsBoardLinkFkeyPatch = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order?: number | null;
  rowId?: string | null;
  schema?: BoardsSectionsSchema | null;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsBoardLinkFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardsSectionForBoardsSectionsBoardLinkFkeyPatch;
  rowId: string;
};
export type BoardOnBoardsSectionForBoardsSectionsBoardIdFkeyNodeIdUpdate = {
  boardsSectionPatch: BoardsSectionPatch;
  id: string;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsBoardIdFkeyUsingBoardsSectionsPkeyUpdate = {
  boardsSectionPatch: updateBoardsSectionOnBoardsSectionForBoardsSectionsBoardIdFkeyPatch;
  rowId: string;
};
export type updateBoardsSectionOnBoardsSectionForBoardsSectionsBoardIdFkeyPatch = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order?: number | null;
  rowId?: string | null;
  schema?: BoardsSectionsSchema | null;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsBoardIdFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardsSectionForBoardsSectionsBoardIdFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardsSectionForBoardsSectionsBoardIdFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardsSectionForBoardsSectionsBoardIdFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardsSectionForBoardsSectionsBoardIdFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardsSectionForBoardsSectionsBoardIdFkeyPatch;
  rowId: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyNodeIdUpdate = {
  boardsSectionPatch: BoardsSectionPatch;
  id: string;
};
export type BoardsSectionOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyUsingBoardsSectionsPkeyUpdate = {
  boardsSectionPatch: updateBoardsSectionOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyPatch;
  rowId: string;
};
export type updateBoardsSectionOnBoardsSectionsItemForBoardsSectionsItemsBoardsSectionIdFkeyPatch = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order?: number | null;
  rowId?: string | null;
  schema?: BoardsSectionsSchema | null;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyNodeIdUpdate = {
  boardsSectionsItemPatch: BoardsSectionsItemPatch;
  id: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyUsingBoardsSectionsItemsPkeyUpdate = {
  boardsSectionsItemPatch: updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyPatch;
  rowId: number;
};
export type updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardOnBoardForBoardsParentIdFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardForBoardsParentIdFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsParentIdFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardForBoardsParentIdFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardForBoardsParentIdFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsParentIdFkeyPatch;
  rowId: string;
};
export type AuthorOnBoardForBoardsUpdatedByFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardForBoardsUpdatedByFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsUpdatedByFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardForBoardsUpdatedByFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardForBoardsUpdatedByFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsUpdatedByFkeyPatch;
  rowId: string;
};
export type AuthorOnBoardForBoardsUpdatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardForBoardsUpdatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardForBoardsUpdatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type BoardOnBoardForBoardsUpdatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnBoardForBoardsLockedByFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardForBoardsLockedByFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsLockedByFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardForBoardsLockedByFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardForBoardsLockedByFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsLockedByFkeyPatch;
  rowId: string;
};
export type AuthorOnBoardForBoardsLockedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardForBoardsLockedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardForBoardsLockedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardOnBoardForBoardsLockedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnBoardForBoardsCreatedByFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardForBoardsCreatedByFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsCreatedByFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardForBoardsCreatedByFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardForBoardsCreatedByFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardForBoardsCreatedByFkeyPatch;
  rowId: string;
};
export type AuthorOnBoardForBoardsCreatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardForBoardsCreatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardForBoardsCreatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardOnBoardForBoardsCreatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyNodeIdUpdate = {
  boardPatch: BoardPatch;
  id: string;
};
export type BoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyUsingBoardsParentIdSlugKeyUpdate = {
  boardPatch: updateBoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyPatch;
  parentId: string;
  slug: string;
};
export type updateBoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyPatch = {
  authorToCreatedBy?: BoardsCreatedByFkeyInput | null;
  authorToLockedBy?: BoardsLockedByFkeyInput | null;
  authorToUpdatedBy?: BoardsUpdatedByFkeyInput | null;
  boardToParentId?: BoardsParentIdFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardIdUsingRowId?: BoardsSectionsBoardIdFkeyInverseInput | null;
  boardsSectionsToBoardLinkUsingRowId?: BoardsSectionsBoardLinkFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsCategoryIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  hasIntroductionText?: boolean | null;
  historiesUsingRowId?: HistoryBoardIdFkeyInverseInput | null;
  image?: ModuleImageInput | null;
  introductionHeadline?: string | null;
  introductionText?: string | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  navigationsUsingRowId?: NavigationBoardFkeyInverseInput | null;
  noindex?: boolean | null;
  parentId?: string | null;
  protected?: boolean | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  startpage?: boolean | null;
  status?: Status | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  visitable?: boolean | null;
};
export type BoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyUsingBoardsPkeyUpdate = {
  boardPatch: updateBoardOnBoardsSectionsItemForBoardsSectionsItemsBoardIdFkeyPatch;
  rowId: string;
};
export type BoardsTeaserOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyNodeIdUpdate = {
  boardsSectionsItemPatch: BoardsSectionsItemPatch;
  id: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyUsingBoardsSectionsItemsPkeyUpdate = {
  boardsSectionsItemPatch: updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyPatch;
  rowId: number;
};
export type updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsTeaserIdFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorOnBoardsTeaserForAuthorUpdateFkNodeIdUpdate = {
  boardsTeaserPatch: BoardsTeaserPatch;
  id: string;
};
export type BoardsTeaserOnBoardsTeaserForAuthorUpdateFkUsingBoardsTeasersPkeyUpdate = {
  boardsTeaserPatch: updateBoardsTeaserOnBoardsTeaserForAuthorUpdateFkPatch;
  rowId: string;
};
export type updateBoardsTeaserOnBoardsTeaserForAuthorUpdateFkPatch = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title?: string | null;
  updatedAt?: string | null;
};
export type AuthorOnBoardsTeaserForAuthorUpdateFkUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardsTeaserForAuthorUpdateFkPatch;
  guid: string;
};
export type updateAuthorOnBoardsTeaserForAuthorUpdateFkPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type BoardsTeaserOnBoardsTeaserForAuthorUpdateFkNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnBoardsTeaserForBoardsTeasersLockedByFkeyNodeIdUpdate = {
  boardsTeaserPatch: BoardsTeaserPatch;
  id: string;
};
export type BoardsTeaserOnBoardsTeaserForBoardsTeasersLockedByFkeyUsingBoardsTeasersPkeyUpdate = {
  boardsTeaserPatch: updateBoardsTeaserOnBoardsTeaserForBoardsTeasersLockedByFkeyPatch;
  rowId: string;
};
export type updateBoardsTeaserOnBoardsTeaserForBoardsTeasersLockedByFkeyPatch = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorOnBoardsTeaserForBoardsTeasersLockedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardsTeaserForBoardsTeasersLockedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardsTeaserForBoardsTeasersLockedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsTeaserOnBoardsTeaserForBoardsTeasersLockedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnBoardsTeaserForAuthorCreateFkNodeIdUpdate = {
  boardsTeaserPatch: BoardsTeaserPatch;
  id: string;
};
export type BoardsTeaserOnBoardsTeaserForAuthorCreateFkUsingBoardsTeasersPkeyUpdate = {
  boardsTeaserPatch: updateBoardsTeaserOnBoardsTeaserForAuthorCreateFkPatch;
  rowId: string;
};
export type updateBoardsTeaserOnBoardsTeaserForAuthorCreateFkPatch = {
  authorToCreatedBy?: AuthorCreateFkInput | null;
  authorToLockedBy?: BoardsTeasersLockedByFkeyInput | null;
  authorToUpdatedBy?: AuthorUpdateFkInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsTeaserIdFkeyInverseInput | null;
  createdAt?: string | null;
  description?: string | null;
  image?: ModuleImageInput | null;
  link?: LinkInput | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  rowId?: string | null;
  status?: BoardsTeaserStatus | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorOnBoardsSectionForBoardsSectionsUpdatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardsSectionForBoardsSectionsUpdatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardsSectionForBoardsSectionsUpdatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsUpdatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnBoardsSectionForBoardsSectionsUpdatedByFkeyNodeIdUpdate = {
  boardsSectionPatch: BoardsSectionPatch;
  id: string;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsUpdatedByFkeyUsingBoardsSectionsPkeyUpdate = {
  boardsSectionPatch: updateBoardsSectionOnBoardsSectionForBoardsSectionsUpdatedByFkeyPatch;
  rowId: string;
};
export type updateBoardsSectionOnBoardsSectionForBoardsSectionsUpdatedByFkeyPatch = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  meta?: any | null;
  order?: number | null;
  rowId?: string | null;
  schema?: BoardsSectionsSchema | null;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
};
export type AuthorOnBoardsSectionForBoardsSectionsCreatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardsSectionForBoardsSectionsCreatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardsSectionForBoardsSectionsCreatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsCreatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnBoardsSectionForBoardsSectionsCreatedByFkeyNodeIdUpdate = {
  boardsSectionPatch: BoardsSectionPatch;
  id: string;
};
export type BoardsSectionOnBoardsSectionForBoardsSectionsCreatedByFkeyUsingBoardsSectionsPkeyUpdate = {
  boardsSectionPatch: updateBoardsSectionOnBoardsSectionForBoardsSectionsCreatedByFkeyPatch;
  rowId: string;
};
export type updateBoardsSectionOnBoardsSectionForBoardsSectionsCreatedByFkeyPatch = {
  authorToCreatedBy?: BoardsSectionsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsUpdatedByFkeyInput | null;
  autofill?: boolean | null;
  boardId?: string | null;
  boardLink?: string | null;
  boardToBoardId?: BoardsSectionsBoardIdFkeyInput | null;
  boardToBoardLink?: BoardsSectionsBoardLinkFkeyInput | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsBoardsSectionIdFkeyInverseInput | null;
  categoryId?: string | null;
  categoryToCategoryId?: BoardsSectionsCategoryIdFkeyInput | null;
  color?: string | null;
  createdAt?: string | null;
  meta?: any | null;
  order?: number | null;
  rowId?: string | null;
  schema?: BoardsSectionsSchema | null;
  showHeroTitleForMobile?: boolean | null;
  title?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyNodeIdUpdate = {
  boardsSectionsItemPatch: BoardsSectionsItemPatch;
  id: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyUsingBoardsSectionsItemsPkeyUpdate = {
  boardsSectionsItemPatch: updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyPatch;
  rowId: number;
};
export type updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsUpdatedByFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
};
export type AuthorOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type ArticleOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyNodeIdUpdate = {
  boardsSectionsItemPatch: BoardsSectionsItemPatch;
  id: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyUsingBoardsSectionsItemsPkeyUpdate = {
  boardsSectionsItemPatch: updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyPatch;
  rowId: number;
};
export type updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyPatch = {
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyPatch;
  rowId: string;
};
export type updateArticleOnBoardsSectionsItemForBoardsSectionsItemsArticleIdFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type AuthorOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyNodeIdUpdate = {
  boardsSectionsItemPatch: BoardsSectionsItemPatch;
  id: string;
};
export type BoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyUsingBoardsSectionsItemsPkeyUpdate = {
  boardsSectionsItemPatch: updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyPatch;
  rowId: number;
};
export type updateBoardsSectionsItemOnBoardsSectionsItemForBoardsSectionsItemsCreatedByFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: BoardsSectionsItemsArticleIdFkeyInput | null;
  authorToCreatedBy?: BoardsSectionsItemsCreatedByFkeyInput | null;
  authorToUpdatedBy?: BoardsSectionsItemsUpdatedByFkeyInput | null;
  boardId?: string | null;
  boardToBoardId?: BoardsSectionsItemsBoardIdFkeyInput | null;
  boardsSectionId?: string | null;
  boardsSectionToBoardsSectionId?: BoardsSectionsItemsBoardsSectionIdFkeyInput | null;
  boardsTeaserId?: string | null;
  boardsTeaserToBoardsTeaserId?: BoardsSectionsItemsTeaserIdFkeyInput | null;
  createdAt?: string | null;
  order?: number | null;
  rowId?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorOnAuthorForAuthorsUpdatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnAuthorForAuthorsUpdatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnAuthorForAuthorsUpdatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
};
export type AuthorOnAuthorForAuthorsUpdatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnAuthorForStatusFkUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnAuthorForStatusFkPatch;
  guid: string;
};
export type updateAuthorOnAuthorForStatusFkPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorStatusOnAuthorForStatusFkNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorStatusNodeIdDelete = {
  id: string;
};
export type AuthorStatusAuthorStatusPkeyDelete = {
  rowId: number;
};
export type AuthorOnAuthorStatusForAuthorGuidAuthorFkNodeIdUpdate = {
  authorStatusPatch: AuthorStatusPatch;
  id: string;
};
export type AuthorStatusPatch = {
  authorGuid?: string | null;
  authorToAuthorGuid?: AuthorGuidAuthorFkInput | null;
  authorsUsingRowId?: StatusFkInverseInput | null;
  emoji?: string | null;
  message?: string | null;
  rowId?: number | null;
  usedAt?: string | null;
};
export type AuthorStatusOnAuthorStatusForAuthorGuidAuthorFkUsingAuthorStatusPkeyUpdate = {
  authorStatusPatch: updateAuthorStatusOnAuthorStatusForAuthorGuidAuthorFkPatch;
  rowId: number;
};
export type updateAuthorStatusOnAuthorStatusForAuthorGuidAuthorFkPatch = {
  authorToAuthorGuid?: AuthorGuidAuthorFkInput | null;
  authorsUsingRowId?: StatusFkInverseInput | null;
  emoji?: string | null;
  message?: string | null;
  rowId?: number | null;
  usedAt?: string | null;
};
export type AuthorOnAuthorStatusForAuthorGuidAuthorFkUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnAuthorStatusForAuthorGuidAuthorFkPatch;
  guid: string;
};
export type updateAuthorOnAuthorStatusForAuthorGuidAuthorFkPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type AuthorStatusOnAuthorStatusForAuthorGuidAuthorFkNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnAuthorForStatusFkNodeIdUpdate = {
  authorStatusPatch: AuthorStatusPatch;
  id: string;
};
export type AuthorStatusOnAuthorForStatusFkUsingAuthorStatusPkeyUpdate = {
  authorStatusPatch: updateAuthorStatusOnAuthorForStatusFkPatch;
  rowId: number;
};
export type updateAuthorStatusOnAuthorForStatusFkPatch = {
  authorGuid?: string | null;
  authorToAuthorGuid?: AuthorGuidAuthorFkInput | null;
  authorsUsingRowId?: StatusFkInverseInput | null;
  emoji?: string | null;
  message?: string | null;
  rowId?: number | null;
  usedAt?: string | null;
};
export type AuthorOnArticleForArticlesRevisionByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnArticleForArticlesRevisionByFkeyPatch;
  guid: string;
};
export type updateAuthorOnArticleForArticlesRevisionByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticleOnArticleForArticlesRevisionByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnArticleForArticlesRevisionByFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnArticleForArticlesRevisionByFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnArticleForArticlesRevisionByFkeyPatch;
  rowId: string;
};
export type updateArticleOnArticleForArticlesRevisionByFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type AuthorOnArticleForArticlesLockedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnArticleForArticlesLockedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnArticleForArticlesLockedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticleOnArticleForArticlesLockedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type AuthorOnArticleForArticlesLockedByFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnArticleForArticlesLockedByFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnArticleForArticlesLockedByFkeyPatch;
  rowId: string;
};
export type updateArticleOnArticleForArticlesLockedByFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type AuthorOnArticleForArticlesCreatedByFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnArticleForArticlesCreatedByFkeyPatch;
  guid: string;
};
export type updateAuthorOnArticleForArticlesCreatedByFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticleOnArticleForArticlesCreatedByFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type ArticlesTagOnArticlesTagForArticlesTagsArticleIdFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnArticlesTagForArticlesTagsArticleIdFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnArticlesTagForArticlesTagsArticleIdFkeyPatch;
  rowId: string;
};
export type updateArticleOnArticlesTagForArticlesTagsArticleIdFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type ArticlesTagsTagIdFkeyInput = {
  connectById?: TagNodeIdConnect | null;
  connectByRowId?: TagTagsPkeyConnect | null;
  connectByText?: TagTagsTextKeyConnect | null;
  create?: ArticlesTagsTagIdFkeyTagsCreateInput | null;
  deleteById?: TagNodeIdDelete | null;
  deleteByRowId?: TagTagsPkeyDelete | null;
  deleteByText?: TagTagsTextKeyDelete | null;
  updateById?: ArticlesTagOnArticlesTagForArticlesTagsTagIdFkeyNodeIdUpdate | null;
  updateByRowId?: TagOnArticlesTagForArticlesTagsTagIdFkeyUsingTagsPkeyUpdate | null;
  updateByText?: TagOnArticlesTagForArticlesTagsTagIdFkeyUsingTagsTextKeyUpdate | null;
};
export type TagNodeIdConnect = {
  id: string;
};
export type TagTagsPkeyConnect = {
  rowId: number;
};
export type TagTagsTextKeyConnect = {
  text: string;
};
export type ArticlesTagsTagIdFkeyTagsCreateInput = {
  articlesTagsUsingRowId?: ArticlesTagsTagIdFkeyInverseInput | null;
  count?: number | null;
  parentTagId?: number | null;
  rowId?: number | null;
  status?: TagsStatus | null;
  tagToParentTagId?: TagsParentTagIdFkeyInput | null;
  text: string;
};
export type ArticlesTagsTagIdFkeyInverseInput = {
  connectByArticleIdAndTagId?: ReadonlyArray<ArticlesTagArticlesTagsArticleIdTagIdKeyConnect> | null;
  create?: ReadonlyArray<ArticlesTagsTagIdFkeyArticlesTagsCreateInput> | null;
  updateByArticleIdAndTagId?: ReadonlyArray<ArticlesTagOnArticlesTagForArticlesTagsTagIdFkeyUsingArticlesTagsArticleIdTagIdKeyUpdate> | null;
};
export type ArticlesTagsTagIdFkeyArticlesTagsCreateInput = {
  articleId?: string | null;
  articleToArticleId?: ArticlesTagsArticleIdFkeyInput | null;
  tagToTagId?: ArticlesTagsTagIdFkeyInput | null;
};
export type ArticlesTagOnArticlesTagForArticlesTagsTagIdFkeyUsingArticlesTagsArticleIdTagIdKeyUpdate = {
  articleId: string;
  articlesTagPatch: updateArticlesTagOnArticlesTagForArticlesTagsTagIdFkeyPatch;
  tagId: number;
};
export type updateArticlesTagOnArticlesTagForArticlesTagsTagIdFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: ArticlesTagsArticleIdFkeyInput | null;
  tagToTagId?: ArticlesTagsTagIdFkeyInput | null;
};
export type TagsParentTagIdFkeyInput = {
  connectById?: TagNodeIdConnect | null;
  connectByRowId?: TagTagsPkeyConnect | null;
  connectByText?: TagTagsTextKeyConnect | null;
  create?: TagsParentTagIdFkeyTagsCreateInput | null;
  deleteById?: TagNodeIdDelete | null;
  deleteByRowId?: TagTagsPkeyDelete | null;
  deleteByText?: TagTagsTextKeyDelete | null;
  updateById?: TagOnTagForTagsParentTagIdFkeyNodeIdUpdate | null;
  updateByRowId?: TagOnTagForTagsParentTagIdFkeyUsingTagsPkeyUpdate | null;
  updateByText?: TagOnTagForTagsParentTagIdFkeyUsingTagsTextKeyUpdate | null;
};
export type TagsParentTagIdFkeyTagsCreateInput = {
  articlesTagsUsingRowId?: ArticlesTagsTagIdFkeyInverseInput | null;
  count?: number | null;
  rowId?: number | null;
  status?: TagsStatus | null;
  tagToParentTagId?: TagsParentTagIdFkeyInput | null;
  text: string;
};
export type TagNodeIdDelete = {
  id: string;
};
export type TagTagsPkeyDelete = {
  rowId: number;
};
export type TagTagsTextKeyDelete = {
  text: string;
};
export type TagOnTagForTagsParentTagIdFkeyNodeIdUpdate = {
  id: string;
  tagPatch: TagPatch;
};
export type TagPatch = {
  articlesTagsUsingRowId?: ArticlesTagsTagIdFkeyInverseInput | null;
  count?: number | null;
  parentTagId?: number | null;
  rowId?: number | null;
  status?: TagsStatus | null;
  tagToParentTagId?: TagsParentTagIdFkeyInput | null;
  text?: string | null;
};
export type TagOnTagForTagsParentTagIdFkeyUsingTagsPkeyUpdate = {
  rowId: number;
  tagPatch: updateTagOnTagForTagsParentTagIdFkeyPatch;
};
export type updateTagOnTagForTagsParentTagIdFkeyPatch = {
  articlesTagsUsingRowId?: ArticlesTagsTagIdFkeyInverseInput | null;
  count?: number | null;
  rowId?: number | null;
  status?: TagsStatus | null;
  tagToParentTagId?: TagsParentTagIdFkeyInput | null;
  text?: string | null;
};
export type TagOnTagForTagsParentTagIdFkeyUsingTagsTextKeyUpdate = {
  tagPatch: updateTagOnTagForTagsParentTagIdFkeyPatch;
  text: string;
};
export type ArticlesTagOnArticlesTagForArticlesTagsTagIdFkeyNodeIdUpdate = {
  id: string;
  tagPatch: TagPatch;
};
export type TagOnArticlesTagForArticlesTagsTagIdFkeyUsingTagsPkeyUpdate = {
  rowId: number;
  tagPatch: updateTagOnArticlesTagForArticlesTagsTagIdFkeyPatch;
};
export type updateTagOnArticlesTagForArticlesTagsTagIdFkeyPatch = {
  articlesTagsUsingRowId?: ArticlesTagsTagIdFkeyInverseInput | null;
  count?: number | null;
  parentTagId?: number | null;
  rowId?: number | null;
  status?: TagsStatus | null;
  tagToParentTagId?: TagsParentTagIdFkeyInput | null;
  text?: string | null;
};
export type TagOnArticlesTagForArticlesTagsTagIdFkeyUsingTagsTextKeyUpdate = {
  tagPatch: updateTagOnArticlesTagForArticlesTagsTagIdFkeyPatch;
  text: string;
};
export type ArticlesTagOnArticlesTagForArticlesTagsArticleIdFkeyUsingArticlesTagsArticleIdTagIdKeyUpdate = {
  articleId: string;
  articlesTagPatch: updateArticlesTagOnArticlesTagForArticlesTagsArticleIdFkeyPatch;
  tagId: number;
};
export type updateArticlesTagOnArticlesTagForArticlesTagsArticleIdFkeyPatch = {
  articleToArticleId?: ArticlesTagsArticleIdFkeyInput | null;
  tagId?: number | null;
  tagToTagId?: ArticlesTagsTagIdFkeyInput | null;
};
export type AuthorOnArticleForArticlesCreatedByFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnArticleForArticlesCreatedByFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnArticleForArticlesCreatedByFkeyPatch;
  rowId: string;
};
export type updateArticleOnArticleForArticlesCreatedByFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type AuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyPatch;
  guid: string;
};
export type updateAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type ArticlesAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type ArticlesAuthorArticlesAuthorsPkeyDelete = {
  articleId: string;
  authorGuid: string;
};
export type ArticlesAuthorNodeIdDelete = {
  id: string;
};
export type ArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyUsingArticlesAuthorsPkeyUpdate = {
  articleId: string;
  articlesAuthorPatch: updateArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyPatch;
  authorGuid: string;
};
export type updateArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyPatch = {
  articleToArticleId?: ArticlesAuthorsArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAuthorGuid?: ArticlesAuthorsAuthorIdFkeyInput | null;
  order?: number | null;
};
export type ArticleOnArticlesAuthorForArticlesAuthorsArticleIdFkeyNodeIdUpdate = {
  articlesAuthorPatch: ArticlesAuthorPatch;
  id: string;
};
export type ArticlesAuthorPatch = {
  articleId?: string | null;
  articleToArticleId?: ArticlesAuthorsArticleIdFkeyInput | null;
  authorGuid?: string | null;
  authorToAuthorGuid?: ArticlesAuthorsAuthorIdFkeyInput | null;
  order?: number | null;
};
export type ArticlesAuthorOnArticlesAuthorForArticlesAuthorsArticleIdFkeyNodeIdUpdate = {
  articlePatch: ArticlePatch;
  id: string;
};
export type ArticleOnArticlesAuthorForArticlesAuthorsArticleIdFkeyUsingArticlesPkeyUpdate = {
  articlePatch: updateArticleOnArticlesAuthorForArticlesAuthorsArticleIdFkeyPatch;
  rowId: string;
};
export type updateArticleOnArticlesAuthorForArticlesAuthorsArticleIdFkeyPatch = {
  allowComments?: boolean | null;
  articlesAuthorsUsingRowId?: ArticlesAuthorsArticleIdFkeyInverseInput | null;
  articlesTagsUsingRowId?: ArticlesTagsArticleIdFkeyInverseInput | null;
  authorToCreatedBy?: ArticlesCreatedByFkeyInput | null;
  authorToLockedBy?: ArticlesLockedByFkeyInput | null;
  authorToRevisionBy?: ArticlesRevisionByFkeyInput | null;
  authorsDescription?: string | null;
  boardsSectionsItemsUsingRowId?: BoardsSectionsItemsArticleIdFkeyInverseInput | null;
  breakingnewsEnd?: string | null;
  canonicalUrl?: string | null;
  capability?: Capability | null;
  categoryToPrimaryCategory?: ArticlesCategoryPrimaryFkeyInput | null;
  createdAt?: string | null;
  createdBy?: string | null;
  districts?: ReadonlyArray<District | null> | null;
  enableAmp?: boolean | null;
  enableInstantArticle?: boolean | null;
  expirationAt?: string | null;
  expirationFulfilledAt?: string | null;
  expirationIn?: IntervalInput | null;
  expirationTemplate?: Expiration | null;
  externalId?: string | null;
  firstPublicationDate?: string | null;
  fulltextIndex?: string | null;
  geolocation?: string | null;
  headline?: string | null;
  historiesUsingRowId?: HistoryArticleIdFkeyInverseInput | null;
  isImported?: boolean | null;
  isLive?: boolean | null;
  isTemplate?: boolean | null;
  language?: string | null;
  lastEdited?: string | null;
  location?: any | null;
  lockedBy?: string | null;
  lockedSince?: string | null;
  meta?: any | null;
  metaDescription?: string | null;
  moduleIds?: ReadonlyArray<number | null> | null;
  modulesUsingRowId?: ModulesArticleIdFkeyInverseInput | null;
  navigationsUsingRowId?: NavigationArticleFkeyInverseInput | null;
  notes?: string | null;
  oneSignalNotificationsUsingRowId?: OneSignalNotificationsArticleIdFkeyInverseInput | null;
  otherCategories?: ReadonlyArray<string | null> | null;
  primaryCategory?: string | null;
  publicationDate?: string | null;
  publicationPriority?: Priority | null;
  pushNotification?: PushNotification | null;
  pushNotificationConfig?: any | null;
  rawLinks?: ReadonlyArray<LinkInput | null> | null;
  redirectionsUsingRowId?: RedirectionsArticleIdFkeyInverseInput | null;
  revisionBy?: string | null;
  rowId?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  sourceBroadcastDate?: string | null;
  sourceOrigin?: string | null;
  status?: Status | null;
  story?: any | null;
  tagsAppIndex?: ReadonlyArray<string | null> | null;
  tagsCmsIndex?: ReadonlyArray<string | null> | null;
  teaserText?: string | null;
  textsearchableIndexCol?: string | null;
  title?: string | null;
};
export type ArticlesAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyUsingArticlesAuthorsPkeyUpdate = {
  articleId: string;
  articlesAuthorPatch: updateArticlesAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyPatch;
  authorGuid: string;
};
export type updateArticlesAuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyPatch = {
  articleId?: string | null;
  articleToArticleId?: ArticlesAuthorsArticleIdFkeyInput | null;
  authorToAuthorGuid?: ArticlesAuthorsAuthorIdFkeyInput | null;
  order?: number | null;
};
export type AuthorOnArticlesAuthorForArticlesAuthorsAuthorIdFkeyNodeIdUpdate = {
  articlesAuthorPatch: ArticlesAuthorPatch;
  id: string;
};
export type AuthorOnBoardsTeaserForAuthorCreateFkUsingAuthorsPkeyUpdate = {
  authorPatch: updateAuthorOnBoardsTeaserForAuthorCreateFkPatch;
  guid: string;
};
export type updateAuthorOnBoardsTeaserForAuthorCreateFkPatch = {
  articlesAuthorsUsingGuid?: ArticlesAuthorsAuthorIdFkeyInverseInput | null;
  articlesToCreatedByUsingGuid?: ArticlesCreatedByFkeyInverseInput | null;
  articlesToLockedByUsingGuid?: ArticlesLockedByFkeyInverseInput | null;
  articlesToRevisionByUsingGuid?: ArticlesRevisionByFkeyInverseInput | null;
  authorStatusToStatus?: StatusFkInput | null;
  authorStatusesUsingGuid?: AuthorGuidAuthorFkInverseInput | null;
  authorToUpdatedBy?: AuthorsUpdatedByFkeyInput | null;
  boardsSectionsItemsToCreatedByUsingGuid?: BoardsSectionsItemsCreatedByFkeyInverseInput | null;
  boardsSectionsItemsToUpdatedByUsingGuid?: BoardsSectionsItemsUpdatedByFkeyInverseInput | null;
  boardsSectionsToCreatedByUsingGuid?: BoardsSectionsCreatedByFkeyInverseInput | null;
  boardsSectionsToUpdatedByUsingGuid?: BoardsSectionsUpdatedByFkeyInverseInput | null;
  boardsTeasersToCreatedByUsingGuid?: AuthorCreateFkInverseInput | null;
  boardsTeasersToLockedByUsingGuid?: BoardsTeasersLockedByFkeyInverseInput | null;
  boardsTeasersToUpdatedByUsingGuid?: AuthorUpdateFkInverseInput | null;
  boardsToCreatedByUsingGuid?: BoardsCreatedByFkeyInverseInput | null;
  boardsToLockedByUsingGuid?: BoardsLockedByFkeyInverseInput | null;
  boardsToUpdatedByUsingGuid?: BoardsUpdatedByFkeyInverseInput | null;
  configsUsingGuid?: ConfigUpdatedByFkeyInverseInput | null;
  description?: string | null;
  email?: string | null;
  firstname?: string | null;
  gender?: Gender | null;
  guid?: string | null;
  historiesToAccomplishedByUsingGuid?: HistoryAccomplishedByFkeyInverseInput | null;
  historiesToAuthorGuidUsingGuid?: HistoryAuthorGuidFkeyInverseInput | null;
  isActive?: boolean | null;
  isLdapUser?: boolean | null;
  jobTitle?: string | null;
  lastname?: string | null;
  profilePicture?: string | null;
  shortDescription?: string | null;
  socialMediaAccounts?: ReadonlyArray<SocialMediaAccountInput | null> | null;
  status?: number | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
};
export type BoardsTeaserOnBoardsTeaserForAuthorCreateFkNodeIdUpdate = {
  authorPatch: AuthorPatch;
  id: string;
};
export type UpdateBoardsTeaserMutation$variables = {
  input: UpdateBoardsTeaserByRowIdInput;
};
export type UpdateBoardsTeaserMutation$data = {
  readonly updateBoardsTeaserByRowId: {
    readonly boardsTeaser: {
      readonly authorByLockedBy: {
        readonly firstname: string | null;
        readonly guid: string;
        readonly lastname: string | null;
      } | null;
      readonly description: string | null;
      readonly id: string;
      readonly image: {
        readonly altText: string | null;
        readonly copyright: string | null;
        readonly title: string | null;
        readonly url: string | null;
      } | null;
      readonly link: {
        readonly label: string | null;
        readonly url: string | null;
      } | null;
      readonly lockedBy: string | null;
      readonly lockedSince: string | null;
      readonly rowId: string;
      readonly status: BoardsTeaserStatus | null;
      readonly title: string;
    } | null;
  } | null;
};
export type UpdateBoardsTeaserMutation = {
  response: UpdateBoardsTeaserMutation$data;
  variables: UpdateBoardsTeaserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copyright",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "altText",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Link",
  "kind": "LinkedField",
  "name": "link",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "guid",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lockedBy",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lockedSince",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBoardsTeaserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBoardsTeaserPayload",
        "kind": "LinkedField",
        "name": "updateBoardsTeaserByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsTeaser",
            "kind": "LinkedField",
            "name": "boardsTeaser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleImage",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "authorByLockedBy",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBoardsTeaserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBoardsTeaserPayload",
        "kind": "LinkedField",
        "name": "updateBoardsTeaserByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsTeaser",
            "kind": "LinkedField",
            "name": "boardsTeaser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleImage",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "authorByLockedBy",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3989c884389c19eb0ddf4da84b777b9d",
    "id": null,
    "metadata": {},
    "name": "UpdateBoardsTeaserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBoardsTeaserMutation(\n  $input: UpdateBoardsTeaserByRowIdInput!\n) {\n  updateBoardsTeaserByRowId(input: $input) {\n    boardsTeaser {\n      id\n      rowId\n      title\n      description\n      image {\n        url\n        copyright\n        title\n        altText\n        id\n      }\n      link {\n        url\n        label\n      }\n      status\n      authorByLockedBy {\n        guid\n        firstname\n        lastname\n        id\n      }\n      lockedBy\n      lockedSince\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbaa3e2750fe176a87b2a238222d1657";

export default node;
