import { faDownload as DownloadIcon } from '@fortawesome/pro-light-svg-icons/faDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1}
`;

const fadeOut = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`;

const OverlayWrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
`;

const OverlayInnerWrapper = styled.div<{ isVisible: boolean }>`
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
	animation: ${(props) => (props.isVisible ? fadeIn : fadeOut)} 300ms linear;
	transition: visibility 300ms linear;
	position: absolute;
`;

const EditButton = styled(Button)`
	font-size: ${(props) => props.theme.fontSizeModuleContent} !important;
	transition: none !important;
	padding: 10px 25px 35px 25px !important;
	box-shadow: 0 0 5px ${(props) => props.theme.colors.blackShadowModuleEditorButton} !important;
`;

const DownloadButton = styled.div`
	cursor: pointer;
	color: #fff;
	position: absolute;
	top: 1.4rem;
	right: 1.4rem;
`;

type Props = {
	onButtonClick: () => void;
	onDownloadButtonClick?: () => void;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	isDownloadButtonVisible: boolean;
	isVisible: boolean;
	buttonLabel: string;
};

function ArticleModuleImageOverlay({
	onButtonClick,
	onDownloadButtonClick,
	onMouseEnter,
	onMouseLeave,
	isDownloadButtonVisible,
	isVisible,
	buttonLabel,
}: Props) {
	return (
		<OverlayWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<OverlayInnerWrapper isVisible={isVisible}>
				{isDownloadButtonVisible && (
					<DownloadButton onClick={onDownloadButtonClick}>
						<FontAwesomeIcon icon={DownloadIcon} size="3x" />
					</DownloadButton>
				)}
				<EditButton type="primary" onClick={onButtonClick}>
					{buttonLabel}
				</EditButton>
			</OverlayInnerWrapper>
		</OverlayWrapper>
	);
}

export default ArticleModuleImageOverlay;
