import type { AppThunkAction } from '@/client/store/redux';

import type { ToggleSubGroupsAction } from '../..';
import { TOGGLE_SUB_GROUPS } from '../../actionTypes';

const toggleSubGroup =
	(id: string): AppThunkAction<ToggleSubGroupsAction> =>
	(dispatch) => {
		if (!id) {
			return;
		}

		dispatch({
			type: TOGGLE_SUB_GROUPS,
			payload: {
				id,
			},
		});
	};

export default toggleSubGroup;
