import { MODULE_TYPE_TEXT } from '@sep/br24-constants';

import { useTranslate } from '@/client/translation/useTranslate';

import type { Validation } from '../../../containers/ArticleEditor';
import RichTextEditor from '../../RichTextEditor';

type Props = {
	defaultValue: string;
	validation?: Validation | null;
	isEditing?: boolean;
	onChange: (value: any) => void;
	onModuleIsEditing?: (nextIsEditing: boolean) => void;
};

export default function ArticleModuleText({ defaultValue, validation, onChange, ...rest }: Props) {
	const translate = useTranslate();

	return (
		<RichTextEditor
			validationError={validation?.text}
			defaultValue={defaultValue}
			onChange={onChange}
			placeholder={translate(`modules.${MODULE_TYPE_TEXT}.textPlaceholder`)}
			{...rest}
		/>
	);
}
