import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateModuleAction, UpdateModuleInput, ValidateAction } from '..';
import { MODULE_UPDATE } from '../actionTypes';

import { validateModule } from './validate';

const updateModule =
	(
		id: string | number,
		input: UpdateModuleInput
	): AppThunkAction<ValidateAction | UpdateModuleAction> =>
	(dispatch) => {
		dispatch({
			type: MODULE_UPDATE,
			constraint: id,
			payload: {
				...input,
			},
		});

		dispatch(validateModule(id));
	};

export default updateModule;
