import type { AppThunkAction } from '@/client/store/redux';

import type { ToggleGlobalGroupLockAction } from '../..';
import { TOGGLE_GLOBAL_GROUP_LOCK } from '../../actionTypes';
import { updateGlobalGroupLockStatus } from '../../database';

const toggleGlobalGroupLock =
	(
		id: string,
		isLocked: boolean,
		{ persist = true } = {}
	): AppThunkAction<ToggleGlobalGroupLockAction> =>
	(dispatch) => {
		dispatch({
			type: TOGGLE_GLOBAL_GROUP_LOCK,
			payload: {
				id,
				isLocked,
			},
		});

		if (persist) {
			updateGlobalGroupLockStatus(id, isLocked);
		}
	};

export default toggleGlobalGroupLock;
