import { Select } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';
import { uniqueId } from 'lodash-es';
import { Component } from 'react';
import styled from 'styled-components';

const { Option } = Select;

const StyledSelect = styled(Select<string, DefaultOptionType>)`
	width: 100%;

	.ant-select-selection {
		background-color: ${(props) => props.theme?.assetManager.itemColor};
		color: ${(props) => props.theme?.colors.white};
		height: 38px;
		border-radius: 3px;
	}

	.ant-select-selection-selected-value {
		line-height: 38px;
	}

	.ant-select-selection__placeholder {
		height: inherit;
		line-height: 26px;
	}

	.ant-select-arrow {
		color: ${(props) => props.theme?.colors.white};
	}

	.anticon svg {
		background-color: ${(props) => props.theme.assetManager.itemColor};
		fill: ${(props) => props.theme.colors.white};
	}
`;

interface SelectOption {
	value: string;
	label: string;
}

type Props = {
	value: string | null;
	options: Array<SelectOption>;
	placeholder?: string;
	allowClear?: boolean;
	onChange: (a: string) => void;
};

class AssetManagerImporterStyledSelect extends Component<Props> {
	private id: string;

	constructor(props: Props) {
		super(props);
		this.id = uniqueId('AssetManagerImporterSelect-');
	}

	handleChange = (value: string) => {
		this.props.onChange(value);
	};

	render() {
		return (
			<div id={this.id}>
				<StyledSelect
					dropdownMatchSelectWidth={false}
					placeholder={this.props.placeholder}
					value={this.props.value}
					onChange={this.handleChange}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					getPopupContainer={() => document.getElementById(this.id)!}
					allowClear={this.props.allowClear}
				>
					{this.props.options.map((option) => (
						<Option value={option.value} key={option.value}>
							{option.label}
						</Option>
					))}
				</StyledSelect>
			</div>
		);
	}
}
export default AssetManagerImporterStyledSelect;
