import {
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	TYPE_BOARD,
} from '@sep/br24-constants';
import { Input, Button, Col, Row, Alert, Spin } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import { Component, type ChangeEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import type { ConnectedProps } from 'react-redux';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import environment from '../../environment';
import { show as showCreator } from '../../store/reducers/creator';
import styles from '../../styles';
import type { WithTranslationProps } from '../../translation';
import { Page } from '../Page';
import { StatusPicker } from '../StatusPicker';

import BoardsTableContainer from './BoardsTableContainer';
import type { BoardsQuery } from './__generated__/BoardsQuery.graphql';

const { Search } = Input;

const allStatusFilters = [STATUS_DEPUBLISHED, STATUS_PUBLISHED, STATUS_DRAFT] as const;

type Status = (typeof allStatusFilters)[number];

type State = {
	searchTerm?: string | null;
	statusFilter: 'ALL' | Status;
};

interface Props extends WithTranslationProps, ReduxProps {}

const SpinnerWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
	height: 90vh;
`;

const Loader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
`;

class Boards extends Component<Props, State> {
	state: State = {
		searchTerm: null,
		statusFilter: STATUS_PUBLISHED,
	};

	debounceRef = debounce((searchTerm: string) => this.setState({ searchTerm }), 500);

	componentWillUnmount() {
		this.debounceRef.cancel();
	}

	handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>): void => {
		if (event.target instanceof HTMLInputElement) {
			const { value: searchTerm } = event.target;
			this.debounceRef(searchTerm);
		}
	};

	handleChangeStatus = (statusFilter: 'ALL' | Status) => {
		this.setState({ statusFilter });
	};

	handleClickCreateBoard = () => {
		this.props.showCreator();
	};

	render() {
		const { searchTerm, statusFilter } = this.state;
		const {
			translation: { translate },
		} = this.props;

		return (
			<Page>
				<Helmet>
					<title>{translate('boards.boards')}</title>
				</Helmet>

				<QueryRenderer<BoardsQuery>
					environment={environment}
					variables={{
						count: 30,
						searchTerm,
						statusFilter: statusFilter === 'ALL' ? allStatusFilters : [statusFilter],
					}}
					query={graphql`
						query BoardsQuery(
							$searchTerm: String
							$statusFilter: [Status]
							$count: Int
							$cursor: Cursor
						) {
							...BoardsTableContainer_query
						}
					`}
					render={({ error, props }) => {
						if (error) {
							return (
								<Alert message={translate('error')} description={error.message} type="error" />
							);
						}

						return (
							<Page.Content>
								<Row>
									<Col className={classNames(styles.pM, styles.toolbar)} span={24}>
										<Button onClick={this.handleClickCreateBoard} type="primary">
											{translate('boards.newBoard')}
										</Button>

										<div>
											<StatusPicker onStatusPick={this.handleChangeStatus} value={statusFilter} />

											<Search
												onChange={this.handleChangeSearchTerm}
												placeholder={translate('boards.searchPlaceholder')}
												style={{ width: 250 }}
											/>
										</div>
									</Col>
								</Row>
								{props ? (
									<BoardsTableContainer query={props} />
								) : (
									<SpinnerWrapper>
										<Loader>
											<Spin size="large" />
										</Loader>
									</SpinnerWrapper>
								)}
							</Page.Content>
						);
					}}
				/>
			</Page>
		);
	}
}

const connector = connect(null, {
	showCreator: () => showCreator({ type: TYPE_BOARD }),
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(Boards));
