import type { ReactNode } from 'react';

import { useTranslate } from '@/client/translation/useTranslate';

import ErrorMessageText from '../../ErrorMessageText';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

export type EditedElements = {
	url?: string | null;
};

type Props = {
	children: ReactNode;
	validation: Array<string> | null;
	key: string | number;
};

export default function ArticleMetaTeasers({ children, validation, ...rest }: Props) {
	const translate = useTranslate();

	return (
		<ArticleMetaPanel
			help={translate('article.meta.teasers.help')}
			isRequired={false}
			title={translate('article.meta.teasers.title')}
			{...rest}
		>
			{validation !== null &&
				validation.map((error) => (
					<ErrorMessageText key={error} text={error} hasBottomMargin={true} />
				))}
			{children}
		</ArticleMetaPanel>
	);
}
