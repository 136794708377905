import { fetchQuery, graphql } from 'react-relay';

import type { NewsroomSearchParameters } from '../../..';
import { AM_TYPE_ARTICLE } from '../../../../../constants';
import environment from '../../../../../environment';

import type { searchNewsroomArticlesQuery } from './__generated__/searchNewsroomArticlesQuery.graphql';
import type { searchNewsroomBoardsQuery } from './__generated__/searchNewsroomBoardsQuery.graphql';

const RESPONSE_LIMIT = 15;

const boardQuery = graphql`
	query searchNewsroomBoardsQuery(
		$limit: Int!
		$after: Cursor
		$term: String!
		$categoryFilter: String
	) {
		filterBoards(first: $limit, after: $after, searchTerm: $term, categoryFilter: $categoryFilter) {
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			edges {
				cursor
				node {
					id
					rowId
					title
					createdAt
					status
					updatedAt
					image {
						url
					}
				}
			}
		}
	}
`;

const articleQuery = graphql`
	query searchNewsroomArticlesQuery(
		$limit: Int!
		$after: Cursor
		$term: String!
		$categoryFilter: String
		$startDateFilter: Datetime
		$endDateFilter: Datetime
	) {
		filterArticles(
			first: $limit
			after: $after
			searchPlain: $term
			categoryFilter: $categoryFilter
			publishedAfter: $startDateFilter
			publishedBefore: $endDateFilter
		) {
			pageInfo {
				hasNextPage
				hasPreviousPage
			}
			edges {
				cursor
				node {
					id
					rowId
					title
					createdAt
					isLive
					lastEdited
					status
					image {
						url
					}
				}
			}
		}
	}
`;

async function fetchArticle(filterParameters: NewsroomSearchParameters) {
	const { lastCursor, startDate, endDate, term, categoryFilter } = filterParameters;

	const isoStartDate = startDate ? startDate.toISOString() : null;
	const isoEndDate = endDate ? endDate.toISOString() : null;

	const queryVariables = {
		limit: RESPONSE_LIMIT,
		term: term || '',
		categoryFilter,
		after: lastCursor,
		startDateFilter: isoStartDate,
		endDateFilter: isoEndDate,
	};

	return fetchQuery<searchNewsroomArticlesQuery>(environment, articleQuery, queryVariables)
		.toPromise()
		.then((res) => res?.filterArticles);
}

async function fetchBoard(filterParameters: NewsroomSearchParameters) {
	const { lastCursor, term, categoryFilter } = filterParameters;

	const queryVariables = {
		limit: RESPONSE_LIMIT,
		term: term || '',
		categoryFilter,
		after: lastCursor,
	};

	return fetchQuery<searchNewsroomBoardsQuery>(environment, boardQuery, queryVariables)
		.toPromise()
		.then((res) => res?.filterBoards);
}

export default (async function searchNewsroom(filterParameters: NewsroomSearchParameters) {
	return filterParameters.typeFilter === AM_TYPE_ARTICLE
		? fetchArticle(filterParameters)
		: fetchBoard(filterParameters);
});
