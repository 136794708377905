import { TYPE_ARTICLE, TYPE_BOARD, TYPE_AUTHOR } from '@sep/br24-constants';
import classNames from 'classnames';

import type { CreatorType } from '@/client/ui/Header';

import Icon from '../Icon';

import styles from './Creator.module.scss';

const TYPE_TEASER = 'TEASER';

type Props = {
	onChange: (type: CreatorType) => void;
	className?: string;
};

export default function TypePicker({ onChange, className, ...rest }: Props) {
	return (
		<ul className={classNames(styles.typeSelect, className)} {...rest}>
			<li onClick={() => onChange(TYPE_ARTICLE)}>
				<Icon name="newspaper-o" size="4x" />
				<h2>Artikel</h2>
			</li>
			<li onClick={() => onChange(TYPE_BOARD)}>
				<Icon name="th" size="4x" />
				<h2>Seite</h2>
			</li>
			<li onClick={() => onChange(TYPE_TEASER)}>
				<Icon name="newspaper-o" size="4x" />
				<h2>Teaser</h2>
			</li>
			<li onClick={() => onChange(TYPE_AUTHOR)}>
				<Icon name="user" size="4x" />
				<h2>Autor</h2>
			</li>
		</ul>
	);
}
