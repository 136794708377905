import { Select, Spin } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';

const { Option } = Select;

const FullWidthSelect = styled(Select<string>)`
	width: 100% !important;
	margin-bottom: 10px !important;
`;

type SearchResultType = {
	id: string;
	title: string;
};

type Props = WithTranslationProps & {
	children: any;
	isSearchWorking: boolean;
	searchTerm?: string | null;
	onSearch: (searchValue?: string | null) => void;
	onSelect: (sophoraId: string) => void;
	onBlur: () => void;
	searchResult: Array<SearchResultType> | undefined | null;
};

class SophoraSearch extends Component<Props> {
	handleSearch = (searchValue: string) => {
		if (typeof searchValue === 'string' && searchValue.includes('br.de')) {
			const matched = /((https|http):\/\/)?(www\.)?br.de\/(.*)\/(.*)\.html?/.exec(searchValue);

			if (matched && matched.length > 4) {
				const nextSophoraID = matched.pop();
				this.props.onSearch(nextSophoraID);
			}
		}
	};

	handleSelect = (sophoraId: string) => {
		console.log('handle select', sophoraId);
		this.props.onSelect(sophoraId);
	};

	render() {
		const {
			children,
			isSearchWorking,
			searchResult,
			onBlur,
			searchTerm,
			translation: { translate },
		} = this.props;

		let options: JSX.Element[] = [];

		if (isSearchWorking && searchTerm) {
			options.push(
				<Option key="loading" disabled={true}>
					<Spin size="small" />
				</Option>
			);
		} else if (Array.isArray(searchResult) && searchResult.length > 0) {
			options = searchResult
				.filter((item) => item.id === searchTerm)
				.map((article) => (
					<Option key={article.id} value={article.id}>
						<span>{article.title}</span>
					</Option>
				));
		} else if (!searchTerm) {
			options = [];
		}

		return (
			<div>
				<FullWidthSelect
					showSearch={true}
					// use undefined here because if not the placeholder is not displayed when empty (antd)
					value={searchTerm || undefined}
					placeholder={translate('article.meta.more.sophoraSearch.searchPlaceholder')}
					filterOption={false}
					showArrow={false}
					notFoundContent={null}
					onBlur={onBlur}
					onSearch={this.handleSearch}
					onSelect={this.handleSelect}
				>
					{options}
				</FullWidthSelect>
				{children}
			</div>
		);
	}
}

export default withTranslation(SophoraSearch);
