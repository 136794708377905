import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../environment';

import DeleteModal from './DeleteModal';
import type { DeleteModalContainerRefetchQuery } from './__generated__/DeleteModalContainerRefetchQuery.graphql';

type Props = {
	boardsTeaserId: string | undefined | null;
	onOk: (key: string) => void;
	onCancel: () => void;
};

export default function ({ boardsTeaserId, ...rest }: Props) {
	return (
		<>
			{boardsTeaserId && (
				<QueryRenderer<DeleteModalContainerRefetchQuery>
					environment={environment}
					query={graphql`
						query DeleteModalContainerRefetchQuery($boardsTeaserId: String!) {
							...DeleteModal_query
						}
					`}
					variables={{
						boardsTeaserId,
					}}
					render={({ props }) => {
						if (props) {
							return <DeleteModal query={props} {...rest} />;
						}
						return null;
					}}
				/>
			)}
		</>
	);
}
