import styled from 'styled-components';

const Wrapper = styled.div`
	height: 100%;
	flex: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
`;

type Props = {
	children: any;
};

function AssetManagerGroupsWrapper({ children }: Props) {
	return <Wrapper>{children}</Wrapper>;
}

export default AssetManagerGroupsWrapper;
