import { getApps, initializeApp } from 'firebase/app';
import type { FirebaseApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, signInWithCustomToken } from 'firebase/auth';
import type { User } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { useEffect, useState } from 'react';

import config from '../config';

// when nextjs reloads during development, the instance might get lost, so we store it here as a global variable
let globalFirebaseAppInstance: FirebaseApp | undefined;

export function getOrInitializeFirebaseApp() {
	if (globalFirebaseAppInstance) {
		return globalFirebaseAppInstance;
	}

	const apps = getApps();

	if (apps[0]) {
		globalFirebaseAppInstance = apps[0];
	} else {
		globalFirebaseAppInstance = initializeApp({
			apiKey: config.VITE_FIREBASE_CRED_API_KEY,
			authDomain: config.VITE_FIREBASE_CRED_AUTH_DOMAIN,
			databaseURL: config.VITE_FIREBASE_CRED_DATABASE_URL,
			projectId: config.VITE_FIREBASE_CRED_PROJECT_ID,
		});
	}

	if (config.VITE_FIREBASE_AUTH_EMULATOR_HOST) {
		connectAuthEmulator(getAuth(), config.VITE_FIREBASE_AUTH_EMULATOR_HOST);
	}

	if (config.VITE_FIREBASE_DATABASE_EMULATOR_HOST) {
		const [host, port] = config.VITE_FIREBASE_DATABASE_EMULATOR_HOST.split(':');
		connectDatabaseEmulator(getDatabase(), host, Number(port));
	}

	return globalFirebaseAppInstance;
}

type TokenExchangeResponse = {
	data?: {
		token: string;
		expiresAt: number;
	};
	error?: unknown;
};

async function createFirebaseCustomTokenForGuid(guid: string) {
	const response = await fetch(config.VITE_FIREBASE_AUTH_URL_EXT, {
		method: 'POST',
		body: JSON.stringify({ guid }),
		headers: new Headers({
			'Content-Type': 'application/json',
		}),
	});

	if (response.status !== 200) {
		throw new Error('Invalid status code obtained');
	}

	const payload = await response.json();

	if (!payload.data) {
		throw new Error(payload);
	}

	return payload as TokenExchangeResponse;
}

async function signInFirebaseUser(customToken: string): Promise<User | null> {
	try {
		const result = await signInWithCustomToken(getAuth(), customToken);
		return result.user;
	} catch (err) {
		console.info(
			'cannot sign in firebase user with customToken based on BR OIDC guid [see error in next log]'
		);
		console.error(err);
		return null;
	}
}

function watchFirebaseAuthState({
	onSignedIn,
	onSignedOut,
}: {
	onSignedIn: () => void;
	onSignedOut: () => void;
}) {
	const unsubscribe = getAuth().onAuthStateChanged((user) => {
		if (user) {
			// User is signed in, see docs for a list of available properties
			// https://firebase.google.com/docs/reference/js/firebase.User
			onSignedIn();
			// ...
		} else {
			// User is signed out
			// ...
			onSignedOut();
		}
	});
	return unsubscribe;
}

export function useFirebase(guid?: string): { loading: boolean; error?: unknown } {
	getOrInitializeFirebaseApp();

	const [customToken, setCustomToken] = useState<string | undefined>();
	const [error, setError] = useState<unknown | undefined>();
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const unsubscribe = watchFirebaseAuthState({
			onSignedIn: () => {
				setLoading(false);
				setError(undefined);
			},
			onSignedOut: () => {
				setLoading(true);
			},
		});

		return unsubscribe;
	});

	useEffect(() => {
		if (guid) {
			const run = async () => {
				try {
					const result = await createFirebaseCustomTokenForGuid(guid);
					setCustomToken(result.data?.token);
				} catch (err) {
					setError(err);
				}
			};

			run();
		}
	}, [guid]);

	useEffect(() => {
		if (customToken) {
			(async () => {
				try {
					await signInFirebaseUser(customToken);
				} catch (err) {
					console.error(err);
				}
			})();
		}
	}, [customToken]);

	return {
		error,
		loading,
	};
}
