import { debounce } from 'lodash-es';
import moment from 'moment';
import { Component } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';

import { ArticleMetaSourceOrigin, ArticleMetaSourceOriginInput } from '../../../../ui/ArticleMeta';

import type { SourceOriginContainerRefetch_query$data } from './__generated__/SourceOriginContainerRefetch_query.graphql';

const SEARCH_DELAY_MS = 500;

type Props = {
	onAdd: (id?: string | null, date?: string | null) => void;
	onChange: (index: number, id?: string | null, date?: string | null) => void;
	onRemove: (index: number) => void;
	query: SourceOriginContainerRefetch_query$data | null;
	relay: any;
	sourceOriginDate: string | undefined | null;
	sourceOriginRowId: string | undefined | null;
	key: string | number;
};

export class SourceOriginContainerRefetch extends Component<Props> {
	handleSearch = debounce((searchTerm: string) => {
		if (this.props.relay && this.props.relay.refetch) {
			this.props.relay.refetch({ filter: { term: searchTerm } });
		}
	}, SEARCH_DELAY_MS);

	componentWillUnmount() {
		this.handleSearch.cancel();
	}

	render() {
		const {
			onAdd,
			onChange,
			onRemove,
			sourceOriginRowId,
			sourceOriginDate,
			query,
			relay,
			...rest
		} = this.props;

		const hasSourceOrigin = sourceOriginRowId || sourceOriginDate;
		const sourceOriginName = query?.viewer?.series?.title || '';
		const edges = query?.viewer?.searchSourceOrigins?.edges || [];
		let suggestions: { id: string; title: string }[] = [];

		if (Array.isArray(edges) && edges.length > 0) {
			suggestions = edges.map((item) => ({
				id: item?.node?.id || '',
				title: item?.node?.title || '',
			}));
		}

		const sourceOriginInput = hasSourceOrigin ? null : (
			<ArticleMetaSourceOriginInput
				suggestions={suggestions}
				onSubmit={onAdd}
				onSearch={this.handleSearch}
			/>
		);

		const items =
			sourceOriginName || hasSourceOrigin
				? [
						{
							title: sourceOriginName,
							date: sourceOriginDate ? moment(sourceOriginDate) : null,
							id: sourceOriginRowId,
						},
				  ]
				: undefined;

		return (
			<ArticleMetaSourceOrigin
				input={sourceOriginInput}
				onChange={onChange}
				onRemove={onRemove}
				onSearch={this.handleSearch}
				suggestions={suggestions}
				items={items}
				{...rest}
			/>
		);
	}
}

export default createRefetchContainer(
	SourceOriginContainerRefetch,
	{
		query: graphql`
			fragment SourceOriginContainerRefetch_query on Query {
				viewer {
					searchSourceOrigins: allSeries(first: 20, filter: $filter) {
						edges {
							node {
								id
								title
							}
						}
					}
					series(id: $sourceOriginRowId) @include(if: $hasSourceOrigin) {
						id
						title
					}
				}
			}
		`,
	},
	graphql`
		query SourceOriginContainerRefetchQuery(
			$filter: MangoSeriesFilter
			$sourceOriginRowId: ID!
			$hasSourceOrigin: Boolean = false
		) {
			...SourceOriginContainerRefetch_query
		}
	`
);
