import { DeleteOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import classNames from 'classnames';
import { Component, type MouseEvent, type ReactNode } from 'react';

import styles from './Card.module.scss';

type Props = {
	children?: ReactNode;
	className?: string | null;
	headerClassName?: string | null;
	contentClassName?: string | null;
	contentWithoutPadding?: boolean;
	actions?: ReactNode[];
	title?: ReactNode;
	collapsible?: boolean;
	confirmClose?: string;
	closable?: boolean;
	onClose?: (event?: MouseEvent) => void;
	bordered?: boolean;
};

type State = {
	isCollapsed: boolean;
};

export default class Card extends Component<Props, State> {
	static defaultProps = {
		collapsible: false,
		closable: false,
		contentWithoutPadding: false,
		bordered: true,
	};

	state = {
		isCollapsed: false,
	};

	handleClickClose = (event?: MouseEvent) => {
		const { onClose } = this.props;

		if (onClose) {
			onClose(event);
		}
	};

	handleClickCollapse = () => {
		const { isCollapsed } = this.state;
		this.setState({ isCollapsed: !isCollapsed });
	};

	renderCloseIcons = () => {
		const { confirmClose } = this.props;

		if (!confirmClose) {
			return <DeleteOutlined data-testid="deletebutton" onClick={this.handleClickClose} />;
		}

		return (
			<Popconfirm
				title={confirmClose}
				okText="Löschen"
				cancelText="Abbrechen"
				onConfirm={this.handleClickClose}
				placement="left"
				data-testid="popconfirm"
			>
				<DeleteOutlined />
			</Popconfirm>
		);
	};

	private renderCollapsibleArea(isCollapsed: boolean) {
		if (isCollapsed) {
			return <ArrowsAltOutlined data-testid="expand-icon" onClick={this.handleClickCollapse} />;
		} else {
			return <ShrinkOutlined data-testid="collapse-icon" onClick={this.handleClickCollapse} />;
		}
	}

	renderHeader() {
		const { title, closable, collapsible, headerClassName, actions } = this.props;
		const { isCollapsed } = this.state;

		if (!title && !closable && !collapsible && !actions) {
			return false;
		}

		return (
			<div
				className={classNames(
					styles.cardHeader,
					{ [styles.cardHeaderCollapsed]: isCollapsed },
					headerClassName
				)}
			>
				{title && (
					<div className={styles.cardHeaderTitle}>
						{title || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
					</div>
				)}

				<div className={styles.linediv}>
					<hr className={styles.line} />
				</div>

				{closable || collapsible || actions ? (
					<div className={styles.cardHeaderActions}>
						{actions || false}
						{collapsible ? this.renderCollapsibleArea(isCollapsed) : false}
						{closable ? this.renderCloseIcons() : false}
					</div>
				) : (
					false
				)}
			</div>
		);
	}

	render() {
		const { className, contentClassName, contentWithoutPadding, children, bordered } = this.props;
		const { isCollapsed } = this.state;

		return (
			<div data-testid="card" className={classNames(styles.card, className)}>
				{this.renderHeader()}
				<div
					className={classNames(
						styles.cardContent,
						{
							[styles.cardContentCollapsed]: isCollapsed,
							[styles.cardContentNoPadding]: contentWithoutPadding,
							[styles.cardContentNoBorder]: !bordered,
						},
						contentClassName
					)}
				>
					{children}
				</div>
			</div>
		);
	}
}
