import type { AppThunkAction } from '@/client/store/redux';

import type { ChangeModeAction, UpdateSelectionAction, Mode } from '..';
import { AM_MODE_EDIT } from '../../../constants';
import { CHANGE_MODE } from '../actionTypes';

import resetSelection from './resetSelection';

const changeMode =
	(mode: Mode): AppThunkAction<ChangeModeAction | UpdateSelectionAction> =>
	(dispatch, getState) => {
		dispatch({
			type: CHANGE_MODE,
			payload: mode,
		});

		const { assetManager } = getState();

		if (assetManager.mode === AM_MODE_EDIT && mode !== AM_MODE_EDIT) {
			dispatch(resetSelection());
		}
	};

export default changeMode;
