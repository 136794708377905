import type { AppThunkAction } from '@/client/store/redux';

import {
	ARTICLE_CREATE_FROM_TEMPLATE,
	STATUS_REQUEST,
	STATUS_SUCCESS,
	STATUS_ERROR,
} from '../actionTypes';
import type {
	ArticleCreateFromTemplateErrorAction,
	ArticleCreateFromTemplateRequestAction,
	ArticleCreateFromTemplateSuccessAction,
	UpdatePayload,
} from '../index';
import copyArticle from '../mutations/ArticleEditorCopyArticleMutation';

export const request = (): ArticleCreateFromTemplateRequestAction => ({
	type: ARTICLE_CREATE_FROM_TEMPLATE,
	status: STATUS_REQUEST,
});

export const success = (payload: UpdatePayload): ArticleCreateFromTemplateSuccessAction => ({
	type: ARTICLE_CREATE_FROM_TEMPLATE,
	status: STATUS_SUCCESS,
	payload,
});

export const error = (err: Error): ArticleCreateFromTemplateErrorAction => ({
	type: ARTICLE_CREATE_FROM_TEMPLATE,
	status: STATUS_ERROR,
	payload: err,
});

const createArticleFromTemplate =
	(
		rowId: string,
		callbackSuccess: (newRowId: string) => void
	): AppThunkAction<
		| ArticleCreateFromTemplateErrorAction
		| ArticleCreateFromTemplateRequestAction
		| ArticleCreateFromTemplateSuccessAction
	> =>
	async (dispatch) => {
		dispatch(request());
		try {
			const article = await copyArticle(rowId);

			const copiedArticle = article.copyArticle;
			if (!copiedArticle || !copiedArticle.article || !copiedArticle.article.rowId) {
				throw new Error('The newly created copy of article does not have the required structure.');
			}
			callbackSuccess(copiedArticle.article.rowId);
			return dispatch(success({ rowId: copiedArticle.article.rowId }));
		} catch (err) {
			return dispatch(error(err as Error));
		}
	};

export default createArticleFromTemplate;
