import { Modal, Button, Tooltip } from 'antd';
import { useState } from 'react';

import { useTranslate } from '@/client/translation/useTranslate';

interface Props {
	isVisible: boolean;
	brokenBy: string;
	brokenDate: string;
	onReadOnlyView?: () => void;
	onReloadDocument?: () => void;
	onOpenDocumentNewTab?: () => void;
}

type State = {
	isVisible: boolean;
};

export default function SaveErrorLockDialog(props: Props) {
	const translate = useTranslate();

	const [isVisible] = useState<State>({ isVisible: props.isVisible });

	const handleOpenDocumentNewTab = () => {
		if (props.onOpenDocumentNewTab) {
			props.onOpenDocumentNewTab();
		}

		window.open(window.location.href, '_blank');
		handleReadOnlyView();
	};

	const handleReadOnlyView = () => {
		if (props.onReadOnlyView) {
			props.onReadOnlyView();
		}
	};

	const handleReloadDocument = () => {
		if (props.onReloadDocument) {
			props.onReloadDocument();
		}

		window.location.reload();
	};

	const handleClose = handleReadOnlyView;

	const { brokenBy, brokenDate } = props;

	return (
		<>
			<Modal
				width={800}
				open={!!isVisible}
				title={translate('lockWarnings.saveErrorLockDialog.title')}
				onCancel={handleClose}
				footer={[
					<Tooltip
						key="openNewTabButton"
						title={translate('lockWarnings.openNewTabButton.tooltip')}
					>
						<Button type="primary" onClick={handleOpenDocumentNewTab}>
							{translate('lockWarnings.openNewTabButton.title')}
						</Button>
					</Tooltip>,

					<Tooltip
						key="openReadOnlyButton"
						title={translate('lockWarnings.openReadOnlyButton.tooltip')}
					>
						<Button type="primary" onClick={handleReadOnlyView}>
							{translate('lockWarnings.openReadOnlyButton.title')}
						</Button>
					</Tooltip>,

					<Tooltip
						key="reloadDocumentButton"
						title={translate('lockWarnings.reloadDocumentButton.tooltip')}
					>
						<Button type="primary" onClick={handleReloadDocument}>
							{translate('lockWarnings.reloadDocumentButton.title')}
						</Button>
					</Tooltip>,
				]}
			>
				{translate('lockWarnings.saveErrorLockDialog.text', {
					name: brokenBy,
					date: brokenDate,
				})}
			</Modal>
		</>
	);
}
