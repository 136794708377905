import { Input as AntInput } from 'antd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

type Props = {
	value: string;
	onChange: (a: any) => void;
};

const Input = styled(AntInput.Search)`
	margin-bottom: 10px;
	height: 42px;
`;

export default function AssetManagerGroupsSearch({ value, onChange }: Props) {
	const translate = useTranslate();

	return (
		<Input
			placeholder={translate('assetManager.groups.search')}
			value={value}
			onChange={(event) => onChange(event.target.value)}
		/>
	);
}
