import { Collapse, Card, Tag, Button } from 'antd';
import type { ComponentType } from 'react';
import type { FallbackProps } from 'react-error-boundary';

import config from '../../config';
import errorRecorder from '../../errorRecorder';

import styles from './ErrorFallback.module.scss';
import errorImageChair from './covers/chair.jpg';
import errorImageCode from './covers/code.jpg';

const { Panel } = Collapse;

const handleClickReloadApplication = () => {
	window.location.href = window.location.origin;
};

const images = [errorImageChair, errorImageCode];
function getRandomImage() {
	return images[Math.floor(Math.random() * images.length)];
}

const ErrorFallback: ComponentType<FallbackProps> = ({ error, resetErrorBoundary }) => {
	return (
		<div>
			<Card
				cover={
					<div className={styles.cover} style={{ backgroundImage: `url(${getRandomImage()})` }}>
						<div className={styles.content}>
							<h1>Hupppsss!</h1>
							<h2>Da ist etwas schief gelaufen!</h2>
						</div>
					</div>
				}
				className={styles.card}
			>
				<div className={styles.message}>
					<p>
						Das sollte natürlich nicht passieren! Sollten Sie diesen Fehler nochmals erhalten können
						Sie sich mit der Kennung <Tag>{errorRecorder.getId()}</Tag> an den Support wenden!
					</p>
					<div style={{ display: 'grid', gap: '1rem', gridAutoFlow: 'column' }}>
						<Button
							type="primary"
							style={{ marginTop: '1em' }}
							onClick={handleClickReloadApplication}
						>
							Applikation neu laden
						</Button>
						{config.VITE_NODE_ENV === 'development' && (
							<Button type="ghost" style={{ marginTop: '1em' }} onClick={resetErrorBoundary}>
								Fehlerinformation schließen
							</Button>
						)}
					</div>
				</div>
				{config.VITE_NODE_ENV === 'development' ? (
					<Collapse className={styles.details} bordered={false}>
						<Panel key="componentStackTrace" header="Component-Stack">
							<pre>
								<code>{error.stack}</code>
							</pre>
						</Panel>
						<Panel key="stackTrace" header="Error-Stack">
							<pre>
								<code>{error.stack}</code>
							</pre>
						</Panel>
					</Collapse>
				) : (
					false
				)}
			</Card>
		</div>
	);
};

export default ErrorFallback;
