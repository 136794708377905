import { Alert } from 'antd';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';
import { filterNullOrUndefined } from '@/client/util/filter';

import type { WithTranslationProps } from '../../../../translation';
import { ArticleMetaCategory } from '../../../../ui/ArticleMeta';
import update from '../../actions/update';
import { articleCategorySelector } from '../../selectors';

import CategoryRenderer from './CategoryRenderer';

interface Props extends WithTranslationProps, ReduxProps {
	primaryCategory: string;
	key: string | number;
}

class CategoryContainer extends Component<Props> {
	handleSelect = (id: string) => {
		this.props.update({ primaryCategory: id });
	};

	render() {
		const {
			primaryCategory,
			translation: { translate },
			update,
			...rest
		} = this.props;

		return (
			<CategoryRenderer>
				{({ error, props }) => {
					const hasError = !!error;

					if (hasError) {
						return <Alert message={translate('error')} description={error.message} type="error" />;
					}

					const edges = props?.allCategories?.edges || [];
					const categories = edges.map((item) => item.node).filter(filterNullOrUndefined);

					return (
						<ArticleMetaCategory
							selected={primaryCategory}
							categories={categories}
							onSelect={this.handleSelect}
							{...rest}
						/>
					);
				}}
			</CategoryRenderer>
		);
	}
}

const connector = connect(articleCategorySelector, {
	update,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(CategoryContainer));
