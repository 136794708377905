import * as util from '@sep/br24-util';
import { Input as AntInput, type InputProps as AntInputProps, Button } from 'antd';
import { type ChangeEvent, Component, type KeyboardEvent } from 'react';
import styled from 'styled-components';

import { HOST_BLACKLIST } from '@/client/constants';
import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { ErrorSolid, Trash2 as TrashIcon } from '../../Icon';
import { ArticleMetaPanelInfo } from '../ArticleMetaPanel';

import { Footer, FooterButton } from '.';

const Form = styled.form`
	position: relative;
`;

const AddURLWrapper = styled.div`
	background: #e8edf0;
	padding: 10px;
	margin-bottom: 10px;
`;

interface Props extends WithTranslationProps {
	existingUrls: Array<string>;
	onCancel: () => void;
	onSave: (url: string) => void;
}

type State = {
	url: string;
	isFresh: boolean;
};

function isUrlValid(url: string): boolean {
	const re = /^(http|https):\/\/.+/i;
	return re.test(url);
}

const StyledInput = styled(AntInput)`
	margin-top: 4px;
	margin-bottom: 4px;
	&.errors {
		border-color: ${(props) => props.theme.colors.stateRed};
	}
`;

const InputError = styled.p`
	font-size: 0.85em;
	color: ${(props) => props.theme.colors.stateRed};
	padding-bottom: 10px;
	padding-top: 5px;
`;

interface InputProps extends AntInputProps {
	errors: string | null;
}

function Input(props: InputProps) {
	const { errors, ...rest } = props;
	return (
		<div>
			{errors && (
				<InputError>
					<ErrorSolid /> {errors}
				</InputError>
			)}
			<StyledInput className={errors ? 'errors' : 'ok'} {...rest} />
		</div>
	);
}

class ArticleMetaTeasersItemFresh extends Component<Props, State> {
	state = {
		url: '',
		isFresh: true,
	};

	handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
		this.setState({ url: ev.target.value, isFresh: false });
	};

	handleClose = () => {
		this.props.onCancel();
	};

	handleSave = () => {
		if (this.state.url !== '' && this.state.url !== null) {
			this.props.onSave(this.state.url);
			this.setState({ url: '', isFresh: true });
			this.handleClose();
		}
	};

	render() {
		const {
			existingUrls,
			translation: { translate },
		} = this.props;

		let errors: string | null = null;
		if (this.state.url) {
			const showValidationError = !(isUrlValid(this.state.url) || this.state.isFresh);
			const alreadyExists = existingUrls.includes(this.state.url);
			let hostname = '';
			try {
				// eslint-disable-next-line no-unused-expressions
				!showValidationError && (hostname = new URL(this.state.url).hostname);
			} catch (err) {
				console.error(err);
			}

			const isWhitelisted = util.url.hostIsWhitelisted(hostname);
			const isBlacklisted = util.url.hostIsBlacklisted(hostname, HOST_BLACKLIST);

			errors = alreadyExists ? translate('article.meta.teasers.alreadyExistsError') : errors;
			errors =
				!isWhitelisted || isBlacklisted
					? translate('article.meta.teasers.isNotWhitelisted', { hostname })
					: errors;
			errors = showValidationError ? translate('article.meta.teasers.invalidUrlError') : errors;
		}

		return (
			<AddURLWrapper>
				<Form>
					<Input
						onChange={this.handleChange}
						value={this.state.url}
						errors={errors}
						onPressEnter={
							// stopPropagation and save
							(ev: KeyboardEvent<HTMLInputElement>) => {
								ev.stopPropagation();
								ev.preventDefault();
								return !errors && this.handleSave();
							}
						}
						placeholder="URL einfügen"
					/>
				</Form>
				<Footer>
					<Button
						className="save"
						type="primary"
						disabled={!!errors || this.state.url === ''}
						onClick={!errors ? this.handleSave : undefined}
						data-testid="save-button"
					>
						{translate('article.meta.teasers.save')}
					</Button>
					<ArticleMetaPanelInfo help={translate('article.meta.teasers.whiteListedURLS')} />
					<FooterButton
						data-testid="closebutton"
						className="flex-end remove"
						onClick={this.handleClose}
					>
						<TrashIcon />
					</FooterButton>
				</Footer>
			</AddURLWrapper>
		);
	}
}

export default withTranslation(ArticleMetaTeasersItemFresh);
