import { Input, Form, Button } from 'antd';
import { type ChangeEvent, Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import config from '../../../config';
import type { WithTranslationProps } from '../../../translation';
import { Confirm } from '../../Icon';

const TITLE_MAXLENGTH = config.articleEditor.meta.links.maxLength;

const FormItem = styled(Form.Item)`
	margin-bottom: 0px !important;
	.ant-form-explain {
		text-align: end;
		font-size: 12px;
	}
`;

const Wrapper = styled.div`
	display: flex;
`;

const AddModeWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 20px;
`;

const FormWrapper = styled.div`
	width: 100%;
`;

export const AddButtonWrapper = styled.div`
	text-align: right;
`;

const SaveHandlerWrapper = styled.div`
	margin-left: 10px;
	display: flex;
	align-items: center;
`;

export const SaveAction = styled('button')`
	color: ${({ theme }) => theme.colors.primaryBlue};
	cursor: 'pointer';
	font-size: 1.5em;
`;

export type EditedElements = {
	label?: string;
	url?: string;
};

export type State = {
	isExternal: boolean | undefined | null;
	label: string;
	url: string;
	wasEdited: EditedElements | undefined | null;
};

type Props = WithTranslationProps &
	AddModeProps & {
		isExternal?: boolean;
		label?: string | null;
		url?: string | null;
	};

type AddModeProps = AddModeDisabledProps | AddModeEnabledProps;

interface AddModeEnabledProps {
	addMode: true;
	onAdd: (data: EditedElements) => void;
}

interface AddModeDisabledProps {
	addMode?: false;
	onSave: (data?: EditedElements | null) => void;
}

const initialState = {
	isExternal: false,
	label: '',
	url: '',
	wasEdited: {},
};

class ArticleMetaLinksEditorComponent extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			...initialState,
			isExternal: props.isExternal,
			label: props.label ?? '',
			url: props.url ?? '',
		};
	}

	handleAdd = () => {
		const { onAdd } = this.props as AddModeEnabledProps;
		const output = {
			isExternal: this.state.isExternal,
			label: this.state.label,
			url: this.state.url,
		};

		onAdd(output);
		this.setState(initialState);
	};

	handleChangeLabel = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value },
		} = event;

		this.setState((prevState) => ({
			...prevState,
			label: value,
			wasEdited: {
				...prevState.wasEdited,
				label: value,
			},
		}));
	};

	handleChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value },
		} = event;

		this.setState((prevState) => ({
			...prevState,
			url: value,
			wasEdited: {
				...prevState.wasEdited,
				url: value,
			},
		}));
	};

	handleSave = () => {
		const { onSave } = this.props as AddModeDisabledProps;

		onSave(this.state.wasEdited);
		this.setState(initialState);
	};

	render() {
		const {
			addMode,
			translation: { translate },
		} = this.props;
		const { label, url } = this.state;

		return (
			<Wrapper>
				<FormWrapper>
					<FormItem
						help={translate('article.meta.links.characterCountText', {
							currentCount: label ? `${label.length}` : 0,
							titleMaxLength: TITLE_MAXLENGTH,
						})}
						validateStatus={label && label.length > TITLE_MAXLENGTH ? 'error' : 'success'}
					>
						<Input
							onChange={this.handleChangeLabel}
							placeholder={translate('article.meta.links.titlePlaceholder')}
							value={label}
						/>
					</FormItem>
					<FormItem>
						<Input
							onChange={this.handleChangeUrl}
							placeholder={translate('article.meta.links.linkPlaceholder')}
							value={url}
						/>
					</FormItem>
					{addMode && (
						<AddModeWrapper>
							<AddButtonWrapper>
								<Button
									onClick={this.handleAdd}
									type="primary"
									data-testid="addbutton"
									disabled={
										!(
											url &&
											url.length > 0 &&
											label &&
											label.length > 0 &&
											label.length <= TITLE_MAXLENGTH
										)
									}
								>
									{translate('article.meta.links.addButtonTitle')}
								</Button>
							</AddButtonWrapper>
						</AddModeWrapper>
					)}
				</FormWrapper>
				{!addMode ? (
					<SaveHandlerWrapper>
						<SaveAction
							disabled={!(url?.length > 0 && label?.length > 0 && label?.length <= TITLE_MAXLENGTH)}
							onClick={this.handleSave}
							data-testid="savebutton"
						>
							<Confirm />
						</SaveAction>
					</SaveHandlerWrapper>
				) : null}
			</Wrapper>
		);
	}
}

export const ArticleMetaLinksEditor = withTranslation(ArticleMetaLinksEditorComponent);
