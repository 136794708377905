/**
 * @generated SignedSource<<04ce6ad021d365315c5ad10827e46c16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BoardLinkPickerQuery$variables = {};
export type BoardLinkPickerQuery$data = {
  readonly allBoards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: string;
        readonly fullSlug: string;
        readonly protected: boolean;
        readonly rowId: string;
        readonly title: string;
        readonly updatedAt: string | null;
      } | null;
    }>;
  } | null;
};
export type BoardLinkPickerQuery = {
  response: BoardLinkPickerQuery$data;
  variables: BoardLinkPickerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "status": "PUBLISHED"
    }
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullSlug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "protected",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardLinkPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "BoardsConnection",
        "kind": "LinkedField",
        "name": "allBoards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Board",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allBoards(condition:{\"status\":\"PUBLISHED\"},first:1000)"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BoardLinkPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "BoardsConnection",
        "kind": "LinkedField",
        "name": "allBoards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Board",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allBoards(condition:{\"status\":\"PUBLISHED\"},first:1000)"
      }
    ]
  },
  "params": {
    "cacheID": "09b26b18ab3fe85c0c2cc67549180166",
    "id": null,
    "metadata": {},
    "name": "BoardLinkPickerQuery",
    "operationKind": "query",
    "text": "query BoardLinkPickerQuery {\n  allBoards(first: 1000, condition: {status: PUBLISHED}) {\n    edges {\n      node {\n        createdAt\n        fullSlug\n        protected\n        rowId\n        title\n        updatedAt\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f35acc7f15cebcbc6bf83dde50151ad";

export default node;
