import styled from 'styled-components';

import { AM_TYPE_LINK } from '../../../constants';

import { Content } from './AssetManagerItemControl';
import TypeIcon from './TypeIcon';

type AssetManagerItemLinkProps = {
	url: string;
};

const Link = styled.div`
	font-size: 1.2rem;
	background-color: #ffffff;
	color: #000000;
	width: 100%;
	border-radius: 2px;
	padding: 0.2rem 1rem;
	width: 100%;
`;

function AssetManagerItemLink({ url }: AssetManagerItemLinkProps) {
	return (
		<>
			<TypeIcon type={AM_TYPE_LINK} />
			<Content>
				<Link>{url}</Link>
			</Content>
		</>
	);
}

export default AssetManagerItemLink;
