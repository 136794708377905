import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateSelectionAction } from '..';

import updateSelection from './updateSelection';

const deselect =
	(id: string): AppThunkAction<UpdateSelectionAction> =>
	(dispatch, getState) => {
		const { assetManager } = getState();
		dispatch(updateSelection([...assetManager.selected.filter((item) => item !== id)]));
	};

export default deselect;
