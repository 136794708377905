import { Button, Form, Input, Modal } from 'antd';
import { useEffect } from 'react';

import { useTranslate } from '@/client/translation/useTranslate';

type InlineLinkEditorProps = {
	href: string;
	text: string;
	open?: boolean;
	onDelete: () => void;
	onCancel: () => void;
	onOk: (result: { href: string; text: string }) => void;
};

export function InlineLinkEditor({
	text,
	href,
	open,
	onCancel: handleCancel,
	onDelete: handleDelete,
	onOk: handleOk,
}: InlineLinkEditorProps) {
	const [form] = Form.useForm<{ href: string; text: string }>();
	const translate = useTranslate();

	useEffect(() => {
		form.setFieldValue('text', text);
		form.setFieldValue('href', href);
	}, [text, href, form]);

	return (
		<Modal
			open={open ?? false}
			forceRender
			title={translate('richTextEditor.inlineLinkEditor.title')}
			onCancel={handleCancel}
			footer={[
				<Button key="delete" danger={true} onClick={handleDelete}>
					{translate('richTextEditor.inlineLinkEditor.deleteLink')}
				</Button>,
				<Button key="cancel" onClick={handleCancel}>
					{translate('richTextEditor.inlineLinkEditor.cancel')}
				</Button>,
				<Button key="submit" type="primary" onClick={() => form.submit()}>
					{translate('richTextEditor.inlineLinkEditor.ok')}
				</Button>,
			]}
		>
			<Form form={form} onFinish={handleOk} scrollToFirstError>
				<Form.Item
					name="text"
					label={translate('richTextEditor.inlineLinkEditor.linkTextLabel')}
					rules={[
						{
							required: true,
							whitespace: true,
							message: translate('richTextEditor.inlineLinkEditor.emptyLinkTextError'),
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="href"
					label={translate('richTextEditor.inlineLinkEditor.linkURLLabel')}
					rules={[
						{
							type: 'url',
							required: true,
							whitespace: true,
							message: translate('richTextEditor.inlineLinkEditor.linkURLError'),
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
}
