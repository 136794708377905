import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import type { Priority } from '@/types/schema';

import ArticleMeta from '../../../ui/ArticleMeta';
import useLocalStorage from '../../../util/useLocalStorage';
import updateAction from '../actions/update';
import { articleEditorMetaSelector } from '../selectors';

import ArticleEditorMetaAuthor from './ArticleEditorMetaAuthor';
import ArticleEditorMetaCategory from './ArticleEditorMetaCategory';
import ArticleEditorMetaGeolocation from './ArticleEditorMetaGeolocation';
import ArticleEditorMetaMore from './ArticleEditorMetaMore';
import ArticleEditorMetaNotes from './ArticleEditorMetaNotes';
import ArticleEditorMetaSEO from './ArticleEditorMetaSEO';
import ArticleEditorMetaSourceOrigin from './ArticleEditorMetaSourceOrigin';
import ArticleEditorMetaTags from './ArticleEditorMetaTags';
import ArticleEditorMetaTeasers from './ArticleEditorMetaTeasers';

function ArticleEditorMeta(props: ReduxProps) {
	const [defaultActive, setDefaultActive] = useLocalStorage(
		'br24-newsroom-articleeditormeta-collapse',
		['category', 'tags', 'authors', 'sourceOrigin', 'links', 'teasers', 'geolocation', 'more']
	);

	const { publicationPriority } = props;

	return (
		<ArticleMeta
			priority={publicationPriority}
			onSelectPriority={(priority: Priority) => {
				props.update({ publicationPriority: priority });
			}}
			defaultActive={defaultActive}
			onChange={setDefaultActive}
			items={[
				<ArticleEditorMetaCategory key="category" />,
				<ArticleEditorMetaTags key="tags" />,
				<ArticleEditorMetaAuthor key="authors" />,
				<ArticleEditorMetaSourceOrigin key="sourceOrigin" />,
				<ArticleEditorMetaTeasers key="teasers" />,
				<ArticleEditorMetaGeolocation key="geolocation" />,
				<ArticleEditorMetaSEO key="seo" />,
				<ArticleEditorMetaNotes key="notes" />,
				<ArticleEditorMetaMore key="more" />,
			]}
		/>
	);
}

const connector = connect(
	(state: ReduxState) => {
		return {
			...articleEditorMetaSelector(state),
		};
	},
	{
		update: updateAction,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ArticleEditorMeta);
