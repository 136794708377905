import { STATUS_DRAFT, STATUS_REVIEW, STATUS_PUBLISHED, STATUS_DELETED } from '@sep/br24-constants';
import joi from 'joi-browser';

import { isSlugAvailable } from '../../../../../util/board';
import createJoiError from '../../../../../util/createJoiError';
import { processValidation } from '../../../../../util/processValidation';
import type { ValidationResult } from '../../../../../util/processValidation';
import type { BoardBuffer } from '../../types';

const schema = joi.object().keys({
	parentId: joi.string().required().allow(null),
	image: joi
		.object()
		.keys({
			url: joi.string().required().error(createJoiError('Es wurde kein Bild hochgeladen.')),
			title: joi
				.string()
				.required()
				.min(3)
				.max(64)
				.error(
					createJoiError(
						'Das Bild benötigt eine Bildbeschreibung die aus min. drei Zeichen besteht. Des Weiteren darf er nicht länger als 64 Zeichen sein.'
					)
				),
			altText: joi
				.string()
				.required()
				.error(createJoiError('Das Bild hat keine Bildbeschreibung.')),
			copyright: joi
				.string()
				.required()
				.error(createJoiError('Das Bild hat keine Copyrightangabe.')),
		})
		.required()
		.allow(null),
	slug: joi
		.string()
		.regex(/^\/[0-9a-z]*((-|\/)[0-9a-z]+)*$/)
		.max(64)
		.error(
			createJoiError(
				'Das URL-Kürzel darf ausschließlich Buchstaben, Zahlen und / am Anfang als auch zwischen den Buchstaben und Zahlen enthalten.'
			)
		)
		.required(),
	title: joi
		.string()
		.required()
		.min(3)
		.max(64)
		.error(createJoiError('Der Titel muss aus min. 3, max. jedoch aus 64 Zeichen bestehen.')),
	description: joi
		.string()
		.required()
		.min(3)
		.max(160)
		.error(
			createJoiError('Die Beschreibung muss aus min. 3, max. jedoch aus 160 Zeichen bestehen.')
		),
	introductionHeadline: joi
		.string()
		.allow('')
		.allow(null)
		.max(75)
		.error(createJoiError('Die Überschrift des Textfeldes darf aus max. 75 Zeichen bestehen.')),
	introductionText: joi
		.string()
		.allow('')
		.allow(null)
		.trim()
		.replace(/<[^>]*>?/gm, '')
		.max(300)
		.error(createJoiError('Das Textfeld darf aus max. 300 Zeichen bestehen')),
	categoryId: joi
		.string()
		.required()
		.allow(null)
		.error(createJoiError('Die ausgewählte Kategorie ist nicht valide.')),
	noindex: joi.boolean().required(),
	protected: joi.boolean().required(),
	status: joi.allow(STATUS_DRAFT, STATUS_REVIEW, STATUS_PUBLISHED, STATUS_DELETED).required(),
});

const defaultOptions = {
	abortEarly: false,
	allowUnknown: true,
	shouldValidateSlug: false,
} as const;

async function validateSlug(
	validationResult: ValidationResult,
	board: BoardBuffer
): Promise<ValidationResult> {
	const isAvailable = await isSlugAvailable(board.slug, board.rowId, board.parentId);
	const updatedValidationResult = { ...validationResult };
	if (!isAvailable) {
		updatedValidationResult.isValid = false;
		updatedValidationResult.error.slug = updatedValidationResult.error.slug || [];
		updatedValidationResult.error.slug.push('Es existiert bereits ein Board mit dem URL-Kürzel.');
	}
	return updatedValidationResult;
}

export default async function validator(
	board: BoardBuffer,
	options: { shouldValidateSlug?: boolean }
): Promise<ValidationResult> {
	const { shouldValidateSlug, ...joiOptions } = { ...defaultOptions, ...options };

	const res = await new Promise<ValidationResult>((resolve) => {
		joi.validate(board, schema, joiOptions, (error) => resolve(processValidation({ error })));
	});
	if (!shouldValidateSlug) {
		return res as ValidationResult;
	} else {
		return validateSlug(res, board);
	}
}
