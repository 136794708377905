/**
 * @generated SignedSource<<fa40f19c20ed06e6f784b70c0ea17276>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LockHandling_board$data = {
  readonly authorByLockedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
  } | null;
  readonly lockedBy: string | null;
  readonly lockedSince: string | null;
  readonly rowId: string;
  readonly " $fragmentType": "LockHandling_board";
};
export type LockHandling_board$key = {
  readonly " $data"?: LockHandling_board$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockHandling_board">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockHandling_board",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authorByLockedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "guid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Board",
  "abstractKey": null
};

(node as any).hash = "6f1192ea59b3d04fd27b130c9c047719";

export default node;
