/**
 * @generated SignedSource<<de52754f5c31fc8bef6112aaf66245d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AvatarLockInfoQuery$variables = {
  guid: string;
  skip: boolean;
};
export type AvatarLockInfoQuery$data = {
  readonly authorByGuid?: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
    readonly profilePicture: string | null;
  } | null;
};
export type AvatarLockInfoQuery = {
  response: AvatarLockInfoQuery$data;
  variables: AvatarLockInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "guid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "guid",
    "variableName": "guid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "guid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePicture",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AvatarLockInfoQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Author",
            "kind": "LinkedField",
            "name": "authorByGuid",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AvatarLockInfoQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Author",
            "kind": "LinkedField",
            "name": "authorByGuid",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "feadbe8411e379d3a7f27fbad3c2cd26",
    "id": null,
    "metadata": {},
    "name": "AvatarLockInfoQuery",
    "operationKind": "query",
    "text": "query AvatarLockInfoQuery(\n  $guid: String!\n  $skip: Boolean!\n) {\n  authorByGuid(guid: $guid) @skip(if: $skip) {\n    guid\n    profilePicture\n    firstname\n    lastname\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b4b4323b8d295d22b37296d878e3332";

export default node;
