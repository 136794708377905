import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ComponentProps } from 'react';
import styled from 'styled-components';

const OverlyaingToolbar = styled.div<{ isSmallStyle?: boolean }>`
	position: absolute;
	z-index: 1;
	top: -10000px;
	left: -10000px;
	opacity: 0;
	background-color: ${(props) => props.theme.colors.greyHeader};
	transition: opacity 500ms;
	border-radius: 2px;
	margin-top: -0.4rem;
	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: ${(props) => (props.isSmallStyle ? '-150px' : '0')};
		right: 0;
		margin: 0 auto;
		width: 0;
		height: 0;
		border-top: solid 4px ${(props) => props.theme.colors.greyHeader};
		border-left: solid 4px transparent;
		border-right: solid 4px transparent;
	}
`;

export default OverlyaingToolbar;

interface ButtonProps {
	isActive: boolean;
	disabled?: boolean;
}

function getButtonColor({ isActive, disabled }: ButtonProps, primaryColor: string) {
	if (disabled) {
		return `#bfbfbf !important`;
	}

	if (isActive) {
		return primaryColor;
	}

	return '#ffffff';
}

const Button = styled.a<ButtonProps>`
	display: inline-block;
	cursor: pointer;
	color: ${(props) => getButtonColor(props, props.theme.colors.primaryBlue)};
	padding: 0.5rem 0.7rem;
	font-size: 1.3rem;
`;

type OverlayingToolbarButtonProps = {
	icon: IconProp;
	isActive: boolean;
};

export function OverlayingToolbarButton({
	icon,
	isActive,
	onClick,
	disabled,
}: OverlayingToolbarButtonProps & ComponentProps<typeof Button>) {
	const handleButtonClick = (event) => {
		if (!disabled) {
			onClick(event);
		}
	};

	return (
		<Button isActive={isActive} onClick={handleButtonClick} disabled={disabled}>
			<FontAwesomeIcon icon={icon} />
		</Button>
	);
}
