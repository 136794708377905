import { commitMutation, type Environment, graphql } from 'react-relay';

import type {
	CreateAuthorMutation,
	AuthorInput,
} from './__generated__/CreateAuthorMutation.graphql';

const mutation = graphql`
	mutation CreateAuthorMutation($input: CreateAuthorInput!) {
		createAuthor(input: $input) {
			author {
				guid
			}
		}
	}
`;

export function createAuthorMutation(
	{
		gender,
		firstname,
		lastname,
		jobTitle,
	}: Pick<AuthorInput, 'gender' | 'firstname' | 'lastname' | 'jobTitle'>,
	environment: Environment
): Promise<CreateAuthorMutation['response']> {
	return new Promise((resolve, reject) => {
		commitMutation<CreateAuthorMutation>(environment, {
			mutation,
			variables: {
				input: {
					author: {
						gender,
						firstname,
						lastname,
						jobTitle,
						isLdapUser: false,
						isActive: true,
					},
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
}
