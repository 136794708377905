import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';

import { useTranslate } from '@/client/translation/useTranslate';

import environment from '../../../../environment';
import styles from '../../../../styles';

import BoardsTeaserSearchContainer from './BoardsTeaserSearchContainer';
import type { BoardsTeaserSearchQuery } from './__generated__/BoardsTeaserSearchQuery.graphql';

type Props = {
	searchTerm: string | undefined | null;
	category: string | undefined | null;
};

export default ({ searchTerm }: Props) => {
	const translate = useTranslate();

	return (
		<QueryRenderer<BoardsTeaserSearchQuery>
			environment={environment}
			query={graphql`
				query BoardsTeaserSearchQuery($count: Int, $cursor: Cursor, $searchTerm: String) {
					...BoardsTeaserSearchContainer_query
				}
			`}
			variables={{
				count: 25,
				searchTerm: searchTerm || '',
			}}
			render={({ props, error }) => {
				if (error) {
					return (
						<div className={styles.contentWrapper}>
							<Alert message={translate('error')} description={error.message} type="error" />
						</div>
					);
				}

				if (!props) {
					return (
						<div className={styles.contentWrapper}>
							<div>
								<Spin size="large" />
							</div>
						</div>
					);
				}
				return <BoardsTeaserSearchContainer query={props} />;
			}}
		/>
	);
};
