import type { AppThunkAction } from '@/client/store/redux';

import type {
	ImportSophoraEntityByIDInput,
	AddItemsToImporterAction,
	AddItemGroupToImporterAction,
	RawItem,
	Image,
	ImportResultsSuccessAction,
	ActionTypeSophoraImportByID,
	ImportResultsRequestAction,
	ImportResultsErrorAction,
} from '../../..';
import config from '../../../../../config';
import * as constants from '../../../../../constants';
import { translate } from '../../../../../translation';
import { AM_IMPORT_SOPHORA_ENTITY_BY_ID } from '../../../actionTypes';
import { addItemGroupToImporter, addItemsToImporter } from '../../addItemsToImporter';
import { error, request, success } from '../importUtils';

type SophoraEntityImage = {
	url: null | string;
	title: null | string;
	description: null | string;
	copyright: null | string;
};
type MediaMetaData = {
	assetId: string;
	sophoraId: string;
	domainId: string;
};

type SophoraImporterResult = {
	error?: { message: string };
	title: null | string;
	headline: null | string;
	text: null | string;
	teaserText: null | string;
	image: Array<SophoraEntityImage>;
	video: Array<SophoraEntityMultimedia>;
	audio: Array<SophoraEntityMultimedia>;
};

type SophoraEntityMultimedia = {
	duration: null | number;
	thumbnail: null | SophoraEntityImage;
	title: null | string;
	url: null | string;
	meta: null | MediaMetaData;
	copyright: null | string;
	description: null | string;
};

function convertSophoraImageToImage(image: SophoraEntityImage): Image {
	return {
		url: image.url,
		title: image.title,
		altText: image.description,
		copyright: image.copyright,
	};
}

const convertSophoraResponseToRawItems = (result: SophoraImporterResult): Array<RawItem> => {
	const items: Array<RawItem> = [];

	if (result.title) {
		items.push({
			type: constants.AM_TYPE_TEXT,
			text: [result.headline, result.title].join(': '),
			isHeadline: true,
		});
	}

	if (result.teaserText) {
		items.push({
			type: constants.AM_TYPE_TEXT,
			text: result.teaserText,
			isTeaserText: true,
		});
	}

	if (result.text) {
		items.push({
			type: constants.AM_TYPE_TEXT,
			text: result.text,
		});
	}

	if (result.audio) {
		result.audio.forEach((audio) => {
			items.push({
				type: constants.AM_TYPE_AUDIO,
				url: audio.url,
				title: audio.title,
				altText: audio.description,
				copyright: audio.copyright,
				thumbnail: audio.thumbnail ? convertSophoraImageToImage(audio.thumbnail) : null,
				duration: audio.duration,
				meta: JSON.stringify(audio.meta),
			});
		});
	}

	if (result.video) {
		result.video.forEach((video) => {
			items.push({
				type: constants.AM_TYPE_VIDEO,
				url: video.url,
				title: video.title,
				altText: video.description,
				copyright: video.copyright,
				thumbnail: video.thumbnail ? convertSophoraImageToImage(video.thumbnail) : null,
				duration: video.duration,
				meta: JSON.stringify(video.meta),
			});
		});
	}

	if (result.image) {
		result.image.forEach((image) => {
			items.push({
				type: constants.AM_TYPE_IMAGE,
				...convertSophoraImageToImage(image),
			});
		});
	}

	return items;
};

export function importSophora(
	sophoraId: ImportSophoraEntityByIDInput
): AppThunkAction<
	| ImportResultsSuccessAction<ActionTypeSophoraImportByID>
	| ImportResultsRequestAction<ActionTypeSophoraImportByID>
	| ImportResultsErrorAction<ActionTypeSophoraImportByID>
	| AddItemsToImporterAction
	| AddItemGroupToImporterAction
> {
	return async (dispatch, getState) => {
		const { assetManager } = getState();

		if (assetManager.isImporterWorking) {
			return;
		}

		dispatch(request(AM_IMPORT_SOPHORA_ENTITY_BY_ID));

		try {
			const res = await fetch(`${config.VITE_SOPHORA_URL_EXT}/sophora/${sophoraId}`);

			if (res.status !== 200) {
				// TODO: describe error
				throw new Error(`${translate('error')} ${res.status}: ${await res.text()}`);
			}

			const data = await res.json();
			const importerResult: SophoraImporterResult = { ...data };

			if (importerResult?.error) {
				throw new Error(importerResult.error.message);
			}
			dispatch(addItemGroupToImporter(constants.AM_IMPORTER_SOPHORA, sophoraId));
			const items = convertSophoraResponseToRawItems(importerResult);

			const { image, audio, video, ...additionalData } = importerResult;

			dispatch(addItemsToImporter(constants.AM_IMPORTER_SOPHORA, items, sophoraId, additionalData));
			dispatch(success(AM_IMPORT_SOPHORA_ENTITY_BY_ID));
		} catch (err) {
			dispatch(error(AM_IMPORT_SOPHORA_ENTITY_BY_ID, err as Error));
		}
	};
}
