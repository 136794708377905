import { TYPE_ARTICLE, TYPE_BOARD } from '@sep/br24-constants';
import { darken, lighten } from 'polished';
import { Component, type MouseEvent, type MouseEventHandler } from 'react';
import styled, { keyframes } from 'styled-components';

import type { CreatorType } from '../Header';
import { CreateArticle, CreateBoard, CreateExternalLink, CreatePlus } from '../Icon';

const TYPE_TEASER = 'TEASER';

export const Button = styled.div`
	display: inline-block;
	height: ${(props) => props.theme.header.height};
	min-width: ${(props) => props.theme.header.height};
	height: ${(props) => props.theme.header.height};
	min-height: ${(props) => props.theme.header.height};
	background-color: ${(props) => props.theme?.colors.primaryBlue};
	border: 0;
	padding: 0;
	margin: 0;
	color: ${(props) => props.theme.colors.br24Font};
	line-height: ${(props) => props.theme.header.height};
	text-align: center;
	overflow: hidden;
	transition: all 200ms ease;
	font-size: 18px;
	&:hover {
		cursor: pointer;
		background-color: ${(props) => darken(0.05, props.theme?.colors.primaryBlue)};
	}
`;

const highlightInnerButton = keyframes`
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
`;

export const InnerButton = styled(Button)`
	&:hover {
		background: ${(props) =>
			`linear-gradient(270deg, ${props.theme?.colors.primaryBlue}, ${lighten(
				0.1,
				props.theme?.colors.primaryBlue
			)})`};
		background-size: 400% 400%;
		animation: ${highlightInnerButton} 5s ease infinite;
	}
`;

export const ExpandableButton = styled(Button)`
	transition: width 100ms linear;
	float: left;
	&:hover {
		width: 165px;
		min-width: 165px;
	}
`;

type Props = {
	onClickType: (event: MouseEvent<HTMLDivElement>, type: CreatorType) => void;
};

type State = {
	isMouseOver: boolean;
};

export default class HeaderCreatorButton extends Component<Props, State> {
	state = {
		isMouseOver: false,
	};

	handleClickArticle: MouseEventHandler<HTMLDivElement> = (event) => {
		const { onClickType } = this.props;
		onClickType(event, TYPE_ARTICLE);
	};

	handleClickBoard: MouseEventHandler<HTMLDivElement> = (event) => {
		const { onClickType } = this.props;
		onClickType(event, TYPE_BOARD);
	};

	handleClickBoardsTeaser: MouseEventHandler<HTMLDivElement> = (event) => {
		const { onClickType } = this.props;
		onClickType(event, TYPE_TEASER);
	};

	handleMouseEnter = () => this.setState({ isMouseOver: true });

	handleMouseLeave = () => this.setState({ isMouseOver: false });

	render() {
		const { isMouseOver } = this.state;

		return (
			<ExpandableButton
				data-testid="expandbutton"
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				{isMouseOver ? (
					<>
						<InnerButton data-testid="createarticle" onClick={this.handleClickArticle}>
							<CreateArticle />
						</InnerButton>
						<InnerButton data-testid="createboard" onClick={this.handleClickBoard}>
							<CreateBoard />
						</InnerButton>
						<InnerButton data-testid="createlink" onClick={this.handleClickBoardsTeaser}>
							<CreateExternalLink />
						</InnerButton>
					</>
				) : (
					<CreatePlus />
				)}
			</ExpandableButton>
		);
	}
}
