export const theme = {
	indicators: {
		dev: '#E74C3C',
		staging: '#9B59B6',
		testing: '#E7E43C',
		master: '#79CE2D',
	},
	priorities: {
		normal: '#00AD5C',
		high: '#F7B349',
		critical: '#F36D69',
	},
	boxShadow: {
		default: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`,
	},
	colors: {
		blackModulesText: '#040404',
		blackShadowModuleEditorButton: '#4C4C4C',
		br24Font: '#FFFFFE', // font
		br24Light: '#999999', // Article description text, splitting lines 50%, description of callToAction 50%, Meta: Outlines fields 50%
		dark1: '#020303', // Material-browser: background
		dark2: '#404040', // Tooltips
		dark3: '#5A5A5A', // ???
		dark4: '#121216', // asset manager
		greyHeader: '#2C2E37',
		greyModulesHelp: '#7E7E7E',
		greyModulesItem: '#999999',
		greyModulesText: '#4A4A4A',
		light1: '#E8EDF0', // Metapanel: Tags, Autoren, Links
		light2: '#EFEFEE', // Metapanel: section background
		light3: '#FCFCFC', // ???
		lightAntd: '#d9d9d9', // Metapanel: borders
		lightAntdUpload: '#fafafa', // Uploader background
		primaryBlack: '#1D2028', // Material-browser: items
		primaryBlue: '#0A9ED7', // buttons, highlights
		stateBlue: '#2B81BA', // state: freigabe
		stateGreen: '#19A084', // state: publiziert
		stateGreen2: '#2EAB61', // state: zeitgesteuert
		stateOrange: '#E57C23', // state: entwurf
		stateRed: '#E84B3D', // state: depubliziert, fehlermeldungen
		stateYellow: '#DFD01C', // state: vorlagen
		white: '#FFFFFF',
		yellowExclMark: '#FAAD14',
		boardSectionTitleColor: '#595959',
	},
	header: {
		height: '55px',
		indicatorHeight: '5px',
	},
	assetManager: {
		width: '400px',
		importer: {
			width: '80%',
			navigatorWidth: '200px',
			backgroundColor: '#1e2027',
		},
		transitionDuration: '300ms',
		navigatorHeight: '50px',
		filterHeight: '40px',
		innerPadding: '10px',
		itemColor: '#1d2027',
		typeSelectorOverlay: {
			padding: '20px',
		},
		trash: {
			height: '50px',
			expandHeight: '80px',
			innerButtonSize: '2rem',
		},
		item: {
			padding: '10px',
		},
		groups: {
			colors: [
				'#1587C6',
				'#39B3B2',
				'#FC5255',
				'#78CE2E',
				'#E01968',
				'#7d7d7d',
				'#F5B73B',
				'#D027DF',
				'#35B873',
				'#FD8F26',
			],
		},
	},
	borderRadius: '2px',
	fontSizeModuleContent: '1.3em',
} as const;

export type Theme = typeof theme;
