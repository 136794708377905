import { Col, Row, Alert, Spin, Button } from 'antd';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../environment';
import styles from '../../styles';
import { Page } from '../Page';

import NavigationOverview from './NavigationList';
import type { NavigationListContainerQuery } from './__generated__/NavigationListContainerQuery.graphql';
import translations from './translations';

export default function () {
	return (
		<QueryRenderer<NavigationListContainerQuery>
			environment={environment}
			query={graphql`
				query NavigationListContainerQuery {
					...NavigationList_query
				}
			`}
			variables={{}}
			render={({ error, props }) => {
				if (error) {
					return <Alert message="Fehler" description={error.message} type="error" />;
				}

				return (
					<Page>
						<Helmet>
							<title>{translations.NM_HTML_TITLE}</title>
						</Helmet>
						<Page.Content>
							<Row>
								<Col span={24} className={classNames(styles.pM, styles.toolbar)}>
									<Button type="primary" disabled={true}>
										Neue Navigation anlegen
									</Button>
								</Col>
							</Row>
							<div style={{ padding: '2rem' }}>
								{props ? <NavigationOverview query={props} /> : <Spin size="large" />}
							</div>
						</Page.Content>
					</Page>
				);
			}}
		/>
	);
}
