import { faBold as BoldIcon } from '@fortawesome/pro-light-svg-icons/faBold';
import { faH2 as H2Icon } from '@fortawesome/pro-light-svg-icons/faH2';
import { faH3 as H3Icon } from '@fortawesome/pro-light-svg-icons/faH3';
import { faItalic as ItalicIcon } from '@fortawesome/pro-light-svg-icons/faItalic';
import { faLink as LinkIcon } from '@fortawesome/pro-light-svg-icons/faLink';
import { faUnderline as UnderlineIcon } from '@fortawesome/pro-light-svg-icons/faUnderline';
import { faList as ListUnorderedIcon } from '@fortawesome/pro-regular-svg-icons/faList';
import { faListOl as ListOrderedIcon } from '@fortawesome/pro-regular-svg-icons/faListOl';
import { faQuoteRight as QuoteIcon } from '@fortawesome/pro-solid-svg-icons/faQuoteRight';
import { forwardRef, Component, type ForwardedRef } from 'react';
import { createPortal } from 'react-dom';
import { Range as SlateRange, Inline, Text } from 'slate';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../translation';
import { OverlayingToolbarButton } from '../OverlyingToolbar';
import OverlyaingToolbar from '../OverlyingToolbar';
import { InlineLinkEditor } from '../OverlyingToolbar/InlineLinkEditor';

import type { StateValue } from './RichTextEditor';
import {
	NodeStandard,
	MarkBold,
	MarkUnderline,
	MarkItalic,
	NodeListItem,
	NodeUnorderedList,
	NodeOrderedList,
	NodeHeading2,
	NodeHeading3,
	NodeLink,
	NodeBlockquote,
} from './formattingTypes';

type Props = {
	value: StateValue;
	onChange: (value: StateValue) => void;
	isSmallStyle: boolean;
};

type HoveringMenuState = {
	isLinkEditorOpen: boolean;
	linkText: string;
	linkHref: string;
	linkSelection: SlateRange;
};

const initialState = {
	isLinkEditorOpen: false,
	linkText: '',
	linkHref: '',
	linkSelection: null,
};

class HoveringMenu extends Component<
	Props & WithTranslationProps & { menuWrapperRef: ForwardedRef<HTMLDivElement> },
	HoveringMenuState
> {
	state = {
		...initialState,
	};

	handleClickNode = (type: string) => {
		const { value, onChange } = this.props;

		const change = value.change();
		const { document } = value;

		if (type === NodeLink.type) {
			const numberOfLinksInSelection = this.getNumberOfLinksInSelection();

			if (numberOfLinksInSelection === 1) {
				// we would like to edit a link within the current selection
				// to visually highlight this set the selection to the link text
				const inlineLink = value.inlines.find((inline) => inline.type === NodeLink.type);

				const range = SlateRange.create({
					anchorKey: inlineLink.getFirstText().key,
					focusKey: inlineLink.getLastText().key,
					focusOffset: inlineLink.getText().length,
					isFocused: true,
				});
				change.select(range);
				this.setState({
					linkHref: inlineLink.data.get('href'),
					linkText: inlineLink.text,
					isLinkEditorOpen: true,
					linkSelection: range,
				});
			} else if (numberOfLinksInSelection === 0) {
				const range = SlateRange.create(value.selection);
				this.setState({
					linkHref: '',
					linkText: value.fragment.text,
					isLinkEditorOpen: true,
					linkSelection: range,
				});
			} else {
				throw new Error('Can not edit link when multiple links are selected');
			}
		} else if (type !== NodeUnorderedList.type && type !== NodeOrderedList.type) {
			const isActive = this.hasNode(type);
			const isList = this.hasNode(NodeListItem.type);

			if (isList) {
				change
					.setBlocks(isActive ? NodeStandard.type : type)
					.unwrapBlock(NodeUnorderedList.type)
					.unwrapBlock(NodeOrderedList.type);
			} else {
				change.setBlocks(isActive ? NodeStandard.type : type);
			}
		} else {
			// handle the extra wrapping required for list buttons.
			const isList = this.hasNode(NodeListItem.type);
			const isType = value.blocks.some(
				(block) => !!document.getClosest(block.key, (parent) => parent.type === type)
			);

			if (isList && isType) {
				change
					.setBlocks(NodeStandard.type)
					.unwrapBlock(NodeUnorderedList.type)
					.unwrapBlock(NodeOrderedList.type);
			} else if (isList) {
				change
					.unwrapBlock(
						type === NodeUnorderedList.type ? NodeOrderedList.type : NodeUnorderedList.type
					)
					.wrapBlock(type);
			} else {
				change.setBlocks(NodeListItem.type).wrapBlock(type);
			}
		}

		onChange(change);
	};

	/**
	 * Returns the number of links for the current selection
	 */
	getNumberOfLinksInSelection = () => {
		const { value } = this.props;
		return value.inlines
			.map((inline) => (inline.type === NodeLink.type ? 1 : 0))
			.reduce((sum, link) => link + sum, 0);
	};

	hasMark = (type: string): boolean => {
		const { value } = this.props;

		return value.activeMarks.some((mark) => mark.type === type);
	};

	hasNode = (type: string): boolean => {
		const { value } = this.props;

		return value.blocks.some((node) => node.type === type);
	};

	handleLinkEditorCancel = () => {
		this.setState({ ...initialState });
	};

	handleLinkEditorOk = ({ text, href }: { href: string; text: string }) => {
		const change = this.props.value.change();
		const range = this.state.linkSelection;
		const { document } = this.props.value;
		this.setState({ ...initialState });

		const marks = document.getInsertMarksAtRange(range);
		const inline = Inline.create({
			type: NodeLink.type,
			data: { href },
			nodes: [Text.create(text)],
		});

		change
			.unwrapInline(NodeLink.type)
			.insertInline(inline, marks)
			.extend(0 - text.length)
			.focus();

		this.props.onChange(change);
	};

	handleLinkEditorRemoveLink = () => {
		const change = this.props.value.change();
		this.setState({ ...initialState });

		change.unwrapInline(NodeLink.type).focus();

		this.props.onChange(change);
	};

	handleClickMark(type: string) {
		const { value, onChange } = this.props;
		const updatedValue = value.change().toggleMark(type);

		onChange(updatedValue);
	}

	renderMarkButton(type: string, icon: any) {
		const onClick = () => this.handleClickMark(type);

		return <OverlayingToolbarButton icon={icon} onClick={onClick} isActive={this.hasMark(type)} />;
	}

	renderNodeButton = (type, icon) => {
		let isActive = this.hasNode(type);
		let disabled = false;

		if ([NodeOrderedList.type, NodeUnorderedList.type].includes(type)) {
			const { value } = this.props;
			const firstBlock = this.props.value.blocks.first();
			if (firstBlock) {
				const parent = value.document.getParent(firstBlock.key);
				isActive = this.hasNode('list-item') && parent && parent.type === type;
			}
		}

		if (type === NodeLink.type) {
			const { value } = this.props;
			const numberOfLinksInSelection = this.getNumberOfLinksInSelection();
			isActive = numberOfLinksInSelection === 1;
			disabled =
				numberOfLinksInSelection > 1 || value.fragment?.isEmpty || !value.fragment?.text.trim();
		}

		const onClick = () => this.handleClickNode(type);

		return (
			<OverlayingToolbarButton
				icon={icon}
				onClick={onClick}
				isActive={isActive}
				disabled={disabled}
			/>
		);
	};

	render() {
		const root = window.document.getElementById('root');
		const { menuWrapperRef, isSmallStyle } = this.props;
		const { isLinkEditorOpen, linkHref, linkText } = this.state;

		return !root
			? null
			: createPortal(
					<>
						<InlineLinkEditor
							open={isLinkEditorOpen}
							href={linkHref}
							text={linkText}
							onCancel={this.handleLinkEditorCancel}
							onOk={this.handleLinkEditorOk}
							onDelete={this.handleLinkEditorRemoveLink}
						/>
						<OverlyaingToolbar
							ref={menuWrapperRef}
							isSmallStyle={isSmallStyle}
							onMouseDown={(e) => {
								// prevent toolbar from taking focus away from editor
								e.preventDefault();
							}}
						>
							{this.renderMarkButton(MarkBold.type, BoldIcon)}
							{this.renderMarkButton(MarkItalic.type, ItalicIcon)}
							{this.renderMarkButton(MarkUnderline.type, UnderlineIcon)}
							{isSmallStyle
								? this.renderNodeButton(NodeHeading3.type, H3Icon)
								: this.renderNodeButton(NodeHeading2.type, H2Icon)}
							{this.renderNodeButton(NodeUnorderedList.type, ListUnorderedIcon)}
							{this.renderNodeButton(NodeOrderedList.type, ListOrderedIcon)}
							{this.renderNodeButton(NodeBlockquote.type, QuoteIcon)}
							{this.renderNodeButton(NodeLink.type, LinkIcon)}
						</OverlyaingToolbar>
					</>,
					root
			  );
	}
}

const WrappedHoveringMenu = withTranslation(HoveringMenu);

// forward the actual reference to apply it to the wrapper.
// eslint-disable-next-line react/no-multi-comp
export default forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => (
	<WrappedHoveringMenu {...props} menuWrapperRef={ref} />
));
