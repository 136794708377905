import type { Moment } from 'moment';
import { Component } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import ArticleMetaDraggableListItem from '../ArticleMetaDraggableListItem';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

import ArticleMetaSourceOriginItem from './ArticleMetaSourceOriginItem';
import type { SuggestionItem } from './ArticleMetaSourceOriginItem';

type HeaderProps = {
	hasItemsBelow: boolean;
};

export const Header = styled.div`
	margin-bottom: ${(props: HeaderProps) => (props.hasItemsBelow ? '1rem' : 0)};
`;

export type ItemProps = {
	date: Moment | null;
	id: string | undefined | null;
	title: string | undefined | null;
};

export type Props = WithTranslationProps & {
	input?: ReactNode;
	items?: Array<ItemProps>;
	onChange: (index: number, title?: string | null, date?: string | null) => void;
	onRemove: (index: number) => void;
	onSearch?: (value: string) => void;
	suggestions: Array<SuggestionItem>;
	key: string | number;
};

class ArticleMetaSourceOrigin extends Component<Props> {
	renderItem = (key: number, value: ItemProps) => {
		const { onRemove, onChange, onSearch, suggestions } = this.props;

		return (
			<ArticleMetaDraggableListItem key={key} index={key} onRemove={onRemove} isDraggable={false}>
				<ArticleMetaSourceOriginItem
					date={value.date}
					id={value.id}
					index={key}
					onChange={onChange}
					onSearch={onSearch}
					suggestions={suggestions}
					title={value.title}
				/>
			</ArticleMetaDraggableListItem>
		);
	};

	render() {
		const {
			input,
			items,
			onChange,
			onRemove,
			onSearch,
			suggestions,
			translation: { translate },
			...rest
		} = this.props;
		const hasItems = !!items && Array.isArray(items) && items.length > 0;

		return (
			<ArticleMetaPanel
				title={translate('article.meta.sourceOrigin.title')}
				help={translate('article.meta.sourceOrigin.help')}
				{...rest}
			>
				{input ? <Header hasItemsBelow={hasItems}>{input}</Header> : null}
				{hasItems && items ? items.map((value, key) => this.renderItem(key, value)) : null}
			</ArticleMetaPanel>
		);
	}
}

export default withTranslation(ArticleMetaSourceOrigin);
