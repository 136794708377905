import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
	MODULE_TYPE_AUDIO,
	MODULE_TYPE_EMBED,
	MODULE_TYPE_GALLERY,
	MODULE_TYPE_IMAGE,
	MODULE_TYPE_TEXT,
	MODULE_TYPE_VIDEO,
	MODULE_TYPE_LIVESTREAM,
	MODULE_ALL,
} from '@sep/br24-constants';
import { Button, Popover } from 'antd';
import { Component, createRef, type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import {
	TypeText as IconTypeText,
	TypeAudio as IconTypeAudio,
	TypeEmbedCode as IconTypeEmbedCode,
	TypeGallery as IconTypeGallery,
	TypeImage as IconTypeImage,
	TypeVideo as IconTypeVideo,
	TypeLiveStream as IconTypeLiveStream,
} from '../../Icon';
import RichTextEditor from '../../RichTextEditor';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex: 1;
`;

const IconsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${(props) => props.theme.colors.primaryBlue};
`;

const TextInputWrapper = styled.div`
	flex: 1;
	margin-left: 10px;
`;

type ModuleAllType = (typeof MODULE_ALL)[number];

interface IconItemProps<ModuleType extends ModuleAllType> {
	children: ReactNode;
	className?: string;
	onClick: (type: ModuleType) => void;
	title: string;
	type: ModuleType;
}

export const IconItem = styled(
	({ className, onClick, type, children, title }: IconItemProps<ModuleAllType>) => (
		<div className={className}>
			<button className="icon-link" onClick={() => onClick(type)}>
				{children}
			</button>
			<div className="icon-title">{title}</div>
		</div>
	)
)`
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon-title {
		display: none;
	}

	.icon-link {
		padding: 10px;
		&:hover {
			background-color: rgba(16, 142, 233, 0.1);
		}
	}

	&:hover {
		.icon-title {
			display: block;
			position: absolute;
			top: 40px;
		}
	}
`;

const StyledPopover = styled(Popover)`
	.ant-popover-content .ant-popover-inner-content {
		padding: 0;
	}
`;

const initialText = '<p></p>';

export interface OnCreate {
	(type: Omit<ModuleAllType, typeof MODULE_TYPE_TEXT>): void;
	(type: typeof MODULE_TYPE_TEXT, data: { text: string }): void;
}

interface Props extends WithTranslationProps {
	onCreate: OnCreate;
	hasTextarea?: boolean;
}

type State = {
	isPopupVisible: boolean;
	text: string;
};

class ArticleModuleCreator extends Component<Props, State> {
	state = {
		isPopupVisible: false,
		text: initialText,
	};

	editorRef = createRef();

	resetEditor = false;

	input: any;

	handleClick = (type: Omit<ModuleAllType, typeof MODULE_TYPE_TEXT>) => {
		this.setState({ isPopupVisible: false });
		this.props.onCreate(type);
	};

	handleClickAddButton = () => {
		if (this.state.text !== '' && this.state.text !== initialText) {
			this.createTextModule(this.state.text);
			this.setState({ text: '' });
		}
	};

	createTextModule = (text: string) => {
		if (this.input && typeof this.input.blur === 'function') {
			this.input.blur();
		}
		this.props.onCreate(MODULE_TYPE_TEXT, { text });
	};

	handleOpenChange = (visible: boolean) => {
		this.setState({ isPopupVisible: visible });
	};

	handleChange = (text: string) => {
		if (this.resetEditor) {
			this.setState({ text: initialText });
			this.resetEditor = false;

			return;
		}

		this.setState({
			text,
		});
	};

	handleKeyDownEnter = (value: string) => {
		this.createTextModule(value);
		this.resetEditor = true;
	};

	renderIcons = () => {
		const {
			translation: { translate },
		} = this.props;
		return (
			<IconsWrapper>
				<IconItem<FC<IconItemProps<typeof MODULE_TYPE_TEXT>>>
					onClick={this.handleClick}
					title={translate(`modules.${MODULE_TYPE_TEXT}.text`)}
					type={MODULE_TYPE_TEXT}
				>
					<IconTypeText size="lg" />
				</IconItem>
				<IconItem<FC<IconItemProps<typeof MODULE_TYPE_IMAGE>>>
					onClick={this.handleClick}
					title={translate(`modules.${MODULE_TYPE_IMAGE}.text`)}
					type={MODULE_TYPE_IMAGE}
				>
					<IconTypeImage size="lg" />
				</IconItem>
				<IconItem<FC<IconItemProps<typeof MODULE_TYPE_AUDIO>>>
					onClick={this.handleClick}
					title={translate(`modules.${MODULE_TYPE_AUDIO}.text`)}
					type={MODULE_TYPE_AUDIO}
				>
					<IconTypeAudio size="lg" />
				</IconItem>
				<IconItem<FC<IconItemProps<typeof MODULE_TYPE_VIDEO>>>
					onClick={this.handleClick}
					title={translate(`modules.${MODULE_TYPE_VIDEO}.text`)}
					type={MODULE_TYPE_VIDEO}
				>
					<IconTypeVideo size="lg" />
				</IconItem>
				<IconItem<FC<IconItemProps<typeof MODULE_TYPE_GALLERY>>>
					onClick={this.handleClick}
					title={translate(`modules.${MODULE_TYPE_GALLERY}.text`)}
					type={MODULE_TYPE_GALLERY}
				>
					<IconTypeGallery size="lg" />
				</IconItem>
				<IconItem<FC<IconItemProps<typeof MODULE_TYPE_LIVESTREAM>>>
					onClick={this.handleClick}
					title={translate(`modules.${MODULE_TYPE_LIVESTREAM}.text`)}
					type={MODULE_TYPE_LIVESTREAM}
				>
					<IconTypeLiveStream size="lg" />
				</IconItem>
				<IconItem<FC<IconItemProps<typeof MODULE_TYPE_EMBED>>>
					onClick={this.handleClick}
					title={translate(`modules.${MODULE_TYPE_EMBED}.text`)}
					type={MODULE_TYPE_EMBED}
				>
					<IconTypeEmbedCode size="lg" />
				</IconItem>
			</IconsWrapper>
		);
	};

	render() {
		const {
			translation: { translate },
			hasTextarea,
		} = this.props;
		const { isPopupVisible, text } = this.state;

		return (
			<Wrapper>
				<StyledPopover
					arrowPointAtCenter={true}
					content={this.renderIcons()}
					onOpenChange={this.handleOpenChange}
					placement="right"
					open={isPopupVisible}
				>
					<Button
						type="primary"
						shape="circle"
						icon={isPopupVisible ? <CloseOutlined /> : <PlusOutlined />}
						onClick={this.handleClickAddButton}
					/>
				</StyledPopover>
				{hasTextarea ? (
					<TextInputWrapper>
						<RichTextEditor
							onChange={this.handleChange}
							onKeyDownEnter={this.handleKeyDownEnter}
							placeholder={translate('modules.creator.textPlaceholder')}
							defaultValue={text}
						/>
					</TextInputWrapper>
				) : null}
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleModuleCreator);
