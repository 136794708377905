import { Modal } from 'antd';
import { createRefetchContainer, graphql } from 'react-relay';

import { useTranslate } from '@/client/translation/useTranslate';

import styles from './BoardsTeaser.module.scss';
import type { DeleteModal_query$data } from './__generated__/DeleteModal_query.graphql';

type Props = {
	query: DeleteModal_query$data;
	onOk: (value: string) => void;
	onCancel: () => void;
};

function DeleteModal({ query, onOk, onCancel }: Props) {
	const translate = useTranslate();

	if (
		query.boardsTeaserByRowId?.boardsSectionsItemsByBoardsTeaserId.nodes &&
		query.boardsTeaserByRowId.boardsSectionsItemsByBoardsTeaserId.nodes.length > 0
	) {
		return (
			<Modal
				width="800px"
				title={<span>{translate('boardsTeaser.action.deleteTeaser.modalTitle')}</span>}
				onOk={() => onOk('ON_BOARD')}
				onCancel={onCancel}
				open={true}
				closable={true}
				maskClosable={false}
			>
				<div>
					<h3>{translate('boardsTeaser.action.deleteTeaser.usedTeaser.problem')}</h3>
					<p>{translate('boardsTeaser.action.deleteTeaser.usedTeaser.description')}</p>
					<div>
						<ul className={styles.boardsList}>
							{query.boardsTeaserByRowId.boardsSectionsItemsByBoardsTeaserId.nodes
								.filter(
									(board, index, self) =>
										index ===
										self.findIndex(
											(item) =>
												item?.boardsSectionByBoardsSectionId?.boardId ===
												board?.boardsSectionByBoardsSectionId?.boardId
										)
								)
								.map((board, index) => {
									if (board) {
										const { rowId, title } =
											board?.boardsSectionByBoardsSectionId?.boardByBoardId ?? {};
										return (
											// eslint-disable-next-line react/no-array-index-key
											<li key={index}>
												<a href={`/boards/${rowId}`} target="_blank" rel="noopener noreferrer">
													{title}
												</a>
											</li>
										);
									}
									return false;
								})}
						</ul>
					</div>
				</div>
			</Modal>
		);
	}
	return (
		<Modal
			width="800px"
			title={<span>{translate('boardsTeaser.action.deleteTeaser.modalTitle')}</span>}
			onOk={() => onOk('NOT_ON_BOARD')}
			onCancel={onCancel}
			open={true}
			closable={true}
			maskClosable={false}
		>
			<div>
				<p>{translate('boardsTeaser.action.deleteTeaser.confirmDelete')}</p>
			</div>
		</Modal>
	);
}

export default createRefetchContainer(
	DeleteModal,
	{
		query: graphql`
			fragment DeleteModal_query on Query {
				boardsTeaserByRowId(rowId: $boardsTeaserId) {
					boardsSectionsItemsByBoardsTeaserId {
						nodes {
							boardsSectionByBoardsSectionId {
								boardId
								boardByBoardId {
									id
									rowId
									title
								}
							}
						}
					}
				}
			}
		`,
	},
	graphql`
		query DeleteModalRefetchQuery($boardsTeaserId: String!) {
			...DeleteModal_query
		}
	`
);
