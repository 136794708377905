// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { App as AuthApp } from '@sep/cms-auth-client';
import { BrowserRouter } from 'react-router-dom';
import 'symbol-observable';

import NewsroomApp from './app';
import config from './config';

let authContainer = document.getElementById('auth-root');

if (!authContainer) {
	authContainer = document.createElement('div');
	authContainer.id = 'auth-root';
	document.body.appendChild(authContainer);
}

const appRoot = document.getElementById('root');

if (!appRoot) {
	throw new Error('Root container not found');
}

const interceptUrls = [
	config.VITE_GRAPHQL_URL_EXT,
	config.VITE_SOPHORA_URL_EXT,
	`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`,
	`${config.VITE_CMS_CLIENT_URL_EXT}${config.VITE_FIREBASE_AUTH_URL_EXT}`,
];

console.log(config.VITE_CMS_CLIENT_URL_EXT);

export default () => (
	<BrowserRouter>
		<AuthApp
			/* disable silent login for dex authentication */
			disableSilentLogin={true}
			appRootDomNode={appRoot}
			auth={{
				authEndpoint: '/auth',
				client_id: config.VITE_AUTH_CLIENT_ID,
				interceptUrls,
			}}
			serviceWorkerPath="/service-worker.js"
			render={async ({ user, logout }) => {
				if (!user) {
					return null;
				}

				return <NewsroomApp user={user} logout={logout} />;
			}}
		/>
	</BrowserRouter>
);
