import { graphql, QueryRenderer, type QueryRendererProps, useRelayEnvironment } from 'react-relay';

import type { TagsRendererQuery } from './__generated__/TagsRendererQuery.graphql';

type Props = {
	searchTerm: string;
	children: QueryRendererProps<TagsRendererQuery>['render'];
};

function TagsRenderer({ searchTerm, children }: Props) {
	const environment = useRelayEnvironment();

	return (
		<QueryRenderer<TagsRendererQuery>
			environment={environment}
			query={graphql`
				query TagsRendererQuery($searchTerm: String!) {
					tags: filterTags(first: 10, searchTerm: $searchTerm) {
						edges {
							node {
								rowId
								text
								count
								status
							}
						}
					}
				}
			`}
			variables={{ searchTerm }}
			render={children}
		/>
	);
}

export default TagsRenderer;
