type Options = {
	type: string;
	key: string;
};

// reusable hotkey-plugin for slate.
export default (options: Options) => {
	if (!options.type || !options.key) {
		throw new Error('Cannot create plugin because type and/or key are missing');
	}

	return {
		onKeyDown(
			event: KeyboardEvent,
			value: {
				toggleMark: (mark: string) => void;
			}
		) {
			if ((!event.ctrlKey && !event.metaKey) || event.key !== options.key) {
				// do not remove "undefined" because something like "false" or "null" also stops the propagation
				// for the current event
				return undefined;
			}

			// finally, prevent the default and toggle the actual style (e.g. strong, italic, ...)
			event.preventDefault();
			value.toggleMark(options.type);

			return true;
		},
	};
};
