import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Button, Card, Alert } from 'antd';

import { useTranslate } from '@/client/translation/useTranslate';
import type { SourceOrigin } from '@/types/schema';

import type { CurrentFilterType } from '../Dashboard';

import PieChart from './PieChart';
import SourceOriginReferenceStatsContainer from './SourceOriginReferenceStatsContainer';

export type Props = {
	startDate: moment.Moment;
	endDate: moment.Moment;
	filter: CurrentFilterType;
};

export default ({ startDate, endDate, filter }: Props) => {
	const translate = useTranslate();

	return (
		<SourceOriginReferenceStatsContainer
			startDate={startDate.toISOString()}
			endDate={endDate.toISOString()}
			filter={filter}
			render={({ error, props }) => {
				if (!props || !props.stats || !Array.isArray(props.stats?.edges)) {
					return null;
				}

				const unsortedItems = [...props.stats.edges];

				const sortedItems: {
					node: {
						sourceOrigin: SourceOrigin;
						count: number;
					};
				}[] = [];

				const noSourceItemIndex = unsortedItems.findIndex(({ node }) => node.sourceOrigin === null);

				if (noSourceItemIndex !== -1) {
					sortedItems.push(...unsortedItems.splice(noSourceItemIndex, 1));
				}

				const finalItems = [...sortedItems, ...unsortedItems];

				return (
					<div>
						{error ? (
							<Alert message={translate('error')} description={error.message} type="error" />
						) : (
							<Card
								title={translate('dashboard.sourceOriginReferenceStats')}
								loading={error === null && props === null}
								extra={
									<Tooltip title={translate('dashboard.sourceOriginReferenceStatsHelp')}>
										<Button shape="circle" size="small" icon={<QuestionCircleOutlined />} />
									</Tooltip>
								}
							>
								{finalItems.filter(({ node: { count } }) => count > 0).length > 0 ? (
									<PieChart data={finalItems} />
								) : (
									<p>{translate('dashboard.noDataSelectedDateRange')}</p>
								)}
							</Card>
						)}
					</div>
				);
			}}
		/>
	);
};
