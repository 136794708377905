/**
 * @generated SignedSource<<1bb65b8905bc05306c58add72e4bbd0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MangoProgrammeFilter = {
  ageRestriction?: MangoIntFilter | null;
  audiences?: MangoAudienceConnectionFilter | null;
  audioFiles?: MangoAudioFileConnectionFilter | null;
  audioOnly?: MangoBooleanFilter | null;
  authors?: MangoAgentConnectionFilter | null;
  availabilityCategory?: MangoAvailabilityCategoryRelationFilter | null;
  availableUntil?: MangoDateFilter | null;
  bookmarked?: MangoBooleanFilter | null;
  broadcasts?: MangoBroadcastEventConnectionFilter | null;
  captionFiles?: MangoTimedTextFileConnectionFilter | null;
  categories?: MangoCategoryConnectionFilter | null;
  categorizations?: MangoCategorizationConnectionFilter | null;
  copyright?: MangoStringFilter | null;
  createdAt?: MangoDateFilter | null;
  credits?: MangoAgentConnectionFilter | null;
  description?: MangoStringFilter | null;
  downloadable?: MangoBooleanFilter | null;
  duration?: MangoBigIntFilter | null;
  embeddable?: MangoBooleanFilter | null;
  episodeNumber?: MangoIntFilter | null;
  episodeOf?: MangoSeriesRelationFilter | null;
  essences?: MangoFileConnectionFilter | null;
  executiveProducers?: MangoAgentConnectionFilter | null;
  externalURLS?: MangoExternalURLConnectionFilter | null;
  facebookAccounts?: MangoFacebookAccountConnectionFilter | null;
  formats?: MangoFormatConnectionFilter | null;
  genres?: MangoGenreConnectionFilter | null;
  hasOfflineUseRights?: MangoBooleanFilter | null;
  hasOnDemandRights?: MangoBooleanFilter | null;
  importedFrom?: MangoImportLocatorConnectionFilter | null;
  inSeason?: MangoSeasonRelationFilter | null;
  kicker?: MangoStringFilter | null;
  linksTo?: MangoLinkableThingRelationFilter | null;
  modifiedAt?: MangoDateFilter | null;
  newerVersion?: MangoVersionedThingRelationFilter | null;
  noIndex?: MangoBooleanFilter | null;
  olderVersion?: MangoVersionedThingRelationFilter | null;
  partOf?: MangoGroupingConnectionFilter | null;
  publications?: MangoPublicationEventConnectionFilter | null;
  relatedLinks?: MangoExternalURLConnectionFilter | null;
  relevantUntil?: MangoDateFilter | null;
  shortDescription?: MangoStringFilter | null;
  signLanguageEssences?: MangoVideoFileConnectionFilter | null;
  status?: MangoStatusRelationFilter | null;
  subjects?: MangoSubjectConnectionFilter | null;
  tags?: MangoTagConnectionFilter | null;
  teaserImages?: MangoImageConnectionFilter | null;
  teasers?: MangoCreativeWorkConnectionFilter | null;
  term?: string | null;
  title?: MangoStringFilter | null;
  trackingInfos?: MangoTrackingInfoRelationFilter | null;
  twitterAccounts?: MangoTwitterAccountConnectionFilter | null;
  versionFrom?: MangoDateFilter | null;
  videoFiles?: MangoVideoFileConnectionFilter | null;
};
export type MangoIntFilter = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number | null> | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
};
export type MangoAudienceConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoBooleanFilter = {
  eq?: boolean | null;
  ne?: boolean | null;
};
export type MangoAudioFileConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoAgentConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoAvailabilityCategoryRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoIDFilter = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
};
export type MangoDateFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string | null> | null;
  lt?: string | null;
  lte?: string | null;
  ne?: string | null;
};
export type MangoBroadcastEventConnectionFilter = {
  broadcastedOn?: MangoLivestreamConnectionFilter | null;
  createdAt?: MangoDateFilter | null;
  empty?: MangoBooleanFilter | null;
  end?: MangoDateFilter | null;
  importedFrom?: MangoImportLocatorConnectionFilter | null;
  isRerun?: MangoBooleanFilter | null;
  modifiedAt?: MangoDateFilter | null;
  publishedBy?: MangoPublicationServiceRelationFilter | null;
  start?: MangoDateFilter | null;
};
export type MangoLivestreamConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoImportLocatorConnectionFilter = {
  createdAt?: MangoDateFilter | null;
  empty?: MangoBooleanFilter | null;
  identifier?: MangoStringFilter | null;
  modifiedAt?: MangoDateFilter | null;
  system?: MangoSystemRelationFilter | null;
};
export type MangoStringFilter = {
  endsWith?: string | null;
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string | null> | null;
  lt?: string | null;
  lte?: string | null;
  matches?: string | null;
  ne?: string | null;
  startsWith?: string | null;
};
export type MangoSystemRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoPublicationServiceRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoTimedTextFileConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoCategoryConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoCategorizationConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoBigIntFilter = {
  eq?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: ReadonlyArray<any | null> | null;
  lt?: any | null;
  lte?: any | null;
  ne?: any | null;
};
export type MangoSeriesRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoFileConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoExternalURLConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoFacebookAccountConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoFormatConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoGenreConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoSeasonRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoLinkableThingRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoVersionedThingRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoGroupingConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoPublicationEventConnectionFilter = {
  createdAt?: MangoDateFilter | null;
  empty?: MangoBooleanFilter | null;
  modifiedAt?: MangoDateFilter | null;
  publishedBy?: MangoPublicationServiceRelationFilter | null;
};
export type MangoVideoFileConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoStatusRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoSubjectConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoTagConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoImageConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoCreativeWorkConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoTrackingInfoRelationFilter = {
  createdAt?: MangoDateFilter | null;
  modifiedAt?: MangoDateFilter | null;
};
export type MangoTwitterAccountConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoBroadcastEventFilter = {
  broadcastedOn?: MangoLivestreamConnectionFilter | null;
  createdAt?: MangoDateFilter | null;
  end?: MangoDateFilter | null;
  importedFrom?: MangoImportLocatorConnectionFilter | null;
  isRerun?: MangoBooleanFilter | null;
  modifiedAt?: MangoDateFilter | null;
  publishedBy?: MangoPublicationServiceRelationFilter | null;
  start?: MangoDateFilter | null;
};
export type searchLiveStreamsQuery$variables = {
  after?: string | null;
  filter?: MangoProgrammeFilter | null;
  rangeFilter?: MangoBroadcastEventFilter | null;
};
export type searchLiveStreamsQuery$data = {
  readonly viewer: {
    readonly allProgrammes: {
      readonly count: number | null;
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly broadcasts: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly broadcastedOn: {
                  readonly edges: ReadonlyArray<{
                    readonly node: {
                      readonly id: string;
                    } | null;
                  } | null> | null;
                } | null;
                readonly end: string | null;
                readonly id: string;
                readonly start: string | null;
              } | null;
            } | null> | null;
          } | null;
          readonly defaultTeaserImage: {
            readonly imageFiles: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly publicLocation: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null;
          readonly id: string;
          readonly kicker: string | null;
          readonly title: string | null;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
      } | null;
    } | null;
  } | null;
};
export type searchLiveStreamsQuery = {
  response: searchLiveStreamsQuery$data;
  variables: searchLiveStreamsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rangeFilter"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "MangoPageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kicker",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicLocation",
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "rangeFilter"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "searchLiveStreamsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "MangoProgrammeConnection",
            "kind": "LinkedField",
            "name": "allProgrammes",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MangoProgrammeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "defaultTeaserImage",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoImageFileConnection",
                            "kind": "LinkedField",
                            "name": "imageFiles",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MangoImageFileEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v11/*: any*/),
                        "concreteType": "MangoBroadcastEventConnection",
                        "kind": "LinkedField",
                        "name": "broadcasts",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoBroadcastEventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MangoLivestreamConnection",
                                    "kind": "LinkedField",
                                    "name": "broadcastedOn",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "MangoLivestreamEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v7/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchLiveStreamsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "MangoProgrammeConnection",
            "kind": "LinkedField",
            "name": "allProgrammes",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MangoProgrammeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "defaultTeaserImage",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoImageFileConnection",
                            "kind": "LinkedField",
                            "name": "imageFiles",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MangoImageFileEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v10/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v11/*: any*/),
                        "concreteType": "MangoBroadcastEventConnection",
                        "kind": "LinkedField",
                        "name": "broadcasts",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoBroadcastEventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v14/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MangoLivestreamConnection",
                                    "kind": "LinkedField",
                                    "name": "broadcastedOn",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "MangoLivestreamEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v14/*: any*/),
                                              (v7/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "030b2b15dcfba6decddc071fe8ed7214",
    "id": null,
    "metadata": {},
    "name": "searchLiveStreamsQuery",
    "operationKind": "query",
    "text": "query searchLiveStreamsQuery(\n  $filter: MangoProgrammeFilter\n  $rangeFilter: MangoBroadcastEventFilter\n  $after: String\n) {\n  viewer {\n    allProgrammes(filter: $filter, after: $after) {\n      count\n      pageInfo {\n        hasNextPage\n        hasPreviousPage\n      }\n      edges {\n        cursor\n        node {\n          __typename\n          id\n          title\n          kicker\n          defaultTeaserImage {\n            __typename\n            imageFiles {\n              edges {\n                node {\n                  __typename\n                  publicLocation\n                  id\n                }\n              }\n            }\n            id\n          }\n          broadcasts(filter: $rangeFilter) {\n            edges {\n              node {\n                __typename\n                start\n                end\n                id\n                broadcastedOn {\n                  edges {\n                    node {\n                      __typename\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8549076a81fa6b0c14a62f607afb7ae4";

export default node;
