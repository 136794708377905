import { UploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Progress, Upload, Tooltip } from 'antd';
import type { UploadChangeParam } from 'antd/lib/upload';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import config from '../../../config';
import ProfileImagePlaceholder from '../../../images/profile-placeholder.png';

const { Group: ButtonGroup } = Button;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	align-items: center;

	> * {
		flex: 0 1 auto;
	}
`;

const Image = styled.img<{ isDisabled: boolean }>`
	width: 196px;
	height: 196px;
	border-radius: 100%;
	overflow: hidden;

	${(props) => (props.isDisabled ? `opacity: 0.4` : 'opacity: 1')};
`;

const Actions = styled.div`
	margin-top: 1rem;
`;

const UploadStatus = styled.div`
	width: 196px;
`;

type Props = {
	disabled: boolean;
	image: string | null;
	uploadStatus: number | undefined | null;
	onEdit: () => void;
	onDelete: () => void;
	onUpload: (info: UploadChangeParam) => void;
};

export function ProfilePicture({
	disabled,
	image,
	uploadStatus,
	onEdit,
	onDelete,
	onUpload,
}: Props) {
	const imageSrc = image ? `${image}&w=1024&h=1024` : ProfileImagePlaceholder;
	const translate = useTranslate();

	return (
		<Wrapper>
			<Image isDisabled={disabled} src={imageSrc} alt={translate('author.profilePicture.alt')} />

			<Actions>
				<ButtonGroup>
					<Upload
						action={`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`}
						multiple={false}
						accept={['image/jpg', 'image/jpeg', 'image/png'].join(',')}
						showUploadList={false}
						onChange={onUpload}
					>
						<Tooltip title={translate('author.profilePicture.upload')}>
							<Button disabled={disabled} icon={<UploadOutlined />} />
						</Tooltip>
					</Upload>
					<Tooltip title={translate('author.profilePicture.edit')}>
						<Button
							icon={<EditOutlined />}
							disabled={disabled || image === null}
							onClick={onEdit}
						/>
					</Tooltip>
					<Tooltip title={translate('author.profilePicture.delete')}>
						<Button
							disabled={disabled || image === null}
							icon={<DeleteOutlined />}
							onClick={onDelete}
						/>
					</Tooltip>
				</ButtonGroup>
			</Actions>

			{uploadStatus && (
				<UploadStatus>
					<Progress percent={uploadStatus} status="active" showInfo={false} />
				</UploadStatus>
			)}
		</Wrapper>
	);
}

ProfilePicture.defaultProp = {
	disabled: false,
};
