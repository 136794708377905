import type { ItemVideo360 } from '..';
import PlaceholderImage from '../../../images/placeholder.png';
import { AssetManagerItemVideo360 as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemVideo360;
};

function AssetManagerItemVideo360({ item }: Props) {
	return (
		<Wrapper
			image={item.thumbnail ? item.thumbnail.url ?? undefined : PlaceholderImage}
			title={item.title ?? ''}
			copyright={item.copyright}
			duration={item.duration}
		/>
	);
}

export default AssetManagerItemVideo360;
