import { STATUS_REQUEST, STATUS_SUCCESS, STATUS_ERROR } from '@sep/br24-constants';

import type {
	ImportResultsRequestAction,
	ImportResultsSuccessAction,
	ImportResultsErrorAction,
	ImportActionType,
} from '../..';

// AM_IMPORTERs in constants. Ex: AM_IMPORTER_LIVESTREAMS
export const alreadyImported = (AM_IMPORTER: string, itemGroupTitle: string, state: any) =>
	Object.keys(state.imports[AM_IMPORTER]).some(
		(storeImportsGroupTitle) => itemGroupTitle === storeImportsGroupTitle
	);

// ACTION_TYPEs found in ActionTypes.js file. Ex: IMPORT_LIVESTREAMS
export const request = <ActionType extends ImportActionType>(
	ACTION_TYPE: ActionType
): ImportResultsRequestAction<ActionType> => ({
	type: ACTION_TYPE,
	status: STATUS_REQUEST,
});

export const success = <ActionType extends ImportActionType>(
	ACTION_TYPE: ActionType
): ImportResultsSuccessAction<ActionType> => ({
	type: ACTION_TYPE,
	status: STATUS_SUCCESS,
});

export const error = <ActionType extends ImportActionType>(
	ACTION_TYPE: ActionType,
	err: Error
): ImportResultsErrorAction<ActionType> => ({
	type: ACTION_TYPE,
	status: STATUS_ERROR,
	payload: err,
});
