import { notification } from 'antd';
import update from 'immutability-helper';
import invariant from 'invariant';
import type { SetOptional } from 'type-fest';

import { randomId } from '@/client/util/br24Utils';
import { generateReducer } from '@/client/util/generateReducer';

import { translate } from '../../translation';
import { getItemIndexFromArray } from '../../util';

import * as actionTypes from './actionTypes';
import type {
	AddAuthorAction,
	AddTagAction,
	ArticleCreateFromTemplateErrorAction,
	CreateModuleAction,
	CreateRedirectionAction,
	DeleteModuleAction,
	DeleteRedirectionAction,
	InitializeAction,
	RemoveAuthorAction,
	RemoveTagAction,
	SaveErrorAction,
	SaveSuccessAction,
	State,
	UpdateAction,
	UpdateModuleAction,
	UpdateAuthorAction,
	ValidateAction,
	Tag,
	Redirection,
	Author,
} from './index';
import { createModuleFromTemplate, createRedirectionFromTemplate } from './templates';

const initialState: State = {
	isEdited: false,
	isWorking: false,
	latestFatalError: null,
	activeArticleId: null,
	articles: {},
	modules: {},
	authors: {},
	redirections: {},
	tags: {},
	user: null,
};

export default generateReducer(initialState, {
	[actionTypes.MODULE_CREATE]: (state: State, action: CreateModuleAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.MODULE_CREATE} action was called but no article was initialized.`
		);

		const { activeArticleId } = state;

		let currentState = state;

		for (const moduleDefinition of action.payload) {
			const newModuleUid = randomId();
			let newModule = {};
			let newOrderedModuleArray = activeArticleId ? state.articles[activeArticleId].modules : [];
			const newOrderedModules = { ...currentState.modules };
			let calcOrder: number | null | undefined = null;

			// if we have a given order by create element
			// we update the order as the given order requires
			// (e. g. drag to 0, then everything needs to be +1)
			if (Number.isInteger(moduleDefinition.order)) {
				calcOrder = moduleDefinition.order;

				newOrderedModuleArray = update(newOrderedModuleArray, {
					$splice: [[calcOrder, 0, newModuleUid]],
				});

				newOrderedModuleArray.forEach((id: number | string, index: number) => {
					if (newOrderedModules[id] && newOrderedModules[id].order !== index) {
						newOrderedModules[id].order = index;
						newOrderedModules[id].__meta__.updated = true;
					}
				});
			}

			// element was created at the bottom
			if (typeof moduleDefinition.order === 'undefined') {
				// when only one element is left, we set the new element
				// on first position because teaser is order 0
				calcOrder = activeArticleId ? state.articles[activeArticleId].modules.length : 0;

				if (!newOrderedModuleArray.includes(newModuleUid)) {
					newOrderedModuleArray.push(newModuleUid);
				}
			}

			// create module with incoming data
			if (moduleDefinition.data) {
				newModule = {
					// $FlowFixMe
					...createModuleFromTemplate(
						activeArticleId,
						moduleDefinition.order,
						moduleDefinition.type
					),
					...moduleDefinition.data,
					id: newModuleUid,
					rowId: null,
					order: calcOrder,
				};
				// create module with empty data
			} else {
				newModule = {
					// $FlowFixMe
					...createModuleFromTemplate(
						activeArticleId,
						moduleDefinition.order,
						moduleDefinition.type
					),
					id: newModuleUid,
					rowId: null,
					order: calcOrder,
				};
			}

			// now we put the pre-edited objects all to together
			// for setting the new values
			const nextModules = {
				...newOrderedModules,
				[newModuleUid]: {
					...newModule,
				},
			};

			currentState = update(currentState, {
				isEdited: { $set: true },
				articles: {
					// $FlowFixMe - Why?
					[state.activeArticleId]: {
						modules: {
							$set: [...newOrderedModuleArray],
						},
					},
				},
				modules: {
					$set: nextModules,
				},
			});
		}
		return currentState;
	},
	[actionTypes.MODULE_UPDATE]: (state: State, action: UpdateModuleAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.MODULE_UPDATE} action was called but no article was initialized.`
		);

		// we need to decide whether to use $set or $merge in immutability helper because e. g. text is a string and the target
		// type for merging is object which leads to an error.
		let ihType: null | '$set' | '$merge' = null;
		const currentModule = state.modules[action.constraint];

		if (
			typeof currentModule[action.payload.type] !== 'object' ||
			Array.isArray(currentModule[action.payload.type])
		) {
			ihType = '$set';
		} else {
			ihType = '$merge';
		}

		/*
			This produces the following flow errors, even though the behavior then would be to have no meta property in the state
			which is what flow would want:

			Cannot get `module.meta` because: Either property `meta` is missing in  `EntityMeta` [1].
			Or property `meta` is missing in  `EntityValidation` [2].
			Or property `meta` is missing in  `RawModuleTypeImage` [3].
			Or property `meta` is missing in  `PersistedModule`
		*/

		// If order changes call update to just validate the module and set order
		if (action.payload.type === 'order') {
			return update(state, {
				isEdited: { $set: true },
				modules: {
					[action.constraint]: {
						__meta__: {
							updated: {
								$set: true,
							},
						},
						order: {
							$set: action.payload.value,
						},
					},
				},
			});
		}

		return update(state, {
			isEdited: { $set: true },
			modules: {
				[action.constraint]: {
					__meta__: {
						updated: {
							$set: true,
						},
					},
					meta: {
						$set: action.payload.meta || currentModule['meta'],
					},
					[action.payload.type]: {
						[ihType]: action.payload.value,
					},
				},
			},
		});
	},
	[actionTypes.MODULE_DELETE]: (state: State, action: DeleteModuleAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.MODULE_DELETE} action was called but no article was initialized.`
		);

		const { activeArticleId } = state;

		// when a integer id comes in, make sure we have an integer value for editing the state
		// because if its a string we cannot update the store properly
		const moduleId = Number(action.payload);
		const parsedModuleId = Number.isNaN(moduleId) ? action.payload : moduleId;

		const nextModuleIds = update(state.articles[activeArticleId].modules, {
			$splice: [
				[getItemIndexFromArray(state.articles[activeArticleId].modules, parsedModuleId), 1],
			],
		});

		// update all items where order have changed
		// because of the removale of an module
		// further we set the deleted element to true
		// to have it ready for graphql mutate
		const updatedModules = { ...state.modules };
		updatedModules[action.payload].__meta__.updated = false;
		updatedModules[action.payload].__meta__.deleted = true;

		nextModuleIds.forEach((id, index) => {
			if (updatedModules[id].order !== index && !updatedModules[id].__meta__.deleted) {
				// updatedModules[id].__meta__.updated = true;
				updatedModules[id].order = index;
			}
		});

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[activeArticleId]: {
					modules: {
						$set: [...nextModuleIds],
					},
				},
			},
			modules: {
				$set: updatedModules,
			},
		});
	},
	[actionTypes.UPDATE]: (state: State, action: UpdateAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.UPDATE} action was called but no article was initialized.`
		);

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[state.activeArticleId]: {
					__meta__: {
						updated: {
							$set: true,
						},
					},
					$merge: action.payload,
				},
			},
		});
	},
	[actionTypes.SAVE]: {
		[actionTypes.STATUS_REQUEST]: (state: State) =>
			update(state, {
				isWorking: { $set: true },
				latestFatalError: { $set: null },
			}),

		[actionTypes.STATUS_SUCCESS]: (state: State, action: SaveSuccessAction) => {
			invariant(
				state.activeArticleId,
				`${actionTypes.STATUS_REQUEST} action was called but no article was initialized.`
			);

			return update(state, {
				isEdited: { $set: false },
				isWorking: { $set: false },
				latestFatalError: { $set: null },
				articles: {
					[state.activeArticleId]: {
						$merge: action.payload,
					},
				},
			});
		},
		[actionTypes.STATUS_ERROR]: (state: State, action: SaveErrorAction) => {
			return update(state, {
				isWorking: {
					$set: false,
				},
				latestFatalError: {
					$set: action.payload,
				},
			});
		},
	},

	[actionTypes.EXIT]: () => {
		return initialState;
	},

	[actionTypes.INITIALIZE]: (state: State, action: InitializeAction) => {
		return update(state, {
			isEdited: { $set: false },
			activeArticleId: { $set: action.constraint },
			articles: { $set: action.payload.articles },
			authors: { $set: action.payload.authors },
			modules: { $set: action.payload.modules },
			redirections: { $set: action.payload.redirections },
			tags: { $set: action.payload.tags },
			user: { $set: action.payload.user },
		});
	},

	[actionTypes.AUTHOR_ADD]: (state: State, action: AddAuthorAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.AUTHOR_ADD} action was called but no article was initialized.`
		);

		const authorGuid = action.payload;
		const authorKeys = Object.keys(state.authors);
		const isDeleted = authorKeys.reduce((deleted, authorKey) => {
			const author = state.authors[authorKey];
			return deleted || (author.guid === authorGuid && author.__meta__.deleted);
		}, false);

		const newAuthor: Author = {
			__meta__: { created: !isDeleted, deleted: false, updated: false },
			guid: authorGuid,
			order: state.articles[state.activeArticleId].authors.length - 1 + 1,
			__validation__: null,
			firstname: null,
			lastname: null,
		};

		const relationId = `${state.activeArticleId}_${authorGuid}`;

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[state.activeArticleId]: {
					authors: {
						$push: [relationId],
					},
				},
			},
			authors: {
				[relationId]: {
					$set: newAuthor,
				},
			},
		});
	},
	[actionTypes.AUTHOR_UPDATE]: (state: State, action: UpdateAuthorAction) => {
		invariant(
			state.activeArticleId,
			'update author action was called but no article was initialized.'
		);

		const { dragIndex, hoverIndex } = action.payload;
		const { activeArticleId } = state;
		const affectedAuthorId = state.articles[activeArticleId].authors[dragIndex];

		// in order to get the new order we need pre-update the the current authors list
		// afterwards we are able to define the new position of the items
		const nextAuthors = update(state.articles[activeArticleId].authors, {
			$splice: [
				[dragIndex, 1],
				[hoverIndex, 0, affectedAuthorId],
			],
		});

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[activeArticleId]: {
					authors: { $set: nextAuthors },
				},
			},
			authors: {
				$apply: (authors: State['authors']) =>
					Object.keys(authors).reduce(
						(accumulator, id) => ({
							...accumulator,
							[id]: {
								...authors[id],
								__meta__: {
									...authors[id].__meta__,
									updated: true,
								},
								order:
									Number.isInteger(authors[id].order) && getItemIndexFromArray(nextAuthors, id),
							},
						}),
						{}
					),
			},
		});
	},
	[actionTypes.AUTHOR_REMOVE]: (state: State, action: RemoveAuthorAction) => {
		invariant(
			state.activeArticleId,
			'remove author action was called but no article was initialized.'
		);

		const { activeArticleId } = state;
		const affectedAuthorId = state.articles[activeArticleId].authors[Number(action.payload)];

		// in order to get the new order we need pre-update the the current authors list
		// afterwards we are able to define the new position of the items
		const nextAuthors = update(state.articles[activeArticleId].authors, {
			$splice: [
				[getItemIndexFromArray(state.articles[activeArticleId].authors, affectedAuthorId), 1],
			],
		});

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[activeArticleId]: {
					authors: { $set: nextAuthors },
				},
			},
			authors: {
				$apply: (authors) =>
					Object.keys(authors).reduce((accumulator, id) => {
						if (id === affectedAuthorId) {
							return {
								...accumulator,
								[id]: {
									...authors[id],
									__meta__: {
										...authors[id].__meta__,
										deleted: true,
									},
									order: null,
								},
							};
						}

						return {
							...accumulator,
							[id]: {
								...authors[id],
								__meta__: {
									...authors[id].__meta__,
									updated: true,
								},
								order: getItemIndexFromArray(nextAuthors, id),
							},
						};
					}, {}),
			},
		});
	},
	[actionTypes.REDIRECTION_CREATE]: (state: State, action: CreateRedirectionAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.REDIRECTION_CREATE} action was called but no article was initialized.`
		);

		const tempRedirectionUid = randomId();

		// check if duplicates
		const redirectionExists = Object.keys(state.redirections).filter(
			(id) => state.redirections[id].sophoraId === action.payload
		);

		if (redirectionExists.length > 0) {
			return state;
		}

		const newRedirection: Redirection = {
			...createRedirectionFromTemplate(state.activeArticleId, action.payload),
			rowId: tempRedirectionUid,
		};

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[state.activeArticleId]: {
					redirections: {
						$push: [tempRedirectionUid],
					},
				},
			},
			redirections: {
				[tempRedirectionUid]: {
					$set: newRedirection,
				},
			},
		});
	},
	[actionTypes.REDIRECTION_DELETE]: (state: State, action: DeleteRedirectionAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.REDIRECTION_DELETE} action was called but no article was initialized.`
		);

		const { activeArticleId } = state;

		invariant(activeArticleId, 'remove author action was called but no article was initialized.');

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[state.activeArticleId]: {
					redirections: {
						$splice: [
							[
								getItemIndexFromArray(
									state.articles[state.activeArticleId].redirections,
									action.payload
								),
								1,
							],
						],
					},
				},
			},
			redirections: {
				[action.payload]: {
					__meta__: {
						deleted: {
							$set: true,
						},
					},
				},
			},
		});
	},
	[actionTypes.TAG_ADD]: (state: State, action: AddTagAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.TAG_ADD} action was called but no article was initialized.`
		);

		const { id: tagId, count, text, status } = action.payload;

		let hasTagText = false;
		Object.keys(state.tags).forEach((key) => {
			hasTagText =
				hasTagText || (state.tags[key].text === text && !state.tags[key].__meta__.deleted);
		});

		// do not add duplicates
		if (state.articles[state.activeArticleId].tags.includes(tagId) || hasTagText) {
			return state;
		}

		let nextTag: SetOptional<Pick<Tag, '__meta__' | 'rowId'>, 'rowId'>;
		if (Number.isInteger(tagId)) {
			// If one removes a tag from an article and adds it again, it is as if nothing has been done.
			// This prevents trying to readd the tags in the connecting table that leads to duplicate key error
			const isDeleted = state.tags[tagId] ? state.tags[tagId].__meta__.deleted : false;

			nextTag = {
				__meta__: { created: false, deleted: false, updated: !isDeleted },
				rowId: tagId as number,
			};
		} else {
			nextTag = {
				__meta__: { created: true, deleted: false, updated: false },
			};
		}

		const newTag: Tag = {
			...nextTag,
			count,
			text,
			status,
			__validation__: null,
		};

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[state.activeArticleId]: {
					tags: {
						$push: [tagId],
					},
				},
			},
			tags: {
				[tagId]: {
					$set: newTag,
				},
			},
		});
	},
	[actionTypes.TAG_REMOVE]: (state: State, action: RemoveTagAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.TAG_REMOVE} action was called but no article was initialized.`
		);

		return update(state, {
			isEdited: { $set: true },
			articles: {
				[state.activeArticleId]: {
					tags: {
						$splice: [
							[
								getItemIndexFromArray(state.articles[state.activeArticleId].tags, action.payload),
								1,
							],
						],
					},
				},
			},
			tags: {
				[action.payload]: {
					__meta__: { deleted: { $set: true } },
				},
			},
		});
	},
	[actionTypes.VALIDATE]: (state: State, action: ValidateAction) => {
		invariant(
			state.activeArticleId,
			`${actionTypes.VALIDATE} action was called but no article was initialized.`
		);

		return update(state, {
			[action.payload.membership]: {
				[action.payload.id]: {
					__validation__: {
						$set: action.payload.result,
					},
				},
			},
		});
	},
	[actionTypes.ARTICLE_CREATE_FROM_TEMPLATE]: {
		[actionTypes.STATUS_REQUEST]: (state: State) =>
			update(state, {
				isWorking: { $set: true },
			}),

		[actionTypes.STATUS_SUCCESS]: (state: State) => {
			notification.success({
				message: translate('modals.articleCreateFromTemplateSuccess.title'),
				description: translate('modals.articleCreateFromTemplateSuccess.content'),
				duration: 3,
			});

			return update(state, {
				isWorking: { $set: false },
			});
		},
		[actionTypes.STATUS_ERROR]: (state: State, action: ArticleCreateFromTemplateErrorAction) => {
			const errorMsg = action.payload && action.payload.message ? action.payload.message : null;

			notification.error({
				message: translate('modals.articleCreateFromTemplateError.title'),
				description: errorMsg,
			});

			return update(state, {
				isWorking: {
					$set: false,
				},
			});
		},
	},
});
