import type { AppThunkAction } from '@/client/store/redux';

import type { RemoveItemOptions, RemoveItemAction, Space } from '..';
import { AM_GLOBAL_SPACE } from '../../../constants';
import { REMOVE_ITEM } from '../actionTypes';
import * as database from '../database';
import type { AssetManagerState } from '../reducer';

const removeItem =
	(
		space: Space,
		id: string,
		{ persist = true }: Partial<RemoveItemOptions> = {},
		group?: string | null
	): AppThunkAction<RemoveItemAction> =>
	(dispatch, getState) => {
		const { activeGlobalGroupId: activeGlobalGroup, spaces } = getState().assetManager;

		const groupToRemoveFrom = group || activeGlobalGroup;

		try {
			let removeFrom:
				| undefined
				| AssetManagerState['spaces']['global'][string]
				| AssetManagerState['spaces']['personal'] = undefined;
			if (space === AM_GLOBAL_SPACE) {
				if (groupToRemoveFrom) {
					removeFrom = spaces[space][groupToRemoveFrom].items;
				}
			} else {
				removeFrom = spaces[space];
			}

			if (!removeFrom || !removeFrom[id] || removeFrom[id].isLockedForDeletion) {
				return;
			}
		} catch (error) {
			return;
		}

		let operation: Promise<unknown> = Promise.resolve();

		if (persist) {
			operation =
				space === AM_GLOBAL_SPACE
					? database.removeFromGlobalGroup(groupToRemoveFrom, id)
					: database.removeFromSpace(space, id);
		}

		operation.then(() => {
			dispatch({
				type: REMOVE_ITEM,
				payload: {
					space,
					group: groupToRemoveFrom,
					id,
				},
			});
		});
	};

export default removeItem;
