import { BOARD_BUFFER_SECTION_ITEM_ADD } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';
import { randomId } from '@/client/util/br24Utils';

import { findSectionById } from '../util/find';

import { validateSection } from './validate';

export type AddItemToSectionAction = {
	type: typeof BOARD_BUFFER_SECTION_ITEM_ADD;
	constraint: {
		boardId: string;
		sectionId: string;
	};
	payload: {
		sectionItemId: string;
		articleId?: string;
		boardsTeaserId?: string;
		boardId?: string;
		order: number;
	};
};

/**
 * Adds a new item to the section with the identifier `sectionInternalId`.
 *
 */
export default function addItemToSection(
	sectionInternalId: string,
	kind: 'article' | 'board' | 'boardsTeaser',
	id: string,
	order?: number
): AppThunkAction<AddItemToSectionAction> {
	return (dispatch, getState) => {
		const section = findSectionById(sectionInternalId, getState);

		if (!section) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SECTION_ITEM_ADD,
			constraint: {
				boardId: section.__meta__.boardId,
				sectionId: section.__meta__.internalId,
			},
			payload: {
				sectionItemId: randomId(),
				[`${kind}Id`]: id,
				order: typeof order === 'undefined' ? section.items.length : order,
			},
		});

		dispatch(validateSection(sectionInternalId, { skipIfAutoValidationIsDisabled: true }));
	};
}
