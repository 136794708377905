import * as util from '@sep/br24-util';
import { Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { displayName } from '@/client/util/br24Utils';

import profileImageFallbackImage from '../../images/profileImage11.jpg';

import type { LockedByAuthor_article$data } from './__generated__/LockedByAuthor_article.graphql';
import type { LockedByAuthor_board$data } from './__generated__/LockedByAuthor_board.graphql';
import type { LockedByAuthor_boardsTeaser$data } from './__generated__/LockedByAuthor_boardsTeaser.graphql';

const AvatarContainer = styled.div`
	background-image: url(${(props: { image: string }) => props.image});
	height: 50px;
	width: 50px;
	background-size: cover;
	border-radius: 50%;
	width: 32px;
	height: 32px;
`;

const TooltipDescription = styled.p`
	margin-top: 0.25rem;
	display: block;
`;

const TooltipTime = styled.span`
	font-weight: bold;
`;

type AvatarProps = {
	lockedEntity:
		| LockedByAuthor_article$data
		| LockedByAuthor_board$data
		| LockedByAuthor_boardsTeaser$data;
};

export function LockedByAuthorAvatar({ lockedEntity }: AvatarProps) {
	if (!lockedEntity.authorByLockedBy) {
		return null;
	}

	const name = displayName({
		firstname: String(lockedEntity.authorByLockedBy.firstname),
		lastname: String(lockedEntity.authorByLockedBy.lastname),
	});

	const title = lockedEntity.lockedSince ? (
		<>
			{name}
			<TooltipDescription>
				Bearbeitet seit{' '}
				<TooltipTime>{moment.utc(lockedEntity.lockedSince).local().fromNow(true)}</TooltipTime>.
			</TooltipDescription>
		</>
	) : (
		name
	);

	return (
		<Tooltip key={lockedEntity.authorByLockedBy.guid} placement="bottom" title={title}>
			<AvatarContainer
				image={
					lockedEntity.authorByLockedBy.profilePicture
						? util.image.resize(lockedEntity.authorByLockedBy.profilePicture, 'square', 's')
						: profileImageFallbackImage
				}
			/>
		</Tooltip>
	);
}
