import { Row, Col } from 'antd';

import type { BoardSectionBuffer } from '../../../store/reducers/boardBuffer';

import { PooledItem } from './SectionItem';

type Props = {
	section: BoardSectionBuffer;
};

export default function ({ section }: Props) {
	return (
		<Row gutter={12}>
			<Col span={8}>
				<PooledItem
					sectionInternalId={section.__meta__.internalId}
					itemPosition={0}
					isDroppable={!section.autofill}
				/>
			</Col>
			<Col span={8}>
				<PooledItem
					sectionInternalId={section.__meta__.internalId}
					itemPosition={1}
					isDroppable={!section.autofill}
				/>
			</Col>
			<Col span={8}>
				<PooledItem
					sectionInternalId={section.__meta__.internalId}
					itemPosition={2}
					isDroppable={!section.autofill}
					compact={true}
				/>
				<PooledItem
					sectionInternalId={section.__meta__.internalId}
					itemPosition={3}
					isDroppable={!section.autofill}
					compact={true}
				/>
				<PooledItem
					sectionInternalId={section.__meta__.internalId}
					itemPosition={4}
					isDroppable={!section.autofill}
					compact={true}
				/>
				<PooledItem
					sectionInternalId={section.__meta__.internalId}
					itemPosition={5}
					isDroppable={!section.autofill}
					compact={true}
				/>
				<PooledItem
					sectionInternalId={section.__meta__.internalId}
					itemPosition={6}
					isDroppable={!section.autofill}
					compact={true}
				/>
			</Col>
		</Row>
	);
}
