import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import { LockedByAuthorAvatar } from './LockedByAuthorAvatar';
import type { LockedByAuthor_article$data } from './__generated__/LockedByAuthor_article.graphql';
import type { LockedByAuthor_board$data } from './__generated__/LockedByAuthor_board.graphql';
import type { LockedByAuthor_boardsTeaser$data } from './__generated__/LockedByAuthor_boardsTeaser.graphql';

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

type LockedByBoardsTeaserPorps = {
	boardsTeaser: LockedByAuthor_boardsTeaser$data;
};

type LockedByArticleProps = {
	article: LockedByAuthor_article$data;
};

type LockeByBoardProps = {
	board: LockedByAuthor_board$data;
};

type Props = LockeByBoardProps | LockedByArticleProps | LockedByBoardsTeaserPorps;

function LockedByAuthorComponent<P extends Props>(props: P) {
	const lockedEntity = props['article'] || props['board'] || props['boardsTeaser'];

	return (
		<Wrapper>
			{lockedEntity.authorByLockedBy ? (
				<LockedByAuthorAvatar lockedEntity={lockedEntity} />
			) : (
				<FontAwesomeIcon icon={faMinus} />
			)}
		</Wrapper>
	);
}

export const LockedByAuthorArticle = createFragmentContainer(
	LockedByAuthorComponent<LockedByArticleProps>,
	{
		article: graphql`
			fragment LockedByAuthor_article on Article {
				lockedSince
				authorByLockedBy {
					guid
					profilePicture
					firstname
					lastname
				}
			}
		`,
	}
);

export const LockedByAuthorBoard = createFragmentContainer(
	LockedByAuthorComponent<LockeByBoardProps>,
	{
		board: graphql`
			fragment LockedByAuthor_board on Board {
				lockedSince
				authorByLockedBy {
					guid
					profilePicture
					firstname
					lastname
				}
			}
		`,
	}
);

export const LockedByAuthorBoardsTeaser = createFragmentContainer(
	LockedByAuthorComponent<LockedByBoardsTeaserPorps>,
	{
		boardsTeaser: graphql`
			fragment LockedByAuthor_boardsTeaser on BoardsTeaser {
				lockedSince
				authorByLockedBy {
					guid
					profilePicture
					firstname
					lastname
				}
			}
		`,
	}
);
