import styled from 'styled-components';

import type { ArticlesTableArticle } from './types';

type Props = {
	node: ArticlesTableArticle;
	hide?: boolean;
};

const Wrapper = styled('span')`
	font-size: 1em;
	color: #000;
`;

function ArticleOrigin(props: Props) {
	const { node, hide } = props;

	if (!node) {
		return null;
	}

	const hasSourceOrigin = node.sourceOrigin !== null;

	return hide ? (
		<Wrapper>{hasSourceOrigin ? 'Ja' : 'Nein'}</Wrapper>
	) : (
		<Wrapper>{hasSourceOrigin ? 'mit Sendungsbezug' : 'ohne Sendungsbezug'}</Wrapper>
	);
}

export default ArticleOrigin;
