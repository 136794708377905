/*
 * These typings are responsible to connect the components to thunked actions that typecheck correctly.
 * Unfortunately we have to copy the type definitions for connect in order to overwrite the default Dispatch
 * type of the react-redux implementation.
 */

import {
	// eslint-disable-next-line @typescript-eslint/no-restricted-imports
	connect as originalConnect,
	// eslint-disable-next-line @typescript-eslint/no-restricted-imports
	useDispatch as originalUseDispatch,
	type InferableComponentEnhancer,
	type InferableComponentEnhancerWithProps,
	type MapStateToPropsParam,
	type MergeProps,
	type ResolveThunks,
} from 'react-redux';
import type { ConnectOptions } from 'react-redux/es/components/connect';
import type { Action, AnyAction } from 'redux';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

import type { ReduxState } from '@/client/store/reducers';

export interface ActionWithPayload<Type, Payload> extends Action<Type> {
	payload: Payload;
}

export type AppThunkAction<A extends Action = AnyAction, ReturnType = void> = ThunkAction<
	ReturnType,
	ReduxState,
	unknown,
	A
>;

export type AppThunkActionType<A extends AppThunkAction<any, any>> = A extends AppThunkAction<
	infer T,
	any
>
	? T
	: never;

export type Dispatch<A extends Action = AnyAction> = ThunkDispatch<ReduxState, unknown, A>;

export const useDispatch: () => Dispatch = originalUseDispatch;

export const connect: Connect<ReduxState> = originalConnect;

export interface DispatchProp<A extends Action = AnyAction> {
	dispatch: Dispatch<A>;
}

export declare type MapDispatchToPropsFunction<TDispatchProps, TOwnProps> = (
	dispatch: Dispatch<Action<unknown>>,
	ownProps: TOwnProps
) => TDispatchProps;

export declare type MapDispatchToPropsFactory<TDispatchProps, TOwnProps> = (
	dispatch: Dispatch<Action<unknown>>,
	ownProps: TOwnProps
) => MapDispatchToPropsFunction<TDispatchProps, TOwnProps>;

export declare type MapDispatchToPropsNonObject<TDispatchProps, TOwnProps> =
	| MapDispatchToPropsFactory<TDispatchProps, TOwnProps>
	| MapDispatchToPropsFunction<TDispatchProps, TOwnProps>;

export declare type MapDispatchToPropsParam<TDispatchProps, TOwnProps> =
	| MapDispatchToPropsFactory<TDispatchProps, TOwnProps>
	| MapDispatchToProps<TDispatchProps, TOwnProps>;

export declare type MapDispatchToProps<TDispatchProps, TOwnProps> =
	| MapDispatchToPropsFunction<TDispatchProps, TOwnProps>
	| TDispatchProps;

export interface Connect<DefaultState = unknown> {
	(): InferableComponentEnhancer<DispatchProp>;
	/** mapState only */
	<TStateProps = {}, no_dispatch = {}, TOwnProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>
	): InferableComponentEnhancerWithProps<TStateProps & DispatchProp, TOwnProps>;
	/** mapDispatch only (as a function) */
	<no_state = {}, TDispatchProps = {}, TOwnProps = {}>(
		mapStateToProps: null | undefined,
		mapDispatchToProps: MapDispatchToPropsNonObject<TDispatchProps, TOwnProps>
	): InferableComponentEnhancerWithProps<TDispatchProps, TOwnProps>;
	/** mapDispatch only (as an object) */
	<no_state = {}, TDispatchProps = {}, TOwnProps = {}>(
		mapStateToProps: null | undefined,
		mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
	): InferableComponentEnhancerWithProps<ResolveThunks<TDispatchProps>, TOwnProps>;
	/** mapState and mapDispatch (as a function)*/
	<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
		mapDispatchToProps: MapDispatchToPropsNonObject<TDispatchProps, TOwnProps>
	): InferableComponentEnhancerWithProps<TStateProps & TDispatchProps, TOwnProps>;
	/** mapState and mapDispatch (as an object) */
	<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
		mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>
	): InferableComponentEnhancerWithProps<TStateProps & ResolveThunks<TDispatchProps>, TOwnProps>;
	/** mergeProps only */
	<no_state = {}, no_dispatch = {}, TOwnProps = {}, TMergedProps = {}>(
		mapStateToProps: null | undefined,
		mapDispatchToProps: null | undefined,
		mergeProps: MergeProps<undefined, DispatchProp, TOwnProps, TMergedProps>
	): InferableComponentEnhancerWithProps<TMergedProps, TOwnProps>;
	/** mapState and mergeProps */
	<TStateProps = {}, no_dispatch = {}, TOwnProps = {}, TMergedProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
		mapDispatchToProps: null | undefined,
		mergeProps: MergeProps<TStateProps, DispatchProp, TOwnProps, TMergedProps>
	): InferableComponentEnhancerWithProps<TMergedProps, TOwnProps>;
	/** mapDispatch (as a object) and mergeProps */
	<no_state = {}, TDispatchProps = {}, TOwnProps = {}, TMergedProps = {}>(
		mapStateToProps: null | undefined,
		mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>,
		mergeProps: MergeProps<undefined, TDispatchProps, TOwnProps, TMergedProps>
	): InferableComponentEnhancerWithProps<TMergedProps, TOwnProps>;
	/** mapState and options */
	<TStateProps = {}, no_dispatch = {}, TOwnProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
		mapDispatchToProps: null | undefined,
		mergeProps: null | undefined,
		options: ConnectOptions<State, TStateProps, TOwnProps>
	): InferableComponentEnhancerWithProps<DispatchProp & TStateProps, TOwnProps>;
	/** mapDispatch (as a function) and options */
	<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}>(
		mapStateToProps: null | undefined,
		mapDispatchToProps: MapDispatchToPropsNonObject<TDispatchProps, TOwnProps>,
		mergeProps: null | undefined,
		options: ConnectOptions<{}, TStateProps, TOwnProps>
	): InferableComponentEnhancerWithProps<TDispatchProps, TOwnProps>;
	/** mapDispatch (as an object) and options*/
	<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}>(
		mapStateToProps: null | undefined,
		mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>,
		mergeProps: null | undefined,
		options: ConnectOptions<{}, TStateProps, TOwnProps>
	): InferableComponentEnhancerWithProps<ResolveThunks<TDispatchProps>, TOwnProps>;
	/** mapState,  mapDispatch (as a function), and options */
	<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
		mapDispatchToProps: MapDispatchToPropsNonObject<TDispatchProps, TOwnProps>,
		mergeProps: null | undefined,
		options: ConnectOptions<State, TStateProps, TOwnProps>
	): InferableComponentEnhancerWithProps<TStateProps & TDispatchProps, TOwnProps>;
	/** mapState,  mapDispatch (as an object), and options */
	<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
		mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>,
		mergeProps: null | undefined,
		options: ConnectOptions<State, TStateProps, TOwnProps>
	): InferableComponentEnhancerWithProps<TStateProps & ResolveThunks<TDispatchProps>, TOwnProps>;
	/** mapState, mapDispatch, mergeProps, and options */
	<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, TMergedProps = {}, State = DefaultState>(
		mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>,
		mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>,
		mergeProps: MergeProps<TStateProps, TDispatchProps, TOwnProps, TMergedProps>,
		options?: ConnectOptions<State, TStateProps, TOwnProps, TMergedProps>
	): InferableComponentEnhancerWithProps<TMergedProps, TOwnProps>;
}
