import type { AppThunkAction } from '@/client/store/redux';

import type { RemoveItemAction } from '..';
import { assetManagerStatusSelector } from '../selectors';

import removeItem from './removeItem';

const removeAll = (): AppThunkAction<RemoveItemAction> => (dispatch, getState) => {
	const { activeSpace, spaces } = getState().assetManager;
	const { isInGlobalGroup, activeGlobalGroup } = assetManagerStatusSelector(getState());

	const affectedItems = isInGlobalGroup
		? Object.keys(activeGlobalGroup?.items ?? {})
		: Object.keys(spaces[activeSpace]);
	affectedItems.forEach((id) => dispatch(removeItem(activeSpace, id, { persist: true })));
};

export default removeAll;
