import { useState, useEffect, useRef, type ReactElement, Children, cloneElement } from 'react';
import { useDrop } from 'react-dnd';

import type { Item } from '../../AssetManager';

interface Props {
	itemTypes: Array<string>;
	acceptedItemTypes: string[];
	onAddViaAttachedDropzone?: (item: Item | undefined) => void;
	onAddViaInitialDropzone?: (item: Item | undefined) => void;
	onResetAttachedDropzone?: () => void;
	children: ReactElement<{ isDropBlocked: boolean; isOver: boolean }>;
}

export function DndCreateModuleContainer(props: Props) {
	const [isDropBlocked, setDropBlocked] = useState(false);
	const firstIsOverCheck = useRef(true);
	const { children, itemTypes, ...rest } = props;
	const { onAddViaInitialDropzone, onAddViaAttachedDropzone, acceptedItemTypes } = props;

	const [{ isOver }, dropTarget] = useDrop<
		{ item: Item | undefined },
		{ wasAdded: boolean },
		{ isOver: boolean }
	>(
		() => ({
			accept: itemTypes,
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
			}),
			canDrop: (_item, monitor) => {
				const draggedItemType = monitor.getItemType();

				return !!draggedItemType && acceptedItemTypes.includes(draggedItemType as string);
			},
			drop: ({ item: itemWithoutOrigin }) => {
				// If the user has hit the plus button to add a module, coming from one of the specific ModuleContainer components.
				if (onAddViaInitialDropzone && typeof onAddViaInitialDropzone === 'function') {
					onAddViaInitialDropzone(itemWithoutOrigin);
					return { wasAdded: true };
				}

				// If the user is dragging something into the article Editor, coming from ModulesContainer.jsx
				if (onAddViaAttachedDropzone && typeof onAddViaAttachedDropzone === 'function') {
					onAddViaAttachedDropzone(itemWithoutOrigin);
					return { wasAdded: true };
				}

				return { wasAdded: false };
			},
			hover: (_item, monitor) => {
				const hoveredItemType = monitor.getItemType();

				if (
					hoveredItemType &&
					!acceptedItemTypes.includes(hoveredItemType as string) &&
					isDropBlocked
				) {
					setDropBlocked(true);
				}
			},
		}),
		[itemTypes, isDropBlocked, onAddViaAttachedDropzone, onAddViaInitialDropzone, acceptedItemTypes]
	);

	useEffect(() => {
		// It seems like something changed in their API where isOver defaults to false, which prevents the dropzone from ever appearing with
		// the logic previously defined here and in ModulePartAttachedDropzone.jsx
		if (firstIsOverCheck.current) {
			firstIsOverCheck.current = false;
		} else if (!isOver) {
			setDropBlocked(true);
		}
	}, [isOver]);

	const childrenWithProps = Children.map(children, (child) =>
		cloneElement(child, { isDropBlocked, isOver, ...rest })
	);

	return <div ref={dropTarget}>{childrenWithProps}</div>;
}
