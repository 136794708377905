import { commitMutation, type Environment, graphql } from 'react-relay';

import type {
	CreateBoardsTeaserMutation,
	BoardsTeaserInput,
} from './__generated__/CreateBoardsTeaserMutation.graphql';

const STATUS_PUBLISHED = 'PUBLISHED';

const mutation = graphql`
	mutation CreateBoardsTeaserMutation($input: CreateBoardsTeaserInput!) {
		createBoardsTeaser(input: $input) {
			boardsTeaser {
				rowId
			}
		}
	}
`;

export function createBoardsTeaserMutation(
	{ title, description, link }: Pick<BoardsTeaserInput, 'title' | 'description' | 'link'>,
	environment: Environment
): Promise<CreateBoardsTeaserMutation['response']> {
	return new Promise((resolve, reject) => {
		commitMutation<CreateBoardsTeaserMutation>(environment, {
			mutation,
			variables: {
				input: {
					boardsTeaser: {
						title,
						description,
						link,
						status: STATUS_PUBLISHED,
					},
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
}
