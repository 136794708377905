import type { ItemLink } from '..';
import { AssetManagerItemLink as Wrapper } from '../../../ui/AssetManager';

type Props = {
	item: ItemLink;
};

function AssetManagerItemLink({ item }: Props) {
	return <Wrapper url={item.url ?? ''} />;
}

export default AssetManagerItemLink;
