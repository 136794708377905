import {
	MODULE_TYPE_VIDEO,
	MODULE_TYPE_AUDIO,
	MODULE_TYPE_VIDEO360,
	MODULE_TYPE_LIVESTREAM,
} from '@sep/br24-constants';
import { lighten } from 'polished';
import type { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { Cancel, TypeAudio, TypeVideo, TypeVideo360, TypeLiveStream } from '../../Icon';

interface DropzoneWrapperProps {
	validation?: boolean;
	isHovered?: boolean;
	isDropBlocked?: boolean;
}

const DropzoneWrapper = styled.div<DropzoneWrapperProps>`
	width: 100%;
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) =>
		props.validation
			? lighten(0.3, props.theme?.colors.stateRed)
			: props.theme?.colors.lightAntdUpload};

	border: 2px dashed
		${(props) =>
			props.isHovered && !props.isDropBlocked
				? props.theme.colors.primaryBlue
				: props.theme.colors.lightAntd};
`;

const TextWrapper = styled.div`
	width: 100%;
	text-align: center;
`;

const ItemWrapper = styled.div`
	font-size: 100px;
	> svg {
		color: ${(props) => props.theme.colors.primaryBlue};
	}
`;

const DescriptionText = styled.p`
	font-size: 1.7em;
`;

type Props = {
	type: string;
	text: string;
	validation?: boolean;
	isOver?: boolean;
	isDropBlocked?: boolean;
};

export const ArticleModuleDropzone: FunctionComponent<Props> = ({
	type,
	text,
	isOver,
	isDropBlocked,
	validation,
}) => {
	const translate = useTranslate();
	let displayItem: JSX.Element | null = null;

	if (type === MODULE_TYPE_VIDEO) {
		displayItem = <TypeVideo />;
	}

	if (type === MODULE_TYPE_AUDIO) {
		displayItem = <TypeAudio />;
	}

	if (type === MODULE_TYPE_VIDEO360) {
		displayItem = <TypeVideo360 />;
	}

	if (type === MODULE_TYPE_LIVESTREAM) {
		displayItem = <TypeLiveStream />;
	}

	if (isDropBlocked) {
		displayItem = <Cancel />;
	}

	return (
		<DropzoneWrapper isDropBlocked={isDropBlocked} isHovered={isOver} validation={validation}>
			<TextWrapper>
				<ItemWrapper>{displayItem}</ItemWrapper>
				{text && !isDropBlocked ? (
					<DescriptionText>{text}</DescriptionText>
				) : (
					<DescriptionText>
						{translate('modules.mixedDropzone.dropIsNotAllowedPlaceholder')}
					</DescriptionText>
				)}
			</TextWrapper>
		</DropzoneWrapper>
	);
};

ArticleModuleDropzone.defaultProps = {
	validation: false,
};
