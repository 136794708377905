import { SORT_EDITED_DESC } from '@sep/br24-constants';
import { Spin, Alert } from 'antd';
import type { ComponentProps } from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import type { FilterQuery } from '@/client/store/reducers/articlesManager';

import environment from '../../../environment';

import ArticlesTable from './ArticlesTable';
import type { ArticlesTableContainerQuery } from './__generated__/ArticlesTableContainerQuery.graphql';

interface Props {
	currentFilter: FilterQuery;
}

const ContentWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
`;

const SpinnerWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
	height: 90vh;
`;

const Loader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
`;

export function ArticlesTableContainer({
	currentFilter = {},
	...rest
}: Props & Omit<ComponentProps<typeof ArticlesTable>, 'filter' | 'query'>) {
	return (
		<QueryRenderer<ArticlesTableContainerQuery>
			environment={environment}
			variables={{
				sortBy: SORT_EDITED_DESC,
				...currentFilter,
				count: 50,
			}}
			query={graphql`
				query ArticlesTableContainerQuery(
					$count: Int
					$cursor: Cursor
					$searchTerm: String
					$publishedBefore: Datetime
					$categoryFilter: String
					$tagsFilter: [String]
					$capabilityFilter: [Capability]
					$statusFilter: Status
					$onlyTemplates: Boolean
					$sortBy: FilterArticlesSort
				) {
					...ArticlesTable_query
				}
			`}
			render={({ error, props: queryRendererProps }) => {
				if (error) {
					return (
						<ContentWrapper>
							<Alert message="Fehler" description={error.message} type="error" />
						</ContentWrapper>
					);
				}

				if (!queryRendererProps) {
					return (
						<SpinnerWrapper>
							<Loader>
								<Spin size="large" />
							</Loader>
						</SpinnerWrapper>
					);
				}

				return <ArticlesTable filter={currentFilter} query={queryRendererProps} {...rest} />;
			}}
		/>
	);
}
