/**
 * @generated SignedSource<<c78077e50a81948df28d210149871ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type SubscriptionHandlerattachClientToLockSubscription$variables = {};
export type SubscriptionHandlerattachClientToLockSubscription$data = {
  readonly onLockUpdate: {
    readonly authorFirstName: string | null;
    readonly authorLastName: string | null;
    readonly authorProfilePicture: string | null;
    readonly entityId: string;
    readonly lockedBy: string | null;
    readonly lockedByEntityId: string | null;
    readonly lockedSince: string | null;
    readonly rowId: string;
    readonly type: string | null;
  } | null;
};
export type SubscriptionHandlerattachClientToLockSubscription = {
  response: SubscriptionHandlerattachClientToLockSubscription$data;
  variables: SubscriptionHandlerattachClientToLockSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LockUpdateItem",
    "kind": "LinkedField",
    "name": "onLockUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lockedBy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lockedByEntityId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lockedSince",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authorFirstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authorLastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authorProfilePicture",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionHandlerattachClientToLockSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SubscriptionHandlerattachClientToLockSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ffb385516a88ed26a2f01f1f0f8624bd",
    "id": null,
    "metadata": {},
    "name": "SubscriptionHandlerattachClientToLockSubscription",
    "operationKind": "subscription",
    "text": "subscription SubscriptionHandlerattachClientToLockSubscription {\n  onLockUpdate {\n    type\n    entityId\n    rowId\n    lockedBy\n    lockedByEntityId\n    lockedSince\n    authorFirstName\n    authorLastName\n    authorProfilePicture\n  }\n}\n"
  }
};
})();

(node as any).hash = "142146cf3ede25ee9882dc53f5faa143";

export default node;
