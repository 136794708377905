import type { ReactNode } from 'react';
import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../../environment';

import type {
	PreviewLinkContainerQuery,
	PreviewLinkContainerQuery$data,
} from './__generated__/PreviewLinkContainerQuery.graphql';

type Props = {
	rowId: string;
	children: (renderProps: {
		error: Error | null;
		props: PreviewLinkContainerQuery$data | null;
		retry: (() => void) | null;
	}) => ReactNode;
};

export default function (props: Props) {
	return (
		<QueryRenderer<PreviewLinkContainerQuery>
			environment={environment}
			query={graphql`
				query PreviewLinkContainerQuery($rowId: String!) {
					boardByRowId(rowId: $rowId) {
						rowId
						title
						slug
						status
					}
				}
			`}
			variables={{
				rowId: props.rowId,
			}}
			render={props.children}
		/>
	);
}
