// this function converts the incoming objects from asset manager
// to the data schema of the article editor
// the asset manager has the same function and converts
// data which is dragged to the asset manager to the schema of the
// asset manager

import {
	MODULE_TYPE_AUDIO,
	MODULE_TYPE_IMAGE,
	MODULE_TYPE_GALLERY,
	MODULE_TYPE_VIDEO,
	MODULE_TYPE_EMBED,
	MODULE_TYPE_TEXT,
	MODULE_TYPE_LIVESTREAM,
} from '@sep/br24-constants';

import config from '@/client/config';

import {
	AM_TYPE_ARTICLE,
	AM_TYPE_TEXT,
	AM_TYPE_EMBED_CODE,
	AM_TYPE_LINK,
	AM_TYPE_GALLERY,
	AM_TYPE_IMAGE,
	AM_TYPE_BRBILD_IMAGE,
	AM_TYPE_AUDIO,
	AM_TYPE_VIDEO,
	AM_TYPE_LIVESTREAM,
	AM_TYPE_BOARD_TEASER,
	AM_TYPE_BOARD,
	AM_TYPE_VIDEO360,
} from '../../constants';
import type {
	Item,
	ItemAudio,
	ItemBrBildImage,
	ItemEmbedCode,
	ItemGallery,
	ItemImage,
	ItemLiveStream,
	ItemText,
	ItemVideo,
} from '../AssetManager';

import type {
	UnpersistedModuleTypeAudio,
	UnpersistedModuleTypeEmbed,
	UnpersistedModuleTypeGallery,
	UnpersistedModuleTypeImage,
	UnpersistedModuleTypeLivestream,
	UnpersistedModuleTypeText,
	UnpersistedModuleTypeVideo,
} from '.';

type OmitFromConvert<T extends { type: unknown; order: unknown }> = Omit<
	T,
	'order' | 'type' | '__meta__' | '__validation__'
>;

async function uploadToImageServer(url: string) {
	// We use medium image size for preview thumbnails but want to use large for the article
	const largeResUrl = url.replace('medium', 'large');

	const response = await fetch(largeResUrl);
	const blob = await response.blob();

	const headers = {};

	const file = new FormData();
	file.append('file', new File([blob], 'image.png'));

	const result = await fetch(`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`, {
		method: 'POST',
		headers,
		body: file,
	})
		.then((res) => {
			if (res.status >= 400) {
				throw new Error('Error while uploading BR Bild content to image server');
			}

			return res.json();
		})
		.catch((e) => {
			throw new Error(e);
		});

	return result;
}

function removeCommonPropertiesFromItem<T extends Item>(item: T) {
	const { type, createdAt, updatedAt, id, isLockedForDeletion, ...rest } = item;
	return rest;
}

async function convertTextItem(
	input: ItemText
): Promise<OmitFromConvert<UnpersistedModuleTypeText>> {
	const { isTeaserText, isHeadline, ...convertedItem } = removeCommonPropertiesFromItem(input);

	return {
		...convertedItem,
	};
}

async function convertEmbedCode(
	input: ItemEmbedCode
): Promise<OmitFromConvert<UnpersistedModuleTypeEmbed>> {
	const convertedItem = removeCommonPropertiesFromItem(input);

	return {
		embed: {
			thumbnail: {
				url: null,
				altText: null,
				copyright: null,
				title: null,
			},
			...convertedItem,
		},
	};
}

async function convertGallery(
	input: ItemGallery
): Promise<OmitFromConvert<UnpersistedModuleTypeGallery>> {
	const convertedItem = removeCommonPropertiesFromItem(input);

	return {
		...convertedItem,
	};
}

async function convertBrBildImage(
	input: ItemBrBildImage
): Promise<OmitFromConvert<UnpersistedModuleTypeImage>> {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { copyrightNotice, timestamp, copyrightStatus, usageConstraints, ...convertedItem } =
		removeCommonPropertiesFromItem(input);
	if (!convertedItem.url) {
		throw new Error(`Image URL to upload not present`);
	}
	const data = await uploadToImageServer(convertedItem.url);
	const imageUrl = data?.url;

	if (!imageUrl) {
		throw new Error(`Failed to retrieve URL from image server: ${convertedItem.url}`);
	}

	return {
		image: {
			...convertedItem,
			url: imageUrl,
		},
	};
}

async function convertImage(
	input: ItemImage
): Promise<OmitFromConvert<UnpersistedModuleTypeImage>> {
	const convertedItem = removeCommonPropertiesFromItem(input);

	return {
		image: {
			...convertedItem,
		},
	};
}

async function convertAudio(
	input: ItemAudio
): Promise<OmitFromConvert<UnpersistedModuleTypeAudio>> {
	const { meta, thumbnail, ...convertedItem } = removeCommonPropertiesFromItem(input);
	const audioMetaData = input.meta;

	return {
		meta: audioMetaData ?? null,
		audio: {
			thumbnail: thumbnail ?? {
				altText: null,
				copyright: null,
				title: null,
				url: null,
			},
			...convertedItem,
		},
	};
}

async function convertVideo(
	input: ItemVideo
): Promise<OmitFromConvert<UnpersistedModuleTypeVideo>> {
	const {
		meta: videoMetaData,
		thumbnail,
		...convertedItem
	} = removeCommonPropertiesFromItem(input);

	return {
		meta: videoMetaData ?? null,
		video: {
			thumbnail: thumbnail ?? {
				altText: null,
				copyright: null,
				title: null,
				url: null,
			},
			...convertedItem,
		},
	};
}

async function convertLivestream(
	input: ItemLiveStream
): Promise<OmitFromConvert<UnpersistedModuleTypeLivestream>> {
	const { broadcastId, title, kicker, start, end, image, ...convertedItem } =
		removeCommonPropertiesFromItem(input);

	return {
		livestream: {
			thumbnail: {
				altText: null,
				copyright: null,
				title: null,
				url: null,
			},
			...convertedItem,
		},
	};
}

const mapType = {
	[AM_TYPE_TEXT]: { converter: convertTextItem, type: MODULE_TYPE_TEXT },
	[AM_TYPE_EMBED_CODE]: { converter: convertEmbedCode, type: MODULE_TYPE_EMBED },
	[AM_TYPE_GALLERY]: { converter: convertGallery, type: MODULE_TYPE_GALLERY },
	[AM_TYPE_IMAGE]: { converter: convertImage, type: MODULE_TYPE_IMAGE },
	[AM_TYPE_BRBILD_IMAGE]: { converter: convertBrBildImage, type: MODULE_TYPE_IMAGE },
	[AM_TYPE_AUDIO]: { converter: convertAudio, type: MODULE_TYPE_AUDIO },
	[AM_TYPE_LIVESTREAM]: { converter: convertLivestream, type: MODULE_TYPE_LIVESTREAM },
	[AM_TYPE_VIDEO]: { converter: convertVideo, type: MODULE_TYPE_VIDEO },
	[AM_TYPE_BOARD_TEASER]: undefined,
	[AM_TYPE_BOARD]: undefined,
	[AM_TYPE_ARTICLE]: undefined,
	[AM_TYPE_LINK]: undefined,
	[AM_TYPE_VIDEO360]: undefined,
} as const;

export default async function convertItem(input: Item) {
	const moduleType = mapType[input.type];

	if (!moduleType) {
		return null;
	}

	return {
		type: moduleType.type,
		data: {
			...(await moduleType.converter(input as any)),
			__meta__: { created: true, deleted: false, updated: false },
			__validation__: null,
		},
	};
}
