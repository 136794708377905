/**
 * @generated SignedSource<<a67817d8f6f094098358bab98c213ab6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavigationListContainerQuery$variables = {};
export type NavigationListContainerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NavigationList_query">;
};
export type NavigationListContainerQuery = {
  response: NavigationListContainerQuery$data;
  variables: NavigationListContainerQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationListContainerQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NavigationList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavigationListContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {
              "type": "NAVIGATION"
            }
          }
        ],
        "concreteType": "NavigationsConnection",
        "kind": "LinkedField",
        "name": "allNavigations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NavigationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Navigation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rowId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allNavigations(condition:{\"type\":\"NAVIGATION\"})"
      }
    ]
  },
  "params": {
    "cacheID": "d30a3cb6136c27a9eb926655813b090c",
    "id": null,
    "metadata": {},
    "name": "NavigationListContainerQuery",
    "operationKind": "query",
    "text": "query NavigationListContainerQuery {\n  ...NavigationList_query\n}\n\nfragment NavigationList_query on Query {\n  allNavigations(condition: {type: NAVIGATION}) {\n    edges {\n      node {\n        id\n        rowId\n        type\n        text\n      }\n    }\n  }\n}\n"
  }
};

(node as any).hash = "b6ebeb6edf0dfcd9af921e92fb4b23e2";

export default node;
