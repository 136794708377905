/**
 * @generated SignedSource<<1c40c67825103d5b77b14454c50a2163>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BoardsTeaserItemQuery$variables = {
  boardsTeaserId: string;
};
export type BoardsTeaserItemQuery$data = {
  readonly boardsTeaser: {
    readonly image: {
      readonly url: string | null;
    } | null;
    readonly link: {
      readonly label: string | null;
    } | null;
    readonly rowId: string;
    readonly title: string;
  } | null;
};
export type BoardsTeaserItemQuery = {
  response: BoardsTeaserItemQuery$data;
  variables: BoardsTeaserItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardsTeaserId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "boardsTeaserId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Link",
  "kind": "LinkedField",
  "name": "link",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardsTeaserItemQuery",
    "selections": [
      {
        "alias": "boardsTeaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardsTeaserItemQuery",
    "selections": [
      {
        "alias": "boardsTeaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9438361e80a2f6bf77215fa691357c4d",
    "id": null,
    "metadata": {},
    "name": "BoardsTeaserItemQuery",
    "operationKind": "query",
    "text": "query BoardsTeaserItemQuery(\n  $boardsTeaserId: String!\n) {\n  boardsTeaser: boardsTeaserByRowId(rowId: $boardsTeaserId) {\n    rowId\n    title\n    image {\n      url\n      id\n    }\n    link {\n      label\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b00d60fa50aa9d2e6a58d8d600cbdc06";

export default node;
