/**
 * @generated SignedSource<<52f5146f22d9b557a40a8f66de4b40d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LivestreamModuleRendererQuery$variables = {
  programmeId: string;
};
export type LivestreamModuleRendererQuery$data = {
  readonly programme: {
    readonly __typename: "MangoProgramme";
    readonly broadcasts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly broadcastedOn: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly labels: ReadonlyArray<string | null> | null;
                readonly streamingUrls: {
                  readonly edges: ReadonlyArray<{
                    readonly node: {
                      readonly publicLocation: string | null;
                    } | null;
                  } | null> | null;
                } | null;
              } | null;
            } | null> | null;
          } | null;
          readonly end: string | null;
          readonly start: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly defaultTeaserImage: {
      readonly copyright: string | null;
      readonly imageFiles: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly publicLocation: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly shortDescription: string | null;
      readonly title: string | null;
    } | null;
    readonly kicker: string | null;
    readonly title: string | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type LivestreamModuleRendererQuery = {
  response: LivestreamModuleRendererQuery$data;
  variables: LivestreamModuleRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "programmeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "programmeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kicker",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "START_DESC"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labels",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicLocation",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v10/*: any*/)
    ],
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copyright",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v10/*: any*/),
      (v14/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LivestreamModuleRendererQuery",
    "selections": [
      {
        "alias": "programme",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mangoNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "MangoBroadcastEventConnection",
                "kind": "LinkedField",
                "name": "broadcasts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoBroadcastEventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoLivestreamConnection",
                            "kind": "LinkedField",
                            "name": "broadcastedOn",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MangoLivestreamEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      {
                                        "alias": null,
                                        "args": (v9/*: any*/),
                                        "concreteType": "MangoLivestreamResourceConnection",
                                        "kind": "LinkedField",
                                        "name": "streamingUrls",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "MangoLivestreamResourceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": (v11/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": "streamingUrls(first:1)"
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "broadcasts(orderBy:\"START_DESC\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "defaultTeaserImage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoImageFileConnection",
                    "kind": "LinkedField",
                    "name": "imageFiles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MangoImageFileEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MangoProgramme",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LivestreamModuleRendererQuery",
    "selections": [
      {
        "alias": "programme",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mangoNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "MangoBroadcastEventConnection",
                "kind": "LinkedField",
                "name": "broadcasts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoBroadcastEventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MangoLivestreamConnection",
                            "kind": "LinkedField",
                            "name": "broadcastedOn",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MangoLivestreamEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v8/*: any*/),
                                      {
                                        "alias": null,
                                        "args": (v9/*: any*/),
                                        "concreteType": "MangoLivestreamResourceConnection",
                                        "kind": "LinkedField",
                                        "name": "streamingUrls",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "MangoLivestreamResourceEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": (v15/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": "streamingUrls(first:1)"
                                      },
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "broadcasts(orderBy:\"START_DESC\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "defaultTeaserImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoImageFileConnection",
                    "kind": "LinkedField",
                    "name": "imageFiles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MangoImageFileEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MangoProgramme",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d37dd5a4145d5c3aaecc6f5bd75a6eed",
    "id": null,
    "metadata": {},
    "name": "LivestreamModuleRendererQuery",
    "operationKind": "query",
    "text": "query LivestreamModuleRendererQuery(\n  $programmeId: ID!\n) {\n  programme: mangoNode(id: $programmeId) {\n    __typename\n    ... on MangoProgramme {\n      title\n      kicker\n      broadcasts(orderBy: START_DESC) {\n        edges {\n          node {\n            __typename\n            start\n            end\n            broadcastedOn {\n              edges {\n                node {\n                  __typename\n                  labels\n                  streamingUrls(first: 1) {\n                    edges {\n                      node {\n                        __typename\n                        publicLocation\n                        id\n                      }\n                    }\n                  }\n                  id\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n      defaultTeaserImage {\n        __typename\n        title\n        shortDescription\n        copyright\n        imageFiles {\n          edges {\n            node {\n              __typename\n              publicLocation\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5e64e01e3bc5bf5a0d5ccf3df4df099";

export default node;
