import { AE_ARTICLE, AE_LINK, AE_LIVESTREAM } from '../../ArticleEditor/itemTypes';

// For now this is just in place to keep items from being deleted when you cancel a drag operation where you started it.
// Code has already been written to expand on this in a future branch.
export default function isDuplicate(
	type: string | symbol | null,
	payload: any,
	activeSpaceItems: Array<any>
): boolean {
	if (typeof payload !== 'object') {
		return false;
	}
	let itemToCheck;
	if (payload.hasOwnProperty('importedItem')) {
		itemToCheck = payload.importedItem;
	} else if (payload.hasOwnProperty('item')) {
		itemToCheck = payload.item;
	} else {
		itemToCheck = payload;
	}

	if (type === AE_LINK) {
		return activeSpaceItems.some((asset) => asset.url === itemToCheck.url) === true;
	}
	if (type === AE_LIVESTREAM) {
		return (
			activeSpaceItems.some(
				(asset) => asset.livestreamId === itemToCheck.livestream.livestreamId
			) === true
		);
	}
	// On the verteilseiten the ids are randomly generated each time, so it isn't enough to compare ids
	return (
		activeSpaceItems.some(
			(asset) =>
				asset.id === (type === AE_ARTICLE ? itemToCheck.articleId : itemToCheck.id) ||
				asset.boardId === (type === AE_ARTICLE ? itemToCheck.articleId : itemToCheck.id) ||
				asset.articleId === (type === AE_ARTICLE ? itemToCheck.articleId : itemToCheck.id) ||
				asset.boardTeaserId === (type === AE_ARTICLE ? itemToCheck.articleId : itemToCheck.id)
		) === true
	);
}
