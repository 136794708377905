import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'react-relay';

import type { UpdateBoardSectionItemMutation as UpdateBoardSectionItemMutationTypes } from './__generated__/UpdateBoardSectionItemMutation.graphql';

const mutation = graphql`
	mutation UpdateBoardSectionItemMutation($input: UpdateBoardsSectionsItemByRowIdInput!) {
		updateBoardsSectionsItemByRowId(input: $input) {
			boardsSectionsItem {
				rowId
				boardId
				articleId
				boardsTeaserId
				order
			}
		}
	}
`;

export type UpdateBoardSectionItemInput = {
	boardId?: string | null;
	articleId?: string | null;
	boardsTeaserId?: string | null;
	order?: number;
};

export type UpdateBoardSectionItemOutput = {
	readonly rowId: number;
	readonly boardId: string | undefined | null;
	readonly articleId: string | undefined | null;
	readonly boardsTeaserId: string | undefined | null;
	readonly order: number | undefined | null;
};

const execute = (
	boardSectionItemRowId: number,
	input: UpdateBoardSectionItemInput,
	environment: Environment
): Promise<UpdateBoardSectionItemMutationTypes['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<UpdateBoardSectionItemMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					rowId: boardSectionItemRowId,
					boardsSectionsItemPatch: input,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});

export default async function UpdateBoardSectionItemMutation(
	boardSectionItemRowId: number,
	input: UpdateBoardSectionItemInput,
	environment: Environment
): Promise<UpdateBoardSectionItemOutput> {
	const res = await execute(boardSectionItemRowId, input, environment);
	if (
		!res ||
		!res.updateBoardsSectionsItemByRowId ||
		!res.updateBoardsSectionsItemByRowId.boardsSectionsItem
	) {
		throw new Error('Something went wrong');
	}
	return res.updateBoardsSectionsItemByRowId.boardsSectionsItem;
}
