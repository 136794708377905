import type { ReactNode } from 'react';
import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../environment';

import type { HeaderUserQueryContainerQuery } from './__generated__/HeaderUserQueryContainerQuery.graphql';

export type InternalUser = {
	readonly guid: string;
	readonly profilePicture: string | undefined | null;
	readonly firstname: string | undefined | null;
	readonly lastname: string | undefined | null;
};

type Props = {
	guid: string;
	render: (user: null | InternalUser) => ReactNode;
};

export default function ({ guid, render }: Props) {
	return (
		<QueryRenderer<HeaderUserQueryContainerQuery>
			environment={environment}
			query={graphql`
				query HeaderUserQueryContainerQuery($guid: String!) {
					authorByGuid(guid: $guid) {
						guid
						profilePicture
						firstname
						lastname
					}
				}
			`}
			variables={{ guid }}
			render={({ error, props }) => {
				if (error) {
					throw error;
				}

				return render(props && props.authorByGuid ? props.authorByGuid : null);
			}}
		/>
	);
}
