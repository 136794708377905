import { useEffect, useState, useContext } from 'react';
import { connect, type ConnectedProps } from 'react-redux';

import { ArticleMetaNotes } from '../../../../ui/ArticleMeta';
import { ArticleEditorContext } from '../../ArticleEditorContext';
import updateAction from '../../actions/update';
import { articleMetaNotesSelector } from '../../selectors';

export const articleNotesContext = {
	meta: {
		notes: {
			hasArticleNotes: false,
		},
	},
};

interface Props extends ReduxProps {
	key: string;
}

const NotesContainer = ({ update, validation, notes, ...rest }: Props) => {
	const { context, setContext } = useContext(ArticleEditorContext);
	const [hasArticleNotes, setHasArticleNotes] = useState(false);

	const hasStrippedValue = (value: string): boolean => {
		return value.replace(/<[^>]+>/g, '').length > 0;
	};

	const handleChangeNotes = (value: string) => {
		if (hasArticleNotes !== hasStrippedValue(value)) {
			setHasArticleNotes(hasStrippedValue(value));
		}

		if (notes !== value) {
			update({ notes: value });
		}
	};

	useEffect(() => {
		setHasArticleNotes(hasStrippedValue(notes));
	}, []);

	useEffect(() => {
		setContext({
			...context,
			...articleNotesContext,
			...{
				meta: {
					notes: {
						hasArticleNotes,
					},
				},
			},
		});
	}, [hasArticleNotes]);

	return (
		<ArticleMetaNotes
			notes={notes}
			onChangeNotes={(value: string) => handleChangeNotes(value)}
			validation={validation}
			{...rest}
		/>
	);
};

const connector = connect(articleMetaNotesSelector, {
	update: updateAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(NotesContainer);
