/**
 * @generated SignedSource<<104623611a3bcaad4a4b0255a704ad32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BRBildCredentials = {
  password: string;
  username: string;
};
export type BRBildRequestConfig = {
  sessionId: string;
};
export type searchBrBildLoginQuery$variables = {
  config?: BRBildRequestConfig | null;
  credentials?: BRBildCredentials | null;
};
export type searchBrBildLoginQuery$data = {
  readonly brBildAPI: {
    readonly login: string | null;
  } | null;
};
export type searchBrBildLoginQuery = {
  response: searchBrBildLoginQuery$data;
  variables: searchBrBildLoginQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "config"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "credentials"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BRBildAPI",
    "kind": "LinkedField",
    "name": "brBildAPI",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "config",
            "variableName": "config"
          },
          {
            "kind": "Variable",
            "name": "credentials",
            "variableName": "credentials"
          }
        ],
        "kind": "ScalarField",
        "name": "login",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "searchBrBildLoginQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchBrBildLoginQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0fe8e4dce61fc0f78cb32260f775ad39",
    "id": null,
    "metadata": {},
    "name": "searchBrBildLoginQuery",
    "operationKind": "query",
    "text": "query searchBrBildLoginQuery(\n  $credentials: BRBildCredentials\n  $config: BRBildRequestConfig\n) {\n  brBildAPI {\n    login(credentials: $credentials, config: $config)\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd77735338a7e522a8e34597b5c81ef0";

export default node;
