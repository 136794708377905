import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../environment';

import BoardLinkPickerDropdown from './BoardLinkPickerDropdown';
import type { BoardLinkPickerQuery } from './__generated__/BoardLinkPickerQuery.graphql';
import { sortBoards } from './util';

type Props = {
	placeholder: string;
	defaultValue?: string | null;
	disabled?: boolean;
	onChange: (value: string | null) => void;
	sectionBoardId: string;
};

export default function ({ sectionBoardId, ...rest }: Props) {
	return (
		<QueryRenderer<BoardLinkPickerQuery>
			environment={environment}
			variables={{}}
			query={graphql`
				query BoardLinkPickerQuery {
					allBoards(first: 1000, condition: { status: PUBLISHED }) {
						edges {
							node {
								createdAt
								fullSlug
								protected
								rowId
								title
								updatedAt
							}
						}
					}
				}
			`}
			render={({ props }) => {
				if (props) {
					const boards = props?.allBoards?.edges || [];
					const sortedBoards = sortBoards(boards);

					const options = sortedBoards
						.filter((board) => board.rowId !== sectionBoardId)
						.map((board) => ({ key: board.rowId, label: board.title }))
						.filter((value) => !!value);

					return <BoardLinkPickerDropdown options={options} {...rest} />;
				}

				return null;
			}}
		/>
	);
}
