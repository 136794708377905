import { PRIORITY_NORMAL } from '@sep/br24-constants';
import { Collapse } from 'antd';
import { Component, Children, type ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';
import type { Priority } from '@/types/schema';

import type { WithTranslationProps } from '../../translation';
import PriorityPicker from '../PriorityPicker';

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.white};
`;

const HeaderWrapper = styled.div`
	border: 1px solid ${(props) => props.theme.colors.lightAntd};
	border-bottom: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Header = styled.div`
	color: ${(props) => props.theme.colors.primaryBlue};
	font-size: 16px;
	margin-top: 6px;
	margin-bottom: 4px;
`;

const HeaderIndicator = styled.div`
	background-color: ${(props) => props.theme.colors.primaryBlue};
	height: 2px;
	width: 120px;
	position: relative;
	top: 1px;
`;

const PriorityWrapper = styled.div`
	padding: 2rem 1rem;
	border: 1px solid ${(props) => props.theme.colors.lightAntd};
	border-bottom: none;
`;

export type ArticleMetaChild = {
	id: string;
};

type Props = WithTranslationProps & {
	defaultActive: Array<string> | undefined | null;
	items: ReactNode;
	onSelectPriority: (priority: Priority) => void;
	priority: Priority | undefined | null;
	onChange: (a: Array<string>) => void;
};

type State = {
	active: Array<string>;
};

class ArticleMeta extends Component<Props, State> {
	static defaultProps = {
		priority: PRIORITY_NORMAL as typeof PRIORITY_NORMAL,
	};

	state = {
		active: Array.isArray(this.props.defaultActive) ? this.props.defaultActive : [],
	};

	handleSelect = (value: string | string[]) => {
		if (!Array.isArray(value)) {
			value = [value];
		}
		this.props.onChange(value);
		this.setState({
			active: value,
		});
	};

	render() {
		const {
			items,
			onSelectPriority,
			priority,
			translation: { translate },
		} = this.props;
		const { active } = this.state;

		return (
			<Wrapper>
				<HeaderWrapper>
					<Header>{translate('article.meta.title')}</Header>
					<HeaderIndicator />
				</HeaderWrapper>

				<PriorityWrapper>
					<PriorityPicker value={priority} onSelect={onSelectPriority} fullWidth={true} />
				</PriorityWrapper>
				<Collapse activeKey={active} onChange={this.handleSelect}>
					{Children.count(items) && items}
				</Collapse>
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleMeta);
