import { STATUS_PUBLISHED } from '@sep/br24-constants';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import { ArticleMetaSEO } from '../../../../ui/ArticleMeta';
import { sanitizeUrl } from '../../../../util/urlify';
import updateAction from '../../actions/update';
import { articleMetaSEOSelector } from '../../selectors';

interface Props extends ReduxProps {
	key: string | number;
}

class SEOContainer extends Component<Props> {
	handleChangeDescription = (value: string) => {
		const { update } = this.props;
		update({ metaDescription: value });
	};

	handleChangeCanonicalUrl = (value: string) => {
		const { update } = this.props;
		update({ canonicalUrl: value });
	};

	handleChangeSeoTitle = (value: string) => {
		const { update, status } = this.props;

		// we only update the slug when the article is not published
		// after article is published the slug should not be changed anymore
		if (status === STATUS_PUBLISHED) {
			update({ seoTitle: value });
		} else {
			update({ seoTitle: value, slug: sanitizeUrl(value) });
		}
	};

	handleChangeSlug = (value: string) => {
		const { update } = this.props;
		update({ slug: value });
	};

	render() {
		const {
			seoTitle,
			metaDescription,
			slug,
			validation,
			title,
			canonicalUrl,
			status,
			update,
			...rest
		} = this.props;

		return (
			<ArticleMetaSEO
				seoTitle={seoTitle}
				seoDescription={metaDescription ?? null}
				seoSlug={slug || null}
				articleTitle={title}
				canonicalUrl={canonicalUrl ?? undefined}
				onChangeSeoTitle={this.handleChangeSeoTitle}
				onChangeDescription={this.handleChangeDescription}
				onChangeSlug={this.handleChangeSlug}
				onChangeCanonicalUrl={this.handleChangeCanonicalUrl}
				validation={validation}
				{...rest}
			/>
		);
	}
}

const connector = connect(articleMetaSEOSelector, {
	update: updateAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SEOContainer);
