/**
 * @generated SignedSource<<890ce928e345d43699872aa0c37f2ef7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Capability = "BASIC" | "FULLY_FEATURED" | "TEXT_ONLY";
export type FilterArticlesSort = "FIRST_PUBLICATION_DATE_ASC" | "FIRST_PUBLICATION_DATE_DESC" | "LAST_EDITED_ASC" | "LAST_EDITED_DESC" | "PUBLICATION_DATE_ASC" | "PUBLICATION_DATE_DESC" | "SEARCH_RANK_DESC";
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type ArticlesTableContainerQuery$variables = {
  capabilityFilter?: ReadonlyArray<Capability | null> | null;
  categoryFilter?: string | null;
  count?: number | null;
  cursor?: any | null;
  onlyTemplates?: boolean | null;
  publishedBefore?: string | null;
  searchTerm?: string | null;
  sortBy?: FilterArticlesSort | null;
  statusFilter?: Status | null;
  tagsFilter?: ReadonlyArray<string | null> | null;
};
export type ArticlesTableContainerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ArticlesTable_query">;
};
export type ArticlesTableContainerQuery = {
  response: ArticlesTableContainerQuery$data;
  variables: ArticlesTableContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "capabilityFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyTemplates"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "publishedBefore"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statusFilter"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagsFilter"
},
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "capabilityFilter",
    "variableName": "capabilityFilter"
  },
  {
    "kind": "Variable",
    "name": "categoryFilter",
    "variableName": "categoryFilter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "onlyTemplates",
    "variableName": "onlyTemplates"
  },
  {
    "kind": "Variable",
    "name": "publishedBefore",
    "variableName": "publishedBefore"
  },
  {
    "kind": "Variable",
    "name": "searchPlain",
    "variableName": "searchTerm"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "statusFilter",
    "variableName": "statusFilter"
  },
  {
    "kind": "Variable",
    "name": "tagsFilter",
    "variableName": "tagsFilter"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticlesTableContainerQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ArticlesTable_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v9/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "ArticlesTableContainerQuery",
    "selections": [
      {
        "alias": "articles",
        "args": (v10/*: any*/),
        "concreteType": "ArticlesConnection",
        "kind": "LinkedField",
        "name": "filterArticles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticlesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rowId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "headline",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryCategory",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "capability",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstPublicationDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicationDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicationPriority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastEdited",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isTemplate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sourceOrigin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleImage",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "categoryByPrimaryCategory",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModulesConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModulesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Module",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Author",
                    "kind": "LinkedField",
                    "name": "authorByRevisionBy",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lockedSince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Author",
                    "kind": "LinkedField",
                    "name": "authorByLockedBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "guid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePicture",
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "articles",
        "args": (v10/*: any*/),
        "filters": [
          "searchPlain",
          "publishedBefore",
          "categoryFilter",
          "tagsFilter",
          "capabilityFilter",
          "onlyTemplates",
          "statusFilter",
          "sortBy"
        ],
        "handle": "connection",
        "key": "ArticlesTable_articles",
        "kind": "LinkedHandle",
        "name": "filterArticles"
      }
    ]
  },
  "params": {
    "cacheID": "c949fbbad93537420e30d041f7497e1c",
    "id": null,
    "metadata": {},
    "name": "ArticlesTableContainerQuery",
    "operationKind": "query",
    "text": "query ArticlesTableContainerQuery(\n  $count: Int\n  $cursor: Cursor\n  $searchTerm: String\n  $publishedBefore: Datetime\n  $categoryFilter: String\n  $tagsFilter: [String]\n  $capabilityFilter: [Capability]\n  $statusFilter: Status\n  $onlyTemplates: Boolean\n  $sortBy: FilterArticlesSort\n) {\n  ...ArticlesTable_query\n}\n\nfragment ArticlesTable_query on Query {\n  articles: filterArticles(first: $count, after: $cursor, searchPlain: $searchTerm, publishedBefore: $publishedBefore, categoryFilter: $categoryFilter, tagsFilter: $tagsFilter, capabilityFilter: $capabilityFilter, onlyTemplates: $onlyTemplates, statusFilter: $statusFilter, sortBy: $sortBy) {\n    pageInfo {\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    totalCount\n    edges {\n      node {\n        createdAt\n        id\n        rowId\n        status\n        headline\n        title\n        slug\n        primaryCategory\n        capability\n        firstPublicationDate\n        publicationDate\n        publicationPriority\n        lastEdited\n        isTemplate\n        sourceOrigin\n        image {\n          url\n          id\n        }\n        categoryByPrimaryCategory {\n          color\n          id\n        }\n        modules {\n          edges {\n            node {\n              type\n              id\n            }\n          }\n        }\n        authorByRevisionBy {\n          firstname\n          lastname\n          id\n        }\n        notes\n        ...LockedByAuthor_article\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment LockedByAuthor_article on Article {\n  lockedSince\n  authorByLockedBy {\n    guid\n    profilePicture\n    firstname\n    lastname\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d3920eb4c4ab82df258ffd78462f8c48";

export default node;
