import * as constants from '@sep/br24-constants';
import { debounce } from 'lodash-es';
import { Component, createRef } from 'react';
import type { SyntheticEvent } from 'react';
import AutoReplace from 'slate-auto-replace';
import CollapseOnEscape from 'slate-collapse-on-escape';
import NoEmpty from 'slate-no-empty';
import PasteLinkify from 'slate-paste-linkify';
import { Editor, getEventTransfer } from 'slate-react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../translation';
import ErrorMessageText from '../ErrorMessageText';

import HoveringMenu from './HoveringMenu';
import { MarkBold, MarkItalic, MarkUnderline } from './formattingTypes';
import HotKey from './plugins/Hotkey';
import { deserialize, serialize } from './util/html';
import renderMark from './util/renderMark';
import renderNode from './util/renderNode';

type RichTextEditorWrapperProps = {
	isSmallStyle: boolean;
};

const EditorWrapper = styled.div<{ hasError: boolean; disabled: boolean }>`
	background-color: ${(props) => (props.disabled ? '#f5f5f5' : '')};
	color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : '')};
	border: ${(props) => (props.hasError ? '1px solid #f5222d' : '1px solid #d9d9d9')};
`;

const RichTextEditorWrapper = styled.div`
	font-size: ${(props: RichTextEditorWrapperProps) => (props.isSmallStyle ? '1em' : 'initial')};
	line-height: ${(props: RichTextEditorWrapperProps) => (props.isSmallStyle ? '1.5' : 'inherit')};
	> * > * + * {
		margin-top: 1em;
	}
`;

const StyledEditor = styled(Editor)`
	padding: 4px 11px;
	&:focus {
		box-shadow: 0 0 0 2px rgba(10, 158, 215, 0.2);
		transition: box-shadow 200ms ease;
		border-right-width: 1px;
	}
`;

export type StateValue = any;
export type StateEvent = any;

interface Props extends WithTranslationProps {
	defaultValue?: string;
	validationError?: string | string[];
	placeholder?: string;
	isEditing?: boolean;
	disabled?: boolean;
	isSmallStyle?: boolean;
	hasError?: boolean;
	onChange: (value: StateValue) => void;
	onKeyDownEnter?: (value: string) => void;
	onModuleIsEditing?: (nextIsEditing: boolean) => void;
}

type State = {
	// TODO: setup flow for slate
	value: StateValue;
};

const plugins = [
	// a slate plugin to prevent empty documents
	NoEmpty('paragraph'),
	// a slate plugin that simply collapses the selection on escape.
	CollapseOnEscape(),
	// a slate plugin that wraps a selection in an inline link element
	// when a url is pasted from the clipboard.
	PasteLinkify(),
	// configure hotkeys
	HotKey({ key: 'b', type: MarkBold.type }),
	HotKey({ key: 'u', type: MarkUnderline.type }),
	HotKey({ key: 'i', type: MarkItalic.type }),
	// auto replace plugin
	AutoReplace({
		trigger: 'space',
		before: /^(>)$/,
		transform: (transform) => transform.setBlock({ type: 'quote' }),
	}),
];

class RichTextEditor extends Component<Props, State> {
	menu = createRef<HTMLDivElement>();

	static defaultProps = {
		defaultValue: '',
		placeholder: undefined,
		autoFocus: false,
		disabled: false,
		isSmallStyle: false,
		hasError: false,
	};

	state = {
		value: deserialize(this.props.defaultValue),
	};

	componentDidMount() {
		this.updateMenu();
	}

	componentDidUpdate() {
		this.updateMenu();
	}

	componentWillUnmount(): void {
		this.resetIsEditing.cancel();
	}

	handleChange = ({ value }: any) => {
		if (this.props.onModuleIsEditing && typeof this.props.onModuleIsEditing === 'function') {
			this.props.onModuleIsEditing(true);
			this.resetIsEditing();
		}

		this.setState({ value });
		this.props.onChange(serialize(value));
	};

	handleFocus = () => {
		if (this.props.onModuleIsEditing && typeof this.props.onModuleIsEditing === 'function') {
			this.props.onModuleIsEditing(true);
			this.resetIsEditing();
		}
	};

	// eslint-disable-next-line consistent-return
	handleKeyDown = (event: StateEvent) => {
		if (this.props.onKeyDownEnter && typeof this.props.onKeyDownEnter === 'function') {
			if (event.key === 'Enter') {
				const { value } = this.state;
				this.props.onKeyDownEnter(serialize(value));
				this.setState({ value: deserialize('<p></p>') });

				// returning false is like a preventdefault for slate key handlers
				return false;
			}
		}
	};

	handlePaste = (event: SyntheticEvent<HTMLElement>, value: any) => {
		const transfer = getEventTransfer(event);

		if (transfer.type !== 'html') {
			return false;
		}

		const { document } = deserialize(transfer.html);
		value.insertFragment(document);

		return true;
	};

	resetIsEditing = debounce(() => this.props.onModuleIsEditing?.(false), 800);

	updateMenu = () => {
		const { value } = this.state;
		const menu = this.menu.current;

		if (!menu) {
			return;
		}

		if (value.isBlurred || value.isEmpty) {
			menu.removeAttribute('style');
			return;
		}

		const selection = window.getSelection();
		const range = selection?.getRangeAt(0);
		const rect = range?.getBoundingClientRect();
		const extraLeft = this.props.isSmallStyle ? 75 : 0;

		menu.style.opacity = '1';
		menu.style.top = `${(rect?.top ?? 0) + window.pageYOffset - menu.offsetHeight}px`;
		menu.style.left = `${
			(rect?.left ?? 0) +
			window.pageXOffset -
			menu.offsetWidth / 2 +
			(rect?.width ?? 0) / 2 +
			extraLeft
		}px`;
	};

	render() {
		const {
			translation: { translate },
			validationError,
			placeholder,
			disabled,
			isSmallStyle,
			hasError,
		} = this.props;
		const { value } = this.state;

		return (
			<EditorWrapper hasError={Boolean(hasError)} disabled={Boolean(disabled)}>
				<HoveringMenu
					ref={this.menu}
					value={value}
					onChange={this.handleChange}
					isSmallStyle={Boolean(isSmallStyle)}
				/>
				<RichTextEditorWrapper isSmallStyle={Boolean(isSmallStyle)}>
					<StyledEditor
						placeholder={
							placeholder || translate(`modules.${constants.MODULE_TYPE_TEXT}.textPlaceholder`)
						}
						value={value}
						plugins={plugins}
						onFocus={this.handleFocus}
						onChange={this.handleChange}
						onKeyDown={this.handleKeyDown}
						renderMark={renderMark}
						renderNode={renderNode}
						readOnly={disabled}
					/>
				</RichTextEditorWrapper>
				{validationError ? <ErrorMessageText text={validationError} /> : null}
			</EditorWrapper>
		);
	}
}

export default withTranslation(RichTextEditor);
