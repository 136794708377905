import { MODULE_TYPE_VIDEO, MODULE_TYPE_LIVESTREAM } from '@sep/br24-constants';
import moment from 'moment';
import { type ReactNode, Component, type ReactElement } from 'react';
import styled from 'styled-components';

import { withTranslation, type WithTranslationProps } from '@/client/translation/withTranslation';

import config from '../../../config';
import type { Validation } from '../../../containers/ArticleEditor';
import ErrorMessageText from '../../ErrorMessageText';
import SVG from '../../SVG';
import { ModulePartInput, ModulePartOverlay } from '../ArticleModuleParts';
import { ReactComponent as VideoDefaultThumbnailSvg } from '../images/imageDefaultThumbnail.svg';
import { ReactComponent as VideoPlayerOverlaySvg } from '../images/videoPlayerOverlay.svg';

const IMAGE_PRELOAD_BLUR = config.articleModules.image.preLoadBlur;
const IMAGE_PRELOAD_QUALITY = config.articleModules.image.preLoadQuality;
const IMAGE_PRELOAD_FIT = config.articleModules.image.preLoadFit;
const IMAGE_PRELOAD_HEIGHT = config.articleModules.image.preLoadHeight;

const Wrapper = styled.div`
	width: 100%;
	margin-bottom: 35px;
`;

const ThumbnailDisplayWrapper = styled.div`
	position: relative;
	margin-bottom: 15px;
`;

const LivestreamThumbnail = styled.img`
	width: 100%;
`;

type DefaultImageProps = {
	className?: string;
};

const DefaultImage = styled(({ className }: DefaultImageProps) => (
	<SVG className={className}>
		<VideoDefaultThumbnailSvg />
	</SVG>
))`
	width: 100%;
	height: 100%;
`;

type VideoPlayerOverlayProps = {
	className?: string;
	src?: string;
};

const VideoPlayerOverlay = styled(({ className }: VideoPlayerOverlayProps) => (
	<SVG className={className}>
		<VideoPlayerOverlaySvg />
	</SVG>
))`
	width: 100%;
	position: absolute;
	bottom: -5px;
`;

const Video = styled.video`
	width: 100%;
`;

const ImageDisplay = styled.img<{ isHidden?: boolean }>`
	width: 100%;
	display: ${(props) => (props.isHidden ? 'none' : 'block')};
`;

type Props = WithTranslationProps & {
	url?: string | null;
	copyright?: string;
	title?: string;
	children?: ReactNode;
	videoTeaserImage: string | undefined | null;
	validation?: Validation | null;
	isLivestream?: boolean;
	liveTitle?: string;
	liveStartTime?: string | null;
	liveEndTime?: string | null;
	isEditing: boolean;
	onChange: (key: string, value: string) => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
	placeholder?: string;
};

type State = {
	isEditOverlayVisible: boolean;
	previewLoaded: boolean;
	startVideo: boolean;
};

const TITLE_LENGTH_MAX = config.articleModules.video.titleMaxLength;

class ArticleModuleVideo extends Component<Props, State> {
	static defaultProps = {
		url: null,
		isLivestream: false,
		livestreamTitle: null,
		liveStartTime: null,
		liveEndTime: null,
	};

	state = {
		isEditOverlayVisible: false,
		previewLoaded: false,
		startVideo: false,
	};

	handleChangeVideoAltText = (value: string) => {
		this.props.onChange('altText', value);
	};

	handleChangeVideoCopyright = (value: string) => {
		this.props.onChange('copyright', value);
	};

	handleChangeVideoTitle = (value: string) => {
		this.props.onChange('title', value);
	};

	handleHover = () => {
		this.setState({
			isEditOverlayVisible: true,
		});
	};

	handleHoverOut = () => {
		this.setState({
			isEditOverlayVisible: false,
		});
	};

	handleLoadVideo = () => {
		this.setState({
			previewLoaded: true,
		});
	};

	handlePauseVideo = () => {
		this.setState({
			startVideo: false,
		});
	};

	handleStartVideo = () => {
		this.setState({
			startVideo: true,
		});
	};

	handleVideoThumbnailLoaded = () => {
		this.setState({
			previewLoaded: true,
		});
	};

	render() {
		const {
			translation: { translate },
			isLivestream,
			liveTitle,
			liveStartTime,
			liveEndTime,
			url,
			title,
			copyright,
			videoTeaserImage,
			validation,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onChange,
			children,
			...rest
		} = this.props;

		const { isEditOverlayVisible, startVideo, previewLoaded } = this.state;

		let videoDisplay: ReactNode = null;

		// Displays the default image with a play overlay
		if (url && !videoTeaserImage) {
			videoDisplay = (
				<ThumbnailDisplayWrapper>
					<VideoPlayerOverlay />
					<DefaultImage />
					<ModulePartOverlay
						isDownloadButtonVisible={false}
						isVisible={isEditOverlayVisible}
						buttonLabel={translate(`modules.${MODULE_TYPE_VIDEO}.loadVideoButtonLabel`)}
						onButtonClick={this.handleStartVideo}
						onMouseEnter={this.handleHover}
						onMouseLeave={this.handleHoverOut}
					/>
				</ThumbnailDisplayWrapper>
			);
		}

		// Display the tumbnail with a play overlay
		if (url && videoTeaserImage) {
			videoDisplay = (
				<ThumbnailDisplayWrapper>
					<VideoPlayerOverlay />
					{!previewLoaded ? (
						<div>
							<ImageDisplay
								src={`${videoTeaserImage}?blur=${IMAGE_PRELOAD_BLUR}&q=${IMAGE_PRELOAD_QUALITY}&fit=${IMAGE_PRELOAD_FIT}&h=${IMAGE_PRELOAD_HEIGHT}`}
							/>
							<ImageDisplay
								isHidden={true}
								src={videoTeaserImage}
								onLoad={this.handleVideoThumbnailLoaded}
							/>
						</div>
					) : (
						<ImageDisplay src={videoTeaserImage} />
					)}
					<ModulePartOverlay
						isDownloadButtonVisible={false}
						isVisible={isEditOverlayVisible}
						buttonLabel={translate(`modules.${MODULE_TYPE_VIDEO}.loadVideoButtonLabel`)}
						onButtonClick={this.handleStartVideo}
						onMouseEnter={this.handleHover}
						onMouseLeave={this.handleHoverOut}
					/>
				</ThumbnailDisplayWrapper>
			);
		}

		// Does load and start the video
		if (url && startVideo) {
			videoDisplay = (
				<Video
					src={url}
					controls={true}
					autoPlay={true}
					onPause={this.handlePauseVideo}
					onLoadedData={this.handleLoadVideo}
				/>
			);
		}

		let livestreamDisplay: ReactElement | null = null;

		// show the livestreams with its information
		if (isLivestream && (liveTitle || liveStartTime || liveEndTime)) {
			const { onModuleIsEditing } = rest;
			livestreamDisplay = (
				<div>
					<ModulePartInput
						label={translate(`modules.${MODULE_TYPE_LIVESTREAM}.titleLabel`)}
						disabled={true}
						onModuleIsEditing={onModuleIsEditing}
						value={liveTitle}
					/>
					<ModulePartInput
						label={translate(`modules.${MODULE_TYPE_LIVESTREAM}.startTimeLabel`)}
						disabled={true}
						onModuleIsEditing={onModuleIsEditing}
						value={moment(liveStartTime).format('DD.MM.YYYY, H:mm')}
					/>
					<ModulePartInput
						label={translate(`modules.${MODULE_TYPE_LIVESTREAM}.endTimeLabel`)}
						disabled={true}
						onModuleIsEditing={onModuleIsEditing}
						value={moment(liveEndTime).format('DD.MM.YYYY, H:mm')}
					/>
				</div>
			);
		}

		// Does show an error message when a livestream is not available anymore
		if (isLivestream && (!liveTitle || !liveStartTime || !liveEndTime)) {
			livestreamDisplay = (
				<ErrorMessageText
					text={translate(`modules.${MODULE_TYPE_LIVESTREAM}.livestreamNotAvailable`)}
					hasBottomMargin={true}
				/>
			);
		}

		let livestreamThumbnailDisplay: ReactElement | null = null;

		if (isLivestream && videoTeaserImage) {
			livestreamThumbnailDisplay = (
				<LivestreamThumbnail alt="BR Livestream" src={videoTeaserImage} />
			);
		}

		const titleHelp = translate(`modules.${MODULE_TYPE_VIDEO}.titleHelp`, {
			count: title ? `${title.length}` : 0,
			max: TITLE_LENGTH_MAX,
		});

		return (
			<div>
				<Wrapper>
					{isLivestream ? (
						livestreamDisplay
					) : (
						<div>
							{videoDisplay}
							<ModulePartInput
								label={translate(`modules.${MODULE_TYPE_VIDEO}.titleLabel`)}
								help={titleHelp}
								{...rest}
								placeholder={translate(`modules.${MODULE_TYPE_VIDEO}.titlePlaceholder`)}
								value={title}
								onChange={this.handleChangeVideoTitle}
								isTextArea={true}
								validationError={validation ? validation['video.title'] : null}
							/>
							<ModulePartInput
								label={translate(`modules.${MODULE_TYPE_VIDEO}.copyrightLabel`)}
								{...rest}
								placeholder={translate(`modules.${MODULE_TYPE_VIDEO}.copyrightPlaceholder`)}
								value={copyright}
								onChange={this.handleChangeVideoCopyright}
								validationError={validation ? validation['video.copyright'] : null}
							/>
						</div>
					)}
				</Wrapper>
				{isLivestream ? livestreamThumbnailDisplay : children}
			</div>
		);
	}
}

export default withTranslation(ArticleModuleVideo);
