/**
 * @generated SignedSource<<db6b2ed1048169ab4854496a5de11542>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
import { FragmentRefs } from "relay-runtime";
export type BoardSearchItem_board$data = {
  readonly category: {
    readonly color: string | null;
    readonly title: string;
  } | null;
  readonly createdAt: string;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly rowId: string;
  readonly slug: string;
  readonly status: Status | null;
  readonly title: string;
  readonly updatedAt: string | null;
  readonly updatedBy: string | null;
  readonly " $fragmentType": "BoardSearchItem_board";
};
export type BoardSearchItem_board$key = {
  readonly " $data"?: BoardSearchItem_board$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardSearchItem_board">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardSearchItem_board",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleImage",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "category",
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categoryByCategoryId",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Board",
  "abstractKey": null
};
})();

(node as any).hash = "e613c70b6492d24842214cb724bf3ea2";

export default node;
