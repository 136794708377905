import moment from 'moment';

import { AM_TYPE_LIVESTREAM } from '../../../constants';

import { Title, Subtitle, Info, Content } from './AssetManagerItemControl';
import Media from './Media';
import TypeIcon from './TypeIcon';

type Props = {
	title: string;
	kicker: string | undefined | null;
	start: string | undefined | null;
	end: string | undefined | null;
	image: string | undefined | null;
};

function AssetManagerItemLivestream({ title, kicker, start, end, image }: Props) {
	return (
		<>
			<TypeIcon type={AM_TYPE_LIVESTREAM} isOverlying={true} />
			<Media image={image} />
			<Content>
				{kicker && title !== kicker && <Subtitle>{kicker}</Subtitle>}
				<Title>{title}</Title>
				{start && <Info>Start: {moment(start).format('DD.MM.YYYY HH:mm')}</Info>}
				{end && <Info>Ende: {moment(end).format('DD.MM.YYYY HH:mm')}</Info>}
			</Content>
		</>
	);
}

export default AssetManagerItemLivestream;
