import { Button as AntButton, Popover as AntPopover, notification } from 'antd';
import { Component } from 'react';
import styled, { type DefaultTheme, type ThemeProps, withTheme } from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';

import AssetManagerGroupsCreator from './AssetManagerGroupsCreator';

interface GroupData {
	title: string;
	color: string;
}

interface Props extends WithTranslationProps, ThemeProps<DefaultTheme> {
	onAdd: (a: GroupData) => void;
}

type State = {
	popoverVisible: boolean;
	newGroup: GroupData;
};

const Wrapper = styled.div`
	margin-top: auto;
	margin-bottom: 0;
`;

const AddWrapper = styled.div`
	margin-top: auto;
	margin-bottom: 0;
`;

const Popover = styled(AntPopover)``;

const Button = styled(AntButton)`
	width: 100%;
	display: block;
`;

function getRndColor(colors: DefaultTheme['assetManager']['groups']['colors']) {
	const rndIndex = Math.floor(Math.random() * colors.length);
	return colors[rndIndex];
}

class AssetManagerGroupsAddButtonComponent extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			popoverVisible: false,
			newGroup: {
				title: '',
				color: getRndColor(props.theme.assetManager.groups.colors),
			},
		};
	}

	getCalendarContainer = (trigger: any) => trigger.parentNode;

	handleAdd = () => {
		if (!this.state.newGroup) {
			return;
		}

		if (this.state.newGroup.title.length < 1) {
			notification.error({
				message: this.props.translation.translate('assetManager.groups.emptyTitleError'),
			});
			return;
		}

		this.props.onAdd(this.state.newGroup);
		this.setState({
			popoverVisible: false,
			newGroup: {
				title: '',
				color: getRndColor(this.props.theme.assetManager.groups.colors),
			},
		});
	};

	handleChange = (state: Partial<GroupData>) => {
		this.setState((prevState: State) => {
			return {
				newGroup: { ...prevState.newGroup, ...state },
			};
		});
	};

	handleHidePopover = () => {
		this.setState({ popoverVisible: false });
	};

	handleOpenChange = (isVisible: boolean) => {
		this.setState({
			popoverVisible: isVisible,
		});
	};

	showPopover = () => {
		this.setState({ popoverVisible: true });
	};

	render() {
		const {
			translation: { translate },
		} = this.props;
		return (
			<Wrapper>
				<AddWrapper>
					<Popover
						getPopupContainer={this.getCalendarContainer}
						trigger="click"
						onOpenChange={this.handleOpenChange}
						open={this.state.popoverVisible}
						content={
							<AssetManagerGroupsCreator
								windowTitle={translate('assetManager.groups.creatorHead')}
								color={this.state.newGroup.color}
								title={this.state.newGroup.title}
								isVisible={this.state.popoverVisible}
								onChange={this.handleChange}
								onSubmit={this.handleAdd}
								onHidePopover={this.handleHidePopover}
							/>
						}
					>
						<Button
							type="primary"
							block={true}
							onClick={this.state.popoverVisible ? this.handleAdd : this.showPopover}
						>
							{this.state.popoverVisible
								? translate('assetManager.groups.save')
								: translate('assetManager.groups.add')}
						</Button>
					</Popover>
				</AddWrapper>
			</Wrapper>
		);
	}
}

export const AssetManagerGroupsAddButton = withTheme(
	withTranslation(AssetManagerGroupsAddButtonComponent)
);
