export default function createJoiError(message: string): (
	errors: Array<{
		type: string;
		path: Array<string>;
	}>
) => {
	type: string;
	path: Array<string>;
	message: string;
} {
	return ([firstError]: Array<{
		type: string;
		path: Array<string>;
	}>) => ({
		type: firstError.type,
		path: firstError.path,
		message,
	});
}
