/**
 * @generated SignedSource<<f5061d80323a419bca7c126226d38198>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type District = "MITTELFRANKEN" | "NIEDERBAYERN" | "OBERBAYERN" | "OBERFRANKEN" | "OBERPFALZ" | "SCHWABEN" | "UNTERFRANKEN";
export type ModuleType = "AUDIO" | "EMBED" | "GALLERY" | "IMAGE" | "LIVESTREAM" | "TEXT" | "VIDEO" | "VIDEO360";
export type ModuleAllocationStatsContainerQuery$variables = {
  categoryFilter?: string | null;
  districtsFilter?: ReadonlyArray<District | null> | null;
  endDate?: string | null;
  startDate?: string | null;
};
export type ModuleAllocationStatsContainerQuery$data = {
  readonly stats: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly count: number;
        readonly type: ModuleType;
      } | null;
    }>;
  } | null;
};
export type ModuleAllocationStatsContainerQuery = {
  response: ModuleAllocationStatsContainerQuery$data;
  variables: ModuleAllocationStatsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "districtsFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = [
  {
    "kind": "Variable",
    "name": "categoryFilter",
    "variableName": "categoryFilter"
  },
  {
    "kind": "Variable",
    "name": "districtsFilter",
    "variableName": "districtsFilter"
  },
  {
    "kind": "Variable",
    "name": "fromDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "toDate",
    "variableName": "endDate"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModuleAllocationStatsContainerQuery",
    "selections": [
      {
        "alias": "stats",
        "args": (v4/*: any*/),
        "concreteType": "ArticleModuleCountResultsConnection",
        "kind": "LinkedField",
        "name": "articleModuleCount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleModuleCountResultsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArticleModuleCountResult",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "LOG",
                    "path": "stats.edges.node.type"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v6/*: any*/),
                    "action": "LOG",
                    "path": "stats.edges.node.count"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModuleAllocationStatsContainerQuery",
    "selections": [
      {
        "alias": "stats",
        "args": (v4/*: any*/),
        "concreteType": "ArticleModuleCountResultsConnection",
        "kind": "LinkedField",
        "name": "articleModuleCount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleModuleCountResultsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArticleModuleCountResult",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b49dd5a3570e346d5f519149f187ac03",
    "id": null,
    "metadata": {},
    "name": "ModuleAllocationStatsContainerQuery",
    "operationKind": "query",
    "text": "query ModuleAllocationStatsContainerQuery(\n  $startDate: Datetime\n  $endDate: Datetime\n  $districtsFilter: [District]\n  $categoryFilter: String\n) {\n  stats: articleModuleCount(fromDate: $startDate, toDate: $endDate, districtsFilter: $districtsFilter, categoryFilter: $categoryFilter) {\n    edges {\n      node {\n        type\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86fd5efc265c2b37d69d1814fa7c9d02";

export default node;
