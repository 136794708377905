import { faUser as PrivateSpaceIcon } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUsers as PublicSpaceIcon } from '@fortawesome/pro-light-svg-icons/faUsers';
import { useState, useEffect, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import HotKey from 'react-hot-keys';
import type { ConnectedProps } from 'react-redux';
import { useDebounce } from 'react-use';
import type { LiteralUnion } from 'type-fest';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { useTranslate } from '@/client/translation/useTranslate';

import { useUser } from '../../auth/AuthContext';
import {
	SlideTransitionGroup,
	SlideTransitionItem,
} from '../../components/SlideTransition/SlideTransition';
import * as constants from '../../constants';
import AssetManagerWrapper, {
	AssetManagerNavigator,
	AssetManagerNavigatorItem,
	AssetManagerFilter,
	AssetManagerLoading,
	AssetManagerContent,
	AssetManagerEdit,
	AssetManagerTypeSelectorOverlay,
} from '../../ui/AssetManager';
import { toAssetManager } from '../ArticleEditor/DragAndDropHandler/dndUtils';

import type { Space, Type, SortDirection, Importer } from '.';
import { AssetManagerGlobalSpace } from './AssetManagerGlobalSpace';
import AssetManagerImporter from './AssetManagerImporter';
import AssetManagerPersonalSpace from './AssetManagerPersonalSpace';
import * as actions from './actions';
import { baseInformationSelector, itemsSelector, assetManagerStatusSelector } from './selectors';

interface Props extends ReduxProps {}

// This drop target is used only for hover, nothing should drop on it.
// This is a bit hacky because I can't just use canDrop. Honestly a lot of this drag feature
// in the Asset Manager is hacky. I think it can be improved with the newer versions of ReactDND
const doNothingOnDrop = () => ({ dropEffect: 'copy' });

function AssetManager({
	activeGlobalGroup,
	showActiveGlobalGroup,
	activeImporter,
	activeSpace,
	changeMode,
	changeSpace,
	filter,
	firebaseConnected,
	flags,
	initialize,
	isInGlobalGroup,
	isInGlobalSpace,
	isInPersonalSpace,
	isVisible,
	items,
	mode,
	setActiveGlobalGroup,
	toggleSubGroups,
	updateFilter,
	removeAll,
	removeSelected,
	activateImporter,
}: Props) {
	const translate = useTranslate();

	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [removeAfterDrop, setRemoveAfterDrop] = useState(true);

	const user = useUser();

	const [{ isOver, canDrop }, dropTarget] = useDrop(
		() => ({
			accept: toAssetManager,
			drop: doNothingOnDrop,
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				canDrop: !!monitor.canDrop(),
			}),
		}),
		[]
	);

	const handleChangeSpace = useCallback(
		(space: string) => {
			// A check in case a user switched modes with the filtering still open
			changeMode(constants.AM_MODE_HARVEST);
			changeSpace(space as Space);
		},
		[changeMode, changeSpace]
	);

	useEffect(() => {
		(async () => initialize(user))();
	}, [initialize, user]);

	useEffect(() => {
		if (isOver) {
			const timer = setTimeout(() => {
				const spaceToSwitchTo = isInGlobalSpace
					? constants.AM_PERSONAL_SPACE
					: constants.AM_GLOBAL_SPACE;
				handleChangeSpace(spaceToSwitchTo);
			}, 800);

			return () => clearTimeout(timer);
		}

		return () => null;
	}, [isOver, handleChangeSpace, isInGlobalSpace]);

	const [, cancel] = useDebounce(
		() => {
			updateFilter({ searchValue }, activeSpace);
		},
		1000,
		[searchValue]
	);

	// ? Is there a better way to arrange everything above this line?
	const handleChangeSearchValue = (value: string) => {
		cancel();
		setSearchValue(value);
	};

	const handleChangeSortDirection = (sortDirection: LiteralUnion<SortDirection, string>) =>
		updateFilter({ sortDirection }, activeSpace);

	const handleClickFilter = () => {
		changeMode(
			mode === constants.AM_MODE_FILTER ? constants.AM_MODE_HARVEST : constants.AM_MODE_FILTER
		);
	};

	const handleClickActivateEditMode = () => {
		changeMode(
			mode === constants.AM_MODE_EDIT ? constants.AM_MODE_HARVEST : constants.AM_MODE_EDIT
		);
	};

	const handleRequestDeleteSelected = () => removeSelected();

	const handleRequestDeleteAll = () => removeAll();

	const handleClickResetFilter = () => {
		updateFilter({ types: [] }, activeSpace);
		handleChangeSearchValue('');
		handleChangeSortDirection(constants.AM_SORT_DIRECTION_RECENT);
		changeMode(constants.AM_MODE_HARVEST);
	};

	const handleClickCloseFilter = () => {
		changeMode(constants.AM_MODE_HARVEST);
	};

	const handleClickApplyFilter = (types: Array<Type>) => {
		updateFilter({ types }, activeSpace);
		changeMode(constants.AM_MODE_HARVEST);
	};

	const handleSelectImporter = (importer: Importer) => activateImporter(importer);

	const handleHotKeyShowHide = () => {
		changeMode(constants.AM_MODE_HARVEST);
	};

	const handleHotKeyHarvest = () => {
		if (!isVisible) {
			return;
		}

		const nextMode =
			mode === constants.AM_MODE_EDIT ? constants.AM_MODE_HARVEST : constants.AM_MODE_EDIT;
		changeMode(nextMode);
	};

	const handleHotKeyFilter = () => {
		if (!isVisible) {
			return;
		}

		const nextMode =
			mode === constants.AM_MODE_FILTER ? constants.AM_MODE_HARVEST : constants.AM_MODE_FILTER;
		changeMode(nextMode);
	};

	const handleHotKeyEdit = () => {
		if (!isVisible) {
			return;
		}

		changeMode(constants.AM_MODE_HARVEST);
	};

	const handleHotKeyImport = () => {
		if (!isVisible) {
			return;
		}

		const nextMode =
			mode === constants.AM_MODE_IMPORT ? constants.AM_MODE_HARVEST : constants.AM_MODE_IMPORT;
		changeMode(nextMode);
	};

	const handleOutsideClick = () => {
		changeMode(constants.AM_MODE_HARVEST);
	};

	// This behavior and the state variable checking for copy/move used to live in withItemDND.
	// But once we introduced the ability to drag an asset between different groups and AM spaces,
	// we had to lift that functionality up here to prevent it being lost in remounts.
	const toggleDragIsCopy = (isCopy: boolean) => {
		setRemoveAfterDrop(!isCopy);
	};

	const showEditButton = items.length > 0 && (isInPersonalSpace || isInGlobalGroup);

	const isInFilterMode = mode === constants.AM_MODE_FILTER;
	const isInEditMode = mode === constants.AM_MODE_EDIT;
	const isInImportMode = mode === constants.AM_MODE_IMPORT;

	const { types, sortDirection } = filter[activeSpace];
	const isFiltered =
		(Array.isArray(types) && types.length > 0) || (searchValue !== null && searchValue.length > 0);

	if (!firebaseConnected) {
		return <AssetManagerLoading outsideClick={handleOutsideClick} isVisible={isVisible} />;
	}

	return (
		<>
			<HotKey
				keyName={constants.HOTKEY_TOGGLE_ASSETMANAGER_VISIBILITY}
				onKeyDown={handleHotKeyShowHide}
			/>
			<HotKey
				keyName={constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_HARVEST}
				onKeyDown={handleHotKeyHarvest}
			/>
			<HotKey
				keyName={constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_FILTER}
				onKeyDown={handleHotKeyFilter}
			/>
			<HotKey
				keyName={constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_EDIT}
				onKeyDown={handleHotKeyEdit}
			/>
			<HotKey
				keyName={constants.HOTKEY_ASSETMANAGER_TOGGLE_MODE_IMPORT}
				onKeyDown={handleHotKeyImport}
			/>
			<SlideTransitionGroup>
				<SlideTransitionItem active={true} comeFromWhere="comeFromRight">
					<AssetManagerWrapper isVisible={isVisible} outsideClick={handleOutsideClick}>
						<AssetManagerImporter
							isVisible={isInImportMode}
							activeImporter={activeImporter}
							onSelectImporter={handleSelectImporter}
							onCloseImporter={handleOutsideClick}
						/>

						{/* Click navigation between Global and Public Space  */}
						{!canDrop && flags.includes(constants.FEATURE_GLOBAL_SPACE) && (
							<AssetManagerNavigator onSelect={handleChangeSpace}>
								<AssetManagerNavigatorItem
									icon={PrivateSpaceIcon}
									id={constants.AM_PERSONAL_SPACE}
									title={translate(`assetManager.space.${constants.AM_PERSONAL_SPACE}`)}
									isActive={isInPersonalSpace}
								/>
								<AssetManagerNavigatorItem
									icon={PublicSpaceIcon}
									id={constants.AM_GLOBAL_SPACE}
									title={translate(`assetManager.space.${constants.AM_GLOBAL_SPACE}`)}
									isActive={isInGlobalSpace}
								/>
							</AssetManagerNavigator>
						)}

						{/* Drag navigation to space not currently in  */}
						{canDrop && flags.includes(constants.FEATURE_GLOBAL_SPACE) && (
							<div ref={dropTarget}>
								<AssetManagerNavigator onSelect={handleChangeSpace}>
									<AssetManagerNavigatorItem
										icon={isInPersonalSpace ? PrivateSpaceIcon : PublicSpaceIcon}
										id={isInPersonalSpace ? constants.AM_PERSONAL_SPACE : constants.AM_GLOBAL_SPACE}
										title={`Zu ${
											isInPersonalSpace
												? translate(`assetManager.space.${constants.AM_GLOBAL_SPACE}`)
												: translate(`assetManager.space.${constants.AM_PERSONAL_SPACE}`)
										}`}
										isActive={true}
									/>
								</AssetManagerNavigator>
							</div>
						)}

						{/* Search and filter at the top of the asset manager  */}
						{(isInPersonalSpace || isInGlobalGroup) && (
							<AssetManagerFilter
								sortDirection={sortDirection}
								isFilterActive={isFiltered}
								isFilterOpen={isInFilterMode}
								isSortDisabled={isInFilterMode}
								onClickFilter={handleClickFilter}
								onCloseFilter={handleClickCloseFilter}
								onReset={handleClickResetFilter}
								searchValue={searchValue || ''}
								onChangeSearchValue={handleChangeSearchValue}
								onChangeSortDirection={handleChangeSortDirection}
							/>
						)}

						<AssetManagerContent isLocked={isInFilterMode} isBlurred={isInFilterMode}>
							{/* At some point it would probably be better to do this via context  */}
							<div style={{ position: 'relative', height: '100%' }}>
								{isInPersonalSpace ? (
									<AssetManagerPersonalSpace
										removeAfterDrop={removeAfterDrop}
										toggleDragIsCopy={toggleDragIsCopy}
									/>
								) : (
									<AssetManagerGlobalSpace
										// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
										activeGlobalGroupData={activeGlobalGroup!}
										showActiveGlobalGroup={showActiveGlobalGroup}
										setActiveGlobalGroup={setActiveGlobalGroup}
										toggleSubGroups={toggleSubGroups}
										removeAfterDrop={removeAfterDrop}
										toggleDragIsCopy={toggleDragIsCopy}
									/>
								)}
							</div>
						</AssetManagerContent>

						{isInFilterMode && (
							<AssetManagerTypeSelectorOverlay
								selected={types}
								onReset={handleClickResetFilter}
								onSubmit={handleClickApplyFilter}
							/>
						)}

						{showEditButton && (
							<AssetManagerEdit
								isActive={isInEditMode}
								isBlurred={isInFilterMode}
								toggleDragIsCopy={toggleDragIsCopy}
								onClickActivate={handleClickActivateEditMode}
								onClickRequestDeleteSelected={handleRequestDeleteSelected}
								onClickRequestDeleteAll={handleRequestDeleteAll}
							/>
						)}
					</AssetManagerWrapper>
				</SlideTransitionItem>
			</SlideTransitionGroup>
		</>
	);
}

const connector = connect(
	(state: ReduxState) => ({
		...baseInformationSelector(state),
		...assetManagerStatusSelector(state),
		items: [...itemsSelector(state)],
		flags: state.user.flags,
	}),
	{
		changeSpace: actions.changeSpace,
		changeMode: actions.changeMode,
		updateFilter: actions.updateFilter,
		initialize: actions.initialize,
		removeAll: actions.removeAll,
		removeSelected: actions.removeSelected,
		activateImporter: actions.activateImporter,
		toggleVisibility: actions.toggleVisibility,
		setActiveGlobalGroup: actions.setActiveGlobalGroup,
		toggleSubGroups: actions.toggleSubGroups,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssetManager);
