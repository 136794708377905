/**
 * @generated SignedSource<<97b60fad6b8a4632bd7a7e8cd29e52f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Gender = "FEMALE" | "MALE";
export type AuthorFilter = {
  and?: ReadonlyArray<AuthorFilter> | null;
  description?: StringFilter | null;
  email?: StringFilter | null;
  firstname?: StringFilter | null;
  gender?: GenderFilter | null;
  guid?: StringFilter | null;
  isActive?: BooleanFilter | null;
  isLdapUser?: BooleanFilter | null;
  jobTitle?: StringFilter | null;
  lastname?: StringFilter | null;
  not?: AuthorFilter | null;
  or?: ReadonlyArray<AuthorFilter> | null;
  profilePicture?: StringFilter | null;
  shortDescription?: StringFilter | null;
  status?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
  updatedBy?: StringFilter | null;
};
export type StringFilter = {
  distinctFrom?: string | null;
  distinctFromInsensitive?: string | null;
  endsWith?: string | null;
  endsWithInsensitive?: string | null;
  equalTo?: string | null;
  equalToInsensitive?: string | null;
  greaterThan?: string | null;
  greaterThanInsensitive?: string | null;
  greaterThanOrEqualTo?: string | null;
  greaterThanOrEqualToInsensitive?: string | null;
  in?: ReadonlyArray<string> | null;
  inInsensitive?: ReadonlyArray<string> | null;
  includes?: string | null;
  includesInsensitive?: string | null;
  isNull?: boolean | null;
  lessThan?: string | null;
  lessThanInsensitive?: string | null;
  lessThanOrEqualTo?: string | null;
  lessThanOrEqualToInsensitive?: string | null;
  like?: string | null;
  likeInsensitive?: string | null;
  notDistinctFrom?: string | null;
  notDistinctFromInsensitive?: string | null;
  notEndsWith?: string | null;
  notEndsWithInsensitive?: string | null;
  notEqualTo?: string | null;
  notEqualToInsensitive?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notInInsensitive?: ReadonlyArray<string> | null;
  notIncludes?: string | null;
  notIncludesInsensitive?: string | null;
  notLike?: string | null;
  notLikeInsensitive?: string | null;
  notStartsWith?: string | null;
  notStartsWithInsensitive?: string | null;
  startsWith?: string | null;
  startsWithInsensitive?: string | null;
};
export type GenderFilter = {
  distinctFrom?: Gender | null;
  equalTo?: Gender | null;
  greaterThan?: Gender | null;
  greaterThanOrEqualTo?: Gender | null;
  in?: ReadonlyArray<Gender> | null;
  isNull?: boolean | null;
  lessThan?: Gender | null;
  lessThanOrEqualTo?: Gender | null;
  notDistinctFrom?: Gender | null;
  notEqualTo?: Gender | null;
  notIn?: ReadonlyArray<Gender> | null;
};
export type BooleanFilter = {
  distinctFrom?: boolean | null;
  equalTo?: boolean | null;
  greaterThan?: boolean | null;
  greaterThanOrEqualTo?: boolean | null;
  greaterThanOrEqualTo?: boolean | null;
  in?: ReadonlyArray<boolean> | null;
  isNull?: boolean | null;
  lessThan?: boolean | null;
  lessThanOrEqualTo?: boolean | null;
  notDistinctFrom?: boolean | null;
  notEqualTo?: boolean | null;
};
export type IntFilter = {
  distinctFrom?: number | null;
  equalTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notDistinctFrom?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
};
export type DatetimeFilter = {
  distinctFrom?: string | null;
  equalTo?: string | null;
  greaterThan?: string | null;
  greaterThanOrEqualTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isNull?: boolean | null;
  lessThan?: string | null;
  lessThanOrEqualTo?: string | null;
  notDistinctFrom?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
};
export type AuthorLoggedInUserContainerQuery$variables = {
  count?: number | null;
  cursor?: any | null;
  loggedInUserFilter?: AuthorFilter | null;
};
export type AuthorLoggedInUserContainerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthorListItemLoggedInUser_query">;
};
export type AuthorLoggedInUserContainerQuery = {
  response: AuthorLoggedInUserContainerQuery$data;
  variables: AuthorLoggedInUserContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loggedInUserFilter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorLoggedInUserContainerQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuthorListItemLoggedInUser_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorLoggedInUserContainerQuery",
    "selections": [
      {
        "alias": "loggedInUser",
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
          },
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "loggedInUserFilter"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": "LASTNAME_ASC"
          }
        ],
        "concreteType": "AuthorsConnection",
        "kind": "LinkedField",
        "name": "allAuthors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "guid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePicture",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isLdapUser",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "53867fdde49dd86c814c4ce554cd3d93",
    "id": null,
    "metadata": {},
    "name": "AuthorLoggedInUserContainerQuery",
    "operationKind": "query",
    "text": "query AuthorLoggedInUserContainerQuery(\n  $count: Int\n  $cursor: Cursor\n  $loggedInUserFilter: AuthorFilter\n) {\n  ...AuthorListItemLoggedInUser_query\n}\n\nfragment AuthorListItemLoggedInUser_query on Query {\n  loggedInUser: allAuthors(first: $count, after: $cursor, filter: $loggedInUserFilter, orderBy: LASTNAME_ASC) {\n    edges {\n      node {\n        guid\n        firstname\n        lastname\n        profilePicture\n        isActive\n        isLdapUser\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "95b9ff3ba3df0146ffbfa54dd77c9b51";

export default node;
