import { darken } from 'polished';
import type { MouseEvent, SyntheticEvent } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { fromAssetManager } from '../../containers/ArticleEditor/DragAndDropHandler/dndUtils';
import { Trash } from '../Icon';

type WrapperProps = {
	isActive: boolean;
	isBlurred: boolean;
	isExpanded: boolean;
	isHovered: boolean;
};

const Wrapper = styled.div<WrapperProps>`
	position: absolute;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	bottom: 0;
	left: 0;
	width: 100%;
	height: ${(props) =>
		props.isExpanded
			? props.theme?.assetManager.trash.expandHeight
			: props.theme?.assetManager.trash.height};
	font-size: 1.05rem;
	border: none;
	background-color: ${(props) =>
		props.isHovered ? props.theme.colors.stateRed : props.theme.colors.primaryBlue};
	color: #ffffff;
	padding: ${(props) => props.theme.assetManager.innerPadding} 0;
	cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
	transition: all 200ms ease;
	filter: ${(props) => (props.isBlurred ? 'blur(4px)' : 'none')};
	&:hover {
		background-color: ${(props) =>
			props.isActive
				? props.theme.colors.primaryBlue
				: darken(0.1, props.theme.colors.primaryBlue)};
	}

	div.tip {
		flex: 1 1 auto;
		text-align: center;
	}
`;

type LeftProps = {
	$isVisible: boolean;
};

const Left = styled.div<LeftProps>`
	width: ${(props) =>
		`calc(${props.theme?.assetManager.width} / 2 - ${props.theme?.assetManager.trash.innerButtonSize} / 2 - 1rem)`};
	padding-left: ${(props) => props.theme?.assetManager.innerPadding};
	visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
	opacity: ${(props) => (props.$isVisible ? 1 : 0)};
	transition: opacity 100ms ease;
`;

type RightProps = {
	$isVisible: boolean;
};

const Right = styled.div<RightProps>`
	width: ${(props) =>
		`calc(${props.theme?.assetManager.width} / 2 - ${props.theme?.assetManager.trash.innerButtonSize} / 2 - 1rem)`};
	padding-right: ${(props) => props.theme?.assetManager.innerPadding};
	opacity: ${(props) => (props.$isVisible ? 1 : 0)};
	transition: opacity 100ms ease;
`;

const Middle = styled.div`
	width: ${(props) => props.theme.assetManager.trash.innerButtonSize};
`;

type IconWrapperProps = {
	isActive: boolean;
};

const IconWrapper = styled.span<IconWrapperProps>`
	font-size: 1.2rem;
	display: inline-block;
	width: ${(props) => props.theme.assetManager.trash.innerButtonSize};
	height: ${(props) => props.theme.assetManager.trash.innerButtonSize};
	line-height: ${(props) => props.theme.assetManager.trash.innerButtonSize};
	color: ${(props) => (props.isActive ? props.theme.colors.primaryBlue : '#ffffff')};
	text-align: center;
	background-color: ${(props) => (props.isActive ? '#ffffff' : 'none')};
	border-radius: 100%;
	position: relative;
	top: 2px;
`;

const Button = styled.button`
	font-size: 1.1rem;
	border: none;
	color: #ffffff;
	background-color: transparent;
	padding: 0.3rem 1rem;
	border-radius: 2px;
	width: 100%;
	border: 1px solid #ffffff;
	outline: none;
	cursor: pointer;
`;

interface AssetManagerEditProps {
	isActive: boolean;
	isBlurred: boolean;
	toggleDragIsCopy: (isCopy: boolean) => void;
	onClickActivate: (event: MouseEvent<HTMLSpanElement>) => void;
	onClickRequestDeleteSelected: (event: SyntheticEvent<HTMLButtonElement>) => void;
	onClickRequestDeleteAll: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

export function AssetManagerEdit({
	isActive = true,
	isBlurred = false,
	onClickActivate,
	onClickRequestDeleteAll,
	onClickRequestDeleteSelected,
	toggleDragIsCopy,
}: AssetManagerEditProps) {
	const [{ canDrop, isOver }, dropTarget] = useDrop(
		() => ({
			accept: fromAssetManager,
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				canDrop: !!monitor.canDrop(),
			}),
			drop: (_item, monitor) => {
				if (monitor.didDrop()) {
					return undefined;
				}

				// It should never be considered a copy operation if moved to the trash
				toggleDragIsCopy(false);
				return { dropEffect: 'move' as const };
			},
		}),
		[toggleDragIsCopy]
	);

	const translate = useTranslate();

	return (
		<div ref={dropTarget}>
			<Wrapper
				isActive={isActive}
				isBlurred={isBlurred}
				isExpanded={canDrop}
				isHovered={isOver}
				onClick={(e) => !isActive && onClickActivate(e)}
			>
				<Left $isVisible={isActive}>
					<Button onClick={(e) => isActive && onClickRequestDeleteSelected(e)}>
						{translate('assetManager.trash.deleteSelected')}
					</Button>
				</Left>
				<Middle>
					<IconWrapper onClick={(e) => isActive && onClickActivate(e)} isActive={isActive}>
						<Trash />
					</IconWrapper>
				</Middle>
				<Right $isVisible={isActive}>
					<Button onClick={(e) => isActive && onClickRequestDeleteAll(e)}>
						{translate('assetManager.trash.deleteAll')}
					</Button>
				</Right>
				{canDrop && <div className="tip">{translate('assetManager.trash.tip')}</div>}
			</Wrapper>
		</div>
	);
}
