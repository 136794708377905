import Html from 'slate-html-serializer';

import { MARKS, NODES } from '../formattingTypes';
import type { SlateNextFunction, FormattingRule, SlateObject } from '../formattingTypes';

const accumulateFormattingRule = (accumulator, node: FormattingRule) => [
	...accumulator,
	{
		deserialize(element: HTMLElement, next: SlateNextFunction) {
			if (element.tagName.toLowerCase() !== node.tag) {
				return undefined;
			}

			return node.deserialize(element, next);
		},
		serialize(object: SlateObject, children: any) {
			if (object.type !== node.type) {
				return undefined;
			}

			return node.serialize(object, children);
		},
	},
];

const html = new Html({
	rules: [
		...Array.from(NODES.values()).reduce(accumulateFormattingRule, []),
		...Array.from(MARKS.values()).reduce(accumulateFormattingRule, []),
	],
});

export function serialize(value: any): any {
	return html.serialize(value);
}

export function deserialize(value: any): any {
	return html.deserialize(value);
}
