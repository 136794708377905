import {
	SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE,
	SOCIAL_MEDIA_ACCOUNT_TWITTER,
	SOCIAL_MEDIA_ACCOUNT_INSTAGRAM,
	SOCIAL_MEDIA_ACCOUNT_YOUTUBE,
	SOCIAL_MEDIA_ACCOUNT_SPOTIFY,
	BOARD_BUFFER_SOCIAL_MEDIA_UPDATE,
} from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import { findSocialMediaAccountById } from '../util/find';

type BoardBufferSocialMediaAccountPatch = {
	service?:
		| typeof SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE
		| typeof SOCIAL_MEDIA_ACCOUNT_TWITTER
		| typeof SOCIAL_MEDIA_ACCOUNT_INSTAGRAM
		| typeof SOCIAL_MEDIA_ACCOUNT_YOUTUBE
		| typeof SOCIAL_MEDIA_ACCOUNT_SPOTIFY;
	label?: string | null;
	accountName?: string;
};

export type UpdateSocialMediaAccountAction = {
	type: typeof BOARD_BUFFER_SOCIAL_MEDIA_UPDATE;
	constraint: {
		boardId: string;
		socialMediaAccountId: string;
	};
	payload: BoardBufferSocialMediaAccountPatch;
};

export default function updateSocialMediaAccount(
	socialMediaAccountInternalId: string,
	socialMediaAccountPatch: BoardBufferSocialMediaAccountPatch
): AppThunkAction<UpdateSocialMediaAccountAction> {
	return (dispatch, getState) => {
		const socialMediaAccount = findSocialMediaAccountById(socialMediaAccountInternalId, getState);

		if (!socialMediaAccount) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SOCIAL_MEDIA_UPDATE,
			constraint: {
				boardId: socialMediaAccount.__meta__.boardId,
				socialMediaAccountId: socialMediaAccount.__meta__.internalId,
			},
			payload: socialMediaAccountPatch,
		});
	};
}
