import { Component, Fragment, type SyntheticEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import * as constants from '../../../../constants';
import { type WithTranslationProps, withTranslation } from '../../../../translation';
import {
	AssetManagerImporterInput,
	AssetManagerImporterForm,
	AssetManagerImporterRow,
	AssetManagerImporterRowItem,
	AssetManagerImporterSelect,
	AssetManagerImporterError,
	AssetManagerImporterInputAction,
	AssetManagerImporterContentEmpty,
} from '../../../../ui/AssetManager';
import { Accept, Spinner } from '../../../../ui/Icon';
import * as actions from '../../actions';
import { importerBaseInformationSelector, importedItemsByImporterSelector } from '../../selectors';
import AssetManagerImporterResults from '../AssetManagerImportResults/AssetManagerImporterResults';

interface AssetManagerImporterBrBildProps extends WithTranslationProps, ReduxProps {}

type State = {
	input: null | string;
	imageTypeFilter: null | string;
	rightsOwnerFilter: null | string;
};

const initialState = {
	input: '',
	imageTypeFilter: null,
	rightsOwnerFilter: null,
};

class AssetManagerImporterBrBildComponent extends Component<
	AssetManagerImporterBrBildProps,
	State
> {
	state = {
		...initialState,
	};

	handleChange = (event: SyntheticEvent<HTMLInputElement>) =>
		this.setState({
			input: event.currentTarget.value,
		});

	handlePaste = (event: ClipboardEvent) => {
		const pastedInput = event?.clipboardData?.getData('Text');

		event.preventDefault();
		this.setState({
			input: pastedInput ?? null,
		});
	};

	handleKeyPress = (event: KeyboardEvent) => {
		if (event.code === 'Enter') {
			event.preventDefault();
			this.handleImport();
		}
	};

	handleChangeImageType = (value: string) => {
		this.setState({ imageTypeFilter: value });
	};

	handleChangeRightsOwner = (value: string) => {
		this.setState({ rightsOwnerFilter: value });
	};

	handleImport = () => {
		const { importResults } = this.props;
		const { input, imageTypeFilter, rightsOwnerFilter } = this.state;

		importResults(
			constants.AM_IMPORTER_BRBILD,
			{
				mode: 'searchBrBild',
			},
			{
				terms: input,
				imageTypes: imageTypeFilter ? [imageTypeFilter] : [],
				rightsOwner: rightsOwnerFilter ? [rightsOwnerFilter] : [],
			}
		);
	};

	render() {
		const {
			translation: { translate },
			latestImporterError,
			isImporterWorking,
			importedItems,
		} = this.props;

		const options = {
			imageRights: [
				{
					value: 'allgemeinmotiv',
					label: 'Allgemeinmotiv',
				},
				{
					value: 'berichterstattung',
					label: 'Berichterstattung',
				},
				{
					value: 'produktion/sendung',
					label: 'Produktion/Sendung',
				},
			],
			rightsOwner: [
				{
					value: 'br',
					label: 'BR',
				},
				{
					value: 'dpa-bildfunk',
					label: 'dpa-Bildfunk',
				},
			],
		};

		return (
			<Fragment>
				{latestImporterError && (
					<AssetManagerImporterError>{latestImporterError.message}</AssetManagerImporterError>
				)}
				<AssetManagerImporterRow>
					<AssetManagerImporterRowItem size={13}>
						<AssetManagerImporterForm>
							<AssetManagerImporterInput
								size="full"
								color="dark"
								placeholder={translate(
									`assetManager.importer.${constants.AM_IMPORTER_BRBILD}.inputPlaceholder`
								)}
								value={this.state.input}
								onChange={this.handleChange}
								onPaste={this.handlePaste}
								onKeyPress={this.handleKeyPress}
							/>
						</AssetManagerImporterForm>
					</AssetManagerImporterRowItem>
					<AssetManagerImporterRowItem size={5}>
						<AssetManagerImporterSelect
							placeholder={translate(
								`assetManager.importer.${constants.AM_IMPORTER_BRBILD}.selectImageTypePlaceholder`
							)}
							value={this.state.imageTypeFilter}
							onChange={this.handleChangeImageType}
							options={options.imageRights}
							allowClear={true}
						/>
					</AssetManagerImporterRowItem>
					<AssetManagerImporterRowItem size={5}>
						<AssetManagerImporterSelect
							placeholder={translate(
								`assetManager.importer.${constants.AM_IMPORTER_BRBILD}.selectRightsOwnerPlaceholder`
							)}
							value={this.state.rightsOwnerFilter}
							onChange={this.handleChangeRightsOwner}
							options={options.rightsOwner}
							allowClear={true}
						/>
					</AssetManagerImporterRowItem>
					<AssetManagerImporterRowItem size={1}>
						<AssetManagerImporterInputAction
							key="import"
							onClick={this.handleImport}
							className={isImporterWorking ? 'loading' : ''}
						>
							{isImporterWorking ? <Spinner /> : <Accept />}
						</AssetManagerImporterInputAction>
					</AssetManagerImporterRowItem>
				</AssetManagerImporterRow>
				{importedItems.length === 0 && (
					<AssetManagerImporterRowItem size={24}>
						<AssetManagerImporterContentEmpty>
							{translate(`assetManager.importer.${constants.AM_IMPORTER_BRBILD}.empty`)}
						</AssetManagerImporterContentEmpty>
					</AssetManagerImporterRowItem>
				)}
				{importedItems.length > 0 && (
					<div>
						<AssetManagerImporterResults
							importer={constants.AM_IMPORTER_BRBILD}
							importedItems={importedItems}
							importerWorking={this.props.isImporterWorking}
						/>
					</div>
				)}
			</Fragment>
		);
	}
}

const connector = connect(
	(state) => ({
		...importerBaseInformationSelector(state),
		importedItems: importedItemsByImporterSelector(constants.AM_IMPORTER_BRBILD)(state),
	}),
	{
		importResults: actions.importResults,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export const AssetManagerImporterBrBild = withTranslation(
	connector(AssetManagerImporterBrBildComponent)
);
