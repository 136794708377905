import { commitMutation, graphql } from 'react-relay';

import environment from '../../../environment';

import type {
	ArticleEditorUpdateArticleMutation,
	ArticlePatch,
} from './__generated__/ArticleEditorUpdateArticleMutation.graphql';

const mutation = graphql`
	mutation ArticleEditorUpdateArticleMutation($input: UpdateArticleByRowIdInput!) {
		updateArticleByRowId(input: $input) {
			article {
				...ArticleEditor_article
			}
		}
	}
`;

export default ({
	rowId,
	...articlePatch
}: Omit<ArticlePatch, 'rowId'> & { rowId: string }): Promise<
	ArticleEditorUpdateArticleMutation['response']
> =>
	new Promise((resolve, reject) => {
		const mut = commitMutation<ArticleEditorUpdateArticleMutation>(environment, {
			mutation,
			variables: {
				input: {
					articlePatch,
					rowId,
				},
			},
			onCompleted: (resp, err) => {
				if (err) {
					return reject(err);
				}
				return resolve(resp);
			},
			onError: (err) => {
				return reject(err);
			},
		});
		return mut;
	});
