import {
	LS_LOCK_DIALOG_OTHER_AUTHOR,
	LS_LOCK_DIALOG_BROKEN_BY_USER,
	LS_READ_ONLY_MODE,
	LS_LOCK_DIALOG_UPDATED_WHILE_LOCK,
} from '@sep/br24-constants';
import { useUser } from '@sep/cms-auth-client';
import moment from 'moment';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { createFragmentContainer } from 'react-relay';
import { type RouteComponentProps, withRouter } from 'react-router';
import { graphql } from 'relay-runtime';

import {
	type LockSubscriptionData,
	type LockSubscriptionEntityType,
	WithLockHandler,
} from '@/client/hooks/useLock';
import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import type { AuthContextType } from '../../auth/AuthContext';
import config from '../../config';
import routes from '../../config/config.routes';
import ArticleEditorUpdateArticleMutation from '../../containers/ArticleEditor/mutations/ArticleEditorUpdateArticleMutation';
import environment from '../../environment';
import UpdateBoardMutation from '../../mutations/UpdateBoardMutation';
import UpdateBoardsTeaserMutation from '../../mutations/UpdateBoardsTeaserMutation';
import { updateLockStatus } from '../../store/reducers/lockStatus';
import {
	DocumentLockDialog,
	BrokenLockDialog,
	UpdatedWhileLockDialog,
	SaveErrorLockDialog,
} from '../../ui/LockDialog';

import type { LockHandling_article$data } from './__generated__/LockHandling_article.graphql';
import type { LockHandling_board$data } from './__generated__/LockHandling_board.graphql';
import type { LockHandling_boardsTeaser$data } from './__generated__/LockHandling_boardsTeaser.graphql';
import { LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED } from './temporary-cms-constants';

type TypeToClosingQuery = {
	['Article']: string;
	['Board']: string;
	['BoardTeaser']: string;
};

const mapTypeToClosingQuery: TypeToClosingQuery = {
	['Article']: 'cms/UpdateArticleLockOnClose',
	['Board']: 'cms/UpdateBoardLockOnClose',
	['BoardTeaser']: 'cms/UpdateBoardTeaserLockOnClose',
};

interface OwnProps extends ReduxProps, RouteComponentProps, Props {
	user: AuthContextType['user'] | null | undefined;
	lockedEntity: LockHandling_article$data;
	entityType: LockSubscriptionEntityType;
}

export interface Props {
	refetchDocument?: (callback: () => void) => void;
}

class LockHandlingComponent extends Component<OwnProps> {
	handleOnUnload: () => void;

	constructor(props: OwnProps) {
		super(props);

		this.handleOnUnload = this.onUnload.bind(this);
		if (!this.props.entityType) {
			throw new Error('error occured while trying to check document locking');
		}
	}

	componentDidMount() {
		window.addEventListener('beforeunload', this.handleOnUnload, false);
		const { user, lockedEntity, entityType } = this.props;

		if (!lockedEntity) {
			return;
		}

		const differentUser = !!(lockedEntity.lockedBy && lockedEntity.lockedBy !== user?.guid);
		const ownUser = !!(lockedEntity.lockedBy && lockedEntity.lockedBy === user?.guid);
		const noUser = !lockedEntity.lockedBy;

		// a lock for the article was found, so display the content lock dialog
		if (differentUser) {
			this.props.updateLockStatus({
				mode: LS_LOCK_DIALOG_OTHER_AUTHOR,
				userInfo: {
					differentUser,
					ownUser,
					noUser,
				},
			});

			return;
		}

		// no lock was found, so set the lock for the current user
		if (noUser) {
			this.updateDocumentLock(entityType, lockedEntity.rowId, user?.guid).then(() => {
				this.props.updateLockStatus({
					mode: null,
					userInfo: {
						differentUser: false,
						noUser: false,
						ownUser: true,
					},
				});
			});
		} else {
			// when lock is for current user
			this.props.updateLockStatus({
				mode: null,
				userInfo: {
					differentUser,
					noUser,
					ownUser,
				},
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleOnUnload, false);

		const {
			lockStatus: { userInfo },
			entityType,
			lockedEntity,
		} = this.props;

		const finalize = () => {
			// reset lock status in store
			this.props.updateLockStatus({
				mode: null,
				userInfo: null,
			});
		};

		// current user has lock, needs to reseted to null after leaving the document
		if (userInfo && userInfo.ownUser) {
			this.updateDocumentLock(entityType, lockedEntity.rowId, null).then(finalize);
		} else {
			finalize();
		}
	}

	// reset lock status to null when the user closes the browser window
	onUnload() {
		const {
			lockStatus: { userInfo },
			entityType,
			lockedEntity,
		} = this.props;

		if (userInfo && userInfo.ownUser) {
			const postData = JSON.stringify({
				id: mapTypeToClosingQuery[entityType],
				variables: {
					rowId: lockedEntity.rowId,
				},
			});

			const http = new XMLHttpRequest();
			http.open('POST', `${config.VITE_GRAPHQL_URL_EXT}/graphql`, false);
			http.setRequestHeader('Content-Type', 'application/json');
			http.send(postData);
		}
	}

	setupLockDialog = () => {
		const {
			lockStatus: { mode, brokenBy },
			lockedEntity,
		} = this.props;

		if (mode === LS_LOCK_DIALOG_OTHER_AUTHOR) {
			return (
				<DocumentLockDialog
					isVisible={true}
					editedBy={`${lockedEntity.authorByLockedBy?.firstname} ${lockedEntity.authorByLockedBy?.lastname}`}
					editedSince={moment.utc(lockedEntity.lockedSince).local().fromNow(true)}
					onBackToOverview={this.handleBackToOverview}
					onOpenReadOnlyView={this.handleReadOnlyView}
					onLockTakeover={this.handleLockTakeover}
				/>
			);
		}

		if (mode === LS_LOCK_DIALOG_UPDATED_WHILE_LOCK) {
			return (
				<UpdatedWhileLockDialog
					isVisible={true}
					onBackToOverview={this.handleBackToOverview}
					onSetLock={this.handleLockTakeover}
				/>
			);
		}

		if (mode === LS_LOCK_DIALOG_BROKEN_BY_USER) {
			return (
				<BrokenLockDialog
					isVisible={true}
					brokenBy={brokenBy ? `${brokenBy.firstname} ${brokenBy.lastname}` : ''}
					brokenDate={brokenBy ? moment(brokenBy.since).format('DD.MM.YYYY, hh:mm:ss') : ''}
					onBackToOverview={this.handleBackToOverview}
					onOpenReadOnlyView={this.handleReadOnlyView}
				/>
			);
		}

		if (mode === LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED) {
			return (
				<SaveErrorLockDialog
					isVisible={true}
					brokenBy={brokenBy ? `${brokenBy.firstname} ${brokenBy.lastname}` : 'unknown'}
					brokenDate={
						brokenBy ? moment(brokenBy.lastEdited).format('DD.MM.YYYY, hh:mm:ss') : 'unknown'
					}
					onReadOnlyView={this.handleReadOnlyView}
				/>
			);
		}

		return null;
	};

	handleBackToOverview = () => {
		const { entityType } = this.props;
		let route: string;

		switch (entityType) {
			case 'Article':
				route = routes.articles;
				break;
			case 'Board':
				route = routes.boards;
				break;
			case 'BoardTeaser':
				route = routes.boardsTeasers;
				break;
		}

		if (route) {
			this.props.history.push(route);
		}
	};

	handleLockTakeover = async () => {
		const { user, lockedEntity, entityType } = this.props;

		await this.updateDocumentLock(entityType, lockedEntity.rowId, user?.guid).then(() => {
			this.props.updateLockStatus({
				mode: null,
				userInfo: {
					differentUser: false,
					noUser: false,
					ownUser: true,
				},
			});
		});

		// If the refetchDocument method is available we call it to refetch the data.
		// We need to load the latest data from db in order to avoid seeing different states.
		// In the callback we update the lockStatus state (to close the dialog).
		if (this.props.refetchDocument) {
			this.props.refetchDocument(() => {
				/*this.props.updateLockStatus({
					mode: null,
					userInfo: {
						differentUser: false,
						noUser: false,
						ownUser: true,
					},
				});*/
			});
		} else {
			// If refetchDocument isn't available we change the state and just reload the page
			// to get the latest data from the db.
			this.props.updateLockStatus({
				mode: null,
				userInfo: {
					differentUser: false,
					noUser: false,
					ownUser: true,
				},
			});

			setTimeout(() => {
				window.location.reload();
			}, 300);
		}
	};

	handleReadOnlyView = () => {
		const {
			lockStatus: { mode },
		} = this.props;

		if (mode !== LS_READ_ONLY_MODE) {
			this.props.updateLockStatus({
				mode: LS_READ_ONLY_MODE,
				brokenBy: null,
			});
		}
	};

	handleSubscriptionUpdate = (lockUpdate: LockSubscriptionData) => {
		const {
			user,
			lockStatus: { mode },
			lockedEntity,
		} = this.props;

		// case 1: user is in read only mode
		if (mode === LS_READ_ONLY_MODE) {
			return;
		}

		// case 2: user has lock dialog but locking user leaves
		// document while lock dialog is still open
		if (
			mode === null &&
			lockUpdate?.rowId === lockedEntity.rowId &&
			!lockUpdate?.lockedBy &&
			!!lockedEntity.lockedBy
		) {
			this.props.updateLockStatus({
				mode: LS_LOCK_DIALOG_UPDATED_WHILE_LOCK,
				brokenBy: null,
				userInfo: {
					differentUser: false,
					noUser: true,
					ownUser: false,
				},
			});
		}

		// case 2: user has lock dialog but locking user leaves
		// document while lock dialog is still open
		if (
			mode === LS_LOCK_DIALOG_OTHER_AUTHOR &&
			lockUpdate?.rowId === lockedEntity.rowId &&
			!lockUpdate?.lockedBy &&
			!!lockedEntity.lockedBy
		) {
			this.props.updateLockStatus({
				mode: LS_LOCK_DIALOG_UPDATED_WHILE_LOCK,
				brokenBy: null,
				userInfo: {
					differentUser: false,
					noUser: true,
					ownUser: false,
				},
			});
		}

		// case 3: user got broken by another user
		if (
			mode !== LS_LOCK_DIALOG_BROKEN_BY_USER &&
			lockUpdate?.lockedBy !== user?.guid &&
			lockUpdate?.rowId === lockedEntity.rowId &&
			lockUpdate?.authorFirstName &&
			lockUpdate?.authorLastName
		) {
			this.props.updateLockStatus({
				mode: LS_LOCK_DIALOG_BROKEN_BY_USER,
				brokenBy: {
					firstname: lockUpdate?.authorFirstName,
					lastname: lockUpdate?.authorLastName,
					// lastEdited: relayData?.lastEdited,
					since: lockUpdate?.lockedSince,
				},
				userInfo: {
					differentUser: true,
					noUser: false,
					ownUser: false,
				},
			});
		}
	};

	/**
	 * Updates the document lock for the given type, rowId and sets it to the user
	 * it that is passed in the userId parameter.
	 */
	updateDocumentLock = async (
		type: LockSubscriptionEntityType,
		rowId: string,
		userId: string | null | undefined
	) => {
		switch (type) {
			case 'Article':
				await ArticleEditorUpdateArticleMutation({
					rowId,
					lockedBy: userId,
				});
				break;

			case 'Board':
				await UpdateBoardMutation(rowId, { lockedBy: userId }, environment);
				break;

			case 'BoardTeaser':
				await UpdateBoardsTeaserMutation(rowId, { lockedBy: userId }, environment);
				break;

			default:
				break;
		}
	};

	render() {
		return (
			<>
				{this.setupLockDialog()}
				<WithLockHandler
					onUpdate={this.handleSubscriptionUpdate}
					entityType={this.props.entityType}
				/>
			</>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		lockStatus: state.lockStatus,
	}),
	{
		updateLockStatus,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

const WrappedConnector = withRouter(connector(LockHandlingComponent));

type EntityTypeToProps = {
	Board: {
		board: LockHandling_board$data;
	};
	Article: {
		article: LockHandling_article$data;
	};
	BoardTeaser: {
		boardsTeaser: LockHandling_boardsTeaser$data;
	};
};

function mapEntityTypeToProperty(entityType: keyof EntityTypeToProps) {
	switch (entityType) {
		case 'Article':
			return 'article';
		case 'Board':
			return 'board';
		case 'BoardTeaser':
			return 'boardsTeaser';
		default:
			throw new Error(`Unsupported entity type ${entityType}`);
	}
}

function withEntityType<P extends keyof EntityTypeToProps>(type: P) {
	const entityProperty = mapEntityTypeToProperty(type);

	return (props: EntityTypeToProps[P] & Props) => {
		const user = useUser();
		const lockedEntity = props[entityProperty];
		return (
			<WrappedConnector {...props} entityType={type} user={user} lockedEntity={lockedEntity} />
		);
	};
}

export const LockHandlingArticle = createFragmentContainer(withEntityType('Article'), {
	article: graphql`
		fragment LockHandling_article on Article {
			rowId
			lockedBy
			lockedSince
			authorByLockedBy {
				guid
				firstname
				lastname
			}
		}
	`,
});

export const LockHandlingBoard = createFragmentContainer(withEntityType('Board'), {
	board: graphql`
		fragment LockHandling_board on Board {
			rowId
			lockedBy
			lockedSince
			authorByLockedBy {
				guid
				firstname
				lastname
			}
		}
	`,
});

export const LockHandlingBoardTeaser = createFragmentContainer(withEntityType('BoardTeaser'), {
	boardsTeaser: graphql`
		fragment LockHandling_boardsTeaser on BoardsTeaser {
			rowId
			lockedBy
			lockedSince
			authorByLockedBy {
				guid
				firstname
				lastname
			}
		}
	`,
});
