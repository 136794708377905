import { Route, Switch } from 'react-router-dom';

import routeConfig from '@/client/config/config.routes';

import Articles from '../../components/Articles';
import Authors from '../../components/Authors';
import Boards from '../../components/Boards';
import BoardsTeasers from '../../components/BoardsTeasers';
import Comments from '../../components/Comments';
import Dashboard from '../../components/Dashboard';
import ErrorHandler from '../../components/ErrorHandler';
import FAQ from '../../components/FAQ';
import Header from '../../components/Header';
import NavigationContainer from '../../components/Navigation/NavigationContainer';
import NavigationListContainer from '../../components/NavigationList/NavigationListContainer';
import NotFound from '../../components/NotFound';
import UserSettings from '../../components/UserSettings';
import ArticleEditorContainer from '../ArticleEditor/ArticleEditorContainer';
import AuthorContainer from '../AuthorContainer';
import BoardContainer from '../BoardContainer';
import BoardsTeaserContainer from '../BoardsTeaserContainer';

import Content from './Content';

export default function createRouter() {
	return (
		<ErrorHandler>
			<Header />
			<Content>
				<Switch>
					<Route path={routeConfig.dashboard} component={Dashboard} exact={true} />
					{/** ----------------------------------------------------------------------------------------------
					 * articles
					 * _______________________________________________________________________________________________ */}
					<Route path={routeConfig.articles} component={Articles} exact={true} />
					<Route path={routeConfig.articleById} component={ArticleEditorContainer} />
					{/** ----------------------------------------------------------------------------------------------
					 * authors
					 * _______________________________________________________________________________________________ */}
					<Route path={routeConfig.authors} component={Authors} exact={true} />
					<Route path={routeConfig.authorById} component={AuthorContainer} />
					{/** ----------------------------------------------------------------------------------------------
					 * boards
					 * _______________________________________________________________________________________________ */}
					<Route path={routeConfig.boards} component={Boards} exact={true} />
					<Route path={routeConfig.boardById} component={BoardContainer} />
					<Route path={routeConfig.boardsTeasers} component={BoardsTeasers} exact={true} />
					<Route path={routeConfig.boardsTeaserById} component={BoardsTeaserContainer} />
					{/** ----------------------------------------------------------------------------------------------
					 * navigation
					 * _______________________________________________________________________________________________ */}
					<Route path={routeConfig.navigations} component={NavigationListContainer} exact={true} />
					<Route path={routeConfig.navigationById} component={NavigationContainer} />
					{/** ----------------------------------------------------------------------------------------------
					 * other
					 * _______________________________________________________________________________________________ */}
					<Route path={routeConfig.comments} component={Comments} exact={true} />
					<Route path={routeConfig.faq} component={FAQ} />
					<Route path={routeConfig.profile} component={UserSettings} />
					<Route component={NotFound} />
				</Switch>
			</Content>
		</ErrorHandler>
	);
}
