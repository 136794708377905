import type { AppThunkAction } from '@/client/store/redux';

import type { DeleteModuleAction } from '..';
import { MODULE_DELETE } from '../actionTypes';

import { validateAll } from './validate';

const deleteModule =
	(id: string | number): AppThunkAction<DeleteModuleAction> =>
	(dispatch) => {
		dispatch({
			type: MODULE_DELETE,
			payload: id,
		});

		dispatch(validateAll());
	};

export default deleteModule;
