import { Children, cloneElement, Component, type ReactElement, type ReactNode } from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

import type { Importer } from '@/client/containers/AssetManager';

const Wrapper = styled.div`
	width: ${(props) => props.theme.assetManager.importer.navigatorWidth};
`;

const List = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
`;

type ListItemProps = {
	hasMargin: boolean;
};

const ListItem = styled.li<ListItemProps>`
	margin-top: 0.5rem;
	margin-right: ${(props) => (props.hasMargin ? props.theme.assetManager.innerPadding : 'none')};
	&:first-child {
		margin-top: 0;
	}
`;

interface ListItemAnchorProps {
	isActive: boolean;
}

const ListItemAnchor = styled.a<ListItemAnchorProps>`
	display: inline-block;
	width: 100%;
	text-align: right;
	background-color: ${(props) => props.theme?.assetManager.importer.backgroundColor};
	padding: 1rem;
	color: #ffffff;
	border-left: ${(props) =>
		props.isActive ? `10px solid ${props.theme?.colors.primaryBlue}` : 'none'};
	&:hover {
		color: ${(props) => props.theme.colors.primaryBlue};
	}
`;

interface AssetManagerImporterNavigatorItemProps {
	onClick?: (id: Importer) => void;
	id: Importer;
	isActive: boolean;
	children: ReactNode;
}

export class AssetManagerImporterNavigatorItem extends Component<AssetManagerImporterNavigatorItemProps> {
	handleClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
		const { id, onClick } = this.props;

		event.preventDefault();
		if (onClick) {
			onClick(id);
		}
	};

	render() {
		const { isActive, children } = this.props;

		return (
			<ListItem hasMargin={!isActive}>
				<ListItemAnchor isActive={isActive} onClick={this.handleClick}>
					{children}
				</ListItemAnchor>
			</ListItem>
		);
	}
}

type AssetManagerNavigatorProps = {
	children: (ReactElement<AssetManagerImporterNavigatorItemProps> | null)[];
	onSelect: (id: Importer) => void;
};

const enhanceChildren = (
	children: (ReactElement<AssetManagerImporterNavigatorItemProps> | null)[],
	onSelect: (id: Importer) => void
) =>
	Children.map(children, (child) => {
		if (!child) {
			return null;
		}
		return cloneElement(child, {
			onClick: onSelect,
		});
	});

function AssetManagerNavigator({ children, onSelect }: AssetManagerNavigatorProps) {
	return (
		<Wrapper>
			<List>{Children.count(children) > 0 ? enhanceChildren(children, onSelect) : null}</List>
		</Wrapper>
	);
}

export default AssetManagerNavigator;
