import { COLOR_ASBESTOS } from '@sep/br24-constants';
import { Dropdown, Button } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import styled from 'styled-components';

import HistoryTimelineContainer, {
	type HistoryTimelineItemType,
} from '../../../containers/HistoryTimeline';
import type { WithTranslationProps } from '../../../translation';
import { withTranslation } from '../../../translation';
import { media } from '../../../util/styled';
import { History, DropdownDown, DropdownUp } from '../../Icon';

type Props = (WithTranslationProps & {
	historyItem: HistoryTimelineItemType | undefined;
	isDisabled: boolean;
	totalItems: number;
}) &
	({ articleId: string | null } | { boardId: string | null });

type State = {
	isDropdownOpen: boolean;
};

const Title = styled.div`
	font-size: 1em;
	white-space: nowrap;
	width: 260px;
	${media.lessThan('giant')`
		display: none;
	`};
`;

const Overlay = styled.div`
	background-color: ${(props) => props.theme.colors.white};
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 10px 15px 0px;
	height: 200px;
	width: 400px;
	overflow-y: scroll;
`;

const DropdownButton = styled(Button)`
	padding: 0 10px;
	color: ${COLOR_ASBESTOS};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const DropdownButtonContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const DropdownIcon = styled.div`
	font-size: 14px !important;
`;

const DropdownIconLast = styled(DropdownIcon)`
	margin-left: 10px;
`;

class HistoryDropdown extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isDropdownOpen: false,
		};
	}

	handleDropdownOpenChange = (open: boolean) => {
		this.setState({ isDropdownOpen: open });
	};

	renderOverlay = () => {
		const articleId = this.props['articleId'] ? this.props['articleId'] : null;
		const boardId = this.props['boardId'] ? this.props['boardId'] : null;

		return (
			<Overlay>
				<HistoryTimelineContainer
					articleId={articleId}
					boardId={boardId}
					totalItems={this.props.totalItems}
				/>
			</Overlay>
		);
	};

	renderTitle(historyItem: HistoryTimelineItemType | undefined) {
		const {
			translation: { translate },
		} = this.props;

		if (historyItem && !this.state.isDropdownOpen) {
			const date = moment(historyItem.date).format(
				translate('article.historyTimeline.moment.format')
			);
			return (
				<Title>
					{translate('article.historyTimeline.item', { status: historyItem.status, date })}
				</Title>
			);
		}
		return <Title>{translate('article.historyTimeline.history')}</Title>;
	}

	render() {
		const { isDisabled, historyItem } = this.props;

		return (
			<Dropdown
				disabled={isDisabled}
				onOpenChange={this.handleDropdownOpenChange}
				dropdownRender={() => this.renderOverlay()}
				placement="bottomLeft"
				trigger={['click', 'hover']}
				mouseLeaveDelay={1}
			>
				<DropdownButton>
					<DropdownButtonContent>
						<DropdownIcon>
							<History />
						</DropdownIcon>
						{this.renderTitle(historyItem)}
						<DropdownIconLast>
							{this.state.isDropdownOpen ? <DropdownUp /> : <DropdownDown />}
						</DropdownIconLast>
					</DropdownButtonContent>
				</DropdownButton>
			</Dropdown>
		);
	}
}

export default withTranslation(HistoryDropdown);
