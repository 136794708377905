import { Row, Col, Radio, Select, notification, Form } from 'antd';
import cn from 'classnames';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import { FEATURE_GLOBAL_SPACE, AM_PERSONAL_SPACE } from '../../constants';
import { changeSpace } from '../../containers/AssetManager/actions';
import PlaceholderImage from '../../images/placeholder_xl.png';
import type { UserSettings as UserSettingsState } from '../../store/reducers/user';
import * as actions from '../../store/reducers/user';
import gs from '../../styles';
import { Page } from '../Page';
import UserAlert from '../UserAlert';

import styles from './UserSettings.module.scss';

const { Group: RadioGroup } = Radio;
const { Option } = Select;

interface Props extends ReduxProps {
	settings: UserSettingsState;
	flags: Array<string>;
}

const FormItem = styled(Form.Item)`
	margin-bottom: 50px;
	width: 100%;
`;

class UserSettings extends Component<Props> {
	captureFlagChange = (key: string) => {
		const { flags, deleteFlag, addFlag } = this.props;
		const hasFlag = flags.includes(key);

		if (hasFlag) {
			deleteFlag(key);
		} else {
			addFlag(key);
		}
	};

	captureSettingsChange = (key: string, value: any) => {
		this.props.updateSettings({ [key]: value });
		notification.success({
			message: 'Erfolg!',
			description: 'Deine Einstellungen wurden erfolgreich gespeichert.',
			duration: 1,
		});
	};

	globalSpaceFlagChange = () => {
		this.props.changeSpace(AM_PERSONAL_SPACE);
		this.captureFlagChange(FEATURE_GLOBAL_SPACE);
	};

	handleCloseAlert = () => null;

	render() {
		const { settings, flags } = this.props;

		return (
			<Page>
				<Page.Content>
					<Form layout="vertical">
						<Row>
							<Col span={24} className={cn(styles.header)}>
								<div
									style={{
										backgroundImage: `url(${PlaceholderImage})`,
									}}
								>
									<div />
								</div>
							</Col>
						</Row>
						<Row>
							<Col span={16} offset={4} className={gs.pM}>
								<UserAlert
									message="Wichtiger Hinweis"
									description="Die Einstellungen werden lediglich für diesen Browser gespeichert. Andere Geräte sind von diesen Einstellungen nicht betroffen."
									className={gs.mbM}
									type="warning"
									flag="USER_SETTINGS_WARNING_SEEN"
								/>
								<FormItem
									label="Artikel automatisch speichern"
									help="Wenn Du diese Option aktivierst, speichert das System Artikel im Bearbeitungsmodus alle zwei Minuten."
								>
									<RadioGroup
										onChange={(event) =>
											this.captureSettingsChange('articleAutoSave', event.target.value)
										}
										value={settings.articleAutoSave}
									>
										<Radio value={true}>Aktiv</Radio>
										<Radio value={false}>Inaktiv</Radio>
									</RadioGroup>
								</FormItem>
								<FormItem
									label="Artikelübersicht automatisch aktualisieren"
									help="Du kannst frei wählen ob die Artikelübersicht automatisch Änderungen erhält."
								>
									<Select
										defaultValue={String(settings.autoRefreshIn)}
										onChange={(value) =>
											this.captureSettingsChange('autoRefreshIn', parseInt(value, 10))
										}
									>
										<Option value="0">Keine automatische Aktualisierung</Option>
										<Option value="30000">Alle 30 Sekunden</Option>
										<Option value="60000">Jede Minute</Option>
										<Option value="300000">Alle fünf Minuten</Option>
										<Option value="600000">Alle zehn Minuten</Option>
									</Select>
								</FormItem>
								<br />
								<h2>Beta Flags</h2>
								<FormItem
									label="Asset Manager Global Space aktivieren"
									help="Wenn Sie diese Option aktivieren, haben Sie Zugriff auf den globalen Bereich der gemeinsam genutzten Assets für andere CMS-Benutzer."
								>
									<RadioGroup
										onChange={() => this.globalSpaceFlagChange()}
										value={flags.includes(FEATURE_GLOBAL_SPACE)}
									>
										<Radio value={true}>Aktiv</Radio>
										<Radio value={false}>Inaktiv</Radio>
									</RadioGroup>
								</FormItem>
							</Col>
						</Row>
					</Form>
				</Page.Content>
			</Page>
		);
	}
}

const connector = connect(
	(state: ReduxState) => ({
		settings: state.user.settings,
		flags: state.user.flags,
	}),
	{
		updateSettings: actions.updateSettings,
		addFlag: actions.addFlag,
		deleteFlag: actions.deleteFlag,
		changeSpace,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(UserSettings);
