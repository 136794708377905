import { Select, Spin } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import Checkmark from '../../Checkmark';

const { Option } = Select;

export type MatchedItem = {
	fullName: string;
	guid: string;
	isActive: boolean;
	isLdapUser: boolean;
	jobTitle: string;
};

const SearchResultItemText = styled.div`
	display: inline-flex;
	align-items: center;
`;

const SearchResultItemJobTitle = styled.div`
	font-style: italic;
`;

const renderItem = (item: MatchedItem) => {
	if (!item.isActive) {
		return null;
	}

	return (
		<Option key={item.guid} value={item.guid}>
			<SearchResultItemText>
				{item.fullName} {item.isLdapUser ? <Checkmark /> : null}
			</SearchResultItemText>
			<SearchResultItemJobTitle>{item.jobTitle ? item.jobTitle : '-'}</SearchResultItemJobTitle>
		</Option>
	);
};

type Props = WithTranslationProps & {
	defaultValue?: string;
	isWorking: boolean;
	items: Array<MatchedItem>;
	onSearch: (searchTerm: string) => void;
	onSelect: (value: string) => void;
};

type State = {
	value: string;
};

class ArticleMetaAuthorSearch extends Component<Props, State> {
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.defaultValue !== prevState.value) {
			return {
				value: nextProps.defaultValue,
			};
		}

		return null;
	}

	state = {
		value: this.props.defaultValue || '',
	};

	handleChange = (value: string) => this.setState({ value });

	render() {
		const { value } = this.state;
		const {
			defaultValue,
			isWorking = false,
			items,
			translation: { translate },
			...rest
		} = this.props;

		return (
			<Select
				style={{ width: '100%' }}
				{...rest}
				filterOption={false}
				// mode="default"
				notFoundContent={isWorking ? <Spin size="small" /> : null}
				onChange={this.handleChange}
				placeholder={translate('article.meta.author.searchPlaceholder')}
				showArrow={false}
				showSearch={true}
				// use undefined here because if not the placeholder is not displayed when empty (antd)
				// eslint-disable-next-line no-undefined
				value={value || undefined}
			>
				{Array.isArray(items) && items.length > 0 && defaultValue !== ''
					? items.map(renderItem)
					: null}
			</Select>
		);
	}
}

export default withTranslation(ArticleMetaAuthorSearch);
