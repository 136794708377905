import { Children, cloneElement, type Key, type SyntheticEvent } from 'react';
import type { ReactElement } from 'react';

type ClonePropsType = {
	onClick: (
		event: SyntheticEvent<HTMLElement>,
		action: string,
		route: string,
		id: (Key | null)[]
	) => void;
	subItems?: ReactElement[] | null;
};

export default function enhanceNavigationChildren(
	handler: (
		event: SyntheticEvent<HTMLElement>,
		action: string,
		route: string,
		id: (Key | null)[]
	) => void,
	items: ReactElement[],
	parents: (Key | null)[] = []
): any {
	return Children.map(items, (child) => {
		const id = [...(parents || []), child.key];

		const cloneProps: ClonePropsType = {
			onClick: (
				event: SyntheticEvent<HTMLElement>,
				action: string,
				route: string,
				id: (Key | null)[]
			) => handler(event, action, route, id),
		};

		if (Array.isArray(child.props.subItems) && child.props.subItems.length > 0) {
			cloneProps.subItems = enhanceNavigationChildren(handler, child.props.subItems, id);
		}

		return cloneElement(child, cloneProps);
	});
}
