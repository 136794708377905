import styled from 'styled-components';

const ArticleMetaTeasersDropzone = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.primaryBlue};
	color: ${(props) => props.theme.colors.white};
	display: flex;
	font-size: 1.2em;
	font-weight: 400;
	justify-content: center;
	margin-bottom: 0;
	opacity: 0.95;
	padding: 1.2rem;
	width: 100%;

	&.no-links {
		height: 100px;
		margin-top: 3px;
		margin-bottom: 25px;
	}

	&.has-links {
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
	}
`;

export const ArticleMetaTeasersDropzoneWrapper = styled.div`
	position: relative;
`;

export default ArticleMetaTeasersDropzone;
