import { v4 as uuid } from 'uuid';

export const upperCaseFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const displayName = (
	input: {
		firstname?: string | null;
		lastname?: string | null;
	} | null
) => {
	if (Boolean(input?.firstname) && Boolean(input?.lastname)) {
		return `${input?.firstname} ${input?.lastname}`;
	}

	return '';
};

export const randomId = uuid;
