import { Alert, Spin } from 'antd';
import { Component, type ComponentProps } from 'react';
import {
	graphql,
	QueryRenderer,
	createPaginationContainer,
	type RelayPaginationProp,
} from 'react-relay';
import styled from 'styled-components';

import environment from '../../environment';
import HistoryTimeline from '../../ui/StatusBar/HistoryTimeline';
import { historyItemToHistoryTimeline } from '../../ui/StatusBar/HistoryTimeline/HistoryTimeline';

import type { HistoryTimelineContainerQuery } from './__generated__/HistoryTimelineContainerQuery.graphql';
import type { HistoryTimelineContainer_query$data } from './__generated__/HistoryTimelineContainer_query.graphql';

const SpinLoader = styled(Spin)`
	width: 100%;
`;

type Props = {
	boardId?: string | null;
	articleId?: string | null;
	query: HistoryTimelineContainer_query$data;
	relay: RelayPaginationProp;
	totalItems: number;
};

class HistoryTimelineRefresher extends Component<Props> {
	componentDidUpdate(prevProps: Props) {
		if (prevProps.totalItems !== this.props.totalItems) {
			let count = 0;
			if (this.props.query.allHistories && Array.isArray(this.props.query.allHistories.edges)) {
				count = this.props.query.allHistories.edges.length;
				count += this.props.totalItems - prevProps.totalItems;
			}

			this.props.relay.refetchConnection(count, undefined, {
				condition: { articleId: this.props.articleId, boardId: this.props.boardId },
			});
		}
	}

	render() {
		const history: Array<ReturnType<typeof historyItemToHistoryTimeline>> = [];

		const edges = this.props.query.allHistories?.edges ?? [];

		if (edges.length > 0) {
			edges.forEach((edge) => {
				const node = edge.node ?? null;

				if (!node) {
					return;
				}

				try {
					history.push(historyItemToHistoryTimeline(node));
				} catch (err) {
					// eslint-disable-next-line no-console
					console.warn('Failed parsing history:', this.props.articleId, this.props.boardId);
				}
			});
		}

		return (
			<HistoryTimeline
				history={history}
				hasMore={this.props.relay.hasMore}
				loadMore={this.props.relay.loadMore}
			/>
		);
	}
}

const HistoryTimelineContainer = createPaginationContainer(
	HistoryTimelineRefresher,
	{
		query: graphql`
			fragment HistoryTimelineContainer_query on Query {
				allHistories(
					condition: $condition
					orderBy: CREATED_AT_DESC
					first: $count
					after: $cursor
				) @connection(key: "HistoryTimelineContainer_allHistories") {
					totalCount
					edges {
						node {
							createdAt
							rowId
							data
							authorByAccomplishedBy {
								name
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps(props) {
			return props.query && props.query.allHistories;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			};
		},
		getVariables(_props, { count, cursor }, fragmentVariables) {
			return {
				...fragmentVariables,
				count,
				cursor,
			};
		},
		query: graphql`
			query HistoryTimelineContainerPaginationQuery(
				$condition: HistoryCondition
				$count: Int!
				$cursor: Cursor
			) {
				...HistoryTimelineContainer_query
			}
		`,
	}
);

export default (props: Omit<ComponentProps<typeof HistoryTimelineContainer>, 'query'>) => (
	<QueryRenderer<HistoryTimelineContainerQuery>
		environment={environment}
		variables={{
			count: 10,
			condition: {
				articleId: props.articleId,
				boardId: props.boardId,
			},
		}}
		query={graphql`
			query HistoryTimelineContainerQuery(
				$condition: HistoryCondition
				$count: Int!
				$cursor: Cursor
			) {
				...HistoryTimelineContainer_query
			}
		`}
		render={({ error, props: renderProps }) => {
			if (error) {
				return <Alert message="Fehler" description={error.message} type="error" />;
			}
			if (renderProps) {
				return <HistoryTimelineContainer query={renderProps} {...props} />;
			}

			return <SpinLoader size="large" />;
		}}
	/>
);
