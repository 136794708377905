import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import config from './config';
import errorRecorder from './errorRecorder';
import { rootReducer } from './store/reducers';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (config: any) => typeof compose;
	}
}

let generalCompose: typeof compose;

// case: environment is 'development' or 'staging', and redux-devtools are available.
if (
	(config.VITE_NODE_ENV === 'development' || config.VITE_BRANCH === 'staging') &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
	generalCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		trace: true,
		traceLimit: 25,
	});
} else {
	generalCompose = compose;
}

export const store = createStore(
	rootReducer,
	{},
	generalCompose(applyMiddleware(errorRecorder.createCaptureActionMiddleware(), thunk))
);
