/**
 * @generated SignedSource<<6d4724b747a8e6891f848610a7bffc07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteArticlesTagByArticleIdAndTagIdInput = {
  articleId: string;
  clientMutationId?: string | null;
  tagId: number;
};
export type ArticleEditorDeleteArticleTagMutation$variables = {
  input: DeleteArticlesTagByArticleIdAndTagIdInput;
};
export type ArticleEditorDeleteArticleTagMutation$data = {
  readonly deleteArticlesTagByArticleIdAndTagId: {
    readonly tagByTagId: {
      readonly count: number;
      readonly rowId: number;
      readonly text: string;
    } | null;
  } | null;
};
export type ArticleEditorDeleteArticleTagMutation = {
  response: ArticleEditorDeleteArticleTagMutation$data;
  variables: ArticleEditorDeleteArticleTagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticleEditorDeleteArticleTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteArticlesTagPayload",
        "kind": "LinkedField",
        "name": "deleteArticlesTagByArticleIdAndTagId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tagByTagId",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticleEditorDeleteArticleTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteArticlesTagPayload",
        "kind": "LinkedField",
        "name": "deleteArticlesTagByArticleIdAndTagId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tagByTagId",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f6550174e31e5383226600cfde8b118",
    "id": null,
    "metadata": {},
    "name": "ArticleEditorDeleteArticleTagMutation",
    "operationKind": "mutation",
    "text": "mutation ArticleEditorDeleteArticleTagMutation(\n  $input: DeleteArticlesTagByArticleIdAndTagIdInput!\n) {\n  deleteArticlesTagByArticleIdAndTagId(input: $input) {\n    tagByTagId {\n      count\n      rowId\n      text\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d7f6448fbd938e9ce9f0bda9c0d752e";

export default node;
