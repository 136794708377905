import { Select } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

const { Option } = Select;

const StyledSelect = styled(Select<string>)`
	display: block;
	min-width: 150px;
`;

type Props = {
	className?: string;
	defaultValue?: string | null;
	disabled?: boolean;
	onChange: (value: string | null) => void;
	options: Array<{
		key: string;
		label: string;
	}>;
	placeholder: string;
	showEmpty?: boolean;
	value?: string;
};

export default class BoardLinkPickerDropdown extends Component<Props> {
	static defaultProps = {
		disabled: false,
		showEmpty: true,
	};

	handleChange = (value: string) => {
		const nextValue = value ? value : null;

		this.props.onChange(nextValue);
	};

	render() {
		const { className, disabled, placeholder, showEmpty } = this.props;

		let { options } = this.props;
		const defaultValue = options.find(({ key }) => key === this.props.defaultValue);
		const value = options.find(({ key }) => key === this.props.value) || defaultValue;

		if (showEmpty) {
			options = [{ key: '', label: 'Keine Verteilseite' }, ...options];
		}

		return (
			<StyledSelect
				className={className}
				disabled={disabled}
				onChange={this.handleChange}
				placeholder={placeholder}
				showSearch={true}
				value={value?.key}
			>
				{options.map(({ key, label }) => (
					<Option key={key} value={key}>
						{label}
					</Option>
				))}
			</StyledSelect>
		);
	}
}
