import type { Coords } from 'google-map-react';
import styled from 'styled-components';

import { Map } from '../../Map';
import type { MapChangeEvent, LocationMeta } from '../../Map';

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

type Props = {
	location: LocationMeta | undefined | null;
	onChange: (value: MapChangeEvent) => void;
	point?: Coords;
};

function ArticleMetaGeolocationLocation({ location, point, onChange }: Props) {
	return (
		<Row>
			<Map
				defaultCenter={point}
				defaultSelectedLocation={point}
				defaultSelectedLocationMeta={location}
				onChange={onChange}
				isSearchDisabled={false}
			/>
		</Row>
	);
}

export default ArticleMetaGeolocationLocation;
