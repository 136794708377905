import { Card } from 'antd';

import gs from '../../../../styles';

function B5TeaserItem() {
	return (
		<Card title="Teaser Rundschau und 110 Sekunden">
			<p className={gs.mbM}>
				Hier werden auf der Website die BR24 Rundschau und BR24 100 Sekunden angezeigt.
			</p>
		</Card>
	);
}

export default B5TeaserItem;
