import type { AppThunkAction } from '@/client/store/redux';

import type { GlobalGroup, UpdateGlobalGroupAction } from '../..';
import { AM_TYPE_GROUP, AM_GLOBAL_SPACE } from '../../../../constants';
import { UPDATE_GLOBAL_GROUP } from '../../actionTypes';
import { updateGlobalGroup as updateGlobalGroupInDatabase } from '../../database';

export function updateGlobalGroup(
	groupId: string,
	patch: Partial<GlobalGroup>,
	{ persist = true } = {}
): AppThunkAction<UpdateGlobalGroupAction> {
	return (dispatch, getState) => {
		const { assetManager } = getState();
		const oldItemIds = Object.keys(assetManager.spaces[AM_GLOBAL_SPACE][groupId].items);
		const patchItemIds = patch.items ? Object.keys(patch.items) : [];
		const itemsAreTheSame =
			oldItemIds.length === patchItemIds.length &&
			patchItemIds.every((k) => oldItemIds.includes(k));

		dispatch({
			type: UPDATE_GLOBAL_GROUP,
			payload: {
				...patch,
				items: patch.items ?? {},
				fresh: !itemsAreTheSame,
				type: AM_TYPE_GROUP,
				id: groupId,
			},
		});

		if (persist) {
			updateGlobalGroupInDatabase(groupId, patch);
		}
	};
}
