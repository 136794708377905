/**
 * @generated SignedSource<<26b0022cb22f2e8867b41de746a1b18c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BoardsTeaserStatus = "DELETED" | "PUBLISHED";
import { FragmentRefs } from "relay-runtime";
export type BoardsTeaserSearchItem_boardsTeaser$data = {
  readonly createdAt: string;
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly link: {
    readonly label: string | null;
  } | null;
  readonly rowId: string;
  readonly status: BoardsTeaserStatus | null;
  readonly title: string;
  readonly updatedAt: string | null;
  readonly " $fragmentType": "BoardsTeaserSearchItem_boardsTeaser";
};
export type BoardsTeaserSearchItem_boardsTeaser$key = {
  readonly " $data"?: BoardsTeaserSearchItem_boardsTeaser$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardsTeaserSearchItem_boardsTeaser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardsTeaserSearchItem_boardsTeaser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleImage",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "link",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "BoardsTeaser",
  "abstractKey": null
};

(node as any).hash = "f6e42036d007017e6fa8fa2bcbc10af6";

export default node;
