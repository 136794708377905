/**
 * @generated SignedSource<<6d7e439e73c0ef6820d75c5302a463e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Capability = "BASIC" | "FULLY_FEATURED" | "TEXT_ONLY";
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
import { FragmentRefs } from "relay-runtime";
export type ArticleSearchItem_article$data = {
  readonly capability: Capability;
  readonly primaryCategory: {
    readonly color: string | null;
    readonly rowId: string;
    readonly title: string;
  } | null;
  readonly publicationDate: string | null;
  readonly rowId: string;
  readonly status: Status;
  readonly teaserImage: string | null;
  readonly title: string;
  readonly " $fragmentType": "ArticleSearchItem_article";
};
export type ArticleSearchItem_article$key = {
  readonly " $data"?: ArticleSearchItem_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleSearchItem_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleSearchItem_article",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": "primaryCategory",
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categoryByPrimaryCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teaserImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicationDate",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "1a137f80170ad0d58364dcde91956afd";

export default node;
