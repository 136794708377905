import { Component } from 'react';
import styled from 'styled-components';

import styles from '../../../styles';

import { getChartDataModuleTypes, getChartDataTotal } from './util';

type Props = {
	data: {
		type: string;
		count: number;
	}[];
};

const HorizontalLine = styled.hr`
	border-style: dotted;
	border-top: 1px;
	margin-bottom: 6px;
`;

class ModuleAllocationStatsLegend extends Component<Props> {
	shouldComponentUpdate({ data: nextData }: Props) {
		return this.props.data !== nextData;
	}

	render() {
		const { data } = this.props;

		const dataTotal = getChartDataTotal(data);
		const dataModuleTypes = getChartDataModuleTypes(data);

		return (
			<div>
				<ul className={styles.chartLegend}>
					<li>
						<div style={{ backgroundColor: dataTotal.totalAv.fill }}>
							{dataTotal.totalAv.percentage}%
						</div>{' '}
						{dataTotal.totalAv.name}
					</li>

					<li>
						<div style={{ backgroundColor: dataTotal.totalText.fill }}>
							{dataTotal.totalText.percentage}%
						</div>{' '}
						{dataTotal.totalText.name}
					</li>
				</ul>

				<HorizontalLine />

				<ul className={styles.chartLegend}>
					<li>
						<div style={{ backgroundColor: dataModuleTypes.video.fill }}>
							{dataModuleTypes.video.percentage}%
						</div>{' '}
						{dataModuleTypes.video.name}
					</li>

					<li>
						<div style={{ backgroundColor: dataModuleTypes.audio.fill }}>
							{dataModuleTypes.audio.percentage}%
						</div>{' '}
						{dataModuleTypes.audio.name}
					</li>

					<li>
						<div style={{ backgroundColor: dataModuleTypes.text.fill }}>
							{dataModuleTypes.text.percentage}%
						</div>{' '}
						{dataModuleTypes.text.name}
					</li>

					<li>
						<div style={{ backgroundColor: dataModuleTypes.breakingNews.fill }}>
							{dataModuleTypes.breakingNews.percentage}%
						</div>{' '}
						{dataModuleTypes.breakingNews.name}
					</li>
				</ul>
			</div>
		);
	}
}

export default ModuleAllocationStatsLegend;
