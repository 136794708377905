import { Row, Col, Collapse, Form } from 'antd';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import {
	addSocialMediaAccount,
	updateSocialMediaAccount,
	deleteSocialMediaAccount,
	provideSocialMediaAccountsByBoardId,
} from '../../../store/reducers/boardBuffer';
import SocialMediaAccounts from '../../SocialMediaAccounts';

const { Panel } = Collapse;

interface Props extends ReduxProps, OwnProps {}

function MetaSocialMediaComponent({ items, add, update, delete: del, ...rest }: Props) {
	return (
		<Panel key="" {...rest} header="Social Media">
			<Form layout="vertical">
				<Row>
					<Col style={{ width: '100%' }}>
						<SocialMediaAccounts onAdd={add} onUpdate={update} onDelete={del} items={items} />
					</Col>
				</Row>
			</Form>
		</Panel>
	);
}

interface OwnProps {
	boardId: string;
}

const connector = connect(
	({ boardBuffer }: ReduxState, { boardId }: OwnProps) => ({
		items: provideSocialMediaAccountsByBoardId(boardBuffer, boardId),
	}),
	(dispatch, { boardId }: OwnProps) => ({
		add: (socialMediaAccount: any) => dispatch(addSocialMediaAccount(boardId, socialMediaAccount)),
		update: (socialMediaAccount: any) =>
			dispatch(
				updateSocialMediaAccount(socialMediaAccount.__meta__.internalId, socialMediaAccount)
			),
		delete: (socialMediaAccount: any) =>
			dispatch(deleteSocialMediaAccount(socialMediaAccount.__meta__.internalId)),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export const MetaSocialMedia = connector(MetaSocialMediaComponent);
