import { Input as AntdInput } from 'antd';

import { AbstractHTMLElement } from './AbstractHTMLElement';

const { TextArea: AntdTextArea } = AntdInput;

export default class Input extends AbstractHTMLElement<typeof AntdTextArea> {
	render() {
		return <AntdTextArea {...this.getChildProps()} />;
	}
}
