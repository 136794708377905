import type { ComponentType, FunctionComponent } from 'react';

import { getDisplayName } from '../util/react';

import type { TranslationContextType } from './Context';
import { translationContext } from './Context';

/**
 * Omit type that behaves better with union types
 *
 * @see https://github.com/microsoft/TypeScript/issues/39556
 */
type BetterOmit<T, K extends string | number | symbol> = T extends unknown ? Omit<T, K> : never;

export type WithTranslationProps = {
	translation: TranslationContextType;
};

/**
 * use like this:
 *
 * export default withTranslation(
	class ArticleStatusTimeControlForm extends Component<Props, State> {
		...
	})


 * @param WrappedComponent
 * @returns
 */
export function withTranslation<P extends WithTranslationProps>(
	WrappedComponent: ComponentType<P>
) {
	// Creating the inner component. The calculated Props type here is the where the magic happens.
	const ComponentWithTranslation: FunctionComponent<BetterOmit<P, keyof WithTranslationProps>> = (
		props: BetterOmit<P, keyof WithTranslationProps>
	) => {
		// Fetch the props you want to inject. This could be done with context instead.

		// props comes afterwards so the can override the default ones.
		// return <WrappedComponent {...themeProps} {...(props as T)} />;
		return (
			<translationContext.Consumer>
				{(context) => <WrappedComponent {...(props as any)} translation={context} />}
			</translationContext.Consumer>
		);
	};
	// Try to create a nice displayName for React Dev Tools.
	ComponentWithTranslation.displayName = `withTranslation(${getDisplayName(WrappedComponent)})`;

	return ComponentWithTranslation;
}
