import update from 'immutability-helper';

import { generateReducer } from '@/client/util/generateReducer';
// import { BOARDS_TEASERS_MANAGER_UPDATE_FILTER } from '@sep/br24-constants';

const BOARDS_TEASERS_MANAGER_UPDATE_FILTER = 'BOARDS_TEASERS_MANAGER_UPDATE_FILTER';
const STORAGE_KEY = 'br-boardsTeasers-filter';

export type SearchQuery = {
	searchTerm: string | undefined | null;
};

const initialState: SearchQuery = {
	searchTerm: null,
	...JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}'),
};

export default generateReducer(
	initialState,
	{
		[BOARDS_TEASERS_MANAGER_UPDATE_FILTER]: (state: SearchQuery, action: UpdateFilterAction) =>
			update(state, {
				$merge: action.payload,
			}),
	},
	(nextState: SearchQuery, action) => {
		if (action.type === BOARDS_TEASERS_MANAGER_UPDATE_FILTER) {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(nextState));
		}
	}
);

export type UpdateFilterAction = {
	type: typeof BOARDS_TEASERS_MANAGER_UPDATE_FILTER;
	payload: SearchQuery;
};

export function updateFilter(payload: SearchQuery): UpdateFilterAction {
	return {
		type: BOARDS_TEASERS_MANAGER_UPDATE_FILTER,
		payload,
	};
}
