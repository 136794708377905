export default {
	article: '/articleEditor',
	articleById: '/articleEditor/:id',
	articles: '/articles',
	authorById: '/authors/:id',
	authors: '/authors',
	boardById: '/boards/:id',
	boards: '/boards',
	boardsTeaserById: '/boardsTeasers/:id',
	boardsTeasers: '/boardsTeasers',
	comments: '/comments',
	dashboard: '/',
	faq: '/faq',
	login: '/login',
	logout: '/logout',
	navigationById: '/navigation-manager/:id',
	navigations: '/navigation-manager',
	profile: '/user-settings',
} as const;
