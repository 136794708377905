import { Form, Row, Col, Collapse } from 'antd';

import { Input } from '../Form';

const { Panel } = Collapse;

type Props = {
	rssFeed: string | null;
	rdfFeed: string | null;
};

export function MetaFeeds({ rssFeed, rdfFeed }: Props) {
	return (
		<Panel key="" header="Feeds">
			<Form layout="vertical">
				<Row>
					<Col span="24">
						<Form.Item label="RSS-Feed">
							<Input placeholder="RSS-Feed" value={rssFeed || ''} disabled={true} />
						</Form.Item>
						<Form.Item label="Sophora-Import-Feed">
							<Input placeholder="Sophora-Import-Feed" value={rdfFeed || ''} disabled={true} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Panel>
	);
}
