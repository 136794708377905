import { DownOutlined, SaveOutlined } from '@ant-design/icons';
import {
	STATUS_DRAFT,
	STATUS_REVIEW,
	STATUS_DEPUBLISHED,
	STATUS_PUBLISHED,
	COLOR_SUN_FLOWER,
} from '@sep/br24-constants';
import { Dropdown, Button, type DropdownProps, type ButtonProps } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import styled, { type StyledProps } from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import config from '../../../config';
import { update, provideBoardById } from '../../../store/reducers/boardBuffer';
import type { BoardStatus } from '../../../store/reducers/boardBuffer';
import type { WithTranslationProps } from '../../../translation';
import translations from '../../../translations';

interface Props extends WithTranslationProps, ReduxProps, OwnProps {
	onRequestSave: () => void;
	isDisabled: boolean;
}

interface StatusProps {
	disabled?: boolean;
	$isTemplate?: boolean;
	$status: BoardStatus | undefined | null;
}

interface StatusDropdownProps extends DropdownProps, StatusProps {}
interface StatusButtonProps extends ButtonProps, StatusProps {}

const getStatusColor = (props: StyledProps<StatusProps>) => {
	if (props.$isTemplate) {
		return COLOR_SUN_FLOWER;
	}

	if (props.disabled) {
		return props.theme?.colors.lightAntd;
	}

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return config.statusTagColorMap[props.$status!];
};

const StatusButton = styled(({ ...rest }: StatusButtonProps) => <Button {...rest} />)`
	padding: 5px;
	width: 32px;
	&:hover,
	&:focus {
		filter: ${({ disabled }) => (disabled ? 'none' : 'brightness(115%)')};
		color: #fff;
		background-color: ${(props) => (props.$status ? getStatusColor(props) : null)};
		border-color: ${(props) => (props.$status ? getStatusColor(props) : null)};
	}
	&:active {
		filter: brightness(85%);
	}

	background-color: ${(props) => (props.$status ? getStatusColor(props) : null)};
	border-color: ${(props) => (props.$status ? getStatusColor(props) : null)};

	& > svg {
		color: #fff;
	}
`;

const StatusDropDown = styled(({ ...rest }: StatusDropdownProps) => <Dropdown {...rest} />)`
	color: #fff;
	background-color: ${(props) => (props.$status ? getStatusColor(props) : null)};
	border-color: ${(props) => (props.$status ? getStatusColor(props) : null)};
	&:hover,
	&:focus {
		filter: ${({ disabled }) => (disabled ? 'none' : 'brightness(115%)')};
		color: #fff;
		background-color: ${(props) => (props.$status ? getStatusColor(props) : null)};
		border-color: ${(props) => (props.$status ? getStatusColor(props) : null)};
	}
	&:active {
		filter: brightness(85%);
	}
`;

class PublicationStatusPicker extends Component<Props> {
	handleClickStatus = ({ key: nextStatus }) => this.props.update(nextStatus);

	render() {
		const {
			onRequestSave,
			isProtected,
			status,
			previousStatus,
			isDisabled,
			translation: { translate },
		} = this.props;
		const menu: ItemType[] = [];

		const tooltipText = isDisabled
			? translate('board.saveButton.locked')
			: translate('board.saveButton.save');

		if (!isProtected) {
			if (status === STATUS_DRAFT || status === STATUS_REVIEW) {
				menu.push({
					label: translate('board.publicationStatusPicker.publish'),
					key: STATUS_PUBLISHED,
				});
			}

			if (status === STATUS_PUBLISHED || status === STATUS_DEPUBLISHED) {
				menu.push({
					label: translate('board.publicationStatusPicker.changeToDraft'),
					key: STATUS_DRAFT,
				});
			}

			if (status === STATUS_PUBLISHED) {
				menu.push({
					label: translate('board.publicationStatusPicker.depublish'),
					key: STATUS_DEPUBLISHED,
				});
			}
		}

		return (
			<>
				<StatusDropDown
					$status={this.props.status}
					menu={{ items: menu, onClick: this.handleClickStatus }}
				>
					<Button>
						<>
							{previousStatus ? translations[previousStatus] : ''}{' '}
							{previousStatus !== status && status ? `(${translations[status]})` : false}{' '}
							{menu.length > 0 ? <DownOutlined /> : false}
						</>
					</Button>
				</StatusDropDown>
				<StatusButton
					type="primary"
					onClick={onRequestSave}
					disabled={isDisabled}
					$status={this.props.status}
					loading={this.props.isWorking}
					title={tooltipText}
					icon={<SaveOutlined />}
				/>
			</>
		);
	}
}

interface OwnProps {
	boardId: string;
}

const connector = connect(
	({ boardBuffer }: ReduxState, { boardId }: OwnProps) => {
		const { status, protected: isProtected } = provideBoardById(boardBuffer, boardId) || {};

		return {
			status,
			isWorking: boardBuffer.isWorkingByBoard[boardId],
			previousStatus: boardBuffer.raw[boardId].status,
			isProtected: isProtected,
		};
	},
	(dispatch, { boardId }: OwnProps) => ({
		update: (value: BoardStatus) => dispatch(update(boardId, { status: value })),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(PublicationStatusPicker));
