/**
 * @generated SignedSource<<0269ad0396db1c2aa017a684fe72108e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type PreviewLinkContainerQuery$variables = {
  rowId: string;
};
export type PreviewLinkContainerQuery$data = {
  readonly boardByRowId: {
    readonly rowId: string;
    readonly slug: string;
    readonly status: Status | null;
    readonly title: string;
  } | null;
};
export type PreviewLinkContainerQuery = {
  response: PreviewLinkContainerQuery$data;
  variables: PreviewLinkContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewLinkContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreviewLinkContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2cf4a4c4da4b81f4bc86879d8a8d61e4",
    "id": null,
    "metadata": {},
    "name": "PreviewLinkContainerQuery",
    "operationKind": "query",
    "text": "query PreviewLinkContainerQuery(\n  $rowId: String!\n) {\n  boardByRowId(rowId: $rowId) {\n    rowId\n    title\n    slug\n    status\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8789cb5d90937d21854bdd893c8b8585";

export default node;
