import styled from 'styled-components';

import CategoryPicker from '../../components/CategoryPicker';

export default styled(CategoryPicker)`
	width: 100%;

	.ant-select-selection {
		background-color: ${(props) => props.theme.assetManager.itemColor};
		color: ${(props) => props.theme.colors.white};
		height: 38px;
		border-radius: 3px;
	}

	.ant-select-selection-selected-value {
		line-height: 38px;
	}

	.ant-select-arrow {
		color: ${(props) => props.theme.colors.white};
	}
`;
