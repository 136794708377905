import { Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { Info } from '../../Icon';

type Props = WithTranslationProps & {
	itemCount: number;
	isLocked: boolean;
	createdAt: string | null;
	createdBy: string | null;
	lastModifiedAt: string | null;
};

const getDateStr = (dateStr: string): string => {
	const isLongAgo = moment().local().diff(dateStr, 'days') > 7;
	return isLongAgo
		? moment(dateStr).local().format('DD.MM.YYYY')
		: moment(dateStr).local().fromNow();
};

const Line = styled.p`
	white-space: nowrap;
	font-size: 0.9em;
`;

// There are currently a couple of lines in the translation with the pipe character to indicate a line break.
// Otherwise we run into a layout issue, where the popup is too big and triggers an overflow->scrollbar loop
const formatMessage = (message: string) => {
	if (message.indexOf('|') < 0) {
		return message;
	}

	const lines = message.split('|');
	return (
		<>
			{lines.map((line, index) => (
				<Line key={index}>{line}</Line>
			))}
		</>
	);
};

function AssetManagerInfoButton({
	createdAt,
	isLocked,
	createdBy,
	itemCount,
	lastModifiedAt,
	translation: { translate },
}: Props) {
	const text = (
		<>
			<Line>
				<strong>{translate('assetManager.groups.infoItems')}:</strong> {itemCount}
			</Line>

			{createdBy && (
				<Line>
					<strong>{translate('assetManager.groups.infoCreatedBy')}:</strong> {createdBy}
				</Line>
			)}

			{createdAt && (
				<Line>
					<strong>{translate('assetManager.groups.infoCreatedAt')}:</strong> {getDateStr(createdAt)}
				</Line>
			)}

			{lastModifiedAt && (
				<Line>
					<strong>{translate('assetManager.groups.infoModified')}:</strong>{' '}
					{getDateStr(lastModifiedAt)}
				</Line>
			)}

			<Line>&nbsp;</Line>
			{isLocked
				? formatMessage(translate('assetManager.groups.infoLocked'))
				: formatMessage(translate('assetManager.groups.infoNotLocked'))}
		</>
	);

	return (
		<Tooltip
			overlayStyle={{ filter: 'invert()' }}
			placement="topRight"
			align={{ offset: [15, 0] }}
			title={text}
		>
			<Info />
		</Tooltip>
	);
}

export default withTranslation(AssetManagerInfoButton);
