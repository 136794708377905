/**
 * @generated SignedSource<<cba5a107b151d84538444c5bfc1eb80a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CategoryArticleStatsContainerQuery$variables = {
  endDate?: string | null;
  startDate?: string | null;
};
export type CategoryArticleStatsContainerQuery$data = {
  readonly stats: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly articleCount: number | null;
        readonly color: string | null;
        readonly title: string;
      } | null;
    }>;
  } | null;
};
export type CategoryArticleStatsContainerQuery = {
  response: CategoryArticleStatsContainerQuery$data;
  variables: CategoryArticleStatsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "fromDate",
      "variableName": "startDate"
    },
    {
      "kind": "Variable",
      "name": "toDate",
      "variableName": "endDate"
    }
  ],
  "kind": "ScalarField",
  "name": "articleCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CategoryArticleStatsContainerQuery",
    "selections": [
      {
        "alias": "stats",
        "args": null,
        "concreteType": "CategoriesConnection",
        "kind": "LinkedField",
        "name": "allCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CategoriesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CategoryArticleStatsContainerQuery",
    "selections": [
      {
        "alias": "stats",
        "args": null,
        "concreteType": "CategoriesConnection",
        "kind": "LinkedField",
        "name": "allCategories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CategoriesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d094b56e6113338c8c5558a414b5311",
    "id": null,
    "metadata": {},
    "name": "CategoryArticleStatsContainerQuery",
    "operationKind": "query",
    "text": "query CategoryArticleStatsContainerQuery(\n  $startDate: Datetime\n  $endDate: Datetime\n) {\n  stats: allCategories {\n    edges {\n      node {\n        title\n        color\n        articleCount(fromDate: $startDate, toDate: $endDate)\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "158dde1a2eed25825544444bdc51c879";

export default node;
