import { darken } from 'polished';
import styled from 'styled-components';

type AssetManagerFilterButtonProps = {
	$isActive?: boolean;
};

const AssetManagerFilterButton = styled('button')<AssetManagerFilterButtonProps>`
	width: 40px;
	height: 40px;
	flex: 1 0 auto;
	border: none;
	background-color: ${(props) => (props.$isActive ? props.theme?.colors.primaryBlue : '#fcfcfc')};
	color: ${(props) => (props.$isActive ? '#ffffff' : '#4c4c4c')};
	outline: none;
	cursor: pointer;
	&:hover {
		color: ${(props) => (props.$isActive ? '#ffffff' : props.theme?.colors.primaryBlue)};
		background-color: ${(props) =>
			darken(0.1, props.$isActive ? props.theme?.colors.primaryBlue : '#fcfcfc')};
	}
`;

export default AssetManagerFilterButton;
