import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'react-relay';

import type { BoardSectionSchema } from '../store/reducers/boardBuffer';

import type {
	UpdateBoardSectionMutation as UpdateBoardSectionMutationTypes,
	UpdateBoardSectionMutation$data,
} from './__generated__/UpdateBoardSectionMutation.graphql';

const mutation = graphql`
	mutation UpdateBoardSectionMutation($input: UpdateBoardsSectionByRowIdInput!) {
		updateBoardsSectionByRowId(input: $input) {
			boardsSection {
				rowId
				schema
				categoryId
				title
				color
				autofill
				meta
				order
				showHeroTitleForMobile
				updatedBy
				updatedAt
				createdBy
				createdAt
			}
		}
	}
`;

export type UpdateBoardSectionInput = {
	schema?: BoardSectionSchema;
	categoryId?: string | null;
	title?: string | null;
	color?: string | null;
	autofill?: boolean;
	meta?: any | null;
	order?: number;
	showHeroTitleForMobile?: boolean;
};

export type UpdateBoardSectionOutput =
	| NonNullable<UpdateBoardSectionMutation$data['updateBoardsSectionByRowId']>['boardsSection'];

const execute = (
	boardSectionRowId: string,
	input: UpdateBoardSectionInput,
	environment: Environment
) =>
	new Promise<UpdateBoardSectionMutationTypes['response']>((resolve, reject) => {
		commitMutation<UpdateBoardSectionMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					rowId: boardSectionRowId,
					boardsSectionPatch: input,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});

export async function UpdateBoardSectionMutation(
	boardSectionRowId: string,
	input: UpdateBoardSectionInput,
	environment: Environment
): Promise<UpdateBoardSectionOutput> {
	if (typeof input.meta === 'object') {
		input.meta = JSON.stringify(input.meta); // eslint-disable-line no-param-reassign
	}

	const res = await execute(boardSectionRowId, input, environment);
	if (!res || !res.updateBoardsSectionByRowId || !res.updateBoardsSectionByRowId.boardsSection) {
		throw new Error('Something went wrong');
	}
	return res.updateBoardsSectionByRowId.boardsSection;
}
