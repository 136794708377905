import { Col, Row } from 'antd';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { useDispatch } from '@/client/store/redux';

import * as authorsActions from '../../store/reducers/authorsFilter';
import { Page } from '../Page';

import AuthorsFilter from './AuthorsFilter';
import AuthorsListContainer from './AuthorsListContainer';

const StyledPage = styled(Page)`
	max-width: 1900px;
	justify-self: center;
	width: 100%;
`;

const StyledAuthorListColumn = styled(Col)`
	padding-inline: 2em;
`;

export default function Authors() {
	const filter = useSelector((state: ReduxState) => state.authorsFilter);
	const dispatch = useDispatch();

	const handleFilterChange = useCallback(
		<T extends keyof authorsActions.AuthorsFilterType>(
			key: T,
			value: authorsActions.AuthorsFilterType[T]
		): void => {
			dispatch(authorsActions.updateFilter({ [key]: value }));
		},
		[dispatch]
	);

	return (
		<>
			<Helmet>
				<title>Autoren</title>
			</Helmet>

			<StyledPage>
				<Row>
					<Col span={24}>
						<AuthorsFilter currentFilter={filter} onChange={handleFilterChange} />
					</Col>
					<StyledAuthorListColumn span={24}>
						<AuthorsListContainer currentFilter={filter} />
					</StyledAuthorListColumn>
				</Row>
			</StyledPage>
		</>
	);
}
