import type { AppThunkAction } from '@/client/store/redux';

import { AM_TYPE_ARTICLE, AM_MODE_IMPORT, AM_IMPORTER_NEWSROOM } from '../../../constants';
import type {
	ActivateImporterAction,
	ChangeModeAction,
	UpdateSearchTerm,
} from '../../AssetManager';
import { CHANGE_MODE } from '../../AssetManager/actionTypes';
import activateImporter from '../../AssetManager/actions/activateImporter';
import importNewsroom from '../../AssetManager/actions/import/importNewsroom/importNewsroom';
import updateImporterSearchTermFromArticleEditor from '../../AssetManager/actions/updateImporterSearchTermFromArticleEditor';
import updateVisibility from '../../AssetManager/actions/updateVisibility';

export default (
		term: string
	): AppThunkAction<UpdateSearchTerm | ActivateImporterAction | ChangeModeAction> =>
	(dispatch) => {
		dispatch(updateVisibility(true));

		dispatch(updateImporterSearchTermFromArticleEditor(term));
		dispatch(
			importNewsroom(
				{ mode: null },
				{
					term,
					categoryFilter: null,
					categoryFilterTitle: null,
					endDate: null,
					startDate: null,
					typeFilter: AM_TYPE_ARTICLE,
				}
			)
		);

		setTimeout(() => {
			dispatch(activateImporter(AM_IMPORTER_NEWSROOM));

			dispatch({
				type: CHANGE_MODE,
				payload: AM_MODE_IMPORT,
			});
		}, 500);
	};
