import type { RemoveItemFromImporterAction, RemoveItemGroupFromImporterAction, Importer } from '..';
import { REMOVE_ITEM_FROM_IMPORTER, REMOVE_ITEM_GROUP_FROM_IMPORTER } from '../actionTypes';

export const removeItemFromImporter = (
	importer: Importer,
	id: string
): RemoveItemFromImporterAction => ({
	type: REMOVE_ITEM_FROM_IMPORTER,

	payload: {
		importer,
		id,
	},
});

export const removeItemGroupFromImporter = (
	importer: Importer,
	itemGroupTitle: string
): RemoveItemGroupFromImporterAction => ({
	type: REMOVE_ITEM_GROUP_FROM_IMPORTER,

	payload: {
		importer,
		itemGroupTitle,
	},
});
