import { Component, createRef, type KeyboardEvent } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import type { Image, Validation } from '../../../containers/ArticleEditor';
import { convertThumbnailValidation } from '../../../util/convertThumbnailValidation';
import { ArrowLeft, ArrowRight } from '../../Icon';
import { ArticleModuleImage } from '../ArticleModuleImage';
import type { ModulePartInputElement } from '../ArticleModuleParts/ModulePartInput/ModulePartInput';

type Props = {
	images: Array<Image>;
	activeElement: number;
	validation?: Validation | null;
	isEditing: boolean;
	isThumbnailUploading: boolean;
	onChange: (key: string, value: string, index: number) => void;
	onNextImage: (index: number) => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
};

const ArrowButtonContainer = styled.div`
	border-radius: 40px;
	height: 50px;
	width: 50px;
	margin-left: 10px;
	margin-right: 10px;
	display: flex;
	position: absolute;
	top: 50%;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.colors.light1};
	z-index: 1000;
	cursor: pointer;
	> svg {
		font-size: 1.2em;
	}
`;

const ArrowButtonContainerRight = styled(ArrowButtonContainer)`
	right: 0;
`;

type ArrowButtonProps = {
	onClick?: () => void;
};

function ArrowButtonLeft(props: ArrowButtonProps) {
	const { onClick } = props;
	return (
		<ArrowButtonContainer onClick={onClick}>
			<ArrowLeft />
		</ArrowButtonContainer>
	);
}

function ArrowButtonRight(props: ArrowButtonProps) {
	const { onClick } = props;
	return (
		<ArrowButtonContainerRight onClick={onClick}>
			<ArrowRight />
		</ArrowButtonContainerRight>
	);
}

export class ArticleModuleGallerySlider extends Component<Props> {
	private slider = createRef<Slider>();

	handleChange = (key: 'url' | 'title' | 'altText' | 'copyright', value: string, index: number) => {
		this.props.onChange(key, value, index);
	};

	handleKeyDown = (event: KeyboardEvent<ModulePartInputElement>) => {
		const target = event.target as ModulePartInputElement;

		const currentFocus = target.id;

		if (currentFocus === 'copyright' && event.key === 'Tab') {
			event.preventDefault();
			this.props.onNextImage(this.props.activeElement + 1);
		}
	};

	render() {
		const { images, activeElement, validation, onChange, ...rest } = this.props;

		// jump to clicked thumbnail
		if (this.slider && this.slider.current) {
			this.slider.current.slickGoTo(activeElement);
		}

		return (
			<Slider
				ref={this.slider}
				dots={false}
				infinite={true}
				speed={500}
				lazyLoad={'progressive'}
				slidesToShow={1}
				slidesToScroll={1}
				swipe={false}
				nextArrow={<ArrowButtonRight />}
				prevArrow={<ArrowButtonLeft />}
				afterChange={(current: number) => this.props.onNextImage(current)}
			>
				{Array.isArray(images) && images.length > 0
					? images.map((image, index) => (
							<ArticleModuleImage
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								altText={image.altText || ''}
								copyright={image.copyright || ''}
								title={image.title || ''}
								url={image.url || ''}
								onKeyDown={this.handleKeyDown}
								onChange={(key, value) => this.handleChange(key, value, index)}
								validation={
									validation
										? convertThumbnailValidation({
												title: validation[`gallery.${index}.title`] || null,
												url: validation[`gallery.${index}.url`] || null,
												altText: validation[`gallery.${index}.altText`] || null,
												copyright: validation[`gallery.${index}.copyright`] || null,
										  })
										: null
								}
								{...rest}
							/>
					  ))
					: null}
			</Slider>
		);
	}
}
