import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateItemOptions, UpdateItemAction, Space, Item } from '..';
import { UPDATE_ITEM } from '../actionTypes';
import * as database from '../database';

function updateItem(
	space: Space,
	id: string,
	patch: Item,
	{ persist }: UpdateItemOptions = { persist: true }
): AppThunkAction<UpdateItemAction> {
	return async (dispatch) => {
		if (persist) {
			await database.updateItem(space, id, patch);
		}

		dispatch({
			type: UPDATE_ITEM,
			payload: {
				space,
				id,
				patch: patch,
			},
		});
	};
}

export default updateItem;
