import { MODULE_TYPE_IMAGE, MODULE_TYPE_GALLERY } from '@sep/br24-constants';
import type { UploadFile } from 'antd';
import { Component } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import type { ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { CreateModuleInput } from '../..';
import { UserContainer } from '../../../../auth/AuthContext';
import config from '../../../../config';
import type { WithTranslationProps } from '../../../../translation';
import { ArticleModuleUploader } from '../../../../ui/ArticleModule';
import { UploadCloud } from '../../../../ui/Icon';
import type { Item } from '../../../AssetManager';
import { DndCreateModuleContainer } from '../../DragAndDropHandler';
import {
	getAllowedDataTypes,
	toCreateModule,
	allAssetManagerTypes,
} from '../../DragAndDropHandler/dndUtils';
import createModule from '../../actions/createModule';
import convert from '../../convert';
import { articleInfoValidationSelector } from '../../selectors';

const Wrapper = styled.div`
	margin-top: 25px;
`;

interface Props extends WithTranslationProps, ReduxProps {}

class StartdropzoneContainer extends Component<Props> {
	handleAddViaInitialDropzone = async (dropItem: Item | undefined) => {
		// check if dropitem exists, because if not
		// a file from outside the app has dropped
		if (!dropItem) {
			return;
		}

		const convertedItem = await convert(dropItem);
		const { createNewModule } = this.props;

		if (convertedItem) {
			createNewModule({ order: 0, ...convertedItem });
		}
	};

	handleSuccessfulUpload = (files: UploadFile<{ url: string }>[] | UploadFile<{ url: string }>) => {
		const { createNewModule } = this.props;

		if (!Array.isArray(files)) {
			files = [files];
		}

		const gallery = files.map((image) => ({
			url: image.response?.url,
			title: '',
			altText: '',
			copyright: '',
		}));

		if (files.length > 1) {
			createNewModule({
				type: MODULE_TYPE_GALLERY,
				order: 0,

				data: {
					gallery,
				},
			} as CreateModuleInput);
		}

		if (files.length === 1) {
			const [newImage] = files;

			createNewModule({
				type: MODULE_TYPE_IMAGE,
				order: 0,

				data: {
					image: {
						url: newImage.response?.url,
						title: '',
						altText: '',
						copyright: '',
					},
				},
			} as CreateModuleInput);
		}
	};

	render() {
		const {
			translation: { translate },
			validation,
		} = this.props;

		const acceptedDataTypes = getAllowedDataTypes();

		return (
			<Wrapper>
				<DndCreateModuleContainer
					onAddViaInitialDropzone={this.handleAddViaInitialDropzone}
					acceptedItemTypes={[...toCreateModule, NativeTypes.FILE]}
					itemTypes={[...allAssetManagerTypes, NativeTypes.FILE]}
					onResetAttachedDropzone={() => {
						/* noop */
					}}
					onAddViaAttachedDropzone={() => {
						/* noop */
					}}
				>
					<UserContainer>
						{(user) => (
							<ArticleModuleUploader
								icon={<UploadCloud />}
								label={translate('modules.mixedDropzone.defaultPlaceholder')}
								user={user}
								action={`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`}
								multiple={true}
								acceptedDataTypes={acceptedDataTypes}
								validation={validation ? !!validation.modules : false}
								onSuccess={this.handleSuccessfulUpload}
							/>
						)}
					</UserContainer>
				</DndCreateModuleContainer>
			</Wrapper>
		);
	}
}

const connector = connect(articleInfoValidationSelector, {
	createNewModule: createModule,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(StartdropzoneContainer));
