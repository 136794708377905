import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import invariant from 'invariant';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

import { media } from '../../util/styled';

export const HeaderPrimaryNavigation = styled.nav`
	height: ${(props) => props.theme.header.height};
`;

export const HeaderPrimaryNavigationList = styled.ul`
	list-style-type: none;
	margin: 0;
	padding: 0;
`;

type HeaderPrimaryNavigationListItemAnchorProps = {
	isActive: boolean;
};

export const HeaderPrimaryNavigationListItemAnchor = styled.a<{ $isActive?: boolean }>`
	display: inline-block;
	height: ${(props) => props.theme?.header.height};
	line-height: ${(props) => props.theme?.header.height};
	padding: 0 1.6rem;

	color: ${(props) => props.theme?.colors.br24Font};
	transition: all 300ms ease;
	&:hover {
		color: ${(props) => props.theme?.colors.br24Font};
		background-color: ${(props) =>
			props.$isActive ? 'background-color: rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.2)'};
		.label {
			display: inline-block;
		}
		.icon {
			margin-right: 8px;
		}
	}
	${(props) => (props.$isActive ? 'background-color: rgba(0, 0, 0, 0.4)' : '')};
`;

export const HeaderPrimaryNavigationListItemIcon = styled(FontAwesomeIcon)`
	margin-right: 8px;
	${media.lessThan('medium')`
		margin-right: 0;
	`};
`;

export const HeaderPrimaryNavigationListItemLabel = styled.span`
	${media.lessThan('medium')`
		display: none;
	`};
`;

type HeaderPrimaryNavigationListItemProps = {
	action?: string;
	className?: string;
	icon?: IconProp;
	label?: string;
	onClick?: (
		event: SyntheticEvent<HTMLAnchorElement>,
		action?: string | null,
		route?: string | null
	) => void;
	route?: string;
} & HeaderPrimaryNavigationListItemAnchorProps;

export const HeaderPrimaryNavigationListItem = styled(
	({
		action,
		className,
		icon,
		isActive = false,
		label,
		onClick,
		route,
		...rest
	}: HeaderPrimaryNavigationListItemProps) => {
		invariant(!!label || !!icon, 'either the prop "label" or "icon" must be provided');

		const handleClick = (event: SyntheticEvent<HTMLAnchorElement>) => {
			if (onClick) {
				onClick(event, action, route);
			}
		};

		return (
			<li className={className} {...rest}>
				<HeaderPrimaryNavigationListItemAnchor
					href={route}
					onClick={handleClick}
					$isActive={isActive}
					data-testid="header-primary-navigation-list-item"
				>
					{icon && <HeaderPrimaryNavigationListItemIcon className="icon" icon={icon} />}
					<HeaderPrimaryNavigationListItemLabel className="label">
						{label}
					</HeaderPrimaryNavigationListItemLabel>
				</HeaderPrimaryNavigationListItemAnchor>
			</li>
		);
	}
)`
	float: left;
	position: relative;
`;
