import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import { useUser } from '../../../auth/AuthContext';
import environment from '../../../environment';

import type { MyLatestArticlesContainerQuery } from './__generated__/MyLatestArticlesContainerQuery.graphql';

function MyLatestArticlesContainer(
	props: Pick<QueryRendererProps<MyLatestArticlesContainerQuery>, 'render'>
) {
	const user = useUser();

	if (user?.guid) {
		const variables = { affectedAuthorGuid: user.guid };
		return (
			<QueryRenderer<MyLatestArticlesContainerQuery>
				environment={environment}
				variables={variables}
				query={graphql`
					query MyLatestArticlesContainerQuery($affectedAuthorGuid: String!) {
						data: myArticlesByAuthorGuid(affectedAuthorGuid: $affectedAuthorGuid, first: 10) {
							edges {
								node {
									createdAt
									article {
										rowId
										title
										status
									}
								}
							}
						}
					}
				`}
				{...props}
			/>
		);
	}
	return null;
}

export default MyLatestArticlesContainer;
