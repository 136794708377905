export function countCategories(boards: { section: number }[]) {
	return boards.reduce((acc, cur) => {
		if (acc[cur.section]) {
			return {
				...acc,
				[cur.section]: Number(acc[cur.section]) + 1,
			};
		}

		return {
			...acc,
			[cur.section]: 1,
		};
	}, {} as { [categoryName: number]: number });
}
