import { MARKS } from '../formattingTypes';
import type { SlateRenderMarkProps } from '../formattingTypes';

const markTypes = Array.from(MARKS.values()).reduce(
	(accumulator, node) => ({
		...accumulator,
		[node.type]: node.render,
	}),
	{}
);

export default (renderProps: SlateRenderMarkProps) => {
	if (markTypes[renderProps.mark.type]) {
		return markTypes[renderProps.mark.type](renderProps);
	}

	return null;
};
