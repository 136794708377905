import { MODULE_TYPE_LIVESTREAM } from '@sep/br24-constants';
import { Alert, Spin } from 'antd';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import { isLivestreamModule, type ModuleTypeLivestream, type UpdateModuleInput } from '../..';
import type { WithTranslationProps } from '../../../../translation';
import {
	ArticleModuleItem,
	ArticleModuleVideo,
	ArticleModuleDropzone,
} from '../../../../ui/ArticleModule';
import type { Item } from '../../../AssetManager';
import { DndCreateModuleContainer } from '../../DragAndDropHandler/DndCreateModuleContainer';
import {
	DndSortModuleContainer,
	type SimpleItem,
} from '../../DragAndDropHandler/DndSortModuleContainer';
import {
	allAssetManagerTypes,
	getAEItemType,
	getAMItemType,
	toSortModule,
} from '../../DragAndDropHandler/dndUtils';
import deleteModule from '../../actions/deleteModule';
import updateModule from '../../actions/updateModule';
import convert from '../../convert';
import { activeArticleModuleSelector } from '../../selectors';
import BrokenModuleContainer from '../ArticleEditorBrokenModule';

import LivestreamModuleRenderer from './LivestreamModuleRenderer';

interface Props extends WithTranslationProps, OwnProps, ReduxProps {
	isEditing: boolean;
	onUpdate: (key: string, type: string, value: any) => void;
	onMove: (dragIndex: SimpleItem, hoverIndex: SimpleItem) => void;
	onDisplayAttachedDropzone: (order: number, position: 'top' | 'bottom') => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
	onResetAttachedDropzone?: () => void;
	onAddViaAttachedDropzone?: (item: Item | undefined) => void;
	onMoveToAssetManager: (id: string | number) => void;
}

class LivestreamModuleContainer extends Component<Props> {
	handleAddViaInitialDropzone = async (dropItem: Item | undefined) => {
		// check if dropitem exists, because if not
		// a file from outside the app has dropped
		if (!dropItem) {
			return;
		}

		const convertedItem = await convert(dropItem);
		const { update } = this.props;

		if (convertedItem) {
			update(this.props.id, convertedItem.data['livestream']);
		}
	};

	handleChangeThumbnail = (key: 'url' | 'title' | 'altText' | 'copyright', value: string) => {
		this.props.updateThumbnail(this.props.id, {
			[key]: value,
		});
	};

	handleDelete = () => {
		this.props.delete(this.props.id);
	};

	handleChange = (key: string, value: string) => {
		this.props.onUpdate(this.props.id, 'video', {
			[key]: value,
		});
	};

	render() {
		const {
			translation: { translate },
			module,
			...rest
		} = this.props;

		if (!isLivestreamModule(module)) {
			return <BrokenModuleContainer id={module['rowId']} />;
		}

		let displayModule: JSX.Element | null = null;
		if (module.livestream && module.livestream.livestreamId && module.livestream.programmeId) {
			displayModule = (
				<div>
					<DndSortModuleContainer
						acceptedItemTypes={{
							dragSource: getAEItemType('LIVESTREAM'),
							dropTarget: toSortModule,
						}}
						{...this.props}
					>
						<ArticleModuleItem
							label={translate(`modules.${MODULE_TYPE_LIVESTREAM}.text`)}
							onDelete={this.handleDelete}
						>
							<LivestreamModuleRenderer programmeId={module.livestream.programmeId.split('|')[0]}>
								{({ error, props }) => {
									const hasError = !!error;
									const isReady =
										error === null && props && props.programme?.__typename === 'MangoProgramme';

									if (hasError) {
										return (
											<Alert
												message={translate('error')}
												description={error.message}
												type="error"
											/>
										);
									}

									if (isReady) {
										const title = props.programme?.title;
										const start = props.programme?.broadcasts?.edges?.[0]?.node?.start;
										const end = props.programme?.broadcasts?.edges?.[0]?.node?.end;
										const image =
											props.programme?.defaultTeaserImage?.imageFiles?.edges?.[0]?.node
												?.publicLocation;

										return (
											<ArticleModuleVideo
												isLivestream={true}
												liveTitle={title ?? undefined}
												liveStartTime={start}
												liveEndTime={end}
												videoTeaserImage={image}
												validation={module.__validation__}
												onChange={this.handleChange}
												{...rest}
											/>
										);
									}

									return <Spin />;
								}}
							</LivestreamModuleRenderer>
						</ArticleModuleItem>
					</DndSortModuleContainer>
				</div>
			);
		} else {
			displayModule = (
				<ArticleModuleItem
					label={translate(`modules.${MODULE_TYPE_LIVESTREAM}.text`)}
					onDelete={this.handleDelete}
				>
					<DndCreateModuleContainer
						onAddViaInitialDropzone={this.handleAddViaInitialDropzone}
						acceptedItemTypes={[getAMItemType('LIVESTREAM')]}
						itemTypes={allAssetManagerTypes}
					>
						<ArticleModuleDropzone
							type={MODULE_TYPE_LIVESTREAM}
							text={translate(`modules.${MODULE_TYPE_LIVESTREAM}.dropzoneText`)}
							validation={!!module.__validation__}
						/>
					</DndCreateModuleContainer>
				</ArticleModuleItem>
			);
		}

		return displayModule;
	}
}

interface OwnProps {
	id: string;
}

const connector = connect(
	(state: ReduxState, { id }: OwnProps) => ({
		module: activeArticleModuleSelector(state, id) as ModuleTypeLivestream,
	}),
	{
		update: (id: string, value: UpdateModuleInput['value']) =>
			updateModule(id, {
				type: 'livestream',
				value,
			}),
		delete: deleteModule,
	},
	(propsFromState, propsFromDispatch, ownProps) => ({
		...propsFromState,
		...propsFromDispatch,
		...ownProps,
		// update thumbnail for video
		updateThumbnail: (
			id: string,
			value: { [K in 'url' | 'title' | 'altText' | 'copyright']?: string }
		) => {
			const {
				module: { livestream },
			} = propsFromState;

			const thumbnail = livestream?.thumbnail;

			const nextThumbnail = {
				thumbnail: {
					altText: thumbnail?.altText,
					copyright: thumbnail?.copyright,
					title: thumbnail?.title,
					url: thumbnail?.url,
					...value,
				},
			};

			propsFromDispatch.update(id, nextThumbnail);
		},
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(LivestreamModuleContainer));
