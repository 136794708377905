import { TYPE_AUTHOR } from '@sep/br24-constants';
import { Button, Tooltip, Radio } from 'antd';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import {
	AUTHORS_FILTERS_ACTIVITY_ACTIVE,
	AUTHORS_FILTERS_ACTIVITY_ALL,
	AUTHORS_FILTERS_ACTIVITY_INACTIVE,
	AUTHORS_FILTERS_LOGIN_TYPE_ALL,
	AUTHORS_FILTERS_LOGIN_TYPE_LDAP,
	AUTHORS_FILTERS_LOGIN_TYPE_PROFILE,
} from '../../../constants';
import type {
	AuthorsFilterType as AuthorsFilterState,
	AuthorsFilterType,
} from '../../../store/reducers/authorsFilter';
import * as creatorActions from '../../../store/reducers/creator';
import type { WithTranslationProps } from '../../../translation';
import DelayedSearch from '../../DelayedSearch';

const { Button: RadioButton, Group: RadioGroup } = Radio;

const Toolbar = styled.div`
	display: flex;
	border-bottom: 1px solid #e9e9e9;
	justify-content: space-between;
	flex-wrap: wrap;

	> div {
		flex: 0 1 auto;
		padding: 12px;
	}
`;

const StyledRadioGroup = styled(RadioGroup)`
	&& {
		margin-right: 2rem;

		&:last-of-type {
			margin-right: 0;
		}
	}
`;

interface Props extends WithTranslationProps, ReduxProperties, RouteComponentProps {
	currentFilter: AuthorsFilterState;
	onChange: <K extends keyof AuthorsFilterType>(key: K, value: AuthorsFilterType[K]) => void;
}

class AuthorsFilter extends Component<Props> {
	handleChangeSearchTerm = (value: string) => this.props.onChange('searchTerm', value);

	handleClickNew = () => {
		this.props.showCreator({ type: TYPE_AUTHOR });
	};

	render() {
		const {
			translation: { translate },
		} = this.props;
		const {
			isActive = AUTHORS_FILTERS_ACTIVITY_ALL,
			isLdapUser = AUTHORS_FILTERS_LOGIN_TYPE_ALL,
			searchTerm = '',
		} = this.props.currentFilter;

		return (
			<Toolbar>
				<div>
					<Button type="primary" onClick={this.handleClickNew}>
						{translate('authors.createAuthor')}
					</Button>
				</div>
				<div>
					<StyledRadioGroup
						defaultValue={isActive}
						onChange={(event) => this.props.onChange('isActive', event.target.value)}
					>
						<RadioButton value={AUTHORS_FILTERS_ACTIVITY_ALL}>
							{translate('authors.filter.all')}
						</RadioButton>
						<RadioButton value={AUTHORS_FILTERS_ACTIVITY_ACTIVE}>
							{translate('authors.filter.status.active')}
						</RadioButton>
						<RadioButton value={AUTHORS_FILTERS_ACTIVITY_INACTIVE}>
							{translate('authors.filter.status.inactive')}
						</RadioButton>
					</StyledRadioGroup>

					<StyledRadioGroup
						defaultValue={isLdapUser}
						onChange={(event) => this.props.onChange('isLdapUser', event.target.value)}
					>
						<RadioButton value={AUTHORS_FILTERS_LOGIN_TYPE_ALL}>
							{translate('authors.filter.all')}
						</RadioButton>
						<Tooltip title="Nutzer, die im CMS arbeiten.">
							<RadioButton value={AUTHORS_FILTERS_LOGIN_TYPE_LDAP}>
								{translate('authors.filter.loginType.ldap')}
							</RadioButton>
						</Tooltip>
						<Tooltip title="Accounts, die erstellt wurden, aber nicht aktiv im CMS arbeiten.">
							<RadioButton value={AUTHORS_FILTERS_LOGIN_TYPE_PROFILE}>
								{translate('authors.filter.loginType.profile')}
							</RadioButton>
						</Tooltip>
					</StyledRadioGroup>
				</div>
				<div>
					<Tooltip title={translate('authors.filter.info')}>
						<DelayedSearch
							placeholder={translate('authors.searchPlaceholder')}
							style={{ width: 280 }}
							onChange={this.handleChangeSearchTerm}
							defaultValue={searchTerm || ''}
						/>
					</Tooltip>
				</div>
			</Toolbar>
		);
	}
}

const connector = connect(null, { showCreator: creatorActions.show });

type ReduxProperties = ConnectedProps<typeof connector>;

export default withTranslation(withRouter(connector(AuthorsFilter)));
