import { Component } from 'react';
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import ModuleAllocationStatsLegend from './ModuleAllocationStatsLegend';
import { getChartDataTotal } from './util';

type Props = {
	data: {
		type: string;
		count: number;
	}[];
};

class PieChartMultimedia extends Component<Props> {
	shouldComponentUpdate({ data: nextData }: Props) {
		return this.props.data !== nextData;
	}

	render() {
		const { data } = this.props;

		const dataTotal = getChartDataTotal(data);
		const dataTotalArray = Object.values(dataTotal);

		return (
			<div>
				<ResponsiveContainer width="100%" aspect={1}>
					<PieChart>
						<Tooltip />

						<Pie
							dataKey="value"
							data={dataTotalArray}
							endAngle={-270}
							startAngle={90}
							innerRadius="50%"
						/>
					</PieChart>
				</ResponsiveContainer>

				<ModuleAllocationStatsLegend data={data} />
			</div>
		);
	}
}

export default PieChartMultimedia;
