import { Component, type SyntheticEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import * as constants from '../../../../constants';
import { type WithTranslationProps, withTranslation } from '../../../../translation';
import {
	AssetManagerImporterInput,
	AssetManagerImporterForm,
	AssetManagerImporterInputAction,
	AssetManagerImporterError,
	AssetManagerImporterContentEmpty,
} from '../../../../ui/AssetManager';
import { Accept, Spinner } from '../../../../ui/Icon';
import addAuthor from '../../../ArticleEditor/actions/addAuthor';
import addTag from '../../../ArticleEditor/actions/addTag';
import createModule from '../../../ArticleEditor/actions/createModule';
import updateArticleEditor from '../../../ArticleEditor/actions/update';
import * as actions from '../../actions';
import { importerBaseInformationSelector, importedItemsByImporterSelector } from '../../selectors';
import AssetManagerImporterResults from '../AssetManagerImportResults/AssetManagerImporterResults';

type AssetManagerImporterSophoraProps = WithTranslationProps & ReduxProps;

type AssetManagerImporterSophoraState = {
	sophoraId: string;
};

class AssetManagerImporterSophoraArticleComponent extends Component<
	AssetManagerImporterSophoraProps,
	AssetManagerImporterSophoraState
> {
	state = {
		sophoraId: '',
	};

	handleChangeSophoraID = (event: SyntheticEvent<HTMLInputElement>) =>
		this.setState({
			sophoraId: event.currentTarget.value,
		});

	handleImport = () => {
		const { importResults } = this.props;
		const { sophoraId } = this.state;

		if (sophoraId.trim().length === 0) {
			return;
		}

		importResults(constants.AM_IMPORTER_SOPHORA_ARTICLE, { mode: 'searchSophora' }, sophoraId);
	};

	handleKeyPress = (event: KeyboardEvent) => {
		if (event.which === 13) {
			event.preventDefault();
			this.handleImport();
		}
	};

	handlePaste = (event: any) => {
		const maybeSophoraID = event.clipboardData.getData('Text');

		if (typeof maybeSophoraID === 'string' && maybeSophoraID.includes('br.de')) {
			const matched = /((https|http):\/\/)?(www\.)?br.de\/(.*)\/(.*)\.html?/.exec(maybeSophoraID);

			if (matched && matched.length > 4) {
				const nextSophoraID = matched.pop();
				event.preventDefault();
				this.setState({
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					sophoraId: nextSophoraID!,
				});
			}
		}
	};

	render() {
		const {
			translation: { translate },
			latestImporterError,
			isImporterWorking,
			importedItems,
		} = this.props;
		const { sophoraId } = this.state;

		return (
			<>
				{latestImporterError && (
					<AssetManagerImporterError>{latestImporterError.message}</AssetManagerImporterError>
				)}
				<AssetManagerImporterForm>
					<AssetManagerImporterInput
						size="full"
						color="dark"
						mode="code"
						placeholder={translate(
							`assetManager.importer.${constants.AM_IMPORTER_SOPHORA_ARTICLE}.inputPlaceholder`
						)}
						onChange={this.handleChangeSophoraID}
						onKeyPress={this.handleKeyPress}
						onPaste={this.handlePaste}
						value={sophoraId}
						actions={[
							<AssetManagerImporterInputAction
								key="import"
								onClick={this.handleImport}
								className={isImporterWorking ? 'loading' : ''}
							>
								{isImporterWorking ? <Spinner /> : <Accept />}
							</AssetManagerImporterInputAction>,
						]}
					/>
				</AssetManagerImporterForm>
				{importedItems.length === 0 && (
					<AssetManagerImporterContentEmpty>
						{translate(`assetManager.importer.${constants.AM_IMPORTER_SOPHORA_ARTICLE}.empty`)}
					</AssetManagerImporterContentEmpty>
				)}
				{importedItems.length > 0 && (
					<div>
						<AssetManagerImporterResults
							importer={constants.AM_IMPORTER_SOPHORA_ARTICLE}
							importedItems={importedItems}
							importerWorking={this.props.isImporterWorking}
						/>
					</div>
				)}
			</>
		);
	}
}

const connector = connect(
	(state) => ({
		...importerBaseInformationSelector(state),
		importedItems: importedItemsByImporterSelector(constants.AM_IMPORTER_SOPHORA_ARTICLE)(state),
	}),

	{
		updateArticleEditor,
		createModule,
		addAuthor,
		addTag,
		importResults: actions.importResults,
		removeFromImporter: actions.removeItemFromImporter,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export const AssetManagerImporterSophoraArticle = withTranslation(
	connector(AssetManagerImporterSophoraArticleComponent)
);
