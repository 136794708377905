import { useRef } from 'react';
import { DragPreviewImage, type DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import dragPreviewImage from '@/client/images/newsroom_drag.png';

import type { DropResult } from '../../AssetManager/AssetManagerSpace/performDrop';

import { allAssetManagerTypes } from './dndUtils';

type Module = { rowId?: string | number; id: string | number; order: number };
type Item = { type: string } & {
	rowId: string | number;
	id?: string;
	order: number;
};
export type SimpleItem = { id: string | number; order: number };

type Props = {
	module: Item;
	acceptedItemTypes: {
		dragSource: string;
		dropTarget: Array<string>;
	};
	children: JSX.Element;
	onMoveToAssetManager: (id: string | number) => void;
	onDisplayAttachedDropzone: (order: number, position: 'top' | 'bottom') => void;
	onResetAttachedDropzone?: () => void;
	onMove: (m1: SimpleItem, m2: SimpleItem) => void;
	isEditing?: boolean;
};

const DragDropContainer = styled.div`
	padding: 1em;
	margin-top: 1em;
`;

export function DndSortModuleContainer(props: Props) {
	const ref = useRef<HTMLDivElement>(null);

	const dropItem = props.module;
	const { onMoveToAssetManager, isEditing, acceptedItemTypes } = props;

	const [, drag, dragPreview] = useDrag<
		Pick<Item, 'type' | 'id' | 'rowId'>,
		DropResult,
		unknown
	>(() => {
		return {
			type: acceptedItemTypes.dragSource,
			item: dropItem,
			end(item, monitor) {
				if (!monitor.didDrop()) {
					return;
				}

				const result = monitor.getDropResult();

				if (!!result?.wasAdded && result.dropEffect === 'move' && item) {
					const id = item.rowId || item.id || 'N/A';
					onMoveToAssetManager(id);
				}
			},
			canDrag: () => !isEditing,
		};
	}, [acceptedItemTypes.dragSource, dropItem, onMoveToAssetManager, isEditing]);

	const [, drop] = useDrop<Module & Item, Item, { isOver: boolean }>({
		// * If SortModuleContainer is handling the drop because the module is not being created or updated, then fail the drop if it is an item from the AssetManager.
		// This fixes a problem where editors would drag items onto pre-existing content, it would treat it as dropped, and remove it from the AssetManager.
		canDrop: (_, monitor: DropTargetMonitor) => {
			const itemType = monitor.getItemType();
			return !!itemType && !(allAssetManagerTypes as (string | symbol)[]).includes(itemType);
		},

		accept: props.acceptedItemTypes.dropTarget,

		hover(item, monitor) {
			const element = ref.current;

			if (!element) {
				return;
			}

			const clientOffset = monitor.getClientOffset() || { y: 0 };
			const hoverBoundingRect = element.getBoundingClientRect();

			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			const hoverItem: SimpleItem = {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				id: props.module.rowId ? props.module.rowId : props.module.id!,
				order: props.module.order,
			};

			// check if element is already in editor or is dragged from outside the editor
			// indicator -> order = true (in editor), order = false (outside editor)
			if (item && Number.isInteger(item.order)) {
				const dragItem: SimpleItem = {
					id: item.rowId ? item.rowId : item.id,
					order: item.order,
				};

				if (
					dragItem.order === hoverItem.order ||
					// dragging down
					(dragItem.order < hoverItem.order && hoverClientY < hoverMiddleY) ||
					// dragging up
					(dragItem.order > hoverItem.order && hoverClientY > hoverMiddleY)
				) {
					return;
				}

				props.onMove(dragItem, hoverItem);
				// eslint-disable-next-line no-param-reassign
				item.order = hoverItem.order;
			}

			// currently and item is hovered which is not part of the editor yet
			if (
				!item.hasOwnProperty('order') &&
				props.onDisplayAttachedDropzone &&
				props.onResetAttachedDropzone
			) {
				const itemHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
				// hovering over 10% on the top/bottom of the module will show the attached dropzone
				const percentageHover = (itemHeight * 10) / 100;

				if (hoverClientY < percentageHover) {
					props.onDisplayAttachedDropzone(hoverItem.order, 'top');
					return;
				}

				if (hoverClientY > itemHeight - percentageHover) {
					props.onDisplayAttachedDropzone(hoverItem.order, 'bottom');
					return;
				}

				props.onResetAttachedDropzone();
			}
		},
	});

	return (
		<>
			<DragPreviewImage connect={dragPreview} src={dragPreviewImage} />
			<div ref={(node) => drag(drop(node))}>
				<DragDropContainer ref={ref}>{props.children}</DragDropContainer>
			</div>
		</>
	);
}
