import { UploadOutlined } from '@ant-design/icons';
import * as util from '@sep/br24-util';
import { Tag, Progress, Upload, Button, notification } from 'antd';
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import classNames from 'classnames';
import update from 'immutability-helper';
import { Component, type MouseEvent } from 'react';

import {
	ImageCropperModal,
	type ImageCropperModalResultHandler,
} from '@/client/ui/ImageCropper/ImageCropperModal';

import type { ModuleImage } from '../../../types/schema';
import { UserContainer } from '../../auth/AuthContext';
import config from '../../config';
import globalStyles from '../../styles';
import { GalleryItem } from '../Gallery';

import styles from './Uploader.module.scss';

type Props = {
	// @see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-accept
	accept?: string[];
	className?: string | null;
	displayButtonText?: boolean;
	headline?: string;
	images?: Array<ModuleImage>;
	maxImages?: number;
	onChange?: (image: Array<ModuleImage>) => void;
	subline?: string;
};

type State = {
	selectedIndex: number | undefined | null;
	isEditorVisible: boolean;
	queue: {
		error: UploadFile[];
		uploading: UploadFile[];
		done: UploadFile[];
	};
};

// TODO: check maxImages, display message.error
class Uploader extends Component<Props, State> {
	queueCleanUpTimeout: any = null;

	static defaultProps: Props = {
		accept: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
		headline: 'Datei hier hin um sie hochzuladen!',
		subline: 'Erlaubt sind .jpg, .jpeg, .png, .gif Dateien!',
		maxImages: 1,
		displayButtonText: true,
	};

	state = {
		isEditorVisible: false,
		selectedIndex: null,
		queue: {
			error: new Array<UploadFile>(),
			uploading: new Array<UploadFile>(),
			done: new Array<UploadFile>(),
		},
	};

	handleDelete = (_event: Event, _image: ModuleImage, index: number): void => {
		const { onChange, images = [] } = this.props;

		images.splice(index, 1);

		onChange?.(images);
	};

	handleEdit = (event: Event | MouseEvent<HTMLDivElement>, _: ModuleImage, index: number): void => {
		event.stopPropagation();

		this.setState({
			isEditorVisible: true,
			selectedIndex: index,
		});
	};

	handleEditorCancel = () => {
		this.setState({
			isEditorVisible: false,
			selectedIndex: null,
		});
	};

	handleEditorOk: ImageCropperModalResultHandler = (src, meta) => {
		const { selectedIndex } = this.state;
		const { images = [], onChange } = this.props;

		if (typeof selectedIndex !== 'number' || !images[selectedIndex]) {
			return;
		}

		images[selectedIndex] = {
			altText: meta?.description,
			copyright: meta?.copyright,
			title: meta?.title,
			url: src,
		};

		this.setState(
			{
				selectedIndex: null,
				isEditorVisible: false,
			},
			() => onChange?.(images)
		);
	};

	handleMoveImage = (dragIndex: number, hoverIndex: number) => {
		const { images = [], onChange } = this.props;
		const affected = images[dragIndex];

		onChange?.(
			update(images, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, affected],
				],
			})
		);
	};

	handleUploadComplete = (info: UploadChangeParam): any => {
		const { status } = info.file;
		const { maxImages = 0, images = [], onChange } = this.props;
		let { queue } = this.state;

		if (queue.uploading.length === 0) {
			if (this.queueCleanUpTimeout) {
				clearTimeout(this.queueCleanUpTimeout);
				this.queueCleanUpTimeout = null;
			}

			this.setState({
				queue: {
					error: [],
					uploading: [],
					done: [],
				},
			});
		}

		switch (status) {
			case 'uploading':
				if (queue.uploading.findIndex((file) => file.uid === info.file.uid) === -1) {
					queue = {
						...queue,
						uploading: [...queue.uploading, info.file],
					};
				}
				break;

			case 'error':
				notification.error({
					message: 'Fehler',
					description: `Die Datei ${info.file.name} konnte nicht hochgeladen werden!`,
					duration: 4,
				});

				queue = {
					...queue,
					uploading: queue.uploading.filter((file) => file.uid !== info.file.uid),
					error: [...queue.error, info.file],
				};
				break;

			case 'done':
				{
					const { altText, url, copyright, title } = info.file.response;

					queue = {
						...queue,
						uploading: queue.uploading.filter((file) => file.uid !== info.file.uid),
						done: [...queue.done, info.file],
					};

					const uploadedImage = {
						url,
						title: title || null,
						copyright: copyright || null,
						altText: altText || null,
					};
					let nextImages = [uploadedImage];

					if (maxImages > 1 || maxImages === -1) {
						nextImages = [...images, ...nextImages];
					}

					onChange?.(nextImages);
				}
				break;

			default:
				break;
		}

		this.setState({ queue });

		if (queue.uploading.length === 0) {
			this.registerQueueCleanUpTimer();
		}
	};

	registerQueueCleanUpTimer = () => {
		if (this.queueCleanUpTimeout) {
			clearTimeout(this.queueCleanUpTimeout);
		}

		this.queueCleanUpTimeout = setTimeout(
			() =>
				this.setState({
					queue: {
						error: [],
						uploading: [],
						done: [],
					},
				}),
			5000
		);
	};

	render() {
		const { images, accept, className, displayButtonText } = this.props;
		const { isEditorVisible, selectedIndex, queue } = this.state;
		const imagesWithUrl = (images || []).filter((image) => image.url).length;

		const selected =
			typeof selectedIndex === 'number' && Array.isArray(images)
				? images[selectedIndex]
				: undefined;

		const draggerProps = {
			action: `${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`,
			accept: accept?.join(','),
			showUploadList: false,
			onChange: this.handleUploadComplete,
		};

		/* eslint-disable react/no-array-index-key */
		return (
			<div className={classNames(styles.uploader, className)}>
				{queue.uploading.length > 0 ? (
					<div
						className={classNames(styles.uploaderProgress, {
							[styles.uploaderProgressBorder]: true,
						})}
					>
						<Progress
							percent={
								Math.round(
									(100 / Math.max(queue.uploading.length, queue.done.length)) * queue.done.length
								) || 1
							}
							status={(() => {
								if (queue.error.length > 0) {
									return 'exception';
								}

								return 'active';
							})()}
						/>

						{queue.uploading.map((file, index) => (
							<Tag key={index}>{file.name}</Tag>
						))}
					</div>
				) : (
					false
				)}
				{images && imagesWithUrl > 0 && (
					<GalleryItem
						// className={styles.galleryItem}
						title={images[0].title ?? ''}
						image={!images[0].url ? undefined : util.image.resize(images[0].url, 'landscape', 'xl')}
						onClick={(event) => this.handleEdit(event, images[0], 0)}
					/>
				)}
				<UserContainer>
					{() => (
						<Upload
							{...{
								...draggerProps,
								headers: {
									// 'X-Requested-With': null,
								},
							}}
							className={globalStyles.fullWidth}
						>
							<Button
								icon={<UploadOutlined />}
								className={classNames(styles.uploadButton, {
									[globalStyles.fullWidth]: displayButtonText,
									[styles.buttonSingle]: (images || []).length > 0 || queue.uploading.length > 0,
								})}
								type="primary"
								ghost={true}
							>
								{displayButtonText
									? (() => `Bild ${imagesWithUrl === 0 ? 'hochladen' : 'ersetzen'}`)()
									: false}
							</Button>
						</Upload>
					)}
				</UserContainer>
				{isEditorVisible && selected?.url ? (
					<ImageCropperModal
						onConfirm={this.handleEditorOk}
						onCancel={this.handleEditorCancel}
						src={selected.url}
						meta={{
							copyright: selected.copyright ?? undefined,
							description: selected.altText ?? undefined,
							title: selected.title ?? undefined,
						}}
					/>
				) : (
					false
				)}
			</div>
		);
		/* eslint-enable react/no-array-index-key */
	}
}

export default Uploader;
