import type {
	BoardBuffer,
	State,
	BoardSectionItemBuffer,
	BoardSectionBuffer,
	BoardBufferValidation,
} from './types';

export function provideBoardById(state: State, boardId: string): BoardBuffer | null {
	return state.boards[boardId] || null;
}

export function provideSocialMediaAccountsByBoardId(state: State, boardId: string) {
	if (!state.boards[boardId]) {
		return [];
	}

	return state.boards[boardId].socialMediaAccounts.map(
		({ __internalId__ }) => state.socialMediaAccounts[__internalId__]
	);
}

export function provideSectionItemByInternalId(
	state: State,
	sectionItemInternalId: string
): BoardSectionItemBuffer | undefined | null {
	return state.sectionItems[sectionItemInternalId] || null;
}

export function provideSectionByInternalId(
	state: State,
	sectionInternalId: string
): BoardSectionBuffer | undefined | null {
	return state.sections[sectionInternalId] || null;
}

export function provideSectionBySectionInternalIdAndPosition(
	state: State,
	sectionInternalId: string,
	sectionItemPosition: number
): BoardSectionItemBuffer | undefined | null {
	const section = state.sections[sectionInternalId];

	if (!section) {
		return null;
	}

	const [sectionItemReference] = section.items.filter(
		(item) => item && state.sectionItems[item.__internalId__].order === sectionItemPosition
	);

	if (!sectionItemReference) {
		return null;
	}

	return state.sectionItems[sectionItemReference.__internalId__];
}

function provideValidationByTypeAndId(
	state: State,
	type: 'boards' | 'sections' | 'sectionItems',
	internalId: string
): BoardBufferValidation | undefined | null {
	const affected = state[type][internalId];

	if (!affected) {
		return null;
	}

	return affected.__meta__.latestValidation;
}

export function provideBoardValidationById(
	state: State,
	boardId: string
): BoardBufferValidation | undefined | null {
	return provideValidationByTypeAndId(state, 'boards', boardId);
}

export function provideSectionValidationById(
	state: State,
	sectionInternalId: string
): BoardBufferValidation | undefined | null {
	return provideValidationByTypeAndId(state, 'sections', sectionInternalId);
}

export function provideSectionItemValidationById(
	state: State,
	sectionItemInternalId: string
): BoardBufferValidation | undefined | null {
	return provideValidationByTypeAndId(state, 'sectionItems', sectionItemInternalId);
}

export function provideMetaBySectionId(state: State, sectionItemInternalId: string, key: string) {
	return state?.sections[sectionItemInternalId]?.meta[key];
}
