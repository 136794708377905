import { TYPE_BOARD, TYPE_ARTICLE, TYPE_AUTHOR } from '@sep/br24-constants';
import { useUser, type User } from '@sep/cms-auth-client';
import { Component } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, MouseTransition } from 'react-dnd-multi-backend';
import { Helmet } from 'react-helmet-async';
import { type RouteComponentProps, withRouter } from 'react-router';

import configRoutes from '@/client/config/config.routes';

import Creator from '../../components/Creator';
import config from '../../config';
import errorRecorder from '../../errorRecorder';
import Layout from '../../ui/Layout';
import AssetManager from '../AssetManager';

import createRouter from './createRouter';
import determineTitleTemplate from './util/determineTitleTemplate';

// global stuff
const TITLE_TEMPLATE = determineTitleTemplate(config.VITE_BRANCH);
const TYPE_TEASER = 'TEASER';
const router = createRouter();

type AppWithUserProps = RouteComponentProps;

const HTML5BackendConfiguration = {
	backends: [
		{
			id: 'html5',
			backend: HTML5Backend,
			transition: MouseTransition,
		},
	],
};

function AppWithUser(props: AppWithUserProps) {
	const user = useUser();
	return <App {...props} user={user} />;
}

interface AppProps extends AppWithUserProps {
	user?: User | null;
}

class App extends Component<AppProps> {
	componentDidUpdate(prevProps: AppProps) {
		if (prevProps.location !== this.props.location) {
			errorRecorder.captureURLChange();
		}
	}

	handleItemCreation = (
		type: typeof TYPE_ARTICLE | typeof TYPE_BOARD | typeof TYPE_AUTHOR | typeof TYPE_TEASER,
		id: string | undefined | null
	) => {
		if (!id) {
			return;
		}

		const { push } = this.props.history;

		if (type === TYPE_ARTICLE) {
			push(`${configRoutes.article}/${id}`);
		} else if (type === TYPE_BOARD) {
			push(`${configRoutes.boards}/${id}`);
		} else if (type === TYPE_AUTHOR) {
			push(`${configRoutes.authors}/${id}`);
		} else if (type === TYPE_TEASER) {
			push(`${configRoutes.boardsTeasers}/${id}`);
		}
	};

	renderReady = () => (
		<>
			<Creator onSuccess={this.handleItemCreation} />

			<AssetManager />
			{router}
		</>
	);

	render() {
		return (
			<DndProvider options={HTML5BackendConfiguration}>
				<Layout>
					<Helmet titleTemplate={TITLE_TEMPLATE}>
						<link
							rel="stylesheet"
							type="text/css"
							href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,800|Source+Code+Pro&display=optional"
						/>
					</Helmet>
					{this.renderReady()}
				</Layout>
			</DndProvider>
		);
	}
}
export default withRouter(AppWithUser);
