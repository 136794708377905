import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateAuthorAction, ValidateAction } from '..';
import { AUTHOR_UPDATE } from '../actionTypes';

import { validateArticle } from './validate';

const updateAuthor =
	(dragIndex: number, hoverIndex: number): AppThunkAction<UpdateAuthorAction | ValidateAction> =>
	(dispatch) => {
		dispatch({
			type: AUTHOR_UPDATE,
			payload: {
				dragIndex,
				hoverIndex,
			},
		});

		dispatch(validateArticle());
	};

export default updateAuthor;
