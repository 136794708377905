import type { Board_board$data } from '@/client/components/Board/__generated__/Board_board.graphql';
import { randomId } from '@/client/util/br24Utils';

import type {
	BoardBuffer,
	BoardSectionBuffer,
	BoardSectionItemBuffer,
	BoardSocialMediaAccountBuffer,
} from '../types';

export interface Untangled {
	board: BoardBuffer;
	sections: Array<BoardSectionBuffer>;
	sectionItems: Array<BoardSectionItemBuffer>;
	socialMediaAccounts: Array<BoardSocialMediaAccountBuffer>;
}

/**
 * Un-tangles the given board-input.
 *
 * @param  {Object} input
 * @return {Object}
 */
export default function untangle(input: Board_board$data): Untangled {
	const sections: BoardSectionBuffer[] = [];
	const sectionItems: BoardSectionItemBuffer[] = [];
	const socialMediaAccounts: BoardSocialMediaAccountBuffer[] = [];
	const boardInternalId = input.rowId;
	const board = {
		__meta__: {
			internalId: boardInternalId,
			latestValidation: null,
			updated: false,
		},
		rowId: boardInternalId,
		parentId: input.parentId,
		image: input.image,
		slug: input.slug,
		title: input.title,
		seoTitle: input.seoTitle,
		description: input.description,
		hasIntroductionText: input.hasIntroductionText,
		introductionHeadline: input.introductionHeadline,
		introductionText: input.introductionText,
		categoryId: input.categoryId,
		rssFeed: input.rssFeed,
		rdfFeed: input.rdfFeed,
		updatedAt: input.updatedAt,
		socialMediaAccounts:
			Array.isArray(input.socialMediaAccounts) && input.socialMediaAccounts.length > 0
				? input.socialMediaAccounts.map((account) => {
						const socialMediaAccountInternalId = randomId();
						const socialMediaAccount = {
							__meta__: {
								internalId: socialMediaAccountInternalId,
								boardId: boardInternalId,
								created: false,
								updated: false,
								deleted: false,
							},
							service: account.service,
							label: account.label || null,
							accountName: account.accountName,
						};

						socialMediaAccounts.push(socialMediaAccount);

						return { __internalId__: socialMediaAccountInternalId };
				  })
				: [],
		sections:
			input.boardsSectionsByBoardId &&
			Array.isArray(input.boardsSectionsByBoardId.edges) &&
			input.boardsSectionsByBoardId.edges.length > 0
				? input.boardsSectionsByBoardId.edges.map((sectionEdge) => {
						const sectionInternalId = randomId();
						const section = {
							__meta__: {
								internalId: sectionInternalId,
								boardId: boardInternalId,
								latestValidation: null,
								created: false,
								updated: false,
								deleted: false,
							},
							rowId: sectionEdge.node.rowId,
							schema: sectionEdge.node.schema,
							categoryId: sectionEdge.node.categoryId || null,
							title: sectionEdge.node.title || null,
							showHeroTitleForMobile: sectionEdge.node.showHeroTitleForMobile || false,
							color: sectionEdge.node.color || null,
							items:
								sectionEdge.node.boardsSectionsItemsByBoardsSectionId &&
								Array.isArray(sectionEdge.node.boardsSectionsItemsByBoardsSectionId.edges) &&
								sectionEdge.node.boardsSectionsItemsByBoardsSectionId.edges.length > 0
									? sectionEdge.node.boardsSectionsItemsByBoardsSectionId.edges.map((itemEdge) => {
											const sectionItemInternalId = randomId();
											const item = {
												__meta__: {
													internalId: sectionItemInternalId,
													boardId: boardInternalId,
													sectionId: sectionInternalId,
													latestValidation: null,
													created: false,
													updated: false,
													deleted: false,
												},
												rowId: itemEdge.node.rowId,
												articleId: itemEdge.node.articleId,
												boardsTeaserId: itemEdge.node.boardsTeaserId,
												boardId: itemEdge.node.boardId,
												order: itemEdge.node.order,
											};

											sectionItems.push(item);

											return { __internalId__: sectionItemInternalId };
									  })
									: [],
							autofill: sectionEdge.node.autofill || false,
							boardLink: sectionEdge.node.boardLink || null,
							meta: sectionEdge.node.meta ? JSON.parse(String(sectionEdge.node.meta || '{}')) : {},
							order: sectionEdge.node.order,
						};

						sections.push(section);

						return {
							__internalId__: section.__meta__.internalId,
							items: section.items.map(({ __internalId__ }) => ({ __internalId__ })),
						};
				  })
				: [],
		noindex: input.noindex || false,
		protected: input.protected || false,
		status: input.status,
		lockedBy: input.lockedBy,
		lockedSince: input.lockedSince,
	};

	return {
		board,
		sections,
		sectionItems,
		socialMediaAccounts,
	};
}
