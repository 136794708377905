import { createSelector } from 'reselect';

import type { ReduxState } from '@/client/store/reducers';

import * as constants from '../../constants';
import { sortBy } from '../../util/sortBy';

import type {
	Importer,
	State,
	Item,
	ItemText,
	ItemLink,
	ItemEmbedCode,
	ItemGallery,
	ItemImage,
	ItemBrBildImage,
	ItemLiveStream,
	ItemAudio,
	ItemVideo,
	ItemVideo360,
	ItemArticle,
	ItemBoard,
	Image,
	AssetManagerSectionDivider,
	TypeToItemMap,
	ItemBoardTeaser,
} from '.';

// * UTILITY FUNCTIONS

export const root = (globalState: ReduxState) => globalState.assetManager;

// * FILTERING BY SEARCH
const searchBaseAndThumbnail = (
	searchCondition: RegExp,
	{ title, copyright, thumbnail }: ItemAudio | ItemVideo360 | ItemVideo
) =>
	(title || '').search(searchCondition) >= 0 ||
	(copyright || '').search(searchCondition) >= 0 ||
	(thumbnail && thumbnail.title ? thumbnail.title || '' : '').search(searchCondition) >= 0 ||
	(thumbnail && thumbnail.copyright ? thumbnail.copyright || '' : '').search(searchCondition) >=
		0 ||
	(thumbnail && thumbnail.altText ? thumbnail.altText || '' : '').search(searchCondition) >= 0;

const searchKickerAndTitle = (searchCondition: RegExp, { title, kicker }: ItemLiveStream) =>
	(title || '').search(searchCondition) >= 0 || (kicker || '').search(searchCondition) >= 0;

const searchByValueHandler = {
	[constants.AM_TYPE_BOARD_TEASER]: () => false,
	[constants.AM_TYPE_BOARD]: (searchCondition: RegExp, item: ItemBoard) =>
		(item.title || '').search(searchCondition) >= 0,
	[constants.AM_TYPE_ARTICLE]: (searchCondition: RegExp, item: ItemArticle) =>
		(item.title || '').search(searchCondition) >= 0,
	[constants.AM_TYPE_TEXT]: (searchCondition: RegExp, item: ItemText) =>
		item.text.search(searchCondition) >= 0,
	[constants.AM_TYPE_LINK]: (searchCondition: RegExp, item: ItemLink) =>
		(item.label || '').search(searchCondition) >= 0,
	[constants.AM_TYPE_EMBED_CODE]: (searchCondition: RegExp, item: ItemEmbedCode) =>
		(item.source || '').search(searchCondition) >= 0,
	[constants.AM_TYPE_GALLERY]: (searchCondition: RegExp, item: ItemGallery) =>
		(item.gallery || []).some(
			({ title, copyright, altText }) =>
				(title || '').search(searchCondition) >= 0 ||
				(copyright || '').search(searchCondition) >= 0 ||
				(altText || '').search(searchCondition) >= 0
		),
	[constants.AM_TYPE_BRBILD_IMAGE]: (
		searchCondition: RegExp,
		{ title, copyright, altText }: ItemBrBildImage
	) =>
		(title || '').search(searchCondition) >= 0 ||
		(copyright || '').search(searchCondition) >= 0 ||
		(altText || '').search(searchCondition) >= 0,
	[constants.AM_TYPE_IMAGE]: (searchCondition: RegExp, { title, copyright, altText }: ItemImage) =>
		(title || '').search(searchCondition) >= 0 ||
		(copyright || '').search(searchCondition) >= 0 ||
		(altText || '').search(searchCondition) >= 0,
	[constants.AM_TYPE_AUDIO]: (searchCondition: RegExp, item: ItemAudio) =>
		searchBaseAndThumbnail(searchCondition, item),
	[constants.AM_TYPE_VIDEO360]: (searchCondition: RegExp, item: ItemVideo360) =>
		searchBaseAndThumbnail(searchCondition, item),
	[constants.AM_TYPE_VIDEO]: (searchCondition: RegExp, item: ItemVideo) =>
		searchBaseAndThumbnail(searchCondition, item),
	[constants.AM_TYPE_LIVESTREAM]: (searchCondition: RegExp, item: ItemLiveStream) =>
		searchKickerAndTitle(searchCondition, item),
} as const;

//* SORTING
function sortBaseAndThumbnail({ title, thumbnail }: ItemAudio | ItemVideo360 | ItemVideo) {
	return title || '' || (thumbnail && thumbnail.title ? thumbnail.title || '' : '');
}

function firstAvailableGalleryData(gallery: Array<Image> | null) {
	const firstImageWithData: any = (gallery || []).find(({ title }) => title);

	if (firstImageWithData.title) {
		return firstImageWithData.title;
	}

	return '';
}

const sortByValueHandler: {
	[T in keyof TypeToItemMap]: (item: TypeToItemMap[T]) => string | boolean;
} = {
	[constants.AM_TYPE_BOARD_TEASER]: (_item: ItemBoardTeaser) => false,
	[constants.AM_TYPE_BOARD]: (item: ItemBoard) => item.title || '',
	[constants.AM_TYPE_ARTICLE]: (item: ItemArticle) => item.title || '',
	[constants.AM_TYPE_TEXT]: (item: ItemText) => item.text.replace(/<[^>]*>?/gm, ''),
	[constants.AM_TYPE_LINK]: (item: ItemLink) => item.label || '',
	[constants.AM_TYPE_EMBED_CODE]: (item: ItemEmbedCode) => item.source || '',
	[constants.AM_TYPE_GALLERY]: (item: ItemGallery) => firstAvailableGalleryData(item.gallery),
	[constants.AM_TYPE_IMAGE]: ({ title }: ItemImage) => title || '',
	[constants.AM_TYPE_BRBILD_IMAGE]: ({ title }: ItemBrBildImage) => title || '',
	[constants.AM_TYPE_AUDIO]: (item: ItemAudio) => sortBaseAndThumbnail(item),
	[constants.AM_TYPE_VIDEO360]: (item: ItemVideo360) => sortBaseAndThumbnail(item),
	[constants.AM_TYPE_VIDEO]: (item: ItemVideo) => sortBaseAndThumbnail(item),
	[constants.AM_TYPE_LIVESTREAM]: (item: ItemLiveStream) => item.title || '',
} as const;

function getSortByValueHandler<T extends keyof TypeToItemMap>(
	type: T
): (item: TypeToItemMap[T]) => string | boolean {
	return sortByValueHandler[type];
}

const sortableData = (data: Array<Item>) =>
	data.filter((item) => getSortByValueHandler(item.type)(item) !== '');

function unsortableData(data: Array<Item>) {
	const unsortableItems = data.filter((item) => getSortByValueHandler(item.type)(item) === '');

	if (unsortableItems.length > 0) {
		const sectionDividerItem: AssetManagerSectionDivider = {
			type: constants.AM_SECTION_DIVIDER,
			id: 'unsortable',
			label: `${unsortableItems.length} unsortierte Elemente`,
		};

		return [sectionDividerItem, ...unsortableItems];
	}

	return [];
}

const sortHandler = {
	[constants.AM_SORT_DIRECTION_RECENT]: (data: Array<Item>) =>
		sortBy(data, (item) => item.createdAt).reverse(),
	[constants.AM_SORT_DIRECTION_OLDEST]: (data: Array<Item>) =>
		sortBy(data, (item) => item.createdAt),
	[constants.AM_SORT_DIRECTION_ALPHABETICAL_ASC]: (data: Array<Item>) => [
		...sortBy(sortableData(data), (item) => getSortByValueHandler(item.type)(item)),
		...unsortableData(data),
	],
	[constants.AM_SORT_DIRECTION_ALPHABETICAL_DESC]: (data: Array<Item>) => [
		...sortBy(sortableData(data), (item) => getSortByValueHandler(item.type)(item)).reverse(),
		...unsortableData(data),
	],
};

// * SELECTOR ESSENTIALS
const conditionSelector = createSelector(
	[root],
	({ activeSpace, activeGlobalGroupId, filter }) => ({
		activeSpace,
		activeGlobalGroupId,
		filter,
	})
);

export const itemsSelector = createSelector(
	[conditionSelector, root],
	(condition, { spaces }: State) => {
		const onGlobalOverviewPage =
			condition.activeSpace === constants.AM_GLOBAL_SPACE && !condition.activeGlobalGroupId;
		const inGlobalGroup =
			condition.activeSpace === constants.AM_GLOBAL_SPACE && !!condition.activeGlobalGroupId;

		let result: Item[] = inGlobalGroup
			? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			  Object.values(spaces.global[condition.activeGlobalGroupId!].items || {})
			: Object.values(spaces[condition.activeSpace]);

		const {
			types: typeFilter = [],
			searchValue,
			sortDirection,
		} = condition.filter[condition.activeSpace];

		if (typeFilter.length > 0) {
			result = result.filter(({ type }) => typeFilter.includes(type));
		}

		if (searchValue && searchValue.length > 0) {
			const searchCondition = new RegExp(searchValue, 'i');

			result = result.filter((item) => {
				if (searchByValueHandler[item.type]) {
					return searchByValueHandler[item.type](searchCondition, item as any);
				}

				return false;
			});
		}

		if (!onGlobalOverviewPage && sortHandler[sortDirection]) {
			return sortHandler[sortDirection](result);
		} else {
			return result;
		}
	}
);

export const globalGroupsSelector = createSelector([root], ({ spaces }: State) => {
	const result = spaces[constants.AM_GLOBAL_SPACE];

	return result;
});

export const deriveAssetManagerStateFromState = ({
	activeGlobalGroupId,
	activeSpace,
	spaces,
	showActiveGlobalGroup: showActiveGlobalGroupState,
}: State) => {
	const isInPersonalSpace = activeSpace === constants.AM_PERSONAL_SPACE;
	const isInGlobalSpace = activeSpace === constants.AM_GLOBAL_SPACE;
	const activeGlobalGroup = activeGlobalGroupId ? spaces.global[activeGlobalGroupId] : null;
	const showActiveGlobalGroup =
		isInGlobalSpace && activeGlobalGroup ? showActiveGlobalGroupState : false;
	let isAbleToSendToAssetManager = true;
	let isInGroupOverview = false;
	let isInGlobalGroup = false;

	if (isInGlobalSpace) {
		isInGroupOverview = !showActiveGlobalGroup;
		isInGlobalGroup = !isInGroupOverview;
		isAbleToSendToAssetManager = !isInGroupOverview;
	}
	// Maybe we could clean some of this up, but the idea is to prevent the same calculations in multiple places that could go wrong
	return {
		activeSpace,
		activeGlobalGroup,
		showActiveGlobalGroup,
		isInPersonalSpace,
		isInGlobalSpace,
		isInGlobalGroup,
		isInGroupOverview,
		isAbleToSendToAssetManager,
	};
};

export const assetManagerStatusSelector = createSelector([root], deriveAssetManagerStateFromState);

export const isAssetManagerVisibleSelector = createSelector([root], ({ isVisible }) => isVisible);

export const baseInformationSelector = createSelector(
	[root],
	({
		isVisible,
		mode,
		activeSpace,
		firebaseConnected,
		activeImporter,
		filter,
		selected,
	}: State) => {
		return {
			isVisible,
			mode,
			activeSpace,
			firebaseConnected,
			activeImporter,
			filter,
			selected,
		};
	}
);

export const importerBaseInformationSelector = createSelector(
	[root],
	({ isImporterWorking, latestImporterError }: State) => ({
		isImporterWorking,
		latestImporterError,
	})
);

export const searchedTermFromArticleEditorSelector = createSelector(
	[root],
	({ importerSearchTermFromArticleEditor }: State) => ({
		importerSearchTermFromArticleEditor,
	})
);

export const importedItemsByImporterSelector = (importer: Importer) =>
	createSelector([root], ({ imports }: State) => Object.values(imports[importer] || {}));
