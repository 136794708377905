import type { ConnectDragSource } from 'react-dnd';
import styled from 'styled-components';

const Headline = styled.div`
	position: relative;
	width: 100%;
	height: 20px;
	border-bottom: 1px solid ${(props) => props.theme.colors.br24Light};
	text-align: center;
	margin-bottom: 2rem;
`;

const HeadlineLabel = styled.span`
	display: inline-block;
	background-color: ${(props) => props.theme.assetManager.importer.backgroundColor};
	padding: 8px 1rem 0 1rem;
	font-size: 1.2rem;
	text-transform: uppercase;
	cursor: move;
	user-select: none;
	max-width: 475px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const HeadlineAction = styled.span`
	display: inline-flex;
	padding: 0 0 0 1rem;
	position: absolute;
	right: 0;
	top: 8px;
	background-color: ${(props) => props.theme.assetManager.importer.backgroundColor};
	cursor: pointer;

	> * {
		margin-right: 8px;
		:last-child {
			margin-right: 0;
		}
	}
`;

const Inner = styled.div`
	display: flex;
	flex-wrap: wrap;
	> div {
		margin-left: 8px;
		min-width: calc(100% * (1 / 3) - 8px);
		max-width: calc(100% * (1 / 3) - 8px);
	}
`;

type AssetManagerImporterResultSetProps = {
	children: any;
	title?: string;
	actions?: any;
	dragRef: ConnectDragSource;
};

function AssetManagerImporterResultSet({
	title,
	actions,
	children,
	dragRef,
	...rest
}: AssetManagerImporterResultSetProps) {
	return (
		<>
			{(title || actions) && (
				<div ref={dragRef}>
					<Headline>
						<HeadlineLabel>{title}</HeadlineLabel>
						{actions && <HeadlineAction>{actions}</HeadlineAction>}
					</Headline>
				</div>
			)}
			<Inner {...rest}>{children}</Inner>
		</>
	);
}

export default AssetManagerImporterResultSet;
