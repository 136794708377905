import * as constants from '@sep/br24-constants';

import * as internalConstants from '../../constants';

export default {
	sayHello: 'Servus {{name}}, Du host oa neis Material!',
	sayHello_plural: 'Servus {{name}}, Du host {{quantity}} neie Materialien!',

	// locales
	german: 'Preißn (Deitsch)',
	english: 'Englisch',
	bavarian: 'Boarisch',

	// navigation items
	navigation: {
		dashboard: 'Drüberschaun',
		articles: 'Artikel',
		boards: 'Seitn',
		authors: 'Leid',
		navigation: 'Navigation',
		comments: 'Kommentare',
		profile: 'I',
		settings: 'Rumpfuschn',
		signOut: 'Abmeidn',
	},

	// modules
	modules: {
		remove: 'Bist Du Dir sicher, dass Du dieses Element löschen möchtest?',
		creator: {
			textPlaceholder: 'Hier schreim...',
		},
		header: {
			titlePlaceholder: 'Titel angeben',
			titleHelp: '{{count}}/{{max}} Zeichen',
			teasertextPlaceholder: 'Teasertext angeben',
			teasertextHelp: '{{count}}/{{max}} Zeichen',
		},
		[constants.MODULE_TYPE_IMAGE]: {
			text: 'Buidl',
			titleLabel: 'A Beschreibung',
			titlePlaceholder: 'Gib a Beschreibung ei',
			titleHelp: '{{count}}/{{max}} Zeichan',
			altTextLabel: 'Oiternativtext',
			altTextPlaceholder: 'Gib an Oiternativtext ei',
			copyrightLabel: 'Copyrait',
			copyrightPlaceholder: 'Gib a Copyrait ei',
			editCropButtonLabel: 'An Zuschnitt ändern',
			noRectWarning:
				'As Buidl hat no koan Ausschnitt und wird deshoib in voller Auflösung ozeigt. Bitte wähl an Ausschnitt aus!',
			saveRectButtonLabel: 'Ausschnitt übernehmen',
			abortRectButtonLabel: 'Abbrechen',
			dropzoneText: 'Ziag a buidl auf de Flächn.',
			selectDownloadLabel: 'Auswoi zum Downloadn',
			downloadCroppedImage: 'Zuaschnitt',
			downloadOriginal: 'Originoi Buiddatei',
			downloadNewsletter: 'Newsletta (700 x 394px)',
			downloadDone: 'Fertig',
		},
		[constants.MODULE_TYPE_GALLERY]: {
			text: 'Galerie',
			dropzoneText: 'Ziag a Packl Buidln auf de Flächn.',
		},
		[constants.MODULE_TYPE_TEXT]: {
			text: 'Text',
			textPlaceholder: 'An Text nei schreim',
			linkEditorPlaceholder: 'An Link nei schreim',
		},
		[constants.MODULE_TYPE_EMBED]: {
			text: 'Embed',
			serviceLabel: 'Plattform',
			servicePlaceholder: 'wird nach Einfügen automatisch erfasst',
			altTextLabel: 'Oiternativtext',
			altTextPlaceholder: 'Oiternativtext eigem',
			previewButtonText: 'Zeigs ma',
			editEmbedText: 'Embed ändern',
		},
		[constants.MODULE_TYPE_AUDIO]: {
			text: 'Audio',
			titleLabel: 'Teasertext',
			titlePlaceholder: 'Teasertext angeben',
			titleHelp: '{{count}}/{{max}} Zeichan',
			copyrightLabel: 'Copyright',
			copyrightPlaceholder: 'Copyright angeben',
			dropzoneText: 'Ziag an Ton auf de Flächn.',
		},
		[constants.MODULE_TYPE_VIDEO]: {
			text: 'Video',
			titleLabel: 'A Beschreibung',
			titlePlaceholder: 'Gib a Beschreibung ei',
			titleHelp: '{{count}}/{{max}} Zeichan',
			copyrightLabel: 'Copyrait',
			copyrightPlaceholder: 'Gib a Copyrait ei',
			loadVideoButtonLabel: 'Video lodn',
			dropzoneText: 'Ziag a Video auf de Flächn.',
		},
		[constants.MODULE_TYPE_LIVESTREAM]: {
			text: 'Liveschtrem',
			titleLabel: 'Titel',
			startTimeLabel: 'Stoart',
			endTimeLabel: 'Ände',
			dropzoneText: 'Ziag an Liveschtrem auf de Flächn.',
			livestreamNotAvailable: 'Da Livestream is nimmer do. Deshoib wirda a nimma ozeigt.',
		},
		[constants.MODULE_TYPE_VIDEO360]: {
			text: '360° Video ( Oidbacha )',
		},
		mixedDropzone: {
			defaultPlaceholder: 'Ziag a Video, an Ton oder a Buidln auf de Flächn.',
			errorNotAuthenticatedHeadline: 'Bist ned eingloggt.',
			errorNotAuthenticatedDesc: 'Bitt logg di neu ins CMS ei.',
			labelFilesAreUploaded: 'Ois wird hochglodn.',
			labelErrorOnUpload: 'A Fehler is bassiert.',
			dropIsNotAllowedPlaceholder: 'Des Modul is hier ned möglich.',
		},
		attachedDropzone: {
			title: 'Ziag des Zeig hierher.',
		},
		brokenModule: {
			title: 'Kaputtes Modul',
			text: 'Dieses Modul ist leider fehlerhaft. Bitte lösche es und speichere den Artikel danach ab.',
		},
	},

	// dashboard stuff
	dashboard: {
		title: 'Drüberschaun',
		statistics: 'Statistikn',
		thisMonth: 'Den Monat',
		lastMonth: 'Letztn Monat',
		aYearAgo: 'Vor am Jahr ({{info}})',
		noDataSelectedDateRange: 'Koane Datn für den Zeitraum gfundn.',
		sourceOriginReferenceStats: 'Artikel mit na Sendung',
		sourceOriginReferenceStatsHelp: 'Kimmt boid.',
		withSourceOrigin: 'Mit am Sendungsbezug',
		noSourceOrigin: 'Koa Sendungsbezug',
		myLatestArticles: 'Meine letztn Artikel',
		moduleAllocationStats: 'Multimediazeigl',
		moduleAllocationStatsHelp: 'Kimmt boid.',
		latestPushNotifications: 'Letztn Pushmeldungn',
		latestPublication: 'Zletzt rausgem',
		categoryArticleStats: 'Artikel in Kategorien',
		categoryArticleStatsHelp: 'Kimmt boid.',
		regionsArticleStats: 'Artikel pro Region',
		regionsArticleStatsHelp: 'Anzahl der Artikel pro Region',
		filter: {
			placeholder: 'Filter auswählen',
			dropdownLabelRegions: 'Regionen',
			dropwdownLabelCategory: 'Rubrik',
			resetFilterText: 'Filter zurücksetzen',
			headlineTotal: 'Gesamt',
			errorText: 'Leider ist ein Fehler aufgetreten.',
		},
		push: {
			withSound: 'Push mit Ton',
			withoutSound: 'Push ohne Ton',
			tagPush: 'Tag-Push',
		},
	},

	board: {
		search: {
			emptySearch: 'Nix gfundn!',
		},
		sectionItem: {
			article: {
				published: 'Publiziert',
			},
			board: {
				updated: 'Gspeichert',
			},
			boardsTeaser: {
				updated: 'Gspeichert',
				errorDescription: 'Da Teaser {{title}} konnt ned gfundn wern.',
			},
		},
		publicationStatusPicker: {
			publish: 'Veröffentlichen',
			changeToDraft: 'In Entwurf umwandeln',
			depublish: 'Depublizieren',
		},
		saveButton: {
			save: 'Speichern',
			locked: 'Das Speichern von Änderungen ist durch einen anderen User gesperrt',
		},
	},

	boardsTeaser: {
		pageTitle: 'Teaser {{title}} bearbeiten',
		dropDownMenu: {
			save: 'Passt! Speicher des Zeig!',
			delete: 'Teaser weghaun',
		},
		formItemHelp: 'Zeichn',
		action: {
			save: {
				auto: {
					message: 'Sauba! Automatisch gspeichert',
					description: 'Dei Teaser {{title}} wurd automatisch gspeichert.',
				},
				manual: {
					message: 'Passt! Erfolgreich gspeichert',
					description: 'Der Teaser {{title}} wurd erfolgreich gspeichert.',
				},
				error: {
					message: 'Kreuz, Kruzifix und Sakrament, Fehler beim Speichern',
					description: 'Dei Zeig konnten nicht gspeichert wern.',
				},
			},
			deleteTeaser: {
				modalTitle: 'Teaser weghaun',
				confirmDelete: 'Mogst du den Teaser wirklich weghaun?',
				usedTeaser: {
					problem:
						'Da Teaser werd grod scho auf ana Seitn hergnomma und ko deswegn ned glöscht wern.',
					description:
						'Damitst den Teaser trotzdem weghaun konnst muast zerst alle aufglisteten Seitn durchgeh und die Dinger ausehaun. Danach konnst as einfach nommoi versucha!',
				},
			},
			searchPlaceholder: 'Sucha...',
			createTeaser: 'Teaser oleng',
		},
		image: {
			title: 'Buidl',
			upload: {
				error: {
					message: 'Fehla',
					description: 'Dei Datei konnt ned hochgladn wern!',
				},
				upload: 'Buidl hochladen',
				replace: 'Buidl ersetzen',
			},
			confirmDelete: 'Soll dieses Bild wirklich wegghaun wern?',
		},
		table: {
			title: 'Titel',
			description: 'Beschreibung',
			active: 'Aktiv',
			lastAuthor: 'Zletzt bearbeitet vo',
			lastUpdate: 'Zletzt bearbeitet',
			noEntry: 'Nix gfundn!',
		},
	},

	// article editor
	article: {
		authErrorLogout:
			'Sie wurden leider ausgeloggt. Bitte öffnen Sie <a href="/logout" target="_blank">den Login</a> in einem neuen Tab, loggen Sie sich ein und drücken Sie dann in diesem Tab auf speichern. <code>{{error}}</code>',
		meta: {
			title: 'Meta',
			author: {
				title: 'Gschrim hods',
				help: 'Beteiligte  BR-Autoren  suchen; Bitte beachten, dass nur aktive Profile in den Suchergebnissen angezeigt werden.  Neuanlage  von  Autoren  erfolgt  über  <a target="_blank" href="/authors">Autoren</a>.',
				searchPlaceholder: 'Grantla sucha...',
			},
			seo: {
				title: 'SEO - Zeig für Google',
				help: '<a target="_blank" href="/faq#seo-informationen-fuer-artikel-editor">Infos</a>  zu  SEO-Anforderungen.  Ohne  Angaben  wird  der  Teasertext  ausgespielt.',
				titlePlaceholder: 'Da SEO-Titel (optional)',
				descPlaceholder: "d' Meta Deschription",
				characterCountText: '{{currentCount}}/{{characterMaxLength}} Buchstom',
				slugCountText: '{{currentCount}}/{{characterMaxLength}} Buchstom',
				slugPlaceholder: 'A URL eigem',
				canonicalUrlPlaceholder: 'Canonical URL eigem',
				seoTitleEditTooltip: 'SEO-Titel verändern',
			},
			category: {
				title: 'Kategorie',
				help: 'Auswahl  bestimmt  Verortung  und  Farbgebung  des  Inhalts.',
				searchPlaceholder: 'Wähl a Kattegorie as',
			},
			sourceOrigin: {
				title: 'Sendungsbezug',
				help: 'Immer  einen  Sendungsbezug  inkl.  Datum  und  Uhrzeit  angeben.  Aktuell  nur  Auswahl  eines  Sendungsbezugs  möglich.',
				datePlaceholder: 'Datum',
				timePlaceholder: 'Zeit',
				inputPlaceholder: 'Sendungsbezug suchen...',
				inputButton: 'Hinzufügen',
			},
			links: {
				title: 'Links',
				help: 'Mögliche  Eingaben  sind  URLs  auf  BR24-Inhalte  und  auf  externe  Seiten.  Sprechende  Linktitel  wählen:  z.B.  B5  aktuell  hier  live  hören.  Erster  Link:  sichtbar  auf  Artikel-Teaser',
				titlePlaceholder: 'Da Linktext',
				linkPlaceholder: 'A Internet Adress',
				externalLinkLabel: 'a extärner Link',
				addButtonTitle: 'Neischreim',
				characterCountText: '{{currentCount}}/{{titleMaxLength}} Buchstom',
			},
			teasers: {
				title: 'Link-Empfehlung',
				help: 'Mögliche  Eingaben  sind  URLs  auf  BR24-Inhalte  und  auf  externe  Seiten.  Erster  Link:  sichtbar  auf  Artikel-Teaser',
				searchPlaceholder: 'Artikelsuche',
				searchButtonLabel: 'Suchen',
				addButtonLabel: 'Link hinzufügen',
				linkPlaceholder: 'URL',
				save: 'Speichern',
				articleNotFoundError: 'Artikel mit dieser ID nicht gefunden.',
				boardNotFoundError: 'Verteilseite mit dieser ID nicht gefunden.',
				couldNotFetchMetaError: 'Daten konnten nicht geladen werden',
				invalidUrlError: 'Unvollständige oder falsche URL.',
				alreadyExistsError: 'Eine URL kann nur ein mal hinzugefügt werden',
				isNotWhitelisted: 'URLs von {{hostname}} sind nicht erlaubt.',
				whiteListedURLS:
					'Folgende Domäne sind erlaubt: br.de, tagesschau.de, sportschau.de, bayern3.de, br-klassik.de, br24.de, ard.de, ardalpha.de, ardmediathek.de, ardaudiothek.de ',
				info: {
					invalid:
						'Die verlinkte Webseite ist nicht erreichbar. Bitte entfernen oder überarbeiten sie diesen Eintrag.',
					depublished: 'Dieser BR24-Artikel ist depubliziert und wird nicht in Web/App angezeigt.',
				},
			},
			tags: {
				title: 'Schlagwörter',
				help: 'Bestenfalls  2-4  sinnvolle  Schlagworte  eingeben;  Auswahl  analog  <a target="_blank" href="/faq#google-suchverhalten">Google-Suchverhalten</a>. Leerzeichen  setzen  und  auf  Groß-/Kleinschreibung  achten.',
				placeholder: 'Schlagwort eingeben',
				button: 'Hinzufügen',
				inputHelp: '{{count}}/{{max}} Zeichen',
				addButtonLabel: 'Hinzufügen',
			},
			geolocation: {
				title: 'Geolocation',
				help: 'Passende  Regierungsbezirke  auswählen.  Falls  Ortsinfo  vorhanden,  unter  journalistischen  Kriterien  eingeben.',
				districtPlaceholder: 'Bezirke wählen',
			},
			more: {
				title: 'Mehra',
				labels: {
					isLive: 'a Live-Bericht',
					allowComments: 'Gwaf is erlaubt',
					enableAmp: 'AMP vo Google',
					hasMigration: 'Wos mit Sophora verknüpfen',
				},
				sophoraSearch: {
					searchPlaceholder: 'Sophora-URL eigem',
					help: 'Olle Artikel de mit Sophora verknüpft san, werden von br.de/nachrichten auf de BR24-Website weitergleitet. Obacht: Verknüpfte Sophora Artikl miasn noch da Migration mit da Hend depubliziert wern.',
					connectedArticleText: 'De Sophora ID für de Verknüpfung:',
				},
			},
			notes: {
				title: 'Nodizn',
				help: 'Interne Nodizn für den Artikl. Des ko hinterher nur a Redakteur im CMS seng.',
				characterCountText: '{{currentCount}}/{{characterMaxLength}} Buchstom',
			},
		},
		action: {
			button: {
				text: {
					[constants.STATUS_DELETED]: 'Gelöscht',
					[constants.STATUS_DEPUBLISHED]: 'Depubliziert',
					[constants.STATUS_DRAFT]: 'Entwurf',
					[constants.STATUS_PUBLISHED]: 'Publiziert',
					[constants.STATUS_REVIEW]: 'in Freigabe',
					[constants.STATUS_SCHEDULED]: 'Zeitgesteuert',
					template: 'Vorlage',
				},
				menu: {
					[constants.STATUS_DELETED]: 'Löschen',
					[constants.STATUS_DEPUBLISHED]: 'Depublizieren',
					[constants.STATUS_DRAFT]: 'zu Entwurf machen',
					[constants.STATUS_PUBLISHED]: 'Publizieren',
					[constants.STATUS_REVIEW]: 'zur Freigabe',
					[constants.STATUS_SCHEDULED]: 'Zeitsteuerung aktivieren',
					changePublicationDate: 'Zeitstempel anpassen',
					template: 'Artikel erstellen',
				},
			},
		},
		historyTimeline: {
			status: {
				[constants.STATUS_DELETED]: 'gelöscht',
				[constants.STATUS_DEPUBLISHED]: 'depubliziert',
				[constants.STATUS_DRAFT]: 'bearbeitet',
				[constants.STATUS_PUBLISHED]: 'publiziert',
				[constants.STATUS_REVIEW]: 'zur Freigabe',
				[constants.STATUS_SCHEDULED]: 'zeitgesteuert',
				[constants.STATUS_CHANGED]: 'bearbeitet',
			},
			moment: {
				format: '[am] DD.MM.YYYY, HH:mm [Uhr]',
			},
			history: 'Historie',
			item: '$t(article.historyTimeline.status.{{status}}) {{date}}',
			itemWithAuthor: '$t(article.historyTimeline.status.{{status}}) von {{author}} {{date}}',
		},
		publishing: {
			publishing: 'Publikation',
			now: 'Sofort publizieren',
			tooltip: 'Publikationsdatum anpassen',
			delay: {
				activate: 'Publikationsdatum anpassen',
				delay: 'Publikationsdatum',
				moment: {
					format: 'DD.MM.YYYY [um] HH:mm',
				},
			},
			error: {
				error: 'Fehler',
				empty: 'Die zeitgesteuerte Veröffentlichung darf nicht leer sein.',
			},
		},
		depublishing: {
			depublishing: 'Depublikation',
			category: {
				custom: 'Benutzerdefinierte Depublikation',
				[constants.EXPIRATION_EPG_BUSINESS_CONTENT]:
					'1 - Programminformationen (EPG) sowie Unternehmensinhalte',
				[constants.EXPIRATION_REPEATED_EVENTS]: '2 - Wiederkehrende Ereignisse',
				[constants.EXPIRATION_SUPPORTING_INFO]: '3 - Programmbegleitende Inhalte',
				[constants.EXPIRATION_SERIES]: '4 - Inhalte zu Serien und Mehrteilern',
				[constants.EXPIRATION_EDUCATION]: '5 - Bildungsinhalte',
				[constants.EXPIRATION_TIME_CULTURE]: '6 - Zeit- und Kulturgeschichte',
			},
			error: {
				error: 'Fehler',
				empty: 'Die zeitgesteuerte Depublikation darf nicht leer sein.',
			},
		},
		notification: {
			silent: 'mit Push',
			tooltip: 'Mit/Ohne Push-Notification',
		},
		notificationModal: {
			cancel: 'Abbrechen',
			headline: 'Achtung',
			save: 'Push jetzt versenden',
			text: 'Soll eine Push-Nachricht (ohne Ton) verschickt werden? Dabei werden automatisch alle aktuellen Änderungen im Artikel gespeichert.',
		},
		previewButton: {
			preview: 'Vorschau',
			toArticle: 'zum Artikel',
			tooltip: 'Vorschau/zum Artikel',
		},
		saveButton: {
			save: 'Speichern',
			locked: 'Es wurden bisher keine Änderungen am Artikel vorgenommen.',
		},
		title: {
			template: 'Vorlage',
		},
		changePubDateOverlay: {
			headline: 'Zeitstempel anpassen',
			select: {
				headline: 'Wähle einen Zeitstempel:',
				now: 'Jetzt',
				lastDate: 'Bisherigen Zeitstempel beibehalten',
				custom: 'Zeitpunkt manuell setzen',
			},
			saveButton: 'Speichern',
			cancelButton: 'Abbrechen',
		},
	},

	boards: {
		boards: 'Seitn',
		newBoard: 'Neie Seitn',
		searchPlaceholder: 'Sucha...',
		table: {
			categories: 'Kategorie',
			edited: 'Scho wos gmacht',
			aktive: 'Aktiv',
			emptyText: 'Nix gfundn.',
			filters: {
				ALL: 'Ois',
				[constants.STATUS_DEPUBLISHED]: 'Rausgschmissn',
				[constants.STATUS_DRAFT]: 'A moi probiern',
				[constants.STATUS_PUBLISHED]: 'Für olle',
			},
			homepage: 'Startseitn',
			regions: 'Regionen (Gottseidank koane Preißn)',
			slug: 'URL-Kürzel',
			status: 'Status',
			topics: 'Themenseitn',
		},
	},

	articles: {
		article: 'Artikel',
		createArticle: 'Artikel erstellen',
		notes: 'Es san Notizn zum Artikl do',
		table: {
			header: {
				type: 'Typ',
				headline: 'Überschrift',
				components: 'Bestandteile',
				active: 'Aktiv',
				editor: 'Bearbeiter',
				status: 'Status',
				publication: 'Veröffentlichung',
				edited: 'Bearbeitet',
				notes: 'Notizen',
			},
			row: {
				createFromTemplate: 'Ois Vorlag verwendn',
				editArticle: "Artikl b'oabeitn",
				republish: 'Numoi veröffentlichn',
			},
		},
		search: {
			articleSearch: 'Artikelsuche...',
			emptySearch: 'Keine Artikel gefunden.',
			clearFilter: 'Alle Filter aufheben',
		},
		copyArticle: {
			error: {
				message: 'Artikel konnte nicht kopiert werden',
				description: 'Die Vorlage {{title}} konnte nicht kopiert werden.',
			},
			success: {
				message: 'Artikel wurde erstellt',
				description: 'Die Vorlage {{title}} wurde erfolgreich kopiert.',
			},
		},
		selection: {
			story: 'Zu einer Story zusammenführen',
			reset: 'Selektion aufheben',
			successMessage: 'Die Artikel {{articles}} wurden zu einer Story vereint.',
			errorMessage: 'Die Artikel konnten nicht zu einer Story vereint werden.',
		},
	},
	lockWarnings: {
		backToOverviewButton: {
			title: 'zur Übersicht',
			tooltip: 'zur Übersicht',
		},
		openReadOnlyButton: {
			title: 'Dokument lesend öffnen',
			tooltip: 'Dokument lesend öffnen',
		},
		lockTakeoverButton: {
			title: 'Sperre brechen',
			tooltip: 'Sperre brechen',
		},
		setLockButton: {
			title: 'Sperre setzen',
			tooltip: 'Sperre setzen',
		},
		reloadDocumentButton: {
			title: 'alle Änderungen ververfen',
			tooltip: 'Dokument neu laden',
		},
		openNewTabButton: {
			title: 'in neuem Tab öffnen',
			tooltip: 'Hier weiter nur lesend arbeiten',
		},
		updatedWhileLockDialog: {
			title: 'Sperre des Dokuments wurde zwischenzeitlich entfernt',
			text: 'Die Sperre für das aktuelle Dokument wurde aufgehoben. Das Dokument ist nun wieder zur Bearbeitung verfügbar.',
		},
		brokenLockDialog: {
			title: 'Achtung! Sperrung dieses Dokuments wurde gebrochen',
			textPiece1: 'Deine Sperre wurde am ',
			textPiece2: ' von ',
			textPiece3: ' durchbrochen. Du kannst deine Änderungen im Lesemodus sichern.',
		},
		contentLockDialog: {
			title: 'Dokument durch anderen Newsroom-Nutzer gesperrt',
			textPiece1: 'Dieses Dokument wird bereits seit ',
			textPiece2: ' von ',
			textPiece3:
				' bearbeitet. Bitte beachte, dass Du nur Änderungen vornehmen kannst, wenn Du die Sperre brichst.',
		},
		saveErrorLockDialog: {
			title: 'Dieses Dokument wurde inzwischen verändert.',
			text: 'Die Sperre für das aktuelle Dokument wurde gebrochen. {{name}} hat am {{date}} das Dokument gespeichert. Um deine Änderungen nicht zu verlieren, kannst in Lesend Modus deine Module in den Assets Manager absichern.',
		},
		readOnlyBar: {
			text: 'Dieses Dokument wurde nur zum Lesen geöffnet, Änderungen daran können NICHT gespeichert werden.',
		},
	},
	capabilities: {
		[constants.CAPABILITY_FULLY_FEATURED]: 'Artikel',
		[constants.CAPABILITY_BASIC]: 'Artikel',
		[constants.CAPABILITY_TEXT_ONLY]: 'Eilmeldung',
	},

	priorities: {
		[constants.PRIORITY_NORMAL]: 'Passd scho',
		[constants.PRIORITY_HIGH]: 'Scho wichtig',
		[constants.PRIORITY_CRITICAL]: 'Sau wichtig!!!',
	},

	modals: {
		articlePublishNow: {
			title: 'Sofort Publizieren',
			content: 'Bist du Dir sicher, dass du den Artikel sofort publizieren willst?',
			ok: 'Publizieren',
			cancel: 'Abbrechen',
		},
		articleDelete: {
			title: 'Artikel löschen',
			content: 'Diese Aktion ist unwiderruflich, gelöschte Artikel sind nicht mehr auffindbar.',
			ok: 'Löschen',
			cancel: 'Abbrechen',
		},
		notificationActivated: {
			title: 'Push-Benachrichtigung',
			content: 'Bei der Veröffentlichung des Artikels wird eine Push-Benachrichtigung verschickt.',
			ok: 'Publizieren',
			cancel: 'Abbrechen',
		},
		articleDepublishConnected: {
			title: 'Depublizieren nicht möglich',
			content:
				'Dieser Inhalt ist auf einer oder mehreren Seiten eingebaut und kann daher nicht depubliziert werden. Bitte entferne den Inhalt erst von der/den Seiten.',
			ok: 'Ok',
		},
		articleCreateFromTemplateSuccess: {
			title: 'Artikel wurde erstellt',
			content: 'Die Vorlage wurde erfolgreich kopiert.',
		},
		articleCreateFromTemplateError: {
			title: 'Die Vorlage konnte nicht kopiert werden.',
		},
		articleNotes: {
			title: 'Artikelnotizen vorhanden',
			content:
				'Für diesen Artikel wurden Notizen hinterlassen. Bist du Dir sicher, dass du den Artikel trotzdem speichern möchtest?',
			ok: 'Ok',
			cancel: 'Abbrechen',
		},
	},

	map: {
		searchPlaceholder: 'Ort suchen',
		searchError:
			'Der Ort konnte nicht korrekt erfasst werden. Bitte einen Ort über die Suchliste auswählen.',
		title: 'Aktueller Ort',
	},

	locations: {
		[constants.LOCATION_DISTRICT]: 'Bezirk',
		[constants.LOCATION_ZIPCODE]: 'PLZ',
		[constants.LOCATION_STREET]: 'Straße',
		[constants.LOCATION_STREETNUMBER]: 'Hausnummer',
		[constants.LOCATION_COUNTRY]: 'Land',
		[constants.LOCATION_STATE]: 'Bundesland',
		[constants.LOCATION_CITY]: 'Stadt',
		[constants.LOCATION_SUBURB]: 'Vorort',
	},

	validation: {
		errors: {
			article: {
				title: 'De Überschrift derf minimum {{minLength}} und maximal {{maxLength}} Zeichn hobm.',
				seoTitle: 'Da SEO-Titel derf minimum {{minLength}} und maximal {{maxLength}} Zeichn hobm.',
				teaserText: 'Da Teaser derf minimum {{minLength}} und maximal {{maxLength}} Zeichn hobm.',
				modules: 'A Artikel muas mindestens oa Modul drin hobm.',
				authors: 'Es muas min. 1 und derf höchstens 5 Madl oda Bua ois Autor/in ogem wern.',
				seo: {
					title: 'Der SEO-Titel darf höchstens {{maxLength}} Zeichen lang sein.',
					slug: 'D`Url derf minimum {{minLength}} und maximal {{maxLength}} Zeichn hobm. Asadem san bloß Zohln, Buchstom und Bindestriche erlaubt.',
					metaDescription:
						'De Meta Deschription muas min. {{minLength}} Zeichn und derf höchstens {{maxLength}} Zeichn long sei.',
				},
				notes: 'Das Notizfeld darf höchstens {{maxLength}} lang sein.',
				links: {
					label:
						'Ein Link verhindert das Speichern und muss deswegen bearbeitet oder entfernt werden.',
					url: 'Links müssen im Format "http(s)://www.<url>.<domain>" angegeben werden.',
				},
				teasers: 'A Link muas im Format "http(s)://www.<url>.<domain> ogem wern.',
				geolocation:
					'De Geloaction hod a foisches Format. Schau moi nach obst an Ort a ausm Dropdown ausgwoit hosd.',
			},
			modules: {
				audio: {
					copyright: 'As Copyright muas ausgfüllt sein.',
					title:
						'De Beschreibug derf minimum {{minLength}} Zeichn und maximal {{maxLength}} Zeichn hobm.',
					url: 'I brauch no a Audio.',
				},
				gallery: {
					isOnFirstPosition:
						'Das Galerie-Modul ist an der ersten Stelle nicht erlaubt. Bitte verschiebe es an eine andere Position darunter.',
				},
				image: {
					copyright: 'As Copyright muas ausgfüllt sein.',
					title:
						'De Beschreibug derf minimum {{minLength}} Zeichn und maximal {{maxLength}} Zeichn hobm.',
					url: 'I brauch no a Buidl und an 16:9 Ausschnitt.',
					altText: 'I brauch no an Oid-Text',
				},
				thumbnail: {
					copyright: 'As Copyright muas ausgfüllt sein.',
					title:
						'De Beschreibug derf minimum {{minLength}} Zeichn und maximal {{maxLength}} Zeichn hobm.',
					url: 'I brauch no a Buidl und an 16:9 Ausschnitt.',
					altText: 'I brauch no an Oid-Text',
				},
				video: {
					url: 'I brauch no a Video.',
					title:
						'De Beschreibug derf minimum {{minLength}} Zeichn und maximal {{maxLength}} Zeichn hobm.',
					copyright: 'As Copyright muas ausgfüllt sein.',
				},
				text: {
					isEmpty: 'I brauch no an text.',
					isOnFirstPosition:
						'A Text Modul is ned erlaubt on da erstn Position. Bittschön an a andere Position ziagn.',
				},
				video360: {
					sources: 'validation.errors.modules.video360.sources',
					url: 'validation.errors.modules.video360.url',
				},
				livestream: 'I brauch no an Liveschtream.',
				embed: {
					isOnFirstPosition:
						'A Embed Modul is ned erlaubt on da erstn Position. Bittschön an a andere Position ziagn.',
					noService: 'I brauch no an ausgwähltn Service.',
					noAltText: 'I brauch no an Alt-Text.',
					noValidation: 'Dei Embed-Code bassd no ned. Bittschön numoi schaugn.',
					noEmbedInsert: 'I brauch noch an Embed-Code',
					noPreviewLoaded: 'Die Vorschau des Embeds muss geladen werden.',
					embedCodeValidationFailed: 'Validierung fehlgschlogn. Schaus da bitte nomal o.',
				},
			},
		},
	},

	// asset manager
	assetManager: {
		connecting: 'Verbindung wird hergestellt…',
		emptyText: 'Ziehe Element aus dem Artikel hier her um sie zu speichern.',
		importer: {
			[internalConstants.AM_IMPORTER_SOPHORA]: {
				title: 'Sophora',
				inputPlaceholder: 'Sophora-ID oder br.de URL eingeben / kopieren...',
				empty: 'Trage eine Sophora-ID in das Suchfeld ein um Inhalte zu sehen.',
				loading: 'Sophora-Inhalte werden gesucht...',
			},
			[internalConstants.AM_IMPORTER_SOPHORA_ARTICLE]: {
				title: 'Sophora',
				inputPlaceholder: 'Sophora-ID oder br.de URL eingeben / kopieren...',
				empty: 'Trage eine Sophora-ID in das Suchfeld ein um Inhalte zu sehen.',
				loading: 'Sophora-Inhalte werden gesucht...',
			},
			[internalConstants.AM_IMPORTER_LIVESTREAMS]: {
				title: 'Livestreams',
				inputPlaceholder: 'Suchbegriff eingeben...',
				empty: 'Aktuell stehen leider keine Livestreams zur Verfügung.',
				loading: 'Livestream-Inhalte werden geladen...',
			},
			[internalConstants.AM_IMPORTER_NEWSROOM]: {
				title: 'Newsroom',
				inputPlaceholder: 'Suchbegriff eingeben...',
				empty: 'Für diese Suche stehen leider keine Newsroom-Inhalte zur Verfügung.',
				loading: 'Newsroom-Inhalte werden geladen...',
			},
			[internalConstants.AM_IMPORTER_BRBILD]: {
				title: 'BR Bild',
				inputPlaceholder: 'Suchbegriff eingeben...',
				selectImageTypePlaceholder: 'Bildtyp wählen...',
				selectRightsOwnerPlaceholder: 'Rechtegeber wählen...',
				empty: 'Trage einen Suchbegriff in das Suchfeld ein um nach Bildern zu suchen.',
				loading: 'BR Bild Inhalte werden geladen...',
			},
		},
		dataTakeoverModal: {
			confirmText: 'Möchtest du die folgenden Daten direkt in den Artikel übernehmen?',
			confirmOk: 'Übernehmen',
			confirmCancel: 'Abbrechen',
			headline: 'Daten übernehmen',
			headlineContent: 'Dachzeile + Überschrift:',
			headlineTeaserText: 'Teasertext:',
			other: 'ggf. Meta Description (SEO)',
		},
		dataTakeoverModalNew: {
			headline: 'Daten übernehmen',
			confirmText: 'Möchtest du die folgenden Daten direkt in den Artikel übernehmen?',
			warningOverwrite: 'Folgende Felder werden dabei überschrieben:',
			confirmOk: 'Übernehmen',
			confirmCancel: 'Abbrechen',
			headlineContent: 'Title',
			headlineTeaserText: 'Teasertext',
			metaDescription: 'Meta Description (SEO)',
			canonicalUrl: 'Canonical URL (SEO)',
			modules: 'Die Module des importierten Artikels werden zum bestehenden Artikel hinzugefügt',
			warningTable: 'Der importierte Artikel enthält eine Tabelle welche nicht übernommen wird',
		},
		item: {
			invalid: {
				title: 'Dieses Element existiert nicht mehr...',
				description: 'Das kann mehrere Gründe haben. Weitere Informationen findest Du unter FAQ.',
			},
			loading: {
				title: 'Dieses Element wird geladen...',
				description: 'Einen Moment bitte!',
			},
			[internalConstants.AM_TYPE_IMAGE]: {
				noTitle: 'Koa Titel do',
				noCopyright: 'Koa Copyreit do',
			},
		},
		space: {
			[internalConstants.AM_GLOBAL_SPACE]: 'Öffentlich',
			[internalConstants.AM_PERSONAL_SPACE]: 'Meins',
		},
		groups: {
			search: 'Suche',
			add: 'Gruppe erstellen',
			save: 'Gruppe speichern',
			lock: 'Sperren',
			unlock: 'Entsperren',
			edit: 'Bearbeiten',
			delete: 'Löschen',
			info: 'Info',
			infoItems: 'Element(e)',
			infoCreatedBy: 'Ersteller',
			infoLocked: 'Diese Gruppe wird nicht|automatisch geleert!',
			infoNotLocked: 'Diese Gruppe wird täglich|um 1:00 Uhr geleert!',
			infoCreatedAt: 'Erstellt am',
			infoModified: 'Letzte Änderung',
			creatorHead: 'Neue Gruppe erstellen',
			creatorPlaceholder: 'Gruppenname',
			emptyTitleError: 'Gruppenname darf nicht leer sein',
			userGenerated: "User-erstellte Grupp'n",
		},
		trash: {
			deleteAll: 'Ois weghaun',
			deleteSelected: 'Ausgwählts weghaun',
			tip: 'Hierhin ziehen',
		},
		filter: {
			[internalConstants.AM_SORT_DIRECTION_RECENT]: 'Nei',
			[internalConstants.AM_SORT_DIRECTION_OLDEST]: 'Oid',
			[internalConstants.AM_SORT_DIRECTION_ALPHABETICAL_ASC]: 'A-Z',
			[internalConstants.AM_SORT_DIRECTION_ALPHABETICAL_DESC]: 'Z-A',
		},
		type: {
			confirm: {
				ok: 'Passt',
				reset: 'Wegschmeissn',
			},
			[internalConstants.AM_TYPE_BOARD_TEASER]: 'Teaser',
			[internalConstants.AM_TYPE_BOARD]: 'Seitn',
			[internalConstants.AM_TYPE_ARTICLE]: 'Artikl',
			[internalConstants.AM_TYPE_TEXT]: 'Text',
			[internalConstants.AM_TYPE_LINK]: 'Link',
			[internalConstants.AM_TYPE_EMBED_CODE]: 'Embed-Code',
			[internalConstants.AM_TYPE_GALLERY]: 'Galerie',
			[internalConstants.AM_TYPE_IMAGE]: 'Buidl',
			[internalConstants.AM_TYPE_AUDIO]: 'Audio',
			[internalConstants.AM_TYPE_VIDEO360]: 'Video 360°',
			[internalConstants.AM_TYPE_VIDEO]: 'Video',
			[internalConstants.AM_TYPE_LIVESTREAM]: 'Livestream',
		},
		AssetManagerButton: {
			toolTip: 'Asset Manager anzeigen',
		},
		ImporterButton: {
			toolTip: 'Importer anzeigen',
		},
		status: {
			depublished: 'Depubliziert',
			draft: 'In Arbeit',
			published: 'Publiziert',
			publishedAt: 'Publiziert am {{date}}',
		},
		pagination: {
			query: 'Suchanfrage',
			queries: 'Suchanfragen',
			result: 'Ergebnis',
			results: 'Ergebnisse',
			page: 'Seite',
		},
	},

	richTextEditor: {
		inlineLinkEditor: {
			title: 'Link bearbeiten',
			deleteLink: 'Link entfernen',
			cancel: 'Abbrechen',
			ok: 'Ok',
			linkTextLabel: 'Link Text',
			emptyLinkTextError: 'Bitte geben Sie einen text für den Link ein',
			linkURLLabel: 'URL',
			linkURLError: 'Bitte geben Sie eine gültige URL ein',
		},
	},

	// general stuff
	featureInWorkTitle: 'Eigentlich schlaf ma nua an ganzen Tag...',
	featureInWorkDescription: "Spaß auf'd Seitn. In Zukunft geht des scho. Einfach a bissl wartn!",
	noData: 'Nix gfundn.',
	error: 'Fehla',
	withSound: 'Da hearst wos',
	withoutSound: 'Da hearst nix',
	load: 'Laden...',
	yes: 'Freili',
	no: 'Naaa',
	success: 'Eafolg',
	fatalError: {
		title: 'Es ist ein unerwarteter Fehler aufgetreten!',
		description:
			'Sollten Sie diesen Fehler nicht zum ersten Mal sehen, wenden Sie sich bitte an den Support. <br><br>Original Fehlermeldung: {{message}}',
	},
	saveArticle: {
		error: {
			[constants.STATUS_DRAFT]: {
				title: 'A Fehla beim speichern!',
				description: 'Da Artikel hod ned speichern wern kennan.',
			},
			[constants.STATUS_REVIEW]: {
				title: 'Chef konns ned oschaun!',
				description:
					'Da Artikel konn ned zur Freigabe vorglegt wern. Schau da mal die rodn Felder o.',
			},
			[constants.STATUS_PUBLISHED]: {
				title: 'Freigem ned möglich!',
				description: 'Da Artikel konn ned freigem wern. Schau da mal die rodn Felder o.',
			},
			[constants.STATUS_SCHEDULED]: {
				title: 'Zeitgsteuert Freigem ned möglich!',
				description:
					'Da Artikel konn ned zeitgsteuert freigem wern. Schau da mal die rodn Felder o.',
			},
			[constants.STATUS_DEPUBLISHED]: {
				title: 'Zurücknehma is ned möglich!',
				description: 'Da Artikel konn ned zruckgnomma wern.',
			},
			[constants.STATUS_DELETED]: {
				title: 'Wegschmeißn ned möglich!',
				description: 'Da Artikel konn ned weggschmissn wern.',
			},
			[constants.STATUS_CANNOT_PLACE]: {
				title: 'Ned do hi.',
				description: '',
			},
			[constants.STATUS_NOT_WHITELISTED]: {
				title: 'Ned do hi.',
				description:
					'Folgende Domäne sind erlaubt: br.de, tagesschau.de, sportschau.de, bayern3.de, br-klassik.de, br24.de',
			},
			[constants.STATUS_ALREADY_ON_THE_LIST]: {
				title: 'Ned do hi.',
				description: 'Dieser Artikel ist bereits in der Liste.',
			},
			[constants.LS_UPDATE_LOCKSTATUS]: {
				title: 'Speichern nicht möglich.',
				description:
					'Der Lock-Status des Artikels kann nicht überprüft werden. Bitte lade den Artikel neu.',
			},
			refetch: {
				title: 'Es ist ein Netzwerkproblem aufgetreten.',
				description:
					'Der Artikel kann nicht neugeladen werden. Bitte lande die Seite neu, um Locking Probleme zu vermeiden.',
			},
			general: {
				title: 'Dei Aktion war ned erfolgreich',
				description: 'Es is leida a Fehla auftretn.',
			},
		},
		success: {
			[constants.STATUS_DRAFT]: {
				title: 'Speichern hod glappt',
				description: 'Dei Entwurf hod gspeichert wern kenna.',
			},
			[constants.STATUS_REVIEW]: {
				title: 'Artikel liegt beim Chef',
				description: 'Dei Chef konn dein Artikel etz oschaun.',
			},
			[constants.STATUS_PUBLISHED]: {
				title: 'Obspeichert is!',
				description: 'Dei Artikel is etz freigem.',
			},
			[constants.STATUS_SCHEDULED]: {
				title: 'Obspeichert is!',
				description: 'Dei Artikel is etz zeitgsteuert freigem.',
			},
			[constants.STATUS_DEPUBLISHED]: {
				title: 'Wegnehma hod glappt!',
				description: 'Dei Artikel is etz nimmer auf da Seitn.',
			},
			[constants.STATUS_DELETED]: {
				title: 'Dei Artikel is glöscht!',
				description: 'Dei Artikel hobe erfolgreich glöscht.',
			},
			general: {
				title: 'Obspeichert is!',
				description: 'Speicher hod glappt.',
			},
			autoSave: {
				title: "I hob's amol g'speichert, ge?",
			},
		},
	},
	authors: {
		createAuthor: 'Neie Leid oleng',
		searchPlaceholder: 'Autoaensuche nach Nochnama...',
		me: 'Des bin i',
		noData:
			'Nix gfundn. Bitte beachte, dass die Autorensuche derzeit nur für den Nachnamen möglich ist.',
		filter: {
			all: 'Olle',
			status: {
				active: 'aktiv',
				inactive: 'inaktiv',
			},
			loginType: {
				ldap: 'mid Account',
				profile: 'ohne Account',
			},
			info: 'Bitte beachten: Aktuell ist eine Autor/innen-Suche nur für Nachnamen möglich!',
		},
		update: {
			error: 'De Dadn konndn ned gspeichert wern!',
			success: 'Der {{lastname}} {{firstname}} wurd erfolgreich bearbadet!',
		},
		systemprofile: 'Systemprofil',
	},
	author: {
		buttonSave: 'Profil speichern',
		buttonDismiss: 'Verwerfen',
		characterCount: '{{count}}/{{max}} Zeichen',
		statusDescription: {
			title: 'Informationstext zur Statuseinstellung',
			firstLine: {
				active:
					'AutorIn wird auf Artikel-Detailseite Web & App angezeigt, zusätzlich Detailseite Autorenprofil verlinkt.',
				inactive:
					'AutorIn wird auf Artikel-Detailseite Web & App angezeigt, keine zusätzliche Detailseite Autorenprofil verlinkt.',
			},
			secondLine: {
				active: 'Autorenprofil inaktiv: keine Detailseite Autorenprofil mehr verlinkt',
				inactive: 'Autorenprofil aktiv: zusätzlich Detailseite Autorenprofil verlinkt',
			},
		},
		statusLabel: {
			active: 'aktiv',
			inactive: 'inaktiv',
		},
		firstname: {
			label: 'Vorname',
			placeholder: 'Vorname angeben',
		},
		lastname: {
			label: 'Nachname',
			placeholder: 'Nachname angeben',
		},
		jobTitle: {
			label: 'Tätigkeitsschwerpunkt',
			placeholder: 'Beschreibe die Tätigkeit, z.B: BR Sport-Experte',
		},
		description: {
			label: 'Journalistisches Profil (optional)',
			placeholder: 'Beschreibe dein journalistisches Profil',
		},
		contact: {
			label: 'Kontakt',
		},
		profilePicture: {
			alt: 'Dein Profilbild',
			delete: 'Lösche dein aktuelles Bild',
			edit: 'Bearbeite dein aktuelles Bild',
			upload: 'Lade ein Bild hoch oder ersetzte es durch ein Neues',
		},
		upload: {
			error: {
				message: 'Fehler',
				description: 'Die Datei {{name}} konnte nicht hochgeladen werden!',
			},
		},
		validation: {
			firstname: 'Dein Vorname muss min. {{min}}, max. jedoch {{max}} Zeichen enthalten.',
			lastname: 'Dein Nachname muss min. {{min}}, max. jedoch {{max}} Zeichen enthalten.',
			jobTitle:
				'Dein Tätigkeitsschwerpunkt muss aus min. {{min}}, max. jedoch {{max}} Zeichen bestehen.',
			description: 'Deine Beschreibung darf aus maximal {{max}} Zeichen bestehen.',
		},
		save: {
			success: {
				message: 'Erfolg',
				description: 'Änderungen gespeichert!',
			},
			error: {
				message: 'Fehler',
				description: 'Deine Änderungen konnten nicht gespeichert werden.',
			},
		},
		socialMedia: {
			add: 'Hinzufügen',
			select: {
				placeholder: 'Plattform wählen...',
			},
			platform: {
				title: 'Plattform',
			},
			account: {
				title: 'Account-Name',
				placeholder: 'URL des Accounts oder E-Mail Adresse',
			},
			label: {
				title: 'Label',
				placeholder: 'Beschreibung des Links',
			},
		},
	},
};
