import { PUSH_NOTIFICATION_SOUND, PUSH_NOTIFICATION_SILENT } from '@sep/br24-constants';
import { Card, Alert, Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';
import { randomId } from '@/client/util/br24Utils';
import { filterNullOrUndefined } from '@/client/util/filter';

import routes from '../../../config/config.routes';
import styles from '../../../styles';
import { Bell, BellStrikethrough, Public, Tag } from '../../../ui/Icon';

import LatestPushNotificationsContainer from './LatestPushNotificationsContainer';
import type { PushNotification } from './__generated__/LatestPushNotificationsContainerQuery.graphql';

const IconWrapper = styled.span`
	padding-right: 12px;
`;

const sharedCss = css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const FlexWrapper = styled.div`
	display: flex;
	min-height: 36px;
	line-height: 36px;
	padding: 0 1em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TextWrapper = styled.div`
	flex: 0 0 70%;
	${sharedCss}
`;

const InfoWrapper = styled.div`
	flex: 0 0 20%;
	${sharedCss}
`;

export default () => {
	const translate = useTranslate();

	const setNotificationIcon = (type: PushNotification | null) => {
		if (type === PUSH_NOTIFICATION_SOUND) {
			return (
				<IconWrapper>
					<Tooltip title={translate('dashboard.push.withSound')}>
						<Bell />
					</Tooltip>
				</IconWrapper>
			);
		}
		if (type === PUSH_NOTIFICATION_SILENT) {
			return (
				<IconWrapper>
					<Tooltip title={translate('dashboard.push.withoutSound')}>
						<BellStrikethrough />
					</Tooltip>
				</IconWrapper>
			);
		}

		return (
			<IconWrapper>
				<Tooltip title={translate('dashboard.push.tagPush')}>
					<Tag />
				</Tooltip>
			</IconWrapper>
		);
	};

	return (
		<LatestPushNotificationsContainer
			render={({ error, props }) => {
				const nodes = (props?.data?.nodes || []).filter(filterNullOrUndefined);
				const dataAvailable = props !== null && nodes.length > 0;

				return (
					<div>
						{error ? (
							<Alert message={translate('error')} description={error.message} type="error" />
						) : (
							<Card
								className={classNames({ [styles.cardWithoutPadding]: dataAvailable })}
								title={translate('dashboard.latestPushNotifications')}
								loading={error === null && props === null}
							>
								<ul>
									{dataAvailable ? (
										nodes.map((node) => {
											const pushDate = moment.utc(node.createdAt).local();
											const elementId = randomId();
											const { articleByArticleId } = node;

											return (
												<li key={elementId}>
													<FlexWrapper>
														<TextWrapper>
															{setNotificationIcon(node.pushType)}
															<Link to={`${routes.article}/${articleByArticleId?.rowId}`}>
																{articleByArticleId?.title}
															</Link>
														</TextWrapper>
														<InfoWrapper>{pushDate.fromNow()}</InfoWrapper>
														<InfoWrapper>
															<IconWrapper>
																<Public />
															</IconWrapper>
															{node.recipients}
														</InfoWrapper>
													</FlexWrapper>
												</li>
											);
										})
									) : (
										<p className={styles.pM}>{translate('noData')}</p>
									)}
								</ul>
							</Card>
						)}
					</div>
				);
			}}
		/>
	);
};
