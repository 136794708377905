import moment from 'moment';
import { fetchQuery, graphql } from 'react-relay';
import type { DeepPartial } from 'redux';

import type { LivestreamSearchParameters } from '../../..';
import environment from '../../../../../environment';

import type { searchLiveStreamsQuery } from './__generated__/searchLiveStreamsQuery.graphql';

const query = graphql`
	query searchLiveStreamsQuery(
		$filter: MangoProgrammeFilter
		$rangeFilter: MangoBroadcastEventFilter
		$after: String
	) {
		viewer {
			allProgrammes(filter: $filter, after: $after) {
				count
				pageInfo {
					hasNextPage
					hasPreviousPage
				}
				edges {
					cursor
					node {
						id
						title
						kicker
						defaultTeaserImage {
							imageFiles {
								edges {
									node {
										publicLocation
									}
								}
							}
						}
						broadcasts(filter: $rangeFilter) {
							edges {
								node {
									start
									end
									id
									broadcastedOn {
										edges {
											node {
												id
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export default (async function searchLiveStreams(filterParameters: LivestreamSearchParameters) {
	const { term, channelId: selection, endDate: lt, startDate, lastCursor } = filterParameters;

	const gt = startDate === null ? moment().startOf('day').format() : startDate;

	const queryVariables = {
		filter: {
			term,
			broadcasts: {
				start: {
					gt,
					lt,
				},
				broadcastedOn: {
					contains: selection,
					empty: {
						eq: false,
					},
				},
			},
		},
		rangeFilter: {
			start: {
				gt,
				lt,
			},
		},
		after: lastCursor,
	};

	if (!term) {
		delete (queryVariables as DeepPartial<typeof queryVariables>).filter?.term;
	}

	// channel
	if (!selection) {
		delete (queryVariables as DeepPartial<typeof queryVariables>).filter?.broadcasts?.broadcastedOn
			?.contains;
	} else {
		delete (queryVariables as DeepPartial<typeof queryVariables>).filter?.broadcasts?.broadcastedOn
			?.empty;
	}

	// ending time in range (less than)
	if (!lt) {
		delete (queryVariables as DeepPartial<typeof queryVariables>).filter?.broadcasts?.start?.lt;
		delete (queryVariables as DeepPartial<typeof queryVariables>).rangeFilter?.start?.lt;
	}

	return fetchQuery<searchLiveStreamsQuery>(environment, query, queryVariables)
		.toPromise()
		.then((res) => res?.viewer?.allProgrammes);
});
