import { Component } from 'react';

import type { ImportedItemGroup } from '../..';
import { AM_DEFAULT_GROUP } from '../../../../constants';

import type { AssetManagerImporterResultSetProps } from './AssetManagerImporterResults';
import ItemGroupResultSet from './ItemGroupResultSet';
import PaginationWrapper from './Pagination';

type State = {
	page: number;
	pagesArray: { searches: ImportedItemGroup[] }[];
	waitingForUpdate: boolean;
};

const ITEMS_PER_PAGE = 12;

class StackAndPaginateResults extends Component<AssetManagerImporterResultSetProps, State> {
	state: State = {
		page: 0,
		pagesArray: [],
		waitingForUpdate: false,
	};

	componentDidMount() {
		if (this.props.importedItems) {
			this.refreshPages(this.props.importedItems.slice().reverse());
			this.setState(() => ({
				page: 0,
			}));
		}
	}

	componentDidUpdate(prevProps: AssetManagerImporterResultSetProps) {
		// These variables are all used for conditionals below, and were pulled out to keep lines a tiny bit shorter
		const itemTotalChanged =
			this.sumItemTotal(this.props.importedItems) !== this.sumItemTotal(prevProps.importedItems);
		const itemGroupTotalChanged =
			this.props.importedItems.length !== prevProps.importedItems.length;
		const prevReversedArray = prevProps.importedItems.slice().reverse();

		// Reversed as above to have the latest searches appear first.
		const reversedArray = this.props.importedItems.slice().reverse();

		// If the importer working before made us wait (or if the itemGroup total changed because of a deletion) and now the importer isn't working, update the pages
		if ((this.state.waitingForUpdate || itemGroupTotalChanged) && !this.props.importerWorking) {
			this.refreshPages(reversedArray);
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState(() => ({
				waitingForUpdate: false,
			}));

			// Trying to only jump the user to the first page if necessary.
			if (
				this.buildPagesArray(reversedArray).length !==
					this.buildPagesArray(prevReversedArray).length ||
				this.props.importedItems.length > prevProps.importedItems.length
			) {
				this.handleChange(1);
			}
		}
		// items are currently coming in and we have to wait for them to stop before updating the pages
		// importerWorking is not alone enough to determine when it is safe to update the pages because items are
		// currently imported one at a time.
		else if ((itemTotalChanged || itemGroupTotalChanged) && this.props.importerWorking) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState(() => ({
				waitingForUpdate: true,
			}));
		}
	}

	// Converts array structured of groupItems ( [{groupTitle: "Bob Ross", items: [livestream, livestream] }, {}, ... ] ),
	// to an array where each element is a page ( [{ searches: [groupItem, groupItem] }, { searches: [groupItem] }, ... ] )
	// That way the pagination can simply pass the page to the array to access all the searches that belong there.
	buildPagesArray = (itemGroupArray: Array<ImportedItemGroup>) => {
		let itemsOnPage = 0;
		return itemGroupArray
			.reduce(
				(accumulatorArray: Array<{ searches: ImportedItemGroup[] }>, itemGroup) => {
					// * The next search is alone bigger than the item limit. Put it on a new page.
					if (itemGroup.items.length >= ITEMS_PER_PAGE) {
						accumulatorArray.push(
							{
								searches: [itemGroup],
							},
							{ searches: [] }
						);
						itemsOnPage = 0;
					}
					// * The next search would be too much with the items already on the page. Put it on a new page but leave room there for future searches.
					else if (itemsOnPage + itemGroup.items.length >= ITEMS_PER_PAGE) {
						accumulatorArray.push({
							searches: [itemGroup],
						});
						itemsOnPage = itemGroup.items.length;
					}
					// * The next search is small enough, so add it to the last page in the array
					else {
						itemsOnPage += itemGroup.items.length;
						const pageToUpdate = accumulatorArray.length === 0 ? 0 : accumulatorArray.length - 1;
						accumulatorArray[pageToUpdate].searches.push(itemGroup);
					}
					return accumulatorArray;
				},
				[{ searches: [] }]
			)
			.filter((page) => page.searches.length !== 0);
	};

	handleChange = (pageFromClick: number) => {
		this.setState(() => {
			let updatedPage: number;
			if (pageFromClick <= 1) {
				updatedPage = 0;
			} else {
				updatedPage = pageFromClick - 1;
			}
			return {
				page: updatedPage,
			};
		});
	};

	refreshPages = (itemGroupArray: Array<ImportedItemGroup>) => {
		if (itemGroupArray[0].groupTitle === AM_DEFAULT_GROUP) {
			this.setState(() => ({
				pagesArray: [],
			}));

			return;
		}

		this.setState(() => ({
			pagesArray: this.buildPagesArray(itemGroupArray),
		}));
	};

	sumItemTotal = (importedItems: Array<any>) =>
		importedItems.reduce(
			(itemTotal, itemGroup) => Number(itemTotal) + Number(itemGroup.items.length),
			0
		);

	render() {
		const { importer, importedItems } = this.props;

		// Just to make sure the default search works
		const itemSearches =
			this.state.pagesArray.length === 0
				? [
						{
							searches: importedItems,
						},
				  ]
				: this.state.pagesArray;

		const searchCount = itemSearches.map((page) => page.searches.length).reduce((a, b) => a + b, 0);
		const resultCount = itemSearches
			.map((page) =>
				page.searches.map((s) => s.items.length).reduce((a, b) => Number(a) + Number(b), 0)
			)
			.reduce((a, b) => Number(a) + Number(b), 0);

		return (
			<>
				<PaginationWrapper
					total={this.state.pagesArray.length}
					current={this.state.page + 1}
					defaultPageSize={1}
					onChange={this.handleChange}
					searchCount={searchCount}
					resultCount={resultCount}
				/>
				{itemSearches[this.state.page].searches.map((importedItemGroup) => (
					<ItemGroupResultSet
						groupTitle={importedItemGroup.groupTitle}
						items={importedItemGroup.items}
						hasNextPage={importedItemGroup.hasNextPage}
						searchParameters={importedItemGroup.searchParameters}
						importer={importer}
						key={importedItemGroup.groupTitle}
					/>
				))}
			</>
		);
	}
}
export default StackAndPaginateResults;
