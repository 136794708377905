import styled from 'styled-components';

import { ErrorIcon } from '../Icon';

type Props = {
	hasBottomMargin?: boolean;
	isMsgIndented?: boolean;
	text?: string | string[] | null;
};

const ErrorMessage = styled.p<Props>`
	margin-bottom: ${(props) => (props.hasBottomMargin ? '10px' : 0)};
	margin-top: ${(props) => (props.hasBottomMargin ? 0 : '10px')};
	font-weight: 600;
	margin-left: ${(props) => (props.isMsgIndented ? '136px' : 0)};
	color: ${(props) => props.theme?.colors.stateRed};
	line-height: 25px;
`;

function ErrorMessageText({ hasBottomMargin, isMsgIndented, text }: Props) {
	return (
		<ErrorMessage hasBottomMargin={hasBottomMargin} isMsgIndented={isMsgIndented}>
			{text ? (
				<span>
					<ErrorIcon /> {text}
				</span>
			) : null}
		</ErrorMessage>
	);
}

ErrorMessageText.defaultProps = {
	hasBottomMargin: false,
	isMsgIndented: false,
};

export default ErrorMessageText;
