import { STATUS_REQUEST, STATUS_SUCCESS, STATUS_ERROR } from '@sep/br24-constants';
import immutableUpdate from 'immutability-helper';
import type { Action } from 'redux';

import type { ActionWithPayload, AppThunkAction } from '@/client/store/redux';
import { generateReducer } from '@/client/util/generateReducer';

import environment from '../../../environment';

import RepublishArticleMutation from './RepublishArticleMutation';
import validator from './validator';

export const ARTICLE_REPUBLISHER_START = 'ARTICLE_REPUBLISHER_START';
export const ARTICLE_REPUBLISHER_UPDATE = 'ARTICLE_REPUBLISHER_UPDATE';
export const ARTICLE_REPUBLISHER_SAVE = 'ARTICLE_REPUBLISHER_SAVE';
export const ARTICLE_REPUBLISHER_CANCEL = 'ARTICLE_REPUBLISHER_CANCEL';

export type Article = {
	rowId: string;
	slug: string;
	title: string;
};

export type ArticlePatch = {
	slug?: string;
	title?: string;
};

export type State = {
	isWorking: boolean;
	active: string | undefined | null;
	latestError: string | undefined | null;
	validationByArticle: {
		[articleId: string]: {
			[key: string]: Array<string>;
		} | null;
	};
	originalArticles: {
		[articleId: string]: Article;
	};
	updatedArticles: {
		[articleId: string]: Article | {};
	};
};

type StartAction = ActionWithPayload<typeof ARTICLE_REPUBLISHER_START, Article>;

type UpdateAction = ActionWithPayload<
	typeof ARTICLE_REPUBLISHER_UPDATE,
	{
		patch: ArticlePatch;
		active: string;
		validation: any;
	}
>;

type SaveRequestAction = {
	type: typeof ARTICLE_REPUBLISHER_SAVE;
	status: typeof STATUS_REQUEST;
};

type SaveSuccessAction = {
	type: typeof ARTICLE_REPUBLISHER_SAVE;
	status: typeof STATUS_SUCCESS;
};

type SaveErrorAction = {
	type: typeof ARTICLE_REPUBLISHER_SAVE;
	status: typeof STATUS_ERROR;
	payload: Error;
};

type SaveAction = AppThunkAction<SaveRequestAction | SaveSuccessAction | SaveErrorAction>;

type CancelAction = Action<typeof ARTICLE_REPUBLISHER_CANCEL>;

const initialState: State = {
	isWorking: false,
	active: null,
	latestError: null,
	validationByArticle: {},
	originalArticles: {},
	updatedArticles: {},
};

export default generateReducer(initialState, {
	[ARTICLE_REPUBLISHER_START]: (state: State, action: StartAction) =>
		immutableUpdate(state, {
			latestError: { $set: null },
			active: { $set: action.payload.rowId },
			originalArticles: {
				[action.payload.rowId]: { $set: action.payload },
			},
			updatedArticles: {
				[action.payload.rowId]: { $set: {} },
			},
			validationByArticle: {
				[action.payload.rowId]: { $set: null },
			},
		}),
	[ARTICLE_REPUBLISHER_UPDATE]: (state: State, action: UpdateAction) =>
		immutableUpdate(state, {
			updatedArticles: {
				[action.payload.active]: {
					$merge: action.payload.patch,
				},
			},
			validationByArticle: {
				[action.payload.active]: {
					$set: action.payload.validation,
				},
			},
		}),
	[ARTICLE_REPUBLISHER_SAVE]: {
		[STATUS_REQUEST]: (state: State) =>
			immutableUpdate(state, {
				$merge: {
					isWorking: true,
					latestError: null,
				},
			}),
		[STATUS_SUCCESS]: (state: State) =>
			immutableUpdate(state, {
				$merge: {
					isWorking: false,
					latestError: null,
				},
			}),
		[STATUS_ERROR]: (state: State, action: SaveErrorAction) =>
			immutableUpdate(state, {
				$merge: {
					isWorking: false,
					latestError: action.payload.message,
				},
			}),
	},
	[ARTICLE_REPUBLISHER_CANCEL]: (state: State) => {
		const nextState: State = immutableUpdate(state, {
			latestError: { $set: null },
			active: { $set: null },
		});

		if (state.active) {
			delete nextState.updatedArticles[state.active];
			delete nextState.originalArticles[state.active];
			delete nextState.validationByArticle[state.active];
		}

		return nextState;
	},
});

export function start(article: Article): StartAction {
	const { rowId, title, slug } = article;

	return {
		type: ARTICLE_REPUBLISHER_START,
		payload: {
			rowId,
			title,
			slug,
		},
	};
}

export function update(patch: ArticlePatch): AppThunkAction<UpdateAction> {
	return (dispatch, getState) => {
		const state = getState().articleRepublisher;

		if (!state.active || state.isWorking || !state.originalArticles[state.active]) {
			return;
		}

		const original = state.originalArticles[state.active];
		const previous = state.updatedArticles[state.active];
		const { isValid, error } = validator(patch, original, previous);

		dispatch({
			type: ARTICLE_REPUBLISHER_UPDATE,
			payload: {
				patch,
				validation: !isValid ? error : null,
				active: state.active,
			},
		});
	};
}

export function save(callback?: (err?: Error | null, res?: any | null) => void): SaveAction {
	return (dispatch, getState) => {
		const state = getState().articleRepublisher;

		if (state.isWorking || !state.active || !state.updatedArticles[state.active]) {
			return;
		}

		const { active } = state;
		const article = state.updatedArticles[active];
		const validation = state.validationByArticle[active];

		if (validation && Object.keys(validation).length > 0) {
			return;
		}

		dispatch({
			type: ARTICLE_REPUBLISHER_SAVE,
			status: STATUS_REQUEST,
		});

		RepublishArticleMutation(active, article as Article, environment)
			.then((res) => {
				dispatch({ type: ARTICLE_REPUBLISHER_SAVE, status: STATUS_SUCCESS });

				if (typeof callback === 'function') {
					callback(null, res);
				}
			})
			.catch((err: Error) => {
				dispatch({ type: ARTICLE_REPUBLISHER_SAVE, status: STATUS_ERROR, payload: err });

				if (typeof callback === 'function') {
					callback(err, null);
				}
			});
	};
}

export function cancel(): CancelAction {
	return {
		type: ARTICLE_REPUBLISHER_CANCEL,
	};
}
