import styled from 'styled-components';

import { getAssetManagerTypeIcon } from '../../Icon';

type AssetManagerItemIconProps = {
	className?: string;
	type?: string;
	isOverlying?: boolean;
	color?: string;
};

const IconWrapper = styled.span`
	display: inline-block;
	background-color: ${(props) => props.color || '#000000'};
	color: #ffffff;
	padding: 0.2rem 0.5rem;
	font-size: 1.2rem;
	border-radius: 2px;
`;

const AssetManagerItemIcon = styled(({ className, type, color }: AssetManagerItemIconProps) => {
	const Icon = getAssetManagerTypeIcon(type);

	return (
		<div className={className}>
			<IconWrapper color={color}>
				<Icon />
			</IconWrapper>
		</div>
	);
})`
	bottom: ${(props) => (props.isOverlying ? '0.4rem' : 'auto')};
	display: flex;
	align-items: center;
	justify-content: center;
	left: ${(props) => (props.isOverlying ? '0.4rem' : 'auto')};
	position: ${(props) => (props.isOverlying ? 'absolute' : 'static')};
	margin: ${(props) => (props.isOverlying ? 'none' : '0 0.4rem')};
`;

export default AssetManagerItemIcon;
