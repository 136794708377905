import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { STATUS_PUBLISHED, TYPE_BOARD, LS_READ_ONLY_MODE } from '@sep/br24-constants';
import { notification, Alert, Collapse, Layout, Row, Col, Button, Dropdown } from 'antd';
import cn from 'classnames';
import { debounce } from 'lodash-es';
import { Component, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import type { ConnectedProps } from 'react-redux';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import { type RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import { AvatarLockInfo } from '@/client/containers/ArticleStatusBar/AvatarLockInfo/AvatarLockInfo';
import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';
import HistoryDropdown from '@/client/ui/StatusBar/HistoryDropdown';
import { historyItemToHistoryTimeline } from '@/client/ui/StatusBar/HistoryTimeline/HistoryTimeline';
import { LockHandlingBoard } from '@/client/util/lockHandler/LockHandling';

import * as boardActions from '../../store/reducers/boardBuffer';
import type { BoardSectionSchema } from '../../store/reducers/boardBuffer';
import * as userActions from '../../store/reducers/user';
import globalStyles from '../../styles';
import type { WithTranslationProps } from '../../translation';
import { DropdownDown } from '../../ui/Icon';
import ReadOnlyBar from '../../ui/ReadOnlyBar';
import { createPreviewUrl, openPreview, triggerReloadPreview } from '../../util/preview';
import { media } from '../../util/styled';
import { Page } from '../Page';
import Sidebar from '../Sidebar';

import AddIcon from './AddIcon';
import styles from './Board.module.scss';
import { MetaGeneral, MetaSocialMedia, MetaFeeds } from './Meta';
import PublicationStatusPicker from './PublicationStatusPicker';
import SearchContainer from './Search';
import Section from './Section';
import SectionPicker from './SectionPicker';
import type { Board_board$data } from './__generated__/Board_board.graphql';

const { Button: DropdownButton } = Dropdown;
const { Content } = Layout;

interface Props extends WithTranslationProps, RouteComponentProps, ReduxProps, OwnProps {
	board: Board_board$data;
}

type State = {
	isSearchCollapsed: boolean;
	isMetaCollapsed: boolean;
	isSectionPickerVisible: boolean;
	insertAt: number | undefined | null;
};

const BoardTitle = styled.h2`
	margin: 0 2em 0 1em;
	font-size: 1.4em;
	line-height: 34px;

	${media.lessThan('giant')`
		font-size: 1.2em;
	`};
`;

const HistoryDropdownWrapper = styled.div`
	margin-left: 1em;
`;

function getHistoryForTitle(histories: Board_board$data['historiesByBoardId']) {
	if (histories?.edges.length > 0 && histories.edges[0].node) {
		return historyItemToHistoryTimeline(histories.edges[0].node);
	}
	return undefined;
}

class Board extends Component<Props, State> {
	state = {
		isSearchCollapsed: false,
		isMetaCollapsed: false,
		isSectionPickerVisible: false,
		insertAt: null,
	};

	componentDidMount() {
		const { initialize, board } = this.props;

		initialize(board);
		document.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown);
		this.debounceRef.cancel();
	}

	handleClickCancel = () => this.setState({ isSectionPickerVisible: false, insertAt: null });

	handleCancelAddSectionBetween = () =>
		this.setState({ isSectionPickerVisible: false, insertAt: null });

	handleClickCollapseMeta = () =>
		this.setState((prevState) => ({ isMetaCollapsed: !prevState.isMetaCollapsed }));

	handleClickCollapseSearch = () =>
		this.setState((prevState) => ({ isSearchCollapsed: !prevState.isSearchCollapsed }));

	handleClickNew = () => this.setState({ isSectionPickerVisible: true });

	handleClickValidationMenu = ({ key: type }) => {
		switch (type) {
			case 'validate':
				this.props.validate();
				break;

			case 'toggleAutoValidate':
				this.props.setAutoValidation(!this.props.isAutoValidatorActive);
				break;

			default:
				break;
		}
	};

	handleClickValidatonButton = () => this.handleClickValidationMenu({ key: 'validate' });

	handleKeyDown = (event: KeyboardEvent) => {
		const {
			lockStatus: { mode },
		} = this.props;

		// allow saving only when board is not in read only mode
		if (mode !== LS_READ_ONLY_MODE && (event.metaKey || event.ctrlKey) && event.which === 83) {
			window.focus();

			if (document.activeElement && typeof document.activeElement['blur'] === 'function') {
				(document.activeElement as any).blur();
			}

			event.preventDefault();
			this.handleSave();
		}
	};

	handleSave = () => {
		const { isSavable, buffer, persist, validate } = this.props;

		if (!isSavable) {
			return;
		}
		const onTheWayToTheClient = buffer.status === STATUS_PUBLISHED;
		validate(
			(isValid) => {
				notification.close('board-save-result');

				if (!isValid && onTheWayToTheClient) {
					notification.error({
						message: 'Die Änderungen konnten nicht gespeichert werden.',
						duration: 0,
						key: 'board-save-result',
					});
					return;
				}

				persist((err) => {
					if (err) {
						return;
					}
					this.refetchDocument(() => {
						notification.success({
							message: 'Alles klar!',
							description: `Die Seite "${
								this.props.board.title || '-'
							}" wurde erfolgreich gespeichert${onTheWayToTheClient ? ' und veröffentlicht' : ''}.`,
							duration: 5,
							key: 'board-save-result',
						});

						triggerReloadPreview();
					});
				});
			},
			{ skipIfAutoValidationIsDisabled: !onTheWayToTheClient }
		);
	};

	private debounceRef = debounce(this.handleSave, 1000);

	handleAddNewSection = () => this.setState({ isSectionPickerVisible: true });

	handleAddSectionBetween = (insertAt: number) =>
		this.setState((prevState) => ({
			isSectionPickerVisible: !prevState.isSectionPickerVisible,
			insertAt,
		}));

	/**
	 * Performs a refetch of the data and (re)initializes the document.
	 * If a callback is given the callback will be executed afterwards.
	 *
	 * The refetchDocument method will also be executed in the HOC withLockHandlingForEditor.
	 */
	refetchDocument = (callback?: () => void) => {
		this.props.relay.refetch({ boardId: this.props.buffer.rowId }, null, () =>
			setTimeout(() => {
				this.props.initialize(this.props.board);

				if (callback) {
					callback();
				}
			}, 1000)
		);
	};

	render() {
		const { isSearchCollapsed, isMetaCollapsed, isSectionPickerVisible, insertAt } = this.state;

		const {
			isAutoValidatorActive,
			isBufferInitialized,
			isSavable,
			buffer,
			addSection,
			latestError,
			lockStatus: { mode },
			board: { lockedBy, historiesByBoardId },
		} = this.props;

		if (!isBufferInitialized) {
			return <span>Laden...</span>;
		}

		const validationMenuItems = [
			{ key: 'validate', label: 'Prüfung starten' },
			{
				key: 'toggleAutoValidate',
				label: `Automatische Prüfung ${isAutoValidatorActive ? 'deaktivieren' : 'aktivieren'}`,
			},
		];

		// TODO: add working indicator as long as isWorking is truely

		return (
			<Page style={{ paddingBottom: 0 }}>
				<Helmet>
					<title>Board bearbeiten</title>
				</Helmet>

				<LockHandlingBoard board={this.props.board} refetchDocument={this.refetchDocument} />

				{latestError ? (
					<Alert
						type="error"
						message="Fehler beim Speichern"
						description={`${latestError.message} (${latestError.stack})`}
						banner={true}
					/>
				) : (
					false
				)}
				{mode === LS_READ_ONLY_MODE ? (
					<ReadOnlyBar />
				) : (
					<Page.Content className={cn(globalStyles.headline)}>
						<div className={globalStyles.headlineLeft}>
							<BoardTitle>{this.props.board.title}</BoardTitle>
							<Suspense fallback={''}>
								<AvatarLockInfo lockedBy={lockedBy} />
							</Suspense>
							<HistoryDropdownWrapper>
								<HistoryDropdown
									boardId={this.props.id}
									historyItem={getHistoryForTitle(historiesByBoardId)}
									totalItems={historiesByBoardId.totalCount}
									isDisabled={false}
								/>
							</HistoryDropdownWrapper>
						</div>
						<div className={globalStyles.buttons}>
							<Button
								// disabled={this.props.isSaving}
								type="primary"
								onClick={() => {
									openPreview(createPreviewUrl(TYPE_BOARD, buffer));
								}}
							>
								{
									buffer.status === STATUS_PUBLISHED ? 'zur Verteilseite' : 'Vorschau'
									//	? translate('board.previewButton.toBoard') : translate('board.previewButton.preview')
								}
							</Button>

							<DropdownButton
								onClick={this.handleClickValidatonButton}
								trigger={['click']}
								icon={<DropdownDown />}
								menu={{ items: validationMenuItems, onClick: this.handleClickValidationMenu }}
							>
								Fehlersuche
							</DropdownButton>
							<PublicationStatusPicker
								boardId={buffer.rowId}
								isDisabled={!isSavable}
								onRequestSave={this.handleSave}
							/>
						</div>
					</Page.Content>
				)}

				<Layout>
					<Sidebar
						border="right"
						overwriteWidth={isMetaCollapsed ? 55 : null}
						className={cn({
							[styles.isCollapsed]: isMetaCollapsed,
						})}
					>
						<div className={cn(styles.sidebarHeader, globalStyles.pM)}>
							<Button
								type="primary"
								shape="circle"
								icon={isMetaCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
								onClick={this.handleClickCollapseMeta}
								className={styles.buttonRight}
							/>
						</div>
						<div className={cn(styles.sidebarContent, globalStyles.pM)}>
							<Collapse defaultActiveKey={['general']}>
								<MetaGeneral key="general" boardId={buffer.rowId} />
								<MetaFeeds key="feeds" rdfFeed={buffer.rdfFeed} rssFeed={buffer.rssFeed} />
								<MetaSocialMedia key="socialMedia" boardId={buffer.rowId} />
							</Collapse>
						</div>
					</Sidebar>
					<Content>
						<Row>
							<Col span={24}>
								<div className={cn(styles.headline)}>
									<SectionPicker
										isSectionPickerVisible={isSectionPickerVisible}
										insertAt={insertAt}
										onSelect={addSection}
										onClickNew={this.handleAddNewSection}
										onClickCancel={this.handleCancelAddSectionBetween}
									/>
								</div>
							</Col>
						</Row>
						<Row style={{ paddingBottom: '4rem' }}>
							{buffer.sections
								.filter((item) => item && item.__internalId__)
								.map(({ __internalId__ }, index) => (
									<Col key={__internalId__} span={24} className={globalStyles.pM}>
										<Section internalId={__internalId__} />
										<AddIcon
											insertAt={index + 1}
											onAddSectionBetween={this.handleAddSectionBetween}
										/>
									</Col>
								))}
						</Row>
					</Content>
					<Sidebar
						border="left"
						overwriteWidth={isSearchCollapsed ? 55 : null}
						className={cn({
							[styles.isCollapsed]: isSearchCollapsed,
						})}
					>
						<div className={cn(styles.sidebarHeader, globalStyles.pM)}>
							<Button
								type="primary"
								shape="circle"
								icon={isSearchCollapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
								onClick={this.handleClickCollapseSearch}
							/>
						</div>
						<div className={styles.sidebarContent}>
							<SearchContainer />
						</div>
					</Sidebar>
				</Layout>
			</Page>
		);
	}
}

const BoardWithRouter = withRouter(withTranslation(Board));

const connector = connect(
	(state: ReduxState, { id }: OwnProps) => {
		const buffer = state.boardBuffer.boards[id] || null;

		return {
			isAutoValidatorActive: !!state.user.settings.boardAutoValidation,
			isBufferInitialized: buffer !== null,
			isSavable: (buffer !== null && state.boardBuffer.savableByBoard[id]) || false,
			isWorking: (buffer !== null && state.boardBuffer.isWorkingByBoard[id]) || false,
			latestError: state.boardBuffer.latestErrorByBoard[id] || null,
			buffer,
			rawBuffer: state.boardBuffer.raw[id] || null,
			lockStatus: state.lockStatus,
		};
	},
	(dispatch, { id, relay }: OwnProps) => ({
		persist: (callback?: (err: null | Error, res?: any | null) => void) =>
			dispatch(boardActions.persist(id, relay.environment, callback)),
		initialize: (data: Board_board$data) => dispatch(boardActions.initializeAndClean(data)),
		addSection: (schema: BoardSectionSchema, order?: number | null) =>
			dispatch(boardActions.addSection(id, schema, order)),
		setAutoValidation: (isActive: boolean) => {
			dispatch(userActions.updateSettings({ boardAutoValidation: isActive }));
			if (!isActive) {
				// clear existing error labels when disabling auto validation
				dispatch(boardActions.clearAllValidations(id));
			}
		},
		validate: (
			callback?: (isValid: boolean) => void,
			options?: {
				skipIfAutoValidationIsDisabled?: boolean;
				shouldValidateSlug?: boolean;
			}
		) => dispatch(boardActions.validateAll(id, callback, options)),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

interface OwnProps {
	id: string;
	relay: RelayRefetchProp;
}

const BoardWithRedux = connector(BoardWithRouter);

export default createRefetchContainer(
	BoardWithRedux,
	{
		board: graphql`
			fragment Board_board on Board {
				rowId
				rssFeed
				rdfFeed
				parentId
				image {
					url
					altText
					title
					copyright
				}
				slug
				title
				seoTitle
				description
				hasIntroductionText
				introductionHeadline
				introductionText
				categoryId
				categoryByCategoryId {
					rowId
				}
				socialMediaAccounts {
					service
					label
					accountName
				}
				noindex
				historiesByBoardId(first: 2, condition: { boardId: $boardId }, orderBy: CREATED_AT_DESC) {
					totalCount
					edges {
						node {
							createdAt
							rowId
							data
							authorByAccomplishedBy {
								name
							}
						}
					}
				}
				boardsSectionsByBoardId(first: 1000, orderBy: ORDER_ASC) {
					totalCount
					edges {
						node {
							boardLink
							rowId
							schema
							categoryId
							categoryByCategoryId {
								rowId
								title
							}
							title
							color
							boardsSectionsItemsByBoardsSectionId(first: 1000, orderBy: ORDER_ASC) {
								totalCount
								edges {
									node {
										rowId
										articleId
										boardsTeaserId
										boardId
										order
									}
								}
							}
							autofill
							meta
							order
							showHeroTitleForMobile
							updatedBy
							authorByUpdatedBy {
								guid
								firstname
								lastname
							}
							updatedAt
							createdBy
							authorByCreatedBy {
								guid
								firstname
								lastname
							}
							createdAt
						}
					}
				}
				protected
				status
				updatedBy
				authorByUpdatedBy {
					guid
					firstname
					lastname
				}
				updatedAt
				createdBy
				authorByCreatedBy {
					guid
					firstname
					lastname
				}
				createdAt
				authorByLockedBy {
					guid
					firstname
					lastname
				}
				lockedBy
				lockedSince
				...LockHandling_board
			}
		`,
	},
	graphql`
		query BoardRefetchQuery($boardId: String!) {
			board: boardByRowId(rowId: $boardId) {
				...Board_board
				rowId
			}
		}
	`
);
