import type { UpdateFilterAction, UpdateFilterInput, Space } from '..';
import { UPDATE_FILTER } from '../actionTypes';

const updateFilter = (input: UpdateFilterInput, space: Space): UpdateFilterAction => {
	const filterData = { ...input };

	// reset search value
	if (
		filterData.hasOwnProperty('searchValue') &&
		typeof filterData.searchValue === 'string' &&
		filterData.searchValue.length === 0
	) {
		filterData.searchValue = null;
	}

	return {
		type: UPDATE_FILTER,
		payload: {
			filterData,
			space,
		},
	};
};

export default updateFilter;
