import { Children, cloneElement, type ReactElement } from 'react';
import { useDrop } from 'react-dnd';

import type { Item } from '../../AssetManager';

interface Props {
	itemTypes: Array<string>;
	acceptedItemTypes: Array<string>;
	onDropUpdate: (item: Item) => void;
	onDropUpload: (files: File[]) => void;
	children: ReactElement<{ isOver: boolean }>;
}

export function DndUpdateModuleContainer({
	itemTypes,
	onDropUpload,
	onDropUpdate,
	children,
	...rest
}: Props) {
	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: itemTypes,
			drop: ({ item, files }: { files: File[]; item: Item }) => {
				if (files) {
					onDropUpload(files);
				}

				if (item) {
					onDropUpdate(item);
				}
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[itemTypes, onDropUpdate, onDropUpload]
	);

	if (!drop) {
		throw new Error('Item was not connected to a dropTarget');
	}

	const childrenWithProps = Children.map(children, (child) =>
		cloneElement(child, { isOver, ...rest })
	);
	const connectedToDropTarget = <div ref={drop}>{childrenWithProps}</div>;

	return connectedToDropTarget;
}
