import classnames from 'classnames';
import { useDrop } from 'react-dnd';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import type { AssetManagerSectionDivider as AssetManagerSectionDividerType, Item } from '..';
import {
	AM_MODE_EDIT,
	AM_TYPE_BOARD_TEASER,
	AM_TYPE_BOARD,
	AM_TYPE_ARTICLE,
	AM_TYPE_TEXT,
	AM_TYPE_LINK,
	AM_TYPE_EMBED_CODE,
	AM_TYPE_GALLERY,
	AM_TYPE_BRBILD_IMAGE,
	AM_TYPE_IMAGE,
	AM_TYPE_AUDIO,
	AM_TYPE_VIDEO360,
	AM_TYPE_VIDEO,
	AM_TYPE_LIVESTREAM,
	AM_SECTION_DIVIDER,
} from '../../../constants';
import { AssetManagerEmptyPlaceholder, AssetManagerSectionDivider } from '../../../ui/AssetManager';
import LockButton, { ItemWrapper } from '../../../ui/AssetManager/AssetManagerItem/Lock';
import { toAssetManager } from '../../ArticleEditor/DragAndDropHandler/dndUtils';
import {
	AssetManagerItemArticle,
	AssetManagerItemAudio,
	AssetManagerItemBoard,
	AssetManagerItemBoardTeaser,
	AssetManagerItemEmbedCode,
	AssetManagerItemGallery,
	AssetManagerItemImage,
	AssetManagerItemLink,
	AssetManagerItemText,
	AssetManagerItemVideo,
	AssetManagerItemVideo360,
	AssetManagerItemLiveStream,
} from '../AssetManagerItem';
import * as actions from '../actions';
import { itemsSelector, baseInformationSelector } from '../selectors';

import styles from './AssetManagerSpace.module.scss';
import isDroppable from './isDroppable';
import isDuplicate from './isDuplicate';
import performDrop from './performDrop';

export type BrokerResult = {
	wasAdded: boolean;
};

interface AssetManagerSpaceProps extends ReduxProps {
	toggleDragIsCopy: (isCopy: boolean) => void;
	removeAfterDrop: boolean;
	isGlobalSpace?: boolean;
}

interface AssetManagerItemProperties {
	isDraggable: boolean;
	removeAfterDrop: boolean;
	toggleDragIsCopy: (isCopy: boolean) => void;
	key: string;
	isSelected?: boolean;
	onSelect?: (id: string) => void;
}

function isItem(item: Item | AssetManagerSectionDividerType): item is Item {
	return item.type !== AM_SECTION_DIVIDER;
}

function renderItem(
	item: Item | AssetManagerSectionDividerType,
	forwardProps: AssetManagerItemProperties
) {
	switch (item.type) {
		case AM_TYPE_BOARD_TEASER:
			return <AssetManagerItemBoardTeaser item={item} {...forwardProps} />;
		case AM_TYPE_BOARD:
			return <AssetManagerItemBoard item={item} {...forwardProps} />;
		case AM_TYPE_ARTICLE:
			return <AssetManagerItemArticle item={item} {...forwardProps} />;
		case AM_TYPE_TEXT:
			return <AssetManagerItemText item={item} {...forwardProps} />;
		case AM_TYPE_LINK:
			return <AssetManagerItemLink item={item} {...forwardProps} />;
		case AM_TYPE_EMBED_CODE:
			return <AssetManagerItemEmbedCode item={item} {...forwardProps} />;
		case AM_TYPE_GALLERY:
			return <AssetManagerItemGallery item={item} {...forwardProps} />;
		case AM_TYPE_BRBILD_IMAGE:
			return <AssetManagerItemImage item={item} {...forwardProps} />;
		case AM_TYPE_IMAGE:
			return <AssetManagerItemImage item={item} {...forwardProps} />;
		case AM_TYPE_AUDIO:
			return <AssetManagerItemAudio item={item} {...forwardProps} />;
		case AM_TYPE_VIDEO360:
			return <AssetManagerItemVideo360 item={item} {...forwardProps} />;
		case AM_TYPE_VIDEO:
			return <AssetManagerItemVideo item={item} {...forwardProps} />;
		case AM_TYPE_LIVESTREAM:
			return <AssetManagerItemLiveStream item={item} {...forwardProps} />;
		case AM_SECTION_DIVIDER:
			return <AssetManagerSectionDivider {...forwardProps} item={item} key={item.id} />;

		default:
			return null;
	}
}

function AssetManagerSpace({
	items,
	mode,
	selected,
	removeAfterDrop,
	toggleDragIsCopy,
	isGlobalSpace,
	addItemToActiveSpace,
	transferLinkFromPosition,
	lockItemForDeletion,
	toggleSelected,
}: AssetManagerSpaceProps) {
	const [, dropTarget] = useDrop(
		() => ({
			accept: toAssetManager,
			canDrop: (item, monitor) => {
				const type = monitor.getItemType();

				// When there were less accepted types in the AssetManager, canDrop incidentally prevented dragging and then dropping in the same space.
				// Now with more accepted types that isn't the case, so isDuplicate was added to the canDrop check. Ideally this will also stop the
				// duplication of items in the asset manager, but in some module types that will be tricky.
				const isDup = isDuplicate(type, item, items);
				return isDroppable(type, item) && !isDup;
			},
			drop: (payload, monitor) => {
				if (monitor.didDrop()) {
					return undefined;
				}

				const type = monitor.getItemType();
				return performDrop(addItemToActiveSpace, transferLinkFromPosition, type, payload);
			},
		}),
		[addItemToActiveSpace, transferLinkFromPosition, items]
	);

	const handleLockToggle = (item: Item) => {
		if (!item.id) {
			return;
		}

		lockItemForDeletion(item.id);
	};

	return (
		<div
			className={classnames({ [styles.dropTarget]: true, [styles.scrollContainer]: true })}
			ref={dropTarget}
		>
			{items.length > 0 ? (
				items.map((item) => {
					const forwardProps: AssetManagerItemProperties = {
						isDraggable: mode !== AM_MODE_EDIT,
						removeAfterDrop,
						toggleDragIsCopy,
						key: isGlobalSpace ? `itemInner-${item.id}` : `item-${item.id}`,
					};

					if (mode === AM_MODE_EDIT) {
						forwardProps.isSelected = selected.includes(item.id);

						forwardProps.onSelect = toggleSelected;
					}
					// This is a temporary fix for locked items dragged into the privateSpace that kept their locked status. Can be reverted to just `isGlobalSpace ? ` at some point.
					return isGlobalSpace ||
						(!isGlobalSpace && isItem(item) && item.isLockedForDeletion === true) ? (
						<ItemWrapper key={`itemOutter-${item.id}`}>
							{renderItem(item, forwardProps)}
							<LockButton
								isLockedForDeletion={isItem(item) && item.isLockedForDeletion}
								onToggle={() => isItem(item) && handleLockToggle(item)}
							/>
						</ItemWrapper>
					) : (
						renderItem(item, forwardProps)
					);
				})
			) : (
				<AssetManagerEmptyPlaceholder />
			)}
		</div>
	);
}

const connector = connect(
	(state: ReduxState) => {
		const { mode, selected } = baseInformationSelector(state);
		return {
			items: itemsSelector(state),
			mode,
			selected,
		};
	},
	{
		toggleSelected: actions.toggleSelected,
		addItemToActiveSpace: actions.addItemToActiveSpace,
		lockItemForDeletion: actions.lockItemForDeletion,
		transferLinkFromPosition: actions.transferLinkFromPosition,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AssetManagerSpace);
