import { AutoComplete, DatePicker, TimePicker } from 'antd';
import moment, { type Moment } from 'moment';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { Edit, Confirm, Clock, Calendar } from '../../Icon';

const Wrapper = styled.div`
	display: flex;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
`;

const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	margin-top: 0.2rem;
`;

const IconWrapper = styled.div`
	margin-left: 0.4rem;
	color: ${(props) => props.theme.colors.br24Light};
`;

const MarginLeft = styled.div`
	margin-left: 1.6rem;
	width: 0;
`;

const ActionWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 1.6rem;
`;

type ActionProps = {
	className?: string;
	disabled?: boolean;
	onClick: () => void;
};

export const EditAction = styled(({ className, onClick }: ActionProps) => (
	<button data-testid="edit-action" className={className} onClick={onClick}>
		<Edit />
	</button>
))`
	color: ${(props) => props.theme.colors.br24Light};
	cursor: pointer;
	font-size: 1.3rem;
`;

export const ConfirmAction = styled(({ className, onClick, disabled }: ActionProps) => (
	<button data-testid="confirm-action" className={className} disabled={disabled} onClick={onClick}>
		<Confirm />
	</button>
))`
	color: ${(props) => props.theme.colors.br24Light};
	cursor: pointer;
	font-size: 1.3rem;
`;

export type SuggestionItem = {
	id: string;
	title: string;
};

type Props = WithTranslationProps & {
	date: Moment | undefined | null;
	id: string | undefined | null;
	index: number;
	onChange: (index: number, title?: string | null, date?: string | null) => void;
	onSearch?: (value: string) => void;
	suggestions: Array<SuggestionItem>;
	title: string | undefined | null;
};

type State = {
	date: Moment | undefined | null;
	isEditing: boolean;
	searchText: string | undefined | null;
	selectedId: string | undefined | null;
	selectedText: string | undefined | null;
};

class ArticleMetaSourceOriginItem extends Component<Props, State> {
	state = {
		date: null,
		isEditing: false,
		searchText: null,
		selectedId: null,
		selectedText: null,
	} as State;

	handleChangeDate = (date: Moment | null) => {
		this.setState({ date });
	};

	handleChangeTime = (time: Moment | null) => {
		this.setState({ date: time });
	};

	handleClickConfirm = () => {
		this.setState(
			{
				isEditing: false,
			},
			() => {
				this.props.onChange(
					this.props.index,
					this.state.selectedId,
					this.state.date ? this.state.date.toISOString() : null
				);
			}
		);
	};

	handleClickEdit = () => {
		this.setState({
			date: this.props.date ? moment(this.props.date) : null,
			isEditing: true,
			searchText: null,
			selectedId: this.props.id,
			selectedText: this.props.title,
		});
	};

	handleSearch = (value: string) => {
		this.setState({ searchText: value, selectedId: null, selectedText: null });
		if (this.props.onSearch) {
			this.props.onSearch(value);
		}
	};

	handleSelect = (id: string) => {
		const selectedItem = this.props.suggestions.filter((item) => item.id === id).shift();
		if (selectedItem) {
			this.setState({ searchText: null, selectedId: id, selectedText: selectedItem.title });
		}
	};

	renderEditable() {
		const {
			suggestions,
			translation: { translate },
		} = this.props;
		const { selectedText, searchText, date } = this.state;

		const options =
			Array.isArray(suggestions) && suggestions.length ? suggestions.map(this.renderOption) : [];
		return (
			<Column>
				<AutoComplete
					filterOption={false}
					onSearch={this.handleSearch}
					onSelect={this.handleSelect}
					placeholder={translate('article.meta.sourceOrigin.inputPlaceholder')}
					value={selectedText || searchText}
					data-testid="autocomplete"
				>
					{options}
				</AutoComplete>
				<Row>
					<DatePicker
						format="DD.MM.YYYY"
						onChange={this.handleChangeDate}
						placeholder={translate('article.meta.sourceOrigin.datePlaceholder')}
						value={date}
					/>
					<MarginLeft />
					<TimePicker
						format="HH:mm"
						onChange={this.handleChangeTime}
						placeholder={translate('article.meta.sourceOrigin.timePlaceholder')}
						value={date}
					/>
				</Row>
			</Column>
		);
	}

	renderLabels() {
		const { title, date } = this.props;
		return (
			<Column>
				<span>{title}</span>
				<Row>
					<span>{date ? date.format('DD.MM.YYYY') : '--.--.----'}</span>
					<IconWrapper>
						<Calendar />
					</IconWrapper>
					<MarginLeft />
					<span>{date ? date.format('HH:mm') : '--:--'}</span>
					<IconWrapper>
						<Clock />
					</IconWrapper>
				</Row>
			</Column>
		);
	}

	renderOption = (item: SuggestionItem) => (
		<AutoComplete.Option key={item.id}>{item.title}</AutoComplete.Option>
	);

	render() {
		const { isEditing, selectedId } = this.state;

		return (
			<Wrapper>
				<Content>{isEditing ? this.renderEditable() : this.renderLabels()}</Content>
				<ActionWrapper>
					{isEditing ? (
						<ConfirmAction onClick={this.handleClickConfirm} disabled={!selectedId} />
					) : (
						<EditAction onClick={this.handleClickEdit} />
					)}
				</ActionWrapper>
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleMetaSourceOriginItem);
