/**
 * @generated SignedSource<<c727da2f910f138c34abdcb03d846471>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchAuthorRendererQuery$variables = {
  searchTerm: string;
};
export type SearchAuthorRendererQuery$data = {
  readonly authors: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstname: string | null;
        readonly guid: string;
        readonly isActive: boolean | null;
        readonly isLdapUser: boolean | null;
        readonly jobTitle: string | null;
        readonly lastname: string | null;
      } | null;
    }>;
  } | null;
};
export type SearchAuthorRendererQuery = {
  response: SearchAuthorRendererQuery$data;
  variables: SearchAuthorRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchTerm"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "guid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLdapUser",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchAuthorRendererQuery",
    "selections": [
      {
        "alias": "authors",
        "args": (v1/*: any*/),
        "concreteType": "AuthorsConnection",
        "kind": "LinkedField",
        "name": "searchAuthors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchAuthorRendererQuery",
    "selections": [
      {
        "alias": "authors",
        "args": (v1/*: any*/),
        "concreteType": "AuthorsConnection",
        "kind": "LinkedField",
        "name": "searchAuthors",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34ea6c0606f73e3764232798a5a100e9",
    "id": null,
    "metadata": {},
    "name": "SearchAuthorRendererQuery",
    "operationKind": "query",
    "text": "query SearchAuthorRendererQuery(\n  $searchTerm: String!\n) {\n  authors: searchAuthors(search: $searchTerm) {\n    edges {\n      node {\n        guid\n        firstname\n        lastname\n        isActive\n        isLdapUser\n        jobTitle\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fa28c23301a6cdb72a6cb35ddb8ca2f";

export default node;
