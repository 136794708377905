/**
 * @generated SignedSource<<63823c8ae13c6e055470f128604f936b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardsTeaserSearchContainerQuery$variables = {
  count: number;
  cursor?: any | null;
  searchTerm?: string | null;
};
export type BoardsTeaserSearchContainerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BoardsTeaserSearchContainer_query">;
};
export type BoardsTeaserSearchContainerQuery = {
  response: BoardsTeaserSearchContainerQuery$data;
  variables: BoardsTeaserSearchContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "searchTerm",
    "variableName": "searchTerm"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardsTeaserSearchContainerQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BoardsTeaserSearchContainer_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardsTeaserSearchContainerQuery",
    "selections": [
      {
        "alias": "boardTeasers",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeasersConnection",
        "kind": "LinkedField",
        "name": "filterBoardsTeasersCms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsTeasersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardsTeaser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rowId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleImage",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Link",
                    "kind": "LinkedField",
                    "name": "link",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "boardTeasers",
        "args": (v1/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "BoardsTeaserSearchContainer_boardTeasers",
        "kind": "LinkedHandle",
        "name": "filterBoardsTeasersCms"
      }
    ]
  },
  "params": {
    "cacheID": "476c6ed522cd275cfbd90c6d37834b98",
    "id": null,
    "metadata": {},
    "name": "BoardsTeaserSearchContainerQuery",
    "operationKind": "query",
    "text": "query BoardsTeaserSearchContainerQuery(\n  $count: Int!\n  $cursor: Cursor\n  $searchTerm: String\n) {\n  ...BoardsTeaserSearchContainer_query\n}\n\nfragment BoardsTeaserSearchContainer_query on Query {\n  boardTeasers: filterBoardsTeasersCms(first: $count, after: $cursor, searchTerm: $searchTerm) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        rowId\n        ...BoardsTeaserSearchItem_boardsTeaser\n        id\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment BoardsTeaserSearchItem_boardsTeaser on BoardsTeaser {\n  rowId\n  title\n  status\n  image {\n    url\n    id\n  }\n  link {\n    label\n  }\n  createdAt\n  updatedAt\n}\n"
  }
};
})();

(node as any).hash = "3cb12f9c48e9441126c0dc5c0e31aec8";

export default node;
