import * as util from '@sep/br24-util';

import { AM_TYPE_TEXT } from '../../../constants';

import { Content } from './AssetManagerItemControl';
import TypeIcon from './TypeIcon';

type AssetManagerItemTextProps = {
	text: string;
};

function AssetManagerItemText({ text }: AssetManagerItemTextProps) {
	return (
		<>
			<TypeIcon type={AM_TYPE_TEXT} />
			<Content $isProminent={true}>{util.string.truncate(text, 120)}</Content>
		</>
	);
}

export default AssetManagerItemText;
