import styled from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	text-align: center;
	margin: 20px auto 30px auto;
	height: 20px;
	width: 100%;
	border-bottom: 1px solid ${(props) => props.theme.colors.br24Light};

	span {
		display: inline-block;
		background-color: ${(props) => props.theme.colors.dark4};
		padding: 8px 1rem 0 1rem;
		font-size: 1.2rem;
		max-width: 250px;

		user-select: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

type Props = {
	item: {
		id: string;
		label: string;
	};
};

// * These components are created in selectors.js upon finding an unsortable item. Could be used for other purposes.
export function AssetManagerSectionDivider({ item }: Props) {
	return (
		<Wrapper>
			<span>{item.label}</span>
		</Wrapper>
	);
}
