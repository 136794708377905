import type { AppThunkAction } from '@/client/store/redux';

import type { ExitAction } from '..';
import { EXIT } from '../actionTypes';

const exit = (): AppThunkAction<ExitAction> => (dispatch) => {
	dispatch({
		type: EXIT,
	});
};

export default exit;
