import styled from 'styled-components';

import { AM_TYPE_EMBED_CODE } from '../../../constants';

import { Content } from './AssetManagerItemControl';
import TypeIcon from './TypeIcon';

type AssetManagerItemEmbedsProps = {
	item: any;
};

const EmbedHeadline = styled.p`
	margin-bottom: 0.5em;
	font-size: 1.25em;
	text-transform: capitalize;
`;

function AssetManagerEmbedCode({ item }: AssetManagerItemEmbedsProps) {
	return (
		<>
			<TypeIcon type={AM_TYPE_EMBED_CODE} />
			<Content $isProminent={true}>
				<EmbedHeadline>
					{item.service}
					-Embed
				</EmbedHeadline>
				<p>{item.altText}</p>
			</Content>
		</>
	);
}

export default AssetManagerEmbedCode;
