/**
 * @generated SignedSource<<35e6c2b36f361d624a31c4c98180b136>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NavigationType = "ARTICLE" | "BOARD" | "CUSTOM" | "NAVIGATION";
export type DeleteNavigationByRowIdInput = {
  clientMutationId?: string | null;
  rowId: number;
};
export type NavigationDeleteMutation$variables = {
  input: DeleteNavigationByRowIdInput;
};
export type NavigationDeleteMutation$data = {
  readonly deleteNavigationByRowId: {
    readonly navigation: {
      readonly articleByArticle: {
        readonly rowId: string;
        readonly title: string;
      } | null;
      readonly boardByBoard: {
        readonly rowId: string;
        readonly title: string;
      } | null;
      readonly color: string | null;
      readonly id: string;
      readonly order: number;
      readonly parent: number | null;
      readonly path: string | null;
      readonly rowId: number;
      readonly text: string | null;
      readonly type: NavigationType;
    } | null;
  } | null;
};
export type NavigationDeleteMutation = {
  response: NavigationDeleteMutation$data;
  variables: NavigationDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parent",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  (v3/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v12 = [
  (v8/*: any*/),
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteNavigationPayload",
        "kind": "LinkedField",
        "name": "deleteNavigationByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Navigation",
            "kind": "LinkedField",
            "name": "navigation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Board",
                "kind": "LinkedField",
                "name": "boardByBoard",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "articleByArticle",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavigationDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteNavigationPayload",
        "kind": "LinkedField",
        "name": "deleteNavigationByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Navigation",
            "kind": "LinkedField",
            "name": "navigation",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Board",
                "kind": "LinkedField",
                "name": "boardByBoard",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "articleByArticle",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "678daa38be97b8f35a2fedaf55fe530d",
    "id": null,
    "metadata": {},
    "name": "NavigationDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation NavigationDeleteMutation(\n  $input: DeleteNavigationByRowIdInput!\n) {\n  deleteNavigationByRowId(input: $input) {\n    navigation {\n      id\n      rowId\n      type\n      parent\n      order\n      text\n      boardByBoard {\n        title\n        rowId\n        id\n      }\n      articleByArticle {\n        title\n        rowId\n        id\n      }\n      path\n      color\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b734ddff65f82486bcbe5c58988a57ce";

export default node;
