import { UploadOutlined } from '@ant-design/icons';
import { LS_READ_ONLY_MODE } from '@sep/br24-constants';
import {
	notification,
	Alert,
	Row,
	Col,
	Dropdown,
	Button,
	Form,
	Input,
	Upload,
	type UploadFile,
} from 'antd';
import type { UploadChangeParam } from 'antd/lib/upload';
import classNames from 'classnames';
import { debounce } from 'lodash-es';
import { Component, type ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import type { ConnectedProps } from 'react-redux';
import { createRefetchContainer, graphql, type RelayRefetchProp } from 'react-relay';
import { type RouteComponentProps, withRouter } from 'react-router';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';
import {
	ImageCropperModal,
	type ImageCropperModalResultHandler,
} from '@/client/ui/ImageCropper/ImageCropperModal';
import { LockHandlingBoardTeaser } from '@/client/util/lockHandler/LockHandling';
import type { ModuleImage } from '@/types/schema';

import { UserContainer } from '../../auth/AuthContext';
import config from '../../config';
import routeConfig from '../../config/config.routes';
import environment from '../../environment';
import UpdateBoardsTeaserMutation from '../../mutations/UpdateBoardsTeaserMutation';
import * as actions from '../../store/reducers/boardsTeaserBuffer';
import type { BoardsTeaserBuffer } from '../../store/reducers/boardsTeaserBuffer';
import globalStyles from '../../styles';
import type { WithTranslationProps } from '../../translation';
import ReadOnlyBar from '../../ui/ReadOnlyBar';
import Card from '../Card';
import { LockedByAuthorBoardsTeaser } from '../LockedByAuthor';
import { Page } from '../Page';

import styles from './BoardsTeaser.module.scss';
import DeleteModalContainer from './DeleteModalContainer';
import type { BoardsTeaser_boardsTeaser$data } from './__generated__/BoardsTeaser_boardsTeaser.graphql';
import { validator, getHumanReadableErrors } from './util';

const { TextArea } = Input;

const STATUS_DELETED = 'DELETED';

interface Props extends WithTranslationProps, OwnProps, ReduxProps, RouteComponentProps {
	boardsTeaser: BoardsTeaser_boardsTeaser$data;
	relay: RelayRefetchProp;
	autoSave?: boolean;
}

type State = {
	error: any | undefined | null;
	isSaving: boolean;
	isImageEditorVisible: boolean;
	showDeleteModal: boolean;
	url: string | undefined | null;
	label: string | null | undefined;
	uploadStatus: number | undefined | null;
};

function getCleanBoardTeaser({
	description,
	image,
	link,
	rowId,
	status,
	title,
}: BoardsTeaser_boardsTeaser$data) {
	return { description, image, link, rowId, status, title };
}

export class BoardsTeaser extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		if (props.autoSave) {
			this.registerAutoSaver();
		}
	}

	state = {
		error: null,
		isSaving: false,
		isImageEditorVisible: false,
		showDeleteModal: false,
		url: this.props.boardsTeaser?.link?.url,
		label: this.props.boardsTeaser?.link?.label,
		uploadStatus: null,
	};

	componentDidMount() {
		const { initializeBuffer, boardsTeaser } = this.props;

		initializeBuffer(getCleanBoardTeaser(boardsTeaser));

		document.addEventListener('keydown', this.handleKeyboardEvent);
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (
			this.props.buffer &&
			nextProps.buffer &&
			this.props.buffer.status === nextProps.buffer.status
		) {
			this.setState({ error: null });
		}
		const { boardsTeaser, initializeBuffer } = this.props;
		if (boardsTeaser !== nextProps.boardsTeaser) {
			initializeBuffer(getCleanBoardTeaser(nextProps.boardsTeaser));
		}
		if (nextProps.autoSave) {
			this.registerAutoSaver();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyboardEvent);
		this.clearPreviousAutoSaver();
		this.debounceRef.cancel();
	}

	autoSaveTimeout: any = null;

	captureChange = (fieldName: string, value: any): Promise<any> => {
		this.props.updateBuffer({ [fieldName]: value });
		return new Promise((resolve) => setTimeout(resolve, 300));
	};

	captureLinkChange = () => {
		const linkData = { url: this.state.url, label: this.state.label };
		this.captureChange('link', linkData);
	};

	clearPreviousAutoSaver = () => {
		if (!this.autoSaveTimeout) {
			return;
		}
		clearTimeout(this.autoSaveTimeout);
		this.autoSaveTimeout = null;
	};

	handleChangeImageUpload = (info: UploadChangeParam<UploadFile<any>>) => {
		const {
			translation: { translate },
		} = this.props;

		const { event } = info;
		const { status } = info.file;

		switch (status) {
			case 'uploading':
				this.setState({ uploadStatus: event ? event.percent : 1 });
				break;

			case 'error':
				notification.error({
					message: translate('boardsTeaser.action.image.upload.error.message'),
					description: translate('boardsTeaser.action.image.upload.error.description'),
					duration: 0,
				});
				this.setState({ uploadStatus: null });
				break;

			case 'done':
				{
					const { url } = info.file.response;
					const { copyright, title, altText } = info.file.response;

					const uploadedImage = {
						url,
						title: title || null,
						copyright: copyright || null,
						altText: altText || null,
					};
					this.captureChange('image', uploadedImage);
				}
				break;

			default:
				break;
		}
	};
	handleDeleteModalClose = () => {
		this.setState({ showDeleteModal: false });
	};

	handleDeleteModalOk = (key: string) => {
		if (key === 'NOT_ON_BOARD') {
			this.captureChange('status', STATUS_DELETED).then(this.handleSave);
			this.setState({ showDeleteModal: false });
		} else {
			this.setState({ showDeleteModal: false });
		}
	};

	handleImageClick = () => {
		console.log('handleImageClick');
		this.setState({ isImageEditorVisible: true });
	};

	handleImageDelete = () => {
		this.captureChange('image', null);
	};

	handleImageEditorCancel = () => {
		this.setState({ isImageEditorVisible: false });
	};

	handleImageEditorOk: ImageCropperModalResultHandler = (url, meta): void => {
		const image: ModuleImage = {
			url,
			altText: meta?.description,
			copyright: meta?.copyright,
			title: meta?.title,
		};

		this.captureChange('image', image);
		this.setState({ isImageEditorVisible: false });
	};

	handleKeyboardEvent = (event: KeyboardEvent) => {
		if ((event.metaKey || event.ctrlKey) && event.which === 83) {
			window.focus();
			if (document.activeElement && typeof document.activeElement['blur'] === 'function') {
				document.activeElement['blur']();
			}
			event.preventDefault();
			if (this.keyboardEventTimeout) {
				clearTimeout(this.keyboardEventTimeout);
				this.keyboardEventTimeout = null;
			}
			this.keyboardEventTimeout = setTimeout(() => this.handleSave(), 500);
		}
	};

	handleLinkChange = (key: string, value: any) => {
		if (key === 'url') {
			this.setState({ url: value }, () => {
				this.captureLinkChange();
			});
		}
		if (key === 'label') {
			this.setState({ label: value === '' ? null : value }, () => {
				this.captureLinkChange();
			});
		}
	};

	handleMenuClick = ({ key }: { key: string }) => {
		if (key === STATUS_DELETED) {
			this.setState({ showDeleteModal: true });
		} else {
			this.handleSave();
		}
	};

	handleSave = (isAutoSave?: boolean) => {
		const { isSaving } = this.state;
		const {
			translation: { translate },
			lockStatus: { mode },
		} = this.props;

		if (isSaving) {
			return;
		}

		if (mode !== LS_READ_ONLY_MODE) {
			this.setState({ isSaving: true }, async () => {
				const {
					boardsTeaser: { rowId, title, status },
				} = this.props;
				let { buffer } = this.props;

				const validationState = validator(buffer);

				if (validationState.error) {
					this.setState({ error: getHumanReadableErrors(validationState), isSaving: false });
					return;
				}

				buffer = validationState.value;
				this.setState({ error: null });

				const { ...boardsTeaserPatch } = buffer;

				UpdateBoardsTeaserMutation(rowId, boardsTeaserPatch, environment)
					.then(() => {
						this.registerAutoSaver(true);
						this.props.relay.refetch(
							() => ({ boardsTeaserID: rowId }),
							null,
							() => {
								this.setState({
									isSaving: false,
								});
							}
						);

						const statusChanged = status !== buffer.status;
						if (statusChanged && buffer.status === STATUS_DELETED) {
							this.props.history.push(routeConfig.boardsTeasers);
						} else if (isAutoSave) {
							notification.success({
								message: translate('boardsTeaser.action.save.auto.message'),
								description: translate('boardsTeaser.action.save.auto.description', { title }),
								duration: 3,
							});
						} else {
							notification.success({
								message: translate('boardsTeaser.action.save.manual.message'),
								description: translate('boardsTeaser.action.save.manual.description', { title }),
								duration: 3,
							});
						}
					})
					.catch((err: any) => {
						notification.error({
							message: translate('boardsTeaser.action.save.error.message'),
							description: translate(`boardsTeaser.action.save.error.description: ${err}`),
							duration: 0,
						});
						this.setState({ isSaving: false });
					});
			});
		}
	};

	keyboardEventTimeout: any = null;

	/**
	 * Performs a refetch of the data and (re)initializes the document.
	 * If a callback is given the callback will be executed afterwards.
	 *
	 * The refetchDocument method will be executed in the HOC withLockHandlingForEditor.
	 */
	refetchDocument = (callback?: () => void) => {
		const {
			boardsTeaser: { rowId },
		} = this.props;

		this.props.relay.refetch(
			() => ({ boardsTeaserID: rowId }),
			null,
			() => {
				// Why are url and label in the state anyway?
				this.setState({
					url: this.props.boardsTeaser?.link?.url,
					label: this.props.boardsTeaser?.link?.label,
				});

				typeof callback === 'function' && callback();
			}
		);
	};

	debounceRef = debounce(() => this.handleSave(true), 500);

	registerAutoSaver = (clearPrevious = false) => {
		const { autoSave } = this.props;

		if (!autoSave) {
			return;
		}
		if (clearPrevious) {
			this.clearPreviousAutoSaver();
		}
		if (this.autoSaveTimeout) {
			return;
		}

		this.autoSaveTimeout = setTimeout(() => {
			window.focus();
			if (document.activeElement && document.activeElement['blur']) {
				(document.activeElement as any).blur();
			}

			this.debounceRef();
		}, 60 * 1000);
	};

	render() {
		const {
			translation: { translate },
		} = this.props;
		const {
			buffer,
			lockStatus: { mode },
			boardsTeaser,
		} = this.props;
		const { isSaving, isImageEditorVisible, showDeleteModal } = this.state;

		if (!buffer) {
			return <span>{translate('load')}</span>;
		}

		const imageWithUrl = buffer.image != null;
		const { image } = buffer as unknown as { image: ModuleImage };

		let deleteModal: ReactElement | null = null;

		if (showDeleteModal) {
			deleteModal = (
				<DeleteModalContainer
					boardsTeaserId={buffer.rowId}
					onOk={this.handleDeleteModalOk}
					onCancel={this.handleDeleteModalClose}
				/>
			);
		}

		return (
			<Page className={styles.page}>
				<Helmet>
					<title>{translate('boardsTeaser.pageTitle', { title: buffer.title })}</title>
				</Helmet>

				{mode === LS_READ_ONLY_MODE ? (
					<ReadOnlyBar />
				) : (
					<Row className={styles.statusBar}>
						<Col span="2">
							<LockedByAuthorBoardsTeaser boardsTeaser={boardsTeaser} />
						</Col>
						<Col offset="20" span="2">
							<Dropdown.Button
								type="primary"
								onClick={() => this.handleSave()}
								menu={{
									items: [
										{
											label: translate('boardsTeaser.dropDownMenu.delete'),
											key: STATUS_DELETED,
										},
									],
									onClick: this.handleMenuClick,
								}}
								disabled={isSaving}
							>
								{translate('boardsTeaser.dropDownMenu.save')}
							</Dropdown.Button>
						</Col>
					</Row>
				)}

				{this.state.error && (
					<Alert
						type="error"
						message={translate('boardsTeaser.action.save.error.message')}
						description={this.state.error}
						className={styles.errorBanner}
						banner={true}
					/>
				)}

				<LockHandlingBoardTeaser
					boardsTeaser={this.props.boardsTeaser}
					refetchDocument={this.refetchDocument}
				/>

				<Page.Content className={styles.content}>
					<Row>
						<Col span={24} className={globalStyles.pM}>
							<Form.Item
								validateStatus={buffer.title.length > 64 ? 'error' : 'success'}
								help={`${buffer.title.length}/64 ${translate('boardsTeaser.formItemHelp')}`}
								className={styles.title}
							>
								<Input
									onChange={(event) => this.captureChange('title', event.target.value)}
									size="large"
									placeholder="Titel"
									value={buffer.title || ''}
								/>
							</Form.Item>
							<Form.Item
								validateStatus={(buffer.description?.length ?? 0) > 250 ? 'error' : 'success'}
								help={`${buffer.description?.length ?? 0}/250 ${translate(
									'boardsTeaser.formItemHelp'
								)}`}
								className={styles.teaserText}
							>
								<TextArea
									onChange={(event) => this.captureChange('description', event.target.value)}
									placeholder="Description"
									rows={3}
									value={buffer.description ?? ''}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24} className={globalStyles.pM}>
							<label className={styles.linklabel}>Link</label>
							<Form>
								<Form.Item>
									<Input
										placeholder="URL"
										value={this.state.url ?? ''}
										onChange={(event) => this.handleLinkChange('url', event.target.value)}
									/>
								</Form.Item>
								<Form.Item
									help={`${buffer.link?.label ? buffer.link.label.length : 0}/64 ${translate(
										'boardsTeaser.formItemHelp'
									)}`}
									validateStatus={(buffer.link?.label?.length ?? 0) > 64 ? 'error' : 'success'}
								>
									<Input
										placeholder="Linktext (optional)"
										value={this.state.label ?? ''}
										onChange={(event) => this.handleLinkChange('label', event.target.value)}
									/>
								</Form.Item>
							</Form>
						</Col>
					</Row>
					<Row>
						<Col span={24} className={globalStyles.pM}>
							{imageWithUrl && (
								<Card
									title={translate('boardsTeaser.image.title')}
									closable={true}
									onClose={() => this.handleImageDelete()}
									confirmClose={translate('boardsTeaser.image.confirmDelete')}
								>
									<div onClick={this.handleImageClick}>
										<img alt={image.title ?? undefined} width="100%" src={image.url ?? undefined} />
									</div>
									<div onClick={this.handleImageClick}>
										<h3>{image.title}</h3>
										<p>{image.altText}</p>
										<p>Copyright: {image.copyright}</p>
									</div>
								</Card>
							)}
							<UserContainer>
								{() => (
									<Upload
										action={`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`}
										multiple={false}
										accept={['image/jpg', 'image/jpeg', 'image/png'].join(',')}
										showUploadList={false}
										onChange={(info) => this.handleChangeImageUpload(info)}
									>
										<Button
											icon={<UploadOutlined />}
											className={classNames(styles.uploadButton, {
												[globalStyles.fullWidth]: true,
												[styles.buttonSingle]: true,
											})}
											type="primary"
											ghost={true}
										>
											{!imageWithUrl
												? translate('boardsTeaser.image.upload.upload')
												: translate('boardsTeaser.image.upload.replace')}
										</Button>
									</Upload>
								)}
							</UserContainer>
						</Col>
					</Row>
				</Page.Content>

				{isImageEditorVisible && image.url && (
					<ImageCropperModal
						src={image.url}
						meta={{
							copyright: image.copyright ?? undefined,
							title: image.title ?? undefined,
							description: image.altText ?? undefined,
						}}
						onConfirm={this.handleImageEditorOk}
						onCancel={this.handleImageEditorCancel}
					/>
				)}

				{deleteModal}
			</Page>
		);
	}
}

const BoardsTeaserCompose = withRouter(withTranslation(BoardsTeaser));

const connector = connect(
	(state: ReduxState, { id }: OwnProps) => ({
		buffer: state.boardsTeaserBuffer[id] || null,

		lockStatus: state.lockStatus,
	}),
	(dispatch, { id }) => ({
		updateBuffer: (data: Partial<BoardsTeaserBuffer>) => dispatch(actions.updateBuffer(id, data)),
		initializeBuffer: (data: BoardsTeaserBuffer) => dispatch(actions.initializeBuffer(id, data)),
	})
);

type ReduxProps = ConnectedProps<typeof connector>;

interface OwnProps {
	id: string;
}

const BoardsTeaserRedux = connector(BoardsTeaserCompose);

export default createRefetchContainer(
	BoardsTeaserRedux,
	{
		boardsTeaser: graphql`
			fragment BoardsTeaser_boardsTeaser on BoardsTeaser {
				rowId
				title
				description
				image {
					url
					copyright
					title
					altText
				}
				link {
					url
					label
				}
				status
				...LockedByAuthor_boardsTeaser
				...LockHandling_boardsTeaser
			}
		`,
	},
	graphql`
		query BoardsTeaserRefetchQuery($boardsTeaserID: String!) {
			boardsTeaser: boardsTeaserByRowId(rowId: $boardsTeaserID) {
				...BoardsTeaser_boardsTeaser
				rowId
			}
		}
	`
);
