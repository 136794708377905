import type { AppThunkAction } from '@/client/store/redux';

import type { RemoveGlobalGroupAction, RemoveGlobalGroupsOptions } from '../..';
import { REMOVE_GLOBAL_GROUP } from '../../actionTypes';
import * as database from '../../database';

const removeGlobalGroup =
	(
		groupId: string,
		{ persist = true }: Partial<RemoveGlobalGroupsOptions> = {}
	): AppThunkAction<RemoveGlobalGroupAction> =>
	async (dispatch) => {
		if (persist) {
			await database.removeGlobalGroup(groupId);
		}

		dispatch({
			type: REMOVE_GLOBAL_GROUP,
			payload: {
				id: groupId,
			},
		});
	};

export default removeGlobalGroup;
