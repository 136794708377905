import { type ComponentType, useEffect } from 'react';

import { LockSubscriptionHandler } from '../util/lockHandler/SubscriptionHandler';
import type { SubscriptionHandlerattachClientToLockSubscription$data } from '../util/lockHandler/__generated__/SubscriptionHandlerattachClientToLockSubscription.graphql';
import { getDisplayName } from '../util/react';

export type LockSubscriptionEntityType = 'Article' | 'Board' | 'BoardTeaser';
export type LockSubscriptionData =
	| SubscriptionHandlerattachClientToLockSubscription$data['onLockUpdate']
	| undefined
	| null;

type UseLockOptions = {
	entityType: LockSubscriptionEntityType;
	onUpdate?: (data: LockSubscriptionData) => void;
};

/**
 * Starts a subscription on lock updates for the specified `entityType`
 *
 */
function useLock({ entityType, onUpdate }: UseLockOptions) {
	useEffect(() => {
		const lockSubscription = new LockSubscriptionHandler();
		lockSubscription.subscribe(
			(data) => {
				if (data?.onLockUpdate?.type === entityType) {
					onUpdate?.(data?.onLockUpdate);
				}
			},
			(error: Error) => {
				console.error('Error when connecting to graphql lock subscriptions', error);
			}
		);

		return () => lockSubscription.unsubscribe();
	}, [entityType, onUpdate]);
}

export function withLock<P>(
	WrappedComponent: ComponentType<P>,
	{ entityType }: Omit<UseLockOptions, 'onUpdate'>
) {
	// Creating the inner component. The calculated Props type here is the where the magic happens.
	function ComponentWithLock(props: P) {
		useLock({ entityType });

		return <WrappedComponent {...(props as any)} />;
	}

	// Try to create a nice displayName for React Dev Tools.
	ComponentWithLock.displayName = `withLock(${getDisplayName(WrappedComponent)})`;

	return ComponentWithLock;
}

export function WithLockHandler(lockOptions: UseLockOptions) {
	useLock(lockOptions);

	return null;
}
