import { faDownload as DownloadIcon } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faTimes as CloseIcon } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MODULE_TYPE_IMAGE } from '@sep/br24-constants';
import { Button } from 'antd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { media } from '../../../../util/styled';

const OverlayWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
`;

const OverlayInnerWrapper = styled.div`
	color: #fff;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: absolute;
`;

const CloseIconWrapper = styled.div`
	position: absolute;
	top: 1.4rem;
	right: 1.4rem;
	cursor: pointer;
`;

const CloseButton = styled.h3`
	color: #fff;
	font-size: 1.3rem;
	text-decoration: underline;
	margin-top: 1rem;
	cursor: pointer;
`;

const DownloadButton = styled(Button)`
	font-size: ${(props) => props.theme.fontSizeModuleContent} !important;
	transition: none !important;

	box-shadow: 0 0 5px ${(props) => props.theme.colors.blackShadowModuleEditorButton} !important;
	min-width: 240px;
	margin: 0.5rem 0;

	${media.greaterThan('monster')`
		padding: 10px 25px 35px 25px !important;
	`};
`;

const DescriptionLabel = styled.h3`
	color: #fff;
	font-weight: 500;

	> svg {
		margin-right: 0.5rem;
	}

	${media.greaterThan('monster')`
		font-size: 1.5rem;
		margin-bottom: 1.2rem;
	`};
`;

const getOriginalImageURL = (urlString: string) => {
	if (!urlString) {
		return null;
	}

	const url = new URL(urlString);

	url.searchParams.delete('rect');
	url.searchParams.delete('w');
	url.searchParams.delete('h');

	return url.href;
};

const getNewsletterImageURL = (urlString: string) => {
	if (!urlString) {
		return null;
	}

	const url = new URL(urlString);

	url.searchParams.set('w', '700');
	url.searchParams.set('h', '394');

	return url.href;
};

const getIsCropped = (urlString: string) => {
	if (!urlString) {
		return false;
	}

	const url = new URL(urlString);
	return url.searchParams.has('rect');
};

type Props = {
	url: string;
	onCloseClick: () => void;
};

export default function ArticleModuleImageOverlay({ url, onCloseClick }: Props) {
	const translate = useTranslate();

	const isCroppedImage = getIsCropped(url);
	const downloadCropped = url;
	const downloadOriginal = getOriginalImageURL(url);
	const downloadNewsletter = getNewsletterImageURL(url);

	return (
		<OverlayWrapper>
			<OverlayInnerWrapper>
				<CloseIconWrapper onClick={onCloseClick}>
					<FontAwesomeIcon icon={CloseIcon} size="2x" />
				</CloseIconWrapper>
				<DescriptionLabel>
					<FontAwesomeIcon icon={DownloadIcon} size="lg" />{' '}
					{translate(`modules.${MODULE_TYPE_IMAGE}.selectDownloadLabel`)}
				</DescriptionLabel>

				{downloadCropped && (
					<DownloadButton
						type="primary"
						href={downloadCropped}
						target="_blank"
						disabled={!isCroppedImage}
					>
						{translate(`modules.${MODULE_TYPE_IMAGE}.downloadCroppedImage`)}
					</DownloadButton>
				)}
				{downloadOriginal && (
					<DownloadButton type="primary" href={downloadOriginal} target="_blank">
						{translate(`modules.${MODULE_TYPE_IMAGE}.downloadOriginal`)}
					</DownloadButton>
				)}
				{downloadNewsletter && (
					<DownloadButton
						type="primary"
						href={downloadNewsletter}
						target="_blank"
						disabled={!isCroppedImage}
					>
						{translate(`modules.${MODULE_TYPE_IMAGE}.downloadNewsletter`)}
					</DownloadButton>
				)}
				<CloseButton onClick={onCloseClick}>
					{translate(`modules.${MODULE_TYPE_IMAGE}.downloadDone`)}
				</CloseButton>
			</OverlayInnerWrapper>
		</OverlayWrapper>
	);
}
