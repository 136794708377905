import { faCommentAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import styled from 'styled-components';

import config from '../../../config';

type Props = {
	tooltip: string;
	isDisabled: boolean;
};

type StatusProps = {
	isDisabled: boolean;
};

const getButtonStatusColor = (props: any) => {
	if (props.isDisabled) {
		return props.theme.colors.lightAntd;
	}

	return config.statusTagColorMap.DRAFT;
};

const Status = styled('div')<StatusProps>`
	padding: 5px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover,
	&:focus {
		filter: ${(props: StatusProps) => (props.isDisabled ? 'none' : 'brightness(115%)')};
	}
	&:active {
		filter: brightness(85%);
	}
	background-color: ${(props: StatusProps) => getButtonStatusColor(props)} !important;
	border-color: ${(props: StatusProps) => getButtonStatusColor(props)} !important;
	> svg {
		color: #fff;
	}
`;

export const ArticleStatusNotes = ({ tooltip, isDisabled }: Props) => (
	<Tooltip title={!isDisabled ? tooltip : null} placement="bottom">
		<Status isDisabled={isDisabled}>
			<FontAwesomeIcon icon={faCommentAlt} />
		</Status>
	</Tooltip>
);
