/*

************************************************************************

HOW TO HANDLE SEO TITLE / SLUG

Artikel initial anlegen:
- SEO Titel ist leer
- Slug ist analog dem Artikel-Titel

Variante 1 - SEO Titel wird gesetzt
- SEO Title: je nach Eingabe
- Slug = neuer SEO Titel
- Änderungen am Artikel Titel haben keine Auswirkungen

Variante 2 - SEO Titel ist gesetzt / Artikel Titel wird geändert (vor Publikation)
- Keine Änderung von SEO Titel oder Slug -> bleibt wie eingegeben

Variante 3 - SEO Titel ist leer / Artikel Titel wird geändert (vor Publikation)
- Slug ändert sich analog zur Eingabe beim Artikel Titel
- SEO Titel bleibt unberührt

Variante 4 - Artikel Titel wird geändert (nach Publikation)
- Nur Titel ändert sich sonst nichts

************************************************************************

*/

import { Form, Input, Row, Col, Tooltip, type InputRef } from 'antd';
import { type ChangeEvent, Component, createRef } from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import config from '../../../config';
import type { WithTranslationProps } from '../../../translation';
import { sanitizeUrl } from '../../../util/urlify';
import ErrorMessageText from '../../ErrorMessageText';
import { Edit } from '../../Icon';
import { ArticleMetaPanel } from '../ArticleMetaPanel';

const TITLE_MAX_LENGTH = config.articleEditor.meta.seo.titleMaxLength;
const DESCRIPTION_MAX_LENGTH = config.articleEditor.meta.seo.descriptionMaxLength;

const { TextArea } = Input;
const FormItem = styled(Form.Item)`
	.ant-form-explain {
		text-align: end;
		font-size: 12px;
	}

	.ant-input-disabled,
	.meta-change-seotitle-icon > svg {
		cursor: pointer !important;
	}

	.meta-input-slug {
		cursor: unset !important;
	}
`;

type State = {
	isSeoTitleLocked: boolean;
};

type Props = WithTranslationProps & {
	articleTitle?: string | null;
	seoDescription: string | undefined | null;
	seoSlug: string | undefined | null;
	seoTitle: string | undefined | null;
	canonicalUrl?: string;
	validation: any;
	onChangeDescription: (description: string) => void;
	onChangeSeoTitle: (seoTitle: string) => void;
	onChangeSlug: (seoTitle: string) => void;
	onChangeCanonicalUrl: (url: string) => void;
	key: string | number;
};

class ArticleMetaSEO extends Component<Props, State> {
	state = {
		isSeoTitleLocked: !this.props.seoTitle,
	};

	seoTitleRef = createRef<InputRef>();

	handleChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
		this.props.onChangeDescription(event.target.value);
	};

	handleChangeSeoTitle = (event: SyntheticEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		this.props.onChangeSeoTitle(target.value);
	};

	handleChangeSlug = (event: SyntheticEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		this.props.onChangeSlug(sanitizeUrl(target.value));
	};

	handleChangeCanonicalUrl = (event: SyntheticEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		this.props.onChangeCanonicalUrl(target.value);
	};

	handleClickSeoTitle = () => {
		if (this.state.isSeoTitleLocked) {
			this.setState(
				{
					isSeoTitleLocked: false,
				},
				() => {
					// eslint-disable-next-line
					this.seoTitleRef && this.seoTitleRef.current && this.seoTitleRef.current.focus();
				}
			);
		}
	};

	render() {
		const {
			articleTitle,
			seoDescription,
			seoSlug,
			seoTitle,
			validation,
			canonicalUrl,
			translation: { translate },
			onChangeDescription,
			onChangeSeoTitle,
			onChangeCanonicalUrl,
			onChangeSlug,
			...rest
		} = this.props;

		const { isSeoTitleLocked } = this.state;

		const description = seoDescription;
		const slug = seoSlug;

		return (
			<ArticleMetaPanel
				help={translate('article.meta.seo.help')}
				isRequired={false}
				title={translate('article.meta.seo.title')}
				{...rest}
			>
				<span onClick={this.handleClickSeoTitle}>
					<FormItem
						help={translate('article.meta.seo.characterCountText', {
							currentCount: seoTitle ? `${seoTitle.length}` : 0,
							characterMaxLength: TITLE_MAX_LENGTH,
						})}
						validateStatus={seoTitle && seoTitle.length > TITLE_MAX_LENGTH ? 'error' : 'success'}
					>
						<Row gutter={8}>
							<Col span={22}>
								<Input
									name="seoTitle"
									disabled={isSeoTitleLocked}
									value={(isSeoTitleLocked ? articleTitle : seoTitle) ?? undefined}
									placeholder={translate('article.meta.seo.titlePlaceholder')}
									onChange={this.handleChangeSeoTitle}
									ref={this.seoTitleRef}
								/>
							</Col>
							<Col span={2}>
								<span className="meta-change-seotitle-icon">
									<Tooltip title={translate('article.meta.seo.seoTitleEditTooltip')}>
										<Edit onClick={this.handleClickSeoTitle} />
									</Tooltip>
								</span>
							</Col>
						</Row>
					</FormItem>
				</span>
				<ErrorMessageText
					text={validation && validation.seoTitle ? validation.seoTitle : null}
					hasBottomMargin={true}
				/>
				<FormItem validateStatus={validation && validation.slug ? 'error' : 'success'}>
					<Input
						name="slug"
						className="meta-input-slug"
						value={slug || ''}
						disabled={true}
						placeholder={translate('article.meta.seo.slugPlaceholder')}
						onChange={this.handleChangeSlug}
					/>
				</FormItem>
				<ErrorMessageText
					text={validation && validation.slug ? validation.slug : null}
					hasBottomMargin={true}
				/>
				<FormItem
					help={translate('article.meta.seo.characterCountText', {
						currentCount: description ? `${description.length}` : 0,
						characterMaxLength: DESCRIPTION_MAX_LENGTH,
					})}
					validateStatus={
						description && description.length > DESCRIPTION_MAX_LENGTH ? 'error' : 'success'
					}
				>
					<TextArea
						name="description"
						onChange={this.handleChangeDescription}
						placeholder={translate('article.meta.seo.descPlaceholder')}
						rows={6}
						value={description || ''}
					/>
				</FormItem>
				<ErrorMessageText
					text={validation && validation.metaDescription ? validation.metaDescription : null}
					hasBottomMargin={true}
				/>
				<FormItem validateStatus={validation && validation.canonicalUrl ? 'error' : 'success'}>
					<Input
						name="canonicalUrl"
						value={canonicalUrl}
						placeholder={translate('article.meta.seo.canonicalUrlPlaceholder')}
						onChange={this.handleChangeCanonicalUrl}
					/>
				</FormItem>
			</ArticleMetaPanel>
		);
	}
}

export default withTranslation(ArticleMetaSEO);
