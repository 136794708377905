import type { AppThunkAction } from '@/client/store/redux';

import type { CleanImporterAction, Importer } from '..';
import { CLEAN_IMPORTER } from '../actionTypes';

const cleanImporter =
	(importer: Importer): AppThunkAction<CleanImporterAction> =>
	(dispatch, getState) => {
		if (Object.keys(getState().assetManager.imports[importer] || {}).length > 0) {
			dispatch({
				type: CLEAN_IMPORTER,
				payload: importer,
			});
		}
	};

export default cleanImporter;
