import {
	PRIORITY_NORMAL,
	STATUS_DRAFT,
	PUSH_NOTIFICATION_SOUND,
	PUSH_NOTIFICATION_NONE,
	CAPABILITY_FULLY_FEATURED,
	CAPABILITY_BASIC,
	CAPABILITY_TEXT_ONLY,
	EXPIRATION_ALL,
	EXPIRATION_SUPPORTING_INFO,
} from '@sep/br24-constants';
import { commitMutation, type Environment, graphql } from 'react-relay';

import type {
	CreateArticleMutation,
	Expiration,
	IntervalInput,
	PushNotification,
	ArticleInput,
} from './__generated__/CreateArticleMutation.graphql';

const mutation = graphql`
	mutation CreateArticleMutation($input: CreateArticleInput!) {
		createArticle(input: $input) {
			article {
				rowId
			}
		}
	}
`;

export function createArticleMutation(
	{
		title,
		slug,
		capability,
		isTemplate,
		primaryCategory,
		revisionBy,
	}: Pick<
		ArticleInput,
		'title' | 'slug' | 'capability' | 'isTemplate' | 'primaryCategory' | 'revisionBy'
	>,
	environment: Environment
): Promise<CreateArticleMutation['response']> {
	let pushNotification: PushNotification | undefined;

	if (capability === CAPABILITY_TEXT_ONLY) {
		pushNotification = PUSH_NOTIFICATION_SOUND;
	} else if (capability === CAPABILITY_BASIC) {
		pushNotification = PUSH_NOTIFICATION_SOUND;
	} else if (capability === CAPABILITY_FULLY_FEATURED) {
		pushNotification = PUSH_NOTIFICATION_NONE;
	}

	let expirationTemplate: Expiration | null = null;
	let expirationIn: IntervalInput | null = null;
	const expirationItem = EXPIRATION_ALL.find(
		(element) => element.id === EXPIRATION_SUPPORTING_INFO
	);
	if (expirationItem && expirationItem.duration !== Infinity) {
		expirationTemplate = EXPIRATION_SUPPORTING_INFO;
		expirationIn = { [expirationItem.units]: expirationItem.duration };
	}

	return new Promise((resolve, reject) => {
		commitMutation<CreateArticleMutation>(environment, {
			mutation,
			variables: {
				input: {
					article: {
						title,
						slug,
						capability,
						primaryCategory,
						revisionBy,
						isTemplate,

						// postgraphql cannot handle nullable enums
						// see: https://github.com/postgraphql/postgraphql/issues/388
						status: STATUS_DRAFT,
						publicationPriority: PRIORITY_NORMAL,
						pushNotification,
						expirationTemplate,
						expirationIn,
						meta: '{"version": 3}',
					},
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
}
