/**
 * @generated SignedSource<<7bdb304c3c2f7694000364b4bf97c9d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmbedModuleEditorContainerQuery$variables = {
  renderImage?: boolean | null;
  service: string;
  source: string;
};
export type EmbedModuleEditorContainerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmbedModuleEditorContainer_query">;
};
export type EmbedModuleEditorContainerQuery = {
  response: EmbedModuleEditorContainerQuery$data;
  variables: EmbedModuleEditorContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "renderImage"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "service"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmbedModuleEditorContainerQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "EmbedModuleEditorContainer_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmbedModuleEditorContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "renderImage",
            "variableName": "renderImage"
          },
          {
            "kind": "Variable",
            "name": "service",
            "variableName": "service"
          },
          {
            "kind": "Variable",
            "name": "source",
            "variableName": "source"
          }
        ],
        "concreteType": "Embed",
        "kind": "LinkedField",
        "name": "embed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "source",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EmbedImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "base64Data",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc5264dc68dc82150006b32088587cd7",
    "id": null,
    "metadata": {},
    "name": "EmbedModuleEditorContainerQuery",
    "operationKind": "query",
    "text": "query EmbedModuleEditorContainerQuery(\n  $service: String!\n  $source: String!\n  $renderImage: Boolean\n) {\n  ...EmbedModuleEditorContainer_query\n}\n\nfragment EmbedModuleEditorContainer_query on Query {\n  embed(service: $service, source: $source, renderImage: $renderImage) {\n    service\n    url\n    source\n    image {\n      url\n      base64Data\n      width\n      height\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6379b7adcc41b5147f0df84f3555698f";

export default node;
