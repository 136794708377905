import type {
	TAG_STATUS_ACTIVE,
	TAG_STATUS_RECOMMENDED,
	TAG_STATUS_SUPERSEDED,
	TAG_STATUS_SPECIAL,
} from '@sep/br24-constants';
import { Tag } from 'antd';
import styled from 'styled-components';

import buildGeoTagsFilter from '../../../util/buildGeoTagsFilter';

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
`;

const StyledTag = styled(Tag)`
	margin-bottom: 8px !important;
	background-color: ${(props) => props.theme.colors.light1} !important;
`;

type TagStatus =
	| typeof TAG_STATUS_ACTIVE
	| typeof TAG_STATUS_RECOMMENDED
	| typeof TAG_STATUS_SUPERSEDED
	| typeof TAG_STATUS_SPECIAL;

export type TagItem = {
	id: number | string;
	text: string;
	status: TagStatus;
};

type Props = {
	onRemove: (id: number | string) => void;
	tags: Array<TagItem>;
};

function ArticleMetaTagsItems({ tags, onRemove }: Props) {
	const geoTagFilter = buildGeoTagsFilter();

	const renderTag = (item: TagItem, index) => {
		// we filter here for geotags (Oberbayern, Oberpfalz etc.) because
		// those tags are automatically added by the Geolocation sections if a Bezirk is available
		// but it was agreed with the Redaktion that we don't display them in the tags and just in
		// geolocation, details see: https://jira.ard.de/browse/BR24-98
		if (geoTagFilter.textFilter.includes(item.text)) {
			return null;
		}

		return (
			<StyledTag
				key={item && item.id ? item.id : index}
				closable={true}
				onClose={() => onRemove(item.id)}
			>
				{item.text}
			</StyledTag>
		);
	};

	return <Row>{Array.isArray(tags) && tags.length ? tags.map(renderTag) : null}</Row>;
}

export default ArticleMetaTagsItems;
