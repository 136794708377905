/**
 * @generated SignedSource<<3e0a13b9b0795af5d6b147a43d487604>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LockedByAuthor_board$data = {
  readonly authorByLockedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
    readonly profilePicture: string | null;
  } | null;
  readonly lockedSince: string | null;
  readonly " $fragmentType": "LockedByAuthor_board";
};
export type LockedByAuthor_board$key = {
  readonly " $data"?: LockedByAuthor_board$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockedByAuthor_board">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockedByAuthor_board",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authorByLockedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "guid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePicture",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Board",
  "abstractKey": null
};

(node as any).hash = "66675e785c01a895f23be05e26ddea4f";

export default node;
