import { commitMutation, graphql } from 'react-relay';

import environment from '../../../environment';

import type { ArticleEditorDepositHistoryMutation } from './__generated__/ArticleEditorDepositHistoryMutation.graphql';

const mutation = graphql`
	mutation ArticleEditorDepositHistoryMutation($input: CreateHistoryForArticleInput!) {
		createHistoryForArticle(input: $input) {
			history {
				rowId
			}
		}
	}
`;

export default (articleRowId: string): Promise<ArticleEditorDepositHistoryMutation['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<ArticleEditorDepositHistoryMutation>(environment, {
			mutation,
			variables: {
				input: {
					affectedArticleId: articleRowId,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
