import { Button } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import UUID from 'uuid-js';

import {
	SlideTransitionItem,
	SlideTransitionGroup,
} from '../../components/SlideTransition/SlideTransition';
import InfoButton from '../../ui/AssetManager/AssetManagerGroups/AssetManagerInfoButton';
import { ArrowAltLeft, Lock } from '../../ui/Icon';
import { toAssetManager } from '../ArticleEditor/DragAndDropHandler/dndUtils';

import type { GlobalGroup } from '.';
import AssetManagerSpace from './AssetManagerSpace';
import AssetManagerSpaceGroupList from './AssetManagerSpaceGroupList';

const BackButton = styled(Button)`
	position: relative;
	width: 100%;
	margin-bottom: 10px;
	height: 42px;
	border-radius: 2px;
	min-height: 42px;
	padding: 0 10px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	.title {
		padding: 0 10px;
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
	}
`;

interface Props {
	setActiveGlobalGroup: (id: null | string) => void;
	toggleSubGroups: (payload: string) => void;
	activeGlobalGroupData: GlobalGroup;
	showActiveGlobalGroup: boolean;
	removeAfterDrop: boolean;
	toggleDragIsCopy: (isCopy: boolean) => void;
}

const defaultGroupData = {
	color: 'initial',
	title: '',
	isLocked: false,
};

// TODO: find usages of this component and verify we did not break ID handling
// A default group is a group with an ID, that's not a UUID (e.g. 'rubrikWissen'). We treat those differently
export const isDefaultGroupId = (id: string) => {
	return UUID.fromURN(id) === null;
};

export function AssetManagerGlobalSpace(props: Props) {
	const [{ isOver }, dropTarget] = useDrop(
		() => ({
			accept: toAssetManager,
			collect: (monitor) => ({
				isOver: !!monitor.isOver(),
				canDrop: !!monitor.canDrop(),
			}),
		}),
		[]
	);

	const toggleGroups = useCallback(() => {
		props.setActiveGlobalGroup(null);
	}, [props]);

	const timer = useRef<NodeJS.Timeout | null>(null);

	useEffect(
		function returnToGroupOverview() {
			if (isOver) {
				timer.current = setTimeout(() => {
					toggleGroups();
				}, 800);
			}

			return () => {
				if (timer.current) {
					clearTimeout(timer.current);
				}
			};
		},
		[isOver, toggleGroups]
	);

	const handleToggleSubGroups = (id: string) => {
		props.toggleSubGroups(id);
	};

	const handleShowSpace = (id: string) => {
		props.setActiveGlobalGroup(id);
	};

	const showActiveGlobalGroup = props.showActiveGlobalGroup;

	const { color, title, isLocked, createdAt, createdBy, lastModifiedAt, items, id } = {
		...defaultGroupData,
		...props.activeGlobalGroupData,
	};

	const isDefault = isDefaultGroupId(id);

	const buttonStyles = { backgroundColor: color, borderColor: color };

	return (
		<SlideTransitionGroup>
			<SlideTransitionItem key="space" active={!showActiveGlobalGroup} comeFromWhere="comeFromLeft">
				<AssetManagerSpaceGroupList
					onToggleSubGroups={handleToggleSubGroups}
					onShowSpace={handleShowSpace}
				/>
			</SlideTransitionItem>

			<SlideTransitionItem key="group" active={showActiveGlobalGroup} comeFromWhere="comeFromRight">
				<div>
					<div ref={dropTarget}>
						<BackButton style={buttonStyles} type="primary" onClick={toggleGroups}>
							<ArrowAltLeft />
							<span className="title">
								{isLocked ? <Lock /> : null} {title}
							</span>
							<InfoButton
								isLocked={isLocked}
								lastModifiedAt={lastModifiedAt}
								createdAt={isDefault ? null : createdAt}
								createdBy={isDefault ? null : createdBy}
								itemCount={items && typeof items === 'object' ? Object.keys(items).length : 0}
							/>
						</BackButton>
					</div>
					<AssetManagerSpace
						removeAfterDrop={props.removeAfterDrop}
						toggleDragIsCopy={props.toggleDragIsCopy}
						isGlobalSpace={true}
					/>
				</div>
			</SlideTransitionItem>
		</SlideTransitionGroup>
	);
}
