import { debounce } from 'lodash-es';
import { Component, type ReactNode } from 'react';
import styled from 'styled-components';

const Div = styled(({ finalHeight, innerRef, ...rest }) => <div ref={innerRef} {...rest} />)`
	display: flex;
	flex-direction: column;
	height: ${(props) => props.finalHeight}px;
	max-height: ${(props) => props.finalHeight}px;
	min-height: ${(props) => props.finalHeight}px;
	overflow: hidden;
`;

type Props = {
	children: ReactNode;
	defaultHeight?: number;
};

type State = {
	height: number;
};

export default class MaxHeight extends Component<Props, State> {
	main: HTMLDivElement | undefined | null = null;

	static defaultProps = {
		defaultHeight: 500,
	};

	state = {
		height: 0,
	};

	componentDidMount() {
		this.calcHeight();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		this.debounceRef.cancel();
	}

	calcHeight = () => {
		if (!this.main) {
			return;
		}

		const { height } = this.state;
		const nextHeight = window.innerHeight;

		if (height !== nextHeight) {
			this.setState({ height: nextHeight });
		}
	};

	captureReference = (ref?: HTMLDivElement | null) => {
		this.main = ref;
	};

	handleResize = () => {
		// TODO use requestAnimationFrame
		this.debounceRef();
	};

	debounceRef = debounce(this.calcHeight, 80);

	render() {
		const { children, defaultHeight } = this.props;
		const { height } = this.state;
		const finalHeight = height || defaultHeight;

		return (
			<Div innerRef={this.captureReference} finalHeight={finalHeight}>
				{children}
			</Div>
		);
	}
}
