import { commitMutation, type Environment, graphql } from 'react-relay';

import type { InsertNewAuthorMutation } from './__generated__/InsertNewAuthorMutation.graphql';

type InsertNewAuthorInput = {
	firstname?: string;
	lastname?: string;
	email?: string;
	guid: string;
};

export default (input: InsertNewAuthorInput, environment: Environment): Promise<boolean> => {
	const { firstname, lastname, email, guid } = input;

	return new Promise((resolve, reject) => {
		commitMutation<InsertNewAuthorMutation>(environment, {
			mutation: graphql`
				mutation InsertNewAuthorMutation($input: InsertNewAuthorInput!) {
					insertNewAuthor(input: $input) {
						wasInserted: boolean
					}
				}
			`,
			variables: {
				input: {
					firstname,
					lastname,
					email,
					guid,
				},
			},
			onCompleted: (res) => resolve(res?.insertNewAuthor?.wasInserted ?? false),
			onError: reject,
		});
	});
};
