import {
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
	STATUS_CHANGED,
	COLOR_ASBESTOS,
} from '@sep/br24-constants';
import { Timeline, Spin } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import config from '../../../config';

const LOAD_MORE_NUM = 10;

const SpinLoader = styled(Spin)`
	width: 100%;
`;

const TimelineText = styled.span`
	font-size: 0.85em;
	color: ${COLOR_ASBESTOS};
`;

type HistoryStatusTranslationData = {
	date: string;
	status: string;
	author?: string;
};

type AuthorNameType = {
	firstname?: string | null;
	lastname?: string | null;
	name?: string | null;
} | null;

type HistoryItemStatus =
	| typeof STATUS_DELETED
	| typeof STATUS_DEPUBLISHED
	| typeof STATUS_DRAFT
	| typeof STATUS_PUBLISHED
	| typeof STATUS_REVIEW
	| typeof STATUS_SCHEDULED;

export type HistoryItem = {
	createdAt: string;
	rowId: number;
	data: {
		status: HistoryItemStatus;
	};
	authorByAccomplishedBy: AuthorNameType;
};

export type ItemType = {
	author?: string;
	date: moment.Moment;
	id: number;
	status: HistoryItemStatus;
};

export function historyItemToHistoryTimeline<T extends HistoryItem>(
	item: T,
	getAuthorNameCustom?: (author: AuthorNameType) => string
): ItemType {
	let authorName = item.authorByAccomplishedBy?.name?.trim() || 'Unbekannt';

	if (getAuthorNameCustom) {
		authorName = getAuthorNameCustom(item.authorByAccomplishedBy);
	}

	let data = item.data;

	if (typeof data === 'string') {
		data = JSON.parse(data);
	}

	return {
		id: item.rowId,
		date: moment(item.createdAt),
		author: authorName,
		status: data.status,
	};
}

type Props = {
	hasMore: () => boolean;
	history: Array<ItemType>;
	loadMore: (x: number) => void;
};

export default function HistoryTimeline(props: Props) {
	const translate = useTranslate();

	return (
		<InfiniteScroll
			hasMore={props.hasMore()}
			loader={<SpinLoader key="history-timeline-spinner" size="large" />}
			loadMore={() => props.loadMore(LOAD_MORE_NUM)}
			useWindow={false}
		>
			<Timeline>
				{props.history &&
					props.history.map((item, index) => {
						// the wording needs to be different when changes are made in the current state
						// only the first action should be displayed with the actual action, everything which comes after
						// chould be displayed with "verändert" state
						// more: https://confluence.ard.de/pages/viewpage.action?pageId=244994837

						let prefix = '';
						let translationIndex = '';

						const isAuthorDefined = !!item.author;
						const isFirst = !props.hasMore() && index === props.history.length - 1;
						const isStatusChange = !isFirst && item.status === props.history[index + 1]?.status;

						const author = item.author || translate('article.historyTimeline.author.unknown');

						const date = moment(item.date).format(
							translate('article.historyTimeline.moment.format')
						);

						const translationData: HistoryStatusTranslationData = {
							author,
							status: isStatusChange ? STATUS_CHANGED : item.status,
							date,
						};

						if (isFirst) {
							translationIndex = 'article.historyTimeline.itemFirst';
							prefix = translate('article.historyTimeline.itemFirstWarning', translationData);
						} else if (isAuthorDefined) {
							translationIndex = 'article.historyTimeline.itemWithAuthor';
						} else {
							translationIndex = 'article.historyTimeline.item';
						}

						const text = translate(translationIndex, translationData);

						return (
							<Timeline.Item
								key={`history-dropdown-${item.id}`}
								color={config.statusTagColorMap[item.status]}
								dot={isFirst ? '⚠️' : undefined}
							>
								<TimelineText>
									{!!prefix ? <p>{prefix}</p> : ''}
									{text}
								</TimelineText>
							</Timeline.Item>
						);
					})}
			</Timeline>
		</InfiniteScroll>
	);
}
