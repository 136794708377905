import {
	LS_UPDATE_LOCKSTATUS,
	LS_LOCK_DIALOG_BROKEN_BY_USER,
	LS_READ_ONLY_MODE,
	LS_LOCK_DIALOG_OTHER_AUTHOR,
	LS_LOCK_DIALOG_UPDATED_WHILE_LOCK,
} from '@sep/br24-constants';
import ih from 'immutability-helper';

import type { LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED } from '@/client/util/lockHandler/temporary-cms-constants';

export type LockStatusState = {
	mode:
		| typeof LS_LOCK_DIALOG_BROKEN_BY_USER
		| typeof LS_LOCK_DIALOG_OTHER_AUTHOR
		| typeof LS_LOCK_DIALOG_UPDATED_WHILE_LOCK
		| typeof LS_READ_ONLY_MODE
		| typeof LS_LOCK_DIALOG_ERROR_DOCUMENT_CHANGED
		| null;

	brokenBy:
		| {
				firstname: string;
				lastname: string;
				since: string;
				lastEdited: string;
		  }
		| undefined
		| null;
	userInfo:
		| {
				differentUser: boolean;
				ownUser: boolean;
				noUser: boolean;
		  }
		| undefined
		| null;
};

const initialState: LockStatusState = {
	mode: null,
	brokenBy: null,
	userInfo: null,
};

const lockStatus = (state: LockStatusState = initialState, action: any) => {
	switch (action.type) {
		case LS_UPDATE_LOCKSTATUS:
			return ih(state, {
				$merge: action.payload,
			});

		default:
			return state;
	}
};

export default lockStatus;
