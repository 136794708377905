/**
 * @generated SignedSource<<8f08a439a2d32e4cdfc46f60533bd0ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NavigationType = "ARTICLE" | "BOARD" | "CUSTOM" | "NAVIGATION";
import { FragmentRefs } from "relay-runtime";
export type NavigationList_query$data = {
  readonly allNavigations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly rowId: number;
        readonly text: string | null;
        readonly type: NavigationType;
      } | null;
    }>;
  } | null;
  readonly " $fragmentType": "NavigationList_query";
};
export type NavigationList_query$key = {
  readonly " $data"?: NavigationList_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavigationList_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavigationList_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "type": "NAVIGATION"
          }
        }
      ],
      "concreteType": "NavigationsConnection",
      "kind": "LinkedField",
      "name": "allNavigations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Navigation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "allNavigations(condition:{\"type\":\"NAVIGATION\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "17fc68750d8f172e104738ca910caa56";

export default node;
