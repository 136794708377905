import * as constants from '@sep/br24-constants';

import * as internalConstants from '../../constants';

export default {
	sayHello: 'Hallo {{name}}, Du hast ein neues Material!',
	sayHello_plural: 'Hallo {{name}}, Du hast {{quantity}} neue Materialien!',

	// locales
	german: 'Deutsch',
	english: 'Englisch',
	bavarian: 'Bairisch',

	// navigation items
	navigation: {
		dashboard: 'Dashboard',
		articles: 'Artikel',
		boards: 'Verteilseiten',
		boardsTeasers: 'Linkteaser',
		authors: 'Autoren',
		navigation: 'Navigation',
		comments: 'Kommentare',
		profile: 'Profil',
		settings: 'Einstellungen',
		signOut: 'Abmelden',
	},

	// modules
	modules: {
		remove: 'Bist Du Dir sicher, dass Du dieses Element löschen möchtest?',
		creator: {
			textPlaceholder: 'Schreibe hier...',
		},
		header: {
			titlePlaceholder: 'Titel angeben...',
			titleHelp: '{{count}}/{{max}} Zeichen',
			teasertextPlaceholder: 'Teasertext angeben...',
			teasertextHelp: '{{count}}/{{max}} Zeichen',
		},
		[constants.MODULE_TYPE_IMAGE]: {
			text: 'Bild',
			titleLabel: 'Bildunterschrift',
			titlePlaceholder: 'Bildunterschrift eingeben',
			titleHelp: '{{count}}/{{max}} Zeichen',
			altTextLabel: 'Alternativtext',
			altTextPlaceholder: 'Alternativtext eingeben',
			copyrightLabel: 'Bildrechte',
			copyrightPlaceholder: 'Copyright eingeben',
			editCropButtonLabel: 'Zuschnitt bearbeiten',
			noRectWarning:
				'Dieses Bild besitzt noch keinen Ausschnitt und wird daher in der voller Auflösung angezeigt. Bitte wähle einen geeigneten Ausschnitt!',
			saveRectButtonLabel: 'Ausschnitt übernehmen',
			abortRectButtonLabel: 'Abbrechen',
			dropzoneText: 'Ziehe ein Bild auf diese Fläche.',
			selectDownloadLabel: 'Auswahl zum Downloaden',
			downloadCroppedImage: 'Zuschnitt',
			downloadOriginal: 'Original Bilddatei',
			downloadNewsletter: 'Newsletter (700 x 394px)',
			downloadDone: 'Fertig',
		},
		[constants.MODULE_TYPE_GALLERY]: {
			text: 'Galerie',
			dropzoneText: 'Ziehe eine Sammlung an Bildern auf diese Fläche.',
		},
		[constants.MODULE_TYPE_TEXT]: {
			text: 'Text',
			textPlaceholder: 'Text eingeben',
			linkEditorPlaceholder: 'Link eingeben',
		},
		[constants.MODULE_TYPE_EMBED]: {
			text: 'Embed',
			serviceLabel: 'Plattform',
			servicePlaceholder: 'wird nach Einfügen des Embed Codes automatisch erfasst',
			altTextLabel: 'Alternativtext',
			altTextPlaceholder: 'Alternativtext eingeben',
			previewButtonText: 'Vorschau laden',
			editEmbedText: 'Embed bearbeiten',
			tabNameEmbedCode: 'Code einfügen',
			tabNamePreviewImage: 'Vorschaubild ändern',
			resetPreviewImageButtonText: 'Bild zurücksetzen',
			previewImageNote:
				'Wenn kein Bild hochgeladen wurde, wird automatisch ein Standardbild angezeigt.',
		},
		[constants.MODULE_TYPE_AUDIO]: {
			text: 'Audio',
			titleLabel: 'Audioteaser',
			titlePlaceholder: 'Audioteaser angeben',
			titleHelp: '{{count}}/{{max}} Zeichen',
			copyrightLabel: 'Copyright',
			copyrightPlaceholder: 'Copyright angeben',
			noAudioUrl: 'Keine Audio-URL gefunden',
			dropzoneText: 'Ziehe ein Audio auf diese Fläche.',
		},
		[constants.MODULE_TYPE_VIDEO]: {
			text: 'Video',
			titleLabel: 'Videoteaser',
			titlePlaceholder: 'Videoteaser eingeben',
			titleHelp: '{{count}}/{{max}} Zeichen',
			copyrightLabel: 'Copyright',
			copyrightPlaceholder: 'Copyright eingeben',
			loadVideoButtonLabel: 'Video laden',
			dropzoneText: 'Ziehe ein Video auf diese Fläche.',
		},
		[constants.MODULE_TYPE_LIVESTREAM]: {
			text: 'Livestream',
			titleLabel: 'Titel',
			startTimeLabel: 'Start',
			endTimeLabel: 'Ende',
			dropzoneText: 'Ziehe einen Livestream auf diese Fläche.',
			livestreamNotAvailable:
				'Der eingefügte Livestream ist nicht mehr verfügbar und wird daher nicht mehr angezeigt.',
		},
		[constants.MODULE_TYPE_VIDEO360]: {
			text: '360° Video ( Überholt ) ',
		},
		mixedDropzone: {
			defaultPlaceholder: 'Ziehe ein Video, Audio oder Bilder auf diese Fläche.',
			errorNotAuthenticatedHeadline: 'Authentifizierung fehlgeschlagen',
			errorNotAuthenticatedDesc: 'Bitte logge dich neu im CMS ein.',
			labelFilesAreUploaded: 'Daten werden hochgeladen.',
			labelErrorOnUpload: 'Leider ist ein Fehler aufgetreten.',
			dropIsNotAllowedPlaceholder: 'Das Modul kann an dieser Stelle nicht eingefügt werden.',
		},
		attachedDropzone: {
			title: 'Ziehe das Modul auf dieses Feld.',
		},
		brokenModule: {
			title: 'Kaputtes Modul',
			text: 'Dieses Modul ist leider fehlerhaft. Bitte lösche es und speichere den Artikel danach ab.',
		},
	},

	// dashboard stuff
	dashboard: {
		title: 'Dashboard',
		statistics: 'Statistiken',
		thisMonth: 'Dieser Monat',
		lastMonth: 'Letzter Monat',
		aYearAgo: 'Vor einem Jahr ({{info}})',
		noDataSelectedDateRange: 'Im angegebenen Zeitraum wurden keine Daten gefunden.',
		sourceOriginReferenceStats: 'Artikel mit Sendungsbezug',
		sourceOriginReferenceStatsHelp: 'Verhältnis der Artikel mit/ohne Sendungsbezüge',
		withSourceOrigin: 'Mit Sendungsbezug',
		noSourceOrigin: 'Ohne Sendungsbezug',
		myLatestArticles: 'Meine zuletzt bearbeiteten Artikel',
		moduleAllocationStats: 'Multimediaverhältnis',
		moduleAllocationStatsHelp: 'Multimediaverhältnis von allen Artikeln im angegeben Zeitraum',
		latestPushNotifications: 'Die letzten Push-Notifications',
		latestPublication: 'Zuletzt Publiziert',
		categoryArticleStats: 'Artikel je Kategorie',
		categoryArticleStatsHelp: 'Artikel je Kategorie',
		regionsArticleStats: 'Artikel pro Region',
		regionsArticleStatsHelp: 'Anzahl der Artikel pro Region',
		filter: {
			placeholder: 'Filter auswählen',
			dropdownLabelRegions: 'Regionen',
			dropwdownLabelCategory: 'Rubrik',
			resetFilterText: 'Filter zurücksetzen',
			headlineTotal: 'Gesamt',
			errorText: 'Leider ist ein Fehler aufgetreten.',
		},
		push: {
			withSound: 'Push mit Ton',
			withoutSound: 'Push ohne Ton',
			tagPush: 'Tag-Push',
		},
	},

	board: {
		search: {
			emptySearch: 'Keine Einträge gefunden!',
		},
		sectionItem: {
			article: {
				published: 'Publiziert ',
			},
			board: {
				updated: 'Aktualisiert ',
			},
			boardsTeaser: {
				updated: 'Aktualisiert ',
				errorDescription: 'Der Linkteaser {{title}} konnte nicht geladen werden',
			},
		},
		publicationStatusPicker: {
			publish: 'Veröffentlichen',
			changeToDraft: 'In Entwurf umwandeln',
			depublish: 'Depublizieren',
		},
		saveButton: {
			save: 'Speichern',
			locked: 'Das Speichern von Änderungen ist durch einen anderen User gesperrt',
		},
	},

	boardsTeaser: {
		pageTitle: 'Linkteaser {{title}} bearbeiten',
		dropDownMenu: {
			save: 'Speichern',
			delete: 'Linkteaser löschen',
		},
		formItemHelp: 'Zeichen',
		action: {
			save: {
				auto: {
					message: 'Automatisch gespeichert',
					description: 'Der Linkteaser {{title}} wurde automatisch gespeichert.',
				},
				manual: {
					message: 'Erfolgreich gespeichert',
					description: 'Der Linkteaser {{title}} wurde erfolgreich gespeichert.',
				},
				error: {
					message: 'Fehler beim Speichern',
					description: 'Deine Änderungen konnten nicht gespeichert werden.',
				},
			},
			deleteTeaser: {
				modalTitle: 'Linkteaser löschen',
				confirmDelete: 'Wollen Sie den Linkteaser wirklich löschen?',
				usedTeaser: {
					problem:
						'Der Linkteaser wird gerade auf einer Seite verwendet und kann deshalb nicht gelöscht werden.',
					description:
						'Wenn Sie den Linkteaser dennoch löschen möchten, entfernen Sie alle Vorkommen des Teasers aus den unten aufgelisteten Seiten, speichern Sie diese und versuchen Sie es erneut.',
				},
			},
			searchPlaceholder: 'Linkteaser-Suche...',
			createTeaser: 'Linkteaser erstellen',
		},
		image: {
			title: 'Bild',
			upload: {
				error: {
					message: 'Fehler',
					description: 'Die Datei konnte nicht hochgeladen werden!',
				},
				upload: 'Bild hochladen',
				replace: 'Bild ersetzen',
			},
			confirmDelete: 'Soll dieses Bild wirklich gelöscht werden?',
		},
		table: {
			title: 'Überschrift',
			description: 'Linkteaser',
			active: 'Aktiv',
			lastAuthor: 'Zuletzt bearbeitet von',
			lastUpdate: 'Zuletzt bearbeitet',
			noEntry: 'Die Suche ergab keine Treffer!',
		},
	},

	// article editor
	article: {
		authErrorLogout:
			'Sie wurden leider ausgeloggt. Bitte öffnen Sie <a href="/logout" target="_blank">den Login</a> in einem neuen Tab, loggen Sie sich ein und drücken Sie dann in diesem Tab auf speichern. <code>{{error}}</code>',
		meta: {
			title: 'Meta',
			author: {
				title: 'Autorinnen und Autoren',
				help: 'Beteiligte  BR-Autoren  suchen; Bitte beachten, dass nur aktive Profile in den Suchergebnissen angezeigt werden.  Neuanlage  von  Autoren  erfolgt  über  <a target="_blank" href="/authors">Autoren</a>.',
				searchPlaceholder: 'Person suchen',
			},
			seo: {
				title: 'SEO - Angaben für Suchmaschinen',
				help: '<a target="_blank" href="/faq#seo-informationen-fuer-artikel-editor">Infos</a>  zu  SEO-Anforderungen.  Ohne  Angaben  wird  der  Teasertext  ausgespielt.',
				titlePlaceholder: 'SEO-Titel (optional)',
				descPlaceholder: 'Meta Description',
				characterCountText: '{{currentCount}}/{{characterMaxLength}} Zeichen',
				slugCountText: '{{currentCount}}/{{characterMaxLength}} Zeichen',
				slugPlaceholder: 'URL-Kürzel eingeben',
				canonicalUrlPlaceholder: 'Canonical URL eingeben',
				seoTitleEditTooltip: 'SEO-Titel verändern',
			},
			category: {
				title: 'Kategorie',
				help: 'Auswahl  bestimmt  Verortung  und  Farbgebung  des  Inhalts.',
				searchPlaceholder: 'Kategorie auswählen',
			},
			sourceOrigin: {
				title: 'Sendungsbezug',
				help: 'Immer  einen  Sendungsbezug  inkl.  Datum  und  Uhrzeit  angeben.  Aktuell  nur  Auswahl  eines  Sendungsbezugs  möglich.',
				datePlaceholder: 'Datum',
				timePlaceholder: 'Zeit',
				inputPlaceholder: 'Sendungsbezug suchen',
				inputButton: 'Hinzufügen',
			},
			links: {
				title: 'Links',
				help: 'Mögliche  Eingaben  sind  URLs  auf  BR24-Inhalte  und  auf  externe  Seiten.  Sprechende  Linktitel  wählen:  z.B.  B5  aktuell  hier  live  hören.  Erster  Link:  sichtbar  auf  Artikel-Teaser',
				titlePlaceholder: 'Linktext',
				linkPlaceholder: 'URL',
				externalLinkLabel: 'extern öffnen',
				addButtonTitle: 'Hinzufügen',
				characterCountText: '{{currentCount}}/{{titleMaxLength}} Zeichen',
			},
			teasers: {
				title: 'Link-Empfehlung',
				help: 'Mögliche  Eingaben  sind  URLs  auf  BR24-Inhalte  und  auf  externe  Seiten.  Erster  Link:  sichtbar  auf  Artikel-Teaser',
				searchPlaceholder: 'Artikelsuche',
				searchButtonLabel: 'Suchen',
				addButtonLabel: 'Link hinzufügen',
				linkPlaceholder: 'URL',
				save: 'Speichern',
				articleNotFoundError: 'Artikel mit dieser ID nicht gefunden.',
				boardNotFoundError: 'Verteilseite mit dieser ID nicht gefunden.',
				couldNotFetchMetaError: 'Daten konnten nicht geladen werden',
				invalidUrlError: 'Unvollständige oder falsche URL.',
				alreadyExistsError: 'Eine URL kann nur ein mal hinzugefügt werden',
				isNotWhitelisted: 'URLs von {{hostname}} sind nicht erlaubt.',
				whiteListedURLS:
					'Folgende Domäne sind erlaubt: br.de, tagesschau.de, sportschau.de, bayern3.de, br-klassik.de, br24.de, ard.de, ardalpha.de, ardmediathek.de, ardaudiothek.de',
				info: {
					invalid:
						'Die verlinkte Webseite ist nicht erreichbar. Bitte entfernen oder überarbeiten sie diesen Eintrag.',
					depublished: 'Dieser BR24-Artikel ist depubliziert und wird nicht in Web/App angezeigt.',
				},
			},
			tags: {
				title: 'Schlagwörter',
				help: 'Bestenfalls  2-4  sinnvolle  Schlagworte  eingeben;  Auswahl  analog  <a target="_blank" href="/faq#google-suchverhalten">Google-Suchverhalten</a>. Leerzeichen  setzen  und  auf  Groß-/Kleinschreibung  achten.',
				placeholder: 'Schlagwort eingeben',
				addButtonLabel: 'Hinzufügen',
				inputHelp: '{{count}}/{{max}} Zeichen',
			},
			geolocation: {
				title: 'Bezirke / Geolocation',
				help: 'Passende  Regierungsbezirke und Sprache auswählen.  Falls  Ortsinfo  vorhanden,  unter  journalistischen  Kriterien  eingeben. Standardmäßig ist deutsch als Sprache gewählt.',
				districtPlaceholder: 'Bezirke wählen',
			},
			more: {
				languagePlaceholder: 'Sprache wählen',
				tooltip: 'Wählen Sie hier die Sprache, in der der Artikel geschrieben wurde.',
				title: 'Mehr',
				labels: {
					isLive: 'LIVE-Berichterstattung',
					allowComments: 'Kommentare erlaubt',
					enableAmp: 'AMP',
					hasMigration: 'Migration',
				},
				sophoraSearch: {
					searchPlaceholder: 'Sophora-URL eingeben',
					help: 'Artikel die mit Sophora verknüpft wurden, leiten Anfragen, die an br.de/nachrichten gestellt werden, auf die neue BR24 - Seite weiter. Wichtiger Hinweis: Verknüpfte Sophoraartikel müssen nach der Migration händisch depubliziert werden.',
					connectedArticleText: 'Verknüpft mit Sophora ID:',
				},
			},
			notes: {
				title: 'Notizen',
				help: 'Interne Notizen und Kommentare zum Artikel. Diese sind nur für Redakteure im CMS sichtbar.',
				characterCountText: '{{currentCount}}/{{characterMaxLength}} Zeichen',
			},
		},
		action: {
			button: {
				text: {
					[constants.STATUS_DELETED]: 'Gelöscht',
					[constants.STATUS_DEPUBLISHED]: 'Depubliziert',
					[constants.STATUS_DRAFT]: 'Entwurf',
					[constants.STATUS_PUBLISHED]: 'Publiziert',
					[constants.STATUS_REVIEW]: 'in Freigabe',
					[constants.STATUS_SCHEDULED]: 'Zeitgesteuert',
					template: 'Vorlage',
				},
				menu: {
					[constants.STATUS_DELETED]: 'Löschen',
					[constants.STATUS_DEPUBLISHED]: 'Depublizieren',
					[constants.STATUS_DRAFT]: 'zu Entwurf machen',
					[constants.STATUS_PUBLISHED]: 'Publizieren',
					[constants.STATUS_REVIEW]: 'zur Freigabe',
					[constants.STATUS_SCHEDULED]: 'Zeitsteuerung aktivieren',
					changePublicationDate: 'Zeitstempel anpassen',
					template: 'Artikel erstellen',
				},
			},
		},
		historyTimeline: {
			status: {
				[constants.STATUS_DELETED]: 'gelöscht',
				[constants.STATUS_DEPUBLISHED]: 'depubliziert',
				[constants.STATUS_DRAFT]: 'bearbeitet',
				[constants.STATUS_PUBLISHED]: 'publiziert',
				[constants.STATUS_REVIEW]: 'zur Freigabe',
				[constants.STATUS_SCHEDULED]: 'zeitgesteuert',
				[constants.STATUS_CHANGED]: 'bearbeitet',
			},
			moment: {
				format: '[am] DD.MM.YYYY, HH:mm [Uhr]',
			},
			history: 'Historie',
			author: {
				unknown: 'Unbekannt',
			},
			item: '$t(article.historyTimeline.status.{{status}}) {{date}}',
			itemWithAuthor: '$t(article.historyTimeline.status.{{status}}) von {{author}} {{date}}',
			itemFirstWarning: 'Historie-Anfang {{date}}.',
			itemFirst: 'Status: $t(article.historyTimeline.status.{{status}}), Author: {{author}}, ',
		},
		publishing: {
			publishing: 'Publikation',
			now: 'Sofort publizieren',
			tooltip: 'Publikationsdatum anpassen',
			delay: {
				activate: 'Publikationsdatum anpassen',
				delay: 'Publikationsdatum',
				moment: {
					format: 'DD.MM.YYYY [um] HH:mm',
				},
			},
			error: {
				error: 'Fehler',
				empty: 'Die zeitgesteuerte Veröffentlichung darf nicht leer sein.',
			},
		},
		depublishing: {
			depublishing: 'Depublikation',
			category: {
				custom: 'Benutzerdefinierte Depublikation',
				[constants.EXPIRATION_EPG_BUSINESS_CONTENT]:
					'1 - Programminformationen (EPG) sowie Unternehmensinhalte',
				[constants.EXPIRATION_REPEATED_EVENTS]: '2 - Wiederkehrende Ereignisse',
				[constants.EXPIRATION_SUPPORTING_INFO]: '3 - Programmbegleitende Inhalte',
				[constants.EXPIRATION_SERIES]: '4 - Inhalte zu Serien und Mehrteilern',
				[constants.EXPIRATION_EDUCATION]: '5 - Bildungsinhalte',
				[constants.EXPIRATION_TIME_CULTURE]: '6 - Zeit- und Kulturgeschichte',
			},
			error: {
				error: 'Fehler',
				empty: 'Die zeitgesteuerte Depublikation darf nicht leer sein.',
			},
		},
		notification: {
			silent: 'mit Push',
			tooltip: 'Mit/Ohne Push-Notification',
		},
		notificationModal: {
			cancel: 'Abbrechen',
			headline: 'Achtung',
			save: 'Push jetzt versenden',
			text: 'Soll eine Push-Nachricht (ohne Ton) verschickt werden? Dabei werden automatisch alle aktuellen Änderungen im Artikel gespeichert.',
		},
		previewButton: {
			preview: 'Vorschau',
			toArticle: 'zum Artikel',
			tooltip: 'Vorschau/zum Artikel',
		},
		saveButton: {
			save: 'Speichern',
			locked: 'Es wurden bisher keine Änderungen am Artikel vorgenommen.',
		},
		title: {
			template: 'Vorlage',
		},
		changePubDateOverlay: {
			headline: 'Zeitstempel anpassen',
			select: {
				headline: 'Wähle einen Zeitstempel:',
				now: 'Jetzt',
				lastDate: 'Bisherigen Zeitstempel beibehalten',
				custom: 'Zeitpunkt manuell setzen',
			},
			saveButton: 'Speichern',
			cancelButton: 'Abbrechen',
		},
	},

	boards: {
		boards: 'Verteilseiten',
		newBoard: 'Verteilseite erstellen',
		searchPlaceholder: 'Suchen...',
		table: {
			categories: 'Kategorie',
			edited: 'Bearbeitet',
			active: 'Aktiv',
			emptyText: 'Keine Verteilseiten gefunden.',
			filters: {
				ALL: 'Alle',
				[constants.STATUS_DEPUBLISHED]: 'Depubliziert',
				[constants.STATUS_DRAFT]: 'Entwurf',
				[constants.STATUS_PUBLISHED]: 'Publiziert',
			},
			homepage: 'Startseite',
			regions: 'Regionen',
			slug: 'URL-Kürzel',
			status: 'Status',
			topics: 'Themenseiten',
		},
	},

	articles: {
		article: 'Artikel',
		createArticle: 'Artikel erstellen',
		notes: 'Zu diesem Artikel wurden Notizen hinterlegt',
		table: {
			header: {
				type: 'Typ',
				headline: 'Titel',
				components: 'Bestandteile',
				active: 'Aktiv',
				editor: 'Bearbeiter',
				status: 'Status',
				sourceOrigin: 'Sendungsbezug',
				publication: 'Veröffentlichung',
				edited: 'Bearbeitet',
				notes: 'Notizen',
			},
			row: {
				createFromTemplate: 'Als Vorlage verwenden',
				editArticle: 'Artikel bearbeiten',
				republish: 'Erneut publizieren',
			},
		},
		search: {
			articleSearch: 'Artikelsuche...',
			emptySearch: 'Keine Artikel gefunden.',
			clearFilter: 'Alle Filter aufheben',
		},
		copyArticle: {
			error: {
				message: 'Artikel konnte nicht kopiert werden',
				description: 'Die Vorlage {{title}} konnte nicht kopiert werden.',
			},
			success: {
				message: 'Artikel wurde erstellt',
				description: 'Die Vorlage {{title}} wurde erfolgreich kopiert.',
			},
		},
		selection: {
			story: 'Zu einer Story zusammenführen',
			reset: 'Selektion aufheben',
			successMessage: 'Die Artikel {{articles}} wurden zu einer Story vereint.',
			errorMessage: 'Die Artikel konnten nicht zu einer Story vereint werden.',
		},
	},
	lockWarnings: {
		backToOverviewButton: {
			title: 'zur Übersicht',
			tooltip: 'zur Übersicht',
		},
		openReadOnlyButton: {
			title: 'Dokument lesend öffnen',
			tooltip: 'Dokument lesend öffnen',
		},
		lockTakeoverButton: {
			title: 'Sperre brechen',
			tooltip: 'Sperre brechen',
		},
		setLockButton: {
			title: 'Sperre setzen',
			tooltip: 'Sperre setzen',
		},
		reloadDocumentButton: {
			title: 'alle Änderungen ververfen',
			tooltip: 'Dokument neu laden',
		},
		openNewTabButton: {
			title: 'in neuem Tab öffnen',
			tooltip: 'Hier weiter nur lesend arbeiten',
		},
		updatedWhileLockDialog: {
			title: 'Sperre des Dokuments wurde zwischenzeitlich entfernt',
			text: 'Die Sperre für das aktuelle Dokument wurde aufgehoben. Das Dokument ist nun wieder zur Bearbeitung verfügbar.',
		},
		saveErrorLockDialog: {
			title: 'Dieses Dokument wurde inzwischen verändert.',
			text: 'Die Sperre für das aktuelle Dokument wurde gebrochen. {{name}} hat am {{date}} das Dokument gespeichert. Um deine Änderungen nicht zu verlieren, kannst in Lesend Modus deine Module in den Assets Manager absichern.',
		},
		brokenLockDialog: {
			title: 'Achtung! Sperrung dieses Dokuments wurde gebrochen',
			textPiece1: 'Deine Sperre wurde am ',
			textPiece2: ' von ',
			textPiece3: ' durchbrochen. Du kannst deine Änderungen im Lesemodus sichern.',
		},
		contentLockDialog: {
			title: 'Dokument durch anderen Newsroom-Nutzer gesperrt',
			textPiece1: 'Dieses Dokument wird bereits seit ',
			textPiece2: ' von ',
			textPiece3:
				' bearbeitet. Bitte beachte, dass Du nur Änderungen vornehmen kannst, wenn Du die Sperre brichst.',
		},
		readOnlyBar: {
			text: 'Dieses Dokument wurde nur zum Lesen geöffnet, Änderungen daran können NICHT gespeichert werden.',
		},
	},
	capabilities: {
		[constants.CAPABILITY_FULLY_FEATURED]: 'Artikel',
		[constants.CAPABILITY_BASIC]: 'Artikel',
		[constants.CAPABILITY_TEXT_ONLY]: 'Eilmeldung',
	},

	priorities: {
		[constants.PRIORITY_NORMAL]: 'Standard',
		[constants.PRIORITY_HIGH]: 'Topstory',
		[constants.PRIORITY_CRITICAL]: 'Eil',
	},

	modals: {
		articlePublishNow: {
			title: 'Sofort Publizieren',
			content: 'Bist du Dir sicher, dass du den Artikel sofort publizieren willst?',
			ok: 'Publizieren',
			cancel: 'Abbrechen',
		},
		articleDelete: {
			title: 'Artikel löschen',
			content: 'Diese Aktion ist unwiderruflich, gelöschte Artikel sind nicht mehr auffindbar.',
			ok: 'Löschen',
			cancel: 'Abbrechen',
		},
		notificationActivated: {
			title: 'Push-Benachrichtigung',
			content: 'Bei der Veröffentlichung des Artikels wird eine Push-Benachrichtigung verschickt.',
			ok: 'Publizieren',
			cancel: 'Abbrechen',
		},
		articleDepublishConnected: {
			title: 'Depublizieren nicht möglich',
			content:
				'Dieser Inhalt ist auf einer oder mehreren Seiten eingebaut und kann daher nicht depubliziert werden. Bitte entferne den Inhalt erst von der/den Seiten.',
			ok: 'Ok',
		},
		articleCreateFromTemplateSuccess: {
			title: 'Artikel wurde erstellt',
			content: 'Die Vorlage wurde erfolgreich kopiert.',
		},
		articleCreateFromTemplateError: {
			title: 'Die Vorlage konnte nicht kopiert werden.',
		},
		articleNotes: {
			title: 'Artikelnotizen vorhanden',
			content:
				'Für diesen Artikel wurden Notizen hinterlassen. Bist du Dir sicher, dass du den Artikel trotzdem speichern möchtest?',
			ok: 'Ok',
			cancel: 'Abbrechen',
		},
	},

	map: {
		searchPlaceholder: 'Ort suchen',
		searchError:
			'Der Ort konnte nicht korrekt erfasst werden. Bitte einen Ort über die Suchliste auswählen.',
		title: 'Aktueller Ort',
	},

	locations: {
		[constants.LOCATION_DISTRICT]: 'Bezirk',
		[constants.LOCATION_ZIPCODE]: 'PLZ',
		[constants.LOCATION_STREET]: 'Straße',
		[constants.LOCATION_STREETNUMBER]: 'Hausnummer',
		[constants.LOCATION_COUNTRY]: 'Land',
		[constants.LOCATION_STATE]: 'Bundesland',
		[constants.LOCATION_CITY]: 'Stadt',
		[constants.LOCATION_SUBURB]: 'Vorort',
	},

	validation: {
		errors: {
			article: {
				title:
					'Die Artikel-Überschrift muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} lang sein.',
				seoTitle:
					'Der SEO-Titel muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} lang sein.',
				teaserText:
					'Der Teasertext muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} lang sein.',
				modules: 'Ein Artikel muss mindestens ein Modul enthalten.',
				authors: 'Es muss mindestens 1 und darf höchstens 5 Autoren/innen angegeben werden.',
				seo: {
					title: 'Der SEO-Titel darf höchstens {{maxLength}} Zeichen lang sein.',
					slug: 'Die URL muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} lang sein. Außerdem darf sie nur Buchstaben, Zahlen und einzelne Bindstriche als Trenner für Wörter enthalten.',
					metaDescription:
						'Die Meta Description muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} Zeichen lang sein.',
				},
				notes: 'Das Notizfeld darf höchstens {{maxLength}} lang sein.',
				links: {
					label:
						'Ein Link verhindert das Speichern und muss deswegen bearbeitet oder entfernt werden.',
					url: 'Links müssen im Format "http(s)://www.<url>.<domain>" angegeben werden.',
				},
				language: 'Der Artikel muss eine Sprache enthalten.',
				teasers: 'URLs müssen im Format "http(s)://www.<url>.<domain>" angegeben werden.',
				geolocation:
					'Die Geolocation ist im falschen Format. Bitte wähle einen Ort aus dem Dropdown-Menü',
			},
			modules: {
				audio: {
					copyright: 'Das Copyright für ein Audio muss befüllt werden.',
					title:
						'Der Teasertext muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} lang sein.',
					url: 'Es muss zwingend ein Audio angegeben werden.',
				},
				gallery: {
					isOnFirstPosition:
						'Das Galerie-Modul ist an der ersten Stelle nicht erlaubt. Bitte verschiebe es an eine andere Position darunter.',
				},
				image: {
					copyright: 'Das Copyright für ein Bild muss befüllt werden.',
					title:
						'Die Bildunterschrift muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} Zeichen lang sein.',
					url: 'Es muss zwingend ein Bildausschnitt gewählt werden.',
					altText: 'Der Alt-Text für ein Bild muss befüllt werden.',
				},
				thumbnail: {
					copyright: 'Das Copyright für ein Bild muss befüllt werden.',
					title:
						'Die Bildunterschrift muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} Zeichen lang sein.',
					url: 'Es muss zwingend ein Bildausschnitt gewählt werden.',
					altText: 'Der Alt-Text für ein Bild muss befüllt werden.',
				},
				video: {
					url: 'Es muss zwingend ein Video angegeben werden.',
					title:
						'Die Videobeschreibung muss min. {{minLength}} Zeichen und darf höchstens {{maxLength}} Zeichen lang sein.',
					copyright: 'Das Copyright für ein Video muss gefüllt werden.',
				},
				text: {
					isEmpty: 'Es muss zwingend ein Text eingegeben werden.',
					isOnFirstPosition:
						'Das Text-Modul ist an der ersten Position nicht erlaubt. Bitte verschiebe es an eine andere Position darunter.',
				},
				video360: {
					sources: 'validation.errors.modules.video360.sources',
					url: 'validation.errors.modules.video360.url',
				},
				livestream: 'Es muss zwingend ein Livestream angegeben werden.',
				embed: {
					isOnFirstPosition:
						'Das Embed-Modul ist an der ersten Stelle nicht erlaubt. Bitte verschiebe es an eine andere Position darunter.',
					noService:
						'Es konnte kein Dienst ermittelt werden. Bitte überprüfe den eingefügten Embed Code.',
					noAltText: 'Der Alt-Text für ein Embed muss befüllt werden.',
					noValidation: 'Der eingefügte Embed-Code konnte nicht korrekt validiert werden.',
					noEmbedInsert: 'Es muss zwingend ein Embed-Code angegeben werden.',
					noPreviewLoaded: 'Die Vorschau des Embeds muss geladen werden.',
					embedCodeValidationFailed:
						'Validierung fehlgeschlagen. Bitte überprüfe den eingefügten Code.',
				},
			},
		},
	},

	// asset manager
	assetManager: {
		connecting: 'Verbindung wird hergestellt…',
		emptyText: 'Ziehe Element aus dem Artikel hier her um sie zu speichern.',
		importer: {
			[internalConstants.AM_IMPORTER_SOPHORA]: {
				title: 'Sophora',
				inputPlaceholder: 'Sophora-ID oder br.de URL eingeben / kopieren...',
				empty: 'Trage eine Sophora-ID in das Suchfeld ein um Inhalte zu sehen.',
				loading: 'Sophora-Inhalte werden gesucht...',
			},
			[internalConstants.AM_IMPORTER_SOPHORA_ARTICLE]: {
				title: 'Sophora',
				inputPlaceholder: 'Sophora-ID oder br.de URL eingeben / kopieren...',
				empty: 'Trage eine Sophora-ID in das Suchfeld ein um Inhalte zu sehen.',
				loading: 'Sophora-Inhalte werden gesucht...',
			},
			[internalConstants.AM_IMPORTER_LIVESTREAMS]: {
				title: 'Livestreams',
				inputPlaceholder: 'Suchbegriff eingeben...',
				empty: 'Aktuell stehen leider keine Livestreams zur Verfügung.',
				loading: 'Livestream-Inhalte werden geladen...',
			},
			[internalConstants.AM_IMPORTER_NEWSROOM]: {
				title: 'Newsroom',
				inputPlaceholder: 'Suchbegriff eingeben...',
				empty: 'Für diese Suche stehen leider keine Newsroom-Inhalte zur Verfügung.',
				loading: 'Newsroom-Inhalte werden geladen...',
			},
			[internalConstants.AM_IMPORTER_BRBILD]: {
				title: 'BR Bild',
				inputPlaceholder: 'Suchbegriff eingeben...',
				selectImageTypePlaceholder: 'Bildtyp wählen...',
				selectRightsOwnerPlaceholder: 'Rechtegeber wählen...',
				empty: 'Trage einen Suchbegriff in das Suchfeld ein um nach Bildern zu suchen.',
				loading: 'BR Bild Inhalte werden geladen...',
			},
		},
		dataTakeoverModal: {
			confirmText: 'Möchtest du die folgenden Daten direkt in den Artikel übernehmen?',
			confirmOk: 'Übernehmen',
			confirmCancel: 'Abbrechen',
			headline: 'Daten übernehmen',
			headlineContent: 'Dachzeile + Überschrift:',
			headlineTeaserText: 'Teasertext:',
			other: 'ggf. Meta Description (SEO)',
		},
		dataTakeoverModalNew: {
			headline: 'Daten übernehmen',
			confirmText: 'Möchtest du die folgenden Daten direkt in den Artikel übernehmen?',
			warningOverwrite: 'Folgende Felder werden dabei überschrieben:',
			confirmOk: 'Übernehmen',
			confirmCancel: 'Abbrechen',
			headlineContent: 'Title',
			headlineTeaserText: 'Teasertext',
			metaDescription: 'Meta Description (SEO)',
			canonicalUrl: 'Canonical URL (SEO)',
			modules: 'Die Module des importierten Artikels werden zum bestehenden Artikel hinzugefügt',
			warningTable: 'Der importierte Artikel enthält eine Tabelle welche nicht übernommen wird',
		},
		item: {
			invalid: {
				title: 'Dieses Element existiert nicht mehr...',
				description: 'Das kann mehrere Gründe haben. Weitere Informationen findest Du unter FAQ.',
			},
			loading: {
				title: 'Dieses Element wird geladen...',
				description: 'Einen Moment bitte!',
			},
			[internalConstants.AM_TYPE_IMAGE]: {
				noTitle: 'Kein Titel vorhanden',
				noCopyright: 'Kein Copyright vorhanden',
			},
		},
		space: {
			[internalConstants.AM_GLOBAL_SPACE]: 'Öffentlich',
			[internalConstants.AM_PERSONAL_SPACE]: 'Privat',
		},
		groups: {
			search: 'Suche',
			add: 'Gruppe erstellen',
			save: 'Gruppe speichern',
			lock: 'Sperren',
			unlock: 'Entsperren',
			edit: 'Bearbeiten',
			delete: 'Löschen',
			info: 'Info',
			infoItems: 'Element(e)',
			infoCreatedBy: 'Ersteller',
			infoCreatedAt: 'Erstellt am',
			infoLocked: 'Diese Gruppe wird nicht|automatisch geleert!',
			infoNotLocked: 'Diese Gruppe wird täglich|um 1:00 Uhr geleert!',
			infoModified: 'Letzte Änderung',
			creatorHead: 'Neue Gruppe erstellen',
			creatorPlaceholder: 'Gruppenname',
			emptyTitleError: 'Gruppenname darf nicht leer sein',
			userGenerated: 'User-erstellte Gruppe',
		},
		trash: {
			deleteAll: 'Alle löschen',
			deleteSelected: 'Auswahl löschen',
			tip: 'Hierhin ziehen',
		},
		filter: {
			[internalConstants.AM_SORT_DIRECTION_RECENT]: 'Neueste',
			[internalConstants.AM_SORT_DIRECTION_OLDEST]: 'Älteste',
			[internalConstants.AM_SORT_DIRECTION_ALPHABETICAL_ASC]: 'A-Z',
			[internalConstants.AM_SORT_DIRECTION_ALPHABETICAL_DESC]: 'Z-A',
		},
		type: {
			confirm: {
				ok: 'Bestätigen',
				reset: 'Filter löschen',
			},
			[internalConstants.AM_TYPE_BOARD_TEASER]: 'Teaser',
			[internalConstants.AM_TYPE_BOARD]: 'Seite',
			[internalConstants.AM_TYPE_ARTICLE]: 'Artikel',
			[internalConstants.AM_TYPE_TEXT]: 'Text',
			[internalConstants.AM_TYPE_LINK]: 'Link',
			[internalConstants.AM_TYPE_EMBED_CODE]: 'Embed-Code',
			[internalConstants.AM_TYPE_GALLERY]: 'Galerie',
			[internalConstants.AM_TYPE_IMAGE]: 'Bild',
			[internalConstants.AM_TYPE_AUDIO]: 'Audio',
			[internalConstants.AM_TYPE_VIDEO360]: 'Video 360°',
			[internalConstants.AM_TYPE_VIDEO]: 'Video',
			[internalConstants.AM_TYPE_LIVESTREAM]: 'Livestream',
		},
		AssetManagerButton: {
			toolTip: 'Asset Manager anzeigen',
		},
		ImporterButton: {
			toolTip: 'Importer anzeigen',
		},
		status: {
			depublished: 'Depubliziert',
			draft: 'In Arbeit',
			published: 'Publiziert',
			publishedAt: 'Publiziert am {{date}}',
		},
		pagination: {
			query: 'Suchanfrage',
			queries: 'Suchanfragen',
			result: 'Ergebnis',
			results: 'Ergebnisse',
			page: 'Seite',
		},
	},

	richTextEditor: {
		inlineLinkEditor: {
			title: 'Link bearbeiten',
			deleteLink: 'Link entfernen',
			cancel: 'Abbrechen',
			ok: 'Ok',
			linkTextLabel: 'Link Text',
			emptyLinkTextError: 'Bitte geben Sie einen text für den Link ein',
			linkURLLabel: 'URL',
			linkURLError: 'Bitte geben Sie eine gültige URL ein',
		},
	},

	// general stuff
	featureInWorkTitle: 'Wir arbeiten hart...',
	featureInWorkDescription:
		'Um Dir Deine Arbeit zu erleichtern. Leider ist diese Funktionalität noch nicht ausgereift.',
	noData: 'Keine Daten gefunden.',
	error: 'Fehler',
	withSound: 'Mit Ton',
	withoutSound: 'Ohne Ton',
	load: 'Laden...',
	yes: 'Ja',
	no: 'Nein',
	success: 'Erfolg',
	fatalError: {
		title: 'Es ist ein unerwarteter Fehler aufgetreten!',
		description:
			'Sollten Sie diesen Fehler nicht zum ersten Mal sehen, wenden Sie sich bitte an den Support. <br><br>Original Fehlermeldung: {{message}}',
	},
	saveArticle: {
		error: {
			[constants.STATUS_DRAFT]: {
				title: 'Speichern fehlgeschlagen!',
				description: 'Der Entwurf konnte nicht gespeichert werden.',
			},
			[constants.STATUS_REVIEW]: {
				title: 'Zur Freigabe vorlegen nicht möglich!',
				description:
					'Der Artikel konnte nicht zur Freigabe vorgelegt werden. Bitte überprüfe die rot markierten Felder.',
			},
			[constants.STATUS_PUBLISHED]: {
				title: 'Publizierung nicht möglich!',
				description:
					'Der Artikel konnte nicht publiziert werden. Bitte überprüfe die rot markierten Felder.',
			},
			[constants.STATUS_SCHEDULED]: {
				title: 'Zeitgesteuerte Publizierung nicht möglich!',
				description:
					'Der Artikel konnte nicht zeitgesteuert publiziert werden. Bitte überprüfe die rot markierten Felder.',
			},
			[constants.STATUS_DEPUBLISHED]: {
				title: 'Depublizierung nicht möglich!',
				description: 'Der Artikel konnte nicht depubliziert werden.',
			},
			[constants.STATUS_DELETED]: {
				title: 'Löschen nicht möglich!',
				description: 'Der Artikel konnte nicht gelöscht werden.',
			},
			[constants.STATUS_CANNOT_PLACE]: {
				title: 'Kann hier nicht platziert werden.',
				description: '',
			},
			[constants.STATUS_NOT_WHITELISTED]: {
				title: 'Kann hier nicht platziert werden.',
				description:
					'Folgende Domäne sind erlaubt: br.de, tagesschau.de, sportschau.de, bayern3.de, br-klassik.de, br24.de',
			},
			[constants.STATUS_ALREADY_ON_THE_LIST]: {
				title: 'Kann hier nicht platziert werden.',
				description: 'Dieser Artikel ist bereits in der Liste.',
			},
			[constants.LS_UPDATE_LOCKSTATUS]: {
				title: 'Speichern nicht möglich.',
				description:
					'Der Lock-Status des Artikels kann nicht überprüft werden. Bitte lade den Artikel neu.',
			},
			refetch: {
				title: 'Es ist ein Netzwerkproblem aufgetreten.',
				description:
					'Der Artikel kann nicht neugeladen werden. Bitte lande die Seite neu, um Locking Probleme zu vermeiden.',
			},
			general: {
				title: 'Aktion war nicht erfolgreich!',
				description: 'Leider ist ein Fehler aufgetreten.',
			},
		},
		success: {
			[constants.STATUS_DRAFT]: {
				title: 'Speichern erfolgreich!',
				description: 'Der Entwurf konnte erfolgreich gespeichert werden.',
			},
			[constants.STATUS_REVIEW]: {
				title: 'Artikel im Review-Modus',
				description: 'Der Artikel wurde zum Review vorgelegt.',
			},
			[constants.STATUS_PUBLISHED]: {
				title: 'Publizierung erfolgreich!',
				description: 'Der Artikel konnte erfolgreich publiziert werden.',
			},
			[constants.STATUS_SCHEDULED]: {
				title: 'Zeitgesteuerte Publizierung erfolgreich!',
				description: 'Der Artikel wurde erfolgreich zeitgesteuert publiziert.',
			},
			[constants.STATUS_DEPUBLISHED]: {
				title: 'Depublizierung erfolgreich',
				description: 'Der Artikel konnte erfolgreich depubliziert werden.',
			},
			[constants.STATUS_DELETED]: {
				title: 'Artikel gelöscht!',
				description: 'Der Artikel wurde erfolgreich gelöscht.',
			},
			general: {
				title: 'Speichern erfolgreich!',
				description: 'Das Speichern war erfolgreich',
			},
			autoSave: {
				title: 'Automatisch gespeichert',
			},
		},
	},
	authors: {
		createAuthor: 'Weiteren Autor erstellen',
		searchPlaceholder: 'Autorensuche nach Nachnamen...',
		me: 'Ich',
		noData:
			'Keine Daten gefunden. Bitte beachte, dass die Autorensuche derzeit nur für den Nachnamen möglich ist.',
		filter: {
			all: 'Alle',
			status: {
				active: 'aktiv',
				inactive: 'inaktiv',
			},
			loginType: {
				ldap: 'mit Account',
				profile: 'ohne Account',
			},
			info: 'Bitte beachten: Aktuell ist eine Autor/innen-Suche nur für Nachnamen möglich!',
		},
		update: {
			error: 'Die Daten konnten nicht gespeichert werden!',
			success: 'Der Benutzer {{firstname}} {{lastname}} wurde erfolgreich bearbeitet!',
		},
		systemprofile: 'Systemprofil',
	},
	metageneral: {
		characterCount: '{{count}}/{{max}} Zeichen',
	},
	author: {
		buttonSave: 'Profil speichern',
		buttonDismiss: 'Verwerfen',
		buttonPreview: 'Vorschau',
		characterCount: '{{count}}/{{max}} Zeichen',
		statusDescription: {
			active: {
				title: 'Autorenprofil aktiv',
				firstline:
					'AutorIn wird auf Artikel-Detailseite Web & App angezeigt, zusätzlich Detailseite Autorenprofil verlinkt.',
				secondline: 'Autorenprofil inaktiv: keine Detailseite Autorenprofil mehr verlinkt',
			},
			inactive: {
				title: 'Autorenprofil inaktiv',
				firstline:
					'AutorIn wird auf Artikel-Detailseite Web & App angezeigt, keine zusätzliche Detailseite Autorenprofil verlinkt.',
				secondline: 'Autorenprofil aktiv: zusätzlich Detailseite Autorenprofil verlinkt',
			},
		},
		statusLabel: {
			active: 'aktiv',
			inactive: 'inaktiv',
		},
		firstname: {
			label: 'Vorname',
			placeholder: 'Vorname angeben',
		},
		lastname: {
			label: 'Nachname',
			placeholder: 'Nachname angeben',
		},
		jobTitle: {
			label: 'Tätigkeitsschwerpunkt',
			placeholder: 'Beschreibe die Tätigkeit, z.B: BR Sport-Experte',
		},
		description: {
			label: 'Journalistisches Profil (optional)',
			placeholder: 'Beschreibe dein journalistisches Profil',
		},
		contact: {
			label: 'Kontakt',
		},
		profilePicture: {
			alt: 'Dein Profilbild',
			delete: 'Lösche dein aktuelles Bild',
			edit: 'Bearbeite dein aktuelles Bild',
			upload: 'Lade ein Bild hoch oder ersetzte es durch ein Neues',
		},
		upload: {
			error: {
				message: 'Fehler',
				description: 'Die Datei {{name}} konnte nicht hochgeladen werden!',
			},
		},
		validation: {
			firstname: 'Dein Vorname muss min. {{min}}, max. jedoch {{max}} Zeichen enthalten.',
			lastname: 'Dein Nachname muss min. {{min}}, max. jedoch {{max}} Zeichen enthalten.',
			jobTitle:
				'Dein Tätigkeitsschwerpunkt muss aus min. {{min}}, max. jedoch {{max}} Zeichen bestehen.',
			description: 'Deine Beschreibung darf aus maximal {{max}} Zeichen bestehen.',
		},
		save: {
			success: {
				message: 'Erfolg',
				description: 'Änderungen gespeichert!',
			},
			error: {
				message: 'Fehler',
				description: 'Deine Änderungen konnten nicht gespeichert werden.',
			},
		},
		socialMedia: {
			add: 'Hinzufügen',
			select: {
				placeholder: 'Plattform wählen...',
			},
			platform: {
				title: 'Plattform',
			},
			account: {
				title: 'Account-Name',
				placeholder: 'URL des Accounts oder E-Mail Adresse',
			},
			label: {
				title: 'Label',
				placeholder: 'Beschreibung des Links',
			},
		},
	},
};
