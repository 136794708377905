import * as util from '@sep/br24-util';
import invariant from 'invariant';
import styled from 'styled-components';

import PlaceholderImage from '../../../images/placeholder.png';

type ImageProps = {
	url: string;
};

const FullImage = styled.div<ImageProps>`
	min-width: 160px;
	max-width: 160px;
	min-height: 90px;
	max-height: 90px;
	background-color: ${(props) => props.theme?.colors.dark4};
	background-image: url(${(props) => props.url});
	background-size: cover;
	overflow: hidden;
`;

const Gallery = styled.div`
	min-width: 160px;
	max-width: 160px;
	min-height: 90px;
	max-height: 90px;
	display: flex;
	flex-wrap: wrap;
`;

const GalleryImage = styled(FullImage)`
	min-width: 80px;
	max-width: 80px;
	min-height: 45px;
	max-height: 45px;
	flex-grow: 1;
`;

type AssetManagerItemMediaProps = {
	images?: Array<string | undefined | null>;
	image?: string | null;
};

const resizeSelfManagedImage = (image: string) => {
	if (image.indexOf('https://img.br.de') >= 0) {
		return util.image.resize(image, 'landscape', 's');
	}

	return image;
};

function AssetManagerItemMedia(props: AssetManagerItemMediaProps) {
	invariant(!(props.image && props.images), 'image & images are defined (AssetManagerItemMedia)');

	if (props.image) {
		return <FullImage url={props.image ? resizeSelfManagedImage(props.image) : PlaceholderImage} />;
	}

	if (Array.isArray(props.images) && props.images.length > 0) {
		return (
			<Gallery>
				{props.images.map((item, index) => (
					<GalleryImage
						key={`${item}_${index}`}
						url={item ? resizeSelfManagedImage(item) : PlaceholderImage}
					/>
				))}
			</Gallery>
		);
	}

	return null;
}

export default AssetManagerItemMedia;
