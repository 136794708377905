/**
 * @generated SignedSource<<820c7aad86689589bc99307eb60c8cc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BoardsTeaserStatus = "DELETED" | "PUBLISHED";
import { FragmentRefs } from "relay-runtime";
export type BoardsTeaser_boardsTeaser$data = {
  readonly description: string | null;
  readonly image: {
    readonly altText: string | null;
    readonly copyright: string | null;
    readonly title: string | null;
    readonly url: string | null;
  } | null;
  readonly link: {
    readonly label: string | null;
    readonly url: string | null;
  } | null;
  readonly rowId: string;
  readonly status: BoardsTeaserStatus | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"LockHandling_boardsTeaser" | "LockedByAuthor_boardsTeaser">;
  readonly " $fragmentType": "BoardsTeaser_boardsTeaser";
};
export type BoardsTeaser_boardsTeaser$key = {
  readonly " $data"?: BoardsTeaser_boardsTeaser$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardsTeaser_boardsTeaser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardsTeaser_boardsTeaser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleImage",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "copyright",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "altText",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "link",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LockedByAuthor_boardsTeaser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LockHandling_boardsTeaser"
    }
  ],
  "type": "BoardsTeaser",
  "abstractKey": null
};
})();

(node as any).hash = "1f101e1616ad039cf4a71e783afcce1a";

export default node;
