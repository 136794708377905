import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';

import { useTranslate } from '@/client/translation/useTranslate';

import environment from '../../../environment';

import BoardsTeasersTable from './BoardsTeasersTable';
import styles from './BoardsTeasersTable.module.scss';
import type { BoardsTeaserNode } from './BoardsTeasersTableRow';
import type { BoardsTeasersTableQuery } from './__generated__/BoardsTeasersTableQuery.graphql';

type Props = {
	currentFilter: any;
	onClickOpen: (node: BoardsTeaserNode) => void;
	autoRefreshIn: number;
};

export default ({ currentFilter = {}, ...rest }: Props) => {
	const translate = useTranslate();

	return (
		<QueryRenderer<BoardsTeasersTableQuery>
			environment={environment}
			variables={{
				...currentFilter,
				count: 50,
			}}
			query={graphql`
				query BoardsTeasersTableContainerQuery($count: Int, $cursor: Cursor, $searchTerm: String) {
					...BoardsTeasersTable_query
				}
			`}
			render={({ error, props: queryRendererProps }) => {
				if (error) {
					return (
						<div className={styles.contentWrapper}>
							<Alert message={translate('error')} description={error.message} type="error" />
						</div>
					);
				}

				if (!queryRendererProps) {
					return (
						<div className={styles.contentWrapper}>
							<div className={styles.loader}>
								<Spin size="large" />
							</div>
						</div>
					);
				}

				return (
					<BoardsTeasersTable currentFilter={currentFilter} query={queryRendererProps} {...rest} />
				);
			}}
		/>
	);
};
