import { MODULE_TYPE_IMAGE } from '@sep/br24-constants';
import { type ReactNode, type KeyboardEvent, useCallback, useState } from 'react';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import config from '../../../config';
import type { Validation } from '../../../containers/ArticleEditor';
import ErrorMessageText from '../../ErrorMessageText';
import { ImageCropper } from '../../ImageCropper/ImageCropper';
import SVG from '../../SVG';
import {
	ModulePartInput,
	ModulePartOverlay,
	ModulePartDownloadOverlay,
} from '../ArticleModuleParts';
import type { ModulePartInputElement } from '../ArticleModuleParts/ModulePartInput/ModulePartInput';
import { ReactComponent as ImageDefaultThumbnail } from '../images/imageDefaultThumbnail.svg';

const ImageDisplayWrapper = styled.div`
	position: relative;
`;

const ImageDisplay = styled.img<{ isHidden?: boolean }>`
	width: 100%;
	display: ${(props) => (props.isHidden ? 'none' : 'block')};
`;

const InputWrapper = styled.div`
	margin-top: 1.5em;
`;

type WrapperProps = {
	hasActiveDropzone?: boolean;
	isThumbnailUploading: boolean;
};

const Wrapper = styled.div<WrapperProps>`
	border: ${(props) =>
		props.hasActiveDropzone ? `2px solid ${props.theme.colors.primaryBlue}` : ''};
	padding: ${(props) => (props.hasActiveDropzone ? '10px' : '')};
	filter: ${(props) => (props.isThumbnailUploading ? 'blur(5px)' : '')};
`;

type DefaultImageProps = {
	className?: string;
};

const DefaultImage = styled(({ className }: DefaultImageProps) => (
	<SVG className={className}>
		<ImageDefaultThumbnail />
	</SVG>
))`
	width: 100%;
	height: 100%;
`;

interface Props {
	altText: string;
	copyright: string;
	title: string;
	url: string;
	isEditing: boolean;
	isOver?: boolean;
	isThumbnailUploading: boolean;
	validation?: Validation | null;
	onKeyDown?: (event: KeyboardEvent<ModulePartInputElement>) => void;
	onChange: (key: 'url' | 'title' | 'altText' | 'copyright', value: string) => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
}

const IMAGE_PRELOAD_BLUR = config.articleModules.image.preLoadBlur;
const IMAGE_PRELOAD_QUALITY = config.articleModules.image.preLoadQuality;
const IMAGE_PRELOAD_FIT = config.articleModules.image.preLoadFit;
const IMAGE_PRELOAD_HEIGHT = config.articleModules.image.preLoadHeight;

const TITLE_LENGTH_MAX = config.articleModules.image.titleMaxLength;

export function ArticleModuleImage({
	onKeyDown,
	url,
	title,
	altText,
	copyright,
	validation,
	onChange,
	isOver,
	isThumbnailUploading,
	...rest
}: Props) {
	const translate = useTranslate();

	const [isCropping, setIsCropping] = useState(false);
	const [isDownloadOverlayVisible, setIsDownloadOverlayVisible] = useState(false);
	const [isEditOverlayVisible, setIsEditOverlayVisible] = useState(false);
	const [previewLoaded, setPreviewLoaded] = useState(false);

	const handleChangeAltText = useCallback(
		(value: string) => {
			onChange('altText', value);
		},
		[onChange]
	);

	const handleChangeCopyright = useCallback(
		(value: string) => {
			onChange('copyright', value);
		},
		[onChange]
	);

	const handleChangeTitle = useCallback(
		(value: string) => {
			console.log('warning this should not work');
			onChange('title', value);
		},
		[onChange]
	);

	const handleCloseDownloadOverlay = useCallback(() => {
		setIsDownloadOverlayVisible(false);
	}, []);

	const handleDownloadButtonClick = useCallback(() => {
		setIsDownloadOverlayVisible(true);
	}, []);

	const handleHover = useCallback(() => {
		setIsEditOverlayVisible(true);
	}, []);

	const handleHoverOut = useCallback(() => {
		setIsEditOverlayVisible(false);
	}, []);

	const handleImageLoad = useCallback(() => {
		setPreviewLoaded(true);
	}, []);

	const handleOpenCropper = useCallback(() => {
		setIsCropping(true);
	}, []);

	const handleSave = useCallback(
		(url: string) => {
			setIsCropping(false);
			setIsDownloadOverlayVisible(false);
			setIsEditOverlayVisible(false);
			setPreviewLoaded(false);

			if (url) {
				onChange('url', url);
			}
		},
		[onChange]
	);

	let displayModule: ReactNode | null = null;

	// show the default thumbnail when no image exists
	if (!url) {
		displayModule = <DefaultImage />;
	}

	// if we have url parameters already in the url we need to add more with '&' instead of '?'
	const urlSeperator = url.includes('rect') ? '&' : '?';

	// image is available but not loaded yet
	if (!previewLoaded && url) {
		displayModule = (
			<div>
				<ImageDisplay
					src={`${url}${urlSeperator}blur=${IMAGE_PRELOAD_BLUR}&q=${IMAGE_PRELOAD_QUALITY}&fit=${IMAGE_PRELOAD_FIT}&h=${IMAGE_PRELOAD_HEIGHT}`}
				/>
				<ImageDisplay
					isHidden={true}
					src={`${url}${urlSeperator}q=80&w=1920`}
					onLoad={handleImageLoad}
				/>
			</div>
		);
	}

	// image is available and should be displayed
	if (previewLoaded && url && !isCropping) {
		displayModule = (
			<div>
				<ImageDisplay src={`${url}${urlSeperator}q=80&w=1920`} />
				{isDownloadOverlayVisible ? (
					<ModulePartDownloadOverlay url={url} onCloseClick={handleCloseDownloadOverlay} />
				) : (
					<ModulePartOverlay
						buttonLabel={translate(`modules.${MODULE_TYPE_IMAGE}.editCropButtonLabel`)}
						isDownloadButtonVisible={true}
						isVisible={isEditOverlayVisible}
						onDownloadButtonClick={handleDownloadButtonClick}
						onButtonClick={handleOpenCropper}
						onMouseEnter={handleHover}
						onMouseLeave={handleHoverOut}
					/>
				)}
			</div>
		);
	}

	// show the image cropper
	if (previewLoaded && url && isCropping) {
		displayModule = (
			<div>
				<ImageCropper
					hideControls={false}
					onCancel={() => setIsCropping(false)}
					onConfirm={handleSave}
					src={url}
				/>
			</div>
		);
	}

	const titleHelp = translate(`modules.${MODULE_TYPE_IMAGE}.titleHelp`, {
		count: title ? `${title.length}` : 0,
		max: TITLE_LENGTH_MAX,
	});

	return (
		<Wrapper hasActiveDropzone={isOver} isThumbnailUploading={isThumbnailUploading}>
			<ImageDisplayWrapper>{displayModule}</ImageDisplayWrapper>
			{validation && validation['image.url'] ? (
				<ErrorMessageText text={validation['image.url']} hasBottomMargin={true} />
			) : null}
			<InputWrapper>
				<ModulePartInput
					id="title"
					help={titleHelp}
					validationError={validation ? validation['image.title'] : null}
					label={translate(`modules.${MODULE_TYPE_IMAGE}.titleLabel`)}
					onKeyDown={onKeyDown}
					onChange={handleChangeTitle}
					isTextArea={true}
					placeholder={translate(`modules.${MODULE_TYPE_IMAGE}.titlePlaceholder`)}
					value={title}
					{...rest}
				/>
				<ModulePartInput
					id="altText"
					label={translate(`modules.${MODULE_TYPE_IMAGE}.altTextLabel`)}
					onKeyDown={onKeyDown}
					onChange={handleChangeAltText}
					placeholder={translate(`modules.${MODULE_TYPE_IMAGE}.altTextPlaceholder`)}
					value={altText}
					validationError={validation ? validation['image.altText'] : null}
					{...rest}
				/>
				<ModulePartInput
					id="copyright"
					label={translate(`modules.${MODULE_TYPE_IMAGE}.copyrightLabel`)}
					onKeyDown={onKeyDown}
					onChange={handleChangeCopyright}
					placeholder={translate(`modules.${MODULE_TYPE_IMAGE}.copyrightPlaceholder`)}
					value={copyright}
					validationError={validation ? validation['image.copyright'] : null}
					{...rest}
				/>
			</InputWrapper>
		</Wrapper>
	);
}
