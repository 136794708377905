import { useTokenSet } from '@sep/cms-auth-client';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import config from '../config';
import { COOKIE_NAME_BR24_ACCESS_TOKEN_FOR_PREVIEW_MODE } from '../constants';

/**
 * this hook synchronizes the auth access token with a client side cookie
 * that will be used for our preview environment `preview.br24.de` and `preview-qs.br24.de`
 * when we try to navigate around (akamai) caching
 */
export const useSyncAccessTokenWithCookieForPreviewMode = () => {
	const [, setCookie, removeCookie] = useCookies();

	const tokenSet = useTokenSet();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const previewUrl = new URL(config.VITE_WEB_PREVIEW_CLIENT_URL_EXT);
			const { hostname } = previewUrl;
			let cookieDomain = 'localhost';

			if (hostname !== 'localhost') {
				const domainParts = hostname.split('.');
				const [domain, topLevelDomain] = domainParts.slice(-2);
				cookieDomain = `.${domain}.${topLevelDomain}`;
			}

			if (tokenSet?.access_token) {
				setCookie(COOKIE_NAME_BR24_ACCESS_TOKEN_FOR_PREVIEW_MODE, tokenSet.access_token, {
					path: '/',
					domain: cookieDomain,
				});
			} else {
				try {
					removeCookie(COOKIE_NAME_BR24_ACCESS_TOKEN_FOR_PREVIEW_MODE, {
						path: '/',
						domain: cookieDomain,
					});
				} catch {
					//
				}
			}
		}
	}, [removeCookie, setCookie, tokenSet?.access_token]);
};
