import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';
import type { Language } from '@/client/ui/ArticleMeta/ArticleMetaMore/languages';

import { ArticleMetaMore } from '../../../../ui/ArticleMeta';
import updateAction from '../../actions/update';
import { articleMetaMoreSelector } from '../../selectors';

import SophoraSearchContainer from './SophoraSearchContainer';

type State = {
	hasMigration: boolean;
};

interface Props extends ReduxProps {
	key: string | number;
}

class MoreContainer extends Component<Props, State> {
	state = {
		hasMigration: Array.isArray(this.props.redirections) && this.props.redirections.length > 0,
	};

	handleCheckboxChange = (key: string, value: boolean) => {
		this.props.update({ [key]: value });
	};

	handleMigrationChange = () => {
		if (Array.isArray(this.props.redirections) && this.props.redirections.length === 0) {
			this.setState((prevState) => ({
				hasMigration: !prevState.hasMigration,
			}));
		}
	};

	handleSelectLanguage = (value?: Language | null) => {
		this.props.update({ language: value as string });
	};

	render() {
		const {
			allowComments,
			enableAmp,
			isLive,
			language,
			externalId,
			update,
			redirections,
			...rest
		} = this.props;
		const { hasMigration } = this.state;

		return (
			<ArticleMetaMore
				allowComments={allowComments ?? undefined}
				enableAmp={enableAmp ?? undefined}
				hasMigration={hasMigration}
				isLive={isLive ?? undefined}
				externalId={externalId ?? undefined}
				language={language ?? undefined}
				onChange={this.handleCheckboxChange}
				onChangeMigration={this.handleMigrationChange}
				onSelectLanguage={this.handleSelectLanguage}
				sophoraSearch={<SophoraSearchContainer />}
				{...rest}
			/>
		);
	}
}

const connector = connect(articleMetaMoreSelector, {
	update: updateAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MoreContainer);
