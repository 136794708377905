import type { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import errorRecorder from '../../errorRecorder';

import ErrorFallback from './ErrorFallback';

export default function ({ children }: { children: ReactNode }): JSX.Element {
	return (
		<ErrorBoundary
			onError={(err, info) => {
				errorRecorder.captureError(err, info.componentStack);
			}}
			FallbackComponent={ErrorFallback}
		>
			{children}
		</ErrorBoundary>
	);
}
