import type { SetActiveGlobalGroupAction } from '../..';
import { SET_ACTIVE_GLOBAL_GROUP } from '../../actionTypes';

export default function setActiveGlobalGroup(
	activeGlobalGroup: string | null
): SetActiveGlobalGroupAction {
	return {
		type: SET_ACTIVE_GLOBAL_GROUP,

		payload: {
			activeGlobalGroup,
		},
	};
}
