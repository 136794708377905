const normalizeData = (data: { type: string; count: number }[]) =>
	data.reduce(
		(acc, { count, type }) => {
			if (type === 'VIDEO' || type === 'VIDEO360' || type === 'LIVESTREAM') {
				return { ...acc, video: acc.video + count, totalAv: acc.totalAv + count };
			}

			if (type === 'AUDIO') {
				return { ...acc, audio: acc.audio + count, totalAv: acc.totalAv + count };
			}

			if (type === 'IMAGE') {
				return { ...acc, text: acc.text + count, totalText: acc.totalText + count };
			}

			if (type === 'TEXT') {
				return { ...acc, breakingNews: acc.breakingNews + count, totalText: acc.totalText + count };
			}

			return acc;
		},
		{
			audio: 0,
			breakingNews: 0,
			text: 0,
			totalAv: 0,
			totalText: 0,
			video: 0,
		}
	);

export default normalizeData;
