import type { ReactNode } from 'react';

import BlockquoteNode from './BlockquoteNode';
import { OrderedListNode, UnorderedListNode } from './ListNode';
import styles from './formattingTypes.module.scss';

const SLATE_KIND_BLOCK = 'block';
const SLATE_KIND_MARK = 'mark';
const SLATE_KIND_INLINE = 'inline';

export type SlateKind = 'block' | 'mark' | 'inline';
export type SlateNextFunction = (children: any) => any;
export type SlateObject = {
	object: SlateKind;
	type: string;
	data: Map<any, any>;
};
export type SlateNode = {
	type: string;
	data: Map<any, any>;
};
export type SlateMark = {
	type: string;
};
export type SlateAttributes = {
	[key: string]: any;
};
export type SlateRenderMarkProps = {
	children: ReactNode;
	mark: SlateMark;
	attributes: SlateAttributes;
};
export type SlateRenderNodeProps = {
	children: ReactNode;
	node: SlateNode;
	attributes: SlateAttributes;
	isSelected: boolean;
};

//                  _        _
//                 | |      | |
//  _ __   ___   __| | ___  | |_ _   _ _ __   ___  ___
// | '_ \ / _ \ / _` |/ _ \ | __| | | | '_ \ / _ \/ __|
// | | | | (_) | (_| |  __/ | |_| |_| | |_) |  __/\__ \
// |_| |_|\___/ \__,_|\___|  \__|\__, | .__/ \___||___/
//                                __/ | |
//                               |___/|_|
//
// NOTE: keep the exported constants and the flow-types in sync

export const TYPE_NODE_BLOCKQUOTE = 'blockquote';
export const TYPE_NODE_HEADING_1 = 'heading-one';
export const TYPE_NODE_HEADING_2 = 'heading-two';
export const TYPE_NODE_HEADING_3 = 'heading-three';
export const TYPE_NODE_HEADING_4 = 'heading-four';
export const TYPE_NODE_HEADING_5 = 'heading-five';
export const TYPE_NODE_HEADING_6 = 'heading-six';
export const TYPE_NODE_LINK = 'link';
export const TYPE_NODE_LIST_ITEM = 'list-item';
export const TYPE_NODE_ORDERED_LIST = 'numbered-list';
export const TYPE_NODE_PARAGRAPH = 'paragraph';
export const TYPE_NODE_UNORDERED_LIST = 'bulleted-list';

export type NodeType =
	| 'blockquote'
	| 'bulleted-list'
	| 'heading-five'
	| 'heading-four'
	| 'heading-one'
	| 'heading-six'
	| 'heading-three'
	| 'heading-two'
	| 'link'
	| 'list-item'
	| 'numbered-list'
	| 'paragraph';

//                       _      _
//                      | |    | |
//  _ __ ___   __ _ _ __| | __ | |_ _   _ _ __   ___  ___
// | '_ ` _ \ / _` | '__| |/ / | __| | | | '_ \ / _ \/ __|
// | | | | | | (_| | |  |   <  | |_| |_| | |_) |  __/\__ \
// |_| |_| |_|\__,_|_|  |_|\_\  \__|\__, | .__/ \___||___/
//                                   __/ | |
//                                  |___/|_|
//
// // NOTE: keep the exported constants and the flow-types in sync

export const TYPE_MARK_BOLD = 'bold';
export const TYPE_MARK_ITALIC = 'italic';
export const TYPE_MARK_UNDERLINE = 'underline';

export type MarkType = 'bold' | 'italic' | 'underline';

export type FormattingType = MarkType | NodeType;
export type FormattingRule = {
	tag: string;
	object: SlateKind;
	type: FormattingType;
	// used by renderMark / renderNode to generate the react-element used by the slate editor
	render(item: SlateRenderMarkProps & SlateRenderNodeProps): ReactNode;
	// deserializes a HTML-element to a slate compatible object
	deserialize(element: HTMLElement, next: SlateNextFunction): void | any;
	// converts a slate object into a reat-node (html converter)
	serialize(object: SlateObject, children: any): void | ReactNode;
};

export const NodeParagraph: FormattingRule = {
	tag: 'p',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_PARAGRAPH,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <p>{children}</p>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <p {...attributes}>{children}</p>;
	},
};

export const NodeListItem: FormattingRule = {
	tag: 'li',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_LIST_ITEM,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <li>{children}</li>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <li {...attributes}>{children}</li>;
	},
};

export const NodeUnorderedList: FormattingRule = {
	tag: 'ul',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_UNORDERED_LIST,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <ul>{children}</ul>;
	},
	render: UnorderedListNode,
};

export const NodeOrderedList: FormattingRule = {
	tag: 'ol',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_ORDERED_LIST,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <ol>{children}</ol>;
	},
	render: OrderedListNode,
};

export const NodeHeading1: FormattingRule = {
	tag: 'h1',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_HEADING_1,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <h1>{children}</h1>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <h1 {...attributes}>{children}</h1>;
	},
};

export const NodeHeading2: FormattingRule = {
	tag: 'h2',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_HEADING_2,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <h2>{children}</h2>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <h2 {...attributes}>{children}</h2>;
	},
};

export const NodeHeading3: FormattingRule = {
	tag: 'h3',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_HEADING_3,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <h3>{children}</h3>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <h3 {...attributes}>{children}</h3>;
	},
};

export const NodeHeading4: FormattingRule = {
	tag: 'h4',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_HEADING_4,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <h4>{children}</h4>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <h4 {...attributes}>{children}</h4>;
	},
};

export const NodeHeading5: FormattingRule = {
	tag: 'h5',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_HEADING_5,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <h5>{children}</h5>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <h5 {...attributes}>{children}</h5>;
	},
};

export const NodeHeading6: FormattingRule = {
	tag: 'h6',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_HEADING_6,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <h6>{children}</h6>;
	},
	render({ attributes, children }: SlateRenderNodeProps) {
		return <h6 {...attributes}>{children}</h6>;
	},
};

export const NodeLink: FormattingRule = {
	tag: 'a',
	object: SLATE_KIND_INLINE,
	type: TYPE_NODE_LINK,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
			data: {
				href: element.getAttribute('href'),
			},
		};
	},
	serialize(object: SlateObject, children: any) {
		if (object.object !== 'inline') {
			return undefined;
		}

		return <a href={object.data.get('href')}>{children}</a>;
	},
	render({ attributes, children, node }: SlateRenderNodeProps) {
		const { data } = node;
		const href = data.get('href');

		return (
			<a className={styles.editorLink} href={href} {...attributes}>
				{children}
			</a>
		);
	},
};

export const NodeBlockquote: FormattingRule = {
	tag: 'blockquote',
	object: SLATE_KIND_BLOCK,
	type: TYPE_NODE_BLOCKQUOTE,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <blockquote>{children}</blockquote>;
	},
	render: BlockquoteNode,
};

export const NodeStandard: FormattingRule = NodeParagraph;

export const NODES: Map<string, FormattingRule> = new Map([
	[NodeParagraph.type, NodeParagraph],
	[NodeListItem.type, NodeListItem],
	[NodeUnorderedList.type, NodeUnorderedList],
	[NodeOrderedList.type, NodeOrderedList],
	[NodeHeading1.type, NodeHeading1],
	[NodeHeading2.type, NodeHeading2],
	[NodeHeading3.type, NodeHeading3],
	[NodeHeading4.type, NodeHeading4],
	[NodeHeading5.type, NodeHeading5],
	[NodeHeading6.type, NodeHeading6],
	[NodeLink.type, NodeLink],
	[NodeBlockquote.type, NodeBlockquote],
]);

export const MarkBold: FormattingRule = {
	tag: 'b',
	object: SLATE_KIND_MARK,
	type: TYPE_MARK_BOLD,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <b>{children}</b>;
	},
	render({ attributes, children }: SlateRenderMarkProps) {
		return <b {...attributes}>{children}</b>;
	},
};

export const MarkItalic: FormattingRule = {
	tag: 'em',
	object: SLATE_KIND_MARK,
	type: TYPE_MARK_ITALIC,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <em>{children}</em>;
	},
	render({ attributes, children }: SlateRenderMarkProps) {
		return <em {...attributes}>{children}</em>;
	},
};

export const MarkUnderline: FormattingRule = {
	tag: 'u',
	object: SLATE_KIND_MARK,
	type: TYPE_MARK_UNDERLINE,
	deserialize(element: HTMLElement, next: SlateNextFunction) {
		return {
			object: this.object,
			type: this.type,
			nodes: next(element.childNodes),
		};
	},
	serialize(_: SlateObject, children: any) {
		return <u>{children}</u>;
	},
	render({ attributes, children }: SlateRenderMarkProps) {
		return <u {...attributes}>{children}</u>;
	},
};

export const MARKS: Map<string, FormattingRule> = new Map([
	[MarkBold.type, MarkBold],
	[MarkItalic.type, MarkItalic],
	[MarkUnderline.type, MarkUnderline],
]);
