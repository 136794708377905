/**
 * @generated SignedSource<<fde3deff5f5171aedb70a8ac04661a2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertNewAuthorInput = {
  clientMutationId?: string | null;
  email?: string | null;
  firstname?: string | null;
  guid?: string | null;
  lastname?: string | null;
};
export type InsertNewAuthorMutation$variables = {
  input: InsertNewAuthorInput;
};
export type InsertNewAuthorMutation$data = {
  readonly insertNewAuthor: {
    readonly wasInserted: boolean | null;
  } | null;
};
export type InsertNewAuthorMutation = {
  response: InsertNewAuthorMutation$data;
  variables: InsertNewAuthorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InsertNewAuthorPayload",
    "kind": "LinkedField",
    "name": "insertNewAuthor",
    "plural": false,
    "selections": [
      {
        "alias": "wasInserted",
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertNewAuthorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsertNewAuthorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cf472852081e17f28ee570746e02c415",
    "id": null,
    "metadata": {},
    "name": "InsertNewAuthorMutation",
    "operationKind": "mutation",
    "text": "mutation InsertNewAuthorMutation(\n  $input: InsertNewAuthorInput!\n) {\n  insertNewAuthor(input: $input) {\n    wasInserted: boolean\n  }\n}\n"
  }
};
})();

(node as any).hash = "108bfd306b4816ce27a3427d47902c9f";

export default node;
