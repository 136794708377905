import { DND_BOARD_ITEM } from '@sep/br24-constants';
import * as util from '@sep/br24-util';

import * as articleEditorItemTypes from '../../ArticleEditor/itemTypes';
import {
	IMPORTED_AUDIO,
	IMPORTED_VIDEO,
	IMPORTED_TEXT,
	IMPORTED_IMAGE,
	IMPORTED_BRBILD_IMAGE,
	IMPORTED_LIVESTREAM,
	IMPORTED_ITEM_GROUP,
	IMPORTED_ARTICLE,
	IMPORTED_BOARD,
	IMPORTED_EMBED,
	IMPORTED_GALLERY,
} from '../AssetManagerImporter/itemTypes';
import {
	AM_BOARD_TEASER,
	AM_BOARD,
	AM_ARTICLE,
	AM_TEXT,
	AM_LINK,
	AM_EMBED_CODE,
	AM_GALLERY,
	AM_IMAGE,
	AM_AUDIO,
	AM_VIDEO360,
	AM_VIDEO,
	AM_LIVESTREAM,
	AM_BRBILD_IMAGE,
} from '../itemTypes';

// TOOD: MISSING -> AUTHOR, EMBED, VIDEO360
export default function isDroppable(type: string | symbol | null, payload: any): boolean {
	if (typeof payload !== 'object') {
		return false;
	}

	switch (type) {
		case IMPORTED_AUDIO:
		case IMPORTED_VIDEO:
		case IMPORTED_TEXT:
		case IMPORTED_IMAGE:
		case IMPORTED_BRBILD_IMAGE:
		case IMPORTED_LIVESTREAM:
		case IMPORTED_ARTICLE:
		case IMPORTED_BOARD:
		case IMPORTED_EMBED:
		case IMPORTED_GALLERY:
			return !!payload.importedItem;

		case IMPORTED_ITEM_GROUP:
			return !!payload.importedItemGroupItems;

		case DND_BOARD_ITEM:
			return !!payload?.type;

		case articleEditorItemTypes.AE_ARTICLE:
			// required: articleId
			return !!payload?.articleId;

		case articleEditorItemTypes.AE_LINK:
			// TODO: check if the link is present
			return true;

		case articleEditorItemTypes.AE_VIDEO:
			// required: video.url OR video.title OR video.copyright OR video.thumbnail.url OR
			// video.thumbnail.title OR video.thumbnail.copyright OR video.thumbnail.altText
			return (
				!!payload?.video?.url ||
				!!payload?.video?.title ||
				!!payload?.video?.copyright ||
				!!payload?.video?.thumbnail?.url ||
				!!payload?.video?.thumbnail?.title ||
				!!payload?.video?.thumbnail?.copyright ||
				!!payload?.video?.thumbnail?.altText
			);

		case articleEditorItemTypes.AE_TEXT: {
			// required: text
			const text = payload?.text;

			if (!text) {
				return false;
			}

			return util.string.stripTags(text).length > 0;
		}

		case articleEditorItemTypes.AE_IMAGE:
			// required: image.url OR image.title OR image.copyright OR image.altText
			return (
				!!payload?.image.url ||
				!!payload?.image.title ||
				!!payload?.image.copyright ||
				!!payload?.image.altText
			);

		case articleEditorItemTypes.AE_GALLERY: {
			// required on each image in the gallery property: url OR title OR
			// copyright OR altText
			const { gallery } = payload;

			if (!Array.isArray(gallery) || gallery.length === 0) {
				return false;
			}

			return gallery.every(
				(image) => !!image?.url || !!image?.title || !!image?.copyright || !!image?.altText
			);
		}

		case articleEditorItemTypes.AE_AUDIO:
			// required: audio.url OR audio.title OR audio.copyright OR audio.thumbnail.url OR
			// audio.thumbnail.title OR audio.thumbnail.copyright OR audio.thumbnail.altText
			return (
				!!payload?.audio?.url ||
				!!payload?.audio?.title ||
				!!payload?.audio?.copyright ||
				!!payload?.audio?.thumbnail?.url ||
				!!payload?.audio?.thumbnail?.title ||
				!!payload?.audio?.thumbnail?.copyright ||
				!!payload?.audio?.thumbnail?.altText
			);

		case articleEditorItemTypes.AE_LIVESTREAM:
			// required: livestream.livestreamId AND livestream.programmeId
			return !!payload?.livestream?.livestreamId && !!payload?.livestream?.programmeId;

		case articleEditorItemTypes.AE_EMBED_CODE:
			return !!payload?.embed?.altText && !!payload?.embed?.source;

		// If it is already in the Asset Manager Space, it should be safely droppable to another Asset Manager Space (private -> global)
		case AM_BOARD_TEASER:
		case AM_BOARD:
		case AM_ARTICLE:
		case AM_TEXT:
		case AM_LINK:
		case AM_EMBED_CODE:
		case AM_GALLERY:
		case AM_IMAGE:
		case AM_BRBILD_IMAGE:
		case AM_AUDIO:
		case AM_VIDEO360:
		case AM_VIDEO:
		case AM_LIVESTREAM:
			return true;
		default:
			break;
	}

	return false;
}
