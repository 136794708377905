import { darken } from 'polished';
import styled from 'styled-components';

import { ArrowLeft } from '../../Icon';

type AssetManagerFilterCloseButtonProps = {
	onClick: any;
};

const FilterCloseButton = styled.button`
	width: 40px;
	height: 40px;
	border: none;
	display: block;
	background-color: ${(props) => props.theme.colors.primaryBlue};
	color: #ffffff;
	outline: none;
	cursor: pointer;
	&:hover {
		color: #ffffff;
		background-color: ${(props) => darken(0.1, props.theme.colors.primaryBlue)};
	}
`;

function AssetManagerFilterCloseButton(props: AssetManagerFilterCloseButtonProps) {
	return (
		<FilterCloseButton onClick={props.onClick}>
			<ArrowLeft />
		</FilterCloseButton>
	);
}

export default AssetManagerFilterCloseButton;
