import { MODULE_TYPE_EMBED } from '@sep/br24-constants';
import { Button, Spin } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import config from '../../../config';
import type { WithTranslationProps } from '../../../translation';
import ErrorMessageText from '../../ErrorMessageText';

const PreviewButtonWrapper = styled.div`
	width: 100%;
	text-align: right;
	margin-top: 10px;
	margin-bottom: 25px;
`;

const IFrameWrapper = styled.div`
	text-align: center;
	margin-bottom: 25px;
`;

const IFrame = styled.iframe<{ iframeHeight: string; iframeWidth: string }>`
	height: ${(props) => `${props.iframeHeight}`};
	width: ${(props) => `${props.iframeWidth}`};
	border: none;
	overflow: hidden;
`;

const SpinnerWrapper = styled.div`
	height: 200px;
	text-align: center;
`;

type Props = WithTranslationProps & {
	previewUrl: string;
	validation: any;
	source: string;
	service: string | null | undefined;
	onChange: (input: { [key: string]: string | undefined | null }) => void;
};

type State = {
	iframeHeight: string;
	iframeWidth: string;
	hasEmbedLoaded: boolean;
};

class ArticleModuleEmbedPreview extends Component<Props, State> {
	state = {
		iframeHeight: '200px',
		iframeWidth: '100%',
		hasEmbedLoaded: false,
	};

	componentDidMount() {
		window.addEventListener('message', this.handleResize, false);

		// Neccessary as EBU renders in its own iFrame
		if (this.props.service === 'ebu') {
			this.setState({ iframeHeight: '452px' });
		}
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.handleResize, false);
	}

	buildPreviewUrl = () => {
		const { service, source } = this.props;
		return `${
			config.VITE_RENDERER_EMBEDS_URL_EXT
		}/embed?service=${service}&source=${encodeURIComponent(source)}`;
	};

	handleEditButtonClick = () => {
		this.props.onChange({ url: null });
	};

	handleEmbedLoaded = () => {
		this.setState({
			hasEmbedLoaded: true,
		});
	};

	handleResize = (event: MessageEvent<any>) => {
		if (event.origin === config.VITE_RENDERER_EMBEDS_URL_EXT) {
			const isSameUrl = event.data.payload.url === this.buildPreviewUrl();
			let sizes: RegExpMatchArray | null = null;

			if (this.props.source) {
				const sizeRegEx = /width="([0-9]+)" height="([0-9]+)"/;
				sizes = this.props.source.match(sizeRegEx);
			}

			if (isSameUrl && this.props.service === 'facebook') {
				// default is a 16:9 view
				let width = '500px';
				let height = '281px';

				if (sizes && sizes[1] && sizes[2]) {
					width = `${parseInt(sizes[1], 10) + 10}px`;
					height = `${parseInt(sizes[2], 10) + 10}px`;
				}

				this.setState({
					iframeHeight: height,
					iframeWidth: width,
				});

				return;
			}

			if (isSameUrl && this.props.service === 'opinary') {
				this.setState({
					iframeHeight: sizes ? `${sizes[1]}px` : '500px',
				});

				return;
			}

			if (isSameUrl && this.props.service === 'scribble') {
				this.setState({
					iframeHeight: sizes ? `${parseInt(sizes[1], 10) + 10}px` : '510px',
					iframeWidth: sizes ? `${parseInt(sizes[2], 10) + 10}px` : '510px',
				});

				return;
			}

			if (
				isSameUrl &&
				event.data.payload.height > parseInt(this.state.iframeHeight.replace('px', ''), 10)
			) {
				const iframeHeight = event.data.payload.height;

				this.setState({
					iframeHeight: `${iframeHeight}px`,
				});
			}
		}
	};

	render() {
		const {
			validation,
			translation: { translate },
		} = this.props;

		const { iframeHeight, iframeWidth, hasEmbedLoaded } = this.state;

		return (
			<>
				<ErrorMessageText
					text={validation && validation.embed ? validation.embed : null}
					hasBottomMargin={true}
				/>
				{!hasEmbedLoaded && (
					<SpinnerWrapper>
						<Spin />
					</SpinnerWrapper>
				)}
				<IFrameWrapper>
					<IFrame
						iframeHeight={iframeHeight}
						iframeWidth={iframeWidth}
						src={this.buildPreviewUrl()}
						frameBorder="0"
						onLoad={this.handleEmbedLoaded}
					/>
				</IFrameWrapper>
				<PreviewButtonWrapper>
					<Button
						type="primary"
						disabled={validation && validation['embed.service']}
						onClick={this.handleEditButtonClick}
					>
						{translate(`modules.${MODULE_TYPE_EMBED}.editEmbedText`)}
					</Button>
				</PreviewButtonWrapper>
			</>
		);
	}
}

export default withTranslation(ArticleModuleEmbedPreview);
