import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './index';
const container = document.getElementById('root');

const FullApp = () => (
	<StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</StrictMode>
);

if (import.meta.hot || !container?.innerText) {
	ReactDOM.render(<FullApp />, container);
} else {
	ReactDOM.hydrate(<FullApp />, container);
}
