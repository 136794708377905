import { Input } from 'antd';
import { debounce } from 'lodash-es';
import { type ChangeEvent, type CSSProperties, useEffect, useMemo } from 'react';

const { Search } = Input;

type Props = {
	onChange: (value: string) => void;
	value?: string | null;
	defaultValue?: string | null;
	delay?: number;
	placeholder?: string | null;
	style?: CSSProperties;
};

export default function DelayedSearch({
	onChange,
	value,
	defaultValue,
	delay = 500,
	placeholder,
	style,
}: Props) {
	const debouncedOnChange = useMemo(
		() =>
			debounce((value) => {
				onChange(String(value));
			}, delay),
		[delay, onChange]
	);

	useEffect(() => debouncedOnChange.cancel(), [debouncedOnChange]);

	function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
		debouncedOnChange(value);
	}

	return (
		<Search
			defaultValue={defaultValue ?? undefined}
			style={style}
			onChange={handleChange}
			value={value ?? undefined}
			placeholder={placeholder ?? undefined}
		/>
	);
}
