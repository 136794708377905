/**
 * @generated SignedSource<<aaa80eb72e9d42e55638f9e9083929b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BoardsTeaserStatus = "DELETED" | "PUBLISHED";
export type AssetManagerItemBoardTeaserQuery$variables = {
  boardsTeaserId: string;
};
export type AssetManagerItemBoardTeaserQuery$data = {
  readonly teaser: {
    readonly image: {
      readonly url: string | null;
    } | null;
    readonly rowId: string;
    readonly status: BoardsTeaserStatus | null;
    readonly title: string;
  } | null;
};
export type AssetManagerItemBoardTeaserQuery = {
  response: AssetManagerItemBoardTeaserQuery$data;
  variables: AssetManagerItemBoardTeaserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardsTeaserId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "boardsTeaserId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetManagerItemBoardTeaserQuery",
    "selections": [
      {
        "alias": "teaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssetManagerItemBoardTeaserQuery",
    "selections": [
      {
        "alias": "teaser",
        "args": (v1/*: any*/),
        "concreteType": "BoardsTeaser",
        "kind": "LinkedField",
        "name": "boardsTeaserByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "537a7983699e97c1f7389132ad9243d7",
    "id": null,
    "metadata": {},
    "name": "AssetManagerItemBoardTeaserQuery",
    "operationKind": "query",
    "text": "query AssetManagerItemBoardTeaserQuery(\n  $boardsTeaserId: String!\n) {\n  teaser: boardsTeaserByRowId(rowId: $boardsTeaserId) {\n    rowId\n    title\n    status\n    image {\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7fea78ad20b70a1b7be8a7fd7a6f1561";

export default node;
