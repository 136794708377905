import { Row, Col } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';
import type { IterableElement } from 'type-fest';

import { withTranslation } from '@/client/translation/withTranslation';

import styles from '../../styles';
import type { WithTranslationProps } from '../../translation';
import type { Author_author$data } from '../Author/__generated__/Author_author.graphql';

import AccountListEditor from './AccountListItem/AccountListEditor';
import { AccountListItem } from './AccountListItem/AccountListItem';

const ItemList = styled.ul`
	list-style-type: none;
`;

type State = {
	affectedIndex: number | undefined | null;
};

export type SocialMediaAccount = NonNullable<
	IterableElement<Author_author$data['socialMediaAccounts']>
>;

interface Props extends WithTranslationProps {
	key?: string;
	items: SocialMediaAccount[];
	onChange?: (value?: SocialMediaAccount[]) => void;
	onAdd?: (socialMediaAccount: SocialMediaAccount) => void;
	onDelete?: (socialMediaAccount: SocialMediaAccount) => void;
	onUpdate?: (socialMediaAccount: SocialMediaAccount) => void;
	onEditStatChange?: (isEditing: boolean) => void;
}

class SocialMediaAccounts extends Component<Props, State> {
	state = {
		affectedIndex: null,
	};

	handleDeleteClick = (affectedIndex: number) => {
		const { onDelete, onChange } = this.props;

		if (
			typeof onDelete === 'function' &&
			Array.isArray(this.props.items) &&
			this.props.items[affectedIndex]
		) {
			onDelete(this.props.items[affectedIndex]);
		}

		const nextData = (this.props.items || []).filter((_node, index) => index !== affectedIndex);
		onChange?.(nextData);
	};

	handleEditEnded = (index: number) => {
		if (index === this.state.affectedIndex) {
			this.setState({ affectedIndex: null });
			this.props.onEditStatChange?.(false);
		}
	};

	handleEditStarted = (index: number) => {
		this.setState({ affectedIndex: index });
		this.props.onEditStatChange?.(true);
	};

	handleSave = (data: SocialMediaAccount): void => {
		const { onAdd, onUpdate, onChange } = this.props;
		const { affectedIndex } = this.state;
		const { service, accountName, label } = data ?? {};

		if (typeof affectedIndex === 'number') {
			if (
				typeof onUpdate === 'function' &&
				Array.isArray(this.props.items) &&
				this.props.items[affectedIndex]
			) {
				const affectedAccount = this.props.items[affectedIndex];
				onUpdate(
					Object.assign(affectedAccount, {
						service,
						accountName,
						label,
					})
				);
			}

			if (typeof onChange === 'function') {
				onChange(
					this.props.items?.map((socialMediaAccount, index) =>
						index === affectedIndex ? data : socialMediaAccount
					)
				);
			}
		} else {
			if (typeof onAdd === 'function') {
				onAdd(data);
			}

			if (typeof onChange === 'function') {
				onChange([...(this.props.items || []), data]);
			}
		}
	};

	render() {
		const { items } = this.props;

		return (
			<div>
				<Row className={styles.mbM}>
					<Col span={24}>
						<AccountListEditor addMode={true} onSave={this.handleSave} />
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<ItemList>
							{items
								? items.map((node, index) => (
										<AccountListItem
											// eslint-disable-next-line react/no-array-index-key
											key={index}
											index={index}
											item={node}
											isEditable={true}
											onDelete={() => this.handleDeleteClick(index)}
											onEditStarted={this.handleEditStarted}
											onEditEnded={this.handleEditEnded}
											onSave={this.handleSave}
										/>
								  ))
								: 'Keine Social-Media-Accounts verbunden.'}
						</ItemList>
					</Col>
				</Row>
			</div>
		);
	}
}

export default withTranslation(SocialMediaAccounts);
