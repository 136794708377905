import { Row, Col } from 'antd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { ErrorSolid } from '../../Icon';
import ArticleMetaDraggableListItem from '../ArticleMetaDraggableListItem';

import SophoraSearchLinkItem from './SophoraSearchLinkItem';

const HelpContainerRow = styled(Row)`
	margin-top: 10px;
`;

const ExclamationMark = styled(ErrorSolid)`
	color: ${(props) => props.theme?.colors.yellowExclMark};
`;

type Props = {
	connectedSophoraIds: Array<any>;
	onRemove: (id: string | number) => void;
};

export default function SophoraSearchLinkList({ connectedSophoraIds, onRemove }: Props) {
	const translate = useTranslate();

	return (
		<div>
			{Array.isArray(connectedSophoraIds) && connectedSophoraIds.length > 0
				? connectedSophoraIds.map((item) => (
						<ArticleMetaDraggableListItem
							key={item.sophoraId}
							onRemove={onRemove}
							index={item.rowId}
							isDraggable={false}
						>
							<SophoraSearchLinkItem sophoraId={item.sophoraId} />
						</ArticleMetaDraggableListItem>
				  ))
				: null}
			<HelpContainerRow gutter={24}>
				<Col span={1}>
					<ExclamationMark />
				</Col>
				<Col span={22}>{translate('article.meta.more.sophoraSearch.help')}</Col>
			</HelpContainerRow>
		</div>
	);
}
