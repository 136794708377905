/**
 * @generated SignedSource<<91467876bc2c40db2854585b17a3a488>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MangoSeriesFilter = {
  audiences?: MangoAudienceConnectionFilter | null;
  audioOnly?: MangoBooleanFilter | null;
  audiothekURLS?: MangoExternalURLConnectionFilter | null;
  authors?: MangoAgentConnectionFilter | null;
  brandingImages?: MangoImageConnectionFilter | null;
  categories?: MangoCategoryConnectionFilter | null;
  categorizations?: MangoCategorizationConnectionFilter | null;
  copyright?: MangoStringFilter | null;
  createdAt?: MangoDateFilter | null;
  credits?: MangoAgentConnectionFilter | null;
  defaultTeaserImage?: MangoImageRelationFilter | null;
  description?: MangoStringFilter | null;
  essences?: MangoFileConnectionFilter | null;
  executiveProducers?: MangoAgentConnectionFilter | null;
  externalURLS?: MangoExternalURLConnectionFilter | null;
  facebookAccounts?: MangoFacebookAccountConnectionFilter | null;
  formats?: MangoFormatConnectionFilter | null;
  genres?: MangoGenreConnectionFilter | null;
  homePages?: MangoExternalURLConnectionFilter | null;
  importedFrom?: MangoImportLocatorConnectionFilter | null;
  kicker?: MangoStringFilter | null;
  linksTo?: MangoLinkableThingRelationFilter | null;
  modifiedAt?: MangoDateFilter | null;
  newerVersion?: MangoVersionedThingRelationFilter | null;
  noIndex?: MangoBooleanFilter | null;
  olderVersion?: MangoVersionedThingRelationFilter | null;
  partOf?: MangoGroupingConnectionFilter | null;
  podcastURLS?: MangoExternalURLConnectionFilter | null;
  producingDepartments?: MangoDepartmentConnectionFilter | null;
  publications?: MangoPublicationEventConnectionFilter | null;
  relatedLinks?: MangoExternalURLConnectionFilter | null;
  scheduleInfo?: MangoStringFilter | null;
  shortDescription?: MangoStringFilter | null;
  status?: MangoStatusRelationFilter | null;
  subjects?: MangoSubjectConnectionFilter | null;
  subscribed?: MangoBooleanFilter | null;
  tags?: MangoTagConnectionFilter | null;
  teaserImages?: MangoImageConnectionFilter | null;
  teasers?: MangoCreativeWorkConnectionFilter | null;
  term?: string | null;
  title?: MangoStringFilter | null;
  trackingInfos?: MangoTrackingInfoRelationFilter | null;
  twitterAccounts?: MangoTwitterAccountConnectionFilter | null;
  versionFrom?: MangoDateFilter | null;
};
export type MangoAudienceConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoBooleanFilter = {
  eq?: boolean | null;
  ne?: boolean | null;
};
export type MangoExternalURLConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoAgentConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoImageConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoCategoryConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoCategorizationConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoStringFilter = {
  endsWith?: string | null;
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string | null> | null;
  lt?: string | null;
  lte?: string | null;
  matches?: string | null;
  ne?: string | null;
  startsWith?: string | null;
};
export type MangoDateFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string | null> | null;
  lt?: string | null;
  lte?: string | null;
  ne?: string | null;
};
export type MangoImageRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoIDFilter = {
  eq?: string | null;
  in?: ReadonlyArray<string | null> | null;
  ne?: string | null;
};
export type MangoFileConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoFacebookAccountConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoFormatConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoGenreConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoImportLocatorConnectionFilter = {
  createdAt?: MangoDateFilter | null;
  empty?: MangoBooleanFilter | null;
  identifier?: MangoStringFilter | null;
  modifiedAt?: MangoDateFilter | null;
  system?: MangoSystemRelationFilter | null;
};
export type MangoSystemRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoLinkableThingRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoVersionedThingRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoGroupingConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoDepartmentConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoPublicationEventConnectionFilter = {
  createdAt?: MangoDateFilter | null;
  empty?: MangoBooleanFilter | null;
  modifiedAt?: MangoDateFilter | null;
  publishedBy?: MangoPublicationServiceRelationFilter | null;
};
export type MangoPublicationServiceRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoStatusRelationFilter = {
  exists?: MangoBooleanFilter | null;
  id?: MangoIDFilter | null;
};
export type MangoSubjectConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoTagConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoCreativeWorkConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type MangoTrackingInfoRelationFilter = {
  createdAt?: MangoDateFilter | null;
  modifiedAt?: MangoDateFilter | null;
};
export type MangoTwitterAccountConnectionFilter = {
  contains?: string | null;
  empty?: MangoBooleanFilter | null;
};
export type SourceOriginRendererQuery$variables = {
  filter?: MangoSeriesFilter | null;
  hasSourceOrigin?: boolean | null;
  sourceOriginRowId: string;
};
export type SourceOriginRendererQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SourceOriginContainerRefetch_query">;
};
export type SourceOriginRendererQuery = {
  response: SourceOriginRendererQuery$data;
  variables: SourceOriginRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "hasSourceOrigin"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceOriginRowId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SourceOriginRendererQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SourceOriginContainerRefetch_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SourceOriginRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "searchSourceOrigins",
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              }
            ],
            "concreteType": "MangoSeriesConnection",
            "kind": "LinkedField",
            "name": "allSeries",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MangoSeriesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "condition": "hasSourceOrigin",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "sourceOriginRowId"
                  }
                ],
                "concreteType": null,
                "kind": "LinkedField",
                "name": "series",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c311afe949e0c6e21a9d51e9f81bd02c",
    "id": null,
    "metadata": {},
    "name": "SourceOriginRendererQuery",
    "operationKind": "query",
    "text": "query SourceOriginRendererQuery(\n  $filter: MangoSeriesFilter\n  $sourceOriginRowId: ID!\n  $hasSourceOrigin: Boolean = false\n) {\n  ...SourceOriginContainerRefetch_query\n}\n\nfragment SourceOriginContainerRefetch_query on Query {\n  viewer {\n    searchSourceOrigins: allSeries(first: 20, filter: $filter) {\n      edges {\n        node {\n          __typename\n          id\n          title\n        }\n      }\n    }\n    series(id: $sourceOriginRowId) @include(if: $hasSourceOrigin) {\n      __typename\n      id\n      title\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4115d2a7cdec39f1a3fcee45ecee6cc";

export default node;
