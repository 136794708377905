import { DISTRICTS_ALL } from '@sep/br24-constants';
import { Select } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';
import { upperCaseFirst } from '@/client/util/br24Utils';
import type { District } from '@/types/schema';

import type { WithTranslationProps } from '../../../translation';

const FullWidthInput = styled(Select<District[]>)`
	width: 100%;
	.ant-select-selection__choice {
		background-color: ${(props) => props.theme.colors.light1} !important;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

interface Props extends WithTranslationProps {
	districts?: District[] | null;
	onChange: (districts: District[]) => void;
	onSelect: (value: District) => void;
	onDeselect: (value: District) => void;
}

class ArticleMetaGeolocationDistrict extends Component<Props> {
	handleChange = (districts: District[]) => {
		if (districts) {
			this.props.onChange(districts);
		}
	};

	handleDeselect = (value: District) => {
		if (value) {
			this.props.onDeselect(value);
		}
	};

	handleSelect = (value: District) => {
		if (value) {
			this.props.onSelect(value);
		}
	};

	render() {
		const {
			districts,
			translation: { translate },
		} = this.props;

		return (
			<Row>
				<FullWidthInput
					mode="multiple"
					onChange={this.handleChange}
					onSelect={this.handleSelect}
					onDeselect={this.handleDeselect}
					placeholder={translate('article.meta.geolocation.districtPlaceholder')}
					value={districts || []}
					data-testid="select"
				>
					{DISTRICTS_ALL.map((district, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Select.Option key={`district-${index}`} value={district}>
							{upperCaseFirst(district.toLowerCase())}
						</Select.Option>
					))}
				</FullWidthInput>
			</Row>
		);
	}
}

export default withTranslation(ArticleMetaGeolocationDistrict);
