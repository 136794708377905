import type { User } from '@sep/cms-auth-client';
import { onUserChange } from '@sep/cms-auth-client';

import type { AppThunkAction } from '@/client/store/redux';

import type {
	Space,
	Item,
	AddGlobalGroupsAction,
	AddItemsAction,
	SetFirebaseConnectedAction,
	GlobalGroup,
} from '..';
import config from '../../../config';
import { AM_PERSONAL_SPACE, AM_TYPE_GROUP } from '../../../constants';
import {
	eventBroadcaster,
	ACTION_CHILD_ADDED,
	ACTION_CHILD_CHANGED,
	ACTION_CHILD_REMOVED,
	fetchSpace,
} from '../database.init';

import { addItems } from './addItems';
import { cleanSpace } from './cleanSpace';
import addGlobalGroups from './global/addGlobalGroups';
import removeGlobalGroup from './global/removeGlobalGroup';
import setFirebaseConnected from './global/setFirebaseConnected';
import { updateGlobalGroup } from './global/updateGlobalGroup';
import removeItem from './removeItem';
import updateItem from './updateItem';
import updateVisibility from './updateVisibility';

function initialize(
	user: User | undefined
): AppThunkAction<AddGlobalGroupsAction | AddItemsAction | SetFirebaseConnectedAction> {
	return async (dispatch) => {
		const fetchSpaces = async () => {
			try {
				const [initialGlobal = [], initialPersonal = []] = await Promise.all([
					fetchSpace('global'),
					fetchSpace('personal'),
				]);

				if (initialGlobal.length > 0) {
					dispatch(addGlobalGroups(initialGlobal, { persist: false, user }));
				}

				if (initialPersonal.length > 0) {
					dispatch(addItems(AM_PERSONAL_SPACE, initialPersonal, { persist: false }));
				}

				dispatch(setFirebaseConnected(true));
				// eslint-disable-next-line no-console
				console.debug('🗄 Asset Manager Spaces fetched!');
			} catch (err) {
				if (config.VITE_BRANCH !== 'master') {
					// dispatch(setFirebaseConnected(true));
					// eslint-disable-next-line no-console
					console.error('🗄 Initializing Asset Manager Spaces database failed: ', err);
				}
			}
		};

		await fetchSpaces();

		// HINT: I don't think we need that anymore as there will ever only be ONE user
		// and if the user changes, the whole app gets reloaded

		onUserChange(() => {
			dispatch(cleanSpace(AM_PERSONAL_SPACE));
			dispatch(updateVisibility(false));
		});

		eventBroadcaster.on(ACTION_CHILD_ADDED, (space: Space, item: Item | GlobalGroup) => {
			if (item.type === AM_TYPE_GROUP) {
				dispatch(addGlobalGroups([item], { persist: false }));
			} else {
				dispatch(addItems(space, [item], { persist: false }));
			}
		});

		eventBroadcaster.on(ACTION_CHILD_CHANGED, (space: Space, item: Item | GlobalGroup) => {
			const { id, type } = item;
			if (type === AM_TYPE_GROUP) {
				dispatch(updateGlobalGroup(id, item, { persist: false }));
			} else {
				dispatch(updateItem(space, id, item, { persist: false }));
			}
		});

		eventBroadcaster.on(ACTION_CHILD_REMOVED, (space: Space, item: Item | GlobalGroup) => {
			const { id, type } = item;
			if (type === AM_TYPE_GROUP) {
				dispatch(removeGlobalGroup(id, { persist: false }));
			} else {
				dispatch(removeItem(space, id, { persist: false }));
			}
		});
	};
}

export default initialize;
