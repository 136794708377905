import { DND_BOARD_ITEM } from '@sep/br24-constants';
import cn from 'classnames';
import { useDrag } from 'react-dnd';

import styles from './DraggableItem.module.scss';

type Props = DragProps & {
	children?: any;
	className?: any; // ClassName
};

type DragProps = {
	type: string;
	id: string;
	internalId: string;
	sectionId: string;
	status: string;
};

export function DraggableItem(props: Props) {
	const { className, children } = props;

	const [{ isDragging }, drag] = useDrag<
		{ type: string },
		DragProps,
		{ isDragging: boolean }
	>(() => {
		return {
			type: DND_BOARD_ITEM,
			item: () => ({
				type: props.type,
				id: props.id,
				internalId: props.internalId,
				sectionId: props.sectionId,
				status: props.status,
			}),
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),
		};
	}, [props.id, props.internalId, props.sectionId, props.status, props.type]);

	return (
		<div
			ref={drag}
			className={cn(styles.draggableItem, { [styles.isDragging]: isDragging }, className)}
		>
			{children}
		</div>
	);
}
