/**
 * @generated SignedSource<<6be117592bf3d5d3ff725330fe316785>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RegionStatsContainerQuery$variables = {
  endDate?: string | null;
  startDate?: string | null;
};
export type RegionStatsContainerQuery$data = {
  readonly stats: {
    readonly nodes: ReadonlyArray<{
      readonly count: number;
      readonly district: string;
    } | null>;
  } | null;
};
export type RegionStatsContainerQuery = {
  response: RegionStatsContainerQuery$data;
  variables: RegionStatsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v2 = [
  {
    "kind": "Variable",
    "name": "fromDate",
    "variableName": "startDate"
  },
  {
    "kind": "Literal",
    "name": "statusFilter",
    "value": "PUBLISHED"
  },
  {
    "kind": "Variable",
    "name": "toDate",
    "variableName": "endDate"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "district",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RegionStatsContainerQuery",
    "selections": [
      {
        "alias": "stats",
        "args": (v2/*: any*/),
        "concreteType": "RegionsArticleCountResultsConnection",
        "kind": "LinkedField",
        "name": "regionsArticleCountByFirstPublicationDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegionsArticleCountResult",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v3/*: any*/),
                "action": "LOG",
                "path": "stats.nodes.district"
              },
              {
                "kind": "RequiredField",
                "field": (v4/*: any*/),
                "action": "LOG",
                "path": "stats.nodes.count"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RegionStatsContainerQuery",
    "selections": [
      {
        "alias": "stats",
        "args": (v2/*: any*/),
        "concreteType": "RegionsArticleCountResultsConnection",
        "kind": "LinkedField",
        "name": "regionsArticleCountByFirstPublicationDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RegionsArticleCountResult",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a2ca67622291de583e75c5a3083a29ab",
    "id": null,
    "metadata": {},
    "name": "RegionStatsContainerQuery",
    "operationKind": "query",
    "text": "query RegionStatsContainerQuery(\n  $startDate: Datetime\n  $endDate: Datetime\n) {\n  stats: regionsArticleCountByFirstPublicationDate(fromDate: $startDate, toDate: $endDate, statusFilter: PUBLISHED) {\n    nodes {\n      district\n      count\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c53a01ab214f0900a050560ebdc019e";

export default node;
