import {
	MODULE_ALL,
	MODULE_TYPE_AUDIO,
	MODULE_TYPE_EMBED,
	MODULE_TYPE_GALLERY,
	MODULE_TYPE_IMAGE,
	MODULE_TYPE_TEXT,
	MODULE_TYPE_VIDEO,
	MODULE_TYPE_VIDEO360,
	MODULE_TYPE_LIVESTREAM,
	VIDEO_RESOLUTION_ALL,
} from '@sep/br24-constants';
import Joi from 'joi-browser';

import type { Module } from '..';
import config from '../../../config';
import { translate } from '../../../translation';
import { createJoiError } from '../../../util';
import { processValidation } from '../../../util/processValidation';
import type { ValidationResult } from '../../../util/processValidation';

const AUDIO_TITLE_MIN_LENGTH = config.articleModules.audio.teasertextMinLength;
const AUDIO_TITLE_MAX_LENGTH = config.articleModules.audio.teasertextMaxLength;

const IMAGE_TITLE_MIN_LENGTH = config.articleModules.image.titleMinLength;
const IMAGE_TITLE_MAX_LENGTH = config.articleModules.image.titleMaxLength;

const VIDEO_TITLE_MIN_LENGTH = config.articleModules.video.titleMinLength;
const VIDEO_TITLE_MAX_LENGTH = config.articleModules.video.titleMaxLength;

const imageItem = {
	url: Joi.string()
		.regex(/rect=/)
		.required()
		.error(createJoiError(translate('validation.errors.modules.thumbnail.url'))),
	title: Joi.string()
		.required()
		.min(IMAGE_TITLE_MIN_LENGTH)
		.max(IMAGE_TITLE_MAX_LENGTH)
		.error(
			createJoiError(
				translate('validation.errors.modules.thumbnail.title', {
					minLength: IMAGE_TITLE_MIN_LENGTH,
					maxLength: IMAGE_TITLE_MAX_LENGTH,
				})
			)
		),
	copyright: Joi.string()
		.required()
		.error(createJoiError(translate('validation.errors.modules.thumbnail.copyright'))),
	altText: Joi.string()
		.required()
		.error(createJoiError(translate('validation.errors.modules.thumbnail.altText'))),
};

const thumbnail = Joi.object().keys(imageItem).required();

const galleryItems = Joi.object().keys(imageItem);

const schema = Joi.object().keys({
	audio: Joi.any().when('type', {
		is: MODULE_TYPE_AUDIO,
		then: Joi.object()
			.keys({
				url: Joi.string()
					.required()
					.error(createJoiError(translate('validation.errors.modules.audio.url'))),
				title: Joi.string()
					.required()
					.min(AUDIO_TITLE_MIN_LENGTH)
					.max(AUDIO_TITLE_MAX_LENGTH)
					.error(
						createJoiError(
							translate('validation.errors.modules.audio.title', {
								minLength: AUDIO_TITLE_MIN_LENGTH,
								maxLength: AUDIO_TITLE_MAX_LENGTH,
							})
						)
					),
				copyright: Joi.string()
					.required()
					.error(createJoiError(translate('validation.errors.modules.audio.copyright'))),
				duration: Joi.number().required(),
				thumbnail,
				meta: Joi.object(),
			})
			.required(),
		otherwise: Joi.required().valid(null),
	}),
	embed: Joi.any().when('type', {
		is: MODULE_TYPE_EMBED,
		then: Joi.when('order', {
			is: 0,
			// workaround: in order to have a validation error here
			// we check for number, without type joi does not throw errors
			then: Joi.number().error(
				createJoiError(translate('validation.errors.modules.embed.isOnFirstPosition'))
			),
			otherwise: Joi.object()
				.keys({
					service: Joi.string()
						.required()
						.error(createJoiError(translate('validation.errors.modules.embed.noService'))),
					altText: Joi.string()
						.required()
						.error(createJoiError(translate('validation.errors.modules.embed.noAltText'))),
					source: Joi.string()
						.required()
						.error(createJoiError(translate('validation.errors.modules.embed.noEmbedInsert'))),
					url: Joi.string()
						.required()
						.regex(/br24\.de|localhost|:3000|.br-dev\.de|.br-staging\.de/)
						.error(createJoiError(translate('validation.errors.modules.embed.noPreviewLoaded'))),
				})
				.required(),
		}),
		otherwise: Joi.required().valid(null),
	}),
	gallery: Joi.any().when('type', {
		is: MODULE_TYPE_GALLERY,
		then: Joi.when('order', {
			is: 0,
			// workaround: in order to have a validation error here
			// we check for number, without type joi does not throw errors
			then: Joi.number().error(
				createJoiError(translate('validation.errors.modules.gallery.isOnFirstPosition'))
			),
			otherwise: Joi.array().min(1).items(galleryItems).required(),
		}),
		otherwise: Joi.required().valid(null),
	}),
	id: Joi.string().required().allow(null),
	image: Joi.any().when('type', {
		is: MODULE_TYPE_IMAGE,
		then: Joi.object()
			.keys({
				url: Joi.string()
					.required()
					.regex(/rect=/)
					.error(createJoiError(translate('validation.errors.modules.image.url'))),
				title: Joi.string()
					.required()
					.min(IMAGE_TITLE_MIN_LENGTH)
					.max(IMAGE_TITLE_MAX_LENGTH)
					.error(
						createJoiError(
							translate('validation.errors.modules.image.title', {
								minLength: IMAGE_TITLE_MIN_LENGTH,
								maxLength: IMAGE_TITLE_MAX_LENGTH,
							})
						)
					),
				copyright: Joi.string()
					.required()
					.error(createJoiError(translate('validation.errors.modules.image.copyright'))),
				altText: Joi.string()
					.required()
					.error(createJoiError(translate('validation.errors.modules.image.altText'))),
			})
			.required(),
		otherwise: Joi.required().valid(null),
	}),
	order: Joi.number().min(0).required(),
	rowId: Joi.number().positive().required().allow(null),
	text: Joi.when('type', {
		is: MODULE_TYPE_TEXT,
		then: Joi.when('order', {
			is: 0,
			// workaround: in order to have a validation error here
			// we check for number, without type joi does not throw errors
			then: Joi.number().error(
				createJoiError(translate('validation.errors.modules.text.isOnFirstPosition'))
			),
			otherwise: Joi.string()
				.required()
				// because the editor inserts an empty <p></p> we have a minimum character
				// count for 8 letters
				.min(8)
				.error(createJoiError(translate('validation.errors.modules.text.isEmpty'))),
		}),
		otherwise: Joi.required().valid(null),
	}),
	type: Joi.allow(...MODULE_ALL).required(),
	video: Joi.any().when('type', {
		is: MODULE_TYPE_VIDEO,
		then: Joi.object()
			.keys({
				title: Joi.string()
					.required()
					.min(VIDEO_TITLE_MIN_LENGTH)
					.max(VIDEO_TITLE_MAX_LENGTH)
					.error(
						createJoiError(
							translate('validation.errors.modules.video.title', {
								minLength: VIDEO_TITLE_MIN_LENGTH,
								maxLength: VIDEO_TITLE_MAX_LENGTH,
							})
						)
					),
				copyright: Joi.string()
					.required()
					.error(createJoiError(translate('validation.errors.modules.video.copyright'))),
				url: Joi.string()
					.required()
					.error(createJoiError(translate('validation.errors.modules.video.url'))),
				duration: Joi.number().required(),
				thumbnail,
				meta: Joi.object(),
			})
			.required(),
		otherwise: Joi.required().valid(null),
	}),
	video360: Joi.any().when('type', {
		is: MODULE_TYPE_VIDEO360,
		then: Joi.object()
			.keys({
				url: Joi.string()
					.required()
					.error(createJoiError('validation.errors.modules.video360.url')),
				duration: Joi.number().required(),
				sources: Joi.object()
					.keys({
						url: Joi.string()
							.required()
							.error(createJoiError('validation.errors.modules.video360.sources')),
						resolution: Joi.allow(...VIDEO_RESOLUTION_ALL).required(),
					})
					.required(),
				thumbnail,
			})
			.required(),
		otherwise: Joi.required().valid(null),
	}),
	livestream: Joi.any().when('type', {
		is: MODULE_TYPE_LIVESTREAM,
		then: Joi.object()
			.keys({
				livestreamId: Joi.string().required(),
				programmeId: Joi.string().required(),
			})
			.required()
			.error(createJoiError('validation.errors.modules.livestream')),
		otherwise: Joi.required().valid(null),
	}),
});

const defaultOptions = {
	abortEarly: false,
	allowUnknown: true,
};

export default function validator(affectedModule: Module): ValidationResult {
	const result = Joi.validate(affectedModule, schema, defaultOptions);
	return processValidation(result);
}
