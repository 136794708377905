import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import { type RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import environment from '../../environment';

import AuthorsListItemLoggedInUser from './AuthorsList/AuthorListItemLoggedInUser';
import type {
	AuthorLoggedInUserContainerQuery,
	AuthorFilter as LoggedInUserFilterType,
} from './__generated__/AuthorLoggedInUserContainerQuery.graphql';

const ContentWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
`;

const SpinnerWrapper = styled.div`
	border-top: 40px solid #f6f6f6;
	height: 90vh;
`;

const Loader = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	right: 50%;
`;

interface Props extends RouteComponentProps {
	loggedInUserFilter: LoggedInUserFilterType;
}

function AuthorLoggedInUserContainer({ loggedInUserFilter, history, match, location }: Props) {
	return (
		<QueryRenderer<AuthorLoggedInUserContainerQuery>
			environment={environment}
			query={graphql`
				query AuthorLoggedInUserContainerQuery(
					$count: Int
					$cursor: Cursor
					$loggedInUserFilter: AuthorFilter
				) {
					...AuthorListItemLoggedInUser_query
				}
			`}
			variables={{
				loggedInUserFilter,
			}}
			render={({ props: queryRendererProps, error }) => {
				if (error) {
					return (
						<ContentWrapper>
							<Alert message="Fehler" description={error.message} type="error" />
						</ContentWrapper>
					);
				}

				if (!queryRendererProps) {
					return (
						<SpinnerWrapper>
							<Loader>
								<Spin size="large" />
							</Loader>
						</SpinnerWrapper>
					);
				}

				return (
					<AuthorsListItemLoggedInUser
						query={queryRendererProps}
						history={history}
						match={match}
						location={location}
					/>
				);
			}}
		/>
	);
}

export default withRouter(AuthorLoggedInUserContainer);
