import type { State } from './Search';

export function resetFilter(): State {
	return {
		searchTerm: null,
		category: null,
		selectedResultOption: 'articles',
	};
}

function updateIfNotEqual(
	key: string
): (value: string | null | undefined) => (previousState: State) => State | undefined | null {
	return (value: string | null | undefined) => (previousState: State) => {
		if (previousState[key] === value) {
			return null;
		}

		return { ...previousState, [key]: value };
	};
}

export const updateSearchTerm = updateIfNotEqual('searchTerm');
export const updateCategory = updateIfNotEqual('category');
