import { MODULE_TYPE_TEXT } from '@sep/br24-constants';
import Joi from 'joi-browser';

import type { JoiHack } from '@/types/hacks';

function generateSequence({ edges }) {
	return edges.map(({ node }) => node.type);
}

let joi = Joi.extend({
	base: Joi.object(),
	name: 'object',
	language: {
		'ordered:invalid': 'invalid order (multiple order {{order}})',
		'hasTextOnlyMarkUp:invalidModule': 'cannot contain module {{module}} at position {{index}}',
		'hasFullyFeaturedMarkUp:imageFirst': 'must contain an image at position 0',
		'hasFullyFeaturedMarkUp:more': 'module count must be greater than 1',
		'hasBasicMarkUp:imageFirst': 'must contain an image at position 0',
		'hasBasicMarkUp:textSecond': 'must contain text at position 1',
		'hasBasicMarkUp:galleryThird': 'position 2 cannot contain {{module}}',
		'hasBasicMarkUp:max': 'too many modules',
	},
	rules: [
		{
			name: 'ordered',
			validate(_params, value, state, options) {
				const { edges } = value;
				const hitted: any[] = [];

				for (let index = 0; index < edges.length; index += 1) {
					const { node } = edges[index];

					if (hitted.indexOf(node.order) !== -1) {
						return this.createError?.(
							'object.ordered:invalid',
							{ value, order: node.order },
							state,
							options
						);
					}

					hitted.push(node.order);
				}

				return value;
			},
		} as JoiHack,
		{
			name: 'hasTextOnlyMarkUp',
			validate(_params, value, state, options) {
				const sequence = generateSequence(value);

				for (let index = 0; index < sequence.length; index += 1) {
					const type = sequence[index];

					if (type !== MODULE_TYPE_TEXT) {
						return this.createError?.(
							'object.hasTextOnlyMarkUp:invalidModule',
							{ value, module: type, index },
							state,
							options
						);
					}
				}

				return value;
			},
		} as JoiHack,
	],
});

joi = joi.extend({
	base: Joi.string(),
	name: 'string',
	language: {
		json: 'invalid json',
	},
	rules: [
		{
			name: 'json',
			validate(_params, value, state, options) {
				try {
					JSON.parse(value);
					return value;
				} catch (err) {
					return this.createError?.('string.json', { value }, state, options);
				}
			},
		} as JoiHack,
	],
});

const extendedJoi = joi;

export default extendedJoi;
