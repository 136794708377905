/**
 * @generated SignedSource<<c9034c4c24bf55b0ed0c6b8fde78ff65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteBoardsSectionsItemByRowIdInput = {
  clientMutationId?: string | null;
  rowId: number;
};
export type DeleteBoardSectionItemMutation$variables = {
  input: DeleteBoardsSectionsItemByRowIdInput;
};
export type DeleteBoardSectionItemMutation$data = {
  readonly deleteBoardsSectionsItemByRowId: {
    readonly boardsSectionsItem: {
      readonly articleId: string | null;
      readonly boardId: string | null;
      readonly order: number | null;
      readonly rowId: number;
    } | null;
  } | null;
};
export type DeleteBoardSectionItemMutation = {
  response: DeleteBoardSectionItemMutation$data;
  variables: DeleteBoardSectionItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boardId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "articleId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteBoardSectionItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBoardsSectionsItemPayload",
        "kind": "LinkedField",
        "name": "deleteBoardsSectionsItemByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsSectionsItem",
            "kind": "LinkedField",
            "name": "boardsSectionsItem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteBoardSectionItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBoardsSectionsItemPayload",
        "kind": "LinkedField",
        "name": "deleteBoardsSectionsItemByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsSectionsItem",
            "kind": "LinkedField",
            "name": "boardsSectionsItem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "502b2444b5679642bbd0e64fa5fe54cf",
    "id": null,
    "metadata": {},
    "name": "DeleteBoardSectionItemMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteBoardSectionItemMutation(\n  $input: DeleteBoardsSectionsItemByRowIdInput!\n) {\n  deleteBoardsSectionsItemByRowId(input: $input) {\n    boardsSectionsItem {\n      rowId\n      boardId\n      articleId\n      order\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78d984146a09d50bd346c3d5338642c0";

export default node;
