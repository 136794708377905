/**
 * @generated SignedSource<<dee446e6a0361912d722a02a245d0a77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BoardsSectionsSchema = "CATEGORY_LEFT_1_BIG" | "CATEGORY_LEFT_2_SMALL" | "CATEGORY_RIGHT_1_BIG" | "CATEGORY_RIGHT_2_SMALL" | "HIGHLIGHT_3" | "RAISED_LEFT_SIMPLE" | "RAISED_RIGHT_SHORTNEWS" | "RAISED_RIGHT_SHORTNEWS_2_SMALL" | "RAISED_RIGHT_SIMPLE" | "RAISED_RIGHT_SIMPLE_WEATHER" | "REGIONAL_1_BIG_TOP_2_SMALL_BELOW" | "REGIONAL_2_BIG_TOP" | "REGIONAL_2_SMALL_TOP_1_BIG_BELOW" | "REGIONAL_4_SIMPLE" | "SMALL_3" | "STAGE_CAROUSEL" | "STAGE_SPLITTED" | "SUBJECTS_NEWS_WEATHER";
export type DeleteBoardsSectionByRowIdInput = {
  clientMutationId?: string | null;
  rowId: string;
};
export type DeleteBoardSectionMutation$variables = {
  input: DeleteBoardsSectionByRowIdInput;
};
export type DeleteBoardSectionMutation$data = {
  readonly deleteBoardsSectionByRowId: {
    readonly boardsSection: {
      readonly autofill: boolean | null;
      readonly categoryId: string | null;
      readonly color: string | null;
      readonly createdAt: string;
      readonly createdBy: string | null;
      readonly meta: any | null;
      readonly order: number;
      readonly rowId: string;
      readonly schema: BoardsSectionsSchema;
      readonly showHeroTitleForMobile: boolean | null;
      readonly title: string | null;
      readonly updatedAt: string | null;
      readonly updatedBy: string | null;
    } | null;
  } | null;
};
export type DeleteBoardSectionMutation = {
  response: DeleteBoardSectionMutation$data;
  variables: DeleteBoardSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schema",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autofill",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "meta",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showHeroTitleForMobile",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedBy",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdBy",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteBoardSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBoardsSectionPayload",
        "kind": "LinkedField",
        "name": "deleteBoardsSectionByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsSection",
            "kind": "LinkedField",
            "name": "boardsSection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteBoardSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteBoardsSectionPayload",
        "kind": "LinkedField",
        "name": "deleteBoardsSectionByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardsSection",
            "kind": "LinkedField",
            "name": "boardsSection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e24f47f190f22f07e50315de96aa72bb",
    "id": null,
    "metadata": {},
    "name": "DeleteBoardSectionMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteBoardSectionMutation(\n  $input: DeleteBoardsSectionByRowIdInput!\n) {\n  deleteBoardsSectionByRowId(input: $input) {\n    boardsSection {\n      rowId\n      schema\n      categoryId\n      title\n      color\n      autofill\n      meta\n      order\n      showHeroTitleForMobile\n      updatedBy\n      updatedAt\n      createdBy\n      createdAt\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2a3848f24f50235e82d0b3c5686149b";

export default node;
