import { Component, type MouseEventHandler } from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';
import type { LiteralUnion } from 'type-fest';

import { useTranslate } from '@/client/translation/useTranslate';
import { withTranslation } from '@/client/translation/withTranslation';

import {
	AM_SORT_DIRECTION_RECENT,
	AM_SORT_DIRECTION_OLDEST,
	AM_SORT_DIRECTION_ALPHABETICAL_ASC,
	AM_SORT_DIRECTION_ALPHABETICAL_DESC,
} from '../../../constants';
import type { WithTranslationProps } from '../../../translation';
import { Close, Filter } from '../../Icon';

import AssetManagerFilterButton from './AssetManagerFilterButton';
import AssetManagerFilterClearButton from './AssetManagerFilterClearButton';
import AssetManagerFilterCloseButton from './AssetManagerFilterCloseButton';
import AssetManagerFilterSearch from './AssetManagerFilterSearch';
import AssetManagerFilterSortDirection from './AssetManagerFilterSortDirection';

type SortDirection =
	| typeof AM_SORT_DIRECTION_OLDEST
	| typeof AM_SORT_DIRECTION_RECENT
	| typeof AM_SORT_DIRECTION_ALPHABETICAL_ASC
	| typeof AM_SORT_DIRECTION_ALPHABETICAL_DESC;

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	z-index: 5;
	margin-bottom: 10px;

	button:first-child {
		margin-right: 10px;
	}
`;

const SearchAndSortWrapper = styled.div`
	display: flex;
	flex: 1 0 auto;
	margin-right: 5px;
	width: calc(100% - 40px - 10px);
`;

type AssetManagerFilterProps = {
	searchValue?: string;
	onChangeSearchValue: (searchValue: string) => void;
	sortDirection?: LiteralUnion<SortDirection, string>;
	onChangeSortDirection: (sortDirection: LiteralUnion<SortDirection, string>) => void;
	isFilterActive?: boolean;
	isFilterOpen?: boolean;
	onClickFilter: () => void;
	onCloseFilter: () => void;
	onReset: () => void;
	onKeyDown?: () => void;
	isSortDisabled?: boolean;
};

function FilterBar(
	props: AssetManagerFilterProps & {
		handleClickFilterButton: (event: SyntheticEvent<HTMLButtonElement>) => void;
		handleResetFilterButton?: MouseEventHandler<HTMLButtonElement>;
	}
) {
	const {
		isFilterActive,
		handleClickFilterButton,
		handleResetFilterButton,
		isSortDisabled,
		sortDirection,
		onChangeSortDirection,
	} = props;

	const translate = useTranslate();

	return (
		<>
			<AssetManagerFilterButton $isActive={isFilterActive} onClick={handleClickFilterButton}>
				<Filter />
			</AssetManagerFilterButton>
			<AssetManagerFilterClearButton
				isVisible={isFilterActive || sortDirection !== AM_SORT_DIRECTION_RECENT}
				onClick={handleResetFilterButton}
			>
				<Close />
			</AssetManagerFilterClearButton>
			<AssetManagerFilterSortDirection
				isDisabled={isSortDisabled}
				value={sortDirection}
				onChange={onChangeSortDirection}
				isActive={!!sortDirection}
				items={[
					{
						id: AM_SORT_DIRECTION_RECENT,
						label: translate(`assetManager.filter.${AM_SORT_DIRECTION_RECENT}`),
					},
					{
						id: AM_SORT_DIRECTION_OLDEST,
						label: translate(`assetManager.filter.${AM_SORT_DIRECTION_OLDEST}`),
					},
					{
						id: AM_SORT_DIRECTION_ALPHABETICAL_ASC,
						label: translate(`assetManager.filter.${AM_SORT_DIRECTION_ALPHABETICAL_ASC}`),
					},
					{
						id: AM_SORT_DIRECTION_ALPHABETICAL_DESC,
						label: translate(`assetManager.filter.${AM_SORT_DIRECTION_ALPHABETICAL_DESC}`),
					},
				]}
			/>
		</>
	);
}

function FilterSearch(props: any) {
	const { handleChangeSearch, handleCloseFilter, searchValue, onKeyDown } = props;

	return (
		<SearchAndSortWrapper>
			<AssetManagerFilterCloseButton onClick={handleCloseFilter} />
			<AssetManagerFilterSearch
				value={searchValue}
				onChange={handleChangeSearch}
				onKeyDown={onKeyDown}
			/>
		</SearchAndSortWrapper>
	);
}

class AssetManagerFilter extends Component<WithTranslationProps & AssetManagerFilterProps> {
	static defaultProps = {
		searchValue: '',
		sortDirection: AM_SORT_DIRECTION_RECENT,
		isFilterActive: false,
		isSortDisabled: false,
	};

	handleChangeSearch = (event: SyntheticEvent<HTMLInputElement>) => {
		event.preventDefault();
		const { onChangeSearchValue } = this.props;

		onChangeSearchValue(event.currentTarget.value);
	};

	handleClickFilterButton = (event: SyntheticEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const { onClickFilter } = this.props;

		onClickFilter();
	};

	handleClickReset: MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();

		const { onReset } = this.props;
		onReset();
	};

	handleCloseFilterButton = (event: SyntheticEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const { onCloseFilter } = this.props;

		onCloseFilter();
	};

	render() {
		const { isFilterOpen } = this.props;

		return (
			<Wrapper>
				{isFilterOpen ? (
					<FilterSearch
						{...this.props}
						handleChangeSearch={this.handleChangeSearch}
						handleCloseFilter={this.handleCloseFilterButton}
					/>
				) : (
					<FilterBar
						{...this.props}
						handleClickFilterButton={this.handleClickFilterButton}
						handleResetFilterButton={this.handleClickReset}
					/>
				)}
			</Wrapper>
		);
	}
}

export default withTranslation(AssetManagerFilter);
