import { MODULE_TYPE_IMAGE } from '@sep/br24-constants';
import type { UploadFile } from 'antd';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { ModuleTypeImage } from '../..';
import { UserContainer } from '../../../../auth/AuthContext';
import config from '../../../../config';
import type { WithTranslationProps } from '../../../../translation';
import {
	ArticleModuleItem,
	ArticleModuleImage,
	ArticleModuleUploader,
} from '../../../../ui/ArticleModule';
import { TypeImage } from '../../../../ui/Icon';
import type { Item } from '../../../AssetManager';
import {
	DndSortModuleContainer,
	DndCreateModuleContainer,
	DndUpdateModuleContainer,
} from '../../DragAndDropHandler';
import type { SimpleItem } from '../../DragAndDropHandler/DndSortModuleContainer';
import {
	getAllowedDataTypes,
	allAssetManagerTypes,
	getAEItemType,
	getAMItemType,
	toSortModule,
} from '../../DragAndDropHandler/dndUtils';
import { activeArticleModuleSelector } from '../../selectors';
import BrokenModuleContainer from '../ArticleEditorBrokenModule';

interface Props extends WithTranslationProps, OwnProps, ReduxProps {
	isEditing: boolean;
	isThumbnailUploading: boolean;
	onMove: (dragIndex: SimpleItem, hoverIndex: SimpleItem) => void;
	onUpdate: (
		id: string,
		type: string,
		item: { [key in 'url' | 'title' | 'altText' | 'copyright']?: string }
	) => void;
	onDelete: (id: string) => void;
	onDisplayAttachedDropzone: (order: number, position: 'top' | 'bottom') => void;
	onAddViaAttachedDropzone: (item: Item | undefined) => void;
	onAddViaInitialDropzone: (id: string, type: string, item: Item | undefined) => void;
	onDropUpdateThumbnail: (id: string, type: string, dropItem: Item) => void;
	onDropUploadThumbnail: (id: string, type: string, dropItem: File) => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
	onMoveToAssetManager: (id: string | number) => void;
	onResetAttachedDropzone?: () => void;
}

class ImageModuleContainer extends Component<Props> {
	handleChange = (key: 'url' | 'title' | 'altText' | 'copyright', value: string) => {
		this.props.onUpdate(this.props.id, 'image', {
			[key]: value,
		});
	};

	handleDelete = () => {
		this.props.onDelete(this.props.id);
	};

	handleInitialDropzoneDrop = (dropItem: Item | undefined) => {
		this.props.onAddViaInitialDropzone(this.props.id, 'image', dropItem);
	};

	handleInitialDropzoneUpload = (
		files: UploadFile<{ url: string }>[] | UploadFile<{ url: string }>
	) => {
		if (!Array.isArray(files)) {
			files = [files];
		}

		if (files.length === 1) {
			const [newImage] = files;
			this.props.onUpdate(this.props.id, 'image', { url: newImage.response?.url });
		}
	};

	handleUpdateOnDrop = (dropItem: Item) => {
		this.props.onDropUpdateThumbnail(this.props.id, 'image', dropItem);
	};

	handleUploadOnDrop = (dropItem: File[]) => {
		const [image] = dropItem;

		// only support one picture upload, not multiple
		if (image && dropItem.length === 1) {
			this.props.onDropUploadThumbnail(this.props.id, 'image', image);
		}
	};

	render() {
		const {
			translation: { translate },
			module,
			...rest
		} = this.props;

		if (!module.image) {
			return <BrokenModuleContainer id={module.rowId} />;
		}

		const acceptedDataTypes = getAllowedDataTypes();

		let displayModule: JSX.Element | null = null;
		if (module.image && module.image.url && module.image.url !== '') {
			displayModule = (
				<div>
					<DndSortModuleContainer
						acceptedItemTypes={{
							dragSource: getAEItemType('IMAGE'),
							dropTarget: toSortModule,
						}}
						{...this.props}
					>
						<ArticleModuleItem
							label={translate(`modules.${MODULE_TYPE_IMAGE}.text`)}
							onDelete={this.handleDelete}
						>
							<DndUpdateModuleContainer
								acceptedItemTypes={[getAMItemType('IMAGE'), getAEItemType('NATIVE')]}
								itemTypes={[getAMItemType('IMAGE'), getAEItemType('NATIVE')]}
								onDropUpdate={this.handleUpdateOnDrop}
								onDropUpload={this.handleUploadOnDrop}
							>
								<ArticleModuleImage
									altText={module.image.altText || ''}
									copyright={module.image.copyright || ''}
									url={module.image.url || ''}
									title={module.image.title || ''}
									onChange={this.handleChange}
									validation={module.__validation__}
									{...rest}
								/>
							</DndUpdateModuleContainer>
						</ArticleModuleItem>
					</DndSortModuleContainer>
				</div>
			);
		} else {
			displayModule = (
				<ArticleModuleItem
					label={translate(`modules.${MODULE_TYPE_IMAGE}.text`)}
					onDelete={this.handleDelete}
				>
					<DndCreateModuleContainer
						onAddViaInitialDropzone={this.handleInitialDropzoneDrop}
						acceptedItemTypes={[getAMItemType('IMAGE'), getAEItemType('NATIVE')]}
						itemTypes={[...allAssetManagerTypes, getAEItemType('NATIVE')]}
					>
						<UserContainer>
							{(user) => (
								<ArticleModuleUploader
									icon={<TypeImage />}
									user={user}
									action={`${config.VITE_IMAGE_UPLOAD_URL_EXT}/image-upload`}
									label={translate(`modules.${MODULE_TYPE_IMAGE}.dropzoneText`)}
									multiple={false}
									acceptedDataTypes={acceptedDataTypes}
									validation={!!module.__validation__}
									onSuccess={this.handleInitialDropzoneUpload}
								/>
							)}
						</UserContainer>
					</DndCreateModuleContainer>
				</ArticleModuleItem>
			);
		}

		return displayModule;
	}
}

interface OwnProps {
	id: string;
}

const connector = connect((state: ReduxState, { id }: OwnProps) => ({
	module: activeArticleModuleSelector(state, id) as ModuleTypeImage,
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(ImageModuleContainer));
