import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../../environment';

import type { LivestreamModuleRendererQuery } from './__generated__/LivestreamModuleRendererQuery.graphql';

type Props = {
	programmeId: string;
	children: QueryRendererProps<LivestreamModuleRendererQuery>['render'];
};

function LivestreamModuleRenderer({ programmeId, children }: Props) {
	return (
		<QueryRenderer<LivestreamModuleRendererQuery>
			environment={environment}
			query={graphql`
				query LivestreamModuleRendererQuery($programmeId: ID!) {
					programme: mangoNode(id: $programmeId) {
						__typename
						... on MangoProgramme {
							title
							kicker
							broadcasts(orderBy: START_DESC) {
								edges {
									node {
										start
										end
										broadcastedOn {
											edges {
												node {
													labels
													streamingUrls(first: 1) {
														edges {
															node {
																publicLocation
															}
														}
													}
												}
											}
										}
									}
								}
							}
							defaultTeaserImage {
								title
								shortDescription
								copyright
								imageFiles {
									edges {
										node {
											publicLocation
										}
									}
								}
							}
						}
					}
				}
			`}
			variables={{ programmeId }}
			render={children}
		/>
	);
}

export default LivestreamModuleRenderer;
