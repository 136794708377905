import { STATUS_DEPUBLISHED, STATUS_DRAFT, STATUS_PUBLISHED } from '@sep/br24-constants';
import { Radio, type RadioChangeEvent } from 'antd';

import { useTranslate } from '@/client/translation/useTranslate';

type StatusPickerStatus =
	| 'ALL'
	| typeof STATUS_PUBLISHED
	| typeof STATUS_DEPUBLISHED
	| typeof STATUS_DRAFT;

interface Props {
	onStatusPick?: (status: StatusPickerStatus) => void;
	value?: StatusPickerStatus;
}

export function StatusPicker({ onStatusPick, value }: Props) {
	const translate = useTranslate();

	const handleStatusPick = (event: RadioChangeEvent) => {
		if (onStatusPick) {
			const { value } = event.target;

			onStatusPick(value);
		}
	};

	return (
		<Radio.Group onChange={handleStatusPick} value={value}>
			<Radio.Button value="ALL">{translate('boards.table.filters.ALL')}</Radio.Button>
			<Radio.Button value={STATUS_PUBLISHED}>
				{translate(`boards.table.filters.${STATUS_PUBLISHED}`)}
			</Radio.Button>
			<Radio.Button value={STATUS_DEPUBLISHED}>
				{translate(`boards.table.filters.${STATUS_DEPUBLISHED}`)}
			</Radio.Button>
			<Radio.Button value={STATUS_DRAFT}>
				{translate(`boards.table.filters.${STATUS_DRAFT}`)}
			</Radio.Button>
		</Radio.Group>
	);
}
