import type { AppThunkAction } from '@/client/store/redux';

import type { CleanSpaceAction, Space } from '..';
import { CLEAN_SPACE } from '../actionTypes';

export const cleanSpace =
	(space: Space): AppThunkAction<CleanSpaceAction> =>
	(dispatch, getState) => {
		if (Object.keys(getState().assetManager[space] || {}).length > 0) {
			dispatch({
				type: CLEAN_SPACE,
				payload: space,
			});
		}
	};
