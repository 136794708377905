import { Input, Form } from 'antd';
import type { TextAreaProps, InputProps } from 'antd/lib/input';
import {
	type ChangeEvent,
	type CSSProperties,
	type FocusEventHandler,
	type KeyboardEvent,
	useCallback,
	useEffect,
	useRef,
} from 'react';
import type { SyntheticEvent } from 'react';
import styled from 'styled-components';

import ErrorMessageText from '../../../ErrorMessageText';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	margin-right: 10px;
`;

const WrapperRow = styled.div`
	display: flex;
	align-items: flex-start;
`;

const InputWrapper = styled.div`
	width: 100%;
`;

interface StyledInputProps extends InputProps {
	$error: boolean;
}

const StyledInput = styled((props: StyledInputProps) => <Input {...props} />)`
	border: none !important;
	height: 100% !important;
	outline: none !important;
	width: 100% !important;
	margin-left: 5px !important;
	font-size: 15px !important;
	font-weight: 400 !important;
	color: ${(props) =>
		props.$error ? props.theme.colors.stateRed : props.theme.colors.blackModulesText} !important;

	&::placeholder {
		font-size: 15px !important;
		font-weight: 400 !important;
		opacity: 0.5;
		color: ${(props) => (props.$error ? props.theme.colors.stateRed : '')} !important;
	}
`;

const FormItem = styled(Form.Item)`
	margin: 0;
`;

interface StyledTextAreaProps extends TextAreaProps {
	$error: boolean;
}

const TextArea = styled((props: StyledTextAreaProps) => <Input.TextArea {...props} />)`
	border: none !important;
	outline: none !important;
	width: 100% !important;
	margin-left: 5px !important;
	font-size: 15px !important;
	color: ${(props) =>
		props.$error ? props.theme?.colors.stateRed : props.theme?.colors.blackModulesText} !important;

	&::placeholder {
		opacity: 0.5;
		color: ${(props) => (props.$error ? props.theme.colors.stateRed : '')} !important;
	}
`;

const HelpWrapper = styled.span`
	margin-left: 25px;
	text-align: right;
	font-size: 11px;
	font-weight: 400;
	white-space: nowrap;
	align-self: flex-end;
	color: ${(props) => props.theme.colors.greyModulesHelp};
`;

const Label = styled.label`
	vertical-align: middle;
	font-size: 15px;
	font-weight: 400;
	padding: 3px 9px;
	min-width: 120px;
	color: ${(props) => props.theme.colors.greyModulesItem};
`;

export type ModulePartInputElement = HTMLInputElement | HTMLTextAreaElement;

type Props = {
	autoSize?:
		| boolean
		| {
				minRows?: number;
				maxRows?: number;
		  };
	className?: string;
	disabled?: boolean;
	help?: string | undefined | null;
	id?: number | string;
	isTextArea?: boolean;
	label?: string | null;
	onBlur?: (value: string) => void;
	onChange?: (event: string) => void;
	onEnter?: (event: SyntheticEvent<ModulePartInputElement>) => void;
	onKeyDown?: (event: KeyboardEvent<ModulePartInputElement>) => void;
	onModuleIsEditing?: (nextIsEditing: boolean) => void;
	placeholder?: string;
	textStyle?: CSSProperties;
	validationError?: string | string[] | null;
	value: string | undefined | null;
};

const ModulePartInput = ({
	onBlur,
	onKeyDown,
	onChange,
	onModuleIsEditing,
	className,
	help,
	id,
	disabled = false,
	textStyle = {},
	autoSize = true,
	isTextArea = false,
	label,
	onEnter,
	placeholder,
	validationError,
	value,
}: Props) => {
	// we use a form to control the input, because otherwise editing text will always reset the cursor position
	const [form] = Form.useForm<{ value: string | null | undefined }>();

	const debounceRef = useRef<any>();

	useEffect(() => {
		form.setFieldValue('value', value);
	}, [value, form]);

	useEffect(() => {
		return () => {
			if (debounceRef.current) {
				clearTimeout(debounceRef.current);
			}
		};
	}, []);

	const resetIsEditing = useCallback(() => {
		if (typeof onModuleIsEditing === 'function') {
			onModuleIsEditing(true);
			if (debounceRef.current) {
				clearTimeout(debounceRef.current);
			}
			debounceRef.current = setTimeout(() => onModuleIsEditing?.(false), 2000);
		}
	}, [onModuleIsEditing]);

	const handleBlur: FocusEventHandler<ModulePartInputElement> = useCallback(
		(event) => {
			const target = event.target;

			if (onBlur) {
				onBlur(target.value);
			} else {
				event.preventDefault();
			}
		},
		[onBlur]
	);

	const handleChange = useCallback(
		(event: ChangeEvent<ModulePartInputElement>) => {
			onChange?.(event.target.value);
			resetIsEditing();
		},
		[onChange, resetIsEditing]
	);

	// eslint-disable-next-line no-nested-ternary
	const errors: string[] = Array.isArray(validationError)
		? validationError
		: validationError
		? [validationError]
		: [];

	return (
		<Form form={form}>
			<Wrapper className={className}>
				<WrapperRow>
					{label ? <Label htmlFor="article-editor-text-input">{label}</Label> : null}
					<InputWrapper>
						{isTextArea ? (
							<FormItem name="value">
								<TextArea
									name="article-editor-text-input"
									style={textStyle}
									autoSize={autoSize}
									disabled={disabled}
									className="input"
									$error={!!validationError}
									onBlur={handleBlur}
									onFocus={resetIsEditing}
									onChange={handleChange}
									onKeyDown={onKeyDown}
									placeholder={placeholder}
								/>
							</FormItem>
						) : (
							<div>
								<FormItem name="value">
									<StyledInput
										name="article-editor-text-input"
										disabled={disabled}
										className="input"
										$error={!!validationError}
										style={textStyle}
										id={String(id)}
										onBlur={handleBlur}
										onFocus={resetIsEditing}
										onChange={handleChange}
										onKeyDown={onKeyDown}
										onPressEnter={onEnter}
										placeholder={placeholder}
									/>
								</FormItem>
							</div>
						)}
					</InputWrapper>
					{help ? <HelpWrapper className="help">{help}</HelpWrapper> : null}
				</WrapperRow>
				<WrapperRow>
					{errors.map((error, index) => (
						<ErrorMessageText text={error} isMsgIndented={Boolean(label)} key={index} />
					))}
				</WrapperRow>
			</Wrapper>
		</Form>
	);
};

ModulePartInput.displayName = 'ModulePartInput';

export default ModulePartInput;
