import { Spin } from 'antd';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import AssetManagerWrapper, { AssetManagerContent } from '.';

const Wrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	.ant-spin-dot {
		margin-right: 10px;
	}
`;

type Props = {
	isVisible: boolean;
	outsideClick: VoidFunction;
};

export default function AssetManagerLoading({ isVisible, outsideClick }: Props) {
	const translate = useTranslate();

	return (
		<AssetManagerWrapper isVisible={isVisible} outsideClick={outsideClick}>
			<AssetManagerContent isLocked={false} isBlurred={false}>
				<Wrapper>
					<Spin size="small" />
					{translate(`assetManager.connecting`)}
				</Wrapper>
			</AssetManagerContent>
		</AssetManagerWrapper>
	);
}
