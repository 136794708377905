import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFilter as FilterSolidIcon } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faAngleDown as AngleDownLightIcon } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAngleRight as AngleRightLightIcon } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faAngleUp as AngleUpLightIcon } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faCheck as CheckLightIcon } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faChevronDown as ChevronDownLightIcon } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp as ChevronUpLightIcon } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faClock as ClockLightIcon } from '@fortawesome/pro-light-svg-icons/faClock';
import { faColumns as ColumnsLightIcon } from '@fortawesome/pro-light-svg-icons/faColumns';
import { faCopyright as CopyrightLightIcon } from '@fortawesome/pro-light-svg-icons/faCopyright';
import { faEdit as EditLightIcon } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEllipsisVAlt as EllipsisVAltLightIcon } from '@fortawesome/pro-light-svg-icons/faEllipsisVAlt';
import { faExchange as ExchangeLightIcon } from '@fortawesome/pro-light-svg-icons/faExchange';
import { faExclamationCircle as ExclamationCircleLightIcon } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faExternalLink as ExternalLinkLightIcon } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faFileAlt as FileAltLightIcon } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFolderOpen as FolderOpenLightIcon } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faInfoCircle as InfoCircleLightIcon } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faPlus as PlusLightIcon } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faSave as SaveLightIcon } from '@fortawesome/pro-light-svg-icons/faSave';
import { faSearch as SearchLightIcon } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSpinner as SpinnerLightIcon } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faTimes as TimesLightIcon } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle as TimesCircleLightIcon } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faTrashAlt as TrashAltLightIcon } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faUser as UserLightIcon } from '@fortawesome/pro-light-svg-icons/faUser';
import { faAlignLeft as AlignLeftRegularIcon } from '@fortawesome/pro-regular-svg-icons/faAlignLeft';
import { faArrowAltLeft as ArrowLeftAltIcon } from '@fortawesome/pro-regular-svg-icons/faArrowAltLeft';
import { faArrowLeft as ArrowLeftRegularIcon } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight as ArrowRightRegularIcon } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faArrowsAlt as ArrowsAltRegularIcon } from '@fortawesome/pro-regular-svg-icons/faArrowsAlt';
import { faBan as BanIcon } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faBell as BellRegularIcon } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faBellSlash as BellStrikethroughIcon } from '@fortawesome/pro-regular-svg-icons/faBellSlash';
import { faCalendarAlt as CalendarAltRegularIcon } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faCheck as CheckRegularIcon } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faChevronDown as ChevronDownRegularIcon } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronRight as ChevronRightRegularIcon } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faClock as ClockRegularIcon } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faCloudUpload as CloudUploadRegularIcon } from '@fortawesome/pro-regular-svg-icons/faCloudUpload';
import { faCode as CodeRegularIcon } from '@fortawesome/pro-regular-svg-icons/faCode';
import { faColumns as ColumnsRegularIcon } from '@fortawesome/pro-regular-svg-icons/faColumns';
import { faCopy as CopyRegularIcon } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faDotCircle as DotCircleIcon } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faEdit as EditRegularIcon } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faEllipsisH as EllipsisHRegularIcon } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faExclamationCircle as ExclamationCircleRegularIcon } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faEye as EyeRegularIcon } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faFileAlt as FileAltRegularIcon } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faHandPaper as HandPaperRegularIcon } from '@fortawesome/pro-regular-svg-icons/faHandPaper';
import { faHeadphones as HeadphonesRegularIcon } from '@fortawesome/pro-regular-svg-icons/faHeadphones';
import { faHistory as HistoryRegularIcon } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faLink as LinkRegularIcon } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faLock as LockIcon } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faLockOpen as LockIconOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import { faMousePointer as MousePointerRegularIcon } from '@fortawesome/pro-regular-svg-icons/faMousePointer';
import { faPlus as PlusRegularIcon } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faQuestion as QuestionRegularIcon } from '@fortawesome/pro-regular-svg-icons/faQuestion';
import { faSearch as SearchRegularIcon } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSort as SortIcon } from '@fortawesome/pro-regular-svg-icons/faSort';
import { faStreetView as StreetViewRegularIcon } from '@fortawesome/pro-regular-svg-icons/faStreetView';
import { faTag as TagIcon } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faTimes as TimesRegularIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTimesCircle as TimesCircleRegularIcon } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faTrash as TrashRegularIcon } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faTrashAlt as TrashAltRegularIcon } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faTvRetro as TvRetroRegularIcon } from '@fortawesome/pro-regular-svg-icons/faTvRetro';
import { faUser as UserRegularIcon } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faUsers as UsersRegularIcon } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faVideo as VideoRegularIcon } from '@fortawesome/pro-regular-svg-icons/faVideo';
import { faCheckCircle as CheckCircleSolidIcon } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faExclamationCircle as ExclamationCircleSolidIcon } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExternalLink as ExternalLinkLightProIcon } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faImage as ImageSolidIcon } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faImages as ImagesSolidIcon } from '@fortawesome/pro-solid-svg-icons/faImages';
import { faQuoteLeft as QuoteLeftSolidIcon } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';
import { faQuoteRight as QuoteRightSolidIcon } from '@fortawesome/pro-solid-svg-icons/faQuoteRight';
import { faUser as UserSolidIcon } from '@fortawesome/pro-solid-svg-icons/faUser';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
} from '@sep/br24-constants';

import * as constants from '../constants';
import type { Status } from '../containers/ArticleEditor';

const createIcon = (icon: IconDefinition) =>
	function ({ ...rest }: Omit<FontAwesomeIconProps, 'icon'>) {
		return <FontAwesomeIcon {...rest} icon={icon} />;
	};

// types (used by the asset-manager & the article editor)
export const TypeAudio = createIcon(HeadphonesRegularIcon);
export const TypeBoardTeaser = createIcon(MousePointerRegularIcon);
export const TypeEmbedCode = createIcon(CodeRegularIcon);
export const TypeGallery = createIcon(ImagesSolidIcon);
export const TypeImage = createIcon(ImageSolidIcon);
export const TypeLink = createIcon(LinkRegularIcon);
export const TypeLiveStream = createIcon(TvRetroRegularIcon);
export const TypeQuote = createIcon(QuoteRightSolidIcon);
export const TypeText = createIcon(AlignLeftRegularIcon);
export const TypeVideo = createIcon(VideoRegularIcon);
export const TypeVideo360 = createIcon(StreetViewRegularIcon);

// global type icons
export const Article = createIcon(FileAltRegularIcon);
export const Board = createIcon(ColumnsRegularIcon);

// misc
export const Accept = createIcon(CheckRegularIcon);
export const ArrowLeft = createIcon(ArrowLeftRegularIcon);
export const ArrowAltLeft = createIcon(ArrowLeftAltIcon);
export const ArrowRight = createIcon(ArrowRightRegularIcon);
export const AssetManager = createIcon(HandPaperRegularIcon);
export const AssetManagerImport = createIcon(ExchangeLightIcon);
export const Author = createIcon(UserRegularIcon);
export const Ban = createIcon(BanIcon);
export const Bell = createIcon(BellRegularIcon);
export const BellStrikethrough = createIcon(BellStrikethroughIcon);
export const Calendar = createIcon(CalendarAltRegularIcon);
export const Cancel = createIcon(TimesRegularIcon);
export const Clock = createIcon(ClockRegularIcon);
export const Close = createIcon(TimesLightIcon);
export const Confirm = createIcon(CheckCircleSolidIcon);
export const Copy = createIcon(CopyRegularIcon);
export const Copyright = createIcon(CopyrightLightIcon);
export const CreateArticle = createIcon(FileAltLightIcon);
export const CreateBoard = createIcon(ColumnsLightIcon);
export const CreateExternalLink = createIcon(ExternalLinkLightIcon);
export const CreateExternalLinkBold = createIcon(ExternalLinkLightProIcon);
export const CreatePlus = createIcon(PlusLightIcon);
export const DirectionDown = createIcon(AngleDownLightIcon);
export const DirectionRight = createIcon(AngleRightLightIcon);
export const DirectionUp = createIcon(AngleUpLightIcon);
export const DotCircle = createIcon(DotCircleIcon);
export const Drag = createIcon(ArrowsAltRegularIcon);
export const DragItem = createIcon(EllipsisVAltLightIcon);
export const DropdownDown = createIcon(ChevronDownLightIcon);
export const DropdownUp = createIcon(ChevronUpLightIcon);
export const Edit = createIcon(EditRegularIcon);
export const Empty = createIcon(TimesCircleRegularIcon);
export const ErrorIcon = createIcon(ExclamationCircleRegularIcon);
export const ErrorSolid = createIcon(ExclamationCircleSolidIcon);
export const Exchange = createIcon(ExchangeLightIcon);
export const Filter = createIcon(FilterSolidIcon);
export const HidePanel = createIcon(ChevronDownRegularIcon);
export const History = createIcon(HistoryRegularIcon);
export const Info = createIcon(InfoCircleLightIcon);
export const Lock = createIcon(LockIcon);
export const LockOpen = createIcon(LockIconOpen);
export const Move = createIcon(ArrowRightRegularIcon);
export const Option = createIcon(EllipsisHRegularIcon);
export const Plus = createIcon(PlusRegularIcon);
export const Preview = createIcon(EyeRegularIcon);
export const Public = createIcon(UsersRegularIcon);
export const Save = createIcon(SaveLightIcon);
export const Search = createIcon(SearchRegularIcon);
export const SearchFilter = createIcon(SearchLightIcon);
export const ShowPanel = createIcon(ChevronRightRegularIcon);
export const Sort = createIcon(SortIcon);
export const Spinner = createIcon(SpinnerLightIcon);
export const TemplateStatus = createIcon(FolderOpenLightIcon);
export const ToolbarEdit = createIcon(EditLightIcon);
export const ToolbarSave = createIcon(CheckLightIcon);
export const ToolbarTrash = createIcon(TrashAltLightIcon);
export const Tag = createIcon(TagIcon);
export const Trash = createIcon(TrashAltRegularIcon);
export const Trash2 = createIcon(TrashRegularIcon);
export const UploadCloud = createIcon(CloudUploadRegularIcon);
export const UserDefault = createIcon(UserSolidIcon);
export const QuoteStart = createIcon(QuoteRightSolidIcon);
export const QuoteEnd = createIcon(QuoteLeftSolidIcon);

// special icon
export const Unknown = createIcon(QuestionRegularIcon);

// utility functions
export function getAssetManagerActionIcon(type: string) {
	switch (type) {
		case constants.AM_HOVER_ACTION_ACCEPT:
			return Accept;

		case constants.AM_HOVER_ACTION_COPY:
			return Copy;

		case constants.AM_HOVER_ACTION_DRAG:
			return Drag;

		case constants.AM_HOVER_ACTION_EDIT:
			return Edit;

		case constants.AM_HOVER_ACTION_MOVE:
			return Move;

		case constants.AM_HOVER_ACTION_TRASH:
			return Trash;

		default:
			return Unknown;
	}
}

export function getAssetManagerTypeIcon(type?: string) {
	switch (type) {
		case constants.AM_TYPE_BOARD_TEASER:
			return TypeBoardTeaser;
		case constants.AM_TYPE_BOARD:
			return Board;
		case constants.AM_TYPE_ARTICLE:
			return Article;
		case constants.AM_TYPE_TEXT:
			return TypeText;
		case constants.AM_TYPE_LINK:
			return TypeLink;
		case constants.AM_TYPE_EMBED_CODE:
			return TypeEmbedCode;
		case constants.AM_TYPE_GALLERY:
			return TypeGallery;
		case constants.AM_TYPE_IMAGE:
			return TypeImage;
		case constants.AM_TYPE_AUDIO:
			return TypeAudio;
		case constants.AM_TYPE_VIDEO360:
			return TypeVideo360;
		case constants.AM_TYPE_VIDEO:
			return TypeVideo;
		case constants.AM_TYPE_LIVESTREAM:
			return TypeLiveStream;
		default:
			return Unknown;
	}
}

const STATUS_TO_ICON_MAP = {
	[STATUS_DELETED]: TimesCircleLightIcon,
	[STATUS_DEPUBLISHED]: ExclamationCircleLightIcon,
	[STATUS_DRAFT]: FileAltLightIcon,
	[STATUS_PUBLISHED]: CheckLightIcon,
	[STATUS_REVIEW]: UserLightIcon,
	[STATUS_SCHEDULED]: ClockLightIcon,
};

export function getStatusIcon(type: Status | null | undefined) {
	if (!type) {
		return Unknown;
	}
	return STATUS_TO_ICON_MAP[type] ? createIcon(STATUS_TO_ICON_MAP[type]) : Unknown;
}
