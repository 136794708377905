import EventEmitter from 'eventemitter3';
import {
	type DatabaseReference,
	ref as databaseRef,
	onChildAdded,
	onChildChanged,
	onChildRemoved,
	getDatabase,
	onValue,
} from 'firebase/database';

import { AM_PERSONAL_SPACE, AM_GLOBAL_SPACE, AM_TYPE_ALL } from '@/client/constants';

import config from '../../config';

import type { GlobalGroup, Item } from '.';

export const eventBroadcaster = new EventEmitter();

export type Space = keyof SpaceItems;

interface SpaceItems {
	[AM_PERSONAL_SPACE]: Item;
	[AM_GLOBAL_SPACE]: GlobalGroup;
}

export const ACTION_CHILD_ADDED = 'CHILD_ADDED';
export const ACTION_CHILD_CHANGED = 'CHILD_CHANGED';
export const ACTION_CHILD_REMOVED = 'CHILD_REMOVED';

let currentFirebaseUserId: string;

function isValidItemGroup(group: GlobalGroup | any): group is GlobalGroup {
	return (
		typeof group === 'object' && group.title && (group.color || ('parent' in group && group.parent))
	);
}

function isValidItem(item: Item | any): boolean {
	return typeof item === 'object' && item && item.type && AM_TYPE_ALL.includes(item.type);
}

const applyEventBroadcaster = (ref: DatabaseReference, space: Space): void => {
	onChildAdded(ref, (snapshot) => {
		const addedItem = snapshot.val();

		if (space === AM_GLOBAL_SPACE && isValidItemGroup(addedItem)) {
			eventBroadcaster.emit(ACTION_CHILD_ADDED, space, addedItem);
		} else if (space === AM_PERSONAL_SPACE && isValidItem(addedItem)) {
			eventBroadcaster.emit(ACTION_CHILD_ADDED, space, addedItem);
		}
	});

	onChildChanged(ref, (snapshot) => {
		const changedItem: Item | any = snapshot.val();
		if (space === AM_GLOBAL_SPACE && isValidItemGroup(changedItem)) {
			eventBroadcaster.emit(ACTION_CHILD_CHANGED, space, changedItem);
		} else if (isValidItem(changedItem)) {
			eventBroadcaster.emit(ACTION_CHILD_CHANGED, space, changedItem);
		}
	});

	onChildRemoved(ref, (snapshot) => {
		const removedItem: Item = snapshot.val();
		if (typeof removedItem.id === 'string') {
			eventBroadcaster.emit(ACTION_CHILD_REMOVED, space, removedItem);
		}
	});
};

export function initializeDatabase(firebaseUserId: string) {
	if (currentFirebaseUserId !== firebaseUserId) {
		currentFirebaseUserId = firebaseUserId;

		const globalRef = getDatabaseReference(AM_GLOBAL_SPACE);

		if (globalRef) {
			applyEventBroadcaster(globalRef, AM_GLOBAL_SPACE);
		}

		const personalRef = getDatabaseReference(AM_PERSONAL_SPACE);

		if (personalRef) {
			applyEventBroadcaster(personalRef, AM_PERSONAL_SPACE);
		}
	}
}

const references = new Map<Space, DatabaseReference>();

/**
 * either returns an existing connection for the given space OR creates a new
 * connection for the given space
 */
export function getDatabaseReference(space: Space): DatabaseReference {
	if (!currentFirebaseUserId) {
		throw new Error('User id for firebase connection not set. Initialize database first.');
	}

	let ref = references.get(space);

	if (!ref) {
		const path = getPath(space, { guid: currentFirebaseUserId, env: config.VITE_BRANCH });
		ref = databaseRef(getDatabase(), path);
		references.set(space, ref);
	}

	if (!ref) {
		throw new Error(`Tried to request non-existing database reference for space: '${space}'`);
	}

	return ref;
}

export async function fetchSpace<T extends Space>(type: T): Promise<SpaceItems[T][]> {
	const filter = type === AM_GLOBAL_SPACE ? isValidItemGroup : isValidItem;

	const ref = getDatabaseReference(type);
	return new Promise((resolve) => {
		onValue(
			ref,
			(snapshot) => {
				if (type === AM_PERSONAL_SPACE && !snapshot.exists()) {
					resolve([]);
				}

				const values = snapshot.val() ?? {};
				resolve(Object.values(values as Record<string, any>).filter((value) => filter(value)));
			},
			{
				onlyOnce: true,
			}
		);
	});
}

function getPath(space: Space, { env, guid }: { env: string; guid: string }) {
	switch (space) {
		case AM_PERSONAL_SPACE:
			return `personal/${guid}`;
		case AM_GLOBAL_SPACE:
			return `global/${env}`;
	}
}
