import { AM_TYPE_VIDEO360 } from '../../../constants';

import ItemMultimedia from './ItemMultimedia';
import type { BaseProps } from './ItemMultimedia';

function AssetmanagerItemVideo360(props: BaseProps) {
	return <ItemMultimedia {...props} type={AM_TYPE_VIDEO360} />;
}

export default AssetmanagerItemVideo360;
