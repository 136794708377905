import cn from 'classnames';
import type { ReactNode } from 'react';
import { useDrop } from 'react-dnd';

import type { ItemArticle, ItemBoard, ItemBoardTeaser } from '@/client/containers/AssetManager';

import { toBoardSection } from '../../../../containers/ArticleEditor/DragAndDropHandler/dndUtils';
import {
	AM_ARTICLE,
	AM_BOARD,
	AM_BOARD_TEASER,
} from '../../../../containers/AssetManager/itemTypes';
import type { BoardDragItemRef } from '../../../../store/reducers/boardBuffer/types';

import styles from './SectionItem.module.scss';

type Props = {
	// "internalId" as well as "onDrop" are used by react-dnd
	internalId: string;
	onDrop: (dropped: BoardDragItemRef) => void;
	children?: ReactNode;
	isDroppable: boolean;
};

const assetManagerTypes = new Set([AM_ARTICLE, AM_BOARD, AM_BOARD_TEASER]);

const droppedTypeMap = {
	[AM_ARTICLE]: {
		type: 'article',
		id: 'articleId',
	},
	[AM_BOARD]: {
		type: 'board',
		id: 'boardId',
	},
	[AM_BOARD_TEASER]: {
		type: 'boardsTeaser',
		id: 'boardTeaserId',
	},
} as const;

function isItemFromAssetManager(
	_item: BoardDragItemRef | { item: ItemBoard | ItemArticle | ItemBoardTeaser },
	type: string
): _item is { item: ItemBoard | ItemArticle | ItemBoardTeaser } {
	return assetManagerTypes.has(type as string);
}

export default function DropTarget({ isDroppable, children, onDrop }: Props) {
	const [{ isOver, canDrop }, drop] = useDrop<
		BoardDragItemRef | { item: ItemBoard | ItemArticle | ItemBoardTeaser },
		void,
		{ isOver: boolean; canDrop: boolean }
	>({
		accept: toBoardSection as unknown as string[],
		collect(monitor) {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		},
		canDrop(dragItem, monitor) {
			if (isItemFromAssetManager(dragItem, monitor.getItemType() as string)) {
				return isDroppable && dragItem.item.isPublished;
			}

			return isDroppable;
		},
		drop(droppedItem, monitor) {
			const droppedType = monitor.getItemType() as string;

			if (isItemFromAssetManager(droppedItem, droppedType)) {
				const mappedType = droppedTypeMap[droppedType].type;
				const mappedId = droppedTypeMap[droppedType].id;

				onDrop({
					type: mappedType,
					id: droppedItem.item[mappedId],
					internalId: null,
					sectionId: null,
				});
			} else {
				onDrop(droppedItem as BoardDragItemRef);
			}
		},
	});

	return (
		<div
			ref={drop}
			className={cn({
				[styles.undroppable]: !isDroppable && isOver,
				[styles.droppable]: isDroppable && canDrop && isOver,
				[styles.available]: canDrop && isDroppable && !isOver,
			})}
		>
			{children}
		</div>
	);
}
