import * as constants from '@sep/br24-constants';

// type Translation = Record<string, string | Record<string, string | Record<string, string>>>;

const translations = {
	[constants.CAPABILITY_FULLY_FEATURED]: 'Artikel',
	[constants.CAPABILITY_BASIC]: 'Basisartikel',
	[constants.CAPABILITY_TEXT_ONLY]: 'Eilmeldung',

	capabilityExplanation: {
		[constants.CAPABILITY_FULLY_FEATURED]: 'Artikel für App und Webseite mit Push-Möglichkeit',
		[constants.CAPABILITY_TEXT_ONLY]: 'Textartikel mit Push',
	},

	[constants.STATUS_DRAFT]: 'Entwurf',
	[constants.STATUS_REVIEW]: 'Freigabe',
	[constants.STATUS_SCHEDULED]: 'Zeitgesteuert',
	[constants.STATUS_DEPUBLISHED]: 'Depubliziert',
	[constants.STATUS_PUBLISHED]: 'Öffentlich',
	[constants.STATUS_DELETED]: 'Gelöscht',

	[constants.PRIORITY_CRITICAL]: 'Eilmeldung',
	[constants.PRIORITY_HIGH]: 'Topstory',
	[constants.PRIORITY_NORMAL]: 'Standard',
	[constants.PRIORITY_LOW]: 'Gering',

	[constants.CATEGORY_ALL]: 'Alle Rubriken',

	[constants.MODULE_TYPE_IMAGE]: 'Bild',
	[`${constants.MODULE_TYPE_IMAGE}_DESCRIPTION`]: 'Bild',
	[constants.MODULE_TYPE_GALLERY]: 'Galerie',
	[`${constants.MODULE_TYPE_GALLERY}_DESCRIPTION`]: 'Galerie',
	[constants.MODULE_TYPE_TEXT]: 'Text',
	[`${constants.MODULE_TYPE_TEXT}_DESCRIPTION`]: 'Text',
	[constants.MODULE_TYPE_EMBED]: 'Embed',
	[`${constants.MODULE_TYPE_EMBED}_DESCRIPTION`]: 'Embed',
	[constants.MODULE_TYPE_AUDIO]: 'Audio',
	[`${constants.MODULE_TYPE_AUDIO}_DESCRIPTION`]: 'Audio',
	[constants.MODULE_TYPE_VIDEO]: 'Video',
	[`${constants.MODULE_TYPE_VIDEO}_DESCRIPTION`]: 'Video',
	[constants.MODULE_TYPE_VIDEO360]: '360° Video',
	[`${constants.MODULE_TYPE_VIDEO360}_DESCRIPTION`]: '360° Video',

	[constants.LOCATION_DISTRICT]: 'Bezirk',
	[constants.LOCATION_ZIPCODE]: 'PLZ',
	[constants.LOCATION_STREET]: 'Straße',
	[constants.LOCATION_STREETNUMBER]: 'Hausnummer',
	[constants.LOCATION_COUNTRY]: 'Land',
	[constants.LOCATION_STATE]: 'Bundesland',
	[constants.LOCATION_CITY]: 'Stadt',
	[constants.LOCATION_SUBURB]: 'Vorort',

	[constants.PUSH_NOTIFICATION_NONE]: 'Keine',
	[constants.PUSH_NOTIFICATION_SILENT]: 'Stumm',
	[constants.PUSH_NOTIFICATION_SOUND]: 'Mit Ton',

	[constants.VIDEO_RESOLUTION_LOW]: 'Gering',
	[constants.VIDEO_RESOLUTION_HIGH]: 'Hoch',
	[constants.VIDEO_RESOLUTION_HD]: 'High-definition',
	[constants.VIDEO_RESOLUTION_UHD]: 'Ultra High-definition',

	[constants.EXPIRATION_EPG_BUSINESS_CONTENT]:
		'1 - Programminformationen (EPG) sowie Unternehmensinhalte',
	[constants.EXPIRATION_REPEATED_EVENTS]: '2 - Wiederkehrende Ereignisse',
	[constants.EXPIRATION_SUPPORTING_INFO]: '3 - Programmbegleitende Inhalte',
	[constants.EXPIRATION_SERIES]: '4 - Inhalte zu Serien und Mehrteilern',
	[constants.EXPIRATION_EDUCATION]: '5 - Bildungsinhalte',
	[constants.EXPIRATION_TIME_CULTURE]: '6 - Zeit- und Kulturgeschichte',

	stageType: {
		[constants.STAGE_TYPE_NONE]: 'Kein Aufmacher',
		[constants.STAGE_TYPE_SIDE_BY_SIDE]: '2 Aufmacher',
		[constants.STAGE_TYPE_CAROUSEL]: '1 Aufmacher +',
	},

	[constants.SOCIAL_MEDIA_ACCOUNT_EMAIL]: 'E-Mail Adresse',
	[constants.SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE]: 'Facebook-Seite',
	[constants.SOCIAL_MEDIA_ACCOUNT_TWITTER]: 'Twitter',
	[constants.SOCIAL_MEDIA_ACCOUNT_INSTAGRAM]: 'Instagram',
	[constants.SOCIAL_MEDIA_ACCOUNT_YOUTUBE]: 'YouTube',
	[constants.SOCIAL_MEDIA_ACCOUNT_SPOTIFY]: 'Spotify',

	socialMediaPlaceholders: {
		[constants.SOCIAL_MEDIA_ACCOUNT_EMAIL]: 'max@mustermann.de',
		[constants.SOCIAL_MEDIA_ACCOUNT_FACEBOOK_PAGE]: 'https://www.facebook.com/max.mustermann123',
		[constants.SOCIAL_MEDIA_ACCOUNT_TWITTER]: 'https://twitter.com/maxmustermann123',
		[constants.SOCIAL_MEDIA_ACCOUNT_INSTAGRAM]: 'https://instagram.com/maxmustermann123',
		[constants.SOCIAL_MEDIA_ACCOUNT_YOUTUBE]: 'https://www.youtube.com/user/max.mustermann123',
		[constants.SOCIAL_MEDIA_ACCOUNT_SPOTIFY]: 'https://open.spotify.com/user/1234567890',
	},

	[constants.SECTION_THEME_LIGHT]: 'Hell',
	[constants.SECTION_THEME_DARK]: 'Dunkel',

	sectionAddon: {
		[constants.SECTION_ADDON_NONE]: 'Kein Modul',
		[constants.SECTION_ADDON_B5_BOERSE]: 'B5 Börse',
		[constants.SECTION_ADDON_BAYERNKARTE]: 'Bayernkarte',
		[constants.SECTION_ADDON_B5_NACHRICHTEN]: 'B5 Nachrichten',
		[constants.SECTION_ADDON_RUNDSCHAU_100_SEKUNDEN]: 'Rundschau 100 Sekunden',
	},

	boardSectionSchema: {
		title: {
			[constants.SECTION_SCHEMA_STAGE_CAROUSEL]: 'Aufmacher: Karussell',
			[constants.SECTION_SCHEMA_STAGE_SPLITTED]: 'Aufmacher: Geteilter Header',
			[constants.SECTION_SCHEMA_SMALL_3]: 'Dreier-Teaser',
			[constants.SECTION_SCHEMA_RAISED_LEFT_SIMPLE]: 'Zweier-Teaser: klein/groß',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE]: 'Zweier-Teaser: groß/klein',
			[constants.SECTION_SCHEMA_REGIONAL_4_SIMPLE]: 'Regional mit 4',
			[constants.SECTION_SCHEMA_REGIONAL_2_BIG_TOP]: 'Regional mit 2',
			[constants.SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW]: 'Regional mit 3',
			[constants.SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW]: 'Regional mit 3',
			[constants.SECTION_SCHEMA_HIGHLIGHT_3]: 'Dreier-Teaser dunkel',
			[constants.SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL]: 'Kategorie (1)',
			[constants.SECTION_SCHEMA_CATEGORY_LEFT_1_BIG]: 'Kategorie (2)',
			[constants.SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL]: 'Kategorie (3)',
			[constants.SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG]: 'Kategorie (4)',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS]: 'Meldungen rechts + ein Teaser',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL]: 'Meldungen rechts + zwei Teaser',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER]: 'Aufmacher Zweier-Teaser',
			[constants.SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER]: 'Aufmacher Vierer-Teaser',
		},
		description: {
			[constants.SECTION_SCHEMA_STAGE_CAROUSEL]:
				'Kann 1 bis 3 Teaser (Artikel/Boards/Teaser)  enthalten.',
			[constants.SECTION_SCHEMA_STAGE_SPLITTED]:
				'Muss zwei Inhalte/Seiten enthalten. Keine Navigation möglich.',
			[constants.SECTION_SCHEMA_SMALL_3]:
				'Muss 3 Teaser enthalten. Anzeige: Alle Teaser nebeneinander in derselben Größe.',
			[constants.SECTION_SCHEMA_RAISED_LEFT_SIMPLE]:
				'Muss 2 Teaser enthalten. Anzeige: Erster Teaser klein, zweiter groß.',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE]:
				'Muss 2 Teaser enthalten. Anzeige: Erster Teaser groß, zweiter klein.',
			[constants.SECTION_SCHEMA_REGIONAL_4_SIMPLE]: '4 Teaser + Karte + B5 Nachrichten/RS100.',
			[constants.SECTION_SCHEMA_REGIONAL_2_BIG_TOP]: '2 Teaser + Karte + B5 Nachrichten/RS100.',
			[constants.SECTION_SCHEMA_REGIONAL_1_BIG_TOP_2_SMALL_BELOW]:
				'3 Teaser + Karte + B5 Nachrichten/RS100.',
			[constants.SECTION_SCHEMA_REGIONAL_2_SMALL_TOP_1_BIG_BELOW]:
				'3 Teaser + Karte + B5 Nachrichten/RS100.',
			[constants.SECTION_SCHEMA_HIGHLIGHT_3]: '3 Teaser. Anzeige: Dunkler Hintergrund',
			[constants.SECTION_SCHEMA_CATEGORY_LEFT_2_SMALL]:
				'Zeigt 5 Artikel aus einer Kategorie + 2 Teaser an.',
			[constants.SECTION_SCHEMA_CATEGORY_LEFT_1_BIG]:
				'Zeigt 5 Artikel aus einer Kategorie + 1 Teaser an.',
			[constants.SECTION_SCHEMA_CATEGORY_RIGHT_2_SMALL]:
				'Zeigt 2 Teaser + 5 Artikel aus einer Kategorie an.',
			[constants.SECTION_SCHEMA_CATEGORY_RIGHT_1_BIG]:
				'Zeigt 1 Teaser + 5 Artikel aus einer Kategorie an.',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS]:
				'Zeigt links einen Teaser an und rechts maximal sieben Meldungen mit Links zur Meldungsseite und den B5-Nachrichten.',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SHORTNEWS_2_SMALL]:
				'Zeigt links zwei Teaser an und rechts maximal fünf Meldungen mit Links zur Meldungsseite und den B5-Nachrichten.',
			[constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER]: '2 Teaser mit Radio Banner',
			[constants.SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER]: '4 Teaser mit Radio Banner',
		},
	},

	sectionPicker: {
		modalTitle: 'Neue Sektion hinzufügen',
		tabSectionsStandard: 'Standard-Sektionen',
		tabSectionsShortnews: 'Sektionen mit Meldungen',
		tabSectionshero: 'Aufmacher',
	},

	[constants.GENDER_MALE]: 'männlich',
	[constants.GENDER_FEMALE]: 'weiblich',

	type: 'Typ',
	pleaseSelectPrimaryCategorie: 'Bitte Kategorie wählen',
	pleaseSelect: 'Bitte wählen',
	searchLocation: 'Ort eingeben...',
	searchLocationError:
		'Der Ort konnte nicht korrekt erfasst werden. Bitte einen Ort über die Suchliste auswählen.',
	searchLocationTitle: 'Wetter',
	searchLocationInputLabel: 'Ort suchen',
	searchLocationCityError:
		'Der ausgewählte Ort für das Wetter muss zwingend in Bayern liegen. Bitte überprüfe den Ort.',
	searchLocationInfo:
		'Sollte kein Ort ausgewählt sein, zeigt die Wetterintegration immer das Wetter der Regierungsbezirke an.',
	create: 'Erstellen',
	cancel: 'Abbrechen',
	title: 'Überschrift',
	slug: 'URL-Kürzel',
	description: 'Teasertext',
	primaryCategory: 'Primäre Kategorie',
	createNewItem: 'Neuen Eintrag erstellen',
	createNewBoard: 'Verteilseite erstellen',
	createNewArticle: 'Neu erstellen',
	createNewTeaser: 'Neuen Linkteaser erstellen',
	section: 'Sammlung',
	firstname: 'Vorname',
	lastname: 'Nachname',
	jobTitle: 'Job-Beschreibung',
	url: 'URL',
	label: 'Linktext',
	'image.url': 'Bild-URL',
	teaserText: 'Teaser-Text',
	mobileTitleLabel: 'Anzeige Titel Mobil',
	mobileTitleText: 'Sektionen-Titel auf Mobilgeräten ganz oben anzeigen',

	articleValidationErrors: {
		'any.required': 'Pflichtfeld.',
		'any.empty': 'Darf nicht leer sein.',
		'string.min': 'Muss aus mindestens {limit} Zeichen bestehen.',
		'string.max': 'Darf aus maximal {limit} Zeichen bestehen.',
	},

	articleSeoMetaDescLabel: 'Meta Description',
	articleSeoMetaDescPlaceholder: 'Beschreibungstext eingeben',
	articleSeoMetaDescTooltip:
		'Eine Meta Description ist ein kurzer Beschreibungstext, der den Inhalt einer Seite für Suchmaschinen zusammenfassend beschreiben soll.',

	articleMigrationMetaHelp:
		'Artikel die mit Sophora verknüpft wurden, leiten Anfragen, die an br.de/nachrichten gestellt werden, auf die neue BR24 - Seite weiter. Wichtiger Hinweis: Verknüpfte Sophoraartikel müssen nach der Migration händisch depubliziert werden.',

	noData: 'Keine Daten gefunden.',
	noDataSelectedDateRange: 'Im angegebenen Zeitraum wurden keine Daten gefunden.',

	help: {
		moduleAllocationStats: 'Beschreibungstext folgt in Kürze.',
		sourceOriginReferenceStats: 'Beschreibungstext folgt in Kürze.',
		categoryArticleStats: 'Beschreibungstext folgt in Kürze.',
	},

	auth: {
		'user: Required, password: Required': 'Bitte gib einen Benutzernamen und ein Passwort ein!',
		'user: Required': 'Bitte gib Deinen Benutzernamen ein!',
		'password: Required': 'Bitte gib Dein Passwort ein!',
		'username or password invalid': 'Benutzername und/oder Passwort falsch',
		'401 HTTP/2.0 401': 'Session abgelaufen - bitte melde Dich neu an!',
		401: 'Session abgelaufen - bitte melde Dich neu an!',
	},
} as const;

export default translations;
