import { Component } from 'react';
import styled from 'styled-components';

import type { SocialMediaAccount } from '../SocialMediaAccounts';

import AccountListEditor from './AccountListEditor';
import { AccountListShow } from './AccountListShow';

const Item = styled.li`
	background-color: #eaeef1;
	padding: 1.2rem 3rem;
	margin-bottom: 0.8rem;
	border-radius: 4px;
	position: relative;

	> h2,
	h3 {
		color: #4a4a4a;
		font-size: 18px;
		line-height: 26px;
		margin: 0;
	}
	> h2 {
		font-weight: 600;
	}
	> h3 {
		font-weight: 400;
	}
`;

type Props = {
	index: number;
	isEditable: boolean | undefined | null;
	item: SocialMediaAccount | null;
	onEditEnded: (index: number) => void;
	onEditStarted: (index: number) => void;
	onSave: (data: SocialMediaAccount) => void;
	onDelete: () => void;
};

type State = {
	isEditing: boolean;
};

export class AccountListItem extends Component<Props, State> {
	state = {
		isEditing: false,
	};

	handleEditForm = () => {
		const { index, onEditStarted } = this.props;
		this.setState(
			{
				isEditing: true,
			},
			() => {
				onEditStarted(index);
			}
		);
	};

	handleSaveForm = (data?: SocialMediaAccount | null) => {
		const { index, onSave, onEditEnded } = this.props;

		this.setState(
			{
				isEditing: false,
			},
			() => {
				onEditEnded(index);
				if (data && Object.keys(data).length > 0) {
					onSave(data);
				}
			}
		);
	};

	render() {
		const { isEditing } = this.state;
		const { isEditable, item, onDelete } = this.props;

		if (!item) {
			return null;
		}

		const { service, accountName, label } = item;

		return (
			<Item>
				{isEditing ? (
					<AccountListEditor
						service={service}
						accountName={accountName}
						label={label}
						onSave={this.handleSaveForm}
					/>
				) : (
					<AccountListShow
						service={service}
						accountName={accountName}
						label={label}
						isEditable={isEditable}
						onEdit={this.handleEditForm}
						onDelete={onDelete}
					/>
				)}
			</Item>
		);
	}
}
