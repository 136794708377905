import { BOARD_BUFFER_SECTION_ITEM_DELETE } from '@sep/br24-constants';

import type { AppThunkAction } from '@/client/store/redux';

import { findSectionItemById } from '../util/find';

import { validateSection } from './validate';

export type DeleteItemInSectionAction = {
	type: typeof BOARD_BUFFER_SECTION_ITEM_DELETE;
	constraint: {
		boardId: string;
		sectionId: string;
		sectionItemId: string;
	};
};

/**
 * Deletes an existing item by `sectionItemInternalId`.
 *
 * @param  {string} sectionItemInternalId
 * @return {Function}
 */
export default function deleteItemInSection(
	sectionItemInternalId: string
): AppThunkAction<DeleteItemInSectionAction> {
	return (dispatch, getState) => {
		const sectionItem = findSectionItemById(sectionItemInternalId, getState);

		if (!sectionItem) {
			return;
		}

		dispatch({
			type: BOARD_BUFFER_SECTION_ITEM_DELETE,
			constraint: {
				boardId: sectionItem.__meta__.boardId,
				sectionId: sectionItem.__meta__.sectionId,
				sectionItemId: sectionItem.__meta__.internalId,
			},
		});

		dispatch(
			validateSection(sectionItem.__meta__.sectionId, { skipIfAutoValidationIsDisabled: true })
		);
	};
}
