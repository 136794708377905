import cn from 'classnames';
import type { ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './SlideTransition.module.scss';

type TransitionItemProps = {
	comeFromWhere: 'comeFromRight' | 'comeFromLeft';
	children: ReactNode;
	active: boolean;
};

export function SlideTransitionItem({ children, comeFromWhere, active }: TransitionItemProps) {
	return (
		<CSSTransition
			in={active}
			unmountOnExit={true}
			timeout={500}
			classNames={{
				enter: styles[`${comeFromWhere}Enter`],
				enterActive: styles[`${comeFromWhere}EnterActive`],
				enterDone: styles[`${comeFromWhere}EnterDone`],
				exit: styles[`${comeFromWhere}Exit`],
				exitActive: styles[`${comeFromWhere}ExitActive`],
				exitDone: styles[`${comeFromWhere}ExitDone`],
			}}
		>
			{children}
		</CSSTransition>
	);
}

type TransitionGroupProps = {
	children?: ReactNode;
	className?: string;
};

export function SlideTransitionGroup({ children, className }: TransitionGroupProps) {
	return (
		<TransitionGroup className={cn(styles.transitionGroup, className)}>{children}</TransitionGroup>
	);
}
