import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { Title, Content } from './AssetManagerItemControl';
import TypeIcon from './TypeIcon';

type AssetManagerItemInvalidProps = {
	type: string;
};

const InvalidContent = styled(Content)`
	color: #f3a3a3;
	h6 {
		color: #f3a3a3;
	}
`;

export default function AssetManagerItemInvalid({ type }: AssetManagerItemInvalidProps) {
	const translate = useTranslate();

	return (
		<>
			<TypeIcon type={type} />
			<InvalidContent>
				<Title>{translate(`assetManager.item.invalid.title`)}</Title>
				{translate(`assetManager.item.invalid.description`)}
			</InvalidContent>
		</>
	);
}
