import { Tooltip } from 'antd';
import moment from 'moment';

import config from '../../../config';

import type { ArticlesTableArticle } from './types';

type Props = {
	node: ArticlesTableArticle;
	dateKey: 'firstPublicationDate' | 'publicationDate' | 'lastEdited';
};

const toLocaleDateTimeString = (dateString?: string) => {
	if (!dateString || !Boolean(dateString)) {
		return null;
	}

	const date = new Date(dateString);

	return `${date.toLocaleDateString('de-DE', { dateStyle: 'short' })} - ${date.toLocaleTimeString(
		'de-DE',
		{
			timeStyle: 'short',
		}
	)}`;
};

const InfoItem = ({ title, content }: { title: string; content: string | null }) => {
	return (
		<div style={{ marginBottom: '0.5em' }}>
			<span style={{ display: 'block' }}>{title}</span>
			<span
				style={{
					display: 'inline-block',
					borderRadius: 4,
					backgroundColor: 'black',
					padding: '2px 4px',
				}}
			>
				{content}
			</span>
		</div>
	);
};

const Info = ({
	publicationDate,
	firstPublicationDate,
	lastEdited,
	createdAt,
}: {
	firstPublicationDate?: string | null;
	publicationDate?: string | null;
	lastEdited?: string;
	createdAt?: string;
}) => {
	return (
		<>
			<div style={{ fontSize: 12 }}>
				{publicationDate && publicationDate !== firstPublicationDate && (
					<InfoItem title="Republikation" content={toLocaleDateTimeString(publicationDate)} />
				)}
				{firstPublicationDate ? (
					<InfoItem
						title="Erstpublikation"
						content={toLocaleDateTimeString(firstPublicationDate)}
					/>
				) : (
					<InfoItem title="Angelegt" content={toLocaleDateTimeString(createdAt)} />
				)}
				<InfoItem title="Aktualisiert" content={toLocaleDateTimeString(lastEdited)} />
			</div>
		</>
	);
};

export default function ArticlesTableRowDate(props: Props) {
	const { node, dateKey } = props;

	if (!node) {
		return null;
	}

	const date =
		dateKey === 'lastEdited'
			? node['lastEdited']
			: node['publicationDate'] ?? node['firstPublicationDate'];

	// console.log(node, dateKey);

	return (
		<>
			<Tooltip
				autoAdjustOverflow={true}
				destroyTooltipOnHide={true}
				key="article_date"
				style={{
					backgroundColor: 'transparent',
				}}
				placement="left"
				title={
					<Info
						publicationDate={node.publicationDate}
						firstPublicationDate={node.firstPublicationDate}
						lastEdited={node.lastEdited}
						createdAt={node.createdAt}
					/>
				}
			>
				{moment.utc(date).local().format(config.moment.absolute)}
			</Tooltip>
		</>
	);
}
