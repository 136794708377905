import type {
	DISTRICT_UNTERFRANKEN,
	DISTRICT_OBERFRANKEN,
	DISTRICT_MITTELFRANKEN,
	DISTRICT_SCHWABEN,
	DISTRICT_OBERBAYERN,
	DISTRICT_NIEDERBAYERN,
	DISTRICT_OBERPFALZ,
} from '@sep/br24-constants';
import type { User } from '@sep/cms-auth-client';
import type { LiteralUnion } from 'type-fest';

import type {
	ArticleEditor_article$data,
	TagsStatus,
} from './__generated__/ArticleEditor_article.graphql';
import type { NormalizeResult } from './actions/util/normalize';

export interface EntityMeta {
	__meta__: {
		created: boolean;
		updated: boolean;
		deleted: boolean;
	};
}

export interface Validation {
	[property: string]: string[];
}

export interface EntityValidation {
	__validation__: Validation | null;
}

type ModuleOrder = {
	order: number;
};

// MODULE PARTS

export type Image = {
	url?: string | null;
	altText?: string | null;
	copyright?: string | null;
	title?: string | null;
};

export type Gallery = Array<Image>;

export type Audio = {
	url: string | undefined | null;
	copyright: string | undefined | null;
	title: string | undefined | null;
	duration: number | undefined | null;
	thumbnail: Image | undefined | null;
};

export type Video = {
	url: string | undefined | null;
	copyright: string | undefined | null;
	title: string | undefined | null;
	duration: number | undefined | null;
	thumbnail: Image | undefined | null;
};

export type Embed = {
	service?: string | null;
	source?: string | null;
	url?: string | null;
	altText?: string | null;
	thumbnail?: Image | null;
};

export type Video360Source = {
	url: string | undefined | null;
	resolution: 'HLS_LOW' | undefined | null | 'HLS_HIGH' | 'FULL_HD' | 'UHD';
};

export type Video360 = {
	copyright: string | undefined | null;
	title: string | undefined | null;
	duration: number | undefined | null;
	thumbnail: Image | undefined | null;
	sources: Array</* VideoSource */ any> | undefined | null;
};

export type Livestream = {
	livestreamId: string | undefined | null;
	programmeId: string | undefined | null;
	thumbnail: null | Image;
};

export type MetaTeaserData = {
	title: string;
	date: string | null;
	description: string | null;
	source: string;
	thumbnail: string | null;
};

// MODULES

export type ModuleType =
	| 'GALLERY'
	| 'EMBED'
	| 'TEXT'
	| 'AUDIO'
	| 'VIDEO'
	| 'IMAGE'
	| 'VIDEO360'
	| 'LIVESTREAM';

export type PersistedModule = {
	id: string;
	rowId: number;
	articleId: string;
};

// Gallery

export type RawModuleTypeGallery = {
	type: 'GALLERY';
	gallery: null | Gallery;
};

export type UnpersistedModuleTypeGallery = EntityMeta &
	EntityValidation &
	RawModuleTypeGallery &
	ModuleOrder;

export type ModuleTypeGallery = UnpersistedModuleTypeGallery & PersistedModule;

// Embed

export type RawModuleTypeEmbed = {
	type: 'EMBED';
	embed: null | Embed;
};

export type UnpersistedModuleTypeEmbed = EntityMeta &
	EntityValidation &
	RawModuleTypeEmbed &
	ModuleOrder;

export type ModuleTypeEmbed = UnpersistedModuleTypeEmbed & PersistedModule;

// Text

export type RawModuleTypeText = {
	type: 'TEXT';
	text: null | string;
};

export type UnpersistedModuleTypeText = EntityMeta &
	EntityValidation &
	RawModuleTypeText &
	ModuleOrder;

export type ModuleTypeText = UnpersistedModuleTypeText & PersistedModule;

// Audio

export type RawModuleTypeAudio = {
	type: 'AUDIO';
	audio: null | Audio;
	meta: null | string;
};

export type UnpersistedModuleTypeAudio = EntityMeta &
	EntityValidation &
	RawModuleTypeAudio &
	ModuleOrder;

export type ModuleTypeAudio = UnpersistedModuleTypeAudio & PersistedModule;

// Video

export type RawModuleTypeVideo = {
	type: 'VIDEO';
	video: null | Video;
	meta: null | string;
};

export type UnpersistedModuleTypeVideo = EntityMeta &
	EntityValidation &
	RawModuleTypeVideo &
	ModuleOrder;

export type ModuleTypeVideo = UnpersistedModuleTypeVideo & PersistedModule;

// Image

export type RawModuleTypeImage = {
	type: 'IMAGE';
	image: null | Image;
};

export type UnpersistedModuleTypeImage = EntityMeta &
	EntityValidation &
	RawModuleTypeImage &
	ModuleOrder;

export type ModuleTypeImage = UnpersistedModuleTypeImage & PersistedModule;

// Video 360

export type RawModuleTypeVideo360 = {
	type: 'VIDEO360';
	video360: null | Video360;
};

export type UnpersistedModuleTypeVideo360 = EntityMeta &
	EntityValidation &
	RawModuleTypeVideo360 &
	ModuleOrder;

export type ModuleTypeVideo360 = UnpersistedModuleTypeVideo360 & PersistedModule;

// Livestream

export type RawModuleTypeLivestream = {
	type: 'LIVESTREAM';
	livestream: null | Livestream;
};

export type UnpersistedModuleTypeLivestream = EntityMeta &
	EntityValidation &
	RawModuleTypeLivestream &
	ModuleOrder;

export type ModuleTypeLivestream = UnpersistedModuleTypeLivestream & PersistedModule;

export type Module =
	| ModuleTypeGallery
	| ModuleTypeEmbed
	| ModuleTypeText
	| ModuleTypeAudio
	| ModuleTypeVideo
	| ModuleTypeImage
	| ModuleTypeVideo360
	| ModuleTypeLivestream;

export type UnpersistedModule =
	| UnpersistedModuleTypeGallery
	| UnpersistedModuleTypeEmbed
	| UnpersistedModuleTypeText
	| UnpersistedModuleTypeAudio
	| UnpersistedModuleTypeVideo
	| UnpersistedModuleTypeImage
	| UnpersistedModuleTypeVideo360
	| UnpersistedModuleTypeLivestream;

export type RawModule =
	| RawModuleTypeGallery
	| RawModuleTypeEmbed
	| RawModuleTypeText
	| RawModuleTypeAudio
	| RawModuleTypeVideo
	| RawModuleTypeImage
	| RawModuleTypeVideo360
	| RawModuleTypeLivestream;

export type Link = {
	url: string | null;
	label: string | null;
	isExternal?: boolean;
};

export type LinkPatch = {
	url?: string;
	label?: string;
	isExternal?: boolean;
};

export type Teaser = {
	meta: any;
	url: string;
	title: string;
	editUrl?: string;
	isFresh?: boolean;
	isExternal: boolean;
	isLoading?: boolean;
	description?: string;
	thumbnailUrl?: string;
	publicationDate?: Date;
	status?: string;
};

export type TeaserPatch = {
	url: string | null;
	editUrl?: string;
	isFresh?: boolean;
	isExternal?: boolean;
	isLoading?: boolean;
	label: string | null;
	description?: string;
	thumbnailUrl?: string;
	publicationDate?: Date;
	newItem?: Omit<TeaserPatch, 'newItem'>;
	article: null | { rowId: string; status: Status };
	board: null | { rowId: string; status: Status };
	meta: null;
};

export type Priority = 'NORMAL' | 'HIGH' | 'CRITICAL' | 'LOW';

export type Status = 'DRAFT' | 'REVIEW' | 'SCHEDULED' | 'DEPUBLISHED' | 'PUBLISHED' | 'DELETED';

export type Capability = 'FULLY_FEATURED' | 'BASIC' | 'TEXT_ONLY';

export type District =
	| typeof DISTRICT_UNTERFRANKEN
	| typeof DISTRICT_OBERFRANKEN
	| typeof DISTRICT_MITTELFRANKEN
	| typeof DISTRICT_SCHWABEN
	| typeof DISTRICT_OBERBAYERN
	| typeof DISTRICT_NIEDERBAYERN
	| typeof DISTRICT_OBERPFALZ;

export type Expiration =
	| 'EPG_BUSINESS_CONTENT'
	| 'REPEATED_EVENTS'
	| 'SUPPORTING_INFO'
	| 'SERIES'
	| 'EDUCATION'
	| 'TIME_CULTURE';

export type PushNotification = 'NONE' | 'SILENT' | 'SOUND';

export type Author = NormalizeResult['authors'][string];

export type Article = Omit<
	NormalizeResult['articles'][string],
	'tags' | 'redirections' | 'modules'
> & {
	/**
	 * The rowId of the tags. It is a number if it is already saved in the database
	 * and a string with a temporary id as a string type if the redirection is not saved to
	 * the database yet.
	 */
	tags: (number | string)[];
	/**
	 * The rowId of the redirection. It is a number if it is already saved in the database
	 * and a string with a temporary id as a string type if the redirection is not saved to
	 * the database yet.
	 */
	redirections: (number | string)[];
	/**
	 * The rowId of the modules. It is a number if it is already saved in the database
	 * and a string with a temporary id as a string type if the redirection is not saved to
	 * the database yet.
	 */
	modules: (number | string)[];
};

export type AuthorPatch = {
	guid?: string;
	firstname?: string;
	lastname?: string;
	order?: number;
};

export type Redirection = Omit<NormalizeResult['redirections'][number], 'rowId' | 'id'> & {
	/**
	 * The rowId of the redirection. It is a number if it is already saved in the database
	 * and a string with a temporary id as a string type if the redirection is not saved to
	 * the database yet.
	 */
	rowId: number | string;

	/**
	 * The id in the store is optional, if the redirectino is not yet saved to the database.
	 */
	id?: string;
};

// Tags have a number type for the rowId, however we use string ids for new unsaved tags
export type Tag = Omit<NormalizeResult['tags'][number], 'rowId'> & {
	/**
	 * The rowId of the redirection. It is a number if it is already saved in the database
	 * and not set if the redirection is not saved to the database yet.
	 */
	rowId?: number;
};

export type RedirectionTemplate = EntityMeta &
	EntityValidation & {
		sophoraId: string;
		articleId: string;
	};

export type State = {
	isEdited: boolean;
	isWorking: boolean;

	latestFatalError: null | Error;
	activeArticleId: string | undefined | null;

	articles: {
		[id: string]: Article;
	};
	modules: {
		[id: string]: Module;
	};
	authors: {
		[id: string]: Author;
	};
	/**
	 * Redirections are indexed by number if they already exist in the database and by
	 * string when they are not saved to the database yet.
	 */
	redirections: Record<string | number, Redirection>;

	/**
	 * Tags are indexed by number if they already exist in the database and by
	 * string when they are not saved to the database yet.
	 */
	tags: Record<string | number, Tag>;

	/**
	 * TODO: Add description
	 */
	user: User | null;
};

// REDUX ACTIONS & INPUTS

export type ActionTypeModuleCreate = 'ARTICLE_EDITOR_MODULE_CREATE';
export type ActionTypeModuleUpdate = 'ARTICLE_EDITOR_MODULE_UPDATE';
export type ActionTypeModuleDelete = 'ARTICLE_EDITOR_MODULE_DELETE';
export type ActionTypeLinkFromAssetManager = 'ARTICLE_EDITOR_LINK_ASSET_FROM_ASSETMANAGER';
export type ActionTypeUpdate = 'ARTICLE_EDITOR_UPDATE';
export type ActionTypeSave = 'ARTICLE_EDITOR_SAVE';
export type ActionTypeInitialize = 'ARTICLE_EDITOR_INITIALIZE';
export type ActionTypeExit = 'ARTICLE_EDITOR_EXIT';
export type ActionTypeAuthorAdd = 'ARTICLE_EDITOR_AUTHOR_ADD';
export type ActionTypeAuthorRemove = 'ARTICLE_EDITOR_AUTHOR_REMOVE';
export type ActionTypeAuthorUpdate = 'ARTICLE_EDITOR_AUTHOR_UPDATE';
export type ActionTypeRedirectionCreate = 'ARTICLE_EDITOR_REDIRECTION_CREATE';
export type ActionTypeRedirectionDelete = 'ARTICLE_EDITOR_REDIRECTION_DELETE';
export type ActionTypeTagAdd = 'ARTICLE_EDITOR_TAG_ADD';
export type ActionTypeTagRemove = 'ARTICLE_EDITOR_TAG_REMOVE';
export type ActionTypeArticleCreateFromTemplate = 'ARTICLE_CREATE_FROM_TEMPLATE';
export type ActionTypeValidate = 'ARTICLE_EDITOR_VALIDATE';

export type ActionType =
	| ActionTypeAuthorAdd
	| ActionTypeAuthorRemove
	| ActionTypeAuthorUpdate
	| ActionTypeInitialize
	| ActionTypeExit
	| ActionTypeModuleCreate
	| ActionTypeModuleDelete
	| ActionTypeModuleUpdate
	| ActionTypeRedirectionCreate
	| ActionTypeRedirectionDelete
	| ActionTypeSave
	| ActionTypeTagAdd
	| ActionTypeTagRemove
	| ActionTypeUpdate
	| ActionTypeArticleCreateFromTemplate
	| ActionTypeValidate;

export type CreateModuleInput = CreateModulePayload;

export type CreateModulePayload = {
	type: ModuleType;
	order: number;
	data?: Omit<RawModule, 'type'>;
};
export type CreateModuleAction = {
	type: ActionTypeModuleCreate;
	payload: CreateModulePayload[];
};
export type CreateModuleActionFunction = (input: CreateModuleInput) => void;

export type AddTagInput = {
	id?: number;
	text: string;
	status?: TagsStatus;
};
export type AddTagPayload = {
	count: number;
	id: number | string;
	text: string;
	status: TagsStatus;
};
export type AddTagAction = {
	type: ActionTypeTagAdd;
	payload: AddTagPayload;
};
export type AddTagActionFunction = (a: { count?: number; id?: number; text: string }) => void;

export type RemoveTagAction = {
	type: ActionTypeTagRemove;
	payload: number | string;
};
export type RemoveTagActionFunction = (id: number) => void;

export type DeleteModuleAction = {
	type: ActionTypeModuleDelete;
	payload: string | number;
};
export type DeleteModuleActionFunction = (id: string | number) => void;

export type InitializeInput = Omit<
	ArticleEditor_article$data,
	' $fragmentSpreads' | ' $fragmentType'
>;

// TODO: Add user: User | undefined;
export type InitializePayload = NormalizeResult;

export type InitializeAction = {
	type: ActionTypeInitialize;
	constraint: string;
	payload: InitializePayload;
};

export type ExitAction = {
	type: ActionTypeExit;
};

export type InitializeActionFunction = (input: InitializeInput) => void;

export type UpdateInput = Partial<Article>;

export type UpdatePayload = Partial<Article>;

export type UpdateAction = {
	type: ActionTypeUpdate;
	payload: UpdatePayload;
};
export type UpdateActionFunction = (patch: UpdateInput) => void;

export type StatusRequest = 'STATUS_REQUEST';
export type StatusSuccess = 'STATUS_SUCCESS';
export type StatusError = 'STATUS_ERROR';
export type SaveStatus = StatusRequest | StatusSuccess | StatusError;

export type SaveInput = {
	status: Status;
};
export type SavePayload = {
	status: Status;
};

export type SaveRequestAction = {
	type: ActionTypeSave;
	status: StatusRequest;
	payload: SavePayload;
};
export type SaveSuccessAction = {
	type: ActionTypeSave;
	status: StatusSuccess;
	payload: SavePayload;
};

export type UpdateStatusAction = {
	type: ActionTypeUpdate;
	payload: UpdateInput;
};

export type SaveErrorAction = {
	type: ActionTypeSave;
	status: StatusError;
	payload: Error;
};

export type BeforeSaveFunctionCallback = (isVisible: boolean) => Promise<void>;
export type SaveActionFunctionCallback = (status?: string) => Promise<void>;
export type SaveActionErrorCallback = (err: Error, status?: string) => void;
export type SaveActionFunction = (
	input?: UpdateInput,
	onSaveFinished?: SaveActionFunctionCallback,
	onSaveError?: SaveActionErrorCallback,
	onBeforeSave?: BeforeSaveFunctionCallback
) => void;

export type ArticleCreateFromTemplateErrorAction = {
	type: ActionTypeArticleCreateFromTemplate;
	status: StatusError;
	payload: Error;
};
export type ArticleCreateFromTemplateRequestAction = {
	type: ActionTypeArticleCreateFromTemplate;
	status: StatusRequest;
};
export type ArticleCreateFromTemplateSuccessAction = {
	type: ActionTypeArticleCreateFromTemplate;
	status: StatusSuccess;
	payload: UpdatePayload;
};
export type CreateArticleFromTemplateActionFunction = (
	articleRowId: string,
	a: (newRowId: string) => void
) => void;

export type UpdateModuleInput = {
	type: string;
	value: any;
	meta?: any;
};

export type UpdateModulePayload = LiteralUnion<UpdateModuleInput, any>;
export type UpdateModuleAction = {
	type: ActionTypeModuleUpdate;
	constraint: string | number;
	payload: UpdateModulePayload;
};
export type UpdateModuleActionFunction = (id: string, patch: UpdateModuleInput) => void;

export type AddAuthorAction = {
	type: ActionTypeAuthorAdd;
	payload: string;
};
export type AddAuthorActionFunction = (guid: string) => void;

export type RemoveAuthorAction = {
	type: ActionTypeAuthorRemove;
	payload: number;
};
export type RemoveAuthorActionFunction = (removeIndex: number) => void;

export type UpdateAuthorAction = {
	type: ActionTypeAuthorUpdate;
	payload: {
		dragIndex: number;
		hoverIndex: number;
	};
};
export type UpdateAuthorActionFunction = (dragIndex: number, hoverIndex: number) => void;

export type CreateRedirectionAction = {
	type: ActionTypeRedirectionCreate;
	payload: string;
};
export type CreateRedirectionActionFunction = (sophoraId: string) => void;

export type DeleteRedirectionAction = {
	type: ActionTypeRedirectionDelete;
	payload: string | number;
};
export type DeleteRedirectionActionFunction = (id: string | number) => void;

export type ValidationMembership = 'modules' | 'articles';
export type ValidateAction = {
	type: ActionTypeValidate;
	payload: {
		id: any;
		membership: ValidationMembership;
		result: null | Validation;
	};
};
export type ValidationActionFunction = (
	membership: ValidationMembership,
	validation: null | Validation
) => void;

export function isLivestreamModule(module: Module): module is ModuleTypeLivestream {
	return module.type === 'LIVESTREAM';
}
