import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../environment';
import type { CurrentFilterType } from '../Dashboard';

import type { SourceOriginReferenceStatsContainerQuery } from './__generated__/SourceOriginReferenceStatsContainerQuery.graphql';

type Props = {
	startDate: string;
	endDate: string;
	filter: CurrentFilterType;
	render: QueryRendererProps<SourceOriginReferenceStatsContainerQuery>['render'];
};

export default function ({ startDate, endDate, filter, ...rest }: Props) {
	return (
		<QueryRenderer<SourceOriginReferenceStatsContainerQuery>
			environment={environment}
			variables={{ startDate, endDate, ...(filter || {}) }}
			query={graphql`
				query SourceOriginReferenceStatsContainerQuery(
					$startDate: Datetime
					$endDate: Datetime
					$districtsFilter: [District]
					$categoryFilter: String
				) {
					stats: articleStatsForSourceOrigin(
						fromDate: $startDate
						toDate: $endDate
						districtsFilter: $districtsFilter
						categoryFilter: $categoryFilter
					) {
						edges {
							node {
								sourceOrigin
								count
							}
						}
					}
				}
			`}
			{...rest}
		/>
	);
}
