import cn from 'classnames';
import { Component, type MouseEvent } from 'react';

import styles from './ColorPicker.module.scss';

type Props = {
	color: string;
	onClick: (color: string) => void;
	isActive: boolean;
	isSmall?: boolean;
};

export default class Color extends Component<Props> {
	handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		const { onClick, color } = this.props;

		event.preventDefault();
		onClick(color);
	};

	render() {
		const { color, isActive, isSmall } = this.props;

		return (
			<button
				type="button"
				className={cn(styles.color, { [styles.isActive]: isActive }, { [styles.isSmall]: isSmall })}
				style={{ backgroundColor: color, boxShadow: isActive ? `0px 0 5px ${color}` : undefined }}
				onClick={this.handleClick}
			/>
		);
	}
}
