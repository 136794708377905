import moment from 'moment';

import type { BoardsTableItem } from '../BoardsTableContainer';

const defaultRegions = [
	'/bayern/mittelfranken',
	'/bayern/niederbayern',
	'/bayern/oberbayern',
	'/bayern/oberfranken',
	'/bayern/oberpfalz',
	'/bayern/schwaben',
	'/bayern/unterfranken',
];

export type TransformedBoard = ReturnType<typeof transformBoards>[number];

export function transformBoards(boards: BoardsTableItem[], defaultCategories: string[]) {
	const sortAlphabetically = (board1: BoardsTableItem, board2: BoardsTableItem) =>
		board1.title.localeCompare(board2.title);

	const homepage = boards
		.filter((board) => board.fullSlug === '/')
		.map((board) => ({ section: 0, ...board }));

	const regions = boards
		.filter((board) => defaultRegions.includes(board.fullSlug))
		.sort(sortAlphabetically)
		.map((board) => ({ section: 1, ...board }));

	const categories = boards
		.filter((board) => defaultCategories.includes(board.fullSlug))
		.sort(sortAlphabetically)
		.map((board) => ({ section: 2, ...board }));

	const rest = boards
		.filter(
			(board) =>
				board.fullSlug !== '/' &&
				!defaultRegions.includes(board.fullSlug) &&
				!defaultCategories.includes(board.fullSlug)
		)
		.sort(
			(board1, board2) => moment(board2.createdAt).valueOf() - moment(board1.createdAt).valueOf()
		)
		.map((board) => ({ section: 3, ...board }));

	return [...homepage, ...regions, ...categories, ...rest].map((board) => ({
		editedAt: board.updatedAt ? board.updatedAt : board.createdAt,
		key: board.rowId,
		...board,
	}));
}
