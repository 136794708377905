import type { AppThunkAction } from '@/client/store/redux';

import type { LockItemForDeletionAction } from '..';
import { AM_GLOBAL_SPACE, AM_PERSONAL_SPACE } from '../../../constants';
import { LOCK_ITEM_FOR_DELETION } from '../actionTypes';
import { lockItemInSpace } from '../database';

const lockItemForDeletion =
	(itemId: string): AppThunkAction<LockItemForDeletionAction> =>
	(dispatch, getState) => {
		const assetManager = getState()?.assetManager;

		if (!assetManager) {
			return;
		}
		const space = assetManager?.activeSpace;
		const activeGlobalGroup = assetManager?.activeGlobalGroupId || '';

		dispatch({
			type: LOCK_ITEM_FOR_DELETION,
			payload: {
				space,
				activeGlobalGroup,
				itemId,
			},
		});

		const isLockedForDeletion =
			space === AM_GLOBAL_SPACE
				? assetManager?.spaces[AM_GLOBAL_SPACE][activeGlobalGroup]?.items[itemId]
						?.isLockedForDeletion
				: assetManager?.spaces[AM_PERSONAL_SPACE][itemId]?.isLockedForDeletion;

		lockItemInSpace(space, itemId, activeGlobalGroup, !Boolean(isLockedForDeletion));
	};

export default lockItemForDeletion;
