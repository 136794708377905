import type { User } from '@sep/cms-auth-client';
import UUID from 'uuid-js';

import type { AppThunkAction } from '@/client/store/redux';

import type { AddGlobalGroupActionPayload, AddGlobalGroupsAction, GlobalGroup } from '../..';
import { AM_GLOBAL_SPACE, AM_TYPE_GROUP } from '../../../../constants';
import { ADD_GLOBAL_GROUP } from '../../actionTypes';
import { addGlobalGroup } from '../../database';

export default function addGlobalGroups(
	groups: (AddGlobalGroupActionPayload | GlobalGroup)[],
	{ persist = true, user = undefined }: { persist?: boolean; user?: User } = {}
): AppThunkAction<AddGlobalGroupsAction> {
	return (dispatch, getState) => {
		const preparedGroups: GlobalGroup[] = groups.map((group) => ({
			id: UUID.create().toString(),
			items: {},
			type: AM_TYPE_GROUP,
			createdAt: new Date().toISOString(),
			lastModifiedAt: new Date().toISOString(),
			isLocked: false,
			createdBy: user ? `${user.firstName} ${user.lastName}` : '',
			...group,
		}));

		const knownGroups = Object.keys(getState().assetManager.spaces[AM_GLOBAL_SPACE]);

		dispatch({
			type: ADD_GLOBAL_GROUP,
			payload: {
				groups: preparedGroups.filter((group) => !knownGroups.includes(group.id)),
			},
		});

		if (persist) {
			preparedGroups.forEach((group) => {
				addGlobalGroup(group);
			});
		}
	};
}
