import type { AssetManagerImporterResultSetProps } from './AssetManagerImporterResults';
import ItemGroupResultSet from './ItemGroupResultSet';

export function StackingSearchResults({
	importedItems,
	importer,
}: AssetManagerImporterResultSetProps) {
	return (
		<>
			{' '}
			{importedItems
				.slice()
				.reverse()
				.map((importedItemGroup) => (
					<ItemGroupResultSet
						groupTitle={importedItemGroup.groupTitle}
						items={importedItemGroup.items}
						importer={importer}
						key={importedItemGroup.groupTitle}
					/>
				))}{' '}
		</>
	);
}
