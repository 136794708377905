import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../../../environment';
import styles from '../../../../styles';

import BoardSearchContainer from './BoardSearchContainer';
import type { BoardSearchQuery } from './__generated__/BoardSearchQuery.graphql';

type Props = {
	searchTerm: string | undefined | null;
	category: string | undefined | null;
};

export default function ({ searchTerm, category }: Props) {
	return (
		<QueryRenderer<BoardSearchQuery>
			environment={environment}
			query={graphql`
				query BoardSearchQuery(
					$count: Int
					$cursor: Cursor
					$searchTerm: String
					$category: String
				) {
					...BoardSearchContainer_query
				}
			`}
			variables={{
				count: 25,
				searchTerm: searchTerm || '',
				category: category || '',
			}}
			render={({ props, error }) => {
				if (error) {
					return (
						<div className={styles.contentWrapper}>
							<Alert message="Fehler" description={error.message} type="error" />
						</div>
					);
				}

				if (!props) {
					return (
						<div className={styles.contentWrapper}>
							<div>
								<Spin size="large" />
							</div>
						</div>
					);
				}
				return <BoardSearchContainer query={props} />;
			}}
		/>
	);
}
