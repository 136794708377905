import { Select } from 'antd';
import { type ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, graphql } from 'react-relay';
import { isPresent } from 'ts-extras';
import type { IterableElement } from 'type-fest';
import { useDebounce } from 'usehooks-ts';

import environment from '@/client/environment';

import translations from '../translations';

import type {
	NavigationArticlePickerQuery,
	NavigationArticlePickerQuery$data,
} from './__generated__/NavigationArticlePickerQuery.graphql';

const OptionContent = ({
	data: { title, firstPublicationDate },
}: {
	data: NonNullable<
		IterableElement<NonNullable<NavigationArticlePickerQuery$data['articles']>['edges']>['node']
	>;
}) => {
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '1fr auto',
				width: '100%',
				gap: '1rem',
			}}
		>
			<span
				style={{
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
				}}
			>
				{title}
			</span>
			{firstPublicationDate && (
				<span style={{ opacity: 0.3 }}>{new Date(firstPublicationDate).toLocaleDateString()}</span>
			)}
		</div>
	);
};

export const NavigationArticlePicker = ({
	initialValues,
	onSelect,
}: {
	initialValues?: {
		id: string;
		title?: string;
	};
	onSelect: ({ id, title }: { id: string; title: string }) => void;
}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce<string>(searchTerm, 300);

	const [articles, setArticles] = useState<NavigationArticlePickerQuery$data['articles']>(null);
	const [loading, setLoading] = useState(false);

	/**
	 * we use a `useEffect` hook here because using relay hooks
	 * would result in a suspnded component and conflict with the state
	 * of the Select input
	 */
	useEffect(() => {
		fetchQuery<NavigationArticlePickerQuery>(
			environment,
			graphql`
				query NavigationArticlePickerQuery($searchTerm: String!) {
					articles: filterArticles(
						searchPlain: $searchTerm
						first: 100
						statusFilter: PUBLISHED
						sortBy: FIRST_PUBLICATION_DATE_DESC
					) {
						edges {
							node {
								title
								rowId
								firstPublicationDate
							}
						}
					}
				}
			`,
			{ searchTerm: debouncedSearchTerm }
		).subscribe({
			start: () => {
				setLoading(true);
			},
			next: (data) => {
				setLoading(false);
				setArticles(data.articles);
			},
		});
	}, [debouncedSearchTerm]);

	const options = useMemo(
		() => articles?.edges.map(({ node }) => node).filter(isPresent) ?? [],
		[articles]
	);

	const handleSelect = useCallback<
		Required<ComponentProps<typeof Select<string, { value: string; label: string }>>>['onSelect']
	>(
		(_: string, { value, label }: { value: string; label: string }) => {
			onSelect({ id: value, title: label });
		},
		[onSelect]
	);

	/** ----------------------------------------------------------------------------------------------
	 * we use this to avoid rendering a row id when switching tabs in the options label field
	 * _______________________________________________________________________________________________ */
	const hasInitialValidValue = useMemo(
		() => initialValues && Boolean(initialValues?.title),
		[initialValues]
	);

	return (
		<>
			{/* <Form.Item
				initialValue={
					hasInitialValidValue && { id: initialValues?.id, title: initialValues?.title }
				}
			> */}
			<Select<string, { value: string; label: string }>
				defaultValue={hasInitialValidValue ? String(initialValues?.id) : undefined}
				labelInValue
				loading={loading}
				onSearch={setSearchTerm}
				onClear={() => setSearchTerm('')}
				onSelect={handleSelect}
				optionFilterProp="label"
				optionLabelProp="label"
				autoClearSearchValue
				placeholder={translations.NM_ARTICLE_SEARCH_PLACEHOLDER}
				showSearch
				allowClear
			>
				{/** ----------------------------------------------------------------------------------------------
				 * this is the initial option if provided
				 * _______________________________________________________________________________________________ */}
				{hasInitialValidValue && (
					<Select.Option
						value={initialValues?.id}
						label={initialValues?.title}
						key={initialValues?.id}
					>
						<OptionContent
							data={{
								firstPublicationDate: null,
								rowId: String(initialValues?.id),
								title: initialValues?.title ?? '',
							}}
						/>
					</Select.Option>
				)}

				{options.map((data) => (
					<Select.Option label={data.title} value={data.rowId} key={data.rowId}>
						<OptionContent
							data={{
								firstPublicationDate: data.firstPublicationDate,
								rowId: data.rowId,
								title: data.title,
							}}
							key={data.rowId}
						/>
					</Select.Option>
				))}
			</Select>
			{/* </Form.Item> */}
		</>
	);
};
