import { useCallback } from 'react';
import { useMutation } from 'react-relay';
import type { PayloadError } from 'relay-runtime';

import { NM_DEFAULT_BUFFER_COLOR } from '@/client/constants';

import {
	navigationCreateMutation,
	navigationDeleteMutation,
	navigationUpdateMutation,
} from './Navigation.graphql';
import type {
	NavigationCreateMutation,
	NavigationCreateMutation$data,
} from './__generated__/NavigationCreateMutation.graphql';
import type {
	NavigationDeleteMutation,
	NavigationDeleteMutation$data,
} from './__generated__/NavigationDeleteMutation.graphql';
import type {
	NavigationUpdateMutation,
	NavigationUpdateMutation$data,
} from './__generated__/NavigationUpdateMutation.graphql';
import type { NavigationItemPayload, TreeNavigationItem } from './types';
import type { getNavigationDiff } from './utils/getNavigationDiff';

type UseMutateNavigation = (
	diff: ReturnType<typeof getNavigationDiff>,
	treeData: TreeNavigationItem[]
) => Promise<void>;

function prepareNavigationData(data: NavigationItemPayload) {
	const navigation: Pick<
		// create payload works for update as well but enforces `type` property
		NavigationCreateMutation['variables']['input']['navigation'],
		'color' | 'order' | 'parent' | 'type' | 'text' | 'article' | 'board' | 'path'
	> = {
		color: data.color ?? NM_DEFAULT_BUFFER_COLOR,
		order: data.order,
		parent: data.parent,
		type: data.type,
		text: Boolean(data.text) ? data.text : null,
	};

	switch (data.type) {
		case 'ARTICLE':
			navigation.article = data.articleByArticle?.rowId;
			break;
		case 'BOARD':
			navigation.board = data.boardByBoard?.rowId;
			break;
		case 'CUSTOM':
			navigation.path = data.path;
			break;
	}

	return navigation;
}

export function useMutateNavigation(): UseMutateNavigation {
	const [commitDelete] = useMutation<NavigationDeleteMutation>(navigationDeleteMutation);

	const [commitCreate] = useMutation<NavigationCreateMutation>(navigationCreateMutation);

	const [commitUpdate] = useMutation<NavigationUpdateMutation>(navigationUpdateMutation);

	const execute = useCallback(
		(diff: ReturnType<typeof getNavigationDiff>, treeData: TreeNavigationItem[]) => {
			return new Promise<void>((resolve, reject) => {
				let operationCount = diff.items.length;

				const errors: (Error | PayloadError[])[] = [];

				function onError(e: Error) {
					operationCount -= 1;
					errors.push(e);
					reject(e);
				}

				function onCompleted(_result: unknown, operationErrors: PayloadError[] | null) {
					operationCount -= 1;
					if (operationErrors && operationErrors.length > 0) {
						errors.push(operationErrors);
						reject(operationErrors);
					}

					if (operationCount === 0) {
						resolve();
					}
				}

				const itemsToDelete = diff.items.filter((item) => item.type === 'delete');
				// sort items in correct order so deletions do not cause errors
				itemsToDelete.sort((a, b) => {
					if (a.item.data.rowId === b.item.data.parent) {
						// parent items will be sorted after child items to
						return 1;
					} else {
						return Math.abs(a.item.data.rowId) - Math.abs(b.item.data.rowId);
					}
				});

				// The postgraphile-plugin-nested-mutations plugin only supports self referencing tables from child to parent
				// https://github.com/mlipscombe/postgraphile-plugin-nested-mutations/issues/33
				// so we do a workaround here by
				// 1. Sorting the items in the correct order of insertion
				// 2. Replacing the parent id pointers of the children with the newly created items

				const addedOrUpdatedItems = breadthFirstOrder(
					diff.items.filter((item) => item.type !== 'delete'),
					treeData
				);

				// executes all mutation operation in sequential order
				// parallel mutations will not work because navigation items might depend on each other
				function performOperation(items: ReturnType<typeof getNavigationDiff>['items']) {
					if (errors.length === 0) {
						const item = items.shift();

						if (item) {
							let operation:
								| typeof commitCreate
								| typeof commitUpdate
								| typeof commitDelete
								| undefined = undefined;
							let variables;

							switch (item.type) {
								case 'create':
									operation = commitCreate;
									variables = { input: { navigation: prepareNavigationData(item.item.data) } };
									break;
								case 'update':
									operation = commitUpdate;
									variables = {
										input: {
											rowId: item.item.data.rowId,
											navigationPatch: prepareNavigationData(item.item.data),
										},
									};
									break;
								case 'delete':
									operation = commitDelete;
									variables = { input: { rowId: item.item.data.rowId } };
									break;
							}

							if (!operation) {
								throw new Error('Unknown operation');
							}

							operation({
								onError,
								onCompleted(
									data:
										| NavigationUpdateMutation$data
										| NavigationCreateMutation$data
										| NavigationDeleteMutation$data,
									operationErrors: PayloadError[] | null
								) {
									onCompleted(data, operationErrors);

									if (items.length > 0) {
										if ('createNavigation' in data) {
											const newId = data.createNavigation?.navigation?.rowId;
											for (const newItem of items) {
												if (newItem.item.data.parent === item.item.data.rowId) {
													newItem.item.data.parent = newId;
												}
											}
										}
										performOperation(items);
									}
								},
								variables,
							});
						}
					}
				}

				performOperation([...itemsToDelete, ...addedOrUpdatedItems]);
			});
		},
		[commitCreate, commitDelete, commitUpdate]
	);

	return execute;
}

/**
 *  Sort items by traversing the navigation tree in breadth first order.
 */
function breadthFirstOrder(
	modifiedItems: ReturnType<typeof getNavigationDiff>['items'],
	treeData: TreeNavigationItem[]
) {
	const result: ReturnType<typeof getNavigationDiff>['items'] = [];
	let children: ReturnType<typeof getNavigationDiff>['items'] = [];
	for (const node of treeData) {
		const modifiedItem = modifiedItems.find((item) => item.item.data.rowId === node.data.rowId);
		if (modifiedItem) {
			result.push(modifiedItem);
		}
		if (node.children) {
			children = children.concat(breadthFirstOrder(modifiedItems, node.children));
		}
	}

	return [...result, ...children];
}
