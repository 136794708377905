import { CAPABILITY_FULLY_FEATURED, CAPABILITY_TEXT_ONLY } from '@sep/br24-constants';
import classNames from 'classnames';

import translations from '../../../translations';
import { SVG } from '../../SVG';

import styles from './FancyCapabilityPicker.module.scss';
import ARTICLE from './article.svg';
import PUSH from './eilmeldungen.svg';

const capabilities = [
	{
		icon: PUSH,
		capability: CAPABILITY_TEXT_ONLY,
	},
	{
		icon: ARTICLE,
		capability: CAPABILITY_FULLY_FEATURED,
	},
] as const;

type Capability = (typeof capabilities)[number]['capability'];

type FancyCapabilityPickerProps = {
	value?: Capability | null;
	className?: string;
	hasError: boolean;
	onChange: (capability: Capability) => void;
};

export default function FancyCapabilityPicker({
	value,
	onChange,
	hasError = false,
	className,
	...rest
}: FancyCapabilityPickerProps) {
	return (
		<div
			data-testid="fancycapabilitypicker"
			className={classNames(className, { [styles.error]: hasError })}
		>
			<h4 className={classNames(styles.chooseType)}>Typ wählen</h4>
			<ul {...rest} className={classNames(styles.capabilityPicker)}>
				{capabilities.map(({ icon, capability }) => (
					<li
						key={capability}
						className={classNames({ [styles.isActive]: capability === value })}
						onClick={() => onChange(capability)}
					>
						<div>
							<SVG src={icon} className={styles.icon} />
						</div>
						<h4>{translations[capability]}</h4>
						<span>{translations.capabilityExplanation[capability]}</span>
					</li>
				))}
			</ul>
		</div>
	);
}
