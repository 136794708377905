import moment from 'moment';

import type { AppThunkAction, Dispatch } from '@/client/store/redux';
import { filterNullOrUndefined } from '@/client/util/filter';

import type {
	ImportResultsAction,
	AddItemsToImporterAction,
	LivestreamSearchParameters,
	RawItemLiveStream,
} from '../../..';
import {
	AM_TYPE_LIVESTREAM,
	AM_IMPORTER_LIVESTREAMS,
	AM_DEFAULT_GROUP,
	IMPORTER_DATE_FORMAT,
} from '../../../../../constants';
import { IMPORT_LIVESTREAMS } from '../../../actionTypes';
import {
	addItemsToImporter,
	addItemGroupToImporter,
	setItemGroupPagination,
} from '../../addItemsToImporter';
import { alreadyImported, request, success, error } from '../importUtils';

import fetchLiveStreams from './fetchLiveStreams';
import searchLiveStreams from './searchLiveStreams';

// * Creates the string used both as a label on the importer page and as an ID to prevent duplicate searches
const generateItemGroupTitle = (searchParameters: LivestreamSearchParameters): string => {
	const { term, channelName, startDate, endDate } = searchParameters;

	const groupTitleTerm = term || '';
	const groupTitleChannel = channelName || '';

	let groupTitleDates;
	if (startDate === null || endDate === null) {
		groupTitleDates = '';
	}
	// Moment.js types seem to be outdated.
	else if (moment(startDate).isSame(moment(endDate), 'day')) {
		groupTitleDates = `${moment(startDate).format(IMPORTER_DATE_FORMAT)}`;
	} else {
		groupTitleDates = `${moment(startDate).format(IMPORTER_DATE_FORMAT)} / ${moment(endDate).format(
			IMPORTER_DATE_FORMAT
		)}`;
	}

	return `${groupTitleTerm} ${groupTitleChannel} ${groupTitleDates}`.trim();
};

// * Essentially fetching the default livestreams
const liveStreamsWithoutSearch = async (dispatch: Dispatch) => {
	dispatch(addItemGroupToImporter(AM_IMPORTER_LIVESTREAMS, AM_DEFAULT_GROUP));
	const res = await fetchLiveStreams();

	const livestreams: RawItemLiveStream[] = [];

	res.forEach((liveStream) => {
		const epgs = liveStream?.epg || [];
		epgs.forEach((epg) => {
			const programmeId = epg?.broadcastEvent?.publicationOf?.id;
			const broadcastId = epg?.broadcastEvent?.id;
			if (typeof programmeId !== 'string' || typeof broadcastId !== 'string') {
				return;
			}

			// Gather title, start and end date and image from the graphql data
			const title = epg?.broadcastEvent?.publicationOf?.title;
			const kicker = epg?.broadcastEvent?.publicationOf?.kicker;
			const start = epg?.broadcastEvent?.start;
			const end = epg?.broadcastEvent?.end;
			const image =
				epg?.broadcastEvent?.publicationOf?.defaultTeaserImage?.imageFiles?.edges?.[0]?.node
					?.publicLocation;

			livestreams.push({
				type: AM_TYPE_LIVESTREAM,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				livestreamId: liveStream!.id!,
				programmeId,
				broadcastId,
				title,
				kicker,
				start,
				end,
				image,
			});
		});
	});
	dispatch(addItemsToImporter(AM_IMPORTER_LIVESTREAMS, livestreams, AM_DEFAULT_GROUP));
	dispatch(success(IMPORT_LIVESTREAMS));
};

//* Searches for livestreams based on filters provided by the user
const liveStreamsWithSearch = async (
	dispatch: Dispatch,
	searchParameters: LivestreamSearchParameters,
	hasBeenImported: boolean,
	itemGroupTitle: string
) => {
	// Only create the itemGroup in the store if it isn't already there
	if (!hasBeenImported) {
		dispatch(addItemGroupToImporter(AM_IMPORTER_LIVESTREAMS, itemGroupTitle, searchParameters));
	}

	const res = await searchLiveStreams(searchParameters);

	if (!res || (res && !res.edges)) {
		throw new Error('While the query itself threw no errors, the response is null or undefined');
	}

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const data = res.edges!;

	if (!Array.isArray(res.edges) || res.edges.length <= 0) {
		dispatch(success(IMPORT_LIVESTREAMS));
		return;
	}

	// Needed if there are no results returned
	const lastCursor = res?.edges?.[res?.edges?.length - 1]?.cursor
		? res?.edges[res?.edges?.length - 1]?.cursor
		: '';

	// dispatch new action to update the hasNextPageValue
	dispatch(
		setItemGroupPagination(
			AM_IMPORTER_LIVESTREAMS,
			itemGroupTitle,
			!!res?.pageInfo?.hasNextPage,
			lastCursor
		)
	);

	const livestreams = data
		.filter(filterNullOrUndefined)
		.map((edge) => edge.node)
		.filter(filterNullOrUndefined)
		.map((programme) => {
			const programmeId = programme.id;
			const livestreamId =
				programme.broadcasts?.edges?.[0]?.node?.broadcastedOn?.edges?.[0]?.node?.id;
			// I have the impression that the first broadcast edge is the next one to take place, and does not include past broadcasts.
			// So this should be safe. But should be verified in testing.
			const broadcastId = programme.broadcasts?.edges?.[0]?.node?.id;

			if (
				typeof programmeId !== 'string' ||
				typeof livestreamId !== 'string' ||
				typeof broadcastId !== 'string'
			) {
				return;
			}
			// Gather title, start and end date and image from the graphql data
			const title = programme.title;
			const kicker = programme.kicker;
			const start = programme.broadcasts?.edges?.[0]?.node?.start;
			const end = programme.broadcasts?.edges?.[0]?.node?.end;
			const image = programme.defaultTeaserImage?.imageFiles?.edges?.[0]?.node?.publicLocation;

			return {
				type: AM_TYPE_LIVESTREAM,
				livestreamId,
				programmeId,
				broadcastId,
				title,
				kicker,
				start,
				end,
				image,
			} as const;
		})
		.filter(filterNullOrUndefined);

	dispatch(addItemsToImporter(AM_IMPORTER_LIVESTREAMS, livestreams, itemGroupTitle));
	dispatch(success(IMPORT_LIVESTREAMS));
};

const importLiveStreams =
	(
		mode: {
			mode: 'loadAvailableLivestreams' | 'searchAvailableLivestreams' | 'fetchNextGroupPage';
		},
		searchParameters?: LivestreamSearchParameters
	): AppThunkAction<ImportResultsAction | AddItemsToImporterAction> =>
	async (dispatch, getState) => {
		const { assetManager } = getState();
		if (assetManager.isImporterWorking) {
			return;
		}
		try {
			const { mode: currentMode } = mode;

			// * Esentially loads the default/fallback livestreams
			if (currentMode === 'loadAvailableLivestreams' || !searchParameters) {
				dispatch(request(IMPORT_LIVESTREAMS));
				liveStreamsWithoutSearch(dispatch);
				return;
			}
			const itemGroupTitle = generateItemGroupTitle(searchParameters);
			const hasBeenImported = alreadyImported(
				AM_IMPORTER_LIVESTREAMS,
				itemGroupTitle,
				assetManager
			);

			if (hasBeenImported) {
				// * This would mean the user simply made the same search twice
				if (currentMode !== 'fetchNextGroupPage') {
					return;
				}

				// * Otherwise, we are loading more in a search that already took place
				// Grabs the last cursor of the upcoming query to be used in pagination.
				const currentItemGroup = assetManager.imports[AM_IMPORTER_LIVESTREAMS][itemGroupTitle];
				const { lastCursor } = currentItemGroup;
				dispatch(request(IMPORT_LIVESTREAMS));
				liveStreamsWithSearch(
					dispatch,
					{ ...searchParameters, lastCursor },
					hasBeenImported,
					itemGroupTitle
				);
				return;
			}

			dispatch(request(IMPORT_LIVESTREAMS));
			await liveStreamsWithSearch(dispatch, searchParameters, hasBeenImported, itemGroupTitle);
		} catch (err: any) {
			dispatch(error(IMPORT_LIVESTREAMS, err));
		}
	};
export default importLiveStreams;
