import type { User } from '@sep/cms-auth-client';
import { normalize as normalizr, schema } from 'normalizr';
import type { Primitive } from 'type-fest';

import type { EntityMeta, EntityValidation } from '../..';
import type { ArticleEditor_article$data } from '../../__generated__/ArticleEditor_article.graphql';

type MetaInformation = EntityMeta & EntityValidation;

const attachMetaInformation = (): MetaInformation => ({
	__meta__: {
		created: false,
		updated: false,
		deleted: false,
	},
	__validation__: null,
});

type ModuleType = Writable<
	NonNullable<ArticleEditor_article$data['modules']['edges'][number]['node']>
> &
	MetaInformation;

type ModuleIdType = NonNullable<
	ArticleEditor_article$data['modules']['edges'][number]['node']
>['rowId'];

const modulesByArticleId = new schema.Object({
	edges: new schema.Array(
		new schema.Object({
			node: new schema.Entity(
				'modules',
				{},
				{
					idAttribute: 'rowId',
					processStrategy: (value) => ({
						...attachMetaInformation(),
						...value,
					}),
				}
			),
		})
	),
});

type AuthorOriginalType = Writable<
	NonNullable<ArticleEditor_article$data['articlesAuthorsByArticleId']['edges'][number]['node']>
>;

type AuthorType = MetaInformation & {
	guid: AuthorOriginalType['authorGuid'];
	order: AuthorOriginalType['order'];
} & AuthorOriginalType['authorByAuthorGuid'];

type AuthorIdType = string;

const articlesAuthorsByArticleId = new schema.Object({
	edges: new schema.Array(
		new schema.Object({
			node: new schema.Entity(
				'authors',
				{},
				{
					// prefix the authors guid with the article rowId because the author <-> article
					// relation doesn't contain an identifier for each persisted row.
					// this means, that if the buffer contains multiple articles with the same authors
					// we may get a race condition. to avoid this case, we need to prefix each author
					// guid with the article rowId to get an unique identifier.
					idAttribute: (item) => `${item.articleId}_${item.authorGuid}`,
					processStrategy: (value) => ({
						...attachMetaInformation(),
						guid: value.authorGuid,
						order: value.order,
						...value.authorByAuthorGuid,
					}),
				}
			),
		})
	),
});

type RedirectionType = Writable<
	NonNullable<ArticleEditor_article$data['redirectionsByArticleId']['edges'][number]['node']>
> &
	MetaInformation;

type RedirectionIdType = NonNullable<
	ArticleEditor_article$data['redirectionsByArticleId']['edges'][number]['node']
>['rowId'];

const redirectionsByArticleId = new schema.Object({
	edges: new schema.Array(
		new schema.Object({
			node: new schema.Entity(
				'redirections',
				{},
				{
					idAttribute: 'rowId',
					processStrategy: (value) => ({
						...attachMetaInformation(),
						...value,
					}),
				}
			),
		})
	),
});

type TagType = Writable<
	NonNullable<
		ArticleEditor_article$data['articlesTagsByArticleId']['edges'][number]['node']
	>['tagByTagId']
> &
	MetaInformation;

type TagIdType = NonNullable<
	NonNullable<
		ArticleEditor_article$data['articlesTagsByArticleId']['edges'][number]['node']
	>['tagByTagId']
>['rowId'];

const articlesTagsByArticleId = new schema.Object<TagType>({
	edges: new schema.Array(
		new schema.Object({
			node: new schema.Object({
				tagByTagId: new schema.Entity<TagType>(
					'tags',
					{},
					{
						idAttribute: 'rowId',
						processStrategy: (value) => ({
							...attachMetaInformation(),
							...value,
						}),
					}
				),
			}),
		})
	),
});

type ArticleNormalizerType = Omit<
	ArticleEditor_article$data,
	'modules' | 'articlesAuthorsByArticleId' | 'redirectionsByArticleId' | 'articlesTagsByArticleId'
> & {
	modules: { edges: { node: ModuleIdType }[] };
	articlesAuthorsByArticleId: { edges: { node: AuthorIdType }[] };
	redirectionsByArticleId: { edges: { node: RedirectionIdType }[] };
	articlesTagsByArticleId: { edges: { node: { tagByTagId: TagIdType } }[] };
} & MetaInformation;
type ArticleIdType = ArticleNormalizerType['rowId'];

const articleSchema = new schema.Entity(
	'articles',
	{
		modules: modulesByArticleId,
		articlesAuthorsByArticleId,
		redirectionsByArticleId,
		articlesTagsByArticleId,
	},
	{
		idAttribute: 'rowId',
		processStrategy: (value) => ({
			...attachMetaInformation(),
			...value,
		}),
	}
);

type Writable<T> = T extends Primitive
	? T
	: T extends Map<infer K, infer V>
	? Map<Writable<K>, Writable<V>>
	: T extends ReadonlyMap<infer K, infer V>
	? Map<Writable<K>, Writable<V>>
	: T extends WeakMap<infer K, infer V>
	? WeakMap<Writable<K>, Writable<V>>
	: T extends Set<infer U>
	? Set<Writable<U>>
	: T extends ReadonlySet<infer U>
	? Set<Writable<U>>
	: T extends WeakSet<infer U>
	? WeakSet<Writable<U>>
	: T extends Promise<infer U>
	? Promise<Writable<U>>
	: T extends {}
	? { -readonly [K in keyof T]: Writable<T[K]> }
	: T;

interface NormalizerResult {
	articles: {
		[id: ArticleIdType]: ArticleNormalizerType;
	};
	modules: {
		[id: ModuleIdType]: ModuleType;
	};
	authors: {
		[id: AuthorIdType]: AuthorType;
	};
	redirections: {
		[id: RedirectionIdType]: RedirectionType;
	};
	tags: {
		[id: TagIdType]: TagType;
	};
	user: User | null;
}

// this type reflects the transformations done by normalizr with the schema definded by us. This type is the
// base type for an article and it should stay connected to the query that retrieves an article from the database
// as the type for the query is autogenerated and reflects the current state of the graphql schema
type ArticleType = Writable<
	Omit<
		ArticleNormalizerType,
		| 'modules'
		| 'modulesByArticleId'
		| 'authors'
		| 'articlesAuthorsByArticleId'
		| 'redirections'
		| 'redirectionsByArticleId'
		| 'tags'
		| 'articlesTagsByArticleId'
	> & {
		modules: ArticleNormalizerType['modules']['edges'][number]['node'][];
		authors: ArticleNormalizerType['articlesAuthorsByArticleId']['edges'][number]['node'][];
		redirections: ArticleNormalizerType['redirectionsByArticleId']['edges'][number]['node'][];
		tags:
			| (
					| NonNullable<
							ArticleNormalizerType['articlesTagsByArticleId']['edges'][number]['node']
					  >['tagByTagId']
			  )[];
		user: User | null;
	}
>;

export type NormalizeResult = Omit<NormalizerResult, 'articles'> & {
	articles: {
		[id: ArticleIdType]: Writable<ArticleType>;
	};
};

export default function normalize(
	input: Omit<ArticleEditor_article$data, ' $fragmentSpreads' | ' $fragmentType'>
): NormalizeResult {
	const {
		entities: { articles, authors, redirections, modules, tags },
	}: { entities: NormalizerResult } = normalizr(input, articleSchema);

	// remove ugly edge-artifacts from the article
	// TODO: we should not change the structure of the input data !! keep what's coming from the server

	if (articles) {
		for (const article of Object.values(articles) as any[]) {
			if (!article) {
				continue;
			}

			article.modules = (article?.modules.edges ?? []).map(({ node }) => node);
			delete article.modulesByArticleId;

			article.authors = (article.articlesAuthorsByArticleId.edges ?? []).map(({ node }) => node);
			delete article.articlesAuthorsByArticleId;

			article.redirections = (article.redirectionsByArticleId.edges ?? []).map(({ node }) => node);
			delete article.redirectionsByArticleId;

			article.tags = (article.articlesTagsByArticleId.edges ?? []).map(
				({ node }) => node?.tagByTagId
			);
			delete article.articlesTagsByArticleId;

			// remove relay internal properties
			delete article.__fragmentOwner;
			delete article.__fragments;
			delete article.__isWithinUnmatchedTypeRefinement;
			delete article.__id;
		}
	}

	return {
		// type conversion has to be done, because article is being mutated
		articles: (articles as unknown as NormalizeResult['articles']) || {},
		authors: authors || {},
		redirections: redirections || {},
		modules: modules || {},
		tags: tags || {},
		user: null,
	};
}
