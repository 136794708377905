/**
 * @generated SignedSource<<540adda2db411e5791f2dc8513f1d5c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type District = "MITTELFRANKEN" | "NIEDERBAYERN" | "OBERBAYERN" | "OBERFRANKEN" | "OBERPFALZ" | "SCHWABEN" | "UNTERFRANKEN";
export type SourceOriginReferenceStatsContainerQuery$variables = {
  categoryFilter?: string | null;
  districtsFilter?: ReadonlyArray<District | null> | null;
  endDate?: string | null;
  startDate?: string | null;
};
export type SourceOriginReferenceStatsContainerQuery$data = {
  readonly stats: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly count: number | null;
        readonly sourceOrigin: string | null;
      } | null;
    }>;
  } | null;
};
export type SourceOriginReferenceStatsContainerQuery = {
  response: SourceOriginReferenceStatsContainerQuery$data;
  variables: SourceOriginReferenceStatsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "districtsFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = [
  {
    "alias": "stats",
    "args": [
      {
        "kind": "Variable",
        "name": "categoryFilter",
        "variableName": "categoryFilter"
      },
      {
        "kind": "Variable",
        "name": "districtsFilter",
        "variableName": "districtsFilter"
      },
      {
        "kind": "Variable",
        "name": "fromDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "toDate",
        "variableName": "endDate"
      }
    ],
    "concreteType": "ArticleStatsSourceOriginsConnection",
    "kind": "LinkedField",
    "name": "articleStatsForSourceOrigin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArticleStatsSourceOriginsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleStatsSourceOrigin",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourceOrigin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SourceOriginReferenceStatsContainerQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SourceOriginReferenceStatsContainerQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "790baf6fe7764fec2631564466b9b0de",
    "id": null,
    "metadata": {},
    "name": "SourceOriginReferenceStatsContainerQuery",
    "operationKind": "query",
    "text": "query SourceOriginReferenceStatsContainerQuery(\n  $startDate: Datetime\n  $endDate: Datetime\n  $districtsFilter: [District]\n  $categoryFilter: String\n) {\n  stats: articleStatsForSourceOrigin(fromDate: $startDate, toDate: $endDate, districtsFilter: $districtsFilter, categoryFilter: $categoryFilter) {\n    edges {\n      node {\n        sourceOrigin\n        count\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b87ce351d7e6ce331c25a0be1e80c9f";

export default node;
