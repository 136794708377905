type AttachFormItemErrorPropsOptions = {
	validateStatus?: 'warning' | 'error';
	startsWith?: boolean;
};

const defaultOptions: AttachFormItemErrorPropsOptions = {
	startsWith: false,
};

const colors = {
	warning: '#e67e22',
	error: '#c0392b',
};

function extractByKey(
	validation: {
		[key: string]: Array<string>;
	},
	key: string,
	startsWith = false
) {
	if (!startsWith) {
		return validation[key] || [];
	}

	return Object.keys(validation)
		.filter((item) => item.startsWith(key))
		.reduce<string[]>((accumulator, item) => [...validation[item], ...accumulator], []);
}

export default function attachFormItemErrorProps(
	validation:
		| {
				[key: string]: Array<string>;
		  }
		| undefined
		| null,
	key: string,
	optionsInput: AttachFormItemErrorPropsOptions = {}
): {
	[key: string]: any;
} {
	const options = { ...defaultOptions, ...optionsInput };
	const props: any = {};

	if (validation) {
		const errors = extractByKey(validation, key, options.startsWith);

		/* eslint-disable react/no-array-index-key */
		if (errors.length > 0) {
			props.validateStatus = options.validateStatus || 'error';
			props.extra = (
				<div style={{ color: colors[props.validateStatus] }}>
					{errors.map((error, index) => (
						<p key={index}>{error}</p>
					))}
				</div>
			);
		}
		/* eslint-enable react/no-array-index-key */
	}

	return props;
}
