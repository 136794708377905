import { Popconfirm } from 'antd';
import { Component } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { Drag, Trash, HidePanel, ShowPanel } from '../../Icon';

const Wrapper = styled.div<{ isHeaderModule: boolean }>`
	margin-top: ${({ isHeaderModule }) => (isHeaderModule ? '14px' : '10px')};
	width: 100%;
	display: flex;
`;

const ContentWrapper = styled.div`
	width: 100%;
`;

const Headline = styled.div`
	position: relative;
	width: 100%;
	height: 20px;
	border-bottom: 1px solid ${(props) => props.theme.colors.br24Light};
	text-align: center;
	margin-bottom: 2rem;
`;

const HeadlineLabel = styled.span`
	display: inline-block;
	background-color: #ffffff;
	padding: 8px 1rem 0 1rem;
	font-size: 1.2rem;
	text-transform: uppercase;
	cursor: move;
`;

const HeadlineHide = styled.a`
	display: inline-block;
	padding: 0 1rem 0 0;
	width: 2rem;
	position: absolute;
	left: 0;
	top: 10px;
	background-color: #ffffff;
	cursor: pointer;
	color: ${(props) => props.theme.colors.br24Light};
`;

const HeadlineDelete = styled.a`
	display: inline-block;
	padding: 0 0 0 1rem;
	position: absolute;
	right: 0;
	top: 10px;
	background-color: #ffffff;
	cursor: pointer;
	color: ${(props) => props.theme.colors.br24Light};
`;

interface Props extends WithTranslationProps {
	children: ReactNode;
	isHeaderModule?: boolean;
	onDelete?: () => void;
	label: string;
}

type State = {
	isCollapsed: boolean;
};

class ArticleModuleItem extends Component<Props, State> {
	state = { isCollapsed: false };

	handleClickHide = () => {
		const { isCollapsed } = this.state;
		this.setState({ isCollapsed: !isCollapsed });
	};

	render() {
		const {
			translation: { translate },
			isHeaderModule = false,
			onDelete,
			label,
			children,
		} = this.props;

		const { isCollapsed } = this.state;

		return (
			<Wrapper isHeaderModule={isHeaderModule}>
				{isHeaderModule ? (
					<ContentWrapper>{children}</ContentWrapper>
				) : (
					<ContentWrapper>
						<Headline>
							<HeadlineHide onClick={this.handleClickHide}>
								{isCollapsed ? <ShowPanel /> : <HidePanel />}
							</HeadlineHide>
							<HeadlineLabel>
								<Drag /> {label}
							</HeadlineLabel>
							<HeadlineDelete>
								<Popconfirm
									title={translate('modules.remove')}
									onConfirm={onDelete}
									okText={translate('yes')}
									cancelText={translate('no')}
								>
									<Trash />
								</Popconfirm>
							</HeadlineDelete>
						</Headline>
						{isCollapsed ? null : children}
					</ContentWrapper>
				)}
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleModuleItem);
