import type {
	ActionTypeAuthorAdd,
	ActionTypeAuthorRemove,
	ActionTypeAuthorUpdate,
	ActionTypeInitialize,
	ActionTypeExit,
	ActionTypeModuleCreate,
	ActionTypeModuleDelete,
	ActionTypeModuleUpdate,
	ActionTypeRedirectionCreate,
	ActionTypeRedirectionDelete,
	ActionTypeArticleCreateFromTemplate,
	ActionTypeSave,
	ActionTypeTagAdd,
	ActionTypeTagRemove,
	ActionTypeUpdate,
	ActionTypeValidate,
	StatusError,
	StatusRequest,
	StatusSuccess,
} from '.';

export const AUTHOR_ADD: ActionTypeAuthorAdd = 'ARTICLE_EDITOR_AUTHOR_ADD';
export const AUTHOR_REMOVE: ActionTypeAuthorRemove = 'ARTICLE_EDITOR_AUTHOR_REMOVE';
export const AUTHOR_UPDATE: ActionTypeAuthorUpdate = 'ARTICLE_EDITOR_AUTHOR_UPDATE';
export const INITIALIZE: ActionTypeInitialize = 'ARTICLE_EDITOR_INITIALIZE';
export const EXIT: ActionTypeExit = 'ARTICLE_EDITOR_EXIT';
export const MODULE_CREATE: ActionTypeModuleCreate = 'ARTICLE_EDITOR_MODULE_CREATE';
export const MODULE_DELETE: ActionTypeModuleDelete = 'ARTICLE_EDITOR_MODULE_DELETE';

export const MODULE_UPDATE: ActionTypeModuleUpdate = 'ARTICLE_EDITOR_MODULE_UPDATE';
export const REDIRECTION_CREATE: ActionTypeRedirectionCreate = 'ARTICLE_EDITOR_REDIRECTION_CREATE';
export const REDIRECTION_DELETE: ActionTypeRedirectionDelete = 'ARTICLE_EDITOR_REDIRECTION_DELETE';
export const ARTICLE_CREATE_FROM_TEMPLATE: ActionTypeArticleCreateFromTemplate =
	'ARTICLE_CREATE_FROM_TEMPLATE';
export const SAVE: ActionTypeSave = 'ARTICLE_EDITOR_SAVE';
export const TAG_ADD: ActionTypeTagAdd = 'ARTICLE_EDITOR_TAG_ADD';
export const TAG_REMOVE: ActionTypeTagRemove = 'ARTICLE_EDITOR_TAG_REMOVE';
export const UPDATE: ActionTypeUpdate = 'ARTICLE_EDITOR_UPDATE';
export const VALIDATE: ActionTypeValidate = 'ARTICLE_EDITOR_VALIDATE';

// used for SAVE and ARTICLE_CREATE_FROM_TEMPLATE
export const STATUS_REQUEST: StatusRequest = 'STATUS_REQUEST';
export const STATUS_SUCCESS: StatusSuccess = 'STATUS_SUCCESS';
export const STATUS_ERROR: StatusError = 'STATUS_ERROR';
