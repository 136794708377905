import { STATUS_PUBLISHED } from '@sep/br24-constants';
import moment from 'moment';

import type { AppThunkAction, Dispatch } from '@/client/store/redux';

import type {
	AddItemsToImporterAction,
	ImportResultsErrorAction,
	ImportResultsRequestAction,
	NewsroomSearchParameters,
	RawItemArticle,
	RawItemBoard,
} from '../../..';
import {
	AM_IMPORTER_NEWSROOM,
	AM_TYPE_ARTICLE,
	IMPORTER_DATE_FORMAT,
} from '../../../../../constants';
import { translate } from '../../../../../translation';
import { AM_IMPORT_NEWSROOM } from '../../../actionTypes';
import {
	addItemsToImporter,
	addItemGroupToImporter,
	setItemGroupPagination,
} from '../../addItemsToImporter';
import { alreadyImported, request, success, error } from '../importUtils';

import searchNewsroom from './searchNewsroom';

// * Creates the string used both as a label on the importer page and as an ID to prevent duplicate searches
const generateItemGroupTitle = (searchParameters: NewsroomSearchParameters): string => {
	const { term, startDate, endDate, categoryFilterTitle, typeFilter } = searchParameters;

	const showDate = typeFilter === AM_TYPE_ARTICLE;

	const type = typeFilter ? translate(`assetManager.type.${typeFilter}`) : '';
	const category = categoryFilterTitle || '';

	let groupTitleDates: string;
	if (startDate === null || endDate === null) {
		groupTitleDates = '';
	} else if (moment(startDate).isSame(moment(endDate), 'day')) {
		groupTitleDates = `${moment(startDate).format(IMPORTER_DATE_FORMAT)}`;
	} else {
		groupTitleDates = `${moment(startDate).format(IMPORTER_DATE_FORMAT)} / ${moment(endDate).format(
			IMPORTER_DATE_FORMAT
		)}`;
	}

	return `${type}: ${term} ${category && '-'} ${category} ${
		showDate ? `- ${groupTitleDates}` : ''
	}`.trim();
};

const newsroomWithSearch = async (
	dispatch: Dispatch,
	searchParameters: NewsroomSearchParameters,
	hasBeenImported: boolean,
	itemGroupTitle: string
) => {
	// Only create the itemGroup in the store if it isn't already there
	if (!hasBeenImported) {
		dispatch(addItemGroupToImporter(AM_IMPORTER_NEWSROOM, itemGroupTitle, searchParameters));
	}
	const res = await searchNewsroom(searchParameters);

	if (!res || (res && !res.edges)) {
		throw new Error('While the query itself threw no errors, the response is null or undefined');
	}

	const data = res.edges || [];

	if (!Array.isArray(res.edges) || res.edges.length <= 0) {
		dispatch(success(AM_IMPORT_NEWSROOM));
		return;
	}

	// Needed if there are no results returned
	const lastCursor = res?.edges[res?.edges?.length - 1].cursor
		? res.edges[res.edges.length - 1].cursor
		: '';

	// dispatch new action to update the hasNextPageValue
	dispatch(
		setItemGroupPagination(
			AM_IMPORTER_NEWSROOM,
			itemGroupTitle,
			res.pageInfo.hasNextPage,
			lastCursor
		)
	);

	const newItems = data
		.map((edge: (typeof data)[number]) => edge.node)
		.filter((node): node is NonNullable<(typeof data)[number]['node']> => !!node?.rowId)
		.map((item) => {
			const id = item.id;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const rowId = item.rowId!;

			const publicationDate = item?.createdAt;
			const title = item?.title;
			const status = item?.status;
			const image = item?.image?.url;

			const updatedAt =
				searchParameters.typeFilter === AM_TYPE_ARTICLE
					? item['lastEdited'] || null
					: item['updatedAt'] || null;

			const isPublished = item.status === STATUS_PUBLISHED;

			const newItem = {
				type: searchParameters.typeFilter,
				id,
				title,
				image,
				publicationDate,
				status: status,
				updatedAt,
				isPublished,
			} as Omit<RawItemBoard | RawItemArticle, 'articleId' | 'boardId'>;

			newItem[searchParameters.typeFilter === AM_TYPE_ARTICLE ? 'articleId' : 'boardId'] = rowId;
			return newItem as RawItemBoard | RawItemArticle;
		});
	dispatch(addItemsToImporter(AM_IMPORTER_NEWSROOM, newItems, itemGroupTitle));

	dispatch(success(AM_IMPORT_NEWSROOM));
};

const importNewsroom =
	(
		mode: {
			mode: 'loadAvailableLivestreams' | 'searchAvailableLivestreams' | 'fetchNextGroupPage' | null;
		},
		searchParameters: NewsroomSearchParameters
	): AppThunkAction<
		| ImportResultsRequestAction<typeof AM_IMPORT_NEWSROOM>
		| ImportResultsErrorAction<typeof AM_IMPORT_NEWSROOM>
		| AddItemsToImporterAction
	> =>
	async (dispatch, getState) => {
		const { assetManager } = getState();
		if (assetManager.isImporterWorking) {
			return;
		}
		try {
			const { mode: currentMode } = mode;
			const itemGroupTitle = generateItemGroupTitle(searchParameters);
			const hasBeenImported = alreadyImported(AM_IMPORTER_NEWSROOM, itemGroupTitle, assetManager);

			if (hasBeenImported) {
				// * This would mean the user simply made the same search twice
				if (currentMode !== 'fetchNextGroupPage') {
					return;
				}

				// * Otherwise, we are loading more in a search that already took place
				// Grabs the last cursor of the upcoming query to be used in pagination. Tried giving it the right type but Flow is ignoring it anyways.
				const currentItemGroup = assetManager.imports[AM_IMPORTER_NEWSROOM][itemGroupTitle];
				const { lastCursor } = currentItemGroup;

				dispatch(request(AM_IMPORT_NEWSROOM));

				newsroomWithSearch(
					dispatch,
					{ ...searchParameters, lastCursor },
					hasBeenImported,
					itemGroupTitle
				);
				return;
			}
			dispatch(request(AM_IMPORT_NEWSROOM));
			await newsroomWithSearch(dispatch, searchParameters, hasBeenImported, itemGroupTitle);
		} catch (err: any) {
			dispatch(error(AM_IMPORT_NEWSROOM, err));
		}
	};
export default importNewsroom;
