import { useState } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import type { Image, Validation } from '../../../containers/ArticleEditor';
import ErrorMessageText from '../../ErrorMessageText';

import ArticleModuleGalleryImage from './ArticleModuleGalleryImage';
import { ArticleModuleGallerySlider } from './ArticleModuleGallerySlider';

const ImageListWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 30px;
`;

const ThumbnailUploaderWrapper = styled.div`
	width: 200px;
	margin: 12px;
	height: 100px;
`;

type Props = {
	images: Array<Image>;
	thumbnailDropzone: ReactNode;
	validation?: Validation | null;
	isEditing: boolean;
	isThumbnailUploading: boolean;
	onMoveImage: (dragIndex: number, hoverIndex: number) => void;
	onChange: (key: string, value: string, index: number) => void;
	onThumbnailDelete: (index: number) => void;
	onModuleIsEditing: (nextIsEditing: boolean) => void;
};

export function ArticleModuleGallery(props: Props) {
	const [activeElement, setActiveElement] = useState(0);
	const [previewLoaded, setPreviewLoaded] = useState(false);

	const handleChange = (key: string, value: string, index: number) => {
		props.onChange(key, value, index);
	};

	const handleImageLoad = () => {
		setPreviewLoaded(true);
	};

	const handleNextImage = (index: number) => {
		setActiveElement(index);
	};

	const handleThumbnailClick = (index: number) => {
		setActiveElement(index);
	};

	const handleThumbnailDelete = (index: number) => {
		let nextActiveElement = 0;

		if (index > activeElement) {
			nextActiveElement = activeElement;
		}

		if (index < activeElement) {
			nextActiveElement = activeElement - 1;
		}

		if (index === activeElement && activeElement > 0) {
			nextActiveElement = activeElement - 1;
		}

		setActiveElement(nextActiveElement);

		props.onThumbnailDelete(index);
	};

	const { images, thumbnailDropzone, validation, onChange, onMoveImage, ...rest } = props;

	return (
		<div>
			{Array.isArray(images) && images.length > 0 ? (
				<div>
					{validation && validation.gallery ? <ErrorMessageText text={validation.gallery} /> : null}
					<ImageListWrapper>
						<ThumbnailUploaderWrapper>{thumbnailDropzone}</ThumbnailUploaderWrapper>
						{images.map((image, index) => (
							<ArticleModuleGalleryImage
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								index={index}
								image={image}
								activeElement={activeElement}
								previewLoaded={previewLoaded}
								handleImageLoad={handleImageLoad}
								handleThumbnailClick={handleThumbnailClick}
								handleThumbnailDelete={handleThumbnailDelete}
								handleMoveImage={onMoveImage}
							/>
						))}
					</ImageListWrapper>
					<ArticleModuleGallerySlider
						onNextImage={handleNextImage}
						onChange={handleChange}
						activeElement={activeElement}
						images={images}
						validation={validation}
						{...rest}
					/>
				</div>
			) : null}
		</div>
	);
}
