import originalJoi from 'joi-browser';

import type { JoiHack } from '@/types/hacks';

import { schema as linkSchema } from '../../../validators/link';

const joi = originalJoi.extend({
	base: originalJoi.string(),
	name: 'string',
	language: {
		json: 'invalid json',
	},
	rules: [
		{
			name: 'json',
			validate(_params, value, state, options) {
				try {
					JSON.parse(value);
					return value;
				} catch (err) {
					return this.createError?.('string.json', { value }, state, options);
				}
			},
		} as JoiHack,
	],
});

const schema = joi.object().keys({
	title: joi.string().min(3).max(64).required(),
	description: joi.string().min(3).max(250).required(),
	image: joi
		.object()
		.allow(null, '')
		.optional()
		.keys({
			url: joi
				.string()
				.regex(
					/^(https:\/\/)(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
				),
			title: joi.string().max(250).allow(null, '').optional().default(null),
			copyright: joi.string().max(250).allow(null, '').optional().default(null),
		}),
	link: joi
		.object()
		.required()
		.keys({
			label: joi.reach(linkSchema, 'label'),
			url: joi.reach(linkSchema, 'url'),
		}),
});

const defaultOptions = {
	abortEarly: false,
	allowUnknown: true,
};

export function validator(data: any, options: any = defaultOptions) {
	return joi.validate(data, schema, options);
}

type ValidationState = {
	value: any;
	error: {
		toString: () => string;
		details: Array<{
			context: {
				key: string;
			};
			message: string;
			path: Array<string>;
			type: string;
		}>;
	};
};

export function getHumanReadableErrors(validationState: ValidationState): string {
	let error = validationState.error.toString();

	validationState.error.details.some(({ path: path_ary, type }) => {
		const path = path_ary.join('.'); // temorary workaround for more readable error messages

		if (
			path === 'title' &&
			(type === 'any.empty' || type === 'string.min' || type === 'string.base')
		) {
			error = 'Der Titel muss aus min. 3, max. jedoch aus 64 Zeichen bestehen.';
			return true;
		}

		if (
			path === 'description' &&
			(type === 'any.empty' || type === 'string.min' || type === 'string.base')
		) {
			error = 'Die Beschreibung muss aus min. 3, max. jedoch aus 250 Zeichen bestehen.';
			return true;
		}

		if (path === 'link.url' && type === 'string.regex.base') {
			error = 'Bitte eine gültige URL inkl. http:// oder https:// eingeben';
			return true;
		}

		if (
			path === 'link.label' &&
			(type === 'any.empty' || type === 'string.min' || type === 'string.base')
		) {
			error = 'Der Linktext muss aus min. 3, max. jedoch aus 250 Zeichen bestehen.';
			return true;
		}

		return false;
	});

	return error;
}
