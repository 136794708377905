/**
 * @generated SignedSource<<86a066daa9505b4d8da983200bc0d240>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SocialMediaAccountService = "EMAIL" | "FACEBOOK_PAGE" | "INSTAGRAM" | "SPOTIFY" | "TWITTER" | "YOUTUBE";
import { FragmentRefs } from "relay-runtime";
export type Author_author$data = {
  readonly description: string | null;
  readonly firstname: string | null;
  readonly guid: string;
  readonly isActive: boolean | null;
  readonly jobTitle: string | null;
  readonly lastname: string | null;
  readonly profilePicture: string | null;
  readonly slug: string | null;
  readonly socialMediaAccounts: ReadonlyArray<{
    readonly accountName: string | null;
    readonly label: string | null;
    readonly service: SocialMediaAccountService | null;
  } | null> | null;
  readonly " $fragmentType": "Author_author";
};
export type Author_author$key = {
  readonly " $data"?: Author_author$data;
  readonly " $fragmentSpreads": FragmentRefs<"Author_author">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Author_author",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "guid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePicture",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialMediaAccount",
      "kind": "LinkedField",
      "name": "socialMediaAccounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "service",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Author",
  "abstractKey": null
};

(node as any).hash = "340fc7c94fbaaf7b4077956c4915350a";

export default node;
