import { EXPIRATION_ALL } from '@sep/br24-constants';
import {
	Form,
	Radio,
	DatePicker,
	Select,
	notification,
	type RadioChangeEvent,
	type FormProps,
} from 'antd';
import moment, { type Moment } from 'moment';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';
import type { Expiration } from '@/types/schema';

import type { WithTranslationProps } from '../../../translation';

const PUBLICATION_TYPES = {
	NOW: 'NOW',
	LATER: 'LATER',
};

const FormComponent = styled(Form)`
	width: 380px;
	background-color: ${(props) => props.theme.colors.white};
	border: 1px solid rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 5px 15px;
`;

export type OnChangeAttribute =
	| 'publicationDate'
	| 'depublicationId'
	| 'depublicationDate'
	| 'depublicationInterval';

type DepublicationId = Expiration | 'custom';

interface Props extends WithTranslationProps {
	defaultDepublicationDate: string | undefined | null;
	defaultDepublicationId: Expiration | undefined | null;
	defaultPublicationDate: string | undefined | null;
	isDisabled: boolean;
	isDisabledPublication: boolean;
	onChange?: (x: OnChangeAttribute, y: any) => void;
}

type State = {
	depublicationDate: Moment | undefined | null;
	depublicationId: DepublicationId;
	isDepublicationPickerDisabled: boolean;
	isDepublicationRequired: boolean;
	publicationDate: Moment | undefined | null;
	publicationType: string;
	fields: FormProps['fields'];
};

class ArticleStatusTimeControlForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		let depublicationId: DepublicationId = 'custom';
		let isDepublicationRequired = false;
		let isDepublicationPickerDisabled = false;
		let depublicationDate: Moment | null = null;
		if (props.defaultDepublicationId) {
			isDepublicationPickerDisabled = true;
			depublicationId = props.defaultDepublicationId;
			const expirationItem = EXPIRATION_ALL.find(
				(element) => element.id === props.defaultDepublicationId
			);
			if (expirationItem && expirationItem.duration !== Infinity) {
				const tmpPublicationDate = props.defaultPublicationDate || moment();
				depublicationDate = moment(tmpPublicationDate).add(
					expirationItem.duration,
					expirationItem.units
				);
			} else if (expirationItem && !expirationItem.required) {
				isDepublicationRequired = false;
				depublicationDate = null;
			}
		} else if (props.defaultDepublicationDate) {
			depublicationDate = moment(this.props.defaultDepublicationDate);
		}

		this.state = {
			depublicationDate,
			depublicationId,
			isDepublicationRequired,
			isDepublicationPickerDisabled,
			publicationDate: props.defaultPublicationDate
				? moment(props.defaultPublicationDate)
				: moment(),
			publicationType: props.defaultPublicationDate
				? PUBLICATION_TYPES.LATER
				: PUBLICATION_TYPES.NOW,
			fields: [],
		};
	}

	static getDerivedStateFromProps(_props: Props, state: State): Partial<State> {
		return {
			fields: [
				{ name: ['publicationType'], value: state.publicationType },
				{ name: ['publicationDate'], value: state.publicationDate },
				{ name: ['depublicationId'], value: state.depublicationId },
				{ name: ['depublicationDate'], value: state.depublicationDate },
			],
		};
	}

	handleDepublicationDateOnChange = (depublicationDate: Moment | null) => {
		const {
			translation: { translate },
		} = this.props;
		if (this.state.isDepublicationRequired && depublicationDate === null) {
			notification.error({
				message: translate('article.depublishing.error'),
				description: translate('article.depublishing.error.empty'),
			});
			return;
		}
		this.setState({ depublicationDate });
		this.notifyChange(
			'depublicationDate',
			depublicationDate ? depublicationDate.toISOString() : null
		);
	};

	handleDepublicationSelectOnChange = (value: DepublicationId) => {
		let depublicationDate = this.props.defaultDepublicationDate
			? moment(this.props.defaultDepublicationDate)
			: null;

		if (value === 'custom') {
			this.setState({
				depublicationId: value,
				isDepublicationRequired: false,
				isDepublicationPickerDisabled: false,
				depublicationDate,
			});
			this.notifyChange('depublicationId', null);
			this.notifyChange(
				'depublicationDate',
				depublicationDate ? depublicationDate.toISOString() : null
			);
			this.notifyChange('depublicationInterval', null);
			return;
		}

		const expirationItem = EXPIRATION_ALL.find((element) => element.id === value);
		let depublicationInterval: Record<string, number> | null = null;
		let isDepublicationPickerDisabled = false;
		let isDepublicationRequired = true;

		if (expirationItem && expirationItem.duration !== Infinity) {
			isDepublicationPickerDisabled = true;
			const tmpPublicationDate = this.state.publicationDate || moment();
			depublicationDate = moment(tmpPublicationDate).add(
				expirationItem.duration,
				expirationItem.units as any
			);

			depublicationInterval = { [expirationItem.units]: expirationItem.duration };
		} else if (expirationItem && !expirationItem.required) {
			isDepublicationRequired = false;
			depublicationDate = null;
		}
		this.setState({
			depublicationId: value,
			isDepublicationRequired,
			isDepublicationPickerDisabled,
			depublicationDate,
		});
		this.notifyChange('depublicationId', value);
		this.notifyChange(
			'depublicationDate',
			depublicationDate && !depublicationInterval ? depublicationDate.toISOString() : null
		);
		this.notifyChange('depublicationInterval', depublicationInterval);
	};

	handlePublicationDateOnChange = (publicationDate: Moment | null) => {
		const {
			translation: { translate },
		} = this.props;
		if (publicationDate === null) {
			notification.error({
				message: translate('article.publishing.error'),
				description: translate('article.publishing.error.empty'),
			});
			return;
		}
		this.setState({ publicationDate });
		this.notifyChange('publicationDate', publicationDate ? publicationDate.toISOString() : null);
	};

	handlePublicationTypeOnChange = (event: RadioChangeEvent) => {
		let publicationDate: Moment | null = null;

		if (event.target.value === PUBLICATION_TYPES.LATER) {
			publicationDate = this.props.defaultPublicationDate
				? moment(this.props.defaultPublicationDate)
				: moment();
		}
		this.setState({
			publicationType: event.target.value,
			publicationDate,
		});
		this.notifyChange('publicationDate', publicationDate ? publicationDate.toISOString() : null);
	};

	private notifyChange = (attr: OnChangeAttribute, value: any) => {
		if (this.props.onChange) {
			this.props.onChange(attr, value);
		}
	};

	render() {
		const {
			isDisabled,
			isDisabledPublication,
			translation: { translate },
		} = this.props;

		return (
			<FormComponent fields={this.state.fields} labelCol={{ span: 8 }} labelAlign="left">
				<Form.Item name="publicationType" label={translate('article.publishing.publishing')}>
					<Radio.Group
						disabled={isDisabled || isDisabledPublication}
						onChange={this.handlePublicationTypeOnChange}
					>
						<Radio value={PUBLICATION_TYPES.NOW}>{translate('article.publishing.now')}</Radio>
						<Radio value={PUBLICATION_TYPES.LATER}>
							{translate('article.publishing.delay.activate')}
						</Radio>
					</Radio.Group>
				</Form.Item>
				{this.state.publicationType === PUBLICATION_TYPES.LATER ? (
					<Form.Item name="publicationDate" label="&nbsp;" colon={false}>
						<DatePicker
							allowClear={false}
							disabled={isDisabled || isDisabledPublication}
							disabledDate={(currentDate) => moment().isAfter(currentDate, 'day')}
							format="DD.MM.YYYY HH:mm"
							showTime={true}
							onChange={this.handlePublicationDateOnChange}
							onSelect={this.handlePublicationDateOnChange}
						/>
					</Form.Item>
				) : null}
				<Form.Item name="depublicationId" label={translate('article.depublishing.depublishing')}>
					<Select disabled={isDisabled} onChange={this.handleDepublicationSelectOnChange}>
						{EXPIRATION_ALL.map((item) => (
							<Select.Option key={item.id} value={item.id}>
								{translate(`article.depublishing.category.${item.id}`)}
							</Select.Option>
						))}
						<Select.Option key="custom" value="custom">
							{translate('article.depublishing.category.custom')}
						</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item name="depublicationDate" label="&nbsp;" colon={false}>
					<DatePicker
						allowClear={!this.state.isDepublicationRequired}
						disabled={isDisabled || this.state.isDepublicationPickerDisabled}
						disabledDate={(currentDate) => moment().isAfter(currentDate, 'day')}
						format="DD.MM.YYYY HH:mm"
						showTime={true}
						onChange={this.handleDepublicationDateOnChange}
						onSelect={this.handleDepublicationDateOnChange}
					/>
				</Form.Item>
			</FormComponent>
		);
	}
}

export default withTranslation(ArticleStatusTimeControlForm);
