import type { Article } from '../..';
import { AE_LINK, AE_ARTICLE, AE_BOARD } from '../../itemTypes';
import { DraggableListItemContainer } from '../DraggableListItem';

import ContainerTeaserItem from './ContainerTeaserItem/ContainerTeaserItem';

export type Link = NonNullable<NonNullable<Article['links']>[number]>;

type ItemProps = {
	index: number;
	item: Link;
	invalidLinks: Pick<Link, 'label' | 'url'>[];
	onRemove: (index: number) => void;
	updateLabelFromMeta: (key: number, label: string) => void;
	move: (dragIndex: number, hoverIndex: number) => void;
};

const acceptedItemTypes: AcceptedItemTypes[] = [AE_ARTICLE, AE_BOARD, AE_LINK];
type AcceptedItemTypes = typeof AE_ARTICLE | typeof AE_BOARD | typeof AE_LINK;

function LinkItem({ index, item, invalidLinks, updateLabelFromMeta, onRemove, move }: ItemProps) {
	const isInvalid =
		Array.isArray(invalidLinks) &&
		invalidLinks.length > 0 &&
		invalidLinks.some((invalidLink) => invalidLink.url === item.url);
	const isDepublished = !!(
		(item.article && item.article.status.toUpperCase() !== 'PUBLISHED') ||
		(item.board && item.board.status?.toUpperCase() !== 'PUBLISHED')
	);

	// eslint-disable-next-line no-nested-ternary
	const type = item.article ? AE_ARTICLE : item.board ? AE_BOARD : AE_LINK;
	const isNewItem = 'newItem' in item;
	return (
		<DraggableListItemContainer<AcceptedItemTypes>
			key={item.url}
			type={type}
			acceptedItemTypes={acceptedItemTypes}
			index={index}
			id={item.board?.rowId}
			url={item.url}
			articleId={item.article?.rowId}
			onRemove={() => onRemove(index)}
			onMove={move}
			isDeletable={false}
			isDraggable={true}
			isInvalidOrDepublished={isInvalid || isDepublished}
		>
			<ContainerTeaserItem
				index={index}
				onRemove={() => onRemove(index)}
				meta={item.meta}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				url={item.url!}
				onDataFetch={updateLabelFromMeta}
				isInvalid={isInvalid}
				isDepublished={isDepublished}
				isNewItem={isNewItem}
			/>
		</DraggableListItemContainer>
	);
}

export default LinkItem;
