import type { Importer, ImportedItemGroup } from '../..';
import { AM_IMPORTER_SOPHORA } from '../../../../constants';

import StackAndPaginateResults from './StackAndPaginateResults';
import { StackingSearchResults } from './StackingSearchResults';

export type AssetManagerImporterResultSetProps = {
	importedItems: Array<ImportedItemGroup>;
	importer: Importer;
	importerWorking: boolean;
};

function AssetManagerImporterResults(props: AssetManagerImporterResultSetProps) {
	const { importer, importedItems } = props;

	if (!importedItems.length) {
		return null;
	}

	// This represents the current styles of displaying importer results. Sophora stacks serveral search results on top of each other until you choose to delete one from the list.
	// Livestreams now takes one search at a time with pagination.
	switch (importer) {
		case AM_IMPORTER_SOPHORA:
			return <StackingSearchResults {...props} />;
		default:
			return <StackAndPaginateResults {...props} />;
	}
}

export default AssetManagerImporterResults;
