import { STATUS_PUBLISHED } from '@sep/br24-constants';
import { image } from '@sep/br24-util';
import { Spin, Alert } from 'antd';
import { graphql, QueryRenderer } from 'react-relay';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import type { ItemArticle } from '..';
import environment from '../../../environment';
import PlaceholderImage from '../../../images/placeholder.png';
import { AssetManagerItemArticle as Wrapper } from '../../../ui/AssetManager';

import type { AssetManagerItemArticleQuery } from './__generated__/AssetManagerItemArticleQuery.graphql';

const SpinWrapper = styled.div`
	padding: 10px;
`;

interface Props {
	item: ItemArticle;
}

// * These Item components are used both in AssetManagerSpace to re-render them, and in ItemGroupResults to show the preview without an additional query
function AssetManagerItemArticle(props: Props) {
	return (
		<>
			{props.item.title ? (
				<Wrapper
					title={props.item.title}
					image={
						props.item.image && typeof props.item.image === 'string'
							? image.resize(props.item.image, 'landscape', 's')
							: PlaceholderImage
					}
					isPublished={props.item.isPublished}
					publicationDate={props.item.publicationDate}
					status={props.item.status}
				/>
			) : (
				<AssetManagerItemArticleRenderer {...props} />
			)}
		</>
	);
}

function AssetManagerItemArticleRenderer(props: Props) {
	const translate = useTranslate();
	return (
		<QueryRenderer<AssetManagerItemArticleQuery>
			environment={environment}
			cacheConfig={{ force: true }}
			query={graphql`
				query AssetManagerItemArticleQuery($articleId: String!) {
					article: articleByRowId(rowId: $articleId) {
						rowId
						title
						teaserImage
						status
						publicationDate
					}
				}
			`}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			variables={{ articleId: props.item.articleId, fetchKey: props.item.isPublished }}
			render={({ error, props: result }) => {
				const hasError = !!error;
				const isReady = error === null && result;
				if (hasError) {
					return <Alert message={translate('error')} type="error" />;
				}
				if (!isReady) {
					return (
						<SpinWrapper>
							<Spin size="large" />
						</SpinWrapper>
					);
				}

				const title = result?.article?.title;
				const teaserImage = result?.article?.teaserImage;
				const status = result?.article?.status;
				const publicationDate: string | null | undefined = result?.article?.publicationDate;

				return (
					<Wrapper
						title={title ?? ''}
						image={teaserImage ? image.resize(teaserImage, 'landscape', 's') : PlaceholderImage}
						isPublished={status === STATUS_PUBLISHED}
						publicationDate={publicationDate}
						status={status}
					/>
				);
			}}
		/>
	);
}
export default AssetManagerItemArticle;
