import { Component, type MouseEvent } from 'react';
import { type RouteComponentProps, withRouter } from 'react-router';

import { withTranslation } from '@/client/translation/withTranslation';

import routes from '../../config/config.routes';
import type { WithTranslationProps } from '../../translation';

import styles from './BoardsTable.module.scss';
import type { BoardsTableItem } from './BoardsTableContainer';
import BoardsTableRow from './BoardsTableRow';
import { countCategories, transformBoards } from './util';
import type { TransformedBoard } from './util/transformBoards';

interface Props extends WithTranslationProps, RouteComponentProps {
	boards: BoardsTableItem[];
	categories: string[];
}

class BoardsTable extends Component<Props> {
	getCategoryCount = (record: TransformedBoard) => {
		const transformedBoards = transformBoards(this.props.boards, this.props.categories);
		const categoryCount = countCategories(transformedBoards);
		const transformedBoard = transformedBoards.find((board) => board.section === record.section);

		if (record.rowId === transformedBoard?.rowId) {
			return categoryCount[record.section];
		}

		return undefined;
	};

	handleClickOpen = (_: MouseEvent, node: any) => {
		this.props.history.push(`${routes.boards}/${node.rowId}`);
	};

	render() {
		const {
			translation: { translate },
		} = this.props;

		const transformedBoards = transformBoards(this.props.boards, this.props.categories);

		return (
			<table className={styles.table}>
				<thead className={styles.tableHeader}>
					<tr>
						<th className={styles.categories} />
						<th style={{ textAlign: 'left' }}>{translate('boards.boards')}</th>
						<th style={{ textAlign: 'left' }}>{translate('boards.table.slug')}</th>
						<th style={{ textAlign: 'center' }}>{translate('boards.table.active')}</th>
						<th>{translate('boards.table.edited')}</th>
						<th>{translate('boards.table.status')}</th>
						<th />
					</tr>
				</thead>
				<tbody className={styles.tbody}>
					{transformedBoards ? (
						transformedBoards.map((board) => (
							<BoardsTableRow
								key={board.rowId}
								node={board}
								onClickOpen={this.handleClickOpen}
								categoryCount={this.getCategoryCount(board)}
							/>
						))
					) : (
						<tr>
							<td />
						</tr>
					)}
				</tbody>
			</table>
		);
	}
}

export default withRouter(withTranslation(BoardsTable));
