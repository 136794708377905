import { commitMutation, type Environment, graphql } from 'react-relay';

import type {
	UpdateBoardsTeaserMutation,
	BoardsTeaserPatch,
} from './__generated__/UpdateBoardsTeaserMutation.graphql';

export default (
	rowId: string,
	boardsTeaserPatch: BoardsTeaserPatch,
	environment: Environment
): Promise<UpdateBoardsTeaserMutation['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<UpdateBoardsTeaserMutation>(environment, {
			mutation: graphql`
				mutation UpdateBoardsTeaserMutation($input: UpdateBoardsTeaserByRowIdInput!) {
					updateBoardsTeaserByRowId(input: $input) {
						boardsTeaser {
							id
							rowId
							title
							description
							image {
								url
								copyright
								title
								altText
							}
							link {
								url
								label
							}
							status
							authorByLockedBy {
								guid
								firstname
								lastname
							}
							lockedBy
							lockedSince
						}
					}
				}
			`,
			variables: {
				input: {
					rowId,
					boardsTeaserPatch,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
