import { AM_TYPE_VIDEO } from '../../../constants';

import ItemMultimedia from './ItemMultimedia';
import type { BaseProps } from './ItemMultimedia';

function AssetManagerItemVideo(props: BaseProps) {
	return <ItemMultimedia {...props} type={AM_TYPE_VIDEO} />;
}

export default AssetManagerItemVideo;
