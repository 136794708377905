import { graphql, QueryRenderer, type QueryRendererProps, useRelayEnvironment } from 'react-relay';

import type { SearchAuthorRendererQuery } from './__generated__/SearchAuthorRendererQuery.graphql';

type Props = {
	searchTerm: string;
	children: QueryRendererProps<SearchAuthorRendererQuery>['render'];
};

function SearchAuthorRenderer({ searchTerm, children }: Props) {
	const environment = useRelayEnvironment();

	return (
		<QueryRenderer<SearchAuthorRendererQuery>
			environment={environment}
			query={graphql`
				query SearchAuthorRendererQuery($searchTerm: String!) {
					authors: searchAuthors(search: $searchTerm) {
						edges {
							node {
								guid
								firstname
								lastname
								isActive
								isLdapUser
								jobTitle
							}
						}
					}
				}
			`}
			variables={{ searchTerm }}
			render={children}
		/>
	);
}

export default SearchAuthorRenderer;
