import { CheckOutlined } from '@ant-design/icons';
import { SOCIAL_MEDIA_ACCOUNT_ALL } from '@sep/br24-constants';
import { Input, Form, Button, Select } from 'antd';
import { type ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import config from '../../../config';
import globalStyles from '../../../styles';
import translations from '../../../translations';
import type { SocialMediaAccount } from '../SocialMediaAccounts';

const LABEL_MAXLENGTH = config.author.socialMedia.label.maxLength;

const { Option } = Select;

const FormItem = styled(Form.Item)`
	margin-bottom: 5px !important;
	.ant-form-explain {
		text-align: end;
		font-size: 12px;
	}
`;

const Wrapper = styled.div`
	display: flex;
`;

const AddModeWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 20px;
`;

const FormWrapper = styled.div`
	width: 100%;
`;

export const AddButtonWrapper = styled.div`
	text-align: right;
`;

const SaveHandlerWrapper = styled.div`
	margin-left: 10px;
	display: flex;
	align-items: center;
`;

interface Props extends Partial<SocialMediaAccount> {
	addMode?: boolean;
	onAdd?: (data?: SocialMediaAccount | null) => void;
	onSave: (data: SocialMediaAccount) => void;
}

function countFormatter(maxLength: number) {
	return {
		formatter: (info: { count: number }) => {
			return (
				<>
					{info.count}/{maxLength}
				</>
			);
		},
	};
}

export default function AccountListEditorNew({
	onSave,
	addMode,
	service,
	accountName,
	label,
}: Props) {
	const translate = useTranslate();
	const [form] = Form.useForm<SocialMediaAccount>();
	const [accountNamePlaceholder, setAccountNamePlaceholder] = useState(
		service
			? translations.socialMediaPlaceholders[service]
			: translate('author.socialMedia.account.placeholder')
	);

	const onHandleSubmit = useCallback(
		(data: SocialMediaAccount) => {
			onSave(data);
			form.resetFields();
			setAccountNamePlaceholder(translate('author.socialMedia.account.placeholder'));
		},
		[onSave, form, translate]
	);

	const calculateAcccountNamePlaceholder = useCallback(
		(newService: (typeof SOCIAL_MEDIA_ACCOUNT_ALL)[number]) => {
			setAccountNamePlaceholder(
				translations.socialMediaPlaceholders[newService] ??
					translate('author.socialMedia.account.placeholder')
			);
		},
		[translate]
	);

	return (
		<Wrapper>
			<FormWrapper>
				<Form
					form={form}
					layout="vertical"
					onFinish={onHandleSubmit}
					autoComplete="off"
					initialValues={{ service, accountName, label }}
					scrollToFirstError
				>
					<FormItem name="service" rules={[{ required: true, message: '' }]}>
						<Select<(typeof SOCIAL_MEDIA_ACCOUNT_ALL)[number]>
							className={globalStyles.fullWidth}
							placeholder={translate('author.socialMedia.select.placeholder')}
							onChange={calculateAcccountNamePlaceholder}
						>
							{SOCIAL_MEDIA_ACCOUNT_ALL.map((accountType) => (
								<Option key={accountType} value={accountType}>
									{translations[accountType] as ReactNode}
								</Option>
							))}
						</Select>
					</FormItem>
					<FormItem name="accountName" rules={[{ required: true, message: '' }]}>
						<Input placeholder={accountNamePlaceholder} />
					</FormItem>
					<FormItem name="label" rules={[{ max: LABEL_MAXLENGTH, message: '' }]}>
						<Input
							placeholder={translate('author.socialMedia.label.placeholder')}
							showCount={countFormatter(LABEL_MAXLENGTH)}
						/>
					</FormItem>

					{addMode && (
						<FormItem>
							<AddModeWrapper>
								<AddButtonWrapper>
									<Button onClick={() => form.submit()}>
										{translate('author.socialMedia.add')}
									</Button>
								</AddButtonWrapper>
							</AddModeWrapper>
						</FormItem>
					)}
				</Form>
			</FormWrapper>
			{!addMode && (
				<SaveHandlerWrapper>
					<Button onClick={() => form.submit()} icon={<CheckOutlined />} shape="circle" />
				</SaveHandlerWrapper>
			)}
		</Wrapper>
	);
}
