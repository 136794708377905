import { AutoComplete, Button, Form } from 'antd';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import config from '../../../config';
import type { WithTranslationProps } from '../../../translation';

const TAG_MAX_LENGTH = config.articleEditor.meta.tags.maxLength;

const FullWidthInput = styled(AutoComplete)`
	width: 100%;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
`;

const ButtonWrapper = styled.div`
	display: flex;
	margin-left: 1rem;
`;

const OptionItemCount = styled.div`
	color: ${(props) => props.theme.colors.br24Light};
	margin-left: 0.6rem;
	font-size: 10px;
`;

export type OptionItem = {
	count: number;
	id: number;
	text: string;
};

type Props = WithTranslationProps & {
	onAdd: (tag: string) => void;
	onSearch: (value: string) => void;
	onSelect: (item: OptionItem) => void;
	suggestions: Array<OptionItem> | undefined | null;
};

type State = {
	value: string;
};

class ArticleMetaTagsInput extends Component<Props, State> {
	state = {
		value: '',
	};

	handleClickAdd = () => {
		this.props.onAdd(this.state.value);
		this.setState({ value: '' }, () => {
			this.props.onSearch('');
		});
	};

	handleSearch = (value: string) => {
		this.setState({ value }, () => {
			this.props.onSearch(value);
		});
	};

	handleSelect = (value: any) => {
		const { suggestions, onSelect, onSearch } = this.props;
		const selectedItem =
			Array.isArray(suggestions) &&
			suggestions.filter((item) => String(item.id) === String(value)).shift();
		if (selectedItem) {
			this.setState({ value: '' }, () => {
				onSelect(selectedItem);
				onSearch('');
			});
		}
	};

	renderOption = (item: OptionItem) => (
		<AutoComplete.Option key={item.id}>
			<Row>
				<div>{item.text}</div>
				<OptionItemCount>{item.count}</OptionItemCount>
			</Row>
		</AutoComplete.Option>
	);

	render() {
		const {
			suggestions,
			translation: { translate },
		} = this.props;
		const { value } = this.state;

		const options =
			Array.isArray(suggestions) && suggestions.length ? suggestions.map(this.renderOption) : [];

		const hasValidationError = value && value.length > TAG_MAX_LENGTH;

		return (
			<Form.Item
				help={
					hasValidationError
						? translate('article.meta.tags.inputHelp', {
								count: value ? value.length : 0,
								max: TAG_MAX_LENGTH,
						  })
						: null
				}
				validateStatus={hasValidationError ? 'error' : 'success'}
			>
				<Row>
					<FullWidthInput
						onSearch={this.handleSearch}
						onSelect={this.handleSelect}
						// optionLabelProp="children"
						placeholder={translate('article.meta.tags.placeholder')}
						value={value}
					>
						{options}
					</FullWidthInput>
					<ButtonWrapper>
						<Button
							data-testid="addbutton"
							disabled={!value || value.length > TAG_MAX_LENGTH}
							onClick={this.handleClickAdd}
						>
							{translate('article.meta.tags.addButtonLabel')}
						</Button>
					</ButtonWrapper>
				</Row>
			</Form.Item>
		);
	}
}

export default withTranslation(ArticleMetaTagsInput);
