import { Modal, Button, notification, Form } from 'antd';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import * as actions from '../../store/reducers/articleRepublisher';
import attachFormItemErrorProps from '../../util/attachFormItemErrorProps';
import { Input } from '../Form';
import LimitedLengthItem from '../LimitedLengthItem';

type Props = ReduxProperties & {
	notify?: boolean;
	onSuccess?: () => void;
	onError?: (err: Error) => void;
};

function ArticleRepublisher({
	isVisible = false,
	update,
	cancel,
	save,
	article,
	validation,
	isWorking,
	isSavable,
	notify = true,
	onSuccess,
	onError,
}: Props) {
	const handleChangeSlug = (value: string) => update({ slug: value });

	const handleChangeTitle = (value: string) => update({ title: value });

	const handleSave = () => {
		save((err) => {
			if (err) {
				if (notify) {
					notification.error({
						message: 'Fehler aufgetreten',
						description: `Es ist ein unerwarteter Fehler aufgetreten (originale Nachricht: ${err.message})`,
					});
				}

				if (typeof onError === 'function') {
					onError(err);
				}

				return;
			}

			if (notify) {
				notification.success({
					message: 'Der Artikel wurde erneut publiziert!',
				});
			}

			if (typeof onSuccess === 'function') {
				onSuccess();
			}

			cancel();
		});
	};

	if (!isVisible || !article) {
		return null;
	}

	return (
		<Modal
			open={isVisible}
			onCancel={cancel}
			title="Artikel Republisher"
			footer={[
				<Button key="cancel" onClick={cancel}>
					Abbrechen
				</Button>,
				<Button
					key="save"
					type="primary"
					loading={isWorking}
					disabled={!isSavable}
					onClick={handleSave}
				>
					Speichern
				</Button>,
			]}
		>
			<Form layout="vertical">
				<p>
					Um den Artikel erneut zu publizieren musst Du ein neues URL-Kürzel angeben. Optional
					kannst Du den Titel ändern.
				</p>
				<br />
				<LimitedLengthItem
					required={true}
					label="URL-Kürzel"
					maxLength={64}
					{...attachFormItemErrorProps(validation, 'slug')}
				>
					<Input
						placeholder="URL-Kürzel"
						defaultValue={article.slug}
						onMagicChange={handleChangeSlug}
					/>
				</LimitedLengthItem>
				<LimitedLengthItem
					required={true}
					label="Titel"
					maxLength={64}
					{...attachFormItemErrorProps(validation, 'title')}
				>
					<Input
						placeholder="Titel"
						defaultValue={article.title}
						onMagicChange={handleChangeTitle}
					/>
				</LimitedLengthItem>
			</Form>
		</Modal>
	);
}

const connector = connect(
	({ articleRepublisher }: ReduxState) => ({
		isWorking: articleRepublisher.isWorking,
		isVisible: typeof articleRepublisher.active === 'string',
		article: articleRepublisher.active
			? {
					title:
						articleRepublisher.updatedArticles[articleRepublisher.active]['title'] ||
						articleRepublisher.originalArticles[articleRepublisher.active].title,
					slug:
						articleRepublisher.updatedArticles[articleRepublisher.active]['slug'] ||
						articleRepublisher.originalArticles[articleRepublisher.active].slug,
			  }
			: null,
		validation: articleRepublisher.active
			? articleRepublisher.validationByArticle[articleRepublisher.active]
			: null,
		isSavable: articleRepublisher.active
			? articleRepublisher.validationByArticle[articleRepublisher.active] === null &&
			  Object.keys(articleRepublisher.updatedArticles[articleRepublisher.active]).length > 0
			: false,
	}),
	{
		update: actions.update,
		cancel: actions.cancel,
		save: actions.save,
	}
);

type ReduxProperties = ConnectedProps<typeof connector>;

export default connector(ArticleRepublisher);
