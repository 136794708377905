import type { Point } from 'google-map-react';

export function pointToGeoLocation(
	point: Point | null | undefined
): `(${number},${number})` | null {
	if (isPoint(point)) {
		return `(${point.x},${point.y})`;
	} else {
		return null;
	}
}

function isPoint(point: any): point is Point {
	return (
		!!point && typeof point === 'object' && point.hasOwnProperty('x') && point.hasOwnProperty('y')
	);
}
