export const UPDATE_VISIBILITY = 'AM_UPDATE_VISIBILITY';
export const CHANGE_SPACE = 'AM_CHANGE_SPACE';
export const CHANGE_MODE = 'AM_CHANGE_MODE';
export const UPDATE_FILTER = 'AM_UPDATE_FILTER';
export const INITIALIZE = 'AM_INITIALIZE';
export const ADD_ITEMS = 'AM_ADD_ITEMS';
export const UPDATE_ITEM = 'AM_UPDATE_ITEM';
export const REMOVE_ITEM = 'AM_REMOVE_ITEM';
export const CLEAN_SPACE = 'AM_CLEAN_SPACE';
export const UPDATE_SELECTION = 'AM_UPDATE_SELECTION';
export const AM_IMPORT_SOPHORA_ENTITY_BY_ID = 'AM_IMPORT_SOPHORA_ENTITY_BY_ID';
export const AM_IMPORT_SOPHORA_ARTICLE = 'AM_IMPORT_SOPHORA_ARTICLE';
export const ADD_ITEMS_TO_IMPORTER = 'AM_ADD_ITEMS_TO_IMPORTER';
export const ADD_ITEM_GROUP_TO_IMPORTER = 'AM_ADD_ITEM_GROUP_TO_IMPORTER';
export const SET_ITEM_GROUP_PAGINATION = 'AM_SET_ITEM_GROUP_PAGINATION';
export const REMOVE_ITEM_FROM_IMPORTER = 'AM_REMOVE_ITEM_FROM_IMPORTER';
export const REMOVE_ITEM_GROUP_FROM_IMPORTER = 'AM_REMOVE_ITEM_GROUP_FROM_IMPORTER';
export const CLEAN_IMPORTER = 'AM_CLEAN_IMPORTER';
export const ACTIVATE_IMPORTER = 'AM_ACTIVATE_IMPORTER';
export const UPDATE_IMPORTER_SEARCH_TERM_FROM_ARTICLE_EDITOR =
	'AM_UPDATE_IMPORTER_SEARCH_TERM_FROM_ARTICLE_EDITOR';
export const IMPORT_LIVESTREAMS = 'AM_IMPORT_LIVESTREAMS';
export const AM_IMPORT_BRBILD = 'AM_IMPORT_BRBILD';
export const AM_IMPORT_NEWSROOM = 'AM_IMPORT_NEWSROOM';
export const IMPORT_BROADCAST_CHANNELS = 'AM_IMPORT_BROADCAST_CHANNELS';
export const ADD_GLOBAL_GROUP = 'AM_ADD_GLOBAL_GROUP';
export const UPDATE_GLOBAL_GROUP = 'AM_UPDATE_GLOBAL_GROUP';
export const REMOVE_GLOBAL_GROUP = 'AM_REMOVE_GLOBAL_GROUP';
export const TOGGLE_GLOBAL_GROUP_LOCK = 'AM_TOGGLE_GLOBAL_GROUP_LOCK';
export const SET_ACTIVE_GLOBAL_GROUP = 'AM_SET_ACTIVE_GLOBAL_GROUP';
export const SET_FIREBASE_CONNECTED = 'AM_SET_FIREBASE_CONNECTED';
export const TOGGLE_SUB_GROUPS = 'AM_TOGGLE_SUB_GROUPS';
export const LOCK_ITEM_FOR_DELETION = 'AM_LOCK_ITEM_FOR_DELETION';
