import { Layout, Row, Col, Button } from 'antd';
import classNames from 'classnames';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';

import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import * as creatorActions from '../../../store/reducers/creator';
import globalStyles from '../../../styles';
import type { WithTranslationProps } from '../../../translation';
import DelayedSearch from '../../DelayedSearch';

const TYPE_TEASER = 'TEASER';

interface Props extends WithTranslationProps, ReduxProps, RouteComponentProps {
	currentFilter: any;
	onChange: (currentFilter: { searchTerm: string }) => void;
}

const { Content } = Layout;

class BoardsTeasersFilter extends Component<Props> {
	handleChangeSearchTerm = (value: string) => this.props.onChange({ searchTerm: value });

	handleClickNew = () => {
		this.props.showCreator({ type: TYPE_TEASER });
	};

	render() {
		const { searchTerm } = this.props.currentFilter;
		const {
			translation: { translate },
		} = this.props;

		return (
			<Content>
				<Row>
					<Col span={24} className={classNames(globalStyles.pM, globalStyles.toolbar)}>
						<div>
							<Button type="primary" onClick={this.handleClickNew}>
								{translate('boardsTeaser.action.createTeaser')}
							</Button>
						</div>
						<div>
							<DelayedSearch
								placeholder={translate('boardsTeaser.action.searchPlaceholder')}
								style={{ width: 250 }}
								onChange={this.handleChangeSearchTerm}
								defaultValue={searchTerm || ''}
							/>
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

const connector = connect(null, { showCreator: creatorActions.show });

type ReduxProps = ConnectedProps<typeof connector>;

export default withTranslation(withRouter(connector(BoardsTeasersFilter)));
