import moment from 'moment';

import { Title, Info, Content, Copyright } from './AssetManagerItemControl';
import Media from './Media';
import TypeIcon from './TypeIcon';

export type BaseProps = {
	image?: string;
	title: string;
	copyright: string | undefined | null;
	duration: number | undefined | null;
};

type AssetManagerItemMultimediaProps = BaseProps & {
	type: string;
};

function AssetManagerItemMultimedia({
	type,
	image,
	title,
	copyright,
	duration,
}: AssetManagerItemMultimediaProps) {
	return (
		<>
			<TypeIcon type={type} isOverlying={true} />
			<Media image={image} />
			<Content>
				<Title>{title}</Title>
				{duration && <Info>{moment.duration(duration, 'seconds').humanize()}</Info>}
				{copyright && <Copyright>{copyright}</Copyright>}
			</Content>
		</>
	);
}

export default AssetManagerItemMultimedia;
