import { Select } from 'antd';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, graphql } from 'react-relay';
import { isPresent } from 'ts-extras';
import { useDebounce } from 'usehooks-ts';

import environment from '../../environment';
import gs from '../../styles';

import type {
	ParentBoardPickerQuery,
	ParentBoardPickerQuery$data,
} from './__generated__/ParentBoardPickerQuery.graphql';

type UniqueIdentifier = number | string | symbol;

const { Option } = Select;

interface IOption {
	key: string;
	value: string;
	children: string;
	label: string;
}

interface Props {
	initialValue?: UniqueIdentifier | null;
	defaultValue?: UniqueIdentifier | null;
	allowClear?: boolean;
	ignore?: Array<string>;
	placeholder?: string;
	onSelect: (rowId: null | string, options?: IOption) => void;
}

export const ParentBoardPicker: FC<Props> = ({
	placeholder = 'Seiten durchsuchen...',
	allowClear = true,
	onSelect,
	defaultValue = '',
	initialValue,
	ignore = [],
}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce<string>(searchTerm, 300);
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<ParentBoardPickerQuery$data | null>(null);

	const rowId = initialValue ?? defaultValue;

	useEffect(() => {
		const subscription = fetchQuery<ParentBoardPickerQuery>(
			environment,
			graphql`
				query ParentBoardPickerQuery($searchTerm: String!) {
					boards: filterBoards(searchTerm: $searchTerm, statusFilter: [PUBLISHED], first: 1000) {
						edges {
							node {
								rowId
								title
							}
						}
					}
				}
			`,
			{ searchTerm: debouncedSearchTerm }
		).subscribe({
			start: () => {
				setLoading(true);
			},
			next: (currentData) => {
				setLoading(false);
				setData(currentData);
			},
		});

		return () => subscription.unsubscribe();
	}, [debouncedSearchTerm]);

	const boards = useMemo(
		() =>
			data?.boards?.edges
				.map(({ node }) => node)
				.filter(isPresent)
				.filter((board) => !ignore.includes(board.rowId)) ?? [],
		[data, ignore]
	);

	const handleSelect = useCallback(
		(_: any, option: IOption) => {
			onSelect(option.value, option);
		},
		[onSelect]
	);

	return (
		<div>
			<Select<string, IOption>
				disabled={loading}
				allowClear={allowClear}
				className={gs.fullWidth}
				defaultValue={rowId ? String(rowId) : null}
				filterOption={false}
				labelInValue
				loading={!data || loading}
				onSearch={setSearchTerm}
				onClear={() => setSearchTerm('')}
				onSelect={handleSelect}
				optionFilterProp="label"
				optionLabelProp="label"
				placeholder={placeholder}
				showSearch
				style={{
					color: data ? 'inherit' : 'transparent',
				}}
			>
				{boards.map(({ rowId, title }) => (
					<Option key={rowId} value={rowId} label={title}>
						<span style={{ opacity: boards ? 1 : 0 }}>{title}</span>
					</Option>
				))}
			</Select>
		</div>
	);
};
