import { MODULE_TYPE_TEXT } from '@sep/br24-constants';
import { Component } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { Item } from '@/client/containers/AssetManager';
import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';

import type { ModuleTypeText } from '../..';
import type { WithTranslationProps } from '../../../../translation';
import { ArticleModuleItem, ArticleModuleText } from '../../../../ui/ArticleModule';
import { DndSortModuleContainer } from '../../DragAndDropHandler';
import type { SimpleItem } from '../../DragAndDropHandler/DndSortModuleContainer';
import { getAEItemType, toSortModule } from '../../DragAndDropHandler/dndUtils';
import { activeArticleModuleSelector } from '../../selectors';
import BrokenModuleContainer from '../ArticleEditorBrokenModule';

interface Props extends WithTranslationProps, OwnProps, ReduxProps {
	isHeaderModule?: boolean;
	isEditing?: boolean;
	onUpdate: (id: string | number, type: string, item: any) => void;
	onDelete?: (id: string | number) => void;
	onModuleIsEditing?: (nextIsEditing: boolean) => void;
	onMove?: (dragIndex: SimpleItem, hoverIndex: SimpleItem) => void;
	onDisplayAttachedDropzone?: (order: number, position: 'top' | 'bottom') => void;
	onResetAttachedDropzone?: () => void;
	onMoveToAssetManager?: (id: string | number) => void;
	onAddViaAttachedDropzone?: (item: Item | undefined) => void;
}

class TextModuleContainer extends Component<Props> {
	handleChange = (value: any) => {
		if (this.props.module.text !== value) {
			this.props.onUpdate(this.props.id, 'text', value);
		}
	};

	handleDelete = () => {
		if (this.props.onDelete) {
			this.props.onDelete(this.props.id);
		}
	};

	render() {
		const {
			module,
			translation: { translate },
			isHeaderModule = false,
			onMoveToAssetManager,
			onDisplayAttachedDropzone,
			onMove,
			...rest
		} = this.props;

		if (typeof module.text !== 'string' && !module.text) {
			return <BrokenModuleContainer id={module.rowId} />;
		}

		if (!isHeaderModule && !(onMoveToAssetManager || onDisplayAttachedDropzone || onMove)) {
			throw new Error(
				'onMoveToAssetManager, onDisplayAttachedDropzone and onMove needs to be set when isHeaderModule is `false`'
			);
		}

		return (
			<div>
				{isHeaderModule ? (
					<ArticleModuleItem
						isHeaderModule={isHeaderModule}
						label={translate(`modules.${MODULE_TYPE_TEXT}.text`)}
					>
						<ArticleModuleText
							validation={module.__validation__}
							defaultValue={module.text || ''}
							onChange={this.handleChange}
							onModuleIsEditing={this.props.onModuleIsEditing}
							isEditing={this.props.isEditing}
						/>
					</ArticleModuleItem>
				) : (
					<DndSortModuleContainer
						acceptedItemTypes={{
							dragSource: getAEItemType('TEXT'),
							dropTarget: toSortModule,
						}}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						onMoveToAssetManager={onMoveToAssetManager!}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						onDisplayAttachedDropzone={onDisplayAttachedDropzone!}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						onMove={onMove!}
						module={module}
						{...rest}
					>
						<ArticleModuleItem
							isHeaderModule={isHeaderModule}
							label={translate(`modules.${MODULE_TYPE_TEXT}.text`)}
							onDelete={this.handleDelete}
						>
							<ArticleModuleText
								validation={module.__validation__}
								defaultValue={module.text || ''}
								onChange={this.handleChange}
								{...rest}
							/>
						</ArticleModuleItem>
					</DndSortModuleContainer>
				)}
			</div>
		);
	}
}

interface OwnProps {
	id: string | number;
}

const connector = connect((state: ReduxState, { id }: OwnProps) => ({
	module: activeArticleModuleSelector(state, id) as ModuleTypeText,
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(withTranslation(TextModuleContainer));
