import type { AppThunkAction } from '@/client/store/redux';

import type { UpdateVisibilityAction } from '..';

import updateVisibility from './updateVisibility';

export const toggleVisibility: () => AppThunkAction<UpdateVisibilityAction> =
	() => (dispatch, getState) => {
		dispatch(updateVisibility(!getState().assetManager.isVisible));
	};

export const hide: () => AppThunkAction<UpdateVisibilityAction> = () => (dispatch) => {
	dispatch(updateVisibility(false));
};

export const show: () => AppThunkAction<UpdateVisibilityAction> = () => (dispatch) => {
	dispatch(updateVisibility(true));
};
