import { Tag } from 'antd';

import { useTranslate } from '@/client/translation/useTranslate';

import type { Importer } from '..';
import config from '../../../config';
import * as constants from '../../../constants';
import {
	AssetManagerImporter as AssetManagerImporterWrapper,
	AssetManagerImporterNavigator,
	AssetManagerImporterNavigatorItem,
	AssetManagerImporterContent,
} from '../../../ui/AssetManager';

import { AssetManagerImporterBrBild } from './AssetManagerImporterBrBild';
import AssetManagerImporterLiveStream from './AssetManagerImporterLiveStream';
import AssetManagerImporterNewsroom from './AssetManagerImporterNewsroom';
import AssetManagerImporterSophora from './AssetManagerImporterSophora';
import { AssetManagerImporterSophoraArticle } from './AssetManagerImporterSophoraArticle';

type AssetManagerImporterProps = {
	isVisible: boolean;
	activeImporter: Importer;
	onSelectImporter: (importer: Importer) => void;
	onCloseImporter: () => void;
};

export default function AssetManagerImporter({
	isVisible = false,
	activeImporter,
	onSelectImporter,
	onCloseImporter,
}: AssetManagerImporterProps) {
	const showImporterSophora = config.VITE_ENABLE_IMPORTER_SOPHORA;
	const translate = useTranslate();

	return (
		<AssetManagerImporterWrapper isVisible={isVisible} onCloseImporter={onCloseImporter}>
			<AssetManagerImporterNavigator onSelect={onSelectImporter}>
				<AssetManagerImporterNavigatorItem
					id={constants.AM_IMPORTER_SOPHORA}
					isActive={activeImporter === constants.AM_IMPORTER_SOPHORA}
				>
					{translate(`assetManager.importer.${constants.AM_IMPORTER_SOPHORA}.title`)}
				</AssetManagerImporterNavigatorItem>
				{showImporterSophora ? (
					<AssetManagerImporterNavigatorItem
						id={constants.AM_IMPORTER_SOPHORA_ARTICLE}
						isActive={activeImporter === constants.AM_IMPORTER_SOPHORA_ARTICLE}
					>
						<Tag color="#108ee9">beta</Tag>
						{translate(`assetManager.importer.${constants.AM_IMPORTER_SOPHORA_ARTICLE}.title`)}{' '}
					</AssetManagerImporterNavigatorItem>
				) : null}
				<AssetManagerImporterNavigatorItem
					id={constants.AM_IMPORTER_LIVESTREAMS}
					isActive={activeImporter === constants.AM_IMPORTER_LIVESTREAMS}
				>
					{translate(`assetManager.importer.${constants.AM_IMPORTER_LIVESTREAMS}.title`)}
				</AssetManagerImporterNavigatorItem>
				<AssetManagerImporterNavigatorItem
					id={constants.AM_IMPORTER_NEWSROOM}
					isActive={activeImporter === constants.AM_IMPORTER_NEWSROOM}
				>
					{translate(`assetManager.importer.${constants.AM_IMPORTER_NEWSROOM}.title`)}
				</AssetManagerImporterNavigatorItem>

				<AssetManagerImporterNavigatorItem
					id={constants.AM_IMPORTER_BRBILD}
					isActive={activeImporter === constants.AM_IMPORTER_BRBILD}
				>
					{translate(`assetManager.importer.${constants.AM_IMPORTER_BRBILD}.title`)}
				</AssetManagerImporterNavigatorItem>
			</AssetManagerImporterNavigator>
			<AssetManagerImporterContent>
				{activeImporter === constants.AM_IMPORTER_SOPHORA && <AssetManagerImporterSophora />}
				{activeImporter === constants.AM_IMPORTER_SOPHORA_ARTICLE && (
					<AssetManagerImporterSophoraArticle />
				)}
				{activeImporter === constants.AM_IMPORTER_LIVESTREAMS && <AssetManagerImporterLiveStream />}
				{activeImporter === constants.AM_IMPORTER_NEWSROOM && <AssetManagerImporterNewsroom />}
				{activeImporter === constants.AM_IMPORTER_BRBILD && <AssetManagerImporterBrBild />}
			</AssetManagerImporterContent>
		</AssetManagerImporterWrapper>
	);
}
