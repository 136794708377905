import { MODULE_TYPE_EMBED } from '@sep/br24-constants';
import { Tabs, Button } from 'antd';
import { Component, type KeyboardEvent, type ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import { ModulePartInput } from '../ArticleModuleParts';
import type { ModulePartInputElement } from '../ArticleModuleParts/ModulePartInput/ModulePartInput';

const Wrapper = styled.div`
	.ant-tabs-tabpane {
		overflow: hidden !important;
	}
`;

const DefaultImageResetButtonWrapper = styled.div`
	margin-bottom: 25px;
	text-align: right;
`;

type Props = WithTranslationProps & {
	service: string | undefined | null;
	altText?: string | null;
	validation: any;
	preview: ReactNode;
	imageUploader: ReactNode;
	onChange: (input: { [key: string]: any }) => void;
	onKeyDown?: (event: KeyboardEvent<ModulePartInputElement>) => void;
};

class ArticleModuleEmbed extends Component<Props> {
	handleChangeAltText = (value: string) => {
		this.props.onChange({ altText: value });
	};

	handleKeyDown = (event: KeyboardEvent<ModulePartInputElement>) => {
		if (this.props.onKeyDown) {
			this.props.onKeyDown(event);
		}
	};

	handleResetImage = () => {
		this.props.onChange({
			thumbnail: {
				url: null,
				altText: null,
				copyright: null,
				title: null,
			},
		});
	};

	render() {
		const {
			service,
			altText,
			preview,
			validation,
			onChange,
			imageUploader,
			translation: { translate },
			...rest
		} = this.props;

		const tabs = [
			{
				label: translate(`modules.${MODULE_TYPE_EMBED}.tabNameEmbedCode`),
				key: 'embed_code',
				children: (
					<>
						{preview}
						<ModulePartInput
							id="service"
							value={service ? service.toUpperCase() : null}
							label={translate(`modules.${MODULE_TYPE_EMBED}.serviceLabel`)}
							placeholder={translate(`modules.${MODULE_TYPE_EMBED}.servicePlaceholder`)}
							validationError={
								validation && validation['embed.service'] ? validation['embed.service'] : null
							}
							disabled={true}
							{...rest}
						/>
						<ModulePartInput
							id="altText"
							value={altText || null}
							label={translate(`modules.${MODULE_TYPE_EMBED}.altTextLabel`)}
							placeholder={translate(`modules.${MODULE_TYPE_EMBED}.altTextPlaceholder`)}
							validationError={validation ? validation['embed.altText'] : null}
							onKeyDown={this.handleKeyDown}
							onChange={this.handleChangeAltText}
							{...rest}
						/>
					</>
				),
			},
			{
				label: translate(`modules.${MODULE_TYPE_EMBED}.tabNamePreviewImage`),
				key: 'embed_image',
				children: (
					<>
						<DefaultImageResetButtonWrapper>
							<Button type="primary" onClick={this.handleResetImage}>
								{translate(`modules.${MODULE_TYPE_EMBED}.resetPreviewImageButtonText`)}
							</Button>
						</DefaultImageResetButtonWrapper>
						{imageUploader}
					</>
				),
			},
		];

		return (
			<Wrapper>
				<Tabs defaultActiveKey="embed_code" items={tabs} />
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleModuleEmbed);
