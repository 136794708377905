import { Row, Col } from 'antd';
import cn from 'classnames';

import type { BoardSectionBuffer } from '../../../store/reducers/boardBuffer';

import styles from './Section.module.scss';
import { PooledItem, B5TeaserItem, MapItem } from './SectionItem';

type Props = {
	section: BoardSectionBuffer;
};

export default function ({ section }: Props) {
	return (
		<Row gutter={12}>
			<Row gutter={12} className={cn(styles.firstRow)}>
				<Col span={8}>
					<PooledItem
						sectionInternalId={section.__meta__.internalId}
						itemPosition={0}
						isDroppable={!section.autofill}
					/>
				</Col>
				<Col span={8}>
					<PooledItem
						sectionInternalId={section.__meta__.internalId}
						itemPosition={1}
						isDroppable={!section.autofill}
					/>
				</Col>
				<Col span={8}>
					<B5TeaserItem />
				</Col>
			</Row>
			<Row gutter={12}>
				<Col span={8}>
					<PooledItem
						sectionInternalId={section.__meta__.internalId}
						itemPosition={2}
						isDroppable={!section.autofill}
					/>
				</Col>
				<Col span={8}>
					<PooledItem
						sectionInternalId={section.__meta__.internalId}
						itemPosition={3}
						isDroppable={!section.autofill}
					/>
				</Col>
				<Col span={8}>
					<MapItem sectionInternalId={section.__meta__.internalId} />
				</Col>
			</Row>
		</Row>
	);
}
