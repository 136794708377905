export type ValidationResult = {
	isValid: boolean;
	error: {
		[key: string]: Array<string>;
	};
};

type JoiErrorItem = {
	type: string;
	path: string;
	message: string;
};

type JoiErrorStack = Array<JoiErrorItem>;

export type JoiValidation = {
	error:
		| {
				details: JoiErrorStack;
		  }
		| undefined
		| null;
};

export function processValidation({ error }: JoiValidation): ValidationResult {
	const result = { isValid: true, error: {} };

	if (error) {
		result.isValid = false;
		result.error = error.details.reduce(
			(accumulator, item) => ({
				...accumulator,
				[Array.isArray(item.path) ? item.path.join('.') : item.path]: [
					...(accumulator[item.path] || []),
					item.message,
				],
			}),
			{}
		);
	}

	return result;
}
