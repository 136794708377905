/**
 * @generated SignedSource<<628f689f587460407a9ec53ae7faa2ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type AssetManagerItemArticleQuery$variables = {
  articleId: string;
};
export type AssetManagerItemArticleQuery$data = {
  readonly article: {
    readonly publicationDate: string | null;
    readonly rowId: string;
    readonly status: Status;
    readonly teaserImage: string | null;
    readonly title: string;
  } | null;
};
export type AssetManagerItemArticleQuery = {
  response: AssetManagerItemArticleQuery$data;
  variables: AssetManagerItemArticleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "articleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "articleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teaserImage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetManagerItemArticleQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssetManagerItemArticleQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc7ecaf5fa9468cd4df9efd19bf0bc0b",
    "id": null,
    "metadata": {},
    "name": "AssetManagerItemArticleQuery",
    "operationKind": "query",
    "text": "query AssetManagerItemArticleQuery(\n  $articleId: String!\n) {\n  article: articleByRowId(rowId: $articleId) {\n    rowId\n    title\n    teaserImage\n    status\n    publicationDate\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "af108b38679a0b3b26d2d9089838cb45";

export default node;
