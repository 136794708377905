import { Modal, Typography } from 'antd';
import moment from 'moment';
import { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import type { ItemBrBildImage, ImportedItemBrBildImage } from '../';
import { AssetManagerItemBrBildImage as Wrapper } from '../../../ui/AssetManager';

type CopyrightStatusProps = {
	value: number;
};

const USAGE_CONSTRAINT_PROGRAM = 'verwendungsendung';
const USAGE_CONSTRAINT_PRODUCTION = 'verwendungproduktion';
const USAGE_CONSTRAINT_INQUIRY = 'aufanfrage';

const ModalContentImage = styled.img`
	max-width: 100%;
	width: 100%;
	margin-bottom: ${(props) => props.theme.assetManager.innerPadding};
`;

const ModalContentListCopyrightStatus = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 9px;
	margin-top: ${(props) => props.theme.assetManager.innerPadding};
	background: ${(props: CopyrightStatusProps) => {
		switch (props.value) {
			case 1:
				return 'red';
			case 2:
				return 'orange';
			case 3:
				return 'green';
			default:
				break;
		}
		return 'none';
	}};
`;

const ModalDialogComponent = ({
	image,
	visible,
	onOk,
	onCancel,
}: {
	image: ItemBrBildImage | ImportedItemBrBildImage;
	visible: boolean;
	onOk: () => void;
	onCancel: () => void;
}) => {
	const imgUrl = image?.url;
	const imgTitle = image?.title;
	const imgAltText = image?.altText;
	const imgCopyright = image?.copyright;
	const imgCopyrightNotice = image?.copyrightNotice === 'undefined' ? null : image.copyrightNotice;
	const imgCopyrightStatus = image?.copyrightStatus;

	const imgTimestamp = image?.timestamp
		? moment(image.timestamp).format('DD.MM.YYYY [um] HH:mm [Uhr]')
		: null;

	const imgUsageConstraints = image?.usageConstraints
		?.map((entry) => {
			switch (entry) {
				case USAGE_CONSTRAINT_PROGRAM:
					return 'Verwendung nur in Zusammenhang mit ursprünglicher Berichterstattung/Sendung/Veranstaltung/Rundfunkanstalt';
				case USAGE_CONSTRAINT_PRODUCTION:
					return 'Verwendung nur innerhalb ursprünglicher Produktion';
				case USAGE_CONSTRAINT_INQUIRY:
					return 'nur auf Anfrage beim Bildmanagement';
				default:
					return entry;
			}
		})
		.join(', ');

	return (
		<Modal
			title={imgTitle}
			open={visible}
			onOk={onOk}
			onCancel={onCancel}
			width="40vw"
			closable={false}
			cancelButtonProps={{ style: { display: 'none' } }}
		>
			<div>
				<ModalContentImage src={imgUrl ?? undefined} alt={imgAltText ?? undefined} />
				<Typography.Paragraph>
					<li>
						<b>Bildinformation:</b> {imgAltText}
					</li>
				</Typography.Paragraph>
				<ul>
					<li>
						<b>Copyright:</b> {imgCopyright}
					</li>
					{imgCopyrightNotice && (
						<li>
							<b>Kommentar:</b> {imgCopyrightNotice}
						</li>
					)}
					{imgUsageConstraints && (
						<li>
							<b>Verwendungsbeschränkung:</b> {imgUsageConstraints}
						</li>
					)}
					<li>
						<b>Erstellungsdatum:</b> {imgTimestamp}
					</li>
				</ul>
				<ModalContentListCopyrightStatus
					value={imgCopyrightStatus ? parseInt(imgCopyrightStatus, 10) : 0}
				/>
			</div>
		</Modal>
	);
};

type Props = {
	item: ItemBrBildImage | ImportedItemBrBildImage;
};

const AssetManagerItemBrBildImage = forwardRef<{ showBrBildImageInfo: () => void }, Props>(
	({ item }, ref) => {
		const [modalVisible, setModalVisible] = useState(false);

		const showModal = () => setModalVisible(true);

		useImperativeHandle(ref, () => ({
			showBrBildImageInfo() {
				showModal();
			},
		}));

		const handleOk = () => setModalVisible(false);
		const handleCancel = () => setModalVisible(false);

		return (
			<>
				<ModalDialogComponent
					image={item}
					visible={modalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
				/>
				<Wrapper
					image={item?.url ?? null}
					title={item?.title ?? null}
					copyright={item?.copyright ?? null}
				/>
			</>
		);
	}
);

export default AssetManagerItemBrBildImage;
