import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Alert, Card, type CardProps } from 'antd';
import type { MouseEventHandler } from 'react';
import { graphql, QueryRenderer } from 'react-relay';

import environment from '../../../../environment';
import placeholderImage from '../../../../images/placeholder.png';

import styles from './SectionItem.module.scss';
import type { BoardItemQuery } from './__generated__/BoardItemQuery.graphql';

type BoardByRowId = {
	rowId: string;
	title: string;
	description: string;
	image:
		| {
				url: string | null;
		  }
		| undefined
		| null;
};

type PassThroughProps = {
	compact?: boolean;
	removable?: boolean;
	openable?: boolean;
	onRequestRemove?: MouseEventHandler<HTMLSpanElement>;
	onRequestOpen?: MouseEventHandler<HTMLSpanElement>;
};

type BoardProps = PassThroughProps & {
	board: BoardByRowId;
};

function Board({
	board: { title, description, image },
	compact,
	removable = true,
	onRequestRemove,
	openable = true,
	onRequestOpen,
}: BoardProps) {
	const additionalProps: CardProps = {};

	if (!compact) {
		additionalProps.cover = (
			<img alt="Vorschaubild" src={image?.url ? image.url : placeholderImage} />
		);
	}

	if (removable || openable) {
		additionalProps.actions = [];

		if (removable) {
			additionalProps.actions.push(<CloseOutlined onClick={onRequestRemove} />);
		}

		if (openable) {
			additionalProps.actions.push(<EditOutlined onClick={onRequestOpen} />);
		}
	}

	return (
		<Card bodyStyle={{ padding: 0 }} {...additionalProps}>
			<div className={styles.content}>
				<b>{title}</b>
				<p>{description}</p>
			</div>
		</Card>
	);
}

type Props = PassThroughProps & {
	id: string;
	compact?: boolean;
};

export default function ({ id: boardId, ...rest }: Props) {
	return (
		<QueryRenderer<BoardItemQuery>
			environment={environment}
			query={graphql`
				query BoardItemQuery($boardId: String!) {
					board: boardByRowId(rowId: $boardId) {
						rowId
						title
						description
						image {
							url
						}
					}
				}
			`}
			variables={{ boardId }}
			render={({ error, props: queryRendererProps }) => {
				if (error) {
					return (
						<Alert
							message="Fehler"
							description={`Die Seite "${boardId}" konnte nicht geladen werden.`}
							type="error"
						/>
					);
				}
				if (queryRendererProps && queryRendererProps.board) {
					return <Board board={queryRendererProps.board} {...rest} />;
				}

				return <Card loading={true} />;
			}}
		/>
	);
}
