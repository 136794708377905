/**
 * @generated SignedSource<<9a44e4c6cf79ac135903f040f1150f9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BoardItemQuery$variables = {
  boardId: string;
};
export type BoardItemQuery$data = {
  readonly board: {
    readonly description: string;
    readonly image: {
      readonly url: string | null;
    } | null;
    readonly rowId: string;
    readonly title: string;
  } | null;
};
export type BoardItemQuery = {
  response: BoardItemQuery$data;
  variables: BoardItemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "boardId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardItemQuery",
    "selections": [
      {
        "alias": "board",
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardItemQuery",
    "selections": [
      {
        "alias": "board",
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eccb187a907bb77ba8c98afdbc56e80e",
    "id": null,
    "metadata": {},
    "name": "BoardItemQuery",
    "operationKind": "query",
    "text": "query BoardItemQuery(\n  $boardId: String!\n) {\n  board: boardByRowId(rowId: $boardId) {\n    rowId\n    title\n    description\n    image {\n      url\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c4c5855509ef6529645af1be0a1b3e4";

export default node;
