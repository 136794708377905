import { SaveOutlined } from '@ant-design/icons';
import {
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
	COLOR_SUN_FLOWER,
} from '@sep/br24-constants';
import { Button, Tooltip } from 'antd';
import styled, { type DefaultTheme } from 'styled-components';

import config from '../../../config';
import { Save } from '../../Icon';

type Status =
	| typeof STATUS_DRAFT
	| typeof STATUS_REVIEW
	| typeof STATUS_SCHEDULED
	| typeof STATUS_DEPUBLISHED
	| typeof STATUS_PUBLISHED
	| typeof STATUS_DELETED;

type Props = {
	isSaveEnabled: boolean;
	isSaving: boolean;
	isTemplate: boolean;
	onSaveClick: () => void;
	status: Status | null | undefined;
	tooltip: string;
};

type StatusButtonProps = {
	$isTemplate: boolean;
	disabled: boolean;
	$status: Status | null | undefined;
};

const getButtonStatusColor = (props: StatusButtonProps & { theme: DefaultTheme }) => {
	if (props.$isTemplate) {
		return COLOR_SUN_FLOWER;
	}

	if (props.disabled) {
		return props.theme.colors.lightAntd;
	}

	if (props.$status) {
		return config.statusTagColorMap[props.$status];
	}
	return undefined;
};

const StatusButton = styled(Button)<StatusButtonProps>`
	margin-left: 3px;
	padding: 5px;
	width: 32px;
	&:hover,
	&:focus {
		filter: ${(props: StatusButtonProps) => (props.disabled ? 'none' : 'brightness(115%)')};
	}
	&:active {
		filter: brightness(85%);
	}
	background-color: ${(props) => getButtonStatusColor(props)} !important;
	border-color: ${(props) => getButtonStatusColor(props)} !important;
	> svg {
		color: #fff;
	}
`;

function ArticleStatusSaveButton(props: Props) {
	return (
		<Tooltip title={props.tooltip} placement="bottom">
			<StatusButton
				$isTemplate={props.isTemplate}
				$status={props.status}
				type="primary"
				onClick={() => {
					if (!props.isSaving) {
						props.onSaveClick();
					}
				}}
				loading={props.isSaving}
				// hack for showing loading state
				icon={props.isSaving ? <SaveOutlined /> : null}
				disabled={!props.isSaveEnabled}
			>
				{props.isSaving ? null : <Save size="lg" />}
			</StatusButton>
		</Tooltip>
	);
}

export default ArticleStatusSaveButton;
