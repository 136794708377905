// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Recorder from '@sep/br-browser-error/Recorder';

import config from './config';

const recorder = new Recorder({
	name: 'br24-cms',
});

if (config.VITE_NODE_ENV === 'development') {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	window.errorRecorder = recorder;
}

export default recorder;
