import type { ReduxState } from '../..';
import { SUBSCRIBED_STORE_KEY } from '../config';
import type {
	BoardBuffer,
	BoardSectionBuffer,
	BoardSectionItemBuffer,
	BoardSocialMediaAccountBuffer,
} from '../types';

type GetState = () => ReduxState;

/**
 * Finds a board by its internalId.
 *
 * @param  {string} 	boardId
 * @param  {Function} getState
 * @return {null|Object}
 */
export function findBoardById(boardId: string, getState: GetState): BoardBuffer | undefined | null {
	return getState()[SUBSCRIBED_STORE_KEY].boards[boardId] || null;
}

/**
 * Finds a section by its internalId.
 *
 * @param  {string}   sectionInternalId
 * @param  {Function} getState
 * @return {null|Object}
 */
export function findSectionById(
	sectionInternalId: string,
	getState: GetState
): BoardSectionBuffer | null {
	return getState()[SUBSCRIBED_STORE_KEY].sections[sectionInternalId] || null;
}

/**
 * Finds a section item by its internalId.
 *
 * @param  {string}   sectionInternalId
 * @param  {Function} getState
 * @return {null|Object}
 */
export function findSectionItemById(
	sectionItemInternalId: string,
	getState: GetState
): BoardSectionItemBuffer | undefined | null {
	return getState()[SUBSCRIBED_STORE_KEY].sectionItems[sectionItemInternalId] || null;
}

/**
 * Finds a social media account by its internalId.
 *
 * @param  {string} socialMediaAccountInternalId
 * @param  {Function} getState
 * @return {null|Object}
 */
export function findSocialMediaAccountById(
	socialMediaAccountInternalId: string,
	getState: GetState
): BoardSocialMediaAccountBuffer | undefined | null {
	return getState()[SUBSCRIBED_STORE_KEY].socialMediaAccounts[socialMediaAccountInternalId] || null;
}

/**
 * Finds a section by `boardId` and `position`.
 *
 * @param {string} boardId
 * @param {number} position
 * @param {Function} getState
 * @return {null|Object}
 */
export function findSectionByBoardIdAndPosition(
	boardId: string,
	position: number,
	getState: GetState
): BoardSectionBuffer | undefined | null {
	const state = getState()[SUBSCRIBED_STORE_KEY];

	const [affectedSectionId] = Object.keys(state.sections).filter(
		(internalId) =>
			state.sections[internalId].__meta__.boardId === boardId &&
			state.sections[internalId].order === position
	);

	return affectedSectionId ? state.sections[affectedSectionId] : null;
}

/**
 * Finds a sectionItem by `sectionId` and `position`.
 *
 * @param {string} sectionId
 * @param {number} position
 * @param {Function} getState
 * @return {null|Object}
 */
export function findSectionItemBySectionIdAndPosition(
	sectionId: string,
	position: number,
	getState: GetState
): BoardSectionItemBuffer | undefined | null {
	const state = getState()[SUBSCRIBED_STORE_KEY];

	const [affectedSectionItemId] = Object.keys(state.sectionItems).filter(
		(internalId) =>
			state.sectionItems[internalId].__meta__.sectionId === sectionId &&
			state.sectionItems[internalId].order === position
	);

	return affectedSectionItemId ? state.sectionItems[affectedSectionItemId] : null;
}
