import * as util from '@sep/br24-util';
import moment from 'moment';
import { Component } from 'react';
import { shouldUpdate } from 'should-update';

import { displayName } from '@/client/util/br24Utils';

import { LockedByAuthorBoardsTeaser } from '../../LockedByAuthor';

import styles from './BoardsTeasersTable.module.scss';
import type { BoardsTeasersTable_query$data } from './__generated__/BoardsTeasersTable_query.graphql';

export type BoardsTeaserNode = NonNullable<
	BoardsTeasersTable_query$data['boardTeasers']
>['edges'][number]['node'];

type Props = {
	node: BoardsTeaserNode;
	onClickOpen: (node: BoardsTeaserNode) => void;
	dateKey: 'createdAt' | 'updatedAt';
};

export default class BoardsTeasersTableRow extends Component<Props> {
	shouldComponentUpdate(nextProps: Props) {
		return shouldUpdate(['node', 'onClickOpen'], this.props, nextProps);
	}

	handleOpen = () => {
		const { node, onClickOpen } = this.props;
		onClickOpen(node);
	};

	render() {
		const { node, dateKey } = this.props;

		return (
			<tr onClick={this.handleOpen}>
				<td className={styles.title}>{node?.title}</td>
				<td>{util.string.truncate(node?.description ?? '', 50)}</td>

				<td>{node && <LockedByAuthorBoardsTeaser boardsTeaser={node} />}</td>

				{node?.authorByUpdatedBy == null ? (
					<td>{node?.authorByCreatedBy && displayName(node.authorByCreatedBy)}</td>
				) : (
					<td>{node.authorByCreatedBy && displayName(node.authorByUpdatedBy)}</td>
				)}
				<td className={styles.lastUpdate}>{moment.utc(node?.[dateKey]).local().fromNow()}</td>
			</tr>
		);
	}
}
