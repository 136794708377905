import { STATUS_PUBLISHED } from '@sep/br24-constants';
import { Radio, Modal, DatePicker, TimePicker, Button } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';

import type { Status } from '@/client/containers/ArticleEditor/__generated__/ArticleEditor_article.graphql';
import { useTranslate } from '@/client/translation/useTranslate';

const SELECT_NOW = 'now';
const SELECT_LASTDATE = 'lastDate';
const SELECT_CUSTOMDATE = 'customDate';

const PubDateSelectOptions = {
	[SELECT_NOW]: SELECT_NOW,
	[SELECT_LASTDATE]: SELECT_LASTDATE,
	[SELECT_CUSTOMDATE]: SELECT_CUSTOMDATE,
};

const StyledModal = styled(Modal)`
	.ant-modal-footer button + button {
		background-color: ${(props) => props.theme.colors.stateGreen2};
		border: none;
	}

	.ant-modal-footer button + button:disabled {
		background-color: #f5f5f5;
	}
`;

const RadioGroupHeadline = styled.h3`
	margin-bottom: 0.3em;
`;

const RadioGroupWrapper = styled.div`
	margin-left: 22px;
	margin-top: 10px;
`;

const TimestampWrapper = styled.span`
	color: ${(props) => props.theme.colors.br24Light};
	margin-left: 15px;
`;

const TimePickerWrapper = styled.span`
	.ant-time-picker {
		width: 148px;
	}
`;

type State = {
	// from https://github.com/facebook/flow/issues/2377#issuecomment-347468822
	isSelected: keyof typeof PubDateSelectOptions;
	setDateByUser: string | null;
};

type Props = {
	articleStatus?: Status | null;
	currentPublicationDate: string | null;
	isVisible: boolean;
	onSave: (date: string | null) => void;
	onClose: () => void;
};

const initialState: State = {
	isSelected: SELECT_NOW,
	setDateByUser: moment().toISOString(),
};

const allTimesAvailable = (date: string | null) => {
	if (!date) {
		return true;
	}
	const dateObj = moment(date);
	const diffInDays = moment().diff(dateObj, 'days');

	return diffInDays > 0;
};

export default function ChangePubDateOverlay({
	articleStatus,
	currentPublicationDate,
	isVisible,
	onClose,
	onSave,
}: Props) {
	const translate = useTranslate();
	const [localState, setLocalState] = useState<State>(initialState);

	const radioStyle = {
		display: 'block',
		height: '30px',
		lineHeight: '30px',
	};

	const resetStateToDefault = () => {
		setLocalState(initialState);
	};

	const handleCancel = () => {
		onClose();

		resetStateToDefault();
	};

	const disabledDate = (current) => {
		// Can not select days after today and today
		if (articleStatus === STATUS_PUBLISHED) {
			return current && current > moment().endOf('day');
		}

		return null;
	};

	const disabledTime = allTimesAvailable(localState.setDateByUser)
		? {
				hours: () => [],
				minutes: () => [],
		  }
		: {
				hours: () => {
					const hours: number[] = [];

					for (let i = moment().hour() + 1; i <= 23; i += 1) {
						hours.push(i);
					}

					return hours;
				},

				minutes: () => {
					const minutes: number[] = [];

					for (let i = moment().minute() + 1; i <= 60; i += 1) {
						minutes.push(i);
					}

					return minutes;
				},
		  };

	const handleSave = () => {
		// if the user selects "jetzt" we need to reset the timestamp when the user sets
		// the now date because there is some time difference from clicking "jetzt" and hitting
		// the save button
		if (localState.isSelected === SELECT_NOW) {
			onSave(moment().toISOString());
		} else {
			onSave(localState.setDateByUser);
		}

		resetStateToDefault();
	};

	// only now / last date are changing the current pub date on select
	// when selecting custom date the user must set a date first
	// what's why there is a seperate handler for this
	const handleChangeRadioButtons = (event: any) => {
		switch (event.target.value) {
			case SELECT_NOW:
				setLocalState(initialState);
				break;

			case SELECT_LASTDATE:
				setLocalState({
					isSelected: SELECT_LASTDATE,
					setDateByUser: currentPublicationDate,
				});
				break;

			case SELECT_CUSTOMDATE:
				setLocalState({
					isSelected: SELECT_CUSTOMDATE,
					setDateByUser: moment().toISOString(),
				});
				break;

			default:
				break;
		}
	};

	// seperate handler for changing pubdate via a custom datepicker
	const handleChangePublicationDate = (date: moment.Moment | null) => {
		setLocalState({
			isSelected: SELECT_CUSTOMDATE,
			setDateByUser: date ? date.toISOString() : null,
		});
	};

	return (
		<StyledModal
			title={translate('article.changePubDateOverlay.headline')}
			open={isVisible}
			onOk={handleSave}
			onCancel={handleCancel}
			okText="Speichern"
			footer={[
				<Button key={translate('article.changePubDateOverlay.cancelButton')} onClick={handleCancel}>
					{translate('article.changePubDateOverlay.cancelButton')}
				</Button>,
				<Button
					key={translate('article.changePubDateOverlay.saveButton')}
					type="primary"
					disabled={localState.isSelected === SELECT_CUSTOMDATE && !localState.setDateByUser}
					onClick={handleSave}
				>
					{translate('article.changePubDateOverlay.saveButton')}
				</Button>,
			]}
		>
			<RadioGroupHeadline>
				{translate('article.changePubDateOverlay.select.headline')}
			</RadioGroupHeadline>
			<Radio.Group onChange={handleChangeRadioButtons} value={localState.isSelected}>
				<Radio style={radioStyle} value={SELECT_NOW}>
					{translate('article.changePubDateOverlay.select.now')}
				</Radio>
				<Radio style={radioStyle} value={SELECT_LASTDATE}>
					{translate('article.changePubDateOverlay.select.lastDate')}
					<TimestampWrapper>{` ${moment(currentPublicationDate).format(
						'DD.MM.YYYY, HH:mm'
					)} Uhr`}</TimestampWrapper>
				</Radio>
				<Radio style={radioStyle} value={SELECT_CUSTOMDATE}>
					{translate('article.changePubDateOverlay.select.custom')}
				</Radio>
				{localState.isSelected === SELECT_CUSTOMDATE ? (
					<RadioGroupWrapper>
						<DatePicker
							allowClear={false}
							format="DD.MM.YYYY"
							disabledDate={disabledDate}
							onChange={handleChangePublicationDate}
						/>
						<TimePickerWrapper>
							<TimePicker
								allowClear={false}
								disabledHours={disabledTime.hours}
								disabledMinutes={disabledTime.minutes}
								format="HH:mm"
								onChange={handleChangePublicationDate}
								value={
									localState.isSelected === SELECT_CUSTOMDATE && localState.setDateByUser
										? moment(localState.setDateByUser)
										: null
								}
							/>
						</TimePickerWrapper>
					</RadioGroupWrapper>
				) : null}
			</Radio.Group>
		</StyledModal>
	);
}
