import { MODULE_TYPE_AUDIO } from '@sep/br24-constants';
import { Component } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { withTranslation, type WithTranslationProps } from '@/client/translation/withTranslation';

import config from '../../../config';
import type { Validation } from '../../../containers/ArticleEditor';
import SVG from '../../SVG';
import { ModulePartInput } from '../ArticleModuleParts';

import { ReactComponent as AudioPlayerSvg } from './audioplayer.svg';

const TEASERTEXT_LENGTH_MAX = config.articleModules.audio.teasertextMaxLength;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const TeasertextInput = styled(ModulePartInput)`
	margin-top: 8px;
	.label-wrapper {
		min-width: 76px;
	}
`;
const CopyrightInput = styled(ModulePartInput)`
	margin-top: 8px;
	.label-wrapper {
		min-width: 76px;
	}
`;

const AudioPlayerSvgStyled = styled(AudioPlayerSvg)`
	.st0 {
		fill: #efefef;
	}
	.st1 {
		fill: #b2b2b2;
	}
	.st2 {
		fill: #0a9ed7;
	}
	.st3 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #b2b2b2;
	}
	.st4 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #0a9ed7;
	}
	.st5 {
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #0a9ed7;
		stroke: #efefee;
		stroke-miterlimit: 10;
	}
	.st6 {
		fill: #1d2028;
	}
	.st7 {
		fill: none;
	}
	.st8 {
		font-family: 'OpenSans-Semibold';
	}
	.st9 {
		font-size: 8.3478px;
	}
`;

const AudioImage = styled(SVG)`
	width: 100%;
	max-width: 600px;
`;

interface ImapePreviewWrapperProps {
	isThumbnailUploading?: boolean;
}

const ImagePreviewWrapper = styled.div<ImapePreviewWrapperProps>`
	margin-top: 14px;
	filter: ${({ isThumbnailUploading }) => (isThumbnailUploading ? 'blur(5px)' : '')};
`;

interface Props extends WithTranslationProps {
	copyright?: string | null;
	children: ReactNode;
	duration?: number | null;
	title?: string | null;
	url?: string | null;
	validation?: Validation | null;
	isThumbnailUploading?: boolean;
	onChange: (id: string, value: string) => void;
	onChangeThumbnail: (id: 'url' | 'title' | 'altText' | 'copyright', value: string) => void;
}

type State = {
	isAudioEnabled: boolean;
	isAudioRendered: boolean;
};

export class ArticleModuleAudio extends Component<Props, State> {
	state = {
		isAudioEnabled: false,
		isAudioRendered: false,
	};

	handleChangeCopyright = (value: string) => {
		this.props.onChange('copyright', value);
	};

	handleChangeTitle = (value: string) => {
		this.props.onChange('title', value);
	};

	renderAudioPlayer() {
		const { url } = this.props;
		const { isAudioEnabled, isAudioRendered } = this.state;

		if (url && isAudioEnabled) {
			return (
				<audio
					ref={(audioPlayer) => {
						if (audioPlayer && typeof audioPlayer.play === 'function' && !isAudioRendered) {
							audioPlayer.play();
							this.setState({ isAudioRendered: true });
						}
					}}
					src={url}
					controls={true}
					style={{ width: '100%' }}
				/>
			);
		}
		if (url && !isAudioEnabled) {
			return (
				<button onClick={() => this.setState({ isAudioEnabled: true })}>
					<AudioImage>
						<AudioPlayerSvgStyled />
					</AudioImage>
				</button>
			);
		}

		return null;
	}

	render() {
		const {
			title,
			copyright,
			validation,
			isThumbnailUploading,
			children,
			translation: { translate },
			onChange,
			...rest
		} = this.props;

		const titleHelp = translate(`modules.${MODULE_TYPE_AUDIO}.titleHelp`, {
			count: title ? `${title.length}` : 0,
			max: TEASERTEXT_LENGTH_MAX,
		});

		const audioPlayer = this.renderAudioPlayer();

		return (
			<Wrapper>
				{audioPlayer}
				<TeasertextInput
					help={titleHelp}
					label={translate(`modules.${MODULE_TYPE_AUDIO}.titleLabel`)}
					onChange={this.handleChangeTitle}
					placeholder={translate(`modules.${MODULE_TYPE_AUDIO}.titlePlaceholder`)}
					value={title}
					isTextArea={true}
					validationError={validation ? (validation['audio.title'] as unknown as string) : ''}
					{...rest}
				/>
				<CopyrightInput
					label={translate(`modules.${MODULE_TYPE_AUDIO}.copyrightLabel`)}
					onChange={this.handleChangeCopyright}
					placeholder={translate(`modules.${MODULE_TYPE_AUDIO}.copyrightPlaceholder`)}
					value={copyright}
					validationError={validation ? (validation['audio.copyright'] as unknown as string) : ''}
					{...rest}
				/>
				<ImagePreviewWrapper isThumbnailUploading={isThumbnailUploading}>
					{children}
				</ImagePreviewWrapper>
			</Wrapper>
		);
	}
}

export default withTranslation(ArticleModuleAudio);
