import { USER_SETTINGS_UPDATE, USER_ADD_FLAG, USER_DELETE_FLAG } from '@sep/br24-constants';
import ih from 'immutability-helper';

import { generateReducer } from '@/client/util/generateReducer';

const STORAGE_KEY = 'br-user';

export type UserSettings = {
	articleAutoSave: boolean;
	autoRefreshIn: number;
	boardAutoValidation: boolean;
};

export type UserFlags = Array<string>;

export type State = {
	settings: UserSettings;
	flags: UserFlags;
};

export type UpdateSettingsPayload = {
	articleAutoSave?: boolean;
	autoRefreshIn?: number;
	boardAutoValidation?: boolean;
};

type UserConfigUpdateAction = {
	type: typeof USER_SETTINGS_UPDATE;
	payload: UpdateSettingsPayload;
};

type UserAddFlagAction = {
	type: typeof USER_ADD_FLAG;
	payload: string;
};

type UserDeleteFlagAction = {
	type: typeof USER_DELETE_FLAG;
	payload: string;
};

let initialState: State = {
	settings: {
		articleAutoSave: false,
		autoRefreshIn: 60000,
		boardAutoValidation: true,
	},
	flags: [],
};

if (localStorage[STORAGE_KEY]) {
	try {
		const currentlyStored = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}');
		initialState = {
			...initialState,
			...currentlyStored,
			settings: {
				...initialState.settings,
				...(currentlyStored.settings || {}),
			},
		};
	} catch (err) {
		const causedBy = err instanceof Error ? ` (caused by: ${err.message})` : '';
		// eslint-disable-next-line no-console
		console.error(`Failed to load localStorage data${causedBy}`);
	}
}

export default generateReducer(
	initialState,
	{
		[USER_SETTINGS_UPDATE]: (state, action: UserConfigUpdateAction) =>
			ih(state, {
				settings: {
					$merge: action.payload,
				},
			}),
		[USER_ADD_FLAG]: (state, action: UserAddFlagAction) =>
			ih(state, {
				flags: {
					$apply: (flags: UserFlags) => {
						if (!flags.includes(action.payload)) {
							return [action.payload, ...flags];
						}

						return [...flags];
					},
				},
			}),
		[USER_DELETE_FLAG]: (state, action: UserDeleteFlagAction) =>
			ih(state, {
				flags: {
					$apply: (flags: UserFlags) => flags.filter((flag) => flag !== action.payload),
				},
			}),
	},
	(nextState: State) => {
		localStorage.setItem(STORAGE_KEY, JSON.stringify(nextState));
	}
);

export function addFlag(flag: string): UserAddFlagAction {
	return {
		type: USER_ADD_FLAG,
		payload: flag,
	};
}

export function deleteFlag(flag: string): UserDeleteFlagAction {
	return {
		type: USER_DELETE_FLAG,
		payload: flag,
	};
}

export function updateSettings(settings: UpdateSettingsPayload): UserConfigUpdateAction {
	return {
		type: USER_SETTINGS_UPDATE,
		payload: settings,
	};
}
