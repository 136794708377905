import { Component } from 'react';

type State = {
	talkIsAvailable: boolean;
};

class Comments extends Component<Record<string, unknown>, State> {
	state = {
		talkIsAvailable: true,
	};

	componentDidMount() {
		const talk = this.getTalkInstance();

		if (talk) {
			window.location.href = talk;
		}
	}

	getTalkInstance = () => {
		const location = window.location.href;

		if (location.includes('gcp-test')) {
			return 'https://comments.gcp-test.br24.br-dev.de';
		}

		if (location.includes('dev')) {
			return 'https://comments.dev.br24.br-dev.de';
		}

		if (location.includes('staging') || location.includes('qs') || location.includes('qa')) {
			return 'https://comments.staging.br24.br-staging.de';
		}

		if (location.includes('newsroom.br24')) {
			return 'https://comments.br24.de';
		}

		if (location.includes('localhost') || location.includes('127.0.0.1')) {
			return 'http://127.0.0.1:6611';
		}

		return 'https://comments.dev.br24.br-dev.de';
	};

	render() {
		const output = this.state.talkIsAvailable ? (
			<div>Weiterleitung zum Kommentar-Tool...</div>
		) : (
			<div>Kommentar-Tool ist nicht verfügbar.</div>
		);

		return output;
	}
}

export default Comments;
