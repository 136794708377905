export const AM_BOARD_TEASER = 'AM_DD_ITEM_TYPE_BOARD_TEASER';
export const AM_BOARD = 'AM_DD_ITEM_TYPE_BOARD';
export const AM_ARTICLE = 'AM_DD_ITEM_TYPE_ARTICLE';
export const AM_TEXT = 'AM_DD_ITEM_TYPE_TEXT';
export const AM_LINK = 'AM_DD_ITEM_TYPE_LINK';
export const AM_EMBED_CODE = 'AM_DD_ITEM_TYPE_EMBED_CODE';
export const AM_GALLERY = 'AM_DD_ITEM_TYPE_GALLERY';
export const AM_BRBILD_IMAGE = 'AM_DD_ITEM_TYPE_BRBILD_IMAGE';
export const AM_IMAGE = 'AM_DD_ITEM_TYPE_IMAGE';
export const AM_AUDIO = 'AM_DD_ITEM_TYPE_AUDIO';
export const AM_VIDEO360 = 'AM_DD_ITEM_TYPE_VIDEO360';
export const AM_VIDEO = 'AM_DD_ITEM_TYPE_VIDEO';
export const AM_LIVESTREAM = 'AM_DD_ITEM_TYPE_LIVESTREAM';

// all types together
export default {
	AM_ARTICLE,
	AM_BOARD,
	AM_BOARD_TEASER,
	AM_TEXT,
	AM_LINK,
	AM_EMBED_CODE,
	AM_GALLERY,
	AM_BRBILD_IMAGE,
	AM_IMAGE,
	AM_AUDIO,
	AM_VIDEO360,
	AM_VIDEO,
	AM_LIVESTREAM,
} as const;
