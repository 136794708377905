/**
 * @generated SignedSource<<56d6163a3b737a7ac13127ac2d93f518>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Capability = "BASIC" | "FULLY_FEATURED" | "TEXT_ONLY";
export type District = "MITTELFRANKEN" | "NIEDERBAYERN" | "OBERBAYERN" | "OBERFRANKEN" | "OBERPFALZ" | "SCHWABEN" | "UNTERFRANKEN";
export type Expiration = "EDUCATION" | "EPG_BUSINESS_CONTENT" | "REPEATED_EVENTS" | "SERIES" | "SUPPORTING_INFO" | "TIME_CULTURE";
export type ModuleType = "AUDIO" | "EMBED" | "GALLERY" | "IMAGE" | "LIVESTREAM" | "TEXT" | "VIDEO" | "VIDEO360";
export type Priority = "CRITICAL" | "HIGH" | "LOW" | "NORMAL";
export type PushNotification = "NONE" | "SILENT" | "SOUND";
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type TagsStatus = "ACTIVE" | "RECOMMENDED" | "SPECIAL" | "SUPERSEDED";
export type VideoResolution = "FULL_HD" | "HLS_HIGH" | "HLS_LOW" | "UHD";
import { FragmentRefs } from "relay-runtime";
export type ArticleEditor_article$data = {
  readonly allowComments: boolean;
  readonly articlesAuthorsByArticleId: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly articleId: string;
        readonly authorByAuthorGuid: {
          readonly firstname: string | null;
          readonly lastname: string | null;
        } | null;
        readonly authorGuid: string;
        readonly order: number | null;
      } | null;
    }>;
  };
  readonly articlesTagsByArticleId: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly tagByTagId: {
          readonly count: number;
          readonly rowId: number;
          readonly status: TagsStatus | null;
          readonly text: string;
        } | null;
      } | null;
    }>;
  };
  readonly authorByLockedBy: {
    readonly firstname: string | null;
    readonly guid: string;
    readonly lastname: string | null;
  } | null;
  readonly authorByRevisionBy: {
    readonly firstname: string | null;
    readonly lastname: string | null;
  } | null;
  readonly authorsDescription: string | null;
  readonly boardsSectionsItemsByArticleId: {
    readonly totalCount: number;
  };
  readonly canonicalUrl: string | null;
  readonly capability: Capability;
  readonly districts: ReadonlyArray<District | null> | null;
  readonly enableAmp: boolean;
  readonly expirationAt: string | null;
  readonly expirationTemplate: Expiration | null;
  readonly externalId: string | null;
  readonly geolocation: string | null;
  readonly headline: string | null;
  readonly historiesByArticleId: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: string;
        readonly data: any;
        readonly rowId: number;
      } | null;
    }>;
    readonly totalCount: number;
  };
  readonly id: string;
  readonly invalidLinks: ReadonlyArray<{
    readonly label: string | null;
    readonly url: string | null;
  } | null> | null;
  readonly isImported: boolean;
  readonly isLive: boolean;
  readonly isTemplate: boolean;
  readonly language: string;
  readonly lastEdited: string;
  readonly links: ReadonlyArray<{
    readonly article: {
      readonly rowId: string;
      readonly status: Status;
    } | null;
    readonly board: {
      readonly rowId: string;
      readonly status: Status | null;
    } | null;
    readonly label: string | null;
    readonly meta: {
      readonly date: string | null;
      readonly description: string | null;
      readonly originalThumbnail: string | null;
      readonly source: string;
      readonly thumbnail: string | null;
      readonly title: string;
      readonly url: string;
    } | null;
    readonly url: string | null;
  } | null> | null;
  readonly location: any | null;
  readonly lockedBy: string | null;
  readonly lockedSince: string | null;
  readonly metaDescription: string | null;
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly articleId: string;
        readonly audio: {
          readonly copyright: string | null;
          readonly duration: number | null;
          readonly thumbnail: {
            readonly altText: string | null;
            readonly copyright: string | null;
            readonly title: string | null;
            readonly url: string | null;
          } | null;
          readonly title: string | null;
          readonly url: string | null;
        } | null;
        readonly embed: {
          readonly altText: string | null;
          readonly service: string | null;
          readonly source: string | null;
          readonly thumbnail: {
            readonly altText: string | null;
            readonly copyright: string | null;
            readonly title: string | null;
            readonly url: string | null;
          } | null;
          readonly url: string | null;
        } | null;
        readonly gallery: ReadonlyArray<{
          readonly altText: string | null;
          readonly copyright: string | null;
          readonly title: string | null;
          readonly url: string | null;
        } | null> | null;
        readonly id: string;
        readonly image: {
          readonly altText: string | null;
          readonly copyright: string | null;
          readonly title: string | null;
          readonly url: string | null;
        } | null;
        readonly livestream: {
          readonly livestreamId: string | null;
          readonly programmeId: string | null;
          readonly thumbnail: {
            readonly altText: string | null;
            readonly copyright: string | null;
            readonly title: string | null;
            readonly url: string | null;
          } | null;
        } | null;
        readonly meta: any | null;
        readonly order: number | null;
        readonly rowId: number;
        readonly text: string | null;
        readonly type: ModuleType | null;
        readonly video: {
          readonly copyright: string | null;
          readonly duration: number | null;
          readonly thumbnail: {
            readonly altText: string | null;
            readonly copyright: string | null;
            readonly title: string | null;
            readonly url: string | null;
          } | null;
          readonly title: string | null;
          readonly url: string | null;
        } | null;
        readonly video360: {
          readonly copyright: string | null;
          readonly duration: number | null;
          readonly sources: ReadonlyArray<{
            readonly resolution: VideoResolution | null;
            readonly url: string | null;
          } | null> | null;
          readonly thumbnail: {
            readonly altText: string | null;
            readonly copyright: string | null;
            readonly title: string | null;
            readonly url: string | null;
          } | null;
          readonly title: string | null;
        } | null;
      } | null;
    }>;
    readonly totalCount: number;
  };
  readonly notes: string | null;
  readonly otherCategories: ReadonlyArray<string | null> | null;
  readonly primaryCategory: string;
  readonly publicationDate: string | null;
  readonly publicationPriority: Priority;
  readonly pushNotification: PushNotification;
  readonly rawLinks: ReadonlyArray<{
    readonly article: {
      readonly status: Status;
    } | null;
    readonly board: {
      readonly status: Status | null;
    } | null;
    readonly label: string | null;
    readonly url: string | null;
  } | null> | null;
  readonly redirectionsByArticleId: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly articleId: string;
        readonly id: string;
        readonly rowId: number;
        readonly sophoraId: string;
      } | null;
    }>;
  };
  readonly rowId: string;
  readonly seoTitle: string | null;
  readonly shareUrl: string;
  readonly shortUrl: string;
  readonly slug: string;
  readonly sourceBroadcastDate: string | null;
  readonly sourceOrigin: string | null;
  readonly status: Status;
  readonly tags: ReadonlyArray<string | null> | null;
  readonly teaserText: string | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"LockHandling_article">;
  readonly " $fragmentType": "ArticleEditor_article";
};
export type ArticleEditor_article$key = {
  readonly " $data"?: ArticleEditor_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleEditor_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "articleId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "altText",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "copyright",
  "storageKey": null
},
v10 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v2/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ModuleImage",
  "kind": "LinkedField",
  "name": "thumbnail",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = [
  (v7/*: any*/),
  (v9/*: any*/),
  (v2/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v17 = [
  (v3/*: any*/),
  (v1/*: any*/)
],
v18 = [
  (v3/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v21 = [
  (v19/*: any*/),
  (v20/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleEditor_article",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pushNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastEdited",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teaserText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "meta",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleImage",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleAudio",
                  "kind": "LinkedField",
                  "name": "audio",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleVideo",
                  "kind": "LinkedField",
                  "name": "video",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleLivestream",
                  "kind": "LinkedField",
                  "name": "livestream",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "livestreamId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "programmeId",
                      "storageKey": null
                    },
                    (v12/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleVideo360",
                  "kind": "LinkedField",
                  "name": "video360",
                  "plural": false,
                  "selections": [
                    (v9/*: any*/),
                    (v2/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "VideoSource",
                      "kind": "LinkedField",
                      "name": "sources",
                      "plural": true,
                      "selections": [
                        (v7/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "resolution",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleEmbed",
                  "kind": "LinkedField",
                  "name": "embed",
                  "plural": false,
                  "selections": [
                    (v14/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "service",
                      "storageKey": null
                    },
                    (v8/*: any*/),
                    (v7/*: any*/),
                    (v12/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ModuleImage",
                  "kind": "LinkedField",
                  "name": "gallery",
                  "plural": true,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                },
                (v15/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicationPriority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAmp",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "links",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v16/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Meta",
          "kind": "LinkedField",
          "name": "meta",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            (v14/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originalThumbnail",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Article",
          "kind": "LinkedField",
          "name": "article",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Board",
          "kind": "LinkedField",
          "name": "board",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "rawLinks",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v16/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Article",
          "kind": "LinkedField",
          "name": "article",
          "plural": false,
          "selections": (v18/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Board",
          "kind": "LinkedField",
          "name": "board",
          "plural": false,
          "selections": (v18/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "invalidLinks",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v16/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorsDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authorByRevisionBy",
      "plural": false,
      "selections": (v21/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "ORDER_ASC"
        }
      ],
      "concreteType": "ArticlesAuthorsConnection",
      "kind": "LinkedField",
      "name": "articlesAuthorsByArticleId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticlesAuthorsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticlesAuthor",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "authorGuid",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "authorByAuthorGuid",
                  "plural": false,
                  "selections": (v21/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "articlesAuthorsByArticleId(first:20,orderBy:\"ORDER_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RedirectionsConnection",
      "kind": "LinkedField",
      "name": "redirectionsByArticleId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RedirectionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Redirection",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sophoraId",
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000
        }
      ],
      "concreteType": "ArticlesTagsConnection",
      "kind": "LinkedField",
      "name": "articlesTagsByArticleId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticlesTagsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticlesTag",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tag",
                  "kind": "LinkedField",
                  "name": "tagByTagId",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "count",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v15/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "articlesTagsByArticleId(first:1000)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceOrigin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceBroadcastDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "otherCategories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "geolocation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "districts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metaDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowComments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTemplate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isImported",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expirationTemplate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 2
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED_AT_DESC"
        }
      ],
      "concreteType": "HistoriesConnection",
      "kind": "LinkedField",
      "name": "historiesByArticleId",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "HistoriesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "History",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "data",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "historiesByArticleId(first:2,orderBy:\"CREATED_AT_DESC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardsSectionsItemsConnection",
      "kind": "LinkedField",
      "name": "boardsSectionsItemsByArticleId",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authorByLockedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "guid",
          "storageKey": null
        },
        (v19/*: any*/),
        (v20/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lockedSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canonicalUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LockHandling_article"
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "01652e31e6a1435e2427c4a11190cbaa";

export default node;
