import styled from 'styled-components';

import SVG from '../SVG';

import MapMarkerSvg from './MapMarkerSvg';

type Props = {
	lat: number;
	lng: number;
};

const Wrapper = styled.div`
	position: relative;
	width: 20px;
	height: 30px;
	left: -10px;
	top: -30px;
`;

const Icon = styled(SVG)`
	min-width: 20px;
`;

export default function Marker({
	// lat & lng are used by the google-maps integration
	lat: _lat,
	lng: _lng,
}: Props) {
	return (
		<Wrapper>
			<MapMarkerSvg />
			<Icon />
		</Wrapper>
	);
}
