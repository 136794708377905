import {
	BOARD_BUFFER_VALIDATE,
	BOARD_BUFFER_ERROR,
	BOARD_BUFFER_INITIALIZE,
	BOARD_BUFFER_INITIALIZE_AND_CLEAN,
	BOARD_BUFFER_CLEAN,
	BOARD_BUFFER_UPDATE,
	BOARD_BUFFER_SECTION_ADD,
	BOARD_BUFFER_SECTION_UPDATE,
	BOARD_BUFFER_SECTION_DELETE,
	BOARD_BUFFER_SECTION_REBUILD_ORDER,
	BOARD_BUFFER_SECTION_ITEM_ADD,
	BOARD_BUFFER_SECTION_ITEM_UPDATE,
	BOARD_BUFFER_SECTION_ITEM_DELETE,
	BOARD_BUFFER_SECTION_ITEM_ORDER,
	BOARD_BUFFER_SOCIAL_MEDIA_ADD,
	BOARD_BUFFER_SOCIAL_MEDIA_UPDATE,
	BOARD_BUFFER_SOCIAL_MEDIA_DELETE,
	BOARD_BUFFER_SECTION_ORDER,
	BOARD_BUFFER_PERSIST,
	STATUS_REQUEST,
	STATUS_ERROR,
	STATUS_SUCCESS,
} from '@sep/br24-constants';
import { Context, type Spec, type CustomCommands } from 'immutability-helper';

import { generateReducer } from '@/client/util/generateReducer';

import type { AddItemToSectionAction } from './actions/addItemToSection';
import type { AddSectionAction } from './actions/addSection';
import type { AddSocialMediaAccountAction } from './actions/addSocialMediaAccount';
import type { CleanAction } from './actions/clean';
import type { DeleteItemInSectionAction } from './actions/deleteItemInSection';
import type { DeleteSectionAction, RebuildSectionOrderAction } from './actions/deleteSection';
import type { DeleteSocialMediaAccountAction } from './actions/deleteSocialMediaAccount';
import type { InitializeAction } from './actions/initialize';
import type { InitializeAndCleanAction } from './actions/initializeAndClean';
import type { OrderSectionAction, OrderSectionItemAction } from './actions/ordering';
import type {
	PersistActionRequest,
	PersistActionSuccess,
	PersistActionError,
} from './actions/persist';
import type { ErrorAction } from './actions/setError';
import type { UpdateAction } from './actions/update';
import type { UpdateItemInSectionAction } from './actions/updateItemInSection';
import type { UpdateSectionAction } from './actions/updateSection';
import type { UpdateSocialMediaAccountAction } from './actions/updateSocialMediaAccount';
import type { ValidateAction } from './actions/validate';
import type {
	State,
	BoardSectionReference,
	BoardSectionBuffer,
	BoardSocialMediaAccountReference,
	BoardBuffer,
	BoardSectionItemBuffer,
	BoardSocialMediaAccountBuffer,
} from './types';
import { sectionSchemaSlotCountMapping } from './util/validators/section';

const extendedImmutableUpdate = new Context();

extendedImmutableUpdate.extend<{ [key: string]: unknown }>(
	'$delete',
	(keys: Array<string>, original) => ({
		...Object.keys(original).reduce((accumulator, key) => {
			if (!keys.includes(key)) {
				accumulator[key] = original[key];
			}

			return accumulator;
		}, {}),
	})
);

type DeleteFromObject = { $delete: any };

export function immutableUpdate<T>(object: T, spec: Spec<T, CustomCommands<DeleteFromObject>>) {
	return extendedImmutableUpdate.update<T, CustomCommands<DeleteFromObject>>(object, spec);
}

const initialState: State = {
	isWorkingByBoard: {},
	latestErrorByBoard: {},
	latestErrorBySection: {},
	savableByBoard: {},

	boards: {},
	sections: {},
	sectionItems: {},
	socialMediaAccounts: {},

	raw: {},
};

export default generateReducer(initialState, {
	[BOARD_BUFFER_INITIALIZE]: (state: State, action: InitializeAction): State =>
		immutableUpdate(state, {
			boards: { [action.payload.board.rowId]: { $set: action.payload.board } },
			sections: action.payload.sections.reduce(
				(accumulator, section) => ({
					...accumulator,
					[section.__meta__.internalId]: { $set: section },
				}),
				{}
			),
			sectionItems: action.payload.sectionItems.reduce(
				(accumulator, sectionItem) => ({
					...accumulator,
					[sectionItem.__meta__.internalId]: { $set: sectionItem },
				}),
				{}
			),
			socialMediaAccounts: action.payload.socialMediaAccounts.reduce(
				(accumulator, socialMediaAccount) => ({
					...accumulator,
					[socialMediaAccount.__meta__.internalId]: { $set: socialMediaAccount },
				}),
				{}
			),
			raw: { [action.payload.board.rowId]: { $set: action.payload.raw } },
		}),
	[BOARD_BUFFER_CLEAN]: (state: State, action: CleanAction): State =>
		immutableUpdate(state, {
			...(() => {
				const boardId = action.constraint.boardId;
				if (boardId) {
					return {
						boards: {
							$apply: (boards: BoardBuffer) => {
								const nextBoards = { ...boards };
								delete nextBoards[boardId];

								return nextBoards;
							},
						},
					};
				}

				return {};
			})(),
			sections: {
				$apply: (sections: { [key: string]: BoardSectionBuffer }) => {
					const nextSections = { ...sections };
					action.constraint.sectionIds.forEach((sectionId) => delete nextSections[sectionId]);

					return nextSections;
				},
			},
			sectionItems: {
				$apply: (sectionItems: { [key: string]: BoardSectionItemBuffer }) => {
					const nextSectionItems = { ...sectionItems };
					action.constraint.sectionItemIds.forEach(
						(sectionItemId) => delete nextSectionItems[sectionItemId]
					);

					return nextSectionItems;
				},
			},
			socialMediaAccounts: {
				$apply: (socialMediaAccounts: { [key: string]: BoardSocialMediaAccountBuffer }) => {
					const nextSocialMediaAccounts = { ...socialMediaAccounts };
					action.constraint.socialMediaAccountIds.forEach(
						(socialMediaAccountId) => delete nextSocialMediaAccounts[socialMediaAccountId]
					);

					return nextSocialMediaAccounts;
				},
			},
		}),
	[BOARD_BUFFER_INITIALIZE_AND_CLEAN]: (state: State, action: InitializeAndCleanAction): State => {
		const newState = immutableUpdate(state, {
			boards: { [action.payload.board.rowId]: { $set: action.payload.board } },
			sections: action.payload.sections.reduce(
				(accumulator, section) => ({
					...accumulator,
					[section.__meta__.internalId]: { $set: section },
				}),
				{
					...((action.constraint ? { $delete: action.constraint.sectionIds } : {}) as {
						$delete: string[];
					}),
				}
			),
			sectionItems: action.payload.sectionItems.reduce(
				(accumulator, sectionItem) => ({
					...accumulator,
					[sectionItem.__meta__.internalId]: { $set: sectionItem },
				}),
				{
					...((action.constraint ? { $delete: action.constraint.sectionItemIds } : {}) as {
						$delete: string[];
					}),
				}
			),
			socialMediaAccounts: action.payload.socialMediaAccounts.reduce(
				(accumulator, socialMediaAccount) => ({
					...accumulator,
					[socialMediaAccount.__meta__.internalId]: { $set: socialMediaAccount },
				}),
				{
					...((action.constraint ? { $delete: action.constraint.socialMediaAccountIds } : {}) as {
						$delete: string[];
					}),
				}
			),
			raw: { [action.payload.board.rowId]: { $set: action.payload.raw } },
		});

		// insert null items for empty sectionsItems into sections.items[]
		return immutableUpdate(newState, {
			sections: (Object.entries(newState.sections) || []).reduce(
				(accumulator, [internalId, section]: [string, BoardSectionBuffer]) => {
					// create a new map as big as the section has slots and initalize them with null
					const newItemsMap = Array(sectionSchemaSlotCountMapping[section.schema]).fill(null);

					if (section.items) {
						section.items.forEach((item) => {
							if (item) {
								const { order } = newState.sectionItems[item.__internalId__];

								// when a slot is already set by a previous item, we take the item of the slot
								// and set it to delete. The item which comes afterwards is newer and is used instead.
								if (newItemsMap[order]) {
									const deleteSlotItemId = newItemsMap[order].__internalId__;
									newState.sectionItems[deleteSlotItemId].__meta__.deleted = true;
								}

								newItemsMap[order] = item;
							}
						});
					}

					return {
						...accumulator,
						[internalId]: {
							items: { $set: Object.values(newItemsMap) },
						},
					};
				},
				{}
			),
		});
	},
	[BOARD_BUFFER_ERROR]: (state: State, action: ErrorAction): State =>
		immutableUpdate(state, {
			latestErrorByBoard: {
				[action.constraint.boardId]: {
					$set: action.payload,
				},
			},
		}),
	[BOARD_BUFFER_UPDATE]: (state: State, action: UpdateAction): State =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			boards: {
				[action.constraint.boardId]: {
					$merge: action.payload as BoardBuffer,
					__meta__: {
						$merge: {
							updated: true,
						},
					},
				},
			},
		}),
	[BOARD_BUFFER_SECTION_ADD]: (state: State, action: AddSectionAction): State => {
		const { order } = action.payload;
		let newOrder: Spec<BoardSectionReference[], CustomCommands<DeleteFromObject>> | null = null;

		if (order) {
			newOrder = {
				$splice: [
					[
						order,
						0,
						{
							__internalId__: action.payload.sectionId,
							items: [],
						},
					],
				],
			};
		} else {
			newOrder = {
				$push: [
					{
						__internalId__: action.payload.sectionId,
						items: [],
					},
				],
			};
		}

		return immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			sections: {
				[action.payload.sectionId]: {
					$set: {
						__meta__: {
							internalId: action.payload.sectionId,
							boardId: action.constraint.boardId,
							latestValidation: null,
							created: true,
							updated: false,
							deleted: false,
						},
						rowId: null,
						schema: action.payload.schema,
						categoryId: null,
						title: null,
						color: null,
						items: Array(sectionSchemaSlotCountMapping[action.payload.schema]).fill(null),
						autofill: false,
						showHeroTitleForMobile: false,
						boardLink: null,
						meta: {},
						order: action.payload.order,
					},
				},
			},
			boards: {
				[action.constraint.boardId]: {
					sections: newOrder,
				},
			},
		});
	},
	[BOARD_BUFFER_SECTION_DELETE]: (state: State, action: DeleteSectionAction): State =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			boards: {
				[action.constraint.boardId]: {
					sections: {
						$apply: (sections: Array<BoardSectionReference>) =>
							sections.filter((section) => section.__internalId__ !== action.constraint.sectionId),
					},
				},
			},
			sections: {
				[action.constraint.sectionId]: {
					__meta__: {
						deleted: {
							$set: true,
						},
					},
				},
			},
			sectionItems: {
				...action.constraint.sectionItemIds.reduce(
					(accumulator, sectionItemId) => ({
						...accumulator,
						[sectionItemId]: {
							__meta__: {
								deleted: {
									$set: true,
								},
							},
						},
					}),
					{}
				),
			},
		}),
	[BOARD_BUFFER_SECTION_REBUILD_ORDER]: (state: State, action: RebuildSectionOrderAction): State =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			sections: {
				$apply: (sections: { [key: string]: BoardSectionBuffer }) => {
					const nextSections = { ...sections };
					const currentOrder = state.boards[action.constraint.boardId].sections;

					currentOrder.forEach((item, index) => {
						const { __internalId__: id } = item;

						if (nextSections[id].order !== index) {
							nextSections[id].order = index;
							nextSections[id].__meta__.updated = true;
						}
					});

					return nextSections;
				},
			},
		}),
	[BOARD_BUFFER_SECTION_UPDATE]: (state: State, action: UpdateSectionAction): State =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			sections: {
				[action.constraint.sectionId]: {
					$merge: action.payload,
					__meta__: {
						updated: {
							$set: true,
						},
					},
				},
			},
		}),
	[BOARD_BUFFER_SECTION_ITEM_ADD]: (state: State, action: AddItemToSectionAction): State =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			sectionItems: {
				[action.payload.sectionItemId]: {
					$set: {
						__meta__: {
							internalId: action.payload.sectionItemId,
							boardId: action.constraint.boardId,
							sectionId: action.constraint.sectionId,
							latestValidation: null,
							created: true,
							updated: false,
							deleted: false,
						},
						rowId: null,
						articleId: action.payload.articleId || null,
						boardsTeaserId: action.payload.boardsTeaserId || null,
						boardId: action.payload.boardId || null,
						order: action.payload.order,
					},
				},
			} as
				| Spec<{ [key: string]: BoardSectionItemBuffer }, CustomCommands<DeleteFromObject>>
				| undefined,
			sections: {
				[action.constraint.sectionId]: {
					items: {
						// inside boardBuffer.sections.items are null values as placeholders for empty slots
						$splice: [
							[
								action.payload.order,
								// when value is undefined splice 1, if there is a value already take value 0
								// in order not to splice the item but insert it on this position
								!state.sections[action.constraint.sectionId].items[action.payload.order] ? 1 : 0,
								// add our new item instead
								{
									__internalId__: action.payload.sectionItemId,
								},
							],
						],
					},
				},
			},
			boards: {
				[action.constraint.boardId]: {
					sections: {
						$apply: (sections: Array<BoardSectionReference>) =>
							sections.map((section) => {
								if (section.__internalId__ !== action.constraint.sectionId) {
									return section;
								}

								return {
									...section,
									// inside boardBuffer.boards.sections.items we have no null values as placeholdes
									items: [...section.items, { __internalId__: action.payload.sectionItemId }],
								};
							}),
					},
				},
			},
		}),
	[BOARD_BUFFER_SECTION_ITEM_UPDATE]: (state: State, action: UpdateItemInSectionAction) =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			sectionItems: {
				[action.constraint.sectionItemId]: {
					$merge: action.payload,
					__meta__: {
						$merge: {
							updated: true,
						},
					},
				},
			},
		}),
	[BOARD_BUFFER_SECTION_ITEM_DELETE]: (state: State, action: DeleteItemInSectionAction) =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			boards: {
				[action.constraint.boardId]: {
					sections: {
						// inside boradBuffer.boards.sections.items we have no null values as placeholdes
						$apply: (sections: Array<BoardSectionReference>) =>
							sections.map((section) => ({
								...section,
								items: section.items.filter(
									({ __internalId__ }) => __internalId__ !== action.constraint.sectionItemId
								),
							})),
					},
				},
			},
			sections: {
				// inside boradBuffer.sections.items are null values as placeholders for empty slots
				$apply: (sections: { [key: string]: BoardSectionBuffer }) => {
					const nextSections = { ...sections };
					nextSections[action.constraint.sectionId].items = nextSections[
						action.constraint.sectionId
					].items.map((item) =>
						item && item.__internalId__ !== action.constraint.sectionItemId ? item : null
					);

					return nextSections;
				},
			},
			sectionItems: {
				[action.constraint.sectionItemId]: {
					__meta__: {
						deleted: {
							$set: true,
						},
					},
				},
			},
		}),
	[BOARD_BUFFER_SECTION_ITEM_ORDER]: (state: State, action: OrderSectionItemAction) => {
		const stateWithOrderedSectionItems = immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			sections: {
				[action.constraint.sectionId]: {
					items: {
						$splice: [
							[action.constraint.dragOrder, 1],
							[
								action.constraint.hoverOrder,
								0,
								state.sections[action.constraint.sectionId].items[action.constraint.dragOrder],
							],
						],
					},
				},
			},
		});

		const newState = immutableUpdate(stateWithOrderedSectionItems, {
			sectionItems: stateWithOrderedSectionItems.sections[action.constraint.sectionId].items.reduce(
				(accumulator, item, order) => {
					if (!item) {
						return accumulator;
					}
					const { __internalId__ } = item;
					return {
						[__internalId__]: {
							__meta__: {
								updated: {
									$set: true,
								},
							},
							order: {
								$set: order,
							},
						},
						...accumulator,
					};
				},
				{}
			),
		});
		return newState;
	},
	[BOARD_BUFFER_SOCIAL_MEDIA_ADD]: (state: State, action: AddSocialMediaAccountAction) =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			boards: {
				[action.constraint.boardId]: {
					socialMediaAccounts: {
						$push: [{ __internalId__: action.payload.socialMediaAccountId }],
					},
					__meta__: {
						$merge: {
							updated: true,
						},
					},
				},
			},
			socialMediaAccounts: {
				[action.payload.socialMediaAccountId]: {
					$set: {
						__meta__: {
							internalId: action.payload.socialMediaAccountId,
							boardId: action.constraint.boardId,
							created: true,
							updated: false,
						},
						service: action.payload.service,
						label: action.payload.label || null,
						accountName: action.payload.accountName,
					},
				},
			} as
				| Spec<{ [key: string]: BoardSocialMediaAccountBuffer }, CustomCommands<DeleteFromObject>>
				| undefined,
		}),
	[BOARD_BUFFER_SOCIAL_MEDIA_UPDATE]: (state: State, action: UpdateSocialMediaAccountAction) =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			boards: {
				[action.constraint.boardId]: {
					__meta__: {
						$merge: {
							updated: true,
						},
					},
				},
			},
			socialMediaAccounts: {
				[action.constraint.socialMediaAccountId]: {
					$merge: {
						service: action.payload.service,
						label: action.payload.label || null,
						accountName: action.payload.accountName,
					},
					__meta__: {
						$merge: {
							updated: true,
						},
					},
				},
			},
		}),
	[BOARD_BUFFER_SOCIAL_MEDIA_DELETE]: (state: State, action: DeleteSocialMediaAccountAction) =>
		immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			boards: {
				[action.constraint.boardId]: {
					socialMediaAccounts: {
						$apply: (socialMediaAccounts: Array<BoardSocialMediaAccountReference>) =>
							socialMediaAccounts.filter(
								(socialMediaAccount) =>
									socialMediaAccount.__internalId__ !== action.constraint.socialMediaAccountId
							),
					},
					__meta__: {
						$merge: {
							updated: true,
						},
					},
				},
			},
			socialMediaAccounts: {
				$delete: [action.constraint.socialMediaAccountId],
			},
		}),
	[BOARD_BUFFER_PERSIST]: {
		[STATUS_REQUEST]: (state: State, action: PersistActionRequest) =>
			immutableUpdate(state, {
				isWorkingByBoard: {
					[action.constraint.boardId]: {
						$set: true,
					},
				},
				latestErrorByBoard: {
					[action.constraint.boardId]: {
						$set: null,
					},
				},
				savableByBoard: {
					[action.constraint.boardId]: {
						$set: false,
					},
				},
			}),
		[STATUS_SUCCESS]: (state: State, action: PersistActionSuccess) =>
			immutableUpdate(state, {
				isWorkingByBoard: {
					[action.constraint.boardId]: {
						$set: false,
					},
				},
				savableByBoard: {
					[action.constraint.boardId]: {
						$set: false,
					},
				},
			}),
		[STATUS_ERROR]: (state: State, action: PersistActionError) =>
			immutableUpdate(state, {
				isWorkingByBoard: {
					[action.constraint.boardId]: {
						$set: false,
					},
				},
				latestErrorByBoard: {
					[action.constraint.boardId]: {
						$set: action.payload,
					},
				},
				savableByBoard: {
					[action.constraint.boardId]: {
						$set: true,
					},
				},
			}),
	},
	[BOARD_BUFFER_SECTION_ORDER]: (state: State, action: OrderSectionAction) => {
		const stateWithOrderedSections = immutableUpdate(state, {
			savableByBoard: {
				[action.constraint.boardId]: {
					$set: true,
				},
			},
			boards: {
				[action.constraint.boardId]: {
					sections: {
						$splice: [
							[action.constraint.dragOrder, 1],
							[
								action.constraint.hoverOrder,
								0,
								state.boards[action.constraint.boardId].sections[action.constraint.dragOrder],
							],
						],
					},
				},
			},
		});

		return immutableUpdate(stateWithOrderedSections, {
			sections: stateWithOrderedSections.boards[action.constraint.boardId].sections.reduce(
				(accumulator, item, order) => {
					if (!item) {
						return accumulator;
					}

					const { __internalId__ } = item;
					return {
						[__internalId__]: {
							__meta__: {
								updated: {
									$set: true,
								},
							},
							order: {
								$set: order,
							},
						},
						...accumulator,
					};
				},
				{}
			),
		});
	},
	[BOARD_BUFFER_VALIDATE]: (state: State, action: ValidateAction) =>
		immutableUpdate(state, {
			[action.constraint.type]: {
				[action.constraint.internalId]: {
					__meta__: {
						latestValidation: {
							$set: action.payload,
						},
					},
				},
			},
		}),
});
