import { STATUS_PUBLISHED } from '@sep/br24-constants';
import * as util from '@sep/br24-util';
import invariant from 'invariant';
import { fetchQuery, graphql } from 'relay-runtime';

import config from '@/client/config';
import type { AppThunkAction } from '@/client/store/redux';

import type { AddItemsAction } from '..';
import { AM_TYPE_LINK, AM_TYPE_ARTICLE, AM_TYPE_BOARD } from '../../../constants';
import environment from '../../../environment';

import type { transferQuery } from './__generated__/transferQuery.graphql';
import addItemToActiveSpace from './addItemToActiveSpace';

export const transferLinkFromPosition =
	(position: number): AppThunkAction<AddItemsAction> =>
	async (dispatch, getState) => {
		const { articleEditor } = getState();
		invariant(
			articleEditor.activeArticleId,
			'cannot transfer link because no article was selected'
		);

		const { links } = articleEditor.articles[articleEditor.activeArticleId];
		invariant(links, 'cannot transfer link because the active article does not contain any links');

		const affectedLink = links[position];
		invariant(
			affectedLink && affectedLink.url,
			'cannot transfer link because there is no link at position %d ',
			position
		);

		const { site, meta: rowId } = util.url.getInfo(affectedLink.url, config.VITE_BRANCH);
		const isInternalUrl = site === 'BR24';

		if (isInternalUrl && rowId) {
			const data = await fetchQuery<transferQuery>(
				environment,
				graphql`
					query transferQuery($rowId: String!) {
						article: articleByRowId(rowId: $rowId) {
							isPublished: status
							title
							status
							image {
								url
							}
							publicationDate
						}
						board: boardByRowId(rowId: $rowId) {
							isPublished: status
							title
							status
							image {
								url
							}
							publicationDate: updatedAt
						}
					}
				`,
				{
					rowId,
				}
			).toPromise();

			if (!data) {
				return null;
			}

			const { article, board } = data;

			const isArticle = !!article;
			const isBoard = !!board;

			if (isArticle) {
				dispatch(
					addItemToActiveSpace({
						type: AM_TYPE_ARTICLE,
						articleId: rowId,
						isPublished: article.isPublished === STATUS_PUBLISHED,
						title: article.title,
						status: article.status,
						image: article.image?.url,
						publicationDate: article.publicationDate,
					})
				);
				return;
			}

			if (isBoard) {
				dispatch(
					addItemToActiveSpace({
						type: AM_TYPE_BOARD,
						boardId: rowId,
						isPublished: board.isPublished === STATUS_PUBLISHED,
						title: board.title,
						status: board.status ?? undefined,
						image: board.image?.url,
						publicationDate: board.publicationDate,
					})
				);
				return;
			}
		}
		dispatch(
			addItemToActiveSpace({
				type: AM_TYPE_LINK,
				label: affectedLink.label,
				url: affectedLink.url,
				target: '_blank',
			})
		);
	};
