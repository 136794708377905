import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../../environment';

import type { EmbedModuleRendererQuery } from './__generated__/EmbedModuleRendererQuery.graphql';

type Props = {
	children: QueryRendererProps<EmbedModuleRendererQuery>['render'];
};

function EmbedModuleRenderer({ children }: Props) {
	return (
		<QueryRenderer<EmbedModuleRendererQuery>
			environment={environment}
			query={graphql`
				query EmbedModuleRendererQuery($service: String!, $source: String!, $renderImage: Boolean) {
					...EmbedModuleEditorContainer_query
				}
			`}
			variables={{
				service: '',
				source: '',
				renderImage: false,
			}}
			render={children}
		/>
	);
}

export default EmbedModuleRenderer;
