import { Card } from 'antd';

import gs from '../../../../styles';

type Props = {
	large?: boolean;
};

function ShortnewsItems({ large }: Props) {
	const amount = large ? 'sieben' : 'fünf';
	return (
		<Card title="Meldungen">
			<p className={gs.mbM}>
				An dieser Stelle werden maximal {amount} Meldungen sowie die Links zur Meldungsseite und den
				B5-Nachrichten angezeigt.
			</p>
		</Card>
	);
}

ShortnewsItems.defaultProps = {
	large: false,
};

export default ShortnewsItems;
