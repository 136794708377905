import { commitMutation, graphql } from 'react-relay';

import environment from '@/client/environment';

import type { ArticleEditorCopyArticleMutation } from './__generated__/ArticleEditorCopyArticleMutation.graphql';

const mutation = graphql`
	mutation ArticleEditorCopyArticleMutation($input: CopyArticleInput!) {
		copyArticle(input: $input) {
			article {
				rowId
			}
		}
	}
`;

export default (baseId: string): Promise<ArticleEditorCopyArticleMutation['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<ArticleEditorCopyArticleMutation>(environment, {
			mutation,
			variables: {
				input: {
					baseId,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
