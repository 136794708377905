import { Card, Row, Col } from 'antd';
import { createFragmentContainer, graphql } from 'react-relay';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import routes from '../../config/config.routes';
import PlaceholderImage from '../../images/placeholder.png';

import type { NavigationList_query$data } from './__generated__/NavigationList_query.graphql';

const { Meta } = Card;

interface Props {
	query: NavigationList_query$data;
}

function isDefined<T>(item: T | null | undefined): item is T {
	return !!item;
}

function NavigationOverview(props: Props) {
	const { query } = props;
	const theme = useTheme();

	return (
		<div>
			<Row gutter={16}>
				{query.allNavigations &&
					query.allNavigations.edges
						.map((item) => item.node)
						.filter(isDefined)
						.map((node) => (
							<Col span={4} key={node.rowId}>
								<Link to={`${routes.navigations}/${node.rowId}`}>
									<Card
										style={{
											boxShadow: theme.boxShadow.default,
											borderRadius: 10,
											overflow: 'hidden',
										}}
										cover={<img alt="example" src={PlaceholderImage} />}
									>
										<Meta title={node.text} description="BR24" />
									</Card>
								</Link>
							</Col>
						))}
			</Row>
		</div>
	);
}

export default createFragmentContainer(NavigationOverview, {
	query: graphql`
		fragment NavigationList_query on Query {
			allNavigations(condition: { type: NAVIGATION }) {
				edges {
					node {
						id
						rowId
						type
						text
					}
				}
			}
		}
	`,
});
