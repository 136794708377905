import { TYPE_ARTICLE, TYPE_BOARD, TYPE_AUTHOR } from '@sep/br24-constants';
import { state as authState, onTokenSetChange } from '@sep/cms-auth-client';

import config from '../config';

export const MESSAGE_TYPE_REQUEST_ACCESS_TOKEN = 'REQUEST_BR24_ACCESS_TOKEN';
export const MESSAGE_TYPE_PROVIDE_ACCESS_TOKEN = 'PROVIDE_BR24_ACCESS_TOKEN';

// this one seems to no longer be used
// we'll trigger a reload on the client each time we have received a new token there, so a manual refresh should not be needed
export const MESSAGE_TYPE_RELOAD = 'RELOAD';

type PreviewType = typeof TYPE_ARTICLE | typeof TYPE_BOARD | typeof TYPE_AUTHOR;

export function createPreviewUrl(
	type: PreviewType,
	data: {
		primaryCategory?: string | null;
		slug?: string | null;
		rowId?: string | null;
		guid?: string;
	}
): string {
	const origin = config.VITE_WEB_PREVIEW_CLIENT_URL_EXT;

	let path = '';

	switch (type) {
		case TYPE_ARTICLE:
			path = `/${data.primaryCategory}/${data.slug},${data.rowId}`;
			break;

		case TYPE_BOARD:
			path = data.slug === '/' ? data.slug : `${data.slug},${data.rowId}`;
			break;

		case TYPE_AUTHOR:
			path = `/autoren${data.slug},${data.guid}`;
			break;
	}

	return `${origin}${path}`;
}

const previewTargets: { origin: string; window: Window }[] = [];

function send(
	target: Window | null,
	origin: string,
	message: {
		type:
			| typeof MESSAGE_TYPE_PROVIDE_ACCESS_TOKEN
			| typeof MESSAGE_TYPE_REQUEST_ACCESS_TOKEN
			| typeof MESSAGE_TYPE_RELOAD;
		payload?: unknown;
	}
) {
	if (target && origin && message) {
		target.postMessage(JSON.stringify(message), origin);
	}
}

/**
 * instructs web clientwindows that were opened by the cms
 * to preview e.g. articles or boards to reload the window
 *
 * we don't differentiate between types of documents or their ID
 * because
 * - an editor should rarely have more than one preview open
 * - reloading all preview windows does not make a difference
 */
export function triggerReloadPreview() {
	previewTargets.forEach((target) => {
		send(target.window, target.origin, { type: 'RELOAD' });
	});
}

/**
 * this function was taken and simplified from https://github.com/digitalegarage/br-auth-client/blob/master/src/Context/Provider.js
 * @param address
 * @returns
 */
export function openPreview(address: string) {
	const { origin: webClientOrigin } = new URL(address);
	const previewWindow = window.open(address);

	function sendToken(payload?: string) {
		if (payload) {
			send(previewWindow, webClientOrigin, {
				type: MESSAGE_TYPE_PROVIDE_ACCESS_TOKEN,
				payload,
			});
		}
	}

	if (previewWindow) {
		previewTargets.push({ window: previewWindow, origin: webClientOrigin });

		previewWindow.addEventListener('close', () => {
			const index = previewTargets.findIndex((target) => target.window === previewWindow);

			if (index >= 0) {
				previewTargets.splice(index, 1);
			}
		});

		window.addEventListener('message', (event) => {
			if (event.isTrusted && event.origin === webClientOrigin) {
				onTokenSetChange((tokenSet) => {
					sendToken(tokenSet.access_token?.value);
				});

				try {
					const data = JSON.parse(event.data);
					if (data.type === MESSAGE_TYPE_REQUEST_ACCESS_TOKEN) {
						sendToken(authState.tokenSet?.access_token?.value);
					}
				} catch (err) {
					console.warn(`could not handle postMessage from ${event.origin}`);
				}
			}
		});
	}
}
