/**
 * @generated SignedSource<<4ff1f245c7801e39163be9fc0dde0197>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetManagerItemLiveStreamRenderedQuery$variables = {
  programmeId: string;
};
export type AssetManagerItemLiveStreamRenderedQuery$data = {
  readonly viewer: {
    readonly programme: {
      readonly __typename: string;
      readonly broadcasts: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly end: string | null;
            readonly id: string;
            readonly start: string | null;
          } | null;
        } | null> | null;
      } | null;
      readonly defaultTeaserImage: {
        readonly imageFiles: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly publicLocation: string | null;
            } | null;
          } | null> | null;
        } | null;
      } | null;
      readonly id: string;
      readonly kicker: string | null;
      readonly title: string | null;
    } | null;
  } | null;
};
export type AssetManagerItemLiveStreamRenderedQuery = {
  response: AssetManagerItemLiveStreamRenderedQuery$data;
  variables: AssetManagerItemLiveStreamRenderedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "programmeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "programmeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kicker",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicLocation",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "START_DESC"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetManagerItemLiveStreamRenderedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "programme",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "defaultTeaserImage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoImageFileConnection",
                    "kind": "LinkedField",
                    "name": "imageFiles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MangoImageFileEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "MangoBroadcastEventConnection",
                "kind": "LinkedField",
                "name": "broadcasts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoBroadcastEventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "broadcasts(orderBy:\"START_DESC\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssetManagerItemLiveStreamRenderedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MangoViewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "programme",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "defaultTeaserImage",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoImageFileConnection",
                    "kind": "LinkedField",
                    "name": "imageFiles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MangoImageFileEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "MangoBroadcastEventConnection",
                "kind": "LinkedField",
                "name": "broadcasts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MangoBroadcastEventEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "broadcasts(orderBy:\"START_DESC\")"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0858e5ad1387a24893c2d6f68b0201a",
    "id": null,
    "metadata": {},
    "name": "AssetManagerItemLiveStreamRenderedQuery",
    "operationKind": "query",
    "text": "query AssetManagerItemLiveStreamRenderedQuery(\n  $programmeId: ID!\n) {\n  viewer {\n    programme(id: $programmeId) {\n      __typename\n      id\n      title\n      kicker\n      defaultTeaserImage {\n        __typename\n        imageFiles {\n          edges {\n            node {\n              __typename\n              publicLocation\n              id\n            }\n          }\n        }\n        id\n      }\n      broadcasts(orderBy: START_DESC) {\n        edges {\n          node {\n            __typename\n            start\n            end\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c3b58cfa4d651dc8186c205ea481f55";

export default node;
