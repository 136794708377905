import { graphql, QueryRenderer, type QueryRendererProps, useRelayEnvironment } from 'react-relay';

import type { SourceOriginRendererQuery } from './__generated__/SourceOriginRendererQuery.graphql';

type Props = {
	defaultSourceOriginRowId: string | undefined | null;
	children: QueryRendererProps<SourceOriginRendererQuery>['render'];
};

function SourceOriginRenderer({ defaultSourceOriginRowId, children }: Props) {
	const environment = useRelayEnvironment();

	return (
		<QueryRenderer<SourceOriginRendererQuery>
			environment={environment}
			query={graphql`
				query SourceOriginRendererQuery(
					$filter: MangoSeriesFilter
					$sourceOriginRowId: ID!
					$hasSourceOrigin: Boolean = false
				) {
					...SourceOriginContainerRefetch_query
				}
			`}
			variables={{
				filter: {},
				sourceOriginRowId: defaultSourceOriginRowId || 'av:',
				hasSourceOrigin: !!defaultSourceOriginRowId,
			}}
			render={children}
		/>
	);
}

export default SourceOriginRenderer;
