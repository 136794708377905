import {
	STATUS_DELETED,
	STATUS_DEPUBLISHED,
	STATUS_DRAFT,
	STATUS_PUBLISHED,
	STATUS_REVIEW,
	STATUS_SCHEDULED,
	STATUS_CANNOT_PLACE,
	STATUS_NOT_WHITELISTED,
	STATUS_ALREADY_ON_THE_LIST,
	LS_UPDATE_LOCKSTATUS,
} from '@sep/br24-constants';

import { translate } from '../../translation';

const NOTIFICATION_DISPLAY_DURATION = 4;

export const getErrorMessage = (type?: string | null) => {
	switch (type) {
		case STATUS_DRAFT:
			return {
				message: translate(`saveArticle.error.${STATUS_DRAFT}.title`),
				description: translate(`saveArticle.error.${STATUS_DRAFT}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_REVIEW:
			return {
				message: translate(`saveArticle.error.${STATUS_REVIEW}.title`),
				description: translate(`saveArticle.error.${STATUS_REVIEW}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_PUBLISHED:
			return {
				message: translate(`saveArticle.error.${STATUS_PUBLISHED}.title`),
				description: translate(`saveArticle.error.${STATUS_PUBLISHED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_SCHEDULED:
			return {
				message: translate(`saveArticle.error.${STATUS_SCHEDULED}.title`),
				description: translate(`saveArticle.error.${STATUS_SCHEDULED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_DEPUBLISHED:
			return {
				message: translate(`saveArticle.error.${STATUS_DEPUBLISHED}.title`),
				description: translate(`saveArticle.error.${STATUS_DEPUBLISHED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_DELETED:
			return {
				message: translate(`saveArticle.error.${STATUS_DELETED}.title`),
				description: translate(`saveArticle.error.${STATUS_DELETED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_CANNOT_PLACE:
			return {
				message: translate(`saveArticle.error.${STATUS_CANNOT_PLACE}.title`),
				description: translate(`saveArticle.error.${STATUS_CANNOT_PLACE}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_NOT_WHITELISTED:
			return {
				message: translate(`saveArticle.error.${STATUS_NOT_WHITELISTED}.title`),
				description: translate(`saveArticle.error.${STATUS_NOT_WHITELISTED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_ALREADY_ON_THE_LIST:
			return {
				message: translate(`saveArticle.error.${STATUS_ALREADY_ON_THE_LIST}.title`),
				description: translate(`saveArticle.error.${STATUS_ALREADY_ON_THE_LIST}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case LS_UPDATE_LOCKSTATUS:
			return {
				message: translate(`saveArticle.error.${LS_UPDATE_LOCKSTATUS}.title`),
				description: translate(`saveArticle.error.${LS_UPDATE_LOCKSTATUS}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		default:
			return {
				message: translate('saveArticle.error.general.title'),
				description: translate('saveArticle.error.general.description'),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};
	}
};

export const getSuccessMessage = (type?: string | null) => {
	switch (type) {
		case STATUS_DRAFT:
			return {
				message: translate(`saveArticle.success.${STATUS_DRAFT}.title`),
				description: translate(`saveArticle.success.${STATUS_DRAFT}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_REVIEW:
			return {
				message: translate(`saveArticle.success.${STATUS_REVIEW}.title`),
				description: translate(`saveArticle.success.${STATUS_REVIEW}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_PUBLISHED:
			return {
				message: translate(`saveArticle.success.${STATUS_PUBLISHED}.title`),
				description: translate(`saveArticle.success.${STATUS_PUBLISHED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_SCHEDULED:
			return {
				message: translate(`saveArticle.success.${STATUS_SCHEDULED}.title`),
				description: translate(`saveArticle.success.${STATUS_SCHEDULED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_DEPUBLISHED:
			return {
				message: translate(`saveArticle.success.${STATUS_DEPUBLISHED}.title`),
				description: translate(`saveArticle.success.${STATUS_DEPUBLISHED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_DELETED:
			return {
				message: translate(`saveArticle.success.${STATUS_DELETED}.title`),
				description: translate(`saveArticle.success.${STATUS_DELETED}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case STATUS_ALREADY_ON_THE_LIST:
			return {
				message: translate(`saveArticle.success.${STATUS_ALREADY_ON_THE_LIST}.title`),
				description: translate(`saveArticle.success.${STATUS_ALREADY_ON_THE_LIST}.description`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		case 'autoSave':
			return {
				message: translate(`saveArticle.success.autoSave.title`),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};

		default:
			return {
				message: translate('saveArticle.success.general.title'),
				description: translate('saveArticle.success.general.description'),
				duration: NOTIFICATION_DISPLAY_DURATION,
			};
	}
};
