import { TYPE_ARTICLE } from '@sep/br24-constants';
import { Layout, Row, Col, Button, Dropdown, notification, Space } from 'antd';
import cn from 'classnames';
import { Component, type MouseEvent } from 'react';
import { Helmet } from 'react-helmet-async';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';
import { withTranslation } from '@/client/translation/withTranslation';
import type { MenuInfo } from '@/types/libs';

import config from '../../config';
import routes from '../../config/config.routes';
import environment from '../../environment';
import CopyArticleMutation from '../../mutations/CopyArticleMutation';
import * as republisherActions from '../../store/reducers/articleRepublisher';
import * as articleActions from '../../store/reducers/articlesManager';
import * as creatorActions from '../../store/reducers/creator';
import styles from '../../styles';
import type { WithTranslationProps } from '../../translation';
import { createPreviewUrl, openPreview } from '../../util/preview';
import ArticleRepublisher from '../ArticleRepublisher';
import DelayedSearch from '../DelayedSearch';
import { Page } from '../Page';

import localStyles from './Articles.module.scss';
import ArticlesFilter from './ArticlesFilter';
import { ArticlesTable } from './ArticlesTable';
import type { ArticlesTableArticle } from './ArticlesTable/types';
import CombineToStoryMutation from './CombineToStoryMutation';

const { Content } = Layout;
const { Button: DropdownButton } = Dropdown;

interface Props extends WithTranslationProps, ReduxProperties, RouteComponentProps {
	selection: Array<string>;
	shouldUseNewArticleManager: boolean;
}

type State = {
	isFilterFoldedOut: boolean;
};

const StyledContent = styled(Content)`
	padding: 0 4rem;
`;

function isFilterActive(filter: articleActions.FilterQuery) {
	return Object.values(filter).some((filterValue) => filterValue != null);
}

class Articles extends Component<Props, State> {
	historyTimer: any = null;

	state = {
		isFilterFoldedOut: false,
	};

	componentDidMount() {
		if (Object.keys(this.props.currentFilter).length < 1) {
			const filter = new URL(window.location.href).searchParams.get('filter');
			if (filter) {
				try {
					const filters = JSON.parse(decodeURIComponent(filter));
					this.props.updateFilter(filters);
				} catch (_) {
					// It's fine, really.
				}
			}
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.currentFilter !== this.props.currentFilter) {
			if (this.historyTimer) {
				clearTimeout(this.historyTimer);
			}

			this.historyTimer = setTimeout(() => {
				const filter = JSON.stringify(this.props.currentFilter);
				this.props.history.push({
					pathname: '/articles',
					search: filter.length > 2 ? `?filter=${filter}` : undefined,
				});
				this.historyTimer = null;
			}, 500);
		}
	}

	handleChangeFilter = (nextFilter: articleActions.FilterQuery): void => {
		this.props.updateFilter(nextFilter);
	};

	handleChangeSearchTerm = (value: string) => {
		this.props.updateFilter({ searchTerm: value || undefined });
	};

	handleChangeSelection = (selection: Array<string>): void => {
		this.props.updateSelection(selection);
	};

	handleClickActionForSelection = ({ key: action }: { key: string }) => {
		const {
			translation: { translate },
		} = this.props;

		switch (action) {
			case 'story':
				CombineToStoryMutation({ affectedArticles: this.props.selection }, environment)
					.then(({ combineToStory }) => {
						notification.success({
							message: translate('articles.selection.successMessage', {
								articles: combineToStory?.articles
									?.map((article) => article?.title ?? '')
									.join(', '),
							}),
							duration: 2,
						});
						this.props.updateSelection([]);
					})
					.catch((_err) => {
						notification.error({
							message: translate('articles.selection.errorMessage'),
							duration: 4,
						});
					});
				break;

			case 'reset':
				this.props.updateSelection([]);
				break;

			default:
				break;
		}
	};

	handleClickCreate = () => {
		this.props.showCreator();
	};

	handleClickFilterToggle = () =>
		this.setState((prevState) => ({
			isFilterFoldedOut: !prevState.isFilterFoldedOut,
		}));

	handleClickFromTemplate = (_menuInfo: MenuInfo, article: ArticlesTableArticle) => {
		const {
			history,
			translation: { translate },
		} = this.props;

		if (!article.isTemplate) {
			return;
		}

		CopyArticleMutation(article.rowId, environment)
			.then(({ copyArticle }) => {
				if (!copyArticle || !copyArticle.article || !copyArticle.article.rowId) {
					return Promise.reject(new Error('Something went wrong'));
				}

				return copyArticle;
			})
			.then((copyArticle) => {
				notification.success({
					message: translate('articles.copyArticle.success.message'),
					description: translate('articles.copyArticle.success.description', {
						title: article.title,
					}),
					duration: 3,
				});
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				history.push(`/articleEditor/${copyArticle.article!.rowId}`);
			})
			.catch(() =>
				notification.error({
					message: translate('articles.copyArticle.error.message'),
					description: translate('articles.copyArticle.error.description', {
						title: article.title,
					}),
				})
			);
	};

	handleClickOpen = (
		event: MouseEvent<HTMLTableDataCellElement | HTMLElement>,
		node: ArticlesTableArticle
	) => {
		const target = event.target as Element;

		if (target && target.classList && [...target.classList].includes('anticon-copy')) {
			event.preventDefault();
			return;
		}

		event.stopPropagation();
		this.props.history.push(`${routes.article}/${node.rowId}`);
	};

	handleClickPreview = (_event: MenuInfo, node: ArticlesTableArticle) => {
		openPreview(createPreviewUrl(TYPE_ARTICLE, node));
	};

	handleClickRepublish = (node: ArticlesTableArticle) => {
		this.props.startRepublishing(node);
	};

	handleSelectFilter = ({ key: id }: any) => {
		if (id === 'reset') {
			this.props.setFilter({});
			this.props.resetSelectedTemplateId();
		} else {
			this.props.activateTemplate(id);
		}
	};

	render() {
		const { isFilterFoldedOut } = this.state;
		const {
			selection,
			templates,
			currentFilter,
			autoRefreshIn,
			selectedTemplateId,
			translation: { translate },
		} = this.props;

		const combineStoryItems = [
			{
				label: translate('articles.selection.story'),
				key: 'story',
			},
			{
				label: translate('articles.selection.reset'),
				key: 'reset',
			},
		];

		const filterMenuItems = [
			...templates.map((template) => ({
				label: template.name,
				key: template.id,
			})),
			{
				label: translate('articles.search.clearFilter'),
				key: 'reset',
			},
		];

		return (
			<Page>
				<Helmet>
					<title>{translate('articles.article')}</title>
				</Helmet>
				<StyledContent>
					<Row>
						<Col span={24} className={cn(styles.pM, styles.toolbar)}>
							<div>
								{config.VITE_ENABLE_STORY_MERGE_FEATURE &&
								selection !== null &&
								(selection || []).length > 0 ? (
									<DropdownButton
										className={styles.mrM}
										menu={{ items: combineStoryItems, onClick: this.handleClickActionForSelection }}
									>
										({(selection || []).length}) {translate('articles.article')}
									</DropdownButton>
								) : (
									false
								)}
								<Button type="primary" onClick={this.handleClickCreate}>
									{translate('articles.createArticle')}
								</Button>
							</div>
							{(typeof selectedTemplateId === 'string' ||
								typeof selectedTemplateId === 'number') && (
								<div className={localStyles.selectedTemplate}>
									<b>{templates[parseInt(selectedTemplateId, 10) - 1].name}</b>
								</div>
							)}
							<Space align="center">
								<DelayedSearch
									placeholder={translate('articles.search.articleSearch')}
									onChange={this.handleChangeSearchTerm}
									value={currentFilter.searchTerm}
									defaultValue={currentFilter.searchTerm}
								/>
								<DropdownButton
									menu={{ items: filterMenuItems, onClick: this.handleSelectFilter }}
									onClick={this.handleClickFilterToggle}
									type={isFilterActive(currentFilter) ? 'primary' : 'default'}
								>
									Filtern
								</DropdownButton>
							</Space>
						</Col>
					</Row>
					<Row>
						<Col span={24} style={{ display: isFilterFoldedOut ? 'block' : 'none' }}>
							<ArticlesFilter onChange={this.handleChangeFilter} currentFilter={currentFilter} />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<ArticlesTable
								onChangeSelection={this.handleChangeSelection}
								onClickOpen={this.handleClickOpen}
								onClickPreview={this.handleClickPreview}
								onClickCreateFromTemplate={this.handleClickFromTemplate}
								onClickRepublish={this.handleClickRepublish}
								selection={selection}
								currentFilter={currentFilter}
								autoRefreshIn={autoRefreshIn}
							/>
						</Col>
					</Row>
				</StyledContent>
				<ArticleRepublisher />
			</Page>
		);
	}
}

const connector = connect(
	({ articlesManager, user }: ReduxState) => ({
		...articlesManager,
		autoRefreshIn: user.settings.autoRefreshIn,
	}),
	{
		updateSelection: articleActions.updateSelection,
		refresh: articleActions.refresh,
		updateFilter: articleActions.updateFilter,
		activateTemplate: articleActions.activateTemplate,
		setFilter: articleActions.setFilter,
		resetSelectedTemplateId: articleActions.resetSelectedTemplateId,
		showCreator: () => creatorActions.show({ type: TYPE_ARTICLE }),
		startRepublishing: republisherActions.start,
	}
);

type ReduxProperties = ConnectedProps<typeof connector>;

export default connector(withTranslation(Articles));
