import { darken } from 'polished';
import styled from 'styled-components';

type AssetManagerFilterClearButtonProps = {
	isVisible: boolean;
};

const AssetManagerFilterClearButton = styled('button')<AssetManagerFilterClearButtonProps>`
	width: 40px;
	height: 40px;
	border: none;
	margin-right: 10px;
	display: ${(props) => (props.isVisible ? 'block' : 'none')};
	background-color: ${(props) => props.theme?.colors.primaryBlue};
	color: #ffffff;
	outline: none;
	cursor: pointer;
	&:hover {
		color: #ffffff;
		background-color: ${(props) => darken(0.1, props.theme?.colors.primaryBlue)};
	}
`;

export default AssetManagerFilterClearButton;
