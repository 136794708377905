import {
	STATUS_DRAFT,
	STATUS_REVIEW,
	STATUS_PUBLISHED,
	STATUS_DEPUBLISHED,
	STATUS_DELETED,
	STATUS_SCHEDULED,
} from '@sep/br24-constants';
import moment from 'moment';
import { type DefaultTheme, useTheme } from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

import { Title, Info, Content } from './AssetManagerItemControl';
import Media from './Media';
import TypeIcon from './TypeIcon';

export type PublishStatus =
	| typeof STATUS_DRAFT
	| typeof STATUS_REVIEW
	| typeof STATUS_PUBLISHED
	| typeof STATUS_DEPUBLISHED
	| typeof STATUS_DELETED
	| typeof STATUS_SCHEDULED
	| undefined;

function mapStatusToColor(status: PublishStatus | undefined | null, theme: DefaultTheme) {
	switch (status) {
		case STATUS_DRAFT:
			return theme.colors.stateOrange;
		case STATUS_REVIEW:
			return theme.colors.stateBlue;
		case STATUS_PUBLISHED:
			return theme.colors.stateGreen;
		case STATUS_DEPUBLISHED:
			return theme.colors.stateRed;
		default:
			return '';
	}
}

export interface BaseProps {
	image: string;
	title: string | undefined;
	isPublished: boolean;
	publicationDate?: string | null;
	status?: PublishStatus | null;
}

interface ItemPublishableProps extends BaseProps {
	type?: string;
}

export default function ItemPublishable(props: ItemPublishableProps) {
	const { status, type, image, title, publicationDate, isPublished = false } = props;

	const translate = useTranslate();
	const theme = useTheme();

	const publishedLabel = publicationDate
		? translate('assetManager.status.publishedAt', {
				date: moment(publicationDate).format('DD.MM.YYYY'),
		  })
		: translate('assetManager.status.published');
	const notPublishedLabel =
		status === STATUS_DEPUBLISHED
			? translate('assetManager.status.depublished')
			: translate('assetManager.status.draft');

	const color = mapStatusToColor(status, theme);
	return (
		<>
			<TypeIcon color={color} type={type} isOverlying={true} />
			<Media image={image} />
			<Content>
				<Title>{title}</Title>
				<Info>{isPublished ? publishedLabel : notPublishedLabel}</Info>
			</Content>
		</>
	);
}
