/**
 * @generated SignedSource<<b268af83305f80e3b895c7cdbb8f91ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GeolocationContainerQuery$variables = {
  textFilter?: ReadonlyArray<string | null> | null;
};
export type GeolocationContainerQuery$data = {
  readonly tags: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly rowId: number;
        readonly text: string;
      } | null;
    }>;
  } | null;
};
export type GeolocationContainerQuery = {
  response: GeolocationContainerQuery$data;
  variables: GeolocationContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "textFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "textFilter",
    "variableName": "textFilter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeolocationContainerQuery",
    "selections": [
      {
        "alias": "tags",
        "args": (v1/*: any*/),
        "concreteType": "TagsConnection",
        "kind": "LinkedField",
        "name": "filterTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeolocationContainerQuery",
    "selections": [
      {
        "alias": "tags",
        "args": (v1/*: any*/),
        "concreteType": "TagsConnection",
        "kind": "LinkedField",
        "name": "filterTags",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "863c6d1a0b05bc58c44c7fe188ae7bdb",
    "id": null,
    "metadata": {},
    "name": "GeolocationContainerQuery",
    "operationKind": "query",
    "text": "query GeolocationContainerQuery(\n  $textFilter: [String]\n) {\n  tags: filterTags(textFilter: $textFilter) {\n    edges {\n      node {\n        rowId\n        text\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "13a7e4656f57309302255c29826aa65f";

export default node;
