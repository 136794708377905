import { PRIORITY_NORMAL, PRIORITY_HIGH, PRIORITY_CRITICAL } from '@sep/br24-constants';
import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd/lib/radio';
import type { RadioButtonProps } from 'antd/lib/radio/radioButton';
import invariant from 'invariant';
import { Component } from 'react';
import styled from 'styled-components';

import { withTranslation } from '@/client/translation/withTranslation';
import type { Priority } from '@/types/schema';

import type { WithTranslationProps } from '../../translation';

const { Button: RadioButton, Group: RadioGroup } = Radio;

interface PriorityItemProps extends RadioButtonProps {
	isActive: boolean;
	fullWidth: boolean;
}

function PriorityButton({
	isActive: _isActive,
	fullWidth: _fullWidth,
	...rest
}: PriorityItemProps) {
	return <RadioButton {...rest} />;
}

export const PriorityNormal = styled(PriorityButton)`
	width: ${(props) => (props.fullWidth ? '33%' : 'auto')};
	text-align: center;
	border-color: ${(props) =>
		props.isActive ? `${props.theme.priorities.normal} !important` : 'default'};
	color: ${(props) => (props.isActive ? `${props.theme.priorities.normal} !important` : 'default')};
	&:hover {
		color: ${(props) => props.theme.priorities.normal};
	}
	&::before {
		background-color: ${(props) =>
			props.isActive ? `${props.theme.priorities.normal} !important` : 'default'};
		&:hover {
			color: ${(props) => props.theme.priorities.normal};
		}
	}
`;

export const PriorityHigh = styled(PriorityButton)`
	width: ${(props) => (props.fullWidth ? '33%' : 'auto')};
	text-align: center;
	border-color: ${(props) =>
		props.isActive ? `${props.theme.priorities.high} !important` : 'default'};
	color: ${(props) => (props.isActive ? `${props.theme.priorities.high} !important` : 'default')};
	box-shadow: -1px 0 0 0
		${(props) => (props.isActive ? `${props.theme.priorities.high} !important` : 'default')};
	&:hover {
		color: ${(props) => props.theme.priorities.high};
	}
	&::before {
		background-color: ${(props) =>
			props.isActive ? `${props.theme.priorities.high} !important` : 'default'};
		&:hover {
			color: ${(props) => props.theme.priorities.high};
		}
	}
`;

export const PriorityCritical = styled(PriorityButton)`
	width: ${(props) => (props.fullWidth ? '33%' : 'auto')};
	text-align: center;
	border-color: ${(props) =>
		props.isActive ? `${props.theme.priorities.critical} !important` : 'default'};
	color: ${(props) =>
		props.isActive ? `${props.theme.priorities.critical} !important` : 'default'};
	box-shadow: -1px 0 0 0
		${(props) => (props.isActive ? `${props.theme.priorities.critical} !important` : 'default')};
	&:hover {
		color: ${(props) => props.theme.priorities.critical};
	}

	&::before {
		background-color: ${(props) =>
			props.isActive ? `${props.theme.priorities.critical} !important` : 'default'};
		&:hover {
			color: ${(props) => props.theme.priorities.critical};
		}
	}
`;

type PriorityPickerProps = WithTranslationProps & {
	value: Priority | null | undefined;
	fullWidth: boolean;
	onSelect: (value: Priority) => void;
};

class PriorityPicker extends Component<PriorityPickerProps> {
	handleChange = (event: RadioChangeEvent) => {
		event.preventDefault();

		invariant(event.target, 'No event-target found');

		this.props.onSelect(event.target.value);
	};

	render() {
		const {
			value,
			fullWidth,
			onSelect,
			translation: { translate },
			...rest
		} = this.props;

		const limitedValue =
			value === PRIORITY_NORMAL || value === PRIORITY_HIGH || value === PRIORITY_CRITICAL
				? value
				: PRIORITY_NORMAL;

		return (
			<RadioGroup
				style={{ ...(fullWidth && { width: '100%' }) }}
				value={limitedValue}
				onChange={this.handleChange}
				{...rest}
			>
				<PriorityNormal
					fullWidth={fullWidth}
					isActive={value === PRIORITY_NORMAL}
					value={PRIORITY_NORMAL}
				>
					{translate(`priorities.${PRIORITY_NORMAL}`)}
				</PriorityNormal>
				<PriorityHigh
					fullWidth={fullWidth}
					isActive={value === PRIORITY_HIGH}
					value={PRIORITY_HIGH}
				>
					{translate(`priorities.${PRIORITY_HIGH}`)}
				</PriorityHigh>
				<PriorityCritical
					fullWidth={fullWidth}
					isActive={value === PRIORITY_CRITICAL}
					value={PRIORITY_CRITICAL}
				>
					{translate(`priorities.${PRIORITY_CRITICAL}`)}
				</PriorityCritical>
			</RadioGroup>
		);
	}
}

export default withTranslation(PriorityPicker);
