import { graphql, QueryRenderer, type QueryRendererProps } from 'react-relay';

import environment from '../../../environment';

import type { LatestPublicationContainerQuery } from './__generated__/LatestPublicationContainerQuery.graphql';

function LatestPublicationContainer(
	props: Pick<QueryRendererProps<LatestPublicationContainerQuery>, 'render'>
) {
	return (
		<QueryRenderer<LatestPublicationContainerQuery>
			environment={environment}
			query={graphql`
				query LatestPublicationContainerQuery {
					data: allArticles(
						first: 10
						condition: { status: PUBLISHED }
						orderBy: FIRST_PUBLICATION_DATE_DESC
					) {
						edges {
							node {
								rowId
								id
								title
								status
								firstPublicationDate
								publicationDate
							}
						}
					}
				}
			`}
			cacheConfig={{}}
			variables={{}}
			{...props}
		/>
	);
}

export default LatestPublicationContainer;
