import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'react-relay';

import type { CreateBoardSectionItemMutation as CreateBoardSectionItemMutationTypes } from './__generated__/CreateBoardSectionItemMutation.graphql';

const mutation = graphql`
	mutation CreateBoardSectionItemMutation($input: CreateBoardsSectionsItemInput!) {
		createBoardsSectionsItem(input: $input) {
			boardsSectionsItem {
				rowId
				boardId
				articleId
				order
			}
		}
	}
`;

export type CreateBoardSectionItemInput = {
	boardsSectionId?: string;
	boardId?: string | null;
	articleId?: string | null;
	order: number;
};

export type CreateBoardSectionItemOutput = {
	readonly rowId: number;
	readonly boardId: string | undefined | null;
	readonly articleId: string | undefined | null;
	readonly order: number | undefined | null;
};

const execute = (
	input: CreateBoardSectionItemInput,
	environment: Environment
): Promise<CreateBoardSectionItemMutationTypes['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<CreateBoardSectionItemMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					boardsSectionsItem: input,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});

export default async function CreateBoardSectionItemMutation(
	input: CreateBoardSectionItemInput,
	environment: Environment
): Promise<CreateBoardSectionItemOutput> {
	const res = await execute(input, environment);
	if (!res || !res.createBoardsSectionsItem || !res.createBoardsSectionsItem.boardsSectionsItem) {
		throw new Error('Something went wrong');
	}
	return res.createBoardsSectionsItem.boardsSectionsItem;
}
