import { MODULE_TYPE_EMBED } from '@sep/br24-constants';
import { Button } from 'antd';
import pretty from 'pretty';
import { Component } from 'react';
import AceEditor from 'react-ace';
import styled from 'styled-components';
import 'brace/mode/html';
import 'brace/theme/textmate';
import 'brace/ext/language_tools';

import { withTranslation } from '@/client/translation/withTranslation';

import type { WithTranslationProps } from '../../../translation';
import ErrorMessageText from '../../ErrorMessageText';

const EditorWrapper = styled.div`
	height: 254px;
	border: 2px solid #f0f0f0;
`;

const PreviewButtonWrapper = styled.div`
	width: 100%;
	text-align: right;
	margin-top: 10px;
	margin-bottom: 25px;

	> p {
		text-align: left;
	}
`;

type Props = WithTranslationProps & {
	source: string;
	validation: any;
	hasErrors: boolean;
	onChangeCode: (input: string) => void;
	onRefetch: () => void;
};

class ArticleModuleEmbedEditor extends Component<Props> {
	handleChangeCode = (input: string) => {
		this.props.onChangeCode(input);
	};

	handlePreviewButtonClick = () => {
		this.props.onRefetch();
	};

	render() {
		const {
			source,
			validation,
			hasErrors,
			translation: { translate },
		} = this.props;

		return (
			<>
				{hasErrors ? (
					<ErrorMessageText
						text={translate(`validation.errors.modules.embed.embedCodeValidationFailed`)}
						hasBottomMargin={true}
					/>
				) : null}
				<EditorWrapper>
					<AceEditor
						value={
							typeof source !== 'undefined' && validation && !validation['embed.service']
								? pretty(source)
								: source
						}
						width="100%"
						height="250px"
						mode="html"
						wrapEnabled={true}
						theme="textmate"
						onChange={this.handleChangeCode}
						editorProps={{ $blockScrolling: Infinity }}
						fontSize={16}
						showPrintMargin={true}
						showGutter={false}
						highlightActiveLine={true}
						setOptions={{
							enableBasicAutocompletion: true,
							enableLiveAutocompletion: true,
							enableSnippets: false,
							showLineNumbers: true,
							tabSize: 2,
						}}
					/>
				</EditorWrapper>
				<ErrorMessageText
					text={validation && validation['embed.source'] ? validation['embed.source'] : null}
					hasBottomMargin={true}
				/>
				<PreviewButtonWrapper>
					<Button
						type="primary"
						disabled={validation && validation['embed.service']}
						onClick={this.handlePreviewButtonClick}
					>
						{translate(`modules.${MODULE_TYPE_EMBED}.previewButtonText`)}
					</Button>
					<ErrorMessageText
						text={validation && validation['embed.url'] ? validation['embed.url'] : null}
						hasBottomMargin={true}
					/>
				</PreviewButtonWrapper>
			</>
		);
	}
}

export default withTranslation(ArticleModuleEmbedEditor);
