/**
 * @generated SignedSource<<49c7725f16b1c6289d3a056c8491fddd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BRBildRequestConfig = {
  sessionId: string;
};
export type BRBildSearchOptionsWithRestrictions = {
  count?: number | null;
  imageTypes?: ReadonlyArray<string | null> | null;
  max?: number | null;
  offset?: number | null;
  rights?: ReadonlyArray<string | null> | null;
};
export type searchBrBildSearchQuery$variables = {
  config?: BRBildRequestConfig | null;
  options?: BRBildSearchOptionsWithRestrictions | null;
  query?: string | null;
};
export type searchBrBildSearchQuery$data = {
  readonly brBildAPI: {
    readonly search: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }> | null;
      readonly totalCount: number;
    } | null;
  } | null;
};
export type searchBrBildSearchQuery = {
  response: searchBrBildSearchQuery$data;
  variables: searchBrBildSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "config"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BRBildAPI",
    "kind": "LinkedField",
    "name": "brBildAPI",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "config",
            "variableName": "config"
          },
          {
            "kind": "Variable",
            "name": "options",
            "variableName": "options"
          },
          {
            "kind": "Variable",
            "name": "query",
            "variableName": "query"
          }
        ],
        "concreteType": "BRBildSearchConnection",
        "kind": "LinkedField",
        "name": "search",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BRBildImageNode",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "searchBrBildSearchQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchBrBildSearchQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d4422907a61694f8fe4978ef0612f70c",
    "id": null,
    "metadata": {},
    "name": "searchBrBildSearchQuery",
    "operationKind": "query",
    "text": "query searchBrBildSearchQuery(\n  $config: BRBildRequestConfig\n  $query: String\n  $options: BRBildSearchOptionsWithRestrictions\n) {\n  brBildAPI {\n    search(config: $config, query: $query, options: $options) {\n      totalCount\n      nodes {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d02fd6aefa53adf817f46a18260254fb";

export default node;
