/**
 * @generated SignedSource<<f76f3c807a432c62984d270c936da9e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Status = "DELETED" | "DEPUBLISHED" | "DRAFT" | "PUBLISHED" | "REVIEW" | "SCHEDULED";
export type ContainerTeaserItemInternalQuery$variables = {
  rowId: string;
};
export type ContainerTeaserItemInternalQuery$data = {
  readonly article: {
    readonly publicationDate: string | null;
    readonly status: Status;
    readonly teaserImage: string | null;
    readonly teaserText: string | null;
    readonly title: string;
  } | null;
  readonly board: {
    readonly publicationDate: string | null;
    readonly teaserImage: {
      readonly url: string | null;
    } | null;
    readonly teaserText: string;
    readonly title: string;
  } | null;
};
export type ContainerTeaserItemInternalQuery = {
  response: ContainerTeaserItemInternalQuery$data;
  variables: ContainerTeaserItemInternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teaserImage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teaserText",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": "publicationDate",
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = {
  "alias": "teaserText",
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerTeaserItemInternalQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "board",
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v7/*: any*/),
          {
            "alias": "teaserImage",
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerTeaserItemInternalQuery",
    "selections": [
      {
        "alias": "article",
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "board",
        "args": (v1/*: any*/),
        "concreteType": "Board",
        "kind": "LinkedField",
        "name": "boardByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v7/*: any*/),
          {
            "alias": "teaserImage",
            "args": null,
            "concreteType": "ModuleImage",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d06c2f7869b03480bfe1d538df7e8b4",
    "id": null,
    "metadata": {},
    "name": "ContainerTeaserItemInternalQuery",
    "operationKind": "query",
    "text": "query ContainerTeaserItemInternalQuery(\n  $rowId: String!\n) {\n  article: articleByRowId(rowId: $rowId) {\n    title\n    publicationDate\n    teaserImage\n    teaserText\n    status\n    id\n  }\n  board: boardByRowId(rowId: $rowId) {\n    title\n    publicationDate: updatedAt\n    teaserImage: image {\n      url\n      id\n    }\n    teaserText: description\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a453a962f655f3aa6020199de3a937bd";

export default node;
