import type { User } from '@sep/cms-auth-client';

import type { ValidateAction } from '@/client/store/reducers/boardBuffer/actions/validate';
import type { AppThunkAction } from '@/client/store/redux';

import type { InitializeInput, InitializeAction } from '..';
import { INITIALIZE } from '../actionTypes';

import normalize from './util/normalize';
import { validateAll } from './validate';

export function initialize(
	input: InitializeInput,
	user: User | undefined | null
): AppThunkAction<InitializeAction | ValidateAction> {
	return (dispatch) => {
		const normalized = normalize(input);

		const { articles, modules, authors, redirections, tags } = normalized;

		dispatch({
			type: INITIALIZE,
			constraint: input.rowId,
			payload: {
				articles,
				modules,
				authors,
				redirections,
				tags,
				user: user || null,
			},
		});

		dispatch(validateAll());
	};
}
