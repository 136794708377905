import { commitMutation, graphql } from 'react-relay';
import type { Environment } from 'react-relay';

import type { DeleteBoardSectionItemMutation as DeleteBoardSectionItemMutationTypes } from './__generated__/DeleteBoardSectionItemMutation.graphql';

const mutation = graphql`
	mutation DeleteBoardSectionItemMutation($input: DeleteBoardsSectionsItemByRowIdInput!) {
		deleteBoardsSectionsItemByRowId(input: $input) {
			boardsSectionsItem {
				rowId
				boardId
				articleId
				order
			}
		}
	}
`;

export function DeleteBoardSectionItemMutation(
	boardSectionItemRowId: number,
	environment: Environment
): Promise<DeleteBoardSectionItemMutationTypes['response']> {
	return new Promise((resolve, reject) => {
		commitMutation<DeleteBoardSectionItemMutationTypes>(environment, {
			mutation,
			variables: {
				input: {
					rowId: boardSectionItemRowId,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
}
