import type { AppThunkAction } from '@/client/store/redux';

import type {
	RawItem,
	ImportSophoraEntityByIDInput,
	AddItemsToImporterAction,
	AddItemGroupToImporterAction,
	ImportResultsRequestAction,
	ImportResultsSuccessAction,
	ImportResultsErrorAction,
	RawItemSophoraArticle,
} from '../../..';
import config from '../../../../../config';
import * as constants from '../../../../../constants';
import { translate } from '../../../../../translation';
import type {
	Module,
	ModuleTypeImage,
	ModuleTypeText,
	ModuleTypeAudio,
	ModuleTypeVideo,
	ModuleTypeEmbed,
	ModuleTypeGallery,
} from '../../../../ArticleEditor';
import { AM_IMPORT_SOPHORA_ARTICLE } from '../../../actionTypes';
import type {
	SophoraArticleImporterResult,
	SophoraAudio,
	SophoraBox,
	SophoraImage,
	SophoraImageGallery,
	SophoraLink,
	SophoraText,
	SophoraTwitter,
	SophoraVideo,
} from '../../../sophora-article-import';
import { addItemsToImporter, addItemGroupToImporter } from '../../addItemsToImporter';
import { error, request, success } from '../importUtils';

interface SophoraArticleImporterError {
	error: { message: string };
}

function convertSophoraResponseToRawItems(
	result: SophoraArticleImporterResult
): (RawItem | RawItemSophoraArticle)[] {
	let items: (RawItem | RawItemSophoraArticle)[] = [
		{ type: constants.AM_TYPE_SOPHORA_ARTICLE, article: result },
	];

	const editorModules = convertSophoraModulesToArticleEditorModules(result);

	items = items.concat(editorModules.map((module) => convertModuleToRawItem(module)));

	return items;
}

export function importSophoraArticle(
	sophoraId: ImportSophoraEntityByIDInput
): AppThunkAction<
	| AddItemsToImporterAction
	| AddItemGroupToImporterAction
	| ImportResultsRequestAction<typeof AM_IMPORT_SOPHORA_ARTICLE>
	| ImportResultsSuccessAction<typeof AM_IMPORT_SOPHORA_ARTICLE>
	| ImportResultsErrorAction<typeof AM_IMPORT_SOPHORA_ARTICLE>
> {
	return async (dispatch, getState) => {
		const { assetManager } = getState();

		if (assetManager.isImporterWorking) {
			return;
		}

		dispatch(request(AM_IMPORT_SOPHORA_ARTICLE));

		try {
			const res = await fetch(`${config.VITE_SOPHORA_URL_EXT}/article/${sophoraId}`);

			if (res.status !== 200) {
				throw new Error(`${translate('error')} ${res.status}: ${await res.text()}`);
			}

			const data = await res.json();
			const importerResult: SophoraArticleImporterError | SophoraArticleImporterResult = {
				...data,
			};

			if ('error' in importerResult) {
				throw new Error(importerResult.error.message);
			}

			dispatch(addItemGroupToImporter(constants.AM_IMPORTER_SOPHORA_ARTICLE, sophoraId));
			const items = convertSophoraResponseToRawItems(importerResult);

			dispatch(addItemsToImporter(constants.AM_IMPORTER_SOPHORA_ARTICLE, items, sophoraId));
			dispatch(success(AM_IMPORT_SOPHORA_ARTICLE));
		} catch (err: any) {
			console.log(err);
			dispatch(error(AM_IMPORT_SOPHORA_ARTICLE, err));
		}
	};
}

export function convertSophoraModulesToArticleEditorModules(
	article: SophoraArticleImporterResult
): Module[] {
	const modules: Module[] = [];
	if (article.leadImage?.[0]) {
		// if there is a lead image use the lead image as the first module
		modules.push(convertToImage(article.leadImage[0]));
	} else if (article.teaserImage?.[0]) {
		// if there is no lead image use the teaser image as first module
		modules.push(convertToImage(article.teaserImage[0]));
	}

	// import modules
	let contentModules = article.modules
		// remove empty text modules
		.filter((module) => !isEmptyTextModule(module))
		// remove tables, as they are not supported in newsroom
		// the import dialog will warn about the table not being
		// imported
		.filter((module) => !isTable(module))
		.map((module) => convertTextModule(module));

	contentModules = combineHeadlineAndTextModule(contentModules);
	contentModules = combineLinks(contentModules);

	const transformedContentModules = contentModules

		.map((module) => {
			switch (module.type) {
				case 'image':
					return convertToImage(module);

				case 'text':
					return convertToText(module);

				case 'box':
					return convertBox(module);

				case 'boxLink':
				case 'paragraphTeaser':
					return convertLink(module);

				case 'audio':
					return convertToAudio(module);

				case 'video':
					return convertToVideo(module);

				case 'twitter':
					return convertTwitter(module);

				default:
					throw new Error('foo');
			}
		})
		.reduce((acc, element) => {
			if (Array.isArray(element)) {
				return acc.concat(element);
			}
			acc.push(element);
			return acc;
		}, [] as Module[]);

	return modules.concat(transformedContentModules);
}

function convertModuleToRawItem(module: Module): RawItem {
	switch (module.type) {
		case 'TEXT':
			return {
				type: constants.AM_TYPE_TEXT,
				text: module.text ?? '',
			};
		case 'AUDIO':
			return {
				type: constants.AM_TYPE_AUDIO,
				title: module.audio?.title ?? null,
				url: module.audio?.url ?? null,
				copyright: module.audio?.copyright ?? null,
				duration: module.audio?.duration ?? null,
				thumbnail: module.audio?.thumbnail ?? null,
				meta: undefined,
				altText: null,
			};

		case 'EMBED':
			return {
				type: constants.AM_TYPE_EMBED_CODE,
				altText: module.embed?.altText ?? null,
				service: module.embed?.service ?? null,
				source: module.embed?.source ?? null,
			};

		case 'IMAGE': {
			return {
				type: constants.AM_TYPE_IMAGE,
				altText: module.image?.altText ?? null,
				url: module.image?.url ?? null,
				copyright: module.image?.copyright ?? null,
				title: module.image?.title ?? null,
			};
		}

		case 'VIDEO':
			return {
				type: constants.AM_TYPE_VIDEO,
				copyright: module.video?.copyright ?? null,
				duration: module.video?.duration ?? null,
				meta: undefined,
				thumbnail: module.video?.thumbnail ?? null,
				title: module.video?.title ?? null,
				url: module.video?.url ?? null,
				altText: null,
			};

		case 'GALLERY':
			return {
				type: constants.AM_TYPE_GALLERY,
				gallery: module.gallery,
			};

		default:
			throw new Error(`Can not convert module of type ${module.type}`);
	}
}

/**
 * Combines a headline and a default text module into one module with a leading heading an a trailing text section.
 * @param modules
 */
function combineHeadlineAndTextModule(modules: SophoraModule[]) {
	const result: SophoraModule[] = [];
	for (let i = 0; i < modules.length; i += 1) {
		const currentModule = modules[i];
		const isLastModule = i === modules.length - 1;
		if (currentModule.type === 'text' && currentModule.style === 'headline' && !isLastModule) {
			const nextModule = modules[i + 1];
			if (nextModule.type === 'text' && nextModule.style === 'default') {
				result.push({
					type: 'text',
					style: 'default',
					text: `${currentModule.text}${nextModule.text}`,
				});
				i += 1;
			} else {
				result.push(currentModule);
			}
		} else {
			result.push(currentModule);
		}
	}
	return result;
}

type SophoraModule =
	| SophoraImage
	| SophoraLink
	| SophoraText
	| SophoraTwitter
	| SophoraBox
	| SophoraAudio
	| SophoraImageGallery
	| SophoraVideo;

function convertToImage(image: SophoraImage): ModuleTypeImage {
	return {
		type: 'IMAGE',
		__meta__: getMetaCreated(),
		__validation__: null,
		image: convertImageProperties(image),
	} as ModuleTypeImage;
}

function convertImageProperties(image: SophoraImage) {
	return {
		url: image.url,
		copyright: image.copyright,
		altText: image.description,
		title: image.title,
	};
}

function convertToText(module: SophoraText): ModuleTypeText {
	return {
		type: 'TEXT',
		__meta__: getMetaCreated(),
		__validation__: null,
		text: module.text ?? '',
	} as ModuleTypeText;
}

function getMetaCreated() {
	return { created: true, updated: false, deleted: false };
}

/**
 * Returns `true` if a text module does not contain text or the text of the module is
 * empty.
 *
 */
function isEmptyTextModule(module: SophoraModule): boolean {
	return module.type === 'text' && (!module.text || (!!module.text && !module.text.trim()));
}

/**
 * Returns `true` if a text module is a table.
 */
export function isTable(module: SophoraModule): boolean {
	return module.type === 'text' && module.style === 'standardTable';
}

function convertTextModule(module: SophoraModule) {
	const m = { ...module };
	if (m.type === 'text') {
		// format according to the style parameter
		switch (m.style) {
			case 'default':
				m.text = wrapInHTMLElement(m.text, 'p');
				break;

			case 'headline':
				m.text = wrapInHTMLElement(m.text, 'h2');
				break;

			case 'quote':
				m.text = wrapInHTMLElement(m.text, 'blockquote');
				break;

			default:
				console.warn(`Unrecognized text paragraph style ${m.style}: ${m.text}`);
		}
	}

	return m;
}

function wrapInHTMLElement(text: string | undefined, element: string) {
	if (typeof text === 'string') {
		return `<${element}>${text}</${element}>`;
	}
	return text;
}

function convertBox(module: SophoraBox) {
	return (
		combineLinks(module.box)
			// eslint-disable-next-line
			.map((box) => {
				switch (box.type) {
					case 'audio':
						return convertToAudio(box);

					case 'video':
						return convertToVideo(box);

					case 'paragraphTeaser':
					case 'boxLink':
						return convertLink(box);

					case 'gallery':
						return convertImageGallery(box);
				}
			})
	);
}
/**
 * Combines multiple links into one link module
 */
function combineLinks<T extends SophoraModule>(module: T[]): T[] {
	return (
		module
			// combines multiple link boxes into one link box
			.reduce((acc, box, index) => {
				const lastBoxModule = acc[acc.length - 1];

				if (
					index > 0 &&
					(lastBoxModule.type === 'paragraphTeaser' || lastBoxModule.type === 'boxLink') &&
					(box.type === 'paragraphTeaser' || box.type === 'boxLink')
				) {
					acc.pop();
					acc.push({ ...lastBoxModule, links: [...lastBoxModule.links, ...box.links] });
					return acc;
				}

				acc.push({ ...box });
				return acc;
			}, [] as T[])
	);
}

function convertToAudio(box: SophoraAudio): ModuleTypeAudio {
	return {
		type: 'AUDIO',
		__meta__: getMetaCreated(),
		__validation__: null,
		audio: {
			url: box.url ?? box.urlIPhone,
			copyright: box.copyright,
			duration: box.duration,
			title: box.title,
			thumbnail: box.thumbnail ? convertImageProperties(box.thumbnail[0]) : undefined,
		},
	} as ModuleTypeAudio;
}

function convertToVideo(box: SophoraVideo): ModuleTypeVideo {
	return {
		type: 'VIDEO',
		__meta__: getMetaCreated(),
		__validation__: null,
		video: {
			url: box.url ?? null,
			copyright: box.copyright,
			duration: box.duration,
			title: box.title ?? box.headline,
			thumbnail: box.thumbnail?.[0] ? convertImageProperties(box.thumbnail[0]) : null,
		},
	} as ModuleTypeVideo;
}
function convertLink(box: SophoraLink): ModuleTypeText {
	const text = box.links.map((link) => `<li><a href="${link.url}">${link.text}</a></li>`).join();

	return {
		type: 'TEXT',
		__meta__: getMetaCreated(),
		__validation__: null,
		text: `<ul>${text}</ul>`,
	} as ModuleTypeText;
}

function convertTwitter(module: SophoraTwitter): ModuleTypeEmbed {
	const code = `<blockquote class="twitter-tweet"><p lang="en" dir="ltr">${module.text}<a href="${module.twitterUrl}"></a></p></blockquote><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`;

	return {
		type: 'EMBED',
		__meta__: getMetaCreated(),
		__validation__: null,
		embed: {
			source: code,
			service: 'twitter',
			altText: module.text,
		},
	} as ModuleTypeEmbed;
}

function convertImageGallery(box: SophoraImageGallery): ModuleTypeGallery {
	return {
		type: 'GALLERY',
		__meta__: getMetaCreated(),
		__validation__: null,
		gallery: box.images
			.map((image) => convertImageProperties(image))
			.map((image) => ({
				url: image.url,
				altText: image.altText,
				copyright: image.copyright ?? null,
				title: image.title,
			})),
	} as ModuleTypeGallery;
}
