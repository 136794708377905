function compareAnArray<T>(left: T[], right: T[]) {
	for (let index = 0, { length } = left; index < length; index += 1) {
		// eslint-disable-next-line no-use-before-define
		const value = compare(left[index], right[index]);
		if (value !== 0) {
			return value;
		}
	}
	return 0;
}

function prepareString(incomingString: string) {
	let modifiedString = incomingString.toLowerCase();
	modifiedString = modifiedString.replace(/[^\w\s]|_/g, '').replace(/\s+/g, ' ');

	return modifiedString;
}

export function compare<T>(originalLeftHand: T[], originalRightHand: T[]): number;
export function compare<T>(originalLeftHand: T, originalRightHand: T): number;
export function compare<T>(originalLeftHand: T | T[], originalRightHand: T | T[]): number {
	if (Array.isArray(originalLeftHand) && Array.isArray(originalRightHand)) {
		return compareAnArray(originalLeftHand, originalRightHand);
	}

	let leftHand: T | T[] | string = originalLeftHand;
	let rightHand: T | T[] | string = originalRightHand;

	// The Infinities are a guarenteed way to get the items without data at the end of the list
	if (typeof originalLeftHand === 'string') {
		leftHand = prepareString(originalLeftHand);
	}

	if (typeof originalRightHand === 'string') {
		rightHand = prepareString(originalRightHand);
	}

	if (leftHand < rightHand) {
		return -1;
	}
	if (leftHand > rightHand) {
		return 1;
	}
	return 0;
}

/**
 * sortBy function using a [Schwartzian transform](https://en.wikipedia.org/wiki/Schwartzian_transform)
 */
export function sortBy<T, C>(data: Array<T>, determineValue: (item: T) => C | C[]): T[] {
	return data
		.map((item) => [determineValue(item), item] as const)
		.sort((leftHand, rightHand) => compare(leftHand[0], rightHand[0]))
		.map((item) => item[1]);
}
