import { commitMutation, graphql, type Environment } from 'react-relay';

import type {
	AuthorPatch,
	UpdateAuthorMutation,
} from './__generated__/UpdateAuthorMutation.graphql';

export default (
	guid: string,
	authorPatch: AuthorPatch,
	environment: Environment
): Promise<UpdateAuthorMutation['response']> =>
	new Promise((resolve, reject) => {
		commitMutation<UpdateAuthorMutation>(environment, {
			mutation: graphql`
				mutation UpdateAuthorMutation($input: UpdateAuthorByGuidInput!) {
					updateAuthorByGuid(input: $input) {
						author {
							gender
							firstname
							lastname
							profilePicture
							jobTitle
							shortDescription
							description
							isLdapUser
							isActive
							socialMediaAccounts {
								service
								label
								accountName
							}
						}
					}
				}
			`,
			variables: {
				input: {
					guid,
					authorPatch,
				},
			},
			onCompleted: resolve,
			onError: reject,
		});
	});
