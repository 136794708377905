import { Component } from 'react';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts';

import styles from '../../../styles';
import { randomColor } from '../../../util';

type Props = {
	data: Array<{
		node: {
			articleCount: number;
			color?: string;
			title: string;
		};
	}>;
};

export default class PieChartComponent extends Component<Props> {
	shouldComponentUpdate({ data: nextData }: Props) {
		return this.props.data !== nextData;
	}

	render() {
		const { data } = this.props;
		const totalCount = data.reduce(
			(accumulator, { node: { articleCount } }) => accumulator + Number(articleCount),
			0
		);

		const chartData = data
			.map(({ node: { title: name, articleCount: value, color } }) => ({
				name,
				value,
				percent: Math.round((100 / totalCount) * value * 100) / 100,
				color: color || randomColor(),
			}))
			.filter(({ value }) => value > 0);

		/* eslint-disable react/no-array-index-key */
		return (
			<div>
				<ResponsiveContainer width="100%" aspect={1}>
					<PieChart>
						<Tooltip />
						<Pie dataKey="value" data={chartData} innerRadius="50%" endAngle={-270} startAngle={90}>
							{chartData.map(({ color }, index) => (
								<Cell key={index} fill={color} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
				<ul className={styles.chartLegend}>
					{chartData.map(({ name, color, percent }, index) => (
						<li key={index}>
							<div style={{ backgroundColor: color }}>{percent}%</div> {name}
						</li>
					))}
				</ul>
			</div>
		);
		/* eslint-enable react/no-array-index-key */
	}
}
