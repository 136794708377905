import { bool, cleanEnv, defaultReporter, str } from 'envalid';

const source = typeof window !== 'undefined' ? (window as any).config : process.env;

export const environmentConfig = cleanEnv(
	source,
	{
		VITE_BRBILD_USER: str(),
		VITE_BRBILD_PASSWORD: str(),
		VITE_BRBILD_URL_EXT: str(),
		VITE_ENABLE_IMPORTER_SOPHORA: bool({ default: false }),
		VITE_FIREBASE_AUTH_EMULATOR_HOST: str({ default: undefined }),
		VITE_FIREBASE_DATABASE_EMULATOR_HOST: str({ default: undefined }),
		VITE_AUTH_PROVIDER: str(),
		VITE_NODE_ENV: str({ default: 'development' }),
		VITE_DEFAULT_LANGUAGE: str({ default: 'de_DE' }),
		VITE_AUTH_CLIENT_ID: str({ default: 'cb72e4e5-bca1-474c-9e5f-6f0bb28f9fa8' }),
		VITE_CMS_CLIENT_URL_EXT: str({ default: 'http://localhost:4040' }),
		VITE_BRANCH: str(),
		VITE_FIREBASE_CRED_API_KEY: str(),
		VITE_FIREBASE_CRED_AUTH_DOMAIN: str(),
		VITE_FIREBASE_CRED_DATABASE_URL: str(),
		VITE_FIREBASE_CRED_PROJECT_ID: str(),
		VITE_GOOGLE_MAPS_API_KEY: str(),
		VITE_GRAPHQL_URL_EXT: str(),
		VITE_RENDERER_EMBEDS_URL_EXT: str(),
		VITE_SOPHORA_URL_EXT: str(),
		VITE_WEB_CLIENT_URL_EXT: str(),
		VITE_WEB_PREVIEW_CLIENT_URL_EXT: str(),
		VITE_IMAGE_UPLOAD_URL_EXT: str(),
		VITE_GA_TRACKING_CODE: str({ default: undefined }),
		VITE_ENABLE_STORY_MERGE_FEATURE: bool({ default: false }),
		VITE_FIREBASE_AUTH_URL_EXT: str({ default: '/firebase/token' }),
	},
	{
		reporter: process.env.NODE_ENV === 'test' ? () => null : defaultReporter,
	}
);
