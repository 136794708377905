import { CloseOutlined } from '@ant-design/icons';
import { COLOR_BOARD_SECTION_4, USER_SECTION_UPDATE_FLAG } from '@sep/br24-constants';
import * as constants from '@sep/br24-constants';
import { Modal, Button, Form, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Component, type MouseEventHandler, type SyntheticEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import type { ReduxState } from '@/client/store/reducers';
import { connect } from '@/client/store/redux';

import { provideSectionValidationById } from '../../../store/reducers/boardBuffer';
import type {
	BoardSectionBufferPatch,
	BoardSectionBuffer,
} from '../../../store/reducers/boardBuffer/types';
import gs from '../../../styles';
import translations from '../../../translations';
import attachFormItemErrorProps from '../../../util/attachFormItemErrorProps';
import BoardLinkPicker from '../../BoardLinkPicker';
import CategoryPicker from '../../CategoryPicker';
import ColorPicker from '../../ColorPicker';
import LimitedLengthItem from '../../LimitedLengthItem';
import UserAlert from '../../UserAlert';
import { Input } from '../Form';

interface Props extends ReduxProps, OwnProps {
	isVisible: boolean;
	onRequestUpdate: (patch: BoardSectionBufferPatch) => void;
	onRequestClose: MouseEventHandler<HTMLElement>;
}

class SectionSettings extends Component<Props> {
	handleChangeAutofill = (event: SyntheticEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		this.props.onRequestUpdate({ autofill: target.checked });
	};

	handleChangeBoardLink = (boardLink: string | null) => {
		this.props.onRequestUpdate({ boardLink });
	};

	handleChangeCategory = (categoryId: string | null) => {
		// If the user chooses a category, we'll save the default color as `section.color`.
		this.props.onRequestUpdate({ categoryId, color: COLOR_BOARD_SECTION_4 });

		const title = this.props.section?.title;

		if (!categoryId && !title) {
			this.props.onRequestUpdate({ boardLink: null });
		}
	};

	handleChangeColor = (color: string) => this.props.onRequestUpdate({ color });

	handleChangeTitle = (title: string) => {
		this.props.onRequestUpdate({ title: title || null });

		const categoryId = this.props.section?.categoryId;

		if (!categoryId && !title) {
			this.props.onRequestUpdate({ boardLink: null });
		}
	};

	handleResetColor = () => this.props.onRequestUpdate({ color: null });

	handleChangeMobileTitle = (event: CheckboxChangeEvent) => {
		const target = event.target;
		this.props.onRequestUpdate({ showHeroTitleForMobile: target.checked });
	};

	render() {
		const { section, validation, isVisible, onRequestClose } = this.props;
		const { categoryId } = section;

		const isBoardLinkPickerDisabled = !section.categoryId && !section.title;

		return (
			<Modal
				title="Einstellungen"
				open={isVisible}
				onCancel={onRequestClose}
				footer={[
					<Button key="close" size="large" onClick={onRequestClose}>
						Fertig
					</Button>,
				]}
			>
				<Form layout="vertical">
					<LimitedLengthItem
						label="Titel"
						maxLength={64}
						{...attachFormItemErrorProps(validation, 'title')}
					>
						<Input
							placeholder="Titel"
							defaultValue={section.title ?? undefined}
							onMagicChange={this.handleChangeTitle}
						/>
					</LimitedLengthItem>
					{translations.boardSectionSchema.title[section.schema] ===
						translations.boardSectionSchema.title[
							constants.SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER
						] && (
						<Form.Item label={translations.mobileTitleLabel}>
							<Checkbox
								defaultChecked={section.showHeroTitleForMobile}
								onChange={this.handleChangeMobileTitle}
							>
								{translations.mobileTitleText}
							</Checkbox>
						</Form.Item>
					)}
					<UserAlert
						flag={USER_SECTION_UPDATE_FLAG}
						message="Änderungen an diesen Einstellungen werden sofort in den Zwischenspeicher übernommen."
						style={{ marginBottom: '1rem' }}
					/>
					{translations.boardSectionSchema.title[section.schema] !==
						translations.boardSectionSchema.title[constants.SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER] &&
						translations.boardSectionSchema.title[section.schema] !==
							translations.boardSectionSchema.title[
								constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER
							] && (
							<Form.Item label="Kategorie">
								<CategoryPicker
									placeholder="Kategorie"
									showEmpty={true}
									displayBlock={true}
									onChange={this.handleChangeCategory}
									defaultValue={section.categoryId}
								/>
							</Form.Item>
						)}
					<Form.Item label="Link auf Verteilseite">
						<BoardLinkPicker
							placeholder="Verteilseite"
							defaultValue={section.boardLink}
							disabled={isBoardLinkPickerDisabled}
							onChange={this.handleChangeBoardLink}
							sectionBoardId={section.__meta__.boardId}
						/>
					</Form.Item>

					{!categoryId &&
						translations.boardSectionSchema.title[section.schema] !==
							translations.boardSectionSchema.title[
								constants.SECTION_SCHEMA_SUBJECTS_NEWS_WEATHER
							] &&
						translations.boardSectionSchema.title[section.schema] !==
							translations.boardSectionSchema.title[
								constants.SECTION_SCHEMA_RAISED_RIGHT_SIMPLE_WEATHER
							] && (
							<Form.Item
								label="Farbe"
								style={{ marginBottom: 0 }}
								{...attachFormItemErrorProps(validation, 'color')}
							>
								<ColorPicker
									defaultColor={section.color ?? undefined}
									onChange={this.handleChangeColor}
								/>

								{section.color ? (
									<Button
										className={gs.mtM}
										icon={<CloseOutlined />}
										type="dashed"
										size="small"
										onClick={this.handleResetColor}
									>
										Farbe zurücksetzten
									</Button>
								) : (
									false
								)}
							</Form.Item>
						)}
				</Form>
			</Modal>
		);
	}
}

interface OwnProps {
	section: BoardSectionBuffer;
}

const connector = connect((state: ReduxState, { section }: OwnProps) => ({
	validation: provideSectionValidationById(state.boardBuffer, section.__meta__.internalId),
}));

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SectionSettings);
