import { Modal } from 'antd';
import { Component } from 'react';
import type { SyntheticEvent } from 'react';
import type { ConnectedProps } from 'react-redux';

import { connect } from '@/client/store/redux';

import * as constants from '../../../../constants';
import { type WithTranslationProps, withTranslation } from '../../../../translation';
import {
	AssetManagerImporterInput,
	AssetManagerImporterForm,
	AssetManagerImporterInputAction,
	AssetManagerImporterError,
	AssetManagerImporterContentEmpty,
} from '../../../../ui/AssetManager';
import { Accept, Spinner } from '../../../../ui/Icon';
import addAuthor from '../../../ArticleEditor/actions/addAuthor';
import addTag from '../../../ArticleEditor/actions/addTag';
import createModule from '../../../ArticleEditor/actions/createModule';
import updateArticleEditor from '../../../ArticleEditor/actions/update';
import * as actions from '../../actions';
import { importerBaseInformationSelector, importedItemsByImporterSelector } from '../../selectors';
import AssetManagerImporterResults from '../AssetManagerImportResults/AssetManagerImporterResults';

interface AssetManagerImporterSophoraProps extends WithTranslationProps, ReduxProps {}

type AssetManagerImporterSophoraState = {
	sophoraId: string;
	takeOverActions: {
		[id: string]: boolean;
	};
};

class AssetManagerImporterSophora extends Component<
	AssetManagerImporterSophoraProps,
	AssetManagerImporterSophoraState
> {
	state = {
		sophoraId: '',
		takeOverActions: {},
	};

	checkForTitleAndHeadline = () => {
		const {
			importedItems: imports,
			translation: { translate },
		} = this.props;

		let headlineAndTeaser: null | any = null;
		const ids: string[] = [];

		if (imports.length > 0) {
			// While not how sophora works yet, it is expected to persist multiple searches in the importer.
			// As a result, this should only trigger for the latest sophora import.
			const lastImport = imports[imports.length - 1].items;

			headlineAndTeaser = lastImport.reduce((accumulator, item) => {
				if ('isTeaserText' in item && item.isTeaserText) {
					ids.push(item.id);

					return {
						...accumulator,
						teaserText: item.text,
					};
				}

				if ('isHeadline' in item && item.isHeadline) {
					ids.push(item.id);

					return {
						...accumulator,
						headline: item.text,
					};
				}

				return accumulator;
			}, {});

			if (!headlineAndTeaser) {
				return null;
			}

			return (
				<Modal
					title={translate(`assetManager.dataTakeoverModal.headline`)}
					open={true}
					okText={translate(`assetManager.dataTakeoverModal.confirmOk`)}
					cancelText={translate(`assetManager.dataTakeoverModal.confirmCancel`)}
					onOk={() =>
						headlineAndTeaser &&
						this.handleTakeOverOk(
							ids,
							headlineAndTeaser.headline,
							headlineAndTeaser.teaserText,
							lastImport
						)
					}
					onCancel={this.handleTakeOverCancel}
				>
					<p>{translate(`assetManager.dataTakeoverModal.confirmText`)}</p>
					<br />
					<h4>{translate(`assetManager.dataTakeoverModal.headlineContent`)}</h4>
					<p>{headlineAndTeaser.headline}</p>
					<br />
					<h4>{translate(`assetManager.dataTakeoverModal.headlineTeaserText`)}</h4>
					<p>{headlineAndTeaser.teaserText}</p>
					<br />
					<h4>{translate(`assetManager.dataTakeoverModal.other`)}</h4>
				</Modal>
			);
		}

		return null;
	};

	handleChangeSophoraID = (event: SyntheticEvent<HTMLInputElement>) =>
		this.setState({
			sophoraId: event.currentTarget.value,
		});

	handleImport = () => {
		const { importResults } = this.props;
		const { sophoraId } = this.state;

		if (sophoraId.trim().length === 0) {
			return;
		}

		importResults(constants.AM_IMPORTER_SOPHORA, { mode: 'searchSophora' }, sophoraId);

		this.setState({
			// workaround, to let "takeover" dialog appear
			// sophoraId: '',
			takeOverActions: {
				[sophoraId]: false,
			},
		});
	};

	handleKeyPress = (event: KeyboardEvent) => {
		if (event.which === 13) {
			event.preventDefault();
			this.handleImport();
		}
	};

	handlePaste = (event: any) => {
		const maybeSophoraID = event.clipboardData.getData('Text');

		if (typeof maybeSophoraID === 'string' && maybeSophoraID.includes('br.de')) {
			const matched = /((https|http):\/\/)?(www\.)?br.de\/(.*)\/(.*)\.html?/.exec(maybeSophoraID);

			if (matched && matched.length > 4) {
				const nextSophoraID = matched.pop();
				event.preventDefault();
				if (nextSophoraID) {
					this.setState({
						sophoraId: nextSophoraID,
					});
				}
			}
		}
	};

	handleTakeOverCancel = () => {
		this.setState((prevState) => ({
			takeOverActions: {
				[prevState.sophoraId]: true,
			},
		}));
	};

	handleTakeOverOk = (
		ids: Array<string>,
		title: string,
		teaserText: string,
		{ additionalData }: any
	) => {
		const { metaDescription } = additionalData; // also includes author and keywords, which are not being used yet
		this.setState(
			(prevState) => ({
				takeOverActions: {
					[prevState.sophoraId]: true,
				},
			}),
			() => {
				this.props.updateArticleEditor({
					title,
					teaserText,
					metaDescription,
					// HINT: we cannot use author here as the data must conform with our graphql schema
					// and authors are being handled by the field `articlesAuthorsUsingRowId` which requires the authors' guid instead the name only
					// author,
				});

				// TODO fill/focus author field or use this.props.addAuthor()

				// TODO: lookup keywords in database via GraphQL to get existing ones and add ids
				/*
				keywords.forEach((text) => {
					this.props.addTag({ text, id: TODO });
				});
				*/

				// TODO: add teaser image via this.props.createModule()
				// this.props.createModule({});

				// TODO: add text via this.props.createModule()

				// delete from importer
				ids.forEach((id) => {
					this.props.removeFromImporter(constants.AM_IMPORTER_SOPHORA, id);
				});
			}
		);
	};

	render() {
		const {
			translation: { translate },
			latestImporterError,
			isImporterWorking,
			importedItems,
		} = this.props;
		const { sophoraId, takeOverActions } = this.state;
		// only show data takeover when a artice is active open
		// otherwise all elements will be display for import
		const articleViewIsActive = window.location.href.includes('articleEditor');

		return (
			<>
				{latestImporterError && (
					<AssetManagerImporterError>{latestImporterError.message}</AssetManagerImporterError>
				)}
				<AssetManagerImporterForm>
					<AssetManagerImporterInput
						size="full"
						color="dark"
						mode="code"
						placeholder={translate(
							`assetManager.importer.${constants.AM_IMPORTER_SOPHORA}.inputPlaceholder`
						)}
						onChange={this.handleChangeSophoraID}
						onKeyPress={this.handleKeyPress}
						onPaste={this.handlePaste}
						value={sophoraId}
						actions={[
							<AssetManagerImporterInputAction
								key="import"
								onClick={this.handleImport}
								className={isImporterWorking ? 'loading' : ''}
							>
								{isImporterWorking ? <Spinner /> : <Accept />}
							</AssetManagerImporterInputAction>,
						]}
					/>
				</AssetManagerImporterForm>
				{importedItems.length === 0 && (
					<AssetManagerImporterContentEmpty>
						{translate(`assetManager.importer.${constants.AM_IMPORTER_SOPHORA}.empty`)}
					</AssetManagerImporterContentEmpty>
				)}
				{importedItems.length > 0 && (
					<div>
						{takeOverActions.hasOwnProperty(sophoraId) &&
						!takeOverActions[sophoraId] &&
						sophoraId !== '' &&
						articleViewIsActive
							? this.checkForTitleAndHeadline()
							: null}
						<AssetManagerImporterResults
							importer={constants.AM_IMPORTER_SOPHORA}
							importedItems={importedItems}
							importerWorking={this.props.isImporterWorking}
						/>
					</div>
				)}
			</>
		);
	}
}

const connector = connect(
	(state) => ({
		...importerBaseInformationSelector(state),
		importedItems: importedItemsByImporterSelector(constants.AM_IMPORTER_SOPHORA)(state),
	}),

	{
		updateArticleEditor,
		createModule,
		addAuthor,
		addTag,
		importResults: actions.importResults,
		removeFromImporter: actions.removeItemFromImporter,
	}
);

type ReduxProps = ConnectedProps<typeof connector>;

export default withTranslation(connector(AssetManagerImporterSophora));
