import styled from 'styled-components';

import { useTranslate } from '@/client/translation/useTranslate';

const SophoraIdWrapper = styled.div`
	font-weight: bold;
	margin-bottom: 10px;
`;

type Props = {
	sophoraId: string;
};

export default function SophoraSearchLinkItem({ sophoraId }: Props) {
	const translate = useTranslate();

	return (
		<div>
			<div>{translate('article.meta.more.sophoraSearch.connectedArticleText')}</div>
			<SophoraIdWrapper>{sophoraId}</SophoraIdWrapper>
		</div>
	);
}
