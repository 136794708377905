import { Spin } from 'antd';
import { graphql, QueryRenderer, type QueryRendererProps, useRelayEnvironment } from 'react-relay';
import styled from 'styled-components';

import Checkmark from '../../../../ui/Checkmark';

import type { AuthorRendererQuery } from './__generated__/AuthorRendererQuery.graphql';

type Props = {
	guid: string;
};

const TextWrapper = styled.div`
	display: inline-flex;
	align-items: center;
`;

const ItemJobTitle = styled.div`
	font-style: italic;
`;

const renderAuthor = ({
	error,
	props,
}: Parameters<QueryRendererProps<AuthorRendererQuery>['render']>[0]) => {
	if (error) {
		throw error;
	}

	const isReady = error === null && props && props.author;

	if (!isReady) {
		return <Spin size="small" />;
	}

	const jobTitle = props.author.jobTitle || '-';

	return props.author.isLdapUser ? (
		<>
			<TextWrapper>
				{props.author.firstname} {props.author.lastname}
				<Checkmark />
			</TextWrapper>
			<ItemJobTitle>{jobTitle}</ItemJobTitle>
		</>
	) : (
		<>
			<div>{`${props.author.firstname} ${props.author.lastname}`}</div>
			<ItemJobTitle>{jobTitle}</ItemJobTitle>
		</>
	);
};

function AuthorRenderer({ guid }: Props) {
	const environment = useRelayEnvironment();
	return (
		<QueryRenderer<AuthorRendererQuery>
			environment={environment}
			query={graphql`
				query AuthorRendererQuery($guid: String!) {
					author: authorByGuid(guid: $guid) {
						firstname
						lastname
						isLdapUser
						jobTitle
					}
				}
			`}
			variables={{ guid }}
			render={renderAuthor}
		/>
	);
}

export default AuthorRenderer;
