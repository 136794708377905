// TODO: split file
// TODO: rename component

import invariant from 'invariant';
import { darken } from 'polished';
import { Component } from 'react';
import type { SyntheticEvent } from 'react';
import HotKey from 'react-hot-keys';
import styled from 'styled-components';

import type { Type } from '@/client/containers/AssetManager';
import { withTranslation } from '@/client/translation/withTranslation';

import {
	AM_TYPE_FILTER,
	AM_TYPE_BRBILD_IMAGE,
	AM_TYPE_IMAGE,
	HOTKEY_ASSETMANAGER_APPLY_FILTER,
} from '../../constants';
import type { WithTranslationProps } from '../../translation';
import { getAssetManagerTypeIcon, Cancel, Accept } from '../Icon';

const Overlay = styled.div`
	width: 100%;
	padding: 5rem 2rem 4rem 2rem;
	text-align: center;
	height: ${(props) =>
		`calc(100vh - ${props.theme.assetManager.navigatorHeight} - ${props.theme.header.height} - ${props.theme.header.indicatorHeight} - ${props.theme?.assetManager.innerPadding} - ${props.theme?.header.indicatorHeight})`};
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: ${(props) => `calc(${props.theme.header.height} + ${props.theme.header.indicatorHeight})`};
	left: 0;
	z-index: 2;
	color: #ffffff;
`;

const Heading = styled.h2`
	font-size: 1.05rem;
	color: #ffffff;
`;

const TypeWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
`;

type TypeButtonProps = {
	isActive?: boolean;
};

const TypeButton = styled.a<TypeButtonProps>`
	display: inline-block;
	color: #ffffff;
	background-color: ${(props) =>
		props.isActive ? props.theme?.colors.primaryBlue : props.theme?.assetManager.itemColor};
	padding: 0.5rem 1rem;
	margin: 0.5rem;
	text-align: left;
	width: calc(100% * (1 / 2) - 1rem - 1px);
	border: 1px solid
		${(props) => (props.isActive ? props.theme?.colors.primaryBlue : 'rgba(255, 255, 255, 0.1)')};
	border-radius: 2px;
	&:hover {
		background-color: ${(props) =>
			props.isActive
				? darken(0.1, props.theme?.colors.primaryBlue)
				: props.theme?.colors.primaryBlue};
		border-color: ${(props) =>
			props.isActive
				? darken(0.1, props.theme?.colors.primaryBlue)
				: props.theme?.colors.primaryBlue};
		color: #ffffff;
	}
`;

const TypeButtonIconWrapper = styled.span`
	display: inline-block;
	margin-right: 0.6rem;
`;

const ActionButtonWrapper = styled.div`
	margin-top: 4rem;
`;

const ActionButtonIconWrapper = styled.span`
	display: inline-block;
	margin-right: 0.6rem;
	font-size: 1.1rem;
	position: relative;
	top: 1px;
`;

const ActionButton = styled.a<TypeButtonProps>`
	padding: 0.8rem 1.4rem;
	color: #ffffff;
	background-color: ${(props) => props.theme.colors.primaryBlue};
	transition: all 200ms ease;
	&:hover {
		color: #ffffff;
		background-color: ${(props) => darken(0.1, props.theme.colors.primaryBlue)};
	}
	&:first-child {
		border-right: 1px solid ${(props) => darken(0.1, props.theme.colors.primaryBlue)};
	}
	&:last-child {
		margin-right: 0;
	}
`;

export type AssetManagerType = Type;

type AssetManagerTypeSelectorOverlayProps = {
	onSubmit: (selected: Array<AssetManagerType>) => void;
	onReset: () => void;
	selected?: Array<AssetManagerType>;
};

type AssetManagerTypeSelectorOverlayState = {
	currentlySelected: Array<AssetManagerType>;
};

class AssetManagerTypeSelectorOverlay extends Component<
	WithTranslationProps & AssetManagerTypeSelectorOverlayProps,
	AssetManagerTypeSelectorOverlayState
> {
	static defaultProps = {
		selected: [],
	};

	state = {
		currentlySelected: this.props.selected || [],
	};

	handleClickItem = (event: SyntheticEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		const { currentlySelected } = this.state;
		const { value } = event.currentTarget.dataset;

		invariant(value, 'Attribute data-value is missing');

		let nextCurrentlySelected: AssetManagerType[] = [];
		if (value && currentlySelected.includes(value as AssetManagerType)) {
			nextCurrentlySelected = currentlySelected.filter((selected: string) => selected !== value);

			if (value === AM_TYPE_IMAGE && nextCurrentlySelected.includes(AM_TYPE_BRBILD_IMAGE)) {
				const index = nextCurrentlySelected.indexOf(AM_TYPE_BRBILD_IMAGE);
				nextCurrentlySelected.splice(index, 1);
			}
		} else {
			nextCurrentlySelected = [value as AssetManagerType, ...currentlySelected];
			if (value === AM_TYPE_IMAGE) {
				nextCurrentlySelected.push(AM_TYPE_BRBILD_IMAGE);
			}
		}

		this.setState({
			currentlySelected: nextCurrentlySelected,
		});
	};

	handleClickReset = (event: SyntheticEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		const { onReset } = this.props;
		this.setState(
			{
				currentlySelected: [],
			},
			() => onReset()
		);
	};

	handleHotkey = () => {
		const { currentlySelected } = this.state;
		this.props.onSubmit(currentlySelected);
	};

	handleSubmit = (event: SyntheticEvent<HTMLAnchorElement>) => {
		event.preventDefault();

		const { currentlySelected } = this.state;
		this.props.onSubmit(currentlySelected);
	};

	render() {
		const {
			translation: { translate },
		} = this.props;
		const { currentlySelected } = this.state;

		return (
			<>
				<HotKey keyName={HOTKEY_ASSETMANAGER_APPLY_FILTER} onKeyDown={this.handleHotkey} />
				<Overlay>
					<Heading>Wählen Sie einen oder mehrere Filter!</Heading>
					<TypeWrapper>
						{AM_TYPE_FILTER.map((type) => {
							const TypeIcon = getAssetManagerTypeIcon(type);
							return (
								<TypeButton
									key={type}
									isActive={currentlySelected.includes(type as AssetManagerType)}
									data-value={type}
									onClick={this.handleClickItem}
								>
									<TypeButtonIconWrapper>
										<TypeIcon />
									</TypeButtonIconWrapper>
									{translate(`assetManager.type.${type}`)}
								</TypeButton>
							);
						})}
					</TypeWrapper>
					<ActionButtonWrapper>
						<ActionButton onClick={this.handleSubmit}>
							<ActionButtonIconWrapper>
								<Accept />
							</ActionButtonIconWrapper>
							{translate('assetManager.type.confirm.ok')}
						</ActionButton>
						<ActionButton onClick={this.handleClickReset}>
							<ActionButtonIconWrapper>
								<Cancel />
							</ActionButtonIconWrapper>
							{translate('assetManager.type.confirm.reset')}
						</ActionButton>
					</ActionButtonWrapper>
				</Overlay>
			</>
		);
	}
}

export default withTranslation(AssetManagerTypeSelectorOverlay);
