import { generateMedia } from 'styled-media-query';

const sizes = {
	monster: '1680px',
	giant: '1440px',
	large: '1280px',
	medium: '1024px',
	tablet: '768px',
	phone: '376px',
};

// eslint-disable-next-line import/prefer-default-export
export const media = generateMedia(sizes);
